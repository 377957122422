// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.
import { includes } from 'lodash'

import { setWidgetActive } from '../actions/app'
import {
  MARK_AS_MODIFIED,
  TOGGLE_WIDGET_MODIFIED,
} from '../actions/widgets'
import { registeredMFEs } from '../utils/MFEUtils'

export default (store: Object) => (next: Function) => (action: Object) => {
  const widgetEditingTypes = [
    MARK_AS_MODIFIED,
  ]
  const editingStarted = () => {
    const {
      payload: {
        widgetId,
        tab
      }
    } = action
    // add activeEdit flag to applicationReducer
    store.dispatch(setWidgetActive(widgetId, tab))
  }

  // logic to disable components in view when a widget is being edited
  if (includes(widgetEditingTypes, action.type)) {
    editingStarted()
    // logic to re-enable components in view when widget editing is completed
  }

  if (action.type === TOGGLE_WIDGET_MODIFIED && action.payload.widgetId) {
    const { isModified } = action.payload
    if (isModified) {
      editingStarted()
    }
  }
  if (action) {
    next(action)
  }
  Object.keys(registeredMFEs).forEach(async (MFEName: string) => {
    const app = registeredMFEs[MFEName]
    if (app.onMainAction) {
      try {
        app.onMainAction(action, store.getState)
      } catch (er) {
        console.error(er)
      }
    }
  })
}
