// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.
import React from 'react'
import { withTranslation } from 'react-i18next'
import IconTooltip from '../../common/Tooltip/IconTooltip/IconTooltip'
import { renderZero as rz } from '../../../utils/commonUtils'
import { type TooltipAction } from '../../common/Tooltip/Tooltip'

type Props = {|
  t: Function, // translation function
  actionCb: Function, // the function used to revert user made changes
  defaultValue: string, // this is shown in the tooltip content if given
  visible: boolean, // whether the component is visible or not
  id: string, // id for e2e testing purposes
  dataType?: string, // datatype for
  text?: string, // optional text to overwrite default text
  actionText?: string, // optional text for the action to overwrite default text
  isVisible?: boolean, // sets css visibility for the component
  content?: React$Element<any>, // JSX content to be displayed in the tooltip
|}

export class UserModifiedIcon extends React.Component<Props> {
  static defaultProps = {
    visible: true,
    dataType: '',
    defaultValue: '',
    id: 'undefined',
    text: '',
    actionText: '',
    isVisible: true,
    multiline: false,
    content: null
  }

  get text(): string {
    const {
      t,
      dataType,
      defaultValue,
      text
    } = this.props
    if (text) return text
    let value = defaultValue
    if (dataType === 'boolean') {
      value = (defaultValue ? `${t('boolean._TRUE_')}` : `${t('boolean._FALSE_')}`)
    }
    // this absolute beaut of a markdown string produces two line changes between the two sentences
    // DON'T FIDDLE WITH
    return `${t('userModifiedInfo._USER_MODIFIED_VALUE_')}  \n` +
    '` `  ' +
    `${rz(value) ? `  \n  ${t('userModifiedInfo._DEFAULT_VALUE_')}: "**${value}**"` : ''}`
  }

  get iconAction(): TooltipAction | null {
    const { actionCb, t, actionText } = this.props
    if (actionCb) {
      return { label: actionText || t('userModifiedInfo._USER_MODIFIED_REVERSE_BUTTON_'), action: actionCb, id: 'UserModifiedContent' }
    }
    return null
  }

  render(): any {
    const { isVisible, content } = this.props
    return (
      !this.props.visible ? null :
      <div style={{ visibility: isVisible ? 'visible' : 'hidden' }}>
        <IconTooltip
          id={`${this.props.id}-UserModifiedIcon`}
          content={content}
          text={this.text}
          action={this.iconAction}
          data-testid={`${this.props.id}-UserModifiedIcon`}>
          <IconTooltip.ActionIcon icon='account_circle' />
        </IconTooltip>
      </div>
    )
  }
}

export default withTranslation('translations')(UserModifiedIcon)
