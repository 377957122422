// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.

import React from 'react'
import { withStyles } from '@material-ui/core'
import ResizeObserver from 'react-resize-observer'
import Tooltip from '../Tooltip/Tooltip'

const styles = (): Object => ({
  overflow: {
    position: 'relative',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis'
  }
})

type Props = {|
  classes: Object, // MUI classes object
  children: string, // content within the wrapping elements
  tooltipText: string, // text for the tooltip
  testId: string, // data-testid if provided
  renderAlways: boolean, // if tooltip should render regardless if text overflows its container
  placement?: string, // passed to Tooltip, placement of the tooltip relative to the content
|}

type State = {|
  isOverflowing: boolean, // if the content is overflowing
|}

class OverflowTooltip extends React.Component<Props, State> {
  static defaultProps = {
    testId: 'overflowing',
    tooltipText: '',
    placement: undefined
  }

  state = {
    isOverflowing: false,
  }

  ref = React.createRef()

  componentDidMount() {
    this.onResize()
  }

  onResize = () => {
    if (!this.props.renderAlways) {
      const { current } = this.ref || {}
      if (current) {
        this.setState({ isOverflowing: current.offsetWidth < current.scrollWidth })
      }
    }
  }

  render(): React$Element<typeof Tooltip> | null {
    const {
      classes,
      testId,
      children,
      tooltipText,
      renderAlways,
      placement
    } = this.props
    const { isOverflowing } = this.state

    // Rendering Tooltip is controlled by providing or not providing a string as a text -prop. Empty string wont get rendered
    return (
      <Tooltip text={isOverflowing || renderAlways ? tooltipText : ''} placement={placement}>
        <div ref={this.ref} data-testid={testId} className={classes.overflow}>
          {children}
          <ResizeObserver onResize={this.onResize} />
        </div>
      </Tooltip>
    )
  }
}

export default withStyles(styles)(OverflowTooltip)
