// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.
import { connect, batch } from 'react-redux'
import { filter, map, isEmpty } from 'lodash'
import { withStyles } from '@material-ui/core'
import { withTranslation } from 'react-i18next'
import React from 'react'

import FormControlLabel from '@material-ui/core/FormControlLabel'
import OverflowTooltip from '../../../common/OverflowTooltip/OverflowTooltip'
import { type MenuItemProps } from '../../../common/menus/components/MenuItems/MenuItem/MenuItem'
import DescriptionCell from '../../../common/lists/common/DescriptionCell/DescriptionCell'
import DropdownMenu from '../../../common/menus/DropdownMenu/DropdownMenu'
import ActivityLinkIcon from '../../../common/ActivityLinkIcon/ActivityLinkIcon'
import ErrorBoundary from '../../../common/ErrorBoundary/ErrorBoundary'
import HamburgerMenu from '../../../common/menus/HamburgerMenu/HamburgerMenu'
import HierarchicalListContainer from '../../HierarchicalListContainer/HierarchicalListContainer'
import Spinner from '../../../common/Spinner/Spinner'
import Switch from '../../../common/Switch/Switch'
import CheckBox from '../../../common/CheckBox/CheckBox'
import SentientHOC from '../../../hocs/SentientHOC/SentientHOC'
import UserModifiedIcon from '../../infoComponents/UserModifiedIcon'

import HALParser, { ALL_KEYS } from '../../../../utils/HALParser'
import { MODAL_TYPE_PROPERTY_EDITOR, MODAL_TYPE_CONFIRMATION_MODAL_WITH_GARS } from '../../../../constants/modalConstants'
import { DATE_D, DATE_W, DATE_A } from '../../../../constants/dateConstants'
import {
  FUNCTIONALSECTOR,
  FUNCTION,
  PROCESS,
  ACTIVITYGROUP,
  ACTIVITY,
  UNIT_EDITING_MODAL,
  OPERATING_PROFILE_SELECT_MODAL,
  ACTIVITY_REGISTRY_WIDGET,
  WOP_SPACE_SCHEDULE,
  ACTIVITY_STRUCTURE,
  WOP_GROUPING_GROUPINGSCHEDULE
} from '../../../../constants/contentTypes'
import {
  ACTIVITY_LOAD,
  ACTIVITY_LOAD_PERIOD,
  CODRIVER,
  DESCRIPTION,
  DRIVER,
  OPERATING_PROFILE,
  SPACE_DRIVER,
  ACTIVITY_LOAD_LENGTH,
  USAGE_TASKS_OCCUPANT_PRESENCE_P_TARGET
} from '../../../../constants/attributes'
import { checkAncestryFor } from '../../../../utils/listUtils'
import { buildRenameModal, buildDeleteModal, openModal } from '../../../../actions/modals'
import { openContentWidget } from '../../../../actions/widgets'
import {
  getActivityScheduleWithEstimateIdRequestDefinitions,
  getActivityScheduleColumnsWithEstimateIdRequestDefinitions,
  getActivityScheduleFunctionalSectorsFunctionsWithItemIdRequest,
  getActivityScheduleFunctionsProcessesWithItemIdRequest,
  getActivityScheduleProcessesActivityGroupsWithItemIdRequest,
  getActivityScheduleActivityGroupsActivitiesWithItemIdRequest,
  patchActivityScheduleFunctionsWithItemIdRequest,
  patchActivityScheduleFunctionalSectorsWithItemIdRequest,
  patchActivityScheduleActivityGroupsWithItemIdRequest,
  patchActivityScheduleProcessesWithItemIdRequest,
  patchActivityScheduleActivitiesWithItemIdRequest,
  basePath,
} from '../../../../utils/generated-api-requests/wop'
import {
  modifyListItem,
  undoModifyListItem,
  copyItem,
  setActivityFilter,
  clearActivityFilters,
  filterWopActivityStructureBySpaces,
  applyListItemModifications,
  deleteListItemRow,
  clearSingleRow
} from '../../../../actions/list'
import { includesGARFnName } from '../../../../utils/GARUtils'
import SpaceProgramOperatingProfileMultiEditingMFEContainer
  from '../../SpaceProgramOperatingProfileMultiEditingMFEContainer/SpaceProgramOperatingProfileMultiEditingMFEContainer'
import SpaceProgramMultiDeleteMFEContainer from '../../SpaceProgramMultiDeleteDialogMFEContainer/SpaceProgramMultiDeleteDialogMFEContainer'
import SpaceProgramMoveModalMFEContainer from '../../SpaceProgramMoveModalMFEContainer/SpaceProgramMoveModalMFEContainer'
import { refreshUserAccessToken } from '../../../../actions/user'

const styles = ({ palette }: TVDTheme) => ({
  listContainer: {
    flex: '1',
    overflowX: 'hidden',
    overflowY: 'auto'
  },
  descriptionRowContainer: {
    alignItems: 'center',
    color: palette.nevada,
    cursor: 'default',
    display: 'flex',
    justifyContent: 'space-between',
    marginRight: 2,
    outline: 'none',
    width: '100%',
  },
  layout: {
    margin: '24px 32px 40px',
    top: '125px'
  },
  checkBoxField: {
    color: palette.nevada,
    marginLeft: '0px'
  },
  checkBox: {
    padding: '0 10px 5px 0'
  },
  checkBoxLabel: {
    fontSize: '14px'
  },
  operatingProfile: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  userModifiedIconContent: {
    display: 'flex',
    flexDirection: 'column',
    '& > *:first-of-type': {
      marginBottom: '10px',
    }
  },
  iconsContainer: {
    display: 'flex',
    alignItems: 'center'
  }
})

type DispatchProps = {|
  dispatchUndoModifyListItem: (row: TVDWOPListItem, listId: string) => void, // to undo a dispatched change in list
  dispatchBuildRenameModal: (row: TVDWOPListItem, listId: string) => void,
  dispatchCopyItem: (row: TVDWOPListItem, listId: string, application: string) => void,
  dispatchBuildDeleteModal: (rowId: string, listId: string, application: string) => void, // open delete list item modal
  dispatchModifyListItem: (string, string | number, string, string | number) => void, // to dispatch a change in the ActivityStructureList
  dispatchOpenModal: (content: Object, id: string) => void, // opens a modal with given content and id1
  dispatchOpenActivityRegistryWidget: (Object) => void, // function to open activity registry widget
  dispatchOpenContentWidget: (content: TVDOpenContentWidgetArguments) => void, // opens a widget with given content
  dispatchSetActivityFilter: (row: TVDWOPListItem) => void, // sets a row as an activityFilter
  dispatchfilterWopActivityStructureBySpaces: () => void, // filters activity structure container by activities corresponding the selection in wopSpaceSchedule
  dispatchClearActivityFilters: () => void, // clear activityFilters
  dispatchApplyListItemModifications: (listItemId: string) => void, // applies modified column data to the actual column data
  dispatchDeleteListItem: (string) => void, // delete list item
  dispatchClearSingleRow: (string, Array<string>) => void, // clear columnData after TimeModel is switched off
|}

type MappedProps = {|
  activeEdit: boolean, // boolean to tell if edit mode is on
  activeCalculation?: boolean, // flag that indicates if calculation is running
  columns: Array<TVDListItemColumn>, // array of ActivityStructure column objects
  listItems: TVDWOPListItems, // list it
  activityStructureId: string, // reduxstore id for current activityStructure
  isEstimateLockedToCurrentUser: boolean, // indicates if estimate is locked to current user
  application: string, // current application
  activeFilterSource: Object, // filter and marker for wopActivitySchedule/wopSpaceSchedule filtering source list
  activityFilter: Object, // filter and marker for wopActivitySchedule/wopSpaceSchedule filtered list
  languageCode: $PropertyType<TVDApplicationStore, 'languageCode'>, // language code that is used in GAR requests to get translated content
  isEstimateFrozen: $PropertyType<TVDApplicationStore, 'isEstimateFrozen'>, // if estimate is frozen
  isRefreshingAccessToken: $PropertyType<TVDApplicationStore, 'isRefreshingAccessToken'> // if the application is in the midst of refreshing the access token
|}

type HOCProps = {|
  classes: Object,
  sentient: TVDSentient, // Object providing helpers via SentientHOC
  t: Function, // i18n translate function
|}

type Props = {
  ...DispatchProps,
  ...MappedProps,
  ...HOCProps
}

type State = {
  displayTimeModelData: boolean,
  isMultiEditOperatingTimesModalOpen: boolean,
  isMultiDeleteDialogOpen: boolean,
  isMoveModalOpen: boolean,
  selectedIds: number[],
  selectedParentIds: number[],
  selectedRow: Object
}

export const OPERATING_PROFILE_USER_SET = 'OPERATING_PROFILE_USER_SET'
const OPERATING_PROFILE_FROM_PROFILE = 'OPERATING_PROFILE_FROM_PROFILE'

export class ActivityStructureContainer extends React.Component<Props, State> {
  static defaultProps = {
    activityFilter: {}
  }

  state = {
    displayTimeModelData: true,
    isMultiEditOperatingTimesModalOpen: false,
    isMultiDeleteDialogOpen: false,
    isMoveModalOpen: false,
    selectedIds: [],
    selectedParentIds: [],
    selectedRow: null
  }

  componentDidUpdate(prevProps: Props) {
    const {
      activityFilter, dispatchfilterWopActivityStructureBySpaces, languageCode, sentient
    } = this.props
    if (prevProps.languageCode !== languageCode) {
      sentient.runSetup()
    }

    if (this.listHasActivityFilter() && (prevProps.activityFilter.id !== activityFilter.id)) {
      dispatchfilterWopActivityStructureBySpaces()
    }
  }

  setIsMultiEditOperatingTimesModalOpen = (isMultiEditOperatingTimesModalOpen: boolean): void => {
    this.setState({
      isMultiEditOperatingTimesModalOpen
    })
  }

  setIsMultiDeleteDialogOpen = (isMultiDeleteDialogOpen: boolean): void => {
    this.setState({
      isMultiDeleteDialogOpen
    })
  }

  setIsMoveModalOpen = (isMoveModalOpen: boolean): void => {
    this.setState({
      isMoveModalOpen
    })
  }

  setSelectedIds = (selectedIds: number[]): void => {
    this.setState({
      selectedIds
    })
  }

  setSelectedRow = (selectedRow: Object): void => {
    this.setState({
      selectedRow
    })
  }

  setSelectedParentIds = (selectedParentIds: number[]): void => {
    this.setState({
      selectedParentIds
    })
  }

  listHasActivityFilter = (): boolean => !isEmpty(this.props.activityFilter)

  switchShouldNotRender = (row: TVDWOPListItem, columnName: string) => {
    const rowType = row.type.toUpperCase()

    if (typeof row.columnData[columnName] !== 'boolean') return true
    if ([FUNCTIONALSECTOR, FUNCTION].includes(rowType)) return (columnName === 'TimeModel' || columnName === 'Mergeable')
    if (rowType === PROCESS) return (columnName === 'Mergeable')
    return false
  }

  rowActivityIsFalse = (row: TVDWOPListItem): boolean => !row.columnData.Activity

  isSwitchDisabled = (row: TVDWOPListItem, columnName: string) => {
    const {
      isEstimateLockedToCurrentUser,
      listItems,
      activeEdit,
      activeCalculation,
      isRefreshingAccessToken
    } = this.props
    if (!isEstimateLockedToCurrentUser || activeEdit || activeCalculation || isRefreshingAccessToken) return true

    const ancestorActivityIsDisabled = checkAncestryFor(listItems, row, this.rowActivityIsFalse)
    const currentRowActivityIsDisabled = !row.columnData.Activity

    if (ancestorActivityIsDisabled) return true
    if (columnName !== 'Activity' && currentRowActivityIsDisabled) return true

    return false
  }

  getSwitch = (row: TVDWOPListItem, columnName: string): React$Element<Switch> | null => {
    if (this.switchShouldNotRender(row, columnName)) return null
    const { dispatchModifyListItem, dispatchClearSingleRow } = this.props

    return (
      <Switch
        onClick={(e: MouseEvent): void => { e.stopPropagation() }}
        onChange={(value: number) => {
          dispatchModifyListItem(ACTIVITY_STRUCTURE, row.id, columnName, value)
          if (!value) {
            dispatchClearSingleRow(
              row.id,
              [ACTIVITY_LOAD, ACTIVITY_LOAD_PERIOD, ACTIVITY_LOAD_LENGTH, USAGE_TASKS_OCCUPANT_PRESENCE_P_TARGET, OPERATING_PROFILE]
            )
          }
        }}
        checked={Boolean(row.columnData[columnName])}
        disabled={this.isSwitchDisabled(row, columnName)}
        testId={`${columnName}-Switch`} />
    )
  }

  // we allow editing based on this documentation: https://tools.haahtela.fi/confluence/pages/viewpage.action?spaceKey=TOTP&title=Toimintaluettelon+tiedot
  shouldAllowUnitEditing = (propertyName: string, listItemType: string): boolean => {
    switch (true) {
      case propertyName === DRIVER:
      case propertyName === CODRIVER: return listItemType !== ACTIVITY.toLowerCase()
      case propertyName === SPACE_DRIVER: return listItemType.toLowerCase() === ACTIVITYGROUP.toLowerCase()
      case propertyName === ACTIVITY_LOAD: return listItemType === PROCESS.toLowerCase()
      // Fixed options d/w/a
      case propertyName === ACTIVITY_LOAD_PERIOD: return listItemType === PROCESS.toLowerCase()
      default: return false
    }
  }

  getUnitTimeOptions = (): Array<MenuItemProps> => [
    {
      localizedName: DATE_D,
      value: DATE_D
    },
    {
      localizedName: DATE_W,
      value: DATE_W
    },
    {
      localizedName: DATE_A,
      value: DATE_A
    }
  ]

  onUnitClick = (listItem: TVDWOPListItem, column: TVDListItemColumn): null | Function => {
    const { dispatchOpenModal } = this.props
    const { localizedName, propertyName } = column
    const {
      columnData: { [DESCRIPTION]: description },
      id,
      columnUnits = {},
      type
    } = listItem
    const { [propertyName]: columnUnit } = columnUnits

    if (!this.shouldAllowUnitEditing(propertyName, type)) return null

    return () => {
      let modalArguments = {
        type: UNIT_EDITING_MODAL,
        title: 'widgets._UNIT_TITLE_',
        titleInterpolation: {
          unitName: localizedName,
          rowDescription: description
        },
        contentProps: {
          initialValue: columnUnit,
          modifyListItemProps: {
            listId: ACTIVITY_STRUCTURE,
            listItemId: id,
            columnName: propertyName
          },
        }
      }
      if (propertyName === ACTIVITY_LOAD_PERIOD) {
        modalArguments = {
          ...modalArguments,
          contentProps: {
            ...modalArguments.contentProps,
            unitOptions: this.getUnitTimeOptions()
          }
        }
      }
      dispatchOpenModal(modalArguments, UNIT_EDITING_MODAL)
    }
  }

  getList = (): React$Element<any> => {
    const {
      activeEdit,
      activeCalculation,
      isEstimateFrozen,
      isEstimateLockedToCurrentUser,
      classes,
      columns,
      sentient,
      isRefreshingAccessToken
    } = this.props

    // API currently returns Description column as readOnly: false, the latter condition in filtering can be removed after API has been fixed
    const editableColumns = filter(columns, (column: TVDListItemColumn) => !column.readOnly && column.propertyName !== 'Description')
    const editableColumnNames = map(editableColumns, (editableColumn: TVDListItemColumn) => editableColumn.propertyName)
    const hiddenColumns = this.state.displayTimeModelData ? map(columns, (column: TVDListItemColumn) => column.propertyName).slice(7) : []

    return (
      <ErrorBoundary>
        {activeCalculation && <Spinner />}
        <div className={classes.listContainer}>
          <HierarchicalListContainer
            hiddenColumns={hiddenColumns}
            initialColumnWidths={{
              [OPERATING_PROFILE]: 180,
              [DRIVER]: 245,
              [CODRIVER]: 320
            }}
            contextMenuItems={(row: TVDWOPListItem) => this.getContextMenuItems(row)}
            listId={ACTIVITY_STRUCTURE}
            disabled={activeEdit || activeCalculation || isRefreshingAccessToken}
            isEstimateLockedToCurrentUser={isEstimateLockedToCurrentUser}
            editableColumns={editableColumnNames}
            testId='activityStructure'
            didMountCallback={() => {
              sentient.runSetup()
            }}
            displayCheckBoxes
            checkboxDisabled={activeEdit || isEstimateFrozen || !isEstimateLockedToCurrentUser}
            onModifiedChange={(modifiedListItem: TVDWOPListItem) => { this.onModify(modifiedListItem) }}
            wrappedCellContents={{
                [DESCRIPTION]: ({
                  row, rowRef, highlightedRow
                }: TVDWOPWrappedCellCallbackParameters) => this.getDescriptionRowContent(row, rowRef, highlightedRow),
                Activity: ({ row, column }: TVDWOPWrappedCellCallbackParameters): React$Element<Switch> | null =>
                  this.getSwitch(row, column.propertyName),
                Mergeable: ({ row, column }: TVDWOPWrappedCellCallbackParameters): React$Element<Switch> | null =>
                  this.getSwitch(row, column.propertyName),
                TimeModel: ({ row, column }: TVDWOPWrappedCellCallbackParameters): React$Element<Switch> | null =>
                  this.getSwitch(row, column.propertyName),
                [OPERATING_PROFILE]: ({ row }: TVDWOPWrappedCellCallbackParameters): React$Element<'div'> | null =>
                  this.getOperatingProfileCell(row),
              }}
            onUnitClick={this.onUnitClick} />
        </div>
      </ErrorBoundary>
    )
  }

  getUserModifiedTooltipContent = (): React$Element<'div'> => {
    const { classes, t } = this.props
    return (
      <div className={classes.userModifiedIconContent}>
        <div>{t('operatingProfile._USER_HAS_MODIFIED_')}</div>
        <div>{t('operatingProfile._REVERT_ORIGINAL_TIP_')}</div>
      </div>
    )
  }

  getOperatingProfileCell = (listItem: TVDWOPListItem): React$Element<'div'> | null => {
    const hasOperatingProfile = this.hasListItemOperatingProfile(listItem)
    if (!hasOperatingProfile) return null
    const {
      t,
      dispatchOpenModal,
      classes,
      listItems
    } = this.props
    const {
      columnData: {
        [OPERATING_PROFILE]: operatingProfile
      },
      columnMeta: {
        [OPERATING_PROFILE]: {
          localizedName,
          userModified,
        } = {}
      } = {}
    } = listItem
    const modifyActivityTimeProfileMenuItem = this.getModifyActivityTimeProfileMenuItem(listItem)
    const userModifiedLovalizedNameSelected = t('operatingProfile._USER_SET_')
    const items = [
      {
        localizedNameSelected: localizedName,
        localizedName: t('operatingProfile._SET_OPERATING_PROFILE_'),
        value: OPERATING_PROFILE_FROM_PROFILE,
        onClickCb: () => {
          const parentWOPListItem = listItem.parentId ? listItems[listItem.parentId] : null
          const onSaveSuccessfulRequestDefinitions = this.getGetGARDefinitions(parentWOPListItem, listItem)
          dispatchOpenModal({
            type: OPERATING_PROFILE_SELECT_MODAL,
            title: t('operatingProfile._SET_OPERATING_PROFILE_'),
            disablePadding: true,
            contentProps: {
              operatingProfile,
              listItem,
              activityStructureId: ACTIVITY_STRUCTURE,
              onSaveSuccessfulRequestDefinitions
            }
          }, OPERATING_PROFILE_SELECT_MODAL)
        }
      },
      {
        ...modifyActivityTimeProfileMenuItem,
        value: OPERATING_PROFILE_USER_SET,
        localizedNameSelected: userModifiedLovalizedNameSelected,
        localizedName: t('operatingProfile._CHANGE_VALUES_')
      }
    ]
    const tooltipText = userModified ? userModifiedLovalizedNameSelected : localizedName
    return (
      <div className={classes.operatingProfile}>
        <OverflowTooltip tooltipText={tooltipText}>
          <DropdownMenu
            fullWidth={false}
            data-testid='test-activity-dropdown'
            minimalist
            value={userModified ? OPERATING_PROFILE_USER_SET : OPERATING_PROFILE_FROM_PROFILE}
            items={items} />
        </OverflowTooltip>
        <UserModifiedIcon
          isVisible={userModified}
          content={this.getUserModifiedTooltipContent()}
          id={localizedName} />
      </div>
    )
  }

  onParentalListItemModifySuccessful = (modifiedListItem: TVDWOPListItem) => {
    const { dispatchUndoModifyListItem } = this.props
    refreshUserAccessToken(() => {
      this.getBranchFromListItem(
        modifiedListItem,
        () => { dispatchUndoModifyListItem(modifiedListItem, ACTIVITY_STRUCTURE) },
        () => { dispatchUndoModifyListItem(modifiedListItem, ACTIVITY_STRUCTURE) }
      )
    })
  }

  onModify = (modifiedListItem: TVDWOPListItem) => {
    const { dispatchUndoModifyListItem, dispatchApplyListItemModifications } = this.props

    if (modifiedListItem) {
      const { type } = modifiedListItem
      switch (type.toLowerCase()) {
        case FUNCTIONALSECTOR.toLowerCase(): {
          patchActivityScheduleFunctionalSectorsWithItemIdRequest(
            { path: { itemId: modifiedListItem.wopItemId }, body: modifiedListItem.modifiedColumnData, },
            { listId: ACTIVITY_STRUCTURE, modifiedListItem },
            () => { this.onParentalListItemModifySuccessful(modifiedListItem) },
            () => { dispatchUndoModifyListItem(modifiedListItem, ACTIVITY_STRUCTURE) }
          )
          break
        }
        case FUNCTION.toLowerCase(): {
          patchActivityScheduleFunctionsWithItemIdRequest(
            { path: { itemId: modifiedListItem.wopItemId }, body: modifiedListItem.modifiedColumnData, },
            { listId: ACTIVITY_STRUCTURE, modifiedListItem },
            () => { this.onParentalListItemModifySuccessful(modifiedListItem) },
            () => { dispatchUndoModifyListItem(modifiedListItem, ACTIVITY_STRUCTURE) }
          )
          break
        }
        case PROCESS.toLocaleLowerCase(): {
          patchActivityScheduleProcessesWithItemIdRequest(
            { path: { itemId: modifiedListItem.wopItemId }, body: modifiedListItem.modifiedColumnData, },
            { listId: ACTIVITY_STRUCTURE, modifiedListItem },
            () => { this.onParentalListItemModifySuccessful(modifiedListItem) },
            () => { dispatchUndoModifyListItem(modifiedListItem, ACTIVITY_STRUCTURE) }
          )
          break
        }
        case ACTIVITYGROUP.toLowerCase(): {
          patchActivityScheduleActivityGroupsWithItemIdRequest(
            { path: { itemId: modifiedListItem.wopItemId }, body: modifiedListItem.modifiedColumnData, },
            { listId: ACTIVITY_STRUCTURE, modifiedListItem },
            () => { this.onParentalListItemModifySuccessful(modifiedListItem) },
            () => { dispatchUndoModifyListItem(modifiedListItem, ACTIVITY_STRUCTURE) }
          )
          break
        }
        case ACTIVITY.toLowerCase(): {
          patchActivityScheduleActivitiesWithItemIdRequest(
            { path: { itemId: modifiedListItem.wopItemId }, body: modifiedListItem.modifiedColumnData, },
            { listId: ACTIVITY_STRUCTURE, modifiedListItem },
            () => {
              this.onParentalListItemModifySuccessful(modifiedListItem)
              dispatchApplyListItemModifications(modifiedListItem.id)
            },
            () => { dispatchUndoModifyListItem(modifiedListItem, ACTIVITY_STRUCTURE) }
          )
          break
        }
        default: {
          console.error(`No PATCH request found for modified list item with type ${type}`)
          break
        }
      }
    }
  }

  getBranchFromListItem = (
    row: TVDWOPListItem,
    successCb?: ?(listItems: TVDWOPListItems) => void,
    errorCb?: ?(Error) => void,
  ) => {
    const { type, wopItemId: itemId } = row
    const mergeOptions = { keepOldList: true }
    switch (type.toLowerCase()) {
      case FUNCTIONALSECTOR.toLowerCase(): {
        getActivityScheduleFunctionalSectorsFunctionsWithItemIdRequest(
          { query: { listType: 'flat' }, path: { itemId } },
          { listId: ACTIVITY_STRUCTURE, mergeOptions }, successCb, errorCb
        )
        break
      }
      case FUNCTION.toLowerCase(): {
        getActivityScheduleFunctionsProcessesWithItemIdRequest(
          { query: { listType: 'flat' }, path: { itemId } },
          { listId: ACTIVITY_STRUCTURE, mergeOptions }, successCb, errorCb
        )
        break
      }
      case PROCESS.toLowerCase(): {
        getActivityScheduleProcessesActivityGroupsWithItemIdRequest(
          { query: { listType: 'flat' }, path: { itemId } },
          { listId: ACTIVITY_STRUCTURE, mergeOptions }, successCb, errorCb
        )
        break
      }
      case ACTIVITYGROUP.toLowerCase():
      case ACTIVITY.toLowerCase(): {
        getActivityScheduleActivityGroupsActivitiesWithItemIdRequest(
          { query: { listType: 'flat' }, path: { itemId } },
          { listId: ACTIVITY_STRUCTURE, mergeOptions }, successCb, errorCb
        )
        break
      }
      default: {
        console.error(`Could not get branch from list item with type ${type}`)
        break
      }
    }
  }

  getPATCHRequestDefinitions = (listItem: TVDWOPListItem): TVDGARConfig | null => {
    const {
      type,
      wopItemId,
    } = listItem
    const sharedConfigs = {
      basePath,
      requestArgs: {
        path: {
          itemId: wopItemId
        }
      },
    }
    switch (type.toUpperCase()) {
      case FUNCTIONALSECTOR: {
        return {
          fnName: 'patchActivityScheduleFunctionalSectorsWithItemIdRequest',
          ...sharedConfigs
        }
      }
      case FUNCTION: {
        return {
          fnName: 'patchActivityScheduleFunctionsWithItemIdRequest',
          ...sharedConfigs
        }
      }
      case PROCESS: {
        return {
          fnName: 'patchActivityScheduleProcessesWithItemIdRequest',
          ...sharedConfigs
        }
      }
      case ACTIVITYGROUP: {
        return {
          fnName: 'patchActivityScheduleActivityGroupsWithItemIdRequest',
          ...sharedConfigs
        }
      }
      case ACTIVITY: {
        return {
          fnName: 'patchActivityScheduleActivitiesWithItemIdRequest',
          ...sharedConfigs
        }
      }
      default: {
        console.error(`Could not return PATCH definitions with type ${type}`)
        return null
      }
    }
  }

  hasListItemOperatingProfile = (listItem: TVDWOPListItem): boolean => {
    const { columnData: { [OPERATING_PROFILE]: operatingProfile } } = listItem
    return operatingProfile === 0 || !!operatingProfile
  }

  getModifyActivityTimeProfileMenuItem = (listItem: TVDWOPListItem, rowRef?: TVDRef, highlightedRow?: string): MenuItemProps => {
    const { dispatchOpenModal, t } = this.props
    const {
      type,
      columnData: {
        [DESCRIPTION]: description,
        [OPERATING_PROFILE]: operatingProfileId
      },
      columnMeta: {
        [OPERATING_PROFILE]: operatingProfile
      } = {}
    } = listItem
    return {
      value: operatingProfileId,
      localizedName: t('buttons._MODIFY_ACTIVITY_TIME_PROFILE_'),
      onClick: () => {
        const onSaveSuccessfulRequestDefinitions = this.getGetGARDefinitions(null, listItem)
        dispatchOpenModal({
          title: t('operatingProfile._EDITING_OPERATING_TIME_', { type: t(`widgets._${type.toUpperCase()}_`), name: description }),
          type: MODAL_TYPE_PROPERTY_EDITOR,
          contentProps: {
            onSaveRequestDefinitions: this.getPATCHRequestDefinitions(listItem),
            onSaveSuccessfulRequestDefinitions,
            properties: HALParser.getEmbedded(operatingProfile)[ALL_KEYS.PROPERTIES]
          }
        }, MODAL_TYPE_PROPERTY_EDITOR)
        if (!rowRef) return
        if (rowRef.current) { rowRef.current.classList.remove(highlightedRow) }
      },
      testId: 'ContextMenuItem-modify-activity-time-profile'
    }
  }

  getDeleteGARDefinitions = (listItem: TVDWOPListItem): TVDGenericRequestDefinitions | typeof undefined => {
    const { type, wopItemId } = listItem

    let fnName
    switch (type.toUpperCase()) {
      case FUNCTIONALSECTOR: fnName = 'deleteActivityScheduleFunctionalSectorsWithItemIdRequest'; break
      case FUNCTION: fnName = 'deleteActivityScheduleFunctionsWithItemIdRequest'; break
      case PROCESS: fnName = 'deleteActivityScheduleProcessesWithItemIdRequest'; break
      case ACTIVITYGROUP: fnName = 'deleteActivityScheduleActivityGroupsWithItemIdRequest'; break
      case ACTIVITY: fnName = 'deleteActivityScheduleActivitiesWithItemIdRequest'; break
      default: {
        console.error(`Could not get WOP list item delete GAR function name with type ${type}`)
        return undefined
      }
    }

    if (!includesGARFnName(basePath, fnName)) return undefined

    return {
      fnName,
      basePath,
      requestArgs: {
        path: { itemId: wopItemId },
      },
      payload: { listStoreId: ACTIVITY_STRUCTURE, listItem, addListLastUpdated: true }
    }
  }

  getGetGARDefinitions = (parentListItem?: ?TVDWOPListItem, listItem: TVDWOPListItem): TVDGenericRequestDefinitions | typeof undefined => {
    const type = parentListItem ? parentListItem.type : listItem.type
    const id = parentListItem ? parentListItem.id : listItem.id
    const wopItemId = parentListItem ? parentListItem.wopItemId : listItem.wopItemId

    let fnName
    switch (type.toUpperCase()) {
      case FUNCTIONALSECTOR: {
        fnName = 'getActivityScheduleWithEstimateIdRequest'
        if (!includesGARFnName(basePath, fnName)) return undefined
        return {
          fnName,
          basePath,
          requestArgs: { query: { listType: 'flat' } },
          payload: {
            listStoreId: ACTIVITY_STRUCTURE,
            mergeOptions: { addListLastUpdated: true, removeInitialListItemsBranchWithIdOf: id }
          },
          successCb: () => {
            this.props.dispatchDeleteListItem(listItem.id)
          }
        }
      }
      case FUNCTION: fnName = 'getActivityScheduleFunctionsProcessesWithItemIdRequest'; break
      case PROCESS: fnName = 'getActivityScheduleProcessesActivityGroupsWithItemIdRequest'; break
      case ACTIVITYGROUP: fnName = 'getActivityScheduleActivityGroupsActivitiesWithItemIdRequest'; break
      default: {
        console.error(`Could not get WOP list item get GAR function name with type ${type}`)
        return undefined
      }
    }

    if (!includesGARFnName(basePath, fnName)) return undefined

    return {
      fnName,
      basePath,
      requestArgs: {
        path: { itemId: wopItemId },
        query: { listType: 'flat' }
      },
      payload: {
        listStoreId: ACTIVITY_STRUCTURE,
        mergeOptions: { addListLastUpdated: true, removeInitialListItemsBranchWithIdOf: id }
      }
    }
  }

  getContextMenuItems = (row: TVDWOPListItem, rowRef?: TVDRef, highlightedRow?: string): Array<MenuItemProps> => {
    const {
      t,
      dispatchBuildRenameModal,
      dispatchCopyItem,
      application,
      dispatchOpenActivityRegistryWidget,
      dispatchOpenModal,
      listItems
    } = this.props
    const hasOperatingProfile = this.hasListItemOperatingProfile(row)

    const getSelectedProcessesAndActivityGroups = () => {
      const selectedProcessesAndActivityGroups = filter(listItems, (listItem: Object) =>
        listItem.selected && (listItem.type.toUpperCase() === PROCESS || listItem.type.toUpperCase() === ACTIVITYGROUP))
        .map((item: Object) => item.wopItemId)
      const hasMultipleSelectedProcessesAndActivityGroups = selectedProcessesAndActivityGroups.length > 1
      return { selectedProcessesAndActivityGroups, hasMultipleSelectedProcessesAndActivityGroups }
    }

    const getSelectedIds = () => {
      const selectedIds = filter(listItems, (listItem: Object) =>
        listItem.selected).map((item: Object) => item.wopItemId)
      const hasMultipleSelectedIds = selectedIds.length > 1 && row.selected
      const selectedParentIds = filter(listItems, (listItem: Object) =>
        listItem.selected && (!listItem.parentId || !listItems[listItem.parentId].selected)).map((item: Object) => item.wopItemId)
      return { selectedIds, hasMultipleSelectedIds, selectedParentIds }
    }

    const getSelectedIdsByType = (type: string) => {
      const selectedIdsByType = filter(listItems, (listItem: Object) =>
        listItem.selected && listItem.type === type).map((item: Object) => item.wopItemId)
      const hasMultipleSelectedIdsByType = selectedIdsByType.length > 1 && row.selected
      return { selectedIdsByType, hasMultipleSelectedIdsByType }
    }

    const { selectedProcessesAndActivityGroups, hasMultipleSelectedProcessesAndActivityGroups } = getSelectedProcessesAndActivityGroups()
    const { selectedIds, hasMultipleSelectedIds, selectedParentIds } = getSelectedIds()
    const { selectedIdsByType, hasMultipleSelectedIdsByType } = getSelectedIdsByType(row.type)
    const items = {
      rename: {
        localizedName: t('buttons._RENAME_'),
        onClick: () => {
          dispatchBuildRenameModal(row, ACTIVITY_STRUCTURE)
          if (!rowRef) return
          if (rowRef.current) { rowRef.current.classList.remove(highlightedRow) }
        },
        testId: 'ContextMenuItem-rename'
      },
      delete: {
        localizedName: t('buttons._DELETE_'),
        onClick: () => {
          const parentWOPListItem = row.parentId ? listItems[row.parentId] : null
          const onSaveSuccessfulRequestDefinitions = this.getGetGARDefinitions(parentWOPListItem, row)
          dispatchOpenModal({
            type: MODAL_TYPE_CONFIRMATION_MODAL_WITH_GARS,
            contentProps: {
              saveButtonText: 'buttons._DELETE_',
              message: `listItemDialog._CONFIRMDELETE_${row.type.toUpperCase()}_`,
              onSaveRequestDefinitions: this.getDeleteGARDefinitions(row),
              onSaveSuccessfulRequestDefinitions
            }
          }, row.id)
          if (!rowRef) return
          if (rowRef.current) { rowRef.current.classList.remove(highlightedRow) }
        },
        testId: 'ContextMenuItem-delete'
      },
      duplicate: {
        localizedName: t('buttons._COPY_'),
        onClick: () => {
          dispatchCopyItem(row, ACTIVITY_STRUCTURE, application)
          if (!rowRef) return
          if (rowRef.current) { rowRef.current.classList.remove(highlightedRow) }
        },
        testId: 'ContextMenuItem-copy'
      },
      addActivityFromRegistry: {
        localizedName: t('buttons._ADD_ACTIVITY_FROM_REGISTRY_'),
        onClick: () => {
          dispatchOpenActivityRegistryWidget({
            widgetId: ACTIVITY_REGISTRY_WIDGET,
            widgetType: ACTIVITY_REGISTRY_WIDGET,
            widgetTitle: row.columnData.Description,
            contentProps: {
              rowId: row.wopItemId,
              rowParentId: row.parentWopItemId,
              rowType: row.type
            },
          })
          if (!rowRef) return
          if (rowRef.current) { rowRef.current.classList.remove(highlightedRow) }
        },
        testId: 'ContextMenuItem-add-activity-from-registry'
      },
      modifyActivityTimeProfile: this.getModifyActivityTimeProfileMenuItem(row, rowRef, highlightedRow),
      multiEditOperatingTimes: {
        localizedName: t('buttons._MODIFY_OPERATING_TIMES_'),
        onClick: () => {
          this.setSelectedIds(selectedProcessesAndActivityGroups)
          this.setIsMultiEditOperatingTimesModalOpen(true)
        },
        testId: 'ContextMenuItem-multiedit-operating-times'
      },
      multiDelete: {
        localizedName: t('buttons._MULTI_DELETE_'),
        onClick: () => {
          this.setSelectedIds(selectedIds)
          this.setSelectedParentIds(selectedParentIds)
          this.setIsMultiDeleteDialogOpen(true)
        },
        testId: 'ContextMenuItem-multi-delete-dialog'
      },
      move: {
        localizedName: hasMultipleSelectedIdsByType ? t(`buttons._MOVE_${row.type.toUpperCase()}_MULTIPLE_`) : t('buttons._MOVE_'),
        onClick: () => {
          this.setSelectedIds(selectedIdsByType)
          this.setIsMoveModalOpen(true)
          this.setSelectedRow(row)
        },
        testId: 'ContextMenuItem-move-modal'
      }
    }
    switch (row.type.toUpperCase()) {
      case FUNCTIONALSECTOR: return [
        items.rename,
        items.duplicate,
        hasMultipleSelectedIds ? items.multiDelete : items.delete
      ]
      case FUNCTION: return [
        items.rename,
        items.duplicate,
        hasMultipleSelectedIds ? items.multiDelete : items.delete,
        items.move
      ]
      case PROCESS: return [
        items.rename,
        items.duplicate,
        ...(hasOperatingProfile && !hasMultipleSelectedProcessesAndActivityGroups ? [items.modifyActivityTimeProfile] : []),
        ...(hasMultipleSelectedProcessesAndActivityGroups ? [items.multiEditOperatingTimes] : []),
        hasMultipleSelectedIds ? items.multiDelete : items.delete,
        items.move
      ]
      case ACTIVITYGROUP: return [
        items.rename,
        items.addActivityFromRegistry,
        items.duplicate,
        ...(hasOperatingProfile && !hasMultipleSelectedProcessesAndActivityGroups ? [items.modifyActivityTimeProfile] : []),
        ...(hasMultipleSelectedProcessesAndActivityGroups ? [items.multiEditOperatingTimes] : []),
        hasMultipleSelectedIds ? items.multiDelete : items.delete,
        items.move
      ]
      case ACTIVITY: return [
        items.addActivityFromRegistry,
        items.duplicate,
        hasMultipleSelectedIds ? items.multiDelete : items.delete,
        items.move
      ]
      default: {
        console.error(`getContextMenuItems: unable to handle row.type "${row.type}"`)
        return []
      }
    }
  }

  isActiveSource = (row: TVDWOPListItem): boolean => Boolean(this.props.activeFilterSource.id === row.id)

  getActivityLinkIcon = (row: TVDWOPListItem): ActivityLinkIcon | null => {
    const { dispatchClearActivityFilters } = this.props
    const rowTypes = [FUNCTIONALSECTOR, FUNCTION, PROCESS, ACTIVITYGROUP]
    const rowHasActivityLink = rowTypes.includes(row.type.toUpperCase()) && row.columnData.Activity

    if (!rowHasActivityLink || this.listHasActivityFilter()) return null
    const onClick = !this.isActiveSource(row) ? () => this.setActivityFilter(row) : () => dispatchClearActivityFilters()

    return <ActivityLinkIcon onClick={onClick} selected={this.isActiveSource(row)} />
  }

  getIconsContainer = (row: TVDWOPListItem, rowRef: TVDRef, highlightedRow: string) => {
    const { classes, isEstimateLockedToCurrentUser } = this.props
    const showContextMenu = isEstimateLockedToCurrentUser

    return (
      <div className={classes.iconsContainer}>
        {
          this.isActiveSource(row) ? this.getActivityLinkIcon(row) : <div data-visible_on_hover>{this.getActivityLinkIcon(row)}</div>
        }
        {
          showContextMenu &&
          <div data-visible_on_hover>
            <HamburgerMenu
              onToggleChange={() => rowRef.current && rowRef.current.classList.add(highlightedRow)}
              onToggleClose={() => rowRef.current && rowRef.current.classList.remove(highlightedRow)}
              id='activityStructureContainer'
              items={this.getContextMenuItems(row, rowRef, highlightedRow)} />
          </div>
        }
      </div>
    )
  }

  setActivityFilter = (row: TVDWOPListItem) => {
    const { dispatchSetActivityFilter, dispatchOpenContentWidget } = this.props

    batch(() => {
      dispatchSetActivityFilter(row)
      dispatchOpenContentWidget({
        widgetId: WOP_SPACE_SCHEDULE,
        widgetType: WOP_SPACE_SCHEDULE,
        contentProps: {
          listStoreId: WOP_SPACE_SCHEDULE,
          activityStructureId: ACTIVITY_STRUCTURE,
          watchListStoreIds: [WOP_GROUPING_GROUPINGSCHEDULE, ACTIVITY_STRUCTURE]
        }
      })
    })
  }

  toggleHidden = () => {
    this.setState({
      displayTimeModelData: !this.state.displayTimeModelData
    })
  }

  toggleButton = (): React$Element<any> => {
    const { t, classes } = this.props
    return (
      <FormControlLabel
        className={classes.checkBoxField}
        value='start'
        control={<div className={classes.checkBox}><CheckBox onChange={this.toggleHidden} /></div>}
        label={<div className={classes.checkBoxLabel}>{t('operatingProfile._SHOW_TIME_MODEL_DATA_')}</div>}
        labelPlacement='end' />
    )
  }

  getDescriptionRowContent(row: TVDWOPListItem, rowRef: TVDRef, highlightedRow: string): React$Element<any> {
    const { classes } = this.props

    return (
      <div data-testid='listRowCell-Description-text' className={classes.descriptionRowContainer}>
        <DescriptionCell text={row.columnData.Description} />
        {this.getIconsContainer(row, rowRef, highlightedRow)}
      </div>
    )
  }


  render(): React$Element<any> {
    const { classes } = this.props

    return (
      <>
        <div className={classes.layout}>
          <SpaceProgramOperatingProfileMultiEditingMFEContainer
            isModalOpen={this.state.isMultiEditOperatingTimesModalOpen}
            setIsModalOpen={(isOpen: boolean): void => {
              this.setIsMultiEditOperatingTimesModalOpen(isOpen)
            }}
            selectedIds={this.state.selectedIds} />
          <SpaceProgramMultiDeleteMFEContainer
            isModalOpen={this.state.isMultiDeleteDialogOpen}
            setIsModalOpen={(isOpen: boolean): void => {
              this.setIsMultiDeleteDialogOpen(isOpen)
            }}
            selectedIds={this.state.selectedIds}
            selectedParentIds={this.state.selectedParentIds} />
          <SpaceProgramMoveModalMFEContainer
            isModalOpen={this.state.isMoveModalOpen}
            setIsModalOpen={(isOpen: boolean): void => {
              this.setIsMoveModalOpen(isOpen)
            }}
            selectedIds={this.state.selectedIds}
            row={this.state.selectedRow} />
          {this.toggleButton()}
        </div>
        {this.getList()}
      </>
    )
  }
}

type ReduxState = {
  +app: Object,
  +list: Object
}

const mapStateToProps = ({ app, list }: ReduxState): MappedProps => {
  const {
    activityStructureId,
    activeEdit,
    activeCalculation,
    isEstimateLockedToCurrentUser,
    application,
    languageCode,
    isEstimateFrozen,
    isRefreshingAccessToken
  } = app

  const columns = list[ACTIVITY_STRUCTURE]?.columns
  const listItems = list[ACTIVITY_STRUCTURE]?.listItems
  const activeFilterSource = list[ACTIVITY_STRUCTURE]?.activeFilterSource || {}
  const activityFilter = list[ACTIVITY_STRUCTURE]?.activityFilter || {}

  return {
    activityStructureId,
    activeEdit,
    activeCalculation,
    columns,
    isEstimateLockedToCurrentUser,
    application,
    listItems,
    activeFilterSource,
    activityFilter,
    languageCode,
    isEstimateFrozen,
    isRefreshingAccessToken
  }
}


function mapDispatchToProps(dispatch: Function): DispatchProps {
  return {
    dispatchBuildRenameModal: (row: TVDWOPListItem, listId: string) => { dispatch(buildRenameModal({ row, listId })) },
    dispatchUndoModifyListItem: (row: TVDWOPListItem, listId: string) => { dispatch(undoModifyListItem({ listItem: row, listId })) },
    dispatchOpenContentWidget: (content: TVDOpenContentWidgetArguments) => { dispatch(openContentWidget(content)) },
    dispatchSetActivityFilter: (row: TVDWOPListItem) => { dispatch(setActivityFilter(row, ACTIVITY_STRUCTURE, WOP_SPACE_SCHEDULE)) },
    dispatchClearActivityFilters: () => { dispatch(clearActivityFilters()) },
    dispatchfilterWopActivityStructureBySpaces: () => { dispatch(filterWopActivityStructureBySpaces()) },
    dispatchCopyItem: (row: Object, listId: string, application: string) => {
      dispatch(copyItem(row, listId, application, [ACTIVITY_STRUCTURE], true))
    },
    dispatchBuildDeleteModal: (rowId: string, listId: string, application: string) =>
      dispatch(buildDeleteModal(rowId, listId, application, [ACTIVITY_STRUCTURE], true)),
    dispatchModifyListItem: (listId: string, listItemId: string | number, columnName: string, value: string | number) => {
      dispatch(modifyListItem({
        listId, listItemId, columnName, value
      }))
    },
    dispatchOpenModal: (content: TVDOpenContentWidgetArguments, id: string) => { dispatch(openModal(content, id)) },
    dispatchOpenActivityRegistryWidget: (content: TVDOpenContentWidgetArguments) => { dispatch(openContentWidget(content)) },
    dispatchApplyListItemModifications: (listItemId: string) => { dispatch(applyListItemModifications(listItemId, ACTIVITY_STRUCTURE)) },
    dispatchDeleteListItem: (listId: string) => { dispatch(deleteListItemRow(ACTIVITY_STRUCTURE, listId)) },
    dispatchClearSingleRow: (listItemId: string, propertyNames: Array<string>) => {
      dispatch(clearSingleRow(ACTIVITY_STRUCTURE, listItemId, propertyNames))
    }
  }
}


const sentientConfig: TVDSentientConfig = {
  getExcludeFromPostPolling: (): boolean => true,
  getSentientStoreId: (): string => ACTIVITY_STRUCTURE,
  getSetupRequestDefinitions: (): TVDGARConfigs =>
    ({
      getActivityScheduleWithEstimateIdRequestDefinitions: getActivityScheduleWithEstimateIdRequestDefinitions({
        requestArgs: { query: { listType: 'flat' } },
        payload: { listStoreId: ACTIVITY_STRUCTURE, mergeOptions: { addListLastUpdated: true } },
      }),
      getActivityScheduleColumnsWithEstimateIdRequestDefinitions: getActivityScheduleColumnsWithEstimateIdRequestDefinitions({
        payload: { listStoreId: ACTIVITY_STRUCTURE }
      })
    })
}

export default withTranslation('translations')((
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SentientHOC(ActivityStructureContainer, sentientConfig)))
))
