// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { compose } from 'redux'
import { connect } from 'react-redux'

import RenovationMeasureList from '../RenovationMeasureList'

import { setSelectedTab } from '../../../../../actions/widgets'
import {
  getRenovationSiteWithEstimateIdRequest
} from '../../../../../utils/generated-api-requests/spaces_renovation'

type DispatchProps = {|
  dispatchSetSelectedTab: () => void, // set renovation building tab as selected
|}

type MappedProps = {|
  postPollingTS: $PropertyType<TVDApplicationStore, 'postPollingTS'>
|}

type ReceivedProps = {|
  listStoreId: string, // list id in store
  widgetId: string, // widget id in store
  isEstimateLockedToCurrentUser: boolean, // indicates if estimate is locked to current user
  disabled: boolean, // indicates if the view is disabled
  widgetTab: string, // tab name where the component is located in as a child
|}

type Props = {|
  ...ReceivedProps,
  ...DispatchProps,
  ...MappedProps
|}

type State = {|
  includeAll: boolean
|}

export class RenovationSite extends Component<Props, State> {
  state = {
    includeAll: true
  }
  componentDidMount = () => {
    const { dispatchSetSelectedTab } = this.props
    dispatchSetSelectedTab()
  }

  componentDidUpdate = (prevProps: Props) => {
    const { postPollingTS } = this.props
    const { includeAll } = this.state
    if (prevProps.postPollingTS !== postPollingTS) {
      getRenovationSiteWithEstimateIdRequest(
        { query: { includeAll, embedMeasures: true } },
        { listStoreId: this.props.listStoreId, mergeOptions: { initialListItems: {} } }
      )
    }
  }

  onCheckBoxChange = (includeAll: boolean) => {
    this.setState({ includeAll })
    getRenovationSiteWithEstimateIdRequest(
      { query: { includeAll, embedMeasures: true } },
      { listStoreId: this.props.listStoreId, mergeOptions: { initialListItems: {} } }
    )
  }

  didMountCallback = () => {
    const { listStoreId } = this.props
    getRenovationSiteWithEstimateIdRequest(
      { query: { includeAll: true, embedMeasures: true } },
      { listStoreId, mergeOptions: { initialListItems: {} } }
    )
  }

  render(): React$Element<any> {
    const { widgetId, widgetTab } = this.props
    return (
      <RenovationMeasureList
        widgetId={widgetId}
        widgetTab={widgetTab}
        listStoreId={this.props.listStoreId}
        didMountCallback={this.didMountCallback}
        onCheckBoxChange={this.onCheckBoxChange} />
    )
  }
}

const mapDispatchToProps = (dispatch: Function, { widgetId, widgetTab }: ReceivedProps): DispatchProps => ({
  dispatchSetSelectedTab: () => { dispatch(setSelectedTab(widgetTab, widgetId)) },
})
const mapStateToProps = ({ app: { postPollingTS } }: TVDReduxStore): MappedProps => ({
  postPollingTS
})

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation('translations'),
)(RenovationSite)
