// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.
import React, { Component } from 'react'
import { withStyles } from '@material-ui/core'
import { withTranslation } from 'react-i18next'
import { ADD_RENOVATION_PROFILE_MODAL } from '../../../constants/contentTypes'
import FooterButtons from '../../containers/widgets/FooterButtons/FooterButtons'
import { type TextButtonProps } from '../TextButton/TextButton'
import RenovationProfileContainer from '../../containers/widgets/Renovation/tabContents/RenovationProfileContainer/RenovationProfileContainer'


const styles = () => ({
  properties: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'auto',
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '65vh',
  },
  footer: {
    width: '100%',
    display: 'flex',
    padding: '25px',
    alignSelf: 'flex-end'
  },
  buttons: {
    display: 'flex',
    flex: '1',
    justifyContent: 'flex-end'
  }
})

export type PropertiesModalContentProps = {|
  onSave: Function, // action excecuted when modal save button is clicked
  onClose: Function // action executed when modal cancel buttons is clicked
|}

type Props = {|
  ...PropertiesModalContentProps,
  classes: Object, // withStyles classes object
  t: Function, // translate function
|}

type State = {|
  profileId: string // id of the currently selected renovation profile
|}

export class RenovationProfileModal extends Component<Props, State> {
  state = {
    profileId: ''
  }

  getFooterButtonItems = (): TextButtonProps[] => {
    const { t, onClose, onSave } = this.props
    const footerButtonItems: TextButtonProps[] = [
      {
        id: 'Properties-Modal-Cancel',
        onClick: () => onSave(this.state.profileId),
        text: t('addRenovationModal._SAVE_PROFILE_')
      },
      {
        id: 'Properties-Modal-Cancel',
        onClick: onClose,
        text: t('widgets._CANCEL_'),
        variant: 'text'
      }
    ]
    return footerButtonItems
  }

  onProfileChange = (profileId: string) => {
    this.setState({ profileId })
  }

  render(): React$Element<any> {
    const { classes } = this.props
    return (
      <div className={classes.root} >
        <div className={classes.properties}>
          <RenovationProfileContainer onProfileChange={this.onProfileChange} type={ADD_RENOVATION_PROFILE_MODAL} />
        </div>
        <div className={classes.footer}>
          <div className={classes.buttons}>
            <FooterButtons items={this.getFooterButtonItems()} />
          </div>
        </div>
      </div>)
  }
}

export default withTranslation('translations')(withStyles(styles)(RenovationProfileModal))
