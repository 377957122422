// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.
/* eslint max-len: ["error", { "code": 350 }] */
/*
  Haahtela Renovation Program Api 1.7.0
  Renovation Program services for Haahtela Api
  undefined
*/

import requestFn from './../requests/request'
import { API_BASE_URL } from './../../constants/envConstants'
import parserMapper from './../parserMapper'
import { getState } from '../../store'

export const basePath = '/renovation-program-api'

export const DATA_GET_CONDITION_WITH_YEAR_START = 'DATA_GET_CONDITION_WITH_YEAR_START'
export const DATA_GET_CONDITION_WITH_YEAR_SUCCESSFUL = 'DATA_GET_CONDITION_WITH_YEAR_SUCCESSFUL'
export const DATA_GET_CONDITION_WITH_YEAR_FAIL = 'DATA_GET_CONDITION_WITH_YEAR_FAIL'

// Returns the condition of the given year.
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/renovation-program.yaml#/Condition/get_condition__estimateId___year_
export const getConditionWithYearRequest = (
  requestArguments: getConditionWithYearRequestRenovationProgramArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_CONDITION_WITH_YEAR_START',
      successful: 'DATA_GET_CONDITION_WITH_YEAR_SUCCESSFUL',
      fail: 'DATA_GET_CONDITION_WITH_YEAR_FAIL'
    },
    embeddedType: null,
    fnName: 'getConditionWithYearRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/renovation-program-api`,
    method: 'GET',
    url: `/condition/${options?.estimateId || store.app.calculation}/${requestArguments.path['year']}`,
    params: {
      renovationScopeId: requestArguments.query?.['renovationScopeId'],
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'getConditionWithYearRequest', '/renovation-program-api'),
    actions: {
      start: {
        type: DATA_GET_CONDITION_WITH_YEAR_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_CONDITION_WITH_YEAR_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_CONDITION_WITH_YEAR_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getConditionWithYearRequestDefinitions = (definitionsArguments: {| requestArgs: getConditionWithYearRequestRenovationProgramArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getConditionWithYearRequestRenovationProgramArguments, payload?: Object |} => ({
  fnName: 'getConditionWithYearRequest',
  basePath: '/renovation-program-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_PATCH_CONDITION_WITH_YEAR_START = 'DATA_PATCH_CONDITION_WITH_YEAR_START'
export const DATA_PATCH_CONDITION_WITH_YEAR_SUCCESSFUL = 'DATA_PATCH_CONDITION_WITH_YEAR_SUCCESSFUL'
export const DATA_PATCH_CONDITION_WITH_YEAR_FAIL = 'DATA_PATCH_CONDITION_WITH_YEAR_FAIL'

// Makes a manual adjustment to condition
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/renovation-program.yaml#/Condition/patch_condition__estimateId___year_
export const patchConditionWithYearRequest = (
  requestArguments: patchConditionWithYearRequestRenovationProgramArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_PATCH_CONDITION_WITH_YEAR_START',
      successful: 'DATA_PATCH_CONDITION_WITH_YEAR_SUCCESSFUL',
      fail: 'DATA_PATCH_CONDITION_WITH_YEAR_FAIL'
    },
    embeddedType: null,
    fnName: 'patchConditionWithYearRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/renovation-program-api`,
    method: 'PATCH',
    url: `/condition/${options?.estimateId || store.app.calculation}/${requestArguments.path['year']}`,
    params: {
      renovationScopeId: requestArguments.query?.['renovationScopeId'],
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'patchConditionWithYearRequest', '/renovation-program-api'),
    actions: {
      start: {
        type: DATA_PATCH_CONDITION_WITH_YEAR_START, payload, config, meta
      },
      successful: {
        type: DATA_PATCH_CONDITION_WITH_YEAR_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_PATCH_CONDITION_WITH_YEAR_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_PUT_DEBUG_GET_RENOVATION_YEAR_NOTIFICATION_START = 'DATA_PUT_DEBUG_GET_RENOVATION_YEAR_NOTIFICATION_START'
export const DATA_PUT_DEBUG_GET_RENOVATION_YEAR_NOTIFICATION_SUCCESSFUL = 'DATA_PUT_DEBUG_GET_RENOVATION_YEAR_NOTIFICATION_SUCCESSFUL'
export const DATA_PUT_DEBUG_GET_RENOVATION_YEAR_NOTIFICATION_FAIL = 'DATA_PUT_DEBUG_GET_RENOVATION_YEAR_NOTIFICATION_FAIL'

// Creates a fake RenovationYear notification with the given estimateId's subscription scope.
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/renovation-program.yaml#/Debug/put_debug_get_renovation_year_notification
export const putDebugGetRenovationYearNotificationRequest = (
  payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_PUT_DEBUG_GET_RENOVATION_YEAR_NOTIFICATION_START',
      successful: 'DATA_PUT_DEBUG_GET_RENOVATION_YEAR_NOTIFICATION_SUCCESSFUL',
      fail: 'DATA_PUT_DEBUG_GET_RENOVATION_YEAR_NOTIFICATION_FAIL'
    },
    embeddedType: null,
    fnName: 'putDebugGetRenovationYearNotificationRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/renovation-program-api`,
    method: 'PUT',
    url: '/debug/get-renovation-year-notification',
    params: {
      estimateId: options?.estimateId || store.app.calculation,
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'putDebugGetRenovationYearNotificationRequest', '/renovation-program-api'),
    actions: {
      start: {
        type: DATA_PUT_DEBUG_GET_RENOVATION_YEAR_NOTIFICATION_START, payload, config, meta
      },
      successful: {
        type: DATA_PUT_DEBUG_GET_RENOVATION_YEAR_NOTIFICATION_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_PUT_DEBUG_GET_RENOVATION_YEAR_NOTIFICATION_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_PUT_DEBUG_GET_SIMULATION_CONCLUDED_NOTIFICATION_START = 'DATA_PUT_DEBUG_GET_SIMULATION_CONCLUDED_NOTIFICATION_START'
export const DATA_PUT_DEBUG_GET_SIMULATION_CONCLUDED_NOTIFICATION_SUCCESSFUL = 'DATA_PUT_DEBUG_GET_SIMULATION_CONCLUDED_NOTIFICATION_SUCCESSFUL'
export const DATA_PUT_DEBUG_GET_SIMULATION_CONCLUDED_NOTIFICATION_FAIL = 'DATA_PUT_DEBUG_GET_SIMULATION_CONCLUDED_NOTIFICATION_FAIL'

// Creates a fake Simulation Concluded notification with the given estimateId's subscription scope.
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/renovation-program.yaml#/Debug/put_debug_get_simulation_concluded_notification
export const putDebugGetSimulationConcludedNotificationRequest = (
  payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_PUT_DEBUG_GET_SIMULATION_CONCLUDED_NOTIFICATION_START',
      successful: 'DATA_PUT_DEBUG_GET_SIMULATION_CONCLUDED_NOTIFICATION_SUCCESSFUL',
      fail: 'DATA_PUT_DEBUG_GET_SIMULATION_CONCLUDED_NOTIFICATION_FAIL'
    },
    embeddedType: null,
    fnName: 'putDebugGetSimulationConcludedNotificationRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/renovation-program-api`,
    method: 'PUT',
    url: '/debug/get-simulation-concluded-notification',
    params: {
      estimateId: options?.estimateId || store.app.calculation,
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'putDebugGetSimulationConcludedNotificationRequest', '/renovation-program-api'),
    actions: {
      start: {
        type: DATA_PUT_DEBUG_GET_SIMULATION_CONCLUDED_NOTIFICATION_START, payload, config, meta
      },
      successful: {
        type: DATA_PUT_DEBUG_GET_SIMULATION_CONCLUDED_NOTIFICATION_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_PUT_DEBUG_GET_SIMULATION_CONCLUDED_NOTIFICATION_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_PUT_DEBUG_GET_SIMULATION_STARTED_NOTIFICATION_START = 'DATA_PUT_DEBUG_GET_SIMULATION_STARTED_NOTIFICATION_START'
export const DATA_PUT_DEBUG_GET_SIMULATION_STARTED_NOTIFICATION_SUCCESSFUL = 'DATA_PUT_DEBUG_GET_SIMULATION_STARTED_NOTIFICATION_SUCCESSFUL'
export const DATA_PUT_DEBUG_GET_SIMULATION_STARTED_NOTIFICATION_FAIL = 'DATA_PUT_DEBUG_GET_SIMULATION_STARTED_NOTIFICATION_FAIL'

// Creates a fake Simulation Started notification with the given estimateId's subscription scope.
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/renovation-program.yaml#/Debug/put_debug_get_simulation_started_notification
export const putDebugGetSimulationStartedNotificationRequest = (
  payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_PUT_DEBUG_GET_SIMULATION_STARTED_NOTIFICATION_START',
      successful: 'DATA_PUT_DEBUG_GET_SIMULATION_STARTED_NOTIFICATION_SUCCESSFUL',
      fail: 'DATA_PUT_DEBUG_GET_SIMULATION_STARTED_NOTIFICATION_FAIL'
    },
    embeddedType: null,
    fnName: 'putDebugGetSimulationStartedNotificationRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/renovation-program-api`,
    method: 'PUT',
    url: '/debug/get-simulation-started-notification',
    params: {
      estimateId: options?.estimateId || store.app.calculation,
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'putDebugGetSimulationStartedNotificationRequest', '/renovation-program-api'),
    actions: {
      start: {
        type: DATA_PUT_DEBUG_GET_SIMULATION_STARTED_NOTIFICATION_START, payload, config, meta
      },
      successful: {
        type: DATA_PUT_DEBUG_GET_SIMULATION_STARTED_NOTIFICATION_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_PUT_DEBUG_GET_SIMULATION_STARTED_NOTIFICATION_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_GET_CONDITION_CLASSIFICATIONS_START = 'DATA_GET_CONDITION_CLASSIFICATIONS_START'
export const DATA_GET_CONDITION_CLASSIFICATIONS_SUCCESSFUL = 'DATA_GET_CONDITION_CLASSIFICATIONS_SUCCESSFUL'
export const DATA_GET_CONDITION_CLASSIFICATIONS_FAIL = 'DATA_GET_CONDITION_CLASSIFICATIONS_FAIL'

// Returns localized condition classifications.
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/renovation-program.yaml#/Enum/get_condition_classifications
export const getConditionClassificationsRequest = (
  payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_CONDITION_CLASSIFICATIONS_START',
      successful: 'DATA_GET_CONDITION_CLASSIFICATIONS_SUCCESSFUL',
      fail: 'DATA_GET_CONDITION_CLASSIFICATIONS_FAIL'
    },
    embeddedType: null,
    fnName: 'getConditionClassificationsRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/renovation-program-api`,
    method: 'GET',
    url: '/condition-classifications',
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'getConditionClassificationsRequest', '/renovation-program-api'),
    actions: {
      start: {
        type: DATA_GET_CONDITION_CLASSIFICATIONS_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_CONDITION_CLASSIFICATIONS_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_CONDITION_CLASSIFICATIONS_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getConditionClassificationsRequestDefinitions = (definitionsArguments: {| payload?: Object |}): {|
  ...TVDGARConfigWithoutRequestArgs, payload?: Object |} => ({
  fnName: 'getConditionClassificationsRequest',
  basePath: '/renovation-program-api',
  payload: definitionsArguments.payload,
})


export const DATA_GET_ESTIMATE_WITH_ESTIMATEID_START = 'DATA_GET_ESTIMATE_WITH_ESTIMATEID_START'
export const DATA_GET_ESTIMATE_WITH_ESTIMATEID_SUCCESSFUL = 'DATA_GET_ESTIMATE_WITH_ESTIMATEID_SUCCESSFUL'
export const DATA_GET_ESTIMATE_WITH_ESTIMATEID_FAIL = 'DATA_GET_ESTIMATE_WITH_ESTIMATEID_FAIL'

// Returns a renovation program estimate.
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/renovation-program.yaml#/Estimate/get_estimate__estimateId_
export const getEstimateWithEstimateIdRequest = (
  payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_ESTIMATE_WITH_ESTIMATEID_START',
      successful: 'DATA_GET_ESTIMATE_WITH_ESTIMATEID_SUCCESSFUL',
      fail: 'DATA_GET_ESTIMATE_WITH_ESTIMATEID_FAIL'
    },
    embeddedType: null,
    fnName: 'getEstimateWithEstimateIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/renovation-program-api`,
    method: 'GET',
    url: `/estimate/${options?.estimateId || store.app.calculation}`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'getEstimateWithEstimateIdRequest', '/renovation-program-api'),
    actions: {
      start: {
        type: DATA_GET_ESTIMATE_WITH_ESTIMATEID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_ESTIMATE_WITH_ESTIMATEID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_ESTIMATE_WITH_ESTIMATEID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getEstimateWithEstimateIdRequestDefinitions = (definitionsArguments: {| payload?: Object |}): {|
  ...TVDGARConfigWithoutRequestArgs, payload?: Object |} => ({
  fnName: 'getEstimateWithEstimateIdRequest',
  basePath: '/renovation-program-api',
  payload: definitionsArguments.payload,
})


export const DATA_PUT_ESTIMATE_WITH_ESTIMATEID_START = 'DATA_PUT_ESTIMATE_WITH_ESTIMATEID_START'
export const DATA_PUT_ESTIMATE_WITH_ESTIMATEID_SUCCESSFUL = 'DATA_PUT_ESTIMATE_WITH_ESTIMATEID_SUCCESSFUL'
export const DATA_PUT_ESTIMATE_WITH_ESTIMATEID_FAIL = 'DATA_PUT_ESTIMATE_WITH_ESTIMATEID_FAIL'

// Update renovation program estimate.
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/renovation-program.yaml#/Estimate/put_estimate__estimateId_
export const putEstimateWithEstimateIdRequest = (
  payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_PUT_ESTIMATE_WITH_ESTIMATEID_START',
      successful: 'DATA_PUT_ESTIMATE_WITH_ESTIMATEID_SUCCESSFUL',
      fail: 'DATA_PUT_ESTIMATE_WITH_ESTIMATEID_FAIL'
    },
    embeddedType: null,
    fnName: 'putEstimateWithEstimateIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/renovation-program-api`,
    method: 'PUT',
    url: `/estimate/${options?.estimateId || store.app.calculation}`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'putEstimateWithEstimateIdRequest', '/renovation-program-api'),
    actions: {
      start: {
        type: DATA_PUT_ESTIMATE_WITH_ESTIMATEID_START, payload, config, meta
      },
      successful: {
        type: DATA_PUT_ESTIMATE_WITH_ESTIMATEID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_PUT_ESTIMATE_WITH_ESTIMATEID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_DELETE_ESTIMATE_WITH_ESTIMATEID_START = 'DATA_DELETE_ESTIMATE_WITH_ESTIMATEID_START'
export const DATA_DELETE_ESTIMATE_WITH_ESTIMATEID_SUCCESSFUL = 'DATA_DELETE_ESTIMATE_WITH_ESTIMATEID_SUCCESSFUL'
export const DATA_DELETE_ESTIMATE_WITH_ESTIMATEID_FAIL = 'DATA_DELETE_ESTIMATE_WITH_ESTIMATEID_FAIL'

// Delete estimate
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/renovation-program.yaml#/Estimate/delete_estimate__estimateId_
export const deleteEstimateWithEstimateIdRequest = (
  payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_DELETE_ESTIMATE_WITH_ESTIMATEID_START',
      successful: 'DATA_DELETE_ESTIMATE_WITH_ESTIMATEID_SUCCESSFUL',
      fail: 'DATA_DELETE_ESTIMATE_WITH_ESTIMATEID_FAIL'
    },
    embeddedType: null,
    fnName: 'deleteEstimateWithEstimateIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/renovation-program-api`,
    method: 'DELETE',
    url: `/estimate/${options?.estimateId || store.app.calculation}`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'deleteEstimateWithEstimateIdRequest', '/renovation-program-api'),
    actions: {
      start: {
        type: DATA_DELETE_ESTIMATE_WITH_ESTIMATEID_START, payload, config, meta
      },
      successful: {
        type: DATA_DELETE_ESTIMATE_WITH_ESTIMATEID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_DELETE_ESTIMATE_WITH_ESTIMATEID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_GET_ESTIMATE_PROPERTIES_WITH_ESTIMATEID_START = 'DATA_GET_ESTIMATE_PROPERTIES_WITH_ESTIMATEID_START'
export const DATA_GET_ESTIMATE_PROPERTIES_WITH_ESTIMATEID_SUCCESSFUL = 'DATA_GET_ESTIMATE_PROPERTIES_WITH_ESTIMATEID_SUCCESSFUL'
export const DATA_GET_ESTIMATE_PROPERTIES_WITH_ESTIMATEID_FAIL = 'DATA_GET_ESTIMATE_PROPERTIES_WITH_ESTIMATEID_FAIL'

// Returns a renovation program estimate properties.
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/renovation-program.yaml#/Estimate/get_estimate__estimateId__properties
export const getEstimatePropertiesWithEstimateIdRequest = (
  payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_ESTIMATE_PROPERTIES_WITH_ESTIMATEID_START',
      successful: 'DATA_GET_ESTIMATE_PROPERTIES_WITH_ESTIMATEID_SUCCESSFUL',
      fail: 'DATA_GET_ESTIMATE_PROPERTIES_WITH_ESTIMATEID_FAIL'
    },
    embeddedType: null,
    fnName: 'getEstimatePropertiesWithEstimateIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/renovation-program-api`,
    method: 'GET',
    url: `/estimate/${options?.estimateId || store.app.calculation}/properties`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'getEstimatePropertiesWithEstimateIdRequest', '/renovation-program-api'),
    actions: {
      start: {
        type: DATA_GET_ESTIMATE_PROPERTIES_WITH_ESTIMATEID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_ESTIMATE_PROPERTIES_WITH_ESTIMATEID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_ESTIMATE_PROPERTIES_WITH_ESTIMATEID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getEstimatePropertiesWithEstimateIdRequestDefinitions = (definitionsArguments: {| payload?: Object |}): {|
  ...TVDGARConfigWithoutRequestArgs, payload?: Object |} => ({
  fnName: 'getEstimatePropertiesWithEstimateIdRequest',
  basePath: '/renovation-program-api',
  payload: definitionsArguments.payload,
})


export const DATA_PUT_ESTIMATE_PROPERTIES_WITH_ESTIMATEID_START = 'DATA_PUT_ESTIMATE_PROPERTIES_WITH_ESTIMATEID_START'
export const DATA_PUT_ESTIMATE_PROPERTIES_WITH_ESTIMATEID_SUCCESSFUL = 'DATA_PUT_ESTIMATE_PROPERTIES_WITH_ESTIMATEID_SUCCESSFUL'
export const DATA_PUT_ESTIMATE_PROPERTIES_WITH_ESTIMATEID_FAIL = 'DATA_PUT_ESTIMATE_PROPERTIES_WITH_ESTIMATEID_FAIL'

// Update renovation program estimate properties.
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/renovation-program.yaml#/Estimate/put_estimate__estimateId__properties
export const putEstimatePropertiesWithEstimateIdRequest = (
  payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_PUT_ESTIMATE_PROPERTIES_WITH_ESTIMATEID_START',
      successful: 'DATA_PUT_ESTIMATE_PROPERTIES_WITH_ESTIMATEID_SUCCESSFUL',
      fail: 'DATA_PUT_ESTIMATE_PROPERTIES_WITH_ESTIMATEID_FAIL'
    },
    embeddedType: null,
    fnName: 'putEstimatePropertiesWithEstimateIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/renovation-program-api`,
    method: 'PUT',
    url: `/estimate/${options?.estimateId || store.app.calculation}/properties`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'putEstimatePropertiesWithEstimateIdRequest', '/renovation-program-api'),
    actions: {
      start: {
        type: DATA_PUT_ESTIMATE_PROPERTIES_WITH_ESTIMATEID_START, payload, config, meta
      },
      successful: {
        type: DATA_PUT_ESTIMATE_PROPERTIES_WITH_ESTIMATEID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_PUT_ESTIMATE_PROPERTIES_WITH_ESTIMATEID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_PUT_ESTIMATE_SIMULATE_WITH_ESTIMATEID_START = 'DATA_PUT_ESTIMATE_SIMULATE_WITH_ESTIMATEID_START'
export const DATA_PUT_ESTIMATE_SIMULATE_WITH_ESTIMATEID_SUCCESSFUL = 'DATA_PUT_ESTIMATE_SIMULATE_WITH_ESTIMATEID_SUCCESSFUL'
export const DATA_PUT_ESTIMATE_SIMULATE_WITH_ESTIMATEID_FAIL = 'DATA_PUT_ESTIMATE_SIMULATE_WITH_ESTIMATEID_FAIL'

// Enable or disable simulation.
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/renovation-program.yaml#/Estimate/put_estimate__estimateId__simulate
export const putEstimateSimulateWithEstimateIdRequest = (
  requestArguments: putEstimateSimulateWithEstimateIdRequestRenovationProgramArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_PUT_ESTIMATE_SIMULATE_WITH_ESTIMATEID_START',
      successful: 'DATA_PUT_ESTIMATE_SIMULATE_WITH_ESTIMATEID_SUCCESSFUL',
      fail: 'DATA_PUT_ESTIMATE_SIMULATE_WITH_ESTIMATEID_FAIL'
    },
    embeddedType: null,
    fnName: 'putEstimateSimulateWithEstimateIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/renovation-program-api`,
    method: 'PUT',
    url: `/estimate/${options?.estimateId || store.app.calculation}/simulate`,
    params: {
      simulationEnabled: requestArguments.query['simulationEnabled'],
      fromYear: requestArguments.query['fromYear'],
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'putEstimateSimulateWithEstimateIdRequest', '/renovation-program-api'),
    actions: {
      start: {
        type: DATA_PUT_ESTIMATE_SIMULATE_WITH_ESTIMATEID_START, payload, config, meta
      },
      successful: {
        type: DATA_PUT_ESTIMATE_SIMULATE_WITH_ESTIMATEID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_PUT_ESTIMATE_SIMULATE_WITH_ESTIMATEID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_GET_ESTIMATE_RENOVATION_SCOPES_WITH_ESTIMATEID_START = 'DATA_GET_ESTIMATE_RENOVATION_SCOPES_WITH_ESTIMATEID_START'
export const DATA_GET_ESTIMATE_RENOVATION_SCOPES_WITH_ESTIMATEID_SUCCESSFUL = 'DATA_GET_ESTIMATE_RENOVATION_SCOPES_WITH_ESTIMATEID_SUCCESSFUL'
export const DATA_GET_ESTIMATE_RENOVATION_SCOPES_WITH_ESTIMATEID_FAIL = 'DATA_GET_ESTIMATE_RENOVATION_SCOPES_WITH_ESTIMATEID_FAIL'

// Returns a renovation scopes.
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/renovation-program.yaml#/Estimate/get_estimate__estimateId__renovation_scopes
export const getEstimateRenovationScopesWithEstimateIdRequest = (
  payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_ESTIMATE_RENOVATION_SCOPES_WITH_ESTIMATEID_START',
      successful: 'DATA_GET_ESTIMATE_RENOVATION_SCOPES_WITH_ESTIMATEID_SUCCESSFUL',
      fail: 'DATA_GET_ESTIMATE_RENOVATION_SCOPES_WITH_ESTIMATEID_FAIL'
    },
    embeddedType: null,
    fnName: 'getEstimateRenovationScopesWithEstimateIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/renovation-program-api`,
    method: 'GET',
    url: `/estimate/${options?.estimateId || store.app.calculation}/renovation-scopes`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'getEstimateRenovationScopesWithEstimateIdRequest', '/renovation-program-api'),
    actions: {
      start: {
        type: DATA_GET_ESTIMATE_RENOVATION_SCOPES_WITH_ESTIMATEID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_ESTIMATE_RENOVATION_SCOPES_WITH_ESTIMATEID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_ESTIMATE_RENOVATION_SCOPES_WITH_ESTIMATEID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getEstimateRenovationScopesWithEstimateIdRequestDefinitions = (definitionsArguments: {| payload?: Object |}): {|
  ...TVDGARConfigWithoutRequestArgs, payload?: Object |} => ({
  fnName: 'getEstimateRenovationScopesWithEstimateIdRequest',
  basePath: '/renovation-program-api',
  payload: definitionsArguments.payload,
})


export const DATA_GET_ESTIMATE_RENOVATION_CLASSIFICATIONS_WITH_ESTIMATEID_START = 'DATA_GET_ESTIMATE_RENOVATION_CLASSIFICATIONS_WITH_ESTIMATEID_START'
export const DATA_GET_ESTIMATE_RENOVATION_CLASSIFICATIONS_WITH_ESTIMATEID_SUCCESSFUL = 'DATA_GET_ESTIMATE_RENOVATION_CLASSIFICATIONS_WITH_ESTIMATEID_SUCCESSFUL'
export const DATA_GET_ESTIMATE_RENOVATION_CLASSIFICATIONS_WITH_ESTIMATEID_FAIL = 'DATA_GET_ESTIMATE_RENOVATION_CLASSIFICATIONS_WITH_ESTIMATEID_FAIL'

// Returns a renovation classifications.
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/renovation-program.yaml#/Estimate/get_estimate__estimateId__renovation_classifications
export const getEstimateRenovationClassificationsWithEstimateIdRequest = (
  payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_ESTIMATE_RENOVATION_CLASSIFICATIONS_WITH_ESTIMATEID_START',
      successful: 'DATA_GET_ESTIMATE_RENOVATION_CLASSIFICATIONS_WITH_ESTIMATEID_SUCCESSFUL',
      fail: 'DATA_GET_ESTIMATE_RENOVATION_CLASSIFICATIONS_WITH_ESTIMATEID_FAIL'
    },
    embeddedType: null,
    fnName: 'getEstimateRenovationClassificationsWithEstimateIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/renovation-program-api`,
    method: 'GET',
    url: `/estimate/${options?.estimateId || store.app.calculation}/renovation-classifications`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'getEstimateRenovationClassificationsWithEstimateIdRequest', '/renovation-program-api'),
    actions: {
      start: {
        type: DATA_GET_ESTIMATE_RENOVATION_CLASSIFICATIONS_WITH_ESTIMATEID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_ESTIMATE_RENOVATION_CLASSIFICATIONS_WITH_ESTIMATEID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_ESTIMATE_RENOVATION_CLASSIFICATIONS_WITH_ESTIMATEID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getEstimateRenovationClassificationsWithEstimateIdRequestDefinitions = (definitionsArguments: {| payload?: Object |}): {|
  ...TVDGARConfigWithoutRequestArgs, payload?: Object |} => ({
  fnName: 'getEstimateRenovationClassificationsWithEstimateIdRequest',
  basePath: '/renovation-program-api',
  payload: definitionsArguments.payload,
})


export const DATA_GET_ESTIMATE_RENOVATION_PROFILES_WITH_ESTIMATEID_START = 'DATA_GET_ESTIMATE_RENOVATION_PROFILES_WITH_ESTIMATEID_START'
export const DATA_GET_ESTIMATE_RENOVATION_PROFILES_WITH_ESTIMATEID_SUCCESSFUL = 'DATA_GET_ESTIMATE_RENOVATION_PROFILES_WITH_ESTIMATEID_SUCCESSFUL'
export const DATA_GET_ESTIMATE_RENOVATION_PROFILES_WITH_ESTIMATEID_FAIL = 'DATA_GET_ESTIMATE_RENOVATION_PROFILES_WITH_ESTIMATEID_FAIL'

// Returns a list of localized renovation profiles.
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/renovation-program.yaml#/Estimate/get_estimate__estimateId__renovation_profiles
export const getEstimateRenovationProfilesWithEstimateIdRequest = (
  payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_ESTIMATE_RENOVATION_PROFILES_WITH_ESTIMATEID_START',
      successful: 'DATA_GET_ESTIMATE_RENOVATION_PROFILES_WITH_ESTIMATEID_SUCCESSFUL',
      fail: 'DATA_GET_ESTIMATE_RENOVATION_PROFILES_WITH_ESTIMATEID_FAIL'
    },
    embeddedType: null,
    fnName: 'getEstimateRenovationProfilesWithEstimateIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/renovation-program-api`,
    method: 'GET',
    url: `/estimate/${options?.estimateId || store.app.calculation}/renovation-profiles`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'getEstimateRenovationProfilesWithEstimateIdRequest', '/renovation-program-api'),
    actions: {
      start: {
        type: DATA_GET_ESTIMATE_RENOVATION_PROFILES_WITH_ESTIMATEID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_ESTIMATE_RENOVATION_PROFILES_WITH_ESTIMATEID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_ESTIMATE_RENOVATION_PROFILES_WITH_ESTIMATEID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getEstimateRenovationProfilesWithEstimateIdRequestDefinitions = (definitionsArguments: {| payload?: Object |}): {|
  ...TVDGARConfigWithoutRequestArgs, payload?: Object |} => ({
  fnName: 'getEstimateRenovationProfilesWithEstimateIdRequest',
  basePath: '/renovation-program-api',
  payload: definitionsArguments.payload,
})


export const DATA_POST_ESTIMATE_START = 'DATA_POST_ESTIMATE_START'
export const DATA_POST_ESTIMATE_SUCCESSFUL = 'DATA_POST_ESTIMATE_SUCCESSFUL'
export const DATA_POST_ESTIMATE_FAIL = 'DATA_POST_ESTIMATE_FAIL'

// Creates a new renovation program estimate.
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/renovation-program.yaml#/Estimate/post_estimate
export const postEstimateRequest = (
  payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_POST_ESTIMATE_START',
      successful: 'DATA_POST_ESTIMATE_SUCCESSFUL',
      fail: 'DATA_POST_ESTIMATE_FAIL'
    },
    embeddedType: null,
    fnName: 'postEstimateRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/renovation-program-api`,
    method: 'POST',
    url: '/estimate',
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'postEstimateRequest', '/renovation-program-api'),
    actions: {
      start: {
        type: DATA_POST_ESTIMATE_START, payload, config, meta
      },
      successful: {
        type: DATA_POST_ESTIMATE_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_POST_ESTIMATE_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_PUT_ESTIMATE_IMPORT_SPACES_ESTIMATE_WITH_ESTIMATEID_START = 'DATA_PUT_ESTIMATE_IMPORT_SPACES_ESTIMATE_WITH_ESTIMATEID_START'
export const DATA_PUT_ESTIMATE_IMPORT_SPACES_ESTIMATE_WITH_ESTIMATEID_SUCCESSFUL = 'DATA_PUT_ESTIMATE_IMPORT_SPACES_ESTIMATE_WITH_ESTIMATEID_SUCCESSFUL'
export const DATA_PUT_ESTIMATE_IMPORT_SPACES_ESTIMATE_WITH_ESTIMATEID_FAIL = 'DATA_PUT_ESTIMATE_IMPORT_SPACES_ESTIMATE_WITH_ESTIMATEID_FAIL'

// Import reference estimate data
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/renovation-program.yaml#/Estimate/put_estimate__estimateId__import_spaces_estimate
export const putEstimateImportSpacesEstimateWithEstimateIdRequest = (
  payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_PUT_ESTIMATE_IMPORT_SPACES_ESTIMATE_WITH_ESTIMATEID_START',
      successful: 'DATA_PUT_ESTIMATE_IMPORT_SPACES_ESTIMATE_WITH_ESTIMATEID_SUCCESSFUL',
      fail: 'DATA_PUT_ESTIMATE_IMPORT_SPACES_ESTIMATE_WITH_ESTIMATEID_FAIL'
    },
    embeddedType: null,
    fnName: 'putEstimateImportSpacesEstimateWithEstimateIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/renovation-program-api`,
    method: 'PUT',
    url: `/estimate/${options?.estimateId || store.app.calculation}/import/spaces-estimate`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'putEstimateImportSpacesEstimateWithEstimateIdRequest', '/renovation-program-api'),
    actions: {
      start: {
        type: DATA_PUT_ESTIMATE_IMPORT_SPACES_ESTIMATE_WITH_ESTIMATEID_START, payload, config, meta
      },
      successful: {
        type: DATA_PUT_ESTIMATE_IMPORT_SPACES_ESTIMATE_WITH_ESTIMATEID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_PUT_ESTIMATE_IMPORT_SPACES_ESTIMATE_WITH_ESTIMATEID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_GET_ESTIMATE_SUMMARY_WITH_ESTIMATEID_START = 'DATA_GET_ESTIMATE_SUMMARY_WITH_ESTIMATEID_START'
export const DATA_GET_ESTIMATE_SUMMARY_WITH_ESTIMATEID_SUCCESSFUL = 'DATA_GET_ESTIMATE_SUMMARY_WITH_ESTIMATEID_SUCCESSFUL'
export const DATA_GET_ESTIMATE_SUMMARY_WITH_ESTIMATEID_FAIL = 'DATA_GET_ESTIMATE_SUMMARY_WITH_ESTIMATEID_FAIL'

// Gets estimate summary data.
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/renovation-program.yaml#/Estimate/get_estimate__estimateId__summary
export const getEstimateSummaryWithEstimateIdRequest = (
  payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_ESTIMATE_SUMMARY_WITH_ESTIMATEID_START',
      successful: 'DATA_GET_ESTIMATE_SUMMARY_WITH_ESTIMATEID_SUCCESSFUL',
      fail: 'DATA_GET_ESTIMATE_SUMMARY_WITH_ESTIMATEID_FAIL'
    },
    embeddedType: null,
    fnName: 'getEstimateSummaryWithEstimateIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/renovation-program-api`,
    method: 'GET',
    url: `/estimate/${options?.estimateId || store.app.calculation}/summary`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'getEstimateSummaryWithEstimateIdRequest', '/renovation-program-api'),
    actions: {
      start: {
        type: DATA_GET_ESTIMATE_SUMMARY_WITH_ESTIMATEID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_ESTIMATE_SUMMARY_WITH_ESTIMATEID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_ESTIMATE_SUMMARY_WITH_ESTIMATEID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getEstimateSummaryWithEstimateIdRequestDefinitions = (definitionsArguments: {| payload?: Object |}): {|
  ...TVDGARConfigWithoutRequestArgs, payload?: Object |} => ({
  fnName: 'getEstimateSummaryWithEstimateIdRequest',
  basePath: '/renovation-program-api',
  payload: definitionsArguments.payload,
})


export const DATA_GET_ESTIMATE_PERMISSIONS_USERS_SELF_WITH_ESTIMATEID_START = 'DATA_GET_ESTIMATE_PERMISSIONS_USERS_SELF_WITH_ESTIMATEID_START'
export const DATA_GET_ESTIMATE_PERMISSIONS_USERS_SELF_WITH_ESTIMATEID_SUCCESSFUL = 'DATA_GET_ESTIMATE_PERMISSIONS_USERS_SELF_WITH_ESTIMATEID_SUCCESSFUL'
export const DATA_GET_ESTIMATE_PERMISSIONS_USERS_SELF_WITH_ESTIMATEID_FAIL = 'DATA_GET_ESTIMATE_PERMISSIONS_USERS_SELF_WITH_ESTIMATEID_FAIL'

// undefined
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/renovation-program.yaml#/Permission/get_estimate__estimateId__permissions_users_self
export const getEstimatePermissionsUsersSelfWithEstimateIdRequest = (
  requestArguments: getEstimatePermissionsUsersSelfWithEstimateIdRequestRenovationProgramArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_ESTIMATE_PERMISSIONS_USERS_SELF_WITH_ESTIMATEID_START',
      successful: 'DATA_GET_ESTIMATE_PERMISSIONS_USERS_SELF_WITH_ESTIMATEID_SUCCESSFUL',
      fail: 'DATA_GET_ESTIMATE_PERMISSIONS_USERS_SELF_WITH_ESTIMATEID_FAIL'
    },
    embeddedType: null,
    fnName: 'getEstimatePermissionsUsersSelfWithEstimateIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/renovation-program-api`,
    method: 'GET',
    url: `/estimate/${options?.estimateId || store.app.calculation}/permissions/users/self`,
    params: {
      directPermissions: requestArguments.query?.['directPermissions'],
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'getEstimatePermissionsUsersSelfWithEstimateIdRequest', '/renovation-program-api'),
    actions: {
      start: {
        type: DATA_GET_ESTIMATE_PERMISSIONS_USERS_SELF_WITH_ESTIMATEID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_ESTIMATE_PERMISSIONS_USERS_SELF_WITH_ESTIMATEID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_ESTIMATE_PERMISSIONS_USERS_SELF_WITH_ESTIMATEID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getEstimatePermissionsUsersSelfWithEstimateIdRequestDefinitions = (definitionsArguments: {| requestArgs: getEstimatePermissionsUsersSelfWithEstimateIdRequestRenovationProgramArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getEstimatePermissionsUsersSelfWithEstimateIdRequestRenovationProgramArguments, payload?: Object |} => ({
  fnName: 'getEstimatePermissionsUsersSelfWithEstimateIdRequest',
  basePath: '/renovation-program-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_GET_ESTIMATE_PERMISSIONS_WITH_ESTIMATEID_START = 'DATA_GET_ESTIMATE_PERMISSIONS_WITH_ESTIMATEID_START'
export const DATA_GET_ESTIMATE_PERMISSIONS_WITH_ESTIMATEID_SUCCESSFUL = 'DATA_GET_ESTIMATE_PERMISSIONS_WITH_ESTIMATEID_SUCCESSFUL'
export const DATA_GET_ESTIMATE_PERMISSIONS_WITH_ESTIMATEID_FAIL = 'DATA_GET_ESTIMATE_PERMISSIONS_WITH_ESTIMATEID_FAIL'

// undefined
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/renovation-program.yaml#/Permission/get_estimate__estimateId__permissions
export const getEstimatePermissionsWithEstimateIdRequest = (
  requestArguments: getEstimatePermissionsWithEstimateIdRequestRenovationProgramArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_ESTIMATE_PERMISSIONS_WITH_ESTIMATEID_START',
      successful: 'DATA_GET_ESTIMATE_PERMISSIONS_WITH_ESTIMATEID_SUCCESSFUL',
      fail: 'DATA_GET_ESTIMATE_PERMISSIONS_WITH_ESTIMATEID_FAIL'
    },
    embeddedType: null,
    fnName: 'getEstimatePermissionsWithEstimateIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/renovation-program-api`,
    method: 'GET',
    url: `/estimate/${options?.estimateId || store.app.calculation}/permissions`,
    params: {
      directPermissions: requestArguments.query?.['directPermissions'],
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'getEstimatePermissionsWithEstimateIdRequest', '/renovation-program-api'),
    actions: {
      start: {
        type: DATA_GET_ESTIMATE_PERMISSIONS_WITH_ESTIMATEID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_ESTIMATE_PERMISSIONS_WITH_ESTIMATEID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_ESTIMATE_PERMISSIONS_WITH_ESTIMATEID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getEstimatePermissionsWithEstimateIdRequestDefinitions = (definitionsArguments: {| requestArgs: getEstimatePermissionsWithEstimateIdRequestRenovationProgramArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getEstimatePermissionsWithEstimateIdRequestRenovationProgramArguments, payload?: Object |} => ({
  fnName: 'getEstimatePermissionsWithEstimateIdRequest',
  basePath: '/renovation-program-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_POST_ESTIMATE_PERMISSIONS_WITH_ESTIMATEID_START = 'DATA_POST_ESTIMATE_PERMISSIONS_WITH_ESTIMATEID_START'
export const DATA_POST_ESTIMATE_PERMISSIONS_WITH_ESTIMATEID_SUCCESSFUL = 'DATA_POST_ESTIMATE_PERMISSIONS_WITH_ESTIMATEID_SUCCESSFUL'
export const DATA_POST_ESTIMATE_PERMISSIONS_WITH_ESTIMATEID_FAIL = 'DATA_POST_ESTIMATE_PERMISSIONS_WITH_ESTIMATEID_FAIL'

// undefined
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/renovation-program.yaml#/Permission/post_estimate__estimateId__permissions
export const postEstimatePermissionsWithEstimateIdRequest = (
  payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_POST_ESTIMATE_PERMISSIONS_WITH_ESTIMATEID_START',
      successful: 'DATA_POST_ESTIMATE_PERMISSIONS_WITH_ESTIMATEID_SUCCESSFUL',
      fail: 'DATA_POST_ESTIMATE_PERMISSIONS_WITH_ESTIMATEID_FAIL'
    },
    embeddedType: null,
    fnName: 'postEstimatePermissionsWithEstimateIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/renovation-program-api`,
    method: 'POST',
    url: `/estimate/${options?.estimateId || store.app.calculation}/permissions`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'postEstimatePermissionsWithEstimateIdRequest', '/renovation-program-api'),
    actions: {
      start: {
        type: DATA_POST_ESTIMATE_PERMISSIONS_WITH_ESTIMATEID_START, payload, config, meta
      },
      successful: {
        type: DATA_POST_ESTIMATE_PERMISSIONS_WITH_ESTIMATEID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_POST_ESTIMATE_PERMISSIONS_WITH_ESTIMATEID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_DELETE_ESTIMATE_PERMISSIONS_WITH_ESTIMATEID_START = 'DATA_DELETE_ESTIMATE_PERMISSIONS_WITH_ESTIMATEID_START'
export const DATA_DELETE_ESTIMATE_PERMISSIONS_WITH_ESTIMATEID_SUCCESSFUL = 'DATA_DELETE_ESTIMATE_PERMISSIONS_WITH_ESTIMATEID_SUCCESSFUL'
export const DATA_DELETE_ESTIMATE_PERMISSIONS_WITH_ESTIMATEID_FAIL = 'DATA_DELETE_ESTIMATE_PERMISSIONS_WITH_ESTIMATEID_FAIL'

// undefined
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/renovation-program.yaml#/Permission/delete_estimate__estimateId__permissions
export const deleteEstimatePermissionsWithEstimateIdRequest = (
  payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_DELETE_ESTIMATE_PERMISSIONS_WITH_ESTIMATEID_START',
      successful: 'DATA_DELETE_ESTIMATE_PERMISSIONS_WITH_ESTIMATEID_SUCCESSFUL',
      fail: 'DATA_DELETE_ESTIMATE_PERMISSIONS_WITH_ESTIMATEID_FAIL'
    },
    embeddedType: null,
    fnName: 'deleteEstimatePermissionsWithEstimateIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/renovation-program-api`,
    method: 'DELETE',
    url: `/estimate/${options?.estimateId || store.app.calculation}/permissions`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'deleteEstimatePermissionsWithEstimateIdRequest', '/renovation-program-api'),
    actions: {
      start: {
        type: DATA_DELETE_ESTIMATE_PERMISSIONS_WITH_ESTIMATEID_START, payload, config, meta
      },
      successful: {
        type: DATA_DELETE_ESTIMATE_PERMISSIONS_WITH_ESTIMATEID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_DELETE_ESTIMATE_PERMISSIONS_WITH_ESTIMATEID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_PUT_ESTIMATE_PERMISSIONS_WITH_ESTIMATEID_START = 'DATA_PUT_ESTIMATE_PERMISSIONS_WITH_ESTIMATEID_START'
export const DATA_PUT_ESTIMATE_PERMISSIONS_WITH_ESTIMATEID_SUCCESSFUL = 'DATA_PUT_ESTIMATE_PERMISSIONS_WITH_ESTIMATEID_SUCCESSFUL'
export const DATA_PUT_ESTIMATE_PERMISSIONS_WITH_ESTIMATEID_FAIL = 'DATA_PUT_ESTIMATE_PERMISSIONS_WITH_ESTIMATEID_FAIL'

// undefined
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/renovation-program.yaml#/Permission/put_estimate__estimateId__permissions
export const putEstimatePermissionsWithEstimateIdRequest = (
  requestArguments: putEstimatePermissionsWithEstimateIdRequestRenovationProgramArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_PUT_ESTIMATE_PERMISSIONS_WITH_ESTIMATEID_START',
      successful: 'DATA_PUT_ESTIMATE_PERMISSIONS_WITH_ESTIMATEID_SUCCESSFUL',
      fail: 'DATA_PUT_ESTIMATE_PERMISSIONS_WITH_ESTIMATEID_FAIL'
    },
    embeddedType: null,
    fnName: 'putEstimatePermissionsWithEstimateIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/renovation-program-api`,
    method: 'PUT',
    url: `/estimate/${options?.estimateId || store.app.calculation}/permissions`,
    params: {
      resetPermissions: requestArguments.query['resetPermissions'],
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'putEstimatePermissionsWithEstimateIdRequest', '/renovation-program-api'),
    actions: {
      start: {
        type: DATA_PUT_ESTIMATE_PERMISSIONS_WITH_ESTIMATEID_START, payload, config, meta
      },
      successful: {
        type: DATA_PUT_ESTIMATE_PERMISSIONS_WITH_ESTIMATEID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_PUT_ESTIMATE_PERMISSIONS_WITH_ESTIMATEID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_GET_ESTIMATE_PERMISSIONS_USERS_WITH_ESTIMATEID_START = 'DATA_GET_ESTIMATE_PERMISSIONS_USERS_WITH_ESTIMATEID_START'
export const DATA_GET_ESTIMATE_PERMISSIONS_USERS_WITH_ESTIMATEID_SUCCESSFUL = 'DATA_GET_ESTIMATE_PERMISSIONS_USERS_WITH_ESTIMATEID_SUCCESSFUL'
export const DATA_GET_ESTIMATE_PERMISSIONS_USERS_WITH_ESTIMATEID_FAIL = 'DATA_GET_ESTIMATE_PERMISSIONS_USERS_WITH_ESTIMATEID_FAIL'

// undefined
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/renovation-program.yaml#/Permission/get_estimate__estimateId__permissions_users
export const getEstimatePermissionsUsersWithEstimateIdRequest = (
  requestArguments: getEstimatePermissionsUsersWithEstimateIdRequestRenovationProgramArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_ESTIMATE_PERMISSIONS_USERS_WITH_ESTIMATEID_START',
      successful: 'DATA_GET_ESTIMATE_PERMISSIONS_USERS_WITH_ESTIMATEID_SUCCESSFUL',
      fail: 'DATA_GET_ESTIMATE_PERMISSIONS_USERS_WITH_ESTIMATEID_FAIL'
    },
    embeddedType: null,
    fnName: 'getEstimatePermissionsUsersWithEstimateIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/renovation-program-api`,
    method: 'GET',
    url: `/estimate/${options?.estimateId || store.app.calculation}/permissions/users`,
    params: {
      includeUsers: requestArguments.query?.['includeUsers'],
      directPermissions: requestArguments.query?.['directPermissions'],
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'getEstimatePermissionsUsersWithEstimateIdRequest', '/renovation-program-api'),
    actions: {
      start: {
        type: DATA_GET_ESTIMATE_PERMISSIONS_USERS_WITH_ESTIMATEID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_ESTIMATE_PERMISSIONS_USERS_WITH_ESTIMATEID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_ESTIMATE_PERMISSIONS_USERS_WITH_ESTIMATEID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getEstimatePermissionsUsersWithEstimateIdRequestDefinitions = (definitionsArguments: {| requestArgs: getEstimatePermissionsUsersWithEstimateIdRequestRenovationProgramArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getEstimatePermissionsUsersWithEstimateIdRequestRenovationProgramArguments, payload?: Object |} => ({
  fnName: 'getEstimatePermissionsUsersWithEstimateIdRequest',
  basePath: '/renovation-program-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_GET_ESTIMATE_PERMISSIONS_USER_GROUPS_WITH_ESTIMATEID_START = 'DATA_GET_ESTIMATE_PERMISSIONS_USER_GROUPS_WITH_ESTIMATEID_START'
export const DATA_GET_ESTIMATE_PERMISSIONS_USER_GROUPS_WITH_ESTIMATEID_SUCCESSFUL = 'DATA_GET_ESTIMATE_PERMISSIONS_USER_GROUPS_WITH_ESTIMATEID_SUCCESSFUL'
export const DATA_GET_ESTIMATE_PERMISSIONS_USER_GROUPS_WITH_ESTIMATEID_FAIL = 'DATA_GET_ESTIMATE_PERMISSIONS_USER_GROUPS_WITH_ESTIMATEID_FAIL'

// undefined
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/renovation-program.yaml#/Permission/get_estimate__estimateId__permissions_user_groups
export const getEstimatePermissionsUserGroupsWithEstimateIdRequest = (
  requestArguments: getEstimatePermissionsUserGroupsWithEstimateIdRequestRenovationProgramArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_ESTIMATE_PERMISSIONS_USER_GROUPS_WITH_ESTIMATEID_START',
      successful: 'DATA_GET_ESTIMATE_PERMISSIONS_USER_GROUPS_WITH_ESTIMATEID_SUCCESSFUL',
      fail: 'DATA_GET_ESTIMATE_PERMISSIONS_USER_GROUPS_WITH_ESTIMATEID_FAIL'
    },
    embeddedType: null,
    fnName: 'getEstimatePermissionsUserGroupsWithEstimateIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/renovation-program-api`,
    method: 'GET',
    url: `/estimate/${options?.estimateId || store.app.calculation}/permissions/user-groups`,
    params: {
      includeUserGroups: requestArguments.query?.['includeUserGroups'],
      directPermissions: requestArguments.query?.['directPermissions'],
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'getEstimatePermissionsUserGroupsWithEstimateIdRequest', '/renovation-program-api'),
    actions: {
      start: {
        type: DATA_GET_ESTIMATE_PERMISSIONS_USER_GROUPS_WITH_ESTIMATEID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_ESTIMATE_PERMISSIONS_USER_GROUPS_WITH_ESTIMATEID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_ESTIMATE_PERMISSIONS_USER_GROUPS_WITH_ESTIMATEID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getEstimatePermissionsUserGroupsWithEstimateIdRequestDefinitions = (definitionsArguments: {| requestArgs: getEstimatePermissionsUserGroupsWithEstimateIdRequestRenovationProgramArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getEstimatePermissionsUserGroupsWithEstimateIdRequestRenovationProgramArguments, payload?: Object |} => ({
  fnName: 'getEstimatePermissionsUserGroupsWithEstimateIdRequest',
  basePath: '/renovation-program-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_GET_RENOVATION_RENOVATION_YEARS_WITH_ESTIMATEID_START = 'DATA_GET_RENOVATION_RENOVATION_YEARS_WITH_ESTIMATEID_START'
export const DATA_GET_RENOVATION_RENOVATION_YEARS_WITH_ESTIMATEID_SUCCESSFUL = 'DATA_GET_RENOVATION_RENOVATION_YEARS_WITH_ESTIMATEID_SUCCESSFUL'
export const DATA_GET_RENOVATION_RENOVATION_YEARS_WITH_ESTIMATEID_FAIL = 'DATA_GET_RENOVATION_RENOVATION_YEARS_WITH_ESTIMATEID_FAIL'

// Returns renovation years.
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/renovation-program.yaml#/Renovation/get_renovation__estimateId__renovation_years
export const getRenovationRenovationYearsWithEstimateIdRequest = (
  requestArguments: getRenovationRenovationYearsWithEstimateIdRequestRenovationProgramArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_RENOVATION_RENOVATION_YEARS_WITH_ESTIMATEID_START',
      successful: 'DATA_GET_RENOVATION_RENOVATION_YEARS_WITH_ESTIMATEID_SUCCESSFUL',
      fail: 'DATA_GET_RENOVATION_RENOVATION_YEARS_WITH_ESTIMATEID_FAIL'
    },
    embeddedType: null,
    fnName: 'getRenovationRenovationYearsWithEstimateIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/renovation-program-api`,
    method: 'GET',
    url: `/renovation/${options?.estimateId || store.app.calculation}/renovation-years`,
    params: {
      fromYear: requestArguments.query?.['fromYear'],
      toYear: requestArguments.query?.['toYear'],
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'getRenovationRenovationYearsWithEstimateIdRequest', '/renovation-program-api'),
    actions: {
      start: {
        type: DATA_GET_RENOVATION_RENOVATION_YEARS_WITH_ESTIMATEID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_RENOVATION_RENOVATION_YEARS_WITH_ESTIMATEID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_RENOVATION_RENOVATION_YEARS_WITH_ESTIMATEID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getRenovationRenovationYearsWithEstimateIdRequestDefinitions = (definitionsArguments: {| requestArgs: getRenovationRenovationYearsWithEstimateIdRequestRenovationProgramArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getRenovationRenovationYearsWithEstimateIdRequestRenovationProgramArguments, payload?: Object |} => ({
  fnName: 'getRenovationRenovationYearsWithEstimateIdRequest',
  basePath: '/renovation-program-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_GET_RENOVATION_RENOVATION_YEARS_RENOVATION_SETS_WITH_YEAR_START = 'DATA_GET_RENOVATION_RENOVATION_YEARS_RENOVATION_SETS_WITH_YEAR_START'
export const DATA_GET_RENOVATION_RENOVATION_YEARS_RENOVATION_SETS_WITH_YEAR_SUCCESSFUL = 'DATA_GET_RENOVATION_RENOVATION_YEARS_RENOVATION_SETS_WITH_YEAR_SUCCESSFUL'
export const DATA_GET_RENOVATION_RENOVATION_YEARS_RENOVATION_SETS_WITH_YEAR_FAIL = 'DATA_GET_RENOVATION_RENOVATION_YEARS_RENOVATION_SETS_WITH_YEAR_FAIL'

// Returns renovation sets.
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/renovation-program.yaml#/Renovation/get_renovation__estimateId__renovation_years__year__renovation_sets
export const getRenovationRenovationYearsRenovationSetsWithYearRequest = (
  requestArguments: getRenovationRenovationYearsRenovationSetsWithYearRequestRenovationProgramArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_RENOVATION_RENOVATION_YEARS_RENOVATION_SETS_WITH_YEAR_START',
      successful: 'DATA_GET_RENOVATION_RENOVATION_YEARS_RENOVATION_SETS_WITH_YEAR_SUCCESSFUL',
      fail: 'DATA_GET_RENOVATION_RENOVATION_YEARS_RENOVATION_SETS_WITH_YEAR_FAIL'
    },
    embeddedType: null,
    fnName: 'getRenovationRenovationYearsRenovationSetsWithYearRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/renovation-program-api`,
    method: 'GET',
    url: `/renovation/${options?.estimateId || store.app.calculation}/renovation-years/${requestArguments.path['year']}/renovation-sets`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'getRenovationRenovationYearsRenovationSetsWithYearRequest', '/renovation-program-api'),
    actions: {
      start: {
        type: DATA_GET_RENOVATION_RENOVATION_YEARS_RENOVATION_SETS_WITH_YEAR_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_RENOVATION_RENOVATION_YEARS_RENOVATION_SETS_WITH_YEAR_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_RENOVATION_RENOVATION_YEARS_RENOVATION_SETS_WITH_YEAR_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getRenovationRenovationYearsRenovationSetsWithYearRequestDefinitions = (definitionsArguments: {| requestArgs: getRenovationRenovationYearsRenovationSetsWithYearRequestRenovationProgramArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getRenovationRenovationYearsRenovationSetsWithYearRequestRenovationProgramArguments, payload?: Object |} => ({
  fnName: 'getRenovationRenovationYearsRenovationSetsWithYearRequest',
  basePath: '/renovation-program-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_GET_RENOVATION_RENOVATION_YEARS_KEY_FIGURES_WITH_YEAR_START = 'DATA_GET_RENOVATION_RENOVATION_YEARS_KEY_FIGURES_WITH_YEAR_START'
export const DATA_GET_RENOVATION_RENOVATION_YEARS_KEY_FIGURES_WITH_YEAR_SUCCESSFUL = 'DATA_GET_RENOVATION_RENOVATION_YEARS_KEY_FIGURES_WITH_YEAR_SUCCESSFUL'
export const DATA_GET_RENOVATION_RENOVATION_YEARS_KEY_FIGURES_WITH_YEAR_FAIL = 'DATA_GET_RENOVATION_RENOVATION_YEARS_KEY_FIGURES_WITH_YEAR_FAIL'

// Returns a renovation year key figures.
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/renovation-program.yaml#/Renovation/get_renovation__estimateId__renovation_years__year__key_figures
export const getRenovationRenovationYearsKeyFiguresWithYearRequest = (
  requestArguments: getRenovationRenovationYearsKeyFiguresWithYearRequestRenovationProgramArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_RENOVATION_RENOVATION_YEARS_KEY_FIGURES_WITH_YEAR_START',
      successful: 'DATA_GET_RENOVATION_RENOVATION_YEARS_KEY_FIGURES_WITH_YEAR_SUCCESSFUL',
      fail: 'DATA_GET_RENOVATION_RENOVATION_YEARS_KEY_FIGURES_WITH_YEAR_FAIL'
    },
    embeddedType: null,
    fnName: 'getRenovationRenovationYearsKeyFiguresWithYearRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/renovation-program-api`,
    method: 'GET',
    url: `/renovation/${options?.estimateId || store.app.calculation}/renovation-years/${requestArguments.path['year']}/key-figures`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'getRenovationRenovationYearsKeyFiguresWithYearRequest', '/renovation-program-api'),
    actions: {
      start: {
        type: DATA_GET_RENOVATION_RENOVATION_YEARS_KEY_FIGURES_WITH_YEAR_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_RENOVATION_RENOVATION_YEARS_KEY_FIGURES_WITH_YEAR_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_RENOVATION_RENOVATION_YEARS_KEY_FIGURES_WITH_YEAR_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getRenovationRenovationYearsKeyFiguresWithYearRequestDefinitions = (definitionsArguments: {| requestArgs: getRenovationRenovationYearsKeyFiguresWithYearRequestRenovationProgramArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getRenovationRenovationYearsKeyFiguresWithYearRequestRenovationProgramArguments, payload?: Object |} => ({
  fnName: 'getRenovationRenovationYearsKeyFiguresWithYearRequest',
  basePath: '/renovation-program-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_PUT_RENOVATION_RENOVATION_YEARS_WITH_YEAR_START = 'DATA_PUT_RENOVATION_RENOVATION_YEARS_WITH_YEAR_START'
export const DATA_PUT_RENOVATION_RENOVATION_YEARS_WITH_YEAR_SUCCESSFUL = 'DATA_PUT_RENOVATION_RENOVATION_YEARS_WITH_YEAR_SUCCESSFUL'
export const DATA_PUT_RENOVATION_RENOVATION_YEARS_WITH_YEAR_FAIL = 'DATA_PUT_RENOVATION_RENOVATION_YEARS_WITH_YEAR_FAIL'

// Updates renovation year.
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/renovation-program.yaml#/Renovation/put_renovation__estimateId__renovation_years__year_
export const putRenovationRenovationYearsWithYearRequest = (
  requestArguments: putRenovationRenovationYearsWithYearRequestRenovationProgramArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_PUT_RENOVATION_RENOVATION_YEARS_WITH_YEAR_START',
      successful: 'DATA_PUT_RENOVATION_RENOVATION_YEARS_WITH_YEAR_SUCCESSFUL',
      fail: 'DATA_PUT_RENOVATION_RENOVATION_YEARS_WITH_YEAR_FAIL'
    },
    embeddedType: null,
    fnName: 'putRenovationRenovationYearsWithYearRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/renovation-program-api`,
    method: 'PUT',
    url: `/renovation/${options?.estimateId || store.app.calculation}/renovation-years/${requestArguments.path['year']}`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'putRenovationRenovationYearsWithYearRequest', '/renovation-program-api'),
    actions: {
      start: {
        type: DATA_PUT_RENOVATION_RENOVATION_YEARS_WITH_YEAR_START, payload, config, meta
      },
      successful: {
        type: DATA_PUT_RENOVATION_RENOVATION_YEARS_WITH_YEAR_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_PUT_RENOVATION_RENOVATION_YEARS_WITH_YEAR_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_PUT_RENOVATION_RENOVATION_SETS_MOVE_WITH_RENOVATIONSETID_START = 'DATA_PUT_RENOVATION_RENOVATION_SETS_MOVE_WITH_RENOVATIONSETID_START'
export const DATA_PUT_RENOVATION_RENOVATION_SETS_MOVE_WITH_RENOVATIONSETID_SUCCESSFUL = 'DATA_PUT_RENOVATION_RENOVATION_SETS_MOVE_WITH_RENOVATIONSETID_SUCCESSFUL'
export const DATA_PUT_RENOVATION_RENOVATION_SETS_MOVE_WITH_RENOVATIONSETID_FAIL = 'DATA_PUT_RENOVATION_RENOVATION_SETS_MOVE_WITH_RENOVATIONSETID_FAIL'

// Move renovation set to year.
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/renovation-program.yaml#/Renovation/put_renovation__estimateId__renovation_sets__renovationSetId__move
export const putRenovationRenovationSetsMoveWithRenovationSetIdRequest = (
  requestArguments: putRenovationRenovationSetsMoveWithRenovationSetIdRequestRenovationProgramArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_PUT_RENOVATION_RENOVATION_SETS_MOVE_WITH_RENOVATIONSETID_START',
      successful: 'DATA_PUT_RENOVATION_RENOVATION_SETS_MOVE_WITH_RENOVATIONSETID_SUCCESSFUL',
      fail: 'DATA_PUT_RENOVATION_RENOVATION_SETS_MOVE_WITH_RENOVATIONSETID_FAIL'
    },
    embeddedType: null,
    fnName: 'putRenovationRenovationSetsMoveWithRenovationSetIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/renovation-program-api`,
    method: 'PUT',
    url: `/renovation/${options?.estimateId || store.app.calculation}/renovation-sets/${requestArguments.path['renovationSetId']}/move`,
    params: {
      toYear: requestArguments.query['toYear'],
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'putRenovationRenovationSetsMoveWithRenovationSetIdRequest', '/renovation-program-api'),
    actions: {
      start: {
        type: DATA_PUT_RENOVATION_RENOVATION_SETS_MOVE_WITH_RENOVATIONSETID_START, payload, config, meta
      },
      successful: {
        type: DATA_PUT_RENOVATION_RENOVATION_SETS_MOVE_WITH_RENOVATIONSETID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_PUT_RENOVATION_RENOVATION_SETS_MOVE_WITH_RENOVATIONSETID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_POST_RENOVATION_RENOVATION_YEARS_SPACES_ESTIMATE_WITH_YEAR_START = 'DATA_POST_RENOVATION_RENOVATION_YEARS_SPACES_ESTIMATE_WITH_YEAR_START'
export const DATA_POST_RENOVATION_RENOVATION_YEARS_SPACES_ESTIMATE_WITH_YEAR_SUCCESSFUL = 'DATA_POST_RENOVATION_RENOVATION_YEARS_SPACES_ESTIMATE_WITH_YEAR_SUCCESSFUL'
export const DATA_POST_RENOVATION_RENOVATION_YEARS_SPACES_ESTIMATE_WITH_YEAR_FAIL = 'DATA_POST_RENOVATION_RENOVATION_YEARS_SPACES_ESTIMATE_WITH_YEAR_FAIL'

// Create spaces estimate from renovation year aggregating renovation profiles to renovation measures.
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/renovation-program.yaml#/Renovation/post_renovation__estimateId__renovation_years__year__spaces_estimate
export const postRenovationRenovationYearsSpacesEstimateWithYearRequest = (
  requestArguments: postRenovationRenovationYearsSpacesEstimateWithYearRequestRenovationProgramArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_POST_RENOVATION_RENOVATION_YEARS_SPACES_ESTIMATE_WITH_YEAR_START',
      successful: 'DATA_POST_RENOVATION_RENOVATION_YEARS_SPACES_ESTIMATE_WITH_YEAR_SUCCESSFUL',
      fail: 'DATA_POST_RENOVATION_RENOVATION_YEARS_SPACES_ESTIMATE_WITH_YEAR_FAIL'
    },
    embeddedType: null,
    fnName: 'postRenovationRenovationYearsSpacesEstimateWithYearRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/renovation-program-api`,
    method: 'POST',
    url: `/renovation/${options?.estimateId || store.app.calculation}/renovation-years/${requestArguments.path['year']}/spaces-estimate`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'postRenovationRenovationYearsSpacesEstimateWithYearRequest', '/renovation-program-api'),
    actions: {
      start: {
        type: DATA_POST_RENOVATION_RENOVATION_YEARS_SPACES_ESTIMATE_WITH_YEAR_START, payload, config, meta
      },
      successful: {
        type: DATA_POST_RENOVATION_RENOVATION_YEARS_SPACES_ESTIMATE_WITH_YEAR_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_POST_RENOVATION_RENOVATION_YEARS_SPACES_ESTIMATE_WITH_YEAR_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_GET_SWAGGER_START = 'DATA_GET_SWAGGER_START'
export const DATA_GET_SWAGGER_SUCCESSFUL = 'DATA_GET_SWAGGER_SUCCESSFUL'
export const DATA_GET_SWAGGER_FAIL = 'DATA_GET_SWAGGER_FAIL'

// undefined
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/renovation-program.yaml#/SwaggerApi/get_swagger
export const getSwaggerRequest = (
  payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_SWAGGER_START',
      successful: 'DATA_GET_SWAGGER_SUCCESSFUL',
      fail: 'DATA_GET_SWAGGER_FAIL'
    },
    embeddedType: null,
    fnName: 'getSwaggerRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/renovation-program-api`,
    method: 'GET',
    url: '/swagger',
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'getSwaggerRequest', '/renovation-program-api'),
    actions: {
      start: {
        type: DATA_GET_SWAGGER_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_SWAGGER_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_SWAGGER_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getSwaggerRequestDefinitions = (definitionsArguments: {| payload?: Object |}): {|
  ...TVDGARConfigWithoutRequestArgs, payload?: Object |} => ({
  fnName: 'getSwaggerRequest',
  basePath: '/renovation-program-api',
  payload: definitionsArguments.payload,
})


export const DATA_GET_SWAGGER_WITH_NODE_START = 'DATA_GET_SWAGGER_WITH_NODE_START'
export const DATA_GET_SWAGGER_WITH_NODE_SUCCESSFUL = 'DATA_GET_SWAGGER_WITH_NODE_SUCCESSFUL'
export const DATA_GET_SWAGGER_WITH_NODE_FAIL = 'DATA_GET_SWAGGER_WITH_NODE_FAIL'

// undefined
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/renovation-program.yaml#/SwaggerApi/get_swagger__node_
export const getSwaggerWithNodeRequest = (
  requestArguments: getSwaggerWithNodeRequestRenovationProgramArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_SWAGGER_WITH_NODE_START',
      successful: 'DATA_GET_SWAGGER_WITH_NODE_SUCCESSFUL',
      fail: 'DATA_GET_SWAGGER_WITH_NODE_FAIL'
    },
    embeddedType: null,
    fnName: 'getSwaggerWithNodeRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/renovation-program-api`,
    method: 'GET',
    url: `/swagger/${requestArguments.path['node']}`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'getSwaggerWithNodeRequest', '/renovation-program-api'),
    actions: {
      start: {
        type: DATA_GET_SWAGGER_WITH_NODE_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_SWAGGER_WITH_NODE_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_SWAGGER_WITH_NODE_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getSwaggerWithNodeRequestDefinitions = (definitionsArguments: {| requestArgs: getSwaggerWithNodeRequestRenovationProgramArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getSwaggerWithNodeRequestRenovationProgramArguments, payload?: Object |} => ({
  fnName: 'getSwaggerWithNodeRequest',
  basePath: '/renovation-program-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_GET_SWAGGER_WITH_CHILDNODE_START = 'DATA_GET_SWAGGER_WITH_CHILDNODE_START'
export const DATA_GET_SWAGGER_WITH_CHILDNODE_SUCCESSFUL = 'DATA_GET_SWAGGER_WITH_CHILDNODE_SUCCESSFUL'
export const DATA_GET_SWAGGER_WITH_CHILDNODE_FAIL = 'DATA_GET_SWAGGER_WITH_CHILDNODE_FAIL'

// undefined
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/renovation-program.yaml#/SwaggerApi/get_swagger__node___childNode_
export const getSwaggerWithChildNodeRequest = (
  requestArguments: getSwaggerWithChildNodeRequestRenovationProgramArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_SWAGGER_WITH_CHILDNODE_START',
      successful: 'DATA_GET_SWAGGER_WITH_CHILDNODE_SUCCESSFUL',
      fail: 'DATA_GET_SWAGGER_WITH_CHILDNODE_FAIL'
    },
    embeddedType: null,
    fnName: 'getSwaggerWithChildNodeRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/renovation-program-api`,
    method: 'GET',
    url: `/swagger/${requestArguments.path['node']}/${requestArguments.path['childNode']}`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'getSwaggerWithChildNodeRequest', '/renovation-program-api'),
    actions: {
      start: {
        type: DATA_GET_SWAGGER_WITH_CHILDNODE_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_SWAGGER_WITH_CHILDNODE_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_SWAGGER_WITH_CHILDNODE_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getSwaggerWithChildNodeRequestDefinitions = (definitionsArguments: {| requestArgs: getSwaggerWithChildNodeRequestRenovationProgramArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getSwaggerWithChildNodeRequestRenovationProgramArguments, payload?: Object |} => ({
  fnName: 'getSwaggerWithChildNodeRequest',
  basePath: '/renovation-program-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})
