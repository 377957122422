// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.

import * as React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withTranslation } from 'react-i18next'
import qs from 'query-string'
import { withRouter } from 'react-router-dom'
import { openModal } from '../../../actions/modals'
import { setSelectedAccount } from '../../../actions/app'
import { ACCOUNT_SELECTION_MODAL } from '../../../constants/modalConstants'
import { getLocalStorage } from '../../../utils/commonUtils'
import { TVDSelectedAccountId } from '../../../constants/localPersistence'
import { getUsersSelfPrivilegesRequest } from '../../../utils/generated-api-requests/users'
import { getUsersAPIBasePath } from '../../../utils/apiUtils'
import { TVD_TOKEN_USER_TYPE_USER } from '../../../constants/apiConstants'
import { saveUsersGroups } from '../../../actions/user'

type HOCProps = {|
  location: Object, // withRouter location object
  history: Object, // withRouter history object
  t: Function, // i18n translate function
|}

type MappedProps = {|
  selectedAccountId?: string, // currently selected account id
  accounts: Array<TVDEnum>, // list of available accounts for the user
  userType: $PropertyType<TVDApplicationStore, 'userType'>, // user type that determines what authentication was used, e,g normal user, account manager or haahtela admin
|}

type DispatchProps = {|
  dispatchOpenModal: (content: Object, id: string) => void, // open modal by adding required modal content to Store
  dispatchSetSelectedAccount: (selectedAccount: TVDEnum) => void, // sets selected account to Store,
  dispatchSaveUsersGroups: () => void, // runs requests to determine what user groups user belongs to and saves the userGroupTypeIds to store
|}

type Props = {|
  ...MappedProps,
  ...DispatchProps,
  ...HOCProps
|}

const AccountSelectionHOC = (WrappedComponent: React.AbstractComponent<any>): any => class extends React.Component<Props> {
  componentDidMount = () => {
    const {
      t,
      dispatchOpenModal,
      accounts,
      location: {
        search,
        pathname
      },
      dispatchSetSelectedAccount,
      history,
      dispatchSaveUsersGroups,
      userType
    } = this.props
    const accountIdQueryParam = qs.parse(search).accountId || getLocalStorage(TVDSelectedAccountId)
    const selectedAccount = accounts.find((account: TVDEnum): boolean => account.value === accountIdQueryParam)

    if (!selectedAccount && accounts.length > 1) {
      dispatchOpenModal({
        type: ACCOUNT_SELECTION_MODAL,
        title: t('drawer._CHOOSE_ACCOUNT_'),
        disablePadding: true,
        contentProps: {
          accounts,
          hideCloseButton: true,
          hideMoreOptions: true
        }
      }, ACCOUNT_SELECTION_MODAL)
    } else {
      const account = selectedAccount || accounts[0]
      const options = {
        basePath: this.getBasePath(),
        selectedAccountId: account.value
      }
      dispatchSetSelectedAccount(account)
      if (userType === TVD_TOKEN_USER_TYPE_USER) dispatchSaveUsersGroups()
      getUsersSelfPrivilegesRequest({}, null, null, options)
      const newSearch = {
        ...qs.parse(search),
        accountId: account.value
      }
      history.push(`${pathname}?${qs.stringify(newSearch)}`)
    }
  }

  getBasePath = (): string | typeof undefined => {
    const { userType } = this.props
    return getUsersAPIBasePath(userType || TVD_TOKEN_USER_TYPE_USER)
  }

  render = (): React$Element<any> => {
    const { selectedAccountId } = this.props
    const newProps = { ...this.props, selectedAccountId }
    return <WrappedComponent {...newProps} />
  }
}

const mapStateToProps = ({ app: { userType, selectedAccountId }, user: { accounts } }: TVDReduxStore): MappedProps => ({
  selectedAccountId,
  accounts,
  userType
})

const mapDispatchToProps = (dispatch: Function): DispatchProps => ({
  dispatchOpenModal: (content: Object, id: string) => { dispatch(openModal(content, id)) },
  dispatchSetSelectedAccount: (selectedAccount: TVDEnum) => { dispatch(setSelectedAccount(selectedAccount)) },
  dispatchSaveUsersGroups: () => { dispatch(saveUsersGroups()) }
})

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation('translations'),
  AccountSelectionHOC
)
