// @flow
// Copyright © 2010–2022 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.

import React from 'react'
import { makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { TextButton } from 'frontend-components'
import SimpleHierarchicalList from '../../../../components/common/lists/SimpleHierarchicalList/SimpleHierarchicalList'
import ResultBar, { ResultBarHeight } from '../../../../components/containers/ResultBar/ResultBar/ResultBar'
import OverflowTooltip from '../../../../components/common/OverflowTooltip/OverflowTooltip'
import Spinner from '../../../../components/common/Spinner/Spinner'
import theme from '../../../../styles/theme'
import { blockRootHeight, blockWidth, blockMinWidth, paddingWidth } from '../../../../constants/summarySizes'

const useStyles = makeStyles(({ palette }: TVDTheme) => ({
  blockTitle: {
    paddingTop: '24px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    paddingLeft: '30px',
    paddingRight: '24px',
    background: palette.white
  },
  blockTitleLeft: {
    display: 'flex',
  },
  blockTitleRight: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  blockRoot: {
    width: `${blockWidth}px`,
    height: blockRootHeight,
    display: 'flex',
    flexDirection: 'column',
    background: palette.white
  },
  blockTitleText: {
    ...theme.typography.classes.h2,
    color: palette.dark80,
    marginLeft: '30px'
  },
  titleSourceTextWrapper: {
    color: palette.dark80,
    display: 'flex',
    alignItems: 'center',
    ...theme.typography.classes.bodySmall,
    marginLeft: 16
  },
  titleSourceText: {
    ...theme.typography.classes.bodySmallBold,
    maxWidth: '300px',
    color: palette.dark80,
    paddingLeft: 8
  },
  blockBody: {
    height: '100%',
    overflowY: 'auto',
    background: palette.white,
    position: 'relative'
  },
  row: {
    textAlign: 'left',
    background: palette.white
  },
  headerRow: {
    '&>div': {
      justifyContent: 'flex-start'
    }
  },
  blockFooter: {
    width: '100%'
  },
  [`@media (min-width: ${blockMinWidth}px)`]: {
    blockRoot: {
      width: `calc(50% - ${paddingWidth}px)`,
    }
  },
  [`@media (max-width: ${blockMinWidth}px)`]: {
    blockRoot: {
      width: '100%',
    }
  },
  icon: {
    width: 38,
    height: 38,
    filter: 'brightness(0) saturate(100%) invert(44%) sepia(6%) saturate(138%) hue-rotate(182deg) brightness(90%) contrast(91%)'
  },
  textWrapper: {
    display: 'flex',
    alignItems: 'baseline'
  }
}))

type ReceivedProps = {|
  testId?: string, // optional string to work as test id for attribute data-testid
  listItems: TVDSchemaScheduleItem[], // list of schedule items
  results: Array<any>,
  wrappedCellContents: TVDWrappedCells,
  columns: Array<Object>, // column contents in an array for the table
  titleImageSrc: string, // src for the icon shown in the title section
  title: string, // string shown in the title section
  titleButtonText: string, // text shown in the title button
  onTitleButtonClick: () => void, // function to run when clicking the title button
  titleSourceText: string, // text assigned as the source
  isLoading?: boolean, // if the content is loading and we should indicate that to the user
  subtitle?: React$Element<any>, // optional content below the main title
  initialColumnWidths?: { [columnName: string]: string | number }, // assign initial column widths based on the column name
  className?: string, // Custom className for style modification
  resultBarFormatOptions?: TVDResultBarFormatOptions
|}

type Props = {|
  ...ReceivedProps
|}

const SummaryBlock = ({
  testId = '',
  titleSourceText = '',
  listItems = [],
  results = [],
  wrappedCellContents,
  columns,
  titleImageSrc,
  title,
  titleButtonText,
  onTitleButtonClick,
  isLoading = false,
  subtitle = null,
  initialColumnWidths = {},
  className = '',
  resultBarFormatOptions = {}
}: Props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  return (
    <div data-testid={testId} className={classes.blockRoot}>
      <div className={classes.blockTitle}>
        <div className={classes.blockTitleLeft}>
          <img src={titleImageSrc} className={classes.icon} width={30} height='auto' alt='icon' />
          <div className={classes.textWrapper}>
            <div className={classes.blockTitleText}>{title}</div>
            <div className={classes.titleSourceTextWrapper}>
              {t('summaryView._SOURCE_')}:
              <span className={classes.titleSourceText}>
                <OverflowTooltip
                  tooltipText={titleSourceText}>
                  {titleSourceText}
                </OverflowTooltip>
              </span>
            </div>
          </div>
        </div>
        <div className={classes.blockTitleRight}>
          <TextButton
            variant='containedSlim'
            onClick={onTitleButtonClick}
            text={titleButtonText} />
        </div>
      </div>
      {subtitle}
      <>
        <div className={`${classes.blockBody} ${className}`} style={{ height: `calc(100% - 70.5px - ${ResultBarHeight}px)` }}>
          {isLoading && (
          <Spinner />
        )}
          {!isLoading && (
          <SimpleHierarchicalList
            initialColumnWidths={initialColumnWidths}
            disableHoverEffects
            disabled
            listHeaderCellClassNames={{
            Descr: classes.headerRow
          }}
            listRowCellClassNames={{
            Descr: classes.row
          }}
            wrappedCellContents={wrappedCellContents}
            columns={columns}
            data={listItems} />
        )}
        </div>
        <div className={classes.blockFooter}>
          <ResultBar
            results={results}
            resultBarFormatOptions={resultBarFormatOptions} />
        </div>
      </>
    </div>
  )
}


export default SummaryBlock
