// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.

export const SPACE_EQUIPMENT_PRODUCT_ASSEMBLY_MFENAME = 'space-equipment-product-assembly-widget'
export const SPACE_EQUIPMENT_PRODUCT_ASSEMBLY_COMPONENTNAME = 'SpaceEquipmentProductAssemblyMFEContainer'

export const SPACE_SURFACE_PRODUCT_ASSEMBLY_MFENAME = 'space-surface-product-assembly-widget'
export const SPACE_SURFACE_PRODUCT_ASSEMBLY_COMPONENTNAME = 'SpaceSurfaceProductAssemblyMFEContainer'

export const BUILDING_ELEMENTS_PRODUCT_ASSEMBLY_MFENAME = 'building-elements-product-assembly-widget'
export const BUILDING_ELEMENTS_PRODUCT_ASSEMBLY_COMPONENTNAME = 'BuildingElementsProductAssemblyMFEContainer'

export const BUILDING_ELEMENTS_ASSEMBLY_PRICE_LIST_MFENAME = 'building-elements-assembly-price-list-widget'
export const BUILDING_ELEMENTS_ASSEMBLY_PRICE_LIST_COMPONENTNAME = 'BuildingElementsAssemblyPriceListMFEContainer'

export const CUSTOM_BUILDING_ELEMENT_ASSEMBLY_MODAL_MFENAME = 'custom-building-element-assembly-modal'
export const CUSTOM_BUILDING_ELEMENT_ASSEMBLY_MODAL_COMPONENTNAME = 'CustomBuildingElementAssemblyModalMFEContainer'
