// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.
import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress'

const styles = ({ palette }: Object): Object => ({
  spinner: {
    color: palette.primary100,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  },
})

type Props = {|
  classes: Object, // styles object
  size?: number, // size of spinner in pixels
  zIndex?: number, // option overwriting zIndex
|}

const Spinner = (props: Props) => (
  <CircularProgress data-testid='spinner' style={{ zIndex: props.zIndex }} size={props.size} className={props.classes.spinner} />
)

Spinner.defaultProps = { size: 40, zIndex: 1 }
export default withStyles(styles)(Spinner)
