// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.

import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'

import LabeledInput from '../../common/LabeledInput/LabeledInput'
import ModalForm from '../../common/ModalForm/ModalForm'
import { type ValidatorInformation } from '../Validator/Validator'
import { validateName } from '../../../utils/validators'

type Props = {|
  t: Function, // translate function
  description: string, // translateKey of the inputField description text
  defaultValue: string, // initial value for input field
  saveButtonText: ?string, // optional text for save button given as translateKey
  onSave: (value: string, successCb: Function) => void, // onSave callback
  onClose: () => void, // close modal
  confirm?: () => void, // open confirmation modal
|}

type State = {
  inputField: string, // sole input field
  inputFieldValid: boolean
}

export class SingleInputModal extends Component<Props, State> {
  static defaultProps = {
    confirm: undefined
  }
  state = {
    inputField: '',
    inputFieldValid: true,
  }

  componentDidMount() {
    const { t, defaultValue } = this.props
    this.setState({ inputField: t(defaultValue) })
  }

  inputField = (): LabeledInput => {
    const { t, description } = this.props
    return (
      <LabeledInput
        customValidator={({ value }: ValidatorInformation) => validateName(value)}
        size='XXXL'
        dataType='string'
        focused
        id='SingleInputModal-inputField'
        handleChange={this.handleChange}
        label={t(description)}
        isValidCb={(isValid: boolean) => this.setState({ inputFieldValid: isValid })}
        required
        value={this.state.inputField} />
    )
  }

  inputs = (): Array<LabeledInput> => [this.inputField()]

  handleChange = (e: SyntheticInputEvent<any>): void => {
    this.setState({ inputField: e.target.value })
  }

  handleSave = (): void => {
    this.props.onSave(this.state.inputField, this.props.onClose)
  }

  handleClose = (): void => {
    const {
      t,
      defaultValue,
      confirm,
      onClose
    } = this.props

    if (t(defaultValue) !== this.state.inputField && confirm) confirm()
    else onClose()
  }

  render(): React$Element<any> {
    const { saveButtonText } = this.props
    return (
      <ModalForm
        testId='SingleInputModal'
        items={this.inputs()}
        onSave={this.handleSave}
        onClose={this.handleClose}
        valid={this.state.inputFieldValid}
        saveButtonText={saveButtonText} />
    )
  }
}
export default withTranslation('translations')(SingleInputModal)
