// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.

import React, { Component, createRef } from 'react'
import { withStyles, RootRef, Icon } from '@material-ui/core'
import { colors, typographyClasses } from 'frontend-assets'
import ArrowRightIcon from '@material-ui/icons/ArrowRight'
import MUIMenuItem from '@material-ui/core/MenuItem'
import { combineStyleClassNames } from '../../../../../../utils/styleUtils'
import ContextMenu from '../../../ContextMenu/ContextMenu'
import Tooltip from '../../../../Tooltip/Tooltip'

const styles = () => ({
  menuItemWithSubMenuRoot: {
    ...typographyClasses.bodyBig,
    paddingTop: '0',
    paddingBottom: '0',
    minHeight: '40px',
    maxWidth: '100%',
    backgroundColor: colors.white,
    color: colors.dark80,
    '&:hover': {
      backgroundColor: colors.gray20
    },
    '&:active': {
      backgroundColor: colors.primary10,
    },
    '&:focus': {
      backgroundColor: colors.white,
      '&:hover': {
        backgroundColor: colors.gray20
      },
      '&:active': {
        backgroundColor: colors.primary10
      }
    },
    '&.Mui-disabled': {
      color: colors.gray80,
    }
  },
  menuItemWithSubMenuSelected: {
    '&$menuItemWithSubMenuSelected': {
      backgroundColor: colors.primary10,
      '&:hover': {
        backgroundColor: colors.gray20
      },
    }
  },
  contentWrapper: {
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    padding: '0px 16px',
  },
  textSpan: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  icon: {
    fontSize: 20,
    color: colors.manatee,
    marginLeft: '5px',
    verticalAlign: 'text-bottom',
  },
  arrowRightIcon: {
    fontSize: 24,
    color: colors.dark60,
    alignSelf: 'center'
  },
  iconHidden: {
    visibility: 'hidden'
  }
})

type State = {
  isMenuOpen: boolean
}

type Props = {
  ...TVDMenuItem,
  handleClose?: () => void, // method to fire if clicking on this needs to close the menu, internal to the menu structure
  classes: Object, // withStyles styles prop
}

export class MenuItemWithSubMenu extends Component<Props, State> {
  static defaultProps = {
    testId: '',
    disabled: false,
    handleClose: null,
    selected: false,
    tooltip: '',
  }

  menuItemWithSubMenuRef = createRef<HTMLElement>()

  state = {
    isMenuOpen: false
  }

  get content(): React$Element<any> {
    const {
      classes,
      localizedName,
      icon,
      isIconHidden
    } = this.props

    return (
      <div className={classes.contentWrapper}>
        <span className={classes.textSpan}>
          { localizedName }
          { icon && <Icon classes={{ root: combineStyleClassNames(classes.icon, isIconHidden && classes.iconHidden) }}>{icon}</Icon> }
        </span>
      </div>
    )
  }

  get tooltipContent(): React$Element<any> {
    return (
      <Tooltip text={this.props.tooltip}>
        { this.content }
      </Tooltip>
    )
  }

  onMouseEnter = () => {
    if (!this.props.disabled) this.setState({ isMenuOpen: true })
  }

  onMouseLeave = () => this.setState({ isMenuOpen: false })

  render(): React$Element<MUIMenuItem> {
    const {
      classes,
      testId,
      localizedName,
      handleClose,
      tooltip,
      subMenuItems
    } = this.props

    const location = {
      zIndex: 1,
      x: 0,
      y: 0,
    }

    const menuItemWithSubMenuElement = this.menuItemWithSubMenuRef.current
    if (menuItemWithSubMenuElement) {
      location.x = menuItemWithSubMenuElement.offsetWidth
      location.y = menuItemWithSubMenuElement.offsetTop
    }

    return (
      <div onMouseLeave={this.onMouseLeave}>
        <RootRef rootRef={this.menuItemWithSubMenuRef}>
          <MUIMenuItem
            disableRipple
            disableGutters
            disabled={this.props.disabled}
            selected={this.state.isMenuOpen}
            onMouseEnter={this.onMouseEnter}
            classes={{
              root: classes.menuItemWithSubMenuRoot,
              selected: classes.menuItemWithSubMenuSelected
            }}
            data-testid={testId || `MenuItem-${localizedName || ''}`}>
            { tooltip ? this.tooltipContent : this.content } <ArrowRightIcon className={classes.arrowRightIcon} />
          </MUIMenuItem>
        </RootRef>
        {
          this.state.isMenuOpen &&
          <ContextMenu
            isSubMenu
            id='ContextSubMenu'
            items={subMenuItems}
            location={location}
            handleClose={handleClose} />
        }
      </div>
    )
  }
}

export default withStyles(styles)(MenuItemWithSubMenu)
