// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.

import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { ListItem, List, Chip } from '@material-ui/core'
import { MoreVert } from '@material-ui/icons'
import { connect, batch } from 'react-redux'
import { compose } from 'redux'
import { withTranslation } from 'react-i18next'
import { withStyles } from '@material-ui/core/styles'
import { typographyClasses, fonts, colors } from 'frontend-assets'
import {
  setSelectedAccount,
  setBuildingId,
  setBuildingName,
  setCalculation,
  clearPrimaryEstimatesForModules,
  setApplication,
  setRealEstateId,
  setRealEstateName,
  setLicenseType,
  setIsEstimateLockedToCurrentUser
} from '../../../actions/app'
import { closeModal, openModal } from '../../../actions/modals'
import { type TextButtonProps, type TVDButtonVariants } from '../TextButton/TextButton'
import FooterButtons from '../../containers/widgets/FooterButtons/FooterButtons'
import Menu from '../menus/Menu/Menu'
import { type MenuItemProps } from '../menus/components/MenuItems/MenuItem/MenuItem'
import { ORGANIZATION_ACCOUNT, CONFIRMATION_MODAL } from '../../../constants/contentTypes'
import { combineStyleClassNames } from '../../../utils/styleUtils'
import {
  getUsersSelfAccountsRequest,
  basePath,
} from '../../../utils/generated-api-requests/users'
import Spinner from '../Spinner/Spinner'
import { getUserTypeFromPathname, getUsersAPIBasePath, getUserPrivilegeFilter } from '../../../utils/apiUtils'
import { saveUsersGroups } from '../../../actions/user'
import { TVD_TOKEN_USER_TYPE_USER } from '../../../constants/apiConstants'
import { MODAL_TYPE_ACCOUNT_SUSPEND_MODAL } from '../../../constants/modalConstants'
import { TVD_MESSAGE_VARIANT_DANGER } from '../../common/ConfirmationModal/ConfirmationModal'
import HALParser from '../../../utils/HALParser'
import { SUSPENDED } from '../../../constants/accountStatusConstants'
import { createQueryParamsString } from '../../../utils/urlUtil'
import { setMainViewActiveIndex } from '../../../actions/view/actions'
import { resetActiveRealEstate } from '../../../actions/activeRealEstate'
import {
  clearWidgets
} from '../../../actions/widgets'

const styles = () => ({
  listItem: {
    ...typographyClasses.bodyDefault,
    color: colors.dark80,
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'space-between',
    height: '45px',
    borderTop: `1px solid ${colors.gray40}`,
    '&:last-of-type': {
      borderBottom: `1px solid ${colors.gray40}`,
    },
    '&:hover': {
      background: colors.primary10
    }
  },
  moreIcon: {
    color: colors.dark80,
    cursor: 'pointer'
  },
  list: {
    padding: '0px',
    marginTop: '31px',
  },
  listItemGutters: {
    paddingLeft: '32px',
    paddingRight: '35px'
  },
  footerButtons: {
    marginTop: '63px',
    display: 'flex',
    justifyContent: 'flex-end',
    paddingRight: '24px',
    marginBottom: '20px'
  },
  closedChipRoot: {
    cursor: 'inherit',
    height: '19px',
    marginLeft: '10px',
    background: colors.error100,
    color: colors.white,
    fontFamily: fonts.fontFamilyBold
  },
  closedChipLabel: {
    paddingLeft: '5px',
    paddingRight: '5px'
  },
  accountNameWrapper: {
    display: 'flex',
    alignItems: 'center'
  },
  cursorDefault: {
    cursor: 'default'
  }
})

type HOCProps = {|
  t: Function, // translate function
  classes: Object, // withstyles classes object
  history: Object, // withRouter history object
  location: Object, // withRouter location object
|}

type DispatchProps = {|
  dispatchCloseModal: (id: string) => void, // closes the current modal
  dispatchOpenModal: (content: Object, id: string) => void, // opens a new modal
  dispatchSetSelectedAccount: (selectedAccount: TVDEnum) => void, // sets an account id as selected one in Store
  dispatchSaveUsersGroups: () => void, // runs requests to determine what user groups user belongs to and saves the userGroupTypeIds to store
  dispatchSetMainViewIndex: (index: number) => void, // used for navigating into real estate list view
  dispatchClearStore: () => void, // clears data from store so we can reselect a real estate
|}

type MappedProps = {|
  accounts: Array<TVDEnum>, // list of accounts
  selectedAccountId?: $PropertyType<TVDApplicationStore, 'selectedAccountId'>, // account that the user has selected
  userType: $PropertyType<TVDApplicationStore, 'userType'>, // type of the user to recognize if user is accessing normal user, account management or haahtela admin content
|}

type ReceivedProps = {|
  hideCloseButton?: boolean, // hiding the close button to force user select an account
  hideMoreOptions?: boolean, // hiding more options e.g when showing the modal for a non admin user
  modalId: string, // the id of the current modal
  includeStatus?: boolean, // if we include status information with getUsersSelfAccountsRequest
|}

type Props = {|
  ...HOCProps,
  ...DispatchProps,
  ...MappedProps,
  ...ReceivedProps
|}

type State = {|
  isLoading: boolean, // if the widget is still loading e.g accounts list
|}

export class AccountSelectionModal extends Component<Props, State> {
  static defaultProps = {
    selectedAccountId: undefined,
    hideCloseButton: false,
    hideMoreOptions: false
  }

  state = {
    isLoading: true
  }

  componentDidMount = () => {
    const { location: { pathname }, includeStatus } = this.props
    const userType = getUserTypeFromPathname(pathname)
    if (userType) {
      const userAPIBasePath = getUsersAPIBasePath(userType)
      const privilegeFilter = getUserPrivilegeFilter(userType)
      const options = { basePath: userAPIBasePath }
      // getting accounts list so that user has up to date accounts list
      getUsersSelfAccountsRequest(
        { query: { privilegeFilter, includeStatus } },
        {},
        () => {
          this.setState({ isLoading: false })
        },
        () => { this.setState({ isLoading: false }) },
        options
      )
    }
  }

  getDeleteAccountRequestDefinitions = (accountId: string): TVDGARConfigs => ({
    deleteAccountRequestDefinition: {
      basePath,
      fnName: 'deleteAccountsWithIdRequest',
      requestArgs: { path: { id: accountId } },
    }
  })

  getConfirmAccountEditModalContent = (account: TVDEnum): Object => {
    const {
      t,
      dispatchCloseModal,
      dispatchSetSelectedAccount,
      dispatchOpenModal,
      location,
    } = this.props

    return {
      title: 'accountManagement._EDIT_',
      saveButtonText: 'accountManagement._EDIT_BUTTON_',
      message: `${t('accountManagement._EDIT_CONFIRMATION_TEXT_')}`,
      type: CONFIRMATION_MODAL,
      onClose: () => { dispatchCloseModal(CONFIRMATION_MODAL) },
      onSave: () => {
        dispatchSetSelectedAccount(account)
        dispatchOpenModal({
          type: ORGANIZATION_ACCOUNT,
          contentProps: {
            accountId: account.value,
            location
          },
          containerType: 'dialog',
          estimateDescription: t('drawer._ACCOUNT_')
        }, ORGANIZATION_ACCOUNT)
      },
    }
  }

  getSuspendAccountOption = (accountId: string): TVDMenuItem => {
    const saveButtonVariant: TVDButtonVariants = 'containedDanger'
    const { dispatchOpenModal, t } = this.props
    const { isLoading } = this.state
    return {
      disabled: isLoading,
      localizedName: t('accountManagement._CLOSE_ACCOUNT_'),
      onClick: () => {
        dispatchOpenModal({
          type: MODAL_TYPE_ACCOUNT_SUSPEND_MODAL,
          contentProps: {
            accountId,
            saveButtonText: t('accountManagement._CLOSE_ACCOUNT_'),
            saveButtonVariant,
            message: [
              t('accountManagement._ARE_YOU_SURE_CLOSING_ACCOUNT_'),
              {
                text: t('accountManagement._CLOSING_ACCOUNT_WARNING_DESCRIPTION_'),
                variant: TVD_MESSAGE_VARIANT_DANGER
              },
              t('accountManagement._ACCOUNT_CAN_BE_OPENED_LATER_')
            ],
          }
        }, MODAL_TYPE_ACCOUNT_SUSPEND_MODAL)
      }
    }
  }

  getMenu = (account: TVDEnum): React$Element<typeof Menu> => {
    const {
      t,
      classes,
      dispatchOpenModal,
      selectedAccountId,
      location,
      dispatchCloseModal,
      modalId
    } = this.props
    const { isLoading } = this.state

    const items: Array<MenuItemProps> = [
      {
        disabled: isLoading,
        localizedName: t('buttons._EDIT_'),
        onClick: () => {
          if (selectedAccountId !== account.value) {
            dispatchCloseModal(modalId)
            dispatchOpenModal(this.getConfirmAccountEditModalContent(account), CONFIRMATION_MODAL)
          } else {
            dispatchOpenModal({
              type: ORGANIZATION_ACCOUNT,
              contentProps: {
                modalId: ORGANIZATION_ACCOUNT,
                accountId: selectedAccountId,
                location
              },
              containerType: 'dialog',
              estimateDescription: t('drawer._ACCOUNT_')
            }, ORGANIZATION_ACCOUNT)
          }
        }
      }
    ]

    return (
      <Menu items={items}>
        <MoreVert className={classes.moreIcon} />
      </Menu>
    )
  }

  getClosedStatusIndicatorChip = () => {
    const { classes, t } = this.props
    return (
      <Chip
        classes={{
          root: classes.closedChipRoot,
          label: classes.closedChipLabel
        }}
        label={t('accountManagement._CLOSED_')} />
    )
  }

  getListItems = (): Array<ListItem> => {
    const {
      accounts,
      classes,
      hideMoreOptions,
      dispatchSetSelectedAccount,
      history,
      userType,
      dispatchSaveUsersGroups,
      includeStatus,
      dispatchSetMainViewIndex,
      dispatchClearStore
    } = this.props
    const { isLoading } = this.state

    return accounts.sort((a: TVDEnum, b: TVDEnum) => {
      if (a.localizedName < b.localizedName) {
        return -1
      }
      if (a.localizedName > b.localizedName) {
        return 1
      }
      return 0
    }).map((account: TVDEnum): React$Element<typeof ListItem> => {
      const { localizedName, value } = account
      let isSuspended = false

      if (includeStatus && !!HALParser.getEmbedded(account)) {
        isSuspended = !!(HALParser.getAccountStatus(account) || [])
          .find(({ status, accountId }: TVDAccountStatus): boolean => status === SUSPENDED && accountId === value)
      }

      return (
        <ListItem
          key={value}
          disabled={isLoading}
          onClick={() => {
            if (isSuspended) return
            dispatchClearStore()
            dispatchSetSelectedAccount(account)
            dispatchSetMainViewIndex(0)
            const url = `/${createQueryParamsString({ accountId: account.value })}`
            if (userType === TVD_TOKEN_USER_TYPE_USER) dispatchSaveUsersGroups()
            history.push(url)
          }}
          className={combineStyleClassNames(classes.listItem, isSuspended && classes.cursorDefault)}
          classes={{
          gutters: classes.listItemGutters
        }}>
          <div className={classes.accountNameWrapper}>
            {localizedName}
            { isSuspended && this.getClosedStatusIndicatorChip() }
          </div>
          {(!hideMoreOptions && !isSuspended) && this.getMenu(account, /* isSuspended */)}
        </ListItem>
      )
    })
  }

  getFooterButtonItems = (): Array<TextButtonProps> => {
    const {
      t, dispatchCloseModal, hideCloseButton, modalId
    } = this.props
    if (hideCloseButton) return []
    return [{
      text: t('widgets._CLOSE_'),
      onClick: () => { dispatchCloseModal(modalId) },
      variant: 'text',
    }]
  }

  render(): React$Element<typeof React.Fragment> {
    const { classes } = this.props
    const { isLoading } = this.state
    return (
      <>
        {isLoading && <Spinner />}
        <List className={classes.list}>{this.getListItems()}</List>
        <div className={classes.footerButtons}>
          <FooterButtons items={this.getFooterButtonItems()} />
        </div>
      </>
    )
  }
}

function mapStateToProps({ app: { selectedAccountId, userType }, user: { accounts } }: TVDReduxStore): MappedProps {
  return {
    selectedAccountId,
    userType,
    accounts
  }
}

function mapDispatchToProps(dispatch: Function): DispatchProps {
  return {
    dispatchCloseModal: (id: string) => { dispatch(closeModal(id)) },
    dispatchOpenModal: (content: Object, id: string) => { dispatch(openModal(content, id)) },
    dispatchSetSelectedAccount: (selectedAccount: TVDEnum) => { dispatch(setSelectedAccount(selectedAccount)) },
    dispatchSaveUsersGroups: () => { dispatch(saveUsersGroups()) },
    dispatchSetMainViewIndex: (index: number) => { dispatch(setMainViewActiveIndex(index)) },
    dispatchClearStore: () => {
      batch(() => {
        dispatch(setBuildingId(''))
        dispatch(setBuildingName(''))
        dispatch(setCalculation(''))
        dispatch(clearPrimaryEstimatesForModules())
        dispatch(clearWidgets())
        dispatch(setApplication(''))
        dispatch(setRealEstateId(''))
        dispatch(setRealEstateName(''))
        dispatch(setLicenseType(''))
        dispatch(setIsEstimateLockedToCurrentUser(false))
        dispatch(resetActiveRealEstate())
      })
    }
  }
}

export default compose(
  withStyles(styles),
  withTranslation('translations'),
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
)(AccountSelectionModal)
