// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.
import * as React from 'react'
import { withStyles } from '@material-ui/core'
import Icon from '@material-ui/core/Icon'
import MuiDialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { typographyClasses, colors, boxShadows, borderRadiuses } from 'frontend-assets'
import withTheme from '@material-ui/core/styles/withTheme'
// $FlowFixMe
import { ReactComponent as SVGIconOtherClose } from '../../../../node_modules/frontend-assets/static/assets/images/icons/Other Close.svg'

const { boxShadowFloatingWindow } = boxShadows
const { borderRadiusLarge } = borderRadiuses

const styles = (): Object => ({
  content: {
    position: 'relative',
    width: '100%',
    padding: 0,
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column'
  },
  closeIcon: {
    cursor: 'pointer',
    marginRight: 20,
    '& path': {
      fill: colors.white
    }
  },
  header: {
    display: 'flex',
    minHeight: '48px',
    maxHeight: '48px',
    backgroundColor: colors.primary100,
    alignItems: 'center',
    marginBottom: '1px',
    justifyContent: 'space-between',
  },
  title: {
    color: colors.white,
    marginLeft: '20px',
    display: 'flex',
  },
  titleRoot: {
    padding: 0,
    marginLeft: 0,
  },
  titleText: {
    ...typographyClasses.h3,
    color: colors.white,
  },
  headerLeft: {
    display: 'flex',
    alignItems: 'center',
  },
  transparentBackdrop: {
    backgroundColor: 'transparent',
  },
  paperRoot: {
    borderRadius: borderRadiusLarge,
    boxShadow: boxShadowFloatingWindow,
  }
})

type Props = {|
  classes: Object, // withStyles classes object
  icon: string | React$Element<any>, // Icon as string from material icons or a custom icon component
  title: string | React$Element<any>, // Dialog title
  children: React$Element<any>[], // Array of react elements for content
  open: boolean, // Boolean that indicates if dialog is open
  onClose?: Function | null, // function to be executed when onClose is triggered in the dialog
  fullWidth?: boolean, // MUI Dialog API boolean prop for making the dialog full width
  index?: number | null, // the index of which the dialog is when looping widgets from store
  overrideClasses?: Object, // override style for title text
  theme: TVDTheme, // MUI Theme see frontend-assets src/theme/MUITheme.js
|}

type State = {
  open: boolean, // Boolean that indicates if dialog is open
}

class Dialog extends React.Component<Props, State> {
  static defaultProps = {
    onClose: null,
    fullWidth: false,
    maxWidth: 'initial',
    index: null,
    overrideClasses: {}
  }
  state = {
    open: this.props.open,
  }

  get header(): React$Element<any> {
    const {
      classes,
      title,
      icon,
      overrideClasses
    } = this.props
    const titleTextClass = overrideClasses && overrideClasses.titleDialog ? overrideClasses.titleDialog : classes.titleText

    return (
      <div className={classes.header}>
        <div className={classes.headerLeft}>
          {typeof icon === 'string' ? <Icon className={classes.title}>{icon}</Icon> : <div className={classes.title}>{icon}</div>}
          <DialogTitle data-testid='dialog-title' className={classes.title} classes={{ root: classes.titleRoot }}>
            {typeof title === 'string' ? <span className={titleTextClass}>{title}</span> : <div className={titleTextClass}>{title}</div>}
          </DialogTitle>
        </div>
        <SVGIconOtherClose
          data-testid='close-dialog-button'
          className={classes.closeIcon}
          onClick={this.handleClose} />
        {/* <Icon data-testid='close-dialog-button' className={classes.closeIcon} onClick={this.handleClose}>clear</Icon> */}
      </div>
    )
  }

  get body(): React.Element<typeof DialogContent> {
    const { classes, children } = this.props
    return (
      <DialogContent className={classes.content}>
        { children }
      </DialogContent>
    )
  }

  handleClose = (): void => {
    this.setState({ open: false })
  }

  handleExited = () => {
    const { onClose } = this.props
    if (onClose) {
      onClose()
    }
  }

  render(): React$Element<typeof MuiDialog> {
    const {
      fullWidth,
      classes,
      index,
      theme
    } = this.props
    const { zIndex } = theme
    const dialogStyles = {
      zIndex: zIndex.modal + index
    }
    return (
      <MuiDialog
        style={dialogStyles}
        fullWidth={fullWidth}
        BackdropProps={{
          classes: {
            root: classes.transparentBackdrop
          }
        }}
        PaperProps={{
          classes: {
            root: classes.paperRoot
          }
        }}
        maxWidth='lg'
        open={this.state.open}
        onExited={this.handleExited}
        onClose={this.handleClose}>
        {this.header}
        {this.body}
      </MuiDialog>
    )
  }
}

export default withTheme(withStyles(styles)(Dialog))
