// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.
import React, { Fragment } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'
import { compose } from 'redux'

import { type AutoCompleteMenuItem } from '../menus/AutoCompleteMenu/AutoCompleteMenu'
import DropdownMenu from '../../common/menus/DropdownMenu/DropdownMenu'

type HOCProps = {|
  t: Function, // translation function
  classes: Object // withStyles classes object
|}

type DispatchProps = {|
  dispatchClearListItemsFilter: () => void, // clears the filtering of the list with id of ACCESS_CONTROL_ADD_PERMISSIONS
|}

type ReceivedProps = {|
  options: Array<AutoCompleteMenuItem>,
  onChange: () => void, // function triggered when Autocomplete value changes
  isLoading?: boolean, // optional indicator to tell if we are loading something and do not want to display the permissions list in UI yet
  handleUserChange: () => void, // function triggered when user value changes
  handlePermissionChange: () => void, // function triggered when permission value changes
  translatedPermissions: Array<AutoCompleteMenuItem>, // permission with correct translation
|}

type Props = {|
  ...HOCProps,
  ...ReceivedProps,
  ...DispatchProps
|}

const styles = ({ palette, typography }: TVDTheme): Object => ({
  contentWrapper: {
    paddingLeft: '32px',
    width: '755px'
  },
  wrapperSpacing: {
    marginTop: '60px',
    marginBottom: '60px',
    alignSelf: 'center',
    paddingLeft: '60px',
    width: 'auto'
  },
  dropdownSpacing: {
    '& [class*="MuiFormControl-root"]': {
      marginBottom: '50px'
    }
  },
  selectGroupsText: {
    fontFamily: typography.latoBoldItalic,
    color: palette.nevada,
    marginBottom: '27px',
    paddingTop: '25px'
  },
})

export class AddAccessRightModal extends React.Component<Props> {
  static defaultProps = {
    isLoading: false,
    translatedPermission: []
  }

  render(): React$Element<typeof Fragment | 'div'> {
    const {
      classes,
      t,
      options,
      handleUserChange,
      handlePermissionChange,
      translatedPermissions
    } = this.props

    const items = options.map((option: Object) => {
      const newOption = {
        value: option.id, localizedName: option.name
      }
      return newOption
    })

    return (
      <div className={`${classes.contentWrapper} ${classes.wrapperSpacing}`}>
        <span className={classes.dropdownSpacing}>
          <DropdownMenu
            disabled={items.length === 0}
            width='XL'
            title={t('addAccessRightModal._SELECT_USER_')}
            items={items}
            id='test-dropdown'
            required
            onChange={handleUserChange} />
        </span>
        <DropdownMenu
          disabled={items.length === 0}
          width='XL'
          title={t('addAccessRightModal._SELECT_USERS_PERMISSION_')}
          items={translatedPermissions}
          id='test-dropdown'
          required
          onChange={handlePermissionChange} />
      </div>
    )
  }
}

export default compose(
  withStyles(styles),
  withTranslation('translations'),
)(AddAccessRightModal)
