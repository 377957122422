// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { TextButton } from 'frontend-components'
import Message from '../Messages/Message'
import type { MessageProps } from '../Messages/Message'
// $FlowFixMe
import { ReactComponent as AlertError } from '../../../../node_modules/frontend-assets/static/assets/images/icons/Alert Error filled.svg'

type Props = {|
    children: React$Element<any>,
    t: Function, // translation function
    dialog: boolean, // is message is displayed in dialog component
    close: Function // function to close errored dialog component
|}

type State = {
  hasError: boolean, // boolean to indicate if wrapped component errored
  stack: string // full error stack
}

export class ErrorBoundary extends Component<Props, State> {
  state = {
    hasError: false,
    stack: ''
  }

  // componentDidCatch(error, info) {
  // TODO: Log Error to log service
  // }

  static getDerivedStateFromError(error: Error): Object {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, stack: error.stack }
  }

  render(): React$Element<any> {
    const messageProps: MessageProps = {
      message: this.props.t('general._ERROR_'),
      type: 'error_no_backgroundcolor',
      icon: <AlertError />,
    }

    return this.state.hasError ? (
      <>
        <Message
          {...messageProps}
          dialog={this.props.dialog}
          close={this.props.close}
          customContent={this.state.stack} />
        {this.props.dialog &&
        <div style={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginTop: 40
        }}>
          <TextButton
            text={this.props.t('buttons._OK_')}
            onClick={this.props.close}
            id='button-OK' />
        </div>
        }
      </>
    ) : this.props.children
  }
}

export default withTranslation('translations')(ErrorBoundary)
