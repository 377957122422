// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'

import ResultBar from './ResultBar/ResultBar'
import {
  getBuildingElementsScheduleValuesWithEstimateIdRequestDefinitions,
  getImportScheduleValuesWithEstimateIdRequestDefinitions,
  getSpaceScheduleSpaceGroupsValuesWithIdRequestDefinitions,
  getSpaceScheduleSpacesValuesWithIdRequestDefinitions,
  getSpaceScheduleValuesWithEstimateIdRequestDefinitions as getSpaceScheduleValuesWithEstimateIdRequestDefinitionsSpaces
} from '../../../utils/generated-api-requests/spaces'
import {
  getPriceitemValuesWithIdRequestDefinitions,
  getAssemblyValuesWithIdRequestDefinitions,
  getScheduleValuesWithEstimateIdRequestDefinitions
} from '../../../utils/generated-api-requests/buildingelements'
import { SPACES, ELEMENTS, LOCAN } from '../../../constants/moduleConstants'
import * as types from '../../../constants/contentTypes'
import SentientHOC from '../../hocs/SentientHOC/SentientHOC'
import { viewMode } from '../../../constants/viewModeConstants'
import { QUANTITY } from '../../../constants/attributes'
import { getEstimateSummaryWithEstimateIdRequestDefinitions } from '../../../utils/generated-api-requests/locan'

type MappedProps = {|
  application: string, // current app in use from Store
  resultBarData: Array<Object>, // result bar data from Store retrieved via resultBarKey
  resultBarListItemColumnData?: Object, // a list item's columnData that is retrieved if widgetResultBarStoreSource is defined
  resultBarListItemColumnUnits?: Object, // list item's columnUnits that are retrieved from widgetResultBarStoreSource if it's defined
  spacesResultView: string, // spaces result view
  spacesEstimateType: string // spaces estimate Type
|}

type ReceivedProps = {|
  resultBarFormatOptions?: TVDResultBarFormatOptions, // assign format options to result bar content
  resultBarKey: string, // prop widgetType or current application
  large?: boolean, // if the resultBar is inside a widget (effects styles)
  contentProps?: Object, // props targeted for the content within a container, e.g passing values from widget to the content it renders
|}

type Props = {|
  ...ReceivedProps,
  ...MappedProps,
  widgetType?: string, // type of the widget
  t: Function, // translation function
  sentient: TVDSentient, // Object providing helpers via SentientHOC
  functionalSectorsLastUpdated?: Object // Object indicating when fuctional sectors last updated
|}

export class ResultBarContainer extends Component<Props> {
  static defaultProps = {
    large: false,
    widgetType: '',
    resultBarData: [],
    functionalSectorsLastUpdated: {},
    spacesResultView: '',
    spacesEstimateType: ''
  }

  componentDidMount() {
    const { sentient } = this.props
    sentient.runSetup()
  }

  componentDidUpdate(prevProps: Props) {
    const {
      sentient,
      functionalSectorsLastUpdated,
      spacesResultView,
      spacesEstimateType
    } = this.props
    if (
      prevProps.functionalSectorsLastUpdated !== functionalSectorsLastUpdated ||
      prevProps.spacesResultView !== spacesResultView ||
      prevProps.spacesEstimateType !== spacesEstimateType
    ) {
      sentient.runSetup()
    }
  }

  getValuesFromStore(): Array<Object> {
    const {
      resultBarListItemColumnData: columnData = {},
      resultBarListItemColumnUnits: columnUnit = {},
      spacesEstimateType,
      t,
      widgetType
    } = this.props
    const amount = columnData.Amount || columnData.QuantityPcs || columnData.QuantitySpaceSurfaceShareAdjusted || columnData[QUANTITY]
    const share = columnData.ShareOfSurfaceQuantityP
    const area = columnData.Area || columnData.AreaTotalM2
    const price = columnData.NewPricePerArea || columnData.PriceSquareMeterNewBuildInCurrency || columnData.PriceSquareMeterRenovationInCurrency
    const totalPrice = columnData.NewPriceTotal || columnData.PriceTotalNewBuildInCurrency || columnData.TotalPrice || columnData.TotalPriceInCurrency
      || columnData.PriceTotalRenovationInCurrency
    const unitPrice = columnData.UnitPrice
    const amountUnit = columnUnit.Quantity || columnUnit.QuantityTotal || t('resultBarUnits._AMOUNT_')
    const unitPriceUnit = columnUnit.UnitPrice || `${t('resultBarUnits._CURRENCY_PER_')} ${amountUnit}` || t('resultBarUnits._UNIT_PRICE_')

    const resultBarArea = {
      propertyName: 'area',
      title: widgetType === types.SPACEGROUP ? t('resultBar._APARTMENT_AREA_') : t('resultBar._ROOM_AREA_'),
      value: area,
      unit: widgetType === types.SPACEGROUP ? t('resultBarUnits._NET_FLOOR_AREA_') : t('resultBarUnits._ROOM_AREA_'),
    }
    const resultBarNewPrice = {
      propertyName: 'NewPricePerArea',
      title: t('resultBar._SQUARE_PRICE_'),
      value: price,
      unit: widgetType === types.SPACEGROUP ? t('resultBarUnits._NET_FLOOR_AREA_PRICE_') : t('resultBarUnits._SQUARE_PRICE_'),
    }
    const resultBarAmount = {
      propertyName: 'amount',
      title: t('resultBar._AMOUNT_'),
      value: amount,
      unit: amountUnit
    }
    const resultBarUnitPrice = {
      propertyName: 'unitPrice',
      title: t('resultBar._UNIT_PRICE_'),
      value: unitPrice,
      unit: unitPriceUnit,
    }
    const resultBarTotalPrice = {
      propertyName: 'totalPrice',
      title: t('resultBar._TOTAL_PRICE_'),
      value: totalPrice,
      unit: t('resultBarUnits._TOTAL_PRICE_'),
    }
    const resultBarShare = {
      propertyName: 'share',
      title: t('resultBar._SHARE_'),
      value: share,
      unit: t('resultBarUnits._SHARE_'),
    }

    switch (widgetType) {
      case types.PRICEITEM:
      case types.ASSEMBLY: {
        return [resultBarAmount, resultBarUnitPrice, resultBarTotalPrice]
      }
      case types.SPACES_ASSEMBLY: {
        return [resultBarAmount, resultBarShare]
      }
      case types.SPACE:
      case types.SPACEGROUP: {
        if (spacesEstimateType === viewMode.SPACESCHEDULE) return [resultBarAmount, resultBarArea]
        return [resultBarAmount, resultBarArea, resultBarNewPrice, resultBarTotalPrice]
      }
      default:
        return []
    }
  }

  render(): React$Element<typeof ResultBar> | null {
    const {
      large,
      resultBarListItemColumnData,
      widgetType,
      resultBarData,
      resultBarFormatOptions
    } = this.props
    if (large) {
      return (
        resultBarData.length > 0 ?
          <ResultBar resultBarFormatOptions={resultBarFormatOptions} results={resultBarData} large={large} /> :
          null
      )
    } else if (resultBarListItemColumnData) {
      return (
        this.getValuesFromStore().length > 0 ?
          <ResultBar resultBarFormatOptions={resultBarFormatOptions} widgetType={widgetType} results={this.getValuesFromStore()} /> :
          null
      )
    }
    return (
      resultBarData.length > 0 ?
        <ResultBar resultBarFormatOptions={resultBarFormatOptions} widgetType={widgetType} results={resultBarData} large={large} /> :
        null
    )
  }
}

type ReduxState = {
  +app: Object,
  +list: Object,
  +spaces: Object,
  +resultBars: Object,
}

function mapStateToProps({
  app, list, resultBars
}: ReduxState, props: Object): MappedProps {
  const {
    application,
    spacesResultView,
    spacesEstimateType
  } = app
  const { resultBarKey, widgetResultBarStoreSource } = props
  const resultBarListItemColumnData = widgetResultBarStoreSource && list[widgetResultBarStoreSource.listId] &&
    list[widgetResultBarStoreSource.listId].listItems !== undefined ?
    list[widgetResultBarStoreSource.listId].listItems[widgetResultBarStoreSource.listItemId]?.columnData :
    null
  const resultBarListItemColumnUnits = widgetResultBarStoreSource && list[widgetResultBarStoreSource.listId] &&
    list[widgetResultBarStoreSource.listId].listItems !== undefined ?
    list[widgetResultBarStoreSource.listId].listItems[widgetResultBarStoreSource.listItemId]?.columnUnits :
    null
  const resultBarData = resultBars[resultBarKey]

  return {
    application,
    resultBarListItemColumnData,
    resultBarData: props.resultBarData || resultBarData,
    resultBarListItemColumnUnits,
    spacesResultView,
    spacesEstimateType,
  }
}

const sentientConfig: TVDSentientConfig = {
  updateOnPropsChange: ({ spacesResultView }: Props): Object => ({ spacesResultView }),
  getSetupRequestDefinitions: (store: TVDReduxStore, props: ReceivedProps): TVDGARConfigs => {
    const {
      resultBarKey,
      contentProps: {
        priceItemId,
        assemblyId,
        spaceId
      } = {}
    } = props
    const getResultBarRequestDefinitions = 'getResultBarRequestDefinitions'
    switch (resultBarKey) {
      case types.SPACEGROUP: {
        if (!spaceId) {
          console.error('Can\'t get space group values without space id')
        }
        return {
          [getResultBarRequestDefinitions]: spaceId ? getSpaceScheduleSpaceGroupsValuesWithIdRequestDefinitions({
            requestArgs: { path: { id: spaceId } },
            payload: { resultBarKey }
          }) : {}
        }
      }
      case types.SPACE: {
        if (!spaceId) {
          console.error('Can\'t get space values without space id')
        }
        return {
          [getResultBarRequestDefinitions]: spaceId ? getSpaceScheduleSpacesValuesWithIdRequestDefinitions({
            requestArgs: { path: { id: spaceId } },
            payload: { resultBarKey }
          }) : {}
        }
      }
      case types.PRICEITEM: {
        if (!priceItemId) {
          console.error('Can\'t get price item values without priceItemId')
        }

        return {
          [getResultBarRequestDefinitions]: priceItemId ? getPriceitemValuesWithIdRequestDefinitions({
            requestArgs: { path: { id: priceItemId } },
            payload: { resultBarKey }
          }) : {}
        }
      }
      case types.ASSEMBLY: {
        if (!assemblyId) {
          console.error('Can\'t get assembly values without assemblyId')
        }

        return {
          [getResultBarRequestDefinitions]: assemblyId ? getAssemblyValuesWithIdRequestDefinitions({
            requestArgs: { path: { id: assemblyId } },
            payload: { resultBarKey }
          }) : {}
        }
      }
      case ELEMENTS: {
        return {
          [getResultBarRequestDefinitions]: getScheduleValuesWithEstimateIdRequestDefinitions({
            payload: { resultBarKey },
            requestArgs: {}
          })
        }
      }
      case SPACES: {
        return {
          [getResultBarRequestDefinitions]: getSpaceScheduleValuesWithEstimateIdRequestDefinitionsSpaces({
            payload: { resultBarKey }
          })
        }
      }

      case types.BUILDING_ELEMENTS_SCHEDULE: {
        return {
          [getResultBarRequestDefinitions]: getBuildingElementsScheduleValuesWithEstimateIdRequestDefinitions({
            payload: { resultBarKey },
          }),
        }
      }
      case types.IMPORT_FROM_WOP_CALCULATION: {
        return {
          [getResultBarRequestDefinitions]: getImportScheduleValuesWithEstimateIdRequestDefinitions({
            payload: { resultBarKey }
          })
        }
      }
      case LOCAN: {
        return {
          [getResultBarRequestDefinitions]: getEstimateSummaryWithEstimateIdRequestDefinitions({
            payload: { resultBarKey },
            requestArgs: {}

          })
        }
      }
      default: {
        return {}
      }
    }
  }
}

export default (connect(mapStateToProps)(withTranslation('translations')(SentientHOC(ResultBarContainer, sentientConfig))))
