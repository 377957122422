// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.

import React, { Component } from 'react'
import { Icon } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { colors, typographyClasses } from 'frontend-assets'
import MUIMenuItem from '@material-ui/core/MenuItem'
import Checkbox from '../../../../CheckBox/CheckBox'
import SkeletonContainer from '../../../../../containers/SkeletonContainer/SkeletonContainer'
import { combineStyleClassNames } from '../../../../../../utils/styleUtils'
import OverflowTooltip from '../../../../OverflowTooltip/OverflowTooltip'
import Tooltip from '../../../../Tooltip/Tooltip'

const styles = () => ({
  disabled: {
    // Overwriting MUI styles to allow disabled item to have tooltip
    pointerEvents: 'auto !important',
    '&:hover': {
      backgroundColor: 'transparent !important'
    }
  },
  menuItemRoot: {
    ...typographyClasses.bodyBig,
    paddingTop: '0',
    paddingBottom: '0',
    minHeight: '40px',
    color: colors.dark80,
    '&:focus': {
      '&:active': {
        backgroundColor: colors.primary10
      }
    },
    '&:hover': {
      backgroundColor: colors.gray20
    },
    '&.Mui-disabled': {
      color: colors.gray80,
    }
  },
  menuItemSelected: {
    backgroundColor: colors.primary10,
    '&$menuItemSelected': {
      backgroundColor: colors.primary10,
      '&:hover': {
        backgroundColor: colors.primary10
      },
      '&:focus': {
        backgroundColor: colors.primary10,
        '&:hover': {
          backgroundColor: colors.primary10
        }
      }
    }
  },
  contentWrapper: {
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    padding: '0px 16px',
  },
  textSpan: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  reverse: {
    flexDirection: 'row-reverse',
    justifyContent: 'flex-end'
  },
  checkboxSpan: {
    marginRight: 10,
  },
  icon: {
    fontSize: 20,
    color: colors.dark60,
    verticalAlign: 'text-bottom',
    flex: 'none'
  },
  iconHidden: {
    visibility: 'hidden'
  }
})

type MenuItemIconType = {|
  name: string, // name of MUI icon if menuItem should have one
  style?: Object, // optionally customize icon style
  alignLeft?: boolean, // if icon should be aligned in the left side of menuitem
|}

type MUIMenuItemProps = {|
  id?: string, // optional unique id that you can map easily menu items e.g using id as the key
  component?: string, // optional component to overwrite default <li /> element wrapping menu item
  disabled?: boolean, // whether the MenuItem is in an inactive state, prevents clicking events from firing
  selected?: boolean, // MUI selection flag, required for dropdowns to work, internal to the menu structure
|}

export type MenuItemProps = {|
  component?: string, // optional component to overwrite default <li /> element wrapping menu item
  value?: string | number, // unique value for the menuItem
  localizedName?: string, // localizedName for the menuItem
  tooltip?: String, // potential tooltip
  onClick?: (event: SyntheticInputEvent<any>) => void, // callback function for clicking on a MenuItem, called with value and localizedName
  // TODO: deprecate onClickCb when onClick works properly with DropdownMenu
  onClickCb?: (event: SyntheticInputEvent<any>) => void, // optional callback function for clicking on a MenuItem
  preventClickEvent?: Function, // prevent click event if other component handles the click
  testId?: string, // id for testing purposes
  disabled?: boolean, // whether the MenuItem is in an inactive state, prevents clicking events from firing
  icon?: MenuItemIconType,
  isIconHidden?: boolean, // toggle icon visibility to reserve space but not show the icon
  subMenuItems?: Array<Object>, // array of menuitems if menuitem has a submenu
  divider?: boolean, // indicates if menuitem is a divider
  localizedNameSelected?: string | number, // string to overwrite localizedName when the MenuItem is selected
  showMarker?: boolean, // toggles on a marker in the top right corner of the selected MenuItem
  |}

type Props = {
  ...MenuItemProps,
  ...MUIMenuItemProps,
  checkbox?: boolean, // whether this menuItem should have a checkbox, handled by internal menu implementation
  handleClose?: () => void, // method to fire if clicking on this needs to close the menu, internal to the menu structure
  selected?: boolean, // MUI selection flag, required for dropdowns to work, internal to the menu structure
  classes: Object, // withStyles styles prop
  renderSkeleton?: boolean, // indicates if we want to show a placeholder Skeleton
  allowPropagation?: boolean, // if the on click event is allowed to propagate
}

export class MenuItem extends Component<Props> {
  static defaultProps = {
    testId: '',
    value: '',
    onClick: null,
    disabled: false,
    handleClose: null,
    checkbox: false,
    selected: false,
    tooltip: '',
    icon: null,
    renderSkeleton: false,
    allowPropagation: false
  }

  get content(): React$Element<any> {
    const {
      classes,
      checkbox,
      localizedName,
      icon,
      renderSkeleton,
      isIconHidden
    } = this.props
    return (
      <div className={combineStyleClassNames((icon && icon.alignLeft) && classes.reverse, classes.contentWrapper)}>
        <SkeletonContainer renderSkeleton={renderSkeleton}>
          { checkbox &&
            <span className={classes.checkboxSpan}>
              <Checkbox checked={this.props.selected} />
            </span>
          }
          {this.getContentContainerComponent((
            <span className={`${classes.textSpan}`}>
              { localizedName }
            </span>))
          }
          { icon &&
            <Icon
              style={{ ...icon.style }}
              classes={{ root: combineStyleClassNames(classes.icon, isIconHidden && classes.iconHidden) }}>
              {icon.name}
            </Icon> }
        </SkeletonContainer>
      </div>
    )
  }

  getContentContainerComponent = (child: React$Element<any>): React$Element<any> => {
    const { tooltip, localizedName } = this.props
    if (tooltip) return child
    return <OverflowTooltip tooltipText={localizedName}>{child}</OverflowTooltip>
  }

  handleClick = (event: SyntheticInputEvent<any>) => {
    const {
      handleClose,
      onClick,
      disabled,
      preventClickEvent,
      allowPropagation,
      onClickCb
    } = this.props

    if (disabled || preventClickEvent) return
    if (!allowPropagation) event.stopPropagation()

    if (onClick) onClick(event)
    if (onClickCb) onClickCb(event)
    if (handleClose) handleClose()
  }

  getMenuItemProps = (): MUIMenuItemProps => {
    const { component, disabled, selected } = this.props
    return { component, disabled, selected }
  }

  render(): React$Element<MUIMenuItem> {
    const {
      classes,
      testId,
      localizedName,
      tooltip,
      renderSkeleton,
      disabled
    } = this.props

    return (
      <Tooltip text={tooltip}>
        <MUIMenuItem
          {...this.getMenuItemProps()}
          disableRipple
          disableGutters
          disabled={this.props.disabled || renderSkeleton}
          onClick={disabled ? null : this.handleClick}
          classes={{
            root: combineStyleClassNames(classes.menuItemRoot, disabled && classes.disabled),
            selected: classes.menuItemSelected,
          }}
          data-testid={testId || `MenuItem-${localizedName || ''}`}>
          {this.content}
        </MUIMenuItem>
      </Tooltip>
    )
  }
}

export default withStyles(styles)(MenuItem)
