// @flow
// Copyright © 2010–2023 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.

import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { openModal } from '../../../actions/modals'
import { getUsersSelfServiceMessagesRequest } from '../../../utils/generated-api-requests/users'
import { MAINTENANCE_OUTAGE_MODAL } from '../../../constants/modalConstants'
import { MAINTENANCE_BREAK } from '../../../constants/serviceMessageConstants'

const useCheckMaintenanceBreaks = () => {
  const dispatch = useDispatch()

  const checkMaintenanceBreaks = useCallback(() => {
    getUsersSelfServiceMessagesRequest(
      {},
      (data: {[key: string]: TVDServiceMessage}) => {
        const maintenanceBreaksData = ((Object.values(data): any): TVDServiceMessage[])
          .sort((a: TVDServiceMessage, b: TVDServiceMessage): number =>
            Date.parse(b.startDate || '') - Date.parse(a.startDate || ''))
          .find((info: TVDServiceMessage): boolean => info.messageType === MAINTENANCE_BREAK)

        if (!maintenanceBreaksData) return
        dispatch(openModal(
          {
            type: MAINTENANCE_OUTAGE_MODAL,
            disableBackdropClick: false,
            contentProps: {
              maintenanceBreaks: maintenanceBreaksData
            }
          },
          MAINTENANCE_OUTAGE_MODAL
        ))
      },
      null,
      {}
    )
  }, [dispatch])
  return [checkMaintenanceBreaks]
}

export default useCheckMaintenanceBreaks
