// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.

import { withTranslation } from 'react-i18next'
import MUIDrawer from '@material-ui/core/Drawer'
import React from 'react'
import { compose } from 'redux'
import { withStyles } from '@material-ui/core'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import { typographyClasses } from 'frontend-assets'
import { map } from 'lodash'
import LanguagePicker from '../LanguagePicker/LanguagePicker'
import FeaturesHOC from '../../hocs/FeaturesHOC/FeaturesHOC'
import {
  FEATURE_SETTINGS, FEATURE_SHOW_INSTRUCTIONS, FEATURE_LANGUAGE_SELECTION, FEATURE_TERMS_OF_USE
} from '../../../constants/features'
import { toggleDrawer } from '../../../actions/view/actions'
import { buildSettingsModal, openModal, openReportModal } from '../../../actions/modals'
import { authenticationFlowType } from '../../../utils/authUtil'
import { ACCOUNT_SELECTION_MODAL, RELEASE_NOTES_MODAL, TERMS_OF_SERVICE } from '../../../constants/modalConstants'
import { deleteEstimateLockWithEstimateIdRequest } from '../../../utils/generated-api-requests/spaces'
import { TVD_PRIVILEGE_FILTER_ACCOUNT_MANAGER } from '../../../constants/apiConstants'
import { ENABLE_FEEDBACK } from '../../../constants/envConstants'
// $FlowFixMe
import { ReactComponent as NewTabIcon } from '../../../../node_modules/frontend-assets/static/assets/images/icons/Go to app.svg'

const drawerWidth = 364

const styles = ({ palette, zIndex }: TVDTheme): Object => ({
  modalRoot: {
    zIndex: zIndex.modal - 1
  },
  drawerPaper: {
    minWidth: drawerWidth,
    zIndex: zIndex.modal
  },
  userInfoWrapper: {
    backgroundColor: palette.white,
    display: 'flex',
    height: 122,
    alignItems: 'center',
    paddingLeft: 48,
    borderBottom: `1px solid ${palette.dark60}`,
  },
  userInformation: {
    display: 'flex',
    flexDirection: 'column',
    color: palette.white
  },
  userName: {
    ...typographyClasses.bodyBigBold,
    color: palette.dark80
  },
  rowIconStyles: {
    paddingRight: 16,
    marginLeft: 23,
    fontSize: 18
  },
  email: {
    ...typographyClasses.bodyDefault,
    color: palette.dark80
  },
  rowStyles: {
    ...typographyClasses.bodyBig,
    display: 'flex',
    alignItems: 'center',
    lineHeight: '48px',
    paddingLeft: 25,
    color: palette.dark80,
    cursor: 'pointer',
    width: '100%',
    '&:hover': {
      backgroundColor: palette.gray20,
    },
    '&:active': {
      backgroundColor: palette.primary10,
    },
  },
  actionList: {
    paddingTop: 30,
    paddingBottom: 10,
  },
  footer: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: 10,
    width: '100%',
    borderTop: `1px solid ${palette.gray60}`,
  },
  iconWrapper: {
    display: 'flex',
    alignItems: 'center',
    '& > svg': {
      marginLeft: '6px'
    }
  }
})

type DispatchProps = {|
  openSettings: Function, // open settings modal
  closeDrawer: Function, // dispatches closing toggle function for drawer
  openGeneralReport: Function, // opens the report modal
  dispatchOpenModal: (content: Object, id: string) => void, // open modal by adding required modal content to Store
|}

type MappedProps = {|
  open?: boolean, // determines if the drawer is open or closed
  claims: Object, // object containing user claims that are used for showing information about the logged in user
  isEstimateLockedToCurrentUser: boolean | null, // indicates if estimate is locked to current user
  accounts: Array<TVDEnum>, // list of available accounts for the user
  selectedAccountName?: string, // the name of the currently selected account
  languageCode?: string, // language code currently used for link paths
  userPrivileges: Array<TVDEnum>, // list of privileges of the user
  activeEstimate?: TVDActiveEstimateStore, // currently active estimate
|}

type Props = {|
  ...DispatchProps,
  ...MappedProps,
  t: Function, // i18next translation function
  classes: Object, // withStyles style object
  features: TVDFeatureHOCProps, // features from Store and helper functions from the HOC
  history: Object, // router history object
|}

type Row = {
  getContent: Function,
  onClick?: Function,
  icon: string,
  link: string | null,
  testId: string,
  target: string,
  featureName?: string
}

const testIdPrefix = 'DrawerRight-'

export class DrawerRight extends React.Component<Props> {
  static defaultProps = {
    open: false,
    selectedAccountName: '',
    userPrivileges: [],
    languageCode: ''
  }

  get rows(): Array<Object> {
    const {
      features: { getEnabledFeatures },
      dispatchOpenModal,
      t,
      accounts,
      selectedAccountName,
      userPrivileges,
      classes,
      claims
    } = this.props

    const supportUrl = 'https://support.realaizer.com/customer/login'

    const rows = getEnabledFeatures([
      {
        featureName: FEATURE_SETTINGS,
        getContent: (): React$Element<'span'> => <span variant='text'>{t('drawer._SETTINGS_')}</span>,
        onClick: () => this.props.openSettings(),
        icon: 'User Info Drawer Profile',
        link: null,
        testId: `${testIdPrefix}settings`
      },
      {
        getContent: (): React$Element<'span'> => <span>{t('drawer._ACCOUNT_', { accountName: selectedAccountName })}</span>,
        onClick: () => {
          dispatchOpenModal({
            type: ACCOUNT_SELECTION_MODAL,
            title: t('drawer._CHOOSE_ACCOUNT_'),
            disablePadding: true,
            contentProps: {
              accounts,
              hideMoreOptions: true
            }
          }, ACCOUNT_SELECTION_MODAL)
        },
        icon: 'User Info Drawer Account',
        link: null,
        testId: `${testIdPrefix}account`
      },
      {
        featureName: FEATURE_SHOW_INSTRUCTIONS,
        getContent: (): React$Element<'span'> => <span className={classes.iconWrapper}>{t('drawer._SHOW_INSTRUCTIONS_')}<NewTabIcon /></span>,
        icon: 'User Info Drawer Support',
        link: supportUrl,
        target: '_blank',
        testId: `${testIdPrefix}instructions`
      },
      {
        getContent: (): React$Element<'span'> => <span className={classes.iconWrapper}>{t('drawer._OPEN_ACADEMY_')}<NewTabIcon /></span>,
        icon: 'User Info Drawer Academy',
        link: 'https://academy.realaizer.com/start',
        target: '_blank',
        testId: `${testIdPrefix}academy`
      },
      {
        featureName: FEATURE_LANGUAGE_SELECTION,
        getContent: (): React$Element<any> => <LanguagePicker />,
        icon: 'User Info Drawer Languages',
        link: null,
        testId: `${testIdPrefix}language`
      },
      {
        featureName: FEATURE_TERMS_OF_USE,
        getContent: (): React$Element<'span'> => <span>{t('drawer._TERMS_OF_SERVICE_')}</span>,
        onClick: () => {
          dispatchOpenModal(
            {
              type: TERMS_OF_SERVICE,
              disableBackdropClick: false,
            },
            TERMS_OF_SERVICE
          )
        },
        icon: 'User Info Drawer Terms of use',
        link: null,
        testId: `${testIdPrefix}terms`
      },
      {
        getContent: (): React$Element<'span'> => <span>{t('drawer._UI_VERSION_')}</span>,
        onClick: () => {
          dispatchOpenModal(
            {
              type: RELEASE_NOTES_MODAL,
              disableBackdropClick: false,
            },
            RELEASE_NOTES_MODAL
          )
        },
        icon: 'User Info Drawer Versions',
        link: null,
        testId: `${testIdPrefix}version`
      },
    ])

    if (ENABLE_FEEDBACK === 'true') {
      rows.push({
        getContent: (): React$Element<'span'> => <span>{t('drawer._GIVE_FEEDBACK_')}</span>,
        icon: 'User Info Drawer Feedback',
        link: null,
        onClick: () => {
          const freshWorksWidget = window.FreshworksWidget
          const freshdeskContainer = document.getElementById('freshworks-container')
          if (
            freshdeskContainer &&
            // We set display none on index.html already for the container
            // to avoid showing the launcher before FreshworksWidget('hide', 'launcher') is run
            (
              freshdeskContainer.style.display === 'none' ||
              // On initial load the display is empty
              freshdeskContainer.style.display === ''
            )
          ) {
            freshdeskContainer.style.display = 'block'
          }
          if (freshWorksWidget) {
            window.FreshworksWidget('disable', 'ticketForm', ['custom_fields.cf_tietosuojaseloste'], {
              ticketId: '80000002079'
            })
            window.FreshworksWidget('prefill', 'ticketForm', {
              email: claims.email,
              name: `${claims.firstName} ${claims.lastName}`,
              custom_fields: {
                cf_tietosuojaseloste: true
              }
            }, { ticketId: '80000002079' })

            freshWorksWidget('open')
            freshWorksWidget('hide', 'launcher')
          }
        },
        testId: `${testIdPrefix}feedback`
      })
    }

    const isAccountManager = !!userPrivileges.find(({ value }: TVDEnum): boolean => value === TVD_PRIVILEGE_FILTER_ACCOUNT_MANAGER)
    if (isAccountManager) {
      rows.splice(2, 0, {
        getContent: (): React$Element<'span'> => <span variant='text'>{t('drawer._CREATE_GENERAL_REPORT_')}</span>,
        onClick: this.props.openGeneralReport,
        icon: 'User Info Drawer Report',
        link: null,
        testId: `${testIdPrefix}report`
      })
    }

    const currentPath = window.location.pathname
    const haahtelaAdminRows = rows.filter((row: Row) => row.featureName !== FEATURE_SETTINGS)

    if (currentPath === '/haahtela-admin') return haahtelaAdminRows
    return rows
  }


  get header(): React$Element<any> {
    const { classes, claims } = this.props
    return (
      <div data-testid={`${testIdPrefix}body-top`} className={classes.userInfoWrapper}>
        <div className={classes.userInformation}>
          <span data-testid='DrawerRight-username' className={classes.userName}>{claims.firstName} {claims.lastName}</span>
          <span data-testid='DrawerRight-email' className={classes.email}>{claims.email}</span>
        </div>
      </div>
    )
  }

  get actionList(): React$Element<any> {
    const { t, classes } = this.props
    return (
      <div data-testid={`${testIdPrefix}body`} className={classes.actionList}>
        {
          map(this.rows, (row: Row) => (
            <a data-testid={row.testId} onClick={row.onClick} key={row.testId} href={row.link} target={row.target} className={classes.rowStyles}>
              <img
                style={{ visibility: row.icon ? 'visible' : 'hidden' }}
                alt={row.icon}
                className={classes.rowIconStyles}
                src={`/assets/images/icons/${row.icon}.svg`} />
              { row.getContent(t) }
            </a>
          ))
        }
      </div>
    )
  }

  get footer(): React$Element<any> {
    const {
      t,
      classes,
      history,
      isEstimateLockedToCurrentUser,
      activeEstimate
    } = this.props

    const logoutRoute = authenticationFlowType ? '/logout' : '/kirjaudu'
    const state = { from: history.location }

    return (
      <div data-testid={`${testIdPrefix}footer`} className={classes.footer}>
        <Link
          className={classes.rowStyles}
          data-testid={`${testIdPrefix}logout`}
          onClick={(ev: SyntheticEvent<any>) => {
            ev.preventDefault()
            if (isEstimateLockedToCurrentUser && !!activeEstimate) {
              deleteEstimateLockWithEstimateIdRequest({}, () => {
                history.push(logoutRoute, state)
              })
            } else {
              history.push(logoutRoute, state)
            }
          }}
          to={logoutRoute}>
          <img
            alt='Sign out'
            className={classes.rowIconStyles}
            src='/assets/images/icons/User Info Drawer Sign out.svg' />
          <span>{t('drawer._LOG_OUT_')}</span>
        </Link>
      </div>
    )
  }

  render(): React$Element<typeof MUIDrawer> {
    const { classes, open, closeDrawer } = this.props
    return (
      <MUIDrawer
        data-testid={testIdPrefix}
        ModalProps={{ hideBackdrop: true, classes: { root: classes.modalRoot }, onClick: () => closeDrawer() }}
        PaperProps={{ classes: { root: classes.drawerPaper } }}
        anchor='right'
        open={open}
        onClose={() => closeDrawer()}>
        { this.header }
        { this.actionList }
        { this.footer }
      </MUIDrawer>
    )
  }
}

const mapStateToProps = ({
  view: { isDrawerOpen: open },
  user: { claims, accounts, userPrivileges },
  app: { isEstimateLockedToCurrentUser, selectedAccountName, languageCode },
  activeEstimate
}: TVDReduxStore): MappedProps => ({
  open,
  claims,
  isEstimateLockedToCurrentUser,
  accounts,
  selectedAccountName,
  userPrivileges,
  languageCode,
  activeEstimate
})

const mapDispatchToProps = (dispatch: Function): DispatchProps => ({
  closeDrawer: () => dispatch(toggleDrawer(false)),
  openSettings: () => dispatch(buildSettingsModal()),
  openGeneralReport: () => dispatch(openReportModal('')),
  dispatchOpenModal: (content: Object, id: string) => { dispatch(openModal(content, id)) }
})

export default compose(
  withTranslation('translations'),
  withRouter,
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
  FeaturesHOC
)(DrawerRight)
