// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.

import React from 'react'

type Props = {
  onChange: (selection: string | number) => void,
  children: Function, // function as a child prop
  forceSelect: string | number, // value for a default selection
  placeholder: boolean, // a placeholder item text for placeholdering
  allowReSelection: ?boolean, // re-selecting selected option allowed
  value?: any, // optional value that makes the component controlled
}

type State = {|
  selected: string | number, // currently selected items
|}

export class DropdownState extends React.Component<Props, State> {
  static defaultProps = {
    forceSelect: '',
    value: undefined
  }

  state = {
    selected: this.props.forceSelect || (this.props.placeholder ? 'placeholder' : ''),
  }

  componentDidMount() {
    const { value } = this.props
    if (value || value === 0) this.setState({ selected: value })
  }

  componentDidUpdate(prevProps: Object) {
    const { value } = this.props
    if (typeof value !== 'undefined' && value !== prevProps.value) {
      this.setState({ selected: value })
    } else {
      // if the forced selection has changed then a new forced value has been given
      const forceSelectHasChanged = () => prevProps.forceSelect !== this.props.forceSelect

      // together with forceSelectHasChanged an empty forceSelect would mean the menu has been reset
      const forceSelectExistsOrIsEmptyStr = () => this.props.forceSelect || this.props.forceSelect === ''

      // a change in placholder status can happen when in some cases data has not yet been received
      const placeholderHasChanged = () => prevProps.placeholder !== this.props.placeholder

      // a changed forceSelect that is truthy or an empty string means the menu has been forcibly controlled
      // therefore we force the current selection to the forced value
      if (forceSelectHasChanged() && forceSelectExistsOrIsEmptyStr()) {
        // if a placeholder has been set then return to that if the menu was reset
        if (this.props.placeholder && this.props.forceSelect === '') {
          // 'placeholder' is the value for the placeholder MenuItem created in MenuItems.jsx
          this.setState({ selected: 'placeholder' })
        } else {
          // otherwise just follow the forced selection
          this.setState({ selected: this.props.forceSelect })
        }
        // this covers cases where the placeholder is changed mid lifecycle but no value is forced.
        // A quickfix for when components render empty without placeholders for a few cycles, like in multiedit situations
      } else if (placeholderHasChanged() && !this.props.forceSelect) {
        this.setState({ selected: 'placeholder' })
      }
    }
  }

  handleSelect = (selectedValue: string | number) => {
    if (this.props.value) return
    // selecting the same selection again does not trigger state changes or callbacks unless explicitly wanted
    if (selectedValue === this.state.selected && !this.props.allowReSelection) return

    this.setState({ selected: selectedValue }, () => {
      // do not call onChange on empty strings or non-existent functions
      if (this.state.selected !== '' && this.props.onChange) {
        this.props.onChange(this.state.selected)
      }
    })
  }

  render(): React$Element<any> {
    return this.props.children({ selected: this.state.selected, handleSelect: this.handleSelect })
  }
}

export default DropdownState
