// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { loadMFE, registeredMFEs } from '../../../utils/MFEUtils'
import { API_BASE_URL, ALLOW_WITH_CREDENTIALS, FEATURE_SET } from '../../../constants/envConstants'
import { openGenericErrorModal } from '../../../utils/modalUtils'
import { dispatch } from '../../../store'
import { setActiveWidgetAsTopmost } from '../../../actions/app'
import { defaultLocation } from '../../../middlewares/widgetLocationMiddleware'

type Props = {|
  setIsWidgetOpen: Function,
  isWidgetOpen: boolean
|}

export const estimateNotesMFERootId = 'containers-estimate-notes-widget'

const EstimateNotesMFEContainer = ({
  setIsWidgetOpen,
  isWidgetOpen
}: Props): null => {
  const {
    accessToken,
    haahtelaApiUserToken,
    userId,
    languageCode,
    selectedAccountId,
    activeEstimate: activeSpacesEstimate,
    isEstimateLockedToCurrentUser,
    widgetOrder,
    activeRealEstate
  } = useSelector(({
    activeEstimate,
    app,
    user,
    activeRealEstate: activeRealEstateStore
  }: TVDReduxStore): {|
    languageCode: $PropertyType<TVDApplicationStore, 'languageCode'>,
    selectedAccountId: $PropertyType<TVDApplicationStore, 'selectedAccountId'>,
    accessToken: $PropertyType<TVDUserAuthorization, 'accessToken'>,
    haahtelaApiUserToken: $PropertyType<TVDUserClaims, 'haahtelaApiUserToken'>,
    userId: $PropertyType<TVDUserClaims, 'userId'>,
    activeEstimate: TVDActiveEstimateStore,
    isEstimateLockedToCurrentUser: $PropertyType<TVDApplicationStore, 'isEstimateLockedToCurrentUser'>,
    widgetOrder: $PropertyType<TVDApplicationStore, 'widgetOrder'>,
    activeRealEstate: TVDActiveRealEstateStore,
  |} => ({
    languageCode: app.languageCode,
    selectedAccountId: app.selectedAccountId,
    accessToken: user.authorization.accessToken,
    haahtelaApiUserToken: user.claims.haahtelaApiUserToken,
    userId: user.claims.userId,
    activeEstimate,
    isEstimateLockedToCurrentUser: app.isEstimateLockedToCurrentUser,
    widgetOrder: app.widgetOrder,
    activeRealEstate: activeRealEstateStore,
  }))

  const estimateId = activeSpacesEstimate?.id
  const registeredEstimateNotesAPIModule = registeredMFEs['containers-estimate-notes-module']
  const rootElement = document.getElementById(estimateNotesMFERootId)

  useEffect(() => {
    if (
      languageCode &&
      accessToken &&
      haahtelaApiUserToken &&
      userId &&
      selectedAccountId &&
      estimateId &&
      setIsWidgetOpen &&
      activeRealEstate
    ) {
      const render = (module: TVDMFEFunctionality): void => {
        if (module.render && !!rootElement) {
          const setWidgetAsTopmost = () => {
            if (widgetOrder[widgetOrder.length - 1] !== estimateNotesMFERootId) {
              dispatch(setActiveWidgetAsTopmost(estimateNotesMFERootId))
            }
          }
          module.render(
            estimateNotesMFERootId,
            {
              APIBaseURL: API_BASE_URL,
              languageCode,
              accessToken,
              haahtelaApiUserToken,
              userId,
              selectedAccountId,
              openGenericErrorModal,
              ALLOW_WITH_CREDENTIALS,
              estimateId,
              activeRealEstate,
              isWidgetOpen,
              setIsWidgetOpen,
              setWidgetAsTopmost,
              widgetOrder,
              defaultLocation,
              isEstimateLockedToCurrentUser,
              FEATURE_SET
            }
          )
        }
      }
      if (registeredEstimateNotesAPIModule) {
        render(registeredEstimateNotesAPIModule)
      } else {
        loadMFE('containers_estimate_notes', 'containers-estimate-notes-module', (module: TVDMFEFunctionality): void => {
          render(module)
        })
      }
    }
  }, [
    languageCode,
    accessToken,
    haahtelaApiUserToken,
    userId,
    selectedAccountId,
    estimateId,
    registeredEstimateNotesAPIModule,
    isEstimateLockedToCurrentUser,
    setIsWidgetOpen,
    isWidgetOpen,
    widgetOrder,
    activeRealEstate
  ])
  return null
}

export default EstimateNotesMFEContainer

