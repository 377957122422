// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import { forEach, keys, omit, reduce, map, mapValues, every, values, isEqual } from 'lodash'
import { withTranslation } from 'react-i18next'

import RelationalSelector from '../../../common/RelationalSelector/RelationalSelector'
import DropdownMenu from '../../../common/menus/DropdownMenu/DropdownMenu'
import LabeledInput from '../../../common/LabeledInput/LabeledInput'

import { postPolling } from '../../../../actions/postPolling'
import { parseRegistryItems } from '../../../../utils/parseUtil'
import {
  getRegistryFunctionsRequest,
  getRegistryFunctionalSectorsRequest,
  getRegistryProcessesRequest,
  getRegistryActivityGroupsRequest,
  postActivityScheduleFunctionalSectorsWithEstimateIdRequest,
  postActivityScheduleFunctionsWithItemIdRequest,
  postActivityScheduleProcessesWithItemIdRequest,
  postActivityScheduleActivityGroupsWithItemIdRequest,
  getRegistryFunctionalSectorsDriversWithIdRequest,
  getRegistryFunctionsDriversWithIdRequest,
  getRegistryProcessesDriversWithIdRequest,
  getRegistryActivityGroupsDriversWithIdRequest,
  getRegistryFunctionsCanAttachToRequest,
  getRegistryProcessesCanAttachToRequest,
  getRegistryActivityGroupsCanAttachToRequest,
  basePath
} from '../../../../utils/generated-api-requests/wop'
import { runGAR, getEnumGARDefinition } from '../../../../utils/GARUtils'
import { FUNCTION, PROCESS, ACTIVITYGROUP, FUNCTIONALSECTOR, ACTIVITY_STRUCTURE } from '../../../../constants/contentTypes'
import { updateSentient } from '../../../../actions/sentients'
import { DRIVER, CODRIVER } from '../../../../constants/attributes'

const styles = () => ({
  content: {
    display: 'flex',
    width: '100%',
    height: '100%',
    padding: '0 0 16px 0px'
  },
  headerRight: {
    display: 'flex',
    flex: '1',
    alignItems: 'center',
    flexDirection: 'row-reverse',
  }
})
type HOCProps = {|
  t: Function, // translation function
  classes: Object, // classes-object created by withstyles function
|}

type MappedProps = {|
  application: string, // current application
  calculation: string, // current calculation id
  isEstimateLockedToCurrentUser: $PropertyType<TVDApplicationStore, 'isEstimateLockedToCurrentUser'>, // if the user owns the lock for the estimate
  activeCalculation: boolean, // flag that indicates if calculation is running
  isEstimateFrozen: $PropertyType<TVDApplicationStore, 'isEstimateFrozen'>, // if estimate is frozen
  languageCode: $PropertyType<TVDApplicationStore, 'languageCode'>,
  isRefreshingAccessToken: $PropertyType<TVDApplicationStore, 'isRefreshingAccessToken'>,
|}

type DispatchProps = {|
  dispatchPostPolling: () => void, // postpolling function
  dispatchUpdateSentient: (sentientStoreId: string) => void // update a single sentient component
|}

type ReceivedProps = {|
  id: string, // top level id for test id generation, otherwise generator appends undefined string to id
  disabled: boolean, // flag to disable widget content
|}

type Props = {|
  ...HOCProps,
  ...MappedProps,
  ...DispatchProps,
  ...ReceivedProps
|}

type State = {|
  driver: Object, // api provided inputs for the driver form in FloatingForm, empty to begin with
  columns: Object, // column states for the four columns in the widget
  selected: Object, // currently selected items in the columns
  classificationDropdown: Object, // current selection, items and disabled status for the classification dropdown
  loadingInProgress: boolean, // optimization to disable the columns while fetching data
|}

type ColumnUpdate = {|
  cells?: Array<any>,
  isLoading?: boolean,
  columnName?: string,
  addressingOptions?: Array<any>,
  addressingId?: string
|}

export class CreateActivity extends Component<Props, State> {
  static defaultProps = {
    id: 'create',
    isEstimateLockedToCurrentUser: true,
  }

  state = {
    columns: {
      functionalsector: {
        cells: [],
        isLoading: false,
        columnName: 'functionalsector'
      },
      function: {
        cells: [],
        isLoading: false,
        addressingOptions: [],
        addressingId: '',
        columnName: 'function'
      },
      process: {
        cells: [],
        isLoading: false,
        addressingOptions: [],
        addressingId: '',
        columnName: 'process'
      },
      activityGroup: {
        cells: [],
        isLoading: false,
        addressingOptions: [],
        addressingId: '',
        columnName: 'activityGroup'
      },
    },
    classificationDropdown: {
      items: [],
      selection: undefined,
      disabled: false
    },
    selected: {},
    loadingInProgress: true,
    driver: {},
  }


  _initialColumns: Object

  componentDidMount() {
    this.getAllColumns()
    this.getDropdownContent()
  }

  componentDidUpdate(prevProps: Object) {
    const { languageCode } = this.props
    if (languageCode !== prevProps.languageCode) {
      this.getAllColumns()
      this.getDropdownContent()
    }
  }

  createColumn = (columnName: string) => {
    const { t, activeCalculation } = this.props
    const { columns } = this.state
    const saveColumn = (formName: string) => {
      this.handleSave(columnName, formName)
    }
    const isFunctionalsector = columnName === 'functionalsector'
    const translationKey = `widgets._${columnName.toUpperCase()}_`
    const dropdownMenu = isFunctionalsector ?
      (<DropdownMenu
        disabled={this.state.classificationDropdown.disabled}
        items={this.state.classificationDropdown.items}
        onChange={this.handleDropDownChange}
        defaultValue={this.state.classificationDropdown.selection} />)
      : null

    return (
      <RelationalSelector.Column
        focusingTooltip={`${t(translationKey)} ${t('floatingForm._NOT_SELECTED_')}`}
        cells={columns[columnName]?.cells || []}
        columnName={columnName}
        title={t(translationKey)}
        searchFieldLabel={t('general._SEARCH_')}
        dropdown={dropdownMenu} >
        <RelationalSelector.FloatingForm
          mountCallback={isFunctionalsector ? this.getRegistryDrivers : this.getDriversAndAddressing} >
          <RelationalSelector.Form
            saveDisabled={activeCalculation || (!isFunctionalsector && this.state.columns[columnName].addressingOptions.length < 1)}
            formName='driver'
            formLabel={t('floatingForm._DRIVER_')}
            addButtonLabel={t('floatingForm._ADD_')}
            onSave={saveColumn}>
            { this.driverInputs() }
            { !isFunctionalsector && this.addressingDropdown(columnName) }
          </RelationalSelector.Form>
        </RelationalSelector.FloatingForm>
      </RelationalSelector.Column>
    )
  }

  addressingDropdown = (columnName: string) => {
    const { t } = this.props
    const { addressingOptions, addressingId } = this.state.columns[columnName]
    const firstItem = addressingOptions && addressingOptions.length > 0 ? addressingOptions[0].value : undefined

    const dropdownStateHandler = (id: string) => this.updateColumn(columnName, { addressingId: id })

    return (
      <DropdownMenu
        required
        onChange={dropdownStateHandler}
        title={t('floatingForm.addTo')}
        defaultValue={addressingId || firstItem}
        items={addressingOptions} />
    )
  }

  handleDriverInput = (e: SyntheticInputEvent<any>, inputName: string) => {
    const { value } = e.currentTarget
    this.setState((state: State) => ({
      driver: {
        ...state.driver,
        [inputName]: {
          ...state.driver[inputName],
          value
        }
      }
    }))
  }

  getDriversAndAddressing = (columnName: string, cellID: number) => {
    this.getRegistryDrivers(columnName, cellID)
    this.getAddressingOptions(columnName)
  }

  // FIX Horrible hack that needs to go as soon as possible 3.2.2020
  isCoDriverDisabled = (driverUnit: string) => driverUnit === 'ei käytössä' || driverUnit === 'not in use' || driverUnit === 'Ur bruk'

  handleSave = (columnName: string, formName: string) => {
    const { selected, columns } = this.state
    const driverData = mapValues(this.state[formName], (input: Object) => input.value)

    this.createFromRegistry({
      columnName,
      addressingId: columns[columnName].addressingId,
      cellID: selected[columnName],
      driverData,
    })
  }

  getAllColumns = () => {
    forEach(keys(this.state.columns), (value: string) => {
      this.getColumnCells(value)
    })
  }

  updateColumn = (columnName: string, columnUpdate: ColumnUpdate) => {
    if (!this._initialColumns) this._initialColumns = {}

    this.setState((prevState: State) => ({
      ...prevState,
      columns: {
        ...prevState.columns,
        [columnName]: {
          ...prevState.columns[columnName],
          ...columnUpdate
        }
      }
    }), () => {
      if (!this._initialColumns[columnName]) {
        this._initialColumns[columnName] = this.state.columns[columnName]
      }
    })
  }

  getAddressingOptions = (columnName: string) => {
    const { columns } = this.state
    if (columnName.toUpperCase() === FUNCTIONALSECTOR) return

    const successCb = (enums: Array<TVDEnum>) => {
      const addressingOptions = parseRegistryItems(enums)
      // conditionally adding the new addressingId if needed. otherwise updateColumn will just use the current value
      const newColumn = {
        addressingId: columns[columnName].addressingId || addressingOptions[0]?.value,
        addressingOptions,
      }
      this.updateColumn(columnName, { ...newColumn })
    }
    switch (columnName.toUpperCase()) {
      case FUNCTION: getRegistryFunctionsCanAttachToRequest({}, successCb); break
      case PROCESS: getRegistryProcessesCanAttachToRequest({}, successCb); break
      case ACTIVITYGROUP: getRegistryActivityGroupsCanAttachToRequest({}, successCb); break
      default: {
        console.error(`Could not get attach to options with column name ${columnName}`)
        break
      }
    }
  }

  /** Checks if even one column, except the specified one, is in loading state. */
  areOtherColumnsLoading = (columnName: string) => {
    const { columns } = this.state
    const columnLoadingStatuses = map(columns, (column: Object) => {
      if (columnName === column.columnName) return false
      return column.isLoading
    })
    return columnLoadingStatuses.some((isLoading: boolean) => isLoading)
  }


  getColumnCells = (columnName: string) => {
    const { selected } = this.state
    const callback = (cells: Array<Object>) => {
      this.setState((state: Object) => ({
        ...state,
        loadingInProgress: this.areOtherColumnsLoading(columnName),
        columns: { ...state.columns, [columnName]: { ...state.columns[columnName], isLoading: false } }
      }), () => {
        this.updateColumn(columnName, { cells })
      })
    }

    const queryItems = {
      classificationId: this.state.classificationDropdown.selection,
      functionalsectorId: selected.functionalsector,
      processId: selected.process,
      activityGroupId: selected.activityGroup,
      functionId: selected.function,
    }

    const query = { ...omit(queryItems, `${columnName}Id`), selectedId: selected[columnName] }

    switch (columnName) {
      case 'function':
        getRegistryFunctionsRequest({ query }, {}, callback)
        break
      case 'functionalsector':
        getRegistryFunctionalSectorsRequest({ query }, {}, callback)
        break
      case 'process':
        getRegistryProcessesRequest({ query }, {}, callback)
        break
      case 'activityGroup':
        getRegistryActivityGroupsRequest({ query }, {}, callback)
        break
      default:
        break
    }
  }

  getDropdownContent = () => {
    runGAR({
      ...getEnumGARDefinition(basePath, 'buildingclassification'),
      successCb: (enums: Array<TVDEnum>) => {
        this.setClassificationDropdown({ items: enums })
      }
    })
  }

  setClassificationDropdown = (newStateSlice: Object) => {
    this.setState((prevState: State) => ({ classificationDropdown: { ...prevState.classificationDropdown, ...newStateSlice } }))
  }

  /**
   * Sets CreateActivity to initial state
   */
  clearAll = () => {
    this.setState((state: Object) => ({
      ...state,
      classificationDropdown: {
        ...state.classificationDropdown,
        selection: undefined,
        disabled: false,
      },
      selected: {},
      addressingOptions: [],
      addressingId: undefined
    }), () => {
      this.getAllColumns()
    })
  }

  createFromRegistry = ({
    columnName,
    addressingId,
    driverData,
    cellID,
  }: Object) => {
    const { dispatchUpdateSentient, dispatchPostPolling } = this.props
    if (addressingId || columnName === 'functionalsector') {
      const itemArguments = {
        path: { itemId: addressingId },
        body: { ...driverData },
        query: { registryId: cellID }
      }

      const aftermath = () => {
        this.getAddressingOptions(columnName)
        dispatchPostPolling()
        dispatchUpdateSentient(ACTIVITY_STRUCTURE)
      }

      switch (columnName) {
        case 'functionalsector':
          postActivityScheduleFunctionalSectorsWithEstimateIdRequest(omit(itemArguments, 'path'), {}, aftermath)
          break
        case 'function':
          postActivityScheduleFunctionsWithItemIdRequest(itemArguments, {}, aftermath)
          break
        case 'process':
          postActivityScheduleProcessesWithItemIdRequest(itemArguments, {}, aftermath)
          break
        case 'activityGroup':
          postActivityScheduleActivityGroupsWithItemIdRequest(itemArguments, {}, aftermath)
          break
        default: console.error(`No createFromRegistry switch case for column: ${columnName}`)
      }
    }
  }

  handleDropDownChange = (selectionValue: string) => {
    this.setState((state: Object) => ({
      classificationDropdown: {
        ...state.classificationDropdown,
        selection: selectionValue
      }
    }), this.getAllColumns)
  }

  handleSelection = (selected: Object) => {
    const { selected: prevSelected } = this.state
    if (!isEqual(prevSelected, selected)) {
      this.setState((state: Object) => ({ ...state, selected, loadingInProgress: true }), () => {
        this.getAllColumns()
        // somehing was selected and the classificationDropdown isn't disabled so disable it
        if (!this.state.classificationDropdown.disabled) this.setClassificationDropdown({ disabled: true })
        // last item was unchecked (selected returned as an object full of undefined properties), enable the classificationDropdown again
        if (every(values(selected), (item: any) => !item)) this.setClassificationDropdown({ disabled: false })
      })
    }
  }

  parseDrivers = (drivers: Array<Object>) => reduce(drivers, (result: Object, driver: Object) => ({ ...result, [driver.propertyName]: driver }), {})

  getRegistryDrivers = (columnName: string, cellId: number) => {
    const setDrivers = (parsedResponse: Object) => {
      this.setState({
        driver: this.parseDrivers(parsedResponse.drivers),
      })
    }

    const parameters = [
      { path: { id: cellId } },
      {},
      (parsedResponse: Object) => setDrivers(parsedResponse)
    ]

    switch (columnName) {
      case 'functionalsector':
        getRegistryFunctionalSectorsDriversWithIdRequest(...parameters)
        break
      case 'function':
        getRegistryFunctionsDriversWithIdRequest(...parameters)
        break
      case 'process':
        getRegistryProcessesDriversWithIdRequest(...parameters)
        break
      case 'activityGroup':
        getRegistryActivityGroupsDriversWithIdRequest(...parameters)
        break
      default:
        console.error(`No switch case found for columnName: "${columnName}"`)
    }
  }

  disabled(): boolean {
    const {
      disabled,
      isEstimateLockedToCurrentUser,
      activeCalculation,
      isEstimateFrozen,
      isRefreshingAccessToken
    } = this.props
    return disabled ||
      !isEstimateLockedToCurrentUser ||
      activeCalculation ||
      !!isEstimateFrozen ||
      isRefreshingAccessToken
  }

  driverInputs(): Array<LabeledInput> {
    const { t } = this.props
    const { driver } = this.state
    const driverTranslations = {
      [DRIVER]: t('floatingForm.sizing'),
      [CODRIVER]: t('floatingForm.secondarySizing')
    }
    return map(driver, (input: Object, index: number) => {
      // FIX Horrible hack for a horrible way to indicate an input is disabled
      const disabledCoDriver = Boolean(input.propertyName === 'CoDriver' && this.isCoDriverDisabled(input.unit))

      return (
        <LabeledInput
          key={index}
          dataType={input.dataType}
          disabled={disabledCoDriver}
          required
          number
          id={`${input.propertyName}Field`}
          size='M'
          handleChange={(e: SyntheticInputEvent<any>) => this.handleDriverInput(e, input.propertyName)}
          label={disabledCoDriver ? '' : driverTranslations[input.propertyName]}
          value={disabledCoDriver ? '' : input.value}
          helperText={input.unit} />
      )
    })
  }

  render(): React$Element<any> {
    const {
      classes,
      t,
      id
    } = this.props

    return (
      <div className={classes.content} data-testid={id}>
        <RelationalSelector
          clearButtonLabel={t('widgets._CLEAR_SELECTIONS_')}
          onChange={this.handleSelection}
          isLoading={this.state.loadingInProgress}
          disabled={this.disabled()}
          onAllClear={this.clearAll} >
          { this.createColumn('functionalsector') }
          { this.createColumn('function') }
          { this.createColumn('process') }
          { this.createColumn('activityGroup') }
        </RelationalSelector>
      </div>
    )
  }
}

const mapStateToProps = ({ app }: TVDReduxStore): MappedProps => {
  const {
    isEstimateLockedToCurrentUser,
    activeCalculation,
    application,
    calculation,
    isEstimateFrozen,
    languageCode,
    isRefreshingAccessToken
  } = app

  return {
    application,
    isEstimateLockedToCurrentUser,
    activeCalculation,
    calculation,
    isEstimateFrozen,
    languageCode,
    isRefreshingAccessToken
  }
}

function mapDispatchToProps(dispatch: Function): Object {
  return {
    dispatchPostPolling: () => { dispatch(postPolling()) },
    dispatchUpdateSentient: (sentientStoreId: string) => { dispatch(updateSentient(sentientStoreId)) }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('translations')(withStyles(styles)(CreateActivity)))
