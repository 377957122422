// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.
import { TVDSelectedAccountId, updateSelectedAccountIdActionTypes } from '../constants/localPersistence'
import { setLocalStorage } from '../utils/commonUtils'

type TVDPersistParameters = {|
  action: TVDAction, // action that is being executed
  getFromStore: (store: TVDReduxStore) => any, // getting data to persist from updated store
  store: TVDReduxStore, // store that is passed to getFromStore,
  persistenceKey: string, // key that will be used to persist the data e.g key for localStorage for localStorage
|}

export const persist = ({
  action,
  getFromStore,
  store,
  persistenceKey
}: TVDPersistParameters) => {
  const { type } = action

  let persistData

  try {
    persistData = getFromStore(store)
  } catch (er) {
    console.error(`Could not get data to persist. persistenceKey: ${persistenceKey} actionType: ${type}`, er)
  }

  if (persistData) {
    setLocalStorage(persistenceKey, persistData)
  }
}


export default ({ getState }: TVDMiddlewareStore): Function => (next: Function): Function => (action: TVDAction) => {
  if (action) {
    next(action)
    const { type } = action

    switch (true) {
      case updateSelectedAccountIdActionTypes.includes(type): {
        persist({
          action,
          getFromStore: ({ app: { selectedAccountId } }: TVDReduxStore): string | typeof undefined => selectedAccountId,
          store: getState(),
          persistenceKey: TVDSelectedAccountId
        })
        break
      }
      default: break
    }
  }
}
