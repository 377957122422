// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.

import {
  STORE_PATCH_OPERATION,
  STORE_PATCH_OPERATION_PARAMETER,
  CLEAR_ALL_PATCH_OPERATIONS,
  CLEAR_PATCH_OPERATIONS,
  CLEAR_PATCH_OPERATION_PARAMETERS,
  CLEAR_PATCH_OPERATION_WITH_RESOURCE_ID,
  CLEAR_PATCH_OPERATION_PARAMETER
} from '../actions/patchOperations'
import {
  removeExistingPatchOperationParametersFn,
  getExistingPatchOperations,
  clearMatchingOperationParametersByType
} from '../utils/patchOperationUtil'
import { TVD_PATCH_OPERATION_TYPE_UPDATE, TVD_PATCH_OPERATION_TYPE_RESET } from '../constants/patchOperationConstants'

const initialState: TVDPatchOperationsStore = {}

export default function patchOperationsReducer(state: TVDPatchOperationsStore = initialState, action: TVDAction): TVDPatchOperationsStore {
  switch (action.type) {
    case STORE_PATCH_OPERATION: {
      const {
        payload: {
          patchOperationStoreId,
          patchOperation,
          storePatchOperationParameterOptions: {
            removeExistingPatchOperationParameters,
            removeExistingPatchOperationParametersBeginningWith
          } = {}
        }
      } = action
      const { [patchOperationStoreId]: { [patchOperation.resourceId]: existingOperations = [] } = {} } = state
      let newPatchOperations = JSON.parse(JSON.stringify(existingOperations))
      const { existingOperationIndex, existingOperation } = getExistingPatchOperations(newPatchOperations, patchOperation.operationType)
      let updatedOperation = {
        ...patchOperation,
        operationParameters: {
          ...(existingOperation ? existingOperation.operationParameters : {}),
          ...patchOperation.operationParameters,
        }
      }
      if (removeExistingPatchOperationParametersBeginningWith) {
        updatedOperation = removeExistingPatchOperationParametersFn(updatedOperation, removeExistingPatchOperationParametersBeginningWith)
      }
      if (removeExistingPatchOperationParameters) {
        updatedOperation = {
          ...updatedOperation,
          operationParameters: patchOperation.operationParameters
        }
      }
      if (typeof existingOperationIndex !== 'undefined') {
        newPatchOperations.splice(existingOperationIndex, 1, updatedOperation)
      } else {
        newPatchOperations.push(updatedOperation)
      }
      if (updatedOperation.operationType === TVD_PATCH_OPERATION_TYPE_UPDATE) {
        newPatchOperations = clearMatchingOperationParametersByType(updatedOperation, newPatchOperations, TVD_PATCH_OPERATION_TYPE_RESET)
      }
      return {
        ...state,
        [patchOperationStoreId]: {
          ...(state[patchOperationStoreId] || {}),
          [patchOperation.resourceId]: newPatchOperations.length > 0 ? newPatchOperations : [updatedOperation]
        }
      }
    }
    case STORE_PATCH_OPERATION_PARAMETER: {
      const {
        payload: {
          patchOperationStoreId,
          patchOperationParameterObject,
          storePatchOperationParameterOptions: {
            removeExistingPatchOperationParametersBeginningWith
          } = {}
        }
      } = action
      const { resourceId, operationType, parameter } = patchOperationParameterObject
      const { [patchOperationStoreId]: { [resourceId]: existingOperations = [] } = {} } = state
      const newPatchOperations = JSON.parse(JSON.stringify(existingOperations))
      const {
        existingOperationIndex,
        existingOperation
      } = getExistingPatchOperations(newPatchOperations, patchOperationParameterObject.operationType)
      let updatedOperation = existingOperation
      if (removeExistingPatchOperationParametersBeginningWith && updatedOperation) {
        updatedOperation = removeExistingPatchOperationParametersFn(updatedOperation, removeExistingPatchOperationParametersBeginningWith)
      }
      updatedOperation = updatedOperation ? {
        ...updatedOperation,
        operationParameters: {
          ...updatedOperation.operationParameters,
          ...patchOperationParameterObject.parameter,
        }
      } : {
        resourceId,
        operationType,
        operationParameters: parameter
      }
      if (typeof existingOperationIndex !== 'undefined') {
        newPatchOperations.splice(existingOperationIndex, 1, updatedOperation)
      } else {
        newPatchOperations.push(updatedOperation)
      }
      return {
        ...state,
        [patchOperationStoreId]: {
          ...(state[patchOperationStoreId] || {}),
          [resourceId]: newPatchOperations
        }
      }
    }

    case CLEAR_PATCH_OPERATION_PARAMETERS: {
      const {
        payload: {
          patchOperationStoreId,
          resourceId,
          operationType
        }
      } = action
      const { [patchOperationStoreId]: { [resourceId]: existingOperations = [] } = {} } = state
      const newPatchOperations = JSON.parse(JSON.stringify(existingOperations))
      const {
        existingOperationIndex,
        existingOperation
      } = getExistingPatchOperations(newPatchOperations, operationType)
      if (existingOperation) {
        const updatedOperation = { ...existingOperation, operationParameters: {} }
        if (typeof existingOperationIndex !== 'undefined') {
          newPatchOperations.splice(existingOperationIndex, 1, updatedOperation)
        }
        return {
          ...state,
          [patchOperationStoreId]: {
            ...state[patchOperationStoreId],
            [resourceId]: newPatchOperations
          }
        }
      }
      return state
    }
    case CLEAR_ALL_PATCH_OPERATIONS: {
      return {}
    }
    case CLEAR_PATCH_OPERATIONS: {
      const { payload: { patchOperationStoreId } } = action
      const { [patchOperationStoreId]: clearedPatchOperations, ...restOfPatchOperations } = state
      return { ...(restOfPatchOperations || {}) }
    }
    case CLEAR_PATCH_OPERATION_WITH_RESOURCE_ID: {
      const {
        payload: {
          patchOperationStoreId,
          patchOperationResourceId
        }
      } = action
      const {
        [patchOperationStoreId]: {
          [patchOperationResourceId]: patchOperation
        } = {}
      } = state
      if (patchOperation) {
        const { [patchOperationResourceId]: patchOperationToExclude, ...restOfPatchOperations } = state[patchOperationStoreId]
        return {
          ...state,
          [patchOperationStoreId]: {
            ...restOfPatchOperations
          }
        }
      }
      return state
    }
    case CLEAR_PATCH_OPERATION_PARAMETER: {
      const {
        payload: {
          patchOperationStoreId,
          resourceId,
          parameterPath,
        }
      } = action
      const { [patchOperationStoreId]: { [resourceId]: existingOperations = [] } = {} } = state
      // creating deep clone with parse - stringify to not refer to object instances in state
      // we loop all patch operations of a the resource to make sure all operation types are cleared from the operation parameter we want to remove
      const newPatchOperations = JSON.parse(JSON.stringify(existingOperations))
        .reduce((patchOperations: Array<TVDPatchOperation>, operation: TVDPatchOperation) => {
          // accessing a single operation parameters
          const { operationParameters } = operation
          const newOperationParameters = Object
            // operationParameters are key/value paris so all the keys are the paths we want to check for a match
            .keys(operationParameters)
            .reduce((result: TVDPatchOperationParameters, existingParameterPath: string): TVDPatchOperationParameters => {
              // we exclude existing parameters which have same path start
              // excluding with "startsWith" ensures that we also get rid of the parameter's properties, i.e slider values
              if (existingParameterPath.startsWith(parameterPath)) {
                return result
              }
              // returning back the operation parameters that we want to keep
              return {
                ...result,
                [existingParameterPath]: operationParameters[existingParameterPath]
              }
            }, {})
            // we exclude the whole patch operation if it ends up not having any operation parameters
          if (Object.keys(newOperationParameters).length === 0) return patchOperations
          // we replace the existing parameters with the new reduced parameters if there is at least one parameter
          return [...patchOperations, { ...operation, operationParameters: newOperationParameters }]
        }, [])
      return {
        ...state,
        [patchOperationStoreId]: {
          ...state[patchOperationStoreId],
          [resourceId]: newPatchOperations
        }
      }
    }
    default: {
      return state
    }
  }
}
