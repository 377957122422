// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.

import { map } from 'lodash'
import Axios from 'axios'
import { buildConfirmationModal } from '../modals'
import { getApiUrl } from '../../utils/apiUtils'
import { PLANNING, WOP_SPACE_SCHEDULE } from '../../constants/contentTypes'
import { postPolling } from '../postPolling'
import { clearList, clearActivityFilters } from '../list'
import { clearDesignModel } from './designModelWidget'
import { type ClearableWidgetIds } from '../../components/containers/widgets/Widget'
import { ALLOW_WITH_CREDENTIALS } from '../../constants/envConstants'


export const OPEN_CONTENT_WIDGET = 'OPEN_CONTENT_WIDGET'
export function openContentWidget({
  contentProps,
  widgetTitle,
  widgetId,
  widgetLevelChild,
  widgetType,
  widgetResultBarStoreSource,
  widgetEnableAllWidgetsOnClose,
  widgetResultBarFormatOptions,
  widgetPreferXYComponentPreferences,
  widgetPreferSizeComponentPreferences
}: TVDOpenContentWidgetArguments): Object {
  return {
    type: OPEN_CONTENT_WIDGET,
    payload: {
      contentProps,
      widgetTitle,
      widgetId,
      widgetType,
      widgetLevelChild,
      widgetResultBarStoreSource,
      widgetEnableAllWidgetsOnClose,
      widgetResultBarFormatOptions,
      widgetPreferXYComponentPreferences,
      widgetPreferSizeComponentPreferences
    }
  }
}

export const CLOSE_WIDGET_SUCCESSFUL = 'CLOSE_WIDGET_SUCCESSFUL'
export function closeWidgetSuccessful(widgetId: string): Object {
  return {
    type: CLOSE_WIDGET_SUCCESSFUL,
    payload: {
      widgetId,
    }
  }
}

export const ENABLE_ALL_WIDGETS = 'ENABLE_ALL_WIDGETS'
export function enableAllWidgets(): Object {
  return {
    type: ENABLE_ALL_WIDGETS,
  }
}

export function closeWidget(widgetId: string): Object {
  return (dispatch: Function, getState: Function): Object | Function => {
    const {
      widgets: { [widgetId]: { modified = false, widgetEnableAllWidgetsOnClose } },
      app: { activeEdit, activeCalculation }
    } = getState()
    if (widgetEnableAllWidgetsOnClose) {
      dispatch(enableAllWidgets())
    }

    if (!modified) {
      return dispatch(closeWidgetSuccessful(widgetId))
    }

    if (activeCalculation) {
      return dispatch(closeWidgetSuccessful(widgetId))
    }

    return dispatch(buildConfirmationModal({
      onSave: () => {
        if (activeEdit || widgetEnableAllWidgetsOnClose) { dispatch(enableAllWidgets()) }
        dispatch(closeWidgetSuccessful(widgetId))
      }
    }))
  }
}

export const CLEAR_PROPERTIES = 'CLEAR_PROPERTIES'
export function clearProperties(resourceId: string): Object {
  return {
    type: CLEAR_PROPERTIES,
    payload: {
      listId: resourceId
    }
  }
}

export function clearWidgetData(ids: ClearableWidgetIds): Object {
  return (dispatch: Function, getState: Function): Object | Function => {
    const { properties, list } = getState()
    const {
      resourceId,
      propertiesStoreId,
      resourceListId,
      activityStructureId,
    } = ids

    if (resourceId && properties[resourceId]) dispatch(clearProperties(resourceId))
    if (resourceListId && properties[resourceListId]) dispatch(clearProperties(resourceListId))
    if (propertiesStoreId && properties[propertiesStoreId]) dispatch(clearProperties(propertiesStoreId))
    if (resourceId && list[resourceId]) dispatch(clearList(resourceId))
    if (resourceId === PLANNING) dispatch(clearDesignModel())
    if (resourceId === WOP_SPACE_SCHEDULE && activityStructureId) dispatch(clearActivityFilters())
  }
}

export const EDIT_PROPERTY = 'EDIT_PROPERTY'
export function editProperty(
  propertiesStoreId: string,
  property: string,
  value: string | number | boolean,
): Object {
  return {
    type: EDIT_PROPERTY,
    payload: {
      propertiesStoreId,
      property,
      value,
    }
  }
}

export const UNDO_EDIT_PROPERTY = 'UNDO_EDIT_PROPERTY'
export function undoEditProperty(
  propertiesStoreId: string,
  property: string,
): Object {
  return {
    type: UNDO_EDIT_PROPERTY,
    payload: {
      propertiesStoreId,
      property,
    }
  }
}

export const PROPERTY_RESET_DEFAULT = 'PROPERTY_RESET_DEFAULT'
export function propertyResetDefault(propertiesStoreId: string, property: string, tab: string, widgetId: string): Object {
  return {
    type: PROPERTY_RESET_DEFAULT,
    payload: {
      propertiesStoreId,
      property,
      tab,
      widgetId
    }
  }
}

export const MARK_AS_MODIFIED = 'MARK_AS_MODIFIED'
export function markAsModified(widgetId: string, tab: string = ''): Object {
  return {
    type: MARK_AS_MODIFIED,
    payload: { widgetId, tab }
  }
}

export const TOGGLE_WIDGET_MODIFIED = 'TOGGLE_WIDGET_MODIFIED'
export function toggleWidgetModified(widgetId: string, tab?: string, isModified: boolean): Object {
  return {
    type: TOGGLE_WIDGET_MODIFIED,
    payload: { widgetId, tab, isModified }
  }
}

export const UPDATE_WIDGET_TITLE = 'UPDATE_WIDGET_TITLE'
export function updateWidgetTitle(widgetId: string, contentProps: Object): Object {
  return {
    type: UPDATE_WIDGET_TITLE,
    payload: { widgetId, widgetTitle: contentProps.widgetTitle }
  }
}

export function deleteCalculationPropertiesEquipments(list: Array<string>): Function {
  return (dispatch: Function, getState: Function) => {
    const { app: { application, calculation } } = getState()
    const url = getApiUrl(application)
    Axios.all(map(list, (id: string) => Axios.delete(`${url}/estimate/${calculation}/equipment/${id}`, {
      ...(ALLOW_WITH_CREDENTIALS ? { withCredentials: true } : {})
    }))).then(() => dispatch(postPolling()))
  }
}

export const SET_SELECTED_TAB = 'SET_SELECTED_TAB'
export function setSelectedTab(tab: string, widgetId: string): Object {
  return {
    type: SET_SELECTED_TAB,
    payload: { tab, widgetId }
  }
}

export const CLEAR_WIDGETS = 'CLEAR_WIDGETS'
export function clearWidgets(): Object {
  return {
    type: CLEAR_WIDGETS,
  }
}

export const SET_DROPDOWN_CONTENTS = 'SET_DROPDOWN_CONTENTS'
export function setDropdownContents(data: Object, resourceId: string, widgetId: string): Object {
  return {
    type: SET_DROPDOWN_CONTENTS,
    payload: { data, resourceId, widgetId }
  }
}

export const SET_MULTIPLE_EDIT_PROPERTIES = 'SET_MULTIPLE_EDIT_PROPERTIES'
export function setMultipleEditProperties(data: Object, resourceId: string): Object {
  return {
    type: SET_MULTIPLE_EDIT_PROPERTIES,
    payload: { data, resourceId }
  }
}


export const SET_WIDGET_CONTENT_PROPS = 'SET_WIDGET_CONTENT_PROPS'
export function setWidgetContentProps(widgetId: string, contentProps: Object): Object {
  return {
    type: SET_WIDGET_CONTENT_PROPS,
    payload: { widgetId, contentProps }
  }
}

export const SET_PROPERTY_OPTIONS = 'SET_PROPERTY_OPTIONS'
export function setPropertyOptions(propertiesStoreId: string, propertyName: string, options: Array<TVDEnum>): Object {
  return {
    type: SET_PROPERTY_OPTIONS,
    payload: { propertiesStoreId, propertyName, options }
  }
}
