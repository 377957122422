// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.

import React, { Component } from 'react'
import { connect, batch } from 'react-redux'
import { compose } from 'redux'
import { withStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'
import { filter, find, isEmpty, omitBy, includes, map, reduce } from 'lodash'

import Icon from '@material-ui/core/Icon'
import SentientHOC from '../../../../hocs/SentientHOC/SentientHOC'
import FeaturesHOC from '../../../../hocs/FeaturesHOC/FeaturesHOC'
import HamburgerMenu from '../../../../common/menus/HamburgerMenu/HamburgerMenu'
import DescriptionCell from '../../../../common/lists/common/DescriptionCell/DescriptionCell'
import MatrixListContainer from '../../../MatrixListContainer/MatrixListContainer'
import Menu from '../../../../common/menus/Menu/Menu'
import DropdownInput from '../../../../common/menus/components/DropdownInput/DropdownInput'

import { RENOVATION_SPACES_TAB, matrixListHeaderHeight } from '../../../../../constants/moduleConstants'
import { RENOVATION_PROFILE_ID, USER_MODIFIED } from '../../../../../constants/attributes'
import { setSelectedTab } from '../../../../../actions/widgets'
import { closeModal, openModal } from '../../../../../actions/modals'
import { buildRenovationProfile, modifyListItem, type TVDModifyListItemPayload, setRenovationProfile } from '../../../../../actions/list'
import { setWidgetActive } from '../../../../../actions/app'
import {
  ADD_RENOVATION_PROFILE_MODAL,
  COPY_RENOVATION_PROFILE_MODAL,
  SPACE,
  SPACEGROUP,
  FUNCTION,
  FUNCTIONALSECTOR
} from '../../../../../constants/contentTypes'
import { traverseListItem, getUserModifiedDataFromColumnMeta, traverseListItems } from '../../../../../utils/listUtils'
import {
  getRenovationSpacesWithEstimateIdRequestDefinitions,
  getRenovationSpacesMeasureTopicsWithEstimateIdRequestDefinitions,
  getRenovationSpacesProfilesWithEstimateIdRequest,
  getRenovationSpacesProfilesPropertiesWithProfileIdRequest
} from '../../../../../utils/generated-api-requests/spaces_renovation'
import {
  patchOperationRenovationProfileBasePath,
  TVD_PATCH_OPERATION_TYPE_RESET,
  TVD_PATCH_OPERATION_TYPE_DELETE
} from '../../../../../constants/patchOperationConstants'
import { createPatchOperation, isTypeAllowedForPatchOperations } from '../../../../../utils/patchOperationUtil'
import { storePatchOperation, clearPatchOperationWithResourceId, clearPatchOperationParameter } from '../../../../../actions/patchOperations'

import HALParser from '../../../../../utils/HALParser'

const styles = ({ palette }: TVDTheme): Object => ({
  RenovationSpacesWrapper: {
    display: 'flex',
    flexGrow: 1,
    height: '100%'
  },
  headerRowContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    marginRight: 2,
    width: '100%',
    outline: 0,
  },
  tablePaddingTop: {
    overflow: 'hidden',
    '& table': {
      overflow: 'visible'
    },
    '& thead > tr': {
      height: matrixListHeaderHeight
    },
  },
  descriptionCell: {
    minWidth: '200px !important',
  },
  dropdownIcon: {
    color: palette.cadetBlue
  },
  overrideInput: {
    '& input, span': {
      cursor: 'pointer'
    }
  }
})

type HOCProps = {|
  classes: Object, // withStyles classes object
  features: TVDFeatureHOCProps, // features from Store and helper functions from the HOC
  sentient: TVDSentient, // Object providing helpers via SentientHOC
  t: Function, // withTranslation translate function
|}

type ReceivedProps = {|
  disabled: boolean, // flag to disable widget content if other widgets are beign modified
  listStoreId: string, // unique id for the HLC instance in "list" ReduxStore
  widgetId: string, // id of widget
  widgetTab: string, // widget tab name where the component is located in
|}

type MappedProps = {|
  columns: Array<TVDListItemColumn>,
  listItems: TVDListItems, // list items found with from Store with listStoreId
  patchOperations: TVDPatchOperations, // patch operations retrieved via a resourceId
|}

type DispatchProps = {|
  dispatchSetSelectedTab: Function, // set renovation spaces tab as selected
  dispatchOpenAddRenovationProfileModal: Function, // function to open addBuildingElements modal
  dispatchSetRenovationProfile: (Object, string) => void, // action to set renovation profile data for row
  dispatchOpenCopyRenovationProfileModal: Function, // function to copy addBuildingElements modal
  dispatchModifyListItem: (ModifyListItemPayload: TVDModifyListItemPayload) => void, // modifies the list item
  dispatchStorePatchOperation: (patchOperation: TVDPatchOperation) => void, // stores a patch operation to Store
  dispatchClearPatchOperationWithResourceId: (patchOperationResourceId: string) => void, // clears a specific patch operation with resourceId
  dispatchClearPatchOperationParameter: (resourceId: string, parameterPath: string) => void, // clears specific patch operation parameter from Store
  dispatchSetWidgetActive: () => void, // sets renovation spaces as active
|}

type Props = {|
  ...ReceivedProps,
  ...MappedProps,
  ...DispatchProps,
  ...HOCProps,
|}

type State = {|
  renovationProfiles: Array<TVDMenuItem>
|}

export class RenovationSpaces extends Component<Props, State> {
  state = {
    renovationProfiles: []
  }

  componentDidMount() {
    this.props.dispatchSetSelectedTab()
    this.props.sentient.runSetup()
    this.getRenovationProfiles()
  }

  deleteProfileAndMeasures = (row: TVDListItem) => {
    const {

      dispatchStorePatchOperation,
      dispatchSetWidgetActive,
      dispatchModifyListItem,
      listStoreId,
    } = this.props
    const { columnData } = row
    if (columnData !== undefined) {
      Object.keys(columnData).forEach((propertyName: string) => {
        if (propertyName !== 'Description' && propertyName !== 'AreaTotalM2') {
          dispatchModifyListItem({
            listId: listStoreId,
            listItemId: row.id,
            columnName: propertyName,
            value: ''
          })
        }
      })
    }

    const patchOperation = createPatchOperation({
      resourceId: row.id,
      value: '',
      basePath: 'columnData/',
      operationType: TVD_PATCH_OPERATION_TYPE_DELETE,
    })
    batch(() => {
      dispatchStorePatchOperation(patchOperation)
      dispatchSetWidgetActive()
    })
  }

  getRenovationProfiles = () => {
    getRenovationSpacesProfilesWithEstimateIdRequest({}, (parsedResponse: Array<TVDMenuItem>) => {
      this.setState({ renovationProfiles: parsedResponse })
    })
  }

  getWrappedCellContents = (): TVDWrappedCells => {
    const { classes } = this.props
    const { renovationProfiles } = this.state

    return {
      Description: ({ content, row }: TVDWrappedCellCallbackParameters): React$Element<'div'> => (
        <div className={classes.headerRowContainer}>
          <DescriptionCell highlighted={row.level > 1} text={content} />
          <HamburgerMenu visibleOnHover id={row.id} items={this.getContextMenuItems(row)} />
        </div>
      ),
      RenovationProfileId: ({ content, row }: TVDWrappedCellCallbackParameters): React$Element<typeof Menu> => (
        <Menu id={row.id} items={this.getRenovationProfileColumnItems(row)}>
          {
            row.type === SPACE.toLowerCase() ?
              <div className={classes.overrideInput}>
                <DropdownInput
                  value={!isEmpty(renovationProfiles) && content ? find(this.state.renovationProfiles, { value: content }).localizedName : ''}
                  endAdornment={<Icon className={classes.dropdownIcon}>arrow_drop_down</Icon>}
                  placeholderMode
                  minimalist
                  readOnly />
              </div>
            : <div />
          }
        </Menu>

      ),
    }
  }

  getSimpleHierarchicalListColumns = () => {
    const { t } = this.props
    return [
      { propertyName: 'Description', localizedName: t('renovationColumns._DESCRIPTION_'), dataType: 'integer' },
      { propertyName: 'AreaTotalM2', localizedName: t('renovationColumns._AREA_TOTAL_'), dataType: 'number' },
      { propertyName: 'RenovationProfileId', localizedName: t('renovationColumns._RENOVATION_PROFILE_'), dataType: 'integer' }
    ]
  }

  resetRenovationMeasuresDefaultValues = (listItem: TVDListItem) => {
    const {
      id,
      columnData,
      modifiedColumnData = {}
    } = listItem
    const profileId = modifiedColumnData.RenovationProfileId || columnData.RenovationProfileId
    const {
      listStoreId,
      dispatchStorePatchOperation,
      dispatchClearPatchOperationParameter,
      dispatchModifyListItem,
      dispatchClearPatchOperationWithResourceId,
      dispatchSetWidgetActive,
      columns,
    } = this.props
    getRenovationSpacesProfilesPropertiesWithProfileIdRequest({
      path: { profileId }
    }, {}, (properties: Array<TVDPropertiesListItem>) => {
      const resetPatchOperation = createPatchOperation({
        resourceId: id,
        value: profileId,
        operationType: TVD_PATCH_OPERATION_TYPE_RESET,
        basePath: 'columnData/RenovationProfileId'
      })
      batch(() => {
        dispatchSetWidgetActive()
        columns.forEach((column: TVDListItemColumn) => {
          const { measures: [{ value: noMeasureOption }] } = HALParser.getEmbedded(column)
          dispatchModifyListItem({
            value: noMeasureOption,
            columnName: column.propertyName,
            listId: listStoreId,
            listItemId: id,
            options: {
              showModifiedIndicator: false,
            }
          })
        })
        dispatchClearPatchOperationWithResourceId(id)
        dispatchStorePatchOperation(resetPatchOperation)
        properties.forEach((property: TVDPropertiesListItem) => {
          const { propertyName, value } = property
          dispatchClearPatchOperationParameter(id, `columnData/${propertyName}`)
          dispatchModifyListItem({
            value,
            columnName: propertyName,
            listId: listStoreId,
            listItemId: id,
            options: {
              showModifiedIndicator: false,
            }
          })
        })
      })
    })
  }

  getIsResetMeasureDefaultsDisabled = (row: TVDListItem): boolean => {
    const {
      id,
      columnMeta = {},
      showModifiedIndicatorColumns = {},
      columnData = {},
      modifiedColumnData = {}
    } = row

    const profileId = columnData[RENOVATION_PROFILE_ID] || modifiedColumnData[RENOVATION_PROFILE_ID]
    if (!profileId) return true

    const { patchOperations: { [id]: resourcePatchOperations = [] } = {} } = this.props
    const hasModifiedIndicator = Object.keys(showModifiedIndicatorColumns)
      .find((columnKey: string): boolean => !!showModifiedIndicatorColumns[columnKey])
    if (!hasModifiedIndicator) {
      const hasRenovationProfileIdReset = resourcePatchOperations.find(({ operationType, operationParameters }: TVDPatchOperation): boolean => {
        if (operationType === TVD_PATCH_OPERATION_TYPE_RESET) {
          return Boolean(Object.keys(operationParameters).find((operationParameterKey: string): boolean =>
            operationParameterKey === `columnData/${RENOVATION_PROFILE_ID}`))
        }
        return false
      })
      if (hasRenovationProfileIdReset) return true
      const hasUserModifiedMetaColumnData = Object.keys(columnMeta)
        .find((columnMetaKey: string): boolean => !!columnMeta[columnMetaKey][USER_MODIFIED])
      return !hasUserModifiedMetaColumnData
    }

    return false
  }

  getRenovationProfileColumnItems(row: TVDListItem): Array<TVDMenuItem> {
    const {
      t,
      dispatchOpenAddRenovationProfileModal,
      dispatchOpenCopyRenovationProfileModal,
      listItems,
      dispatchSetRenovationProfile,
      dispatchStorePatchOperation,
      dispatchSetWidgetActive
    } = this.props

    const menuItems: Array<TVDMenuItem> = [
      {
        localizedName: t('renovation._SET_RENOVATION_PROFILE_'),
        onClick: () => { dispatchOpenAddRenovationProfileModal(row) },
        testId: 'MenuItem-set-renovation-profile'
      },
      {
        disabled: !row.columnData.RenovationProfileId,
        localizedName: t('renovation._COPY_PROFILE_'),
        onClick: () => {
          dispatchOpenCopyRenovationProfileModal(listItems, row, true)
        },
        testId: 'MenuItem-copy-profile',
      },
      {
        disabled: !row.columnData.RenovationProfileId,
        localizedName: t('renovation._DELETE_PROFILE_'),
        onClick: () => {
          const { columnData: { RenovationProfileId } } = row
          if (RenovationProfileId) {
            // get renovation profile values with rows RenovationProfileId
            getRenovationSpacesProfilesPropertiesWithProfileIdRequest({
              path: { profileId: RenovationProfileId }
            }, {}, (measures: Array<TVDRenovationProfileProperty>) => {
              const renovationProfileData = reduce(measures, (result: Object, property: TVDRenovationProfileProperty): Object => ({
                ...result, [property.propertyName]: property.value
              }), {})

              // map key of every value that is received from renovation profile to array
              const renovationProfileDataKeys = map(renovationProfileData, (value: string, key: string) => key)

              // omit from row columnData those values that are received from renovation profile
              const dataWithoutProfile = omitBy(row.columnData, (value: string, key: string) => includes(renovationProfileDataKeys, key))

              // keep those values that are set by user but also in the scope of renovationProfile
              const userModifiedValues = getUserModifiedDataFromColumnMeta(row)

              const newColumnData = {
                ...dataWithoutProfile,
                ...userModifiedValues,
                RenovationProfileId: ''
              }

              const patchOperation = createPatchOperation({
                resourceId: row.id,
                value: '',
                operationType: TVD_PATCH_OPERATION_TYPE_DELETE,
                basePath: patchOperationRenovationProfileBasePath
              })

              batch(() => {
                dispatchSetWidgetActive()
                dispatchStorePatchOperation(patchOperation)
                dispatchSetRenovationProfile(newColumnData, row.id)
              })
            })
          }
        },
        testId: 'MenuItem-delete-profile'
      },
      (row.type === SPACE.toLowerCase() ? {
        disabled: this.getIsResetMeasureDefaultsDisabled(row),
        localizedName: t('renovation._RESET_PROFILE_DEFAULT_VALUES_FOR_RENOVATION_MEASURES_'),
        onClick: () => { this.resetRenovationMeasuresDefaultValues(row) },
        testId: 'MenuItem-reset-renovation-default-values'
      } : {}),
    ]

    return menuItems
  }


  getContextMenuItems(row: TVDListItem): Array<TVDMenuItem> {
    const {
      t,
      dispatchOpenAddRenovationProfileModal,
      dispatchOpenCopyRenovationProfileModal,
      dispatchSetWidgetActive,
      listStoreId,
      listItems
    } = this.props
    const { type } = row

    const hamburgerMenuItems: Array<TVDMenuItem> = [
      {
        localizedName: t(`renovation._SET_RENOVATION_PROFILE_FOR_SPACES_IN_${row.type.toUpperCase()}_`),
        onClick: () => { dispatchOpenAddRenovationProfileModal(row, listItems) },
        testId: `ContextMenuItem-set-renovation-profile-for-spaces-in-${row.type}`
      },
      {
        localizedName: t(`renovation._DELETE_RENOVATION_PROFILES_FROM_SPACES_IN_${row.type.toUpperCase()}_`),
        onClick: () => {
          batch(() => {
            dispatchSetWidgetActive()
            traverseListItems(
              listItems,
              (currentItem: TVDListItem) => {
                if (!currentItem.isRemoved && isTypeAllowedForPatchOperations(currentItem.type, listStoreId)) {
                  this.deleteProfileAndMeasures(currentItem)
                }
                return {}
              },
              row.id
            )
          })
        },
        testId: `ContextMenuItem-delete-renovation-profile-from-spaces-in-${row.type}`
      }
    ]
    const dropdownMenuItems: Array<TVDMenuItem> = [
      {
        localizedName: t('renovation._SET_RENOVATION_PROFILE_'),
        onClick: () => { dispatchOpenAddRenovationProfileModal(row) },
        testId: 'ContextMenuItem-set-renovation-profile'
      },
      ...(type === SPACE.toLowerCase() ? [{
        localizedName: t('renovation._COPY_PROFILE_AND_MEASURES_'),
        onClick: () => {
          dispatchOpenCopyRenovationProfileModal(listItems, row)
        },
        testId: 'ContextMenuItem-copy-profile-and-measure'
      }] : []),
      {
        localizedName: t('renovation._DELETE_PROFILE_AND_MEASURES_'),
        onClick: () => {
          this.deleteProfileAndMeasures(row)
        },
        testId: 'ContextMenuItem-delete-profile-and-measure'
      },
    ]

    return row.type.toUpperCase() === SPACE ? dropdownMenuItems : hamburgerMenuItems
  }
  render(): React$Element<'div'> {
    const {
      classes,
      disabled,
      listStoreId,
      columns,
      widgetId,
      widgetTab,
    } = this.props

    return (
      <div className={classes.RenovationSpacesWrapper}>
        {
          columns &&
          <MatrixListContainer
            contextMenuItems={(row: Object) => this.getContextMenuItems(row)}
            widgetId={widgetId}
            widgetTab={widgetTab}
            listStoreId={listStoreId}
            disabled={disabled}
            wrapperStyleClassName={classes.tablePaddingTop}
            wrappedCellContents={this.getWrappedCellContents()}
            listHeaderCellClassNames={{ Description: classes.descriptionCell }}
            resizeDisabledColumns={[RENOVATION_PROFILE_ID]}
            simpleHierarchicalListColumns={this.getSimpleHierarchicalListColumns()}
            matrixColumns={columns} />
        }
      </div>
    )
  }
}


const mapStateToProps = ({ list, patchOperations }: TVDReduxStore, { listStoreId }: ReceivedProps): MappedProps => {
  const { [listStoreId]: { listItems = {}, columns = [] } = {} } = list
  return {
    columns,
    listItems,
    patchOperations: patchOperations[listStoreId]
  }
}

const mapDispatchToProps = (dispatch: Function, {
  widgetId, listStoreId, widgetTab, t
}: ReceivedProps & HOCProps): DispatchProps => ({
  dispatchModifyListItem: (modifyListItemPayload: TVDModifyListItemPayload) => { dispatch(modifyListItem(modifyListItemPayload)) },
  dispatchSetSelectedTab: () => { dispatch(setSelectedTab(RENOVATION_SPACES_TAB, widgetId)) },
  dispatchSetWidgetActive: () => { dispatch(setWidgetActive(widgetId, widgetTab)) },
  dispatchOpenAddRenovationProfileModal: (row: Object, listItems: Object) => {
    const modalId = ADD_RENOVATION_PROFILE_MODAL
    const onSaveHandler = (profileId: string) => {
      const rowIds = []
      switch (row.type.toUpperCase()) {
        case SPACE: {
          rowIds.push(row.id)
          break
        }
        case SPACEGROUP:
        case FUNCTION:
        case FUNCTIONALSECTOR: {
          const spaceRowsWithinSelectedRow = filter(traverseListItem(listItems, row), (_row: TVDListItem) => _row.type.toUpperCase() === SPACE)
          for (const _row of spaceRowsWithinSelectedRow) {
            rowIds.push(_row.id)
          }
          break
        }
        default: console.error(`onSaveHandler: Unable to handle the case "${row.type.toUpperCase()}"`)
      }
      dispatch(closeModal(modalId))
      dispatch(setWidgetActive(widgetId, RENOVATION_SPACES_TAB))
      dispatch(buildRenovationProfile(modalId, RENOVATION_SPACES_TAB, rowIds, profileId))
    }
    dispatch(openModal(
      {
        onSave: onSaveHandler,
        onClose: () => dispatch(closeModal(modalId)),
        type: ADD_RENOVATION_PROFILE_MODAL,
        disablePadding: true, // flag to disable padding in modal
        title: `${t('addRenovationModal._TITLE_')}${row.columnData.Description}`
      },
      modalId
    ))
  },
  dispatchOpenCopyRenovationProfileModal: (listItems: TVDListItems, row: TVDListItem, copyOnlyProfile?: boolean | null) => {
    const modalId = COPY_RENOVATION_PROFILE_MODAL
    dispatch(openModal(
      {
        onClose: () => dispatch(closeModal(modalId)),
        type: COPY_RENOVATION_PROFILE_MODAL,
        disablePadding: true, // flag to disable padding in modal
        title: 'copyRenovationModal._TITLE_',
        listItems,
        copyOnlyProfile,
        listStoreId,
        row,
        widgetId,
        widgetTab,
      },
      modalId
    ))
  },
  dispatchSetRenovationProfile: (newColumnData: Object, rowId: string) => dispatch(setRenovationProfile(newColumnData, listStoreId, rowId)),
  dispatchStorePatchOperation: (patchOperation: TVDPatchOperation) => { dispatch(storePatchOperation(listStoreId, patchOperation)) },
  dispatchClearPatchOperationWithResourceId: (patchOperationResourceId: string) => {
    dispatch(clearPatchOperationWithResourceId(listStoreId, patchOperationResourceId))
  },
  dispatchClearPatchOperationParameter: (resourceId: string, parameterPath: string) => {
    dispatch(clearPatchOperationParameter(
      listStoreId,
      resourceId,
      parameterPath,
    ))
  },
})

const sentientConfig: TVDSentientConfig = {
  getSetupRequestDefinitions: (store: Object, { listStoreId }: Props): TVDGARConfigs => ({
    getRenovationSpacesWithEstimateIdRequestDefinitions: getRenovationSpacesWithEstimateIdRequestDefinitions({
      payload: { listStoreId, mergeOptions: { initialListItems: {} } }
    }),
    getRenovationSpacesMeasureTopicsWithEstimateIdRequestDefinitions: getRenovationSpacesMeasureTopicsWithEstimateIdRequestDefinitions({
      requestArgs: { listStoreId, query: { embedMeasures: true } }, payload: { listStoreId }
    })
  })
}

export default compose(
  withTranslation('translations'),
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
  FeaturesHOC
)(SentientHOC(RenovationSpaces, sentientConfig))
