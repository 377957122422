// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.

import { withStyles } from '@material-ui/core/styles'
import React, { Component } from 'react'
import { combineStyleClassNames } from '../../../utils/styleUtils'
import theme from '../../../styles/theme'

type ReceivedProps = {|
  value: string, // the unit value shown in the component
  disabled?: boolean, // if component is disabled
  onClick?: () => void, // function ran when user clicks the unit text
  testId?: string, // optional data-testid given to the component for testing purposes
|}

type Props = {|
  ...ReceivedProps,
  classes: Object, // style object from withStyles
|}

const styles = ({ palette }: TVDTheme): Object => ({
  unit: {
    display: 'flex',
    outline: 'none',
    alignItems: 'center',
    paddingLeft: '10px',
    ...theme.typography.classes.bodyDefault,
    color: palette.dark60,
  },
  editable: {
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline'
    }
  },
})

export class Unit extends Component<Props> {
  static defaultProps = {
    editable: false,
    onClick: undefined,
    testId: '',
  }

  getIsAllowedToEdit = (): boolean => {
    const { onClick, disabled } = this.props
    return !!onClick && !disabled
  }

  onClick = (event: SyntheticMouseEvent<any>) => {
    const { onClick } = this.props
    event.stopPropagation()
    if (onClick) onClick()
  }

  getViewField = (): React$Element<'div'> => {
    const {
      value,
      classes,
      testId,
    } = this.props
    const isAllowedToEdit = this.getIsAllowedToEdit()
    return (
      <div
        role='button'
        tabIndex={0}
        onClick={isAllowedToEdit ? this.onClick : null}
        data-testid={testId ? `${testId}-unit` : null}
        className={combineStyleClassNames(classes.unit, isAllowedToEdit && classes.editable)}>
        {value}
      </div>
    )
  }

  render(): React$Element<'div'> {
    return this.getViewField()
  }
}

export default withStyles(styles)(Unit)
