// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.

import React from 'react'
import { map, includes, filter, isEqual, sortBy, indexOf, isEmpty } from 'lodash'

export type CheckedItems = Array<string>

export type MultipleSelectMenuStateProps = {|
  items: Array<TVDMenuItem>, // array of items in the menu
  onChange?: (checkedItems: CheckedItems) => void, // function that is called when a change in the menu happens
  selectAllItem?: string, // the value of the selectAllItem
  forcedValues: CheckedItems, // initially selected values in the menu, does not trigger onChange
  placeholder: boolean, // whether the menu has a placeholder
|}

type Props = {
  ...MultipleSelectMenuStateProps,
  children: ({selected: CheckedItems, handleSelectItem: Function}) => React$Element<any>, // function as a child prop
}

type State = {
  checkedItems: CheckedItems, // values for checked items
}

export class MultipleSelectMenuState extends React.Component<Props, State> {
  static defaultProps = {
    selectAllItem: '',
    forcedValues: [],
  }

  state = {
    checkedItems: this.props.placeholder ? ['placeholder'] : this.props.forcedValues
  }


  componentDidUpdate(prevProps: Object) {
    const { forcedValues = [], placeholder } = this.props

    // if the ForcedValues has not changed it means the component is back to being used normally and forcedValues should be ignored
    const haveForcedValuesChanged = () => !isEqual(sortBy(prevProps.forcedValues), sortBy(forcedValues))

    // if the current state is the same as the forced values then it means they were just updated and shouldn't be updated again
    const forcedValuesEqualState = () => isEqual(sortBy(forcedValues), sortBy(this.getSelectedItemsFromProps(this.state.checkedItems)))

    // update the state through the selectItem - method which takes a possible selectAllItem into account.
    // since this is a forced update we don't run the onChange prop callback - presumably the forcing parent element knows what it has forcibly selected
    if (haveForcedValuesChanged() && !forcedValuesEqualState()) {
      // return to placeholder value if an empty selection was forced, otherwise act normal
      if (isEmpty(forcedValues) && placeholder) {
        this.selectItem(['placeholder'], false)
      } else {
        this.selectItem(forcedValues, false)
      }
    }
  }

  /** Returns and array of the value properties of the objects in the *items* prop.
   * @return Array<string>
   */
  get itemValues(): CheckedItems {
    return map(this.props.items, (item: TVDMenuItem) => item.value)
  }

  /** Item selection function that is exposed from the component. Handles removing placeholder values and SelectAll values.
   * @param {CheckedItems} checkedItems An array of strings, the values of the menuitems that are being selected.
   * @param {boolean} [runCallback] Boolean value if for if the onChange prop callback should be ran after update. *Default: true*
   */
  selectItem = (checkedItems: CheckedItems, runCallback: boolean = true) => {
    const removePlaceholder = () => filter(checkedItems, (checkedItem: string) => checkedItem !== 'placeholder')

    const result = isEmpty(removePlaceholder()) && this.props.placeholder ? ['placeholder'] : removePlaceholder()

    if (this.props.selectAllItem) {
      this.handleChangeWithSelectAll(result)
    } else {
      this.updateState(result, runCallback)
    }
  }

  /** State updater that runs the onChange prop if it exists and if it is told to do so (by default yes)
   * @param {CheckedItems} checkedItems The complete array of selected item values the state is updated with
   * @param {boolean} [runCallback] Whether the onChange prop will be called with filtered *selected* state values after update. *Default: true*
   */
  updateState = (checkedItems: CheckedItems, runCallback: boolean = true) => {
    const { onChange } = this.props

    this.setState({
      checkedItems
    }, () => {
      // calls the onChange callback with proper selected items filtered from the state
      if (onChange && runCallback) {
        onChange(this.getSelectedItemsFromProps(this.state.checkedItems))
      }
    })
  }

  /** Filters the items-prop values based on the provided CheckedItems. This will remove the selectAll item and placeholder
   * since they were never sent in with the items prop.
   * @param {CheckedItems} items An array of strings that is used as the filter on the items prop.
   */
  getSelectedItemsFromProps = (SelectedItems: CheckedItems) => filter(this.itemValues, (value: string) => indexOf(SelectedItems, value) !== -1)

  /** Inserts the value property of every object in the *items* prop into the state. Aka. selecting everything.  */
  selectAll = () => {
    const { selectAllItem = '' } = this.props
    const result = this.itemValues
    result.push(selectAllItem)
    this.updateState(result, true)
  }

  /** Inserts an empty selection into the state or if the *placeholder* prop is true, selects hidden placeholder. */
  unselectAll = () => {
    const { placeholder } = this.props

    const newState = placeholder ? ['placeholder'] : []
    this.updateState(newState, true)
  }

  /** Hanldes selections when a selectAll option exists.
   *  * selects the selectAll item if everything else is manually selected.
   *  * unselects the selectAll item if after everything being selected something is unselected.
   *  * selects everything when the SelectAll item is selected.
   *  * unselects everything if the selectAll item is unselected.
   * @param {CheckedItems} selection The checked items, including the SelectAll.
   */
  handleChangeWithSelectAll(selection: CheckedItems) {
    const { items } = this.props

    const selectAllIsSelected = includes(this.state.checkedItems, this.props.selectAllItem)
    const selectionIncludesSelectAll = includes(selection, this.props.selectAllItem)
    const allItemsSelected = selection.length === items.length

    // the selectAll item was selected
    if (selectionIncludesSelectAll && !selectAllIsSelected) {
      this.selectAll()

    // the previously selected SelectAll was unselected so unselect everything
    } else if (!selectionIncludesSelectAll && selectAllIsSelected) {
      this.unselectAll()

    // selectAll was selected but an actual item was unselected
    } else if (selectionIncludesSelectAll && selectAllIsSelected) {
      // remove selectAllitem from the selection
      this.updateState(this.getSelectedItemsFromProps(selection))

    // selectAll is not selected but all items were selected so select it as well
    } else if (!selectionIncludesSelectAll && allItemsSelected) {
      this.selectAll()
    } else {
      this.updateState(selection)
    }
  }

  render(): any {
    return (
      this.props.children({
        selected: this.state.checkedItems,
        handleSelectItem: this.selectItem
      })
    )
  }
}

export default MultipleSelectMenuState
