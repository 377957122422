// @flow
// Copyright © 2010–2021 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.

import React, { useState, useEffect, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import { TextButton } from 'frontend-components'
import { useTranslation } from 'react-i18next'
import HamburgerMenu from '../../../components/common/menus/HamburgerMenu/HamburgerMenu'
import Modal from '../../../components/common/Modal/Modal'
import Spinner from '../../../components/common/Spinner/Spinner'
import { MODAL_TYPE_ADD_ACCESS_RIGHT_MODAL, MODAL_TYPE_USER_MODAL } from '../../../constants/modalConstants'
import SimpleDataTable, { type TVDSimpleDataTableColumn } from '../../../components/common/lists/SimpleDataTable/SimpleDataTable'
import DescriptionCell from '../../../components/common/lists/common/DescriptionCell/DescriptionCell'
import { NAME, EMAIL, USER_ACCESS } from '../../../constants/attributes'
import {
  getRealEstatesUserGroupsWithRealEstateIdRequest,
  getRealEstatesUsersWithRealEstateIdRequest,
  deleteRealEstatesUserGroupsUsersWithUserIdRequest,
  postRealEstatesUserGroupsUsersWithUserIdRequest
} from '../../../utils/generated-api-requests/realestate'
import { CONFIRMATION_MODAL } from '../../../constants/contentTypes'
import { openModal, closeModal } from '../../../actions/modals'
import { type TVDParserParameters } from '../../../utils/parserMapper'
import { getHighestPermissionFromGroups } from '../../../utils/permissionsUtils'
import { OWNER } from '../../../constants/permissions'
import DropdownMenu from '../../../components/common/menus/DropdownMenu/DropdownMenu'

const useStyles = makeStyles(({ palette, typography }: TVDTheme) => ({
  wrapper: {
    height: '700px',
    display: 'flex',
    flexDirection: 'column'
  },
  simpleDataTableWrapper: {
    overflow: 'auto'
  },
  addAccessRightButtonContainer: {
    marginTop: '29px',
    marginLeft: '30px',
    marginBottom: '38px'
  },
  alignLeft: {
    justifyContent: 'flex-start',
    textAlign: 'left'
  },
  descriptionWrapper: {
    display: 'flex',
    '&:hover [data-visible_on_hover_id="hamburgerMenu"]': {
      visibility: 'visible'
    }
  },
  hamburgerWrapper: {
    paddingLeft: '5px'
  },
  highlightedMessage: {
    color: palette.primary100,
    fontFamily: typography.fontFamilySemild,
    '&:after': {
      content: '"?"',
      color: palette.nevada
    }
  }
}))

type Props = {|
  realEstateName: string, // name of the project to be renamed
  realEstateId: string, // realestate id for the api call
|}

type ExtendedTVDUser = {
  ...TVDUser,
  usersGroups: TVDUserGroup[],
  name: string,
  userGroupDescription: string,
  userGroupId: string
}

// TODO: https://haahtela-kehitys.atlassian.net/browse/HAAH-18074 this sort function will be removed in the process of refactoring the project real estate permissions to frontend-estimate-management repository

const sortUserAlphabetically = (aUser: ExtendedTVDUser, bUser: ExtendedTVDUser): number => {
  switch (true) {
    case aUser[0].name > bUser[0].name: return 1
    case bUser[0].name > aUser[0].name: return -1
    default: return 0
  }
}


const BuildingSettingsDialog = (props: Props): React$Element<typeof Modal> => {
  const classes = useStyles()
  const { t } = useTranslation()
  const [rows, setRows] = useState<Array<any>>([])
  const [isLoading, setIsLoading] = useState(false)
  const [realEstateUserGroupsOptions, setRealEstateUserGroupsOptions] = useState<TVDEnum[]>([])
  const dispatch = useDispatch()


  const getRealEstatePermission = useCallback(() => {
    setIsLoading(true)

    getRealEstatesUsersWithRealEstateIdRequest({
      path: { realEstateId: props.realEstateId },
    })

    getRealEstatesUserGroupsWithRealEstateIdRequest(
      {
        path: { realEstateId: props.realEstateId },
        query: { embedPermissions: true, embedUsers: true }
      },
      {},
      (userGroupData: TVDParserParameters) => {
        const {
          HALParsedData: {
            _embedded: {
              usergroups: realEstateUserGroups
            }
          }
        } = userGroupData

        setRealEstateUserGroupsOptions(realEstateUserGroups.map(({ id, localizedDescription }: any): TVDMenuItem =>
          ({
            value: id,
            localizedName: localizedDescription
          })))

        const allUsers = realEstateUserGroups.reduce((prev: any, next: any) => {
          const { _embedded: { users: userGroupUsers } } = next
          const unaddedUsers = userGroupUsers.filter((user: TVDUser): boolean => !prev.find((prevUser: TVDUser) => prevUser.id === user.id))
          return [...prev, ...unaddedUsers]
        }, [])
          .map((user: TVDUser): Object => {
            const usersGroups = realEstateUserGroups.filter((userGroup: TVDUserGroup): boolean => {
              const { _embedded: { users: userGroupUsers } = {} } = userGroup
              const isUserInGroup = !!userGroupUsers.find((userGroupUser: TVDUser): boolean => user.id === userGroupUser.id)
              return isUserInGroup
            })
            return {
              ...user,
              name: `${user.firstName} ${user.lastName}`,
              usersGroups
              // type: fileterredOwners.includes(nextUser.id) ? OWNER : USER_GROUP,
            }
          })
          .map((user: TVDUser & { usersGroups: TVDUserGroup[], name: string }) => {
            const { group } = getHighestPermissionFromGroups(user.usersGroups)
            return {
              ...user,
              userGroupId: group?.id,
              userGroupDescription: group?.localizedDescription,
            }
          })

        const usersList = allUsers.map((user: ExtendedTVDUser): [ExtendedTVDUser, string, string ] => [
          user,
          user.email,
          user.userGroupDescription
        ])

        setRows(usersList.sort(sortUserAlphabetically))
        setIsLoading(false)
      }
    )
  }, [props.realEstateId])

  useEffect(() => {
    getRealEstatePermission()
  }, [getRealEstatePermission])

  const openAddAccessRightModal = () => {
    dispatch(openModal({
      type: MODAL_TYPE_ADD_ACCESS_RIGHT_MODAL,
      disablePadding: true,
      title: t('addAccessRightModal._NEW_ACCESS_RIGHT_'),
      contentProps: {
        realEstateId: props.realEstateId,
        onAddAccessRightSuccessful: getRealEstatePermission,
        isBuildingSettings: true,
        translatedPermissions: realEstateUserGroupsOptions,
        usersToExclude: rows.map((row: any): string => row[0].id)
      }
    }, MODAL_TYPE_ADD_ACCESS_RIGHT_MODAL))
  }

  const openUserModal = (description: string, userId: string) => {
    dispatch(openModal({
      type: MODAL_TYPE_USER_MODAL,
      disableEdit: true,
      containerType: 'dialog',
      estimateDescription: description,
      userId,
      patchUserSuccessCallBack: () => { getRealEstatePermission() }
    }, MODAL_TYPE_USER_MODAL))
  }

  const getColumns = (): Array<TVDSimpleDataTableColumn> => [
    {
      key: NAME,
      localizedName: t('listColumns._NAME_')
    },
    {
      key: EMAIL,
      localizedName: t('listColumns._EMAIL_')
    },
    {
      key: USER_ACCESS,
      localizedName: t('listColumns._USER_ACCESS_')
    }
  ]

  const getCellClassNames = (): { [columnKey: string]: string } =>
    getColumns().reduce((result: { [columnKey: string]: string }, column: TVDSimpleDataTableColumn) => ({
      ...result,
      [column.key]: classes.alignLeft
    }), {})

  const getHamburgerMenuItems = (rowIndex: number): Array<TVDMenuItem> | null => {
    const menuItems: Array<TVDMenuItem> = [
      {
        localizedName: t('buildingModal._DELETE_ACCESS_RIGHT_'),
        testId: 'ContextMenuItem-delete',
        onClick: () => {
          const rowToDelete = rows[rowIndex]
          const { userGroupId, id, name } = rowToDelete[0]

          if (userGroupId && id) {
            const content = {
              onSave: () => {
                deleteRealEstatesUserGroupsUsersWithUserIdRequest(
                  {
                    path: {
                      realEstateId: props.realEstateId,
                      userGroupId,
                      userId: id
                    }
                  },
                  {},
                  () => { getRealEstatePermission() },
                  null
                )
              },
              type: CONFIRMATION_MODAL,
              message: [
                t('buildingModal._CONFIRM_DELETE_ACCESS_RIGHT_'),
                <span className={classes.highlightedMessage}>{name}</span>
              ],
              saveButtonText: 'buttons._DELETE_',
              onClose: () => { dispatch(closeModal(CONFIRMATION_MODAL)) },
            }
            dispatch(openModal(content, CONFIRMATION_MODAL))
          } else console.error(`cannot execute delete request: no subject id or text found for ${name}`)
        }
      }]
    return menuItems
  }

  const getHamburgerMenu = (rowIndex: number): React$Element<HamburgerMenu> =>
    <HamburgerMenu items={getHamburgerMenuItems(rowIndex)} visibleOnHoverId='hamburgerMenu' />

  return (
    <>
      <div className={classes.wrapper}>
        {isLoading && <Spinner />}
        <div className={classes.addAccessRightButtonContainer}>
          <TextButton text={t('calculationInformation._ADD_ACCESS_RIGHT_')} onClick={openAddAccessRightModal} />
        </div>
        <div className={classes.simpleDataTableWrapper}>
          <SimpleDataTable
            rows={rows}
            columns={getColumns()}
            headerCellInitialWidth={207}
            cellClassNames={getCellClassNames()}
            fullWidthPusher
            wrappedCellContents={{
                index0: ({ content, rowIndex }: TVDSimpleDataTableWrappedCellCallbackParameters): React$Element<'div'> => {
                  const isContentObj = typeof content === 'object'
                  const text = isContentObj ? content.name : content

                  return (
                    <div className={classes.descriptionWrapper}>
                      <DescriptionCell
                        onClick={() => {
                          openUserModal(content.name, content.id)
                        }}
                        paddingLeft='32px'
                        text={text}
                        fontSize='14px'
                        highlighted />
                      {content.type !== OWNER &&
                      <div className={classes.hamburgerWrapper} data-visible_on_hover>{ getHamburgerMenu(rowIndex) }</div>}
                    </div>
                  )
                },
                index2: ({ rowIndex }: TVDSimpleDataTableWrappedCellCallbackParameters): React$Element<'div'> => {
                  const isOwner = rows[rowIndex][0].type === OWNER
                  const { userGroupId, id } = rows[rowIndex][0]

                  const handleDropdownChange = (selectedUserGroupId: string) => {
                  const add = () => {
                    postRealEstatesUserGroupsUsersWithUserIdRequest(
                      {
                        path: {
                          realEstateId: props.realEstateId,
                          userGroupId: selectedUserGroupId,
                          userId: id
                        }
                      },
                      {},
                      () => { getRealEstatePermission() },
                      null
                    )
                  }
                  if (!userGroupId) {
                    add()
                  } else {
                    deleteRealEstatesUserGroupsUsersWithUserIdRequest(
                      {
                        path: {
                          realEstateId: props.realEstateId,
                          userGroupId,
                          userId: id
                        }
                      },
                      {},
                      () => { add() },
                      null
                    )
                  }
                  }

                  return (
                    <div className={classes.descriptionWrapper}>
                      <DropdownMenu
                        width='XL'
                        items={realEstateUserGroupsOptions}
                        defaultValue={rows[rowIndex][0].userGroupId}
                        disabled={isOwner}
                        minimalist
                        onChange={handleDropdownChange} />
                    </div>
                  )
                }
            }} />
        </div>
      </div>
    </>
  )
}

export default BuildingSettingsDialog
