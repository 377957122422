// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.
/*
 * This hook is used to communicate between different instances of the same application e.g running in different tabs.
 * The hook is intended to be eventually moved from frontend repository to frontend-core repository.
 * During which some of the global variables will be moved to a more appropriate place.
 */
import { useEffect, useCallback } from 'react'
import uuidv4 from 'uuid/v4'
import { setLocalStorage, getLocalStorage } from '../../utils/commonUtils'

type OnMessageCallbackConfig = {|
  type: string, // The type of message to listen to that is usually defined as a constant
  callback: any, // The callback function to be called when the message is received
|}

export type CrossAppInstanceMessage = {|
  applicationInstanceId: string, // The id of the application instance that sent the message
  type: string, // The type of message that is usually defined as a constant
  payload: { [key: string]: any }, // The payload of the message which is free to be what ever needs to be transferred between instances
|}

export type InstanceCrossAppState = {
  activeEstimate?: {|
    id: string, // The id of the active estimate
    isLockedToCurrentUser: boolean, // If the active estimate is locked to the current user
  |}
}

/*
 * The CrossAppState is an object stored in localStorage and updated by instances of the application
 */
export type CrossAppState = {
  [instanceId: string]: InstanceCrossAppState, // A single instance of an application state
}

type UseCrossAppInstanceCommunicationReturn = {|
  addOnMessageCallback: (key: string, onMessageCallbackConfig: OnMessageCallbackConfig) => void, // Add a callback to listen to a specific message type
  sendMessage: (message: $Diff<CrossAppInstanceMessage, { applicationInstanceId: string }>) => void, // Send a message to all other instances of the application
  getCrossAppState: () => CrossAppState, // Get the current cross app state
  setCrossAppStateByInstance: (
    instanceId: string,
    callback: (instanceCrossAppState?: InstanceCrossAppState) => InstanceCrossAppState
  ) => void, // Set the cross app state for a specific instance
  clearCrossAppStateByInstance: (instanceId: string) => void, // Clear the cross app state for a specific instance
  setSelfCrossAppStateByInstance: (callback: (instanceCrossAppState?: InstanceCrossAppState) => InstanceCrossAppState) => void, // Set the cross app state for the current instance
  clearSelfCrossAppState: () => void, // Clear the cross app state for the current instance
|}

/*
 * The BroadcastChannelMessage is the message that is sent between application instances
 * We are typing here the minimum to ensure that internally we use correct structure for the data
 */
type BroadcastChannelMessage = {|
  data: CrossAppInstanceMessage
|}


const currentApplicationInstanceId = uuidv4()
const APP_BROADCAST_CHANNEL_NAME = 'TVDBroadcastChannel'
// Creating a fake BroadcastChannel to run unit tests
// TODO: fix mocking of BroadcastChannel
const appBroadcastChannel = window.BroadcastChannel ? new window.BroadcastChannel(APP_BROADCAST_CHANNEL_NAME) : {
  // eslint-disable-next-line no-unused-vars
  postMessage: (args: any) => {},
  onmessage: () => {},
  close: () => {}
}
const onMessageCallbacks: { [key: string]: OnMessageCallbackConfig } = {}
let hasBoundOnMessage = false

const crossAppStateStorageKey = `${APP_BROADCAST_CHANNEL_NAME}-2f07e4d2-f581-4ee1-9ae4-884ff64355f2`
const crossAppState: CrossAppState = {}

export default (): UseCrossAppInstanceCommunicationReturn => {
  const addOnMessageCallback = useCallback((key: string, onMessageCallbackConfig: OnMessageCallbackConfig) => {
    if (!onMessageCallbacks[key]) {
      onMessageCallbacks[key] = onMessageCallbackConfig
    } else {
      console.warn(`Key ${key} already exists in onMessageCallbacks`)
    }
  }, [])
  useEffect(() => {
    if (!hasBoundOnMessage) {
      appBroadcastChannel.onmessage = (BCMessageMessageEvent: any) => {
        const BCMessage: BroadcastChannelMessage = BCMessageMessageEvent
        const {
          data
        } = BCMessage
        const {
          applicationInstanceId,
          type,
        } = data
        if (applicationInstanceId !== currentApplicationInstanceId) {
          Object.keys(onMessageCallbacks).forEach((onMessageCallbackConfigKey: string) => {
            const onMessageCallbackConfig = onMessageCallbacks[onMessageCallbackConfigKey]
            const { type: callbackType, callback } = onMessageCallbackConfig
            if (callbackType === type) {
              callback(data)
            }
          })
        }
      }
      hasBoundOnMessage = true
    }
    return () => {
      appBroadcastChannel.close()
    }
  }, [])

  const sendMessage = useCallback((message: $Diff<CrossAppInstanceMessage, { applicationInstanceId: string }>) => {
    try {
      appBroadcastChannel.postMessage({
        applicationInstanceId: currentApplicationInstanceId,
        ...message
      })
    } catch (e) {
      console.error(e)
    }
  }, [])

  const getCrossAppState = useCallback(() => JSON.parse(getLocalStorage(crossAppStateStorageKey) || '{}'), [])

  const setCrossAppStateByInstance = useCallback((
    instanceId: string,
    callback: (instanceCrossAppState?: InstanceCrossAppState) => InstanceCrossAppState
  ) => {
    const storage = getCrossAppState()
    storage[instanceId] = callback(crossAppState[instanceId])
    setLocalStorage(crossAppStateStorageKey, JSON.stringify(storage))
  }, [getCrossAppState])

  const clearCrossAppStateByInstance = useCallback((instanceId: string) => {
    const storage = getCrossAppState()
    delete storage[instanceId]
    setLocalStorage(crossAppStateStorageKey, JSON.stringify(storage))
  }, [getCrossAppState])

  const clearSelfCrossAppState = useCallback(() => {
    clearCrossAppStateByInstance(currentApplicationInstanceId)
  }, [clearCrossAppStateByInstance])

  const setSelfCrossAppStateByInstance = useCallback((callback: (instanceCrossAppState?: InstanceCrossAppState) => InstanceCrossAppState) => {
    setCrossAppStateByInstance(currentApplicationInstanceId, callback)
  }, [setCrossAppStateByInstance])


  return {
    addOnMessageCallback,
    sendMessage,
    getCrossAppState,
    setCrossAppStateByInstance,
    clearCrossAppStateByInstance,
    setSelfCrossAppStateByInstance,
    clearSelfCrossAppState
  }
}
