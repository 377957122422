// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.
import React from 'react'
import TextField from '@material-ui/core/TextField'
import { Autocomplete } from '@material-ui/lab'
import { withStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'
import { Chip } from '@material-ui/core'


type HOCProps = {|
  t: Function, // translation function
  classes: Object // withStyles classes object
|}

export type AutoCompleteMenuItem = {|
  name: string, // display value of menu item
  id: string, // id value of menu item that can be used in requests and such
  type?: string, // optional type prop if it's needed to have different types for different menuitems in same menu
  disabled?: boolean, // if the single menu item is to be disabled
|}

type ReceivedProps = {|
  options: Array<AutoCompleteMenuItem>, // array of options for AutoCompleteMenu
  label: string, // label text to display on menu textfield
  onChange: () => void, // function triggered when AutoCompleteMenu value changes
  multiple?: boolean, // flag to support multiple selected values in menu
  value?: Array<AutoCompleteMenuItem>, // array of selections provided by parent component
  getOptionSelected?: () => boolean, // Used to determine if an option is selected
  disabled?: boolean, // if the Autocomplete component is disabled
  helperText?: string, // optional helpert text for the input
  disableClearable?: boolean, // MUI Autocomplete prop that if true, the input can't be cleared
  disableBackspace?: boolean, // disabled using backspace in the input field
  onKeyUp?: (inputValue: string) => void, // optional listener for onKeyUp events for the input
  freeSolo?: boolean, // if true, the Autocomplete is free solo, meaning that the user input is not bound to provided options.
  width?: string, // optional width for the dropdown
  placeholder?: string, // optional placeholder for the TextField
  required?: boolean, // optional required information for the TextField
  error?: boolean, // if TextField is in error state or not
  disableCloseOnSelect?: boolean, // if the dropdown should close on selection
  inputValue?: string, // the users written input value that may or may not match an option's name
|}

type Props = {|
  ...HOCProps,
  ...ReceivedProps
|}

const styles = ({ palette, typography }: TVDTheme): Object => ({
  popper: {
    zIndex: '9001'
  },
  chip: {
    width: 'max-content', // supported in Edge, Firefox and Chrome now
    maxWidth: '180px',
    minWidth: '60px',
    maxHeight: '24px',
    padding: '0 8px',
    background: palette.primary100,
    color: palette.white,
    justifyContent: 'space-between', // keeps the closing icon at the end of the chip even if the text is short or nonexistant
    margin: '0',
    '&:hover': {
      background: palette.primary100
    },
    '&:focus': {
      backgroundColor: palette.primary100
    }
  },
  deleteIcon: {
    color: palette.tint01, // close enough to invision prototype color #94D3EE
    height: '19px',
    fontSize: '20px',
    margin: '0 -7px 1px -2px',
    '&:hover': {
      color: palette.ui01
    }
  },
  label: {
    padding: '0 6px 2px 6px',
    fontFamily: typography.fontFamilySemiBold
  },
  asterisk: {
    color: palette.messageError
  },
})

export class AutoCompleteMenu extends React.Component<Props> {
  static defaultProps = {
    multiple: false,
    disabled: false,
    helperText: '',
    disableClearable: false,
    disableBackspace: false,
    options: [],
    getOptionSelected: undefined,
    freeSolo: false,
    width: undefined,
    placeholder: '',
    required: false,
    error: false,
    disableCloseOnSelect: true,
    inputValue: ''
  }

  filterOptionsByNameWithValue = (): Array<AutoCompleteMenuItem> => {
    const { inputValue = AutoCompleteMenu.defaultProps.inputValue, options } = this.props
    return options.filter(({ name }: AutoCompleteMenuItem) => name.includes(inputValue))
  }

  render(): React$Element<typeof Autocomplete> {
    const {
      classes,
      t,
      multiple,
      options,
      onChange,
      label,
      value,
      getOptionSelected,
      disabled,
      helperText,
      disableClearable,
      disableBackspace,
      onKeyUp,
      freeSolo,
      width,
      placeholder,
      required,
      error,
      disableCloseOnSelect,
      inputValue
    } = this.props

    return (
      <Autocomplete
        filterOptions={inputValue ? this.filterOptionsByNameWithValue : undefined}
        freeSolo={freeSolo}
        disableClearable={disableClearable}
        disabled={disabled}
        multiple={multiple}
        options={options}
        value={value}
        getOptionLabel={(option: AutoCompleteMenuItem) => option.name || value}
        getOptionDisabled={(option: AutoCompleteMenuItem): boolean => !!option.disabled}
        getOptionSelected={getOptionSelected}
        classes={{
          popper: classes.popper,
          tag: classes.chip
        }}
        disableCloseOnSelect={disableCloseOnSelect}
        onChange={onChange}
        style={{ width: width || '280px' }}
        noOptionsText={t('dropdowns._NO_OPTIONS_')}
        renderTags={(tagValue: Array<AutoCompleteMenuItem>, getTagProps: Function): Array<React$Element<typeof Chip>> =>
            tagValue.map((tag: any, index: number) => {
              const matchingMenuItem = options.find(({ id }: AutoCompleteMenuItem): boolean => id === tag.id)
              if (!matchingMenuItem) { console.error(`No matching menu item foudn with tag id ${tag.id}`) }
              return (
                <Chip
                  {...getTagProps({ index })}
                  key={tag.id}
                  classes={{
                  deleteIcon: classes.deleteIcon,
                  label: classes.label
                }}
                  disabled={matchingMenuItem?.disabled || disabled}
                  label={tag.name} />
              )
            })}
        renderInput={(params: MUIAutocompleteInputProps) => (
          <TextField
            {...params}
            error={error}
            value={inputValue}
            InputLabelProps={{
              classes: {
                asterisk: classes.asterisk
              }
            }}
            required={required}
            placeholder={placeholder}
            helperText={helperText}
            variant='standard'
            label={label}
            onKeyUp={(event: SyntheticKeyboardEvent<any>) => {
              // $FlowFixMe value missing from the event type even though it exists in reality
              if (onKeyUp) onKeyUp(event.target.value)

              if (disableBackspace && event.key === 'Backspace') {
                event.stopPropagation()
              }
            }} />
        )} />
    )
  }
}

export default (withStyles(styles)(withTranslation('translations')(AutoCompleteMenu)))
