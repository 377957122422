// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.

import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Icon, IconButton } from '@material-ui/core'
import LayersIcon from '@material-ui/icons/Layers'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import { combineStyleClassNames } from '../../../../utils/styleUtils'

import Menu from '../Menu/Menu'

const styles = ({ palette }: TVDTheme): Object => ({
  icon: {
    fontSize: 16
  },
  menuIconButton: {
    color: palette.dark60,
    width: 26,
    height: 26,
    '&:hover': {
      backgroundColor: palette.gray40,
    },
    '&:active': {
      background: palette.primary60,
      color: palette.white
    }
  },
  iconButton: {
    padding: 3
  },
  initiallyHidden: {
    visibility: 'hidden'
  },
  layersIcon: {
    color: palette.dark80
  },
  vertIcon: {
    width: '30px',
    height: '24px'
  },
  vertIconSize: {
    width: '30px',
    height: '24px'
  }
})

export type HamburgerMenuProps = {|
  items: Array<TVDMenuItem>, // items for the menu
|}

type Props = {|
  ...HamburgerMenuProps,
  visibleOnHover?: boolean | null, // css on-hover for the components that are shown when data-visible_on_hover rule is applied,
  visibleOnHoverId: ?string, // visible when corresponding [data-visible_on_hover_id="${visibleOnHoverId}"] rule is applied
  id: string, // for testing purposes
  classes: Object, // withStyles styles prop
  renderSkeletons?: boolean, // indicates if need to render placeholder Skeletons
  menuTitle: ?React$Element<any>, // should the menuItems have a title on top of them
  onToggleChange?: Function, // to add the class when row is active or focus
  onToggleClose?: Function, // to remove the class when the row is not active or focus
  hasLayersIcon?: boolean, // flag to use open layers icon
  hasMoreVertIcon?: boolean, // flag to use more vert icon
  selected?: string, // value of the selected menu item
|}

export class HamburgerMenu extends Component<Props> {
  static defaultProps = {
    visibleOnHover: null,
    renderSkeletons: false,
    onToggleChange: undefined,
    onToggleClose: undefined,
    hasLayersIcon: false,
    hasMoreVertIcon: false,
    selected: ''
  }

  hasIcon = (): React$Element<any> | string => {
    const { hasLayersIcon, hasMoreVertIcon, classes } = this.props

    switch (true) {
      case hasLayersIcon: return <LayersIcon />
      case hasMoreVertIcon: return <MoreVertIcon classes={{ root: classes.vertIconSize }} />
      default: return 'menu'
    }
  }

  hasIconClass = (): string => {
    const {
      hasLayersIcon, hasMoreVertIcon, classes, visibleOnHoverId
    } = this.props

    switch (true) {
      case hasLayersIcon: return classes.layersIcon
      case hasMoreVertIcon: return classes.vertIcon
      default: return combineStyleClassNames(classes.icon, visibleOnHoverId && classes.initiallyHidden)
    }
  }

  hasIconButtonClass = (): string => {
    const {
      hasLayersIcon, hasMoreVertIcon, classes
    } = this.props

    switch (true) {
      case hasLayersIcon: return classes.iconButton
      case hasMoreVertIcon: return classes.iconButton
      default: return combineStyleClassNames(classes.menuIconButton)
    }
  }

  render(): React$Element<any> {
    const {
      items,
      classes,
      renderSkeletons,
      visibleOnHoverId,
      menuTitle,
      onToggleChange,
      onToggleClose,
      hasLayersIcon,
      selected
    } = this.props

    // disablePortal will place this menu independent of the root component and prevent problems with hover-activated hamburger menus
    // and drawers that would otherwise influence the opening position for the menu
    return (
      <span className={classes.menuWrapper}>
        <Menu
          renderSkeletons={renderSkeletons}
          menuTitle={menuTitle}
          items={items}
          placement={hasLayersIcon ? 'right-end' : 'bottom-start'}
          disablePortal={false}
          id='ContextMenuItems'
          onToggleChange={onToggleChange}
          onToggleClose={onToggleClose}
          hasStyle={!!hasLayersIcon}
          selected={selected}>
          <IconButton
            data-testid={`ContextMenu-${this.props.id}`}
            className={this.hasIconButtonClass()}
            disableRipple>
            <Icon
              data-visible_on_hover={this.props.visibleOnHover}
              data-visible_on_hover_id={visibleOnHoverId}
              className={this.hasIconClass()}>
              {this.hasIcon()}
            </Icon>
          </IconButton>
        </Menu>
      </span>
    )
  }
}

export default withStyles(styles)(HamburgerMenu)
