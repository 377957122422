// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.

import { reduce, filter, assign, mapValues, size, includes, omit, isEmpty } from 'lodash'
import {
  ADD_LIST_ITEM,
  CLEAR_LIST,
  CLEAR_LIST_ITEMS_COLUMN_DATA_FILTER,
  CLEAR_LIST_ITEMS_FILTER,
  DELETE_LIST_ITEM,
  FILTER_LIST_ITEMS_BY_COLUMN_DATA,
  FILTER_LIST_ITEMS_WITH_FILTER_FN,
  MERGE_LIST_ITEMS,
  MODIFY_LIST_ITEM,
  SAVE_LIST_DONE,
  SET_MOCK_LIST_TO_STORE,
  SET_LIST_COLUMNS,
  SET_LIST_ITEM_DEFAULTS,
  SET_LIST_ITEM_FILTER,
  SET_RENOVATION_PROFILE,
  TOGGLE_ALL_LIST_ITEMS_OPEN,
  TOGGLE_LIST_ITEM_OPEN,
  TOGGLE_LIST_ITEM_SELECTED,
  SET_LIST_ITEM_LOADED,
  type TVDModifyListItemOptions,
  UNDO_MODIFY_LIST_ITEM,
  SET_ACTIVITY_FILTER,
  CLEAR_ACTIVITY_FILTERS,
  FILTER_WOP_SPACE_SCHEDULE_BY_ACTIVITIES,
  FILTER_WOP_ACTIVITY_STRUCTURE_BY_SPACES,
  SET_PROCESS_ACTIVITY_ITEMS,
  SET_BUILDINGS_LIST_TO_STORE,
  APPLY_LIST_ITEM_MODIFICATIONS,
  UPDATE_LIST_LAST_UPDATED,
  DELETE_LIST_ITEM_ROW,
  CLEAR_SINGLE_ROW,
  CLOSE_ALL_LIST_ITEMS,
  REMOVE_ADDED_LIST_ITEMS,
  SET_LIST_ITEM_OPEN
} from '../actions/list'
import {
  getColumnDataFilteredListItems,
  getListItemParentIds,
  getSearchFilteredListItems,
  traverseListItems,
  filterListByActivities,
  traverseListItem,
  filterListBySpaces,
} from '../utils/listUtils'
import {
  REMOVE_BUILDING_TASK_SCHEDULE
} from '../actions/elements'
import {
  DATA_GET_SPACE_SCHEDULE_COLUMNS_WITH_ESTIMATEID_SUCCESSFUL,
  DATA_DELETE_SPACES_EQUIPMENT_WITH_ID_SUCCESSFUL,
  DATA_PATCH_SPACE_SCHEDULE_SPACE_GROUPS_WITH_ID_SUCCESSFUL,
  DATA_PATCH_SPACE_SCHEDULE_SPACES_WITH_ID_SUCCESSFUL,
  DATA_PATCH_SPACE_SCHEDULE_FUNCTIONAL_SECTORS_WITH_ID_SUCCESSFUL,
  DATA_PATCH_SPACE_SCHEDULE_FUNCTIONS_WITH_ID_SUCCESSFUL,
  DATA_DELETE_ESTIMATE_EQUIPMENT_WITH_EQUIPMENTID_SUCCESSFUL,
  DATA_DELETE_SPACES_SURFACES_WITH_ID_SUCCESSFUL,
  DATA_PATCH_SPACES_EQUIPMENT_WITH_ID_SUCCESSFUL,
  DATA_PATCH_SPACES_SURFACES_WITH_ID_SUCCESSFUL,
  DATA_POST_SPACES_EQUIPMENT_WITH_ID_SUCCESSFUL,
  DATA_POST_SPACES_SURFACES_WITH_ID_SUCCESSFUL,
  DATA_POST_ESTIMATE_EQUIPMENT_WITH_EQUIPMENTID_SUCCESSFUL,
  DATA_GET_COMMON_FUNCTIONS_COLUMNS_WITH_FUNCTIONTYPE_SUCCESSFUL,
} from '../utils/generated-api-requests/spaces'
import {
  DATA_PATCH_HEADING_WITH_ID_SUCCESSFUL,
  DATA_PATCH_ASSEMBLY_WITH_ID_SUCCESSFUL,
  DATA_PATCH_PRICEITEM_WITH_ID_SUCCESSFUL
} from '../utils/generated-api-requests/buildingelements'
import { mergeListItems } from '../utils/reducers/list'
import {
  VALUE_PROPERTY,
  SCHEDULE_ITEM,
  ELEMENT_SCHEDULE_ITEM,
  BUILDING_TASK_SCHEDULE_ITEM,
  ACTIVITY_SCHEDULE_ITEM,
  ENUMERAL,
  SPACE_SCHEDULE_ITEM,
  OPERATING_PROFILE_SCHEDULE_ITEM,
  GROUPING_SCHEDULE_ITEM,
} from '../constants/schemaKeys'
import { reduceSuccessfulEmbeddedActions, type ReduceSuccessfulEmbeddedActionsCallbackArguments } from '../utils/reducerMergeUtil'
import {
  DATA_PATCH_GROUPING_SCHEDULE_FUNCTIONAL_SECTOR_GROUPS_WITH_ITEMID_SUCCESSFUL,
  DATA_PATCH_GROUPING_SCHEDULE_PROCESS_GROUPS_WITH_ITEMID_SUCCESSFUL,
  DATA_PATCH_GROUPING_SCHEDULE_FUNCTION_GROUPS_WITH_ITEMID_SUCCESSFUL,
  DATA_PATCH_ACTIVITY_SCHEDULE_FUNCTIONAL_SECTORS_WITH_ITEMID_SUCCESSFUL,
  DATA_PATCH_ACTIVITY_SCHEDULE_FUNCTIONS_WITH_ITEMID_SUCCESSFUL,
  DATA_PATCH_ACTIVITY_SCHEDULE_PROCESSES_WITH_ITEMID_SUCCESSFUL,
  DATA_PATCH_ACTIVITY_SCHEDULE_ACTIVITY_GROUPS_WITH_ITEMID_SUCCESSFUL
} from '../utils/generated-api-requests/wop'
import { ACTIVITY_REGISTRY_WIDGET, ACTIVITYGROUP, WOP_SPACE_SCHEDULE, ACTIVITY_STRUCTURE } from '../constants/contentTypes'

const initialState = {}

export default function list(state: TVDListStore = initialState, action: Object = {}): Object {
  switch (action.type) {
    case DATA_PATCH_GROUPING_SCHEDULE_FUNCTION_GROUPS_WITH_ITEMID_SUCCESSFUL:
    case DATA_PATCH_GROUPING_SCHEDULE_PROCESS_GROUPS_WITH_ITEMID_SUCCESSFUL:
    case DATA_PATCH_GROUPING_SCHEDULE_FUNCTIONAL_SECTOR_GROUPS_WITH_ITEMID_SUCCESSFUL:
    case DATA_PATCH_ASSEMBLY_WITH_ID_SUCCESSFUL:
    case DATA_PATCH_PRICEITEM_WITH_ID_SUCCESSFUL:
    case DATA_PATCH_HEADING_WITH_ID_SUCCESSFUL:
    case DATA_PATCH_SPACE_SCHEDULE_FUNCTIONAL_SECTORS_WITH_ID_SUCCESSFUL:
    case DATA_PATCH_SPACE_SCHEDULE_FUNCTIONS_WITH_ID_SUCCESSFUL:
    case DATA_PATCH_SPACE_SCHEDULE_SPACES_WITH_ID_SUCCESSFUL:
    case DATA_PATCH_SPACE_SCHEDULE_SPACE_GROUPS_WITH_ID_SUCCESSFUL:
    case DATA_PATCH_ACTIVITY_SCHEDULE_FUNCTIONAL_SECTORS_WITH_ITEMID_SUCCESSFUL:
    case DATA_PATCH_ACTIVITY_SCHEDULE_FUNCTIONS_WITH_ITEMID_SUCCESSFUL:
    case DATA_PATCH_ACTIVITY_SCHEDULE_PROCESSES_WITH_ITEMID_SUCCESSFUL:
    case DATA_PATCH_ACTIVITY_SCHEDULE_ACTIVITY_GROUPS_WITH_ITEMID_SUCCESSFUL:
    {
      const {
        payload: {
          listId,
          modifiedListItem,
          mergeOptions
        },
        config,
      } = action
      return mergeListItems({
        state,
        listId,
        newListItems: modifiedListItem ? { [modifiedListItem.id]: modifiedListItem } : {},
        config,
        options: mergeOptions
      })
    }

    case REMOVE_BUILDING_TASK_SCHEDULE: {
      const { id } = action.payload
      const newState = assign({}, state)
      delete newState[id]
      return {
        ...newState
      }
    }

    case SET_LIST_ITEM_LOADED: {
      const { payload: { listId, listItemId } } = action
      const { listItems } = state[listId]
      if (!listItems[listItemId]) {
        console.error(`SET_LIST_ITEM_LOADED: no list item found with id: ${listItemId}`)
        return state
      }

      return {
        ...state,
        [listId]: {
          ...state[listId],
          listItems: {
            ...listItems,
            [listItemId]: {
              ...listItems[listItemId],
              isLoaded: true,
            }
          }
        }
      }
    }

    case SET_LIST_ITEM_OPEN: {
      const { payload: { listStoreId, listItemId, isOpen } } = action
      const listById = state[listStoreId]
      if (!listById) {
        console.error(`SET_LIST_ITEM_OPEN: no list found with id: ${listStoreId}`)
        return state
      }
      const { listItems, filteredListItems } = listById
      const listItemsKey: string = filteredListItems ? 'filteredListItems' : 'listItems'
      const resolvedListItems = filteredListItems || listItems
      const item = resolvedListItems[listItemId]

      // this logic prevents crashing when switching to different list with same preference id so that
      // the item that was not found from the other list does not get only e.g { isOpen: true } here when it should have TVDListItem properties too
      if (!item) {
        return state
      }

      return {
        ...state,
        [listStoreId]: {
          ...state[listStoreId],
          [listItemsKey]: {
            ...resolvedListItems,
            [listItemId]: {
              ...resolvedListItems[listItemId],
              isOpen,
            }
          }
        }
      }
    }

    case TOGGLE_LIST_ITEM_OPEN: {
      const { payload: { listId, listItemId } } = action
      const { filteredListItems } = state[listId]
      const listItems = filteredListItems ? 'filteredListItems' : 'listItems'

      if (!state[listId][listItems][listItemId]) {
        console.error(`TOGGLE_LIST_ITEM_OPEN: no list item found with id: ${listItemId}`)
        return state
      }

      return {
        ...state,
        [listId]: {
          ...state[listId],
          // since flow version 0.111 union cannot be used as a computed property due to flow performance issues
          // if massive union is used. Skipped here because only two possible values shouldn't cause issues.
          // $FlowFixMe
          [listItems]: {
            ...state[listId][listItems],
            [listItemId]: {
              ...state[listId][listItems][listItemId],
              isOpen: !state[listId][listItems][listItemId].isOpen,
            }
          }
        }
      }
    }


    case TOGGLE_ALL_LIST_ITEMS_OPEN: {
      const {
        payload: {
          listId,
        }
      } = action

      const { listItems } = state[listId] || {}
      return {
        ...state,
        [listId]: {
          ...state[listId],
          listItems: mapValues(listItems, (item: Object) => {
            if (item.canHaveChildren) item.isOpen = true
            return item
          })
        }
      }
    }

    case TOGGLE_LIST_ITEM_SELECTED: {
      const { payload: { listId, listItemId, shouldUnselectParents } } = action
      const { listItems, filteredListItems } = state[listId]

      const isFiltered = Boolean(filteredListItems)
      const items = isFiltered ? filteredListItems : listItems
      const toggledItem = items[listItemId]

      const updatedListItems = traverseListItems(items, (listItem: Object) => {
        const parents = getListItemParentIds(items, listItem)
        const toggledItemIsParentOfCurrentItem = parents.includes(listItemId)
        const toggledItemIsCurrentItem = listItem.id === listItemId
        const toggledItemParents = getListItemParentIds(items, toggledItem)

        // if an item is selected and shouldUnselectParents prop is true, setting also parents as not selected
        const toggledItemIsChildOfCurrentItem = toggledItem.selected && toggledItemParents.includes(listItem.id)

        return {
          [listItem.id]: {
            ...listItem,
            selected: (toggledItemIsParentOfCurrentItem || toggledItemIsCurrentItem || (shouldUnselectParents && toggledItemIsChildOfCurrentItem))
              ? !toggledItem.selected : listItem.selected
          }
        }
      })

      // Sync the selected status of normal listItems with filteredListItems
      // intentionally syncing only if found direct counterpart from listItems and not taking in account if parent is selected (design team choice)
      const listItemsSyncedWithFilteredListItems = isFiltered ? Object.keys(listItems).reduce((result: Object, _listItemId: string) => {
        const listItem = filteredListItems[_listItemId]
        if (!listItem) return result
        return {
          ...result,
          [listItem.id]: {
            ...listItems[_listItemId],
            selected: listItem.selected
          }
        }
      }, listItems) : {}

      const selectedFilteredListItems = isFiltered ? { listItems: listItemsSyncedWithFilteredListItems } : {}
      const listItemsKey: string = isFiltered ? 'filteredListItems' : 'listItems'
      return {
        ...state,
        [listId]: {
          ...state[listId],
          [listItemsKey]: updatedListItems,
          ...selectedFilteredListItems
        }
      }
    }

    case MODIFY_LIST_ITEM: {
      const {
        payload: {
          listId,
          listItemId,
          columnName,
          value,
          options = {},
        }
      } = action
      const { showModifiedIndicator }: TVDModifyListItemOptions = options
      const listItem = state[listId].listItems[listItemId]
      const hasFilteredListItems = size(state[listId].filteredListItems) > 0
      const { showModifiedIndicatorColumns: existingModifiedIndicatorColumns = {} } = listItem

      const modifiedListItem = {
        ...listItem,
        modifiedColumnData: {
          ...(listItem.modifiedColumnData || {}),
          [columnName]: value,
        },
        modifiedColumn: columnName,
        showModifiedIndicatorColumns: {
          ...existingModifiedIndicatorColumns,
          [columnName]: typeof showModifiedIndicator === 'boolean' ? showModifiedIndicator : true
        }
      }

      return {
        ...state,
        [listId]: {
          ...state[listId],
          isListModified: true,
          listItems: {
            ...state[listId].listItems,
            [listItemId]: modifiedListItem,
          },
          ...(hasFilteredListItems && {
            filteredListItems: {
              ...state[listId].filteredListItems,
              [listItemId]: modifiedListItem,
            }
          })
        }
      }
    }

    case UNDO_MODIFY_LIST_ITEM: {
      const {
        payload: {
          listId,
          listItem,
        }
      } = action

      const unModifiedListItem = {
        ...state[listId].listItems[listItem.id],
        modifiedColumnData: {},
        modifiedColumn: null,
        showModifiedIndicatorColumns: {}
      }

      return {
        ...state,
        [listId]: {
          ...state[listId],
          isListModified: false,
          listItems: {
            ...state[listId].listItems,
            [listItem.id]: unModifiedListItem,
          },
        }
      }
    }

    case APPLY_LIST_ITEM_MODIFICATIONS: {
      const {
        payload: {
          listStoreId,
          listItemId,
        }
      } = action

      const listItem = state[listStoreId].listItems[listItemId]
      const newListItem = {
        ...listItem,
        columnData: {
          ...listItem.columnData,
          ...listItem.modifiedColumnData
        },
        modifiedColumnData: {},
        modifiedColumn: null,
        showModifiedIndicatorColumns: {}
      }

      return {
        ...state,
        [listStoreId]: {
          ...state[listStoreId],
          isListModified: false,
          listItems: {
            ...state[listStoreId].listItems,
            [listItemId]: newListItem
          },
        }
      }
    }

    case DELETE_LIST_ITEM_ROW: {
      const {
        payload: {
          listId,
          listItemId,
        }
      } = action
      if (!state[listId]) return state
      const { listItems } = state[listId]
      const { [listItemId]: addedListItem, ...newListItems } = listItems
      return {
        ...state,
        [listId]: {
          listLastModifiedTS: new Date().getTime(),
          isListModified: true,
          ...state[listId],
          listItems: newListItems
        }
      }
    }

    case DELETE_LIST_ITEM: {
      const {
        payload: {
          listId,
          listItemId,
        }
      } = action
      if (!state[listId]) return state
      const { listItems } = state[listId]
      const { [listItemId]: listItemToDelete } = listItems
      // removing unsaved and recently added row
      if (listItemToDelete.isAdded || listItemToDelete.isUserAdded) {
        const { [listItemId]: addedListItem, ...newListItems } = listItems
        return {
          ...state,
          [listId]: {
            isListModified: true,
            ...state[listId],
            listLastModifiedTS: new Date().getTime(),
            listItems: newListItems
          }
        }
      }
      const { parentId } = listItems[listItemId]
      const parentChildren = filter(listItems, (filteredListItem: Object) => filteredListItem.parentId === parentId)
      const updatedParent = parentId && listItems[parentId] && parentChildren.length === 1 ? {
        [parentId]: {
          ...listItems[parentId],
          canHaveChildren: false
        }
      } : {}
      return {
        ...state,
        [listId]: {
          ...state[listId],
          isListModified: true,
          listLastModifiedTS: new Date().getTime(),
          listItems: {
            ...state[listId].listItems,
            ...updatedParent,
            [listItemId]: {
              ...state[listId].listItems[listItemId],
              isRemoved: true,
            },
          }
        }
      }
    }

    case ADD_LIST_ITEM: {
      const {
        payload: {
          listId,
          listItem,
          parentId,
          isUserAdded
        }
      } = action
      const newListItem = {
        ...listItem,
      }
      const { listItems } = state[listId]
      if (parentId) {
        newListItem.parentId = parentId
        const { level: parentListItemLevel } = listItems[parentId]
        newListItem.level = parentListItemLevel + 1
      }
      const newParent = parentId ?
        {
          [parentId]: {
            ...listItems[parentId],
            canHaveChildren: true,
            isOpen: true,
          }
        } : {}

      return {
        ...state,
        [listId]: {
          ...state[listId],
          listLastModifiedTS: new Date().getTime(),
          isListModified: true,
          listItems: {
            ...state[listId].listItems,
            ...newParent,
            [newListItem.id]: {
              ...newListItem,
              parentId,
              // since flow version 0.111 union cannot be used as a computed property due to flow performance issues
              // if massive union is used. Skipped here because only two possible values shouldn't cause issues.
              // $FlowFixMe
              [isUserAdded ? 'isUserAdded' : 'isAdded']: true,
              modifiedColumn: ''
            }
          },
        }
      }
    }

    case SET_LIST_ITEM_FILTER: {
      const {
        payload: {
          listFilter, listId, filteredTypes, filterKey
        }
      } = action
      if (listFilter.length === 0) {
        const { listFilter: assignedListFilter, filteredListItems, ...newListState } = state[listId]
        return { ...state, [listId]: newListState }
      }
      const { listItems, filterColumnDataValue, filterColumnDataKey } = state[listId]
      let listItemsToFilter = listItems
      if (filterColumnDataValue && filterColumnDataKey) {
        listItemsToFilter = getColumnDataFilteredListItems(listItems, filterColumnDataKey, filterColumnDataValue)
      }
      const filteredListItems = getSearchFilteredListItems(listItemsToFilter, listFilter, filteredTypes, filterKey)
      return {
        ...state,
        [listId]: {
          ...state[listId],
          listFilter,
          filteredListItems,
          filteredTypes
        }
      }
    }

    case SAVE_LIST_DONE: {
      const {
        payload: {
          listId
        }
      } = action
      if (!state[listId]) return state
      return {
        ...state,
        [listId]: {
          ...state[listId],
          listItems: reduce(state[listId].listItems, (result: Object, listItem: Object) => {
            if (listItem.modifiedColumnData) {
              return {
                ...result,
                [listItem.id]: {
                  ...listItem,
                  modifiedColumnData: {},
                  modifiedColumn: ''
                }
              }
            }
            return { ...result, [listItem.id]: { ...listItem } }
          }, {}),
          isListModified: false,
        }
      }
    }

    case DATA_DELETE_SPACES_SURFACES_WITH_ID_SUCCESSFUL:
    case DATA_DELETE_ESTIMATE_EQUIPMENT_WITH_EQUIPMENTID_SUCCESSFUL:
    case DATA_DELETE_SPACES_EQUIPMENT_WITH_ID_SUCCESSFUL: {
      const {
        payload: {
          listId,
          listStoreId,
          listItem,
        }
      } = action
      const storeId = listStoreId || listId
      const { listItems } = state[storeId]

      return {
        ...state,
        [storeId]: {
          ...state[storeId],
          listItems: reduce(listItems, (result: Object, nextListItem: Object) => {
            if (nextListItem.id === listItem.id) {
              return result
            }
            return {
              ...result,
              [nextListItem.id]: {
                ...nextListItem,
                modifiedColumn: ''
              }
            }
          }, {})
        }
      }
    }


    case DATA_PATCH_SPACES_EQUIPMENT_WITH_ID_SUCCESSFUL:
    case DATA_PATCH_SPACES_SURFACES_WITH_ID_SUCCESSFUL: {
      const {
        payload: {
          listId,
          listItem
        }
      } = action
      const { listItems } = state[listId]
      return {
        ...state,
        [listId]: {
          ...state[listId],
          listItems: reduce(listItems, (result: Object, nextListItem: Object) => {
            if (nextListItem.id === listItem.id) {
              return {
                ...result,
                [nextListItem.id]: {
                  ...nextListItem,
                  modifiedColumn: '',
                  columnData: {
                    ...nextListItem.columnData,
                    ...listItem.modifiedColumnData,
                  },
                }
              }
            }
            return {
              ...result,
              [nextListItem.id]: nextListItem
            }
          }, {})
        }
      }
    }
    case DATA_POST_SPACES_EQUIPMENT_WITH_ID_SUCCESSFUL:
    case DATA_POST_ESTIMATE_EQUIPMENT_WITH_EQUIPMENTID_SUCCESSFUL:
    case DATA_POST_SPACES_SURFACES_WITH_ID_SUCCESSFUL: {
      const {
        payload: {
          listId,
          listItem
        }
      } = action
      const { listItems } = state[listId]
      return {
        ...state,
        [listId]: {
          ...state[listId],
          listItems: {
            ...listItems,
            [listItem.id]: listItem,
          }
        }
      }
    }

    case CLEAR_LIST: {
      const { payload: { listId } } = action
      const { [listId]: omittedList, ...newState } = state

      return newState
    }

    case CLEAR_SINGLE_ROW: {
      const { payload: { listId, listItemId, propertyNames } } = action
      const listContent = { ...state[listId] }
      propertyNames.forEach((propertyName: string) => {
        delete listContent.listItems[listItemId].columnData[propertyName]
        if (listContent.listItems[listItemId].columnUnits && listContent.listItems[listItemId].columnUnits[propertyName]) {
          delete listContent.listItems[listItemId].columnUnits[propertyName]
        }
      })

      return {
        ...state,
        [listId]: { ...listContent }
      }
    }

    case SET_MOCK_LIST_TO_STORE: {
      const {
        payload: {
          listStoreId,
          listItems,
          columns
        }
      } = action
      return {
        ...state,
        [listStoreId]: {
          listItems,
          columns,
        }
      }
    }

    case SET_RENOVATION_PROFILE: {
      const { payload: { newColumnData, listStoreId, rowId } } = action
      const originalRow = state[listStoreId].listItems[rowId]
      return {
        ...state,
        [listStoreId]: {
          ...state[listStoreId],
          isListModified: true,
          listItems: {
            ...state[listStoreId].listItems,
            [rowId]: {
              ...state[listStoreId].listItems[rowId],
              columnData: {
                // Setting renovation profile contains only Matrix cell data
                // so leave Description (Selite) and AreaTotalM2 (m2 yhteensä) columns untouched
                Description: originalRow.columnData.Description,
                AreaTotalM2: originalRow.columnData.AreaTotalM2,
                ...newColumnData
              },
              modifiedColumnData: newColumnData,
              showModifiedIndicatorColumns: Object.keys(newColumnData)
                .reduce((result: { [columnName: string]: boolean }, columnName: string): {[columnName: string]: boolean} => ({
                  ...result, [columnName]: false
                }), {}),
              defaultColumnData: newColumnData
            },
          },
        }
      }
    }

    case SET_LIST_COLUMNS: {
      const {
        payload: {
          listStoreId,
          columns
        }
      } = action
      return {
        ...state,
        [listStoreId]: {
          ...(state[listStoreId] || {}),
          columns,
        }
      }
    }

    case MERGE_LIST_ITEMS: {
      const {
        payload: {
          listStoreId,
          listItems,
          mergeOptions
        }
      } = action
      return mergeListItems({
        state,
        listId: listStoreId,
        newListItems: listItems,
        options: mergeOptions,
      })
    }

    case SET_LIST_ITEM_DEFAULTS: {
      const {
        payload: {
          listItemId,
          listStoreId,
          defaults
        }
      } = action
      const {
        [listStoreId]: {
          listItems: {
            [listItemId]: {
              defaultColumnData = {}
            }
          }
        }
      } = state
      return {
        ...state,
        [listStoreId]: {
          ...state[listStoreId],
          listItems: {
            ...state[listStoreId].listItems,
            [listItemId]: {
              ...state[listStoreId].listItems[listItemId],
              defaultColumnData: {
                ...defaultColumnData,
                ...defaults
              }
            }
          }
        }
      }
    }

    case FILTER_LIST_ITEMS_WITH_FILTER_FN: {
      const { payload: { listStoreId, filterFn } } = action
      const { [listStoreId]: { listItems, filteredListItems: filteredListItemsFromStore } } = state
      const items = filteredListItemsFromStore || listItems

      if (!items) {
        console.error(`No list items nor filtered list items found with store id of ${listStoreId}`)
        return state
      }

      const { listFilter, filteredTypes } = state[listStoreId]
      const initialItems = listFilter ? getSearchFilteredListItems(items, listFilter, filteredTypes) : items

      const filteredListItems = Object.keys(initialItems).reduce((result: Object, listItemId: string) => {
        const listItem = initialItems[listItemId]
        if (filterFn(listItem)) return { ...result, [listItemId]: listItem }
        return result
      }, {})

      return {
        ...state,
        [listStoreId]: { ...state[listStoreId], filteredListItems }
      }
    }

    case FILTER_LIST_ITEMS_BY_COLUMN_DATA: {
      const {
        payload: {
          listStoreId,
          filterColumnDataKey,
          filterColumnDataValue
        }
      } = action
      const {
        [listStoreId]: {
          listItems
        }
      } = state
      if (!listItems) {
        console.error(`No list items found with store id of ${listStoreId}`)
        return state
      }
      const { listFilter, filteredTypes } = state[listStoreId]
      const initialItems = listFilter ? getSearchFilteredListItems(listItems, listFilter, filteredTypes) : listItems
      const filteredListItems = getColumnDataFilteredListItems(initialItems, filterColumnDataKey, filterColumnDataValue)

      return {
        ...state,
        [listStoreId]: {
          ...state[listStoreId],
          filteredListItems,
          filterColumnDataKey,
          filterColumnDataValue
        }
      }
    }

    case FILTER_WOP_ACTIVITY_STRUCTURE_BY_SPACES: {
      const { listItems, activityFilter } = state[ACTIVITY_STRUCTURE]
      const { filterIds } = activityFilter || {}

      return {
        ...state,
        [ACTIVITY_STRUCTURE]: {
          ...state[ACTIVITY_STRUCTURE],
          filteredListItems: filterListBySpaces(listItems, filterIds)
        }
      }
    }

    case FILTER_WOP_SPACE_SCHEDULE_BY_ACTIVITIES: {
      const {
        [WOP_SPACE_SCHEDULE]: {
          listItems
        },
        [ACTIVITY_STRUCTURE]: {
          listItems: activityStructureListItems,
          activeFilterSource
        }
      } = state

      const activityGroupIds = []
      traverseListItem(activityStructureListItems, activeFilterSource, (row: TVDWOPListItem): void => {
        if (row.type.toUpperCase() === ACTIVITYGROUP) activityGroupIds.push(row.wopItemId)
      })

      return {
        ...state,
        [WOP_SPACE_SCHEDULE]: {
          ...state[WOP_SPACE_SCHEDULE],
          filteredListItems: filterListByActivities(listItems, activityGroupIds)
        }
      }
    }

    case CLEAR_LIST_ITEMS_COLUMN_DATA_FILTER: {
      const { payload: { listStoreId } } = action
      const singleListStore = state[listStoreId]
      if (!singleListStore) {
        console.error(`No list store found with ${listStoreId}`)
        return state
      }
      const {
        listFilter,
        listItems,
        filteredTypes,
      } = singleListStore
      const {
        filteredListItems,
        filterColumnDataKey,
        filterColumnDataValue,
        ...restOfSingleListStore
      } = singleListStore
      if (filteredListItems && listFilter) {
        const filteredListItemsWithoutColumnDataFilter = getSearchFilteredListItems(listItems, listFilter, filteredTypes)
        return {
          ...state,
          [listStoreId]: {
            ...restOfSingleListStore,
            filteredListItems: filteredListItemsWithoutColumnDataFilter
          }
        }
      }
      return {
        ...state,
        [listStoreId]: restOfSingleListStore,
      }
    }

    case CLEAR_LIST_ITEMS_FILTER: {
      const { payload: { listStoreId } } = action
      const singleListStore = state[listStoreId]
      if (!singleListStore) {
        console.error(`No list store found with ${listStoreId}`)
        return state
      }

      if (isEmpty(state[listStoreId].filteredListItems)) {
        // allows clearFilteredListItems to be called without crashing even if it already is empty
        return state
      }
      const {
        filteredListItems,
        filterColumnDataKey,
        filterColumnDataValue,
        listItems
      } = singleListStore
      let newFilteredListItems
      // if there is columnData filters, filterColumnDataKey and filterColumnDataValue, we get filtered items with them and without the listFilter as that is now being cleared
      if (filterColumnDataKey && filterColumnDataValue) {
        newFilteredListItems = getColumnDataFilteredListItems(listItems, filterColumnDataKey, filterColumnDataValue)
      }
      // merging filtered and current list items to keep the isOpen status
      const newState = mergeListItems({
        state,
        newListItems: newFilteredListItems || filteredListItems,
        listId: listStoreId,
        options: {
          useNewListItemOpenStatus: true
        }
      })
      const { filteredListItems: unusedFilteredListItems, listFilter, ...restOfNewListStore } = newState[listStoreId]
      return {
        ...newState,
        [listStoreId]: {
          ...restOfNewListStore,
          ...(newFilteredListItems ? { filteredListItems: newFilteredListItems } : {})
        },
      }
    }

    case SET_ACTIVITY_FILTER: {
      const { payload: { sourceListId, filteredListId, row } } = action
      if (isEmpty(row) || !sourceListId || !filteredListId) {
        console.error(`Missing one of the required data: sourceListId "${sourceListId}", filteredListId "${filteredListId}" or row: "${row}"`)
        return state
      }

      return {
        ...state,
        [sourceListId]: {
          ...state[sourceListId],
          activityFilter: undefined, // cannot exist at the same time with activeFilterSource in the same list
          activeFilterSource: row
        },
        [filteredListId]: {
          ...state[filteredListId],
          activeFilterSource: undefined, // cannot exist at the same time with activityFilter in the same list
          activityFilter: row,
        }
      }
    }
    case CLEAR_ACTIVITY_FILTERS: {
      const fieldsToClear = [
        'activityFilter',
        'filteredListItems',
        'activeFilterSource',
      ]

      const clearedFiltersFromActivityStructure = omit(state[ACTIVITY_STRUCTURE], fieldsToClear)
      const clearedFiltersFromWopSpaceSchedule = omit(state[WOP_SPACE_SCHEDULE], fieldsToClear)

      return {
        ...state,
        [ACTIVITY_STRUCTURE]: clearedFiltersFromActivityStructure,
        [WOP_SPACE_SCHEDULE]: clearedFiltersFromWopSpaceSchedule
      }
    }

    case SET_PROCESS_ACTIVITY_ITEMS: {
      const {
        payload: {
          listStoreId,
          listItems,
          columns
        }
      } = action
      return {
        ...state,
        [listStoreId]: {
          listItems,
          columns,
        }
      }
    }
    case SET_BUILDINGS_LIST_TO_STORE: {
      const { listStoreId, buildingsList, columns } = action.payload

      return {
        ...state,
        [listStoreId]: {
          ...(state[listStoreId] || {}),
          listItems: buildingsList,
          columns
        }
      }
    }

    case UPDATE_LIST_LAST_UPDATED: {
      const { payload: { listStoreId } } = action
      const listLastUpdated = new Date().getTime()
      const listStore = state[listStoreId]

      if (!listStore) {
        console.error(`Could not find list to update listLastUpdated with listStoreId of ${listStoreId}`)
        return state
      }

      return {
        ...state,
        [listStoreId]: {
          ...listStore,
          listLastUpdated

        }
      }
    }

    case CLOSE_ALL_LIST_ITEMS: {
      const { payload: { listStoreId } } = action
      const listStore = state[listStoreId]
      if (!listStore) {
        console.error(`No list found with listStoreId of ${listStoreId}`)
        return state
      }
      const { listItems } = listStore

      return {
        ...state,
        [listStoreId]: {
          ...listStore,
          listItems: Object.keys(listItems).reduce((prev: TVDListItems, listItemId: string) => ({
            ...prev,
            [listItemId]: {
              ...listItems[listItemId],
              isOpen: false
            }
          }), {})
        }
      }
    }

    case REMOVE_ADDED_LIST_ITEMS: {
      const { payload: { listStoreId } } = action
      const listStore = state[listStoreId]

      if (!listStore) {
        console.error(`No list found with listStoreId of ${listStoreId}`)
        return state
      }

      const { listItems } = listStore

      return {
        ...state,
        [listStoreId]: {
          ...listStore,
          listLastModifiedTS: new Date().getTime(),
          listItems: Object.keys(listItems).reduce((withoutAddedListItems: TVDListItems, listItemId: string) => {
            const listItem = listItems[listItemId]
            const { isUserAdded, isAdded } = listItem
            if (isUserAdded || isAdded) {
              return withoutAddedListItems
            }
            return {
              ...withoutAddedListItems,
              [listItemId]: listItem
            }
          }, {})
        }
      }
    }

    default:
      break
  }

  return reduceSuccessfulEmbeddedActions({
    action,
    state,
    conditionFn: () => action && action.payload && (action.payload.listId || action.payload.listStoreId),
    cb: ({ schemaKey, newState }: ReduceSuccessfulEmbeddedActionsCallbackArguments) => {
      // Trying to convert from using listId to listStoreId to support *StoreId naming schema
      const { payload: { listId, listStoreId } } = action
      const storeId = listId || listStoreId
      switch (schemaKey) {
        case ACTIVITY_SCHEDULE_ITEM:
        case BUILDING_TASK_SCHEDULE_ITEM:
        case ELEMENT_SCHEDULE_ITEM:
        case SCHEDULE_ITEM:
        case SPACE_SCHEDULE_ITEM:
        case GROUPING_SCHEDULE_ITEM:
        case OPERATING_PROFILE_SCHEDULE_ITEM: {
          const {
            payload: {
              parsedResponse: newListItems,
              modifiedListItem,
              mergeOptions,
              columns
            },
            config
          } = action

          return mergeListItems({
            state: newState,
            listId: storeId,
            newListItems: modifiedListItem ? { [modifiedListItem.id]: modifiedListItem } : newListItems,
            config,
            columns,
            options: mergeOptions
          })
        }
        // Columns have schema of ValueProperty
        case VALUE_PROPERTY: {
          const { parsedResponse, addedColumns = [] } = action.payload
          const actionTypesToShowUnitsInColumns = [
            DATA_GET_SPACE_SCHEDULE_COLUMNS_WITH_ESTIMATEID_SUCCESSFUL,
            DATA_GET_COMMON_FUNCTIONS_COLUMNS_WITH_FUNCTIONTYPE_SUCCESSFUL
          ]
          const showUnitsInColumns = includes(actionTypesToShowUnitsInColumns, action.type)
          return {
            ...state,
            [storeId]: {
              ...state[storeId],
              columns: {
                ...(showUnitsInColumns
                  ? parsedResponse.map((column: Object) => (column.unit ? { ...column, localizedName: column.unit } : column))
                  : parsedResponse),
                ...addedColumns.reduce((result: Object, column: Object) => ({ ...result, [column.propertyName]: column }), {})
              }
            }
          }
        }
        // MeasureTopics and Activities have schema of Enumeral
        case ENUMERAL: {
          if (storeId === ACTIVITY_REGISTRY_WIDGET) {
            const { parsedResponse = {} } = action.payload
            return {
              ...state,
              [storeId]: {
                ...state[storeId],
                listItems: parsedResponse
              }
            }
          }
          const { parsedResponse = [] } = action.payload
          const columns = parsedResponse.map(({ value, localizedName, _embedded }: TVDEnum): TVDListItemColumn => ({
            dataType: '',
            propertyName: value,
            localizedName,
            _embedded,
          }))
          return {
            ...state,
            [storeId]: {
              ...state[storeId],
              columns
            }
          }
        }
        default:
          return newState
      }
    }
  })
}
