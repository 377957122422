// @flow
// Copyright © 2010–2023 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.
import { SET_ACTIVE_REAL_ESTATE, RESET_ACTIVE_REAL_ESTATE } from '../actions/activeRealEstate'

const initialState: TVDActiveEstimateStore = null

export default function activeRealEstateReducer(state: TVDActiveEstimateStore = initialState, action: TVDAction): TVDActiveEstimateStore {
  switch (action.type) {
    case SET_ACTIVE_REAL_ESTATE: {
      const { payload: { activeRealEstate } } = action
      return activeRealEstate
    }

    case RESET_ACTIVE_REAL_ESTATE: return null

    default: return state
  }
}
