// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.

import { compose } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import React, { Component } from 'react'

import { UNIT_POST_FIX } from '../../../constants/attributes'
import { closeModal } from '../../../actions/modals'
import {
  commonIgnoredCharacters,
} from '../../../constants/validationConstants'
import { modifyListItem } from '../../../actions/list'
import DropdownMenu from '../menus/DropdownMenu/DropdownMenu'
import LabeledInput from '../LabeledInput/LabeledInput'
import ModalForm from '../../common/ModalForm/ModalForm'

type DispatchProps = {|
  dispatchCloseModal: () => void, // close current modal with the modalId from props
  dispatchModifyListItem: (value: string) => void // edit the list item's unit in store
|}

type ModifyListItemProps = {|
  listId: string, // list id in Store
  listItemId: string, // id of a listItem
  columnName: string, // propertyName of the column we are editing the unit in
|}

type ReceivedProps = {|
  modalId: string, // modalId that is in ReduxStore and used to close the modal
  initialValue: string, // the initial value of the unit before editing
  modifyListItemProps: ModifyListItemProps, // details required when editing listItem
  unitOptions?: Array<TVDMenuItem> // predefined options user can select instead of giving the unit in an input field
|}

type Props = {|
  ...DispatchProps,
  ...ReceivedProps,
  t: Function, // translation function
|}

type State = {|
  unitValue: string, // controlled input field value
  unitValid: boolean, // if the input field is considered valid
|}

type ItemTypes = Array<React$Element<typeof LabeledInput | typeof DropdownMenu>>

export class UnitEditingModal extends Component<Props, State> {
  static defaultProps = {
    unitOptions: null
  }

  state = {
    unitValue: '',
    unitValid: false,
  }

  componentDidMount = () => {
    const { initialValue } = this.props
    this.setState({ unitValue: initialValue })
  }

  onClose = () => {
    const { dispatchCloseModal } = this.props
    dispatchCloseModal()
  }

  handleInputChange = (e: SyntheticInputEvent<any>) => {
    this.setState({ unitValue: e.target.value })
  }

  handleSave = () => {
    const { dispatchModifyListItem, dispatchCloseModal } = this.props
    const { unitValue } = this.state
    dispatchModifyListItem(unitValue)
    dispatchCloseModal()
  }

  getItems = (): ItemTypes => {
    const { unitOptions } = this.props
    return unitOptions ? [this.getDropdown()] : [this.getInputField()]
  }

  getHelperText = (): string => {
    const { t, initialValue } = this.props
    return t('widgets._UNIT_DEFAULT_', { defaultValue: initialValue })
  }

  getInputField = (): LabeledInput => {
    const { t } = this.props
    const { unitValue } = this.state
    return (
      <LabeledInput
        dataType='string'
        ignoreCharacters={commonIgnoredCharacters}
        focused
        id='SingleInputModal-inputField'
        handleChange={this.handleInputChange}
        label={t('widgets._UNIT_')}
        isValidCb={(isValid: boolean) => { this.setState({ unitValid: isValid }) }}
        required
        helperText={this.getHelperText()}
        value={unitValue} />
    )
  }

  getDropdown = (): React$Element<typeof DropdownMenu> => {
    const { unitOptions } = this.props
    return (
      <DropdownMenu
        helperText={this.getHelperText()}
        onChange={(selectedUnitValue: string) => {
          this.setState({ unitValue: selectedUnitValue, unitValid: true })
        }}
        items={unitOptions} />
    )
  }

  render(): React$Element<any> {
    const { t } = this.props
    const { unitValid } = this.state
    return (
      <ModalForm
        testId='UnitEditingModal'
        items={this.getItems()}
        valid={unitValid}
        onSave={() => { this.handleSave() }}
        onClose={() => { this.onClose() }}
        saveButtonText={t('buttons._SAVE_')} />
    )
  }
}

const mapDispatchToProps = (dispatch: Function, { modalId, modifyListItemProps }: ReceivedProps): DispatchProps => ({
  dispatchCloseModal: () => { dispatch(closeModal(modalId)) },
  dispatchModifyListItem: (value: string) => {
    dispatch(modifyListItem({
      ...modifyListItemProps,
      columnName: `${modifyListItemProps.columnName}${UNIT_POST_FIX}`,
      value,
    }))
  }
})

export default compose(
  withTranslation('translations'),
  connect(null, mapDispatchToProps)
)(UnitEditingModal)
