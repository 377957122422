// @flow
// Copyright © 2010–2022 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.
// Received from haahtela-api/apis_required/common/feature-control/HaahtelaFeatureControl.proto

// 0 is unknown as per grpc enum guidelines
export const SIMULATIONRESULT_UNKNOWN = 0

// Hintatieto - Uudisrakentaminen
export const SIMULATIONRESULT_SPACE_PRICING_DATA_NEW_PRICE = 1

// Hintatieto - Korjausrakentaminen
export const SIMULATIONRESULT_SPACE_PRICING_DATA_RENOVATION = 2

// CO2 - Uudisrakentaminen
export const SIMULATIONRESULT_CO2_NEW_CONSTRUCTION = 3

// CO2 - Korjausrakentaminen
export const SIMULATIONRESULT_CO2_RENOVATION = 4

