// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.

import React, { Component } from 'react'
import { withStyles } from '@material-ui/core'

const styles = ({ palette: { skeleton } }: TVDTheme) => ({
  skeleton: {
    height: '12px',
    width: '100%',
    backgroundColor: skeleton,
    borderRadius: '4px',
    'animation-timing-function': 'ease-in-out',
    animation: '$pulse 1.5s ease-in-out infinite',
    '&:after': {
      content: ' ',
    }
  },
  '@keyframes pulse': {
    '0%': {
      opacity: '0.4',
    },
    '50%': {
      opacity: '1',
    },
    '100%': {
      opacity: '0.4',
    }
  },
  wrapper: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
  }
})

type Props = {|
  classes: Object, // withStyles styles prop
  width?: number | string, //  width of the whole Skeleton; both wrapper and the inner width containing CSS animated div
  skeletonHeight?: number | string, //  height of only the Skeleton; height containing CSS animated div
|}

class Skeleton extends Component<Props> {
  static defaultProps = {
    width: null,
    skeletonHeight: null,
  }

  render(): React$Element<any> {
    const { classes, width, skeletonHeight } = this.props
    return (
      <div data-testid='skeleton-wrapper' style={{ width }} className={classes.wrapper}>
        <div data-testid='skeleton' style={{ height: skeletonHeight }} className={classes.skeleton} />
      </div>
    )
  }
}

export default withStyles(styles)(Skeleton)
