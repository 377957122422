// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withTranslation } from 'react-i18next'
import { withStyles } from '@material-ui/core/styles'
import { typographyClasses, colors } from 'frontend-assets'

import SentientHOC from '../../../../../hocs/SentientHOC/SentientHOC'
import {
  getRenovationSpacesProfilesWithEstimateIdRequestDefinitions,

} from '../../../../../../utils/generated-api-requests/spaces_renovation'
import PropertyContainer from '../../../../../common/Tabs/Properties/PropertyContainer/PropertyContainer'
import DropdownMenu from '../../../../../common/menus/DropdownMenu/DropdownMenu'
import type { MenuItemProps } from '../../../../../common/menus/components/MenuItems/MenuItem/MenuItem'
import FeaturesHOC from '../../../../../hocs/FeaturesHOC/FeaturesHOC'

const { h4 } = typographyClasses
const { dark80 } = colors

const styles = () => ({
  header: {
    ...h4,
    color: dark80,
    padding: '32px 24px 24px'
  },
  dropdown: {
    paddingLeft: '30px'
  },
})

type ReceivedProps = {|
    onProfileChange: (string) => void // callback function triggered when renovation profile is saved
|}

type Props = {|
  ...ReceivedProps,
  t: (string) => string, // i18n translation function
  type: string, // type of widget used to call proper request
  classes: Object, // withStyles classes object
  sentient: TVDSentient, // Object providing helpers via SentientHOC

|}

type State = {|
  profileItems: Array<MenuItemProps>, // array of profileIds
  profileId: string, // Id of selected profile
|}

export class RenovationProfileContainer extends Component<Props, State> {
  static defaultProps = {
    type: ''
  }
  state = {
    profileItems: [],
    profileId: '',
  }

  componentDidMount() {
    const { sentient, onProfileChange } = this.props
    sentient.runSetup({
      getRenovationSpacesProfilesWithEstimateIdRequestDefinitions: {
        successCb: (profileItems: Array<Object>) => {
          this.setState({
            profileItems,
            profileId: profileItems[0].value
          })
          onProfileChange(profileItems[0].value)
        }
      }
    })
  }

  handleChange = (value: string) => {
    this.setState({
      profileId: value,
    })
    this.props.onProfileChange(value)
  }

  getDropDownContents = (): React$Element<typeof DropdownMenu> => (
    <DropdownMenu
      items={this.state.profileItems}
      onChange={this.handleChange}
      defaultValue={this.state.profileId} />
  )

  render(): React$Element<any> {
    const { classes, t, type } = this.props
    return (
      <div>
        <div className={classes.header}>
          {t('addRenovationModal._SELECT_PROFILE_')}
        </div>
        <div className={classes.dropdown}>
          {this.getDropDownContents()}
        </div>
        <div className={classes.header}>
          {t('addRenovationModal._PROFILE_PROPERTIES_')}
        </div>
        <div>
          {this.state.profileId.length > 0 &&
          <PropertyContainer
            type={type}
            modalId={type}
            resourceId={this.state.profileId}
            propertiesStoreId={type}
            staticValue />
          }
        </div>
      </div>
    )
  }
}

const sentientConfig: TVDSentientConfig = {
  getSetupRequestDefinitions: (): TVDGARConfigs =>
    ({
      getRenovationSpacesProfilesWithEstimateIdRequestDefinitions: getRenovationSpacesProfilesWithEstimateIdRequestDefinitions({
        payload: {},
      })
    })
}


export default compose(
  withTranslation('translations'),
  connect(),
  withStyles(styles),
  FeaturesHOC
)(SentientHOC(RenovationProfileContainer, sentientConfig))
