// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.
import * as React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'
import { map } from 'lodash'
import { setLanguageCode } from '../../../actions/app'
import { type TVDSupportedLanguageCodes, supportedLanguageCodes } from '../../../constants/languageCodes'
import { postPolling } from '../../../actions/postPolling'
import theme from '../../../styles/theme'

const styles = ({ palette }: Object): Object => ({
  container: {
    display: 'inline',
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  clickable: {
    height: '19px',
    width: '19px',
    margin: '0 16px 0 0',
    color: palette.dark80,
    ...theme.typography.classes.bodyBig,
    textAlign: 'center',
    cursor: 'pointer',
    textTransform: 'uppercase',
    fontWeight: '300',
    '&:focus': {
      outline: '0px solid transparent'
    },
    '&:last-child': {
      marginRight: '0px'
    }
  },
  selected: {
    ...theme.typography.classes.bodyBigBold, // makes the selected item a wee bit bold
  }
})

type DispatchProps = {|
  dispatchSetLanguageCode: (languageCode: TVDSupportedLanguageCodes) => void // sets language code to Store for GAR usage
|}
type Props = {|
  ...DispatchProps,
  classes: Object, // withStyles classes object
  i18n: Object
|}

type State = {
  selected: string // the currently selected localization
}

class LanguagePicker extends React.Component<Props, State> {
  state = {
    selected: this.props.i18n.language // props as a seed for initial selected language
  }

  get languageLinks(): Array<React$Element<'span'>> {
    const { i18n, classes, dispatchSetLanguageCode } = this.props
    const { selected } = this.state
    const linkstyle = classes.clickable

    const handleClick = (selection: string) => {
      this.setState({ selected: selection })
      i18n.changeLanguage(selection)
      dispatchSetLanguageCode(this.getSupportedLanguageWithI18n(selection))
    }

    return (
      map(i18n.options.availableLanguages, (lang: string, index: number) => (
        <span
          role='button'
          tabIndex={0}
          onClick={() => { handleClick(lang) }}
          className={selected === lang ? `${linkstyle} ${classes.selected}` : linkstyle}
          key={index}>
          {lang}
        </span>
      ))
    )
  }

  getSupportedLanguageWithI18n = (i18nLanguage: string): TVDSupportedLanguageCodes => {
    switch (i18nLanguage) {
      case 'fi': return supportedLanguageCodes.fi
      case 'en': return supportedLanguageCodes.en
      case 'sv': return supportedLanguageCodes.sv
      default: {
        console.error(`No supported language with i18n language selection: ${i18nLanguage} defaulting to fi`)
        return supportedLanguageCodes.fi
      }
    }
  }

  render(): React$Element<'div'> {
    const { classes } = this.props
    return (
      <div className={classes.container}>
        { this.languageLinks }
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch: Function) => ({
  dispatchSetLanguageCode: (languageCode: TVDSupportedLanguageCodes) => {
    dispatch(setLanguageCode(languageCode))
    dispatch(postPolling())
  }
})

export default compose(
  withTranslation('translations'),
  withStyles(styles),
  connect(null, mapDispatchToProps)
)(LanguagePicker)
