// @flow
// Copyright © 2010–2023 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { loadMFE, registeredMFEs } from '../../../utils/MFEUtils'
import { API_BASE_URL, ALLOW_WITH_CREDENTIALS } from '../../../constants/envConstants'
import { openGenericErrorModal } from '../../../utils/modalUtils'
import { dispatch } from '../../../store'
import { postPolling } from '../../../actions/postPolling'
import { setActiveWidgetAsTopmost } from '../../../actions/app'

type Props = {|
  setIsTasksWidgetOpen: Function,
  isTasksWidgetOpen: boolean
|}

export const buildingElementsTasksWidgetMFERootId = 'building-elements-tasks-widget'

const BuildingElementsTasksWidgetMFEContainer = ({
  setIsTasksWidgetOpen,
  isTasksWidgetOpen
}: Props): null => {
  const {
    realEstateId,
    accessToken,
    haahtelaApiUserToken,
    userId,
    languageCode,
    selectedAccountId,
    userPrivileges,
    licenseType,
    realEstateName,
    activeEstimate: activeBuildingElementsEstimate,
    activeEdit,
    activeCalculation,
    widgetOrder
  } = useSelector(({
    activeEstimate,
    app,
    user,
  }: TVDReduxStore): {|
    languageCode: $PropertyType<TVDApplicationStore, 'languageCode'>,
    selectedAccountId: $PropertyType<TVDApplicationStore, 'selectedAccountId'>,
    accessToken: $PropertyType<TVDUserAuthorization, 'accessToken'>,
    haahtelaApiUserToken: $PropertyType<TVDUserClaims, 'haahtelaApiUserToken'>,
    userId: $PropertyType<TVDUserClaims, 'userId'>,
    userPrivileges: $PropertyType<TVDUserStore, 'userPrivileges'>,
    licenseType: $PropertyType<TVDApplicationStore, 'licenseType'>,
    realEstateName: $PropertyType<TVDApplicationStore, 'realEstateName'>,
    activeEstimate: TVDActiveEstimateStore,
    realEstateId: $PropertyType<TVDApplicationStore, 'realEstateId'>,
    activeEdit: $PropertyType<TVDApplicationStore, 'activeEdit'>,
    activeCalculation: $PropertyType<TVDApplicationStore, 'activeCalculation'>,
    widgetOrder: $PropertyType<TVDApplicationStore, 'widgetOrder'>,
  |} => ({
    languageCode: app.languageCode,
    selectedAccountId: app.selectedAccountId,
    accessToken: user.authorization.accessToken,
    haahtelaApiUserToken: user.claims.haahtelaApiUserToken,
    userId: user.claims.userId,
    realEstateName: app.realEstateName,
    userPrivileges: user.userPrivileges,
    licenseType: app.licenseType,
    activeEstimate,
    realEstateId: app.realEstateId,
    activeEdit: app.activeEdit,
    activeCalculation: app.activeCalculation,
    widgetOrder: app.widgetOrder
  }))

  const estimateId = activeBuildingElementsEstimate?.id

  useEffect(() => {
    loadMFE('building_elements', 'building-elements-api-module')
  }, [])

  const updatePostPollingTS = () => {
    dispatch(postPolling())
  }

  const registeredBuildingElementsAPIModule = registeredMFEs['building-elements-api-module']
  useEffect(() => {
    if (
      languageCode &&
      accessToken &&
      haahtelaApiUserToken &&
      userId &&
      selectedAccountId &&
      userPrivileges &&
      estimateId &&
      realEstateId &&
      setIsTasksWidgetOpen
    ) {
      if (
        registeredBuildingElementsAPIModule &&
        registeredBuildingElementsAPIModule.run &&
        !!document.getElementById(buildingElementsTasksWidgetMFERootId)
      ) {
        const setWidgetAsTopmost = () => {
          if (widgetOrder[widgetOrder.length - 1] !== buildingElementsTasksWidgetMFERootId) {
            dispatch(setActiveWidgetAsTopmost(buildingElementsTasksWidgetMFERootId))
          }
        }
        registeredBuildingElementsAPIModule.run(
          'renderBuildingElementsWidget',
          [
            buildingElementsTasksWidgetMFERootId,
            {
              APIBaseURL: API_BASE_URL,
              languageCode,
              accessToken,
              haahtelaApiUserToken,
              userId,
              selectedAccountId,
              openGenericErrorModal,
              ALLOW_WITH_CREDENTIALS,
              realEstateId,
              setIsTasksWidgetOpen,
              isTasksWidgetOpen,
              estimateId,
              activeCalculation,
              activeEdit,
              updatePostPollingTS,
              setWidgetAsTopmost,
              widgetOrder
            }
          ]
        )
      }
    }
  }, [
    languageCode,
    accessToken,
    haahtelaApiUserToken,
    userId,
    selectedAccountId,
    userPrivileges,
    registeredBuildingElementsAPIModule,
    estimateId,
    licenseType,
    realEstateName,
    realEstateId,
    setIsTasksWidgetOpen,
    isTasksWidgetOpen,
    activeEdit,
    activeCalculation,
    widgetOrder
  ])
  return null
}

export default BuildingElementsTasksWidgetMFEContainer

