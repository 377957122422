// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.
import { getState, dispatch } from '../../store'
import {
  patchSpacesEquipmentWithIdRequest,
  deleteSpacesEquipmentWithIdRequest,
  patchSpacesSurfacesWithIdRequest,
  postSpacesSurfacesWithIdRequest,
  deleteSpacesSurfacesWithIdRequest,
  patchEstimateEquipmentWithEquipmentIdRequest,
  deleteEstimateEquipmentWithEquipmentIdRequest,
  postSpacesEquipmentWithIdRequest,
  patchBuildingElementsScheduleItemWithItemIdRequest,
  postEstimateEquipmentWithEquipmentIdRequest,
} from '../generated-api-requests/spaces'
import { postPolling } from '../../actions/postPolling'
import {
  saveList as saveListAction,
  undoModifyListItem,
  removeAddedListItems
} from '../../actions/list'
import { patchPriceitemWithIdRequest, deletePriceitemWithIdRequest } from '../generated-api-requests/buildingelements'

type SaveList = {|
  listId: string,
  patchRequest?: Object,
  postRequest?: Object,
  deleteRequest?: Object,
  callback?: Object,
|}

export const getArgsWithOptions = (requestObj: Object, listItem: Object) => {
  // set when the options is not the usual fourth index so the setting gets applied to request options object
  const optionsArgsIndex = requestObj.optionsIndex || 4
  const args = requestObj.getArgs(listItem)
  // setting all request to have this in their settings (fourth argument) for requestFn to not stop loading after each list request
  args[optionsArgsIndex] = { ...(args[optionsArgsIndex] || {}), disableRefreshEstimateLock: true }
  return args
}

export const saveList = async ({
  listId,
  patchRequest,
  postRequest,
  deleteRequest,
  callback,
}: SaveList) => {
  const { list: { [listId]: { listItems } } } = getState()
  dispatch(saveListAction())
  for (const listItemId in listItems) {
    if (listItems[listItemId]) {
      const listItem = listItems[listItemId]
      switch (true) {
        case listItem.isUserAdded:
        case listItem.isAdded:
          // eslint-disable-next-line no-await-in-loop
          if (postRequest) { await postRequest.getFn(listItem)(...getArgsWithOptions(postRequest, listItem)) }
          break
        case listItem.modifiedColumnData && Object.keys(listItem.modifiedColumnData).length > 0:
          // eslint-disable-next-line no-await-in-loop
          if (patchRequest) { await patchRequest.getFn(listItem)(...getArgsWithOptions(patchRequest, listItem)) }
          break
        case listItem.isRemoved:
          // eslint-disable-next-line no-await-in-loop
          if (deleteRequest) { await deleteRequest.getFn(listItem)(...getArgsWithOptions(deleteRequest, listItem)) }
          break
        default:
          break
      }
    }
  }
  if (callback) callback()
  dispatch(postPolling())
}

export const saveEquipmentRegistryList = (listId: string, spaceId: string) => {
  saveList({
    listId,
    patchRequest: {
      getFn: () => patchSpacesEquipmentWithIdRequest,
      getArgs: (listItem: Object) => [{
        path: { id: listItem.id, spaceId },
        body: { ...listItem.modifiedColumnData }
      }, { listId, listItem }]
    },
    postRequest: {
      getFn: () => (postSpacesEquipmentWithIdRequest),
      getArgs: (listItem: Object) => (listItem.isUserAdded ? [{
        path: { spaceId, id: listItem.parentId },
        body: { ...listItem.columnData, ...listItem.modifiedColumnData }
      }, { listId, listItem }] : [{
        path: { spaceId, id: listItem.parentId },
        query: { registryItemId: listItem.registryItemId },
        body: { ...listItem.columnData, ...listItem.modifiedColumnData }
      }, { listId, listItem }])
    },
    deleteRequest: {
      getFn: () => deleteSpacesEquipmentWithIdRequest,
      getArgs: (listItem: Object) => ([{ path: { id: listItem.id, spaceId } }, { listId, listItem }]),
    },
  })
}

export const saveAreaEquipmentRegistryList = (listId: string) => {
  saveList({
    listId,
    patchRequest: {
      getFn: () => patchEstimateEquipmentWithEquipmentIdRequest,
      getArgs: (listItem: Object) => ([{
        path: { equipmentId: listItem.id },
        body: { ...listItem.modifiedColumnData }
      }, { listId, listItem }])
    },
    postRequest: {
      getFn: () => postEstimateEquipmentWithEquipmentIdRequest,
      getArgs: (listItem: Object) => [{
        body: { ...listItem.columnData, ...listItem.modifiedColumnData },
        path: { equipmentId: listItem.parentId },
        // in area equipment, the listItem's id is not generated uuid like spaces equipment
        ...(listItem.isAdded ? { query: { registryItemId: listItem.id } } : {})
      }, { listId, listItem }]
    },
    deleteRequest: {
      getFn: () => deleteEstimateEquipmentWithEquipmentIdRequest,
      getArgs: (listItem: Object) => [{ path: { equipmentId: listItem.id } }, { listId, listItem }]
    },
    callback: () => {
      dispatch(removeAddedListItems(listId))
    }
  })
}

export const saveSurfacesRegistryList = (listId: string, spaceId: string) => {
  saveList({
    listId,
    patchRequest: {
      getFn: () => patchSpacesSurfacesWithIdRequest,
      getArgs: (listItem: Object) => [{ path: { id: listItem.id, spaceId }, body: { ...listItem.modifiedColumnData } }, { listId, listItem }]
    },
    postRequest: {
      getFn: () => postSpacesSurfacesWithIdRequest,
      getArgs: (listItem: Object) => [{
        path: { id: listItem.parentId, spaceId },
        query: { registryItemId: listItem.registryItemId },
        // TODO: fix to API either UnitPrice not being required or that we have the column for unitprice + mock data has unit price
        body: { ...listItem.columnData, ...listItem.modifiedColumnData }
      }, { listId, listItem }]
    },
    deleteRequest: {
      getFn: () => deleteSpacesSurfacesWithIdRequest,
      getArgs: (listItem: Object) => [{
        path: { id: listItem.id, spaceId }
      }, { listId, listItem }]
    },
  })
}

export const saveAssemblyList = (resourceId: string) => {
  saveList({
    listId: resourceId,
    patchRequest: {
      getFn: () => patchPriceitemWithIdRequest,
      getArgs: (listItem: Object) => ([{
        path: { id: listItem.id },
        body: { ...listItem.columnData, ...listItem.modifiedColumnData }
      }, { listId: resourceId, modifiedListItem: listItem }])
    },
    postRequest: {
      // TODO: post request when adding priceItem in assembly widget
    },
    deleteRequest: {
      getFn: () => deletePriceitemWithIdRequest,
      getArgs: (listItem: Object) => [{ path: { id: listItem.id } }, { listId: resourceId, listItem }]
    }
  })
}

export const saveBuildingElementsScheduleList = (listId: string) => {
  saveList({
    listId,
    patchRequest: {
      getFn: () => patchBuildingElementsScheduleItemWithItemIdRequest,
      getArgs: (listItem: Object) => [{
        path: { itemId: listItem.id },
        body: { ...listItem.modifiedColumnData },
      }, { listId, listItem }, null, dispatch(undoModifyListItem({ listId, listItem }))]
    },
  })
}
