// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.

import { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { loadMFE, registeredMFEs } from '../../../utils/MFEUtils'
import { API_BASE_URL, ALLOW_WITH_CREDENTIALS, FEATURE_SET } from '../../../constants/envConstants'
import { openGenericErrorModal } from '../../../utils/modalUtils'
import { dispatch } from '../../../store'
import { setCalculationActive, setCalculationComplete, setWidgetActive, setActiveWidgetAsTopmost } from '../../../actions/app'
import { postPolling } from '../../../actions/postPolling'
import { enableAllWidgets } from '../../../actions/widgets'
import { defaultLocation } from '../../../middlewares/widgetLocationMiddleware'
import { setMFEStates } from '../../../actions/MFEStates'
import {
  SPACE_EQUIPMENT_PRODUCT_ASSEMBLY_MFENAME,
  SPACE_EQUIPMENT_PRODUCT_ASSEMBLY_COMPONENTNAME
} from '../../../constants/MFEStateConstants'

export const spaceEquipmentProductAssemblyMFERootId = 'space-equipment-product-assembly-widget'

interface SpaceEquipmentProductAssemblyMFEContainerContentProps {
  spaceId: string,
 spaceEquipmentAssemblyId: string
}
interface SpaceEquipmentProductAssemblyMFEContainerProps {
  contentProps: SpaceEquipmentProductAssemblyMFEContainerContentProps
}
const SpaceEquipmentProductAssemblyMFEContainer = ({
  contentProps
}: SpaceEquipmentProductAssemblyMFEContainerProps): null => {
  const moduleOnUnmountRef = useRef<$PropertyType<TVDMFEFunctionality, 'onUnmount'>>()
  const {
    accessToken,
    haahtelaApiUserToken,
    userId,
    languageCode,
    selectedAccountId,
    activeRealEstate,
    activeEstimate: activeSpacesEstimate,
    postPollingTS,
    activeEdit,
    activeCalculation,
    isEstimateLockedToCurrentUser,
    widgetOrder,
    MFEStates,
    application
  } = useSelector(({
    activeEstimate,
    app,
    user,
    activeRealEstate: activeRealEstateStore,
    MFEStates: MFEStatesStore
  }: TVDReduxStore): {|
    application: $PropertyType<TVDApplicationStore, 'application'>,
    languageCode: $PropertyType<TVDApplicationStore, 'languageCode'>,
    selectedAccountId: $PropertyType<TVDApplicationStore, 'selectedAccountId'>,
    accessToken: $PropertyType<TVDUserAuthorization, 'accessToken'>,
    haahtelaApiUserToken: $PropertyType<TVDUserClaims, 'haahtelaApiUserToken'>,
    userId: $PropertyType<TVDUserClaims, 'userId'>,
    activeEstimate: TVDActiveEstimateStore,
    postPollingTS: $PropertyType<TVDApplicationStore, 'postPollingTS'>,
    activeEdit: $PropertyType<TVDApplicationStore, 'activeEdit'>,
    activeCalculation: $PropertyType<TVDApplicationStore, 'activeCalculation'>,
    isEstimateLockedToCurrentUser: $PropertyType<TVDApplicationStore, 'isEstimateLockedToCurrentUser'>,
    activeRealEstate: TVDActiveRealEstateStore,
    widgetOrder: $PropertyType<TVDApplicationStore, 'widgetOrder'>,
    MFEStates: TVDMFEStatesStore
  |} => ({
    application: app.application,
    languageCode: app.languageCode,
    selectedAccountId: app.selectedAccountId,
    accessToken: user.authorization.accessToken,
    haahtelaApiUserToken: user.claims.haahtelaApiUserToken,
    userId: user.claims.userId,
    activeEstimate,
    postPollingTS: app.postPollingTS,
    activeEdit: app.activeEdit,
    activeCalculation: app.activeCalculation,
    isEstimateLockedToCurrentUser: app.isEstimateLockedToCurrentUser,
    activeRealEstate: activeRealEstateStore,
    widgetOrder: app.widgetOrder,
    MFEStates: MFEStatesStore
  }))

  useEffect(() => () => {
    if (moduleOnUnmountRef.current) {
      moduleOnUnmountRef.current()
    }
  }, [application])

  const MFEState = MFEStates[SPACE_EQUIPMENT_PRODUCT_ASSEMBLY_MFENAME] || {}

  const setIsWidgetOpen = () => {
    dispatch(setMFEStates(
      SPACE_EQUIPMENT_PRODUCT_ASSEMBLY_MFENAME,
      false,
      SPACE_EQUIPMENT_PRODUCT_ASSEMBLY_COMPONENTNAME,
      null
    ))
  }

  const updatePostPollingTS = () => {
    dispatch(postPolling())
  }

  const dispatchSetCalculationActive = () => {
    dispatch(setCalculationActive())
  }

  const dispatchSetCalculationComplete = () => {
    dispatch(setCalculationComplete())
  }

  const dispatchSetWidgetActive = (active: boolean) => {
    if (active) {
      dispatch(setWidgetActive(SPACE_EQUIPMENT_PRODUCT_ASSEMBLY_MFENAME))
    } else {
      dispatch(enableAllWidgets())
    }
  }

  const registeredSpacesAPIModule = registeredMFEs['containers-space-equipment-product-assembly-module']

  const {
    spaceId = null,
    spaceEquipmentAssemblyId = null
  } = contentProps

  useEffect(() => {
    if (
      languageCode &&
      accessToken &&
      haahtelaApiUserToken &&
      userId &&
      selectedAccountId &&
      activeSpacesEstimate &&
      spaceEquipmentAssemblyId &&
      activeRealEstate &&
      spaceId
    ) {
      const render = (module: TVDMFEFunctionality): void => {
        if (module.render) {
          const setWidgetAsTopmost = () => {
            if (widgetOrder[widgetOrder.length - 1] !== spaceEquipmentProductAssemblyMFERootId) {
              dispatch(setActiveWidgetAsTopmost(spaceEquipmentProductAssemblyMFERootId))
            }
          }
          moduleOnUnmountRef.current = module.onUnmount
          module.render(
            spaceEquipmentProductAssemblyMFERootId,
            {
              APIBaseURL: API_BASE_URL,
              languageCode,
              accessToken,
              haahtelaApiUserToken,
              userId,
              selectedAccountId,
              ALLOW_WITH_CREDENTIALS,
              openGenericErrorModal,
              activeRealEstate,
              isWidgetOpen: MFEState.isOpen,
              setIsWidgetOpen,
              setWidgetAsTopmost,
              widgetOrder,
              defaultLocation,
              isEstimateLockedToCurrentUser,
              estimateId: activeSpacesEstimate.id,
              spaceEquipmentAssemblyId,
              postPollingTS,
              updatePostPollingTS,
              activeEdit,
              activeCalculation,
              setCalculationActive: dispatchSetCalculationActive,
              setCalculationComplete: dispatchSetCalculationComplete,
              setIsWidgetActive: dispatchSetWidgetActive,
              spaceId,
              activeEstimate: activeSpacesEstimate,
              FEATURE_SET
            }
          )
        }
      }
      if (registeredSpacesAPIModule) {
        render(registeredSpacesAPIModule)
      } else {
        loadMFE('containers_product_assembly', 'containers-space-equipment-product-assembly-module', (module: TVDMFEFunctionality): void => {
          render(module)
        })
      }
    }
  }, [
    languageCode,
    accessToken,
    haahtelaApiUserToken,
    userId,
    selectedAccountId,
    activeSpacesEstimate,
    activeRealEstate,
    MFEState.isOpen,
    widgetOrder,
    postPollingTS,
    spaceEquipmentAssemblyId,
    activeEdit,
    activeCalculation,
    registeredSpacesAPIModule,
    isEstimateLockedToCurrentUser,
    spaceId
  ])
  return null
}

export default SpaceEquipmentProductAssemblyMFEContainer
