// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.
import Axios from 'axios'
import { runGARs } from '../../utils/GARUtils'
import { saveListDone } from '../list'
import { enableAllWidgets } from '../widgets'
import { refreshEstimateLock, setCalculationComplete, updatePostPollingTS } from '../app'
import { refreshUserAccessToken } from '../user'
import { clearAllPatchOperations } from '../patchOperations'


export const DATA_POST_POLLING_SUCCESSFUL = 'DATA_POST_POLLING_SUCCESSFUL'
export function postPollingSuccessful(): Object {
  return {
    type: DATA_POST_POLLING_SUCCESSFUL
  }
}

export const DATA_POST_POLLING_INIT = 'DATA_POST_POLLING_INIT'
export const DATA_POST_POLLING_START = 'DATA_POST_POLLING_START'

type TVDPostPollingOptions = {
  preventEnableAllWidgets?: boolean, // does not allow widget's to be enabled after post polling
  ignoreExcludeFromPostPollingIds?: Array<string>, // sentientStoreIds that, if they have excludeFromPostPolling true, will be still used in this run of post polling exceptionally, but the setting remains
}

export function postPolling(options?: TVDPostPollingOptions = {}, updateDataCb?: Function): Object {
  return (dispatch: Function, getState: Function) => {
    dispatch({ type: DATA_POST_POLLING_START }) // to see when post polling starts
    const { preventEnableAllWidgets, ignoreExcludeFromPostPollingIds = [] } = options
    const { list, sentients, app: { isEstimateLockedToCurrentUser } } = getState()

    const postPollingDone = () => {
      refreshUserAccessToken(() => {
        if (updateDataCb) updateDataCb()
        if (!preventEnableAllWidgets) dispatch(enableAllWidgets())
        if (isEstimateLockedToCurrentUser) dispatch(refreshEstimateLock())
        dispatch(setCalculationComplete())
        dispatch(clearAllPatchOperations())
        dispatch(postPollingSuccessful())
        dispatch(updatePostPollingTS()) // to see when post polling starts
      })
    }
    const hasSentients = Object.keys(sentients).length > 0
    if (hasSentients) {
      const getSentientRequests = (): Array<Promise<any>> => Object.keys(sentients).reduce((result: Object, sentientStoreId: string) => {
        const { [sentientStoreId]: { requestDefinitions, excludeFromPostPolling } } = sentients
        if (excludeFromPostPolling && !ignoreExcludeFromPostPollingIds.includes(sentientStoreId)) return result
        return [...result, ...runGARs(requestDefinitions)]
      }, [])
      Axios.all(getSentientRequests()).then(() => {
        Object.keys(list).forEach((listKey: string) => { dispatch(saveListDone(listKey)) })
        postPollingDone()
      })
    } else { postPollingDone() }
  }
}

