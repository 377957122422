// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.

const getValueProperties = () => ({
  _embedded: {
    properties: [
      {
        propertyName: 'QuantitySpacesPcs',
        localizedName: 'Tilat',
        unit: 'kpl',
        dataType: 'number',
        value: 52,
        readOnly: false,
        hidden: false,
        userModified: false
      },
      {
        propertyName: 'AreaTotalM2',
        localizedName: 'Huoneala',
        unit: 'hum²',
        dataType: 'number',
        value: 728.0000000000001,
        readOnly: false,
        hidden: false,
        userModified: false
      }
    ]
  }
})


const getEnum = () => ({
  _embedded: {
    enumeral: [
      {
        value: 'enumeralValue',
        localizedName: 'enumeralLocalizedNameMock'
      },
    ]
  }
})

const getBuildingTaskScheduleColumns = () => ({
  _embedded: {
    properties: [
      {
        propertyName: 'Description',
        localizedName: 'Mock Description',
        dataType: 'string',
      },
      {
        propertyName: 'Quantity',
        localizedName: 'Mock quantity',
        unit: 'kpl',
        dataType: 'number',
        readOnly: false,
        hidden: false,
        userModified: false
      }
    ]
  }
})

const getCommonFunctions = () => ({
  _embedded: {
    items: [
      {
        type: 'functionalsector',
        id: '8913c3a4-93d8-4a49-8608-2d75cac6a119',
        columnData: {
          Description: 'Yhteistoiminnot',
        },
        referenceId: 'b14e795a-1b1e-4675-a2d8-b00bdc43c0c5',
      },
      {
        type: 'function',
        id: 'fa231f46-c840-4ea1-a1e0-6ab570991408',
        parentId: '8913c3a4-93d8-4a49-8608-2d75cac6a119',
        columnData: {
          Description: 'Osastoiva liikenne',
          AreaTotal: 377,
        },
        referenceId: '10cbb314-c256-4566-8e6f-ae6d94f9930f',
      },
      {
        type: 'spacegroup',
        id: '319dcd69-c97e-4786-8a1d-9d61d1f854a5',
        parentId: 'fa231f46-c840-4ea1-a1e0-6ab570991408',
        columnData: {
          Description: 'Osastoiva liikenne',
          Amount: 1,
          Area: 377,
          AreaTotal: 377,
        },
        referenceId: '5843d2ec-0805-458a-aeac-f000d497cbf3',
      },
      {
        type: 'space',
        id: '98f91b4a-84ee-4d8a-b629-ea134f820858',
        parentId: '319dcd69-c97e-4786-8a1d-9d61d1f854a5',
        columnData: {
          Description: 'Porrasaula',
          Amount: 1,
          AreaPerAmount: 54.6,
          Area: 54.6,
          AreaTotal: 54.6,
        },
        referenceId: 'f041908d-652a-450b-8d3b-c3613346d703',
      },
      {
        type: 'space',
        id: 'b2351d47-101d-46fd-8ccc-7d3952a41888',
        parentId: '319dcd69-c97e-4786-8a1d-9d61d1f854a5',
        columnData: {
          Description: 'Tuulikaappi',
          Amount: 1,
          AreaPerAmount: 11.3,
          Area: 11.3,
          AreaTotal: 11.3,
        },
        referenceId: 'ca8644b8-0b9f-408d-afc5-7e20cda15ba4',
      },
    ],
  },
})

const mockResponses = {
  getImportScheduleValuesWithEstimateIdRequest: {
    status: 200,
    data: getValueProperties()
  },
  getOperatingProfileScheduleWithEstimateIdRequest: {
    status: 200,
    data: {
      _embedded: {
        items: [
          {
            id: 'item1',
            columnData: {
              Description: 'Apuvälinevarastointi',
              OperatingProfile: 1,
              WeeksInAYear: 12,
              DaysInAWeek: 3,
              HoursInADay: 5,
              UseageDegreeGoal: 75,
              Topic: 'Topic'
            },
            columnMeta: {
              OperatingProfile: {
                localizedName: 'Profiili 1',
                userModified: false
              }
            }
          },
          {
            id: 'item2',
            columnData: {
              Description: 'Apuvälinevarastointi 2',
              OperatingProfile: 2,
              WeeksInAYear: 1,
              DaysInAWeek: 5,
              HoursInADay: 12,
              UseageDegreeGoal: 31,
              Topic: 'Topic1'
            },
            columnMeta: {
              OperatingProfile: {
                localizedName: 'Profiili 1',
                userModified: false
              }
            }
          },
          {
            id: 'item3',
            columnData: {
              Description: 'Apuvälinevarastointi 3',
              OperatingProfile: 2,
              WeeksInAYear: 1,
              DaysInAWeek: 5,
              HoursInADay: 12,
              UseageDegreeGoal: 31,
              Topic: 'Topic1'
            },
            columnMeta: {
              OperatingProfile: {
                localizedName: 'Profiili 1',
                userModified: false
              }
            }
          }
        ]
      }
    }
  },
  getScheduleValuesWithEstimateIdRequest: {
    status: 200,
    data: getValueProperties()
  },
  getPriceitemCanMoveToWithIdRequest: {
    status: 200,
    data: getEnum()
  },
  // getReferenceScheduleEstimatesWithEstimateIdRequest: {
  // status: 200,
  // data: getEnum()
  // },
  getAssemblyPropertiesWithIdRequest: {
    status: 200,
    data: getValueProperties()
  },
  getBuildingTasksScheduleColumnsWithEstimateIdRequest: {
    status: 200,
    data: getBuildingTaskScheduleColumns()
  },
  putScheduleWithEstimateIdRequest: {
    status: 202,
    data: {}
  },
  getCommonFunctionsWithFunctionTypeRequest: {
    status: 200,
    data: getCommonFunctions()
  },
  postCommonFunctionsWithFunctionTypeRequest: {
    status: 200,
    data: {}
  },
  getPricelistItemAssemblyPropertiesWithEstimateIdRequest: {
    status: 200,
    data: {
      _embedded: {
        properties: [
          {
            propertyName: 'Amount',
            localizedName: 'Määrä',
            dataType: 'integer',
            unit: 'm²',
            value: 396,
          },
          {
            propertyName: 'Width',
            localizedName: 'Leveys',
            dataType: 'number',
            unit: 'm',
            value: 1.25,
          },
          {
            propertyName: 'Height',
            localizedName: 'Korkeus',
            dataType: 'number',
            unit: 'm',
            value: 1.6,
          },
        ],
      },
    }
  },
}

export const getMockResponse = (actions: TVDActions) => {
  const { start: { meta: { fnName } = {} } } = actions
  return mockResponses[fnName]
}

export const hasMockImplementation = (actions: TVDActions) => {
  const { start: { meta: { fnName } = {} } } = actions
  return typeof mockResponses[fnName] !== 'undefined'
}

export const getMockRenovationScheduleItems = () => ({
  listItemOne: {
    id: 'listItemOne',
    parentId: null,
    userModified: false,
    isOpen: false,
    canHaveChildren: true,
    columnData: {
      Description: 'Mock descriptions',
      SquareTotal: 600
    },
    type: 'item',
    _links: {
      self: {},
      items: { href: '' }
    },
  },
  listItemTwo: {
    parentId: 'listItemOne',
    id: 'listItemTwo',
    columnData: {
      SquareTotal: 600,
      Description: 'Mock description',
      RenovationProfile: 'Profiili 1'
    },
    type: 'item',
    _links: {
      self: {},
      items: { href: '' }
    },
  },
})

export const getMockRenovationColumns = () => [
  {
    propertyName: 'Description',
    localizedName: 'Mock Description Column',
    dataType: 'string',
  },
  {
    propertyName: 'SquareTotal',
    localizedName: 'm² yhteensä',
    dataType: 'number',
  },
  {
    propertyName: 'RenovationProfile',
    localizedName: 'Korjausprofiili',
    dataType: 'string'
  },
]
