// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.
import React, { Fragment } from 'react'
import { withTranslation } from 'react-i18next'
import { withStyles } from '@material-ui/core/styles'
import { map, find } from 'lodash'
import Icon from '@material-ui/core/Icon'
import SvgIcon from '@material-ui/core/SvgIcon'
import { typographyClasses, colors } from 'frontend-assets'

import SVGVerticalSlider from '../Sliders/SVGVerticalSlider'
import InputField from '../../../../common/InputField/InputField'
import { designModelSliderColors } from '../../../../../constants/moduleConstants'
import { formatValue } from '../../../../../utils/listUtils'
import { black } from '../../../../../../node_modules/frontend-assets/src/theme/colors'

// arbitrary number that dictates in what relation pixels correspond to meters in levels sliders.
// (story.height needs to match slider.height visually as well as their indicated values in UI)
export const SVG_HEIGHT = 1180
const ARCHITECTURE_SECTION_HEIGHT = 227 // height from bottom of the Editor container to bottom of the Architecture container.
const { h4 } = typographyClasses
const { dark80 } = colors

const styles = ({ palette, typography }: Object): Object => ({
  root: {
    padding: '34px 20px 0 35px',
    width: '100%',
    position: 'relative'
  },
  header: {
    ...h4,
    color: dark80,
    padding: '24px 0px 32px 0px'
  },
  levels: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    gap: '6px'
  },
  slidersContainer: {
    display: 'flex',
    padding: '17px 0'
  },
  legend: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '5px',
    font: `14px ${typography.fontFamilyBase}`,
    color: palette.nevada
  },
  legendIcon: {
    display: 'flex',
    justifyContent: 'center',
    marginRight: '15px',
  },
  origoline: {
    position: 'absolute',
    overflow: 'visible',
    height: '10px'
  },
  miniEditor: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '60px',
    height: '30px',
  },
  unit: {
    ...typographyClasses.bodyDefault,
    color: colors.dark60,
    paddingLeft: '3px',
    paddingBottom: '4px'
  }
})

type Props = {
  classes: Object, // withStyles classes object
  t: Function, // i18n translate function
  updateVerticalSlider: (string, number, number) => void, // function to handle changes of vertical sliders
  patchSlider: Function, // function to patch sliders value
  verticalSliders: Array<TVDSVGVerticalSlider>, // array of sliders that are rendered as SVGVerticalSlider components
  disabled: boolean, // if is disabled and styles get applied for the disabled state
  levelsTop: number, // the amount of pixels that level graphs should be from top of the container
}

type State = {|
  origoY: number, // the Y value how far from the top origo line should be positioned
|}

export class Levels extends React.Component<Props, State> {
  rootRef: TVDRef = React.createRef()
  slidersRef: TVDRef = React.createRef()

  state = {
    origoY: 0
  }

  componentDidMount = () => {
    this.setState({ origoY: this.getOrigoY() })
  }

  componentDidUpdate = (prevProps: Props) => {
    const { levelsTop } = this.props
    if (levelsTop !== prevProps.levelsTop) {
      this.setState({ origoY: this.getOrigoY() })
    }
  }

  get vertical(): Array<SVGVerticalSlider> | null {
    const { origoY } = this.state

    if (origoY <= 0) return null

    const {
      verticalSliders,
      updateVerticalSlider,
      patchSlider,
      disabled
    } = this.props

    return map(verticalSliders, (slider: Object) => {
      const baselineVar = slider.id !== 'LevelOfOriginalGroundSurfaceM'
        ? origoY - verticalSliders[3].height
        : origoY
      return (
        <SVGVerticalSlider
          key={slider.id}
          id={slider.id}
          disabled={disabled}
          height={slider.height}
          baselineY={baselineVar}
          update={updateVerticalSlider}
          svgHeight={SVG_HEIGHT}
          sliders={verticalSliders}
          patchSlider={patchSlider}
          origoLevel={origoY}
          graphHeight={origoY + ARCHITECTURE_SECTION_HEIGHT}
          miniEditor={this.getMiniEditor(slider.id)} />
      )
    })
  }

  get legends(): React$Element<any> {
    const { classes, t, verticalSliders } = this.props
    const legendIconWidth = { width: '40px' }
    return (
      <Fragment>
        <div className={classes.legend}>
          <SvgIcon style={{ ...legendIconWidth, fontSize: '23px' }} className={classes.legendIcon}>
            <path d='M0 12v1h23v-1h-23z' width='5' />
          </SvgIcon>
          <span>{t('designModel._LOWEST_FLOOR_LEVEL_')}</span>
        </div>
        {map(verticalSliders, (slider: TVDSVGVerticalSlider) => (
          <div key={`${slider.id}-legend`} className={classes.legend}>
            <Icon className={classes.legendIcon} style={{ ...legendIconWidth, fontSize: '15px', color: designModelSliderColors[slider.id] }}>
              brightness_1
            </Icon>
            <span>{t(`designModel._${slider.id}_`)}</span>
          </div>
        ))}
      </Fragment>
    )
  }

  get origoLine(): React$Element<any> | null {
    const { origoY } = this.state
    if (origoY <= 0) return null
    return (
      <svg className={this.props.classes.origoline} id='origoLine'>
        <line stroke={black} strokeWidth='1' x1='0' y1={origoY} x2='300' y2={origoY} />
      </svg>
    )
  }

  handleInputValue = (slider: Object, inputValue: number | string) => {
    const formattedValue = formatValue(inputValue, 'number')

    // do type check because formatValue function may return a string
    if (typeof formattedValue === 'number' && formattedValue !== slider.value) {
      this.props.updateVerticalSlider(slider.id, formattedValue * SVG_HEIGHT / 100, formattedValue)
      this.props.patchSlider(slider, formattedValue, 'verticalSliders')
    }
  }

  getOrigoY(): number {
    const { levelsTop } = this.props
    const rootRefOffsetHeight = this.rootRef.current?.offsetHeight || 0
    const sliderRefOffsetHeight = this.slidersRef.current?.offsetHeight || 0

    return (levelsTop - (rootRefOffsetHeight - sliderRefOffsetHeight)) - 14.5
  }

  getMiniEditor(sliderId: string): React$Element<any> {
    const slider = find(this.props.verticalSliders, (vSlider: TVDSVGVerticalSlider) => vSlider.id === sliderId)

    return (
      <div key={`${sliderId}-miniEditor`} id={`${sliderId}-miniEditor`} className={this.props.classes.miniEditor}>
        <InputField
          id={`${sliderId}-InputField`}
          disabled={this.props.disabled}
          dataType='number'
          onChange={(inputValue: number) => { this.handleInputValue(slider, inputValue) }}
          initialValue={formatValue(slider.value, 'number')} />
        <p className={this.props.classes.unit}>m</p>
      </div>
    )
  }

  render(): React$Element<any> {
    const { classes, t } = this.props

    return (
      <div ref={this.rootRef} className={classes.root}>
        <div className={classes.header}>{t('designModel._LEVELS_HEADER_')}</div>
        <div className={classes.levels}>
          {this.legends}
          <div ref={this.slidersRef} className={classes.slidersContainer}>
            {this.vertical}
            {this.origoLine}
          </div>
        </div>
      </div>
    )
  }
}

export default withTranslation('translations')(withStyles(styles, { withTheme: true })(Levels))
