// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.

import React, { Component } from 'react'
import { Divider } from '@material-ui/core'
import { map } from 'lodash'

import MenuItem from './MenuItem/MenuItem'
import MenuItemWithSubMenu from './MenuItemWithSubMenu/MenuItemWithSubMenu'
import OverflowTooltip from '../../../OverflowTooltip/OverflowTooltip'

type Props = {|
  items: Array<TVDMenuItem>, // array of item props from which to create MenuItems
  placeholder?: string, // placeholder text that will be given to an invisible placeholder MenuItem so it'll show in the menu input
  handleClose?: Function | null, // function from MenuCores to handle menu closing
  checkboxItems?: boolean, // creates checkboxed items instead of the normal ones
  children: ({ menuItems: Array<MenuItem> }) => React$Element<any>, // render prop function as a child which is called with the created MenuItems
  renderSkeletons?: boolean, // indicates if we need to render placeholder Skeletons
  selected?: string | number, // value of the selected menu item
  disabled?: boolean, // if we set all menu items as disabled
  menuTitle: ?React$Element<any>, // should the menuItems have a title on top of them
|}

export class MenuItems extends Component<Props> {
  static defaultProps = {
    items: [],
    handleClose: null,
    checkboxItems: false,
    placeholder: '',
    renderSkeletons: false,
    selected: '',
    disabled: false,
    menuTitle: undefined
  }

  items(): Array<MenuItem> {
    const {
      items,
      handleClose,
      checkboxItems,
      placeholder,
      renderSkeletons,
      selected,
      disabled,
      menuTitle
    } = this.props

    // no actual items so to avoid returning just a titleItem or a divider, return an empty array
    if (!items || !items.length) return []

    const result = map(items, (item: TVDMenuItem, index: number) => {
      if (item.divider) return <Divider style={{ margin: '8px 0' }} key='divider' />

      const menuItem: TVDMenuItem = {
        ...item,
        disabled: disabled || item.disabled,
        value: typeof item.value !== 'undefined' ? item.value : `MenuItem-${item.localizedName || 'test'}-${index}`,
        selected: item.value === selected
      }

      const { value, localizedNameSelected, localizedName } = item
      const displayText = (value === selected) && localizedNameSelected ? localizedNameSelected : localizedName
      const displayTextOverflowing = <OverflowTooltip tooltipText={displayText}>{displayText}</OverflowTooltip>

      if (menuItem.subMenuItems) {
        return (
          <MenuItemWithSubMenu
            {...menuItem}
            renderSkeleton={renderSkeletons}
            checkbox={checkboxItems}
            key={index}
            value={item.value}
            handleClose={handleClose}>
            {displayTextOverflowing}
          </MenuItemWithSubMenu>
        )
      }
      return (
        <MenuItem
          {...menuItem}
          renderSkeleton={renderSkeletons}
          checkbox={checkboxItems}
          key={index}
          value={item.value}
          handleClose={handleClose}>
          {displayTextOverflowing}
        </MenuItem>
      )
    })

    // splice in a placeholder if required
    if (placeholder) {
      result.splice(
        0,
        0,
        <li
          style={{ display: 'none' }}
          key='placeholder'
          value='placeholder'>
          {placeholder}
        </li>
      )
    }

    if (menuTitle) result.unshift(React.cloneElement(menuTitle, { key: 'menuTitle' }))
    return result
  }

  render(): React$Element<any> {
    return this.props.children({ menuItems: this.items() })
  }
}

export default MenuItems
