// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.

import { SETUP_SENTIENT, UNSET_SENTIENT } from '../actions/sentients'


type SentientsStore = {
  +[sentientStoreId: string]: TVDSentientSetup
}
const initialState: SentientsStore = {}

export default function sentientsReducer(state: SentientsStore = initialState, action: Object = {}): Object {
  switch (action.type) {
    case SETUP_SENTIENT: {
      const { payload: { setup, sentientStoreId } } = action
      return {
        ...state,
        [sentientStoreId]: {
          ...setup,
        }
      }
    }

    case UNSET_SENTIENT: {
      const { payload: { sentientStoreId } } = action
      return Object.keys(state).reduce((newState: Object, stateSentientStoreId: string) => ({
        ...newState,
        ...(stateSentientStoreId !== sentientStoreId ? { [stateSentientStoreId]: state[stateSentientStoreId] } : {})
      }), {})
    }

    default:
      return state
  }
}
