// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.

export const UPDATE_SLIDER = 'UPDATE_SLIDER'
export function updateSlider(id: string, value: number, sliderGroup: string): Object {
  return {
    type: UPDATE_SLIDER,
    payload: { id, value, sliderGroup }
  }
}

export const UPDATE_VERTICAL_SLIDER = 'UPDATE_VERTICAL_SLIDER'
export function updateVerticalSlider(sliderId: string, height: number, value: number): Object {
  return {
    type: UPDATE_VERTICAL_SLIDER,
    payload: { sliderId, height, value }
  }
}

export const MODIFY_STORY_PROPERTY = 'MODIFY_STORY_PROPERTY'
export function modifyStoryProperty(propertyName: string, value: number | string): Object {
  return {
    type: MODIFY_STORY_PROPERTY,
    payload: { propertyName, value }
  }
}

export const CLEAR_DESIGN_MODEL = 'CLEAR_DESIGN_MODEL'
export function clearDesignModel(): Object {
  return {
    type: CLEAR_DESIGN_MODEL
  }
}

export const SET_UPDATE_PROTECTED_CIRCULATION = 'SET_UPDATE_PROTECTED_CIRCULATION'
export function setUpdateProtectedCirculation(value: boolean): Object {
  return {
    type: SET_UPDATE_PROTECTED_CIRCULATION,
    payload: { value }
  }
}
