// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.
import * as React from 'react'
import { withStyles } from '@material-ui/core/styles'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import Typography from '@material-ui/core/Typography'
import Icon from '@material-ui/core/Icon'
import theme from '../../../../styles/theme'

const styles = ({ palette }: TVDTheme) => ({
  heading: {
    flexBasis: '33.33%',
    flexShrink: 0,
    color: palette.dark80,
    ...theme.typography.classes.h5,
    paddingLeft: '16px'
  },
  expandable: {
    minHeight: '48px',
    boxShadow: 'none',
    // overwrites style rule .MuiExpansionPanel-root.Mui-expanded that is margin: 16px 0
    margin: '0px !important',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    '&:before': {
      content: 'none'
    }
  },
  bottomBorder: {
    borderBottom: `1px solid ${palette.gray60}`
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '2px',
    maxHeight: '550px',
    padding: 0,
  },
  icon: {
    color: palette.primary100,
    right: 20,
  }
})

type Props = {
  classes: Object, // withStyles classes object
  content: any, // any content shown in the panel details
  expanded: boolean,
  title: string,
  toggle: Function,
  id: string, // given id to the component's root div that can be used as test-id
}

const Expandable = (props: Props) => {
  const {
    classes, content, expanded, title, toggle, id
  } = props

  const expandable = id !== 'createCalculation-import' ? `${classes.expandable} ${classes.bottomBorder}` : classes.expandable

  return (
    <ExpansionPanel TransitionProps={{ appear: true }} data-testid={id} expanded={expanded} onChange={toggle} classes={{ root: expandable }} >
      <ExpansionPanelSummary classes={{ expandIcon: classes.icon }} expandIcon={<Icon>expand_more</Icon>}>
        <Typography className={classes.heading}>{title}</Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails className={classes.content}>
        {content}
      </ExpansionPanelDetails>
    </ExpansionPanel>
  )
}

export default withStyles(styles)(Expandable)
