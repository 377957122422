// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.
import {
  CREATE_OTHER_SPACES,
  CREATE_CIRCULATION,
  CREATE_FACILITY_SERVICES,
  CREATE_TECHNICAL_BUILDING_SERVICES,
  CREATE_PROTECTED_CIRCULATION,
} from '../constants/contentTypes'

export const getFooterTextByWidgetType = (widgetType: string): string | typeof undefined => {
  switch (widgetType) {
    case CREATE_OTHER_SPACES: {
      return 'createOtherSpaces._FOOTERTEXT_'
    }
    case CREATE_CIRCULATION: {
      return 'createOtherSpaces._CIRCULATION_FOOTER_TEXT_'
    }
    case CREATE_FACILITY_SERVICES: {
      return 'createOtherSpaces._FACILITY_SERVICES_FOOTER_TEXT_'
    }
    case CREATE_TECHNICAL_BUILDING_SERVICES: {
      return 'createOtherSpaces._TECHNICAL_BUILDING_SERVICES_FOOTER_TEXT_'
    }
    case CREATE_PROTECTED_CIRCULATION: {
      return 'createOtherSpaces._PROTECTED_CIRCULATION_FOOTER_TEXT_'
    }
    default:
      return undefined
  }
}
