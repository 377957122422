// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.

import { SET_SELECTED_ACCOUNT } from '../actions/app'

export const TVDSelectedAccountId = 'TVDSelectedAccountId'

export const updateSelectedAccountIdActionTypes = [SET_SELECTED_ACCOUNT]

export const SHOW_LANDING_PAGE = 'ShowLandingPage'
