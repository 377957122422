// @flow
// Copyright © 2010–2022 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.

import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles, Paper, IconButton } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { useTranslation } from 'react-i18next'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import { getLegalTermsOfServiceRequest } from '../../../utils/generated-api-requests/users'
import { getUsersAPIBasePath } from '../../../utils/apiUtils'
import { TVD_TOKEN_USER_TYPE_USER, TVD_HEADER_LANGUAGE } from '../../../constants/apiConstants'
import theme from '../../../styles/theme'
import { closeModal } from '../../../actions/modals'

type ReceivedProps = {|
  id: string, // unique identifier for the modal that can be used to close it via dispatch
|}

type Props = {|
  ...ReceivedProps,
|}

const useStyles = makeStyles(() => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    height: '854px',
    width: '740px',
    boxShadow: 'none',
    paddingBottom: '96px'
  },
  title: {
    ...theme.typography.classes.loginHeadline,
    textAlign: 'center',
    marginTop: '34px',
    marginBottom: '80px'
  },
  TOSTextWrapper: {
    overflowY: 'scroll',
    marginLeft: '85px',
    marginRight: '85px'
  },
  reactMarkDown: {
    '& p': theme.typography.classes.bodyDefault,
    '& h2': theme.typography.classes.h4,
    '& h3': theme.typography.classes.bodyBigBold,
    '& tr, td': theme.typography.classes.bodyDefault
  },
  '::-webkit-scrollbar': {
    width: '10px'
  },
  iconButton: {
    position: 'absolute',
    top: 0,
    right: 0,
    padding: '19px'
  }
}))

const TermsOfService = ({ id }: Props): React$Element<'div'> => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const { t } = useTranslation()
  const [TOSText, setTOSText] = useState('')
  const { languageCode, userType } = useSelector(({ app }: TVDReduxStore): TVDApplicationStore => app)

  useEffect(() => {
    const userAPIBasePath = getUsersAPIBasePath(userType || TVD_TOKEN_USER_TYPE_USER)
    const options = { basePath: userAPIBasePath }
    if (languageCode) {
      getLegalTermsOfServiceRequest({}, ({ termsText }: TVDTermsOfService) => {
        setTOSText(termsText)
      }, null, {
        ...options,
        headers: { [TVD_HEADER_LANGUAGE]: languageCode }
      })
    }
  }, [languageCode, userType])

  return (
    <div className={classes.wrapper}>
      <Paper className={classes.paper}>
        <div className={classes.title}>{t('termsOfService._TERMS_OF_SERVICE_')}</div>
        <div className={classes.TOSTextWrapper}>
          <ReactMarkdown
            remarkPlugins={[remarkGfm]}
            className={classes.reactMarkDown}>
            {TOSText}
          </ReactMarkdown>
        </div>
      </Paper>
      <IconButton
        className={classes.iconButton}
        onClick={() => {
        dispatch(closeModal(id))
      }}><CloseIcon />
      </IconButton>
    </div>
  )
}

export default TermsOfService
