// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.

import * as React from 'react'
import type { Element } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core'
import CalculationsList from './CalculationsList/CalculationsList'
import Import from './Import/Import'
import Expandable from './Expandable/Expandable'
import CalculationInformation from '../CalculationInformationContainer/components/CalculationInformation'
import { getPathNameFromURL } from '../../../utils/apiUtils'
import { ESTIMATE_TYPE_SPACE, ESTIMATE_TYPE_BUILDING_ELEMENTS, ESTIMATE_TYPE_WOP } from '../../../constants/moduleConstants'
import { setCalculation } from '../../../actions/app'
import { getEstimateWithEstimateIdRequest as elementsGetEstimateWithEstimateIdRequest } from '../../../utils/generated-api-requests/buildingelements'
import { getEstimateWithEstimateIdRequest as spacesGetEstimateWithEstimateIdRequest } from '../../../utils/generated-api-requests/spaces'
import { getEstimateWithEstimateIdRequest as wopGetEstimateWithEstimateIdRequest } from '../../../utils/generated-api-requests/wop'
import Spinner from '../../common/Spinner/Spinner'
import { getEstimatesRequest } from '../../../utils/generated-api-requests/estimates'
import ErrorBoundary from '../../common/ErrorBoundary/ErrorBoundary'

const styles = () => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto'
  }
})

type MappedProps = {|
  activeCalculation?: boolean, // flag that indicates if calculation is running
  userId: $PropertyType<TVDUserClaims, 'userId'>, // users guid
|}

type HOCProps = {|
  classes: Object, // withStyles style definitions
  t: (string) => string, // i18next translation function
|}

type DispatchProps = {|
  dispatchSetEstimateId: (string) => void, // sets estimateId to store
|}

type Props = {|
  ...MappedProps,
  ...HOCProps,
  ...DispatchProps,
  calculations: Object, // module calculations object
  app: string, // app name
  closeFn: Function, // function to fire when the accordion is to close
  estimateType: string, // the type of estimate for this app type
  onSave: Function, // function to save calculation on create new Expandable
  selectedBuildingId: string, // ID of selected building
|}

type State = {
  expanded: ?string | boolean // indicates which expansion panel is to be expanded
}

export class CreateCalculation extends React.Component<Props, State> {
  static defaultProps = {
    activeCalculation: false
  }

  state = {
    expanded: 'panel1',
  }

  handleChange = (panel: string) => (event: Object, expanded: string | boolean): void => {
    this.setState({ expanded: expanded ? panel : false })
  }

  calculationsEP(): Element<typeof Expandable> {
    const {
      t,
      app,
      calculations,
      estimateType,
      selectedBuildingId
    } = this.props

    return (
      <Expandable
        id='createCalculation-calculations-list'
        panel='panel1'
        toggle={this.handleChange('panel1')}
        expanded={this.state.expanded === 'panel1'}
        title={t('createCalculation._CALCULATIONS_').toUpperCase()}
        content={
          <ErrorBoundary>
            <CalculationsList
              closeFn={this.props.closeFn}
              selectedBuildingId={selectedBuildingId}
              app={app}
              estimateType={estimateType && estimateType.toUpperCase()}
              calculations={calculations} />
          </ErrorBoundary>
      } />
    )
  }

  createCalculationEP(): Element<typeof Expandable> {
    const { t, app, estimateType } = this.props

    return (
      <Expandable
        id='createCalculation-create-new'
        panel='panel2'
        toggle={this.handleChange('panel2')}
        expanded={this.state.expanded === 'panel2'}
        title={t('createCalculation._CREATE_NEW_').toUpperCase()}
        content={<CalculationInformation
          isExpanded={this.state.expanded === 'panel2'}
          testId='CreateNew'
          onSave={this.props.onSave}
          estimateType={estimateType}
          app={app}
          hideCancelButton
          showIsPrivateCheckbox />} />
    )
  }

  importCalculationEP(): Element<typeof Expandable> {
    const {
      t,
      estimateType,
      selectedBuildingId,
      userId
    } = this.props

    return (
      <Expandable
        id='createCalculation-import'
        panel='panel3'
        toggle={this.handleChange('panel3')}
        expanded={this.state.expanded === 'panel3'}
        title={t('createCalculation._IMPORT_').toUpperCase()}
        content={<Import
          userId={userId}
          importCb={(response: TVDRequestResponse) => this.getImportCalculationCb(response)}
          app={estimateType}
          selectedBuildingId={selectedBuildingId} />} />
    )
  }

  getEstimates() {
    const { estimateType, selectedBuildingId } = this.props
    getEstimatesRequest({ query: { estimateType, buildingId: selectedBuildingId } }, {}, () => {
      this.setState({ expanded: 'panel1' })
    })
  }

  getImportCalculationCb(response: TVDRequestResponse) {
    const { dispatchSetEstimateId, estimateType } = this.props
    const { headers: { location } } = response
    const pathName = getPathNameFromURL(location)
    const newId = pathName.split('/').pop()
    const cb = () => {
      dispatchSetEstimateId('')
    }
    dispatchSetEstimateId(newId)
    switch (estimateType) {
      case ESTIMATE_TYPE_SPACE:
        spacesGetEstimateWithEstimateIdRequest({}, cb)
        break
      case ESTIMATE_TYPE_BUILDING_ELEMENTS:
        elementsGetEstimateWithEstimateIdRequest({}, cb)
        break
      case ESTIMATE_TYPE_WOP:
        wopGetEstimateWithEstimateIdRequest({}, cb)
        break
      default:
        break
    }
    this.getEstimates()
  }

  render(): React$Element<'div'> {
    const { classes } = this.props
    return (
      <div className={classes.wrapper}>
        {this.props.activeCalculation && <Spinner /> }
        {this.calculationsEP()}
        {this.createCalculationEP()}
        {this.importCalculationEP()}
      </div>
    )
  }
}

const mapStateToProps = ({ app: { activeCalculation }, user: { claims: { userId } } }: TVDReduxStore): MappedProps => ({
  activeCalculation,
  userId
})

const mapDispatchToProps = (dispatch: Function): DispatchProps => ({
  dispatchSetEstimateId: (calculation: string) => dispatch(setCalculation(calculation))
})

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(withTranslation('translations')(CreateCalculation)))
