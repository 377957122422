// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core'
import { typographyClasses, colors } from 'frontend-assets'
import { compose } from 'redux'
import { withTranslation } from 'react-i18next'
import { TextButton } from 'frontend-components'
import type { TVDParserParameters } from '../../../../utils/parserMapper'
import DropdownMenu from '../../../common/menus/DropdownMenu/DropdownMenu'
import HierarchicalListContainer from '../../HierarchicalListContainer/HierarchicalListContainer'
import HamburgerMenu from '../../../common/menus/HamburgerMenu/HamburgerMenu'
import DescriptionCell from '../../../common/lists/common/DescriptionCell/DescriptionCell'
import { IMPORT_FROM_WOP_CALCULATION } from '../../../../constants/contentTypes'
import { postPolling } from '../../../../actions/postPolling'
import { buildAttachSpaceModal } from '../../../../actions/modals'
import {
  getImportScheduleIdWithEstimateIdRequest,
  getImportScheduleEstimatesWithEstimateIdRequest,
  putImportScheduleIdWithEstimateIdRequest,
  postSpaceScheduleWithEstimateIdRequest,
  getImportScheduleWithEstimateIdRequest,
  getImportScheduleColumnsWithEstimateIdRequest,
  getImportScheduleValuesWithEstimateIdRequest
} from '../../../../utils/generated-api-requests/spaces'
import { getEstimatesWithEstimateIdRequest } from '../../../../utils/generated-api-requests/estimates'
// $FlowFixMe
import { ReactComponent as CheckSingleSVG } from
  '../../../../../node_modules/frontend-assets/static/assets/images/icons/Check Single.svg'
// $FlowFixMe
import { ReactComponent as InfoTooltipSVG } from
  '../../../../../node_modules/frontend-assets/static/assets/images/icons/Data Table Info tooltip.svg'
import { importWopReferenceEstimateRequest } from '../../../../utils/ungenerated-api-requests/spaces'

const styles = (): Object => ({
  importFromWopCalculation: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    minHeight: '100%',
    height: '100%',
    overflowY: 'auto'
  },
  tools: {
    display: 'flex',
    paddingTop: '25px'
  },
  dropdown: {
    paddingLeft: '35px'
  },
  buttonWrapper: {
    padding: '10px 35px',
    display: 'inline-flex',
    alignItems: 'center'
  },
  placeholder: {
    display: 'flex',
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0 30px'
  },
  placeholderText: {
    ...typographyClasses.h1,
    color: colors.dark80,
    textAlign: 'center'
  },
  wrappedCellContent: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  statusContainer: {
    padding: '14px 0 14px 30px',
  },
  statusIndicator: {
    color: colors.primary120,
    paddingTop: '2px'
  },
  errorIcon: {
    marginRight: 8
  },
  iconContainer: {
    ...typographyClasses.bodySmall,
    color: colors.dark120,
    display: 'flex',
    alignItems: 'center',
    height: 26
  },
  refreshLink: {
    ...typographyClasses.bodySmallSemiBoldUnderline,
    color: colors.primary100,
    cursor: 'pointer',
    marginLeft: '4px',
  }
})

type DispatchProps = {|
  dispatchPostPolling: () => void, // initiates post polling
  dispatchBuildAttachSpaceModal: (Object) => void, // builds modal to attach space row to specific space-group
|}

type MappedProps = {|
  activeCalculation: boolean, // if the app is in the midst of a calculating
  selectedAccountId: $PropertyType<TVDApplicationStore, 'selectedAccountId'> // Id of the selected account
|}

type ReceivedProps = {|
  isEstimateLockedToCurrentUser: boolean, // if the space estimate is locked to current user
|}

type Props = {|
  ...DispatchProps,
  ...MappedProps,
  ...ReceivedProps,
  classes: Object, // withStyles classes object
  items: Array<TVDMenuItem>, // items for DropdownMenus
  t: (t: string) => string, // i18n translation function
|}

type State = {|
  dropdownItems: Array<TVDMenuItem>, // array of wop estimate items for dropdown
  selectedEstimate: string, // id of selected wop estimate
  isListOutDated: boolean, // if list is up to date with the latest changes
  referenceEstimateUpdated: boolean // if the reference estimate has been updated
|}

type ImportableWOPEstimate = {|
  localizedName: string, // name of the estimate
  value: string, // id of the estimate
|}

export class ImportFromWopCalculation extends Component<Props, State> {
  state = {
    dropdownItems: [],
    selectedEstimate: '',
    isListOutDated: false,
    referenceEstimateUpdated: false
  }

  componentDidMount() {
    getImportScheduleEstimatesWithEstimateIdRequest({}, (importableWOPEstimates: Array<ImportableWOPEstimate>) => {
      // TODO: Solve why we are not returning directly the referenceScheduleId through parsers
      getImportScheduleIdWithEstimateIdRequest({}, ({ HALParsedData: referenceScheduleId }: TVDParserParameters) => {
        this.setState({
          selectedEstimate: referenceScheduleId,
          dropdownItems: this.getDropdownItems(importableWOPEstimates)
        })
        this.onHierarchicalListCallBack()
      })
    })
    getEstimatesWithEstimateIdRequest(
      {},
      (estimate: TVDCalculation) => {
        this.setState({
          referenceEstimateUpdated: !!estimate.referenceEstimateUpdated,
          isListOutDated: !!estimate.referenceEstimateUpdated
        })
      },
      {
        estimateId: this.state.selectedEstimate,
        selectedAccountId: this.props.selectedAccountId
      }
    )
  }

  getDropdownItems = (estimates: Array<ImportableWOPEstimate>): Array<TVDMenuItem> => estimates.map((WOPEstimate: TVDMenuItem) => ({
    value: WOPEstimate.value,
    localizedName: WOPEstimate.localizedName,
    testid: `DropdownMenu-${WOPEstimate.localizedName}`,
    onClick: () => {
      const { selectedEstimate } = this.state
      if (selectedEstimate === WOPEstimate.value) this.onHierarchicalListCallBack(true)
    }
  }))

  getListStatusIndicator = () => {
    const { classes, t } = this.props
    const { isListOutDated, referenceEstimateUpdated } = this.state
    if (!referenceEstimateUpdated && !isListOutDated) {
      return null
    }
    return (
      <div className={classes.statusContainer}>
        <div className={classes.statusIndicator}>
          <span className={classes.iconContainer}>
            {!referenceEstimateUpdated && isListOutDated ? <CheckSingleSVG className={classes.errorIcon} /> : ''}
            {!referenceEstimateUpdated && isListOutDated ? t('importFromWopCalculation._IMPORT_UPDATED_') : ''}
            {referenceEstimateUpdated ? <InfoTooltipSVG /> : ''}
            {referenceEstimateUpdated ? t('importFromWopCalculation._IMPORT_OUTDATED_') : ''}
            {referenceEstimateUpdated ?
              <span
                className={classes.refreshLink}
                role='button'
                tabIndex={0}
                onClick={() => {
                  this.onHierarchicalListCallBack(true)
                  }}>
                {t('wopSpaceSchedule._UPDATE_BE_ESTIMATE_')}
              </span> : ''}
          </span>
        </div>
      </div>
    )
  }

  getTools = (): React$Element<any> => {
    const {
      classes,
      t,
      dispatchPostPolling,
      isEstimateLockedToCurrentUser,
      activeCalculation
    } = this.props
    const { selectedEstimate, dropdownItems } = this.state

    return (
      <div className={classes.tools}>
        <div className={classes.dropdown}>
          <DropdownMenu
            width='XXL'
            title={t('importFromWopCalculation._CHOOSE_ESTIMATE_')}
            defaultValue={selectedEstimate}
            items={dropdownItems}
            onChange={(estimateId: string) => {
              this.setState({ selectedEstimate: estimateId }, () => {
                this.onHierarchicalListCallBack(true)
              })
          }} />
        </div>
        <div className={classes.buttonWrapper}>
          <TextButton
            testId='import-from-wop-estimate-button'
            disabled={!isEstimateLockedToCurrentUser || !selectedEstimate || activeCalculation}
            text={t('importFromWopCalculation._IMPORT_FROM_WOP_ESTIMATE_')}
            onClick={() => {
              importWopReferenceEstimateRequest({}, dispatchPostPolling)
            }} />
        </div>
      </div>
    )
  }

  getPlaceHolder = (): React$Element<any> => {
    const { t, classes, items } = this.props
    const placeholderText = items ? t('importFromWopCalculation._NO_SELECTED_ITEM_') : t('importFromWopCalculation._NO_ITEMS_')
    return (
      <div className={classes.placeholder}>
        <span className={classes.placeholderText}>{placeholderText}</span>
      </div>
    )
  }

  runSelectedEstimateGETRequests = () => {
    const { selectedAccountId } = this.props
    const { selectedEstimate } = this.state
    getImportScheduleColumnsWithEstimateIdRequest({ listId: selectedEstimate })
    getImportScheduleWithEstimateIdRequest({ query: { listType: 'flat' } }, { listId: selectedEstimate })
    getImportScheduleValuesWithEstimateIdRequest(
      { resultBarKey: IMPORT_FROM_WOP_CALCULATION },
      undefined,
      undefined,
      { options: { estimateId: selectedEstimate } }
    )
    getEstimatesWithEstimateIdRequest(
      {},
      (estimate: TVDCalculation) => {
        this.setState({
          referenceEstimateUpdated: !!estimate.referenceEstimateUpdated
        })
      },
      {
        estimateId: selectedEstimate,
        selectedAccountId
      }
    )
  }

  onHierarchicalListCallBack = (shouldSaveSelectedEstimateId?: boolean) => {
    const { selectedEstimate } = this.state
    if (shouldSaveSelectedEstimateId) {
      putImportScheduleIdWithEstimateIdRequest({ body: selectedEstimate }, {}, () => {
        this.runSelectedEstimateGETRequests()
      })
    } else {
      this.runSelectedEstimateGETRequests()
    }
  }

  getContextMenuItems = (row: TVDListItem) => {
    const { t, dispatchBuildAttachSpaceModal, dispatchPostPolling } = this.props
    const contextMenuItems = {
      functionalSector: [{
        localizedName: t('importFromWopCalculation._IMPORT_SINGLE_WOP_FUNCTIONALSECTOR_'),
        onClick: () => postSpaceScheduleWithEstimateIdRequest({ body: { selectedIds: [row.id] } }, {}, dispatchPostPolling),
        testId: 'ContextMenuItem-import-single-wop-functionalsector'
      }],
      function: [{
        localizedName: t('importFromWopCalculation._IMPORT_SINGLE_WOP_FUNCTION_'),
        onClick: () => dispatchBuildAttachSpaceModal(row),
        testId: 'ContextMenuItem-import-single-wop-function'
      }],
      spaceGroup: [{
        localizedName: t('importFromWopCalculation._IMPORT_SINGLE_WOP_SPACEGROUP_'),
        onClick: () => dispatchBuildAttachSpaceModal(row),
        testId: 'ContextMenuItem-import-single-wop-spacegroup'
      }],
      space: [{
        localizedName: t('importFromWopCalculation._IMPORT_SINGLE_WOP_SPACE_'),
        onClick: () => dispatchBuildAttachSpaceModal(row),
        testId: 'ContextMenuItem-import-single-wop-space'
      }]
    }
    return contextMenuItems[row.type]
  }


  iconsContainer = (row: TVDListItem): React$Element<'div'> | null => {
    const { classes, isEstimateLockedToCurrentUser } = this.props
    return (
      <div className={classes.iconsContainer} data-visible_on_hover>
        {
          isEstimateLockedToCurrentUser &&
          <HamburgerMenu id='importFromWopCalculation' items={this.getContextMenuItems(row)} />
        }
      </div>
    )
  }

  render(): React$Element<any> {
    const { classes } = this.props
    const { selectedEstimate } = this.state

    return (
      <div className={classes.importFromWopCalculation}>
        { this.getListStatusIndicator() }
        { this.getTools() }
        { selectedEstimate ?
          <HierarchicalListContainer
            contextMenuItems={(row: Object) => this.getContextMenuItems(row)}
            disabled={false}
            wrappedCellContents={{
            Description: ({ row }: TVDWrappedCellCallbackParameters) => (
              <div className={classes.wrappedCellContent}>
                <DescriptionCell highlighted={row.level > 1} text={row.columnData.Description} />
                { this.iconsContainer(row) }
              </div>
            ),
            }}
            listId={selectedEstimate}
            listType={IMPORT_FROM_WOP_CALCULATION}
            testId='importFromWopCalculationList' />
          : this.getPlaceHolder()
        }
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch: Function): DispatchProps => ({
  dispatchPostPolling: () => { dispatch(postPolling()) },
  dispatchBuildAttachSpaceModal: (row: Object) => { dispatch(buildAttachSpaceModal(row)) }
})

const mapStateToProps = ({ app: { activeCalculation, selectedAccountId } }: TVDReduxStore): MappedProps => ({
  activeCalculation,
  selectedAccountId
})

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation('translations')
)(ImportFromWopCalculation)
