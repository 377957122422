// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.
import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { IconButton, Icon } from '@material-ui/core'
import Tooltip, { type TooltipAction } from '../Tooltip'
// $FlowFixMe
import { ReactComponent as UserDefined } from '../../../../../node_modules/frontend-assets/static/assets/images/icons/User_defined_tooltip.svg'
// $FlowFixMe
import { ReactComponent as Info } from '../../../../../node_modules/frontend-assets/static/assets/images/icons/infoTooltip.svg'
// $FlowFixMe
import { ReactComponent as Help } from '../../../../../node_modules/frontend-assets/static/assets/images/icons/Help_tooltip.svg'

/*
  INSTRUCTIONS:
  There are two different icons defined in this component:

  <IconTooltip content={'string or JSX as content'} action={{ label: 'label for the action', action: () => actionFunction}}>
    <IconTooltip.SimpleIcon icon='icon_name' />
  </IconTooltip>

  or

  <IconTooltip content={'string or JSX as content'} action={{ label: 'label for the action', action: () => actionFunction}}>
    <IconTooltip.ActionIcon icon='icon_name' />
  </IconTooltip>
*/

const styles = ({ palette }: Object): Object => ({
  iconHover: {
    cursor: 'default',
    '&:hover': {
      backgroundColor: palette.gray40
    }
  },
  iconClick: {
    cursor: 'pointer'
  },
  actionIcon: {
    color: palette.dark60,
    borderRadius: '50%'
  },
  simpleIcon: {
    color: palette.primary100,
    borderRadius: '50%'
  },
  iconButton: {
    padding: '5px'
  }
})

type Props = {
  classes: Object, // style object
  text: string, // content for the tooltip
  children: React$Element<any>, // the displayed icon: use either an IconTooltip.SimpleIcon or an IconTooltip.ActionIcon
  openWithClick?: Boolean, // whether the tooltip will open on hover, otherwise it'll open with a click
  onOpen?: () => {}, // function that is fired when the tooltip opens
  action?: TooltipAction, // creates an action button at the end of the tooltip
  id?: string, // for e2e testing, passed to the IconButton
  multiline?: Boolean,
  onMouseEnter?: (callBack: Function) => {}, // function that is fired when the tooltip is hovered
  content?: React$Element<any>, // JSX content to be displayed in the tooltip
  list?: Boolean, // flag to indicate that tooltip content is a list of strings
  placement?: string // prop to override default tooltip position
}

type State = {
  open: boolean, // whether the tooltip is open or not in controlled mode
  iconHovered: boolean, // whether the user is hovering over the icon
  tooltipHovered: boolean, // whether the user is hovering over the tooltip
}

const imgOptions = {
  account_circle: <UserDefined />,
  help: <Help />,
  info_outline: <Info />
}

export class IconTooltip extends React.Component<Props, State> {
  static defaultProps = {
    openWithClick: false,
    onOpen: () => { },
    action: null,
    id: '',
    multiline: false,
    onMouseEnter: null,
    title: '',
    content: null,
    list: false,
    placement: 'bottom-start'
  }

  state = {
    open: false,
    iconHovered: false,
    tooltipHovered: false
  }

  closeTooltip = () => {
    // don't close the tooltip if the user is hovering either the icon or the tip
    if (!this.state.iconHovered && !this.state.tooltipHovered) this.setState({ open: false })
  }

  openTooltip = () => {
    // this prevents the tooltip from opening if the user is no longer hovering on the icon
    if (this.state.iconHovered) this.setState({ open: true })
  }

  toggleTooltip = () => this.setState((state: Object) => ({ open: !state.open }))

  // timeout so tooltips don't pop open immediately as the user hovers over icons
  handleMouseEnterIcon = () => this.setState({ iconHovered: true }, () => {
    const { onMouseEnter, text } = this.props
    if (text) {
      setTimeout(this.openTooltip, 500)
    } else if (onMouseEnter) {
      onMouseEnter(() => setTimeout(this.openTooltip, 500))
    }
  })

  // timeout gives the user time to move from the icon to the tooltip before
  // the tooltip attempts to close (it won't if the user is on the tooltip)
  handleMouseLeaveIcon = () => {
    this.setState({ iconHovered: false }, () => setTimeout(this.closeTooltip, 500))
  }

  // used to set a flag that tells us that the user is on the tooltip and it shouldn't close
  handleMouseEnterTooltip = () => this.setState({ tooltipHovered: true })

  // gives the user a small window of time to return to the tooltip so it won't close on them
  handleMouseLeaveTooltip = () => this.setState({ tooltipHovered: false }, () => setTimeout(this.closeTooltip, 500))

  handleClick = (e: SyntheticEvent<any>) => {
    e.stopPropagation()
    if (this.props.openWithClick) this.toggleTooltip()
  }

  // Compound components
  static SimpleIcon = ({
    icon,
    classes,
    openWithClick,
    id,
    iconClasses,
    ...props
  }: Object) => (
    <IconButton
      data-testid={id}
      {...props}
      classes={{ root: `${classes.iconButton} ${openWithClick ? classes.iconClick : classes.iconHover}` }}>
      <Icon style={{ fontSize: '16px' }} classes={{ root: iconClasses || classes.simpleIcon }}>{imgOptions[icon] || icon}</Icon>
    </IconButton>
  )

  static ActionIcon = ({
    icon,
    classes,
    openWithClick,
    id,
    ...props
  }: Object) => (
    <IconButton
      data-testid={id}
      {...props}
      classes={{ root: `${classes.iconButton} ${openWithClick ? classes.iconClick : classes.iconHover}` }}>
      <Icon style={{ fontSize: '16px' }} classes={{ root: classes.actionIcon }}>{imgOptions[icon] || icon}</Icon>
    </IconButton>
  )
  // Compound components

  getAction(): TooltipAction | null {
    const { action, id = 'IconTooltip' } = this.props
    if (action) {
      // we need to close the tooltip when the action is clicked
      const newAction = () => {
        this.setState({ open: false })
        action.action()
      }
      return {
        label: action.label,
        action: newAction,
        id: `${id}-tooltipAction`
      }
    }
    return null
  }

  render(): any {
    const {
      classes,
      openWithClick,
      text,
      onOpen,
      id,
      multiline,
      content,
      list,
      placement
    } = this.props

    const TooltipContent = React.forwardRef((props: Object, ref: Object) => (
      React.cloneElement(this.props.children, {
        style: { visibility: this.state.open ? 'visible' : 'inherit' },
        onClick: this.handleClick,
        onMouseLeave: openWithClick ? this.handleMouseLeaveIcon : null,
        openWithClick,
        classes,
        id,
        innerRef: ref
      })
    ))

    const openProp = openWithClick ? { open: this.state.open } : {}
    const controlledTooltipProps = openWithClick ? {
      onMouseLeave: this.handleMouseLeaveTooltip,
      onMouseEnter: this.handleMouseEnterTooltip
    } : {}
    return (
      <Tooltip
        {...openProp}
        list={list}
        content={content}
        interactive
        text={text}
        onOpen={onOpen}
        action={this.getAction()}
        enterDelay={0}
        data-testid={id}
        multiline={multiline}
        PopperProps={{
          ...controlledTooltipProps,
          disablePortal: true,
          style: { opacity: 1 },
          modifiers: {
            flip: { enabled: true },
            preventOverflow: {
              enabled: true,
              boundariesElement: 'viewport'
            }
          }
        }}
        placement={placement}>
        <div>
          <TooltipContent />
        </div>
      </Tooltip>
    )
  }
}

export default withStyles(styles)(IconTooltip)
