// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.

import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { Backdrop } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'
import { colors } from 'frontend-assets'

import Modal from '../../common/Modal/Modal'
import Dialog from '../../common/Dialog/Dialog'
import Content from '../../common/Content/Content'

import { closeModal } from '../../../actions/modals'
import { ACCESS_CONTROL_USER_GROUP, CONFIRMATION_MODAL, CONFIRMATION_MODAL_PADDED } from '../../../constants/contentTypes'
import {
  MODAL_TYPE_CALCULATION_INFORMATION,
  MODAL_TYPE_SETTINGS,
  MODAL_TYPE_USER_MODAL,
  BUILDING_SETTINGS
} from '../../../constants/modalConstants'

type HOCProps = {|
  t: Function, // translate function
  theme: Object, // withStyles theme object
  classes: Object, // withStyles classes object
|}

type DispatchProps = {|
  dispatchCloseModal: (string) => void, // fn that dispatches deleting modal from store
|}

type MappedProps = {|
  openModals: Object, // open modals from store
  modalCount: number, // total number of modals stored in Store
|}

type Props = {|
  ...HOCProps,
  ...MappedProps,
  ...DispatchProps,
|}

const styles = () => ({
  backdrop: {
    zIndex: 1201,
    backgroundColor: colors.dialogBackdrop
  },
  noTextTransform: {
    textTransform: 'none'
  }
})

export class ModalsContainer extends Component<Props> {
  getDialogTitlePrefix = (type: string): React$Element<any> | null => {
    const { t } = this.props
    switch (type) {
      case MODAL_TYPE_CALCULATION_INFORMATION: {
        return <span>{t('calculationList._CALCULATION_').toUpperCase()}: </span>
      }
      case MODAL_TYPE_SETTINGS: {
        return <span>{t('settings._SETTINGS_TITLE_')}</span>
      }
      case MODAL_TYPE_USER_MODAL: {
        return <span>{t('userModal._USER_TITLE_')}</span>
      }
      case ACCESS_CONTROL_USER_GROUP: {
        return <span>{t('widgets._ACCESS_CONTROL_USER_GROUP_')}: </span>
      }
      case BUILDING_SETTINGS: {
        return <span>{`${t('settings._BUILDING_SETTINGS_')}: `}</span>
      }
      default:
        return null
    }
  }

  getIsModalTypeTopMost = (type: string): boolean => {
    const topMostModalTypes = [
      CONFIRMATION_MODAL,
      CONFIRMATION_MODAL_PADDED
    ]
    return topMostModalTypes.includes(type)
  }

  getDialogTitle(estimateDescription: string, type: string): React$Element<any> {
    const { classes } = this.props
    return (
      <Fragment>
        {this.getDialogTitlePrefix(type)}
        {estimateDescription && <span className={classes.noTextTransform}>{estimateDescription}</span>}
      </Fragment>
    )
  }

  render(): React$Element<any> {
    const {
      openModals,
      dispatchCloseModal,
      modalCount,
      classes,
      theme,
    } = this.props
    const { zIndex } = theme
    let hasTopMostModalOpen = null

    return (
      <div>
        {
          Object.keys(openModals).map((modalKey: string) => {
            const modal = openModals[modalKey]
            const isModalTypeTopMost = this.getIsModalTypeTopMost(modal.type)
            if (hasTopMostModalOpen === null && isModalTypeTopMost) hasTopMostModalOpen = true

            return (
              modal.containerType === 'dialog' ?
                <Dialog
                  data-testid={modal.id}
                  key={modal.id}
                  index={isModalTypeTopMost ? 9999 : modal.index}
                  maxWidth={1200}
                  fullWidth
                  open
                  title={this.getDialogTitle(modal.estimateDescription || modal.buildingName, modal.type)}
                  onClose={() => {
                    if (modal.onClose) modal.onClose()
                    else dispatchCloseModal(modal.id)
                  }}>
                  <Content modalId={modal.id} {...modal} />
                </Dialog>
              :
                <Modal
                  disableBackdropClick={modal.disableBackdropClick}
                  disableEscapeKeyDown={modal.disableEscapeKeyDown}
                  data-testid={modal.id}
                  title={modal.title}
                  titleInterpolation={modal.titleInterpolation}
                  key={modal.id}
                  index={isModalTypeTopMost ? 9999 : modal.index}
                  open
                  onClose={() => {
                    if (modal.onClose) modal.onClose()
                    else dispatchCloseModal(modal.id)
                  }}
                  disablePadding={modal.disablePadding}>
                  <Content modalId={modal.id} {...modal} />
                </Modal>
            )
          })
        }
        {
          modalCount > 0 &&
            <Backdrop
              open
              classes={{ root: classes.backdrop }}
              style={{
                zIndex: hasTopMostModalOpen ? 9998 : zIndex.modal + modalCount - 1
              }} />
        }
      </div>
    )
  }
}

type ReduxState = {|
  +modals: {|
    openModals: Object, // open modals from store
    modalCount: number, // total number of modals stored in Store
  |}
|}

const mapStateToProps = ({ modals: { openModals, modalCount } }: ReduxState): MappedProps => ({
  openModals,
  modalCount
})

const mapDispatchToProps = (dispatch: Function): DispatchProps => ({
  dispatchCloseModal: (id: string) => dispatch(closeModal(id))
})

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation('translations'),
  withStyles(styles, { withTheme: true })
)(ModalsContainer)
