// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.

import React, { Component, createRef } from 'react'
import { withStyles, RootRef } from '@material-ui/core'
import Skeleton from '../../common/Skeleton/Skeleton'

const styles = () => ({
  originalContentRef: {
    visibility: 'hidden',
    position: 'fixed',
  }
})

type Props = {|
  classes: Object, // withStyles styles prop
  children: any, // child components
  renderSkeleton?: boolean, // toggle for using Skeleton in place of child component
|}

type State = {|
  width?: number | null, // pixel width of root ref element
  skeletonHeight?: number | null, // pixel height of skeleton animated div
|}

class SkeletonContainer extends Component<Props, State> {
  static defaultProps = {
    renderSkeleton: false,
  }

  state = {
    width: null,
    skeletonHeight: null,
  }

  originalContentRef = createRef<HTMLElement>()

  componentDidMount() {
    const refEl = this.originalContentRef.current
    if (refEl) {
      const { width, height } = refEl.getBoundingClientRect()
      this.setState({ width, skeletonHeight: height })
    }
  }

  render(): React$Element<any> {
    const { classes, children, renderSkeleton } = this.props
    const { width, skeletonHeight } = this.state
    return (
      <>
        { (!width && !skeletonHeight && renderSkeleton) &&
        <RootRef rootRef={this.originalContentRef}>
          <div className={classes.originalContentRef}>
            {children}
          </div>
        </RootRef>
        }
        {renderSkeleton ? <Skeleton width={width} skeletonHeight={skeletonHeight} /> : children}
      </>
    )
  }
}

export default withStyles(styles)(SkeletonContainer)
