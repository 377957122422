// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.

import React, { Component } from 'react'
import { connect, batch } from 'react-redux'
import { compose } from 'redux'
import { reduce, filter } from 'lodash'
import { withStyles } from '@material-ui/core/styles'

import FeaturesHOC from '../../hocs/FeaturesHOC/FeaturesHOC'
import MatrixList from '../../common/lists/MatrixList/MatrixList'
import SimpleHierarchicalList from '../../common/lists/SimpleHierarchicalList/SimpleHierarchicalList'

import HALParser from '../../../utils/HALParser'
import { combineStyleClassNames } from '../../../utils/styleUtils'
import { createPatchOperation, isTypeAllowedForPatchOperations } from '../../../utils/patchOperationUtil'
import { getRootListItems, mergeModifiedColumnData, traverseListItems, measureHasEditableProperties } from '../../../utils/listUtils'
import { storePatchOperation, clearPatchOperations, type StorePatchOperationParameterOptions } from '../../../actions/patchOperations'
import { toggleListItemOpen, modifyListItem, clearList } from '../../../actions/list'
import { toggleWidgetModified } from '../../../actions/widgets'
import { setWidgetActive } from '../../../actions/app'
import { openModal } from '../../../actions/modals'
import { TVD_PATCH_OPERATION_TYPE_UPDATE } from '../../../constants/patchOperationConstants'
import { MEASURE_PROPERTIES_CONTAINER, MATRIX_LIST_LEFT } from '../../../constants/contentTypes'
import { matrixListHeaderUnderlayHeight } from '../../../constants/moduleConstants'

const UNDERLAY_FALLBACK_WIDTH = 800

const styles = ({ palette }: TVDTheme): Object => ({
  listsWrapper: {
    display: 'flex',
    height: '100%',
    width: '100%',
  },
  matrixList: {
    overflow: 'auto',
    width: '100%',
  },
  headerUnderlay: {
    position: 'absolute',
    height: matrixListHeaderUnderlayHeight,
    background: palette.white,
    zIndex: 1,
  }
})

export type TVDEditedPropertyValues = {
  [propertyName: string]: number | string | boolean
}

type HOCProps = {|
  classes: Object, // withStyles classes object
  features: TVDFeatureHOCProps, // features from Store and helper functions from the HOC
  sentient: TVDSentient, // Object providing helpers via SentientHOC
|}

type ReceivedProps = {|
  disabled: boolean, // flag to disable widget content if other widgets are beign modified
  listStoreId: string, // unique id for the HLC instance in "list" ReduxStore
  listHeaderCellClassNames?: { [propertyName: string]: string }, // optional overwrites for specific listHeaderCell styles
  wrappedCellContents?: TVDWrappedCells, // optional cell wrappers for SimpleHierarchicalList
  simpleHierarchicalListColumns: Array<TVDListItemColumn>, // static columns for simpleHierarchicalList
  matrixColumns: Array<TVDListItemColumn>, // columns that are styled to look like more of a matrix
  resizeDisabledColumns?: Array<string>, // list of column propertyNames that user can't resize
  wrapperStyleClassName?: string, // a withStyles class name provided for styling the wrapping div - good for choosing a value that shows matrixColumns fully in the UI
  didMountCallback: (listId: string) => void, // Fn used to do data requests for the list
  widgetTab: string, // key of the current active tab to set correct tab active
  widgetId: string, // id of the current widget
  contextMenuItems: Function, // callback for creating contextMenu on right click in SimpleHierachicalList
|}

type DispatchProps = {|
  dispatchToggleListItemOpen: (string) => void, // toggles list item open status
  dispatchStorePatchOperation: (patchOperation: TVDPatchOperation, options?: StorePatchOperationParameterOptions) => void, // store a TVDPatchOperation to ReduxStore
  dispatchSetWidgetActive: () => void, // sets the current widget active for save pattern
  dispatchModifyListItem: (listItemId: string, columnName: string, value: string | number) => void, // sets a measure topic property list row's Value key as modified with the modified value
  dispatchToggleWidgetModified: () => void, // sets current component's wrapping widget as modified
  dispatchClearPatchOperations: () => void, // clears all patch operations tied to this list instance
  dispatchOpenModal: (
    measureName: string,
    properties: TVDPropertiesListItems,
    parentPatchOperation: TVDPatchOperation,
    columnValue: string,
    measureValue: string | number,
    basePath: string,
    editedPropertyValues: TVDEditedPropertyValues,
    rowType: string, // row's type e.g spaces, spacegroup
  ) => void, // opens a modal
    dispatchClearList: () => void, // clears list from Store
|}

type MappedProps = {|
  listItems: { [listItemId: string]: TVDListItem },
  patchOperations: TVDPatchOperations,
  isEstimateLockedToCurrentUser: $PropertyType<TVDApplicationStore, 'isEstimateLockedToCurrentUser'> // if the user owns the lock for the estimate
|}

type Props = {|
  ...ReceivedProps,
  ...DispatchProps,
  ...MappedProps,
  ...HOCProps,
|}

type State = {|
  matrixListWidth: number // width for the headerUnderlay element
|}
export class MatrixListContainer extends Component<Props, State> {
  static defaultProps = {
    matrixColumns: [],
    listHeaderCellClassNames: {},
    wrappedCellContents: {},
    wrapperStyleClassName: ''
  }

  state = {
    matrixListWidth: 0
  }

  descriptionListWrapperRef: TVDRef = React.createRef()
  matrixListWrapperRef: TVDRef = React.createRef()

  // Observes the size of the widget, updates the new matrixList width into the component state (uses shouldComponentUpdate)
  resizeObserver = new window.ResizeObserver(() => {
    const { current: matrixListNode } = this.matrixListWrapperRef
    if (matrixListNode) {
      this.setState({ matrixListWidth: matrixListNode.clientWidth - 17 || UNDERLAY_FALLBACK_WIDTH })
    }
  })

  componentDidMount() {
    const { listStoreId, didMountCallback } = this.props
    if (didMountCallback) { didMountCallback(listStoreId) }
  }

  shouldComponentUpdate(prevProps: Object, prevState: Object): boolean {
    if (this.state.matrixListWidth === prevState.matrixListWidth && prevProps !== this.props) return true
    if (this.state.matrixListWidth === prevState.matrixListWidth) return false
    return true
  }

  componentDidUpdate() {
    const { listStoreId, didMountCallback } = this.props
    if (didMountCallback) { didMountCallback(listStoreId) }
  }

  componentWillUnmount() {
    const { dispatchClearPatchOperations, dispatchClearList } = this.props
    dispatchClearPatchOperations()
    dispatchClearList()
    this.resizeObserver.disconnect()
  }

  traverseListItem = (listItems: TVDListItems, rootListItem: TVDListItem, childCb?: Function) => {
    if (rootListItem.isRemoved) return []
    let childCount = 0

    const children = filter(listItems, (listItem: TVDListItem) => {
      const child = listItem.parentId === rootListItem.id && !listItem.isRemoved
      if (child) childCount += 1
      if (childCb && child) childCb(listItem, rootListItem)
      return child && rootListItem.isOpen
    })

    const childrenWithComputedProperties = children.map((child: TVDListItem) => ({
      ...child,
      columnData: mergeModifiedColumnData(child),
      level: rootListItem.level + 1
    }))

    const rootListItemWithComputedProperties = {
      ...rootListItem,
      columnData: mergeModifiedColumnData(rootListItem),
      canHaveChildren: rootListItem.canHaveChildren || childCount > 0
    }
    return reduce(childrenWithComputedProperties, (result: Array<TVDListItem>, child: TVDListItem) => [
      ...result,
      ...this.traverseListItem(listItems, child, childCb)
    ], [rootListItemWithComputedProperties])
  }

  openRow = ({ canHaveChildren, id }: TVDListItem): void => {
    const { dispatchToggleListItemOpen } = this.props
    if (canHaveChildren) dispatchToggleListItemOpen(id)
  }

  getEditedPropertyValues = (measureValue: string | number, operationParameters: TVDPatchOperationParameters): TVDEditedPropertyValues =>
    Object.keys(operationParameters).reduce((
      result: { [propertyName: string]: string | boolean | number },
      operationParameterKey: string
    ) => {
      const keyParts = operationParameterKey.split('/')
      const [measureTopicId, propertyName] = keyParts.slice(keyParts.length - 2)
      if (propertyName && measureTopicId === measureValue) {
        return {
          ...result,
          [propertyName]: operationParameters[operationParameterKey]
        }
      }
      return result
    }, {})

  // TODO: consider creating/finding more generic type than TVDRenovationMeasureTopic
  onCellClick = (listItem: TVDListItem, column: TVDListItemColumn, measureValue: string | number) => {
    const {
      dispatchStorePatchOperation,
      dispatchSetWidgetActive,
      dispatchModifyListItem,
      dispatchOpenModal,
      patchOperations,
      listStoreId,
      listItems
    } = this.props

    const { propertyName } = column

    const basePath = `columnData/${propertyName}`
    const resourceId = listItem.id
    const patchOperation = createPatchOperation({
      resourceId,
      value: measureValue,
      basePath,
      operationType: TVD_PATCH_OPERATION_TYPE_UPDATE,
    })

    const { measures } = HALParser.getEmbedded(column)
    const selectedMeasure = measures.find(({ value }: TVDRenovationMeasureTopic) => value === measureValue)
    const { properties } = HALParser.getEmbedded(selectedMeasure)
    if (measureHasEditableProperties(properties)) {
      const { operationParameters = {} } = (patchOperations[resourceId] || [])
        .find(({ operationType }: TVDPatchOperation) => operationType === TVD_PATCH_OPERATION_TYPE_UPDATE) || {}
      const editedPropertyValues = operationParameters ? this.getEditedPropertyValues(measureValue, operationParameters) : {}

      dispatchOpenModal(
        selectedMeasure.localizedName,
        properties,
        patchOperation,
        propertyName,
        measureValue,
        `${basePath}/${measureValue}`,
        editedPropertyValues,
        listItem.type
      )
    } else {
      batch(() => {
        const storePatchOperationParameterOptions = { removeExistingPatchOperationParametersBeginningWith: `${basePath}/` }
        dispatchSetWidgetActive()
        traverseListItems(
          listItems,
          (currentItem: TVDListItem) => {
            if (!currentItem.isRemoved) {
              if (isTypeAllowedForPatchOperations(currentItem.type, listStoreId)) {
                dispatchStorePatchOperation({ ...patchOperation, resourceId: currentItem.id }, storePatchOperationParameterOptions)
                dispatchModifyListItem(currentItem.id, propertyName, measureValue)
              }
              return { [currentItem.id]: currentItem }
            }
            return {}
          },
          listItem.id,
          (childListItem: TVDListItem) => {
            if (!childListItem.isRemoved && isTypeAllowedForPatchOperations(childListItem.type, listStoreId)) {
              dispatchModifyListItem(childListItem.id, propertyName, measureValue)
              dispatchStorePatchOperation({ ...patchOperation, resourceId: childListItem.id, }, storePatchOperationParameterOptions)
            }
          }
        )
      })
    }
  }

  onMouseWheelOrTouchpadScroll = (e: SyntheticWheelEvent<any>) => {
    const { deltaY } = e
    const { current: descriptionListNode }: TVDRef = this.descriptionListWrapperRef
    const { current: matrixListNode }: TVDRef = this.matrixListWrapperRef

    if (descriptionListNode && matrixListNode && deltaY) {
      const newScrollTop = descriptionListNode.scrollTop + deltaY

      if (newScrollTop !== matrixListNode.scrollTop) {
        matrixListNode.scrollTop = newScrollTop
        descriptionListNode.scrollTop = matrixListNode.scrollTop
      }
    }
  }

  onScrollBarInteraction = () => {
    const { current: descriptionListNode } = this.descriptionListWrapperRef
    const { current: matrixListNode } = this.matrixListWrapperRef
    if (descriptionListNode && matrixListNode) descriptionListNode.scrollTop = matrixListNode.scrollTop
  }

  getListHierarchy(listItems: TVDListItems): Array<TVDListItem> {
    return reduce(getRootListItems(listItems), (result: Array<TVDListItem>, rootListItem: TVDListItem) => [
      ...result,
      ...this.traverseListItem(listItems, rootListItem)
    ], [])
  }

  render(): React$Element<'div'> {
    const {
      classes,
      disabled,
      simpleHierarchicalListColumns,
      matrixColumns,
      wrappedCellContents,
      listItems,
      resizeDisabledColumns,
      wrapperStyleClassName,
      listHeaderCellClassNames,
      listStoreId,
      contextMenuItems,
      widgetId,
      widgetTab,
      isEstimateLockedToCurrentUser
    } = this.props

    const items = this.getListHierarchy(listItems)

    if (this.matrixListWrapperRef.current) {
      this.resizeObserver.observe(this.matrixListWrapperRef.current)
    }

    return (
      <div onWheel={this.onMouseWheelOrTouchpadScroll} className={combineStyleClassNames(classes.listsWrapper, wrapperStyleClassName)}>
        <div style={{ overflow: 'hidden', minWidth: 500 }} ref={this.descriptionListWrapperRef}>
          <SimpleHierarchicalList
            contextMenuItems={contextMenuItems}
            wrappedCellContents={wrappedCellContents}
            disabled={disabled}
            data={items}
            id={MATRIX_LIST_LEFT}
            testId={MATRIX_LIST_LEFT}
            onRowClick={this.openRow}
            columns={simpleHierarchicalListColumns}
            disableSpanners
            resizeDisabledColumns={resizeDisabledColumns}
            listHeaderCellClassNames={listHeaderCellClassNames}
            allowOnRowClick={!isEstimateLockedToCurrentUser} />
        </div>
        <div ref={this.matrixListWrapperRef} id='mli' onScroll={this.onScrollBarInteraction} className={classes.matrixList}>
          { this.matrixListWrapperRef && <div className={classes.headerUnderlay} style={{ width: this.state.matrixListWidth }} /> }
          <MatrixList
            widgetId={widgetId}
            widgetTab={widgetTab}
            listStoreId={listStoreId}
            disabled={disabled}
            columns={matrixColumns}
            onMenuCellClick={this.onCellClick}
            listItems={items} />
        </div>
      </div>
    )
  }
}

const mapStateToProps = ({ list, patchOperations, app }: TVDReduxStore, { listStoreId }: ReceivedProps): MappedProps => {
  const { listItems } = list[listStoreId] || {}
  const { isEstimateLockedToCurrentUser } = app

  return {
    listItems,
    patchOperations: patchOperations[listStoreId] || [],
    isEstimateLockedToCurrentUser
  }
}

const mapDispatchToProps = (dispatch: Function, { listStoreId, widgetId, widgetTab }: ReceivedProps): DispatchProps => ({
  dispatchToggleListItemOpen: (listItemId: string) => dispatch(toggleListItemOpen(listItemId, listStoreId)),
  dispatchStorePatchOperation: (patchOperation: TVDPatchOperation, storePatchOperationParameterOptions?: StorePatchOperationParameterOptions) => {
    dispatch(storePatchOperation(listStoreId, patchOperation, storePatchOperationParameterOptions))
  },
  dispatchToggleWidgetModified: () => { dispatch(toggleWidgetModified(widgetId, widgetTab, true)) },
  dispatchModifyListItem: (listItemId: string, columnName: string, value: string | number) => {
    dispatch(modifyListItem({
      columnName, listId: listStoreId, listItemId, value
    }))
  },
  dispatchSetWidgetActive: () => { dispatch(setWidgetActive(widgetId, widgetTab)) },
  dispatchClearPatchOperations: () => { dispatch(clearPatchOperations(listStoreId)) },
  dispatchOpenModal: (
    measureName: string,
    properties: TVDPropertiesListItems,
    parentPatchOperation: TVDPatchOperation,
    columnValue: string, measureValue: string | number,
    basePath: string,
    editedPropertyValues: TVDEditedPropertyValues,
    rowType: string,
  ) => {
    dispatch(openModal({
      title: 'renovation._RATIO_MODAL_',
      titleInterpolation: { measureName },
      type: MEASURE_PROPERTIES_CONTAINER,
      disablePadding: true,
      contentProps: {
        properties,
        parentPatchOperation,
        columnValue,
        measureValue,
        listStoreId,
        basePath,
        widgetId,
        widgetTab,
        testId: MEASURE_PROPERTIES_CONTAINER,
        editedPropertyValues,
        rowType,
      }
    }, MEASURE_PROPERTIES_CONTAINER))
  },
  dispatchClearList: () => { dispatch(clearList(listStoreId)) }
})

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
  FeaturesHOC
)(MatrixListContainer)

