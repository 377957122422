// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.

import { includes, each, isEmpty } from 'lodash'
import {
  DELETE_LIST_ITEM,
  DELETE_LIST_ROW,
  updateListLastUpdated,
} from '../actions/list'
import { closeWidget } from '../actions/widgets'

const itemDeleteActions = [
  DELETE_LIST_ITEM,
  DELETE_LIST_ROW
]
export default (store: Object) => (next: Function) => (action: TVDAction) => {
  if (action && includes(itemDeleteActions, action.type)) {
    const { widgets } = store.getState()
    if (!isEmpty(widgets)) { // skip everything if no widgets are open
      const { id, listItemId: pListItemId, listId } = action.payload
      const listItemId = id || pListItemId
      if (widgets[listItemId]) {
        store.dispatch(closeWidget(listItemId))
      } else if (listId) { // if both ifs are false check if rows children have widgets open
        const list = store.getState().list[listId]
        const { listItems } = list
        const row = listItems[listItemId]
        if (row.canHaveChildren) {
          const openWidgets = []
          // traverse listItems with deleted row id as parentId and check if widgets are open with any of those ids
          const traverseChildren = (parentId: Object) => {
            listItems[parentId].isOpen = true
            each(listItems, (listItem: Object) => {
              if (listItem.parentId && listItem.parentId === parentId) {
                if (widgets[listItem.id] && !includes(openWidgets, listItem.id)) {
                  openWidgets.push(listItem.id)
                }
                traverseChildren(listItem.id)
              }
            })
          }
          traverseChildren(row.id)
          if (!isEmpty(openWidgets)) {
            each(openWidgets, (openWidgetId: string) => store.dispatch(closeWidget(openWidgetId)))
          }
        }
      }
    }
  }

  if (action) {
    next(action)
  }

  const {
    type,
    payload: {
      listStoreId,
      listId,
      shouldNotUpdateListLastUpdated,
    } = {},
    meta: {
      fnName
    } = {}
  } = action
  const id = listStoreId || listId
  if (type.startsWith('DATA_GET') && type.endsWith('_SUCCESSFUL') && id) {
    if (listId) console.error('Use listStoreId instead of listId in action payload', type, fnName)
    if (!shouldNotUpdateListLastUpdated) store.dispatch(updateListLastUpdated(id))
  }
}
