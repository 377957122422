// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.
import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import MUIStepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import { IconButton } from '@material-ui/core'
import { colors, fonts, typographyClasses } from 'frontend-assets'
import { combineStyleClassNames } from '../../../utils/styleUtils'
// $FlowFixMe
import { ReactComponent as SVGIconCheckSingle } from '../../../../node_modules/frontend-assets/static/assets/images/icons/Check Single.svg'

const spacing = 5

type Props = {
  classes: Object, // withStyles classes object
  steps: Array<string>, // array of step headers
  activeStep: number
}

const styles = (): Object => ({
  root: {
    width: '100%',
  },
  content: {
    display: 'flex',
    justifyContent: 'flex-start',
    margin: spacing,
  },
  stepper: {
    backgroundColor: colors.gray20,
    display: 'flex',
    justifyContent: 'center',
    height: '100px',
    padding: '5px'
  },
  stepLabelRoot: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '10px',
    '& > span:nth-child(2) > span': {
      width: '300px'
    }
  },
  stepIcon: {
    color: colors.dark20,
    width: 28,
    height: 28
  },
  stepIconActive: {
    color: `${colors.primary80} !important`
  },
  stepIconText: {
    ...typographyClasses.bodyDefault,
    fill: colors.black,
    transform: 'translateY(1px)' // to align the number vertically to the center
  },
  stepIconTextActive: {
    fill: colors.white
  },
  checkIcon: {
    backgroundColor: colors.dark20,
    width: 28,
    height: 28,
    padding: 0
  },
  stepLabel: {
    fontFamily: `${fonts.fontFamilyBase} !important`,
    color: `${colors.dark80} !important`
  },
  stepLabelBold: {
    ...typographyClasses.bodyDefaultBold,
    fontFamily: `${fonts.fontFamilyBold} !important`,
    color: `${colors.dark80} !important`
  },
})

class Stepper extends React.Component<Props> {
  render(): React$Element<any> {
    const { classes, steps, activeStep } = this.props

    return (
      <div data-testid='stepper-root' className={classes.root}>
        <MUIStepper className={classes.stepper} activeStep={activeStep} alternativeLabel>
          {steps.map((label: string) => (
            <Step key={label}>
              <StepLabel
                classes={{
                  root: classes.stepLabelRoot,
                  label: classes.stepLabel,
                  active: classes.stepLabelBold,
                  completed: classes.stepLabelBold
                }}
                {...activeStep === 1 && label !== steps[activeStep] &&
                  { icon: <IconButton className={classes.checkIcon}><SVGIconCheckSingle /></IconButton> }
                  }
                StepIconProps={{
                  classes: {
                    root: classes.stepIcon,
                    active: classes.stepIconActive,
                    completed: classes.stepIconCompleted,
                    text: steps[activeStep] === label
                    ? combineStyleClassNames(classes.stepIconText, classes.stepIconTextActive)
                    : classes.stepIconText
                  }
                }}>
                {label}
              </StepLabel>
            </Step>
            ))
          }
        </MUIStepper>
      </div>
    )
  }
}


export default withStyles(styles)(Stepper)
