// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.
/* eslint max-len: ["error", { "code": 350 }] */

import requestFn from './../requests/request'
import { API_BASE_URL } from './../../constants/envConstants'
import parserMapper from './../parserMapper'

export const basePath = '/users-api'


export const DATA_GET_ACCOUNT_LICENSE_SETTINGS_WITH_ACCOUNT_ID_START = 'DATA_GET_ACCOUNT_LICENSE_SETTINGS_WITH_ACCOUNT_ID_START '
export const DATA_GET_ACCOUNT_LICENSE_SETTINGS_WITH_ACCOUNT_ID_SUCCESSFUL = 'DATA_GET_ACCOUNT_LICENSE_SETTINGS_WITH_ACCOUNT_ID_SUCCESSFUL'
export const DATA_GET_ACCOUNT_LICENSE_SETTINGS_WITH_ACCOUNT_ID_FAIL = 'DATA_GET_ACCOUNT_LICENSE_SETTINGS_WITH_ACCOUNT_ID_FAIL'

export const getAccountLicenseSettings = (
  requestArguments: Object,
  payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const meta = {
    actionNames: {
      start: 'DATA_GET_ACCOUNT_LICENSE_SETTINGS_WITH_ACCOUNT_ID_START',
      successful: 'DATA_GET_ACCOUNT_LICENSE_SETTINGS_WITH_ACCOUNT_ID_SUCCESSFUL',
      fail: 'DATA_GET_ACCOUNT_LICENSE_SETTINGS_WITH_ACCOUNT_ID_FAIL'
    },
    embeddedType: {},
    fnName: 'getAccountLicenseSettings'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}${basePath}`,
    url: `/accounts/${requestArguments.path?.accountId}/license-settings`,
    method: 'GET',
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      nonEmbedded: {
        schemaPath: '#/definitions/GetAccountLicenseSettings',
        schemaKey: 'GetAccountLicenseSettings'
      }
    }, 'getAccountLicenseSettings', basePath),
    actions: {
      start: {
        type: DATA_GET_ACCOUNT_LICENSE_SETTINGS_WITH_ACCOUNT_ID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_ACCOUNT_LICENSE_SETTINGS_WITH_ACCOUNT_ID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_ACCOUNT_LICENSE_SETTINGS_WITH_ACCOUNT_ID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}
