// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core'
import WorkIcon from '@material-ui/icons/Work'
import Typography from '@material-ui/core/Typography'
import Header from '../../common/Header/Header'
import DrawerRight from '../../common/DrawerRight/DrawerRight'
import AppsList from './AppsList'
import ModalsContainer from '../../containers/ModalsContainer/ModalsContainer'
import { LICENSE, licenseUrl, buildingUrl, calculationUrl } from '../../../constants/moduleConstants'
import { openModal } from '../../../actions/modals'
import AccountSelectionHOC from '../../hocs/AccountSelectionHOC/AccountSelectionHOC'
import { MODAL_TYPE_HAAHTELA_ADMIN_LICENSE } from '../../../constants/modalConstants'

const useStyles = makeStyles(({ palette, typography }: TVDTheme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    backgroundColor: palette.white
  },
  contentWrapper: {
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  selectedAccountName: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    color: palette.primary100,
    marginTop: '6vh',
    marginBottom: '90px',
    zIndex: '1'
  },
  selectedAccountNameText: {
    fontFamily: typography.latoBoldItalic,
    fontSize: '26px',
    color: palette.nevada,
    marginLeft: '9px',
    paddingTop: '2px'
  },
  icon: {
    fontSize: '48px'
  }
}))

const SelectedAccountElement = (): React$Element<'div'> => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { app: { selectedAccountName } } = useSelector((state: TVDReduxStore): TVDReduxStore => state)

  return (
    <div className={classes.selectedAccountName}>
      <WorkIcon className={classes.icon} />
      <Typography className={classes.selectedAccountNameText}>
        <span>{t('drawer._ACCOUNT_', { accountName: selectedAccountName })}</span>
      </Typography>
    </div>
  )
}

export const HaahtelaAdmin = () => {
  const classes = useStyles()
  const apps = [LICENSE]
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const handleAppSelect = (url: string) => {
    switch (url) {
      case licenseUrl:
        dispatch(openModal({ type: MODAL_TYPE_HAAHTELA_ADMIN_LICENSE, title: t('apps._LICENSE_') }, MODAL_TYPE_HAAHTELA_ADMIN_LICENSE))
        break

      case buildingUrl:
        dispatch(openModal({ type: '', title: t('apps._BUILDING_') }, ''))
        break

      case calculationUrl:
        dispatch(openModal({ type: '', title: t('apps._CALCULATION_') }, ''))
        break

      default:
        console.error(`No valid modal type for url ${url}`)
    }
  }

  return (
    <div className={classes.root}>
      <Header />
      <DrawerRight />
      <div className={classes.contentWrapper}>
        <AppsList
          displayApps={apps}
          onAppClick={(url: string) => { handleAppSelect(url) }}
          availableAppIds={apps} />
        <SelectedAccountElement />
      </div>
      <ModalsContainer />
    </div>
  )
}

export default AccountSelectionHOC(HaahtelaAdmin)
