// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.

import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import { SVGIcon } from 'frontend-components'
import { borderRadiuses, colors, typographyClasses } from 'frontend-assets'
import Icon from '../icons/Icon/Icon'
import { combineStyleClassNames } from '../../../../utils/styleUtils'
import { REAL_ESTATES } from '../../../../constants/moduleConstants'

const {
  primary80, darkBlue, dark120, white
} = colors

const {
  bodyTiny, bodyTinyBold
} = typographyClasses

const useStyles = makeStyles(() => ({
  appButton: {
    width: 82,
    height: 82,
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    color: white,
    backgroundColor: dark120,
    zIndex: 1,
    padding: 12,
    transition: 'none',
    borderRadius: 0,
    '&:hover': {
      backgroundColor: darkBlue,
    },
    '& path': {
      fill: white
    },
    '&:hover path': {
      fill: white,
    },
    // '&:disabled > span > span > img ': {
    //   filter: 'brightness(0) saturate(100%) invert(86%) sepia(0%) saturate(2%) hue-rotate(330deg) brightness(91%) contrast(86%)',
    // },
  },
  appButtonRealEstate: {
    '&:first-of-type': {
      borderRadius: `0 ${borderRadiuses.borderRadiusLarge}px 0 0`
    }
  },
  typography: {
    ...bodyTiny,
    textTransform: 'none',
    hyphens: 'manual',
    lineHeight: 1.3,
    marginTop: 8,
  },
  typographyActive: {
    ...bodyTinyBold,
    textTransform: 'none',
    hyphens: 'manual',
    lineHeight: 1.3,
    marginTop: 8
  },
  typographyRealEstate: {
    ...bodyTinyBold,
    textTransform: 'none',
    color: white,
    marginTop: 8,
  },
  label: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  active: {
    color: white,
    backgroundColor: primary80,
    '& path': {
      fill: white,
    },
    '&:hover': {
      backgroundColor: primary80,
    },
    '&:hover path': {
      fill: white,
    }
  }
}))

type Props = {|
  label: string, // the text in the button
  disabled?: boolean, // indicates if button is disabled
  active: boolean, // indicates that button is in active state for example it represents a selected app in appbar
  testid: string, // testid for testing purposes
  onClick: Function, // click handler
  children: React$Element<typeof Icon> | React$Element<typeof SVGIcon>,
  appName: string // name of the button
|}

const IconButton = (props: Props) => {
  const handleClick = () => {
    props.onClick(props.testid)
  }
  const classes = useStyles()

  const getClassNames = () => {
    if (props.appName === REAL_ESTATES) {
      return combineStyleClassNames(classes.appButton, classes.appButtonRealEstate, props.active && classes.active)
    }
    return combineStyleClassNames(classes.appButton, props.active && classes.active)
  }
  return (
    <Button
      disableTouchRipple
      data-testid={props.testid}
      disabled={props.disabled}
      disableElevation
      className={getClassNames()}
      classes={{ label: classes.label }}
      onClick={handleClick}>
      {props.children}
      <Typography
      // eslint-disable-next-line
        className={(props.appName === REAL_ESTATES) ? classes.typographyRealEstate :
          ((props.active) ? classes.typographyActive : classes.typography)}>
        {props.label}
      </Typography>
    </Button>
  )
}

IconButton.defaultProps = {
  onClick: () => {},
  active: false,
  disabled: false
}

export default IconButton
