// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.
export const VALUE_PROPERTY = 'ValueProperty'
export const BUILDING = 'Building'
export const SCHEDULE_ITEM = 'ScheduleItem'
export const ELEMENT_SCHEDULE_ITEM = 'ElementScheduleItem'
export const BUILDING_TASK_SCHEDULE_ITEM = 'BuildingTaskScheduleItem'
export const ENUMERAL = 'Enumeral'
export const ESTIMATE = 'Estimate'
export const LICENSE = 'License'
export const HAL = 'HAL'
export const DOCUMENT = 'Document'
export const ACTIVITY_SCHEDULE_ITEM = 'ActivityScheduleItem'
export const SPACE_SCHEDULE_ITEM = 'SpaceScheduleItem'
export const OPERATING_PROFILE_SCHEDULE_ITEM = 'OperatingProfileScheduleItem'
export const GROUPING_SCHEDULE_ITEM = 'GroupingScheduleItem'
export const USER = 'User'
export const SUBJECT_PERMISSIONS = 'SubjectPermissions'
export const USER_REDUCED = 'UserReduced'
export const USER_GROUP = 'UserGroup'
