// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.
/* eslint max-len: ["error", { "code": 350 }] */
/*
  Haahtela Users Api 39.11.1
  Haahtela API for managing Users and Accounts
  192.168.254.119:5007
*/

import requestFn from './../requests/request'
import { API_BASE_URL } from './../../constants/envConstants'
import parserMapper from './../parserMapper'
import { getState } from '../../store'

export const basePath = '/users-api'

export const DATA_GET_USERS_SELF_START = 'DATA_GET_USERS_SELF_START'
export const DATA_GET_USERS_SELF_SUCCESSFUL = 'DATA_GET_USERS_SELF_SUCCESSFUL'
export const DATA_GET_USERS_SELF_FAIL = 'DATA_GET_USERS_SELF_FAIL'

// Returns a user, full information. Presumes that the user is properly authenticated.
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/users.yaml#/Users/get_users_self
export const getUsersSelfRequest = (
  payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_USERS_SELF_START',
      successful: 'DATA_GET_USERS_SELF_SUCCESSFUL',
      fail: 'DATA_GET_USERS_SELF_FAIL'
    },
    embeddedType: {
      nonEmbedded: {
        schemaPath: '#/definitions/User',
        schemaKey: 'User'
      }
    },
    fnName: 'getUsersSelfRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}${options?.basePath || '/users-api'}`,
    method: 'GET',
    url: '/users/self',
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      nonEmbedded: {
        schemaPath: '#/definitions/User',
        schemaKey: 'User'
      }
    }, 'getUsersSelfRequest', '/users-api'),
    actions: {
      start: {
        type: DATA_GET_USERS_SELF_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_USERS_SELF_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_USERS_SELF_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getUsersSelfRequestDefinitions = (definitionsArguments: {| payload?: Object |}): {|
  ...TVDGARConfigWithoutRequestArgs, payload?: Object |} => ({
  fnName: 'getUsersSelfRequest',
  basePath: '/users-api',
  payload: definitionsArguments.payload,
})


export const DATA_GET_USERS_SELF_MODULES_START = 'DATA_GET_USERS_SELF_MODULES_START'
export const DATA_GET_USERS_SELF_MODULES_SUCCESSFUL = 'DATA_GET_USERS_SELF_MODULES_SUCCESSFUL'
export const DATA_GET_USERS_SELF_MODULES_FAIL = 'DATA_GET_USERS_SELF_MODULES_FAIL'

// Gets the modules that user (based on authentication) has been granted access in the current Account
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/users.yaml#/Users/get_users_self_modules
export const getUsersSelfModulesRequest = (
  payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_USERS_SELF_MODULES_START',
      successful: 'DATA_GET_USERS_SELF_MODULES_SUCCESSFUL',
      fail: 'DATA_GET_USERS_SELF_MODULES_FAIL'
    },
    embeddedType: {
      modulesIds: {
        schemaKey: null
      }
    },
    fnName: 'getUsersSelfModulesRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}${options?.basePath || '/users-api'}`,
    method: 'GET',
    url: '/users/self/modules',
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      modulesIds: {
        schemaKey: null
      }
    }, 'getUsersSelfModulesRequest', '/users-api'),
    actions: {
      start: {
        type: DATA_GET_USERS_SELF_MODULES_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_USERS_SELF_MODULES_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_USERS_SELF_MODULES_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getUsersSelfModulesRequestDefinitions = (definitionsArguments: {| payload?: Object |}): {|
  ...TVDGARConfigWithoutRequestArgs, payload?: Object |} => ({
  fnName: 'getUsersSelfModulesRequest',
  basePath: '/users-api',
  payload: definitionsArguments.payload,
})


export const DATA_GET_USERS_SELF_BUILDINGS_START = 'DATA_GET_USERS_SELF_BUILDINGS_START'
export const DATA_GET_USERS_SELF_BUILDINGS_SUCCESSFUL = 'DATA_GET_USERS_SELF_BUILDINGS_SUCCESSFUL'
export const DATA_GET_USERS_SELF_BUILDINGS_FAIL = 'DATA_GET_USERS_SELF_BUILDINGS_FAIL'

// Gets buildings that the user (based on authentication) has access to in the current Account
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/users.yaml#/Users/get_users_self_buildings
export const getUsersSelfBuildingsRequest = (
  payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_USERS_SELF_BUILDINGS_START',
      successful: 'DATA_GET_USERS_SELF_BUILDINGS_SUCCESSFUL',
      fail: 'DATA_GET_USERS_SELF_BUILDINGS_FAIL'
    },
    embeddedType: {
      buildingIds: {
        schemaKey: null
      }
    },
    fnName: 'getUsersSelfBuildingsRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}${options?.basePath || '/users-api'}`,
    method: 'GET',
    url: '/users/self/buildings',
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      buildingIds: {
        schemaKey: null
      }
    }, 'getUsersSelfBuildingsRequest', '/users-api'),
    actions: {
      start: {
        type: DATA_GET_USERS_SELF_BUILDINGS_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_USERS_SELF_BUILDINGS_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_USERS_SELF_BUILDINGS_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getUsersSelfBuildingsRequestDefinitions = (definitionsArguments: {| payload?: Object |}): {|
  ...TVDGARConfigWithoutRequestArgs, payload?: Object |} => ({
  fnName: 'getUsersSelfBuildingsRequest',
  basePath: '/users-api',
  payload: definitionsArguments.payload,
})


export const DATA_GET_USERS_SELF_ESTIMATES_START = 'DATA_GET_USERS_SELF_ESTIMATES_START'
export const DATA_GET_USERS_SELF_ESTIMATES_SUCCESSFUL = 'DATA_GET_USERS_SELF_ESTIMATES_SUCCESSFUL'
export const DATA_GET_USERS_SELF_ESTIMATES_FAIL = 'DATA_GET_USERS_SELF_ESTIMATES_FAIL'

// Gets estimates that the user (based on authentication) has access to in the current Account
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/users.yaml#/Users/get_users_self_estimates
export const getUsersSelfEstimatesRequest = (
  payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_USERS_SELF_ESTIMATES_START',
      successful: 'DATA_GET_USERS_SELF_ESTIMATES_SUCCESSFUL',
      fail: 'DATA_GET_USERS_SELF_ESTIMATES_FAIL'
    },
    embeddedType: {
      estimateIds: {
        schemaKey: null
      }
    },
    fnName: 'getUsersSelfEstimatesRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}${options?.basePath || '/users-api'}`,
    method: 'GET',
    url: '/users/self/estimates',
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      estimateIds: {
        schemaKey: null
      }
    }, 'getUsersSelfEstimatesRequest', '/users-api'),
    actions: {
      start: {
        type: DATA_GET_USERS_SELF_ESTIMATES_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_USERS_SELF_ESTIMATES_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_USERS_SELF_ESTIMATES_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getUsersSelfEstimatesRequestDefinitions = (definitionsArguments: {| payload?: Object |}): {|
  ...TVDGARConfigWithoutRequestArgs, payload?: Object |} => ({
  fnName: 'getUsersSelfEstimatesRequest',
  basePath: '/users-api',
  payload: definitionsArguments.payload,
})


export const DATA_GET_USERS_SELF_ACCOUNTS_START = 'DATA_GET_USERS_SELF_ACCOUNTS_START'
export const DATA_GET_USERS_SELF_ACCOUNTS_SUCCESSFUL = 'DATA_GET_USERS_SELF_ACCOUNTS_SUCCESSFUL'
export const DATA_GET_USERS_SELF_ACCOUNTS_FAIL = 'DATA_GET_USERS_SELF_ACCOUNTS_FAIL'

// Gets active Accounts that the User has access to.
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/users.yaml#/Users/get_users_self_accounts
export const getUsersSelfAccountsRequest = (
  requestArguments: getUsersSelfAccountsRequestUsersArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_USERS_SELF_ACCOUNTS_START',
      successful: 'DATA_GET_USERS_SELF_ACCOUNTS_SUCCESSFUL',
      fail: 'DATA_GET_USERS_SELF_ACCOUNTS_FAIL'
    },
    embeddedType: {
      accounts: {
        schemaPath: '#/definitions/Enumeral',
        schemaKey: 'Enumeral'
      }
    },
    fnName: 'getUsersSelfAccountsRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}${options?.basePath || '/users-api'}`,
    method: 'GET',
    url: '/users/self/accounts',
    params: {
      privilegeFilter: requestArguments.query?.['privilegeFilter'],
      includeStatus: requestArguments.query?.['includeStatus'],
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      accounts: {
        schemaPath: '#/definitions/Enumeral',
        schemaKey: 'Enumeral'
      }
    }, 'getUsersSelfAccountsRequest', '/users-api'),
    actions: {
      start: {
        type: DATA_GET_USERS_SELF_ACCOUNTS_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_USERS_SELF_ACCOUNTS_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_USERS_SELF_ACCOUNTS_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getUsersSelfAccountsRequestDefinitions = (definitionsArguments: {| requestArgs: getUsersSelfAccountsRequestUsersArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getUsersSelfAccountsRequestUsersArguments, payload?: Object |} => ({
  fnName: 'getUsersSelfAccountsRequest',
  basePath: '/users-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_GET_USERS_SELF_PRIVILEGES_START = 'DATA_GET_USERS_SELF_PRIVILEGES_START'
export const DATA_GET_USERS_SELF_PRIVILEGES_SUCCESSFUL = 'DATA_GET_USERS_SELF_PRIVILEGES_SUCCESSFUL'
export const DATA_GET_USERS_SELF_PRIVILEGES_FAIL = 'DATA_GET_USERS_SELF_PRIVILEGES_FAIL'

// Gets system privileges that the user has. List of possible privileges can be requested from /enum/privileges.
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/users.yaml#/Users/get_users_self_privileges
export const getUsersSelfPrivilegesRequest = (
  payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_USERS_SELF_PRIVILEGES_START',
      successful: 'DATA_GET_USERS_SELF_PRIVILEGES_SUCCESSFUL',
      fail: 'DATA_GET_USERS_SELF_PRIVILEGES_FAIL'
    },
    embeddedType: {
      privileges: {
        schemaPath: '#/definitions/Enumeral',
        schemaKey: 'Enumeral'
      }
    },
    fnName: 'getUsersSelfPrivilegesRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}${options?.basePath || '/users-api'}`,
    method: 'GET',
    url: '/users/self/privileges',
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      privileges: {
        schemaPath: '#/definitions/Enumeral',
        schemaKey: 'Enumeral'
      }
    }, 'getUsersSelfPrivilegesRequest', '/users-api'),
    actions: {
      start: {
        type: DATA_GET_USERS_SELF_PRIVILEGES_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_USERS_SELF_PRIVILEGES_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_USERS_SELF_PRIVILEGES_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getUsersSelfPrivilegesRequestDefinitions = (definitionsArguments: {| payload?: Object |}): {|
  ...TVDGARConfigWithoutRequestArgs, payload?: Object |} => ({
  fnName: 'getUsersSelfPrivilegesRequest',
  basePath: '/users-api',
  payload: definitionsArguments.payload,
})


export const DATA_GET_USERS_SELF_LEGAL_CONSENT_START = 'DATA_GET_USERS_SELF_LEGAL_CONSENT_START'
export const DATA_GET_USERS_SELF_LEGAL_CONSENT_SUCCESSFUL = 'DATA_GET_USERS_SELF_LEGAL_CONSENT_SUCCESSFUL'
export const DATA_GET_USERS_SELF_LEGAL_CONSENT_FAIL = 'DATA_GET_USERS_SELF_LEGAL_CONSENT_FAIL'

// Gets current Consent information for user.
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/users.yaml#/Users/get_users_self_legal_consent
export const getUsersSelfLegalConsentRequest = (
  payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_USERS_SELF_LEGAL_CONSENT_START',
      successful: 'DATA_GET_USERS_SELF_LEGAL_CONSENT_SUCCESSFUL',
      fail: 'DATA_GET_USERS_SELF_LEGAL_CONSENT_FAIL'
    },
    embeddedType: {
      nonEmbedded: {
        schemaPath: '#/definitions/UserConsent',
        schemaKey: 'UserConsent'
      }
    },
    fnName: 'getUsersSelfLegalConsentRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}${options?.basePath || '/users-api'}`,
    method: 'GET',
    url: '/users/self/legal/consent',
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      nonEmbedded: {
        schemaPath: '#/definitions/UserConsent',
        schemaKey: 'UserConsent'
      }
    }, 'getUsersSelfLegalConsentRequest', '/users-api'),
    actions: {
      start: {
        type: DATA_GET_USERS_SELF_LEGAL_CONSENT_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_USERS_SELF_LEGAL_CONSENT_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_USERS_SELF_LEGAL_CONSENT_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getUsersSelfLegalConsentRequestDefinitions = (definitionsArguments: {| payload?: Object |}): {|
  ...TVDGARConfigWithoutRequestArgs, payload?: Object |} => ({
  fnName: 'getUsersSelfLegalConsentRequest',
  basePath: '/users-api',
  payload: definitionsArguments.payload,
})


export const DATA_PATCH_USERS_SELF_LEGAL_CONSENT_START = 'DATA_PATCH_USERS_SELF_LEGAL_CONSENT_START'
export const DATA_PATCH_USERS_SELF_LEGAL_CONSENT_SUCCESSFUL = 'DATA_PATCH_USERS_SELF_LEGAL_CONSENT_SUCCESSFUL'
export const DATA_PATCH_USERS_SELF_LEGAL_CONSENT_FAIL = 'DATA_PATCH_USERS_SELF_LEGAL_CONSENT_FAIL'

// Updates Consent information for user.
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/users.yaml#/Users/patch_users_self_legal_consent
export const patchUsersSelfLegalConsentRequest = (
  requestArguments: patchUsersSelfLegalConsentRequestUsersArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_PATCH_USERS_SELF_LEGAL_CONSENT_START',
      successful: 'DATA_PATCH_USERS_SELF_LEGAL_CONSENT_SUCCESSFUL',
      fail: 'DATA_PATCH_USERS_SELF_LEGAL_CONSENT_FAIL'
    },
    embeddedType: null,
    fnName: 'patchUsersSelfLegalConsentRequest'
  }
  const config = {
    headers: { 'Content-Type': 'application/json' },
    baseURL: `${API_BASE_URL}${options?.basePath || '/users-api'}`,
    method: 'PATCH',
    url: '/users/self/legal/consent',
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: requestArguments.body
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'patchUsersSelfLegalConsentRequest', '/users-api'),
    actions: {
      start: {
        type: DATA_PATCH_USERS_SELF_LEGAL_CONSENT_START, payload, config, meta
      },
      successful: {
        type: DATA_PATCH_USERS_SELF_LEGAL_CONSENT_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_PATCH_USERS_SELF_LEGAL_CONSENT_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_GET_USERS_SELF_LEGAL_CONSENT_IS_UPDATE_REQUIRED_START = 'DATA_GET_USERS_SELF_LEGAL_CONSENT_IS_UPDATE_REQUIRED_START'
export const DATA_GET_USERS_SELF_LEGAL_CONSENT_IS_UPDATE_REQUIRED_SUCCESSFUL = 'DATA_GET_USERS_SELF_LEGAL_CONSENT_IS_UPDATE_REQUIRED_SUCCESSFUL'
export const DATA_GET_USERS_SELF_LEGAL_CONSENT_IS_UPDATE_REQUIRED_FAIL = 'DATA_GET_USERS_SELF_LEGAL_CONSENT_IS_UPDATE_REQUIRED_FAIL'

// Returns true if the Terms of Service has changed since the User last gave consent.
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/users.yaml#/Users/get_users_self_legal_consent_is_update_required
export const getUsersSelfLegalConsentIsUpdateRequiredRequest = (
  payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_USERS_SELF_LEGAL_CONSENT_IS_UPDATE_REQUIRED_START',
      successful: 'DATA_GET_USERS_SELF_LEGAL_CONSENT_IS_UPDATE_REQUIRED_SUCCESSFUL',
      fail: 'DATA_GET_USERS_SELF_LEGAL_CONSENT_IS_UPDATE_REQUIRED_FAIL'
    },
    embeddedType: {
      nonEmbedded: {
        schemaKey: null
      }
    },
    fnName: 'getUsersSelfLegalConsentIsUpdateRequiredRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}${options?.basePath || '/users-api'}`,
    method: 'GET',
    url: '/users/self/legal/consent/is-update-required',
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      nonEmbedded: {
        schemaKey: null
      }
    }, 'getUsersSelfLegalConsentIsUpdateRequiredRequest', '/users-api'),
    actions: {
      start: {
        type: DATA_GET_USERS_SELF_LEGAL_CONSENT_IS_UPDATE_REQUIRED_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_USERS_SELF_LEGAL_CONSENT_IS_UPDATE_REQUIRED_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_USERS_SELF_LEGAL_CONSENT_IS_UPDATE_REQUIRED_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getUsersSelfLegalConsentIsUpdateRequiredRequestDefinitions = (definitionsArguments: {| payload?: Object |}): {|
  ...TVDGARConfigWithoutRequestArgs, payload?: Object |} => ({
  fnName: 'getUsersSelfLegalConsentIsUpdateRequiredRequest',
  basePath: '/users-api',
  payload: definitionsArguments.payload,
})


export const DATA_GET_USERS_SELF_SERVICE_MESSAGES_START = 'DATA_GET_USERS_SELF_SERVICE_MESSAGES_START'
export const DATA_GET_USERS_SELF_SERVICE_MESSAGES_SUCCESSFUL = 'DATA_GET_USERS_SELF_SERVICE_MESSAGES_SUCCESSFUL'
export const DATA_GET_USERS_SELF_SERVICE_MESSAGES_FAIL = 'DATA_GET_USERS_SELF_SERVICE_MESSAGES_FAIL'

// Returns current messages that the User should be showed concerning the service, for example upcoming maintenance breaks.
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/users.yaml#/Users/get_users_self_service_messages
export const getUsersSelfServiceMessagesRequest = (
  payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_USERS_SELF_SERVICE_MESSAGES_START',
      successful: 'DATA_GET_USERS_SELF_SERVICE_MESSAGES_SUCCESSFUL',
      fail: 'DATA_GET_USERS_SELF_SERVICE_MESSAGES_FAIL'
    },
    embeddedType: {
      nonEmbedded: {
        schemaPath: '#/definitions/ServiceMessageCollection',
        schemaKey: 'ServiceMessageCollection'
      }
    },
    fnName: 'getUsersSelfServiceMessagesRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}${options?.basePath || '/users-api'}`,
    method: 'GET',
    url: '/users/self/service-messages',
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      nonEmbedded: {
        schemaPath: '#/definitions/ServiceMessageCollection',
        schemaKey: 'ServiceMessageCollection'
      }
    }, 'getUsersSelfServiceMessagesRequest', '/users-api'),
    actions: {
      start: {
        type: DATA_GET_USERS_SELF_SERVICE_MESSAGES_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_USERS_SELF_SERVICE_MESSAGES_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_USERS_SELF_SERVICE_MESSAGES_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getUsersSelfServiceMessagesRequestDefinitions = (definitionsArguments: {| payload?: Object |}): {|
  ...TVDGARConfigWithoutRequestArgs, payload?: Object |} => ({
  fnName: 'getUsersSelfServiceMessagesRequest',
  basePath: '/users-api',
  payload: definitionsArguments.payload,
})


export const DATA_POST_USERS_SELF_SERVICE_MESSAGES_SIGNED_START = 'DATA_POST_USERS_SELF_SERVICE_MESSAGES_SIGNED_START'
export const DATA_POST_USERS_SELF_SERVICE_MESSAGES_SIGNED_SUCCESSFUL = 'DATA_POST_USERS_SELF_SERVICE_MESSAGES_SIGNED_SUCCESSFUL'
export const DATA_POST_USERS_SELF_SERVICE_MESSAGES_SIGNED_FAIL = 'DATA_POST_USERS_SELF_SERVICE_MESSAGES_SIGNED_FAIL'

// Sign a ServiceMessage as seen by POSTing its id and hash.
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/users.yaml#/Users/post_users_self_service_messages_signed
export const postUsersSelfServiceMessagesSignedRequest = (
  requestArguments: postUsersSelfServiceMessagesSignedRequestUsersArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_POST_USERS_SELF_SERVICE_MESSAGES_SIGNED_START',
      successful: 'DATA_POST_USERS_SELF_SERVICE_MESSAGES_SIGNED_SUCCESSFUL',
      fail: 'DATA_POST_USERS_SELF_SERVICE_MESSAGES_SIGNED_FAIL'
    },
    embeddedType: null,
    fnName: 'postUsersSelfServiceMessagesSignedRequest'
  }
  const config = {
    headers: { 'Content-Type': 'application/json' },
    baseURL: `${API_BASE_URL}${options?.basePath || '/users-api'}`,
    method: 'POST',
    url: '/users/self/service-messages/signed',
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: requestArguments.body
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'postUsersSelfServiceMessagesSignedRequest', '/users-api'),
    actions: {
      start: {
        type: DATA_POST_USERS_SELF_SERVICE_MESSAGES_SIGNED_START, payload, config, meta
      },
      successful: {
        type: DATA_POST_USERS_SELF_SERVICE_MESSAGES_SIGNED_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_POST_USERS_SELF_SERVICE_MESSAGES_SIGNED_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_GET_USERS_SELF_SESSION_START = 'DATA_GET_USERS_SELF_SESSION_START'
export const DATA_GET_USERS_SELF_SESSION_SUCCESSFUL = 'DATA_GET_USERS_SELF_SESSION_SUCCESSFUL'
export const DATA_GET_USERS_SELF_SESSION_FAIL = 'DATA_GET_USERS_SELF_SESSION_FAIL'

// Gets session information for current User.
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/users.yaml#/Users/get_users_self_session
export const getUsersSelfSessionRequest = (
  payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_USERS_SELF_SESSION_START',
      successful: 'DATA_GET_USERS_SELF_SESSION_SUCCESSFUL',
      fail: 'DATA_GET_USERS_SELF_SESSION_FAIL'
    },
    embeddedType: {
      nonEmbedded: {
        schemaPath: '#/definitions/UserSession',
        schemaKey: 'UserSession'
      }
    },
    fnName: 'getUsersSelfSessionRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}${options?.basePath || '/users-api'}`,
    method: 'GET',
    url: '/users/self/session',
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      nonEmbedded: {
        schemaPath: '#/definitions/UserSession',
        schemaKey: 'UserSession'
      }
    }, 'getUsersSelfSessionRequest', '/users-api'),
    actions: {
      start: {
        type: DATA_GET_USERS_SELF_SESSION_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_USERS_SELF_SESSION_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_USERS_SELF_SESSION_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getUsersSelfSessionRequestDefinitions = (definitionsArguments: {| payload?: Object |}): {|
  ...TVDGARConfigWithoutRequestArgs, payload?: Object |} => ({
  fnName: 'getUsersSelfSessionRequest',
  basePath: '/users-api',
  payload: definitionsArguments.payload,
})


export const DATA_PUT_USERS_SELF_SESSION_START = 'DATA_PUT_USERS_SELF_SESSION_START'
export const DATA_PUT_USERS_SELF_SESSION_SUCCESSFUL = 'DATA_PUT_USERS_SELF_SESSION_SUCCESSFUL'
export const DATA_PUT_USERS_SELF_SESSION_FAIL = 'DATA_PUT_USERS_SELF_SESSION_FAIL'

// Replaces currently saved User Session with a new Session.
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/users.yaml#/Users/put_users_self_session
export const putUsersSelfSessionRequest = (
  requestArguments: putUsersSelfSessionRequestUsersArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_PUT_USERS_SELF_SESSION_START',
      successful: 'DATA_PUT_USERS_SELF_SESSION_SUCCESSFUL',
      fail: 'DATA_PUT_USERS_SELF_SESSION_FAIL'
    },
    embeddedType: null,
    fnName: 'putUsersSelfSessionRequest'
  }
  const config = {
    headers: { 'Content-Type': 'application/json' },
    baseURL: `${API_BASE_URL}${options?.basePath || '/users-api'}`,
    method: 'PUT',
    url: '/users/self/session',
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: requestArguments.body
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'putUsersSelfSessionRequest', '/users-api'),
    actions: {
      start: {
        type: DATA_PUT_USERS_SELF_SESSION_START, payload, config, meta
      },
      successful: {
        type: DATA_PUT_USERS_SELF_SESSION_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_PUT_USERS_SELF_SESSION_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_GET_USERS_EMAIL_WITH_EMAIL_START = 'DATA_GET_USERS_EMAIL_WITH_EMAIL_START'
export const DATA_GET_USERS_EMAIL_WITH_EMAIL_SUCCESSFUL = 'DATA_GET_USERS_EMAIL_WITH_EMAIL_SUCCESSFUL'
export const DATA_GET_USERS_EMAIL_WITH_EMAIL_FAIL = 'DATA_GET_USERS_EMAIL_WITH_EMAIL_FAIL'

// Returns a User matching given email. Accounts and matching privileges are embedded.
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/users.yaml#/Users/get_users_email__email_
export const getUsersEmailWithEmailRequest = (
  requestArguments: getUsersEmailWithEmailRequestUsersArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_USERS_EMAIL_WITH_EMAIL_START',
      successful: 'DATA_GET_USERS_EMAIL_WITH_EMAIL_SUCCESSFUL',
      fail: 'DATA_GET_USERS_EMAIL_WITH_EMAIL_FAIL'
    },
    embeddedType: {
      accounts: {
        schemaPath: '#/definitions/Enumeral',
        schemaKey: 'Enumeral'
      }
    },
    fnName: 'getUsersEmailWithEmailRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}${options?.basePath || '/users-api'}`,
    method: 'GET',
    url: `/users/email/${requestArguments.path['email']}`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      accounts: {
        schemaPath: '#/definitions/Enumeral',
        schemaKey: 'Enumeral'
      }
    }, 'getUsersEmailWithEmailRequest', '/users-api'),
    actions: {
      start: {
        type: DATA_GET_USERS_EMAIL_WITH_EMAIL_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_USERS_EMAIL_WITH_EMAIL_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_USERS_EMAIL_WITH_EMAIL_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getUsersEmailWithEmailRequestDefinitions = (definitionsArguments: {| requestArgs: getUsersEmailWithEmailRequestUsersArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getUsersEmailWithEmailRequestUsersArguments, payload?: Object |} => ({
  fnName: 'getUsersEmailWithEmailRequest',
  basePath: '/users-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_POST_USERS_START = 'DATA_POST_USERS_START'
export const DATA_POST_USERS_SUCCESSFUL = 'DATA_POST_USERS_SUCCESSFUL'
export const DATA_POST_USERS_FAIL = 'DATA_POST_USERS_FAIL'

// Creates a new User. Either Account or User Groups for new Users have to be specified.
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/users.yaml#/Users/post_users
export const postUsersRequest = (
  requestArguments: postUsersRequestUsersArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_POST_USERS_START',
      successful: 'DATA_POST_USERS_SUCCESSFUL',
      fail: 'DATA_POST_USERS_FAIL'
    },
    embeddedType: null,
    fnName: 'postUsersRequest'
  }
  const config = {
    headers: { 'Content-Type': 'application/json' },
    baseURL: `${API_BASE_URL}${options?.basePath || '/users-api'}`,
    method: 'POST',
    url: '/users',
    params: {
      accountId: options?.selectedAccountId || store.app.selectedAccountId,
      userGroupIds: requestArguments.query?.['userGroupIds'],
      language: options?.languageCode || store.app.languageCode
    },
    data: requestArguments.body
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'postUsersRequest', '/users-api'),
    actions: {
      start: {
        type: DATA_POST_USERS_START, payload, config, meta
      },
      successful: {
        type: DATA_POST_USERS_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_POST_USERS_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_GET_USERS_START = 'DATA_GET_USERS_START'
export const DATA_GET_USERS_SUCCESSFUL = 'DATA_GET_USERS_SUCCESSFUL'
export const DATA_GET_USERS_FAIL = 'DATA_GET_USERS_FAIL'

// Returns a list of users for an Account. Amount of properties may vary depending on user's access level.
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/users.yaml#/Users/get_users
export const getUsersRequest = (
  requestArguments: getUsersRequestUsersArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_USERS_START',
      successful: 'DATA_GET_USERS_SUCCESSFUL',
      fail: 'DATA_GET_USERS_FAIL'
    },
    embeddedType: {
      users: {
        schemaPath: '#/definitions/User',
        schemaKey: 'User'
      }
    },
    fnName: 'getUsersRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}${options?.basePath || '/users-api'}`,
    method: 'GET',
    url: '/users',
    params: {
      accountId: options?.selectedAccountId || store.app.selectedAccountId,
      includeGroups: requestArguments.query?.['includeGroups'],
      language: options?.languageCode || store.app.languageCode
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      users: {
        schemaPath: '#/definitions/User',
        schemaKey: 'User'
      }
    }, 'getUsersRequest', '/users-api'),
    actions: {
      start: {
        type: DATA_GET_USERS_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_USERS_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_USERS_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getUsersRequestDefinitions = (definitionsArguments: {| requestArgs: getUsersRequestUsersArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getUsersRequestUsersArguments, payload?: Object |} => ({
  fnName: 'getUsersRequest',
  basePath: '/users-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_GET_USERS_WITH_ID_START = 'DATA_GET_USERS_WITH_ID_START'
export const DATA_GET_USERS_WITH_ID_SUCCESSFUL = 'DATA_GET_USERS_WITH_ID_SUCCESSFUL'
export const DATA_GET_USERS_WITH_ID_FAIL = 'DATA_GET_USERS_WITH_ID_FAIL'

// Returns a User. Amount of properties may vary depending on user's access level.
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/users.yaml#/Users/get_users__id_
export const getUsersWithIdRequest = (
  requestArguments: getUsersWithIdRequestUsersArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_USERS_WITH_ID_START',
      successful: 'DATA_GET_USERS_WITH_ID_SUCCESSFUL',
      fail: 'DATA_GET_USERS_WITH_ID_FAIL'
    },
    embeddedType: {
      nonEmbedded: {
        schemaPath: '#/definitions/User',
        schemaKey: 'User'
      }
    },
    fnName: 'getUsersWithIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}${options?.basePath || '/users-api'}`,
    method: 'GET',
    url: `/users/${requestArguments.path['id']}`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      nonEmbedded: {
        schemaPath: '#/definitions/User',
        schemaKey: 'User'
      }
    }, 'getUsersWithIdRequest', '/users-api'),
    actions: {
      start: {
        type: DATA_GET_USERS_WITH_ID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_USERS_WITH_ID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_USERS_WITH_ID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getUsersWithIdRequestDefinitions = (definitionsArguments: {| requestArgs: getUsersWithIdRequestUsersArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getUsersWithIdRequestUsersArguments, payload?: Object |} => ({
  fnName: 'getUsersWithIdRequest',
  basePath: '/users-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_DELETE_USERS_WITH_ID_START = 'DATA_DELETE_USERS_WITH_ID_START'
export const DATA_DELETE_USERS_WITH_ID_SUCCESSFUL = 'DATA_DELETE_USERS_WITH_ID_SUCCESSFUL'
export const DATA_DELETE_USERS_WITH_ID_FAIL = 'DATA_DELETE_USERS_WITH_ID_FAIL'

// Removes User from an Account.
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/users.yaml#/Users/delete_users__id_
export const deleteUsersWithIdRequest = (
  requestArguments: deleteUsersWithIdRequestUsersArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_DELETE_USERS_WITH_ID_START',
      successful: 'DATA_DELETE_USERS_WITH_ID_SUCCESSFUL',
      fail: 'DATA_DELETE_USERS_WITH_ID_FAIL'
    },
    embeddedType: null,
    fnName: 'deleteUsersWithIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}${options?.basePath || '/users-api'}`,
    method: 'DELETE',
    url: `/users/${requestArguments.path['id']}`,
    params: {
      accountId: options?.selectedAccountId || store.app.selectedAccountId,
      language: options?.languageCode || store.app.languageCode
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'deleteUsersWithIdRequest', '/users-api'),
    actions: {
      start: {
        type: DATA_DELETE_USERS_WITH_ID_START, payload, config, meta
      },
      successful: {
        type: DATA_DELETE_USERS_WITH_ID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_DELETE_USERS_WITH_ID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_PATCH_USERS_WITH_ID_START = 'DATA_PATCH_USERS_WITH_ID_START'
export const DATA_PATCH_USERS_WITH_ID_SUCCESSFUL = 'DATA_PATCH_USERS_WITH_ID_SUCCESSFUL'
export const DATA_PATCH_USERS_WITH_ID_FAIL = 'DATA_PATCH_USERS_WITH_ID_FAIL'

// Updates user properties. Only admin-level users can modify some properties (for example status).
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/users.yaml#/Users/patch_users__id_
export const patchUsersWithIdRequest = (
  requestArguments: patchUsersWithIdRequestUsersArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_PATCH_USERS_WITH_ID_START',
      successful: 'DATA_PATCH_USERS_WITH_ID_SUCCESSFUL',
      fail: 'DATA_PATCH_USERS_WITH_ID_FAIL'
    },
    embeddedType: null,
    fnName: 'patchUsersWithIdRequest'
  }
  const config = {
    headers: { 'Content-Type': 'application/json' },
    baseURL: `${API_BASE_URL}${options?.basePath || '/users-api'}`,
    method: 'PATCH',
    url: `/users/${requestArguments.path['id']}`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: requestArguments.body
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'patchUsersWithIdRequest', '/users-api'),
    actions: {
      start: {
        type: DATA_PATCH_USERS_WITH_ID_START, payload, config, meta
      },
      successful: {
        type: DATA_PATCH_USERS_WITH_ID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_PATCH_USERS_WITH_ID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_GET_USERS_LOCK_WITH_ID_START = 'DATA_GET_USERS_LOCK_WITH_ID_START'
export const DATA_GET_USERS_LOCK_WITH_ID_SUCCESSFUL = 'DATA_GET_USERS_LOCK_WITH_ID_SUCCESSFUL'
export const DATA_GET_USERS_LOCK_WITH_ID_FAIL = 'DATA_GET_USERS_LOCK_WITH_ID_FAIL'

// If resource is locked, returns information (owner, expiration) about the current lock
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/users.yaml#/Users/get_users__id__lock
export const getUsersLockWithIdRequest = (
  requestArguments: getUsersLockWithIdRequestUsersArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_USERS_LOCK_WITH_ID_START',
      successful: 'DATA_GET_USERS_LOCK_WITH_ID_SUCCESSFUL',
      fail: 'DATA_GET_USERS_LOCK_WITH_ID_FAIL'
    },
    embeddedType: {
      nonEmbedded: {
        schemaPath: '#/definitions/ResourceLock',
        schemaKey: 'ResourceLock'
      }
    },
    fnName: 'getUsersLockWithIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}${options?.basePath || '/users-api'}`,
    method: 'GET',
    url: `/users/${requestArguments.path['id']}/lock`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      nonEmbedded: {
        schemaPath: '#/definitions/ResourceLock',
        schemaKey: 'ResourceLock'
      }
    }, 'getUsersLockWithIdRequest', '/users-api'),
    actions: {
      start: {
        type: DATA_GET_USERS_LOCK_WITH_ID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_USERS_LOCK_WITH_ID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_USERS_LOCK_WITH_ID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getUsersLockWithIdRequestDefinitions = (definitionsArguments: {| requestArgs: getUsersLockWithIdRequestUsersArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getUsersLockWithIdRequestUsersArguments, payload?: Object |} => ({
  fnName: 'getUsersLockWithIdRequest',
  basePath: '/users-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_PUT_USERS_LOCK_WITH_ID_START = 'DATA_PUT_USERS_LOCK_WITH_ID_START'
export const DATA_PUT_USERS_LOCK_WITH_ID_SUCCESSFUL = 'DATA_PUT_USERS_LOCK_WITH_ID_SUCCESSFUL'
export const DATA_PUT_USERS_LOCK_WITH_ID_FAIL = 'DATA_PUT_USERS_LOCK_WITH_ID_FAIL'

// Obtain a lock to a resource for editing. Edits are not permitted before first obtaining a lock.
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/users.yaml#/Users/put_users__id__lock
export const putUsersLockWithIdRequest = (
  requestArguments: putUsersLockWithIdRequestUsersArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_PUT_USERS_LOCK_WITH_ID_START',
      successful: 'DATA_PUT_USERS_LOCK_WITH_ID_SUCCESSFUL',
      fail: 'DATA_PUT_USERS_LOCK_WITH_ID_FAIL'
    },
    embeddedType: null,
    fnName: 'putUsersLockWithIdRequest'
  }
  const config = {
    headers: { 'Content-Type': 'application/json' },
    baseURL: `${API_BASE_URL}${options?.basePath || '/users-api'}`,
    method: 'PUT',
    url: `/users/${requestArguments.path['id']}/lock`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: requestArguments.body
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'putUsersLockWithIdRequest', '/users-api'),
    actions: {
      start: {
        type: DATA_PUT_USERS_LOCK_WITH_ID_START, payload, config, meta
      },
      successful: {
        type: DATA_PUT_USERS_LOCK_WITH_ID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_PUT_USERS_LOCK_WITH_ID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_DELETE_USERS_LOCK_WITH_ID_START = 'DATA_DELETE_USERS_LOCK_WITH_ID_START'
export const DATA_DELETE_USERS_LOCK_WITH_ID_SUCCESSFUL = 'DATA_DELETE_USERS_LOCK_WITH_ID_SUCCESSFUL'
export const DATA_DELETE_USERS_LOCK_WITH_ID_FAIL = 'DATA_DELETE_USERS_LOCK_WITH_ID_FAIL'

// Deletes a resource lock. If the lock was created by another user, error 423 is returned.
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/users.yaml#/Users/delete_users__id__lock
export const deleteUsersLockWithIdRequest = (
  requestArguments: deleteUsersLockWithIdRequestUsersArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_DELETE_USERS_LOCK_WITH_ID_START',
      successful: 'DATA_DELETE_USERS_LOCK_WITH_ID_SUCCESSFUL',
      fail: 'DATA_DELETE_USERS_LOCK_WITH_ID_FAIL'
    },
    embeddedType: null,
    fnName: 'deleteUsersLockWithIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}${options?.basePath || '/users-api'}`,
    method: 'DELETE',
    url: `/users/${requestArguments.path['id']}/lock`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'deleteUsersLockWithIdRequest', '/users-api'),
    actions: {
      start: {
        type: DATA_DELETE_USERS_LOCK_WITH_ID_START, payload, config, meta
      },
      successful: {
        type: DATA_DELETE_USERS_LOCK_WITH_ID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_DELETE_USERS_LOCK_WITH_ID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_GET_USERS_MODULES_WITH_ID_START = 'DATA_GET_USERS_MODULES_WITH_ID_START'
export const DATA_GET_USERS_MODULES_WITH_ID_SUCCESSFUL = 'DATA_GET_USERS_MODULES_WITH_ID_SUCCESSFUL'
export const DATA_GET_USERS_MODULES_WITH_ID_FAIL = 'DATA_GET_USERS_MODULES_WITH_ID_FAIL'

// Gets the modules that user has been granted access
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/users.yaml#/Users/get_users__id__modules
export const getUsersModulesWithIdRequest = (
  requestArguments: getUsersModulesWithIdRequestUsersArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_USERS_MODULES_WITH_ID_START',
      successful: 'DATA_GET_USERS_MODULES_WITH_ID_SUCCESSFUL',
      fail: 'DATA_GET_USERS_MODULES_WITH_ID_FAIL'
    },
    embeddedType: {
      modulesIds: {
        schemaKey: null
      }
    },
    fnName: 'getUsersModulesWithIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}${options?.basePath || '/users-api'}`,
    method: 'GET',
    url: `/users/${requestArguments.path['id']}/modules`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      modulesIds: {
        schemaKey: null
      }
    }, 'getUsersModulesWithIdRequest', '/users-api'),
    actions: {
      start: {
        type: DATA_GET_USERS_MODULES_WITH_ID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_USERS_MODULES_WITH_ID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_USERS_MODULES_WITH_ID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getUsersModulesWithIdRequestDefinitions = (definitionsArguments: {| requestArgs: getUsersModulesWithIdRequestUsersArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getUsersModulesWithIdRequestUsersArguments, payload?: Object |} => ({
  fnName: 'getUsersModulesWithIdRequest',
  basePath: '/users-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_GET_USERS_BUILDINGS_WITH_ID_START = 'DATA_GET_USERS_BUILDINGS_WITH_ID_START'
export const DATA_GET_USERS_BUILDINGS_WITH_ID_SUCCESSFUL = 'DATA_GET_USERS_BUILDINGS_WITH_ID_SUCCESSFUL'
export const DATA_GET_USERS_BUILDINGS_WITH_ID_FAIL = 'DATA_GET_USERS_BUILDINGS_WITH_ID_FAIL'

// Gets buildings that the user has access to
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/users.yaml#/Users/get_users__id__buildings
export const getUsersBuildingsWithIdRequest = (
  requestArguments: getUsersBuildingsWithIdRequestUsersArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_USERS_BUILDINGS_WITH_ID_START',
      successful: 'DATA_GET_USERS_BUILDINGS_WITH_ID_SUCCESSFUL',
      fail: 'DATA_GET_USERS_BUILDINGS_WITH_ID_FAIL'
    },
    embeddedType: {
      buildingIds: {
        schemaKey: null
      }
    },
    fnName: 'getUsersBuildingsWithIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}${options?.basePath || '/users-api'}`,
    method: 'GET',
    url: `/users/${requestArguments.path['id']}/buildings`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      buildingIds: {
        schemaKey: null
      }
    }, 'getUsersBuildingsWithIdRequest', '/users-api'),
    actions: {
      start: {
        type: DATA_GET_USERS_BUILDINGS_WITH_ID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_USERS_BUILDINGS_WITH_ID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_USERS_BUILDINGS_WITH_ID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getUsersBuildingsWithIdRequestDefinitions = (definitionsArguments: {| requestArgs: getUsersBuildingsWithIdRequestUsersArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getUsersBuildingsWithIdRequestUsersArguments, payload?: Object |} => ({
  fnName: 'getUsersBuildingsWithIdRequest',
  basePath: '/users-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_GET_USERS_ESTIMATES_WITH_ID_START = 'DATA_GET_USERS_ESTIMATES_WITH_ID_START'
export const DATA_GET_USERS_ESTIMATES_WITH_ID_SUCCESSFUL = 'DATA_GET_USERS_ESTIMATES_WITH_ID_SUCCESSFUL'
export const DATA_GET_USERS_ESTIMATES_WITH_ID_FAIL = 'DATA_GET_USERS_ESTIMATES_WITH_ID_FAIL'

// Gets estimates that the user has access to
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/users.yaml#/Users/get_users__id__estimates
export const getUsersEstimatesWithIdRequest = (
  requestArguments: getUsersEstimatesWithIdRequestUsersArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_USERS_ESTIMATES_WITH_ID_START',
      successful: 'DATA_GET_USERS_ESTIMATES_WITH_ID_SUCCESSFUL',
      fail: 'DATA_GET_USERS_ESTIMATES_WITH_ID_FAIL'
    },
    embeddedType: {
      estimateIds: {
        schemaKey: null
      }
    },
    fnName: 'getUsersEstimatesWithIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}${options?.basePath || '/users-api'}`,
    method: 'GET',
    url: `/users/${requestArguments.path['id']}/estimates`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      estimateIds: {
        schemaKey: null
      }
    }, 'getUsersEstimatesWithIdRequest', '/users-api'),
    actions: {
      start: {
        type: DATA_GET_USERS_ESTIMATES_WITH_ID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_USERS_ESTIMATES_WITH_ID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_USERS_ESTIMATES_WITH_ID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getUsersEstimatesWithIdRequestDefinitions = (definitionsArguments: {| requestArgs: getUsersEstimatesWithIdRequestUsersArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getUsersEstimatesWithIdRequestUsersArguments, payload?: Object |} => ({
  fnName: 'getUsersEstimatesWithIdRequest',
  basePath: '/users-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_GET_USERS_ACCOUNTS_WITH_ID_START = 'DATA_GET_USERS_ACCOUNTS_WITH_ID_START'
export const DATA_GET_USERS_ACCOUNTS_WITH_ID_SUCCESSFUL = 'DATA_GET_USERS_ACCOUNTS_WITH_ID_SUCCESSFUL'
export const DATA_GET_USERS_ACCOUNTS_WITH_ID_FAIL = 'DATA_GET_USERS_ACCOUNTS_WITH_ID_FAIL'

// Gets Accounts that the User has access to.
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/users.yaml#/Users/get_users__id__accounts
export const getUsersAccountsWithIdRequest = (
  requestArguments: getUsersAccountsWithIdRequestUsersArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_USERS_ACCOUNTS_WITH_ID_START',
      successful: 'DATA_GET_USERS_ACCOUNTS_WITH_ID_SUCCESSFUL',
      fail: 'DATA_GET_USERS_ACCOUNTS_WITH_ID_FAIL'
    },
    embeddedType: {
      accountIds: {
        schemaKey: null
      }
    },
    fnName: 'getUsersAccountsWithIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}${options?.basePath || '/users-api'}`,
    method: 'GET',
    url: `/users/${requestArguments.path['id']}/accounts`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      accountIds: {
        schemaKey: null
      }
    }, 'getUsersAccountsWithIdRequest', '/users-api'),
    actions: {
      start: {
        type: DATA_GET_USERS_ACCOUNTS_WITH_ID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_USERS_ACCOUNTS_WITH_ID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_USERS_ACCOUNTS_WITH_ID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getUsersAccountsWithIdRequestDefinitions = (definitionsArguments: {| requestArgs: getUsersAccountsWithIdRequestUsersArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getUsersAccountsWithIdRequestUsersArguments, payload?: Object |} => ({
  fnName: 'getUsersAccountsWithIdRequest',
  basePath: '/users-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_GET_USERS_ROLES_WITH_ID_START = 'DATA_GET_USERS_ROLES_WITH_ID_START'
export const DATA_GET_USERS_ROLES_WITH_ID_SUCCESSFUL = 'DATA_GET_USERS_ROLES_WITH_ID_SUCCESSFUL'
export const DATA_GET_USERS_ROLES_WITH_ID_FAIL = 'DATA_GET_USERS_ROLES_WITH_ID_FAIL'

// Gets roles that the user has been granted. Includes roles inherited from user groups and licenses.
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/users.yaml#/Users/get_users__id__roles
export const getUsersRolesWithIdRequestDeprecated = (
  requestArguments: getUsersRolesWithIdRequestUsersArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_USERS_ROLES_WITH_ID_START',
      successful: 'DATA_GET_USERS_ROLES_WITH_ID_SUCCESSFUL',
      fail: 'DATA_GET_USERS_ROLES_WITH_ID_FAIL'
    },
    embeddedType: {
      roleIds: {
        schemaKey: null
      }
    },
    fnName: 'getUsersRolesWithIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}${options?.basePath || '/users-api'}`,
    method: 'GET',
    url: `/users/${requestArguments.path['id']}/roles`,
    params: {
      accountId: options?.selectedAccountId || store.app.selectedAccountId,
      language: options?.languageCode || store.app.languageCode
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      roleIds: {
        schemaKey: null
      }
    }, 'getUsersRolesWithIdRequest', '/users-api'),
    actions: {
      start: {
        type: DATA_GET_USERS_ROLES_WITH_ID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_USERS_ROLES_WITH_ID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_USERS_ROLES_WITH_ID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getUsersRolesWithIdRequestDefinitions = (definitionsArguments: {| requestArgs: getUsersRolesWithIdRequestUsersArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getUsersRolesWithIdRequestUsersArguments, payload?: Object |} => ({
  fnName: 'getUsersRolesWithIdRequest',
  basePath: '/users-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_GET_USERS_ROLES_USER_WITH_ID_START = 'DATA_GET_USERS_ROLES_USER_WITH_ID_START'
export const DATA_GET_USERS_ROLES_USER_WITH_ID_SUCCESSFUL = 'DATA_GET_USERS_ROLES_USER_WITH_ID_SUCCESSFUL'
export const DATA_GET_USERS_ROLES_USER_WITH_ID_FAIL = 'DATA_GET_USERS_ROLES_USER_WITH_ID_FAIL'

// Gets roles that has been granted directly to the user.
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/users.yaml#/Users/get_users__id__roles_user
export const getUsersRolesUserWithIdRequestDeprecated = (
  requestArguments: getUsersRolesUserWithIdRequestUsersArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_USERS_ROLES_USER_WITH_ID_START',
      successful: 'DATA_GET_USERS_ROLES_USER_WITH_ID_SUCCESSFUL',
      fail: 'DATA_GET_USERS_ROLES_USER_WITH_ID_FAIL'
    },
    embeddedType: null,
    fnName: 'getUsersRolesUserWithIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}${options?.basePath || '/users-api'}`,
    method: 'GET',
    url: `/users/${requestArguments.path['id']}/roles/user`,
    params: {
      accountId: options?.selectedAccountId || store.app.selectedAccountId,
      language: options?.languageCode || store.app.languageCode
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'getUsersRolesUserWithIdRequest', '/users-api'),
    actions: {
      start: {
        type: DATA_GET_USERS_ROLES_USER_WITH_ID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_USERS_ROLES_USER_WITH_ID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_USERS_ROLES_USER_WITH_ID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getUsersRolesUserWithIdRequestDefinitions = (definitionsArguments: {| requestArgs: getUsersRolesUserWithIdRequestUsersArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getUsersRolesUserWithIdRequestUsersArguments, payload?: Object |} => ({
  fnName: 'getUsersRolesUserWithIdRequest',
  basePath: '/users-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_GET_USERS_ROLES_USER_GROUPS_WITH_ID_START = 'DATA_GET_USERS_ROLES_USER_GROUPS_WITH_ID_START'
export const DATA_GET_USERS_ROLES_USER_GROUPS_WITH_ID_SUCCESSFUL = 'DATA_GET_USERS_ROLES_USER_GROUPS_WITH_ID_SUCCESSFUL'
export const DATA_GET_USERS_ROLES_USER_GROUPS_WITH_ID_FAIL = 'DATA_GET_USERS_ROLES_USER_GROUPS_WITH_ID_FAIL'

// Gets roles that has the user has via user group membership.
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/users.yaml#/Users/get_users__id__roles_user_groups
export const getUsersRolesUserGroupsWithIdRequestDeprecated = (
  requestArguments: getUsersRolesUserGroupsWithIdRequestUsersArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_USERS_ROLES_USER_GROUPS_WITH_ID_START',
      successful: 'DATA_GET_USERS_ROLES_USER_GROUPS_WITH_ID_SUCCESSFUL',
      fail: 'DATA_GET_USERS_ROLES_USER_GROUPS_WITH_ID_FAIL'
    },
    embeddedType: null,
    fnName: 'getUsersRolesUserGroupsWithIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}${options?.basePath || '/users-api'}`,
    method: 'GET',
    url: `/users/${requestArguments.path['id']}/roles/user-groups`,
    params: {
      accountId: options?.selectedAccountId || store.app.selectedAccountId,
      language: options?.languageCode || store.app.languageCode
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'getUsersRolesUserGroupsWithIdRequest', '/users-api'),
    actions: {
      start: {
        type: DATA_GET_USERS_ROLES_USER_GROUPS_WITH_ID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_USERS_ROLES_USER_GROUPS_WITH_ID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_USERS_ROLES_USER_GROUPS_WITH_ID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getUsersRolesUserGroupsWithIdRequestDefinitions = (definitionsArguments: {| requestArgs: getUsersRolesUserGroupsWithIdRequestUsersArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getUsersRolesUserGroupsWithIdRequestUsersArguments, payload?: Object |} => ({
  fnName: 'getUsersRolesUserGroupsWithIdRequest',
  basePath: '/users-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_GET_USERS_ROLES_LICENSE_WITH_ID_START = 'DATA_GET_USERS_ROLES_LICENSE_WITH_ID_START'
export const DATA_GET_USERS_ROLES_LICENSE_WITH_ID_SUCCESSFUL = 'DATA_GET_USERS_ROLES_LICENSE_WITH_ID_SUCCESSFUL'
export const DATA_GET_USERS_ROLES_LICENSE_WITH_ID_FAIL = 'DATA_GET_USERS_ROLES_LICENSE_WITH_ID_FAIL'

// Gets roles that has the user has via account membership.
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/users.yaml#/Users/get_users__id__roles_license
export const getUsersRolesLicenseWithIdRequestDeprecated = (
  requestArguments: getUsersRolesLicenseWithIdRequestUsersArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_USERS_ROLES_LICENSE_WITH_ID_START',
      successful: 'DATA_GET_USERS_ROLES_LICENSE_WITH_ID_SUCCESSFUL',
      fail: 'DATA_GET_USERS_ROLES_LICENSE_WITH_ID_FAIL'
    },
    embeddedType: null,
    fnName: 'getUsersRolesLicenseWithIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}${options?.basePath || '/users-api'}`,
    method: 'GET',
    url: `/users/${requestArguments.path['id']}/roles/license`,
    params: {
      accountId: options?.selectedAccountId || store.app.selectedAccountId,
      language: options?.languageCode || store.app.languageCode
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'getUsersRolesLicenseWithIdRequest', '/users-api'),
    actions: {
      start: {
        type: DATA_GET_USERS_ROLES_LICENSE_WITH_ID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_USERS_ROLES_LICENSE_WITH_ID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_USERS_ROLES_LICENSE_WITH_ID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getUsersRolesLicenseWithIdRequestDefinitions = (definitionsArguments: {| requestArgs: getUsersRolesLicenseWithIdRequestUsersArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getUsersRolesLicenseWithIdRequestUsersArguments, payload?: Object |} => ({
  fnName: 'getUsersRolesLicenseWithIdRequest',
  basePath: '/users-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_POST_USERS_ROLES_WITH_ROLEID_START = 'DATA_POST_USERS_ROLES_WITH_ROLEID_START'
export const DATA_POST_USERS_ROLES_WITH_ROLEID_SUCCESSFUL = 'DATA_POST_USERS_ROLES_WITH_ROLEID_SUCCESSFUL'
export const DATA_POST_USERS_ROLES_WITH_ROLEID_FAIL = 'DATA_POST_USERS_ROLES_WITH_ROLEID_FAIL'

// Grants a role to a user
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/users.yaml#/Users/post_users__id__roles__roleId_
export const postUsersRolesWithRoleIdRequestDeprecated = (
  requestArguments: postUsersRolesWithRoleIdRequestUsersArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_POST_USERS_ROLES_WITH_ROLEID_START',
      successful: 'DATA_POST_USERS_ROLES_WITH_ROLEID_SUCCESSFUL',
      fail: 'DATA_POST_USERS_ROLES_WITH_ROLEID_FAIL'
    },
    embeddedType: null,
    fnName: 'postUsersRolesWithRoleIdRequest'
  }
  const config = {
    headers: { 'Content-Type': 'application/json' },
    baseURL: `${API_BASE_URL}${options?.basePath || '/users-api'}`,
    method: 'POST',
    url: `/users/${requestArguments.path['id']}/roles/${requestArguments.path['roleId']}`,
    params: {
      accountId: options?.selectedAccountId || store.app.selectedAccountId,
      language: options?.languageCode || store.app.languageCode
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'postUsersRolesWithRoleIdRequest', '/users-api'),
    actions: {
      start: {
        type: DATA_POST_USERS_ROLES_WITH_ROLEID_START, payload, config, meta
      },
      successful: {
        type: DATA_POST_USERS_ROLES_WITH_ROLEID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_POST_USERS_ROLES_WITH_ROLEID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_DELETE_USERS_ROLES_WITH_ROLEID_START = 'DATA_DELETE_USERS_ROLES_WITH_ROLEID_START'
export const DATA_DELETE_USERS_ROLES_WITH_ROLEID_SUCCESSFUL = 'DATA_DELETE_USERS_ROLES_WITH_ROLEID_SUCCESSFUL'
export const DATA_DELETE_USERS_ROLES_WITH_ROLEID_FAIL = 'DATA_DELETE_USERS_ROLES_WITH_ROLEID_FAIL'

// Revokes a role from a user
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/users.yaml#/Users/delete_users__id__roles__roleId_
export const deleteUsersRolesWithRoleIdRequestDeprecated = (
  requestArguments: deleteUsersRolesWithRoleIdRequestUsersArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_DELETE_USERS_ROLES_WITH_ROLEID_START',
      successful: 'DATA_DELETE_USERS_ROLES_WITH_ROLEID_SUCCESSFUL',
      fail: 'DATA_DELETE_USERS_ROLES_WITH_ROLEID_FAIL'
    },
    embeddedType: null,
    fnName: 'deleteUsersRolesWithRoleIdRequest'
  }
  const config = {
    headers: { 'Content-Type': 'application/json' },
    baseURL: `${API_BASE_URL}${options?.basePath || '/users-api'}`,
    method: 'DELETE',
    url: `/users/${requestArguments.path['id']}/roles/${requestArguments.path['roleId']}`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'deleteUsersRolesWithRoleIdRequest', '/users-api'),
    actions: {
      start: {
        type: DATA_DELETE_USERS_ROLES_WITH_ROLEID_START, payload, config, meta
      },
      successful: {
        type: DATA_DELETE_USERS_ROLES_WITH_ROLEID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_DELETE_USERS_ROLES_WITH_ROLEID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_GET_ORGANIZATIONS_START = 'DATA_GET_ORGANIZATIONS_START'
export const DATA_GET_ORGANIZATIONS_SUCCESSFUL = 'DATA_GET_ORGANIZATIONS_SUCCESSFUL'
export const DATA_GET_ORGANIZATIONS_FAIL = 'DATA_GET_ORGANIZATIONS_FAIL'

// Returns a list of organization matching given filters
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/users.yaml#/Organizations/get_organizations
export const getOrganizationsRequest = (
  requestArguments: getOrganizationsRequestUsersArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_ORGANIZATIONS_START',
      successful: 'DATA_GET_ORGANIZATIONS_SUCCESSFUL',
      fail: 'DATA_GET_ORGANIZATIONS_FAIL'
    },
    embeddedType: {
      organizations: {
        schemaPath: '#/definitions/Organization',
        schemaKey: 'Organization'
      }
    },
    fnName: 'getOrganizationsRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}${options?.basePath || '/users-api'}`,
    method: 'GET',
    url: '/organizations',
    params: {
      name: requestArguments.query?.['name'],
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      organizations: {
        schemaPath: '#/definitions/Organization',
        schemaKey: 'Organization'
      }
    }, 'getOrganizationsRequest', '/users-api'),
    actions: {
      start: {
        type: DATA_GET_ORGANIZATIONS_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_ORGANIZATIONS_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_ORGANIZATIONS_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getOrganizationsRequestDefinitions = (definitionsArguments: {| requestArgs: getOrganizationsRequestUsersArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getOrganizationsRequestUsersArguments, payload?: Object |} => ({
  fnName: 'getOrganizationsRequest',
  basePath: '/users-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_POST_ORGANIZATIONS_START = 'DATA_POST_ORGANIZATIONS_START'
export const DATA_POST_ORGANIZATIONS_SUCCESSFUL = 'DATA_POST_ORGANIZATIONS_SUCCESSFUL'
export const DATA_POST_ORGANIZATIONS_FAIL = 'DATA_POST_ORGANIZATIONS_FAIL'

// Creates a new organization
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/users.yaml#/Organizations/post_organizations
export const postOrganizationsRequest = (
  requestArguments: postOrganizationsRequestUsersArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_POST_ORGANIZATIONS_START',
      successful: 'DATA_POST_ORGANIZATIONS_SUCCESSFUL',
      fail: 'DATA_POST_ORGANIZATIONS_FAIL'
    },
    embeddedType: null,
    fnName: 'postOrganizationsRequest'
  }
  const config = {
    headers: { 'Content-Type': 'application/json' },
    baseURL: `${API_BASE_URL}${options?.basePath || '/users-api'}`,
    method: 'POST',
    url: '/organizations',
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: requestArguments.body
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'postOrganizationsRequest', '/users-api'),
    actions: {
      start: {
        type: DATA_POST_ORGANIZATIONS_START, payload, config, meta
      },
      successful: {
        type: DATA_POST_ORGANIZATIONS_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_POST_ORGANIZATIONS_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_GET_ORGANIZATIONS_WITH_ID_START = 'DATA_GET_ORGANIZATIONS_WITH_ID_START'
export const DATA_GET_ORGANIZATIONS_WITH_ID_SUCCESSFUL = 'DATA_GET_ORGANIZATIONS_WITH_ID_SUCCESSFUL'
export const DATA_GET_ORGANIZATIONS_WITH_ID_FAIL = 'DATA_GET_ORGANIZATIONS_WITH_ID_FAIL'

// Returns a organization
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/users.yaml#/Organizations/get_organizations__id_
export const getOrganizationsWithIdRequest = (
  requestArguments: getOrganizationsWithIdRequestUsersArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_ORGANIZATIONS_WITH_ID_START',
      successful: 'DATA_GET_ORGANIZATIONS_WITH_ID_SUCCESSFUL',
      fail: 'DATA_GET_ORGANIZATIONS_WITH_ID_FAIL'
    },
    embeddedType: {
      nonEmbedded: {
        schemaPath: '#/definitions/Organization',
        schemaKey: 'Organization'
      }
    },
    fnName: 'getOrganizationsWithIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}${options?.basePath || '/users-api'}`,
    method: 'GET',
    url: `/organizations/${requestArguments.path['id']}`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      nonEmbedded: {
        schemaPath: '#/definitions/Organization',
        schemaKey: 'Organization'
      }
    }, 'getOrganizationsWithIdRequest', '/users-api'),
    actions: {
      start: {
        type: DATA_GET_ORGANIZATIONS_WITH_ID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_ORGANIZATIONS_WITH_ID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_ORGANIZATIONS_WITH_ID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getOrganizationsWithIdRequestDefinitions = (definitionsArguments: {| requestArgs: getOrganizationsWithIdRequestUsersArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getOrganizationsWithIdRequestUsersArguments, payload?: Object |} => ({
  fnName: 'getOrganizationsWithIdRequest',
  basePath: '/users-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_DELETE_ORGANIZATIONS_WITH_ID_START = 'DATA_DELETE_ORGANIZATIONS_WITH_ID_START'
export const DATA_DELETE_ORGANIZATIONS_WITH_ID_SUCCESSFUL = 'DATA_DELETE_ORGANIZATIONS_WITH_ID_SUCCESSFUL'
export const DATA_DELETE_ORGANIZATIONS_WITH_ID_FAIL = 'DATA_DELETE_ORGANIZATIONS_WITH_ID_FAIL'

// Deletes a organization
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/users.yaml#/Organizations/delete_organizations__id_
export const deleteOrganizationsWithIdRequest = (
  requestArguments: deleteOrganizationsWithIdRequestUsersArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_DELETE_ORGANIZATIONS_WITH_ID_START',
      successful: 'DATA_DELETE_ORGANIZATIONS_WITH_ID_SUCCESSFUL',
      fail: 'DATA_DELETE_ORGANIZATIONS_WITH_ID_FAIL'
    },
    embeddedType: null,
    fnName: 'deleteOrganizationsWithIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}${options?.basePath || '/users-api'}`,
    method: 'DELETE',
    url: `/organizations/${requestArguments.path['id']}`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'deleteOrganizationsWithIdRequest', '/users-api'),
    actions: {
      start: {
        type: DATA_DELETE_ORGANIZATIONS_WITH_ID_START, payload, config, meta
      },
      successful: {
        type: DATA_DELETE_ORGANIZATIONS_WITH_ID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_DELETE_ORGANIZATIONS_WITH_ID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_PATCH_ORGANIZATIONS_WITH_ID_START = 'DATA_PATCH_ORGANIZATIONS_WITH_ID_START'
export const DATA_PATCH_ORGANIZATIONS_WITH_ID_SUCCESSFUL = 'DATA_PATCH_ORGANIZATIONS_WITH_ID_SUCCESSFUL'
export const DATA_PATCH_ORGANIZATIONS_WITH_ID_FAIL = 'DATA_PATCH_ORGANIZATIONS_WITH_ID_FAIL'

// Updates organization properties
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/users.yaml#/Organizations/patch_organizations__id_
export const patchOrganizationsWithIdRequest = (
  requestArguments: patchOrganizationsWithIdRequestUsersArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_PATCH_ORGANIZATIONS_WITH_ID_START',
      successful: 'DATA_PATCH_ORGANIZATIONS_WITH_ID_SUCCESSFUL',
      fail: 'DATA_PATCH_ORGANIZATIONS_WITH_ID_FAIL'
    },
    embeddedType: null,
    fnName: 'patchOrganizationsWithIdRequest'
  }
  const config = {
    headers: { 'Content-Type': 'application/json' },
    baseURL: `${API_BASE_URL}${options?.basePath || '/users-api'}`,
    method: 'PATCH',
    url: `/organizations/${requestArguments.path['id']}`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: requestArguments.body
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'patchOrganizationsWithIdRequest', '/users-api'),
    actions: {
      start: {
        type: DATA_PATCH_ORGANIZATIONS_WITH_ID_START, payload, config, meta
      },
      successful: {
        type: DATA_PATCH_ORGANIZATIONS_WITH_ID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_PATCH_ORGANIZATIONS_WITH_ID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_GET_ORGANIZATIONS_LOCK_WITH_ID_START = 'DATA_GET_ORGANIZATIONS_LOCK_WITH_ID_START'
export const DATA_GET_ORGANIZATIONS_LOCK_WITH_ID_SUCCESSFUL = 'DATA_GET_ORGANIZATIONS_LOCK_WITH_ID_SUCCESSFUL'
export const DATA_GET_ORGANIZATIONS_LOCK_WITH_ID_FAIL = 'DATA_GET_ORGANIZATIONS_LOCK_WITH_ID_FAIL'

// If resource is locked, returns information (owner, expiration) about the current lock
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/users.yaml#/Organizations/get_organizations__id__lock
export const getOrganizationsLockWithIdRequest = (
  requestArguments: getOrganizationsLockWithIdRequestUsersArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_ORGANIZATIONS_LOCK_WITH_ID_START',
      successful: 'DATA_GET_ORGANIZATIONS_LOCK_WITH_ID_SUCCESSFUL',
      fail: 'DATA_GET_ORGANIZATIONS_LOCK_WITH_ID_FAIL'
    },
    embeddedType: {
      nonEmbedded: {
        schemaPath: '#/definitions/ResourceLock',
        schemaKey: 'ResourceLock'
      }
    },
    fnName: 'getOrganizationsLockWithIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}${options?.basePath || '/users-api'}`,
    method: 'GET',
    url: `/organizations/${requestArguments.path['id']}/lock`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      nonEmbedded: {
        schemaPath: '#/definitions/ResourceLock',
        schemaKey: 'ResourceLock'
      }
    }, 'getOrganizationsLockWithIdRequest', '/users-api'),
    actions: {
      start: {
        type: DATA_GET_ORGANIZATIONS_LOCK_WITH_ID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_ORGANIZATIONS_LOCK_WITH_ID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_ORGANIZATIONS_LOCK_WITH_ID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getOrganizationsLockWithIdRequestDefinitions = (definitionsArguments: {| requestArgs: getOrganizationsLockWithIdRequestUsersArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getOrganizationsLockWithIdRequestUsersArguments, payload?: Object |} => ({
  fnName: 'getOrganizationsLockWithIdRequest',
  basePath: '/users-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_PUT_ORGANIZATIONS_LOCK_WITH_ID_START = 'DATA_PUT_ORGANIZATIONS_LOCK_WITH_ID_START'
export const DATA_PUT_ORGANIZATIONS_LOCK_WITH_ID_SUCCESSFUL = 'DATA_PUT_ORGANIZATIONS_LOCK_WITH_ID_SUCCESSFUL'
export const DATA_PUT_ORGANIZATIONS_LOCK_WITH_ID_FAIL = 'DATA_PUT_ORGANIZATIONS_LOCK_WITH_ID_FAIL'

// Obtain a lock to a resource for editing. Edits are not permitted before first obtaining a lock.
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/users.yaml#/Organizations/put_organizations__id__lock
export const putOrganizationsLockWithIdRequest = (
  requestArguments: putOrganizationsLockWithIdRequestUsersArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_PUT_ORGANIZATIONS_LOCK_WITH_ID_START',
      successful: 'DATA_PUT_ORGANIZATIONS_LOCK_WITH_ID_SUCCESSFUL',
      fail: 'DATA_PUT_ORGANIZATIONS_LOCK_WITH_ID_FAIL'
    },
    embeddedType: null,
    fnName: 'putOrganizationsLockWithIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}${options?.basePath || '/users-api'}`,
    method: 'PUT',
    url: `/organizations/${requestArguments.path['id']}/lock`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: requestArguments.body
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'putOrganizationsLockWithIdRequest', '/users-api'),
    actions: {
      start: {
        type: DATA_PUT_ORGANIZATIONS_LOCK_WITH_ID_START, payload, config, meta
      },
      successful: {
        type: DATA_PUT_ORGANIZATIONS_LOCK_WITH_ID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_PUT_ORGANIZATIONS_LOCK_WITH_ID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_DELETE_ORGANIZATIONS_LOCK_WITH_ID_START = 'DATA_DELETE_ORGANIZATIONS_LOCK_WITH_ID_START'
export const DATA_DELETE_ORGANIZATIONS_LOCK_WITH_ID_SUCCESSFUL = 'DATA_DELETE_ORGANIZATIONS_LOCK_WITH_ID_SUCCESSFUL'
export const DATA_DELETE_ORGANIZATIONS_LOCK_WITH_ID_FAIL = 'DATA_DELETE_ORGANIZATIONS_LOCK_WITH_ID_FAIL'

// Deletes a resource lock. If the lock was created by another user, error 423 is returned.
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/users.yaml#/Organizations/delete_organizations__id__lock
export const deleteOrganizationsLockWithIdRequest = (
  requestArguments: deleteOrganizationsLockWithIdRequestUsersArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_DELETE_ORGANIZATIONS_LOCK_WITH_ID_START',
      successful: 'DATA_DELETE_ORGANIZATIONS_LOCK_WITH_ID_SUCCESSFUL',
      fail: 'DATA_DELETE_ORGANIZATIONS_LOCK_WITH_ID_FAIL'
    },
    embeddedType: null,
    fnName: 'deleteOrganizationsLockWithIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}${options?.basePath || '/users-api'}`,
    method: 'DELETE',
    url: `/organizations/${requestArguments.path['id']}/lock`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'deleteOrganizationsLockWithIdRequest', '/users-api'),
    actions: {
      start: {
        type: DATA_DELETE_ORGANIZATIONS_LOCK_WITH_ID_START, payload, config, meta
      },
      successful: {
        type: DATA_DELETE_ORGANIZATIONS_LOCK_WITH_ID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_DELETE_ORGANIZATIONS_LOCK_WITH_ID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_GET_ACCOUNTS_START = 'DATA_GET_ACCOUNTS_START'
export const DATA_GET_ACCOUNTS_SUCCESSFUL = 'DATA_GET_ACCOUNTS_SUCCESSFUL'
export const DATA_GET_ACCOUNTS_FAIL = 'DATA_GET_ACCOUNTS_FAIL'

// Returns an embedded list of current Accounts
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/users.yaml#/Accounts/get_accounts
export const getAccountsRequest = (
  requestArguments: getAccountsRequestUsersArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_ACCOUNTS_START',
      successful: 'DATA_GET_ACCOUNTS_SUCCESSFUL',
      fail: 'DATA_GET_ACCOUNTS_FAIL'
    },
    embeddedType: {
      accounts: {
        schemaPath: '#/definitions/Account',
        schemaKey: 'Account'
      }
    },
    fnName: 'getAccountsRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}${options?.basePath || '/users-api'}`,
    method: 'GET',
    url: '/accounts',
    params: {
      name: requestArguments.query?.['name'],
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      accounts: {
        schemaPath: '#/definitions/Account',
        schemaKey: 'Account'
      }
    }, 'getAccountsRequest', '/users-api'),
    actions: {
      start: {
        type: DATA_GET_ACCOUNTS_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_ACCOUNTS_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_ACCOUNTS_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getAccountsRequestDefinitions = (definitionsArguments: {| requestArgs: getAccountsRequestUsersArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getAccountsRequestUsersArguments, payload?: Object |} => ({
  fnName: 'getAccountsRequest',
  basePath: '/users-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_POST_ACCOUNTS_START = 'DATA_POST_ACCOUNTS_START'
export const DATA_POST_ACCOUNTS_SUCCESSFUL = 'DATA_POST_ACCOUNTS_SUCCESSFUL'
export const DATA_POST_ACCOUNTS_FAIL = 'DATA_POST_ACCOUNTS_FAIL'

// Create a new Account
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/users.yaml#/Accounts/post_accounts
export const postAccountsRequest = (
  requestArguments: postAccountsRequestUsersArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_POST_ACCOUNTS_START',
      successful: 'DATA_POST_ACCOUNTS_SUCCESSFUL',
      fail: 'DATA_POST_ACCOUNTS_FAIL'
    },
    embeddedType: null,
    fnName: 'postAccountsRequest'
  }
  const config = {
    headers: { 'Content-Type': 'application/json' },
    baseURL: `${API_BASE_URL}${options?.basePath || '/users-api'}`,
    method: 'POST',
    url: '/accounts',
    params: {
      licenseType: requestArguments.query['licenseType'],
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: requestArguments.body
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'postAccountsRequest', '/users-api'),
    actions: {
      start: {
        type: DATA_POST_ACCOUNTS_START, payload, config, meta
      },
      successful: {
        type: DATA_POST_ACCOUNTS_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_POST_ACCOUNTS_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_GET_ACCOUNTS_WITH_ID_START = 'DATA_GET_ACCOUNTS_WITH_ID_START'
export const DATA_GET_ACCOUNTS_WITH_ID_SUCCESSFUL = 'DATA_GET_ACCOUNTS_WITH_ID_SUCCESSFUL'
export const DATA_GET_ACCOUNTS_WITH_ID_FAIL = 'DATA_GET_ACCOUNTS_WITH_ID_FAIL'

// Get an Account
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/users.yaml#/Accounts/get_accounts__id_
export const getAccountsWithIdRequest = (
  requestArguments: getAccountsWithIdRequestUsersArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_ACCOUNTS_WITH_ID_START',
      successful: 'DATA_GET_ACCOUNTS_WITH_ID_SUCCESSFUL',
      fail: 'DATA_GET_ACCOUNTS_WITH_ID_FAIL'
    },
    embeddedType: {
      nonEmbedded: {
        schemaPath: '#/definitions/Account',
        schemaKey: 'Account'
      }
    },
    fnName: 'getAccountsWithIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}${options?.basePath || '/users-api'}`,
    method: 'GET',
    url: `/accounts/${requestArguments.path['id']}`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      nonEmbedded: {
        schemaPath: '#/definitions/Account',
        schemaKey: 'Account'
      }
    }, 'getAccountsWithIdRequest', '/users-api'),
    actions: {
      start: {
        type: DATA_GET_ACCOUNTS_WITH_ID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_ACCOUNTS_WITH_ID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_ACCOUNTS_WITH_ID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getAccountsWithIdRequestDefinitions = (definitionsArguments: {| requestArgs: getAccountsWithIdRequestUsersArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getAccountsWithIdRequestUsersArguments, payload?: Object |} => ({
  fnName: 'getAccountsWithIdRequest',
  basePath: '/users-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_DELETE_ACCOUNTS_WITH_ID_START = 'DATA_DELETE_ACCOUNTS_WITH_ID_START'
export const DATA_DELETE_ACCOUNTS_WITH_ID_SUCCESSFUL = 'DATA_DELETE_ACCOUNTS_WITH_ID_SUCCESSFUL'
export const DATA_DELETE_ACCOUNTS_WITH_ID_FAIL = 'DATA_DELETE_ACCOUNTS_WITH_ID_FAIL'

// Delete an Account. Requires acquiring a resource lock first.
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/users.yaml#/Accounts/delete_accounts__id_
export const deleteAccountsWithIdRequest = (
  requestArguments: deleteAccountsWithIdRequestUsersArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_DELETE_ACCOUNTS_WITH_ID_START',
      successful: 'DATA_DELETE_ACCOUNTS_WITH_ID_SUCCESSFUL',
      fail: 'DATA_DELETE_ACCOUNTS_WITH_ID_FAIL'
    },
    embeddedType: null,
    fnName: 'deleteAccountsWithIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}${options?.basePath || '/users-api'}`,
    method: 'DELETE',
    url: `/accounts/${requestArguments.path['id']}`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'deleteAccountsWithIdRequest', '/users-api'),
    actions: {
      start: {
        type: DATA_DELETE_ACCOUNTS_WITH_ID_START, payload, config, meta
      },
      successful: {
        type: DATA_DELETE_ACCOUNTS_WITH_ID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_DELETE_ACCOUNTS_WITH_ID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_PATCH_ACCOUNTS_WITH_ID_START = 'DATA_PATCH_ACCOUNTS_WITH_ID_START'
export const DATA_PATCH_ACCOUNTS_WITH_ID_SUCCESSFUL = 'DATA_PATCH_ACCOUNTS_WITH_ID_SUCCESSFUL'
export const DATA_PATCH_ACCOUNTS_WITH_ID_FAIL = 'DATA_PATCH_ACCOUNTS_WITH_ID_FAIL'

// Update account properties. Requires acquiring a resource lock first.
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/users.yaml#/Accounts/patch_accounts__id_
export const patchAccountsWithIdRequest = (
  requestArguments: patchAccountsWithIdRequestUsersArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_PATCH_ACCOUNTS_WITH_ID_START',
      successful: 'DATA_PATCH_ACCOUNTS_WITH_ID_SUCCESSFUL',
      fail: 'DATA_PATCH_ACCOUNTS_WITH_ID_FAIL'
    },
    embeddedType: null,
    fnName: 'patchAccountsWithIdRequest'
  }
  const config = {
    headers: { 'Content-Type': 'application/json' },
    baseURL: `${API_BASE_URL}${options?.basePath || '/users-api'}`,
    method: 'PATCH',
    url: `/accounts/${requestArguments.path['id']}`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: requestArguments.body
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'patchAccountsWithIdRequest', '/users-api'),
    actions: {
      start: {
        type: DATA_PATCH_ACCOUNTS_WITH_ID_START, payload, config, meta
      },
      successful: {
        type: DATA_PATCH_ACCOUNTS_WITH_ID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_PATCH_ACCOUNTS_WITH_ID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_GET_ACCOUNTS_LOCK_WITH_ID_START = 'DATA_GET_ACCOUNTS_LOCK_WITH_ID_START'
export const DATA_GET_ACCOUNTS_LOCK_WITH_ID_SUCCESSFUL = 'DATA_GET_ACCOUNTS_LOCK_WITH_ID_SUCCESSFUL'
export const DATA_GET_ACCOUNTS_LOCK_WITH_ID_FAIL = 'DATA_GET_ACCOUNTS_LOCK_WITH_ID_FAIL'

// If resource is locked, returns information (owner, expiration) about the current lock
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/users.yaml#/Accounts/get_accounts__id__lock
export const getAccountsLockWithIdRequest = (
  requestArguments: getAccountsLockWithIdRequestUsersArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_ACCOUNTS_LOCK_WITH_ID_START',
      successful: 'DATA_GET_ACCOUNTS_LOCK_WITH_ID_SUCCESSFUL',
      fail: 'DATA_GET_ACCOUNTS_LOCK_WITH_ID_FAIL'
    },
    embeddedType: {
      nonEmbedded: {
        schemaPath: '#/definitions/ResourceLock',
        schemaKey: 'ResourceLock'
      }
    },
    fnName: 'getAccountsLockWithIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}${options?.basePath || '/users-api'}`,
    method: 'GET',
    url: `/accounts/${requestArguments.path['id']}/lock`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      nonEmbedded: {
        schemaPath: '#/definitions/ResourceLock',
        schemaKey: 'ResourceLock'
      }
    }, 'getAccountsLockWithIdRequest', '/users-api'),
    actions: {
      start: {
        type: DATA_GET_ACCOUNTS_LOCK_WITH_ID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_ACCOUNTS_LOCK_WITH_ID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_ACCOUNTS_LOCK_WITH_ID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getAccountsLockWithIdRequestDefinitions = (definitionsArguments: {| requestArgs: getAccountsLockWithIdRequestUsersArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getAccountsLockWithIdRequestUsersArguments, payload?: Object |} => ({
  fnName: 'getAccountsLockWithIdRequest',
  basePath: '/users-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_PUT_ACCOUNTS_LOCK_WITH_ID_START = 'DATA_PUT_ACCOUNTS_LOCK_WITH_ID_START'
export const DATA_PUT_ACCOUNTS_LOCK_WITH_ID_SUCCESSFUL = 'DATA_PUT_ACCOUNTS_LOCK_WITH_ID_SUCCESSFUL'
export const DATA_PUT_ACCOUNTS_LOCK_WITH_ID_FAIL = 'DATA_PUT_ACCOUNTS_LOCK_WITH_ID_FAIL'

// Obtain a lock to a resource for editing. Edits are not permitted before first obtaining a lock.
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/users.yaml#/Accounts/put_accounts__id__lock
export const putAccountsLockWithIdRequest = (
  requestArguments: putAccountsLockWithIdRequestUsersArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_PUT_ACCOUNTS_LOCK_WITH_ID_START',
      successful: 'DATA_PUT_ACCOUNTS_LOCK_WITH_ID_SUCCESSFUL',
      fail: 'DATA_PUT_ACCOUNTS_LOCK_WITH_ID_FAIL'
    },
    embeddedType: null,
    fnName: 'putAccountsLockWithIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}${options?.basePath || '/users-api'}`,
    method: 'PUT',
    url: `/accounts/${requestArguments.path['id']}/lock`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: requestArguments.body
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'putAccountsLockWithIdRequest', '/users-api'),
    actions: {
      start: {
        type: DATA_PUT_ACCOUNTS_LOCK_WITH_ID_START, payload, config, meta
      },
      successful: {
        type: DATA_PUT_ACCOUNTS_LOCK_WITH_ID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_PUT_ACCOUNTS_LOCK_WITH_ID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_DELETE_ACCOUNTS_LOCK_WITH_ID_START = 'DATA_DELETE_ACCOUNTS_LOCK_WITH_ID_START'
export const DATA_DELETE_ACCOUNTS_LOCK_WITH_ID_SUCCESSFUL = 'DATA_DELETE_ACCOUNTS_LOCK_WITH_ID_SUCCESSFUL'
export const DATA_DELETE_ACCOUNTS_LOCK_WITH_ID_FAIL = 'DATA_DELETE_ACCOUNTS_LOCK_WITH_ID_FAIL'

// Deletes a resource lock. If the lock was created by another user, error 423 is returned.
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/users.yaml#/Accounts/delete_accounts__id__lock
export const deleteAccountsLockWithIdRequest = (
  requestArguments: deleteAccountsLockWithIdRequestUsersArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_DELETE_ACCOUNTS_LOCK_WITH_ID_START',
      successful: 'DATA_DELETE_ACCOUNTS_LOCK_WITH_ID_SUCCESSFUL',
      fail: 'DATA_DELETE_ACCOUNTS_LOCK_WITH_ID_FAIL'
    },
    embeddedType: null,
    fnName: 'deleteAccountsLockWithIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}${options?.basePath || '/users-api'}`,
    method: 'DELETE',
    url: `/accounts/${requestArguments.path['id']}/lock`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'deleteAccountsLockWithIdRequest', '/users-api'),
    actions: {
      start: {
        type: DATA_DELETE_ACCOUNTS_LOCK_WITH_ID_START, payload, config, meta
      },
      successful: {
        type: DATA_DELETE_ACCOUNTS_LOCK_WITH_ID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_DELETE_ACCOUNTS_LOCK_WITH_ID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_GET_ACCOUNTS_BILLING_WITH_ID_START = 'DATA_GET_ACCOUNTS_BILLING_WITH_ID_START'
export const DATA_GET_ACCOUNTS_BILLING_WITH_ID_SUCCESSFUL = 'DATA_GET_ACCOUNTS_BILLING_WITH_ID_SUCCESSFUL'
export const DATA_GET_ACCOUNTS_BILLING_WITH_ID_FAIL = 'DATA_GET_ACCOUNTS_BILLING_WITH_ID_FAIL'

// Get billing information for an Account.
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/users.yaml#/Accounts/get_accounts__id__billing
export const getAccountsBillingWithIdRequest = (
  requestArguments: getAccountsBillingWithIdRequestUsersArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_ACCOUNTS_BILLING_WITH_ID_START',
      successful: 'DATA_GET_ACCOUNTS_BILLING_WITH_ID_SUCCESSFUL',
      fail: 'DATA_GET_ACCOUNTS_BILLING_WITH_ID_FAIL'
    },
    embeddedType: {
      nonEmbedded: {
        schemaPath: '#/definitions/AccountBilling',
        schemaKey: 'AccountBilling'
      }
    },
    fnName: 'getAccountsBillingWithIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}${options?.basePath || '/users-api'}`,
    method: 'GET',
    url: `/accounts/${requestArguments.path['id']}/billing`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      nonEmbedded: {
        schemaPath: '#/definitions/AccountBilling',
        schemaKey: 'AccountBilling'
      }
    }, 'getAccountsBillingWithIdRequest', '/users-api'),
    actions: {
      start: {
        type: DATA_GET_ACCOUNTS_BILLING_WITH_ID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_ACCOUNTS_BILLING_WITH_ID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_ACCOUNTS_BILLING_WITH_ID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getAccountsBillingWithIdRequestDefinitions = (definitionsArguments: {| requestArgs: getAccountsBillingWithIdRequestUsersArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getAccountsBillingWithIdRequestUsersArguments, payload?: Object |} => ({
  fnName: 'getAccountsBillingWithIdRequest',
  basePath: '/users-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_PATCH_ACCOUNTS_BILLING_WITH_ID_START = 'DATA_PATCH_ACCOUNTS_BILLING_WITH_ID_START'
export const DATA_PATCH_ACCOUNTS_BILLING_WITH_ID_SUCCESSFUL = 'DATA_PATCH_ACCOUNTS_BILLING_WITH_ID_SUCCESSFUL'
export const DATA_PATCH_ACCOUNTS_BILLING_WITH_ID_FAIL = 'DATA_PATCH_ACCOUNTS_BILLING_WITH_ID_FAIL'

// Update billing information for an Account.
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/users.yaml#/Accounts/patch_accounts__id__billing
export const patchAccountsBillingWithIdRequest = (
  requestArguments: patchAccountsBillingWithIdRequestUsersArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_PATCH_ACCOUNTS_BILLING_WITH_ID_START',
      successful: 'DATA_PATCH_ACCOUNTS_BILLING_WITH_ID_SUCCESSFUL',
      fail: 'DATA_PATCH_ACCOUNTS_BILLING_WITH_ID_FAIL'
    },
    embeddedType: null,
    fnName: 'patchAccountsBillingWithIdRequest'
  }
  const config = {
    headers: { 'Content-Type': 'application/json' },
    baseURL: `${API_BASE_URL}${options?.basePath || '/users-api'}`,
    method: 'PATCH',
    url: `/accounts/${requestArguments.path['id']}/billing`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: requestArguments.body
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'patchAccountsBillingWithIdRequest', '/users-api'),
    actions: {
      start: {
        type: DATA_PATCH_ACCOUNTS_BILLING_WITH_ID_START, payload, config, meta
      },
      successful: {
        type: DATA_PATCH_ACCOUNTS_BILLING_WITH_ID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_PATCH_ACCOUNTS_BILLING_WITH_ID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_GET_ACCOUNTS_STATUS_WITH_ID_START = 'DATA_GET_ACCOUNTS_STATUS_WITH_ID_START'
export const DATA_GET_ACCOUNTS_STATUS_WITH_ID_SUCCESSFUL = 'DATA_GET_ACCOUNTS_STATUS_WITH_ID_SUCCESSFUL'
export const DATA_GET_ACCOUNTS_STATUS_WITH_ID_FAIL = 'DATA_GET_ACCOUNTS_STATUS_WITH_ID_FAIL'

// Get status information for an Account.
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/users.yaml#/Accounts/get_accounts__id__status
export const getAccountsStatusWithIdRequest = (
  requestArguments: getAccountsStatusWithIdRequestUsersArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_ACCOUNTS_STATUS_WITH_ID_START',
      successful: 'DATA_GET_ACCOUNTS_STATUS_WITH_ID_SUCCESSFUL',
      fail: 'DATA_GET_ACCOUNTS_STATUS_WITH_ID_FAIL'
    },
    embeddedType: {
      nonEmbedded: {
        schemaPath: '#/definitions/AccountStatus',
        schemaKey: 'AccountStatus'
      }
    },
    fnName: 'getAccountsStatusWithIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}${options?.basePath || '/users-api'}`,
    method: 'GET',
    url: `/accounts/${requestArguments.path['id']}/status`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      nonEmbedded: {
        schemaPath: '#/definitions/AccountStatus',
        schemaKey: 'AccountStatus'
      }
    }, 'getAccountsStatusWithIdRequest', '/users-api'),
    actions: {
      start: {
        type: DATA_GET_ACCOUNTS_STATUS_WITH_ID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_ACCOUNTS_STATUS_WITH_ID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_ACCOUNTS_STATUS_WITH_ID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getAccountsStatusWithIdRequestDefinitions = (definitionsArguments: {| requestArgs: getAccountsStatusWithIdRequestUsersArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getAccountsStatusWithIdRequestUsersArguments, payload?: Object |} => ({
  fnName: 'getAccountsStatusWithIdRequest',
  basePath: '/users-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_PATCH_ACCOUNTS_STATUS_WITH_ID_START = 'DATA_PATCH_ACCOUNTS_STATUS_WITH_ID_START'
export const DATA_PATCH_ACCOUNTS_STATUS_WITH_ID_SUCCESSFUL = 'DATA_PATCH_ACCOUNTS_STATUS_WITH_ID_SUCCESSFUL'
export const DATA_PATCH_ACCOUNTS_STATUS_WITH_ID_FAIL = 'DATA_PATCH_ACCOUNTS_STATUS_WITH_ID_FAIL'

// Update status information for an Account.
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/users.yaml#/Accounts/patch_accounts__id__status
export const patchAccountsStatusWithIdRequest = (
  requestArguments: patchAccountsStatusWithIdRequestUsersArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_PATCH_ACCOUNTS_STATUS_WITH_ID_START',
      successful: 'DATA_PATCH_ACCOUNTS_STATUS_WITH_ID_SUCCESSFUL',
      fail: 'DATA_PATCH_ACCOUNTS_STATUS_WITH_ID_FAIL'
    },
    embeddedType: null,
    fnName: 'patchAccountsStatusWithIdRequest'
  }
  const config = {
    headers: { 'Content-Type': 'application/json' },
    baseURL: `${API_BASE_URL}${options?.basePath || '/users-api'}`,
    method: 'PATCH',
    url: `/accounts/${requestArguments.path['id']}/status`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: requestArguments.body
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'patchAccountsStatusWithIdRequest', '/users-api'),
    actions: {
      start: {
        type: DATA_PATCH_ACCOUNTS_STATUS_WITH_ID_START, payload, config, meta
      },
      successful: {
        type: DATA_PATCH_ACCOUNTS_STATUS_WITH_ID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_PATCH_ACCOUNTS_STATUS_WITH_ID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_GET_LICENSES_START = 'DATA_GET_LICENSES_START'
export const DATA_GET_LICENSES_SUCCESSFUL = 'DATA_GET_LICENSES_SUCCESSFUL'
export const DATA_GET_LICENSES_FAIL = 'DATA_GET_LICENSES_FAIL'

// Returns an embedded list of current Licenses for an Account.
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/users.yaml#/Licenses/get_licenses
export const getLicensesRequest = (
  requestArguments: getLicensesRequestUsersArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_LICENSES_START',
      successful: 'DATA_GET_LICENSES_SUCCESSFUL',
      fail: 'DATA_GET_LICENSES_FAIL'
    },
    embeddedType: {
      licenses: {
        schemaPath: '#/definitions/License',
        schemaKey: 'License'
      }
    },
    fnName: 'getLicensesRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}${options?.basePath || '/users-api'}`,
    method: 'GET',
    url: '/licenses',
    params: {
      accountId: options?.selectedAccountId || store.app.selectedAccountId,
      embedFeatures: requestArguments.query?.['embedFeatures'],
      language: options?.languageCode || store.app.languageCode
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      licenses: {
        schemaPath: '#/definitions/License',
        schemaKey: 'License'
      }
    }, 'getLicensesRequest', '/users-api'),
    actions: {
      start: {
        type: DATA_GET_LICENSES_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_LICENSES_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_LICENSES_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getLicensesRequestDefinitions = (definitionsArguments: {| requestArgs: getLicensesRequestUsersArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getLicensesRequestUsersArguments, payload?: Object |} => ({
  fnName: 'getLicensesRequest',
  basePath: '/users-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_POST_LICENSES_START = 'DATA_POST_LICENSES_START'
export const DATA_POST_LICENSES_SUCCESSFUL = 'DATA_POST_LICENSES_SUCCESSFUL'
export const DATA_POST_LICENSES_FAIL = 'DATA_POST_LICENSES_FAIL'

// Create a new License
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/users.yaml#/Licenses/post_licenses
export const postLicensesRequest = (
  requestArguments: postLicensesRequestUsersArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_POST_LICENSES_START',
      successful: 'DATA_POST_LICENSES_SUCCESSFUL',
      fail: 'DATA_POST_LICENSES_FAIL'
    },
    embeddedType: null,
    fnName: 'postLicensesRequest'
  }
  const config = {
    headers: { 'Content-Type': 'application/json' },
    baseURL: `${API_BASE_URL}${options?.basePath || '/users-api'}`,
    method: 'POST',
    url: '/licenses',
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: requestArguments.body
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'postLicensesRequest', '/users-api'),
    actions: {
      start: {
        type: DATA_POST_LICENSES_START, payload, config, meta
      },
      successful: {
        type: DATA_POST_LICENSES_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_POST_LICENSES_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_GET_LICENSES_WITH_ID_START = 'DATA_GET_LICENSES_WITH_ID_START'
export const DATA_GET_LICENSES_WITH_ID_SUCCESSFUL = 'DATA_GET_LICENSES_WITH_ID_SUCCESSFUL'
export const DATA_GET_LICENSES_WITH_ID_FAIL = 'DATA_GET_LICENSES_WITH_ID_FAIL'

// Get a License
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/users.yaml#/Licenses/get_licenses__id_
export const getLicensesWithIdRequest = (
  requestArguments: getLicensesWithIdRequestUsersArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_LICENSES_WITH_ID_START',
      successful: 'DATA_GET_LICENSES_WITH_ID_SUCCESSFUL',
      fail: 'DATA_GET_LICENSES_WITH_ID_FAIL'
    },
    embeddedType: {
      nonEmbedded: {
        schemaPath: '#/definitions/License',
        schemaKey: 'License'
      }
    },
    fnName: 'getLicensesWithIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}${options?.basePath || '/users-api'}`,
    method: 'GET',
    url: `/licenses/${requestArguments.path['id']}`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      nonEmbedded: {
        schemaPath: '#/definitions/License',
        schemaKey: 'License'
      }
    }, 'getLicensesWithIdRequest', '/users-api'),
    actions: {
      start: {
        type: DATA_GET_LICENSES_WITH_ID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_LICENSES_WITH_ID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_LICENSES_WITH_ID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getLicensesWithIdRequestDefinitions = (definitionsArguments: {| requestArgs: getLicensesWithIdRequestUsersArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getLicensesWithIdRequestUsersArguments, payload?: Object |} => ({
  fnName: 'getLicensesWithIdRequest',
  basePath: '/users-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_DELETE_LICENSES_WITH_ID_START = 'DATA_DELETE_LICENSES_WITH_ID_START'
export const DATA_DELETE_LICENSES_WITH_ID_SUCCESSFUL = 'DATA_DELETE_LICENSES_WITH_ID_SUCCESSFUL'
export const DATA_DELETE_LICENSES_WITH_ID_FAIL = 'DATA_DELETE_LICENSES_WITH_ID_FAIL'

// Delete a License. Requires acquiring a resource lock first.
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/users.yaml#/Licenses/delete_licenses__id_
export const deleteLicensesWithIdRequest = (
  requestArguments: deleteLicensesWithIdRequestUsersArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_DELETE_LICENSES_WITH_ID_START',
      successful: 'DATA_DELETE_LICENSES_WITH_ID_SUCCESSFUL',
      fail: 'DATA_DELETE_LICENSES_WITH_ID_FAIL'
    },
    embeddedType: null,
    fnName: 'deleteLicensesWithIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}${options?.basePath || '/users-api'}`,
    method: 'DELETE',
    url: `/licenses/${requestArguments.path['id']}`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'deleteLicensesWithIdRequest', '/users-api'),
    actions: {
      start: {
        type: DATA_DELETE_LICENSES_WITH_ID_START, payload, config, meta
      },
      successful: {
        type: DATA_DELETE_LICENSES_WITH_ID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_DELETE_LICENSES_WITH_ID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_PATCH_LICENSES_WITH_ID_START = 'DATA_PATCH_LICENSES_WITH_ID_START'
export const DATA_PATCH_LICENSES_WITH_ID_SUCCESSFUL = 'DATA_PATCH_LICENSES_WITH_ID_SUCCESSFUL'
export const DATA_PATCH_LICENSES_WITH_ID_FAIL = 'DATA_PATCH_LICENSES_WITH_ID_FAIL'

// Update license properties. Requires acquiring a resource lock first.
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/users.yaml#/Licenses/patch_licenses__id_
export const patchLicensesWithIdRequest = (
  requestArguments: patchLicensesWithIdRequestUsersArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_PATCH_LICENSES_WITH_ID_START',
      successful: 'DATA_PATCH_LICENSES_WITH_ID_SUCCESSFUL',
      fail: 'DATA_PATCH_LICENSES_WITH_ID_FAIL'
    },
    embeddedType: null,
    fnName: 'patchLicensesWithIdRequest'
  }
  const config = {
    headers: { 'Content-Type': 'application/json' },
    baseURL: `${API_BASE_URL}${options?.basePath || '/users-api'}`,
    method: 'PATCH',
    url: `/licenses/${requestArguments.path['id']}`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: requestArguments.body
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'patchLicensesWithIdRequest', '/users-api'),
    actions: {
      start: {
        type: DATA_PATCH_LICENSES_WITH_ID_START, payload, config, meta
      },
      successful: {
        type: DATA_PATCH_LICENSES_WITH_ID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_PATCH_LICENSES_WITH_ID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_GET_LICENSES_LOCK_WITH_ID_START = 'DATA_GET_LICENSES_LOCK_WITH_ID_START'
export const DATA_GET_LICENSES_LOCK_WITH_ID_SUCCESSFUL = 'DATA_GET_LICENSES_LOCK_WITH_ID_SUCCESSFUL'
export const DATA_GET_LICENSES_LOCK_WITH_ID_FAIL = 'DATA_GET_LICENSES_LOCK_WITH_ID_FAIL'

// If resource is locked, returns information (owner, expiration) about the current lock
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/users.yaml#/Licenses/get_licenses__id__lock
export const getLicensesLockWithIdRequest = (
  requestArguments: getLicensesLockWithIdRequestUsersArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_LICENSES_LOCK_WITH_ID_START',
      successful: 'DATA_GET_LICENSES_LOCK_WITH_ID_SUCCESSFUL',
      fail: 'DATA_GET_LICENSES_LOCK_WITH_ID_FAIL'
    },
    embeddedType: {
      nonEmbedded: {
        schemaPath: '#/definitions/ResourceLock',
        schemaKey: 'ResourceLock'
      }
    },
    fnName: 'getLicensesLockWithIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}${options?.basePath || '/users-api'}`,
    method: 'GET',
    url: `/licenses/${requestArguments.path['id']}/lock`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      nonEmbedded: {
        schemaPath: '#/definitions/ResourceLock',
        schemaKey: 'ResourceLock'
      }
    }, 'getLicensesLockWithIdRequest', '/users-api'),
    actions: {
      start: {
        type: DATA_GET_LICENSES_LOCK_WITH_ID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_LICENSES_LOCK_WITH_ID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_LICENSES_LOCK_WITH_ID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getLicensesLockWithIdRequestDefinitions = (definitionsArguments: {| requestArgs: getLicensesLockWithIdRequestUsersArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getLicensesLockWithIdRequestUsersArguments, payload?: Object |} => ({
  fnName: 'getLicensesLockWithIdRequest',
  basePath: '/users-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_PUT_LICENSES_LOCK_WITH_ID_START = 'DATA_PUT_LICENSES_LOCK_WITH_ID_START'
export const DATA_PUT_LICENSES_LOCK_WITH_ID_SUCCESSFUL = 'DATA_PUT_LICENSES_LOCK_WITH_ID_SUCCESSFUL'
export const DATA_PUT_LICENSES_LOCK_WITH_ID_FAIL = 'DATA_PUT_LICENSES_LOCK_WITH_ID_FAIL'

// Obtain a lock to a resource for editing. Edits are not permitted before first obtaining a lock.
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/users.yaml#/Licenses/put_licenses__id__lock
export const putLicensesLockWithIdRequest = (
  requestArguments: putLicensesLockWithIdRequestUsersArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_PUT_LICENSES_LOCK_WITH_ID_START',
      successful: 'DATA_PUT_LICENSES_LOCK_WITH_ID_SUCCESSFUL',
      fail: 'DATA_PUT_LICENSES_LOCK_WITH_ID_FAIL'
    },
    embeddedType: null,
    fnName: 'putLicensesLockWithIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}${options?.basePath || '/users-api'}`,
    method: 'PUT',
    url: `/licenses/${requestArguments.path['id']}/lock`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: requestArguments.body
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'putLicensesLockWithIdRequest', '/users-api'),
    actions: {
      start: {
        type: DATA_PUT_LICENSES_LOCK_WITH_ID_START, payload, config, meta
      },
      successful: {
        type: DATA_PUT_LICENSES_LOCK_WITH_ID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_PUT_LICENSES_LOCK_WITH_ID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_DELETE_LICENSES_LOCK_WITH_ID_START = 'DATA_DELETE_LICENSES_LOCK_WITH_ID_START'
export const DATA_DELETE_LICENSES_LOCK_WITH_ID_SUCCESSFUL = 'DATA_DELETE_LICENSES_LOCK_WITH_ID_SUCCESSFUL'
export const DATA_DELETE_LICENSES_LOCK_WITH_ID_FAIL = 'DATA_DELETE_LICENSES_LOCK_WITH_ID_FAIL'

// Deletes a resource lock. If the lock was created by another user, error 423 is returned.
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/users.yaml#/Licenses/delete_licenses__id__lock
export const deleteLicensesLockWithIdRequest = (
  requestArguments: deleteLicensesLockWithIdRequestUsersArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_DELETE_LICENSES_LOCK_WITH_ID_START',
      successful: 'DATA_DELETE_LICENSES_LOCK_WITH_ID_SUCCESSFUL',
      fail: 'DATA_DELETE_LICENSES_LOCK_WITH_ID_FAIL'
    },
    embeddedType: null,
    fnName: 'deleteLicensesLockWithIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}${options?.basePath || '/users-api'}`,
    method: 'DELETE',
    url: `/licenses/${requestArguments.path['id']}/lock`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'deleteLicensesLockWithIdRequest', '/users-api'),
    actions: {
      start: {
        type: DATA_DELETE_LICENSES_LOCK_WITH_ID_START, payload, config, meta
      },
      successful: {
        type: DATA_DELETE_LICENSES_LOCK_WITH_ID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_DELETE_LICENSES_LOCK_WITH_ID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_GET_LICENSES_MODULES_WITH_ID_START = 'DATA_GET_LICENSES_MODULES_WITH_ID_START'
export const DATA_GET_LICENSES_MODULES_WITH_ID_SUCCESSFUL = 'DATA_GET_LICENSES_MODULES_WITH_ID_SUCCESSFUL'
export const DATA_GET_LICENSES_MODULES_WITH_ID_FAIL = 'DATA_GET_LICENSES_MODULES_WITH_ID_FAIL'

// Gets modules that the license has been granted access to.
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/users.yaml#/Licenses/get_licenses__id__modules
export const getLicensesModulesWithIdRequest = (
  requestArguments: getLicensesModulesWithIdRequestUsersArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_LICENSES_MODULES_WITH_ID_START',
      successful: 'DATA_GET_LICENSES_MODULES_WITH_ID_SUCCESSFUL',
      fail: 'DATA_GET_LICENSES_MODULES_WITH_ID_FAIL'
    },
    embeddedType: {
      modules: {
        schemaPath: '#/definitions/LicenseModule',
        schemaKey: 'LicenseModule'
      }
    },
    fnName: 'getLicensesModulesWithIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}${options?.basePath || '/users-api'}`,
    method: 'GET',
    url: `/licenses/${requestArguments.path['id']}/modules`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      modules: {
        schemaPath: '#/definitions/LicenseModule',
        schemaKey: 'LicenseModule'
      }
    }, 'getLicensesModulesWithIdRequest', '/users-api'),
    actions: {
      start: {
        type: DATA_GET_LICENSES_MODULES_WITH_ID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_LICENSES_MODULES_WITH_ID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_LICENSES_MODULES_WITH_ID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getLicensesModulesWithIdRequestDefinitions = (definitionsArguments: {| requestArgs: getLicensesModulesWithIdRequestUsersArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getLicensesModulesWithIdRequestUsersArguments, payload?: Object |} => ({
  fnName: 'getLicensesModulesWithIdRequest',
  basePath: '/users-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_GET_LICENSES_ROLES_WITH_ID_START = 'DATA_GET_LICENSES_ROLES_WITH_ID_START'
export const DATA_GET_LICENSES_ROLES_WITH_ID_SUCCESSFUL = 'DATA_GET_LICENSES_ROLES_WITH_ID_SUCCESSFUL'
export const DATA_GET_LICENSES_ROLES_WITH_ID_FAIL = 'DATA_GET_LICENSES_ROLES_WITH_ID_FAIL'

// Gets roles that the license has been granted.
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/users.yaml#/Licenses/get_licenses__id__roles
export const getLicensesRolesWithIdRequestDeprecated = (
  requestArguments: getLicensesRolesWithIdRequestUsersArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_LICENSES_ROLES_WITH_ID_START',
      successful: 'DATA_GET_LICENSES_ROLES_WITH_ID_SUCCESSFUL',
      fail: 'DATA_GET_LICENSES_ROLES_WITH_ID_FAIL'
    },
    embeddedType: {
      roleIds: {
        schemaKey: null
      }
    },
    fnName: 'getLicensesRolesWithIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}${options?.basePath || '/users-api'}`,
    method: 'GET',
    url: `/licenses/${requestArguments.path['id']}/roles`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      roleIds: {
        schemaKey: null
      }
    }, 'getLicensesRolesWithIdRequest', '/users-api'),
    actions: {
      start: {
        type: DATA_GET_LICENSES_ROLES_WITH_ID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_LICENSES_ROLES_WITH_ID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_LICENSES_ROLES_WITH_ID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getLicensesRolesWithIdRequestDefinitions = (definitionsArguments: {| requestArgs: getLicensesRolesWithIdRequestUsersArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getLicensesRolesWithIdRequestUsersArguments, payload?: Object |} => ({
  fnName: 'getLicensesRolesWithIdRequest',
  basePath: '/users-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_POST_LICENSES_ROLES_WITH_ROLEID_START = 'DATA_POST_LICENSES_ROLES_WITH_ROLEID_START'
export const DATA_POST_LICENSES_ROLES_WITH_ROLEID_SUCCESSFUL = 'DATA_POST_LICENSES_ROLES_WITH_ROLEID_SUCCESSFUL'
export const DATA_POST_LICENSES_ROLES_WITH_ROLEID_FAIL = 'DATA_POST_LICENSES_ROLES_WITH_ROLEID_FAIL'

// Grants a role to a license
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/users.yaml#/Licenses/post_licenses__id__roles__roleId_
export const postLicensesRolesWithRoleIdRequestDeprecated = (
  requestArguments: postLicensesRolesWithRoleIdRequestUsersArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_POST_LICENSES_ROLES_WITH_ROLEID_START',
      successful: 'DATA_POST_LICENSES_ROLES_WITH_ROLEID_SUCCESSFUL',
      fail: 'DATA_POST_LICENSES_ROLES_WITH_ROLEID_FAIL'
    },
    embeddedType: null,
    fnName: 'postLicensesRolesWithRoleIdRequest'
  }
  const config = {
    headers: { 'Content-Type': 'application/json' },
    baseURL: `${API_BASE_URL}${options?.basePath || '/users-api'}`,
    method: 'POST',
    url: `/licenses/${requestArguments.path['id']}/roles/${requestArguments.path['roleId']}`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'postLicensesRolesWithRoleIdRequest', '/users-api'),
    actions: {
      start: {
        type: DATA_POST_LICENSES_ROLES_WITH_ROLEID_START, payload, config, meta
      },
      successful: {
        type: DATA_POST_LICENSES_ROLES_WITH_ROLEID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_POST_LICENSES_ROLES_WITH_ROLEID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_DELETE_LICENSES_ROLES_WITH_ROLEID_START = 'DATA_DELETE_LICENSES_ROLES_WITH_ROLEID_START'
export const DATA_DELETE_LICENSES_ROLES_WITH_ROLEID_SUCCESSFUL = 'DATA_DELETE_LICENSES_ROLES_WITH_ROLEID_SUCCESSFUL'
export const DATA_DELETE_LICENSES_ROLES_WITH_ROLEID_FAIL = 'DATA_DELETE_LICENSES_ROLES_WITH_ROLEID_FAIL'

// Revokes a role from a license
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/users.yaml#/Licenses/delete_licenses__id__roles__roleId_
export const deleteLicensesRolesWithRoleIdRequestDeprecated = (
  requestArguments: deleteLicensesRolesWithRoleIdRequestUsersArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_DELETE_LICENSES_ROLES_WITH_ROLEID_START',
      successful: 'DATA_DELETE_LICENSES_ROLES_WITH_ROLEID_SUCCESSFUL',
      fail: 'DATA_DELETE_LICENSES_ROLES_WITH_ROLEID_FAIL'
    },
    embeddedType: null,
    fnName: 'deleteLicensesRolesWithRoleIdRequest'
  }
  const config = {
    headers: { 'Content-Type': 'application/json' },
    baseURL: `${API_BASE_URL}${options?.basePath || '/users-api'}`,
    method: 'DELETE',
    url: `/licenses/${requestArguments.path['id']}/roles/${requestArguments.path['roleId']}`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'deleteLicensesRolesWithRoleIdRequest', '/users-api'),
    actions: {
      start: {
        type: DATA_DELETE_LICENSES_ROLES_WITH_ROLEID_START, payload, config, meta
      },
      successful: {
        type: DATA_DELETE_LICENSES_ROLES_WITH_ROLEID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_DELETE_LICENSES_ROLES_WITH_ROLEID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_GET_USER_GROUPS_START = 'DATA_GET_USER_GROUPS_START'
export const DATA_GET_USER_GROUPS_SUCCESSFUL = 'DATA_GET_USER_GROUPS_SUCCESSFUL'
export const DATA_GET_USER_GROUPS_FAIL = 'DATA_GET_USER_GROUPS_FAIL'

// Returns an embedded list of current User Groups for an Account
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/users.yaml#/User%20Groups/get_user_groups
export const getUserGroupsRequest = (
  payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_USER_GROUPS_START',
      successful: 'DATA_GET_USER_GROUPS_SUCCESSFUL',
      fail: 'DATA_GET_USER_GROUPS_FAIL'
    },
    embeddedType: {
      usergroups: {
        schemaPath: '#/definitions/UserGroup',
        schemaKey: 'UserGroup'
      }
    },
    fnName: 'getUserGroupsRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}${options?.basePath || '/users-api'}`,
    method: 'GET',
    url: '/user-groups',
    params: {
      accountId: options?.selectedAccountId || store.app.selectedAccountId,
      language: options?.languageCode || store.app.languageCode
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      usergroups: {
        schemaPath: '#/definitions/UserGroup',
        schemaKey: 'UserGroup'
      }
    }, 'getUserGroupsRequest', '/users-api'),
    actions: {
      start: {
        type: DATA_GET_USER_GROUPS_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_USER_GROUPS_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_USER_GROUPS_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getUserGroupsRequestDefinitions = (definitionsArguments: {| payload?: Object |}): {|
  ...TVDGARConfigWithoutRequestArgs, payload?: Object |} => ({
  fnName: 'getUserGroupsRequest',
  basePath: '/users-api',
  payload: definitionsArguments.payload,
})


export const DATA_POST_USER_GROUPS_START = 'DATA_POST_USER_GROUPS_START'
export const DATA_POST_USER_GROUPS_SUCCESSFUL = 'DATA_POST_USER_GROUPS_SUCCESSFUL'
export const DATA_POST_USER_GROUPS_FAIL = 'DATA_POST_USER_GROUPS_FAIL'

// Create a new User Group
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/users.yaml#/User%20Groups/post_user_groups
export const postUserGroupsRequest = (
  requestArguments: postUserGroupsRequestUsersArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_POST_USER_GROUPS_START',
      successful: 'DATA_POST_USER_GROUPS_SUCCESSFUL',
      fail: 'DATA_POST_USER_GROUPS_FAIL'
    },
    embeddedType: null,
    fnName: 'postUserGroupsRequest'
  }
  const config = {
    headers: { 'Content-Type': 'application/json' },
    baseURL: `${API_BASE_URL}${options?.basePath || '/users-api'}`,
    method: 'POST',
    url: '/user-groups',
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: requestArguments.body
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'postUserGroupsRequest', '/users-api'),
    actions: {
      start: {
        type: DATA_POST_USER_GROUPS_START, payload, config, meta
      },
      successful: {
        type: DATA_POST_USER_GROUPS_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_POST_USER_GROUPS_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_GET_USER_GROUPS_WITH_ID_START = 'DATA_GET_USER_GROUPS_WITH_ID_START'
export const DATA_GET_USER_GROUPS_WITH_ID_SUCCESSFUL = 'DATA_GET_USER_GROUPS_WITH_ID_SUCCESSFUL'
export const DATA_GET_USER_GROUPS_WITH_ID_FAIL = 'DATA_GET_USER_GROUPS_WITH_ID_FAIL'

// Get a User Group
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/users.yaml#/User%20Groups/get_user_groups__id_
export const getUserGroupsWithIdRequest = (
  requestArguments: getUserGroupsWithIdRequestUsersArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_USER_GROUPS_WITH_ID_START',
      successful: 'DATA_GET_USER_GROUPS_WITH_ID_SUCCESSFUL',
      fail: 'DATA_GET_USER_GROUPS_WITH_ID_FAIL'
    },
    embeddedType: {
      nonEmbedded: {
        schemaPath: '#/definitions/UserGroup',
        schemaKey: 'UserGroup'
      }
    },
    fnName: 'getUserGroupsWithIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}${options?.basePath || '/users-api'}`,
    method: 'GET',
    url: `/user-groups/${requestArguments.path['id']}`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      nonEmbedded: {
        schemaPath: '#/definitions/UserGroup',
        schemaKey: 'UserGroup'
      }
    }, 'getUserGroupsWithIdRequest', '/users-api'),
    actions: {
      start: {
        type: DATA_GET_USER_GROUPS_WITH_ID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_USER_GROUPS_WITH_ID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_USER_GROUPS_WITH_ID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getUserGroupsWithIdRequestDefinitions = (definitionsArguments: {| requestArgs: getUserGroupsWithIdRequestUsersArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getUserGroupsWithIdRequestUsersArguments, payload?: Object |} => ({
  fnName: 'getUserGroupsWithIdRequest',
  basePath: '/users-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_DELETE_USER_GROUPS_WITH_ID_START = 'DATA_DELETE_USER_GROUPS_WITH_ID_START'
export const DATA_DELETE_USER_GROUPS_WITH_ID_SUCCESSFUL = 'DATA_DELETE_USER_GROUPS_WITH_ID_SUCCESSFUL'
export const DATA_DELETE_USER_GROUPS_WITH_ID_FAIL = 'DATA_DELETE_USER_GROUPS_WITH_ID_FAIL'

// Delete a User Group. Requires acquiring a resource lock first.
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/users.yaml#/User%20Groups/delete_user_groups__id_
export const deleteUserGroupsWithIdRequest = (
  requestArguments: deleteUserGroupsWithIdRequestUsersArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_DELETE_USER_GROUPS_WITH_ID_START',
      successful: 'DATA_DELETE_USER_GROUPS_WITH_ID_SUCCESSFUL',
      fail: 'DATA_DELETE_USER_GROUPS_WITH_ID_FAIL'
    },
    embeddedType: null,
    fnName: 'deleteUserGroupsWithIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}${options?.basePath || '/users-api'}`,
    method: 'DELETE',
    url: `/user-groups/${requestArguments.path['id']}`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'deleteUserGroupsWithIdRequest', '/users-api'),
    actions: {
      start: {
        type: DATA_DELETE_USER_GROUPS_WITH_ID_START, payload, config, meta
      },
      successful: {
        type: DATA_DELETE_USER_GROUPS_WITH_ID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_DELETE_USER_GROUPS_WITH_ID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_PATCH_USER_GROUPS_WITH_ID_START = 'DATA_PATCH_USER_GROUPS_WITH_ID_START'
export const DATA_PATCH_USER_GROUPS_WITH_ID_SUCCESSFUL = 'DATA_PATCH_USER_GROUPS_WITH_ID_SUCCESSFUL'
export const DATA_PATCH_USER_GROUPS_WITH_ID_FAIL = 'DATA_PATCH_USER_GROUPS_WITH_ID_FAIL'

// Update User Group properties. Requires acquiring a resource lock first.
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/users.yaml#/User%20Groups/patch_user_groups__id_
export const patchUserGroupsWithIdRequest = (
  requestArguments: patchUserGroupsWithIdRequestUsersArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_PATCH_USER_GROUPS_WITH_ID_START',
      successful: 'DATA_PATCH_USER_GROUPS_WITH_ID_SUCCESSFUL',
      fail: 'DATA_PATCH_USER_GROUPS_WITH_ID_FAIL'
    },
    embeddedType: null,
    fnName: 'patchUserGroupsWithIdRequest'
  }
  const config = {
    headers: { 'Content-Type': 'application/json' },
    baseURL: `${API_BASE_URL}${options?.basePath || '/users-api'}`,
    method: 'PATCH',
    url: `/user-groups/${requestArguments.path['id']}`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: requestArguments.body
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'patchUserGroupsWithIdRequest', '/users-api'),
    actions: {
      start: {
        type: DATA_PATCH_USER_GROUPS_WITH_ID_START, payload, config, meta
      },
      successful: {
        type: DATA_PATCH_USER_GROUPS_WITH_ID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_PATCH_USER_GROUPS_WITH_ID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_GET_USER_GROUPS_USERS_WITH_ID_START = 'DATA_GET_USER_GROUPS_USERS_WITH_ID_START'
export const DATA_GET_USER_GROUPS_USERS_WITH_ID_SUCCESSFUL = 'DATA_GET_USER_GROUPS_USERS_WITH_ID_SUCCESSFUL'
export const DATA_GET_USER_GROUPS_USERS_WITH_ID_FAIL = 'DATA_GET_USER_GROUPS_USERS_WITH_ID_FAIL'

// Gets the Users associated with the User Group
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/users.yaml#/User%20Groups/get_user_groups__id__users
export const getUserGroupsUsersWithIdRequest = (
  requestArguments: getUserGroupsUsersWithIdRequestUsersArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_USER_GROUPS_USERS_WITH_ID_START',
      successful: 'DATA_GET_USER_GROUPS_USERS_WITH_ID_SUCCESSFUL',
      fail: 'DATA_GET_USER_GROUPS_USERS_WITH_ID_FAIL'
    },
    embeddedType: {
      users: {
        schemaPath: '#/definitions/User',
        schemaKey: 'User'
      }
    },
    fnName: 'getUserGroupsUsersWithIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}${options?.basePath || '/users-api'}`,
    method: 'GET',
    url: `/user-groups/${requestArguments.path['id']}/users`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      users: {
        schemaPath: '#/definitions/User',
        schemaKey: 'User'
      }
    }, 'getUserGroupsUsersWithIdRequest', '/users-api'),
    actions: {
      start: {
        type: DATA_GET_USER_GROUPS_USERS_WITH_ID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_USER_GROUPS_USERS_WITH_ID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_USER_GROUPS_USERS_WITH_ID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getUserGroupsUsersWithIdRequestDefinitions = (definitionsArguments: {| requestArgs: getUserGroupsUsersWithIdRequestUsersArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getUserGroupsUsersWithIdRequestUsersArguments, payload?: Object |} => ({
  fnName: 'getUserGroupsUsersWithIdRequest',
  basePath: '/users-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_GET_USER_GROUPS_USERS_COUNT_WITH_ID_START = 'DATA_GET_USER_GROUPS_USERS_COUNT_WITH_ID_START'
export const DATA_GET_USER_GROUPS_USERS_COUNT_WITH_ID_SUCCESSFUL = 'DATA_GET_USER_GROUPS_USERS_COUNT_WITH_ID_SUCCESSFUL'
export const DATA_GET_USER_GROUPS_USERS_COUNT_WITH_ID_FAIL = 'DATA_GET_USER_GROUPS_USERS_COUNT_WITH_ID_FAIL'

// Gets the amount of Users associated with the User Group
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/users.yaml#/User%20Groups/get_user_groups__id__users_count
export const getUserGroupsUsersCountWithIdRequest = (
  requestArguments: getUserGroupsUsersCountWithIdRequestUsersArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_USER_GROUPS_USERS_COUNT_WITH_ID_START',
      successful: 'DATA_GET_USER_GROUPS_USERS_COUNT_WITH_ID_SUCCESSFUL',
      fail: 'DATA_GET_USER_GROUPS_USERS_COUNT_WITH_ID_FAIL'
    },
    embeddedType: {
      nonEmbedded: {
        schemaKey: null
      }
    },
    fnName: 'getUserGroupsUsersCountWithIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}${options?.basePath || '/users-api'}`,
    method: 'GET',
    url: `/user-groups/${requestArguments.path['id']}/users/count`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      nonEmbedded: {
        schemaKey: null
      }
    }, 'getUserGroupsUsersCountWithIdRequest', '/users-api'),
    actions: {
      start: {
        type: DATA_GET_USER_GROUPS_USERS_COUNT_WITH_ID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_USER_GROUPS_USERS_COUNT_WITH_ID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_USER_GROUPS_USERS_COUNT_WITH_ID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getUserGroupsUsersCountWithIdRequestDefinitions = (definitionsArguments: {| requestArgs: getUserGroupsUsersCountWithIdRequestUsersArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getUserGroupsUsersCountWithIdRequestUsersArguments, payload?: Object |} => ({
  fnName: 'getUserGroupsUsersCountWithIdRequest',
  basePath: '/users-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_POST_USER_GROUPS_USERS_WITH_USERID_START = 'DATA_POST_USER_GROUPS_USERS_WITH_USERID_START'
export const DATA_POST_USER_GROUPS_USERS_WITH_USERID_SUCCESSFUL = 'DATA_POST_USER_GROUPS_USERS_WITH_USERID_SUCCESSFUL'
export const DATA_POST_USER_GROUPS_USERS_WITH_USERID_FAIL = 'DATA_POST_USER_GROUPS_USERS_WITH_USERID_FAIL'

// Adds a User to a User Group
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/users.yaml#/User%20Groups/post_user_groups__id__users__userId_
export const postUserGroupsUsersWithUserIdRequest = (
  requestArguments: postUserGroupsUsersWithUserIdRequestUsersArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_POST_USER_GROUPS_USERS_WITH_USERID_START',
      successful: 'DATA_POST_USER_GROUPS_USERS_WITH_USERID_SUCCESSFUL',
      fail: 'DATA_POST_USER_GROUPS_USERS_WITH_USERID_FAIL'
    },
    embeddedType: null,
    fnName: 'postUserGroupsUsersWithUserIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}${options?.basePath || '/users-api'}`,
    method: 'POST',
    url: `/user-groups/${requestArguments.path['id']}/users/${requestArguments.path['userId']}`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'postUserGroupsUsersWithUserIdRequest', '/users-api'),
    actions: {
      start: {
        type: DATA_POST_USER_GROUPS_USERS_WITH_USERID_START, payload, config, meta
      },
      successful: {
        type: DATA_POST_USER_GROUPS_USERS_WITH_USERID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_POST_USER_GROUPS_USERS_WITH_USERID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_DELETE_USER_GROUPS_USERS_WITH_USERID_START = 'DATA_DELETE_USER_GROUPS_USERS_WITH_USERID_START'
export const DATA_DELETE_USER_GROUPS_USERS_WITH_USERID_SUCCESSFUL = 'DATA_DELETE_USER_GROUPS_USERS_WITH_USERID_SUCCESSFUL'
export const DATA_DELETE_USER_GROUPS_USERS_WITH_USERID_FAIL = 'DATA_DELETE_USER_GROUPS_USERS_WITH_USERID_FAIL'

// Removes a User from a User Group
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/users.yaml#/User%20Groups/delete_user_groups__id__users__userId_
export const deleteUserGroupsUsersWithUserIdRequest = (
  requestArguments: deleteUserGroupsUsersWithUserIdRequestUsersArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_DELETE_USER_GROUPS_USERS_WITH_USERID_START',
      successful: 'DATA_DELETE_USER_GROUPS_USERS_WITH_USERID_SUCCESSFUL',
      fail: 'DATA_DELETE_USER_GROUPS_USERS_WITH_USERID_FAIL'
    },
    embeddedType: null,
    fnName: 'deleteUserGroupsUsersWithUserIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}${options?.basePath || '/users-api'}`,
    method: 'DELETE',
    url: `/user-groups/${requestArguments.path['id']}/users/${requestArguments.path['userId']}`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'deleteUserGroupsUsersWithUserIdRequest', '/users-api'),
    actions: {
      start: {
        type: DATA_DELETE_USER_GROUPS_USERS_WITH_USERID_START, payload, config, meta
      },
      successful: {
        type: DATA_DELETE_USER_GROUPS_USERS_WITH_USERID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_DELETE_USER_GROUPS_USERS_WITH_USERID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_GET_USER_GROUPS_LOCK_WITH_ID_START = 'DATA_GET_USER_GROUPS_LOCK_WITH_ID_START'
export const DATA_GET_USER_GROUPS_LOCK_WITH_ID_SUCCESSFUL = 'DATA_GET_USER_GROUPS_LOCK_WITH_ID_SUCCESSFUL'
export const DATA_GET_USER_GROUPS_LOCK_WITH_ID_FAIL = 'DATA_GET_USER_GROUPS_LOCK_WITH_ID_FAIL'

// If resource is locked, returns information (owner, expiration) about the current lock
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/users.yaml#/User%20Groups/get_user_groups__id__lock
export const getUserGroupsLockWithIdRequest = (
  requestArguments: getUserGroupsLockWithIdRequestUsersArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_USER_GROUPS_LOCK_WITH_ID_START',
      successful: 'DATA_GET_USER_GROUPS_LOCK_WITH_ID_SUCCESSFUL',
      fail: 'DATA_GET_USER_GROUPS_LOCK_WITH_ID_FAIL'
    },
    embeddedType: {
      nonEmbedded: {
        schemaPath: '#/definitions/ResourceLock',
        schemaKey: 'ResourceLock'
      }
    },
    fnName: 'getUserGroupsLockWithIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}${options?.basePath || '/users-api'}`,
    method: 'GET',
    url: `/user-groups/${requestArguments.path['id']}/lock`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      nonEmbedded: {
        schemaPath: '#/definitions/ResourceLock',
        schemaKey: 'ResourceLock'
      }
    }, 'getUserGroupsLockWithIdRequest', '/users-api'),
    actions: {
      start: {
        type: DATA_GET_USER_GROUPS_LOCK_WITH_ID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_USER_GROUPS_LOCK_WITH_ID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_USER_GROUPS_LOCK_WITH_ID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getUserGroupsLockWithIdRequestDefinitions = (definitionsArguments: {| requestArgs: getUserGroupsLockWithIdRequestUsersArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getUserGroupsLockWithIdRequestUsersArguments, payload?: Object |} => ({
  fnName: 'getUserGroupsLockWithIdRequest',
  basePath: '/users-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_PUT_USER_GROUPS_LOCK_WITH_ID_START = 'DATA_PUT_USER_GROUPS_LOCK_WITH_ID_START'
export const DATA_PUT_USER_GROUPS_LOCK_WITH_ID_SUCCESSFUL = 'DATA_PUT_USER_GROUPS_LOCK_WITH_ID_SUCCESSFUL'
export const DATA_PUT_USER_GROUPS_LOCK_WITH_ID_FAIL = 'DATA_PUT_USER_GROUPS_LOCK_WITH_ID_FAIL'

// Obtain a lock to a resource for editing. Edits are not permitted before first obtaining a lock.
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/users.yaml#/User%20Groups/put_user_groups__id__lock
export const putUserGroupsLockWithIdRequest = (
  requestArguments: putUserGroupsLockWithIdRequestUsersArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_PUT_USER_GROUPS_LOCK_WITH_ID_START',
      successful: 'DATA_PUT_USER_GROUPS_LOCK_WITH_ID_SUCCESSFUL',
      fail: 'DATA_PUT_USER_GROUPS_LOCK_WITH_ID_FAIL'
    },
    embeddedType: null,
    fnName: 'putUserGroupsLockWithIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}${options?.basePath || '/users-api'}`,
    method: 'PUT',
    url: `/user-groups/${requestArguments.path['id']}/lock`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: requestArguments.body
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'putUserGroupsLockWithIdRequest', '/users-api'),
    actions: {
      start: {
        type: DATA_PUT_USER_GROUPS_LOCK_WITH_ID_START, payload, config, meta
      },
      successful: {
        type: DATA_PUT_USER_GROUPS_LOCK_WITH_ID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_PUT_USER_GROUPS_LOCK_WITH_ID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_DELETE_USER_GROUPS_LOCK_WITH_ID_START = 'DATA_DELETE_USER_GROUPS_LOCK_WITH_ID_START'
export const DATA_DELETE_USER_GROUPS_LOCK_WITH_ID_SUCCESSFUL = 'DATA_DELETE_USER_GROUPS_LOCK_WITH_ID_SUCCESSFUL'
export const DATA_DELETE_USER_GROUPS_LOCK_WITH_ID_FAIL = 'DATA_DELETE_USER_GROUPS_LOCK_WITH_ID_FAIL'

// Deletes a resource lock. If the lock was created by another user, error 423 is returned.
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/users.yaml#/User%20Groups/delete_user_groups__id__lock
export const deleteUserGroupsLockWithIdRequest = (
  requestArguments: deleteUserGroupsLockWithIdRequestUsersArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_DELETE_USER_GROUPS_LOCK_WITH_ID_START',
      successful: 'DATA_DELETE_USER_GROUPS_LOCK_WITH_ID_SUCCESSFUL',
      fail: 'DATA_DELETE_USER_GROUPS_LOCK_WITH_ID_FAIL'
    },
    embeddedType: null,
    fnName: 'deleteUserGroupsLockWithIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}${options?.basePath || '/users-api'}`,
    method: 'DELETE',
    url: `/user-groups/${requestArguments.path['id']}/lock`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'deleteUserGroupsLockWithIdRequest', '/users-api'),
    actions: {
      start: {
        type: DATA_DELETE_USER_GROUPS_LOCK_WITH_ID_START, payload, config, meta
      },
      successful: {
        type: DATA_DELETE_USER_GROUPS_LOCK_WITH_ID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_DELETE_USER_GROUPS_LOCK_WITH_ID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_GET_USER_GROUPS_ROLES_WITH_ID_START = 'DATA_GET_USER_GROUPS_ROLES_WITH_ID_START'
export const DATA_GET_USER_GROUPS_ROLES_WITH_ID_SUCCESSFUL = 'DATA_GET_USER_GROUPS_ROLES_WITH_ID_SUCCESSFUL'
export const DATA_GET_USER_GROUPS_ROLES_WITH_ID_FAIL = 'DATA_GET_USER_GROUPS_ROLES_WITH_ID_FAIL'

// Gets roles that the user group has been granted. Includes roles inherited from license.
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/users.yaml#/User%20Groups/get_user_groups__id__roles
export const getUserGroupsRolesWithIdRequestDeprecated = (
  requestArguments: getUserGroupsRolesWithIdRequestUsersArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_USER_GROUPS_ROLES_WITH_ID_START',
      successful: 'DATA_GET_USER_GROUPS_ROLES_WITH_ID_SUCCESSFUL',
      fail: 'DATA_GET_USER_GROUPS_ROLES_WITH_ID_FAIL'
    },
    embeddedType: {
      roleIds: {
        schemaKey: null
      }
    },
    fnName: 'getUserGroupsRolesWithIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}${options?.basePath || '/users-api'}`,
    method: 'GET',
    url: `/user-groups/${requestArguments.path['id']}/roles`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      roleIds: {
        schemaKey: null
      }
    }, 'getUserGroupsRolesWithIdRequest', '/users-api'),
    actions: {
      start: {
        type: DATA_GET_USER_GROUPS_ROLES_WITH_ID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_USER_GROUPS_ROLES_WITH_ID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_USER_GROUPS_ROLES_WITH_ID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getUserGroupsRolesWithIdRequestDefinitions = (definitionsArguments: {| requestArgs: getUserGroupsRolesWithIdRequestUsersArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getUserGroupsRolesWithIdRequestUsersArguments, payload?: Object |} => ({
  fnName: 'getUserGroupsRolesWithIdRequest',
  basePath: '/users-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_GET_USER_GROUPS_ROLES_USER_GROUPS_WITH_ID_START = 'DATA_GET_USER_GROUPS_ROLES_USER_GROUPS_WITH_ID_START'
export const DATA_GET_USER_GROUPS_ROLES_USER_GROUPS_WITH_ID_SUCCESSFUL = 'DATA_GET_USER_GROUPS_ROLES_USER_GROUPS_WITH_ID_SUCCESSFUL'
export const DATA_GET_USER_GROUPS_ROLES_USER_GROUPS_WITH_ID_FAIL = 'DATA_GET_USER_GROUPS_ROLES_USER_GROUPS_WITH_ID_FAIL'

// Gets roles that has the user group has been granted directly
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/users.yaml#/Users/get_user_groups__id__roles_user_groups
export const getUserGroupsRolesUserGroupsWithIdRequestDeprecated = (
  requestArguments: getUserGroupsRolesUserGroupsWithIdRequestUsersArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_USER_GROUPS_ROLES_USER_GROUPS_WITH_ID_START',
      successful: 'DATA_GET_USER_GROUPS_ROLES_USER_GROUPS_WITH_ID_SUCCESSFUL',
      fail: 'DATA_GET_USER_GROUPS_ROLES_USER_GROUPS_WITH_ID_FAIL'
    },
    embeddedType: null,
    fnName: 'getUserGroupsRolesUserGroupsWithIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}${options?.basePath || '/users-api'}`,
    method: 'GET',
    url: `/user-groups/${requestArguments.path['id']}/roles/user-groups`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'getUserGroupsRolesUserGroupsWithIdRequest', '/users-api'),
    actions: {
      start: {
        type: DATA_GET_USER_GROUPS_ROLES_USER_GROUPS_WITH_ID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_USER_GROUPS_ROLES_USER_GROUPS_WITH_ID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_USER_GROUPS_ROLES_USER_GROUPS_WITH_ID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getUserGroupsRolesUserGroupsWithIdRequestDefinitions = (definitionsArguments: {| requestArgs: getUserGroupsRolesUserGroupsWithIdRequestUsersArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getUserGroupsRolesUserGroupsWithIdRequestUsersArguments, payload?: Object |} => ({
  fnName: 'getUserGroupsRolesUserGroupsWithIdRequest',
  basePath: '/users-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_GET_USER_GROUPS_ROLES_LICENSE_WITH_ID_START = 'DATA_GET_USER_GROUPS_ROLES_LICENSE_WITH_ID_START'
export const DATA_GET_USER_GROUPS_ROLES_LICENSE_WITH_ID_SUCCESSFUL = 'DATA_GET_USER_GROUPS_ROLES_LICENSE_WITH_ID_SUCCESSFUL'
export const DATA_GET_USER_GROUPS_ROLES_LICENSE_WITH_ID_FAIL = 'DATA_GET_USER_GROUPS_ROLES_LICENSE_WITH_ID_FAIL'

// Gets roles that has the user group has via license.
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/users.yaml#/User%20Groups/get_user_groups__id__roles_license
export const getUserGroupsRolesLicenseWithIdRequestDeprecated = (
  requestArguments: getUserGroupsRolesLicenseWithIdRequestUsersArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_USER_GROUPS_ROLES_LICENSE_WITH_ID_START',
      successful: 'DATA_GET_USER_GROUPS_ROLES_LICENSE_WITH_ID_SUCCESSFUL',
      fail: 'DATA_GET_USER_GROUPS_ROLES_LICENSE_WITH_ID_FAIL'
    },
    embeddedType: null,
    fnName: 'getUserGroupsRolesLicenseWithIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}${options?.basePath || '/users-api'}`,
    method: 'GET',
    url: `/user-groups/${requestArguments.path['id']}/roles/license`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'getUserGroupsRolesLicenseWithIdRequest', '/users-api'),
    actions: {
      start: {
        type: DATA_GET_USER_GROUPS_ROLES_LICENSE_WITH_ID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_USER_GROUPS_ROLES_LICENSE_WITH_ID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_USER_GROUPS_ROLES_LICENSE_WITH_ID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getUserGroupsRolesLicenseWithIdRequestDefinitions = (definitionsArguments: {| requestArgs: getUserGroupsRolesLicenseWithIdRequestUsersArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getUserGroupsRolesLicenseWithIdRequestUsersArguments, payload?: Object |} => ({
  fnName: 'getUserGroupsRolesLicenseWithIdRequest',
  basePath: '/users-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_POST_USER_GROUPS_ROLES_WITH_ROLEID_START = 'DATA_POST_USER_GROUPS_ROLES_WITH_ROLEID_START'
export const DATA_POST_USER_GROUPS_ROLES_WITH_ROLEID_SUCCESSFUL = 'DATA_POST_USER_GROUPS_ROLES_WITH_ROLEID_SUCCESSFUL'
export const DATA_POST_USER_GROUPS_ROLES_WITH_ROLEID_FAIL = 'DATA_POST_USER_GROUPS_ROLES_WITH_ROLEID_FAIL'

// Grants a role to a user group
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/users.yaml#/User%20Groups/post_user_groups__id__roles__roleId_
export const postUserGroupsRolesWithRoleIdRequestDeprecated = (
  requestArguments: postUserGroupsRolesWithRoleIdRequestUsersArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_POST_USER_GROUPS_ROLES_WITH_ROLEID_START',
      successful: 'DATA_POST_USER_GROUPS_ROLES_WITH_ROLEID_SUCCESSFUL',
      fail: 'DATA_POST_USER_GROUPS_ROLES_WITH_ROLEID_FAIL'
    },
    embeddedType: null,
    fnName: 'postUserGroupsRolesWithRoleIdRequest'
  }
  const config = {
    headers: { 'Content-Type': 'application/json' },
    baseURL: `${API_BASE_URL}${options?.basePath || '/users-api'}`,
    method: 'POST',
    url: `/user-groups/${requestArguments.path['id']}/roles/${requestArguments.path['roleId']}`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'postUserGroupsRolesWithRoleIdRequest', '/users-api'),
    actions: {
      start: {
        type: DATA_POST_USER_GROUPS_ROLES_WITH_ROLEID_START, payload, config, meta
      },
      successful: {
        type: DATA_POST_USER_GROUPS_ROLES_WITH_ROLEID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_POST_USER_GROUPS_ROLES_WITH_ROLEID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_DELETE_USER_GROUPS_ROLES_WITH_ROLEID_START = 'DATA_DELETE_USER_GROUPS_ROLES_WITH_ROLEID_START'
export const DATA_DELETE_USER_GROUPS_ROLES_WITH_ROLEID_SUCCESSFUL = 'DATA_DELETE_USER_GROUPS_ROLES_WITH_ROLEID_SUCCESSFUL'
export const DATA_DELETE_USER_GROUPS_ROLES_WITH_ROLEID_FAIL = 'DATA_DELETE_USER_GROUPS_ROLES_WITH_ROLEID_FAIL'

// Revokes a role from a user group
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/users.yaml#/User%20Groups/delete_user_groups__id__roles__roleId_
export const deleteUserGroupsRolesWithRoleIdRequestDeprecated = (
  requestArguments: deleteUserGroupsRolesWithRoleIdRequestUsersArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_DELETE_USER_GROUPS_ROLES_WITH_ROLEID_START',
      successful: 'DATA_DELETE_USER_GROUPS_ROLES_WITH_ROLEID_SUCCESSFUL',
      fail: 'DATA_DELETE_USER_GROUPS_ROLES_WITH_ROLEID_FAIL'
    },
    embeddedType: null,
    fnName: 'deleteUserGroupsRolesWithRoleIdRequest'
  }
  const config = {
    headers: { 'Content-Type': 'application/json' },
    baseURL: `${API_BASE_URL}${options?.basePath || '/users-api'}`,
    method: 'DELETE',
    url: `/user-groups/${requestArguments.path['id']}/roles/${requestArguments.path['roleId']}`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'deleteUserGroupsRolesWithRoleIdRequest', '/users-api'),
    actions: {
      start: {
        type: DATA_DELETE_USER_GROUPS_ROLES_WITH_ROLEID_START, payload, config, meta
      },
      successful: {
        type: DATA_DELETE_USER_GROUPS_ROLES_WITH_ROLEID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_DELETE_USER_GROUPS_ROLES_WITH_ROLEID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_POST_ROLES_START = 'DATA_POST_ROLES_START'
export const DATA_POST_ROLES_SUCCESSFUL = 'DATA_POST_ROLES_SUCCESSFUL'
export const DATA_POST_ROLES_FAIL = 'DATA_POST_ROLES_FAIL'

// Create a new Role
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/users.yaml#/Roles/post_roles
export const postRolesRequestDeprecated = (
  requestArguments: postRolesRequestUsersArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_POST_ROLES_START',
      successful: 'DATA_POST_ROLES_SUCCESSFUL',
      fail: 'DATA_POST_ROLES_FAIL'
    },
    embeddedType: null,
    fnName: 'postRolesRequest'
  }
  const config = {
    headers: { 'Content-Type': 'application/json' },
    baseURL: `${API_BASE_URL}${options?.basePath || '/users-api'}`,
    method: 'POST',
    url: '/roles',
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: requestArguments.body
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'postRolesRequest', '/users-api'),
    actions: {
      start: {
        type: DATA_POST_ROLES_START, payload, config, meta
      },
      successful: {
        type: DATA_POST_ROLES_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_POST_ROLES_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_GET_ROLES_WITH_ID_START = 'DATA_GET_ROLES_WITH_ID_START'
export const DATA_GET_ROLES_WITH_ID_SUCCESSFUL = 'DATA_GET_ROLES_WITH_ID_SUCCESSFUL'
export const DATA_GET_ROLES_WITH_ID_FAIL = 'DATA_GET_ROLES_WITH_ID_FAIL'

// Get a Role
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/users.yaml#/Roles/get_roles__id_
export const getRolesWithIdRequestDeprecated = (
  requestArguments: getRolesWithIdRequestUsersArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_ROLES_WITH_ID_START',
      successful: 'DATA_GET_ROLES_WITH_ID_SUCCESSFUL',
      fail: 'DATA_GET_ROLES_WITH_ID_FAIL'
    },
    embeddedType: {
      nonEmbedded: {
        schemaPath: '#/definitions/Role',
        schemaKey: 'Role'
      }
    },
    fnName: 'getRolesWithIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}${options?.basePath || '/users-api'}`,
    method: 'GET',
    url: `/roles/${requestArguments.path['id']}`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      nonEmbedded: {
        schemaPath: '#/definitions/Role',
        schemaKey: 'Role'
      }
    }, 'getRolesWithIdRequest', '/users-api'),
    actions: {
      start: {
        type: DATA_GET_ROLES_WITH_ID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_ROLES_WITH_ID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_ROLES_WITH_ID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getRolesWithIdRequestDefinitions = (definitionsArguments: {| requestArgs: getRolesWithIdRequestUsersArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getRolesWithIdRequestUsersArguments, payload?: Object |} => ({
  fnName: 'getRolesWithIdRequest',
  basePath: '/users-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_DELETE_ROLES_WITH_ID_START = 'DATA_DELETE_ROLES_WITH_ID_START'
export const DATA_DELETE_ROLES_WITH_ID_SUCCESSFUL = 'DATA_DELETE_ROLES_WITH_ID_SUCCESSFUL'
export const DATA_DELETE_ROLES_WITH_ID_FAIL = 'DATA_DELETE_ROLES_WITH_ID_FAIL'

// Delete a Role.
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/users.yaml#/Roles/delete_roles__id_
export const deleteRolesWithIdRequestDeprecated = (
  requestArguments: deleteRolesWithIdRequestUsersArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_DELETE_ROLES_WITH_ID_START',
      successful: 'DATA_DELETE_ROLES_WITH_ID_SUCCESSFUL',
      fail: 'DATA_DELETE_ROLES_WITH_ID_FAIL'
    },
    embeddedType: null,
    fnName: 'deleteRolesWithIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}${options?.basePath || '/users-api'}`,
    method: 'DELETE',
    url: `/roles/${requestArguments.path['id']}`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'deleteRolesWithIdRequest', '/users-api'),
    actions: {
      start: {
        type: DATA_DELETE_ROLES_WITH_ID_START, payload, config, meta
      },
      successful: {
        type: DATA_DELETE_ROLES_WITH_ID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_DELETE_ROLES_WITH_ID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_PATCH_ROLES_WITH_ID_START = 'DATA_PATCH_ROLES_WITH_ID_START'
export const DATA_PATCH_ROLES_WITH_ID_SUCCESSFUL = 'DATA_PATCH_ROLES_WITH_ID_SUCCESSFUL'
export const DATA_PATCH_ROLES_WITH_ID_FAIL = 'DATA_PATCH_ROLES_WITH_ID_FAIL'

// Update role properties.
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/users.yaml#/Roles/patch_roles__id_
export const patchRolesWithIdRequestDeprecated = (
  requestArguments: patchRolesWithIdRequestUsersArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_PATCH_ROLES_WITH_ID_START',
      successful: 'DATA_PATCH_ROLES_WITH_ID_SUCCESSFUL',
      fail: 'DATA_PATCH_ROLES_WITH_ID_FAIL'
    },
    embeddedType: null,
    fnName: 'patchRolesWithIdRequest'
  }
  const config = {
    headers: { 'Content-Type': 'application/json' },
    baseURL: `${API_BASE_URL}${options?.basePath || '/users-api'}`,
    method: 'PATCH',
    url: `/roles/${requestArguments.path['id']}`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: requestArguments.body
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'patchRolesWithIdRequest', '/users-api'),
    actions: {
      start: {
        type: DATA_PATCH_ROLES_WITH_ID_START, payload, config, meta
      },
      successful: {
        type: DATA_PATCH_ROLES_WITH_ID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_PATCH_ROLES_WITH_ID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_GET_ROLES_POLICIES_WITH_ID_START = 'DATA_GET_ROLES_POLICIES_WITH_ID_START'
export const DATA_GET_ROLES_POLICIES_WITH_ID_SUCCESSFUL = 'DATA_GET_ROLES_POLICIES_WITH_ID_SUCCESSFUL'
export const DATA_GET_ROLES_POLICIES_WITH_ID_FAIL = 'DATA_GET_ROLES_POLICIES_WITH_ID_FAIL'

// Returns the access control policies currently granted to the role
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/users.yaml#/Roles/get_roles__id__policies
export const getRolesPoliciesWithIdRequestDeprecated = (
  requestArguments: getRolesPoliciesWithIdRequestUsersArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_ROLES_POLICIES_WITH_ID_START',
      successful: 'DATA_GET_ROLES_POLICIES_WITH_ID_SUCCESSFUL',
      fail: 'DATA_GET_ROLES_POLICIES_WITH_ID_FAIL'
    },
    embeddedType: {
      nonEmbedded: {
        schemaKey: null
      }
    },
    fnName: 'getRolesPoliciesWithIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}${options?.basePath || '/users-api'}`,
    method: 'GET',
    url: `/roles/${requestArguments.path['id']}/policies`,
    params: {
      resourceId: requestArguments.query?.['resourceId'],
      resourceType: requestArguments.query?.['resourceType'],
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      nonEmbedded: {
        schemaKey: null
      }
    }, 'getRolesPoliciesWithIdRequest', '/users-api'),
    actions: {
      start: {
        type: DATA_GET_ROLES_POLICIES_WITH_ID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_ROLES_POLICIES_WITH_ID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_ROLES_POLICIES_WITH_ID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getRolesPoliciesWithIdRequestDefinitions = (definitionsArguments: {| requestArgs: getRolesPoliciesWithIdRequestUsersArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getRolesPoliciesWithIdRequestUsersArguments, payload?: Object |} => ({
  fnName: 'getRolesPoliciesWithIdRequest',
  basePath: '/users-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_POST_ROLES_POLICIES_WITH_ID_START = 'DATA_POST_ROLES_POLICIES_WITH_ID_START'
export const DATA_POST_ROLES_POLICIES_WITH_ID_SUCCESSFUL = 'DATA_POST_ROLES_POLICIES_WITH_ID_SUCCESSFUL'
export const DATA_POST_ROLES_POLICIES_WITH_ID_FAIL = 'DATA_POST_ROLES_POLICIES_WITH_ID_FAIL'

// Grant new policies to role
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/users.yaml#/Roles/post_roles__id__policies
export const postRolesPoliciesWithIdRequestDeprecated = (
  requestArguments: postRolesPoliciesWithIdRequestUsersArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_POST_ROLES_POLICIES_WITH_ID_START',
      successful: 'DATA_POST_ROLES_POLICIES_WITH_ID_SUCCESSFUL',
      fail: 'DATA_POST_ROLES_POLICIES_WITH_ID_FAIL'
    },
    embeddedType: null,
    fnName: 'postRolesPoliciesWithIdRequest'
  }
  const config = {
    headers: { 'Content-Type': 'application/json' },
    baseURL: `${API_BASE_URL}${options?.basePath || '/users-api'}`,
    method: 'POST',
    url: `/roles/${requestArguments.path['id']}/policies`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: requestArguments.body
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'postRolesPoliciesWithIdRequest', '/users-api'),
    actions: {
      start: {
        type: DATA_POST_ROLES_POLICIES_WITH_ID_START, payload, config, meta
      },
      successful: {
        type: DATA_POST_ROLES_POLICIES_WITH_ID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_POST_ROLES_POLICIES_WITH_ID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_DELETE_ROLES_POLICIES_WITH_ID_START = 'DATA_DELETE_ROLES_POLICIES_WITH_ID_START'
export const DATA_DELETE_ROLES_POLICIES_WITH_ID_SUCCESSFUL = 'DATA_DELETE_ROLES_POLICIES_WITH_ID_SUCCESSFUL'
export const DATA_DELETE_ROLES_POLICIES_WITH_ID_FAIL = 'DATA_DELETE_ROLES_POLICIES_WITH_ID_FAIL'

// Revoke policies from the role
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/users.yaml#/Roles/delete_roles__id__policies
export const deleteRolesPoliciesWithIdRequestDeprecated = (
  requestArguments: deleteRolesPoliciesWithIdRequestUsersArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_DELETE_ROLES_POLICIES_WITH_ID_START',
      successful: 'DATA_DELETE_ROLES_POLICIES_WITH_ID_SUCCESSFUL',
      fail: 'DATA_DELETE_ROLES_POLICIES_WITH_ID_FAIL'
    },
    embeddedType: null,
    fnName: 'deleteRolesPoliciesWithIdRequest'
  }
  const config = {
    headers: { 'Content-Type': 'application/json' },
    baseURL: `${API_BASE_URL}${options?.basePath || '/users-api'}`,
    method: 'DELETE',
    url: `/roles/${requestArguments.path['id']}/policies`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: requestArguments.body
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'deleteRolesPoliciesWithIdRequest', '/users-api'),
    actions: {
      start: {
        type: DATA_DELETE_ROLES_POLICIES_WITH_ID_START, payload, config, meta
      },
      successful: {
        type: DATA_DELETE_ROLES_POLICIES_WITH_ID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_DELETE_ROLES_POLICIES_WITH_ID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_GET_LEGAL_TERMS_OF_SERVICE_START = 'DATA_GET_LEGAL_TERMS_OF_SERVICE_START'
export const DATA_GET_LEGAL_TERMS_OF_SERVICE_SUCCESSFUL = 'DATA_GET_LEGAL_TERMS_OF_SERVICE_SUCCESSFUL'
export const DATA_GET_LEGAL_TERMS_OF_SERVICE_FAIL = 'DATA_GET_LEGAL_TERMS_OF_SERVICE_FAIL'

// Returns the current Terms of Service.
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/users.yaml#/Legal/get_legal_terms_of_service
export const getLegalTermsOfServiceRequest = (
  payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_LEGAL_TERMS_OF_SERVICE_START',
      successful: 'DATA_GET_LEGAL_TERMS_OF_SERVICE_SUCCESSFUL',
      fail: 'DATA_GET_LEGAL_TERMS_OF_SERVICE_FAIL'
    },
    embeddedType: {
      nonEmbedded: {
        schemaPath: '#/definitions/TermsOfService',
        schemaKey: 'TermsOfService'
      }
    },
    fnName: 'getLegalTermsOfServiceRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}${options?.basePath || '/users-api'}`,
    method: 'GET',
    url: '/legal/terms-of-service',
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      nonEmbedded: {
        schemaPath: '#/definitions/TermsOfService',
        schemaKey: 'TermsOfService'
      }
    }, 'getLegalTermsOfServiceRequest', '/users-api'),
    actions: {
      start: {
        type: DATA_GET_LEGAL_TERMS_OF_SERVICE_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_LEGAL_TERMS_OF_SERVICE_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_LEGAL_TERMS_OF_SERVICE_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getLegalTermsOfServiceRequestDefinitions = (definitionsArguments: {| payload?: Object |}): {|
  ...TVDGARConfigWithoutRequestArgs, payload?: Object |} => ({
  fnName: 'getLegalTermsOfServiceRequest',
  basePath: '/users-api',
  payload: definitionsArguments.payload,
})


export const DATA_GET_SERVICE_MESSAGES_START = 'DATA_GET_SERVICE_MESSAGES_START'
export const DATA_GET_SERVICE_MESSAGES_SUCCESSFUL = 'DATA_GET_SERVICE_MESSAGES_SUCCESSFUL'
export const DATA_GET_SERVICE_MESSAGES_FAIL = 'DATA_GET_SERVICE_MESSAGES_FAIL'

// Returns current messages that users should be showed concerning the service, for example upcoming maintenance breaks.
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/users.yaml#/Service%20Messages/get_service_messages
export const getServiceMessagesRequest = (
  payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_SERVICE_MESSAGES_START',
      successful: 'DATA_GET_SERVICE_MESSAGES_SUCCESSFUL',
      fail: 'DATA_GET_SERVICE_MESSAGES_FAIL'
    },
    embeddedType: {
      nonEmbedded: {
        schemaPath: '#/definitions/ServiceMessageCollection',
        schemaKey: 'ServiceMessageCollection'
      }
    },
    fnName: 'getServiceMessagesRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}${options?.basePath || '/users-api'}`,
    method: 'GET',
    url: '/service-messages',
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      nonEmbedded: {
        schemaPath: '#/definitions/ServiceMessageCollection',
        schemaKey: 'ServiceMessageCollection'
      }
    }, 'getServiceMessagesRequest', '/users-api'),
    actions: {
      start: {
        type: DATA_GET_SERVICE_MESSAGES_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_SERVICE_MESSAGES_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_SERVICE_MESSAGES_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getServiceMessagesRequestDefinitions = (definitionsArguments: {| payload?: Object |}): {|
  ...TVDGARConfigWithoutRequestArgs, payload?: Object |} => ({
  fnName: 'getServiceMessagesRequest',
  basePath: '/users-api',
  payload: definitionsArguments.payload,
})


export const DATA_GET_ENUM_WITH_ENUM_START = 'DATA_GET_ENUM_WITH_ENUM_START'
export const DATA_GET_ENUM_WITH_ENUM_SUCCESSFUL = 'DATA_GET_ENUM_WITH_ENUM_SUCCESSFUL'
export const DATA_GET_ENUM_WITH_ENUM_FAIL = 'DATA_GET_ENUM_WITH_ENUM_FAIL'

// Returns members of given enum as embedded Enumerals
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/users.yaml#/Enum/get_enum__enum_
export const getEnumWithEnumRequest = (
  requestArguments: getEnumWithEnumRequestUsersArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_ENUM_WITH_ENUM_START',
      successful: 'DATA_GET_ENUM_WITH_ENUM_SUCCESSFUL',
      fail: 'DATA_GET_ENUM_WITH_ENUM_FAIL'
    },
    embeddedType: {
      enumeral: {
        schemaPath: '#/definitions/Enumeral',
        schemaKey: 'Enumeral'
      }
    },
    fnName: 'getEnumWithEnumRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}${options?.basePath || '/users-api'}`,
    method: 'GET',
    url: `/enum/${requestArguments.path['enumParam']}`,
    params: {
      category: requestArguments.query?.['category'],
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      enumeral: {
        schemaPath: '#/definitions/Enumeral',
        schemaKey: 'Enumeral'
      }
    }, 'getEnumWithEnumRequest', '/users-api'),
    actions: {
      start: {
        type: DATA_GET_ENUM_WITH_ENUM_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_ENUM_WITH_ENUM_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_ENUM_WITH_ENUM_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getEnumWithEnumRequestDefinitions = (definitionsArguments: {| requestArgs: getEnumWithEnumRequestUsersArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getEnumWithEnumRequestUsersArguments, payload?: Object |} => ({
  fnName: 'getEnumWithEnumRequest',
  basePath: '/users-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_GET_SWAGGER_START = 'DATA_GET_SWAGGER_START'
export const DATA_GET_SWAGGER_SUCCESSFUL = 'DATA_GET_SWAGGER_SUCCESSFUL'
export const DATA_GET_SWAGGER_FAIL = 'DATA_GET_SWAGGER_FAIL'

// Returns Swagger specification of the API
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/users.yaml#/Swagger/get_swagger
export const getSwaggerRequest = (
  payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_SWAGGER_START',
      successful: 'DATA_GET_SWAGGER_SUCCESSFUL',
      fail: 'DATA_GET_SWAGGER_FAIL'
    },
    embeddedType: null,
    fnName: 'getSwaggerRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}${options?.basePath || '/users-api'}`,
    method: 'GET',
    url: '/swagger',
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'getSwaggerRequest', '/users-api'),
    actions: {
      start: {
        type: DATA_GET_SWAGGER_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_SWAGGER_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_SWAGGER_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getSwaggerRequestDefinitions = (definitionsArguments: {| payload?: Object |}): {|
  ...TVDGARConfigWithoutRequestArgs, payload?: Object |} => ({
  fnName: 'getSwaggerRequest',
  basePath: '/users-api',
  payload: definitionsArguments.payload,
})


export const DATA_GET_SWAGGER_WITH_NODE_START = 'DATA_GET_SWAGGER_WITH_NODE_START'
export const DATA_GET_SWAGGER_WITH_NODE_SUCCESSFUL = 'DATA_GET_SWAGGER_WITH_NODE_SUCCESSFUL'
export const DATA_GET_SWAGGER_WITH_NODE_FAIL = 'DATA_GET_SWAGGER_WITH_NODE_FAIL'

// Returns a first level node from the Swagger specification of the API
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/users.yaml#/Swagger/get_swagger__node_
export const getSwaggerWithNodeRequest = (
  requestArguments: getSwaggerWithNodeRequestUsersArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_SWAGGER_WITH_NODE_START',
      successful: 'DATA_GET_SWAGGER_WITH_NODE_SUCCESSFUL',
      fail: 'DATA_GET_SWAGGER_WITH_NODE_FAIL'
    },
    embeddedType: null,
    fnName: 'getSwaggerWithNodeRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}${options?.basePath || '/users-api'}`,
    method: 'GET',
    url: `/swagger/${requestArguments.path['node']}`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'getSwaggerWithNodeRequest', '/users-api'),
    actions: {
      start: {
        type: DATA_GET_SWAGGER_WITH_NODE_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_SWAGGER_WITH_NODE_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_SWAGGER_WITH_NODE_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getSwaggerWithNodeRequestDefinitions = (definitionsArguments: {| requestArgs: getSwaggerWithNodeRequestUsersArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getSwaggerWithNodeRequestUsersArguments, payload?: Object |} => ({
  fnName: 'getSwaggerWithNodeRequest',
  basePath: '/users-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_GET_SWAGGER_WITH_CHILDNODE_START = 'DATA_GET_SWAGGER_WITH_CHILDNODE_START'
export const DATA_GET_SWAGGER_WITH_CHILDNODE_SUCCESSFUL = 'DATA_GET_SWAGGER_WITH_CHILDNODE_SUCCESSFUL'
export const DATA_GET_SWAGGER_WITH_CHILDNODE_FAIL = 'DATA_GET_SWAGGER_WITH_CHILDNODE_FAIL'

// Returns a second level node from the Swagger specification of the API
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/users.yaml#/Swagger/get_swagger__node___childNode_
export const getSwaggerWithChildNodeRequest = (
  requestArguments: getSwaggerWithChildNodeRequestUsersArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_SWAGGER_WITH_CHILDNODE_START',
      successful: 'DATA_GET_SWAGGER_WITH_CHILDNODE_SUCCESSFUL',
      fail: 'DATA_GET_SWAGGER_WITH_CHILDNODE_FAIL'
    },
    embeddedType: null,
    fnName: 'getSwaggerWithChildNodeRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}${options?.basePath || '/users-api'}`,
    method: 'GET',
    url: `/swagger/${requestArguments.path['node']}/${requestArguments.path['childNode']}`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'getSwaggerWithChildNodeRequest', '/users-api'),
    actions: {
      start: {
        type: DATA_GET_SWAGGER_WITH_CHILDNODE_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_SWAGGER_WITH_CHILDNODE_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_SWAGGER_WITH_CHILDNODE_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getSwaggerWithChildNodeRequestDefinitions = (definitionsArguments: {| requestArgs: getSwaggerWithChildNodeRequestUsersArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getSwaggerWithChildNodeRequestUsersArguments, payload?: Object |} => ({
  fnName: 'getSwaggerWithChildNodeRequest',
  basePath: '/users-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})
