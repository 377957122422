// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.
import React from 'react'
import { withStyles } from '@material-ui/core'
import TableRow from '@material-ui/core/TableRow'
import ListHeaderCell from '../ListHeaderCell/ListHeaderCell'
import ListRowCell from '../ListRowCell/ListRowCell'

const styles = ({ palette }: Object): Object => ({
  root: {
    height: '40px',
  },
  row: {
    '&$row': {
      '&:hover': {
        backgroundColor: palette.ui01,
      }
    }
  }
})

type Props = {
  classes: Object, // style object
  children: Array<ListHeaderCell | ListRowCell>, // the cells of the row
  onClick?: ?Function, // what happens when you click the row
  onBlur?: ?Function, // function triggered when input loses focus
  onMouseOver: ?Function,
  onMouseLeave?: ?Function,
  onFocus?: ?Function,
  testId?: string | null,
  className?: string | null, // optional className provided to TableRow
  noHoverEffect?: boolean
}

const ListRow = (props: Props) => {
  const {
    className,
    children,
    onClick,
    onBlur,
    onMouseOver,
    onMouseLeave,
    testId,
    onFocus,
    noHoverEffect,
    classes
  } = props
  return (
    <TableRow
      hover={!noHoverEffect}
      className={className}
      classes={{ root: classes.root, hover: classes.row }}
      data-testid={testId}
      onMouseOver={onMouseOver}
      onMouseLeave={onMouseLeave}
      onFocus={onFocus}
      onClick={onClick}
      onBlur={onBlur}>
      {children}
    </TableRow>
  )
}

ListRow.defaultProps = {
  onClick: null, // by default clicking a row does nothing
  onBlur: null, // by default right-clicking a row does nothing
  onMouseOver: null, // do nothing by default
  onMouseLeave: null, // do nothing by default
  onFocus: null, // do nothing by default
  testId: null, // id given to table row for testing purposes
  className: null, // className given to TableRow
  noHoverEffect: false, // don't display a hover effect, mostly for the header row
}

export default withStyles(styles)(ListRow)
