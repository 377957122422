// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withStyles } from '@material-ui/core'
import { withTranslation } from 'react-i18next'
import { filter } from 'lodash'
import { v4 } from 'uuid'
import Typography from '@material-ui/core/Typography'
import HamburgerMenu from '../../../common/menus/HamburgerMenu/HamburgerMenu'
import HierarchicalListContainer from '../../../containers/HierarchicalListContainer/HierarchicalListContainer'
import DescriptionCell from '../../../common/lists/common/DescriptionCell/DescriptionCell'
import UserModifiedIcon from '../../../containers/infoComponents/UserModifiedIcon'
import InfoPopover from '../../../common/InfoPopover/InfoPopover'
import SentientHOC from '../../../hocs/SentientHOC/SentientHOC'
import { AMOUNT, QUANTITY } from '../../../../constants/attributes'
import { EQUIPMENT, AREA_EQUIPMENT_REGISTRY, AREA_EQUIPMENT } from '../../../../constants/contentTypes'
import { EQUIPMENTS_TAB } from '../../../../constants/moduleConstants'
import { getIsUserModifiedContent } from '../../../../utils/listUtils'
import { RENOVATION } from '../../../../constants/viewModeConstants'
import { createUserDefinedEquipmentModal } from '../../../../actions/modals'
import { deleteListItem, addListItem, toggleAllListItemsOpen, modifyListItem } from '../../../../actions/list'
import {
  toggleWidgetModified,
  setSelectedTab,
  openContentWidget,
  markAsModified,
  setWidgetContentProps
} from '../../../../actions/widgets'
import {
  getEstimateEquipmentItemsDefaultWithItemIdRequest,
  getEstimateEquipmentColumnsWithEstimateIdRequestDefinitions,
  getEstimateEquipmentWithEstimateIdRequestDefinitions
} from '../../../../utils/generated-api-requests/spaces'

const styles = ({ palette, typography }: TVDTheme) => ({
  headerRowContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    marginRight: 2,
    width: '100%'
  },
  pointer: {
    cursor: 'pointer',
  },
  iconsContainer: {
    lineHeight: 1,
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center'
  },
  warningBox: {
    backgroundColor: palette.catskillWhite,
    padding: '12px 28px'
  },
  warningLabel: {
    fontWeight: 400,
    font: `14px ${typography.fontFamilyBase}`,
    color: palette.nevada
  }
})

type DispatchProps = {|
  dispatchSetWidgetContentProps: (contentProps: Object) => void, // sets widget content props
  dispatchCreateEquipment: (Function, Object) => void, // open create custom equipment modal
  dispatchToggleWidgetModified: (boolean) => void, // Marks the tab to have unsaved changes
  dispatchOpenContentWidget: (TVDOpenContentWidgetArguments) => void, // Open widget function
  dispatchAddListItem: (Object, string, boolean) => void, // adding list item to Store
  dispatchDeleteListItem: (string) => void,
  dispatchSetSelectedTab: Function,
  dispatchOpenEquipmentRegistry: Function, // fn to open registry widget
  dispatchOpenAllListItems: Function, // fn to open the list automatically
  dispatchModifyListItemIsUserModifiedContent: (string, boolean) => void, // sets userModifiedContent true/false for a listItem
  dispatchMarkSelfAsModified: () => void, // sets current widget with the widgetId from props as modified
|}

type ReceivedProps = {|
  resourceId: string, //  resouce uuid used to make API requests for a specific resource
  widgetId: string, // widget's id
  listStoreId: string, // id to be used for storing schedule items to store for a list
|}

type HOCProps = {|
  t: Function, // translate function
  classes: Object, // withstyles classes object
  sentient: TVDSentient, // Object providing helpers via SentientHOC
|}

type MappedProps = {|
  isEstimateFrozen: $PropertyType<TVDApplicationStore, 'isEstimateFrozen'>, // if estimate is frozen
  calculation: string, // ID of the calculation
  widgetType: string, // the constant type string value of the widget
  application: string, // application in use
  isEstimateLockedToCurrentUser: $PropertyType<TVDApplicationStore, 'isEstimateLockedToCurrentUser'>, // if estimate is locked to current user
  spacesEstimateType: $PropertyType<TVDApplicationStore, 'spacesEstimateType'>, // spaces estimate type
  spacesResultView: $PropertyType<TVDApplicationStore, 'spacesResultView'>, // spaces result view
  listLastModifiedTS?: number, // when the current list, found with listStoreId, has been modified timestamp
  isPostPolling: $PropertyType<TVDApplicationStore, 'isPostPolling'>,
  listItems: TVDListItems,
|}

type Props = {|
  ...ReceivedProps,
  ...DispatchProps,
  ...HOCProps,
  ...MappedProps,
  listStoreId: string, // uuid or constant used as the key in list Store for the list
  disabled: boolean, // status of if the widget is disabled or not where the Equipments is rendered
|}

type State = {
  parentId: string, // uuid for the newly added listItem as it's parentId
}

export class AreaEquipments extends Component<Props, State> {
  static defaultProps = {
    isEstimateLockedToCurrentUser: false
  }

  state = {
    parentId: ''
  }

  componentDidMount() {
    const { dispatchSetWidgetContentProps } = this.props
    dispatchSetWidgetContentProps({ disableFooterActions: false })
    this.props.dispatchSetSelectedTab()
  }

  componentDidUpdate(prevProps: Props) {
    const {
      spacesEstimateType,
      sentient,
      spacesResultView,
      listLastModifiedTS,
      isPostPolling,
      listItems,
      dispatchMarkSelfAsModified
    } = this.props

    if (
      prevProps.spacesEstimateType !== spacesEstimateType ||
      prevProps.spacesResultView !== spacesResultView
    ) {
      sentient.runSetup()
    }
    if ((listLastModifiedTS || 0) > (prevProps.listLastModifiedTS || 0)) {
      sentient.updateSetup()
    }
    if (!isPostPolling && prevProps.isPostPolling) {
      const hasAtLeastOneUserAddedRow = !!Object.keys(listItems).find((listItemId: string): boolean => {
        const { isUserAdded, isAdded } = listItems[listItemId]
        return !!(isUserAdded || isAdded)
      })
      if (hasAtLeastOneUserAddedRow) {
        dispatchMarkSelfAsModified()
      }
    }
  }

  getIsRowAddedOrCreated = ({ isUserAdded, isAdded }: TVDListItem) => isUserAdded || isAdded

  openElementWidget = (id: string, title: string) => {
    const { dispatchOpenContentWidget } = this.props
    dispatchOpenContentWidget({
      widgetId: id,
      widgetType: EQUIPMENT,
      widgetTitle: title,
      contentProps: {
        equipmentId: id,
        propertiesStoreId: id,
      }
    })
  }

  onRowClick = (row: Object) => {
    const {
      canHaveChildren,
      id,
      columnData: { Description },
    } = row
    if (
      !canHaveChildren &&
      row.parentId !== undefined &&
      !this.getIsRowAddedOrCreated(row)
    ) {
      this.openElementWidget(id, Description)
    }
  }

  onModifiedChange = (isModified: boolean) => {
    const { dispatchToggleWidgetModified } = this.props
    dispatchToggleWidgetModified(isModified)
  }

  getDescriptionCellContent = (content: string, row: Object) => {
    const { classes, } = this.props
    const {
      canHaveChildren,
      parentId,
    } = row

    // set row disabled and dont let return default values when isUserAdded or added row
    return (
      <div className={`${classes.headerRowContainer}`}>
        <DescriptionCell
          highlighted={!!(
            !canHaveChildren &&
            parentId !== undefined &&
            !this.getIsRowAddedOrCreated(row)
          )}
          text={content} />
        { this.getIconsContainer(row) }
      </div>
    )
  }

  showContextMenu = (): boolean => {
    const { isEstimateFrozen, isEstimateLockedToCurrentUser } = this.props
    return !!isEstimateLockedToCurrentUser && !isEstimateFrozen
  }

  getWarningText = (): React$Element<any> | null => {
    const { spacesEstimateType, t, classes } = this.props

    return spacesEstimateType === RENOVATION ?
      <div className={classes.warningBox}>
        <Typography className={classes.warningLabel}>
          {t('tabs._WARNING_MESSAGE_')}
        </Typography>
      </div> :
      null
  }

  static getAddedListItems = (listItems: TVDListItems): TVDListItems => Object.keys(listItems)
    .reduce((filteredListItems: TVDListItems, listItemId: string): TVDListItems => {
      const listItem = listItems[listItemId]
      if (listItem.isAdded) {
        return {
          ...filteredListItems,
          [listItemId]: listItem
        }
      }
      return filteredListItems
    }, {})


  getIconsContainer(row: TVDListItem): React$Element<any> | null {
    const { classes, application } = this.props

    return (
      <div className={classes.iconsContainer} data-visible_on_hover>
        {
            !!(
              getIsUserModifiedContent(row) &&
              !this.getIsRowAddedOrCreated(row)
            ) &&
              this.getUserModifiedIcon(row)
          }
        {
          row.canGetInfo &&
          <InfoPopover
            id={`${row.id}-InfoPopover`}
            application={application}
            infoData={{
              itemId: row.id,
              type: AREA_EQUIPMENT,
              heading: row.columnData.Description
            }} />
        }
        {
          this.showContextMenu() && this.getHamburgerMenu(row)
        }
      </div>
    )
  }

  getUserModifiedIcon(row: TVDListItem): React$Element<any> {
    const { listStoreId, t, dispatchModifyListItemIsUserModifiedContent } = this.props
    const actionCb = () => {
      getEstimateEquipmentItemsDefaultWithItemIdRequest(
        { path: { itemId: row.id } },
        {
          listId: listStoreId,
          itemId: row.id,
          mergeOptions: {
            initialListItems: { [row.id]: row },
          }
        }, () => { dispatchModifyListItemIsUserModifiedContent(row.id, false) }
      )
    }
    return ((
      <UserModifiedIcon
        actionText={t('userModifiedInfo._SPACES_RESET_DEFAULT_VALUE_')}
        id={`${row.columnData.Description}-modifiedIcon`}
        visible
        actionCb={actionCb} />
    ))
  }

  getContextMenuItems(row: Object): Array<TVDMenuItem> {
    if (!this.showContextMenu()) return []
    const {
      t,
      dispatchCreateEquipment,
      dispatchDeleteListItem,
      dispatchAddListItem,
      dispatchOpenEquipmentRegistry,
    } = this.props
    const { id: rowId, parentId: rowParentId } = row
    const { parentId } = this.state

    const menuItems: Array<TVDMenuItem> = [
      {
        localizedName: t('widgets._AREA_EQUIPMENT_REGISTRY_'),
        onClick: () => {
          dispatchOpenEquipmentRegistry(rowParentId || rowId)
        },
        testId: 'ContextMenuItem-open-area-equipments_registry'
      },
      {
        localizedName: t('widgets._ADD_USER_DEFINED_ITEM_'),
        onClick: () => {
          dispatchCreateEquipment((addedRow: Object) => {
            dispatchAddListItem({ ...addedRow, id: v4() }, parentId, true)
          }, { parentId })
        },
        testId: 'ContextMenuItem-add-user-defined-item'
      },
      {
        localizedName: t('buttons._DELETE_'),
        onClick: () => {
          dispatchDeleteListItem(rowId)
        },
        testId: 'ContextMenuItem-delete'
      }
    ]
    if (rowParentId === undefined) menuItems.pop()

    return menuItems
  }

  getHamburgerMenu(row: Object): React$Element<HamburgerMenu> {
    const { columnData: { Description: rowDescription } } = row
    const menuItems = this.getContextMenuItems(row)

    return <HamburgerMenu visibleOnHover id={rowDescription} items={menuItems} />
  }

  render(): React$Element<any> {
    const {
      listStoreId,
      disabled,
      isEstimateLockedToCurrentUser,
      sentient,
      dispatchOpenAllListItems,
      isEstimateFrozen
    } = this.props
    return (
      <>
        {this.getWarningText()}
        <HierarchicalListContainer
          isEstimateFrozen={isEstimateFrozen}
          contextMenuItems={(row: Object) => this.getContextMenuItems(row)}
          isEstimateLockedToCurrentUser={isEstimateLockedToCurrentUser}
          disabled={disabled}
          listId={listStoreId}
          testId='equipmentsList'
          editableColumns={[AMOUNT, QUANTITY]}
          wrappedCellContents={{
              Description: ({ content, row }: Object) => this.getDescriptionCellContent(content, row),
            }}
          didMountCallback={() => {
              sentient.runSetup({
                getEstimateEquipmentWithEstimateIdRequestDefinitions: {
                  successCb: (listItems: Object) => {
                    const { id: parentId } = filter(listItems, ({ parentId: listItemParentId }: Object) => !listItemParentId)[0]
                    this.setState({ parentId }, () => {
                      dispatchOpenAllListItems(listStoreId)
                    })
                  }
                }
              })
            }}
          onRowClick={(row: Object) => this.onRowClick(row)}
          onModifiedChange={(isModified: boolean) => this.onModifiedChange(isModified)} />
      </>
    )
  }
}

function mapStateToProps({ app, widgets, list }: TVDReduxStore, { resourceId, listStoreId }: ReceivedProps): MappedProps {
  const {
    calculation,
    isEstimateLockedToCurrentUser,
    application,
    isEstimateFrozen,
    spacesEstimateType,
    spacesResultView,
    isPostPolling
  } = app
  const {
    [listStoreId]: {
      listLastModifiedTS,
      listItems
    } = {}
  } = list
  const { [resourceId]: { widgetType } = {} } = widgets
  return {
    application,
    calculation,
    widgetType,
    isEstimateLockedToCurrentUser,
    isEstimateFrozen,
    spacesEstimateType,
    spacesResultView,
    listLastModifiedTS,
    isPostPolling,
    listItems
  }
}

function mapDispatchToProps(dispatch: Function, { widgetId, resourceId, listStoreId }: ReceivedProps): DispatchProps {
  return {
    dispatchSetWidgetContentProps: (contentProps: Object) => { dispatch(setWidgetContentProps(widgetId, contentProps)) },
    dispatchToggleWidgetModified: (isModified: boolean) => { dispatch(toggleWidgetModified(widgetId, 'equipments', isModified)) },
    dispatchOpenContentWidget: (openContentWidgetArgs: TVDOpenContentWidgetArguments) => { dispatch(openContentWidget(openContentWidgetArgs)) },
    dispatchCreateEquipment: (onSave: Function, listItem: Object) => { dispatch(createUserDefinedEquipmentModal(onSave, listItem)) },
    dispatchDeleteListItem: (rowId: string) => { dispatch(deleteListItem(listStoreId, rowId)) },
    dispatchSetSelectedTab: () => { dispatch(setSelectedTab(EQUIPMENTS_TAB, widgetId)) },
    dispatchOpenAllListItems: (listId: string) => { dispatch(toggleAllListItemsOpen(listId)) },
    dispatchAddListItem: (listItem: Object, parentId: string, isUserAdded: boolean) => {
      dispatch(addListItem(listStoreId, listItem, parentId, isUserAdded))
    },
    dispatchOpenEquipmentRegistry: (parentId: string) => {
      dispatch(openContentWidget({
        contentProps: {
          parentId,
          spaceId: resourceId,
          targetListStoreId: listStoreId,
          widgetLevelChild: true
        },
        widgetId: AREA_EQUIPMENT_REGISTRY,
        widgetType: AREA_EQUIPMENT_REGISTRY,
      }))
    },
    dispatchModifyListItemIsUserModifiedContent: (listItemId: string, isUserModified: boolean) => {
      dispatch(modifyListItem({
        columnName: 'userModifiedContent',
        listId: listStoreId,
        listItemId,
        value: isUserModified
      }))
    },
    dispatchMarkSelfAsModified: () => {
      dispatch(markAsModified(widgetId))
    }
  }
}

const sentientConfig: TVDSentientConfig = {
  updateOnPropsChange: ({ spacesEstimateType, spacesResultView }: Props): Object => ({ spacesEstimateType, spacesResultView }),
  getSetupRequestDefinitions: (store: TVDReduxStore, { listStoreId }: TVDGARConfigs) => {
    const {
      list: {
        [listStoreId]: {
          listItems = {}
        } = {}
      }
    } = store
    const addedListItems = Object.keys(listItems).reduce((filteredListItems: TVDListItems, listItemId: string): TVDListItems => {
      const listItem = listItems[listItemId]
      if (listItem.isAdded || listItem.isUserAdded) {
        return {
          ...filteredListItems,
          [listItemId]: listItem
        }
      }
      return filteredListItems
    }, {})
    return ({
      getEstimateEquipmentColumnsWithEstimateIdRequestDefinitions: getEstimateEquipmentColumnsWithEstimateIdRequestDefinitions({
        payload: { listId: listStoreId },
      }),
      getEstimateEquipmentWithEstimateIdRequestDefinitions: getEstimateEquipmentWithEstimateIdRequestDefinitions({
        payload: { listId: listStoreId, mergeOptions: { openAllListItems: true, initialListItems: addedListItems } },
        requestArgs: { query: { listType: 'flat' } },
      }),
    })
  }
}

export default compose(
  withStyles(styles),
  withTranslation('translations'),
  connect(mapStateToProps, mapDispatchToProps),
)(SentientHOC(AreaEquipments, sentientConfig))
