// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.
import { runGARs } from '../../utils/GARUtils'

export const SETUP_SENTIENT = 'SETUP_SENTIENT'
export function setupSentient(sentientStoreId: string, setup: TVDSentientSetup): Object {
  return {
    type: SETUP_SENTIENT,
    payload: { sentientStoreId, setup }
  }
}

export const UNSET_SENTIENT = 'UNSET_SENTIENT'
export function unsetSentient(sentientStoreId: string): Object {
  return {
    type: UNSET_SENTIENT,
    payload: { sentientStoreId }
  }
}

export const UPDATE_SENTIENT = 'UPDATE_SENTIENT'
export function updateSentient(sentientStoreId: string): Function {
  return (dispatch: Function, getState: Function): TVDAction => {
    const {
      sentients:
      {
        [sentientStoreId]: {
          requestDefinitions
        }
      }
    } = getState()

    runGARs(requestDefinitions)

    return {
      type: UPDATE_SENTIENT,
      payload: { sentientStoreId }
    }
  }
}

