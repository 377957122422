// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.
import axios from 'axios'
import * as qs from 'query-string'
import { clearSelectedAccount, setIsRefreshingAccessToken } from '../app'
import {
  wso2Oauth2Config,
  authServiceConfig,
  authenticationFlowType,
  getAuthUrlByUserType,
  getAuthURLFromPathname
} from '../../utils/authUtil'
import { wait } from '../../utils/apiUtils'
import { getState, dispatch } from '../../store'
import { setLocalStorage } from '../../utils/commonUtils'
import { getUserGroupsRequest, getUsersRequest } from '../../utils/generated-api-requests/users'
import * as allUserGroupTypeIds from '../../constants/userGroupTypeIds'
import HALParser from '../../utils/HALParser'
import { ALLOW_WITH_CREDENTIALS } from '../../constants/envConstants'
import {
  TVD_TOKEN_USER_TYPE_USER,
} from '../../constants/apiConstants'


export const RESET_USER_DATA = 'RESET_USER_DATA'
export const SAVE_AUTHORIZATION_DATA = 'SAVE_AUTHORIZATION_DATA'
export const SAVE_AUTH_SERVICE_API_TOKEN = 'SAVE_AUTH_SERVICE_API_TOKEN'
export const SAVE_NAME_ID = 'SAVE_NAME_ID'
export const SAVE_ROUTE = 'SAVE_ROUTE'
export const SAVE_SESSION_INDEX = 'SAVE_SESSION_INDEX'
export const SAVE_USER_CLAIMS = 'SAVE_USER_CLAIMS'
export const SAVE_ID_TOKEN = 'SAVE_ID_TOKEN'

export const SAVE_USER_INFO = 'SAVE_USER_INFO'

export const isTokenExpired = () => {
  // Use a 5 minute threshold when comparin expiration time to current time
  // so that we never end up in a situation where the access token
  // expires just as we send an API request.
  const tokenExpirationThreshholdMillis = 300000
  const { authorization } = getState().user
  if (authorization) {
    const { exp: tokenExpiresAtSeconds } = authorization
    if (tokenExpiresAtSeconds) {
      const tokenExpiresAtMillis = (tokenExpiresAtSeconds * 1000) - tokenExpirationThreshholdMillis
      const currentTimeMillis = new Date().getTime()
      // console.log('currentTimeMillis', currentTimeMillis)
      // console.log('tokenExpiresAtMillis', tokenExpiresAtMillis)
      // console.log('currentTimeMillis > tokenExpiresAtMillis', currentTimeMillis > tokenExpiresAtMillis)
      return currentTimeMillis >= tokenExpiresAtMillis
    }
    console.log('Expiration time not available! Token marked as expired.')
    return true
  }
  console.log('Authorization data not available! Token marked as expired.')
  return true
}

export const isAuthenticatedUser = ({ authorization, claims }: Object) => {
  const { active } = authorization
  const { userId } = claims
  if (active && userId) {
    return true
  }
  return false
}

export type SyncUserData = {|
  authorization: TVDUserAuthorization,
  userId?: $PropertyType<TVDUserClaims, 'userId'>,
  haahtelaApiUserToken: $PropertyType<TVDUserClaims, 'haahtelaApiUserToken'>,
  authServiceApiToken: string, // token for accessing the AuthService API
|}

export const SYNC_USER_DATA = 'SYNC_USER_DATA'
export const syncUserData = (userData: SyncUserData): TVDAction => ({
  type: SYNC_USER_DATA,
  payload: { userData }
})

// data actions for handling authorization data
export const saveRoute = (route: string): Object => ({
  type: SAVE_ROUTE,
  route,
})

export const saveNameId = (nameId: string): Object => ({
  type: SAVE_NAME_ID,
  nameId,
})

export const saveIdToken = (idToken: string): Object => ({
  type: SAVE_ID_TOKEN,
  idToken,
})

export const saveSessionIndex = (sessionIndex: string): Object => ({
  type: SAVE_SESSION_INDEX,
  sessionIndex,
})

export const saveUserClaims = (claims: Object): Object => ({
  type: SAVE_USER_CLAIMS,
  payload: claims,
})

export const resetUserData = () => ({
  type: RESET_USER_DATA
})

export const saveAuthorizationData = (authorizationData: Object) => ({
  type: SAVE_AUTHORIZATION_DATA,
  payload: authorizationData,
})

export const saveAuthServiceApiToken = (authServiceApiToken: Object) => ({
  type: SAVE_AUTH_SERVICE_API_TOKEN,
  authServiceApiToken,
})

// this function takes the user out of the TakuPro web app and into the WSO2 Identity Server SSO page
export const login = (route: string) => {
  console.log({ wso2Oauth2Config })
  const {
    authorizationUrl,
    redirectUri,
    clientId,
  } = wso2Oauth2Config

  const { authServiceUrl } = authServiceConfig

  // TOOD: remove authorization code auth flow after we have the fine grained API access control specified
  const url = authenticationFlowType === 'SAML' ?
    `${authServiceUrl}/login?route=${encodeURIComponent(route)}` :
    `${authorizationUrl}?scope=default&response_type=code&redirect_uri=${redirectUri}&client_id=${clientId}`

  window.location = url
}

function getAuthServiceTVDRequestConfig(authServiceApiToken: string): Function {
  const config = {
    headers: {
      Authorization: `Bearer ${authServiceApiToken}`,
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    ...(ALLOW_WITH_CREDENTIALS ? { withCredentials: true } : {})
  }
  return config
}

export async function introspectAccessToken(accessToken: string, authUrl: string, authServiceApiToken: string): Function {
  try {
    const params = qs.stringify({
      token: accessToken,
    })
    const response = await axios.post(`${authUrl}/introspect`, params, getAuthServiceTVDRequestConfig(authServiceApiToken))
    const {
      active,
      exp,
      iat,
      scope,
    } = response.data

    if (active) {
      const authorizationData = {
        accessToken,
        active,
        exp,
        iat,
        scope,
      }
      return authorizationData
    }
    throw new Error('Access token is no longer valid.')
  } catch (error) {
    // TODO: how should we handle error messages in the UI?
    const errorMsg = `Introspecting access token failed! ${error}`
    console.log(errorMsg)
    throw new Error(errorMsg)
  }
}

export async function getAuthServiceApiToken(jwtId: string, authUrl: string): Function {
  try {
    const params = qs.stringify({
      jwtId,
    })
    const response = await axios.post(
      `${authUrl}/jwt`,
      params,
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        ...(ALLOW_WITH_CREDENTIALS ? { withCredentials: true } : {})
      }
    )
    return response.data
  } catch (error) {
    // TODO: how should we handle error messages in the UI?
    const errorMsg = `Retrieving AuthService API token failed! ${error}`
    console.log(errorMsg)
    throw new Error(errorMsg)
  }
}

export async function getAccessToken(atid: string, authUrl: string, authServiceApiToken: string): Function {
  try {
    const params = qs.stringify({
      atid,
    })
    const response = await axios.post(`${authUrl}/token`, params, getAuthServiceTVDRequestConfig(authServiceApiToken))
    const { accessToken } = response.data
    return accessToken
  } catch (error) {
    // TODO: how should we handle error messages in the UI?
    const errorMsg = `Getting access token failed! ${error}`
    console.log(errorMsg)
    throw new Error(errorMsg)
  }
}

export type RefreshAccessTokenReturn = {|
  isRefreshingSuccessful: boolean,
  newAccessToken?: string | null
|}

export async function refreshAccessToken(
  userType: TVDTokenUserType,
  cb?: (RefreshAccessTokenReturn) => void
): Promise<RefreshAccessTokenReturn> {
  const { app: { isRefreshingAccessToken } } = getState()
  let isRefreshingSuccessful = true
  let newAccessToken = null
  if (!isRefreshingAccessToken) {
    dispatch(setIsRefreshingAccessToken(true))
    setLocalStorage('takupro.isRefreshingToken', 'true')
    try {
      // const refreshStartTimeMillis = new Date().getTime()
      const {
        authorization: { accessToken },
        authServiceApiToken: oldAuthServiceApiToken
      } = getState().user
      const authServiceUrl = getAuthUrlByUserType(userType)
      if (accessToken) {
        const params = qs.stringify({
          expiredAccessToken: accessToken,
        })
        const response = await axios.post(`${authServiceUrl}/token`, params, getAuthServiceTVDRequestConfig(oldAuthServiceApiToken))
        const { renewedAccessToken, authServiceApiToken, haahtelaApiUserToken } = response.data
        newAccessToken = renewedAccessToken
        const authorizationData = await introspectAccessToken(renewedAccessToken, authServiceUrl, authServiceApiToken)
        localStorage.setItem('takupro.isRefreshingToken', 'false')
        // const refreshEndTimeMillis = new Date().getTime()
        // console.log(`Refreshing access token took ${refreshEndTimeMillis - refreshStartTimeMillis} milliseconds to complete.`)
        dispatch(syncUserData({
          authorization: authorizationData,
          authServiceApiToken,
          haahtelaApiUserToken,
        }))
      } else {
        // TODO: how should we handle error messages in the UI?
        const errorMsg = 'Access token not available.'
        console.log(errorMsg)
        isRefreshingSuccessful = false
        throw new Error(errorMsg)
      }
    } catch (error) {
      // TODO: how should we handle error messages in the UI?
      const errorMsg = `Refreshing access token failed! ${error}`
      console.log(errorMsg)
    }
    setLocalStorage('takupro.isRefreshingToken', 'false')
    dispatch(setIsRefreshingAccessToken(false))
  }
  if (cb) cb({ isRefreshingSuccessful, newAccessToken })
  return { isRefreshingSuccessful, newAccessToken }
}

export async function refreshUserAccessToken(cb?: ({ isRefreshingSuccessful: boolean }) => void): Promise<any> {
  return refreshAccessToken(TVD_TOKEN_USER_TYPE_USER, cb)
}

export async function revokeAccessToken(authServiceUrl: string): Function {
  try {
    const {
      authorization,
      authServiceApiToken: oldAuthServiceApiToken
    } = getState().user
    if (authorization.accessToken) {
      const { accessToken } = authorization

      await axios.delete(`${authServiceUrl}/token/${accessToken}`, getAuthServiceTVDRequestConfig(oldAuthServiceApiToken))
    } else {
      // TODO: how should we handle error messages in the UI?
      const errorMsg = 'Access token not available.'
      console.log(errorMsg)
      throw new Error(errorMsg)
    }
  } catch (error) {
    // TODO: how should we handle error messages in the UI?
    const errorMsg = `Revoking access token failed! ${error}`
    console.log(errorMsg)
  }
}

export async function refreshTokenIfExpired(actions: Object, userType: TVDTokenUserType): Function {
  if (isTokenExpired()) {
    const isRefreshingToken = JSON.parse(localStorage.getItem('takupro.isRefreshingToken') || 'false')
    console.log('takupro.isRefreshingToken', isRefreshingToken)
    if (isRefreshingToken) {
      const tokenRefreshWaitTimeMillis = 2000
      // debounce any incoming API calls while token is being refreshed
      // should take only a few hunderd milliseconds to refresh the token but playing it safe with 2000 ms)
      console.log(`Token expired but token already being refreshed! Waiting ${tokenRefreshWaitTimeMillis} ms before continuing.`)
      await wait(tokenRefreshWaitTimeMillis)
    } else {
      console.log(`Token expired! Refreshing token before action[${actions.start.type}`)
      try {
        await refreshAccessToken(userType)
      } catch (err) {
        console.log(err)
      }
    }
    console.log(`Continuing with action [${actions.start.type}]`)
  }
}

export function logout(): Function {
  return async () => {
    if (authenticationFlowType === 'SAML') {
      const authUrl = getAuthURLFromPathname()
      await revokeAccessToken(authUrl)
      const { nameId, sessionIndex, idToken } = getState().user
      console.log('Clearing user data...')
      dispatch(resetUserData())
      dispatch(clearSelectedAccount())
      console.log(`Logging out user ${nameId}...`)
      window.location = `${authUrl}/logout?nameId=${nameId}&sessionIndex=${sessionIndex}&idTokenHint=${idToken}`
    }
  }
}

export const saveUserInfo = (user: Object, userApps: Array<number>): Function => ({
  type: SAVE_USER_INFO,
  payload: { user, userApps }
})

export const STORE_USER_GROUP_TYPE_IDS = 'STORE_USER_GROUP_TYPE_IDS'
export const storeUserGroupTypeIds = (userGroupTypeIds: Array<number>): TVDAction => ({
  type: STORE_USER_GROUP_TYPE_IDS,
  payload: { userGroupTypeIds }
})

export const saveUsersGroups = () => (thunkDispatch: Function, thunkGetState: () => TVDReduxStore) => {
  const allUserGroupTypeIdsArray = Object.keys(allUserGroupTypeIds)
    .map((userGroupTypeConstantKey: string): number => allUserGroupTypeIds[userGroupTypeConstantKey])

  // not configured to handle running in account management
  getUserGroupsRequest({}, (groups: Array<TVDUserGroup>) => {
    const {
      user: {
        claims: {
          userId
        }
      }
    } = thunkGetState()

    const allUserGroups = groups.reduce((userGroups: Array<TVDUserGroup>, userGroup: TVDUserGroup): any => {
      if (allUserGroupTypeIdsArray.includes(userGroup.userGroupTypeId)) {
        return [...userGroups, userGroup]
      }
      return userGroups
    }, [])


    const userGroupTypeIdsUserIsIncluded = []

    getUsersRequest({ query: { includeGroups: true } }, {}, (users: Array<TVDUser>) => {
      const matchingUser = users.find((user: TVDUser): boolean => user.id === userId)
      if (!matchingUser) {
        console.error(`could not find user among list of users with id of ${userId}`)
        return
      }
      const { usergroups } = HALParser.getEmbedded(matchingUser)
      usergroups.forEach((userGroup: TVDEnum) => {
        const matchingUserGroup = allUserGroups.find((allUserGroupsGroup: TVDUserGroup): boolean => allUserGroupsGroup.id === userGroup.value)
        if (matchingUserGroup) {
          userGroupTypeIdsUserIsIncluded.push(matchingUserGroup.userGroupTypeId)
        }
      })
      thunkDispatch(storeUserGroupTypeIds(userGroupTypeIdsUserIsIncluded))
    })
  })
}
