// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.

import React from 'react'
import { compose } from 'redux'
import { connect, batch } from 'react-redux'
import { withStyles } from '@material-ui/core'
import { withTranslation } from 'react-i18next'
import { find } from 'lodash'
import { typographyClasses, colors } from 'frontend-assets'

import SimpleHierarchicalList from '../SimpleHierarchicalList/SimpleHierarchicalList'
import MenuCell, { MENU_CELL_CONTENT_TYPE_DROPDOWN, MENU_CELL_CONTENT_TYPE_HAMBURGER } from '../common/MenuCell/MenuCell'

import { modifyListItem, setListItemDefaults, type TVDModifyListItemPayload } from '../../../../actions/list'
import HALParser from '../../../../utils/HALParser'
import { measureHasEditableProperties } from '../../../../utils/listUtils'
import {
  FUNCTIONALSECTOR,
  FUNCTION,
  SPACEGROUP,
  SPACE,
  RENOVATION_SPACEGROUPS,
  RENOVATION_SPACES, COPY_RENOVATION_PROFILE_MODAL,
} from '../../../../constants/contentTypes'
import { getRenovationSpacesProfilesPropertiesWithProfileIdRequest } from '../../../../utils/generated-api-requests/spaces_renovation'
import { TVD_PATCH_OPERATION_TYPE_RESET } from '../../../../constants/patchOperationConstants'
import { createPatchOperation } from '../../../../utils/patchOperationUtil'
import { setWidgetActive } from '../../../../actions/app'
import { clearPatchOperationParameter, storePatchOperation } from '../../../../actions/patchOperations'
import { closeModal, openModal } from '../../../../actions/modals'

const styles = ({ palette }: TVDTheme): Object => ({
  simpleMatrixWrapper: {
    flex: 1,
    '& [class*="MuiInput-formControl"]': {
      fontSize: '14px',
      margin: 'auto',
      marginBottom: '8px',
      marginTop: '8px !important',
      width: '50%',
      backgroundColor: 'transparent',
      '&:hover': {
        fontWeight: 'bold',
        borderBottom: '2px solid',
        borderBottomColor: palette.ui02
      }
    }
  },
  cellBorders: {
    borderLeft: `1px solid ${palette.porcelain}`,
    borderRight: `1px solid ${palette.porcelain}`,
    padding: '0 !important',
  },
  smallColumn: {
    maxWidth: '35px !important',
    minWidth: '30px !important',
    background: 'transparent',
    padding: '0px !important',
    '& > * ': {
      width: '110px',
      marginLeft: '50%',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      transformOrigin: 'left',
      transform: 'rotate(-40deg)',
      display: 'flex',
      justifyContent: 'flex-start',
      fontSize: '12px'
    }
  },
  menuTitleWrapper: {
    display: 'flex',
    flexDirection: 'column',
    padding: '3px 16px 6px',
  },
  menuTitle: {
    ...typographyClasses.bodyDefault,
    margin: 0,
    color: colors.dark80
  },
})

type ReceivedProps = {|
  // TODO: refactor so that we don't need listStoreId and we can give matrix cell content from outside the list
  listStoreId: string, // the unfortunate id that let's us differentiate matrix lists in order to render different content on different cell types
  widgetId: string, // id of the widget that the component is in
  widgetTab: string, // tab name where the component is in
  disabled?: boolean, // if the element is considered disabled
|}

type DispatchProps = {|
  dispatchModifyListItem: (payload: TVDModifyListItemPayload) => void, // adds modified status to list item in Store
  dispatchSetListItemDefaults: (listItemId: string, defaults: { [columnName: string]: string }) => void, // sets defaultColumnData for a list item in Store
  dispatchSetWidgetActive: () => void, // sets widget status active in Store with widget id and tab
  dispatchClearPatchOperationParameter: (resourceId: string, parameterPath: string) => void, // clears a patch operation parameter from Store with resourceId and path
  dispatchStorePatchOperation: (patchOperation: TVDPatchOperation) => void, // stores a patchOperation to Store
  dispatchOpenCopyRenovationProfileModal: Function, // function to copy addBuildingElements modal
|}

type MappedProps = {|
  isEstimateLockedToCurrentUser: $PropertyType<TVDApplicationStore, 'isEstimateLockedToCurrentUser'> // if the user owns the lock for the estimate
|}

type Props = {|
  ...ReceivedProps,
  ...DispatchProps,
  ...MappedProps,
  columns: Array<TVDListItemColumn>, // Data for columns
  listItems: Array<TVDListItem>, // list items for the hierarchical list
  testId: string, // data-testid
  onMenuCellClick: (listItem: TVDListItem, column: Object, value: string) => void, // onChange function to call for MenuCell
  classes: Object, // withStyles style object
  t: Function, // translate function
|}

type TVDMappedColumnClassNames = { [propertyName: string]: string }
type TVDMappedMatrixCellContentItems = {|
  ...TVDSchemaMeasure,
  _embedded?: Object,
  properties?: Array<Object>,
  onClickCb: () => void, // menuItem onClick callback
|}

type State = {|
  isLoadingDefaults: boolean, // if the component is loading default values for menu cells
|}
export class MatrixList extends React.Component<Props, State> {
  state = {
    isLoadingDefaults: false
  }

  handleColumnChange = (columnValue: string, rowId: string, value: string) => {
    const { dispatchModifyListItem, listStoreId } = this.props
    dispatchModifyListItem({
      listId: listStoreId,
      listItemId: rowId,
      columnName: columnValue,
      value
    })
  }

  getWrappedMatrixCellContents = (): TVDWrappedCells => {
    const { columns } = this.props
    return columns.reduce((result: Object, column: TVDListItemColumn) => ({
      ...result,
      [column.propertyName]: ({ row }: TVDWrappedCellCallbackParameters) => this.getMatrixCellContent(row, column)
    }), {})
  }

  resetOperation = (row: TVDListItem, columnPropertyName: string, defaultValue: any) => {
    const {
      listStoreId,
      dispatchModifyListItem,
      dispatchSetWidgetActive,
      dispatchClearPatchOperationParameter,
      dispatchStorePatchOperation,
    } = this.props

    if (columnPropertyName) {
      const resetPatchOperation = createPatchOperation({
        resourceId: row.id,
        value: defaultValue.value,
        operationType: TVD_PATCH_OPERATION_TYPE_RESET,
        basePath: `columnData/${columnPropertyName}`
      })
      batch(() => {
        dispatchModifyListItem({
          value: defaultValue.value,
          columnName: columnPropertyName,
          listId: listStoreId,
          listItemId: row.id,
          options: {
            showModifiedIndicator: false,
          }
        })
        dispatchSetWidgetActive()
        dispatchClearPatchOperationParameter(row.id, `columnData/${columnPropertyName}`)
        dispatchStorePatchOperation(resetPatchOperation)
      })
    }
  }

  getOptions = (row: TVDListItem, columnPropertyName?: string = '', measures: Array<TVDSchemaMeasure>): Array<MenuItemProps> => {
    const { t, dispatchOpenCopyRenovationProfileModal, listItems } = this.props
    const { showModifiedIndicatorColumns = {}, columnMeta: { [columnPropertyName]: { userModified } = {} } = {} } = row

    const defaultValue = this.getDefaultValue(row, columnPropertyName, measures)
    const hasModifiedColumnIndicator = Object.prototype.hasOwnProperty.call(showModifiedIndicatorColumns, columnPropertyName)
    const canResetDefaultOrCopyValue = hasModifiedColumnIndicator ? showModifiedIndicatorColumns[columnPropertyName] : userModified

    const options = []

    if (defaultValue && canResetDefaultOrCopyValue) {
      options.push({
        disabled: !canResetDefaultOrCopyValue,
        localizedName: t('renovation._RESET_PROFILE_DEFAULT_VALUE_FOR_RENOVATION_MEASURE_', { defaultValue: defaultValue.localizedName }),
        onClickCb: () => { this.resetOperation(row, columnPropertyName, defaultValue) }
      })
    }

    if (canResetDefaultOrCopyValue) {
      options.push({
        localizedName: t('renovation._COPY_PROFILE_VALUES_FOR_RENOVATION_MEASURES_'),
        onClickCb: () => {
          const selectedMeasure = measures.find((measure: TVDSchemaMeasure) =>
            measure.value === row.columnData[columnPropertyName]) || { localizedName: '' }
          dispatchOpenCopyRenovationProfileModal(listItems, row, false, columnPropertyName, selectedMeasure.localizedName)
        }
      })
    }

    if (options.length) options.unshift({ divider: true })
    return options
  }

  getDefaultValue = (row: TVDListItem, columnValue: string, measures: Array<TVDSchemaMeasure>): {| localizedName: string, value: string |} | null => {
    const { defaultColumnData: { [columnValue]: defaultColumnDataValue } = {} } = row
    if (defaultColumnDataValue) {
      const { localizedName, value } = measures.find((measure: TVDSchemaMeasure) => measure.value === defaultColumnDataValue) || {}
      return {
        localizedName: localizedName.split('-')[0].trim(),
        value
      }
    }
    return null
  }

  setListItemDefaults = (listItem: TVDListItem) => {
    const { dispatchSetListItemDefaults } = this.props
    const {
      modifiedColumnData = {},
      defaultColumnData = {},
      columnData,
      id
    } = listItem
    const profileId = modifiedColumnData.RenovationProfileId || columnData.RenovationProfileId
    if (Object.keys(defaultColumnData).length === 0 && profileId) {
      this.setState({ isLoadingDefaults: true })
      getRenovationSpacesProfilesPropertiesWithProfileIdRequest({
        path: { profileId }
      }, {}, (properties: Array<TVDPropertiesListItem>) => {
        batch(() => {
          const defaults = properties.reduce((result: Object, { propertyName, value: propertyValue }: TVDPropertiesListItem) => ({
            ...result, [propertyName]: propertyValue
          }), {})
          dispatchSetListItemDefaults(id, defaults)
        })
        this.setState({ isLoadingDefaults: false })
      })
    }
  }

  getMatrixCellContent = (row: TVDListItem, column: TVDListItemColumn): React$Element<MenuCell> | null => {
    const { onMenuCellClick, listStoreId, disabled } = this.props
    const { measures } = HALParser.getEmbedded(column)
    const { isLoadingDefaults } = this.state
    const { propertyName } = column
    const {
      modifiedColumnData = {},
      columnData,
      columnMeta,
      showModifiedIndicatorColumns = {},
      type
    } = row

    const items = measures.map((measure: TVDSchemaMeasure): TVDMappedMatrixCellContentItems => {
      const { properties } = HALParser.getEmbedded(measure) || {}
      const { _embedded, ...plainMeasure } = measure

      const parsedMeasure: TVDMappedMatrixCellContentItems = {
        ...plainMeasure,
        onClickCb: () => {
          if (!disabled) {
            onMenuCellClick(row, column, measure.value)
          }
        }
      }

      if (measureHasEditableProperties(properties)) parsedMeasure.properties = properties
      return parsedMeasure
    })

    const value = modifiedColumnData[propertyName] || columnData[propertyName] // after setting renovation profile, both of these data is missing (renders zeroes after profile selected)
    const selectedMeasure = value && find(items, (item: Object) => item.value === value)

    const isUserModifiedValue = Boolean(columnMeta && columnMeta[column.propertyName]?.userModified)
    const isModifiedUnsavedValue = Boolean(modifiedColumnData[propertyName] && showModifiedIndicatorColumns[column.propertyName])

    const isDefaultValue = Boolean(!isUserModifiedValue && !isModifiedUnsavedValue)
    const isZeroValue = Boolean(value === measures[0].value)
    const isDefaultZeroValue = Boolean(isDefaultValue && isZeroValue)

    switch (type.toUpperCase()) {
      case FUNCTIONALSECTOR:
      case FUNCTION: {
        return (<MenuCell
          disabled={disabled}
          menuTitle={this.getMenuTitle(row.type, column)}
          cellContentType={MENU_CELL_CONTENT_TYPE_HAMBURGER}
          items={items} />)
      }
      case SPACEGROUP: {
        // Renders a different type of MenuCell component if RENOVATION_SPACEGROUPS list instead of RENOVATION_SPACES list
        if (listStoreId === RENOVATION_SPACEGROUPS) {
          return (<MenuCell
            disabled={disabled}
            menuTitle={this.getMenuTitle(row.type, column)}
            showModifiedIcon={!isDefaultValue}
            cellContentType={MENU_CELL_CONTENT_TYPE_DROPDOWN}
            value={value}
            items={items}
            hideArrow
            row={row}
            measureTopicId={propertyName}
            renderEmpty={isDefaultZeroValue}
            selectedMeasure={selectedMeasure} />)
        }
        return (<MenuCell
          disabled={disabled}
          cellContentType={MENU_CELL_CONTENT_TYPE_HAMBURGER}
          menuTitle={this.getMenuTitle(row.type, column)}
          items={items} />)
      }
      case SPACE: {
        const _items = [...items, ...(this.getOptions(row, propertyName, measures))]

        return (_items && <MenuCell
          disabled={disabled}
          renderSkeletons={isLoadingDefaults}
          onDropdownOpen={() => this.setListItemDefaults(row)}
          onChange={(valueData: string) => this.handleColumnChange(propertyName, row.id, valueData)}
          showModifiedIcon={!isDefaultValue}
          cellContentType={MENU_CELL_CONTENT_TYPE_DROPDOWN}
          value={value}
          hideArrow
          measureTopicId={propertyName}
          row={row}
          items={_items}
          renderEmpty={isDefaultZeroValue}
          selectedMeasure={selectedMeasure} />)
      }
      default: return null
    }
  }

  getMenuTitle = (rowType: string, column: TVDListItemColumn) => {
    const { t, classes, listStoreId } = this.props
    const targetRow = listStoreId === RENOVATION_SPACES ? SPACE : SPACEGROUP
    const translateKey = `renovation._${listStoreId}_SET_MEASURE_TO_ALL_${targetRow}_IN_${rowType.toUpperCase()}_`
    return (
      <div className={classes.menuTitleWrapper}>
        <p className={classes.menuTitle}>
          {`${t(translateKey)} `}
        </p>
        <p className={classes.menuTitle}>
          {column.localizedName} {t('renovation._RENOVATION_SITE_RENOVATION_MEASURE_').toLowerCase()}:
        </p>
      </div>
    )
  }

  getColumnNames = (): Array<string> => this.props.columns.map(({ propertyName }: TVDListItemColumn) => propertyName)

  getMappedColumnClasses(className: string): TVDMappedColumnClassNames {
    return this.props.columns.reduce((result: TVDMappedColumnClassNames, { propertyName }: TVDListItemColumn): TVDMappedColumnClassNames => ({
      ...result, [propertyName]: className
    }), {})
  }

  disabled(): boolean {
    const { isEstimateLockedToCurrentUser } = this.props
    return !isEstimateLockedToCurrentUser
  }

  render(): React$Element<'div'> {
    const {
      columns,
      classes,
      listItems,
      testId,
    } = this.props

    return (
      <div className={classes.simpleMatrixWrapper}>
        <SimpleHierarchicalList
          hideListOpenerIcon
          testId={testId}
          resizeDisabledColumns={this.getColumnNames()}
          listRowCellClassNames={this.getMappedColumnClasses(classes.cellBorders)}
          wrappedCellContents={this.getWrappedMatrixCellContents()}
          data={listItems}
          columns={columns}
          listHeaderCellClassNames={this.getMappedColumnClasses(classes.smallColumn)} />
      </div>
    )
  }
}

const mapStateToProps = ({ app: { isEstimateLockedToCurrentUser } }: TVDReduxStore): MappedProps => ({
  isEstimateLockedToCurrentUser
})

const mapDispatchToProps = (dispatch: Function, {
  widgetId, widgetTab, listStoreId, t
}: ReceivedProps & Props): DispatchProps => ({
  dispatchSetWidgetActive: () => { dispatch(setWidgetActive(widgetId, widgetTab)) },
  dispatchModifyListItem: (payload: TVDModifyListItemPayload) => { dispatch(modifyListItem(payload)) },
  dispatchClearPatchOperationParameter: (resourceId: string, parameterPath: string) => {
    dispatch(clearPatchOperationParameter(listStoreId, resourceId, parameterPath))
  },
  dispatchOpenCopyRenovationProfileModal: (
    listItems: TVDListItems, row: TVDListItem,
    copyOnlyProfile?: boolean | null, columnPropertyName: string,
    selectedMeasure: string
  ) => {
    const modalId = COPY_RENOVATION_PROFILE_MODAL
    dispatch(openModal(
      {
        onClose: () => dispatch(closeModal(modalId)),
        type: COPY_RENOVATION_PROFILE_MODAL,
        disablePadding: true, // flag to disable padding in modal
        title: `${t('copyRenovationModal._TITLE_')}${row.columnData.Description}, ${selectedMeasure}`,
        listItems,
        copyOnlyProfile,
        columnPropertyName,
        listStoreId,
        row,
        widgetId,
        widgetTab,
      },
      modalId
    ))
  },
  dispatchSetListItemDefaults: (listItemId: string, defaults: Object) => { dispatch(setListItemDefaults(listStoreId, listItemId, defaults)) },
  dispatchStorePatchOperation: (patchOperation: TVDPatchOperation) => { dispatch(storePatchOperation(listStoreId, patchOperation)) }
})

export default compose(withTranslation('translations'), connect(mapStateToProps, mapDispatchToProps), withStyles(styles),)(MatrixList)
