// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.
import React from 'react'
import type { Element } from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { withStyles } from '@material-ui/core'
import { filter, findKey } from 'lodash'
import { compose } from 'redux'

import HierarchicalListContainer from '../../HierarchicalListContainer/HierarchicalListContainer'
import DescriptionCell from '../../../common/lists/common/DescriptionCell/DescriptionCell'
import HamburgerMenu from '../../../common/menus/HamburgerMenu/HamburgerMenu'
import InfoPopover from '../../../common/InfoPopover/InfoPopover'
import Spinner from '../../../common/Spinner/Spinner'
import SentientHOC from '../../../hocs/SentientHOC/SentientHOC'
import ErrorBoundary from '../../../common/ErrorBoundary/ErrorBoundary'

import { postPolling } from '../../../../actions/postPolling'
import { copyItem, undoModifyListItem } from '../../../../actions/list'
import { openContentWidget } from '../../../../actions/widgets'
import {
  buildRenameModal,
  buildDeleteModal,
  buildCreateModal,
  buildMoveModal,
  openModal,
  closeModal
} from '../../../../actions/modals'
import { removeHighlight } from '../../../../utils/commonUtils'

import {
  getScheduleColumnsWithEstimateIdRequestDefinitions,
  getScheduleWithEstimateIdRequestDefinitions,
  patchAssemblyWithIdRequest,
  patchHeadingWithIdRequest,
  patchPriceitemWithIdRequest
} from '../../../../utils/generated-api-requests/buildingelements'
import { CHANGE_ORDER_FIRST, CHANGE_ORDER_UP, CHANGE_ORDER_DOWN, CHANGE_ORDER_LAST } from '../../../../constants/requestConstants'
import UserModifiedIcon from '../../infoComponents/UserModifiedIcon'
import { type TVDConfirmModalContent } from '../../../common/ConfirmationModal/ConfirmationModal'
import { ELEMENTS } from '../../../../constants/moduleConstants'
import { TOTAL_PRICE_IN_CURRENCY, UNIT_PRICE, QUANTITY } from '../../../../constants/attributes'
import {
  ASSEMBLY,
  PRICEITEM,
  HEADING,
  ITEM,
  CONFIRMATION_MODAL,
  BUILDING_ELEMENTS_SCHEDULE
} from '../../../../constants/contentTypes'
import theme from '../../../../styles/theme'
import { setMFEStates } from '../../../../actions/MFEStates'
import {
  BUILDING_ELEMENTS_PRODUCT_ASSEMBLY_MFENAME,
  BUILDING_ELEMENTS_PRODUCT_ASSEMBLY_COMPONENTNAME,
  CUSTOM_BUILDING_ELEMENT_ASSEMBLY_MODAL_MFENAME,
  CUSTOM_BUILDING_ELEMENT_ASSEMBLY_MODAL_COMPONENTNAME,
  BUILDING_ELEMENTS_ASSEMBLY_PRICE_LIST_MFENAME,
  BUILDING_ELEMENTS_ASSEMBLY_PRICE_LIST_COMPONENTNAME
} from '../../../../constants/MFEStateConstants'
import { getIsFeatureEnabledInSet } from '../../../../utils/features'
import {
  FEATURE_BUILDING_ELEMENTS_PRODUCT_ASSEMBLY_WIDGET,
  FEATURE_BUILDING_ELEMENTS_CUSTOM_ASSEMBLY,
  FEATURE_BUILDING_ELEMENTS_PRICING
} from '../../../../constants/features'
import FeaturesHOC from '../../../hocs/FeaturesHOC/FeaturesHOC'


const styles = ({ palette }: TVDTheme) => ({
  listContainer: {
    flex: 1,
    height: 0,
    paddingTop: 40
  },
  descriptionRowContainer: {
    alignItems: 'center',
    cursor: 'default',
    display: 'flex',
    justifyContent: 'space-between',
    marginRight: 2,
    outline: 'none',
    width: '100%',
  },
  disabled: {
    color: palette.dark60
  },
  iconsContainer: {
    lineHeight: 1,
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center'
  },
  subHeader: {
    alignItems: 'center',
    color: palette.dark80,
    cursor: 'default',
    display: 'flex',
    ...theme.typography.classes.bodyDefaultBold
  },
  descriptionCellRight: {
    display: 'flex'
  }
})

type DispatchProps = {|
  dispatchOpenBuildingElementsAssemblyPriceList: () => void, // open assembly price list
  dispatchBuildRenameModal: (Object) => void, // open rename list item modal
  dispatchCopyItem: (Object) => void, // copy list item
  dispatchBuildDeleteModal: (string) => void, // open delete list item modal
  dispatchBuildCreateModal: (string, string) => void, // open create list item modal
  dispatchOpenContentWidgetPriceItem: (string, string) => void, // open priceItem widget
  dispatchOpenBuildingElementsProductAssembly: (string, string) => void, // open assembly widget
  dispatchPostPolling: () => void, // initiates post polling
  dispatchUndoModifyListItem: (TVDListItem) => void, // used to revert back to the old value if patching fails
  dispatchOpenConfirmResetModal: (content: TVDConfirmModalContent, id: string) => void, // open a modal with custom content and type
  dispatchCloseModal: (id: string) => void, // remove a modal from openModals in Redux Store and close it in the UI
  dispatchOpenCustomBuildingElementsModal: (string) => void, // open custom building elements modal
  dispatchOpenContentWidget: (TVDOpenContentWidgetArguments) => void, // Open widget function
  dispatchOpenContentWidgetAssembly: (string, string) => void, // open assembly widget
|}

type MappedProps = {|
  application: string, // current application
  buildingElementsListId: string, // the unique id for the main building elements list
  calculation: string, // calculation id
  activeEdit: boolean, // boolean to tell if edit mode is on
  activeCalculation?: boolean, // flag that indicates if calculation is running
  list: Object,
  isEstimateFrozen: $PropertyType<TVDApplicationStore, 'isEstimateFrozen'>,
  isEstimateLockedToCurrentUser: $PropertyType<TVDApplicationStore, 'isEstimateLockedToCurrentUser'>
|}

type HOCProps = {|
  t: Function, // i18n translation function
  classes: Object, // withStyles styles prop
  sentient: TVDSentient, // Object providing helpers via SentientHOC
  features: TVDFeatureHOCProps, // features HOC with utility functions that can e.g filter disabled features out of an array of objects

|}

type ReceivedProps = {|
  listId: string, // id for the hierarchical list that has connections to Store
|}

type Props = {
  ...DispatchProps,
  ...MappedProps,
  ...HOCProps,
  ...ReceivedProps
}

export class ElementsListContainer extends React.Component<Props> {
  get list(): Element<typeof HierarchicalListContainer> {
    const {
      classes,
      calculation,
      buildingElementsListId,
      activeEdit,
      activeCalculation,
      dispatchPostPolling,
      sentient,
      dispatchUndoModifyListItem,
      isEstimateFrozen,
      isEstimateLockedToCurrentUser
    } = this.props

    return (
      <ErrorBoundary>
        { activeCalculation && <Spinner /> }
        <div className={classes.listContainer}>
          { calculation && <HierarchicalListContainer
            isEstimateFrozen={isEstimateFrozen}
            isEstimateLockedToCurrentUser={isEstimateLockedToCurrentUser}
            displayCheckBoxes
            checkboxDisabled={activeEdit || isEstimateFrozen || !isEstimateLockedToCurrentUser}
            listType={ELEMENTS}
            listId={buildingElementsListId}
            contextMenuItems={(row: Object) => this.getContextMenuItems(row)}
            testId='elementsList'
            editableColumns={[QUANTITY, UNIT_PRICE, TOTAL_PRICE_IN_CURRENCY]}
            editableColumnCheck={(row: TVDListItem): boolean => {
              const rowType = row.type.toUpperCase()
              if (rowType === HEADING) return false
              return (!(row.level < 3 || activeEdit))
            }}
            onModifiedChange={(modifiedListItem: Object) => {
              if (modifiedListItem) {
                const requestParams = [
                  { path: { id: modifiedListItem.id }, body: modifiedListItem.modifiedColumnData },
                  { modifiedListItem, listId: buildingElementsListId }, () => {
                    dispatchPostPolling()
                  },
                  () => dispatchUndoModifyListItem(modifiedListItem)
                ]
                switch (modifiedListItem.type.toLowerCase()) {
                  case PRICEITEM.toLowerCase():
                    patchPriceitemWithIdRequest(...requestParams)
                    break
                  case ASSEMBLY.toLowerCase():
                    patchAssemblyWithIdRequest(...requestParams)
                    break
                  default:
                    break
                }
              }
            }}
            wrappedCellContents={{
              Description: ({
                content, rowIndex, row, rowRef, highlightedRow
              }: Object) => this.getDescriptionRowContent(content, rowIndex, row, rowRef, highlightedRow),
            }}
            didMountCallback={() => {
              sentient.runSetup({
                getScheduleWithEstimateIdRequestDefinitions: { requestArgs: { query: { listType: 'flat' } } }
              })
            }} />}
        </div>
      </ErrorBoundary>
    )
  }

  listHasSelectedRows = () => {
    const { buildingElementsListId, list } = this.props
    const listHasSelectedRows = (typeof findKey(list[buildingElementsListId].listItems, 'selected') !== 'undefined')
    return listHasSelectedRows
  }

  showContextMenu = (row: TVDListItem): boolean => {
    const { isEstimateFrozen, isEstimateLockedToCurrentUser } = this.props
    if (!isEstimateLockedToCurrentUser || isEstimateFrozen) return false
    const listHasSelectedRows = this.listHasSelectedRows()
    const showContextMenu = !(((typeof row.selected === 'undefined' || row.selected === false) && listHasSelectedRows))
    return showContextMenu
  }

  async patchChangeOrderBySelectedRowType(row: TVDListItem, changeOrder: TVDChangeRowOrder): Promise<any> {
    const { buildingElementsListId, dispatchPostPolling, list } = this.props

    let selectedItems = filter(list[buildingElementsListId].listItems, (listItem: Object) => listItem.selected)
    if (selectedItems.length === 0) selectedItems = [{ id: row.id, type: row.type }]
    if (changeOrder === CHANGE_ORDER_FIRST || changeOrder === CHANGE_ORDER_DOWN) selectedItems = selectedItems.reverse()

    try {
      for (const selectedItem of selectedItems) {
        switch (selectedItem.type.toUpperCase()) {
          case PRICEITEM:
            // eslint-disable-next-line no-await-in-loop
            await patchPriceitemWithIdRequest(
              { body: {}, path: { id: selectedItem.id }, query: { changeOrder } },
              {}, null, null, { disableRefreshEstimateLock: true }
            )
            break
          case ASSEMBLY:
            // eslint-disable-next-line no-await-in-loop
            await patchAssemblyWithIdRequest(
              { body: {}, path: { id: selectedItem.id }, query: { changeOrder } },
              {}, null, null, { disableRefreshEstimateLock: true }
            )
            break
          case HEADING:
            // eslint-disable-next-line no-await-in-loop
            await patchHeadingWithIdRequest(
              { body: {}, path: { id: selectedItem.id }, query: { changeOrder } },
              {}, null, null, { disableRefreshEstimateLock: true }
            )
            break
          default:
            console.error(`No patch request found for item type: ${selectedItem.type}`)
        }
      }
    } catch (err) {
      console.error('Patch changeOrder failed', err)
    } finally {
      dispatchPostPolling()
    }
  }

  resetToPricingByType(id: string, type: string) {
    const { dispatchPostPolling } = this.props
    switch (type.toUpperCase()) {
      case ASSEMBLY: {
        patchAssemblyWithIdRequest({ body: {}, path: { id }, query: { resetPricing: true } }, {}, () => { dispatchPostPolling() })
        break
      }
      case PRICEITEM: {
        patchPriceitemWithIdRequest({ body: {}, path: { id }, query: { resetPricing: true } }, {}, () => { dispatchPostPolling() })
        break
      }
      default: {
        console.error(`No reset GAR for type ${type}`)
      }
    }
  }

  getUserModifiedPricingIcon({
    userModifiedPricing,
    type,
    id,
    columnData: { Description }
  }: TVDListItem): React$Element<UserModifiedIcon> {
    const { t } = this.props
    return (
      <UserModifiedIcon
        id={Description}
        isVisible={!!userModifiedPricing}
        text={t('userModifiedInfo._USER_MODIFIED_PRICING_BE')}
        actionCb={() => { this.openConfirmResetModal(id, type) }}
        actionText={t('userModifiedInfo._REVERT_TO_PRICING_')} />
    )
  }

  getDescriptionRowContent(content: string, rowIndex: number, row: TVDListItem, rowRef: TVDRef, highlightedRow: string): React$Element<any> {
    const {
      classes,
      dispatchOpenContentWidgetPriceItem,
      dispatchOpenBuildingElementsProductAssembly,
      activeEdit,
      dispatchOpenContentWidgetAssembly
    } = this.props
    const rowType = row.type.toUpperCase()

    const basicCellContent = () => <DescriptionCell highlighted={row.level > 2 && !activeEdit} text={content} />

    switch (rowType) {
      case ITEM: {
        return (
          <div
            data-testid='listRowCell-Description-text-Item'
            className={classes.descriptionRowContainer}>
            {basicCellContent()}
            {this.getIconsContainer(row, rowRef, highlightedRow)}
          </div>
        )
      }
      case HEADING: {
        return (
          <div data-testid='listRowCell-Description-text-Heading' className={`${classes.descriptionRowContainer} ${classes.subHeader}`}>
            <DescriptionCell text={content} />
            {this.getIconsContainer(row, rowRef, highlightedRow)}
          </div>
        )
      }
      case PRICEITEM:
      case ASSEMBLY: {
        return (
          <div
            data-testid='listRowCell-Description-text-Price-Assembly'
            className={activeEdit ? `${classes.descriptionRowContainer} ${classes.disabled}`
                      : `${classes.descriptionRowContainer}`}
            role='button'
            tabIndex={0}
            onClick={(event: Object) => {
              const { id, columnData: { Description } } = row
              if (activeEdit) return
              if (rowType === PRICEITEM) {
                dispatchOpenContentWidgetPriceItem(Description, id)
              } else if (getIsFeatureEnabledInSet(FEATURE_BUILDING_ELEMENTS_PRODUCT_ASSEMBLY_WIDGET)) {
                  dispatchOpenBuildingElementsProductAssembly(id, Description)
                } else {
                  dispatchOpenContentWidgetAssembly(Description, id)
                }
              event.stopPropagation()
            }}>
            {basicCellContent()}
            {this.getIconsContainer(row, rowRef, highlightedRow)}
          </div>
        )
      }
      default: {
        return (
          <div data-testid='listRowCell-Description-text' className={classes.descriptionRowContainer}>
            {basicCellContent()}
            {this.getIconsContainer(row, rowRef, highlightedRow)}
          </div>
        )
      }
    }
  }

  getContextMenuItems(row: Object, rowRef?: TVDRef, highlightedRow?: string): Array<Object> {
    if (!this.showContextMenu(row)) return []
    const listHasSelectedRows = this.listHasSelectedRows()
    const {
      t,
      list,
      buildingElementsListId,
      dispatchBuildRenameModal,
      dispatchCopyItem,
      dispatchBuildDeleteModal,
      dispatchBuildCreateModal,
      dispatchOpenCustomBuildingElementsModal,
      dispatchOpenBuildingElementsAssemblyPriceList,
      dispatchOpenContentWidget,
      features
    } = this.props
    const { listItems } = list[buildingElementsListId]

    const multipleSelected = filter(listItems, (listItem: Object) => listItem.selected).length > 1

    const isSelected = (type: string): boolean =>
      filter(listItems, (listItem: Object) =>
        listItem.type?.toLowerCase() === type.toLowerCase()
        && listItem.selected).length > 0

    const multipleTypesSelected = (): boolean => {
      if (isSelected(PRICEITEM) && isSelected(HEADING)) {
        return true
      }
      if (isSelected(PRICEITEM) && isSelected(ASSEMBLY)) {
        return true
      }
      if (isSelected(ASSEMBLY) && isSelected(HEADING)) {
        return true
      }
      return false
    }

    const pricingMenuItems = [{
      localizedName: t('buttons._CREATE_FROM_PRICING_'),
      onClick: () => {
        removeHighlight(rowRef, highlightedRow)
        if (getIsFeatureEnabledInSet(FEATURE_BUILDING_ELEMENTS_PRICING)) {
          dispatchOpenBuildingElementsAssemblyPriceList()
        } else {
          dispatchOpenContentWidget({
            widgetId: row.id,
            widgetType: 'CREATE_FROM_PRICING',
            contentProps: {
              resourceId: row.id,
              resourceListId: buildingElementsListId
            }
          })
        }
      },
      testId: 'ContextMenuItem-create_from_pricing'
    }]

    const classificationItems = features.getEnabledFeatures([{
      localizedName: t('buttons._CREATE_FROM_PRICING_'),
      onClick: () => {
        removeHighlight(rowRef, highlightedRow)
        if (getIsFeatureEnabledInSet(FEATURE_BUILDING_ELEMENTS_PRICING)) {
          dispatchOpenBuildingElementsAssemblyPriceList()
        } else {
          dispatchOpenContentWidget({
            widgetId: row.id,
            widgetType: 'CREATE_FROM_PRICING',
            contentProps: {
              resourceId: row.id,
              resourceListId: buildingElementsListId
            }
          })
        }
      },
      testId: 'ContextMenuItem-create_from_pricing'
    }, {
      localizedName: t('buttons._CREATE_ITEM_'),
      onClick: () => {
        dispatchBuildCreateModal(ITEM.toLowerCase(), row.id)
        removeHighlight(rowRef, highlightedRow)
      },
      testId: 'ContextMenuItem-create_item'
    }, {
      localizedName: t('buttons._CREATE_HEADING_'),
      onClick: () => {
        dispatchBuildCreateModal(HEADING.toLowerCase(), row.id)
        removeHighlight(rowRef, highlightedRow)
      },
      testId: 'ContextMenuItem-create_heading'
    }, {
      featureName: FEATURE_BUILDING_ELEMENTS_CUSTOM_ASSEMBLY,
      localizedName: t('buttons._CREATE_CUSTOM_ASSEMBLY_'),
      onClick: () => {
        dispatchOpenCustomBuildingElementsModal(row.id)
        removeHighlight(rowRef, highlightedRow)
      },
      testId: 'ContextMenuItem-new_create_item'
    }])

    const subMenuIconStyle = { marginRight: 10 }

    const singleSelectedActions = features.getEnabledFeatures([
      {
        localizedName: t('buttons._CREATE_ITEM_'),
        onClick: () => {
          dispatchBuildCreateModal(ITEM.toLowerCase(), row.parentId)
          removeHighlight(rowRef, highlightedRow)
        },
        testId: 'ContextMenuItem-create_item'
      }, {
        localizedName: t('buttons._CREATE_HEADING_'),
        onClick: () => {
          dispatchBuildCreateModal(HEADING.toLowerCase(), row.parentId)
          removeHighlight(rowRef, highlightedRow)
        },
        testId: 'ContextMenuItem-create_heading'
      }, {
        localizedName: t('buttons._RENAME_'),
        onClick: () => {
          dispatchBuildRenameModal(row)
          removeHighlight(rowRef, highlightedRow)
        },
        testId: 'ContextMenuItem-rename'
      }, {
        featureName: FEATURE_BUILDING_ELEMENTS_CUSTOM_ASSEMBLY,
        localizedName: t('buttons._CREATE_CUSTOM_ASSEMBLY_'),
        onClick: () => {
          dispatchOpenCustomBuildingElementsModal(row.type === 'assembly' ? row.parentId : row.id)
          removeHighlight(rowRef, highlightedRow)
        },
        testId: 'ContextMenuItem-new_create_item'
      }
    ])

    const multipleSelectedActions = [
      {
        localizedName: listHasSelectedRows ? t('buttons._MOVE_SELECTED_IN_LIST_') : t('buttons._MOVE_IN_LIST_'),
        testId: 'ContextMenuItem-move-in-list',
        subMenuItems: [
          {
            localizedName: t('buttons._MOVE_TO_FIRST_'),
            onClick: () => {
              this.patchChangeOrderBySelectedRowType(row, CHANGE_ORDER_FIRST)
              removeHighlight(rowRef, highlightedRow)
            },
            testId: 'ContextMenuItem-move-to-first',
            icon: {
              alignLeft: true,
              name: 'first_page',
              style: { ...subMenuIconStyle, transform: 'rotate(90deg)' },
            }
          },
          {
            localizedName: t('buttons._MOVE_UP_'),
            onClick: () => {
              this.patchChangeOrderBySelectedRowType(row, CHANGE_ORDER_UP)
              removeHighlight(rowRef, highlightedRow)
            },
            testId: 'ContextMenuItem-move-up',
            icon: {
              alignLeft: true,
              name: 'keyboard_arrow_up',
              style: { ...subMenuIconStyle }
            }
          },
          {
            localizedName: t('buttons._MOVE_DOWN_'),
            onClick: () => {
              this.patchChangeOrderBySelectedRowType(row, CHANGE_ORDER_DOWN)
              removeHighlight(rowRef, highlightedRow)
            },
            testId: 'ContextMenuItem-move-down',
            icon: {
              alignLeft: true,
              name: 'keyboard_arrow_down',
              style: { ...subMenuIconStyle }
            }
          },
          {
            localizedName: t('buttons._MOVE_TO_LAST_'),
            onClick: () => {
              this.patchChangeOrderBySelectedRowType(row, CHANGE_ORDER_LAST)
              removeHighlight(rowRef, highlightedRow)
            },
            testId: 'ContextMenuItem-move-to-last',
            icon: {
              alignLeft: true,
              name: 'last_page',
              style: { ...subMenuIconStyle, transform: 'rotate(90deg)' }
            }
          },
        ]
      }, {
        localizedName: listHasSelectedRows ? t('buttons._COPY_SELECTED_') : t('buttons._COPY_'),
        onClick: () => {
          dispatchCopyItem(row)
          removeHighlight(rowRef, highlightedRow)
        },
        testId: 'ContextMenuItem-copy'
      }, {
        localizedName: listHasSelectedRows ? t('buttons._DELETE_MULTIPLE_') : t('buttons._DELETE_'),
        onClick: () => {
          dispatchBuildDeleteModal(row.id)
          removeHighlight(rowRef, highlightedRow)
        },
        testId: 'ContextMenuItem-delete'
      },
    ]

    const revertPricingMenuItem = {
      localizedName: t('userModifiedInfo._REVERT_TO_PRICING_'),
      onClick: () => { this.openConfirmResetModal(row.id, row.type) },
      testId: 'ContextMenuItem-reset'
    }

    const basicMenuItems = [...singleSelectedActions, ...multipleSelectedActions]

    if (row.level < 2) return pricingMenuItems
    if (row.level === 2 && !multipleSelected) return classificationItems
    if (row.level === 2 && multipleSelected) return pricingMenuItems
    if (multipleTypesSelected()) return multipleSelectedActions.slice(1) // hiding "move selected option"
    if (multipleSelected) return multipleSelectedActions
    if (row.userModifiedPricing) { basicMenuItems.push(revertPricingMenuItem) }
    return basicMenuItems
  }

  getIconsContainer(row: TVDListItem, rowRef: TVDRef, highlightedRow: string): React$Element<'div'> | null {
    const { classes, application, activeEdit } = this.props
    const { type, columnData = {}, canGetInfo } = row
    const canHaveUserModifiedPricingIcon = type.toLowerCase() === PRICEITEM.toLowerCase() || type.toLowerCase() === ASSEMBLY.toLowerCase()

    if (activeEdit) return null

    return (
      <div className={classes.iconsContainer} data-visible_on_hover>
        { canHaveUserModifiedPricingIcon && this.getUserModifiedPricingIcon(row)}
        {
          canGetInfo &&
          <InfoPopover
            id={`${row.id}-InfoPopover`}
            application={application}
            infoData={{
              id: row.id,
              type: BUILDING_ELEMENTS_SCHEDULE,
              propertyName: row.id,
              heading: row.columnData.Description
            }}
            resourceId={row.id} />
        }
        {
          this.showContextMenu(row) &&
          <HamburgerMenu
            id={columnData.Description}
            items={this.getContextMenuItems(row, rowRef, highlightedRow)}
            onToggleChange={() => rowRef.current && rowRef.current.classList.add(highlightedRow)}
            onToggleClose={() => rowRef.current && rowRef.current.classList.remove(highlightedRow)} />
        }
      </div>
    )
  }

  openConfirmResetModal(id: string, type: string) {
    const { dispatchOpenConfirmResetModal, dispatchCloseModal, t } = this.props
    const modalId = `confirm-reset-${id}`
    const content: TVDConfirmModalContent = {
      saveButtonText: 'buttons._RESET_TO_PRICING_',
      message: `${t('listItemDialog._RESET_TO_PRICING_CONFIRM')}\n\n${t('listItemDialog._RESET_TO_PRICING_CONFIRM_EXPLANATION')}`,
      type: CONFIRMATION_MODAL,
      onSave: () => { this.resetToPricingByType(id, type) },
      onClose: () => { dispatchCloseModal(modalId) }
    }
    dispatchOpenConfirmResetModal(content, modalId)
  }

  render(): React$Element<any> | null {
    return (
      <>
        { this.list }
      </>
    )
  }
}

const mapStateToProps = ({ app, list }: TVDReduxStore): MappedProps => {
  const {
    application,
    calculation,
    buildingElementsListId,
    activeEdit,
    activeCalculation,
    isEstimateFrozen,
    isEstimateLockedToCurrentUser
  } = app

  return {
    application,
    buildingElementsListId,
    calculation,
    activeEdit,
    activeCalculation,
    list,
    isEstimateFrozen,
    isEstimateLockedToCurrentUser
  }
}

function mapDispatchToProps(dispatch: Function, { listId }: ReceivedProps): Object {
  return {
    dispatchOpenBuildingElementsAssemblyPriceList: () => {
      dispatch(setMFEStates(
        BUILDING_ELEMENTS_ASSEMBLY_PRICE_LIST_MFENAME,
        true,
        BUILDING_ELEMENTS_ASSEMBLY_PRICE_LIST_COMPONENTNAME,
        null
      ))
    },
    dispatchBuildRenameModal: (row: Object) => dispatch(buildRenameModal({ row, listId })),
    dispatchCopyItem: (row: Object) => dispatch(copyItem(row, listId)),
    dispatchBuildDeleteModal: (rowId: string) => dispatch(buildDeleteModal(rowId, listId, '_DELETE_')),
    dispatchBuildMoveModal: (row: Object, multiple: boolean = false) => dispatch(buildMoveModal({ row, listId, multiple })),
    dispatchOpenContentWidget: (openContentWidgetArgs: TVDOpenContentWidgetArguments) => dispatch(openContentWidget(openContentWidgetArgs)),
    dispatchBuildCreateModal: (rowType: string, newId: string) => dispatch(buildCreateModal(rowType, newId, listId)),
    dispatchOpenContentWidgetAssembly: (widgetTitle: string, id: string) => dispatch(openContentWidget({
      widgetType: ASSEMBLY,
      widgetId: id,
      widgetTitle,
      widgetResultBarFormatOptions: { disableRounding: true },
      contentProps: {
        attributesPropertiesStoreId: id,
        itemsListStoreId: id,
        assemblyId: id,
      }
    })),
    dispatchOpenBuildingElementsProductAssembly: (buildingElementAssemblyId: string, buildingElementAssemblyDescription: string) => {
      dispatch(setMFEStates(
        BUILDING_ELEMENTS_PRODUCT_ASSEMBLY_MFENAME,
        true,
        BUILDING_ELEMENTS_PRODUCT_ASSEMBLY_COMPONENTNAME,
        {
          buildingElementAssemblyId,
          buildingElementAssemblyDescription
        }
      ))
    },
    dispatchOpenContentWidgetPriceItem: (widgetTitle: string, id: string) => (
      dispatch(openContentWidget({
        widgetType: PRICEITEM,
        widgetId: id,
        widgetTitle,
        widgetResultBarFormatOptions: { disableRounding: true },
        contentProps: {
          listStoreId: listId,
          propertiesStoreId: id,
          priceItemId: id,
        }
      }))
    ),
    dispatchPostPolling: () => {
      dispatch(postPolling())
    },
    dispatchOpenConfirmResetModal: (content: TVDOpenContentWidgetArguments, modalId: string) => { dispatch(openModal(content, modalId)) },
    dispatchCloseModal: (modalId: string) => { dispatch(closeModal(modalId)) },
    dispatchUndoModifyListItem: (row: TVDListItem) => dispatch(undoModifyListItem({ listId, listItem: row })),
    dispatchOpenCustomBuildingElementsModal: (buildingElementClassificationId: string) => {
      dispatch(setMFEStates(
        CUSTOM_BUILDING_ELEMENT_ASSEMBLY_MODAL_MFENAME,
        true,
        CUSTOM_BUILDING_ELEMENT_ASSEMBLY_MODAL_COMPONENTNAME,
        { buildingElementClassificationId }
      ))
    }
  }
}

const sentientConfig: TVDSentientConfig = {
  getSetupRequestDefinitions: (store: Object, { buildingElementsListId }: Props): TVDGARConfigs =>
    ({
      getScheduleColumnsWithEstimateIdRequestDefinitions: getScheduleColumnsWithEstimateIdRequestDefinitions({
        payload: { listStoreId: buildingElementsListId }
      }),
      getScheduleWithEstimateIdRequestDefinitions: getScheduleWithEstimateIdRequestDefinitions({
        payload: { listStoreId: buildingElementsListId },
        requestArgs: { query: { listType: 'flat' } }
      })
    })
}

export default compose(
  withTranslation('translations'),
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
  FeaturesHOC,
)(SentientHOC(ElementsListContainer, sentientConfig))
