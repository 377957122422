// @flow
// Copyright © 2010–2022 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.

import { assign, cloneDeep, last } from 'lodash'
import { v4 } from 'uuid'
import {
  CLEAR_SELECTED_ACCOUNT,
  SET_APPLICATION,
  SET_ACTIVE_WIDGET_AS_TOPMOST,
  SET_SPACES_ESTIMATE_TYPE,
  SET_SPACES_RESULT_VIEW,
  SET_BUILDING_ID,
  SET_BUILDING_NAME,
  SET_CALCULATION,
  SET_CALCULATION_ACTIVE,
  SET_CALCULATION_COMPLETE,
  SET_ESTIMATE_NAME,
  SET_LANGUAGE_CODE,
  SET_SELECTED_ACCOUNT,
  SET_WIDGET_ACTIVE,
  SET_USER_TYPE,
  STORE_COUNTRIES,
  STORE_MUNICIPALITIES,
  STORE_TYPES_OF_POPULATION_CENTER,
  STORE_LOCATIONS_WITHIN_POPULATION_CENTER,
  SET_PRIMARY_ESTIMATE_FOR_MODULE,
  SET_REAL_ESTATE_ID,
  STORE_PRIMARY_ESTIMATES_FOR_MODULES,
  SET_REAL_ESTATE_NAME,
  CLEAR_PRIMARY_ESTIMATES_FOR_MODULES,
  SET_SITE_ID,
  STORE_MFE_DETAILS,
  SET_IS_ESTIMATE_FROZEN,
  STORE_REAL_ESTATE_MODULE_FEATURES,
  SET_LICENSE_TYPE,
  SET_INDEX_NUMBER_ON_ASSESSMENT_DATE,
  SET_REAL_ESTATE_LAST_UPDATED_TS,
  SET_IS_ESTIMATE_LOCKED_TO_CURRENT_USER,
  UPDATE_POST_POLLING_TS,
  SET_IS_REFRESHING_ACCESS_TOKEN,
  SET_ACTIVE_EDIT,
  SET_IS_PAUSING_ENABLED_FOR_USE_CASE_PROJECT_LICENSES
} from '../actions/app'
import { DATA_GET_MUNICIPALITIES_SUCCESSFUL, DATA_GET_COUNTRIES_SUCCESSFUL } from '../utils/requests/projects'
import { setLocalStorage, getLocalStorage } from '../utils/commonUtils'
import {
  DATA_GET_BUILDINGS_SUCCESSFUL,
  DATA_PATCH_ESTIMATES_WITH_ESTIMATEID_SUCCESSFUL,
  DATA_PUT_ESTIMATES_LOCK_WITH_ESTIMATEID_SUCCESSFUL
} from '../utils/generated-api-requests/estimates'
import {
  DATA_GET_USERS_SELF_LEGAL_CONSENT_IS_UPDATE_REQUIRED_SUCCESSFUL,
} from '../utils/generated-api-requests/users'
import {
  DATA_PUT_ESTIMATE_LOCK_WITH_ESTIMATEID_SUCCESSFUL,
  DATA_PUT_ESTIMATE_LOCK_WITH_ESTIMATEID_FAIL
} from '../utils/generated-api-requests/spaces'
import {
  DATA_GET_REAL_ESTATES_MODULES_WITH_REALESTATEID_SUCCESSFUL
} from '../utils/generated-api-requests/realestate'
import { DATA_POST_POLLING_SUCCESSFUL, DATA_POST_POLLING_START } from '../actions/postPolling'
import { ENABLE_ALL_WIDGETS } from '../actions/widgets'
import { getFeatures } from '../utils/features'
import { FEATURE_SET } from '../constants/envConstants'
import { ACTIVITY_STRUCTURE } from '../constants/contentTypes'
import { supportedLanguageCodes } from '../constants/languageCodes'
import i18n from '../i18n'
import { SPACESCHEDULE, NEWCONSTRUCTION } from '../constants/viewModeConstants'

const initialLanguage = getLocalStorage('TVDLanguageCode') || supportedLanguageCodes.fi
i18n.changeLanguage(initialLanguage)

const initialState: TVDApplicationStore = {
  application: '',
  buildingId: '',
  buildingName: '',
  calculation: '',
  estimateName: '',
  activeEdit: false,
  activeCalculation: false,
  isEstimateLockedToCurrentUser: null,
  isEstimateFrozen: null,
  activeWidget: '',
  activeTab: '',
  buildingElementsListId: v4(),
  spacesListId: v4(),
  activityStructureId: ACTIVITY_STRUCTURE,
  municipalities: {},
  countries: [],
  buildings: [],
  spacesEstimateType: NEWCONSTRUCTION,
  spacesResultView: SPACESCHEDULE,
  features: getFeatures(FEATURE_SET),
  widgetOrder: [],
  isPostPolling: false,
  selectedAccountId: undefined,
  selectedAccountName: undefined,
  languageCode: initialLanguage,
  moduleIds: [],
  userType: undefined,
  legalConsentUpdateRequired: undefined,
  typesOfPopulationCenter: [],
  locationsWithinPopulationCenter: [],
  primaryEstimates: {},
  realEstateName: '',
  realEstateId: '',
  siteId: '',
  MFEDetails: undefined,
  realEstateSimulationResults: [],
  realEstateModuleFeatures: [],
  licenseType: '',
  realEstateLastUpdatedTS: 0,
  postPollingTS: 0,
  indexNumberOnAssessmentDate: undefined,
  isRefreshingAccessToken: false,
  isPausingEnabledForUseCaseProjectLicenses: null
}

export default function applicationReducer(state: TVDApplicationStore = initialState, action: Object = {}): TVDApplicationStore {
  switch (action.type) {
    case SET_APPLICATION: {
      const { application } = action
      const result = assign({}, state)
      result.application = application
      return result
    }
    case SET_SPACES_ESTIMATE_TYPE: {
      const { spacesEstimateType } = action
      const result = assign({}, state)
      result.spacesEstimateType = spacesEstimateType
      return result
    }
    case SET_SPACES_RESULT_VIEW: {
      const { spacesResultView } = action
      const result = assign({}, state)
      result.spacesResultView = spacesResultView
      return result
    }
    case SET_CALCULATION: {
      const {
        payload: {
          calculation,
        }
      } = action
      return {
        ...state,
        calculation,
      }
    }
    case SET_BUILDING_ID: {
      const { buildingId } = action
      setLocalStorage('buildingId', buildingId)
      return {
        ...state,
        buildingId
      }
    }
    case SET_BUILDING_NAME: {
      const { buildingName } = action
      setLocalStorage('buildingName', buildingName)
      return {
        ...state,
        buildingName
      }
    }
    case SET_ESTIMATE_NAME: {
      const { estimateName } = action
      return {
        ...state,
        estimateName
      }
    }

    case SET_WIDGET_ACTIVE: {
      const { widgetId, tab } = action.payload
      return {
        ...state,
        activeWidget: widgetId,
        activeTab: tab,
        activeEdit: true,
      }
    }

    case ENABLE_ALL_WIDGETS: {
      return {
        ...state,
        activeWidget: '',
        activeTab: '',
        activeEdit: false
      }
    }

    case SET_CALCULATION_ACTIVE: {
      return {
        ...state,
        activeCalculation: true,
      }
    }

    case SET_CALCULATION_COMPLETE: {
      return {
        ...state,
        activeCalculation: false,
      }
    }

    case DATA_GET_MUNICIPALITIES_SUCCESSFUL: {
      const { payload: { parsedResponse: municipalities } } = action
      return {
        ...state,
        municipalities
      }
    }

    case DATA_GET_COUNTRIES_SUCCESSFUL: {
      const { payload: { parsedResponse: countries } } = action
      return {
        ...state,
        countries
      }
    }

    case DATA_GET_BUILDINGS_SUCCESSFUL: {
      const { payload: { parsedResponse: buildings, ignoreStore } } = action
      if (ignoreStore) return state
      const parsedBuildings = buildings.map((building: Object) => {
        const buildingToReturn = cloneDeep(building)
        buildingToReturn.buildingId = building.id
        delete buildingToReturn.id
        return buildingToReturn
      })
      return {
        ...state,
        buildings: parsedBuildings
      }
    }

    case DATA_PUT_ESTIMATES_LOCK_WITH_ESTIMATEID_SUCCESSFUL:
    case DATA_PUT_ESTIMATE_LOCK_WITH_ESTIMATEID_SUCCESSFUL: {
      const { payload: { disableStoreUpdate } = {} } = action
      if (disableStoreUpdate) return state
      return {
        ...state,
        isEstimateLockedToCurrentUser: true,
      }
    }

    case DATA_PUT_ESTIMATE_LOCK_WITH_ESTIMATEID_FAIL: {
      return {
        ...state,
        isEstimateLockedToCurrentUser: false,
      }
    }

    case SET_ACTIVE_WIDGET_AS_TOPMOST: {
      const { widgetId } = action.payload
      if (last(state.widgetOrder) === widgetId) return state

      const widgetOrder = [...state.widgetOrder]
      if (widgetOrder.includes(widgetId)) widgetOrder.splice(widgetOrder.indexOf(widgetId), 1)
      widgetOrder.push(widgetId) // topmost widget is last in the array

      return {
        ...state, widgetOrder
      }
    }

    case DATA_POST_POLLING_START: {
      return {
        ...state,
        isPostPolling: true,
        activeCalculation: true
      }
    }

    case DATA_POST_POLLING_SUCCESSFUL: {
      return {
        ...state,
        isPostPolling: false,
        activeCalculation: false
      }
    }

    case SET_SELECTED_ACCOUNT: {
      const { payload: { selectedAccount: { localizedName, value } } } = action
      return {
        ...state,
        selectedAccountId: value,
        selectedAccountName: localizedName
      }
    }

    case CLEAR_SELECTED_ACCOUNT: {
      return {
        ...state,
        selectedAccountId: undefined,
        selectedAccountName: undefined
      }
    }

    case SET_LANGUAGE_CODE: {
      const { payload: { languageCode } } = action
      setLocalStorage('TVDLanguageCode', languageCode)
      return {
        ...state,
        languageCode
      }
    }

    case DATA_GET_REAL_ESTATES_MODULES_WITH_REALESTATEID_SUCCESSFUL: {
      const { payload: { parsedResponse } } = action
      const moduleIds = Object.keys(parsedResponse).reduce((reducedModuleIds: number[], indexKey: number | string): number[] => {
        const { moduleId } = parsedResponse[indexKey]
        return moduleId ? [...reducedModuleIds, moduleId] : reducedModuleIds
      }, [])
      return {
        ...state,
        moduleIds
      }
    }
    case SET_USER_TYPE: {
      const { payload: { userType } } = action
      return {
        ...state,
        userType
      }
    }
    case DATA_GET_USERS_SELF_LEGAL_CONSENT_IS_UPDATE_REQUIRED_SUCCESSFUL: {
      const { payload: { parsedResponse: { HALParsedData } } } = action
      return {
        ...state,
        legalConsentUpdateRequired: HALParsedData
      }
    }

    case STORE_COUNTRIES: {
      const { payload: { countries } } = action
      return {
        ...state,
        countries
      }
    }

    case STORE_MUNICIPALITIES: {
      const { payload: { municipalities, countryId } } = action
      return {
        ...state,
        municipalities: {
          ...state.municipalities,
          [countryId]: municipalities
        }
      }
    }

    case STORE_TYPES_OF_POPULATION_CENTER: {
      const { payload: { enums } } = action
      return {
        ...state,
        typesOfPopulationCenter: enums
      }
    }

    case STORE_LOCATIONS_WITHIN_POPULATION_CENTER: {
      const { payload: { enums } } = action
      return {
        ...state,
        locationsWithinPopulationCenter: enums
      }
    }

    case SET_PRIMARY_ESTIMATE_FOR_MODULE: {
      const { payload: { moduleName, estimate } } = action
      return {
        ...state,
        primaryEstimates: {
          ...state.primaryEstimates,
          [moduleName]: estimate
        }
      }
    }

    case STORE_PRIMARY_ESTIMATES_FOR_MODULES: {
      const { payload: { primaryEstimates } } = action
      return {
        ...state,
        primaryEstimates: {
          ...state.primaryEstimates,
          ...primaryEstimates
        }
      }
    }

    case SET_REAL_ESTATE_ID: {
      const { payload: { realEstateId } } = action
      return {
        ...state,
        realEstateId
      }
    }

    case SET_REAL_ESTATE_NAME: {
      const { payload: { realEstateName } } = action
      return {
        ...state,
        realEstateName
      }
    }

    case CLEAR_PRIMARY_ESTIMATES_FOR_MODULES: {
      return {
        ...state,
        primaryEstimates: {}
      }
    }

    case SET_SITE_ID: {
      const { payload: { siteId } } = action
      return {
        ...state,
        siteId
      }
    }

    case DATA_PATCH_ESTIMATES_WITH_ESTIMATEID_SUCCESSFUL: {
      const { payload: { Description, indexNumberOnAssessmentDate } } = action
      if (Description) {
        return {
          ...state,
          estimateName: Description,
          indexNumberOnAssessmentDate
        }
      }
      return state
    }

    case STORE_MFE_DETAILS: {
      const { payload: { MFEDetails } } = action
      return {
        ...state,
        MFEDetails
      }
    }

    case SET_IS_ESTIMATE_FROZEN: {
      const { payload: { isFrozen } } = action
      return {
        ...state,
        isEstimateFrozen: isFrozen
      }
    }

    case STORE_REAL_ESTATE_MODULE_FEATURES: {
      const { payload: { ids } } = action
      return {
        ...state,
        realEstateModuleFeatures: ids
      }
    }

    case SET_LICENSE_TYPE: {
      const { payload: { licenseType } } = action
      return {
        ...state,
        licenseType
      }
    }

    case SET_REAL_ESTATE_LAST_UPDATED_TS: {
      const { payload: { realEstateLastUpdatedTS } } = action
      return {
        ...state,
        realEstateLastUpdatedTS
      }
    }
    case SET_IS_ESTIMATE_LOCKED_TO_CURRENT_USER: {
      const { payload: { isEstimateLockedToCurrentUser } } = action
      return {
        ...state,
        isEstimateLockedToCurrentUser
      }
    }
    case UPDATE_POST_POLLING_TS: {
      const { payload: { postPollingTS } } = action
      return {
        ...state,
        postPollingTS
      }
    }
    case SET_INDEX_NUMBER_ON_ASSESSMENT_DATE: {
      const { payload: { indexNumberOnAssessmentDate } } = action
      return {
        ...state,
        indexNumberOnAssessmentDate
      }
    }
    case SET_IS_REFRESHING_ACCESS_TOKEN: {
      const { payload: { isRefreshingAccessToken } } = action
      return {
        ...state,
        isRefreshingAccessToken
      }
    }
    case SET_ACTIVE_EDIT: {
      const { activeEdit } = action.payload
      return {
        ...state,
        activeEdit,
      }
    }
    case SET_IS_PAUSING_ENABLED_FOR_USE_CASE_PROJECT_LICENSES: {
      const { isPausingEnabledForUseCaseProjectLicenses } = action.payload
      return {
        ...state,
        isPausingEnabledForUseCaseProjectLicenses
      }
    }
    default:
      return state
  }
}
