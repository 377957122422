// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'

import SentientHOC from '../../../../hocs/SentientHOC/SentientHOC'
import DescriptionCell from '../../../../common/lists/common/DescriptionCell/DescriptionCell'
import MatrixListContainer from '../../../MatrixListContainer/MatrixListContainer'

import { RENOVATION_SPACEGROUPS_TAB, matrixListHeaderHeight } from '../../../../../constants/moduleConstants'
import { setSelectedTab } from '../../../../../actions/widgets'
import {
  getRenovationSpaceGroupsWithEstimateIdRequestDefinitions,
  getRenovationSpaceGroupsMeasureTopicsWithEstimateIdRequestDefinitions
} from '../../../../../utils/generated-api-requests/spaces_renovation'

const styles = (): Object => ({
  wrapper: {
    display: 'flex',
    flexGrow: 1,
    height: '100%'
  },
  headerRowContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    marginRight: 2,
    width: '100%',
    outline: 0,
  },
  tablePaddingTop: {
    overflow: 'hidden',
    '& table': {
      overflow: 'visible'
    },
    '& thead > tr': {
      height: matrixListHeaderHeight
    },
  },
})

type HOCProps = {|
  classes: Object, // withStyles classes object
  features: TVDFeatureHOCProps, // features from Store and helper functions from the HOC
  sentient: TVDSentient, // Object providing helpers via SentientHOC
  t: Function, // withTranslation translate function
|}

type ReceivedProps = {|
  disabled: boolean, // flag to disable widget content if other widgets are beign modified
  listStoreId: string, // unique id for the HLC instance in "list" ReduxStore
  widgetId: string, // id of widget
  widgetTab: string, // widget tab name where the component is located in
|}

type MappedProps = {|
  columns: Array<TVDListItemColumn>, // columns for MatrixListContainer
|}

type DispatchProps = {|
  dispatchSetSelectedTab: Function, // set renovation spaces tab as selected
|}

type Props = {|
  ...ReceivedProps,
  ...MappedProps,
  ...DispatchProps,
  ...HOCProps,
|}

export class RenovationSpaceGroups extends Component<Props> {
  componentDidMount() {
    this.props.dispatchSetSelectedTab()
    this.props.sentient.runSetup()
  }

  getWrappedCellContents = (): TVDWrappedCells => {
    const { classes } = this.props
    return {
      Description: ({ content }: TVDWrappedCellCallbackParameters): React$Element<'div'> => (
        <div className={classes.headerRowContainer}>
          <DescriptionCell text={content} />
        </div>
      ),
    }
  }

  getSimpleHierarchicalListColumns = () => {
    const { t } = this.props
    return [
      { propertyName: 'Description', localizedName: t('renovationColumns._DESCRIPTION_'), dataType: 'string' },
      { propertyName: 'AreaTotalM2', localizedName: t('renovationColumns._AREA_TOTAL_'), dataType: 'number' },
    ]
  }

  render(): React$Element<'div'> {
    const {
      classes,
      disabled,
      listStoreId,
      columns,
      widgetId,
      widgetTab,
    } = this.props

    return (
      <div className={classes.wrapper}>
        {
          columns &&
          <MatrixListContainer
            widgetId={widgetId}
            widgetTab={widgetTab}
            listStoreId={listStoreId}
            disabled={disabled}
            wrapperStyleClassName={classes.tablePaddingTop}
            wrappedCellContents={this.getWrappedCellContents()}
            simpleHierarchicalListColumns={this.getSimpleHierarchicalListColumns()}
            matrixColumns={columns} />
        }
      </div>
    )
  }
}

type ReduxState = {|
  +list: Object
|}

const mapStateToProps = ({ list }: ReduxState, { listStoreId }: ReceivedProps): MappedProps => ({
  columns: list[listStoreId]?.columns
})

const mapDispatchToProps = (dispatch: Function, { widgetId }: ReceivedProps): DispatchProps => ({
  dispatchSetSelectedTab: () => { dispatch(setSelectedTab(RENOVATION_SPACEGROUPS_TAB, widgetId)) },
})

const sentientConfig: TVDSentientConfig = {
  getSetupRequestDefinitions: (store: Object, { listStoreId }: Props): TVDGARConfigs => ({
    getRenovationSpaceGroupsWithEstimateIdRequestDefinitions: getRenovationSpaceGroupsWithEstimateIdRequestDefinitions({
      payload: { listStoreId, mergeOptions: { initialListItems: {} } }
    }),
    getRenovationSpaceGroupsMeasureTopicsWithEstimateIdRequestDefinitions: getRenovationSpaceGroupsMeasureTopicsWithEstimateIdRequestDefinitions({
      requestArgs: { listStoreId, query: { embedMeasures: true } }, payload: { listStoreId }
    })
  })
}

export default compose(
  withTranslation('translations'),
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
)(SentientHOC(RenovationSpaceGroups, sentientConfig))
