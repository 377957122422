// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.

export type StorePatchOperationParameterOptions = {
  removeExistingPatchOperationParameters?: boolean, // when storing an operation parameter option, it will  clear all other parameters and set one found in payload as the only one
  removeExistingPatchOperationParametersBeginningWith?: string // removes all existing patch operation parameters under that patch operation that begin with the provided text
}

export type TVDStorePatchOperationParameterOptions = {
  removeExistingPatchOperationParametersBeginningWith?: string, // removes all existing patch operation parameters under that patch operation that begin with the provided text
}

export const STORE_PATCH_OPERATION = 'STORE_PATCH_OPERATION'
export const storePatchOperation = (
  patchOperationStoreId: string,
  patchOperation: TVDPatchOperation,
  storePatchOperationParameterOptions?: StorePatchOperationParameterOptions
) => ({
  type: STORE_PATCH_OPERATION,
  payload: {
    patchOperationStoreId,
    patchOperation,
    storePatchOperationParameterOptions
  }
})

export const STORE_PATCH_OPERATION_PARAMETER = 'STORE_PATCH_OPERATION_PARAMETER'
export const storePatchOperationParameter = (
  patchOperationStoreId: string,
  patchOperationParameterObject: TVDPatchOperationParameterObject,
  storePatchOperationParameterOptions?: TVDStorePatchOperationParameterOptions
) => ({
  type: STORE_PATCH_OPERATION_PARAMETER,
  payload: {
    patchOperationStoreId,
    patchOperationParameterObject,
    storePatchOperationParameterOptions,
  }
})

export const CLEAR_ALL_PATCH_OPERATIONS = 'CLEAR_ALL_PATCH_OPERATIONS'
export const clearAllPatchOperations = () => ({
  type: CLEAR_ALL_PATCH_OPERATIONS,
  payload: {}
})

export const CLEAR_PATCH_OPERATIONS = 'CLEAR_PATCH_OPERATIONS'
export const clearPatchOperations = (patchOperationStoreId: string) => ({
  type: CLEAR_PATCH_OPERATIONS,
  payload: {
    patchOperationStoreId
  }
})

export const CLEAR_PATCH_OPERATION_PARAMETERS = 'CLEAR_PATCH_OPERATION_PARAMETERS'
export const clearPatchOperationParameters = (patchOperationStoreId: string, resourceId: string): TVDAction => ({
  type: CLEAR_PATCH_OPERATION_PARAMETERS,
  payload: {
    patchOperationStoreId,
    resourceId,
  }
})

export const CLEAR_PATCH_OPERATION_WITH_RESOURCE_ID = 'CLEAR_PATCH_OPERATION_WITH_RESOURCE_ID'
export const clearPatchOperationWithResourceId = (patchOperationStoreId: string, patchOperationResourceId: string) => ({
  type: CLEAR_PATCH_OPERATION_WITH_RESOURCE_ID,
  payload: {
    patchOperationStoreId,
    patchOperationResourceId
  }
})

export const CLEAR_PATCH_OPERATION_PARAMETER = 'CLEAR_PATCH_OPERATION_PARAMETER'
export const clearPatchOperationParameter = (
  patchOperationStoreId: string,
  resourceId: string,
  parameterPath: string,
) => ({
  type: CLEAR_PATCH_OPERATION_PARAMETER,
  payload: {
    patchOperationStoreId,
    resourceId,
    parameterPath,
  }
})
