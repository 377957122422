// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.
// SentientHOC documentation: https://tools.haahtela.fi/confluence/display/TPA/SentientHOC

import * as React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { v4 } from 'uuid'
import { merge } from 'lodash'
import { setupSentient, unsetSentient } from '../../../actions/sentients'
import { runGARs } from '../../../utils/GARUtils'

type MapProps = {|
  store: Object, // the ReduxStore that is used for configuring Sentient setup get function from the component that uses this HOC
|}

type MapDispatch = {|
  dispatchSetupSentient: (sentientStoreId: string, setup: TVDSentientSetup) => void, // adds the setup to Store with the key from setup.sentientStoreId
  dispatchUnsetSentient: (sentientStoreId: string) => void, // removes a sentient setup from Store with the key sentientStoreId
|}
type Props = {|
  ...MapDispatch,
  ...MapProps,
|}

type State = {|
  sentientStoreId: string, // generated uuid to be unique Store key for the sentient data in Store
|}

const SentientComponent = (WrappedComponent: React.AbstractComponent<any>, config: TVDSentientConfig) => class extends React.Component<Props, State> {
  state = {
    sentientStoreId: ''
  }

  componentDidMount() {
    this.setState({ sentientStoreId: this.getSentientStoreId() }, () => {
      this.updateSetup()
    })
  }

  componentDidUpdate(prevProps: Object) {
    this.updateOnPropsChange(prevProps)
  }

  componentWillUnmount() {
    const { dispatchUnsetSentient } = this.props
    dispatchUnsetSentient(this.state.sentientStoreId)
  }

  get storeAndProps(): {|
    store: Object,
    props: Object
    |} {
    const {
      store,
      dispatchSetupSentient,
      dispatchUnsetSentient,
      ...originalProps
    } = this.props
    return {
      store,
      props: originalProps
    }
  }

  getSentientStoreId = (): string => {
    const { props } = this.storeAndProps
    const { getSentientStoreId } = config
    if (getSentientStoreId) return getSentientStoreId(props)
    return v4()
  }

  getExcludeFromPostPolling = (): boolean => {
    const { props } = this.storeAndProps
    const { getExcludeFromPostPolling } = config
    if (getExcludeFromPostPolling) return getExcludeFromPostPolling(props)
    return false
  }


  getSetupRequestDefinitions(overwrites?: TVDGARConfigOverwrites = {}): TVDGARConfigs {
    const { getSetupRequestDefinitions } = config
    const { store, props } = this.storeAndProps
    const definitions = getSetupRequestDefinitions(store, props)
    if (Object.keys(definitions).length === 0) return {}
    if (overwrites) {
      const overwriteConfigs = Object.keys(overwrites).reduce((res: Object, overwriteDefinitionKey: string) => {
        if (!definitions[overwriteDefinitionKey]) {
          console.error(`No definition found to overwrite with definition key ${overwriteDefinitionKey}`)
          return res
        }
        return {
          ...res,
          [overwriteDefinitionKey]: merge(definitions[overwriteDefinitionKey], overwrites[overwriteDefinitionKey])
        }
      }, {})
      return {
        ...definitions,
        ...overwriteConfigs
      }
    }
    return definitions
  }

  updateOnPropsChange(prevProps: Props) {
    const { updateOnPropsChange } = config
    if (updateOnPropsChange) {
      const { props } = this.storeAndProps
      const propKeys = Object.keys(updateOnPropsChange(props) || {})
      propKeys.forEach((propKey: string) => {
        // shallow compare only
        if (prevProps[propKey] !== props[propKey]) {
          this.updateSetup()
        }
      })
    }
  }

  updateSetup() {
    const { dispatchSetupSentient } = this.props
    const requestDefinitions = this.getSetupRequestDefinitions()
    if (requestDefinitions && Object.keys(requestDefinitions).length > 0) {
      dispatchSetupSentient(
        this.state.sentientStoreId,
        {
          requestDefinitions,
          excludeFromPostPolling: this.getExcludeFromPostPolling()
        }
      )
    }
  }

  render(): React.Node {
    const { props } = this.storeAndProps
    const newProps = {
      ...props,
      sentient: {
        updateSetup: this.updateSetup.bind(this),
        runSetup: (overwrites: TVDGARConfigOverwrites) => {
          const definitions = this.getSetupRequestDefinitions(overwrites)
          if (Object.keys(definitions).length > 0) {
            runGARs(definitions)
          }
        }
      },
    }
    return <WrappedComponent {...newProps} />
  }
}

const mapStateToProps = (store: Object) => ({ store })

const mapDispatchToProps = (dispatch: Function) => ({
  dispatchSetupSentient: (sentientStoreId: string, setup: TVDSentientSetup) => { dispatch(setupSentient(sentientStoreId, setup)) },
  dispatchUnsetSentient: (sentientStoreId: string) => { dispatch(unsetSentient(sentientStoreId)) },
})

const SentientHOC = compose(connect(mapStateToProps, mapDispatchToProps), SentientComponent)
export default SentientHOC
