// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { loadMFE, registeredMFEs } from '../../../utils/MFEUtils'
import { API_BASE_URL, ALLOW_WITH_CREDENTIALS, FEATURE_SET } from '../../../constants/envConstants'
import { openGenericErrorModal } from '../../../utils/modalUtils'
import { dispatch } from '../../../store'
import { postPolling } from '../../../actions/postPolling'
import { ACTIVITY_STRUCTURE } from '../../../constants/contentTypes'

type Props = {|
  setIsModalOpen: Function, // function to handle modal's open state
  isModalOpen: boolean, // if the modal is open
  selectedIds: number[], // array of wopItemIds of the rows that are selected in WOP schedule
  selectedParentIds: number[], // array of parentIds that are selected in WOP schedule (for multi delete API call)
|}

const SpaceProgramMultiDeleteMFEContainer = ({
  setIsModalOpen,
  isModalOpen,
  selectedIds,
  selectedParentIds
}: Props): React$Element<'div'> => {
  const [isLoadingMFE, setIsLoadingMFE] = useState<boolean | null>(null)
  const {
    realEstateId,
    accessToken,
    haahtelaApiUserToken,
    userId,
    languageCode,
    selectedAccountId,
    activeEstimate: activeSpaceProgramEstimate,
  } = useSelector(({
    activeEstimate,
    app,
    user,
  }: TVDReduxStore): {|
    languageCode: $PropertyType<TVDApplicationStore, 'languageCode'>,
    selectedAccountId: $PropertyType<TVDApplicationStore, 'selectedAccountId'>,
    accessToken: $PropertyType<TVDUserAuthorization, 'accessToken'>,
    haahtelaApiUserToken: $PropertyType<TVDUserClaims, 'haahtelaApiUserToken'>,
    userId: $PropertyType<TVDUserClaims, 'userId'>,
    realEstateId: $PropertyType<TVDApplicationStore, 'realEstateId'>,
    activeEstimate: TVDActiveEstimateStore,
  |} => ({
    languageCode: app.languageCode,
    selectedAccountId: app.selectedAccountId,
    accessToken: user.authorization.accessToken,
    haahtelaApiUserToken: user.claims.haahtelaApiUserToken,
    userId: user.claims.userId,
    realEstateId: app.realEstateId,
    activeEstimate,
  }))

  const updatePostPollingTS = () => {
    dispatch(postPolling({ ignoreExcludeFromPostPollingIds: [ACTIVITY_STRUCTURE] }))
  }
  const registeredSpaceProgramAPIModule = registeredMFEs['space-program-api-module']

  useEffect(() => {
    if (
      languageCode &&
      accessToken &&
      haahtelaApiUserToken &&
      userId &&
      selectedAccountId &&
      realEstateId &&
      activeSpaceProgramEstimate &&
      selectedIds &&
      selectedParentIds
    ) {
      const render = (module: TVDMFEFunctionality): void => {
        const moduleRun = module.run || null
        if (moduleRun) {
          moduleRun(
            'renderMultiDeleteDialog',
            [
              'space-program-multi-delete-dialog',
              {
                APIBaseURL: API_BASE_URL,
                languageCode,
                accessToken,
                haahtelaApiUserToken,
                userId,
                selectedAccountId,
                openGenericErrorModal,
                ALLOW_WITH_CREDENTIALS,
                realEstateId,
                updatePostPollingTS,
                activeEstimate: activeSpaceProgramEstimate,
                selectedIds,
                isModalOpen,
                setIsModalOpen,
                selectedParentIds,
                FEATURE_SET
              }
            ]
          )
        }
      }
      if (registeredSpaceProgramAPIModule && !isLoadingMFE) {
        render(registeredSpaceProgramAPIModule)
      } else if (!isLoadingMFE) {
        setIsLoadingMFE(true)
        loadMFE('space_program', 'space-program-api-module', (module: TVDMFEFunctionality): void => {
          render(module)
          setIsLoadingMFE(false)
        })
      }
    }
  }, [
    languageCode,
    accessToken,
    haahtelaApiUserToken,
    userId,
    selectedAccountId,
    registeredSpaceProgramAPIModule,
    realEstateId,
    activeSpaceProgramEstimate,
    selectedIds,
    isModalOpen,
    setIsModalOpen,
    isLoadingMFE,
    selectedParentIds
  ])
  return (
    <div id='space-program-multi-delete-dialog' />
  )
}

export default SpaceProgramMultiDeleteMFEContainer

