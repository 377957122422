// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.
import React, { Component } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import {
  DAYS_IN_A_WEEK,
  HOURS_IN_A_DAY,
  USEAGE_DEGREE_GOAL,
  WEEKS_IN_A_YEAR
} from '../../../constants/attributes'
import { runGAR } from '../../../utils/GARUtils'
import LabeledInput from '../LabeledInput/LabeledInput'
import ModalForm from '../ModalForm/ModalForm'
import { formatValue } from '../../../utils/listUtils'
import { closeModal } from '../../../actions/modals'
import { refreshUserAccessToken } from '../../../actions/user'

type DispatchProps = {|
  dispatchCloseModal: () => void, // closing modal action with the id given as props
|}

type ReceivedProps = {|
  modalId: string, // id of the modal that the component is rendered in
  properties: Array<TVDSchemaValueProperty>,
  onSaveRequestDefinitions: TVDGARConfig,
  onSaveSuccessfulRequestDefinitions: TVDGARConfig, // GAR definitions to GET row that has been updated
|}

type Props = {|
  ...DispatchProps,
  ...ReceivedProps,
  t: Function, // translate function from withTranslation
|}

type State = {
  properties: TVDSchemaValueProperties,
  propertiesValid: {
    [propertyName: string]: boolean
  },
}

export class PropertyEditor extends Component<Props, State> {
  state = {
    properties: {},
    propertiesValid: {}
  }

  componentDidMount() {
    const { properties } = this.props
    const propertiesObj: TVDSchemaValueProperties = properties.reduce((
      result: TVDSchemaValueProperties,
      property: TVDSchemaValueProperty
    ): TVDSchemaValueProperties => ({
      ...result,
      [property.propertyName]: property
    }), {})
    this.setState({ properties: propertiesObj })
  }

  getLabel = (propertyName: string): string => {
    const { t } = this.props
    switch (propertyName) {
      case HOURS_IN_A_DAY: return t('operatingProfile._OPERATING_TIME_IN_A_DAY_')
      case DAYS_IN_A_WEEK: return t('operatingProfile._OPERATING_TIME_IN_A_WEEK_')
      case WEEKS_IN_A_YEAR: return t('operatingProfile._OPERATING_TIME_IN_A_YEAR_')
      case USEAGE_DEGREE_GOAL: return t('operatingProfile._USEAGE_DEGREE_GOAL_')
      default: {
        console.error(`Could not get translation key for propertyName ${propertyName}`)
        return ''
      }
    }
  }

  getAdornment = (propertyName: string): string => {
    const { t } = this.props
    switch (propertyName) {
      case HOURS_IN_A_DAY: return t('operatingProfile._HOURS_IN_A_DAY_POSTFIX_LONG_')
      case DAYS_IN_A_WEEK: return t('operatingProfile._DAYS_IN_A_WEEK_POSTFIX_LONG_')
      case WEEKS_IN_A_YEAR: return t('operatingProfile._WEEKS_IN_A_YEAR_POSTFIX_LONG_')
      case USEAGE_DEGREE_GOAL: return '%'
      default: {
        console.error(`Could not get adornment with propertyName ${propertyName}`)
        return ''
      }
    }
  }

  getInputs = (): Array<typeof LabeledInput> => {
    const { properties } = this.state
    return Object.keys(properties).map((propertyName: string) => {
      const {
        dataType,
        value,
        unit,
        localizedName
      } = properties[propertyName]
      return (
        <LabeledInput
          key={propertyName}
          id={`${propertyName}-attribute-input`}
          dataType={dataType}
          handleChange={({ currentTarget }: SyntheticKeyboardEvent<any>) => { this.handleUserInput(propertyName, currentTarget.value) }}
          isValidCb={(isValid: boolean) => { this.setValidity(propertyName, isValid) }}
          label={localizedName || this.getLabel(propertyName)}
          value={value}
          helperText={unit || this.getAdornment(propertyName)} />
      )
    })
  }

  handleUserInput = (propertyName: string, value: string) => {
    const { properties } = this.state
    this.setState({
      properties: {
        ...properties,
        [propertyName]: {
          ...properties[propertyName],
          value
        }
      }
    })
  }

  checkValidity = (): boolean => {
    const { propertiesValid } = this.state
    return Object.keys(propertiesValid).every((propertyName: string): boolean => propertiesValid[propertyName])
  }

  setValidity = (propertyName: string, isValid: boolean) => {
    const { propertiesValid } = this.state
    this.setState({ propertiesValid: { ...propertiesValid, [propertyName]: isValid } })
  }

  onSave = () => {
    const { properties } = this.state
    const { onSaveSuccessfulRequestDefinitions, onSaveRequestDefinitions, dispatchCloseModal } = this.props
    const formattedAttributes = Object.keys(properties).reduce((
      result: { [propertyName: string]: string | number },
      propertyName: string
    ) => {
      const { value, dataType }: TVDSchemaValueProperty = properties[propertyName]
      return {
        ...result,
        [propertyName]: formatValue(value, dataType)
      }
    }, {})
    runGAR({
      ...onSaveRequestDefinitions,
      requestArgs: {
        ...onSaveRequestDefinitions.requestArgs,
        body: formattedAttributes
      },
      successCb: () => {
        runGAR({
          ...onSaveSuccessfulRequestDefinitions,
          successCb: () => {
            refreshUserAccessToken(() => {
              dispatchCloseModal()
            })
          }
        })
      }
    })
  }

  render(): React$Element<typeof ModalForm> {
    const { dispatchCloseModal } = this.props
    const { properties } = this.state

    return (
      <ModalForm
        testId='attributes-edit'
        items={Object.keys(properties).length > 0 && this.getInputs()}
        onSave={this.onSave}
        onClose={() => dispatchCloseModal()}
        valid={this.checkValidity()} />
    )
  }
}

const mapDispatchToProps = (dispatch: Function, { modalId }: ReceivedProps): DispatchProps => ({
  dispatchCloseModal: () => { dispatch(closeModal(modalId)) },
})

export default compose(
  withTranslation('translations'),
  connect(null, mapDispatchToProps),
)(PropertyEditor)
