// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { isEmpty } from 'lodash'

import StoryProperties from './StoryProperties/StoryProperties'

import { markAsModified } from '../../../../actions/widgets/'
import { modifyStoryProperty } from '../../../../actions/widgets/designModelWidget'
import { getDesignModelStoriesWithStoryIdRequestDefinitions } from '../../../../utils/generated-api-requests/spaces'
import { commasToDots } from '../../../../utils/commonUtils'
import SentientHOC from '../../../hocs/SentientHOC/SentientHOC'

type HOCProps = {|
  sentient: TVDSentient, // Object providing helpers via SentientHOC
|}

type MappedProps = {|
  storyProperties: Object
|}

type DispatchProps = {|
  closeWidget: () => Object, // close widget action
  dispatchModifyStoryProperty: (propertyName: string, value: number | string) => void,
  dispatchMarkAsModified: () => Object, // Marks the Story to have unsaved changes
|}

type ReceivedProps = {|
  manualFeeding: boolean, // manualFeeding flag
  resourceId: string, // id of story
|}

type Props = {|
  ...HOCProps,
  ...DispatchProps,
  ...MappedProps,
  ...ReceivedProps
|}

export class StoryContainer extends Component<Props> {
  componentDidMount() {
    const { sentient } = this.props
    sentient.runSetup()
  }

  render(): React$Element<any> {
    const {
      dispatchMarkAsModified,
      dispatchModifyStoryProperty,
      storyProperties
    } = this.props

    return (
      <>
        {
          !isEmpty(storyProperties) &&
          <StoryProperties
            onBlur={(propertyName: string, value: string | number) => {
              dispatchModifyStoryProperty(propertyName, commasToDots(value))
              dispatchMarkAsModified()
            }}
            storyProperties={storyProperties} />
        }
      </>
    )
  }
}

type ReduxState = {
  +designModel: Object,
}

function mapStateToProps({ designModel }: ReduxState): MappedProps {
  const { openStory: { properties = {} } = {} } = designModel
  return {
    storyProperties: properties
  }
}

function mapDispatchToProps(dispatch: Function, { resourceId }: Object): Object {
  return {
    dispatchMarkAsModified: () => { dispatch(markAsModified(resourceId)) },
    dispatchModifyStoryProperty: (propertyName: string, value: number | string) => { dispatch(modifyStoryProperty(propertyName, value)) },
  }
}

type SentientProps = {|
  resourceId: string, // storyID
|}

const sentientConfig: TVDSentientConfig = {
  getSetupRequestDefinitions: (store: Object, { resourceId }: SentientProps): TVDGARConfigs =>
    ({
      getDesignModelStoriesWithStoryIdRequestDefinitions: getDesignModelStoriesWithStoryIdRequestDefinitions({
        payload: {},
        requestArgs: { path: { storyId: resourceId } }
      })
    })
}

export default connect(mapStateToProps, mapDispatchToProps)(SentientHOC(StoryContainer, sentientConfig))
