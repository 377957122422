// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.
import {
  AUTH_SERVICE_URL,
  SERVICE_PROVIDER_CLIENT_ID, AUTH_FLOW_TYPE
} from '../constants/envConstants'
import { TVD_TOKEN_USER_TYPE_USER } from '../constants/apiConstants'

export const wso2Oauth2Config = {
  authorizationUrl: process.env.REACT_APP_AUTHORIZATION_URL || '',
  redirectUri: process.env.REACT_APP_REDIRECT_URI || '',
  clientId: SERVICE_PROVIDER_CLIENT_ID,
}

export const authServiceConfig = {
  authServiceUrl: AUTH_SERVICE_URL || '',
}

export const authenticationFlowType = AUTH_FLOW_TYPE

export const getAuthUrlByUserType = (userType: TVDTokenUserType): string => {
  let authUrl
  switch (userType) {
    case TVD_TOKEN_USER_TYPE_USER: authUrl = authServiceConfig.authServiceUrl; break
    default: {
      throw new Error(`Could not determine authUrl with userType of ${userType}`)
    }
  }
  return authUrl
}

export const getAuthURLFromPathname = (): string => {
  const { authServiceUrl } = authServiceConfig
  switch (true) {
    default: return authServiceUrl
  }
}
