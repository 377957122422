// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.

import qs from 'query-string'
import slugify from 'slugify'
import {
  SPACES,
  spacesUrl,
  ELEMENTS, elementsUrl,
  WOP,
  wopUrl,
  LOCAN,
  locanUrl,
  REAL_ESTATES,
  summaryUrl,
  PROFITABILITY,
  profitabilityUrl,
  PROCUREMENT_ELEMENTS,
  PROCUREMENT_ELEMENTS_NEW,
  procurementElementsNewUrl,
  RENOVATION_PROGRAM,
  procurementElementsUrl,
  renovationProgramUrl,
  USAGE_AND_MAINTENANCE,
  usageAndMaintenanceUrl
} from '../constants/moduleConstants'

export const resolveModuleUrl = (appName: string): string => {
  switch (appName) {
    case SPACES:
      return spacesUrl
    case ELEMENTS:
      return elementsUrl
    case WOP:
      return wopUrl
    case LOCAN:
      return locanUrl
    case REAL_ESTATES:
      return summaryUrl
    case PROFITABILITY:
      return profitabilityUrl
    case PROCUREMENT_ELEMENTS:
      return procurementElementsUrl
    case RENOVATION_PROGRAM:
      return renovationProgramUrl
    case USAGE_AND_MAINTENANCE:
      return usageAndMaintenanceUrl
    case PROCUREMENT_ELEMENTS_NEW:
      return procurementElementsNewUrl
    default:
      return '/'
  }
}

export const createQueryParamsString = (paramsObj?: Object): string => {
  if (!paramsObj || Object.keys(paramsObj).length === 0) return ''
  return `?${qs.stringify(paramsObj)}`
}

type GetEstimateHistoryObj= {|
  app: string, // application name
  realEstateName: string, // description of the estimate
  accountId: string, // id of the account the estimate belongs to
  estimateId: string, // id of the estimate
  realEstateId: string, // id of the real estate
|}

export const getEstimateHistoryObj = ({
  app,
  realEstateName,
  accountId,
  estimateId,
  realEstateId
}: GetEstimateHistoryObj): TVDHistoryObj => {
  const pathname = [
    resolveModuleUrl(app).replace('/', ''),
    realEstateName
  ].map((path: string): string => slugify(path)).join('/')
  const search = createQueryParamsString({ accountId, estimateId, realEstateId })

  return { pathname: `/${pathname}`, search }
}

export const getHistoryObj = ({
  app,
  accountId,
  realEstateId
}: Object) => {
  const pathname = [
    resolveModuleUrl(app).replace('/', ''),
  ].map((path: string): string => slugify(path)).join('/')
  const search = createQueryParamsString({ accountId, realEstateId })
  return { pathname: `/${pathname}`, search }
}

export const getSignOutRouteByPath = (path: string) => {
  let pathname
  switch (true) {
    case path.startsWith('/account-management'): pathname = '/account-management/kirjaudu'; break
    case path.startsWith('/haahtela-admin'): pathname = '/haahtela-admin/kirjaudu'; break
    default: {
      pathname = '/kirjaudu'
    }
  }
  return pathname
}

export const getLastPathPart = (path: string): string => {
  const splitURL = path.split('/')
  return splitURL[splitURL.length - 1]
}
