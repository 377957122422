// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withStyles } from '@material-ui/core'
import { withTranslation } from 'react-i18next'

import HierarchicalListContainer from '../../../HierarchicalListContainer/HierarchicalListContainer'
import HamburgerMenu from '../../../../common/menus/HamburgerMenu/HamburgerMenu'
import DescriptionCell from '../../../../common/lists/common/DescriptionCell/DescriptionCell'

import { postPolling } from '../../../../../actions/postPolling'
import {
  getBuildingTasksScheduleColumnsWithEstimateIdRequest,
  getBuildingTasksScheduleWithEstimateIdRequest,
  postBuildingTasksWithEstimateIdRequest,
  putBuildingTasksWithEstimateIdRequest
} from '../../../../../utils/generated-api-requests/buildingelements'

type HOCProps = {|
  t: Function, // translate function from withTranslations
  classes: Object, // styles object from withStyles
|}

type DispatchProps = {|
  dispatchPostPolling: () => void, // initiates post polling process to update the UI
|}

type Props = {|
  ...HOCProps,
  ...DispatchProps,
  listId: string, // id of building element task data table
  disabled: boolean, // flag to disable data table events,
  taskIds: Array<string>
|}


const styles = () => ({
  rowWrapper: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    marginRight: 2,
    width: '100%'
  }
})

export class BuildingTaskPropertyDataContainer extends Component<Props> {
  get wrappedCellContents(): TVDWrappedCells {
    const { classes } = this.props
    return {
      Description: ({ row, content }: TVDWrappedCellCallbackParameters) => (
        <div className={classes.rowWrapper}>
          <DescriptionCell highlighted text={content} />
          {this.getHamburgerMenu(row)}
        </div>
      )
    }
  }

  getHamburgerMenuItems({ id, level }: TVDListItem): Array<TVDMenuItem> | null {
    const { t, dispatchPostPolling } = this.props
    switch (level) {
      case 2: {
        return [
          {
            localizedName: t('widgets._ADD_AND_REPLACE_HEADER_ROW_TO_ESTIMATE_'),
            onClick: () => {
              putBuildingTasksWithEstimateIdRequest({ body: { selectedIds: [id] } }, {}, () => {
                dispatchPostPolling()
              })
            }
          },
          {
            localizedName: t('widgets._ADD_HEADER_ROW_CHILDREN_TO_ESTIMATE_'),
            onClick: () => {
              postBuildingTasksWithEstimateIdRequest({ body: { selectedIds: [id] } }, {}, () => {
                dispatchPostPolling()
              })
            }
          },
        ]
      }
      case 3: {
        return [
          {
            localizedName: t('widgets._ADD_ROW_TO_ESTIMATE_'),
            onClick: () => {
              postBuildingTasksWithEstimateIdRequest({ body: { selectedIds: [id] } }, {}, () => {
                dispatchPostPolling()
              })
            }
          },
        ]
      }
      default:
        return null
    }
  }

  getHamburgerMenu(row: Object): React$Element<HamburgerMenu> | null {
    const { columnData: { Description: rowDescription } } = row
    const menuItems = this.getHamburgerMenuItems(row)
    return menuItems ? <HamburgerMenu visibleOnHover id={rowDescription} items={menuItems} /> : null
  }

  render(): React$Element<any> {
    const {
      listId, taskIds, disabled
    } = this.props
    return (
      <div>
        <HierarchicalListContainer
          disabled={disabled}
          wrappedCellContents={this.wrappedCellContents}
          didMountCallback={() => {
            getBuildingTasksScheduleColumnsWithEstimateIdRequest({ listStoreId: listId })
            getBuildingTasksScheduleWithEstimateIdRequest(
              { query: { buildingTaskIds: taskIds } },
              { listStoreId: listId, mergeOptions: { openAllListItems: true } }
            )
           }}
          listId={listId} />
      </div>
    )
  }
}

function mapDispatchToProps(dispatch: Function): DispatchProps {
  return {
    dispatchPostPolling: () => dispatch(postPolling({ preventEnableAllWidgets: true }))
  }
}

export default compose(
  connect(null, mapDispatchToProps),
  withTranslation('translations'),
  withStyles(styles)
)(BuildingTaskPropertyDataContainer)
