// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.
import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { includes } from 'lodash'
import { withTranslation } from 'react-i18next'

import AppButton, { type AppButtonProps as AppButtonComponentProps } from '../../common/AppButton/AppButton'

import { applications } from '../../../constants/moduleConstants'

type Props = {|
  t: Function, // translation function
  classes: Object, // classes-object created by withstyles function
  onAppClick: Function, // function executed when clicking a module
  features: TVDFeatureHOCProps, // features from Store and helper functions from the HOC
  availableAppIds: Array<number>, // array indicating which modules are available to user
  displayApps: Array<string>, // array indicating whichapps are available to display to user
  viewMode: string // viewmode after login
|}

const styles = (): Object => ({
  appsButtons: {
    display: 'flex',
    flexDirection: 'row',
  }
})

class AppsList extends Component<Props> {
  render(): React$Element<'div'> {
    const {
      t,
      classes,
      availableAppIds,
      onAppClick,
      displayApps
    } = this.props

    const currentApps = applications.filter((app: TVDAppButton) => includes(displayApps, app.name))

    const appsList = currentApps.map((app: TVDAppButton): React$Element<AppButton> => {
      const props: AppButtonComponentProps = {
        ...app,
        label: t(`apps._${app.name}_`),
        disabled: !includes(availableAppIds, app.id),
        onClick: onAppClick
      }
      return <AppButton {...props} key={app.name} />
    })

    return (
      <div className={classes.appsButtons}>
        { appsList }
      </div>
    )
  }
}

export default withStyles(styles)(withTranslation('translations')(AppsList))
