// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.

import { allFeatureSets, featuresAllowedInTesting } from '../constants/featureSets'
import { FEATURE_SET, API_BASE_URL } from '../constants/envConstants'

export const isAllowedInTesting = (featureName: string): boolean => (
  new URL(API_BASE_URL).hostname === 'api.testing.haahtela.fi' &&
  featuresAllowedInTesting.includes(featureName)
)

export const getIsFeatureDisabled = (featureName: string, featureList: Array<string>) =>
  featureList.filter((featureListName: string) => featureListName === featureName).length > 0 && !isAllowedInTesting(featureName)

export const getEnabledFeatures = (
  list: Array<Object>, // object list that hold featureName and callback
  featureList: Array<string>, // list of disabled features
  key: string = 'featureName' // key that is used to get the featureName
): Array<Object> => {
  const result = []
  list.forEach((obj: Object) => {
    const { onFeatureDisabled, ...restOfObj } = obj
    const featureName = obj[key]
    const isFeatureDisabled = getIsFeatureDisabled(featureName, featureList)
    if (isFeatureDisabled && onFeatureDisabled) {
      result.push(onFeatureDisabled())
    } else if (!isFeatureDisabled || isAllowedInTesting(featureName)) {
      result.push(restOfObj)
    }
  })
  return result
}

export const getFeatures = (featureSetKey: string) => {
  const features: TVDFeatureSettings = allFeatureSets[featureSetKey]
  if (!features) {
    throw new Error(`Exiting: No feature set found with key "${featureSetKey}"`)
  }
  return features
}

export const getIsFeatureEnabledInSet = (featureName: string): boolean => {
  const currentFeatureSet = allFeatureSets[FEATURE_SET]
  if (!currentFeatureSet) {
    console.error(`Could not find any feature sets with ${FEATURE_SET}`)
    return true
  }
  return !currentFeatureSet.disabled.includes(featureName) || isAllowedInTesting(featureName)
}
