// @flow
// Copyright © 2010–2023 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.
import React, { Component, Fragment } from 'react'
import { withStyles } from '@material-ui/core'
import { map, reduce, isEmpty } from 'lodash'

import LabeledInput from '../LabeledInput/LabeledInput'
import ModalForm from '../ModalForm/ModalForm'
import DescriptionCell from '../lists/common/DescriptionCell/DescriptionCell'
import DropDownContainer from '../../containers/DropDownContainer/DropDownContainer'

import { getPricelistItemAssemblyPropertiesWithIdRequest } from '../../../utils/generated-api-requests/buildingelements'
import { formatValue } from '../../../utils/listUtils'

const styles = ({ palette }: TVDTheme): Object => ({
  description: {
    color: palette.defaultText,
    marginBottom: 20,
  },
})

type Props = {|
  classes: Object, // withStyles styles prop
  description: string, // description text above the form and below the modal title
  close: Function, // closing function for the attributes edit
  save: Function, // saving function for the attributes edit
  type: string, // type of the item, e.g assembly
  pricelistItemId: string, // list uuid, used in request arguments
  parentId: string, // uuid of the parent item, used in request arguments
  contentProps: Object, // modal content related properties
|}

type State = {
  attributes: Object,
  attributesValid: Object,
}

export class AttributesEdit extends Component<Props, State> {
  state = {
    attributes: {},
    attributesValid: {}
  }

  componentDidMount() {
    const { contentProps: { modifiedColumnData } } = this.props

    getPricelistItemAssemblyPropertiesWithIdRequest(
      { path: { id: this.props.pricelistItemId } },
      undefined,
      (res: Object) => {
        const attributes = reduce(res, (result: Object, attribute: Object) => {
          const hasModifiedColumnData = Object.keys(modifiedColumnData).includes(attribute.propertyName)
          if (hasModifiedColumnData) {
            attribute.value = modifiedColumnData[attribute.propertyName]
          }
          return { ...result, [attribute.propertyName]: attribute }
        }, {})
        this.setState({ attributes })
      }
    )
  }


  get inputs(): Array<typeof LabeledInput> {
    const { attributes } = this.state

    return map(attributes, (attribute: Object) => (
      attribute.dataType !== 'enum'
        ? <LabeledInput
            key={attribute.propertyName}
            id={`${attribute.propertyName}-attribute-input`}
            dataType={attribute.dataType}
            handleChange={({ currentTarget }: SyntheticKeyboardEvent<any>) => this.handleUserInput(attribute.propertyName, currentTarget.value)}
            isValidCb={(isValid: boolean) => this.setValidity(attribute.propertyName, isValid)}
            adornment={attribute.unit}
            label={attribute.localizedName}
            value={attribute.value} />
        : <DropDownContainer
            title={attribute.localizedName}
            requestDefinition={attribute.enumRequestDefinitions}
            width='L'
            onDropDownChange={(selected: string) => {
            this.handleUserInput(attribute.propertyName, selected)
          }}
            defaultValue={attribute.value}
            value={attribute.value}
            id={`Attribute-${attribute.propertyName}`} />
    ))
  }

  handleUserInput = (propertyName: string, value: string) => (
    this.setState({
      attributes: {
        ...this.state.attributes,
        [propertyName]: {
          ...this.state.attributes[propertyName],
          value
        }
      }
    })
  )

  checkValidity = () => Object.keys(this.state.attributesValid).every((key: string) => this.state.attributesValid[key])

  setValidity = (key: string, isValid: boolean) => {
    this.setState({ attributesValid: { ...this.state.attributesValid, [`${key}Valid`]: isValid } })
  }

  render(): React$Element<any> {
    const {
      classes,
      close,
      save,
      type,
      pricelistItemId,
      parentId,
      description,
    } = this.props

    const { attributes } = this.state

    return (
      <Fragment>
        <div className={classes.description}>
          <DescriptionCell text={description} />
        </div>
        <ModalForm
          testId='attributes-edit'
          items={!isEmpty(attributes) && this.inputs}
          onSave={() => {
            save({
              type,
              data: {
                ...Object
                .keys(attributes)
                .reduce((
                  prev: $PropertyType<State, 'attributes'>,
                  attributeKey: string
                ): $PropertyType<State, 'attributes'> => {
                  const { value, dataType } = attributes[attributeKey]
                  return {
                    ...prev,
                    [attributeKey]: formatValue(value, dataType)
                  }
                }, {}),
                Description: description, // adding description later as it is not editable attribute
              },
              pricelistItemId,
              parentId,
            })
          }}
          onClose={() => close()}
          valid={this.checkValidity()} />
      </Fragment>
    )
  }
}

export default withStyles(styles)(AttributesEdit)
