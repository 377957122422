// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.
import React from 'react'
import { withStyles } from '@material-ui/core'
import MUISlider from '@material-ui/core/Slider'
import InputField from '../InputField/InputField'

const styles = ({ palette }: TVDTheme) => ({
  slider: {
    display: 'flex',
    width: '100%'
  },
  inputFieldWrapper: {
    marginRight: '20px'
  },
  MUISlider: {
    marginRight: '3px',
    display: 'flex',
    flex: 1,
    color: palette.primary80,
    '& [class*="MuiSlider-rail"]': {
      color: palette.dark20
    },
    '& [class*="MuiSlider-thumb"]': {
      width: 11,
      height: 11,
      marginTop: -4.5
    },
    '& [class*="MuiSlider-thumb"][class*="MuiSlider-active"]': {
      width: 16,
      height: 16,
      marginTop: -7
    },
    '& [class*="Mui-disabled"]': {
      color: palette.dark60
    },
    '& [class*="Mui-disabled.MuiSlider-thumb"]': {
      width: 8,
      height: 8,
      marginTop: -3
    },
  },

})

type State = {|
  value: number, // value of slider
|}

type Props = {|
  classes: Object, // withStyles classes object
  value: number, // slider value as integer
  disabled?: boolean, // indicates if component is disabled
  unit: string, // unit to show in the InputField
  id: string, // propertyName or id
  onChange?: (number) => void, // onChange callback
  onChangeCommitted?: (number) => void, // onChangeCommitted callback
|}

export class Slider extends React.Component<Props, State> {
  static defaultProps = {
    onChange: null,
    onChangeCommitted: null,
    disabled: false,
  }
  state = {
    value: this.props.value
  }

  handleInput = (value: number) => {
    const { onChange, onChangeCommitted } = this.props
    if (onChange) onChange(value)
    this.setState({ value }, onChangeCommitted && onChangeCommitted(value))
  }

  handleChange = (mouseEvent: SyntheticMouseEvent<any>, value: number) => {
    this.setState({ value })
  }

  handleCommittedChange = (mouseEvent: SyntheticMouseEvent<any>, value: number) => {
    const { onChangeCommitted } = this.props
    if (onChangeCommitted) { onChangeCommitted(value) }
    this.setState({ value })
  }

  render(): React$Element<'div'> {
    const {
      classes,
      disabled,
      unit,
      id
    } = this.props
    return (
      <div className={classes.slider}>
        <div className={classes.inputFieldWrapper}>
          <InputField
            alignLeft
            id={`sliderInputField-${id}`}
            width={unit ? 50 : 25}
            dataType='integer'
            unit={unit}
            onChange={this.handleInput}
            initialValue={this.state.value}
            disabled={disabled} />
        </div>
        <div className={classes.MUISlider}>
          <MUISlider
            id={`slider-${id}`}
            classes={{ root: classes.MUISlider }}
            value={this.state.value}
            min={0}
            max={100}
            step={1}
            onChange={this.handleChange}
            onChangeCommitted={this.handleCommittedChange}
            disabled={disabled} />
        </div>
      </div>
    )
  }
}

export default withStyles(styles)(Slider)
