// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.
import React from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { withStyles } from '@material-ui/core/styles'
import { compose } from 'redux'
import { withRouter } from 'react-router-dom'
import { colors, typographyClasses } from 'frontend-assets'
import ModuleHeader from '../../common/ModuleHeader/ModuleHeader'
import ResultBarContainer from '../../containers/ResultBar/ResultBarContainer'
import DropdownMenu, { type DropdownMenuProps } from '../../common/menus/DropdownMenu/DropdownMenu'
import SpacesListContainer from '../../containers/list/SpacesList/SpacesListContainer'
import { SPACES } from '../../../constants/moduleConstants'
import ViewHeaderText from '../../common/ViewHeaderText/ViewHeaderText'
import { viewMode, spacesResultViewTypes } from '../../../constants/viewModeConstants'
import {
  SIMULATIONRESULT_CO2_NEW_CONSTRUCTION,
  SIMULATIONRESULT_CO2_RENOVATION
} from '../../../constants/simulationResults'

import { ResultBarHeight } from '../../containers/ResultBar/ResultBar/ResultBar'
import FrozenNotification from '../../common/FrozenNotification/FrozenNotification'
import {
  getEnumWithEnumRequest,
  getSpaceScheduleColumnsWithEstimateIdRequest,
  getSpaceScheduleWithEstimateIdRequest
} from '../../../utils/generated-api-requests/spaces'
import { headerHeight } from '../../../components/common/Header/Header'
import { setSpacesEstimateType, setSpacesResultView } from '../../../actions/app'
import { postPolling } from '../../../actions/postPolling'
import SpacesListMFEContainer from '../../containers/SpacesListMFEContainer/SpacesListMFEContainer'
import {
  importFromSpaceScheduleWidgetMFERootId
} from '../../containers/ImportFromAnotherEstimateWidgetMFEContainer/ImportFromAnotherEstimateWidgetMFEContainer'
import {
  buildingElementsScheduleWidgetMFERootId
} from '../../containers/BuildingElementsScheduleWidgetMFEContainer/BuildingElementsScheduleWidgetMFEContainer'
import { estimateNotesMFERootId } from '../../containers/EstimateNotesMFEContainer/EstimateNotesMFEContainer'
import {
  siteEquipmentProductAssemblyMFERootId
} from '../../containers/SiteEquipmentProductAssemblyMFEContainer/SiteEquipmentProductAssemblyMFEContainer'
import {
  spaceEquipmentProductAssemblyMFERootId
} from '../../containers/SpaceEquipmentProductAssemblyMFEContainer/SpaceEquipmentProductAssemblyMFEContainer'
import {
  spaceSurfaceProductAssemblyMFERootId
} from '../../containers/SpaceSurfaceProductAssemblyMFEContainer/SpaceSurfaceProductAssemblyMFEContainer'
import { spacesLifecycleCO2WidgetMFERootId } from '../../containers/SpacesLifecycleCO2WidgetMFEContainer/SpacesLifecycleCO2WidgetMFEContainer'
// import { FEATURE_SPACES_LIST_MFE } from '../../../constants/features'
// import { FEATURE_SET } from '../../../constants/envConstants'

const styles = ({ palette }: TVDTheme) => ({
  layout: {
    display: 'flex',
    margin: '24px 32px 40px',
  },
  root: {
    paddingTop: headerHeight,
    height: '100%',
    width: '100%',
    background: palette.gray20,
  },
  innerContainer: {
    background: palette.white,
    display: 'flex',
    flexDirection: 'column',
    height: `calc(100% - ${ResultBarHeight}px)`
  },
  dropdownsContainer: {
    display: 'flex',
    width: '100%',
    flex: 1,
  },
  missingSpaces: {
    ...typographyClasses.h1,
    color: colors.dark80,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    paddingBottom: '110px',
    paddingTop: '90px',
    left: `calc(50% + ${90}px)`,
  },
  missingSpacesText: {
    height: 88,
    textAlign: 'center',
    width: '100%'
  },
})

type MappedProps = {|
  spacesListId: string, // id for spaces list
  isEstimateFrozen: $PropertyType<TVDApplicationStore, 'isEstimateFrozen'>, // if estimate is frozen
  isEstimateLockedToCurrentUser: $PropertyType<TVDApplicationStore, 'isEstimateLockedToCurrentUser'>, // if the user owns the lock for the estimate
  disabled: $PropertyType<TVDApplicationStore, 'activeEdit'>, // if save pattern is active
  languageCode: $PropertyType<TVDApplicationStore, 'languageCode'>, // language code that is used in GAR requests to get translated content
  realEstateSimulationResults: $PropertyType<TVDApplicationStore, 'realEstateSimulationResults'>, // a list of enum numbers that correspond to an feature enum
  spacesResultView: string, // spaces result view
  spacesEstimateType: string, // spaces estimate Type
  list: Object
|}
type DispatchProps = {|
  dispatchSetSpacesEstimateType: Function,
  dispatchSetViewType: Function,
  dispatchPostPolling: () => void, // initiating post polling process
|}
type Props = {|
  ...MappedProps,
  ...DispatchProps,
  t: Function, // translate function
  classes: Object, // classes-object generated by withStyles function
|}

type State = {
  spacesBuildingTypeDropdownItems: Array<TVDEnum>,
  spacesShowMenuDropdownItems: Array<TVDEnum>
}

export class Spaces extends React.Component<Props, State> {
  state: State = {
    spacesBuildingTypeDropdownItems: [],
    spacesShowMenuDropdownItems: [],
  }

  componentDidMount() {
    this.getSpacesDropdownItems()
  }

  componentDidUpdate = (prevProps: Props) => {
    const { languageCode } = this.props
    if (prevProps.languageCode !== languageCode) {
      this.getSpacesDropdownItems()
    }
  }

  get viewHeaderText(): React$Element<ViewHeaderText> | null {
    const { spacesEstimateType, t } = this.props
    return spacesEstimateType === viewMode.RENOVATION ?
      <ViewHeaderText>{t('renovation._SPACES_VIEW_HEADER_TEXT_')}</ViewHeaderText> :
      null
  }

  handleBuildingTypeChange = (selection: string): void => {
    const { dispatchSetSpacesEstimateType, dispatchPostPolling } = this.props
    dispatchSetSpacesEstimateType(selection)
    dispatchPostPolling()
  }

  handleViewTypeChange = (selection: string): void => {
    const { dispatchSetViewType, spacesListId } = this.props
    dispatchSetViewType(selection)
    getSpaceScheduleColumnsWithEstimateIdRequest({ listStoreId: spacesListId })
    getSpaceScheduleWithEstimateIdRequest({ query: { listType: 'flat' } }, { listStoreId: spacesListId })
  }


  getDropdowns = (): React$Element<any> => {
    const {
      t,
      disabled,
      spacesEstimateType,
      spacesResultView,
      classes,
      realEstateSimulationResults
    } = this.props

    const {
      spacesBuildingTypeDropdownItems,
      spacesShowMenuDropdownItems
    } = this.state

    const BuildingTypeMenuProps: DropdownMenuProps = {
      items: spacesBuildingTypeDropdownItems,
      onChange: this.handleBuildingTypeChange,
      title: t('dropdowns._ESTIMATE_TYPE_'),
      defaultValue: spacesEstimateType,
      id: 'show',
      disabled,
    }

    let showMenuProps: DropdownMenuProps = {
      items: spacesShowMenuDropdownItems,
      onChange: this.handleViewTypeChange,
      title: t('dropdowns._SHOW_RESULT_'),
      defaultValue: spacesResultView,
      id: 'show',
      disabled,
    }

    if (
      spacesEstimateType === viewMode.NEWCONSTRUCTION &&
      !realEstateSimulationResults.includes(SIMULATIONRESULT_CO2_NEW_CONSTRUCTION)
    ) {
      const newItems = showMenuProps.items.filter((item: TVDMenuItem): boolean =>
        item.value !== spacesResultViewTypes.CARBONFOOTPRINT)
      showMenuProps = {
        ...showMenuProps,
        items: newItems
      }
    }
    if (
      spacesEstimateType === viewMode.RENOVATION &&
      !realEstateSimulationResults.includes(SIMULATIONRESULT_CO2_RENOVATION)
    ) {
      const newItems = showMenuProps.items.filter((item: TVDMenuItem): boolean =>
        item.value !== spacesResultViewTypes.CARBONFOOTPRINT)
      showMenuProps = {
        ...showMenuProps,
        items: newItems
      }
    }

    return (
      <div className={classes.dropdownsContainer}>
        <DropdownMenu allowReSelection {...BuildingTypeMenuProps} />
        <div style={{ paddingLeft: '20px' }}>
          <DropdownMenu allowReSelection {...showMenuProps} />
        </div>
      </div>
    )
  }

  getSpacesDropdownItems = () => {
    getEnumWithEnumRequest(
      { path: { enumParam: 'ESpacesEstimateType' } },
      {},
      (parsedResponse: Array<TVDEnum>) => {
        this.setState({ spacesBuildingTypeDropdownItems: parsedResponse })
      }
    )

    getEnumWithEnumRequest(
      { path: { enumParam: 'ESpacesResultView' } },
      {},
      (parsedResponse: Array<TVDEnum>) => {
        this.setState({ spacesShowMenuDropdownItems: parsedResponse })
      }
    )
  }

  isObjectEmpty = (obj: Object): boolean => {
    if (Object.keys(obj).length === 0) return true
    return false
  }

  isListEmpty = (): boolean => {
    const { list, spacesListId } = this.props
    if (list && list[spacesListId]?.listItems && this.isObjectEmpty(list[spacesListId].listItems)) return true
    return false
  }

  getMissingSpacesNotification = (): React$Element<'div'> => {
    const { classes, t } = this.props
    const topMessage = t('spacesScene.missingSpaces').split('\n')[0]
    const bottomMessage = t('spacesScene.missingSpaces').split('\n')[1]
    return (
      <div className={classes.missingSpaces}>
        <div className={classes.missingSpacesText}>
          <div>{topMessage}</div>
          <div>{bottomMessage}</div>
        </div>
      </div>
    )
  }

  render(): React$Element<any> | null {
    const {
      spacesListId,
      isEstimateFrozen,
      isEstimateLockedToCurrentUser,
      classes
    } = this.props

    // useMFESpacesList is just a boolean to easily toggle between the MFE and older version of the spaces view
    // you can change the value to
    // FEATURE_SET === 'development' && !!localStorage.getItem(FEATURE_SPACES_LIST_MFE)
    // in order to revert allowing showing the MFE version in development env and when user has set correct storage item
    const useMFESpacesList = true

    return useMFESpacesList ?
      (
        <div className={classes.root}>
          <div
            className={classes.innerContainer}>
            <div id={importFromSpaceScheduleWidgetMFERootId} />
            <div id={buildingElementsScheduleWidgetMFERootId} />
            <div id={estimateNotesMFERootId} />
            <div id={siteEquipmentProductAssemblyMFERootId} />
            <div id={spacesLifecycleCO2WidgetMFERootId} />
            <div id={spaceEquipmentProductAssemblyMFERootId} />
            <div id={spaceSurfaceProductAssemblyMFERootId} />
            <ModuleHeader />
            <FrozenNotification disabled={!isEstimateFrozen || !isEstimateLockedToCurrentUser} />
            <SpacesListMFEContainer />
          </div>
        </div>
      ) : (
        <div className={classes.root}>
          <div
            className={classes.innerContainer}>
            <ModuleHeader />
            <FrozenNotification disabled={!isEstimateFrozen || !isEstimateLockedToCurrentUser} />
            {/* /!*{this.viewHeaderText}*!/ not needed now will be done in a new ticket(https://tools.haahtela.fi/jira/browse/TOIM-664) */}
            <div className={classes.layout}>
              <div id='actionContainer'>{this.getDropdowns()}</div>
            </div>
            <SpacesListContainer listId={spacesListId} />
            {this.isListEmpty() && this.getMissingSpacesNotification()}
          </div>
          <ResultBarContainer resultBarKey={SPACES} />
        </div>
      )
  }
}

const mapStateToProps = ({ app, list, activeEstimate }: TVDReduxStore): MappedProps => {
  const {
    spacesListId,
    spacesResultView,
    spacesEstimateType,
    activeEdit,
    isEstimateLockedToCurrentUser,
    languageCode,
    realEstateSimulationResults
  } = app
  return {
    spacesListId,
    spacesResultView,
    spacesEstimateType,
    disabled: activeEdit,
    isEstimateFrozen: !!activeEstimate?.frozen,
    isEstimateLockedToCurrentUser,
    languageCode,
    realEstateSimulationResults,
    list,
  }
}

function mapDispatchToProps(dispatch: Function): Object {
  return {
    dispatchSetSpacesEstimateType: (mode: string) => { dispatch(setSpacesEstimateType(mode)) },
    dispatchSetViewType: (mode: string) => { dispatch(setSpacesResultView(mode)) },
    dispatchPostPolling: () => { dispatch(postPolling()) }
  }
}
export default compose(
  withTranslation('translations'),
  withStyles(styles),
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(Spaces)
