// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.

import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

const styles = ({ palette, typography }: TVDTheme): Object => ({
  modal: {
    height: '471px',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden'
  },
  tvdLogo: {
    minWidth: '218px',
    maxWidth: '218px',
    display: 'flex',
    padding: '38px 0 0 29px'
  },
  content: {
    width: '468px',
    height: '200px',
    overflow: 'auto',
    padding: '45px 0 0 29px'
  },
  releaseVersionText: {
    fontFamily: typography.latoBoldItalic,
    fontSize: '18px',
    color: palette.nevada,
    marginBottom: '20px'
  },
  infoText: {
    color: palette.nevada,
    fontSize: '14px',
    fontFamily: typography.fontFamilyBase
  },
  diamondLogo: {
    minWidth: '355px',
    maxWidth: '355px',
    minHeight: '142px',
    maxHeight: '142px',
    marginTop: '60px',
    display: 'flex',
    alignSelf: 'flex-end'
  },
  contactInfo: {
    color: palette.lochmara,
  }
})

type HOCProps = {|
    classes: Object, // created by material ui withStyles HOC
    t: (string) => string // created by 18next withTranslation HOC
|}

type Props = {|
    ...HOCProps,
    releaseVersion: string, // currently running version of the TVD software
|}


export class SubSystemInfoModal extends Component<Props> {
  getTVDLogo = (): React$Element<'img'> => {
    const { classes } = this.props
    return <img className={classes.tvdLogo} src='/assets/images/logo/Haahtela_TVD_logo.svg' alt='TVDLogo' />
  }

  // TODO: return somekind of info text when it's specified (TPYT-1290)
  getInfoText = (): string => ''

  getContent = (): React$Element<any> => {
    const { classes, releaseVersion } = this.props
    return (
      <div className={classes.content}>
        <Typography className={classes.releaseVersionText}>{releaseVersion}</Typography>
        <Typography className={classes.infoText}>{this.getInfoText()}</Typography>
        <Typography className={classes.contactInfo}>www.haahtela.fi</Typography>
        <Typography className={classes.contactInfo}>tuki@haahtela.fi</Typography>
      </div>
    )
  }

  getDiamondLogo = (): React$Element<'img'> => {
    const { classes } = this.props
    return <img className={classes.diamondLogo} src='/assets/images/login/Haahtela_graafinen_elementti.svg' alt='diamondLogo' />
  }

  render(): React$Element<'div'> {
    const { classes } = this.props
    return (
      <div className={classes.modal}>
        {this.getTVDLogo()}
        {this.getContent()}
        {this.getDiamondLogo()}
      </div>
    )
  }
}
export default withTranslation('translations')(withStyles(styles)(SubSystemInfoModal))
