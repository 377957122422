// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.
import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import { colors, typographyClasses } from 'frontend-assets'
import { combineStyleClassNames } from '../../../utils/styleUtils'
import theme from '../../../styles/theme'

const styles = () => ({
  widgetRoot: {
    display: 'flex',
    height: '100%',
    alignItems: 'center'
  },
  snackbarRoot: {
    display: 'flex',
    alignItems: 'center'
  },
  dialogRoot: {
    flexGrow: '1',
  },
  paper: {
    display: 'flex',
    margin: 'auto',
    maxWidth: '500px',
    padding: '8px 16px',
    borderRadius: '0px!important',
    alignItems: 'center',
    boxShadow: 'none',
    gap: 8
  },
  snackbarPaper: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    margin: '6px',
    padding: '8px 16px',
    boxShadow: 'none',
    borderRadius: '0px!important',
    height: 36,
    gap: 8
  },
  multiline: {
    width: '280px',
    maxWidth: '280px',
  },
  messageContainer: {
    ...typographyClasses.bodySmall,
    color: colors.dark120,
    display: 'flex',
    flex: '2',
    flexDirection: 'column'
  },
  title: {
    color: theme.palette.primary120,
    fontSize: '16px',
    fontWeight: '600',
  },
  message: {
    maxHeight: '100px',
    lineHeight: '20px',
    overflow: 'hidden',
  }
})

// required props to render message correctly
export type MessageProps = {|
  message: string, // message text
  type: 'info' | 'info_no_backgroundcolor' | 'success' | 'error' | 'error_no_backgroundcolor', // message type to style message appropriately
  icon?: React$Element<any>, // message icon
  multiline?: boolean, // flag to use message multiline styles
  snackbar?: boolean, // flag to use message snackbar styles
|}

type Props = {
  ...MessageProps,
  classes: Object, // classes object created by withStyles function
  dialog: boolean, // boolean is message displayed in dialog component
  customContent: React$Element<any>, // if message should render custom content
}

export class Message extends Component<Props> {
  getColor = (type: string): string => {
    const BGcolors = {
      info: colors.gray20,
      info_no_backgroundcolor: 'transparent !important',
      success: colors.success10,
      error: colors.primary10,
      error_no_backgroundcolor: 'transparent !important'
    }
    return BGcolors[type] || theme.palette.white
  }

  render(): React$Element<any> {
    const {
      classes,
      dialog,
      type,
      message,
      multiline,
      snackbar,
      icon,
      customContent
    } = this.props


    const rootClass = combineStyleClassNames(
      dialog && classes.dialogRoot,
      (!snackbar && !dialog) && classes.widgetRoot,
      snackbar && classes.snackbarRoot
    )

    const paperStyles = combineStyleClassNames(multiline && classes.multiline, snackbar && classes.snackbarPaper, !snackbar && classes.paper)

    const messageStyles = combineStyleClassNames(classes.message, multiline && classes.multiline)

    return (
      <div className={rootClass}>
        <Paper style={{ backgroundColor: this.getColor(type) }} className={paperStyles}>
          {icon}
          <div className={classes.messageContainer}>
            { message && <div data-testid='message' className={messageStyles}>{message}</div> }
            { customContent && customContent }
          </div>
        </Paper>
      </div>
    )
  }
}

export default withStyles(styles)(Message)
