// @flow
// Copyright © 2010–2022 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.
import React, { useEffect } from 'react'
import { useSelector, useStore } from 'react-redux'
import { useHistory } from 'react-router-dom'
import qs from 'query-string'
import RealEstateManagementMFEContainer from './RealEstateManagementMFEContainer'
import AccountInformation from '../components/scenes/AccountInformation/AccountInformation'
import {
  MAIN_VIEW_KEY,
  MAIN_VIEW_VALUE_ACCOUNT_INFORMATION
} from '../constants/searchParams'
import { createQueryParamsString } from '../utils/urlUtil'
import { setMainViewActiveIndex } from '../actions/view/actions'

const MainViewSwitch = (): React$Element<typeof AccountInformation | typeof RealEstateManagementMFEContainer> => {
  const { mainViewActiveIndex } = useSelector(({ view }: TVDReduxStore): TVDViewStore => view)
  const { dispatch } = useStore()
  const history = useHistory()
  const { location: { search } } = history
  const searchParams = qs.parse(search)

  useEffect(() => {
    const hasAccountInformationSearchParam = searchParams[MAIN_VIEW_KEY] === MAIN_VIEW_VALUE_ACCOUNT_INFORMATION
    switch (true) {
      case mainViewActiveIndex === null && hasAccountInformationSearchParam: {
        dispatch(setMainViewActiveIndex(1))
        break
      }
      case mainViewActiveIndex === null && !hasAccountInformationSearchParam: {
        dispatch(setMainViewActiveIndex(0))
        break
      }
      // user has just clicked to navigate to real estate listing
      case mainViewActiveIndex === 0 && hasAccountInformationSearchParam: {
        const { selectedMainView, accountInformationView, ...restOfSearch } = searchParams
        history.replace({
          pathname: '/',
          search: createQueryParamsString(restOfSearch)
        })
        break
      }
      // user has just clicked to navigate to account information
      case mainViewActiveIndex === 1 && !hasAccountInformationSearchParam: {
        history.replace({
          pathname: '/',
          search: createQueryParamsString({ ...searchParams, [MAIN_VIEW_KEY]: MAIN_VIEW_VALUE_ACCOUNT_INFORMATION })
        })
        break
      }
      default: break
    }
  }, [
    history,
    mainViewActiveIndex,
    searchParams,
    dispatch
  ])

  switch (mainViewActiveIndex) {
    case 1: return <AccountInformation />
    case 0:
    default: {
      return <RealEstateManagementMFEContainer />
    }
  }
}

export default MainViewSwitch
