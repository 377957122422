// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.
import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { map } from 'lodash'

import ResultBox, { type ResultBoxProps } from '../ResultBox/ResultBox'

export const ResultBarHeight = 91

const styles = ({ palette }: Object): Object => ({
  resultbar: {
    height: ResultBarHeight,
    display: 'flex',
    borderTop: `1px solid ${palette.gray80}`,
    position: 'relative',
    width: '100%',
    bottom: '0',
    overflow: 'hidden',
    backgroundColor: palette.white,
  }
})

type Props = {|
  results: Array<ResultBoxProps>, // ResultBar results array
  classes: Object, // withStyles classes object
  widgetType: string, // widgetType indicates that its rendered inside a widget and distinguishes rendered resultbars from each other
  resultBarFormatOptions?: TVDResultBarFormatOptions, // assign format options to result bar content
|}

class ResultBar extends Component<Props> {
  static defaultProps = {
    resultBarFormatOptions: undefined
  }

  get resultBoxes(): Array<React$Element<typeof ResultBox>> {
    const {
      results,
      widgetType,
      resultBarFormatOptions
    } = this.props
    const testId = widgetType ? `${widgetType}-resultbox` : 'resultbox'
    return map(results, (result: Object) => (
      <ResultBox
        resultBarFormatOptions={resultBarFormatOptions}
        key={`${testId}-${result.propertyName}`}
        testId={testId}
        result={result} />
    ))
  }

  render(): React$Element<any> {
    const { classes } = this.props
    return (
      <div className={classes.resultbar}>
        {this.resultBoxes}
      </div>
    )
  }
}

export default withStyles(styles)(ResultBar)
