// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.

import axios from 'axios'
import { TVD_HEADER_ACCOUNT_ID } from '../../../constants/apiConstants'
import {
  API_BASE_URL,
  ALLOW_WITH_CREDENTIALS
} from '../../../constants/envConstants'
import { getDefaultRequestHeaders } from './requestUtils'
import { basePath as locanBasePath } from '../../../utils/generated-api-requests/locan'

const executeQuery = async (endpoint: string) => {
  const headers = getDefaultRequestHeaders()
  const { data } = await axios.get(`${API_BASE_URL}/estimates-api${endpoint}`, {
    headers: {
      ...headers
    },
    params: {
      accountId: headers[TVD_HEADER_ACCOUNT_ID],
    },
    ...(ALLOW_WITH_CREDENTIALS ? { withCredentials: true } : {})
  })
  return data
}

const executeLocanQuery = async (endpoint: string) => {
  const headers = getDefaultRequestHeaders()
  const { data } = await axios.get(`${API_BASE_URL}${locanBasePath}${endpoint}`, {
    headers: {
      ...headers
    },
    params: {
      accountId: headers[TVD_HEADER_ACCOUNT_ID],
    },
    ...(ALLOW_WITH_CREDENTIALS ? { withCredentials: true } : {})
  })
  return data
}


export const getEstimatesByBuildingId = async (estimateType: string, buildingId: string) => {
  const estimates = await executeQuery(`/estimates?estimateType=${estimateType}&buildingId=${buildingId}`)
  return estimates
}

export const followEstimatesLink = async (link: string) => {
  const estimate = await executeQuery(link)
  return estimate
}

// The ID is for Finland
export const getMunicipalities = async () => {
  const municipalities = await executeQuery('/enum/Municipalities?category=5ba51fc8-3afe-4fa5-ba1e-d61b05d4cef6')
  return municipalities
}

export const getPostalCode = async (municipalityID: string) => {
  const postalCode = await executeQuery(`/gis/postal-codes?municipalityId=${municipalityID}`)
  return postalCode
}

export const getEstimateLocationById = async (estimateId: string) => {
  const location = await executeLocanQuery(`/estimate/${estimateId}/location`)
  return location
}

