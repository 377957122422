// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.

import React, { Component } from 'react'
import { connect, batch } from 'react-redux'
import { compose } from 'redux'
import { withTranslation } from 'react-i18next'
import { Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { combineStyleClassNames } from '../../../utils/styleUtils'
import { modifyListItem } from '../../../actions/list'
import {
  storePatchOperation,
  type StorePatchOperationParameterOptions,
  storePatchOperationParameter,
  clearPatchOperationParameters
} from '../../../actions/patchOperations'
import { createPatchOperationParameter, isTypeAllowedForPatchOperations } from '../../../utils/patchOperationUtil'
import { setWidgetActive } from '../../../actions/app'
import { TVD_PATCH_OPERATION_TYPE_UPDATE } from '../../../constants/patchOperationConstants'
import PropertiesDataTable from '../../common/Tabs/Properties/PropertiesDataTable/PropertiesDataTable'
import CheckBox from '../../common/CheckBox/CheckBox'
import Slider from '../../common/Slider/Slider'
import FooterButtons from '../widgets/FooterButtons/FooterButtons'
import { closeModal } from '../../../actions/modals'
import {
  getRenovationSpacesMeasureTopicsMeasuresPropertiesWithMeasureIdRequest,
  getRenovationSpaceGroupsMeasureTopicsMeasuresPropertiesWithMeasureIdRequest
} from '../../../utils/generated-api-requests/spaces_renovation'
import { type TVDEditedPropertyValues } from '../MatrixListContainer/MatrixListContainer'
import Spinner from '../../common/Spinner/Spinner'
import { traverseListItems } from '../../../utils/listUtils'
import { RENOVATION_SPACES, RENOVATION_SPACEGROUPS, SPACE, SPACEGROUP } from '../../../constants/contentTypes'

const styles = ({ palette, typography }: TVDTheme): Object => ({
  hidden: {
    visibility: 'hidden'
  },
  row: {
    '&:nth-of-type(2)': {
      borderTop: `1px solid ${palette.geyser}`,
    },
    borderBottom: `1px solid ${palette.geyser}`,
    display: 'flex',
    height: '38px',
    alignItems: 'center',
    paddingLeft: '19px',
  },
  message: {
    height: '20px',
    width: '167px',
    paddingLeft: '32px',
    color: palette.nevada,
    fontFamily: typography.fontFamily,
    fontSize: '16px',
    lineHeight: '20px',
  },
  sliderContainer: {
    display: 'flex',
    width: '70%',
    paddingTop: '23.5px',
    paddingBottom: '27px',
  },
  sliderSingleValue: {
    paddingLeft: '32px',
    width: '100%',
    marginRight: '42px'
  },
  localizedName: {
    color: palette.defaultText,
    marginLeft: '38px',
    fontSize: '16px'
  },
  childLocalizedName: {
    marginLeft: '52px',
    fontSize: '14px'
  },
  half: {
    display: 'flex',
    width: '50%'
  },
  slider: {
    width: '100%',
    marginRight: '42px'
  },
  wrapper: {
    marginTop: '43px',
  },
  spinnerWrapper: {
    height: '75px'
  },
  topText: {
    fontFamily: typography.latoBoldItalic,
    color: palette.manatee,
    marginBottom: '24px',
    marginLeft: '29px'
  },
  footerButtonsWrapper: {
    marginTop: '35px',
    marginBottom: '24px',
    marginRight: '32px',
    display: 'flex',
    justifyContent: 'flex-end'
  }
})
type HOCProps = {|
  t: Function, // translate function provided by HOC withTranslation
  classes: Object, // classes object with classNames provided by withStyles HOC
|}

type ReceivedProps = {|
  parentPatchOperation: TVDPatchOperation, // patch operation performed when measure properties is changed
  columnValue: string, // measure topics columns value
  measureValue: string, // measure topics value
  basePath: string, // the base path used for creating patch operation parameters
  modalId: string, // id of the modal the component is in,
  listStoreId: string, // id the list the component was opened from
  widgetId: string, // id of the widget the component is in
  widgetTab: string, // name of the tab the component is in
  testId: string, // data-testid attribute provided as a prefix for various component's elements e.g cancel and save buttons
  editedPropertyValues: TVDEditedPropertyValues,
  rowType: string, // row's type e.g spaces or spacegroup
  properties: Array<TVDPropertiesListItem>, // unmodified default properties which we use in case we did not open spacegroup or space row
|}

type MappedProps = {|
  listItems: TVDListItems, // list items retrieved from Store with prop listStoreId
|}
type DispatchProps = {|
  dispatchStorePatchOperationParameter: (patchOperationParameterObject: TVDPatchOperationParameterObject) => void, // stores patch operation parameter to Store
  dispatchStorePatchOperation: (patchOperation: TVDPatchOperation, storePatchOperationParameterOptions?: StorePatchOperationParameterOptions) => void, // stores patch operation to Store
  dispatchModifyListItem: (listItemId: string, columnName: string, value: string | number) => void, // sets list item as modified
  dispatchSetWidgetActive: () => void, // sets the widget the component is in as active and enabling save pattern
  dispatchCloseModal: () => void, // closes the modal so it no longer is registered in Store
  dispatchClearPatchOperationParameters: (resourceId: string) => void, // clears patch operations from specific resource
|}

type Props = {|
  ...ReceivedProps,
  ...MappedProps,
  ...DispatchProps,
  ...HOCProps,
|}

type State = {|
  modifiedProperties: {
    [propertyName: string]: string | number
  },
  properties: Array<TVDPropertiesListItem>, // properties for the column which can be defaults or edited ones
  isLoading: boolean, // status telling if we show spinner or not
|}

export class MeasurePropertiesContainer extends Component<Props, State> {
  state = {
    modifiedProperties: {},
    properties: [],
    isLoading: false
  }

  componentDidMount() {
    const {
      measureValue,
      parentPatchOperation,
      columnValue,
      listStoreId,
      rowType,
      properties
    } = this.props
    this.setState({ isLoading: true })
    const requestArguments = {
      path: { measureId: measureValue, topicId: columnValue, id: parentPatchOperation.resourceId },
    }
    const successCb = (measureTopicProperties: Array<TVDPropertiesListItem>) => {
      this.setState({ properties: this.getModifiedProperties(measureTopicProperties), isLoading: false })
    }
    if (rowType !== SPACE.toLowerCase() && rowType !== SPACEGROUP.toLowerCase()) {
      successCb(properties.map((property: TVDPropertiesListItem) => ({ ...property, value: property.defaultValue })))
    } else {
      switch (listStoreId) {
        case RENOVATION_SPACES:
          getRenovationSpacesMeasureTopicsMeasuresPropertiesWithMeasureIdRequest(requestArguments, {}, successCb)
          break
        case RENOVATION_SPACEGROUPS: {
          getRenovationSpaceGroupsMeasureTopicsMeasuresPropertiesWithMeasureIdRequest(requestArguments, {}, successCb)
          break
        }
        default: {
          console.error(`No valid GAR for id ${listStoreId}`)
          break
        }
      }
    }
  }

  getModifiedProperties = (measureTopicProperties: Array<TVDPropertiesListItem>): Array<TVDPropertiesListItem> => {
    const { editedPropertyValues } = this.props
    return measureTopicProperties.map((property: TVDPropertiesListItem) => {
      const modifiedValue = editedPropertyValues[property.propertyName]
      if (typeof modifiedValue !== 'undefined') {
        return { ...property, modifiedValue }
      }
      return property
    })
  }

  onSliderChange = (changedValue: number, propertyName: string) => {
    const { modifiedProperties } = this.state
    this.setState({
      modifiedProperties: {
        ...modifiedProperties,
        [propertyName]: changedValue
      }
    })
  }

  getSlider = (property: TVDPropertiesListItem, propertyValue: number | typeof undefined | boolean | string): React$Element<Slider> => {
    const { propertyName, unit } = property
    const value = propertyValue || 0
    return (<Slider
      // TODO: fix back end to return unit
      unit={unit || '%'}
      key={`${propertyName}-${typeof value === 'undefined' ? 'undefined' : value.toString()}`}
      value={value}
      id={`${propertyName}-slider`}
      onChangeCommitted={(changedValue: number) => { this.onSliderChange(changedValue, propertyName) }}
      onChange={(changedValue: number) => { this.onSliderChange(changedValue, propertyName) }} />
    )
  }

  onCheckboxChange = (
    propertyName: string,
    propertyValue: string | number | typeof undefined | boolean,
    value: string | number | boolean,
    dataType: string,
    isChecked: boolean
  ) => {
    const { modifiedProperties } = this.state
    let newValue
    switch (dataType) {
      case 'number': {
        if (isChecked) {
          newValue = 0
          break
        }
        newValue = (propertyValue === 0 || typeof propertyValue === 'undefined') ? value : propertyValue
        break
      }
      case 'boolean': {
        if (isChecked) {
          newValue = false
          break
        }
        newValue = typeof propertyValue !== 'undefined' ? !propertyValue : !value
        break
      }
      default: {
        console.error(`Could not handle properties dataType of ${dataType} to set value - reverting to defaultValue`)
        break
      }
    }
    if (typeof newValue !== 'undefined') {
      this.setState({
        modifiedProperties: {
          ...modifiedProperties,
          [propertyName]: newValue
        }
      })
    }
  }

  getPropertyValue = (propertyName: string): string | number | boolean | typeof undefined => {
    const { modifiedProperties, properties } = this.state
    const [locallyUnModifiedProperty] = properties.filter((property: TVDPropertiesListItem) => propertyName === property.propertyName)
    const locallyModifiedPropertyValue = modifiedProperties[propertyName]
    if (typeof locallyModifiedPropertyValue !== 'undefined') return locallyModifiedPropertyValue
    return typeof locallyUnModifiedProperty.modifiedValue !== 'undefined' ? locallyUnModifiedProperty.modifiedValue : locallyUnModifiedProperty.value
  }
  getRowSingleValue = (property: TVDPropertiesListItem): React$Element<'div'> => {
    const { classes, t } = this.props
    const {
      propertyName
    } = property
    const propertyValue = this.getPropertyValue(propertyName)
    return (
      <div key={`${property.propertyName}'-slider'}`}>
        <Typography className={classes.message}>{t('renovation._RATIO_')}</Typography>
        <div className={classes.sliderContainer}>
          <div className={classes.sliderSingleValue}>{this.getSlider(property, propertyValue)}</div>
        </div>
      </div>
    )
  }
  getRow = (property: TVDPropertiesListItem, isChildRow?: boolean): React$Element<'div'> => {
    const { classes, t } = this.props
    const {
      localizedName, propertyName, value, dataType
    } = property
    const propertyValue = this.getPropertyValue(propertyName)
    const isBooleanChecked = dataType === 'boolean' && propertyValue
    const isNumberChecked = dataType === 'number' && typeof propertyValue === 'number' && propertyValue > 0
    const isStringChecked = dataType === 'string' && !!propertyValue
    const isChecked = !!(isBooleanChecked || isNumberChecked || isStringChecked)
    return (
      <div
        key={`${property.propertyName}${isChildRow ? '-slider' : ''}`}
        className={combineStyleClassNames(classes.row, isChildRow && classes.childRow)}>
        <div className={classes.half}>
          <div className={combineStyleClassNames(isChildRow && classes.hidden)}>
            <CheckBox checked={isChecked} onChange={() => this.onCheckboxChange(propertyName, propertyValue, value, dataType, isChecked)} />
          </div>
          <span className={combineStyleClassNames(classes.localizedName, isChildRow && classes.childLocalizedName)}>
            {isChildRow ? t('renovation._RATIO_') : localizedName}
          </span>
        </div>
        <div className={classes.half}>
          {isChildRow && <div className={classes.slider}>{this.getSlider(property, propertyValue)}</div>}
        </div>
      </div>
    )
  }

  getRows = (): Array<React$Element<PropertiesDataTable>> => {
    const { properties } = this.state
    return properties.reduce((allRows: Array<React$Element<any>>, property: TVDPropertiesListItem): Array<React$Element<any>> => {
      const { dataType } = property
      const renderChildRow = dataType === 'number'
      if (properties.length === 1) {
        return [this.getRowSingleValue(property)]
      }
      if (renderChildRow) {
        return [
          ...allRows,
          this.getRow(property),
          this.getRow(property, true),
        ]
      }
      return [...allRows, this.getRow(property)]
    }, [])
  }

  shouldClearPatchOperationParameters = (listItemId: string): boolean => {
    const { columnValue, measureValue, listItems } = this.props
    const { modifiedColumnData = {} } = listItems[listItemId]
    return !!modifiedColumnData[columnValue] && modifiedColumnData[columnValue] !== measureValue
  }

  save = () => {
    const {
      parentPatchOperation,
      columnValue,
      measureValue,
      basePath,
      dispatchStorePatchOperationParameter,
      dispatchStorePatchOperation,
      dispatchModifyListItem,
      dispatchSetWidgetActive,
      dispatchCloseModal,
      listItems,
      listStoreId,
      dispatchClearPatchOperationParameters,
    } = this.props
    const { modifiedProperties, properties } = this.state
    const propertiesToSave = Object.keys(modifiedProperties).length === 0 ?
      properties.reduce((result: $PropertyType<State, 'modifiedProperties'>, { propertyName, value }: TVDPropertiesListItem) =>
        ({ ...result, [propertyName]: value }), {}) :
      modifiedProperties
    const { resourceId } = parentPatchOperation
    batch(() => {
      const { resourceId: listItemId } = parentPatchOperation

      const patchOperationParameters = Object.keys(propertiesToSave).map((propertyName: string): TVDPatchOperationParameterObject =>
        createPatchOperationParameter({
          resourceId,
          basePath,
          resourcePath: `${propertyName}`,
          value: propertiesToSave[propertyName],
          operationType: TVD_PATCH_OPERATION_TYPE_UPDATE
        }))

      const traverseCallback = (currentListItemId: string) => {
        if (this.shouldClearPatchOperationParameters(currentListItemId)) {
          dispatchClearPatchOperationParameters(currentListItemId)
        }
        dispatchModifyListItem(currentListItemId, columnValue, measureValue)
        dispatchStorePatchOperation({ ...parentPatchOperation, resourceId: currentListItemId })
        patchOperationParameters.forEach((patchOperationParameter: TVDPatchOperationParameterObject) => {
          dispatchStorePatchOperationParameter({ ...patchOperationParameter, resourceId: currentListItemId })
        })
      }

      dispatchSetWidgetActive()
      traverseListItems(
        listItems,
        (currentItem: TVDListItem): TVDListItem | {} => {
          if (!currentItem.isRemoved) {
            if (isTypeAllowedForPatchOperations(currentItem.type, listStoreId)) {
              traverseCallback(currentItem.id)
            }
            return { [currentItem.id]: currentItem }
          }
          return {}
        },
        listItemId,
        (childListItem: TVDListItem) => {
          if (!childListItem.isRemoved && isTypeAllowedForPatchOperations(childListItem.type, listStoreId)) {
            traverseCallback(childListItem.id)
          }
        }
      )

      dispatchCloseModal()
    })
  }

  cancel = () => {
    const { dispatchCloseModal } = this.props
    dispatchCloseModal()
  }

  getFooterButtons = () => {
    const { t, classes, testId } = this.props
    const { modifiedProperties } = this.state
    const hasModifiedProperties = Object.keys(modifiedProperties).length > 0
    const items = [
      {
        id: `${testId}-Save`,
        onClick: this.save,
        text: t('buttons._SAVE_'),
      },
      {
        id: `${testId}-Cacel`,
        onClick: this.cancel,
        text: !hasModifiedProperties ? t('widgets._CLOSE_') : t('widgets._CANCEL_'),
        variant: 'text'
      }
    ]
    return (
      <div className={classes.footerButtonsWrapper}>
        <FooterButtons items={items} />
      </div>
    )
  }

  render(): React$Element<'div'> {
    const { classes, t } = this.props
    const { isLoading, properties } = this.state
    return (
      <div className={classes.wrapper}>
        {properties.length > 1 &&
        <div className={classes.topText}>{t('renovation._CHOOSE_PARTS_TO_RENEW_')}:</div>
        }
        {isLoading ? <div className={classes.spinnerWrapper}><Spinner /></div> : this.getRows()}
        {this.getFooterButtons()}
      </div>
    )
  }
}

const mapStateToProps = ({ list }: TVDReduxStore, { listStoreId }: ReceivedProps): MappedProps => {
  const { [listStoreId]: { listItems } } = list
  return { listItems }
}

const mapDispatchToProps = (dispatch: Function, {
  modalId,
  listStoreId,
  widgetId,
  widgetTab,
}: ReceivedProps): DispatchProps => ({
  dispatchCloseModal: () => { dispatch(closeModal(modalId)) },
  dispatchModifyListItem: (listItemId: string, columnName: string, value: string | number) => {
    dispatch(modifyListItem({
      columnName,
      listId: listStoreId,
      listItemId,
      value
    }))
  },
  dispatchStorePatchOperation: (patchOperation: TVDPatchOperation, storePatchOperationParameterOptions?: StorePatchOperationParameterOptions) => {
    dispatch(storePatchOperation(listStoreId, patchOperation, storePatchOperationParameterOptions))
  },
  dispatchStorePatchOperationParameter: (patchOperationParameterObject: TVDPatchOperationParameterObject) => {
    dispatch(storePatchOperationParameter(listStoreId, patchOperationParameterObject))
  },
  dispatchSetWidgetActive: () => { dispatch(setWidgetActive(widgetId, widgetTab)) },
  dispatchClearPatchOperationParameters: (resourceId: string) => { dispatch(clearPatchOperationParameters(listStoreId, resourceId)) }
})

export default compose(
  withTranslation('translations'),
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
)(MeasurePropertiesContainer)
