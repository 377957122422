// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.
import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { map } from 'lodash'
import { withTranslation } from 'react-i18next'

import { type AutoCompleteMenuItem } from '../../common/menus/AutoCompleteMenu/AutoCompleteMenu'
import FooterButtons from '../widgets/FooterButtons/FooterButtons'
import AddAccessRightModal from '../../common/AddAccessRightModal/AddAccessRightModal'
import Spinner from '../../common/Spinner/Spinner'

import { DESCRIPTION, ESTIMATE_TYPE, USER_PERMISSIONS_TO_ADD } from '../../../constants/attributes'
import { ESTIMATE } from '../../../constants/contentTypes'
import { closeModal } from '../../../actions/modals'
import { getUsersRequest } from '../../../utils/generated-api-requests/users'
import {
  postEstimatesPermissionsWithEstimateIdRequest,
} from '../../../utils/generated-api-requests/estimates'
import {
  postRealEstatesUserGroupsUsersWithUserIdRequest,
  getRealEstatesUsersWithRealEstateIdRequest
} from '../../../utils/generated-api-requests/realestate'
import { READ, WRITE, MANAGER } from '../../../constants/permissions'
import { type TVDParserParameters } from '../../../utils/parserMapper'

type HOCProps = {|
  t: Function, // translation function
|}

type DispatchProps = {|
  dispatchCloseModal: () => void, // function to close modal
|}

type ReceivedProps = {|
  modalId: string, // id of modal
  onAddAccessRightSuccessful: () => void,
  estimateId?: string, // id of the estimate which the modal was opened from
  isBuildingSettings?: boolean, // flag indicates is the modal is opened from building settings - this is just temporary fix for UI, after we update all modal to have new UI, this will be removed
  defaultIsLoading?: boolean, // flag to make isLoading false - this is just temporary fix for UI, after we update all modal to have new UI, this will be removed
  translatedPermissions?: Array<AutoCompleteMenuItem>, // permission with correct translation
  usersToExclude?: string[], // optional array of ids of users who should be excluded from the list, e.g if they have existing user rights
  realEstateId?: $PropertyType<TVDApplicationStore, 'realEstateId'>, // uuid of a real estate
|}

type Props = {|
  ...HOCProps,
  ...DispatchProps,
  ...ReceivedProps
|}

type State = {|
  usersOptions: Array<AutoCompleteMenuItem>,
  isLoading: boolean, // if the component is still loading or setting up the list items for the first time
  selectedUser: string, // the user id which is selected to assign in certain user group
  selectedUserPermission: TVDSchemaSubjectPermissionEnum | '', // the selected user permission e.g "write"
|}


export class AddAccessRightModalContainer extends React.Component<Props, State> {
  static defaultProps = {
    estimateId: undefined,
    isBuildingSettings: false, // this is just temporary fix for UI, after we update all modal to have new UI, this will be removed
    defaultIsLoading: undefined, // this is just temporary fix for UI, after we update all modal to have new UI, this will be removed
    usersToExclude: []
  }

  state = {
    usersOptions: [],
    isLoading: this.props.defaultIsLoading !== undefined ? this.props.defaultIsLoading : true, // this is just temporary fix for UI, after we update all modal to have new UI, defaultIsLoading will be removed
    selectedUser: '',
    selectedUserPermission: ''
  }

  componentDidMount() {
    const {
      isBuildingSettings,
      realEstateId,
      usersToExclude = AddAccessRightModalContainer.defaultProps.usersToExclude
    } = this.props
    if (isBuildingSettings) {
      getUsersRequest(
        { query: { includeGroups: true } },
        {},
        (users?: Array<TVDUsersResponse>) => {
          const filteredUsers = users?.filter((user: TVDUsersResponse): boolean =>
            !(usersToExclude && usersToExclude.includes(user.id)))

          const userOptions = map(filteredUsers, (user: TVDUser): AutoCompleteMenuItem =>
            ({ name: `${user.firstName} ${user.lastName}`, id: user.id, type: 'user' }))

          this.setState({ usersOptions: userOptions, isLoading: false })
        },
        () => {
          this.setState({ isLoading: false })
        }
      )
    } else if (realEstateId) {
      getRealEstatesUsersWithRealEstateIdRequest(
        {
          path: { realEstateId }
        },
        {},
        ({ HALParsedData }: TVDParserParameters) => {
          const { _embedded: { users } } = HALParsedData
          const filteredUsers = users.filter((user: TVDUsersResponse): boolean =>
            !(usersToExclude && usersToExclude.includes(user.id)))

          const userOptions = filteredUsers.map((user: TVDUsersResponse) =>
            ({ name: `${user.firstName} ${user.lastName}`, id: user.id, type: 'user' }))

          this.setState({ usersOptions: userOptions, isLoading: false })
        }
      )
    }
  }

  handleSave = (): void => {
    const {
      dispatchCloseModal,
      onAddAccessRightSuccessful,
      isBuildingSettings,
      estimateId,
      realEstateId
    } = this.props
    const {
      selectedUser,
      selectedUserPermission
    } = this.state


    const permissionSet = this.getPermissionSet(selectedUserPermission)
    if (isBuildingSettings && realEstateId && selectedUserPermission && selectedUser) {
      postRealEstatesUserGroupsUsersWithUserIdRequest(
        {
          path: {
            realEstateId,
            userGroupId: selectedUserPermission,
            userId: selectedUser
          }
        },
        {},
        () => {
          if (typeof onAddAccessRightSuccessful === 'function') onAddAccessRightSuccessful()
          dispatchCloseModal()
        },
        null
      )
    } else {
      postEstimatesPermissionsWithEstimateIdRequest(
        {
          body: [{ subjectId: selectedUser, permissions: permissionSet }]
        },
        {},
        () => {
          if (typeof onAddAccessRightSuccessful === 'function') onAddAccessRightSuccessful()
          dispatchCloseModal()
        },
        null,
        { estimateId }
      )
    }
  }

  handleClose = (): void => {
    this.props.dispatchCloseModal()
  }

  handleUserChange = (selection: string) => {
    this.setState({ selectedUser: selection })
  }

  handlePermissionChange = (selection: TVDSchemaSubjectPermissionEnum) => {
    this.setState({ selectedUserPermission: selection })
  }

  getColumns = (): Array<TVDListItemColumn> => {
    const { t } = this.props
    return [
      { propertyName: DESCRIPTION, localizedName: t('settings._PROJECT_'), dataType: 'string' },
      { propertyName: ESTIMATE_TYPE, localizedName: t('settings._ESTIMATE_TYPE_'), dataType: 'string' },
      { propertyName: USER_PERMISSIONS_TO_ADD, localizedName: t('settings._USER_RIGHTS_'), dataType: 'enum' },
    ]
  }

  removePermissionFromState = (id: string, permissionsKey: string) => {
    const { [id]: rowToRemove, ...rest } = this.state[permissionsKey]
    this.setState({
      [permissionsKey]: {
        ...rest
      }
    })
  }

  getPermissionSet = (permission: TVDSchemaSubjectPermissionEnum | ''): Array<TVDSchemaSubjectPermissionEnum> => {
    switch (permission) {
      case WRITE: {
        return [WRITE, READ]
      }
      case MANAGER: {
        return [WRITE, READ, MANAGER]
      }
      case READ: {
        return [READ]
      }
      default: {
        return []
      }
    }
  }

  isValid = (): boolean => {
    const {
      selectedUser,
      selectedUserPermission
    } = this.state
    return !!(selectedUser && selectedUserPermission)
  }

  getListItemFromEstimate = (estimate: TVDCalculation): TVDListItem => {
    const { id, buildingId, description } = estimate
    return {
      id,
      parentId: buildingId,
      type: ESTIMATE.toLowerCase(),
      columnData: { Description: description },
      level: 0, // level will be recalculated by HLC
      canGetInfo: false,
    }
  }

  getListItemsFromModule = (modules: {[ moduleName: string ]: TVDCalculation }): TVDListItems => {
    const listItems = Object.keys(modules).reduce((allBuildingEstimates: TVDListItems, moduleName: string): TVDListItems => {
      const estimates = modules[moduleName]
      const estimateListItems = Object.keys(estimates).reduce((moduleEstimates: TVDListItems, moduleEstimateId: string): TVDListItems => {
        const listItem = this.getListItemFromEstimate(estimates[moduleEstimateId])
        return {
          ...moduleEstimates,
          [listItem.id]: listItem
        }
      }, {})
      return estimateListItems
    }, {})
    return listItems
  }


  translatedPermissions = (): Array<TVDMenuItem> => {
    const { t } = this.props
    const assignablePermissions = [WRITE, READ, MANAGER]
    return assignablePermissions.map((permission: string) => ({
      value: permission,
      localizedName: t(`permissions._${permission.toUpperCase()}_`)
    }))
  }


  render(): React$Element<'div'> {
    const {
      t,
      translatedPermissions
    } = this.props
    const { isLoading } = this.state

    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {isLoading && <Spinner />}
        <AddAccessRightModal
          translatedPermissions={translatedPermissions || this.translatedPermissions()}
          isLoading={isLoading}
          options={this.state.usersOptions}
          handlePermissionChange={this.handlePermissionChange}
          handleUserChange={this.handleUserChange} />
        <div style={{ display: 'flex', alignSelf: 'flex-end', margin: '25px 37px 25px 0' }}>
          <FooterButtons items={[
            {
              text: t('calculationInformation._ADD_ACCESS_RIGHT_'),
              onClick: this.handleSave,
              variant: 'contained',
              disabled: !this.isValid()
            },
            {
              text: t('buttons._CANCEL_'),
              onClick: this.handleClose,
              variant: 'text',
            }
          ]} />
        </div>
      </div>
    )
  }
}

function mapDispatchToProps(dispatch: Function, { modalId }: ReceivedProps): DispatchProps {
  return {
    dispatchCloseModal: () => { dispatch(closeModal(modalId)) },
  }
}

export default compose(
  connect(null, mapDispatchToProps),
  withTranslation('translations')
)(AddAccessRightModalContainer)
