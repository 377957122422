// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.

import React from 'react'
import TableCell from '@material-ui/core/TableCell'
import { withStyles } from '@material-ui/core'
import { Resizable } from 'react-resizable'
import { typographyClasses, colors } from 'frontend-assets'
import { combineStyleClassNames } from '../../../../../utils/styleUtils'

type Props = {
  children: string, // content is required
  alignRight?: boolean, // if true, content is aligned right
  classes: Object, // withStyles style classes
  initialWidth?: number, // initial width for the cell,
  style?: Object, // optional table cell styles
  resizeDisabled?: boolean, // if resize is disabled
  propertyName: string, // column property name
  cellClassName?: string, // additional withStyles generated style class for TableCell
  fullWidth?: boolean, // if the component will have full width
}

type State = {
  width: number
}

const styles = (): Object => ({
  root: {
    ...typographyClasses.bodyDefault,
    color: colors.dark80,
    borderBottom: `1px solid ${colors.gray40}`,
    paddingRight: 0,
    padding: '0px',
    top: 0,
    position: 'sticky',
    zIndex: 1,
    backgroundColor: colors.white
  }
})

class ListHeaderCell extends React.Component<Props, State> {
  static defaultProps = {
    alignRight: false,
    initialWidth: undefined,
    style: {},
    resizeDisabled: false,
    colSpan: 1,
    cellClassName: '',
    fullWidth: false
  }
  state = {
    width: this.props.initialWidth !== undefined ? this.props.initialWidth : 100,
  }

  height = 40
  cellRightPadding = 8

  get cell(): React$Element<typeof TableCell> {
    const {
      children,
      alignRight,
      classes,
      style,
      propertyName,
      cellClassName,
      fullWidth
    } = this.props
    const alignment = alignRight ? 'right' : 'left'
    const width = fullWidth ? '100%' : `${this.state.width}px`
    return (
      <TableCell
        data-testid={`hierarchical-list-header-${propertyName}`}
        className={combineStyleClassNames(classes.root, cellClassName)}
        style={{
          width,
          height: this.height,
          minWidth: fullWidth ? '100%' : `${this.state.width + this.cellRightPadding}px`,
          maxWidth: fullWidth ? '100%' : `${this.state.width}px`,
          verticalAlign: 'bottom',
          ...style,
      }}
        variant='head'
        align={alignment}>
        { children }
      </TableCell>
    )
  }

  get resizableCell(): React$Element<typeof Resizable> {
    return (
      <Resizable
        width={this.state.width}
        height={this.height}
        axis='x'
        minConstraints={[100, 100]}
        onResize={this.handleResize}>
        {this.cell}
      </Resizable>
    )
  }

  handleResize = (event: SyntheticEvent<any>, { size }: any) => {
    this.setState({ width: size.width })
  }

  render(): React$Element<typeof Resizable> {
    const {
      resizeDisabled,
      propertyName
    } = this.props
    return (
      resizeDisabled || propertyName === 'checkbox' ? this.cell : this.resizableCell
    )
  }
}

export default withStyles(styles)(ListHeaderCell)
