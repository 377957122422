// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.
import * as React from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'

import Spinner from '../../common/Spinner/Spinner'

import { logout, isAuthenticatedUser } from '../../../actions/user'
import { setLocalStorage } from '../../../utils/commonUtils'
import { SHOW_LANDING_PAGE } from '../../../constants/localPersistence'

type HOCProps = {|
  location: Object // withRouter location object
|}

type Props = {
  ...HOCProps,
  user: Object,
  logout: Function,
}

const styles = ({ palette }: TVDTheme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    backgroundColor: palette.catskillWhite
  },
})

class Logout extends React.Component<Props> {
  componentDidMount() {
    const { user, location: { state: { from } = {} } = {} } = this.props
    if (isAuthenticatedUser(user)) {
      this.props.logout(from)
      setLocalStorage(SHOW_LANDING_PAGE, 'true')
    }
  }

  render(): React.Node {
    return (
      <Spinner />
    )
  }
}

const mapStateToProps = ({ user }: Object): Object => ({ user })

const mapDispatchToProps = (dispatch: Function): Object => ({
  // form is a location object passed from location.state: https://reactrouter.com/web/api/location
  logout: () => { dispatch(logout()) }
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Logout))
