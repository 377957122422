// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.

import React, { type Node } from 'react'
import { withStyles } from '@material-ui/core'
import { compose } from 'redux'
import { connect } from 'react-redux'

import { withTranslation } from 'react-i18next'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import { typographyClasses, colors, borderRadiuses, boxShadows, } from 'frontend-assets'
import ErrorBoundary from '../../common/ErrorBoundary/ErrorBoundary'
import Spinner from '../Spinner/Spinner'
import { combineStyleClassNames } from '../../../utils/styleUtils'

const { borderRadiusLarge } = borderRadiuses
const { boxShadowComponent } = boxShadows

const styles = (): Object => ({
  paper: {
    boxShadow: boxShadowComponent,
    borderRadius: borderRadiusLarge
  },
  content: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flex: '1 1 auto',
    flexDirection: 'column',
    minWidth: 550,
    minHeight: 100,
    padding: '16px 32px 24px 32px'
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    margin: '32px 32px 0 32px'
  },
  title: {
    ...typographyClasses.h3,
    color: colors.dark100
  },
  titleRoot: {
    padding: 0,
  },
  disableBackdrop: {
    backgroundColor: 'transparent'
  },
  disablePadding: {
    padding: 0,
  },
  backdrop: {
    backgroundColor: colors.dialogBackdrop
  }
})

type TitleInterpolation = {
  [key: string]: string
}

type MappedProps = {|
  activeCalculation?: boolean // flag that indicates if calculation is running
|}

type Props = {|
  ...MappedProps,
  classes: Object, // withStyles classes object
  open: boolean, // Indicates if modal is open or not
  title: string, // Modal title
  titleInterpolation?: TitleInterpolation, // Optional modal title interpolation (e.g. add current row description to title)
  onClose: () => void, // closes modal
  t: Function, // translation function
  children?: React$Element<any>, // optional dialog content
  index?: number, // index of the modal which is counted from the number of widgets in store
  theme: Object, // withStyles theme object
  disableEscapeKeyDown?: boolean, // allow closing modal with ESC key
  disableBackdropClick?: boolean, // allow closing modal with clicking the backdrop
  disablePadding?: boolean, // to remove padding from content
  showOwnBackdrop?: boolean, // if we want to use the modals own backdrop vs
|}

type State = {|
  open: boolean, // Boolean that indicates if dialog is open
|}

export class Modal extends React.Component<Props, State> {
  static defaultProps = {
    children: null,
    index: 0,
    disableEscapeKeyDown: true,
    disableBackdropClick: true,
    disablePadding: false,
    titleInterpolation: {},
    activeCalculation: false,
    showOwnBackdrop: false,
  }

  state = {
    open: this.props.open,
  }

  get zIndex(): number {
    const { index, theme: { zIndex } } = this.props
    return zIndex.modal + index
  }

  get header(): React$Element<any> | null {
    const {
      classes,
      t,
      title,
      titleInterpolation
    } = this.props
    if (!title) return null

    return (
      <div className={classes.header}>
        <DialogTitle classes={{ root: classes.titleRoot }} className={classes.title}>
          <div className={classes.title}>{t(title, titleInterpolation)}</div>
        </DialogTitle>
      </div>
    )
  }

  handleClose = (): void => {
    this.setState({
      open: false,
    })
    this.props.onClose()
  }

  render(): Node {
    const {
      classes,
      disableBackdropClick,
      disableEscapeKeyDown,
      disablePadding,
      activeCalculation,
      showOwnBackdrop
    } = this.props

    return (
      <Dialog
        classes={{ paper: classes.paper }}
        style={{ zIndex: this.zIndex }}
        BackdropProps={{
          classes: {
            root: combineStyleClassNames(!showOwnBackdrop && classes.disableBackdrop, showOwnBackdrop && classes.backdrop),
          }
        }}
        data-testid='Dialog'
        disableBackdropClick={disableBackdropClick}
        disableEscapeKeyDown={disableEscapeKeyDown}
        maxWidth={false}
        open={this.state.open}
        onClose={this.handleClose}>

        { activeCalculation && <Spinner /> }
        {this.header}
        <div className={`${classes.content} ${disablePadding ? classes.disablePadding : ''}`}>
          <ErrorBoundary dialog close={this.handleClose}>
            {this.props.children}
          </ErrorBoundary>
        </div>

      </Dialog>
    )
  }
}

const mapStateToProps = ({ app: { activeCalculation } }: TVDReduxStore): MappedProps => ({
  activeCalculation
})

export default compose(
  connect(mapStateToProps),
  withTranslation('translations'),
  withStyles(styles, { withTheme: true })
)(Modal)
