// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.

import React from 'react'
import MUIIcon from '@material-ui/core/Icon'

type Props = {
  iconName: string, // MUI icon names
  fontSize: string, // Font size for the icon
}

const Icon = (props: Props) => (
  <MUIIcon style={{ fontSize: props.fontSize }}>
    {props.iconName}
  </MUIIcon>
)

Icon.defaultProps = {
  fontSize: '48px',
}

export default Icon
