// @flow
// Copyright © 2010–2022 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withTranslation } from 'react-i18next'
import { withStyles } from '@material-ui/core'
import { TextButton } from 'frontend-components'

import { isEmpty } from 'lodash'
import { typographyClasses, colors } from 'frontend-assets'

import HierarchicalListContainer from '../../HierarchicalListContainer/HierarchicalListContainer'
import SentientHOC from '../../../hocs/SentientHOC/SentientHOC'
import DescriptionCell from '../../../common/lists/common/DescriptionCell/DescriptionCell'
import HamburgerMenu from '../../../common/menus/HamburgerMenu/HamburgerMenu'
import DropdownMenu from '../../../common/menus/DropdownMenu/DropdownMenu'
import DropdownSearchMenu from '../../../common/menus/DropdownSearchMenu/DropdownSearchMenu'
import Message from '../../../common/Messages/Message'

import {
  IMPORT_FROM_CALCULATION,
  PROPERTIES_MODAL,
  CONFIRMATION_MODAL,
  HEADING
} from '../../../../constants/contentTypes'
import { importViewModes } from '../../../../constants/viewModeConstants'
import { postPolling } from '../../../../actions/postPolling'
import { buildPropertiesModal, closeModal, openModal } from '../../../../actions/modals'
import { clearList } from '../../../../actions/list'
import {
  getReferenceScheduleWithEstimateIdRequest,
  putScheduleWithEstimateIdRequest,
  postScheduleWithEstimateIdRequest,
  putReferenceScheduleViewWithEstimateIdRequest,
  getReferenceScheduleColumnsWithEstimateIdRequest,
  getReferenceScheduleEstimatesWithEstimateIdRequestDefinitions,
  getReferenceScheduleValuesWithEstimateIdRequestDefinitions,
  getReferenceScheduleIdWithEstimateIdRequest,
  putReferenceScheduleIdWithEstimateIdRequest,
  putEstimatePropertiesWithEstimateIdRequest,
  getReferenceScheduleValuesWithEstimateIdRequest,
  getReferenceScheduleViewWithEstimateIdRequest
} from '../../../../utils/generated-api-requests/buildingelements'
import { getEnumWithEnumRequest } from '../../../../utils/generated-api-requests/spaces'
import { getEstimatesWithEstimateIdRequest } from '../../../../utils/generated-api-requests/estimates'
import { isListItemTypeBold, formatCellContent } from '../../../../utils/listUtils'
import { RESOURCE_CONFLICT_STATUS } from '../../../../utils/requests/request'
import { TOTAL_PRICE_IN_CURRENCY, UNIT_PRICE } from '../../../../constants/attributes'
// $FlowFixMe
import { ReactComponent as CheckSingleSVG } from
  '../../../../../node_modules/frontend-assets/static/assets/images/icons/Check Single.svg'
// $FlowFixMe
import { ReactComponent as InfoTooltipSVG } from
  '../../../../../node_modules/frontend-assets/static/assets/images/icons/Data Table Info tooltip.svg'
// $FlowFixMe
import { ReactComponent as AlertError } from '../../../../../node_modules/frontend-assets/static/assets/images/icons/Alert Error filled.svg'

const styles = (): Object => ({
  importFromCalculation: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    minHeight: '100%',
    height: '100%',
    overflowY: 'auto'
  },
  tools: {
    display: 'flex',
    padding: '16px',
    gap: '8px',
    alignItems: 'center'
  },
  buttonWrapper: {
    display: 'flex',
    padding: '8px'
  },
  message: {
    display: 'flex',
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0 30px'
  },
  messageText: {
    ...typographyClasses.h1,
    color: colors.dark80,
    textAlign: 'center'
  },
  headerRowContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    marginRight: 2,
    width: '100%',
    outline: 0,
    color: colors.primary120
  },
  statusContainer: {
    padding: '14px 0 14px 30px',
  },
  statusIndicator: {
    color: colors.primary120,
    paddingTop: '2px'
  },
  errorIcon: {
    marginRight: 8
  },
  iconContainer: {
    ...typographyClasses.bodySmall,
    color: colors.dark120,
    display: 'flex',
    alignItems: 'center',
    height: 26
  },
  refreshLink: {
    ...typographyClasses.bodySmallSemiBoldUnderline,
    color: colors.primary100,
    cursor: 'pointer',
    marginLeft: '4px',
  }
})

type MappedProps = {|
  isEstimateLockedToCurrentUser: $PropertyType<TVDApplicationStore, 'isEstimateLockedToCurrentUser'>, // flag to indicate if estimate is locked for current user
  selectedAccountId: $PropertyType<TVDApplicationStore, 'selectedAccountId'> // Id of the selected account
|}

type DispatchProps = {|
  dispatchPostPolling: () => void, // initiates post polling
  dispatchOpenPropertiesModal: Function, // function to open properties modal from import building-task properties button
  dispatchOpenAddBuildingElementsModal: Function, // function to open addBuildingElements modal
  dispatchClearList: Function, // clears a list from redux state
|}

type Props = {|
  t: (string) => string, // i18n translation function
  classes: Object, // withStyles classes object
  sentient: TVDSentient, // Object providing helpers via SentientHOC
  ...DispatchProps,
  ...MappedProps
|}

type DropdownContent = {
  HALParsedData: string,
  basePath: string
}

type State = {|
  calculations: Array<TVDMenuItem>, // array of calculationIds
  calculationsLoaded: boolean, // flag to indicate that calculations are loaded and callbacks are completed
  selectedCalculation: DropdownContent, // id of selected calculation
  viewType: DropdownContent, // type of calculation view
  importedCalculation: DropdownContent, // id of imported calculation
  viewModeDropdownItems: Array<TVDMenuItem>, // estimate type dropdown menuitems
  isSourceEstimateFrozen: boolean, // if the estimate that we are trying to import from is in frozen state
  isListOutDated: boolean, // if list is up to date with the latest changes
  referenceEstimateUpdated: boolean, // flag to indicate if the estimate has been updated
|}

export class ImportFromCalculation extends Component<Props, State> {
  state = {
    calculations: [],
    calculationsLoaded: false,
    selectedCalculation: { HALParsedData: '', basePath: '' },
    importedCalculation: { HALParsedData: '', basePath: '' },
    viewType: { HALParsedData: '', basePath: '' },
    viewModeDropdownItems: [],
    isSourceEstimateFrozen: false,
    isListOutDated: false,
    referenceEstimateUpdated: false
  }

  componentDidMount() {
    const { selectedAccountId, sentient } = this.props
    const { selectedCalculation } = this.state
    sentient.runSetup({
      getReferenceScheduleEstimatesWithEstimateIdRequestDefinitions: {
        successCb: (calculations: Array<Object>) => {
          getReferenceScheduleIdWithEstimateIdRequest({}, (referenceScheduleIdResponse: DropdownContent) => {
            getReferenceScheduleViewWithEstimateIdRequest({}, (referenceScheduleViewTypeResponse: DropdownContent) => {
              this.setState({
                calculations,
                calculationsLoaded: true,
                viewType: referenceScheduleViewTypeResponse,
                importedCalculation: referenceScheduleIdResponse,
                selectedCalculation: referenceScheduleIdResponse
              })
            })
          })
        }
      }
    })
    const { NEWCONSTRUCTION, RENOVATION } = importViewModes
    getEnumWithEnumRequest(
      { path: { enumParam: 'ESpacesEstimateType' } },
      {},
      (parsedResponse: Array<TVDEnum>) => {
        this.setState({ viewModeDropdownItems: parsedResponse.filter((viewmode: Object) => [NEWCONSTRUCTION, RENOVATION].includes(viewmode.value)) })
      }
    )
    getEstimatesWithEstimateIdRequest(
      {},
      (estimate: TVDCalculation) => {
        this.setState({
          referenceEstimateUpdated: !!estimate.referenceEstimateUpdated,
          isListOutDated: !!estimate.referenceEstimateUpdated
        })
      },
      {
        estimateId: selectedCalculation.HALParsedData,
        selectedAccountId
      }
    )
  }

  getPlaceholder = (): React$Element<'div'> | null => {
    const { t, classes } = this.props
    const { calculations, selectedCalculation, calculationsLoaded } = this.state
    if (!calculationsLoaded) return null

    let placeholderText
    if (!isEmpty(calculations) && !selectedCalculation.HALParsedData) placeholderText = t('importFromCalculation._CALCULATION_NOT_SELECTED_')
    else if (isEmpty(calculations)) placeholderText = t('importFromCalculation._NO_CALCULATIONS_')

    return (
      <div className={classes.message}>
        <div className={classes.messageText}>{placeholderText}</div>
      </div>
    )
  }

  getSourceFrozenErrorMessage = (): React$Element<'div'> => {
    const { t } = this.props

    return (
      <Message
        type='error'
        icon={<AlertError />}
        message={t('importFromCalculation._SOURCE_ESTIMATE_FROZEN_')}
        snackbar />
    )
  }

  selectCalculation = (selection: string) => {
    this.setState((state: Object) => (
      {
        selectedCalculation: { ...state.selectedCalculation, HALParsedData: selection },
        viewType: this.state.viewType.HALParsedData ?
          this.state.viewType :
          { ...this.state.viewType, HALParsedData: importViewModes.NEWCONSTRUCTION },
        isSourceEstimateFrozen: false
      }), () => {
      putReferenceScheduleIdWithEstimateIdRequest(
        { body: selection },
        {},
        this.updateElementList,
        (er: TVDErrorResponse) => {
          if (er.response.status === RESOURCE_CONFLICT_STATUS) {
            this.setState({ isSourceEstimateFrozen: true })
          }
        },
        { spacesEstimateType: this.state.viewType }
      )
    })
  }

  selectViewType = (viewType: string) => {
    const { selectedAccountId } = this.props
    const { importedCalculation, selectedCalculation } = this.state
    if (importedCalculation.HALParsedData) this.props.dispatchClearList(importedCalculation)

    this.setState((state: Object) => ({ viewType: { ...state.viewType, HALParsedData: viewType } }), () => {
      putReferenceScheduleViewWithEstimateIdRequest({ body: viewType }, {}, () => {
        getReferenceScheduleColumnsWithEstimateIdRequest({ listId: importedCalculation })
        getReferenceScheduleWithEstimateIdRequest({ listId: importedCalculation })
        getReferenceScheduleValuesWithEstimateIdRequest({ resultBarKey: IMPORT_FROM_CALCULATION })
        getEstimatesWithEstimateIdRequest(
          {},
          (estimate: TVDCalculation) => {
            this.setState({
              referenceEstimateUpdated: !!estimate.referenceEstimateUpdated,
            })
          },
          {
            estimateId: selectedCalculation.HALParsedData,
            selectedAccountId
          }
        )
      }, null, { spacesEstimateType: viewType })
    })
  }

  updateElementList = () => {
    const { selectedAccountId } = this.props
    const { selectedCalculation, importedCalculation } = this.state
    // if view already contains imported calculation and user changes calculation using the dropdown,
    // reset importedCalculation value first and then set new selection as importedCalculation in callBack
    // to trigger HierarchicalListContainer's didMountCallBack

    if (importedCalculation) {
      this.setState({ importedCalculation: { HALParsedData: '', basePath: '' } }, () => {
        this.setState({ importedCalculation: selectedCalculation })
      })
    } else this.setState({ importedCalculation: selectedCalculation })
    getEstimatesWithEstimateIdRequest(
      {},
      (estimate: TVDCalculation) => {
        this.setState({
          referenceEstimateUpdated: !!estimate.referenceEstimateUpdated,
        })
      },
      {
        estimateId: selectedCalculation.HALParsedData,
        selectedAccountId
      }
    )
  }

  getDescriptionCellContent = (content: string, row: Object) => {
    const { classes, isEstimateLockedToCurrentUser } = this.props
    const isHighlightedWithoutCursor = row.level > 2
    return (
      <div
        className={classes.headerRowContainer}>
        <DescriptionCell
          data-testid='description-cell'
          noPointer={isHighlightedWithoutCursor}
          highlighted={isHighlightedWithoutCursor}
          bold={isListItemTypeBold(row.type)}
          text={content} />
        { isEstimateLockedToCurrentUser && row.type !== HEADING.toLowerCase() && this.getHamburgerMenu(row) }
      </div>
    )
  }

  getListStatusIndicator = () => {
    const { classes, t } = this.props
    const { isListOutDated, referenceEstimateUpdated } = this.state
    if (!referenceEstimateUpdated && !isListOutDated) {
      return null
    }
    return (
      <div className={classes.statusContainer}>
        <div className={classes.statusIndicator}>
          <span className={classes.iconContainer}>
            {!referenceEstimateUpdated && isListOutDated ? <CheckSingleSVG className={classes.errorIcon} /> : ''}
            {!referenceEstimateUpdated && isListOutDated ? t('importFromCalculation._IMPORT_UPDATED_') : ''}
            {referenceEstimateUpdated ? <InfoTooltipSVG /> : ''}
            {referenceEstimateUpdated ? t('importFromCalculation._IMPORT_OUTDATED_') : ''}
            {referenceEstimateUpdated ?
              <span
                className={classes.refreshLink}
                role='button'
                tabIndex={0}
                onClick={() => {
                    this.getRefreshList()
                  }}>
                {t('wopSpaceSchedule._UPDATE_')}
              </span> : ''}
          </span>
        </div>
      </div>
    )
  }

  getRefreshList = () => {
    const { selectedCalculation, viewType } = this.state
    putReferenceScheduleIdWithEstimateIdRequest(
      { body: selectedCalculation.HALParsedData },
      {},
      this.updateElementList,
      (er: TVDErrorResponse) => {
        if (er.response.status === RESOURCE_CONFLICT_STATUS) {
          this.setState({ isSourceEstimateFrozen: true })
        }
      },
      { spacesEstimateType: viewType }
    )
  }

  calculationsDropdown(): React$Element<any> {
    const { t } = this.props
    const { calculations, selectedCalculation } = this.state
    return (
      <div>
        <DropdownSearchMenu
          disableClearable
          width='XXL'
          value={selectedCalculation.HALParsedData}
          items={calculations}
          title={t('importFromCalculation._CALCULATION_')}
          onChange={this.selectCalculation} />
      </div>
    )
  }

  tools(): React$Element<any> {
    const { t, classes, dispatchOpenAddBuildingElementsModal } = this.props
    const { selectedCalculation, viewType, isSourceEstimateFrozen } = this.state
    const disabled = !selectedCalculation.HALParsedData || !viewType.HALParsedData || isSourceEstimateFrozen
    return (
      <div className={classes.tools}>
        {this.calculationsDropdown()}
        {this.viewmodeDropdown()}
        <div className={classes.buttonWrapper}>
          <TextButton
            text={t('importFromCalculation._IMPORT_BUILDING_ELEMENTS_')}
            onClick={() => {
              dispatchOpenAddBuildingElementsModal()
            }}
            id='import-button'
            disabled={disabled} />
          <div style={{ paddingLeft: '9px' }}>
            <TextButton
              text={t('importFromCalculation._IMPORT_BUILDING_TASKS_PROPERTIES_')}
              onClick={() => this.props.dispatchOpenPropertiesModal()}
              id='import-tasks-button'
              variant='outlined'
              disabled={disabled} />
          </div>
        </div>
      </div>
    )
  }

  viewmodeDropdown(): React$Element<any> {
    const { t } = this.props
    const { isSourceEstimateFrozen } = this.state

    const viewModeOptions = this.state.selectedCalculation.HALParsedData ? this.state.viewModeDropdownItems : []
    return (
      <div>
        <DropdownMenu
          width='L'
          disabled={isSourceEstimateFrozen}
          allowReSelection
          title={t('importFromCalculation._CALCULATION_TYPE_')}
          defaultValue={this.state.viewType.HALParsedData}
          items={viewModeOptions}
          onChange={this.selectViewType} />
      </div>
    )
  }

  getHamburgerMenu(row: Object): React$Element<HamburgerMenu> {
    const { columnData: { Description } = {} } = row
    return <HamburgerMenu visibleOnHover id={Description} items={this.getContextMenuItems(row)} />
  }

  getContextMenuItems(row: Object): Array<TVDMenuItem> {
    const {
      t,
      dispatchPostPolling
    } = this.props
    const isClassificationElement = row.level >= 0 && row.level < 3
    const addItem = {
      localizedName: isClassificationElement ?
        t('importFromCalculation._ADD_BUILDING_ELEMENTS_') : t('importFromCalculation._ADD_BUILDING_ELEMENT_'),
      onClick: () => postScheduleWithEstimateIdRequest({
        body: { selectedIds: [row.id] }, query: { importReference: true }
      }, {}, () => dispatchPostPolling()),
      testId: 'ContextMenuItem-add-item-from-space-estimate'
    }
    const replaceItem = {
      localizedName: isClassificationElement ?
        t('importFromCalculation._REPLACE_BUILDING_ELEMENTS_') : t('importFromCalculation._REPLACE_BUILDING_ELEMENT_'),
      onClick: () => putScheduleWithEstimateIdRequest({
        body: { selectedIds: [row.id] }, query: { importReference: true }
      }, {}, () => dispatchPostPolling()),
      testId: 'ContextMenuItem-replace-item-from-space-estimate'
    }
    return isClassificationElement ? [addItem, replaceItem] : [addItem]
  }


  render(): React$Element<any> {
    const { classes } = this.props
    const { importedCalculation, selectedCalculation, isSourceEstimateFrozen } = this.state
    const showPlaceholder = !importedCalculation.HALParsedData || !selectedCalculation.HALParsedData

    return (
      <div className={classes.importFromCalculation}>
        {this.getListStatusIndicator()}
        {this.tools()}
        { isSourceEstimateFrozen && this.getSourceFrozenErrorMessage()}
        { (showPlaceholder && !isSourceEstimateFrozen) && this.getPlaceholder() }
        { !(showPlaceholder || isSourceEstimateFrozen) &&
          (
            <HierarchicalListContainer
              disabled={false}
              listId={this.state.importedCalculation}
              listType={IMPORT_FROM_CALCULATION}
              testId='importFromCalculationList'
              editableColumns={[]}
              initialColumnWidths={{
                [TOTAL_PRICE_IN_CURRENCY]: 150
              }}
              didMountCallback={() => {
                getReferenceScheduleColumnsWithEstimateIdRequest({ listId: importedCalculation })
                getReferenceScheduleWithEstimateIdRequest({ listId: importedCalculation })
                getReferenceScheduleValuesWithEstimateIdRequest({ resultBarKey: IMPORT_FROM_CALCULATION })
              }}
              wrappedCellContents={{
                Description: ({ content, row }: Object) => this.getDescriptionCellContent(content, row),
                [UNIT_PRICE]: ({ row }: Object) => (
                  Object.prototype.hasOwnProperty.call(row.columnData, UNIT_PRICE) ?
                    formatCellContent(row.columnData[UNIT_PRICE].toString(), 'number') :
                    ''),
                [TOTAL_PRICE_IN_CURRENCY]: ({ row }: Object) => {
                  if (row.type !== 'priceItem' && row.columnData[TOTAL_PRICE_IN_CURRENCY] === 0) {
                    return ''
                  }
                  return (
                  Object.prototype.hasOwnProperty.call(row.columnData, TOTAL_PRICE_IN_CURRENCY) ?
                  formatCellContent(row.columnData[TOTAL_PRICE_IN_CURRENCY].toString(), 'number') :
                  '')
},
              }}
              onRowClick={() => {}} />
          )
        }
      </div>
    )
  }
}

const mapStateToProps = ({
  app: {
    isEstimateLockedToCurrentUser,
    selectedAccountId
  }
}: TVDReduxStore): MappedProps => ({
  isEstimateLockedToCurrentUser,
  selectedAccountId
})

const mapDispatchToProps = (dispatch: Function): DispatchProps => ({
  dispatchPostPolling: () => {
    dispatch(postPolling())
  },
  dispatchOpenPropertiesModal: () => {
    const contentProps = {
      customWidth: 850,
      saveButtonText: 'importFromCalculation._IMPORT_BUILDING_TASKS_PROPERTIES_SAVE_BUTTON_',
      footerInfoText: 'importFromCalculation._PROPERTIES_REPLACE_TIP_',
      onSave: () => putEstimatePropertiesWithEstimateIdRequest({ query: { importReference: true } }, {}, () => {
        dispatch(closeModal(PROPERTIES_MODAL))
        dispatch(postPolling())
      }),
      onClose: () => dispatch(closeModal(PROPERTIES_MODAL))
    }
    const modalProps = {
      type: PROPERTIES_MODAL, // string to define modal type
      disablePadding: true, // flag to disable padding in modal
      title: 'importFromCalculation._IMPORT_BUILDING_TASKS_PROPERTIES_MODAL_TITLE_',
    }
    dispatch(buildPropertiesModal(contentProps, modalProps))
  },
  dispatchOpenAddBuildingElementsModal: () => {
    const modalId = 'CONFIRMATION_MODAL'
    const content = {
      type: CONFIRMATION_MODAL,
      saveButtonText: 'importFromCalculation._ADDING_BUILDING_ELEMENTS_',
      onSave: () => putScheduleWithEstimateIdRequest(
        { body: {}, query: { importReference: true } },
        {},
        () => {
          dispatch(closeModal(CONFIRMATION_MODAL))
          dispatch(postPolling())
        }
      ),
      onClose: () => dispatch(closeModal(CONFIRMATION_MODAL)),
      message: ['importFromCalculation._ADDING_BUILDING_ELEMENTS_HEADER_', 'importFromCalculation._ADDING_BUILDING_ELEMENTS_TEXT_']
    }
    dispatch(openModal(content, modalId))
  },
  dispatchClearList: (importedCalculation: string) => dispatch(clearList(importedCalculation))
})

const sentientConfig: TVDSentientConfig = {
  getSetupRequestDefinitions: (): TVDGARConfigs =>
    ({
      getReferenceScheduleEstimatesWithEstimateIdRequestDefinitions: getReferenceScheduleEstimatesWithEstimateIdRequestDefinitions({
        payload: {},
      }),
      getReferenceScheduleValuesWithEstimateIdRequestDefinitions: getReferenceScheduleValuesWithEstimateIdRequestDefinitions({
        payload: { resultBarKey: IMPORT_FROM_CALCULATION },
      })
    })
}

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation('translations')
)(SentientHOC(ImportFromCalculation, sentientConfig))
