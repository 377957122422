// @flow
// Copyright © 2010–2022 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.

import { createConnection } from 'frontend-websocket'
import { API_REALTIME_URL } from '../constants/envConstants'
import { getState } from '../store'

// Only instance of a connection that is required for the entire application life cycle
let WSConnection = null

// Returns a instance of HubConnection https://github.com/DefinitelyTyped/DefinitelyTyped/blob/99e22ccf1e98ed5d9166c4d0cf01c3ee38e7796e/types/signalr/v1/index.d.ts#L89
export const initWSConnection = (accessToken: $PropertyType<TVDUserAuthorization, 'accessToken'>): Object => {
  WSConnection = createConnection(API_REALTIME_URL, accessToken, null, () => {
    const { user: { authorization } } = getState()
    if (!authorization.accessToken) {
      console.error('No authorization access token found')
    }
    return authorization.accessToken || accessToken
  })
  return WSConnection
}

// Returns a instance of HubConnection https://github.com/DefinitelyTyped/DefinitelyTyped/blob/99e22ccf1e98ed5d9166c4d0cf01c3ee38e7796e/types/signalr/v1/index.d.ts#L89
export const getWSConnection = (): Object | null => {
  if (!WSConnection) {
    console.error('No connetion instance found')
  }
  return WSConnection
}
