// Copyright © 2010–2022 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import invokeMethod from '../invokeMethod/invokeMethod';
var invokeSubscribeWS = function (invokeSubscribeMethodArgs) { return (invokeMethod(__assign(__assign({}, invokeSubscribeMethodArgs), { method: 'Subscribe' }))); };
export default invokeSubscribeWS;
