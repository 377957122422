// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.
import { HubConnectionBuilder } from '@microsoft/signalr';
var createConnection = function (wsURL, // usually a environment specific url to create a websocket connection to
accessToken, // user's valid uuid access token
logLevel, // optional level of logging for capturing error cases
getAccessToken) {
    var options = {
        skipNegotiation: true,
        transport: 1,
        accessTokenFactory: function () {
            if (getAccessToken) {
                return getAccessToken();
            }
            return accessToken;
        }
    };
    var connection = new HubConnectionBuilder()
        .withUrl(wsURL, options)
        .configureLogging(logLevel || 4)
        .withAutomaticReconnect()
        .build();
    return connection;
};
export default createConnection;
