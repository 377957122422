// @flow
// Copyright © 2010–2023 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.

export const SET_ACTIVE_REAL_ESTATE = 'SET_ACTIVE_REAL_ESTATE'
export function setActiveRealEstate(activeRealEstate: TVDRealEstate): TVDAction {
  return {
    type: SET_ACTIVE_REAL_ESTATE,
    payload: { activeRealEstate }
  }
}

export const RESET_ACTIVE_REAL_ESTATE = 'RESET_ACTIVE_REAL_ESTATE'
export function resetActiveRealEstate(): TVDAction {
  return {
    type: RESET_ACTIVE_REAL_ESTATE,
    payload: {}
  }
}

