// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.
import { useEffect, useState, useMemo } from 'react'
import { useStore, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import qs from 'query-string'
import { TVDSelectedAccountId } from '../../../constants/localPersistence'
import { ACCOUNT_SELECTION_MODAL } from '../../../constants/modalConstants'
import { getLocalStorage } from '../../../utils/commonUtils'
import { getUsersSelfPrivilegesRequest } from '../../../utils/generated-api-requests/users'
import { openModal } from '../../../actions/modals'
import { setSelectedAccount } from '../../../actions/app'
import { getUsersAPIBasePath, getUserTypeFromPathname } from '../../../utils/apiUtils'
import { TVD_TOKEN_USER_TYPE_USER } from '../../../constants/apiConstants'
import { MAIN_VIEW_KEY } from '../../../constants/searchParams'
import { openGenericErrorModal } from '../../../utils/modalUtils'

/**
 * Custom hook to prompt user to select an account with ACCOUNT_SELECTION_MODAL if one is not already selected
 * or to handle account selection manually if
 * selectedAccountId can be found from localStorage or url or user has access to only one account
 */
export const useAccountSelection = () => {
  const { dispatch, getState } = useStore() // https://react-redux.js.org/api/hooks#usestore
  const history = useHistory() // https://reactrouter.com/web/api/Hooks/usehistory
  const { search, pathname } = useLocation() // https://reactrouter.com/web/api/Hooks/uselocation
  const { t } = useTranslation() // https://react.i18next.com/latest/usetranslation-hook
  const [isUserPrivilegesReady, setIsUserPrivilegesReady] = useState<boolean | null>(null)
  const parsedSearchParams = qs.parse(search) || {}
  const { accountId, [MAIN_VIEW_KEY]: selectedMainViewQueryParam } = parsedSearchParams
  const accountIdQueryParam = accountId || getLocalStorage(TVDSelectedAccountId)
  const {
    selectedAccountIdFromStore,
    accountsFromStore
  } = useSelector(({ app: { selectedAccountId }, user: { userPrivileges, accounts } }: TVDReduxStore): any => ({
    selectedAccountIdFromStore: selectedAccountId,
    userPrivilegesFromStore: userPrivileges,
    accountsFromStore: accounts
  }))
  /**
   * determines which url basepath to use for API requests
   * @returns string or undefined
   */
  const basePath = useMemo((): string | typeof undefined => {
    const userType = getUserTypeFromPathname(pathname)
    return getUsersAPIBasePath(userType || TVD_TOKEN_USER_TYPE_USER)
  }, [pathname])

  useEffect(() => {
    if (accountsFromStore) {
      const selectedAccount = accountsFromStore.find((account: TVDEnum): boolean => account.value === accountIdQueryParam)
      if (selectedAccount) {
        dispatch(setSelectedAccount(selectedAccount))
      }
    }
  }, [dispatch, accountIdQueryParam, accountsFromStore])

  useEffect(() => {
    if (selectedAccountIdFromStore) {
      setIsUserPrivilegesReady(false)
      getUsersSelfPrivilegesRequest(
        {},
        (): void => {
          setIsUserPrivilegesReady(true)
        },
        () => {
          openGenericErrorModal()
        },
        { basePath }
      )
    }
  }, [basePath, selectedAccountIdFromStore])

  useEffect(() => {
    if (selectedAccountIdFromStore && selectedAccountIdFromStore === accountIdQueryParam) {
      const newSearch = {
        ...qs.parse(search),
        accountId: selectedAccountIdFromStore
      }
      history.push(`${pathname}?${qs.stringify(newSearch)}`)
    }
  }, [selectedAccountIdFromStore, accountIdQueryParam, history, search, pathname])

  useEffect(() => {
    const { user: { accounts } } = getState()
    const selectedAccount = accounts.find((account: TVDEnum): boolean => account.value === accountIdQueryParam)

    if (!selectedAccount && accounts.length > 1) {
      if (window.location.pathname !== '/' || selectedMainViewQueryParam) {
        localStorage.removeItem(TVDSelectedAccountId)
        history.push({ pathname: '/', search: '' })
      }
      dispatch(openModal({
        type: ACCOUNT_SELECTION_MODAL,
        title: t('drawer._CHOOSE_ACCOUNT_'),
        disablePadding: true,
        contentProps: {
          accounts,
          hideCloseButton: true,
          hideMoreOptions: true
        }
      }, ACCOUNT_SELECTION_MODAL))
    } else {
      const account = selectedAccount || accounts[0]
      dispatch(setSelectedAccount(account))
    }
  }, [dispatch, getState, history, search, pathname, t, accountIdQueryParam, selectedMainViewQueryParam]) // add additional param to useEffect hook that will ensure the useEffect only runs once. To avoid infinite loops

  return { isUserPrivilegesReady }
}
