// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.
export const REMOVE_ELEMENT_ITEM = 'REMOVE_ELEMENT_ITEM'
export const RENAME_VALUE = 'RENAME_VALUE'
export const GET_BUILDING_TASK_SCHEDULE_PARSED = 'GET_BUILDING_TASK_SCHEDULE_PARSED'
export const REMOVE_BUILDING_TASK_SCHEDULE = 'REMOVE_BUILDING_TASK_SCHEDULE'

export function removeBuildingTaskSchedule(id: string): Object {
  return {
    type: REMOVE_BUILDING_TASK_SCHEDULE,
    payload: { id }
  }
}
