// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'

import PermissionsListContainer from '../PermissionsListContainer/PermissionsListContainer'

import { USER_RIGHTS, DESCRIPTION, ESTIMATE_TYPE } from '../../../../constants/attributes'
import { ACCESS_CONTROL_ESTIMATES } from '../../../../constants/contentTypes'
import { MODAL_TYPE_SETTINGS } from '../../../../constants/modalConstants'
import { mergeListItems } from '../../../../actions/list'

const styles = (): Object => ({
  estimatesTabContent: {
    display: 'flex',
    minHeight: '100%',
    maxHeight: '100%'
  },
})

type HOCProps = {|
  t: Function, // i18n translate function
  classes: Object, // withStyles classes object
|}

type ReceivedProps = {|

|}

type MappedProps = {|
  userId: string, // currently signed in user's uuid
|}

type DispatchProps = {|
|}

type Props = {|
  ...ReceivedProps,
  ...MappedProps,
  ...DispatchProps,
  ...HOCProps,
|}

export class SettingsEstimates extends Component<Props> {
  getColumns = (): Array<TVDListItemColumn> => {
    const { t } = this.props
    return [
      { propertyName: DESCRIPTION, localizedName: t('settings._DESCRIPTION_'), dataType: 'string' },
      { propertyName: USER_RIGHTS, localizedName: t('settings._USER_RIGHTS_'), dataType: 'string' },
      { propertyName: ESTIMATE_TYPE, localizedName: t('settings._ESTIMATE_TYPE_'), dataType: 'string' },
    ]
  }

  render(): React$Element<'div'> {
    const { classes, userId } = this.props
    return (
      <div className={classes.estimatesTabContent}>
        <PermissionsListContainer
          subjectId={userId}
          modalId={MODAL_TYPE_SETTINGS}
          listId={ACCESS_CONTROL_ESTIMATES}
          columns={this.getColumns()} />
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch: Function) => ({
  dispatchSetListItemsToStore: (listStoreId: string, listItems: Object) => { dispatch(mergeListItems(listStoreId, listItems)) },
})

const mapStateToProps = ({ user: { claims: { userId } } }: TVDReduxStore): MappedProps => ({
  userId
})

export default compose(
  withTranslation('translations'),
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
)(SettingsEstimates)
