// @flow
// Copyright © 2010–2022 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.
import { SET_ACTIVE_ESTIMATE, RESET_ACTIVE_ESTIMATE } from '../actions/activeEstimate'
import { SET_IS_ESTIMATE_FROZEN } from '../actions/app'

const initialState: TVDActiveEstimateStore = null

export default function activeEstimateReducer(state: TVDActiveEstimateStore = initialState, action: TVDAction): TVDActiveEstimateStore {
  switch (action.type) {
    case SET_ACTIVE_ESTIMATE: {
      const { payload: { activeEstimate } } = action
      return activeEstimate
    }

    case RESET_ACTIVE_ESTIMATE: return null

    case SET_IS_ESTIMATE_FROZEN: {
      const { payload: { isFrozen } } = action
      if (state) {
        return {
          ...state,
          frozen: isFrozen
        }
      }
      return state
    }

    default: return state
  }
}
