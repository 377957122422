// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.

import { validate } from 'jsonschema'
import { getState } from '../store'
import MFEFunctionalitySchema from '../MFEFunctionalitySchema.json'

export const registeredMFEs: { [moduleName: string]: TVDMFEFunctionality } = {}

export const registerMFE = (MFE: TVDMFEFunctionality) => {
  try {
    const { valid, errors } = validate(MFE, MFEFunctionalitySchema)
    if (!valid) {
      throw new Error(`Failed to validate MFE ${errors}`)
    }
    if (registeredMFEs[MFE.name]) {
      throw new Error(`Error registering MFE: ${MFE.name} is already registered`)
    }
    registeredMFEs[MFE.name] = MFE
  } catch (er) {
    console.error(er)
  }
}
export const getMFE = (moduleName: string): TVDMFEFunctionality => {
  try {
    const registeredModule = registeredMFEs[moduleName]
    if (!registeredModule) throw new Error(`Could not find module from registered ones with name ${moduleName}`)
    return registeredModule
  } catch (error) {
    console.error(error)
    return {
      name: '',
      // eslint-disable-next-line no-unused-vars
      render: (id: string) => {},
      onMainAction: () => {}
    }
  }
}

export const getMFEBuildFileSource = (MFEName: string, MFEModuleName: string) => {
  const { app: { MFEDetails } }: TVDReduxStore = getState()
  const MFEDetail = MFEDetails?.[MFEName]
  if (MFEDetail) {
    const src = MFEDetail.modules.reduce((result: string, moduleName: string, index: number) => {
      if (moduleName === MFEModuleName) return MFEDetail.entriesJS[index]
      return result
    }, '')
    return src
  }
  console.error(`No MFE file source could be solved from details with module name ${MFEModuleName}`)
  return ''
}

export const loadMFE = (MFEName: string, MFEModuleName: string, onLoadCb?: (TVDMFEFunctionality) => void) => {
  const id = `mfe-${MFEModuleName}`

  // assuming the MFE is loaded and registered as the element id is found
  if (document.getElementById(id) && window.TVDBootstrappedMFEs[MFEModuleName]) {
    if (onLoadCb) onLoadCb(getMFE(MFEModuleName))
    return
  }

  const { app: { MFEDetails } }: TVDReduxStore = getState()
  const scriptEl = document.createElement('script')
  const { body } = document
  const MFEDetail = MFEDetails?.[MFEName]
  if (MFEDetail && body) {
    const src = getMFEBuildFileSource(MFEName, MFEModuleName)
    scriptEl.id = id
    scriptEl.src = src
    scriptEl.onload = () => {
      registerMFE(window.TVDBootstrappedMFEs[MFEModuleName])
      if (onLoadCb) onLoadCb(getMFE(MFEModuleName))
    }
    body.appendChild(scriptEl)
  } else {
    console.error(`No MFE could be solved from details with module name ${MFEModuleName}`)
  }
}
