// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.
import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { colors, borderRadiuses, boxShadows } from 'frontend-assets'
import MUITooltip from '@material-ui/core/Tooltip'
import MUIButton from '@material-ui/core/Button'
import MUIPaper from '@material-ui/core/Paper'
import ReactMarkdown from 'react-markdown'
import { isEmpty, omit } from 'lodash'

import { combineStyleClassNames } from '../../../utils/styleUtils'
import theme from '../../../styles/theme'

/*
  INSTRUCTIONS:
  Wrap the Tooltip component around the desired element to give it a tooltip e.g. <Tooltip><AnyComponent /></Tooltip>
*/

const { borderRadiusNormal } = borderRadiuses
const { boxShadowComponent } = boxShadows

const styles = ({ palette, tooltipMarkdown }: Object): Object => ({
  tooltip: {
    ...theme.typography.classes.bodySmall,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-end',
    backgroundColor: colors.primary10,
    boxShadow: boxShadowComponent,
    borderRadius: borderRadiusNormal,
    marginRight: '14px',
    marginLeft: '14px',
    padding: 8,
    maxWidth: '400px',
  },
  multiline: {
    '& > *': {
      overflowWrap: 'break-word',
      whiteSpace: 'normal',
    }
  },
  typography: {
    color: palette.dark100
  },
  popOver: {
    backgroundColor: colors.primary10
  },
  action: {
    ...theme.typography.classes.bodySmall,
    padding: '12px 0 0 0',
    color: palette.primary100,
    textTransform: 'none',
    minWidth: '0px',
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    width: '100%',
  },
  list: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    width: '100%',
    maxHeight: '360px',
    overflowY: 'auto'
  },
  actionContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '300px'
  },
  tooltipMarkdown: { ...tooltipMarkdown }
})

export type TooltipAction = {
  label: string, // the text in the tooltip action button
  action: Function, // the callback function from the tooltip action button
  id: string // id for the action for e2e testing purposes
}

type Props = {|
  classes: Object, // styles object
  children: React$Element<any>, // only works with a single component as children
  content?: React$Element<any>, // JSX content to be displayed in the tooltip
  text?: String, // text to be displayed in the tooltip, goes to ReactMarkdown
  placement: string, // if tooltip must appear somewhere else than on the right side of the parent element
  enterDelay?: number, // delay when tooltip is displayed on hover,
  open: Boolean | null, // for controlling the tooltip from the outside
  onOpen?: () => {}, // function fired when the tooltip opens,
  action?: TooltipAction | null, // used to create the action at the bottom of the tooltip, if desired
  interactive?: Boolean,
  multiline?: Boolean,
  disablePortal?: Boolean,
  PopperProps: Object, // props provided for the popper component inside MUI's tooltip
  list?: Boolean, // flag to indicate that tooltip contet is a list of strings
  theme: Object, // material-ui theme object
|}

export class Tooltip extends React.Component<Props> {
  static defaultProps = {
    enterDelay: 1000,
    content: '',
    open: null,
    onOpen: () => {},
    action: null,
    interactive: false,
    text: '',
    multiline: false,
    disablePortal: false,
    title: '',
    list: false
  }

  get content(): any {
    const {
      classes,
      action,
      content,
      text,
      multiline,
      list
    } = this.props
    return (
      <MUIPaper
        onClick={(event: SyntheticEvent<any>) => { event.stopPropagation() }}
        elevation={0}
        className={combineStyleClassNames(classes.popOver, classes.paper, list && classes.list)}>
        { content || <ReactMarkdown className={`${classes.tooltipMarkdown} ${multiline ? classes.multiline : ''}`}>{text}</ReactMarkdown> }
        { action ?
          <div className={classes.actionContainer}>
            <MUIButton
              data-testid={action.id}
              disableRipple
              size='small'
              className={classes.action}
              onClick={this.handleActionClick}>
              {action.label}
            </MUIButton>
          </div>
          : null }
      </MUIPaper>
    )
  }

  handleActionClick = (event: SyntheticEvent<any>) => {
    event.stopPropagation()
    if (this.props.action) this.props.action.action()
  }

  handleNullOpenProp = (open: Boolean | null): Object => (open !== null ? { open } : {})

  render(): any {
    const {
      placement,
      enterDelay,
      content,
      classes,
      children,
      open,
      onOpen,
      interactive,
      text,
      multiline,
      disablePortal,
      PopperProps,
      action,
      theme: { zIndex },
      ...restProps
    } = this.props
    // after Material-UI v4 update 'open' prop
    // can be only true/false or not defined at all to MUITooltip
    // if we default prop to false, some tooltips (e.g. BuildingListItem) are never displayed
    // by omitting open prop when it's null it will work as before update to v4.
    const openProp = this.handleNullOpenProp(open)

    return (
      <MUITooltip
        {...omit(restProps, 'list')}
        {...openProp}
        placement={placement || 'right'}
        enterDelay={enterDelay}
        disableHoverListener={isEmpty(content) && !text}
        title={this.content}
        PopperProps={{
          ...PopperProps,
          disablePortal,
          // without this visibility hack the tooltip won't close if the content goes empty while the tooltip is open
          // evident in MultipleEditInputs where user input should remove the tooltip that can still be open
          style: { visibility: text || content ? 'visible' : 'hidden', zIndex: zIndex.topMost }
        }}
        classes={{ tooltip: classes.tooltip }}
        onOpen={onOpen}
        interactive={interactive}>
        {children}
      </MUITooltip>
    )
  }
}

export default withStyles(styles, { withTheme: true })(Tooltip)
