// @flow
// Copyright © 2010–2022 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.

let worker = null

export default (): Worker | null => {
  if (!worker) {
    worker = window.Worker ? new Worker('/webworker-module') : null
  }
  if (!worker) {
    console.error('Could not get Worker')
  }
  return worker
}

