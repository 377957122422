// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { withStyles } from '@material-ui/core/styles'
import { TextButton } from 'frontend-components'
import { addListItem, setListItemFilter } from '../../../../actions/list/'
import HierarchicalListContainer from '../../HierarchicalListContainer/HierarchicalListContainer'
import { AMOUNT, QUANTITYPCS, QUANTITY } from '../../../../constants/attributes'
import {
  getEstimateEquipmentColumnsWithEstimateIdRequest,
  getEstimateEquipmentRegistryWithEstimateIdRequest
} from '../../../../utils/generated-api-requests/spaces'
import ListSearchField from '../../../common/lists/ListSearchField/ListSearchField'
import DescriptionCell from '../../../common/lists/common/DescriptionCell/DescriptionCell'

const ELEMENT_REGISTRY = 'ELEMENT_REGISTRY'

const styles = ({ palette }: Object): Object => ({
  headerRowContainer: {
    display: 'flex',
    width: '100%'
  },
  searchFieldWrapper: {
    margin: '10px 30px'
  },
  amountCell: {
    display: 'flex'
  },
  unit: {
    marginLeft: '13px',
    color: palette.defaultText
  }
})

type DispatchProps = {|
  dispatchAddListItem: (Object) => void, // fn to add list item to Store
  dispatchSetListItemFilter: (string) => void // fn to add search filter to the list to Store
|}


type StoreProps = {|
  targetListStoreId: string, // target list id that the item will be added in Store
  parentId: string
|}

type Props = {|
  ...DispatchProps,
  classes: Object, // withStyles classes object
  t: Function, // i18next translation function
  type: string, // widgetType
|}


export class EquipmentRegistry extends Component<Props> {
  onEnter = (e: SyntheticKeyboardEvent<any>) => {
    if (e.key === 'Enter') {
      // typecast to avoid flow error: https://github.com/facebook/flow/issues/2099
      this.handleChange((e.target: window.HTMLInputElement).value)
    }
  }

  getDescriptionCellContent = (content: string) => {
    const { classes } = this.props

    return (
      <div className={classes.headerRowContainer}>
        <DescriptionCell text={content} />
      </div>
    )
  }


  onBlur = (e: SyntheticInputEvent<any>) => {
    this.handleChange(e.target.value)
  }

  handleChange = (value: string) => {
    const { dispatchSetListItemFilter } = this.props
    dispatchSetListItemFilter(value)
  }

  searchField(): React$Element<any> {
    const {
      classes,
    } = this.props
    return (
      <div className={classes.searchFieldWrapper}>
        <ListSearchField listId={ELEMENT_REGISTRY} />
      </div>
    )
  }

  render(): React$Element<any> {
    const { dispatchAddListItem, t, type } = this.props
    return (
      <React.Fragment>
        {this.searchField()}
        <HierarchicalListContainer
          editableColumns={[AMOUNT, QUANTITYPCS, QUANTITY]}
          widgetType={type}
          columnsVisibleOnHover={['addButtonColumn']}
          didMountCallback={() => {
            getEstimateEquipmentRegistryWithEstimateIdRequest(
              { query: { listType: 'flat' } },
              { listId: ELEMENT_REGISTRY }
            )
            getEstimateEquipmentColumnsWithEstimateIdRequest({
              listId: ELEMENT_REGISTRY,
              addedColumns: [{ propertyName: 'addButtonColumn', localizedName: '', dataType: '' }]
            })
          }}
          listId={ELEMENT_REGISTRY}
          wrappedCellContents={{
            Description: ({ content }: TVDWrappedCellCallbackParameters) => this.getDescriptionCellContent(content),
            addButtonColumn: ({ row }: TVDWrappedCellCallbackParameters) => (
              <TextButton
                testId={`${row.columnData.Description}-addButton`}
                variant='small'
                text={t('buttons._ADD_')}
                onClick={() => dispatchAddListItem(row)} />
            )
          }} />
      </React.Fragment>
    )
  }
}


function mapDispatchToProps(dispatch: Function, { targetListStoreId, parentId }: StoreProps): Object {
  return {
    dispatchAddListItem: (listItem: Object) => dispatch(addListItem(targetListStoreId, listItem, parentId)),
    dispatchSetListItemFilter: (listFilter: string) => dispatch(setListItemFilter(ELEMENT_REGISTRY, listFilter))
  }
}

export default connect(null, mapDispatchToProps)(withTranslation('translations')(withStyles(styles)(EquipmentRegistry)))
