// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.
import React, { Component } from 'react'
import { withStyles, Typography } from '@material-ui/core'
import { withTranslation } from 'react-i18next'
import { map, flatten } from 'lodash'

import ModalForm from '../ModalForm/ModalForm'

import { getEstimatesRequest, deleteBuildingsWithBuildingIdRequest, getBuildingsRequest } from '../../../utils/generated-api-requests/estimates'

type Props = {|
  classes: Object, // withStyles classes object
  t: Function, // translate function
  onClose: Function, // close modal function
  contentProps: Object, // object containing props for modal content
  userId: $PropertyType<TVDUserClaims, 'userId'> // id of the user
|}

type State = {|
  allEstimates: string,
|}

const styles = ({ palette, typography }: TVDTheme): Object => ({
  messageContainer: {
    display: 'flex',
    flexDirection: 'column'
  },
  message: {
    color: palette.nevada,
    fontFamily: typography.fontFamily,
    fontSize: 16,
    width: 500,
    padding: '0 0 18px'
  },
  highlightedMessage: {
    justifyContent: 'left',
    color: palette.primary100,
    fontFamily: typography.fontFamily,
    fontSize: 16,
    width: 500,
    fontWeight: 600,
    padding: '24px'
  },
})

export class DeleteBuildingModal extends Component<Props, State> {
  state = {
    allEstimates: '',
  }

  componentDidMount() {
    const { contentProps } = this.props
    getEstimatesRequest({ query: { buildingId: contentProps.buildingId } }, {}, (estimatesByType: Object) => {
      const allEstimates = flatten(map(estimatesByType, (estimates: Object) => map(estimates, (estimate: Object) => estimate)))
      this.setState({ allEstimates })
    })
  }

  handleSave = () => {
    const { onClose, contentProps, userId } = this.props
    deleteBuildingsWithBuildingIdRequest({ path: { buildingId: contentProps.buildingId } }, {}, () => {
      getBuildingsRequest({ query: { permissionsSubject: userId } })
      onClose()
    })
  }

  headerMessage(): React$Element<typeof Typography> {
    const { classes, t } = this.props
    return (
      <Typography className={classes.message}>{t('projectDrawer._BUILDING_DELETE_HEADER_')}</Typography>
    )
  }

  highlightedMessage(): React$Element<typeof Typography> {
    const { classes, contentProps } = this.props
    return (
      <Typography className={classes.highlightedMessage}>{`${contentProps.buildingName}* ?`}</Typography>
    )
  }

  footerMessage(): React$Element<typeof Typography> {
    const { classes, t } = this.props
    return (
      <Typography className={classes.message}>
        {t('projectDrawer._BUILDING_DELETE_FOOTER_', { estimates: this.state.allEstimates.length })}
      </Typography>
    )
  }


  items(): Array<Typography> {
    return [this.headerMessage(), this.highlightedMessage(), this.footerMessage()]
  }

  render(): React$Element<any> {
    const { t, onClose } = this.props

    return (
      <ModalForm
        testId='DeleteBuildingModal'
        items={this.items()}
        onSave={() => this.handleSave()}
        onClose={() => onClose()}
        valid
        saveButtonText={t('projectDrawer._DELETE_BUILDING_')} />
    )
  }
}

export default withTranslation('translations')(withStyles(styles)(DeleteBuildingModal))
