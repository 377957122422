// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.
/* eslint max-len: ["error", { "code": 350 }] */
/*
  Haahtela Location Analysis Api 3.0.0
  Location Analysis services for Haahtela Api - this file is to be deprecated, please use openapi.yaml instead!
  192.168.254.119:5025
*/

import requestFn from './../requests/request'
import { API_BASE_URL } from './../../constants/envConstants'
import parserMapper from './../parserMapper'
import { getState } from '../../store'

export const basePath = '/location-analysis-api'

export const DATA_GET_ESTIMATE_WITH_ESTIMATEID_START = 'DATA_GET_ESTIMATE_WITH_ESTIMATEID_START'
export const DATA_GET_ESTIMATE_WITH_ESTIMATEID_SUCCESSFUL = 'DATA_GET_ESTIMATE_WITH_ESTIMATEID_SUCCESSFUL'
export const DATA_GET_ESTIMATE_WITH_ESTIMATEID_FAIL = 'DATA_GET_ESTIMATE_WITH_ESTIMATEID_FAIL'

// Returns Location Analysis Estimate properties
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/locan.yaml#/Location%20Analysis%20Estimate/get_estimate__estimateId_
export const getEstimateWithEstimateIdRequest = (
  payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_ESTIMATE_WITH_ESTIMATEID_START',
      successful: 'DATA_GET_ESTIMATE_WITH_ESTIMATEID_SUCCESSFUL',
      fail: 'DATA_GET_ESTIMATE_WITH_ESTIMATEID_FAIL'
    },
    embeddedType: {
      nonEmbedded: {
        schemaPath: '#/definitions/Estimate',
        schemaKey: 'Estimate'
      }
    },
    fnName: 'getEstimateWithEstimateIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/location-analysis-api`,
    method: 'GET',
    url: `/estimate/${options?.estimateId || store.app.calculation}`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      nonEmbedded: {
        schemaPath: '#/definitions/Estimate',
        schemaKey: 'Estimate'
      }
    }, 'getEstimateWithEstimateIdRequest', '/location-analysis-api'),
    actions: {
      start: {
        type: DATA_GET_ESTIMATE_WITH_ESTIMATEID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_ESTIMATE_WITH_ESTIMATEID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_ESTIMATE_WITH_ESTIMATEID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getEstimateWithEstimateIdRequestDefinitions = (definitionsArguments: {| payload?: Object |}): {|
  ...TVDGARConfigWithoutRequestArgs, payload?: Object |} => ({
  fnName: 'getEstimateWithEstimateIdRequest',
  basePath: '/location-analysis-api',
  payload: definitionsArguments.payload,
})


export const DATA_GET_ESTIMATE_LOCK_WITH_ESTIMATEID_START = 'DATA_GET_ESTIMATE_LOCK_WITH_ESTIMATEID_START'
export const DATA_GET_ESTIMATE_LOCK_WITH_ESTIMATEID_SUCCESSFUL = 'DATA_GET_ESTIMATE_LOCK_WITH_ESTIMATEID_SUCCESSFUL'
export const DATA_GET_ESTIMATE_LOCK_WITH_ESTIMATEID_FAIL = 'DATA_GET_ESTIMATE_LOCK_WITH_ESTIMATEID_FAIL'

// If resource is locked, returns information (owner, expiration) about the current lock
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/locan.yaml#/Location%20Analysis%20Estimate/get_estimate__estimateId__lock
export const getEstimateLockWithEstimateIdRequest = (
  payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_ESTIMATE_LOCK_WITH_ESTIMATEID_START',
      successful: 'DATA_GET_ESTIMATE_LOCK_WITH_ESTIMATEID_SUCCESSFUL',
      fail: 'DATA_GET_ESTIMATE_LOCK_WITH_ESTIMATEID_FAIL'
    },
    embeddedType: {
      nonEmbedded: {
        schemaPath: '#/definitions/ResourceLock',
        schemaKey: 'ResourceLock'
      }
    },
    fnName: 'getEstimateLockWithEstimateIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/location-analysis-api`,
    method: 'GET',
    url: `/estimate/${options?.estimateId || store.app.calculation}/lock`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      nonEmbedded: {
        schemaPath: '#/definitions/ResourceLock',
        schemaKey: 'ResourceLock'
      }
    }, 'getEstimateLockWithEstimateIdRequest', '/location-analysis-api'),
    actions: {
      start: {
        type: DATA_GET_ESTIMATE_LOCK_WITH_ESTIMATEID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_ESTIMATE_LOCK_WITH_ESTIMATEID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_ESTIMATE_LOCK_WITH_ESTIMATEID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getEstimateLockWithEstimateIdRequestDefinitions = (definitionsArguments: {| payload?: Object |}): {|
  ...TVDGARConfigWithoutRequestArgs, payload?: Object |} => ({
  fnName: 'getEstimateLockWithEstimateIdRequest',
  basePath: '/location-analysis-api',
  payload: definitionsArguments.payload,
})


export const DATA_PUT_ESTIMATE_LOCK_WITH_ESTIMATEID_START = 'DATA_PUT_ESTIMATE_LOCK_WITH_ESTIMATEID_START'
export const DATA_PUT_ESTIMATE_LOCK_WITH_ESTIMATEID_SUCCESSFUL = 'DATA_PUT_ESTIMATE_LOCK_WITH_ESTIMATEID_SUCCESSFUL'
export const DATA_PUT_ESTIMATE_LOCK_WITH_ESTIMATEID_FAIL = 'DATA_PUT_ESTIMATE_LOCK_WITH_ESTIMATEID_FAIL'

// Obtain a lock to a resource for editing. Edits are not permitted before first obtaining a lock. If another user has already locked the resource, Resource Locked Error (423) is returned.
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/locan.yaml#/Location%20Analysis%20Estimate/put_estimate__estimateId__lock
export const putEstimateLockWithEstimateIdRequest = (
  requestArguments: putEstimateLockWithEstimateIdRequestLocanArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_PUT_ESTIMATE_LOCK_WITH_ESTIMATEID_START',
      successful: 'DATA_PUT_ESTIMATE_LOCK_WITH_ESTIMATEID_SUCCESSFUL',
      fail: 'DATA_PUT_ESTIMATE_LOCK_WITH_ESTIMATEID_FAIL'
    },
    embeddedType: null,
    fnName: 'putEstimateLockWithEstimateIdRequest'
  }
  const config = {
    headers: { 'Content-Type': 'application/json' },
    baseURL: `${API_BASE_URL}/location-analysis-api`,
    method: 'PUT',
    url: `/estimate/${options?.estimateId || store.app.calculation}/lock`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: requestArguments.body
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'putEstimateLockWithEstimateIdRequest', '/location-analysis-api'),
    actions: {
      start: {
        type: DATA_PUT_ESTIMATE_LOCK_WITH_ESTIMATEID_START, payload, config, meta
      },
      successful: {
        type: DATA_PUT_ESTIMATE_LOCK_WITH_ESTIMATEID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_PUT_ESTIMATE_LOCK_WITH_ESTIMATEID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_DELETE_ESTIMATE_LOCK_WITH_ESTIMATEID_START = 'DATA_DELETE_ESTIMATE_LOCK_WITH_ESTIMATEID_START'
export const DATA_DELETE_ESTIMATE_LOCK_WITH_ESTIMATEID_SUCCESSFUL = 'DATA_DELETE_ESTIMATE_LOCK_WITH_ESTIMATEID_SUCCESSFUL'
export const DATA_DELETE_ESTIMATE_LOCK_WITH_ESTIMATEID_FAIL = 'DATA_DELETE_ESTIMATE_LOCK_WITH_ESTIMATEID_FAIL'

// Deletes a resource lock. If the lock was created by another user, 423 error is returned.
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/locan.yaml#/Location%20Analysis%20Estimate/delete_estimate__estimateId__lock
export const deleteEstimateLockWithEstimateIdRequest = (
  payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_DELETE_ESTIMATE_LOCK_WITH_ESTIMATEID_START',
      successful: 'DATA_DELETE_ESTIMATE_LOCK_WITH_ESTIMATEID_SUCCESSFUL',
      fail: 'DATA_DELETE_ESTIMATE_LOCK_WITH_ESTIMATEID_FAIL'
    },
    embeddedType: null,
    fnName: 'deleteEstimateLockWithEstimateIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/location-analysis-api`,
    method: 'DELETE',
    url: `/estimate/${options?.estimateId || store.app.calculation}/lock`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'deleteEstimateLockWithEstimateIdRequest', '/location-analysis-api'),
    actions: {
      start: {
        type: DATA_DELETE_ESTIMATE_LOCK_WITH_ESTIMATEID_START, payload, config, meta
      },
      successful: {
        type: DATA_DELETE_ESTIMATE_LOCK_WITH_ESTIMATEID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_DELETE_ESTIMATE_LOCK_WITH_ESTIMATEID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_GET_ESTIMATE_PROPERTIES_INFO_WITH_PROPERTYNAME_START = 'DATA_GET_ESTIMATE_PROPERTIES_INFO_WITH_PROPERTYNAME_START'
export const DATA_GET_ESTIMATE_PROPERTIES_INFO_WITH_PROPERTYNAME_SUCCESSFUL = 'DATA_GET_ESTIMATE_PROPERTIES_INFO_WITH_PROPERTYNAME_SUCCESSFUL'
export const DATA_GET_ESTIMATE_PROPERTIES_INFO_WITH_PROPERTYNAME_FAIL = 'DATA_GET_ESTIMATE_PROPERTIES_INFO_WITH_PROPERTYNAME_FAIL'

// Get short information text for a estimate property
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/locan.yaml#/Location%20Analysis%20Estimate/get_estimate__estimateId__properties_info__propertyName_
export const getEstimatePropertiesInfoWithPropertyNameRequest = (
  requestArguments: getEstimatePropertiesInfoWithPropertyNameRequestLocanArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_ESTIMATE_PROPERTIES_INFO_WITH_PROPERTYNAME_START',
      successful: 'DATA_GET_ESTIMATE_PROPERTIES_INFO_WITH_PROPERTYNAME_SUCCESSFUL',
      fail: 'DATA_GET_ESTIMATE_PROPERTIES_INFO_WITH_PROPERTYNAME_FAIL'
    },
    embeddedType: null,
    fnName: 'getEstimatePropertiesInfoWithPropertyNameRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/location-analysis-api`,
    method: 'GET',
    url: `/estimate/${options?.estimateId || store.app.calculation}/properties/info/${requestArguments.path['propertyName']}`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'getEstimatePropertiesInfoWithPropertyNameRequest', '/location-analysis-api'),
    actions: {
      start: {
        type: DATA_GET_ESTIMATE_PROPERTIES_INFO_WITH_PROPERTYNAME_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_ESTIMATE_PROPERTIES_INFO_WITH_PROPERTYNAME_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_ESTIMATE_PROPERTIES_INFO_WITH_PROPERTYNAME_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getEstimatePropertiesInfoWithPropertyNameRequestDefinitions = (definitionsArguments: {| requestArgs: getEstimatePropertiesInfoWithPropertyNameRequestLocanArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getEstimatePropertiesInfoWithPropertyNameRequestLocanArguments, payload?: Object |} => ({
  fnName: 'getEstimatePropertiesInfoWithPropertyNameRequest',
  basePath: '/location-analysis-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_GET_ESTIMATE_PROPERTIES_WITH_ESTIMATEID_START = 'DATA_GET_ESTIMATE_PROPERTIES_WITH_ESTIMATEID_START'
export const DATA_GET_ESTIMATE_PROPERTIES_WITH_ESTIMATEID_SUCCESSFUL = 'DATA_GET_ESTIMATE_PROPERTIES_WITH_ESTIMATEID_SUCCESSFUL'
export const DATA_GET_ESTIMATE_PROPERTIES_WITH_ESTIMATEID_FAIL = 'DATA_GET_ESTIMATE_PROPERTIES_WITH_ESTIMATEID_FAIL'

// Returns properties of a Location Analysis Estimate
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/locan.yaml#/Location%20Analysis%20Estimate/get_estimate__estimateId__properties
export const getEstimatePropertiesWithEstimateIdRequest = (
  payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_ESTIMATE_PROPERTIES_WITH_ESTIMATEID_START',
      successful: 'DATA_GET_ESTIMATE_PROPERTIES_WITH_ESTIMATEID_SUCCESSFUL',
      fail: 'DATA_GET_ESTIMATE_PROPERTIES_WITH_ESTIMATEID_FAIL'
    },
    embeddedType: {
      properties: {
        schemaPath: '#/definitions/ValueProperty',
        schemaKey: 'ValueProperty'
      }
    },
    fnName: 'getEstimatePropertiesWithEstimateIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/location-analysis-api`,
    method: 'GET',
    url: `/estimate/${options?.estimateId || store.app.calculation}/properties`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      properties: {
        schemaPath: '#/definitions/ValueProperty',
        schemaKey: 'ValueProperty'
      }
    }, 'getEstimatePropertiesWithEstimateIdRequest', '/location-analysis-api'),
    actions: {
      start: {
        type: DATA_GET_ESTIMATE_PROPERTIES_WITH_ESTIMATEID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_ESTIMATE_PROPERTIES_WITH_ESTIMATEID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_ESTIMATE_PROPERTIES_WITH_ESTIMATEID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getEstimatePropertiesWithEstimateIdRequestDefinitions = (definitionsArguments: {| payload?: Object |}): {|
  ...TVDGARConfigWithoutRequestArgs, payload?: Object |} => ({
  fnName: 'getEstimatePropertiesWithEstimateIdRequest',
  basePath: '/location-analysis-api',
  payload: definitionsArguments.payload,
})


export const DATA_PATCH_ESTIMATE_PROPERTIES_WITH_ESTIMATEID_START = 'DATA_PATCH_ESTIMATE_PROPERTIES_WITH_ESTIMATEID_START'
export const DATA_PATCH_ESTIMATE_PROPERTIES_WITH_ESTIMATEID_SUCCESSFUL = 'DATA_PATCH_ESTIMATE_PROPERTIES_WITH_ESTIMATEID_SUCCESSFUL'
export const DATA_PATCH_ESTIMATE_PROPERTIES_WITH_ESTIMATEID_FAIL = 'DATA_PATCH_ESTIMATE_PROPERTIES_WITH_ESTIMATEID_FAIL'

// Update properties of a Location Analysis Estimate
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/locan.yaml#/Location%20Analysis%20Estimate/patch_estimate__estimateId__properties
export const patchEstimatePropertiesWithEstimateIdRequest = (
  requestArguments: patchEstimatePropertiesWithEstimateIdRequestLocanArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_PATCH_ESTIMATE_PROPERTIES_WITH_ESTIMATEID_START',
      successful: 'DATA_PATCH_ESTIMATE_PROPERTIES_WITH_ESTIMATEID_SUCCESSFUL',
      fail: 'DATA_PATCH_ESTIMATE_PROPERTIES_WITH_ESTIMATEID_FAIL'
    },
    embeddedType: null,
    fnName: 'patchEstimatePropertiesWithEstimateIdRequest'
  }
  const config = {
    headers: { 'Content-Type': 'application/json' },
    baseURL: `${API_BASE_URL}/location-analysis-api`,
    method: 'PATCH',
    url: `/estimate/${options?.estimateId || store.app.calculation}/properties`,
    params: {
      resetProperties: requestArguments.query?.['resetProperties'],
      updateBuildingServices: requestArguments.query?.['updateBuildingServices'],
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: requestArguments.body
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'patchEstimatePropertiesWithEstimateIdRequest', '/location-analysis-api'),
    actions: {
      start: {
        type: DATA_PATCH_ESTIMATE_PROPERTIES_WITH_ESTIMATEID_START, payload, config, meta
      },
      successful: {
        type: DATA_PATCH_ESTIMATE_PROPERTIES_WITH_ESTIMATEID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_PATCH_ESTIMATE_PROPERTIES_WITH_ESTIMATEID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_GET_ESTIMATE_LOCATION_WITH_ESTIMATEID_START = 'DATA_GET_ESTIMATE_LOCATION_WITH_ESTIMATEID_START'
export const DATA_GET_ESTIMATE_LOCATION_WITH_ESTIMATEID_SUCCESSFUL = 'DATA_GET_ESTIMATE_LOCATION_WITH_ESTIMATEID_SUCCESSFUL'
export const DATA_GET_ESTIMATE_LOCATION_WITH_ESTIMATEID_FAIL = 'DATA_GET_ESTIMATE_LOCATION_WITH_ESTIMATEID_FAIL'

// Returns location-related properties of a Location Analysis Estimate
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/locan.yaml#/Location%20Analysis%20Estimate/get_estimate__estimateId__location
export const getEstimateLocationWithEstimateIdRequest = (
  payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_ESTIMATE_LOCATION_WITH_ESTIMATEID_START',
      successful: 'DATA_GET_ESTIMATE_LOCATION_WITH_ESTIMATEID_SUCCESSFUL',
      fail: 'DATA_GET_ESTIMATE_LOCATION_WITH_ESTIMATEID_FAIL'
    },
    embeddedType: {
      nonEmbedded: {
        schemaPath: '#/definitions/Location',
        schemaKey: 'Location'
      }
    },
    fnName: 'getEstimateLocationWithEstimateIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/location-analysis-api`,
    method: 'GET',
    url: `/estimate/${options?.estimateId || store.app.calculation}/location`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      nonEmbedded: {
        schemaPath: '#/definitions/Location',
        schemaKey: 'Location'
      }
    }, 'getEstimateLocationWithEstimateIdRequest', '/location-analysis-api'),
    actions: {
      start: {
        type: DATA_GET_ESTIMATE_LOCATION_WITH_ESTIMATEID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_ESTIMATE_LOCATION_WITH_ESTIMATEID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_ESTIMATE_LOCATION_WITH_ESTIMATEID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getEstimateLocationWithEstimateIdRequestDefinitions = (definitionsArguments: {| payload?: Object |}): {|
  ...TVDGARConfigWithoutRequestArgs, payload?: Object |} => ({
  fnName: 'getEstimateLocationWithEstimateIdRequest',
  basePath: '/location-analysis-api',
  payload: definitionsArguments.payload,
})


export const DATA_PATCH_ESTIMATE_LOCATION_WITH_ESTIMATEID_START = 'DATA_PATCH_ESTIMATE_LOCATION_WITH_ESTIMATEID_START'
export const DATA_PATCH_ESTIMATE_LOCATION_WITH_ESTIMATEID_SUCCESSFUL = 'DATA_PATCH_ESTIMATE_LOCATION_WITH_ESTIMATEID_SUCCESSFUL'
export const DATA_PATCH_ESTIMATE_LOCATION_WITH_ESTIMATEID_FAIL = 'DATA_PATCH_ESTIMATE_LOCATION_WITH_ESTIMATEID_FAIL'

// Update location-related properties of a Location Analysis Estimate
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/locan.yaml#/Location%20Analysis%20Estimate/patch_estimate__estimateId__location
export const patchEstimateLocationWithEstimateIdRequest = (
  requestArguments: patchEstimateLocationWithEstimateIdRequestLocanArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_PATCH_ESTIMATE_LOCATION_WITH_ESTIMATEID_START',
      successful: 'DATA_PATCH_ESTIMATE_LOCATION_WITH_ESTIMATEID_SUCCESSFUL',
      fail: 'DATA_PATCH_ESTIMATE_LOCATION_WITH_ESTIMATEID_FAIL'
    },
    embeddedType: null,
    fnName: 'patchEstimateLocationWithEstimateIdRequest'
  }
  const config = {
    headers: { 'Content-Type': 'application/json' },
    baseURL: `${API_BASE_URL}/location-analysis-api`,
    method: 'PATCH',
    url: `/estimate/${options?.estimateId || store.app.calculation}/location`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: requestArguments.body
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'patchEstimateLocationWithEstimateIdRequest', '/location-analysis-api'),
    actions: {
      start: {
        type: DATA_PATCH_ESTIMATE_LOCATION_WITH_ESTIMATEID_START, payload, config, meta
      },
      successful: {
        type: DATA_PATCH_ESTIMATE_LOCATION_WITH_ESTIMATEID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_PATCH_ESTIMATE_LOCATION_WITH_ESTIMATEID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_GET_ESTIMATE_FUNCTIONAL_SECTORS_WITH_ESTIMATEID_START = 'DATA_GET_ESTIMATE_FUNCTIONAL_SECTORS_WITH_ESTIMATEID_START'
export const DATA_GET_ESTIMATE_FUNCTIONAL_SECTORS_WITH_ESTIMATEID_SUCCESSFUL = 'DATA_GET_ESTIMATE_FUNCTIONAL_SECTORS_WITH_ESTIMATEID_SUCCESSFUL'
export const DATA_GET_ESTIMATE_FUNCTIONAL_SECTORS_WITH_ESTIMATEID_FAIL = 'DATA_GET_ESTIMATE_FUNCTIONAL_SECTORS_WITH_ESTIMATEID_FAIL'

// Returns a list of Functional Sectors for a Location Analysis Estimate.
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/locan.yaml#/Location%20Analysis%20Estimate/get_estimate__estimateId__functional_sectors
export const getEstimateFunctionalSectorsWithEstimateIdRequest = (
  requestArguments: getEstimateFunctionalSectorsWithEstimateIdRequestLocanArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_ESTIMATE_FUNCTIONAL_SECTORS_WITH_ESTIMATEID_START',
      successful: 'DATA_GET_ESTIMATE_FUNCTIONAL_SECTORS_WITH_ESTIMATEID_SUCCESSFUL',
      fail: 'DATA_GET_ESTIMATE_FUNCTIONAL_SECTORS_WITH_ESTIMATEID_FAIL'
    },
    embeddedType: {
      items: {
        schemaPath: '#/definitions/ScheduleItem',
        schemaKey: 'ScheduleItem'
      }
    },
    fnName: 'getEstimateFunctionalSectorsWithEstimateIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/location-analysis-api`,
    method: 'GET',
    url: `/estimate/${options?.estimateId || store.app.calculation}/functional-sectors`,
    params: {
      listType: requestArguments.query['listType'],
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      items: {
        schemaPath: '#/definitions/ScheduleItem',
        schemaKey: 'ScheduleItem'
      }
    }, 'getEstimateFunctionalSectorsWithEstimateIdRequest', '/location-analysis-api'),
    actions: {
      start: {
        type: DATA_GET_ESTIMATE_FUNCTIONAL_SECTORS_WITH_ESTIMATEID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_ESTIMATE_FUNCTIONAL_SECTORS_WITH_ESTIMATEID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_ESTIMATE_FUNCTIONAL_SECTORS_WITH_ESTIMATEID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getEstimateFunctionalSectorsWithEstimateIdRequestDefinitions = (definitionsArguments: {| requestArgs: getEstimateFunctionalSectorsWithEstimateIdRequestLocanArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getEstimateFunctionalSectorsWithEstimateIdRequestLocanArguments, payload?: Object |} => ({
  fnName: 'getEstimateFunctionalSectorsWithEstimateIdRequest',
  basePath: '/location-analysis-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_POST_ESTIMATE_FUNCTIONAL_SECTORS_WITH_ESTIMATEID_START = 'DATA_POST_ESTIMATE_FUNCTIONAL_SECTORS_WITH_ESTIMATEID_START'
export const DATA_POST_ESTIMATE_FUNCTIONAL_SECTORS_WITH_ESTIMATEID_SUCCESSFUL = 'DATA_POST_ESTIMATE_FUNCTIONAL_SECTORS_WITH_ESTIMATEID_SUCCESSFUL'
export const DATA_POST_ESTIMATE_FUNCTIONAL_SECTORS_WITH_ESTIMATEID_FAIL = 'DATA_POST_ESTIMATE_FUNCTIONAL_SECTORS_WITH_ESTIMATEID_FAIL'

// Adds a new Functional Sector to a Location Analysis Estimate.
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/locan.yaml#/Location%20Analysis%20Estimate/post_estimate__estimateId__functional_sectors
export const postEstimateFunctionalSectorsWithEstimateIdRequest = (
  requestArguments: postEstimateFunctionalSectorsWithEstimateIdRequestLocanArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_POST_ESTIMATE_FUNCTIONAL_SECTORS_WITH_ESTIMATEID_START',
      successful: 'DATA_POST_ESTIMATE_FUNCTIONAL_SECTORS_WITH_ESTIMATEID_SUCCESSFUL',
      fail: 'DATA_POST_ESTIMATE_FUNCTIONAL_SECTORS_WITH_ESTIMATEID_FAIL'
    },
    embeddedType: null,
    fnName: 'postEstimateFunctionalSectorsWithEstimateIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/location-analysis-api`,
    method: 'POST',
    url: `/estimate/${options?.estimateId || store.app.calculation}/functional-sectors`,
    params: {
      registryId: requestArguments.query?.['registryId'],
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'postEstimateFunctionalSectorsWithEstimateIdRequest', '/location-analysis-api'),
    actions: {
      start: {
        type: DATA_POST_ESTIMATE_FUNCTIONAL_SECTORS_WITH_ESTIMATEID_START, payload, config, meta
      },
      successful: {
        type: DATA_POST_ESTIMATE_FUNCTIONAL_SECTORS_WITH_ESTIMATEID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_POST_ESTIMATE_FUNCTIONAL_SECTORS_WITH_ESTIMATEID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_GET_ESTIMATE_FUNCTIONAL_SECTORS_WITH_ID_START = 'DATA_GET_ESTIMATE_FUNCTIONAL_SECTORS_WITH_ID_START'
export const DATA_GET_ESTIMATE_FUNCTIONAL_SECTORS_WITH_ID_SUCCESSFUL = 'DATA_GET_ESTIMATE_FUNCTIONAL_SECTORS_WITH_ID_SUCCESSFUL'
export const DATA_GET_ESTIMATE_FUNCTIONAL_SECTORS_WITH_ID_FAIL = 'DATA_GET_ESTIMATE_FUNCTIONAL_SECTORS_WITH_ID_FAIL'

// Returns a Functional Sector for a Location Analysis Estimate.
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/locan.yaml#/Location%20Analysis%20Estimate/get_estimate__estimateId__functional_sectors__id_
export const getEstimateFunctionalSectorsWithIdRequest = (
  requestArguments: getEstimateFunctionalSectorsWithIdRequestLocanArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_ESTIMATE_FUNCTIONAL_SECTORS_WITH_ID_START',
      successful: 'DATA_GET_ESTIMATE_FUNCTIONAL_SECTORS_WITH_ID_SUCCESSFUL',
      fail: 'DATA_GET_ESTIMATE_FUNCTIONAL_SECTORS_WITH_ID_FAIL'
    },
    embeddedType: {
      nonEmbedded: {
        schemaPath: '#/definitions/ScheduleItem',
        schemaKey: 'ScheduleItem'
      }
    },
    fnName: 'getEstimateFunctionalSectorsWithIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/location-analysis-api`,
    method: 'GET',
    url: `/estimate/${options?.estimateId || store.app.calculation}/functional-sectors/${requestArguments.path['id']}`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      nonEmbedded: {
        schemaPath: '#/definitions/ScheduleItem',
        schemaKey: 'ScheduleItem'
      }
    }, 'getEstimateFunctionalSectorsWithIdRequest', '/location-analysis-api'),
    actions: {
      start: {
        type: DATA_GET_ESTIMATE_FUNCTIONAL_SECTORS_WITH_ID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_ESTIMATE_FUNCTIONAL_SECTORS_WITH_ID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_ESTIMATE_FUNCTIONAL_SECTORS_WITH_ID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getEstimateFunctionalSectorsWithIdRequestDefinitions = (definitionsArguments: {| requestArgs: getEstimateFunctionalSectorsWithIdRequestLocanArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getEstimateFunctionalSectorsWithIdRequestLocanArguments, payload?: Object |} => ({
  fnName: 'getEstimateFunctionalSectorsWithIdRequest',
  basePath: '/location-analysis-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_DELETE_ESTIMATE_FUNCTIONAL_SECTORS_WITH_ID_START = 'DATA_DELETE_ESTIMATE_FUNCTIONAL_SECTORS_WITH_ID_START'
export const DATA_DELETE_ESTIMATE_FUNCTIONAL_SECTORS_WITH_ID_SUCCESSFUL = 'DATA_DELETE_ESTIMATE_FUNCTIONAL_SECTORS_WITH_ID_SUCCESSFUL'
export const DATA_DELETE_ESTIMATE_FUNCTIONAL_SECTORS_WITH_ID_FAIL = 'DATA_DELETE_ESTIMATE_FUNCTIONAL_SECTORS_WITH_ID_FAIL'

// Delete a Functional Sector. Will queue calculation if needed
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/locan.yaml#/Location%20Analysis%20Estimate/delete_estimate__estimateId__functional_sectors__id_
export const deleteEstimateFunctionalSectorsWithIdRequest = (
  requestArguments: deleteEstimateFunctionalSectorsWithIdRequestLocanArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_DELETE_ESTIMATE_FUNCTIONAL_SECTORS_WITH_ID_START',
      successful: 'DATA_DELETE_ESTIMATE_FUNCTIONAL_SECTORS_WITH_ID_SUCCESSFUL',
      fail: 'DATA_DELETE_ESTIMATE_FUNCTIONAL_SECTORS_WITH_ID_FAIL'
    },
    embeddedType: null,
    fnName: 'deleteEstimateFunctionalSectorsWithIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/location-analysis-api`,
    method: 'DELETE',
    url: `/estimate/${options?.estimateId || store.app.calculation}/functional-sectors/${requestArguments.path['id']}`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'deleteEstimateFunctionalSectorsWithIdRequest', '/location-analysis-api'),
    actions: {
      start: {
        type: DATA_DELETE_ESTIMATE_FUNCTIONAL_SECTORS_WITH_ID_START, payload, config, meta
      },
      successful: {
        type: DATA_DELETE_ESTIMATE_FUNCTIONAL_SECTORS_WITH_ID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_DELETE_ESTIMATE_FUNCTIONAL_SECTORS_WITH_ID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_PATCH_ESTIMATE_FUNCTIONAL_SECTORS_WITH_ID_START = 'DATA_PATCH_ESTIMATE_FUNCTIONAL_SECTORS_WITH_ID_START'
export const DATA_PATCH_ESTIMATE_FUNCTIONAL_SECTORS_WITH_ID_SUCCESSFUL = 'DATA_PATCH_ESTIMATE_FUNCTIONAL_SECTORS_WITH_ID_SUCCESSFUL'
export const DATA_PATCH_ESTIMATE_FUNCTIONAL_SECTORS_WITH_ID_FAIL = 'DATA_PATCH_ESTIMATE_FUNCTIONAL_SECTORS_WITH_ID_FAIL'

// Update column data of a Functional Sector
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/locan.yaml#/Location%20Analysis%20Estimate/patch_estimate__estimateId__functional_sectors__id_
export const patchEstimateFunctionalSectorsWithIdRequest = (
  requestArguments: patchEstimateFunctionalSectorsWithIdRequestLocanArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_PATCH_ESTIMATE_FUNCTIONAL_SECTORS_WITH_ID_START',
      successful: 'DATA_PATCH_ESTIMATE_FUNCTIONAL_SECTORS_WITH_ID_SUCCESSFUL',
      fail: 'DATA_PATCH_ESTIMATE_FUNCTIONAL_SECTORS_WITH_ID_FAIL'
    },
    embeddedType: null,
    fnName: 'patchEstimateFunctionalSectorsWithIdRequest'
  }
  const config = {
    headers: { 'Content-Type': 'application/json' },
    baseURL: `${API_BASE_URL}/location-analysis-api`,
    method: 'PATCH',
    url: `/estimate/${options?.estimateId || store.app.calculation}/functional-sectors/${requestArguments.path['id']}`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: requestArguments.body
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'patchEstimateFunctionalSectorsWithIdRequest', '/location-analysis-api'),
    actions: {
      start: {
        type: DATA_PATCH_ESTIMATE_FUNCTIONAL_SECTORS_WITH_ID_START, payload, config, meta
      },
      successful: {
        type: DATA_PATCH_ESTIMATE_FUNCTIONAL_SECTORS_WITH_ID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_PATCH_ESTIMATE_FUNCTIONAL_SECTORS_WITH_ID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_GET_ESTIMATE_SUMMARY_WITH_ESTIMATEID_START = 'DATA_GET_ESTIMATE_SUMMARY_WITH_ESTIMATEID_START'
export const DATA_GET_ESTIMATE_SUMMARY_WITH_ESTIMATEID_SUCCESSFUL = 'DATA_GET_ESTIMATE_SUMMARY_WITH_ESTIMATEID_SUCCESSFUL'
export const DATA_GET_ESTIMATE_SUMMARY_WITH_ESTIMATEID_FAIL = 'DATA_GET_ESTIMATE_SUMMARY_WITH_ESTIMATEID_FAIL'

// Returns read-only, summary information for a Location Analysis Estimate.
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/locan.yaml#/Location%20Analysis%20Estimate/get_estimate__estimateId__summary
export const getEstimateSummaryWithEstimateIdRequest = (
  requestArguments: getEstimateSummaryWithEstimateIdRequestLocanArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_ESTIMATE_SUMMARY_WITH_ESTIMATEID_START',
      successful: 'DATA_GET_ESTIMATE_SUMMARY_WITH_ESTIMATEID_SUCCESSFUL',
      fail: 'DATA_GET_ESTIMATE_SUMMARY_WITH_ESTIMATEID_FAIL'
    },
    embeddedType: {
      summary: {
        schemaPath: '#/definitions/ValueProperty',
        schemaKey: 'ValueProperty'
      }
    },
    fnName: 'getEstimateSummaryWithEstimateIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/location-analysis-api`,
    method: 'GET',
    url: `/estimate/${options?.estimateId || store.app.calculation}/summary`,
    params: {
      view: requestArguments.query?.['view'],
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      summary: {
        schemaPath: '#/definitions/ValueProperty',
        schemaKey: 'ValueProperty'
      }
    }, 'getEstimateSummaryWithEstimateIdRequest', '/location-analysis-api'),
    actions: {
      start: {
        type: DATA_GET_ESTIMATE_SUMMARY_WITH_ESTIMATEID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_ESTIMATE_SUMMARY_WITH_ESTIMATEID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_ESTIMATE_SUMMARY_WITH_ESTIMATEID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getEstimateSummaryWithEstimateIdRequestDefinitions = (definitionsArguments: {| requestArgs: getEstimateSummaryWithEstimateIdRequestLocanArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getEstimateSummaryWithEstimateIdRequestLocanArguments, payload?: Object |} => ({
  fnName: 'getEstimateSummaryWithEstimateIdRequest',
  basePath: '/location-analysis-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_GET_PRICING_AREAS_START = 'DATA_GET_PRICING_AREAS_START'
export const DATA_GET_PRICING_AREAS_SUCCESSFUL = 'DATA_GET_PRICING_AREAS_SUCCESSFUL'
export const DATA_GET_PRICING_AREAS_FAIL = 'DATA_GET_PRICING_AREAS_FAIL'

// Get pricing areas as GeoJSON.
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/locan.yaml#/Pricing%20Areas/get_pricing_areas
export const getPricingAreasRequest = (
  requestArguments: getPricingAreasRequestLocanArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_PRICING_AREAS_START',
      successful: 'DATA_GET_PRICING_AREAS_SUCCESSFUL',
      fail: 'DATA_GET_PRICING_AREAS_FAIL'
    },
    embeddedType: {
      nonEmbedded: {
        schemaPath: '#/definitions/FeatureCollection',
        schemaKey: 'FeatureCollection'
      }
    },
    fnName: 'getPricingAreasRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/location-analysis-api`,
    method: 'GET',
    url: '/pricing-areas',
    params: {
      bbox: requestArguments.query['bbox'],
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      nonEmbedded: {
        schemaPath: '#/definitions/FeatureCollection',
        schemaKey: 'FeatureCollection'
      }
    }, 'getPricingAreasRequest', '/location-analysis-api'),
    actions: {
      start: {
        type: DATA_GET_PRICING_AREAS_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_PRICING_AREAS_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_PRICING_AREAS_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getPricingAreasRequestDefinitions = (definitionsArguments: {| requestArgs: getPricingAreasRequestLocanArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getPricingAreasRequestLocanArguments, payload?: Object |} => ({
  fnName: 'getPricingAreasRequest',
  basePath: '/location-analysis-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_GET_ENUM_WITH_ENUM_START = 'DATA_GET_ENUM_WITH_ENUM_START'
export const DATA_GET_ENUM_WITH_ENUM_SUCCESSFUL = 'DATA_GET_ENUM_WITH_ENUM_SUCCESSFUL'
export const DATA_GET_ENUM_WITH_ENUM_FAIL = 'DATA_GET_ENUM_WITH_ENUM_FAIL'

// Returns members of given enum as embedded Enumerals
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/locan.yaml#/Enum/get_enum__enum_
export const getEnumWithEnumRequest = (
  requestArguments: getEnumWithEnumRequestLocanArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_ENUM_WITH_ENUM_START',
      successful: 'DATA_GET_ENUM_WITH_ENUM_SUCCESSFUL',
      fail: 'DATA_GET_ENUM_WITH_ENUM_FAIL'
    },
    embeddedType: {
      nonEmbedded: {
        schemaPath: '#/definitions/Enumeral',
        schemaKey: 'Enumeral'
      }
    },
    fnName: 'getEnumWithEnumRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/location-analysis-api`,
    method: 'GET',
    url: `/enum/${requestArguments.path['enumParam']}`,
    params: {
      category: requestArguments.query?.['category'],
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      nonEmbedded: {
        schemaPath: '#/definitions/Enumeral',
        schemaKey: 'Enumeral'
      }
    }, 'getEnumWithEnumRequest', '/location-analysis-api'),
    actions: {
      start: {
        type: DATA_GET_ENUM_WITH_ENUM_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_ENUM_WITH_ENUM_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_ENUM_WITH_ENUM_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getEnumWithEnumRequestDefinitions = (definitionsArguments: {| requestArgs: getEnumWithEnumRequestLocanArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getEnumWithEnumRequestLocanArguments, payload?: Object |} => ({
  fnName: 'getEnumWithEnumRequest',
  basePath: '/location-analysis-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_GET_SWAGGER_START = 'DATA_GET_SWAGGER_START'
export const DATA_GET_SWAGGER_SUCCESSFUL = 'DATA_GET_SWAGGER_SUCCESSFUL'
export const DATA_GET_SWAGGER_FAIL = 'DATA_GET_SWAGGER_FAIL'

// Returns Swagger specification of the API
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/locan.yaml#/Swagger/get_swagger
export const getSwaggerRequest = (
  payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_SWAGGER_START',
      successful: 'DATA_GET_SWAGGER_SUCCESSFUL',
      fail: 'DATA_GET_SWAGGER_FAIL'
    },
    embeddedType: null,
    fnName: 'getSwaggerRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/location-analysis-api`,
    method: 'GET',
    url: '/swagger',
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'getSwaggerRequest', '/location-analysis-api'),
    actions: {
      start: {
        type: DATA_GET_SWAGGER_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_SWAGGER_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_SWAGGER_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getSwaggerRequestDefinitions = (definitionsArguments: {| payload?: Object |}): {|
  ...TVDGARConfigWithoutRequestArgs, payload?: Object |} => ({
  fnName: 'getSwaggerRequest',
  basePath: '/location-analysis-api',
  payload: definitionsArguments.payload,
})


export const DATA_GET_SWAGGER_WITH_NODE_START = 'DATA_GET_SWAGGER_WITH_NODE_START'
export const DATA_GET_SWAGGER_WITH_NODE_SUCCESSFUL = 'DATA_GET_SWAGGER_WITH_NODE_SUCCESSFUL'
export const DATA_GET_SWAGGER_WITH_NODE_FAIL = 'DATA_GET_SWAGGER_WITH_NODE_FAIL'

// Returns a first level node from the Swagger specification of the API
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/locan.yaml#/Swagger/get_swagger__node_
export const getSwaggerWithNodeRequest = (
  requestArguments: getSwaggerWithNodeRequestLocanArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_SWAGGER_WITH_NODE_START',
      successful: 'DATA_GET_SWAGGER_WITH_NODE_SUCCESSFUL',
      fail: 'DATA_GET_SWAGGER_WITH_NODE_FAIL'
    },
    embeddedType: null,
    fnName: 'getSwaggerWithNodeRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/location-analysis-api`,
    method: 'GET',
    url: `/swagger/${requestArguments.path['node']}`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'getSwaggerWithNodeRequest', '/location-analysis-api'),
    actions: {
      start: {
        type: DATA_GET_SWAGGER_WITH_NODE_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_SWAGGER_WITH_NODE_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_SWAGGER_WITH_NODE_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getSwaggerWithNodeRequestDefinitions = (definitionsArguments: {| requestArgs: getSwaggerWithNodeRequestLocanArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getSwaggerWithNodeRequestLocanArguments, payload?: Object |} => ({
  fnName: 'getSwaggerWithNodeRequest',
  basePath: '/location-analysis-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_GET_SWAGGER_WITH_CHILDNODE_START = 'DATA_GET_SWAGGER_WITH_CHILDNODE_START'
export const DATA_GET_SWAGGER_WITH_CHILDNODE_SUCCESSFUL = 'DATA_GET_SWAGGER_WITH_CHILDNODE_SUCCESSFUL'
export const DATA_GET_SWAGGER_WITH_CHILDNODE_FAIL = 'DATA_GET_SWAGGER_WITH_CHILDNODE_FAIL'

// Returns a second level node from the Swagger specification of the API
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/locan.yaml#/Swagger/get_swagger__node___childNode_
export const getSwaggerWithChildNodeRequest = (
  requestArguments: getSwaggerWithChildNodeRequestLocanArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_SWAGGER_WITH_CHILDNODE_START',
      successful: 'DATA_GET_SWAGGER_WITH_CHILDNODE_SUCCESSFUL',
      fail: 'DATA_GET_SWAGGER_WITH_CHILDNODE_FAIL'
    },
    embeddedType: null,
    fnName: 'getSwaggerWithChildNodeRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/location-analysis-api`,
    method: 'GET',
    url: `/swagger/${requestArguments.path['node']}/${requestArguments.path['childNode']}`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'getSwaggerWithChildNodeRequest', '/location-analysis-api'),
    actions: {
      start: {
        type: DATA_GET_SWAGGER_WITH_CHILDNODE_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_SWAGGER_WITH_CHILDNODE_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_SWAGGER_WITH_CHILDNODE_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getSwaggerWithChildNodeRequestDefinitions = (definitionsArguments: {| requestArgs: getSwaggerWithChildNodeRequestLocanArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getSwaggerWithChildNodeRequestLocanArguments, payload?: Object |} => ({
  fnName: 'getSwaggerWithChildNodeRequest',
  basePath: '/location-analysis-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})
