// @flow
// Copyright © 2010–2022 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.
import React from 'react'
import { connect, useSelector } from 'react-redux'
import { compose } from 'redux'
import MUITabs from '@material-ui/core/Tabs'
import { useTranslation } from 'react-i18next'
import MUITab from '@material-ui/core/Tab'
import { boxShadows } from 'frontend-assets'
import { makeStyles } from '@material-ui/core/styles'
import { bodyDefaultBold } from '../../../../node_modules/frontend-assets/src/theme/typographyClasses'
import { REAL_ESTATE_MANAGER, ACCOUNT_MANAGER } from '../../../constants/privileges'

type ReceivedProps = {|
  onChange: (selectedTabIndex: number) => void, // returns the selected tab index
|}

type MappedProps = {|
  userPrivileges: $PropertyType<TVDUserStore, 'userPrivileges'>
|}

type Props = {
  ...ReceivedProps,
  ...MappedProps
}

const useStyles = makeStyles(({ palette }: TVDTheme) => ({
  tabRoot: {
    ...bodyDefaultBold,
    color: palette.dark80,
    width: 218,
    borderTop: '3px solid transparent',
    borderBottom: '3px solid transparent',
    textTransform: 'none',
    opacity: 1,
    '&:hover': {
      borderBottom: `3px solid ${palette.dark60}`
    }
  },
  tabsBar: {
    backgroundColor: palette.white,
    color: palette.dark80,
    boxShadow: boxShadows.boxShadowComponent
  },
  selectedTab: {
    color: palette.primary100
  },
  tabPrimary: {
    color: palette.dark80,
  },
  indicator: {
    backgroundColor: palette.primary100,
    height: 3
  },
  tabsRoot: {
    width: '100%'
  }
}))

const HeaderTabs = ({ onChange, userPrivileges }: Props): React$Element<typeof MUITabs> => {
  const classes = useStyles()
  const tabClasses = {
    root: classes.tabRoot,
    textColorPrimary: classes.tabPrimary,
    selected: classes.selectedTab
  }
  const mainViewActiveIndex = useSelector(({
    view
  }: TVDReduxStore): $PropertyType<TVDViewStore, 'mainViewActiveIndex'> => view.mainViewActiveIndex)
  const { t } = useTranslation()
  const hasAccountInformationAccess = userPrivileges.find(({ value }: TVDEnum): boolean => value === REAL_ESTATE_MANAGER || value === ACCOUNT_MANAGER)

  return (
    <MUITabs
      classes={{ root: classes.tabsRoot, indicator: classes.indicator }}
      value={mainViewActiveIndex || 0}
      centered
      onChange={(event: SyntheticEvent<any>, tabIndex: number): void => {
        onChange(tabIndex)
      }}>
      <MUITab
        data-testid='HeaderTabs-real-estates'
        classes={tabClasses}
        label={t('mainView.realEstates')}
        disableRipple />
      {hasAccountInformationAccess &&
        <MUITab
          data-testid='HeaderTabs-account-information'
          classes={tabClasses}
          label={t('mainView.accountInformation')}
          disableRipple />}
    </MUITabs>
  )
}

const mapStateToProps = ({ user: { userPrivileges } }: TVDReduxStore): MappedProps => ({ userPrivileges })

export default compose(connect(mapStateToProps))(HeaderTabs)
