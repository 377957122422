// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withTranslation } from 'react-i18next'
import { withStyles } from '@material-ui/core/styles'
import { map } from 'lodash'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'

import InputField from '../../../common/InputField/InputField'
import DropDownContainer from '../../DropDownContainer/DropDownContainer'
import Switch from '../../../common/Switch/Switch'
import InfoPopover from '../../../common/InfoPopover/InfoPopover'
import FeaturesHOC from '../../../hocs/FeaturesHOC/FeaturesHOC'
import SentientHOC from '../../../hocs/SentientHOC/SentientHOC'
import Feature from '../../Feature/Feature'

import { getPriceitemPropertiesWithIdRequestDefinitions } from '../../../../utils/generated-api-requests/buildingelements'
import {
  getEstimateEquipmentPropertiesWithEquipmentIdRequestDefinitions,
  getSpacesEquipmentPropertiesWithIdRequestDefinitions,
  getSpacesSurfacesPropertiesWithIdRequestDefinitions
} from '../../../../utils/generated-api-requests/spaces'
import { PRICEITEM, EQUIPMENT } from '../../../../constants/contentTypes'
import {
  markAsModified,
  clearProperties,
  editProperty
} from '../../../../actions/widgets/index'
import { ELEMENT_INFO_POPOVER, ELEMENT_INPUTS } from '../../../../constants/features'
import ViewHeaderText from '../../../common/ViewHeaderText/ViewHeaderText'

const styles = ({ palette }: TVDTheme) => ({
  table: {
    width: '100%',
    borderTop: `1px solid ${palette.porcelain}`,
  },
  row: {
    borderBottom: `1px solid ${palette.porcelain}`,
    alignItems: 'center',
    height: '40px',
    '&:hover [data-visible_on_hover]': {
      visibility: 'visible',
    },
    display: 'flex',
    width: '950px'
  },
  cell: {
    width: '50%',
    border: '0',
    color: palette.nevada,
    fontSize: '14px',
    lineHeight: '20px',
    whiteSpace: 'nowrap',
    padding: '0 16px'
  },
  valueCell: {
    padding: 0,
    color: 'grey',
    border: '0px',
    whiteSpace: 'nowrap',
  },
  infoIcon: {
    display: 'flex',
    alignItems: 'center',
    visibility: 'hidden',
    marginLeft: '10px'
  },
  paddedInputWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '145px'
  },
  valueCellWrapper: {
    display: 'flex',
  },
  cellContent: {
    display: 'flex',
  },
  unit: {
    color: palette.manatee,
    marginLeft: '5px',
    lineHeight: '1.5'
  },
  tableWrapper: {
    overflow: 'auto'
  },
  mainWrapper: {
    overflow: 'hidden',
    flexDirection: 'column',
    display: 'flex',
  }
})


type DispatchProps = {|
  dispatchClearProperties: () => void, // clears current properties from Store (properties) by resourceListId prop
  dispatchEditProperty: (string, string | number | boolean) => void, // posts attribute's new value to API
|}

type MappedProps = {|
  application: string, // current active module e.g SPACES
  modified: boolean, // determines if Element is modified
  widgetType: string, // the constant type string value of the widget
  disabled: boolean, // flag to disable widget content if other widgets are beign edited
  propertiesData: Object, // propertiesData object
  isEstimateLockedToCurrentUser: $PropertyType<TVDApplicationStore, 'isEstimateLockedToCurrentUser'>, // if the user owns the lock for the estimate
  isEstimateFrozen: $PropertyType<TVDApplicationStore, 'isEstimateFrozen'>, // if estimate is frozen
|}

type Props = {|
  classes: Object, // withStyles classes object
  t: Function, // translate function
  features: TVDFeatureHOCProps, // features from Store and helper functions from the HOC
  sentient: TVDSentient, // Object providing helpers via SentientHOC
  priceItemId: string, // id of priceitem that elements widget represents,
  equipmentId: string, // id of equipment if element widget was opened from space equipments list
  spaceId: string, // id of space if element widget was opened from space equipment list
  ...DispatchProps,
  ...MappedProps
|}

type State = {
  attributes: Object, //  object that includes attributes
  modifiedAttributes: Object, //  object that includes key value pairs of modified attributes
}

export class Element extends Component<Props, State> {
  static defaultProps = {
    isEstimateLockedToCurrentUser: false,
    getData: null
  }

  componentDidMount() {
    const { sentient } = this.props
    sentient.runSetup()
  }

  componentWillUnmount() {
    this.props.dispatchClearProperties()
  }

  getInfoPopoverInfoData = (row: Object): Object => {
    const { propertyName } = row
    const {
      spaceId,
      equipmentId,
      priceItemId,
      widgetType
    } = this.props

    // infoPopover info request requires different itemId prop
    // depending in which module the element widget was opened
    const itemIdsByWidgetType = {
      [PRICEITEM]: priceItemId,
      [EQUIPMENT]: equipmentId
    }

    return {
      spaceId,
      id: itemIdsByWidgetType[widgetType],
      type: widgetType,
      heading: row.localizedName,
      propertyName
    }
  }

  disabled(): boolean {
    const {
      disabled,
      isEstimateLockedToCurrentUser,
      features,
      isEstimateFrozen
    } = this.props
    return disabled ||
      !isEstimateLockedToCurrentUser ||
      features.getIsFeatureDisabled(ELEMENT_INPUTS) ||
      !!isEstimateFrozen
  }

  table(): React$Element<any> {
    const {
      classes,
      propertiesData,
      application,
    } = this.props

    return (
      propertiesData &&
        <Table className={classes.table}>
          <TableBody>
            {
              map(propertiesData, (row: Object) => {
                const {
                  _links: {
                    info: {
                      href: linksInfoHref
                    } = {}
                  } = {}
                } = row
                return (
                  <TableRow
                    data-testid={`Element-attribute-row-${row.propertyName}`}
                    className={classes.row}
                    key={row.propertyName}>
                    <TableCell className={classes.cell}>
                      <div className={classes.cellContent}>
                        {row.localizedName }
                      </div>
                    </TableCell>
                    <TableCell className={classes.valueCell}>
                      <div className={classes.valueCellWrapper}>
                        {this.getInput(row)}
                        {
                      !!linksInfoHref &&
                      <div data-visible_on_hover className={classes.infoIcon}>
                        <Feature name={ELEMENT_INFO_POPOVER}>
                          <InfoPopover
                            application={application}
                            infoData={{ ...this.getInfoPopoverInfoData(row) }}
                            id={`${row.propertyName}-InfoPopover`} />
                        </Feature>
                      </div>
                    }
                      </div>
                    </TableCell>
                  </TableRow>
                )
            })
          }
          </TableBody>
        </Table>
    )
  }

  getInput(row: Object): React$Element<any> {
    const { dispatchEditProperty } = this.props
    const { dataType, propertyName, enumRequestDefinitions } = row
    const {
      classes
    } = this.props
    switch (dataType) {
      case 'bool':
      case 'boolean':
        return ((
          <Switch
            onChange={(value: boolean) => dispatchEditProperty(propertyName, value)}
            initialState={row.value}
            disabled={this.disabled()}
            testId={`Attribute-${propertyName}`} />
        ))
      case 'enum':
        return (
          <div className={classes.paddedInputWrapper}>
            <DropDownContainer
              requestDefinition={enumRequestDefinitions}
              width='XXL'
              minimalist
              onDropDownChange={(selected: string) => {
                dispatchEditProperty(row.propertyName, selected)
              }}
              defaultValue={row.value}
              value={row.value}
              disabled={this.disabled()}
              id={`Attribute-${propertyName}`} />
          </div>
        )
      case 'double':
      case 'string':
      case 'int':
      case 'integer':
      case 'number':
        return (
          <div className={classes.paddedInputWrapper}>
            <InputField
              dataType={row.dataType}
              disabled={this.disabled()}
              width={145}
              unit={row.unit}
              onChange={(value: number) => { dispatchEditProperty(row.propertyName, value) }}
              initialValue={row.value}
              id={`Property-${propertyName}`} />
          </div>
        )
      default:
        return <div>{dataType}</div>
    }
  }

  render(): React$Element<any> {
    const { classes, t } = this.props
    return (
      <div className={classes.mainWrapper}>
        <ViewHeaderText inWidget>{t('widgets._ATTRIBUTES_')}</ViewHeaderText>
        <div className={classes.tableWrapper}>
          { this.table() }
        </div>
      </div>
    )
  }
}

type ownProps = {|
  propertiesStoreId: string, // key to be used to retrieve specific properties from Store
  widgetId: string, // key to be used to retrieve specific widget's content from Store
  modified: boolean, // determines if Element is modified
|}

const mapStateToProps = ({
  widgets, properties, app
}: TVDReduxStore, { propertiesStoreId, widgetId }: ownProps): MappedProps => {
  const { modified, disabled, widgetType } = widgets[widgetId] || {}
  const { isEstimateLockedToCurrentUser, application, isEstimateFrozen } = app
  return {
    modified,
    disabled,
    widgetType,
    propertiesData: properties[propertiesStoreId],
    isEstimateLockedToCurrentUser,
    application,
    isEstimateFrozen
  }
}

function mapDispatchToProps(dispatch: Function, props: ownProps): DispatchProps {
  const {
    modified,
    propertiesStoreId,
    widgetId,
  } = props

  return {
    dispatchEditProperty: (property: string, value: string | number | boolean) => {
      dispatch(editProperty(propertiesStoreId, property, value))
      if (!modified) dispatch(markAsModified(widgetId))
    },
    dispatchClearProperties: () => {
      dispatch(clearProperties(propertiesStoreId))
    }
  }
}

type SentientProps = {|
  ...Props,
  equipmentId?: string, // when rendering Equipment, we require equipmentId to be used for API GET requests
  surfaceId?: string, // when rendering a surface Equipment, we require surfaceId to be used for API GET requests
  propertiesStoreId: string, // key to the properties Store object where the properties is stored
|}

const sentientConfig: TVDSentientConfig = {
  getSetupRequestDefinitions: (store: Object, {
    widgetType,
    propertiesStoreId,
    priceItemId = '',
    surfaceId = '',
    equipmentId = '',
    spaceId = ''
  }: SentientProps): TVDGARConfigs => {
    switch (widgetType) {
      case PRICEITEM: {
        return {
          getPriceitemPropertiesWithIdRequestDefinitions: getPriceitemPropertiesWithIdRequestDefinitions({
            payload: { propertiesStoreId },
            requestArgs: { path: { id: priceItemId } }
          })
        }
      }
      case EQUIPMENT: {
        switch (true) {
          case !!surfaceId: {
            return {
              getSpacesSurfacesPropertiesWithIdRequestDefinitions: getSpacesSurfacesPropertiesWithIdRequestDefinitions({
                payload: { propertiesStoreId },
                requestArgs: { path: { id: surfaceId, spaceId } }
              })
            }
          }
          case !!spaceId: {
            return {
              getSpacesEquipmentPropertiesWithIdRequestDefinitions: getSpacesEquipmentPropertiesWithIdRequestDefinitions({
                requestArgs: { path: { spaceId, id: equipmentId } },
                payload: { propertiesStoreId }
              })
            }
          }
          case !!equipmentId:
            return {
              getEstimateEquipmentPropertiesWithEquipmentIdRequestDefinitions: getEstimateEquipmentPropertiesWithEquipmentIdRequestDefinitions({
                payload: { propertiesStoreId },
                requestArgs: { path: { equipmentId } }
              })
            }
          default: {
            throw new Error('No valid request argument id provided for Element sentient configurations')
          }
        }
      }
      default: {
        throw new Error('No valid widget type provided for Element sentient configurations')
      }
    }
  },

}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation('translations'),
  withStyles(styles),
  FeaturesHOC
)(SentientHOC(Element, sentientConfig))
