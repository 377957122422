// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.
/* eslint max-len: ["error", { "code": 350 }] */
/*
  Haahtela Estimates Api 26.0.0
  This file will be deprecated in the near future, refer to openapi.yaml instead.
  192.168.254.119:5005
*/

import requestFn from './../requests/request'
import { API_BASE_URL } from './../../constants/envConstants'
import parserMapper from './../parserMapper'
import { getState } from '../../store'

export const basePath = '/estimates-api'

export const DATA_GET_BUILDINGS_START = 'DATA_GET_BUILDINGS_START'
export const DATA_GET_BUILDINGS_SUCCESSFUL = 'DATA_GET_BUILDINGS_SUCCESSFUL'
export const DATA_GET_BUILDINGS_FAIL = 'DATA_GET_BUILDINGS_FAIL'

// Returns an embedded list of Buildings for an Account.
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/estimates.yaml#/Buildings/get_buildings
export const getBuildingsRequest = (
  requestArguments: getBuildingsRequestEstimatesArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_BUILDINGS_START',
      successful: 'DATA_GET_BUILDINGS_SUCCESSFUL',
      fail: 'DATA_GET_BUILDINGS_FAIL'
    },
    embeddedType: {
      buildings: {
        schemaPath: '#/definitions/Building',
        schemaKey: 'Building'
      }
    },
    fnName: 'getBuildingsRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}${options?.basePath || '/estimates-api'}`,
    method: 'GET',
    url: '/buildings',
    params: {
      accountId: options?.selectedAccountId || store.app.selectedAccountId,
      permissionsSubject: requestArguments.query?.['permissionsSubject'],
      language: options?.languageCode || store.app.languageCode
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      buildings: {
        schemaPath: '#/definitions/Building',
        schemaKey: 'Building'
      }
    }, 'getBuildingsRequest', '/estimates-api'),
    actions: {
      start: {
        type: DATA_GET_BUILDINGS_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_BUILDINGS_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_BUILDINGS_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getBuildingsRequestDefinitions = (definitionsArguments: {| requestArgs: getBuildingsRequestEstimatesArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getBuildingsRequestEstimatesArguments, payload?: Object |} => ({
  fnName: 'getBuildingsRequest',
  basePath: '/estimates-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_POST_BUILDINGS_START = 'DATA_POST_BUILDINGS_START'
export const DATA_POST_BUILDINGS_SUCCESSFUL = 'DATA_POST_BUILDINGS_SUCCESSFUL'
export const DATA_POST_BUILDINGS_FAIL = 'DATA_POST_BUILDINGS_FAIL'

// Create a new building
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/estimates.yaml#/Buildings/post_buildings
export const postBuildingsRequest = (
  requestArguments: postBuildingsRequestEstimatesArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_POST_BUILDINGS_START',
      successful: 'DATA_POST_BUILDINGS_SUCCESSFUL',
      fail: 'DATA_POST_BUILDINGS_FAIL'
    },
    embeddedType: null,
    fnName: 'postBuildingsRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}${options?.basePath || '/estimates-api'}`,
    method: 'POST',
    url: '/buildings',
    params: {
      isPrivate: requestArguments.query?.['isPrivate'],
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: requestArguments.body
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'postBuildingsRequest', '/estimates-api'),
    actions: {
      start: {
        type: DATA_POST_BUILDINGS_START, payload, config, meta
      },
      successful: {
        type: DATA_POST_BUILDINGS_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_POST_BUILDINGS_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_DELETE_BUILDINGS_WITH_BUILDINGID_START = 'DATA_DELETE_BUILDINGS_WITH_BUILDINGID_START'
export const DATA_DELETE_BUILDINGS_WITH_BUILDINGID_SUCCESSFUL = 'DATA_DELETE_BUILDINGS_WITH_BUILDINGID_SUCCESSFUL'
export const DATA_DELETE_BUILDINGS_WITH_BUILDINGID_FAIL = 'DATA_DELETE_BUILDINGS_WITH_BUILDINGID_FAIL'

// Delete a building
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/estimates.yaml#/Buildings/delete_buildings__buildingId_
export const deleteBuildingsWithBuildingIdRequest = (
  requestArguments: deleteBuildingsWithBuildingIdRequestEstimatesArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_DELETE_BUILDINGS_WITH_BUILDINGID_START',
      successful: 'DATA_DELETE_BUILDINGS_WITH_BUILDINGID_SUCCESSFUL',
      fail: 'DATA_DELETE_BUILDINGS_WITH_BUILDINGID_FAIL'
    },
    embeddedType: null,
    fnName: 'deleteBuildingsWithBuildingIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}${options?.basePath || '/estimates-api'}`,
    method: 'DELETE',
    url: `/buildings/${requestArguments.path['buildingId']}`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'deleteBuildingsWithBuildingIdRequest', '/estimates-api'),
    actions: {
      start: {
        type: DATA_DELETE_BUILDINGS_WITH_BUILDINGID_START, payload, config, meta
      },
      successful: {
        type: DATA_DELETE_BUILDINGS_WITH_BUILDINGID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_DELETE_BUILDINGS_WITH_BUILDINGID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_GET_BUILDINGS_WITH_BUILDINGID_START = 'DATA_GET_BUILDINGS_WITH_BUILDINGID_START'
export const DATA_GET_BUILDINGS_WITH_BUILDINGID_SUCCESSFUL = 'DATA_GET_BUILDINGS_WITH_BUILDINGID_SUCCESSFUL'
export const DATA_GET_BUILDINGS_WITH_BUILDINGID_FAIL = 'DATA_GET_BUILDINGS_WITH_BUILDINGID_FAIL'

// Returns a building
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/estimates.yaml#/Buildings/get_buildings__buildingId_
export const getBuildingsWithBuildingIdRequest = (
  requestArguments: getBuildingsWithBuildingIdRequestEstimatesArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_BUILDINGS_WITH_BUILDINGID_START',
      successful: 'DATA_GET_BUILDINGS_WITH_BUILDINGID_SUCCESSFUL',
      fail: 'DATA_GET_BUILDINGS_WITH_BUILDINGID_FAIL'
    },
    embeddedType: {
      nonEmbedded: {
        schemaPath: '#/definitions/Building',
        schemaKey: 'Building'
      }
    },
    fnName: 'getBuildingsWithBuildingIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}${options?.basePath || '/estimates-api'}`,
    method: 'GET',
    url: `/buildings/${requestArguments.path['buildingId']}`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      nonEmbedded: {
        schemaPath: '#/definitions/Building',
        schemaKey: 'Building'
      }
    }, 'getBuildingsWithBuildingIdRequest', '/estimates-api'),
    actions: {
      start: {
        type: DATA_GET_BUILDINGS_WITH_BUILDINGID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_BUILDINGS_WITH_BUILDINGID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_BUILDINGS_WITH_BUILDINGID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getBuildingsWithBuildingIdRequestDefinitions = (definitionsArguments: {| requestArgs: getBuildingsWithBuildingIdRequestEstimatesArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getBuildingsWithBuildingIdRequestEstimatesArguments, payload?: Object |} => ({
  fnName: 'getBuildingsWithBuildingIdRequest',
  basePath: '/estimates-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_PATCH_BUILDINGS_WITH_BUILDINGID_START = 'DATA_PATCH_BUILDINGS_WITH_BUILDINGID_START'
export const DATA_PATCH_BUILDINGS_WITH_BUILDINGID_SUCCESSFUL = 'DATA_PATCH_BUILDINGS_WITH_BUILDINGID_SUCCESSFUL'
export const DATA_PATCH_BUILDINGS_WITH_BUILDINGID_FAIL = 'DATA_PATCH_BUILDINGS_WITH_BUILDINGID_FAIL'

// Update building properties
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/estimates.yaml#/Buildings/patch_buildings__buildingId_
export const patchBuildingsWithBuildingIdRequest = (
  requestArguments: patchBuildingsWithBuildingIdRequestEstimatesArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_PATCH_BUILDINGS_WITH_BUILDINGID_START',
      successful: 'DATA_PATCH_BUILDINGS_WITH_BUILDINGID_SUCCESSFUL',
      fail: 'DATA_PATCH_BUILDINGS_WITH_BUILDINGID_FAIL'
    },
    embeddedType: null,
    fnName: 'patchBuildingsWithBuildingIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}${options?.basePath || '/estimates-api'}`,
    method: 'PATCH',
    url: `/buildings/${requestArguments.path['buildingId']}`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: requestArguments.body
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'patchBuildingsWithBuildingIdRequest', '/estimates-api'),
    actions: {
      start: {
        type: DATA_PATCH_BUILDINGS_WITH_BUILDINGID_START, payload, config, meta
      },
      successful: {
        type: DATA_PATCH_BUILDINGS_WITH_BUILDINGID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_PATCH_BUILDINGS_WITH_BUILDINGID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_GET_BUILDINGS_LOCK_WITH_BUILDINGID_START = 'DATA_GET_BUILDINGS_LOCK_WITH_BUILDINGID_START'
export const DATA_GET_BUILDINGS_LOCK_WITH_BUILDINGID_SUCCESSFUL = 'DATA_GET_BUILDINGS_LOCK_WITH_BUILDINGID_SUCCESSFUL'
export const DATA_GET_BUILDINGS_LOCK_WITH_BUILDINGID_FAIL = 'DATA_GET_BUILDINGS_LOCK_WITH_BUILDINGID_FAIL'

// If resource is locked, returns information (owner, expiration) about the current lock
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/estimates.yaml#/Buildings/get_buildings__buildingId__lock
export const getBuildingsLockWithBuildingIdRequest = (
  requestArguments: getBuildingsLockWithBuildingIdRequestEstimatesArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_BUILDINGS_LOCK_WITH_BUILDINGID_START',
      successful: 'DATA_GET_BUILDINGS_LOCK_WITH_BUILDINGID_SUCCESSFUL',
      fail: 'DATA_GET_BUILDINGS_LOCK_WITH_BUILDINGID_FAIL'
    },
    embeddedType: {
      nonEmbedded: {
        schemaPath: '#/definitions/ResourceLock',
        schemaKey: 'ResourceLock'
      }
    },
    fnName: 'getBuildingsLockWithBuildingIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}${options?.basePath || '/estimates-api'}`,
    method: 'GET',
    url: `/buildings/${requestArguments.path['buildingId']}/lock`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      nonEmbedded: {
        schemaPath: '#/definitions/ResourceLock',
        schemaKey: 'ResourceLock'
      }
    }, 'getBuildingsLockWithBuildingIdRequest', '/estimates-api'),
    actions: {
      start: {
        type: DATA_GET_BUILDINGS_LOCK_WITH_BUILDINGID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_BUILDINGS_LOCK_WITH_BUILDINGID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_BUILDINGS_LOCK_WITH_BUILDINGID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getBuildingsLockWithBuildingIdRequestDefinitions = (definitionsArguments: {| requestArgs: getBuildingsLockWithBuildingIdRequestEstimatesArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getBuildingsLockWithBuildingIdRequestEstimatesArguments, payload?: Object |} => ({
  fnName: 'getBuildingsLockWithBuildingIdRequest',
  basePath: '/estimates-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_PUT_BUILDINGS_LOCK_WITH_BUILDINGID_START = 'DATA_PUT_BUILDINGS_LOCK_WITH_BUILDINGID_START'
export const DATA_PUT_BUILDINGS_LOCK_WITH_BUILDINGID_SUCCESSFUL = 'DATA_PUT_BUILDINGS_LOCK_WITH_BUILDINGID_SUCCESSFUL'
export const DATA_PUT_BUILDINGS_LOCK_WITH_BUILDINGID_FAIL = 'DATA_PUT_BUILDINGS_LOCK_WITH_BUILDINGID_FAIL'

// Obtain a lock to a resource for editing. Edits are not permitted before first obtaining a lock.
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/estimates.yaml#/Buildings/put_buildings__buildingId__lock
export const putBuildingsLockWithBuildingIdRequest = (
  requestArguments: putBuildingsLockWithBuildingIdRequestEstimatesArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_PUT_BUILDINGS_LOCK_WITH_BUILDINGID_START',
      successful: 'DATA_PUT_BUILDINGS_LOCK_WITH_BUILDINGID_SUCCESSFUL',
      fail: 'DATA_PUT_BUILDINGS_LOCK_WITH_BUILDINGID_FAIL'
    },
    embeddedType: null,
    fnName: 'putBuildingsLockWithBuildingIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}${options?.basePath || '/estimates-api'}`,
    method: 'PUT',
    url: `/buildings/${requestArguments.path['buildingId']}/lock`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: requestArguments.body
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'putBuildingsLockWithBuildingIdRequest', '/estimates-api'),
    actions: {
      start: {
        type: DATA_PUT_BUILDINGS_LOCK_WITH_BUILDINGID_START, payload, config, meta
      },
      successful: {
        type: DATA_PUT_BUILDINGS_LOCK_WITH_BUILDINGID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_PUT_BUILDINGS_LOCK_WITH_BUILDINGID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_DELETE_BUILDINGS_LOCK_WITH_BUILDINGID_START = 'DATA_DELETE_BUILDINGS_LOCK_WITH_BUILDINGID_START'
export const DATA_DELETE_BUILDINGS_LOCK_WITH_BUILDINGID_SUCCESSFUL = 'DATA_DELETE_BUILDINGS_LOCK_WITH_BUILDINGID_SUCCESSFUL'
export const DATA_DELETE_BUILDINGS_LOCK_WITH_BUILDINGID_FAIL = 'DATA_DELETE_BUILDINGS_LOCK_WITH_BUILDINGID_FAIL'

// Deletes a resource lock. If the lock was created by another user, error 423 is returned.
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/estimates.yaml#/Buildings/delete_buildings__buildingId__lock
export const deleteBuildingsLockWithBuildingIdRequest = (
  requestArguments: deleteBuildingsLockWithBuildingIdRequestEstimatesArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_DELETE_BUILDINGS_LOCK_WITH_BUILDINGID_START',
      successful: 'DATA_DELETE_BUILDINGS_LOCK_WITH_BUILDINGID_SUCCESSFUL',
      fail: 'DATA_DELETE_BUILDINGS_LOCK_WITH_BUILDINGID_FAIL'
    },
    embeddedType: null,
    fnName: 'deleteBuildingsLockWithBuildingIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}${options?.basePath || '/estimates-api'}`,
    method: 'DELETE',
    url: `/buildings/${requestArguments.path['buildingId']}/lock`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'deleteBuildingsLockWithBuildingIdRequest', '/estimates-api'),
    actions: {
      start: {
        type: DATA_DELETE_BUILDINGS_LOCK_WITH_BUILDINGID_START, payload, config, meta
      },
      successful: {
        type: DATA_DELETE_BUILDINGS_LOCK_WITH_BUILDINGID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_DELETE_BUILDINGS_LOCK_WITH_BUILDINGID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_GET_BUILDINGS_PERMISSIONS_WITH_BUILDINGID_START = 'DATA_GET_BUILDINGS_PERMISSIONS_WITH_BUILDINGID_START'
export const DATA_GET_BUILDINGS_PERMISSIONS_WITH_BUILDINGID_SUCCESSFUL = 'DATA_GET_BUILDINGS_PERMISSIONS_WITH_BUILDINGID_SUCCESSFUL'
export const DATA_GET_BUILDINGS_PERMISSIONS_WITH_BUILDINGID_FAIL = 'DATA_GET_BUILDINGS_PERMISSIONS_WITH_BUILDINGID_FAIL'

// Gets permissions for Users and User Groups that have access to a Building.
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/estimates.yaml#/Buildings/get_buildings__buildingId__permissions
export const getBuildingsPermissionsWithBuildingIdRequest = (
  requestArguments: getBuildingsPermissionsWithBuildingIdRequestEstimatesArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_BUILDINGS_PERMISSIONS_WITH_BUILDINGID_START',
      successful: 'DATA_GET_BUILDINGS_PERMISSIONS_WITH_BUILDINGID_SUCCESSFUL',
      fail: 'DATA_GET_BUILDINGS_PERMISSIONS_WITH_BUILDINGID_FAIL'
    },
    embeddedType: {
      permissions: {
        schemaPath: '#/definitions/SubjectPermissions',
        schemaKey: 'SubjectPermissions'
      },
      users: {
        schemaPath: '#/definitions/User',
        schemaKey: 'User'
      },
      usergroups: {
        schemaPath: '#/definitions/UserGroup',
        schemaKey: 'UserGroup'
      }
    },
    fnName: 'getBuildingsPermissionsWithBuildingIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}${options?.basePath || '/estimates-api'}`,
    method: 'GET',
    url: `/buildings/${requestArguments.path['buildingId']}/permissions`,
    params: {
      directPermissions: requestArguments.query?.['directPermissions'],
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      permissions: {
        schemaPath: '#/definitions/SubjectPermissions',
        schemaKey: 'SubjectPermissions'
      },
      users: {
        schemaPath: '#/definitions/User',
        schemaKey: 'User'
      },
      usergroups: {
        schemaPath: '#/definitions/UserGroup',
        schemaKey: 'UserGroup'
      }
    }, 'getBuildingsPermissionsWithBuildingIdRequest', '/estimates-api'),
    actions: {
      start: {
        type: DATA_GET_BUILDINGS_PERMISSIONS_WITH_BUILDINGID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_BUILDINGS_PERMISSIONS_WITH_BUILDINGID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_BUILDINGS_PERMISSIONS_WITH_BUILDINGID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getBuildingsPermissionsWithBuildingIdRequestDefinitions = (definitionsArguments: {| requestArgs: getBuildingsPermissionsWithBuildingIdRequestEstimatesArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getBuildingsPermissionsWithBuildingIdRequestEstimatesArguments, payload?: Object |} => ({
  fnName: 'getBuildingsPermissionsWithBuildingIdRequest',
  basePath: '/estimates-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_POST_BUILDINGS_PERMISSIONS_WITH_BUILDINGID_START = 'DATA_POST_BUILDINGS_PERMISSIONS_WITH_BUILDINGID_START'
export const DATA_POST_BUILDINGS_PERMISSIONS_WITH_BUILDINGID_SUCCESSFUL = 'DATA_POST_BUILDINGS_PERMISSIONS_WITH_BUILDINGID_SUCCESSFUL'
export const DATA_POST_BUILDINGS_PERMISSIONS_WITH_BUILDINGID_FAIL = 'DATA_POST_BUILDINGS_PERMISSIONS_WITH_BUILDINGID_FAIL'

// Grants new permissions for Users or User Groups to an Building.
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/estimates.yaml#/Buildings/post_buildings__buildingId__permissions
export const postBuildingsPermissionsWithBuildingIdRequestDeprecated = (
  requestArguments: postBuildingsPermissionsWithBuildingIdRequestEstimatesArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_POST_BUILDINGS_PERMISSIONS_WITH_BUILDINGID_START',
      successful: 'DATA_POST_BUILDINGS_PERMISSIONS_WITH_BUILDINGID_SUCCESSFUL',
      fail: 'DATA_POST_BUILDINGS_PERMISSIONS_WITH_BUILDINGID_FAIL'
    },
    embeddedType: null,
    fnName: 'postBuildingsPermissionsWithBuildingIdRequest'
  }
  const config = {
    headers: { 'Content-Type': 'application/json' },
    baseURL: `${API_BASE_URL}${options?.basePath || '/estimates-api'}`,
    method: 'POST',
    url: `/buildings/${requestArguments.path['buildingId']}/permissions`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: requestArguments.body
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'postBuildingsPermissionsWithBuildingIdRequest', '/estimates-api'),
    actions: {
      start: {
        type: DATA_POST_BUILDINGS_PERMISSIONS_WITH_BUILDINGID_START, payload, config, meta
      },
      successful: {
        type: DATA_POST_BUILDINGS_PERMISSIONS_WITH_BUILDINGID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_POST_BUILDINGS_PERMISSIONS_WITH_BUILDINGID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_DELETE_BUILDINGS_PERMISSIONS_WITH_BUILDINGID_START = 'DATA_DELETE_BUILDINGS_PERMISSIONS_WITH_BUILDINGID_START'
export const DATA_DELETE_BUILDINGS_PERMISSIONS_WITH_BUILDINGID_SUCCESSFUL = 'DATA_DELETE_BUILDINGS_PERMISSIONS_WITH_BUILDINGID_SUCCESSFUL'
export const DATA_DELETE_BUILDINGS_PERMISSIONS_WITH_BUILDINGID_FAIL = 'DATA_DELETE_BUILDINGS_PERMISSIONS_WITH_BUILDINGID_FAIL'

// Revokes permissions from Users or User Groups to an Building.
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/estimates.yaml#/Buildings/delete_buildings__buildingId__permissions
export const deleteBuildingsPermissionsWithBuildingIdRequestDeprecated = (
  requestArguments: deleteBuildingsPermissionsWithBuildingIdRequestEstimatesArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_DELETE_BUILDINGS_PERMISSIONS_WITH_BUILDINGID_START',
      successful: 'DATA_DELETE_BUILDINGS_PERMISSIONS_WITH_BUILDINGID_SUCCESSFUL',
      fail: 'DATA_DELETE_BUILDINGS_PERMISSIONS_WITH_BUILDINGID_FAIL'
    },
    embeddedType: null,
    fnName: 'deleteBuildingsPermissionsWithBuildingIdRequest'
  }
  const config = {
    headers: { 'Content-Type': 'application/json' },
    baseURL: `${API_BASE_URL}${options?.basePath || '/estimates-api'}`,
    method: 'DELETE',
    url: `/buildings/${requestArguments.path['buildingId']}/permissions`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: requestArguments.body
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'deleteBuildingsPermissionsWithBuildingIdRequest', '/estimates-api'),
    actions: {
      start: {
        type: DATA_DELETE_BUILDINGS_PERMISSIONS_WITH_BUILDINGID_START, payload, config, meta
      },
      successful: {
        type: DATA_DELETE_BUILDINGS_PERMISSIONS_WITH_BUILDINGID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_DELETE_BUILDINGS_PERMISSIONS_WITH_BUILDINGID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_GET_BUILDINGS_PERMISSIONS_USERS_WITH_BUILDINGID_START = 'DATA_GET_BUILDINGS_PERMISSIONS_USERS_WITH_BUILDINGID_START'
export const DATA_GET_BUILDINGS_PERMISSIONS_USERS_WITH_BUILDINGID_SUCCESSFUL = 'DATA_GET_BUILDINGS_PERMISSIONS_USERS_WITH_BUILDINGID_SUCCESSFUL'
export const DATA_GET_BUILDINGS_PERMISSIONS_USERS_WITH_BUILDINGID_FAIL = 'DATA_GET_BUILDINGS_PERMISSIONS_USERS_WITH_BUILDINGID_FAIL'

// Gets permissions for users that have access to a building.
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/estimates.yaml#/Buildings/get_buildings__buildingId__permissions_users
export const getBuildingsPermissionsUsersWithBuildingIdRequest = (
  requestArguments: getBuildingsPermissionsUsersWithBuildingIdRequestEstimatesArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_BUILDINGS_PERMISSIONS_USERS_WITH_BUILDINGID_START',
      successful: 'DATA_GET_BUILDINGS_PERMISSIONS_USERS_WITH_BUILDINGID_SUCCESSFUL',
      fail: 'DATA_GET_BUILDINGS_PERMISSIONS_USERS_WITH_BUILDINGID_FAIL'
    },
    embeddedType: {
      permissions: {
        schemaPath: '#/definitions/SubjectPermissions',
        schemaKey: 'SubjectPermissions'
      }
    },
    fnName: 'getBuildingsPermissionsUsersWithBuildingIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}${options?.basePath || '/estimates-api'}`,
    method: 'GET',
    url: `/buildings/${requestArguments.path['buildingId']}/permissions/users`,
    params: {
      directPermissions: requestArguments.query?.['directPermissions'],
      includeUsers: requestArguments.query?.['includeUsers'],
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      permissions: {
        schemaPath: '#/definitions/SubjectPermissions',
        schemaKey: 'SubjectPermissions'
      }
    }, 'getBuildingsPermissionsUsersWithBuildingIdRequest', '/estimates-api'),
    actions: {
      start: {
        type: DATA_GET_BUILDINGS_PERMISSIONS_USERS_WITH_BUILDINGID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_BUILDINGS_PERMISSIONS_USERS_WITH_BUILDINGID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_BUILDINGS_PERMISSIONS_USERS_WITH_BUILDINGID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getBuildingsPermissionsUsersWithBuildingIdRequestDefinitions = (definitionsArguments: {| requestArgs: getBuildingsPermissionsUsersWithBuildingIdRequestEstimatesArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getBuildingsPermissionsUsersWithBuildingIdRequestEstimatesArguments, payload?: Object |} => ({
  fnName: 'getBuildingsPermissionsUsersWithBuildingIdRequest',
  basePath: '/estimates-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_GET_BUILDINGS_PERMISSIONS_USERS_SELF_WITH_BUILDINGID_START = 'DATA_GET_BUILDINGS_PERMISSIONS_USERS_SELF_WITH_BUILDINGID_START'
export const DATA_GET_BUILDINGS_PERMISSIONS_USERS_SELF_WITH_BUILDINGID_SUCCESSFUL = 'DATA_GET_BUILDINGS_PERMISSIONS_USERS_SELF_WITH_BUILDINGID_SUCCESSFUL'
export const DATA_GET_BUILDINGS_PERMISSIONS_USERS_SELF_WITH_BUILDINGID_FAIL = 'DATA_GET_BUILDINGS_PERMISSIONS_USERS_SELF_WITH_BUILDINGID_FAIL'

// Gets permissions for the current user for an building.
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/estimates.yaml#/Buildings/get_buildings__buildingId__permissions_users_self
export const getBuildingsPermissionsUsersSelfWithBuildingIdRequest = (
  requestArguments: getBuildingsPermissionsUsersSelfWithBuildingIdRequestEstimatesArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_BUILDINGS_PERMISSIONS_USERS_SELF_WITH_BUILDINGID_START',
      successful: 'DATA_GET_BUILDINGS_PERMISSIONS_USERS_SELF_WITH_BUILDINGID_SUCCESSFUL',
      fail: 'DATA_GET_BUILDINGS_PERMISSIONS_USERS_SELF_WITH_BUILDINGID_FAIL'
    },
    embeddedType: {
      permissions: {
        schemaPath: '#/definitions/SubjectPermissions',
        schemaKey: 'SubjectPermissions'
      }
    },
    fnName: 'getBuildingsPermissionsUsersSelfWithBuildingIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}${options?.basePath || '/estimates-api'}`,
    method: 'GET',
    url: `/buildings/${requestArguments.path['buildingId']}/permissions/users/self`,
    params: {
      directPermissions: requestArguments.query?.['directPermissions'],
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      permissions: {
        schemaPath: '#/definitions/SubjectPermissions',
        schemaKey: 'SubjectPermissions'
      }
    }, 'getBuildingsPermissionsUsersSelfWithBuildingIdRequest', '/estimates-api'),
    actions: {
      start: {
        type: DATA_GET_BUILDINGS_PERMISSIONS_USERS_SELF_WITH_BUILDINGID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_BUILDINGS_PERMISSIONS_USERS_SELF_WITH_BUILDINGID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_BUILDINGS_PERMISSIONS_USERS_SELF_WITH_BUILDINGID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getBuildingsPermissionsUsersSelfWithBuildingIdRequestDefinitions = (definitionsArguments: {| requestArgs: getBuildingsPermissionsUsersSelfWithBuildingIdRequestEstimatesArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getBuildingsPermissionsUsersSelfWithBuildingIdRequestEstimatesArguments, payload?: Object |} => ({
  fnName: 'getBuildingsPermissionsUsersSelfWithBuildingIdRequest',
  basePath: '/estimates-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_GET_BUILDINGS_PERMISSIONS_USER_GROUPS_WITH_BUILDINGID_START = 'DATA_GET_BUILDINGS_PERMISSIONS_USER_GROUPS_WITH_BUILDINGID_START'
export const DATA_GET_BUILDINGS_PERMISSIONS_USER_GROUPS_WITH_BUILDINGID_SUCCESSFUL = 'DATA_GET_BUILDINGS_PERMISSIONS_USER_GROUPS_WITH_BUILDINGID_SUCCESSFUL'
export const DATA_GET_BUILDINGS_PERMISSIONS_USER_GROUPS_WITH_BUILDINGID_FAIL = 'DATA_GET_BUILDINGS_PERMISSIONS_USER_GROUPS_WITH_BUILDINGID_FAIL'

// Gets permissions for user groups that have access to a building.
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/estimates.yaml#/Buildings/get_buildings__buildingId__permissions_user_groups
export const getBuildingsPermissionsUserGroupsWithBuildingIdRequest = (
  requestArguments: getBuildingsPermissionsUserGroupsWithBuildingIdRequestEstimatesArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_BUILDINGS_PERMISSIONS_USER_GROUPS_WITH_BUILDINGID_START',
      successful: 'DATA_GET_BUILDINGS_PERMISSIONS_USER_GROUPS_WITH_BUILDINGID_SUCCESSFUL',
      fail: 'DATA_GET_BUILDINGS_PERMISSIONS_USER_GROUPS_WITH_BUILDINGID_FAIL'
    },
    embeddedType: {
      permissions: {
        schemaPath: '#/definitions/SubjectPermissions',
        schemaKey: 'SubjectPermissions'
      }
    },
    fnName: 'getBuildingsPermissionsUserGroupsWithBuildingIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}${options?.basePath || '/estimates-api'}`,
    method: 'GET',
    url: `/buildings/${requestArguments.path['buildingId']}/permissions/user-groups`,
    params: {
      directPermissions: requestArguments.query?.['directPermissions'],
      includeUserGroups: requestArguments.query?.['includeUserGroups'],
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      permissions: {
        schemaPath: '#/definitions/SubjectPermissions',
        schemaKey: 'SubjectPermissions'
      }
    }, 'getBuildingsPermissionsUserGroupsWithBuildingIdRequest', '/estimates-api'),
    actions: {
      start: {
        type: DATA_GET_BUILDINGS_PERMISSIONS_USER_GROUPS_WITH_BUILDINGID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_BUILDINGS_PERMISSIONS_USER_GROUPS_WITH_BUILDINGID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_BUILDINGS_PERMISSIONS_USER_GROUPS_WITH_BUILDINGID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getBuildingsPermissionsUserGroupsWithBuildingIdRequestDefinitions = (definitionsArguments: {| requestArgs: getBuildingsPermissionsUserGroupsWithBuildingIdRequestEstimatesArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getBuildingsPermissionsUserGroupsWithBuildingIdRequestEstimatesArguments, payload?: Object |} => ({
  fnName: 'getBuildingsPermissionsUserGroupsWithBuildingIdRequest',
  basePath: '/estimates-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_GET_ESTIMATES_START = 'DATA_GET_ESTIMATES_START'
export const DATA_GET_ESTIMATES_SUCCESSFUL = 'DATA_GET_ESTIMATES_SUCCESSFUL'
export const DATA_GET_ESTIMATES_FAIL = 'DATA_GET_ESTIMATES_FAIL'

// Returns an embedded list of Estimates for a Building.
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/estimates.yaml#/Estimates/get_estimates
export const getEstimatesRequest = (
  requestArguments: getEstimatesRequestEstimatesArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_ESTIMATES_START',
      successful: 'DATA_GET_ESTIMATES_SUCCESSFUL',
      fail: 'DATA_GET_ESTIMATES_FAIL'
    },
    embeddedType: {
      estimates: {
        schemaPath: '#/definitions/Estimate',
        schemaKey: 'Estimate'
      }
    },
    fnName: 'getEstimatesRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}${options?.basePath || '/estimates-api'}`,
    method: 'GET',
    url: '/estimates',
    params: {
      estimateType: requestArguments.query['estimateType'],
      buildingId: requestArguments.query['buildingId'],
      permissionsSubject: requestArguments.query['permissionsSubject'],
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      estimates: {
        schemaPath: '#/definitions/Estimate',
        schemaKey: 'Estimate'
      }
    }, 'getEstimatesRequest', '/estimates-api'),
    actions: {
      start: {
        type: DATA_GET_ESTIMATES_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_ESTIMATES_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_ESTIMATES_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getEstimatesRequestDefinitions = (definitionsArguments: {| requestArgs: getEstimatesRequestEstimatesArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getEstimatesRequestEstimatesArguments, payload?: Object |} => ({
  fnName: 'getEstimatesRequest',
  basePath: '/estimates-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_POST_ESTIMATES_START = 'DATA_POST_ESTIMATES_START'
export const DATA_POST_ESTIMATES_SUCCESSFUL = 'DATA_POST_ESTIMATES_SUCCESSFUL'
export const DATA_POST_ESTIMATES_FAIL = 'DATA_POST_ESTIMATES_FAIL'

// Create a new estimate under a building or a site.
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/estimates.yaml#/Estimates/post_estimates
export const postEstimatesRequest = (
  requestArguments: postEstimatesRequestEstimatesArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_POST_ESTIMATES_START',
      successful: 'DATA_POST_ESTIMATES_SUCCESSFUL',
      fail: 'DATA_POST_ESTIMATES_FAIL'
    },
    embeddedType: null,
    fnName: 'postEstimatesRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}${options?.basePath || '/estimates-api'}`,
    method: 'POST',
    url: '/estimates',
    params: {
      parentId: requestArguments.query['parentId'],
      copyId: requestArguments.query['copyId'],
      importId: requestArguments.query['importId'],
      isPrivate: requestArguments.query['isPrivate'],
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: requestArguments.body
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'postEstimatesRequest', '/estimates-api'),
    actions: {
      start: {
        type: DATA_POST_ESTIMATES_START, payload, config, meta
      },
      successful: {
        type: DATA_POST_ESTIMATES_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_POST_ESTIMATES_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_PATCH_ESTIMATES_WITH_ESTIMATEID_START = 'DATA_PATCH_ESTIMATES_WITH_ESTIMATEID_START'
export const DATA_PATCH_ESTIMATES_WITH_ESTIMATEID_SUCCESSFUL = 'DATA_PATCH_ESTIMATES_WITH_ESTIMATEID_SUCCESSFUL'
export const DATA_PATCH_ESTIMATES_WITH_ESTIMATEID_FAIL = 'DATA_PATCH_ESTIMATES_WITH_ESTIMATEID_FAIL'

// Update estimate properties
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/estimates.yaml#/Estimates/patch_estimates__estimateId_
export const patchEstimatesWithEstimateIdRequest = (
  requestArguments: patchEstimatesWithEstimateIdRequestEstimatesArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_PATCH_ESTIMATES_WITH_ESTIMATEID_START',
      successful: 'DATA_PATCH_ESTIMATES_WITH_ESTIMATEID_SUCCESSFUL',
      fail: 'DATA_PATCH_ESTIMATES_WITH_ESTIMATEID_FAIL'
    },
    embeddedType: null,
    fnName: 'patchEstimatesWithEstimateIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}${options?.basePath || '/estimates-api'}`,
    method: 'PATCH',
    url: `/estimates/${options?.estimateId || store.app.calculation}`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: requestArguments.body
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'patchEstimatesWithEstimateIdRequest', '/estimates-api'),
    actions: {
      start: {
        type: DATA_PATCH_ESTIMATES_WITH_ESTIMATEID_START, payload, config, meta
      },
      successful: {
        type: DATA_PATCH_ESTIMATES_WITH_ESTIMATEID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_PATCH_ESTIMATES_WITH_ESTIMATEID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_GET_ESTIMATES_WITH_ESTIMATEID_START = 'DATA_GET_ESTIMATES_WITH_ESTIMATEID_START'
export const DATA_GET_ESTIMATES_WITH_ESTIMATEID_SUCCESSFUL = 'DATA_GET_ESTIMATES_WITH_ESTIMATEID_SUCCESSFUL'
export const DATA_GET_ESTIMATES_WITH_ESTIMATEID_FAIL = 'DATA_GET_ESTIMATES_WITH_ESTIMATEID_FAIL'

// Returns an estimate
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/estimates.yaml#/Estimates/get_estimates__estimateId_
export const getEstimatesWithEstimateIdRequest = (
  payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_ESTIMATES_WITH_ESTIMATEID_START',
      successful: 'DATA_GET_ESTIMATES_WITH_ESTIMATEID_SUCCESSFUL',
      fail: 'DATA_GET_ESTIMATES_WITH_ESTIMATEID_FAIL'
    },
    embeddedType: {
      nonEmbedded: {
        schemaPath: '#/definitions/Estimate',
        schemaKey: 'Estimate'
      }
    },
    fnName: 'getEstimatesWithEstimateIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}${options?.basePath || '/estimates-api'}`,
    method: 'GET',
    url: `/estimates/${options?.estimateId || store.app.calculation}`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      nonEmbedded: {
        schemaPath: '#/definitions/Estimate',
        schemaKey: 'Estimate'
      }
    }, 'getEstimatesWithEstimateIdRequest', '/estimates-api'),
    actions: {
      start: {
        type: DATA_GET_ESTIMATES_WITH_ESTIMATEID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_ESTIMATES_WITH_ESTIMATEID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_ESTIMATES_WITH_ESTIMATEID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getEstimatesWithEstimateIdRequestDefinitions = (definitionsArguments: {| payload?: Object |}): {|
  ...TVDGARConfigWithoutRequestArgs, payload?: Object |} => ({
  fnName: 'getEstimatesWithEstimateIdRequest',
  basePath: '/estimates-api',
  payload: definitionsArguments.payload,
})


export const DATA_DELETE_ESTIMATES_WITH_ESTIMATEID_START = 'DATA_DELETE_ESTIMATES_WITH_ESTIMATEID_START'
export const DATA_DELETE_ESTIMATES_WITH_ESTIMATEID_SUCCESSFUL = 'DATA_DELETE_ESTIMATES_WITH_ESTIMATEID_SUCCESSFUL'
export const DATA_DELETE_ESTIMATES_WITH_ESTIMATEID_FAIL = 'DATA_DELETE_ESTIMATES_WITH_ESTIMATEID_FAIL'

// Deletes an estimate
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/estimates.yaml#/Estimates/delete_estimates__estimateId_
export const deleteEstimatesWithEstimateIdRequest = (
  payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_DELETE_ESTIMATES_WITH_ESTIMATEID_START',
      successful: 'DATA_DELETE_ESTIMATES_WITH_ESTIMATEID_SUCCESSFUL',
      fail: 'DATA_DELETE_ESTIMATES_WITH_ESTIMATEID_FAIL'
    },
    embeddedType: null,
    fnName: 'deleteEstimatesWithEstimateIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}${options?.basePath || '/estimates-api'}`,
    method: 'DELETE',
    url: `/estimates/${options?.estimateId || store.app.calculation}`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'deleteEstimatesWithEstimateIdRequest', '/estimates-api'),
    actions: {
      start: {
        type: DATA_DELETE_ESTIMATES_WITH_ESTIMATEID_START, payload, config, meta
      },
      successful: {
        type: DATA_DELETE_ESTIMATES_WITH_ESTIMATEID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_DELETE_ESTIMATES_WITH_ESTIMATEID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_GET_ESTIMATES_LOCK_WITH_ESTIMATEID_START = 'DATA_GET_ESTIMATES_LOCK_WITH_ESTIMATEID_START'
export const DATA_GET_ESTIMATES_LOCK_WITH_ESTIMATEID_SUCCESSFUL = 'DATA_GET_ESTIMATES_LOCK_WITH_ESTIMATEID_SUCCESSFUL'
export const DATA_GET_ESTIMATES_LOCK_WITH_ESTIMATEID_FAIL = 'DATA_GET_ESTIMATES_LOCK_WITH_ESTIMATEID_FAIL'

// If resource is locked, returns information (owner, expiration) about the current lock
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/estimates.yaml#/Estimates/get_estimates__estimateId__lock
export const getEstimatesLockWithEstimateIdRequest = (
  payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_ESTIMATES_LOCK_WITH_ESTIMATEID_START',
      successful: 'DATA_GET_ESTIMATES_LOCK_WITH_ESTIMATEID_SUCCESSFUL',
      fail: 'DATA_GET_ESTIMATES_LOCK_WITH_ESTIMATEID_FAIL'
    },
    embeddedType: {
      nonEmbedded: {
        schemaPath: '#/definitions/ResourceLock',
        schemaKey: 'ResourceLock'
      }
    },
    fnName: 'getEstimatesLockWithEstimateIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}${options?.basePath || '/estimates-api'}`,
    method: 'GET',
    url: `/estimates/${options?.estimateId || store.app.calculation}/lock`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      nonEmbedded: {
        schemaPath: '#/definitions/ResourceLock',
        schemaKey: 'ResourceLock'
      }
    }, 'getEstimatesLockWithEstimateIdRequest', '/estimates-api'),
    actions: {
      start: {
        type: DATA_GET_ESTIMATES_LOCK_WITH_ESTIMATEID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_ESTIMATES_LOCK_WITH_ESTIMATEID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_ESTIMATES_LOCK_WITH_ESTIMATEID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getEstimatesLockWithEstimateIdRequestDefinitions = (definitionsArguments: {| payload?: Object |}): {|
  ...TVDGARConfigWithoutRequestArgs, payload?: Object |} => ({
  fnName: 'getEstimatesLockWithEstimateIdRequest',
  basePath: '/estimates-api',
  payload: definitionsArguments.payload,
})


export const DATA_PUT_ESTIMATES_LOCK_WITH_ESTIMATEID_START = 'DATA_PUT_ESTIMATES_LOCK_WITH_ESTIMATEID_START'
export const DATA_PUT_ESTIMATES_LOCK_WITH_ESTIMATEID_SUCCESSFUL = 'DATA_PUT_ESTIMATES_LOCK_WITH_ESTIMATEID_SUCCESSFUL'
export const DATA_PUT_ESTIMATES_LOCK_WITH_ESTIMATEID_FAIL = 'DATA_PUT_ESTIMATES_LOCK_WITH_ESTIMATEID_FAIL'

// Obtain a lock to a resource for editing.
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/estimates.yaml#/Estimates/put_estimates__estimateId__lock
export const putEstimatesLockWithEstimateIdRequest = (
  requestArguments: putEstimatesLockWithEstimateIdRequestEstimatesArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_PUT_ESTIMATES_LOCK_WITH_ESTIMATEID_START',
      successful: 'DATA_PUT_ESTIMATES_LOCK_WITH_ESTIMATEID_SUCCESSFUL',
      fail: 'DATA_PUT_ESTIMATES_LOCK_WITH_ESTIMATEID_FAIL'
    },
    embeddedType: null,
    fnName: 'putEstimatesLockWithEstimateIdRequest'
  }
  const config = {
    headers: { 'Content-Type': 'application/json' },
    baseURL: `${API_BASE_URL}${options?.basePath || '/estimates-api'}`,
    method: 'PUT',
    url: `/estimates/${options?.estimateId || store.app.calculation}/lock`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: requestArguments.body
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'putEstimatesLockWithEstimateIdRequest', '/estimates-api'),
    actions: {
      start: {
        type: DATA_PUT_ESTIMATES_LOCK_WITH_ESTIMATEID_START, payload, config, meta
      },
      successful: {
        type: DATA_PUT_ESTIMATES_LOCK_WITH_ESTIMATEID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_PUT_ESTIMATES_LOCK_WITH_ESTIMATEID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_DELETE_ESTIMATES_LOCK_WITH_ESTIMATEID_START = 'DATA_DELETE_ESTIMATES_LOCK_WITH_ESTIMATEID_START'
export const DATA_DELETE_ESTIMATES_LOCK_WITH_ESTIMATEID_SUCCESSFUL = 'DATA_DELETE_ESTIMATES_LOCK_WITH_ESTIMATEID_SUCCESSFUL'
export const DATA_DELETE_ESTIMATES_LOCK_WITH_ESTIMATEID_FAIL = 'DATA_DELETE_ESTIMATES_LOCK_WITH_ESTIMATEID_FAIL'

// Deletes a resource lock. If the lock was created by another user, error 423 is returned.
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/estimates.yaml#/Estimates/delete_estimates__estimateId__lock
export const deleteEstimatesLockWithEstimateIdRequest = (
  payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_DELETE_ESTIMATES_LOCK_WITH_ESTIMATEID_START',
      successful: 'DATA_DELETE_ESTIMATES_LOCK_WITH_ESTIMATEID_SUCCESSFUL',
      fail: 'DATA_DELETE_ESTIMATES_LOCK_WITH_ESTIMATEID_FAIL'
    },
    embeddedType: null,
    fnName: 'deleteEstimatesLockWithEstimateIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}${options?.basePath || '/estimates-api'}`,
    method: 'DELETE',
    url: `/estimates/${options?.estimateId || store.app.calculation}/lock`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'deleteEstimatesLockWithEstimateIdRequest', '/estimates-api'),
    actions: {
      start: {
        type: DATA_DELETE_ESTIMATES_LOCK_WITH_ESTIMATEID_START, payload, config, meta
      },
      successful: {
        type: DATA_DELETE_ESTIMATES_LOCK_WITH_ESTIMATEID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_DELETE_ESTIMATES_LOCK_WITH_ESTIMATEID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_GET_ESTIMATES_PERMISSIONS_WITH_ESTIMATEID_START = 'DATA_GET_ESTIMATES_PERMISSIONS_WITH_ESTIMATEID_START'
export const DATA_GET_ESTIMATES_PERMISSIONS_WITH_ESTIMATEID_SUCCESSFUL = 'DATA_GET_ESTIMATES_PERMISSIONS_WITH_ESTIMATEID_SUCCESSFUL'
export const DATA_GET_ESTIMATES_PERMISSIONS_WITH_ESTIMATEID_FAIL = 'DATA_GET_ESTIMATES_PERMISSIONS_WITH_ESTIMATEID_FAIL'

// Gets permissions for Users and User Groups that have access to an Estimate.
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/estimates.yaml#/Estimates/get_estimates__estimateId__permissions
export const getEstimatesPermissionsWithEstimateIdRequest = (
  requestArguments: getEstimatesPermissionsWithEstimateIdRequestEstimatesArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_ESTIMATES_PERMISSIONS_WITH_ESTIMATEID_START',
      successful: 'DATA_GET_ESTIMATES_PERMISSIONS_WITH_ESTIMATEID_SUCCESSFUL',
      fail: 'DATA_GET_ESTIMATES_PERMISSIONS_WITH_ESTIMATEID_FAIL'
    },
    embeddedType: {
      permissions: {
        schemaPath: '#/definitions/SubjectPermissions',
        schemaKey: 'SubjectPermissions'
      },
      users: {
        schemaPath: '#/definitions/User',
        schemaKey: 'User'
      },
      usergroups: {
        schemaPath: '#/definitions/UserGroup',
        schemaKey: 'UserGroup'
      }
    },
    fnName: 'getEstimatesPermissionsWithEstimateIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}${options?.basePath || '/estimates-api'}`,
    method: 'GET',
    url: `/estimates/${options?.estimateId || store.app.calculation}/permissions`,
    params: {
      directPermissions: requestArguments.query?.['directPermissions'],
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      permissions: {
        schemaPath: '#/definitions/SubjectPermissions',
        schemaKey: 'SubjectPermissions'
      },
      users: {
        schemaPath: '#/definitions/User',
        schemaKey: 'User'
      },
      usergroups: {
        schemaPath: '#/definitions/UserGroup',
        schemaKey: 'UserGroup'
      }
    }, 'getEstimatesPermissionsWithEstimateIdRequest', '/estimates-api'),
    actions: {
      start: {
        type: DATA_GET_ESTIMATES_PERMISSIONS_WITH_ESTIMATEID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_ESTIMATES_PERMISSIONS_WITH_ESTIMATEID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_ESTIMATES_PERMISSIONS_WITH_ESTIMATEID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getEstimatesPermissionsWithEstimateIdRequestDefinitions = (definitionsArguments: {| requestArgs: getEstimatesPermissionsWithEstimateIdRequestEstimatesArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getEstimatesPermissionsWithEstimateIdRequestEstimatesArguments, payload?: Object |} => ({
  fnName: 'getEstimatesPermissionsWithEstimateIdRequest',
  basePath: '/estimates-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_POST_ESTIMATES_PERMISSIONS_WITH_ESTIMATEID_START = 'DATA_POST_ESTIMATES_PERMISSIONS_WITH_ESTIMATEID_START'
export const DATA_POST_ESTIMATES_PERMISSIONS_WITH_ESTIMATEID_SUCCESSFUL = 'DATA_POST_ESTIMATES_PERMISSIONS_WITH_ESTIMATEID_SUCCESSFUL'
export const DATA_POST_ESTIMATES_PERMISSIONS_WITH_ESTIMATEID_FAIL = 'DATA_POST_ESTIMATES_PERMISSIONS_WITH_ESTIMATEID_FAIL'

// Grants new permissions for Users or User Groups to an Estimate.
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/estimates.yaml#/Estimates/post_estimates__estimateId__permissions
export const postEstimatesPermissionsWithEstimateIdRequest = (
  requestArguments: postEstimatesPermissionsWithEstimateIdRequestEstimatesArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_POST_ESTIMATES_PERMISSIONS_WITH_ESTIMATEID_START',
      successful: 'DATA_POST_ESTIMATES_PERMISSIONS_WITH_ESTIMATEID_SUCCESSFUL',
      fail: 'DATA_POST_ESTIMATES_PERMISSIONS_WITH_ESTIMATEID_FAIL'
    },
    embeddedType: null,
    fnName: 'postEstimatesPermissionsWithEstimateIdRequest'
  }
  const config = {
    headers: { 'Content-Type': 'application/json' },
    baseURL: `${API_BASE_URL}${options?.basePath || '/estimates-api'}`,
    method: 'POST',
    url: `/estimates/${options?.estimateId || store.app.calculation}/permissions`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: requestArguments.body
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'postEstimatesPermissionsWithEstimateIdRequest', '/estimates-api'),
    actions: {
      start: {
        type: DATA_POST_ESTIMATES_PERMISSIONS_WITH_ESTIMATEID_START, payload, config, meta
      },
      successful: {
        type: DATA_POST_ESTIMATES_PERMISSIONS_WITH_ESTIMATEID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_POST_ESTIMATES_PERMISSIONS_WITH_ESTIMATEID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_PUT_ESTIMATES_PERMISSIONS_WITH_ESTIMATEID_START = 'DATA_PUT_ESTIMATES_PERMISSIONS_WITH_ESTIMATEID_START'
export const DATA_PUT_ESTIMATES_PERMISSIONS_WITH_ESTIMATEID_SUCCESSFUL = 'DATA_PUT_ESTIMATES_PERMISSIONS_WITH_ESTIMATEID_SUCCESSFUL'
export const DATA_PUT_ESTIMATES_PERMISSIONS_WITH_ESTIMATEID_FAIL = 'DATA_PUT_ESTIMATES_PERMISSIONS_WITH_ESTIMATEID_FAIL'

// Resets permissions on an Estimate to either public or private.
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/estimates.yaml#/Estimates/put_estimates__estimateId__permissions
export const putEstimatesPermissionsWithEstimateIdRequest = (
  requestArguments: putEstimatesPermissionsWithEstimateIdRequestEstimatesArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_PUT_ESTIMATES_PERMISSIONS_WITH_ESTIMATEID_START',
      successful: 'DATA_PUT_ESTIMATES_PERMISSIONS_WITH_ESTIMATEID_SUCCESSFUL',
      fail: 'DATA_PUT_ESTIMATES_PERMISSIONS_WITH_ESTIMATEID_FAIL'
    },
    embeddedType: null,
    fnName: 'putEstimatesPermissionsWithEstimateIdRequest'
  }
  const config = {
    headers: { 'Content-Type': 'application/json' },
    baseURL: `${API_BASE_URL}${options?.basePath || '/estimates-api'}`,
    method: 'PUT',
    url: `/estimates/${options?.estimateId || store.app.calculation}/permissions`,
    params: {
      resetPermissions: requestArguments.query['resetPermissions'],
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'putEstimatesPermissionsWithEstimateIdRequest', '/estimates-api'),
    actions: {
      start: {
        type: DATA_PUT_ESTIMATES_PERMISSIONS_WITH_ESTIMATEID_START, payload, config, meta
      },
      successful: {
        type: DATA_PUT_ESTIMATES_PERMISSIONS_WITH_ESTIMATEID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_PUT_ESTIMATES_PERMISSIONS_WITH_ESTIMATEID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_DELETE_ESTIMATES_PERMISSIONS_WITH_ESTIMATEID_START = 'DATA_DELETE_ESTIMATES_PERMISSIONS_WITH_ESTIMATEID_START'
export const DATA_DELETE_ESTIMATES_PERMISSIONS_WITH_ESTIMATEID_SUCCESSFUL = 'DATA_DELETE_ESTIMATES_PERMISSIONS_WITH_ESTIMATEID_SUCCESSFUL'
export const DATA_DELETE_ESTIMATES_PERMISSIONS_WITH_ESTIMATEID_FAIL = 'DATA_DELETE_ESTIMATES_PERMISSIONS_WITH_ESTIMATEID_FAIL'

// Revokes permissions from Users or User Groups to an estimate.
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/estimates.yaml#/Estimates/delete_estimates__estimateId__permissions
export const deleteEstimatesPermissionsWithEstimateIdRequest = (
  requestArguments: deleteEstimatesPermissionsWithEstimateIdRequestEstimatesArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_DELETE_ESTIMATES_PERMISSIONS_WITH_ESTIMATEID_START',
      successful: 'DATA_DELETE_ESTIMATES_PERMISSIONS_WITH_ESTIMATEID_SUCCESSFUL',
      fail: 'DATA_DELETE_ESTIMATES_PERMISSIONS_WITH_ESTIMATEID_FAIL'
    },
    embeddedType: null,
    fnName: 'deleteEstimatesPermissionsWithEstimateIdRequest'
  }
  const config = {
    headers: { 'Content-Type': 'application/json' },
    baseURL: `${API_BASE_URL}${options?.basePath || '/estimates-api'}`,
    method: 'DELETE',
    url: `/estimates/${options?.estimateId || store.app.calculation}/permissions`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: requestArguments.body
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'deleteEstimatesPermissionsWithEstimateIdRequest', '/estimates-api'),
    actions: {
      start: {
        type: DATA_DELETE_ESTIMATES_PERMISSIONS_WITH_ESTIMATEID_START, payload, config, meta
      },
      successful: {
        type: DATA_DELETE_ESTIMATES_PERMISSIONS_WITH_ESTIMATEID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_DELETE_ESTIMATES_PERMISSIONS_WITH_ESTIMATEID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_GET_ESTIMATES_PERMISSIONS_USERS_WITH_ESTIMATEID_START = 'DATA_GET_ESTIMATES_PERMISSIONS_USERS_WITH_ESTIMATEID_START'
export const DATA_GET_ESTIMATES_PERMISSIONS_USERS_WITH_ESTIMATEID_SUCCESSFUL = 'DATA_GET_ESTIMATES_PERMISSIONS_USERS_WITH_ESTIMATEID_SUCCESSFUL'
export const DATA_GET_ESTIMATES_PERMISSIONS_USERS_WITH_ESTIMATEID_FAIL = 'DATA_GET_ESTIMATES_PERMISSIONS_USERS_WITH_ESTIMATEID_FAIL'

// Gets permissions for users that have access to a estimate.
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/estimates.yaml#/Estimates/get_estimates__estimateId__permissions_users
export const getEstimatesPermissionsUsersWithEstimateIdRequest = (
  requestArguments: getEstimatesPermissionsUsersWithEstimateIdRequestEstimatesArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_ESTIMATES_PERMISSIONS_USERS_WITH_ESTIMATEID_START',
      successful: 'DATA_GET_ESTIMATES_PERMISSIONS_USERS_WITH_ESTIMATEID_SUCCESSFUL',
      fail: 'DATA_GET_ESTIMATES_PERMISSIONS_USERS_WITH_ESTIMATEID_FAIL'
    },
    embeddedType: {
      permissions: {
        schemaPath: '#/definitions/SubjectPermissions',
        schemaKey: 'SubjectPermissions'
      }
    },
    fnName: 'getEstimatesPermissionsUsersWithEstimateIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}${options?.basePath || '/estimates-api'}`,
    method: 'GET',
    url: `/estimates/${options?.estimateId || store.app.calculation}/permissions/users`,
    params: {
      directPermissions: requestArguments.query?.['directPermissions'],
      includeUsers: requestArguments.query?.['includeUsers'],
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      permissions: {
        schemaPath: '#/definitions/SubjectPermissions',
        schemaKey: 'SubjectPermissions'
      }
    }, 'getEstimatesPermissionsUsersWithEstimateIdRequest', '/estimates-api'),
    actions: {
      start: {
        type: DATA_GET_ESTIMATES_PERMISSIONS_USERS_WITH_ESTIMATEID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_ESTIMATES_PERMISSIONS_USERS_WITH_ESTIMATEID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_ESTIMATES_PERMISSIONS_USERS_WITH_ESTIMATEID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getEstimatesPermissionsUsersWithEstimateIdRequestDefinitions = (definitionsArguments: {| requestArgs: getEstimatesPermissionsUsersWithEstimateIdRequestEstimatesArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getEstimatesPermissionsUsersWithEstimateIdRequestEstimatesArguments, payload?: Object |} => ({
  fnName: 'getEstimatesPermissionsUsersWithEstimateIdRequest',
  basePath: '/estimates-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_GET_ESTIMATES_PERMISSIONS_USERS_SELF_WITH_ESTIMATEID_START = 'DATA_GET_ESTIMATES_PERMISSIONS_USERS_SELF_WITH_ESTIMATEID_START'
export const DATA_GET_ESTIMATES_PERMISSIONS_USERS_SELF_WITH_ESTIMATEID_SUCCESSFUL = 'DATA_GET_ESTIMATES_PERMISSIONS_USERS_SELF_WITH_ESTIMATEID_SUCCESSFUL'
export const DATA_GET_ESTIMATES_PERMISSIONS_USERS_SELF_WITH_ESTIMATEID_FAIL = 'DATA_GET_ESTIMATES_PERMISSIONS_USERS_SELF_WITH_ESTIMATEID_FAIL'

// Gets permissions for the current user for an estimate.
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/estimates.yaml#/Estimates/get_estimates__estimateId__permissions_users_self
export const getEstimatesPermissionsUsersSelfWithEstimateIdRequest = (
  requestArguments: getEstimatesPermissionsUsersSelfWithEstimateIdRequestEstimatesArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_ESTIMATES_PERMISSIONS_USERS_SELF_WITH_ESTIMATEID_START',
      successful: 'DATA_GET_ESTIMATES_PERMISSIONS_USERS_SELF_WITH_ESTIMATEID_SUCCESSFUL',
      fail: 'DATA_GET_ESTIMATES_PERMISSIONS_USERS_SELF_WITH_ESTIMATEID_FAIL'
    },
    embeddedType: {
      permissions: {
        schemaPath: '#/definitions/SubjectPermissions',
        schemaKey: 'SubjectPermissions'
      }
    },
    fnName: 'getEstimatesPermissionsUsersSelfWithEstimateIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}${options?.basePath || '/estimates-api'}`,
    method: 'GET',
    url: `/estimates/${options?.estimateId || store.app.calculation}/permissions/users/self`,
    params: {
      directPermissions: requestArguments.query?.['directPermissions'],
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      permissions: {
        schemaPath: '#/definitions/SubjectPermissions',
        schemaKey: 'SubjectPermissions'
      }
    }, 'getEstimatesPermissionsUsersSelfWithEstimateIdRequest', '/estimates-api'),
    actions: {
      start: {
        type: DATA_GET_ESTIMATES_PERMISSIONS_USERS_SELF_WITH_ESTIMATEID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_ESTIMATES_PERMISSIONS_USERS_SELF_WITH_ESTIMATEID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_ESTIMATES_PERMISSIONS_USERS_SELF_WITH_ESTIMATEID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getEstimatesPermissionsUsersSelfWithEstimateIdRequestDefinitions = (definitionsArguments: {| requestArgs: getEstimatesPermissionsUsersSelfWithEstimateIdRequestEstimatesArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getEstimatesPermissionsUsersSelfWithEstimateIdRequestEstimatesArguments, payload?: Object |} => ({
  fnName: 'getEstimatesPermissionsUsersSelfWithEstimateIdRequest',
  basePath: '/estimates-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_GET_ESTIMATES_PERMISSIONS_USER_GROUPS_WITH_ESTIMATEID_START = 'DATA_GET_ESTIMATES_PERMISSIONS_USER_GROUPS_WITH_ESTIMATEID_START'
export const DATA_GET_ESTIMATES_PERMISSIONS_USER_GROUPS_WITH_ESTIMATEID_SUCCESSFUL = 'DATA_GET_ESTIMATES_PERMISSIONS_USER_GROUPS_WITH_ESTIMATEID_SUCCESSFUL'
export const DATA_GET_ESTIMATES_PERMISSIONS_USER_GROUPS_WITH_ESTIMATEID_FAIL = 'DATA_GET_ESTIMATES_PERMISSIONS_USER_GROUPS_WITH_ESTIMATEID_FAIL'

// Gets permissions for user groups that have access to a estimate.
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/estimates.yaml#/Estimates/get_estimates__estimateId__permissions_user_groups
export const getEstimatesPermissionsUserGroupsWithEstimateIdRequest = (
  requestArguments: getEstimatesPermissionsUserGroupsWithEstimateIdRequestEstimatesArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_ESTIMATES_PERMISSIONS_USER_GROUPS_WITH_ESTIMATEID_START',
      successful: 'DATA_GET_ESTIMATES_PERMISSIONS_USER_GROUPS_WITH_ESTIMATEID_SUCCESSFUL',
      fail: 'DATA_GET_ESTIMATES_PERMISSIONS_USER_GROUPS_WITH_ESTIMATEID_FAIL'
    },
    embeddedType: {
      permissions: {
        schemaPath: '#/definitions/SubjectPermissions',
        schemaKey: 'SubjectPermissions'
      }
    },
    fnName: 'getEstimatesPermissionsUserGroupsWithEstimateIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}${options?.basePath || '/estimates-api'}`,
    method: 'GET',
    url: `/estimates/${options?.estimateId || store.app.calculation}/permissions/user-groups`,
    params: {
      directPermissions: requestArguments.query?.['directPermissions'],
      includeUserGroups: requestArguments.query?.['includeUserGroups'],
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      permissions: {
        schemaPath: '#/definitions/SubjectPermissions',
        schemaKey: 'SubjectPermissions'
      }
    }, 'getEstimatesPermissionsUserGroupsWithEstimateIdRequest', '/estimates-api'),
    actions: {
      start: {
        type: DATA_GET_ESTIMATES_PERMISSIONS_USER_GROUPS_WITH_ESTIMATEID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_ESTIMATES_PERMISSIONS_USER_GROUPS_WITH_ESTIMATEID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_ESTIMATES_PERMISSIONS_USER_GROUPS_WITH_ESTIMATEID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getEstimatesPermissionsUserGroupsWithEstimateIdRequestDefinitions = (definitionsArguments: {| requestArgs: getEstimatesPermissionsUserGroupsWithEstimateIdRequestEstimatesArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getEstimatesPermissionsUserGroupsWithEstimateIdRequestEstimatesArguments, payload?: Object |} => ({
  fnName: 'getEstimatesPermissionsUserGroupsWithEstimateIdRequest',
  basePath: '/estimates-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_GET_ESTIMATES_INDEXPOINTS_START = 'DATA_GET_ESTIMATES_INDEXPOINTS_START'
export const DATA_GET_ESTIMATES_INDEXPOINTS_SUCCESSFUL = 'DATA_GET_ESTIMATES_INDEXPOINTS_SUCCESSFUL'
export const DATA_GET_ESTIMATES_INDEXPOINTS_FAIL = 'DATA_GET_ESTIMATES_INDEXPOINTS_FAIL'

// Gets index points for a given index date and adjustment
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/estimates.yaml#/Estimates/get_estimates_indexpoints
export const getEstimatesIndexpointsRequest = (
  requestArguments: getEstimatesIndexpointsRequestEstimatesArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_ESTIMATES_INDEXPOINTS_START',
      successful: 'DATA_GET_ESTIMATES_INDEXPOINTS_SUCCESSFUL',
      fail: 'DATA_GET_ESTIMATES_INDEXPOINTS_FAIL'
    },
    embeddedType: {
      nonEmbedded: {
        schemaKey: null
      }
    },
    fnName: 'getEstimatesIndexpointsRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}${options?.basePath || '/estimates-api'}`,
    method: 'GET',
    url: '/estimates/indexpoints',
    params: {
      assessmentDate: requestArguments.query['assessmentDate'],
      priceLevelChangeP: requestArguments.query['priceLevelChangeP'],
      buildingId: requestArguments.query['buildingId'],
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      nonEmbedded: {
        schemaKey: null
      }
    }, 'getEstimatesIndexpointsRequest', '/estimates-api'),
    actions: {
      start: {
        type: DATA_GET_ESTIMATES_INDEXPOINTS_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_ESTIMATES_INDEXPOINTS_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_ESTIMATES_INDEXPOINTS_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getEstimatesIndexpointsRequestDefinitions = (definitionsArguments: {| requestArgs: getEstimatesIndexpointsRequestEstimatesArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getEstimatesIndexpointsRequestEstimatesArguments, payload?: Object |} => ({
  fnName: 'getEstimatesIndexpointsRequest',
  basePath: '/estimates-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_GET_ESTIMATES_LOCATIONINFO_START = 'DATA_GET_ESTIMATES_LOCATIONINFO_START'
export const DATA_GET_ESTIMATES_LOCATIONINFO_SUCCESSFUL = 'DATA_GET_ESTIMATES_LOCATIONINFO_SUCCESSFUL'
export const DATA_GET_ESTIMATES_LOCATIONINFO_FAIL = 'DATA_GET_ESTIMATES_LOCATIONINFO_FAIL'

// Gets location info for municipal district.
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/estimates.yaml#/Estimates/get_estimates_locationInfo
export const getEstimatesLocationInfoRequest = (
  requestArguments: getEstimatesLocationInfoRequestEstimatesArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_ESTIMATES_LOCATIONINFO_START',
      successful: 'DATA_GET_ESTIMATES_LOCATIONINFO_SUCCESSFUL',
      fail: 'DATA_GET_ESTIMATES_LOCATIONINFO_FAIL'
    },
    embeddedType: {
      nonEmbedded: {
        schemaKey: null
      }
    },
    fnName: 'getEstimatesLocationInfoRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}${options?.basePath || '/estimates-api'}`,
    method: 'GET',
    url: '/estimates/locationInfo',
    params: {
      municipalDistrictId: requestArguments.query?.['municipalDistrictId'],
      postalCode: requestArguments.query?.['postalCode'],
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      nonEmbedded: {
        schemaKey: null
      }
    }, 'getEstimatesLocationInfoRequest', '/estimates-api'),
    actions: {
      start: {
        type: DATA_GET_ESTIMATES_LOCATIONINFO_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_ESTIMATES_LOCATIONINFO_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_ESTIMATES_LOCATIONINFO_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getEstimatesLocationInfoRequestDefinitions = (definitionsArguments: {| requestArgs: getEstimatesLocationInfoRequestEstimatesArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getEstimatesLocationInfoRequestEstimatesArguments, payload?: Object |} => ({
  fnName: 'getEstimatesLocationInfoRequest',
  basePath: '/estimates-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_GET_ESTIMATES_INFO_WITH_PROPERTYNAME_START = 'DATA_GET_ESTIMATES_INFO_WITH_PROPERTYNAME_START'
export const DATA_GET_ESTIMATES_INFO_WITH_PROPERTYNAME_SUCCESSFUL = 'DATA_GET_ESTIMATES_INFO_WITH_PROPERTYNAME_SUCCESSFUL'
export const DATA_GET_ESTIMATES_INFO_WITH_PROPERTYNAME_FAIL = 'DATA_GET_ESTIMATES_INFO_WITH_PROPERTYNAME_FAIL'

// Get short information text for an estimate property
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/estimates.yaml#/Estimates/get_estimates_info__propertyName_
export const getEstimatesInfoWithPropertyNameRequest = (
  requestArguments: getEstimatesInfoWithPropertyNameRequestEstimatesArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_ESTIMATES_INFO_WITH_PROPERTYNAME_START',
      successful: 'DATA_GET_ESTIMATES_INFO_WITH_PROPERTYNAME_SUCCESSFUL',
      fail: 'DATA_GET_ESTIMATES_INFO_WITH_PROPERTYNAME_FAIL'
    },
    embeddedType: null,
    fnName: 'getEstimatesInfoWithPropertyNameRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}${options?.basePath || '/estimates-api'}`,
    method: 'GET',
    url: `/estimates/info/${requestArguments.path['propertyName']}`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'getEstimatesInfoWithPropertyNameRequest', '/estimates-api'),
    actions: {
      start: {
        type: DATA_GET_ESTIMATES_INFO_WITH_PROPERTYNAME_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_ESTIMATES_INFO_WITH_PROPERTYNAME_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_ESTIMATES_INFO_WITH_PROPERTYNAME_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getEstimatesInfoWithPropertyNameRequestDefinitions = (definitionsArguments: {| requestArgs: getEstimatesInfoWithPropertyNameRequestEstimatesArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getEstimatesInfoWithPropertyNameRequestEstimatesArguments, payload?: Object |} => ({
  fnName: 'getEstimatesInfoWithPropertyNameRequest',
  basePath: '/estimates-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_GET_GIS_POSTAL_CODES_START = 'DATA_GET_GIS_POSTAL_CODES_START'
export const DATA_GET_GIS_POSTAL_CODES_SUCCESSFUL = 'DATA_GET_GIS_POSTAL_CODES_SUCCESSFUL'
export const DATA_GET_GIS_POSTAL_CODES_FAIL = 'DATA_GET_GIS_POSTAL_CODES_FAIL'

// Gets the postal codes for a municipality id. Also returns the default postal code in "defaultValue" field.
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/estimates.yaml#/GIS/get_gis_postal_codes
export const getGisPostalCodesRequest = (
  requestArguments: getGisPostalCodesRequestEstimatesArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_GIS_POSTAL_CODES_START',
      successful: 'DATA_GET_GIS_POSTAL_CODES_SUCCESSFUL',
      fail: 'DATA_GET_GIS_POSTAL_CODES_FAIL'
    },
    embeddedType: {
      'postal-codes': {
        schemaPath: '#/definitions/Enumeral',
        schemaKey: 'Enumeral'
      }
    },
    fnName: 'getGisPostalCodesRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}${options?.basePath || '/estimates-api'}`,
    method: 'GET',
    url: '/gis/postal-codes',
    params: {
      municipalityId: requestArguments.query?.['municipalityId'],
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      'postal-codes': {
        schemaPath: '#/definitions/Enumeral',
        schemaKey: 'Enumeral'
      }
    }, 'getGisPostalCodesRequest', '/estimates-api'),
    actions: {
      start: {
        type: DATA_GET_GIS_POSTAL_CODES_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_GIS_POSTAL_CODES_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_GIS_POSTAL_CODES_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getGisPostalCodesRequestDefinitions = (definitionsArguments: {| requestArgs: getGisPostalCodesRequestEstimatesArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getGisPostalCodesRequestEstimatesArguments, payload?: Object |} => ({
  fnName: 'getGisPostalCodesRequest',
  basePath: '/estimates-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_GET_GIS_POSTAL_CODES_DEFAULT_START = 'DATA_GET_GIS_POSTAL_CODES_DEFAULT_START'
export const DATA_GET_GIS_POSTAL_CODES_DEFAULT_SUCCESSFUL = 'DATA_GET_GIS_POSTAL_CODES_DEFAULT_SUCCESSFUL'
export const DATA_GET_GIS_POSTAL_CODES_DEFAULT_FAIL = 'DATA_GET_GIS_POSTAL_CODES_DEFAULT_FAIL'

// Gets the default postal code and localized municipality name for given search parameters. If search returns multiple results, best match is returned.
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/estimates.yaml#/GIS/get_gis_postal_codes_default
export const getGisPostalCodesDefaultRequest = (
  requestArguments: getGisPostalCodesDefaultRequestEstimatesArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_GIS_POSTAL_CODES_DEFAULT_START',
      successful: 'DATA_GET_GIS_POSTAL_CODES_DEFAULT_SUCCESSFUL',
      fail: 'DATA_GET_GIS_POSTAL_CODES_DEFAULT_FAIL'
    },
    embeddedType: {
      nonEmbedded: {
        schemaPath: '#/definitions/Enumeral',
        schemaKey: 'Enumeral'
      }
    },
    fnName: 'getGisPostalCodesDefaultRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}${options?.basePath || '/estimates-api'}`,
    method: 'GET',
    url: '/gis/postal-codes/default',
    params: {
      municipalityId: requestArguments.query?.['municipalityId'],
      municipalityName: requestArguments.query?.['municipalityName'],
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      nonEmbedded: {
        schemaPath: '#/definitions/Enumeral',
        schemaKey: 'Enumeral'
      }
    }, 'getGisPostalCodesDefaultRequest', '/estimates-api'),
    actions: {
      start: {
        type: DATA_GET_GIS_POSTAL_CODES_DEFAULT_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_GIS_POSTAL_CODES_DEFAULT_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_GIS_POSTAL_CODES_DEFAULT_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getGisPostalCodesDefaultRequestDefinitions = (definitionsArguments: {| requestArgs: getGisPostalCodesDefaultRequestEstimatesArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getGisPostalCodesDefaultRequestEstimatesArguments, payload?: Object |} => ({
  fnName: 'getGisPostalCodesDefaultRequest',
  basePath: '/estimates-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_GET_GIS_POSTAL_CODES_MUNICIPALITY_WITH_POSTALCODE_START = 'DATA_GET_GIS_POSTAL_CODES_MUNICIPALITY_WITH_POSTALCODE_START'
export const DATA_GET_GIS_POSTAL_CODES_MUNICIPALITY_WITH_POSTALCODE_SUCCESSFUL = 'DATA_GET_GIS_POSTAL_CODES_MUNICIPALITY_WITH_POSTALCODE_SUCCESSFUL'
export const DATA_GET_GIS_POSTAL_CODES_MUNICIPALITY_WITH_POSTALCODE_FAIL = 'DATA_GET_GIS_POSTAL_CODES_MUNICIPALITY_WITH_POSTALCODE_FAIL'

// Gets the value and localizedName of the municipality to which the given postal code area belongs to
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/estimates.yaml#/GIS/get_gis_postal_codes__postalCode__municipality
export const getGisPostalCodesMunicipalityWithPostalCodeRequest = (
  requestArguments: getGisPostalCodesMunicipalityWithPostalCodeRequestEstimatesArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_GIS_POSTAL_CODES_MUNICIPALITY_WITH_POSTALCODE_START',
      successful: 'DATA_GET_GIS_POSTAL_CODES_MUNICIPALITY_WITH_POSTALCODE_SUCCESSFUL',
      fail: 'DATA_GET_GIS_POSTAL_CODES_MUNICIPALITY_WITH_POSTALCODE_FAIL'
    },
    embeddedType: {
      nonEmbedded: {
        schemaPath: '#/definitions/Enumeral',
        schemaKey: 'Enumeral'
      }
    },
    fnName: 'getGisPostalCodesMunicipalityWithPostalCodeRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}${options?.basePath || '/estimates-api'}`,
    method: 'GET',
    url: `/gis/postal-codes/${requestArguments.path['postalCode']}/municipality`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      nonEmbedded: {
        schemaPath: '#/definitions/Enumeral',
        schemaKey: 'Enumeral'
      }
    }, 'getGisPostalCodesMunicipalityWithPostalCodeRequest', '/estimates-api'),
    actions: {
      start: {
        type: DATA_GET_GIS_POSTAL_CODES_MUNICIPALITY_WITH_POSTALCODE_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_GIS_POSTAL_CODES_MUNICIPALITY_WITH_POSTALCODE_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_GIS_POSTAL_CODES_MUNICIPALITY_WITH_POSTALCODE_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getGisPostalCodesMunicipalityWithPostalCodeRequestDefinitions = (definitionsArguments: {| requestArgs: getGisPostalCodesMunicipalityWithPostalCodeRequestEstimatesArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getGisPostalCodesMunicipalityWithPostalCodeRequestEstimatesArguments, payload?: Object |} => ({
  fnName: 'getGisPostalCodesMunicipalityWithPostalCodeRequest',
  basePath: '/estimates-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_GET_ENUM_WITH_ENUM_START = 'DATA_GET_ENUM_WITH_ENUM_START'
export const DATA_GET_ENUM_WITH_ENUM_SUCCESSFUL = 'DATA_GET_ENUM_WITH_ENUM_SUCCESSFUL'
export const DATA_GET_ENUM_WITH_ENUM_FAIL = 'DATA_GET_ENUM_WITH_ENUM_FAIL'

// Returns members of given enum as embedded Enumerals
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/estimates.yaml#/Enum/get_enum__enum_
export const getEnumWithEnumRequest = (
  requestArguments: getEnumWithEnumRequestEstimatesArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_ENUM_WITH_ENUM_START',
      successful: 'DATA_GET_ENUM_WITH_ENUM_SUCCESSFUL',
      fail: 'DATA_GET_ENUM_WITH_ENUM_FAIL'
    },
    embeddedType: {
      nonEmbedded: {
        schemaPath: '#/definitions/HAL',
        schemaKey: 'HAL'
      }
    },
    fnName: 'getEnumWithEnumRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}${options?.basePath || '/estimates-api'}`,
    method: 'GET',
    url: `/enum/${requestArguments.path['enumParam']}`,
    params: {
      category: requestArguments.query?.['category'],
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      nonEmbedded: {
        schemaPath: '#/definitions/HAL',
        schemaKey: 'HAL'
      }
    }, 'getEnumWithEnumRequest', '/estimates-api'),
    actions: {
      start: {
        type: DATA_GET_ENUM_WITH_ENUM_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_ENUM_WITH_ENUM_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_ENUM_WITH_ENUM_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getEnumWithEnumRequestDefinitions = (definitionsArguments: {| requestArgs: getEnumWithEnumRequestEstimatesArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getEnumWithEnumRequestEstimatesArguments, payload?: Object |} => ({
  fnName: 'getEnumWithEnumRequest',
  basePath: '/estimates-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_GET_QUEUE_WITH_ID_START = 'DATA_GET_QUEUE_WITH_ID_START'
export const DATA_GET_QUEUE_WITH_ID_SUCCESSFUL = 'DATA_GET_QUEUE_WITH_ID_SUCCESSFUL'
export const DATA_GET_QUEUE_WITH_ID_FAIL = 'DATA_GET_QUEUE_WITH_ID_FAIL'

// Returns status of queued operation
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/estimates.yaml#/Queue/get_queue__id_
export const getQueueWithIdRequest = (
  requestArguments: getQueueWithIdRequestEstimatesArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_QUEUE_WITH_ID_START',
      successful: 'DATA_GET_QUEUE_WITH_ID_SUCCESSFUL',
      fail: 'DATA_GET_QUEUE_WITH_ID_FAIL'
    },
    embeddedType: {
      nonEmbedded: {
        schemaPath: '#/definitions/QueueStatus',
        schemaKey: 'QueueStatus'
      }
    },
    fnName: 'getQueueWithIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}${options?.basePath || '/estimates-api'}`,
    method: 'GET',
    url: `/queue/${requestArguments.path['id']}`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      nonEmbedded: {
        schemaPath: '#/definitions/QueueStatus',
        schemaKey: 'QueueStatus'
      }
    }, 'getQueueWithIdRequest', '/estimates-api'),
    actions: {
      start: {
        type: DATA_GET_QUEUE_WITH_ID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_QUEUE_WITH_ID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_QUEUE_WITH_ID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getQueueWithIdRequestDefinitions = (definitionsArguments: {| requestArgs: getQueueWithIdRequestEstimatesArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getQueueWithIdRequestEstimatesArguments, payload?: Object |} => ({
  fnName: 'getQueueWithIdRequest',
  basePath: '/estimates-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_DELETE_QUEUE_WITH_ID_START = 'DATA_DELETE_QUEUE_WITH_ID_START'
export const DATA_DELETE_QUEUE_WITH_ID_SUCCESSFUL = 'DATA_DELETE_QUEUE_WITH_ID_SUCCESSFUL'
export const DATA_DELETE_QUEUE_WITH_ID_FAIL = 'DATA_DELETE_QUEUE_WITH_ID_FAIL'

// Deletes an operation from the queue
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/estimates.yaml#/Queue/delete_queue__id_
export const deleteQueueWithIdRequest = (
  requestArguments: deleteQueueWithIdRequestEstimatesArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_DELETE_QUEUE_WITH_ID_START',
      successful: 'DATA_DELETE_QUEUE_WITH_ID_SUCCESSFUL',
      fail: 'DATA_DELETE_QUEUE_WITH_ID_FAIL'
    },
    embeddedType: null,
    fnName: 'deleteQueueWithIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}${options?.basePath || '/estimates-api'}`,
    method: 'DELETE',
    url: `/queue/${requestArguments.path['id']}`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'deleteQueueWithIdRequest', '/estimates-api'),
    actions: {
      start: {
        type: DATA_DELETE_QUEUE_WITH_ID_START, payload, config, meta
      },
      successful: {
        type: DATA_DELETE_QUEUE_WITH_ID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_DELETE_QUEUE_WITH_ID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_GET_SWAGGER_START = 'DATA_GET_SWAGGER_START'
export const DATA_GET_SWAGGER_SUCCESSFUL = 'DATA_GET_SWAGGER_SUCCESSFUL'
export const DATA_GET_SWAGGER_FAIL = 'DATA_GET_SWAGGER_FAIL'

// Returns Swagger specification of the API
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/estimates.yaml#/Swagger/get_swagger
export const getSwaggerRequest = (
  payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_SWAGGER_START',
      successful: 'DATA_GET_SWAGGER_SUCCESSFUL',
      fail: 'DATA_GET_SWAGGER_FAIL'
    },
    embeddedType: null,
    fnName: 'getSwaggerRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}${options?.basePath || '/estimates-api'}`,
    method: 'GET',
    url: '/swagger',
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'getSwaggerRequest', '/estimates-api'),
    actions: {
      start: {
        type: DATA_GET_SWAGGER_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_SWAGGER_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_SWAGGER_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getSwaggerRequestDefinitions = (definitionsArguments: {| payload?: Object |}): {|
  ...TVDGARConfigWithoutRequestArgs, payload?: Object |} => ({
  fnName: 'getSwaggerRequest',
  basePath: '/estimates-api',
  payload: definitionsArguments.payload,
})


export const DATA_GET_SWAGGER_WITH_NODE_START = 'DATA_GET_SWAGGER_WITH_NODE_START'
export const DATA_GET_SWAGGER_WITH_NODE_SUCCESSFUL = 'DATA_GET_SWAGGER_WITH_NODE_SUCCESSFUL'
export const DATA_GET_SWAGGER_WITH_NODE_FAIL = 'DATA_GET_SWAGGER_WITH_NODE_FAIL'

// Returns a first level node from the Swagger specification of the API
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/estimates.yaml#/Swagger/get_swagger__node_
export const getSwaggerWithNodeRequest = (
  requestArguments: getSwaggerWithNodeRequestEstimatesArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_SWAGGER_WITH_NODE_START',
      successful: 'DATA_GET_SWAGGER_WITH_NODE_SUCCESSFUL',
      fail: 'DATA_GET_SWAGGER_WITH_NODE_FAIL'
    },
    embeddedType: null,
    fnName: 'getSwaggerWithNodeRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}${options?.basePath || '/estimates-api'}`,
    method: 'GET',
    url: `/swagger/${requestArguments.path['node']}`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'getSwaggerWithNodeRequest', '/estimates-api'),
    actions: {
      start: {
        type: DATA_GET_SWAGGER_WITH_NODE_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_SWAGGER_WITH_NODE_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_SWAGGER_WITH_NODE_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getSwaggerWithNodeRequestDefinitions = (definitionsArguments: {| requestArgs: getSwaggerWithNodeRequestEstimatesArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getSwaggerWithNodeRequestEstimatesArguments, payload?: Object |} => ({
  fnName: 'getSwaggerWithNodeRequest',
  basePath: '/estimates-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_GET_SWAGGER_WITH_CHILDNODE_START = 'DATA_GET_SWAGGER_WITH_CHILDNODE_START'
export const DATA_GET_SWAGGER_WITH_CHILDNODE_SUCCESSFUL = 'DATA_GET_SWAGGER_WITH_CHILDNODE_SUCCESSFUL'
export const DATA_GET_SWAGGER_WITH_CHILDNODE_FAIL = 'DATA_GET_SWAGGER_WITH_CHILDNODE_FAIL'

// Returns a second level node from the Swagger specification of the API
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/estimates.yaml#/Swagger/get_swagger__node___childNode_
export const getSwaggerWithChildNodeRequest = (
  requestArguments: getSwaggerWithChildNodeRequestEstimatesArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_SWAGGER_WITH_CHILDNODE_START',
      successful: 'DATA_GET_SWAGGER_WITH_CHILDNODE_SUCCESSFUL',
      fail: 'DATA_GET_SWAGGER_WITH_CHILDNODE_FAIL'
    },
    embeddedType: null,
    fnName: 'getSwaggerWithChildNodeRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}${options?.basePath || '/estimates-api'}`,
    method: 'GET',
    url: `/swagger/${requestArguments.path['node']}/${requestArguments.path['childNode']}`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'getSwaggerWithChildNodeRequest', '/estimates-api'),
    actions: {
      start: {
        type: DATA_GET_SWAGGER_WITH_CHILDNODE_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_SWAGGER_WITH_CHILDNODE_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_SWAGGER_WITH_CHILDNODE_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getSwaggerWithChildNodeRequestDefinitions = (definitionsArguments: {| requestArgs: getSwaggerWithChildNodeRequestEstimatesArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getSwaggerWithChildNodeRequestEstimatesArguments, payload?: Object |} => ({
  fnName: 'getSwaggerWithChildNodeRequest',
  basePath: '/estimates-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_GET_DOCUMENTS_START = 'DATA_GET_DOCUMENTS_START'
export const DATA_GET_DOCUMENTS_SUCCESSFUL = 'DATA_GET_DOCUMENTS_SUCCESSFUL'
export const DATA_GET_DOCUMENTS_FAIL = 'DATA_GET_DOCUMENTS_FAIL'

// Returns an embedded collection of documents
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/estimates.yaml#/Documents/get_documents
export const getDocumentsRequest = (
  requestArguments: getDocumentsRequestEstimatesArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_DOCUMENTS_START',
      successful: 'DATA_GET_DOCUMENTS_SUCCESSFUL',
      fail: 'DATA_GET_DOCUMENTS_FAIL'
    },
    embeddedType: {
      documents: {
        schemaPath: '#/definitions/Document',
        schemaKey: 'Document'
      }
    },
    fnName: 'getDocumentsRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}${options?.basePath || '/estimates-api'}`,
    method: 'GET',
    url: '/documents',
    params: {
      category: requestArguments.query?.['category'],
      contentSearch: requestArguments.query?.['contentSearch'],
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      documents: {
        schemaPath: '#/definitions/Document',
        schemaKey: 'Document'
      }
    }, 'getDocumentsRequest', '/estimates-api'),
    actions: {
      start: {
        type: DATA_GET_DOCUMENTS_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_DOCUMENTS_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_DOCUMENTS_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getDocumentsRequestDefinitions = (definitionsArguments: {| requestArgs: getDocumentsRequestEstimatesArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getDocumentsRequestEstimatesArguments, payload?: Object |} => ({
  fnName: 'getDocumentsRequest',
  basePath: '/estimates-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_GET_DOCUMENTS_WITH_ID_START = 'DATA_GET_DOCUMENTS_WITH_ID_START'
export const DATA_GET_DOCUMENTS_WITH_ID_SUCCESSFUL = 'DATA_GET_DOCUMENTS_WITH_ID_SUCCESSFUL'
export const DATA_GET_DOCUMENTS_WITH_ID_FAIL = 'DATA_GET_DOCUMENTS_WITH_ID_FAIL'

// Returns a Markdown-formatted document (for example help texts)
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/estimates.yaml#/Documents/get_documents__category___id_
export const getDocumentsWithIdRequest = (
  requestArguments: getDocumentsWithIdRequestEstimatesArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_DOCUMENTS_WITH_ID_START',
      successful: 'DATA_GET_DOCUMENTS_WITH_ID_SUCCESSFUL',
      fail: 'DATA_GET_DOCUMENTS_WITH_ID_FAIL'
    },
    embeddedType: null,
    fnName: 'getDocumentsWithIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}${options?.basePath || '/estimates-api'}`,
    method: 'GET',
    url: `/documents/${requestArguments.path['category']}/${requestArguments.path['id']}`,
    params: {
      language: requestArguments.query?.['language'],
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'getDocumentsWithIdRequest', '/estimates-api'),
    actions: {
      start: {
        type: DATA_GET_DOCUMENTS_WITH_ID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_DOCUMENTS_WITH_ID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_DOCUMENTS_WITH_ID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getDocumentsWithIdRequestDefinitions = (definitionsArguments: {| requestArgs: getDocumentsWithIdRequestEstimatesArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getDocumentsWithIdRequestEstimatesArguments, payload?: Object |} => ({
  fnName: 'getDocumentsWithIdRequest',
  basePath: '/estimates-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_GET_REPORTS_START = 'DATA_GET_REPORTS_START'
export const DATA_GET_REPORTS_SUCCESSFUL = 'DATA_GET_REPORTS_SUCCESSFUL'
export const DATA_GET_REPORTS_FAIL = 'DATA_GET_REPORTS_FAIL'

// Returns a list of available report types as Enumerals with reportType as value and a localized name for the type.
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/estimates.yaml#/Reports/get_reports
export const getReportsRequest = (
  payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_REPORTS_START',
      successful: 'DATA_GET_REPORTS_SUCCESSFUL',
      fail: 'DATA_GET_REPORTS_FAIL'
    },
    embeddedType: {
      items: {
        schemaPath: '#/definitions/Enumeral',
        schemaKey: 'Enumeral'
      }
    },
    fnName: 'getReportsRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}${options?.basePath || '/estimates-api'}`,
    method: 'GET',
    url: '/reports',
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      items: {
        schemaPath: '#/definitions/Enumeral',
        schemaKey: 'Enumeral'
      }
    }, 'getReportsRequest', '/estimates-api'),
    actions: {
      start: {
        type: DATA_GET_REPORTS_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_REPORTS_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_REPORTS_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getReportsRequestDefinitions = (definitionsArguments: {| payload?: Object |}): {|
  ...TVDGARConfigWithoutRequestArgs, payload?: Object |} => ({
  fnName: 'getReportsRequest',
  basePath: '/estimates-api',
  payload: definitionsArguments.payload,
})


export const DATA_GET_REPORTS_WITH_REPORTTYPE_START = 'DATA_GET_REPORTS_WITH_REPORTTYPE_START'
export const DATA_GET_REPORTS_WITH_REPORTTYPE_SUCCESSFUL = 'DATA_GET_REPORTS_WITH_REPORTTYPE_SUCCESSFUL'
export const DATA_GET_REPORTS_WITH_REPORTTYPE_FAIL = 'DATA_GET_REPORTS_WITH_REPORTTYPE_FAIL'

// Returns a pdf report of given type for requested estimate.
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/estimates.yaml#/Reports/get_reports__reportType_
export const getReportsWithReportTypeRequest = (
  requestArguments: getReportsWithReportTypeRequestEstimatesArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_REPORTS_WITH_REPORTTYPE_START',
      successful: 'DATA_GET_REPORTS_WITH_REPORTTYPE_SUCCESSFUL',
      fail: 'DATA_GET_REPORTS_WITH_REPORTTYPE_FAIL'
    },
    embeddedType: null,
    fnName: 'getReportsWithReportTypeRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}${options?.basePath || '/estimates-api'}`,
    method: 'GET',
    url: `/reports/${requestArguments.path['reportType']}`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'getReportsWithReportTypeRequest', '/estimates-api'),
    actions: {
      start: {
        type: DATA_GET_REPORTS_WITH_REPORTTYPE_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_REPORTS_WITH_REPORTTYPE_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_REPORTS_WITH_REPORTTYPE_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getReportsWithReportTypeRequestDefinitions = (definitionsArguments: {| requestArgs: getReportsWithReportTypeRequestEstimatesArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getReportsWithReportTypeRequestEstimatesArguments, payload?: Object |} => ({
  fnName: 'getReportsWithReportTypeRequest',
  basePath: '/estimates-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})
