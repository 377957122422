// @flow
// Copyright © 2010–2023 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.

export const SET_MFE_STATES = 'SET_MFE_STATES'
// TODO:  consider making the isOpen etc as an object so we can pass e.g  content props for the MFEContainer that is rendered via the floating window container
export function setMFEStates(
  mfeName: string,
  isOpen: boolean,
  componentName: string,
  contentProps?: any
): TVDAction {
  return {
    type: SET_MFE_STATES,
    payload: {
      mfeName,
      isOpen,
      componentName,
      contentProps
    }
  }
}

export const RESET_MFE_STATES = 'RESET_MFE_STATES'
export function resetMFEStates(): TVDAction {
  return {
    type: RESET_MFE_STATES,
    payload: {}
  }
}
