// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'

import FeaturesHOC from '../../../hocs/FeaturesHOC/FeaturesHOC'
import SentientHOC from '../../../hocs/SentientHOC/SentientHOC'
import HierarchicalListContainer from '../../HierarchicalListContainer/HierarchicalListContainer'
import HamburgerMenu from '../../../common/menus/HamburgerMenu/HamburgerMenu'
import InfoPopover from '../../../common/InfoPopover/InfoPopover'
import UserModifiedIcon from '../../infoComponents/UserModifiedIcon'
import DescriptionCell from '../../../common/lists/common/DescriptionCell/DescriptionCell'
import ViewHeaderText from '../../../common/ViewHeaderText/ViewHeaderText'

import { RENOVATION, NEWSPACESCHEDULE } from '../../../../constants/viewModeConstants'
import { BUILDING_ELEMENTS_SCHEDULE } from '../../../../constants/contentTypes'
import { MODIFICATION_NEW_BUILD_IN_CURRENCY, UNIT_PRICE, TOTAL_PRICE } from '../../../../constants/attributes'
import { BUILDING_ELEMENTS_SCHEDULE_CHANGE } from '../../../../constants/features'
import { formatCellContent, isListItemTypeBold } from '../../../../utils/listUtils'
import { setCalculationActive } from '../../../../actions/app'
import { modifyListItem } from '../../../../actions/list'
import { saveBuildingElementsScheduleList } from '../../../../utils/requests/list'
import {
  getBuildingElementsScheduleColumnsWithEstimateIdRequestDefinitions,
  getBuildingElementsScheduleWithEstimateIdRequestDefinitions
} from '../../../../utils/generated-api-requests/spaces'

const styles = ({ palette }: Object): Object => ({
  listContainer: {
    overflowY: 'auto',
    overflowX: 'hidden'
  },
  headerRowContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    color: palette.nevada,
  },
  contextMenuContainer: {
    minWidth: 15
  },
  unit: {
    color: palette.manatee,
  },
  infoIcon: {
    display: 'flex',
    alignItems: 'right',
    visibility: 'hidden',
    paddingRight: '10px',
  },
})

type HOCProps = {|
  t: Function, // translate function
  classes: Object, // withStyles classes object
  features: TVDFeatureHOCProps, // features from Store and helper functions from the HOC
  sentient: TVDSentient, // Object providing helpers via SentientHOC
|}

type MappedProps = {|
  isEstimateLockedToCurrentUser: $PropertyType<TVDApplicationStore, 'isEstimateLockedToCurrentUser'>, // if the user owns the lock for the estimate
  spacesEstimateType: $PropertyType<TVDApplicationStore, 'spacesEstimateType'>, // spaces estimate type
  spacesResultView: $PropertyType<TVDApplicationStore, 'spacesResultView'> // spaces result view
|}

type DispatchProps = {|
  dispatchModifyListItem: Function, // modify Change field on list item
  dispatchSaveBuildingElementsScheduleList: Function, // save changes
  dispatchSetLoading: Function, // function to show the spinner in widget
|}

type ReceivedProps = {|
  listStoreId: string, // list id found in Store
|}

type Props = {|
  ...HOCProps,
  ...MappedProps,
  ...DispatchProps,
  ...ReceivedProps
|}

export class BuildingElementsSchedule extends Component<Props> {
  static defaultProps = {
    isEstimateLockedToCurrentUser: false
  }

  componentDidUpdate(prevProps: Props) {
    const { sentient, spacesResultView, spacesEstimateType } = this.props
    if (
      prevProps.spacesResultView !== spacesResultView ||
      prevProps.spacesEstimateType !== spacesEstimateType
    ) {
      sentient.runSetup()
    }
  }

  getHamburgerMenu(listItem: Object): React$Element<HamburgerMenu> {
    const { t, dispatchModifyListItem } = this.props

    const menuItems: Array<TVDMenuItem> = [
      {
        localizedName: t('buttons._DELETE_'),
        onClick: () => { dispatchModifyListItem(listItem.parentId, listItem.id) },
        testId: 'ContextMenuItem-delete'
      }
    ]
    return <HamburgerMenu items={menuItems} visibleOnHover />
  }

  viewHeaderText(): React$Element<ViewHeaderText> | null {
    const { spacesEstimateType, t } = this.props
    return spacesEstimateType === RENOVATION ?
      <ViewHeaderText inWidget>{t('renovation._BUILDING_ELEMENTS_SCHEDULE_VIEW_HEADER_TEXT_')}</ViewHeaderText> :
      null
  }

  render(): React$Element<any> {
    const {
      classes,
      listStoreId,
      dispatchSaveBuildingElementsScheduleList,
      isEstimateLockedToCurrentUser,
      features,
      sentient,
      spacesResultView
    } = this.props
    return (
      <>
        { this.viewHeaderText() }
        <div className={classes.listContainer}>
          <HierarchicalListContainer
            initialColumnWidths={{
              Description: 450
            }}
            allowOverflow
            reverseTraverse={{
              filterItem: (listItem: TVDListItem) => listItem.columnData[MODIFICATION_NEW_BUILD_IN_CURRENCY],
              alterItem: (listItem: TVDListItem) => ({ ...listItem, showModifiedIcon: true })
            }}
            isEstimateLockedToCurrentUser={isEstimateLockedToCurrentUser}
            listId={listStoreId}
            testId={BUILDING_ELEMENTS_SCHEDULE}
            listType={BUILDING_ELEMENTS_SCHEDULE}
            editableColumns={[MODIFICATION_NEW_BUILD_IN_CURRENCY]}
            editableColumnUnits={[MODIFICATION_NEW_BUILD_IN_CURRENCY]}
            hiddenColumns={[
              ...spacesResultView === NEWSPACESCHEDULE ? [UNIT_PRICE, TOTAL_PRICE] : [],
              ...features.getIsFeatureDisabled(BUILDING_ELEMENTS_SCHEDULE_CHANGE) ? [MODIFICATION_NEW_BUILD_IN_CURRENCY] : [],
            ]}
            wrappedCellContents={{
              Description: ({ content, row: listItem }: Object) => {
                const {
                  type,
                  _links: {
                    info: {
                      href: linksInfoHref
                    } = {}
                  } = {}
                } = listItem
                return (
                  <div className={classes.headerRowContainer}>
                    <DescriptionCell text={content} bold={isListItemTypeBold(type)} />
                    <div className={classes.contextMenuContainer}>
                      { type === 'generated' && this.getHamburgerMenu(listItem) }
                    </div>
                    {
                      !!linksInfoHref &&
                      <div data-visible_on_hover className={classes.infoIcon}>
                        <InfoPopover
                          infoData={{
                            propertyName: listItem.columnData.Description,
                            heading: listItem.columnData.Description,
                            itemId: listItem.id,
                            type: BUILDING_ELEMENTS_SCHEDULE,
                          }}
                          id={`${listItem.columnData.Description}-InfoPopover`} />
                      </div>
                    }
                  </div>)
              },
              Quantity: ({
                content,
                dataType
              }: Object) => {
                const result = content ? `${formatCellContent(content, dataType)}` : ''
                return (
                  <div className={classes.unit}>{`${result}`}</div>
                )
              },
              Unit: ({ content, dataType }: Object) => formatCellContent(content, dataType),
              UnitPrice: ({ content, dataType }: Object) => formatCellContent(content, dataType),
              TotalPrice: ({
                content,
                row: listItem,
                dataType
              }: Object) => {
                const result = content ? `${formatCellContent(content, dataType)}` : ''
                return (
                  <div>
                    {result}
                    {listItem.columnData[MODIFICATION_NEW_BUILD_IN_CURRENCY] || listItem.showModifiedIcon ?
                      <span style={{ position: 'absolute', transform: 'translate3d(22px, 0, 0)' }}><UserModifiedIcon /></span>
                      : null }
                  </div>
                )
              },
            }}
            didMountCallback={() => {
            this.props.dispatchSetLoading()
            sentient.runSetup()
            }}
            onModifiedChange={(modifiedListItem: Object) => {
              if (modifiedListItem) {
                dispatchSaveBuildingElementsScheduleList()
              }
            }} />
        </div>
      </>
    )
  }
}

type ReduxState = {
  +widgets: Object,
  +app: Object,
}

type OwnProps = {
  widgetId: string, // key of the widget corresponding to the one in Store
}

function mapStateToProps({ widgets, app }: ReduxState, { widgetId }: OwnProps): Object {
  const { [widgetId]: { modified = false } = {} } = widgets
  const {
    isEstimateLockedToCurrentUser,
    spacesResultView,
    spacesEstimateType
  } = app
  return {
    modified,
    isEstimateLockedToCurrentUser,
    spacesResultView,
    spacesEstimateType
  }
}

function mapDispatchToProps(dispatch: Function, { listStoreId }: Object): Object {
  return {
    dispatchSaveBuildingElementsScheduleList: () => { dispatch(saveBuildingElementsScheduleList(listStoreId)) },
    dispatchSetLoading: () => { dispatch(setCalculationActive()) },
    dispatchModifyListItem: (listItemId: string) => {
      dispatch(modifyListItem({
        listItemId,
        listId: listStoreId,
        columnName: MODIFICATION_NEW_BUILD_IN_CURRENCY,
        value: '0'
      }))
    },
  }
}

const sentientConfig: TVDSentientConfig = {
  updateOnPropsChange: ({ spacesResultView, spacesEstimateType }: Props): Object => ({ spacesResultView, spacesEstimateType }),
  getSetupRequestDefinitions: (store: Object, { listStoreId }: Props) => ({
    getBuildingElementsScheduleColumnsWithEstimateIdRequesDefinitions: getBuildingElementsScheduleColumnsWithEstimateIdRequestDefinitions({
      payload: { listStoreId },
    }),
    getBuildingElementsScheduleWithEstimateIdRequestDefinitions: getBuildingElementsScheduleWithEstimateIdRequestDefinitions({
      payload: { listStoreId },
      requestArgs: { query: { listType: 'flat' } }
    }),
  }),
}

export default compose(
  withStyles(styles),
  withTranslation('translations'),
  FeaturesHOC,
  connect(mapStateToProps, mapDispatchToProps)
)(SentientHOC(BuildingElementsSchedule, sentientConfig))
