// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.

import { reduce } from 'lodash'

export const getFilteredWidgets = (state: Object, currentWidgetType: string): Object =>
  reduce(state, (result: Object, widget: Object, key: string) => {
    if (currentWidgetType === widget.widgetType) {
      return result
    }
    return {
      ...result,
      [key]: widget
    }
  }, {})
