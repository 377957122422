// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.
import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { map } from 'lodash'
import { withTranslation } from 'react-i18next'

import { SPACES, ELEMENTS, WOP, LOCAN } from '../../../constants/moduleConstants'
import Dialog from '../../../components/common/Dialog/Dialog'
import CreateCalculation from '../../../components/containers/CreateCalculation/CreateCalculation'

import { clearCalculations } from '../../../actions/calculations'
import { incrementModalCount, decrementModalCount } from '../../../actions/modals'
import { getEstimateHistoryObj } from '../../../utils/urlUtil'
import { elementTypeByApp } from '../../../utils/commonUtils'
import { getPathNameFromURL } from '../../../utils/apiUtils'
import { postEstimatesRequest, getEstimatesRequest } from '../../../utils/generated-api-requests/estimates'
import { getEstimateWithEstimateIdRequest as elementsGetEstimateWithEstimateIdRequest } from '../../../utils/generated-api-requests/buildingelements'
import { getEstimateWithEstimateIdRequest as spacesGetEstimateWithEstimateIdRequest } from '../../../utils/generated-api-requests/spaces'
import { getEstimateWithEstimateIdRequest as wopGetEstimateWithEstimateIdRequest } from '../../../utils/generated-api-requests/wop'
import { getEstimateWithEstimateIdRequest as locanGetEstimateWithEstimateIdRequest } from '../../../utils/generated-api-requests/locan'

type HOCProps = {|
  t: Function, // translate function,
  classes: Object, // classes-object created by withstyles function
  history: Object, // withRouter history object
|}

type MappedProps = {|
  moduleCalculations: Object, // Object containing calculations per module from Store
  buildingId: string, // the currently selected buildingId,
  buildingName: string, // name of selected building in project drawer
  selectedAccountId?: string, // id of the selected account
  userId: string, // id of user
  realEstateId: $PropertyType<TVDApplicationStore, 'realEstateId'>,
  realEstateName: $PropertyType<TVDApplicationStore, 'realEstateName'>
|}

type DispatchProps = {|
  dispatchClearCalculations: Function, // dispatched function to set calculations to empty in store
  dispatchIncrementModalCount: Function, // dispatched action to add 1 to the current count of modals in Store
  dispatchDecrementModalCount: Function // dispatched action to deduct 1 from the current count of modals in Store
|}

type ReceivedProps = {|
  app: string, // app's name
  closeModal: Function, // function to close the modal
  onClose: Function, // function triggered when dialog calls it's onClose
|}

type Props = {
  ...HOCProps,
  ...MappedProps,
  ...DispatchProps,
  ...ReceivedProps
}

const styles = (): Object => ({
  icon: {
    width: 24,
    height: 24,
  }
})

export class CalculationManagerModal extends Component<Props> {
  static defaultProps = {
    selectedAccountId: undefined,
  }

  componentDidMount() {
    const {
      app,
      buildingId,
      dispatchIncrementModalCount,
      userId
    } = this.props
    const estimateType = elementTypeByApp(app)
    if (buildingId) {
      getEstimatesRequest({ query: { estimateType, buildingId, permissionsSubject: userId } })
    }
    dispatchIncrementModalCount()
  }

  componentDidUpdate(prevProps: Object) {
    const { app, buildingId, userId } = this.props
    if (buildingId && prevProps.buildingId !== buildingId) {
      const estimateType = elementTypeByApp(app)
      getEstimatesRequest({ query: { estimateType, buildingId, permissionsSubject: userId } })
    }
  }

  componentWillUnmount() {
    const { dispatchClearCalculations } = this.props
    dispatchClearCalculations()
  }

  postCalculation = (values: Object) => {
    const {
      estimateDescription,
      assessmentDate,
      priceLevelChangeP,
      isPrivate
    } = values
    const {
      buildingId,
      app,
      selectedAccountId,
      history,
      realEstateId,
      realEstateName
    } = this.props
    let body = {
      Description: estimateDescription,
      AssessmentDate: assessmentDate,
      estimateType: elementTypeByApp(app),
      PriceLevelChangeP: priceLevelChangeP,
    }

    if (app === LOCAN) {
      body = {
        estimateType: elementTypeByApp(app),
        description: estimateDescription
      }
    }

    postEstimatesRequest({
      body,
      query: {
        parentId: buildingId,
        isPrivate
      }
    }, {}, ({ headers: { location } }: TVDRequestResponse) => {
      const pathName = getPathNameFromURL(location)
      const newId = pathName.split('/').pop()

      const cb = () => {
        if (selectedAccountId) {
          history.push(getEstimateHistoryObj({
            app,
            accountId: selectedAccountId,
            estimateId: newId,
            realEstateName,
            realEstateId
          }))
        } else {
          console.error('Could not navigate to newly created estimate. No selectedAccountId provided')
        }
      }
      switch (app) {
        case SPACES:
          spacesGetEstimateWithEstimateIdRequest({}, cb)
          break
        case ELEMENTS:
          elementsGetEstimateWithEstimateIdRequest({}, cb)
          break
        case WOP:
          wopGetEstimateWithEstimateIdRequest({}, cb)
          break
        case LOCAN: {
          locanGetEstimateWithEstimateIdRequest({}, cb)
          break
        }
        default:
          break
      }
    }, null, { disableRefreshEstimateLock: true })
    this.handleClose()
  }

  handleClose = () => {
    this.props.closeModal()
    this.props.onClose()
    this.props.dispatchDecrementModalCount()
  }

  moduleCalculations(): Array<Object> {
    const { app = '', moduleCalculations } = this.props
    switch (app) {
      case ELEMENTS:
        // TODO: get API to have it returned either lower case or not
        return map((moduleCalculations.buildingelements || moduleCalculations.buildingElements), (calculation: Object) => calculation)
      case SPACES:
        return map(moduleCalculations.space, (calculation: Object) => calculation)
      case WOP:
        return map(moduleCalculations.wop, (calculation: Object) => calculation)
      case LOCAN:
        return map(moduleCalculations.locan, (calculation: Object) => calculation)
      default:
        return []
    }
  }


  render(): React$Element<any> {
    const {
      t, app, buildingId
    } = this.props

    const estimateType = elementTypeByApp(app)

    return (
      <Dialog
        maxWidth={1200}
        fullWidth
        open
        title={t(`apps._${app && app}_`)}
        onClose={this.handleClose}>
        <CreateCalculation
          closeFn={this.handleClose}
          app={app}
          onSave={this.postCalculation}
          calculations={this.moduleCalculations()}
          estimateType={estimateType}
          selectedBuildingId={buildingId} />
      </Dialog>
    )
  }
}

const mapStateToProps = ({ app, calculations: { moduleCalculations }, user: { claims: { userId } } }: TVDReduxStore): MappedProps => {
  const {
    buildingId,
    buildingName,
    selectedAccountId,
    realEstateId,
    realEstateName
  } = app
  return {
    moduleCalculations,
    buildingId,
    buildingName,
    selectedAccountId,
    userId,
    realEstateId,
    realEstateName

  }
}

const mapDispatchToProps = (dispatch: Function): DispatchProps => ({
  dispatchClearCalculations: () => { dispatch(clearCalculations()) },
  dispatchIncrementModalCount: () => { dispatch(incrementModalCount()) },
  dispatchDecrementModalCount: () => { dispatch(decrementModalCount()) }
})

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
  withTranslation('translations')
)(CalculationManagerModal)
