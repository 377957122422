// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.

import React, { Component } from 'react'
import { withStyles } from '@material-ui/core'
import { withTranslation } from 'react-i18next'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { uniq } from 'lodash'
import { TextButton } from 'frontend-components'
import { sortListItemsAlphabetically } from '../../../utils/listUtils'
import FooterButtons from '../../containers/widgets/FooterButtons/FooterButtons'
import { type TextButtonProps } from '../TextButton/TextButton'
import HierarchicalListContainer from '../../containers/HierarchicalListContainer/HierarchicalListContainer'
import { closeModal } from '../../../actions/modals'
import {
  DESCRIPTION,
  OPERATING_PROFILE,
  WEEKS_IN_A_YEAR,
  DAYS_IN_A_WEEK,
  HOURS_IN_A_DAY,
  USEAGE_DEGREE_GOAL,
  TOPIC
} from '../../../constants/attributes'
import DescriptionCell from '../lists/common/DescriptionCell/DescriptionCell'
import {
  getOperatingProfileScheduleWithEstimateIdRequest,
  patchActivityScheduleProcessesWithItemIdRequest,
  patchActivityScheduleActivityGroupsWithItemIdRequest
} from '../../../utils/generated-api-requests/wop'
import DropdownMenu, { type DropdownMenuProps } from '../menus/DropdownMenu/DropdownMenu'
import ListSearchField from '../lists/ListSearchField/ListSearchField'
import { PROCESS, ACTIVITYGROUP } from '../../../constants/contentTypes'
import { postPolling } from '../../../actions/postPolling'
import { clearList, filterListItemsByColumnData, clearListItemsColumnDataFilter } from '../../../actions/list'
import { runGAR } from '../../../utils/GARUtils'
import { refreshUserAccessToken } from '../../../actions/user'

const styles = () => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '65vh',
  },
  footer: {
    width: '100%',
    display: 'flex',
    padding: '25px',
    alignSelf: 'flex-end'
  },
  buttons: {
    display: 'flex',
    flex: '1',
    justifyContent: 'flex-end'
  },
  searchTools: {
    '& > *:nth-child(1)': {
      marginRight: '60px'
    },
    marginLeft: '30px',
    display: 'flex',
    alignItems: 'center',
    marginBottom: '60px',
    marginTop: '25px',
  },
  operatingTime: {
    fontSize: '16px',
    display: 'flex',
    '& > span': {
      maxWidth: '56px',
      minWidth: '56px',
      textAlign: 'left',
      marginRight: '35px'
    },
    '& > span:last-of-type': {
      marginRight: '0px'
    }
  },
  useageDegreeGoal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '16px'
  },
  operatingTimeHeader: {
    '& > *': {
      justifyContent: 'flex-start !important',
    }
  },
  useageDegreeGoalHeader: {
    '& > *': {
      justifyContent: 'center !important',
    },
  },
  useageDegreeGoalRowCell: {
    '& > * > *': {
      justifyContent: 'center !important',
    },
  },
  addButtonWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%'
  },
})

type DispatchProps = {|
  dispatchCloseModal: () => void, // tries to close the modal with the given modalId from ReceivedProps
  dispatchPostPolling: () => void, // start the post polling process
  dispatchClearList: () => void, // clears list from Store with an id which is in this component the OPERATING_PROFILE_LIST
  dispatchFilterListItemsByColumnData: (columnDataValue: string) => void, // filters list items by a column data's value in Store
  dispatchClearListItemsColumnDataFilter: () => void, // clears filters of a list in Store
|}

type ReceivedProps = {|
  operatingProfile: number, // operating profile id used to get profile list
  modalId: string, // id of the modal that the component is rendered in
  listItem: TVDWOPListItem, // listItem that the modal was opened from
  activityStructureId: string, // the store id of the list that the modal was opened from
  onSaveSuccessfulRequestDefinitions: TVDGARConfig, // GAR definitions to GET row that has been updated
|}

type Props = {|
  ...ReceivedProps,
  ...DispatchProps,
  classes: Object, // withStyles classes object
  t: Function, // translate function
|}

type State = {|
  topics: Array<string>, // all unique topics found within the operatingProfile schedule items
  isPatchPending: boolean, // if we are in the midst of patchinging the operating profile
|}

// property name that is not included in columnData but is UI's composition of several other columnData
export const OPERATING_TIME = 'OPERATING_TIME'
// property name that is only to create a column for the add button
export const ADD_BUTTON_COLUMN = 'ADD_BUTTON_COLUMN'
// option in the attribute classification dropdown that allow you the filter to be cleared and show all options
export const LIST_ALL_OPTION_VALUE = 'LIST_ALL_OPTION_VALUE'
// own list store id for this component
export const OPERATING_PROFILE_LIST_STORE_ID = 'OPERATING_PROFILE_LIST_STORE_ID'

export class OperatingProfileSelectModal extends Component<Props, State> {
  state = {
    topics: [],
    isPatchPending: false
  }

  componentDidMount() {
    getOperatingProfileScheduleWithEstimateIdRequest({
      listStoreId: OPERATING_PROFILE_LIST_STORE_ID
    }, (listItems: TVDWOPListItems) => {
      const uniqTopics = uniq(Object.keys(listItems).map((listItemId: string) => listItems[listItemId].columnData[TOPIC]))
      this.setState({ topics: uniqTopics })
    })
  }

  componentWillUnmount = () => {
    const { dispatchClearList } = this.props
    dispatchClearList()
  }

  getFooterButtonItems = (): TextButtonProps[] => {
    const { t, dispatchCloseModal } = this.props
    return [
      {
        id: 'OperatingProfile-modal-cancel',
        onClick: () => { dispatchCloseModal() },
        text: t('widgets._CANCEL_'),
        variant: 'text'
      }
    ]
  }

  getColumns = (): Array<TVDListItemColumn> => {
    const { t } = this.props
    return [
      { propertyName: DESCRIPTION, localizedName: t('widgets._DESCRIPTION_'), dataType: 'string' },
      { propertyName: OPERATING_TIME, localizedName: t('widgets._OPERATING_TIME_'), dataType: 'string' },
      { propertyName: USEAGE_DEGREE_GOAL, localizedName: t('operatingProfile._USEAGE_DEGREE_GOAL_'), dataType: 'string' },
      { propertyName: ADD_BUTTON_COLUMN, localizedName: '', dataType: 'string' }
    ]
  }

  getColumnDataKeyPostfix = (columnDataKey: string): string => {
    const { t } = this.props
    const NO_TRANSLATION_FOUND = 'NO_TRANSLATION_FOUND'
    const postfixTranslation = t([`operatingProfile._${columnDataKey}_POSTFIX_`], NO_TRANSLATION_FOUND)
    if (postfixTranslation === NO_TRANSLATION_FOUND) {
      console.error(`No postfix in operating profile modal found for column data key "${columnDataKey}"`)
      return ''
    }
    return postfixTranslation
  }

  getOperatingTimeCellContent = ({ row }: TVDWrappedCellCallbackParameters): React$Element<'div'> => {
    const { classes } = this.props
    const {
      columnData: {
        [WEEKS_IN_A_YEAR]: WeeksInAYear = '',
        [DAYS_IN_A_WEEK]: DaysInAWeek = '',
        [HOURS_IN_A_DAY]: HoursInADay = '',
      }
    } = row
    return (
      <div className={classes.operatingTime}>
        <span>{`${WeeksInAYear} ${this.getColumnDataKeyPostfix('WEEKS_IN_A_YEAR')}`}</span>
        <span>{`${DaysInAWeek} ${this.getColumnDataKeyPostfix('DAYS_IN_A_WEEK')}`}</span>
        <span>{`${HoursInADay} ${this.getColumnDataKeyPostfix('HOURS_IN_A_DAY')}`}</span>
      </div>)
  }

  getDescriptionCellContent = ({ row }: TVDWrappedCellCallbackParameters): React$Element<typeof DescriptionCell> => {
    const { columnData: { [DESCRIPTION]: description } } = row
    return <DescriptionCell text={description} />
  }

  getUseageDegreeGoalCellContent = ({ row }: TVDWrappedCellCallbackParameters): React$Element<'div'> => {
    const { classes } = this.props
    const { columnData: { [USEAGE_DEGREE_GOAL]: useageDegreeGoal } } = row
    return <div className={classes.useageDegreeGoal}>{useageDegreeGoal}</div>
  }

  getAddButtonCellContent = ({ row }: TVDWrappedCellCallbackParameters) => {
    const {
      t, listItem: { wopItemId, type },
      activityStructureId,
      onSaveSuccessfulRequestDefinitions,
      dispatchCloseModal,
      classes
    } = this.props
    const {
      isPatchPending
    } = this.state
    const props: TextButtonProps = {
      text: t('buttons._SET_'),
      disabled: isPatchPending,
      showSpinner: isPatchPending,
      onClick: () => {
        this.setState({ isPatchPending: true })
        const requestArguments = [
          { path: { itemId: wopItemId }, body: { [OPERATING_PROFILE]: row.id } },
          { listStoreId: activityStructureId },
          () => {
            runGAR({
              ...onSaveSuccessfulRequestDefinitions,
              successCb: () => {
                refreshUserAccessToken(() => {
                  dispatchCloseModal()
                })
              },
              errorCb: () => {
                this.setState({
                  isPatchPending: false
                })
              }
            })
          }
        ]
        switch (type.toUpperCase()) {
          case PROCESS: {
            patchActivityScheduleProcessesWithItemIdRequest(...requestArguments)
            break
          }
          case ACTIVITYGROUP: {
            patchActivityScheduleActivityGroupsWithItemIdRequest(...requestArguments)
            break
          }
          default: {
            console.error(`List item type ${type} is not supported for updating operating profile`)
            break
          }
        }
      }
    }
    return (
      <div className={classes.addButtonWrapper}>
        <TextButton variant='small' {...props} />
      </div>
    )
  }

  getTopicDropdown = () => {
    const { topics } = this.state
    const { t, dispatchFilterListItemsByColumnData, dispatchClearListItemsColumnDataFilter } = this.props
    const items = topics.map((topic: string): MenuItemProps => ({
      localizedName: topic,
      value: topic,
    }))
    items.sort((prevItem: MenuItemProps, nextItem: MenuItemProps) => {
      const prevName = prevItem.localizedName
      const nextName = nextItem.localizedName
      if (prevName < nextName) {
        return -1
      }
      if (prevName > nextName) {
        return 1
      }
      return 0
    })
    const listAllOption = {
      value: LIST_ALL_OPTION_VALUE,
      localizedName: t('dropdowns._ALL_')
    }

    const props: DropdownMenuProps = {
      items: [listAllOption, ...items],
      width: 'L',
      title: t('operatingProfile._ATTRIBUTE_CLASSIFICATION_'),
      defaultValue: LIST_ALL_OPTION_VALUE,
      onChange: (topic: string) => {
        if (topic === LIST_ALL_OPTION_VALUE) {
          dispatchClearListItemsColumnDataFilter()
        } else {
          dispatchFilterListItemsByColumnData(topic)
        }
      }
    }
    return <DropdownMenu {...props} />
  }

  render(): React$Element<any> {
    const { classes } = this.props
    return (
      <div className={classes.root} >
        <div className={classes.searchTools}>
          <div><ListSearchField listId={OPERATING_PROFILE_LIST_STORE_ID} /></div>
          <div>{this.getTopicDropdown()}</div>
        </div>
        <HierarchicalListContainer
          listId={OPERATING_PROFILE_LIST_STORE_ID}
          sortFn={sortListItemsAlphabetically}
          addedColumns={this.getColumns()}
          columnsVisibleOnHover={[ADD_BUTTON_COLUMN]}
          listHeaderCellClassNames={{
            [OPERATING_TIME]: classes.operatingTimeHeader,
            [USEAGE_DEGREE_GOAL]: classes.useageDegreeGoalHeader,
          }}
          initialColumnWidths={{
            [DESCRIPTION]: 310,
            [USEAGE_DEGREE_GOAL]: 130,
            [OPERATING_TIME]: 250
          }}
          wrappedCellContents={{
            [DESCRIPTION]: this.getDescriptionCellContent.bind(this),
            [USEAGE_DEGREE_GOAL]: this.getUseageDegreeGoalCellContent.bind(this),
            [OPERATING_TIME]: this.getOperatingTimeCellContent.bind(this),
            [ADD_BUTTON_COLUMN]: this.getAddButtonCellContent.bind(this)
          }}
          listRowCellClassNames={{
            [USEAGE_DEGREE_GOAL]: classes.useageDegreeGoalRowCell
          }}
          fullHeightColumns={[ADD_BUTTON_COLUMN]} />
        <div className={classes.footer}>
          <div className={classes.buttons}>
            <FooterButtons items={this.getFooterButtonItems()} />
          </div>
        </div>
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch: Function, { modalId }: ReceivedProps): DispatchProps => ({
  dispatchCloseModal: () => { dispatch(closeModal(modalId)) },
  dispatchPostPolling: () => { dispatch(postPolling()) },
  dispatchClearList: () => { dispatch(clearList(OPERATING_PROFILE_LIST_STORE_ID)) },
  dispatchFilterListItemsByColumnData: (columnDataValue: string) => {
    dispatch(filterListItemsByColumnData(OPERATING_PROFILE_LIST_STORE_ID, TOPIC, columnDataValue))
  },
  dispatchClearListItemsColumnDataFilter: () => {
    dispatch(clearListItemsColumnDataFilter(OPERATING_PROFILE_LIST_STORE_ID))
  }
})

export default compose(
  connect(null, mapDispatchToProps),
  withTranslation('translations'),
  withStyles(styles),
)(OperatingProfileSelectModal)
