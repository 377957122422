// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import { Icon } from '@material-ui/core'
import { find, debounce, isEmpty } from 'lodash'

import HamburgerMenu from '../../../menus/HamburgerMenu/HamburgerMenu'
import DropdownMenu from '../../../menus/DropdownMenu/DropdownMenu'
import Tooltip from '../../../Tooltip/Tooltip'

import { SPACE, SPACEGROUP } from '../../../../../constants/contentTypes'
import { combineStyleClassNames } from '../../../../../utils/styleUtils'
import { measureHasEditableProperties } from '../../../../../utils/listUtils'
import {
  getRenovationSpacesMeasureTopicsMeasuresPropertiesWithMeasureIdRequest,
  getRenovationSpaceGroupsMeasureTopicsMeasuresPropertiesWithMeasureIdRequest
} from '../../../../../utils/generated-api-requests/spaces_renovation'

export const styles = ({ palette }: Object): Object => ({
  modifiedIcon: {
    position: 'absolute',
    top: '-10px',
    right: '-10px',
    transform: 'rotate(-45deg)',
    pointerEvents: 'none',
    color: palette.ui02,
    fontSize: '25px',
  },
  wrapper: {
    minHeight: '39px',
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    '&:hover [data-visible_on_hover_id="hoveringMenuCell"]': {
      visibility: 'visible'
    }
  },
  highlightBackground: {
    backgroundColor: palette.support08
  }
})

export const MENU_CELL_CONTENT_TYPE_DROPDOWN: 'MENU_CELL_CONTENT_TYPE_DROPDOWN' = 'MENU_CELL_CONTENT_TYPE_DROPDOWN'
export const MENU_CELL_CONTENT_TYPE_HAMBURGER: 'MENU_CELL_CONTENT_TYPE_HAMBURGER' = 'MENU_CELL_CONTENT_TYPE_HAMBURGER'
export type TVDMenuCellContentType = typeof MENU_CELL_CONTENT_TYPE_DROPDOWN | typeof MENU_CELL_CONTENT_TYPE_HAMBURGER

type ReceivedProps = {|
  defaultValue: string | number, // default value for DropdownMenu that is preselected on render
  items: Array<TVDMenuItem>, // menu items for DropdownMenu component
  showModifiedIcon?: boolean, // boolean to toggle if we show the modified icon in the top right corner of the cell
  highlightBackground?: boolean, // change background color of the cell to predefined bg color
  cellContentType: TVDMenuCellContentType, // allowed content types which determine what child component is rendered
  value: string, // controlled selected value of the dropdown menu
  onChange?: Function, // optional onChange listener for the dropdown menu
  menuTitle: ?React$Element<any>, // should the menuItems have a title on top of them
  onDropdownOpen?: (event: SyntheticEvent<any>) => void, // optional callback for when the dropdown is opened
  renderSkeletons?: boolean, // show skeleton components instead of menu items
  hideArrow?: boolean, // whether to show dropdown arrow icon in the dropdownInput or not
  selectedMeasure?: TVDMeasure, // Currently selected measure
  measureTopicId: string, // id of measurecolumn measure
  row: TVDListItem, // row object that contains the MenuCell
  renderEmpty?: boolean, // if dropdown should render an empty value (despite having a valid data as selected value)
  disabled?: boolean, // if we want the user to see the values but not interact with them in the menu cell
|}

type MappedProps = {|
  patchOperations: TVDPatchOperationParameters //
|}

type Props = {|
  ...ReceivedProps,
  ...MappedProps,
  classes: Object, // from HOC withStyles classes object
|}

type State = {|
  tooltipMessage: string
|}

export class MenuCell extends Component<Props, State> {
  static defaultProps = {
    tooltipText: '',
    highlightBackground: false,
    onChange: () => {},
    showModifiedIcon: false,
    onDropdownOpen: undefined,
    hideArrow: false
  }

  state = {
    tooltipMessage: ''
  }

  debouncedMouseEnter = debounce(() => {
    const { measureTopicId, row, patchOperations } = this.props
    const selectedMeasure = find(this.props.items, (item: Object) => item.value === this.props.value)

    switch (row.type.toUpperCase()) {
      case SPACE: {
        const isColumnModified = row.modifiedColumnData ? Object.keys(row.modifiedColumnData).includes(measureTopicId) : false
        if (patchOperations.RENOVATION_SPACES && isColumnModified) {
          const modifiedColumnData = row.modifiedColumnData || {}
          const dataKey = (key: string) => `columnData/${measureTopicId}/${modifiedColumnData[measureTopicId]}/${key}`
          const measureProperties = selectedMeasure.properties.map((property: TVDPropertiesListItem) => {
            const value = patchOperations.RENOVATION_SPACES[row.id][0].operationParameters[dataKey(property.propertyName)]
            property.value = value || property.defaultValue
            return property
          })
          this.setState({ tooltipMessage: this.parseTooltipMessage(measureProperties, selectedMeasure.localizedName) })
          return
        }

        getRenovationSpacesMeasureTopicsMeasuresPropertiesWithMeasureIdRequest(
          { path: { measureId: selectedMeasure.value, topicId: measureTopicId, id: row.id } },
          {},
          (measureProperties: Array<TVDPropertiesListItem>) => {
            this.setState({ tooltipMessage: this.parseTooltipMessage(measureProperties, selectedMeasure.localizedName) })
          }
        )
        break
      }
      case SPACEGROUP: {
        const isColumnModified = row.modifiedColumnData ? Object.keys(row.modifiedColumnData).includes(measureTopicId) : false
        if (patchOperations.RENOVATION_SPACEGROUPS && isColumnModified) {
          const modifiedColumnData = row.modifiedColumnData || {}
          const dataKey = (key: string) => `columnData/${measureTopicId}/${modifiedColumnData[measureTopicId]}/${key}`
          const measureProperties = selectedMeasure.properties.map((property: TVDPropertiesListItem) => {
            const value = patchOperations.RENOVATION_SPACEGROUPS[row.id][0].operationParameters[dataKey(property.propertyName)]
            property.value = value || property.defaultValue
            return property
          })
          this.setState({ tooltipMessage: this.parseTooltipMessage(measureProperties, selectedMeasure.localizedName) })
          return
        }

        getRenovationSpaceGroupsMeasureTopicsMeasuresPropertiesWithMeasureIdRequest(
          { path: { measureId: selectedMeasure.value, topicId: measureTopicId, id: row.id } },
          {},
          (measureProperties: Array<TVDPropertiesListItem>) => {
            this.setState({ tooltipMessage: this.parseTooltipMessage(measureProperties, selectedMeasure.localizedName) })
          }
        )
        break
      }
      default:
        break
    }
  }, 500)

  parseTooltipMessageProperty = (property: Object): string => {
    if (property.dataType === 'number') return `${property.localizedName} ${property.value}%`
    return property.localizedName
  }

  parseTooltipMessage = (measureProperties: Array<TVDPropertiesListItem>, messageHeadline: string): string => {
    const selectedProperties = measureProperties.filter((property: TVDPropertiesListItem) => property.value)
    const message = // This weird lookin' thing is a line break for markdown. Many alternative options didnt work. Handle with care.
      `${messageHeadline}:  \n` +
      '` `  ' +
      `${selectedProperties.map((property: TVDPropertiesListItem) => this.parseTooltipMessageProperty(property)).join(', ')}`
    return message
  }

  parseDropdownLocalizedName = (localizedName: string) => localizedName.split(' - ')[0]

  getCellContent = (): React$Element<typeof DropdownMenu> | React$Element<typeof HamburgerMenu> | null => {
    const {
      cellContentType,
      onChange,
      value,
      defaultValue,
      items,
      menuTitle,
      onDropdownOpen,
      renderSkeletons,
      hideArrow,
      renderEmpty,
      disabled
    } = this.props
    switch (cellContentType) {
      case MENU_CELL_CONTENT_TYPE_DROPDOWN: {
        return (<DropdownMenu
          disabled={disabled}
          renderSkeletons={renderSkeletons}
          renderEmpty={renderEmpty}
          onDropdownOpen={onDropdownOpen}
          allowReSelection
          width='FULL'
          fullHeight
          minimalist
          noSelectIcon
          value={value}
          defaultValue={defaultValue}
          localizedNameParser={this.parseDropdownLocalizedName}
          items={items}
          onChange={onChange}
          hideArrow={hideArrow}
          preventTooltip />)
      }
      case MENU_CELL_CONTENT_TYPE_HAMBURGER: {
        return !disabled ? (
          <HamburgerMenu
            items={items}
            menuTitle={menuTitle}
            visibleOnHoverId='hoveringMenuCell' />
        ) : <></>
      }
      default: {
        console.error(`No valid content for cell with cell content type of ${cellContentType}`)
        return null
      }
    }
  }

  render(): React$Element<any> {
    const {
      classes,
      showModifiedIcon,
      highlightBackground,
      value,
      selectedMeasure
    } = this.props

    // $FlowFixMe: Flow expects a properties to be an object according to the type TVDMeasure, but in this case needs to be an Array to maintain properties order
    const hasEditableProperties = Boolean(value && selectedMeasure && measureHasEditableProperties(selectedMeasure.properties))

    if (!hasEditableProperties) {
      return (
        <Tooltip text={selectedMeasure && selectedMeasure.localizedName} >
          <div className={combineStyleClassNames(classes.wrapper, highlightBackground && classes.highlightBackground)}>
            { showModifiedIcon && <Icon id='showModifiedIcon' className={classes.modifiedIcon}>arrow_right</Icon> }
            { this.getCellContent() }
          </div>
        </Tooltip>
      )
    }

    const contentProps = isEmpty(this.state.tooltipMessage)
      ? { content: <div /> }
      : { text: this.state.tooltipMessage }

    return (
      <Tooltip onMouseEnter={this.debouncedMouseEnter} {...contentProps}>
        <div className={combineStyleClassNames(classes.wrapper, highlightBackground && classes.highlightBackground)}>
          { showModifiedIcon && <Icon id='showModifiedIcon' className={classes.modifiedIcon}>arrow_right</Icon> }
          { this.getCellContent() }
        </div>
      </Tooltip>
    )
  }
}

const mapStateToProps = ({ patchOperations }: TVDReduxStore): MappedProps => ({
  patchOperations
})

export default connect(mapStateToProps, null)(withStyles(styles)(MenuCell))
