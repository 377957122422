// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { loadMFE, registeredMFEs } from '../../../utils/MFEUtils'
import {
  API_BASE_URL,
  ALLOW_WITH_CREDENTIALS,
  FEATURE_SET
} from '../../../constants/envConstants'
import { openGenericErrorModal } from '../../../utils/modalUtils'
import { dispatch } from '../../../store'
import { openContentWidget } from '../../../actions/widgets'
import { setSpacesEstimateType, setSpacesResultView, setCalculationActive, setCalculationComplete, setActiveEdit } from '../../../actions/app'
import { postPolling } from '../../../actions/postPolling'
import {
  SPACE,
  MULTI_EDIT_SPACE_GROUP_WIDGET,
  MULTI_EDIT_SPACE_WIDGET,
  SPACES
} from '../../../constants/contentTypes'

import { setShowSpaceListPropertiesComponentPreference } from '../../../actions/componentPreferences'

export const spacesListComponentPreferencesId = `${SPACES.toLowerCase()}-list`

type OpenMultiEditWidgetArgs = {|
  isEditingSpaces: boolean, // if user is editing spaces (true) or space groups (false),
  rowId: string, // uuid of the row that was the row which from opening multiedit widget was done
  overwittenSelectedRows: TVDListItems, // list items that will be used instead of Redux Store ones for doing multiediting
|}

const SpacesListMFEContainer = (): React$Element<'div'> => {
  const {
    realEstateId,
    accessToken,
    haahtelaApiUserToken,
    userId,
    languageCode,
    selectedAccountId,
    userPrivileges,
    licenseType,
    realEstateName,
    activeEstimate: activeSpacesEstimate,
    postPollingTS,
    activeEdit,
    activeCalculation,
    spacesListId,
    estimateType,
    resultViewType,
    isEstimateLockedToCurrentUser,
    componentPreferences: componentPreferencesStore
  } = useSelector(({
    activeEstimate,
    app,
    user,
    componentPreferences
  }: TVDReduxStore): {|
    languageCode: $PropertyType<TVDApplicationStore, 'languageCode'>,
    selectedAccountId: $PropertyType<TVDApplicationStore, 'selectedAccountId'>,
    accessToken: $PropertyType<TVDUserAuthorization, 'accessToken'>,
    haahtelaApiUserToken: $PropertyType<TVDUserClaims, 'haahtelaApiUserToken'>,
    userId: $PropertyType<TVDUserClaims, 'userId'>,
    userPrivileges: $PropertyType<TVDUserStore, 'userPrivileges'>,
    licenseType: $PropertyType<TVDApplicationStore, 'licenseType'>,
    realEstateName: $PropertyType<TVDApplicationStore, 'realEstateName'>,
    activeEstimate: TVDActiveEstimateStore,
    realEstateId: $PropertyType<TVDApplicationStore, 'realEstateId'>,
    postPollingTS: $PropertyType<TVDApplicationStore, 'postPollingTS'>,
    activeEdit: $PropertyType<TVDApplicationStore, 'activeEdit'>,
    activeCalculation: $PropertyType<TVDApplicationStore, 'activeCalculation'>,
    spacesListId: $PropertyType<TVDApplicationStore, 'spacesListId'>,
    estimateType: $PropertyType<TVDApplicationStore, 'spacesEstimateType'>,
    resultViewType: $PropertyType<TVDApplicationStore, 'spacesResultView'>,
    isEstimateLockedToCurrentUser: $PropertyType<TVDApplicationStore, 'isEstimateLockedToCurrentUser'>,
    componentPreferences: ComponentPreferencesStore,
  |} => ({
    languageCode: app.languageCode,
    selectedAccountId: app.selectedAccountId,
    accessToken: user.authorization.accessToken,
    haahtelaApiUserToken: user.claims.haahtelaApiUserToken,
    userId: user.claims.userId,
    realEstateName: app.realEstateName,
    userPrivileges: user.userPrivileges,
    licenseType: app.licenseType,
    activeEstimate,
    realEstateId: app.realEstateId,
    postPollingTS: app.postPollingTS,
    activeEdit: app.activeEdit,
    activeCalculation: app.activeCalculation,
    spacesListId: app.spacesListId,
    estimateType: app.spacesEstimateType,
    resultViewType: app.spacesResultView,
    isEstimateLockedToCurrentUser: app.isEstimateLockedToCurrentUser,
    componentPreferences
  }))

  const updatePostPollingTS = (cb?: () => void) => {
    dispatch(postPolling(undefined, cb))
  }

  const dispatchSetCalculationActive = () => {
    dispatch(setCalculationActive())
  }

  const dispatchSetCalculationComplete = () => {
    dispatch(setCalculationComplete())
  }

  const spacesListComponentPreferences = componentPreferencesStore[spacesListComponentPreferencesId]?.preferences

  const dispatchSetShowSpaceListPropertiesComponentPreference = (showSpaceProperties: boolean, showSpaceGroupProperties: boolean) => {
    dispatch(setShowSpaceListPropertiesComponentPreference(spacesListComponentPreferencesId, showSpaceProperties, showSpaceGroupProperties))
  }

  const dispatchSetActiveEdit = (active: boolean) => {
    dispatch(setActiveEdit(active))
  }

  const registeredSpacesAPIModule = registeredMFEs['spaces-api-module']

  useEffect(() => {
    const dispatchEstimateType = (spacesEstimateType: string) => {
      dispatch(setSpacesEstimateType(spacesEstimateType))
    }
    const dispatchResultViewType = (newResultViewType: string) => {
      dispatch(setSpacesResultView(newResultViewType))
    }
    const descriptionClick = (row: any): void => {
      dispatch(openContentWidget({
        widgetId: row.id,
        widgetType: row.scheduleItemType.toUpperCase(),
        widgetTitle: row.description,
        widgetResultBarFormatOptions: { disableRounding: true },
        widgetPreferXYComponentPreferences: true,
        widgetPreferSizeComponentPreferences: true,
        contentProps: {
          spaceId: row.id,
          propertiesStoreId: `${row.id}-properties`,
          ...(row.scheduleItemType.toUpperCase() === SPACE ? {
            equipmentsListStoreId: `${row.id}-equipments-list`,
            surfacesListStoreId: `${row.id}-surfaces-list`
          } : {}),
        }
      }))
    }

    const openMultiEditWidget = (openMultiEditWidgetArgs: OpenMultiEditWidgetArgs): void => {
      const {
        isEditingSpaces,
        rowId,
        overwittenSelectedRows
      } = openMultiEditWidgetArgs
      const widgetType = isEditingSpaces ? MULTI_EDIT_SPACE_WIDGET : MULTI_EDIT_SPACE_GROUP_WIDGET
      dispatch(openContentWidget({
        widgetId: widgetType,
        widgetType,
        contentProps: {
          resourceId: rowId,
          listStoreId: spacesListId,
          propertiesStoreId: spacesListId,
          overwittenSelectedRows
        }
      }))
    }

    if (
      languageCode &&
      accessToken &&
      haahtelaApiUserToken &&
      userId &&
      selectedAccountId &&
      userPrivileges &&
      activeSpacesEstimate &&
      realEstateId &&
      estimateType &&
      resultViewType
    ) {
      const render = (module: TVDMFEFunctionality): void => {
        const moduleRun = module.run || null
        if (moduleRun) {
          moduleRun(
            'renderSpacesList',
            [
              'spaces-list',
              {
                APIBaseURL: API_BASE_URL,
                languageCode,
                accessToken,
                haahtelaApiUserToken,
                userId,
                selectedAccountId,
                openGenericErrorModal,
                ALLOW_WITH_CREDENTIALS,
                realEstateId,
                activeEstimate: activeSpacesEstimate,
                descriptionClick,
                postPollingTS,
                updatePostPollingTS,
                activeEdit,
                activeCalculation,
                dispatchEstimateType,
                dispatchResultViewType,
                openMultiEditWidget,
                estimateType,
                resultViewType,
                isEstimateLockedToCurrentUser,
                setCalculationActive: dispatchSetCalculationActive,
                setCalculationComplete: dispatchSetCalculationComplete,
                spacesListComponentPreferences,
                dispatchSetShowSpaceListPropertiesComponentPreference,
                dispatchSetActiveEdit,
                FEATURE_SET
              }
            ]
          )
        }
      }
      if (registeredSpacesAPIModule) {
        render(registeredSpacesAPIModule)
      } else {
        loadMFE('spaces', 'spaces-api-module', (module: TVDMFEFunctionality): void => {
          render(module)
        })
      }
    }
  }, [
    spacesListId,
    languageCode,
    accessToken,
    haahtelaApiUserToken,
    userId,
    selectedAccountId,
    userPrivileges,
    activeSpacesEstimate,
    licenseType,
    realEstateName,
    realEstateId,
    postPollingTS,
    activeEdit,
    activeCalculation,
    registeredSpacesAPIModule,
    estimateType,
    resultViewType,
    isEstimateLockedToCurrentUser,
    spacesListComponentPreferences
  ])
  return (
    <div id='spaces-list' style={{ height: '100%' }} />
  )
}

export default SpacesListMFEContainer

