// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.
import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { map } from 'lodash'

import CheckboxRow from '../../../../common/CheckboxRow/CheckboxRow'

const styles = {
  createOtherSpacesList: {
    display: 'flex',
    flexDirection: 'column',
  }
}

type Props = {|
  classes: Object, // withStyles classes object
  checkboxRows: Array<TVDCheckboxRowProps>, // Array of CommonFunction row
|}

export class CreateOtherSpacesList extends Component<Props> {
  render(): React$Element<any> {
    const { classes } = this.props
    return (
      <div className={classes.createOtherSpacesList}>
        {
          map(this.props.checkboxRows, (row: Object) => <CheckboxRow selectAllChild disabled key={row.description} {...row} />)
        }
      </div>
    )
  }
}

export default withStyles(styles)(CreateOtherSpacesList)
