// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.

import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core'
import FooterButtons from '../../containers/widgets/FooterButtons/FooterButtons'
import { MODAL_TYPE_UNFREEZE_ESTIMATE } from '../../../constants/modalConstants'
import {
  getEstimatesWithEstimateIdRequest,
  patchEstimatesWithEstimateIdRequest
} from '../../../utils/generated-api-requests/estimates'
import { closeModal } from '../../../actions/modals'
import { postPolling } from '../../../actions/postPolling'
import { setIndexNumberOnAssessmentDate, setIsEstimateFrozen } from '../../../actions/app'
import theme from '../../../styles/theme'

const useStyles = makeStyles(({ palette }: TVDTheme) => ({
  wrapper: {
    maxWidth: '550px',
    color: palette.dark80,
    ...theme.typography.classes.bodyBig,
  },
  top: {
    marginBottom: '10px',
    marginTop: '30px'
  },
  buttonsWrapper: {
    marginTop: '42px',
    justifyContent: 'flex-end',
    display: 'flex'
  }
}))

const UnfreezeEstimateModal = (): React$Element<'div'> => {
  const classes = useStyles()
  const [isLoading, setIsLoading] = useState()
  const id = MODAL_TYPE_UNFREEZE_ESTIMATE
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const buttons = [
    {
      id: `${id}-Save`,
      disabled: isLoading,
      showSpinner: isLoading,
      onClick: () => {
        setIsLoading(true)
        patchEstimatesWithEstimateIdRequest(
          {
            body: {
              frozen: false
            }
          },
          {},
          () => {
            dispatch(closeModal(id))
            dispatch(postPolling())
            dispatch(setIsEstimateFrozen(false))
            getEstimatesWithEstimateIdRequest(
              {},
              (estimate: TVDCalculation) => {
                dispatch(setIndexNumberOnAssessmentDate(estimate.indexNumberOnAssessmentDate))
              }
            )
          },
          () => {
            setIsLoading(false)
          }
        )
      },
      text: t('buttons._OPEN_AND_UPDATE_')
    },
    {
      id: `${id}-Cancel`,
      onClick: () => { dispatch(closeModal(id)) },
      text: t('buttons._CANCEL_'),
      variant: 'text'
    }
  ]

  return (
    <div className={classes.wrapper}>
      <div className={classes.top}>{t('UnfreezeEstimateModal._ARE_YOU_SURE_')}</div>
      <div>{t('UnfreezeEstimateModal._BODY_')}</div>
      <div className={classes.buttonsWrapper}><FooterButtons items={buttons} /></div>
    </div>
  )
}

export default UnfreezeEstimateModal
