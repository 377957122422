// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.

import React, { useState, useEffect } from 'react'
import { useSelector, useStore } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import Grid from '@material-ui/core/Grid/Grid'
import { useQuery, useMutation, useQueryClient } from 'react-query'
import { TextButton } from 'frontend-components'

import Modal from '../../../components/common/Modal/Modal'
import Message from '../../../components/common/Messages/Message'
import DropdownMenu from '../../../components/common/menus/DropdownMenu/DropdownMenu'

import { getEstimatesByBuildingId } from '../../common/queries/estimatesQueries'
import {
  putPrimarySpacesEstimate,
  putPrimaryLocanEstimate,
  putPrimaryWopEstimate,
  putPrimaryBuildingElementsEstimate,
  putPrimaryProfitabilityEstimate,
  putPrimaryProcurementElementsEstimate,
  putPrimaryRenovationProgramEstimate,
  putPrimaryUsageMaintenanceEstimate
} from '../../common/queries/realEstateMutations'
import {
  SPACES,
  LOCAN,
  ELEMENTS,
  WOP,
  PROFITABILITY,
  PROCUREMENT_ELEMENTS,
  RENOVATION_PROGRAM,
  USAGE_AND_MAINTENANCE
} from '../../../constants/moduleConstants'
import { setPrimaryEstimateForModule } from '../../../actions/app'
import { apps } from '../AppBar/AppBar'
// $FlowFixMe
import { ReactComponent as AlertInfoOutlinedIcon } from '../../../../node_modules/frontend-assets/static/assets/images/icons/Alert Info outlined.svg'

const useStyles = makeStyles(({ palette }: TVDTheme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    height: '450px',
    width: '600px'
  },
  content: {
    display: 'flex',
    flex: 2,
  },
  buttonMargin: {
    margin: '8px 0'
  },
  messagesContentWrapper: {
    display: 'flex',
    flexWrap: 'nowrap'
  },
  message: {
    color: palette.primary120
  },
  dropdownWrapper: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    margin: '16px 0'
  },
  dropdownMenu: {
    margin: 'auto',
    marginBottom: 16
  }
}))

type TVDEstimateNameAndValue = {|
  localizedName: $PropertyType<TVDCalculation, 'description'>, // TVD estimate name
  value: $PropertyType<TVDCalculation, 'id'> // TVD estimate id
|}

type Props = {|
  onClose: () => void, // function that is executed when Modal is attempted to be closed
  buildingId: string, // building id object for fetchng spaces estimates by building id
  siteId: string // id of the site
|}

const PrimarySelectionModal = (props: Props): React$Element<typeof Modal> => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { primaryEstimates, moduleIds }: TVDApplicationStore = useSelector(({ app }: TVDReduxStore): TVDApplicationStore => app)
  const { dispatch } = useStore()
  const [primaryEstimatesEditableStatus, setPrimaryEstimateEditableStatus] = useState<{[moduleName: string]: boolean}>({})

  const [primaryEstimateIds, setPrimaryEstimateIds] = useState({ ...primaryEstimates })

  useEffect(() => {
    // Changed this to not include estimate lock checks
    // The logic for checking primary estimate being editable is left as there most likely will come checks
    const editableStatus = Object.keys(primaryEstimates).reduce(
      (
        prev: { [moduleName: string]: boolean },
        moduleName: string
      ): { [moduleName: string]: boolean } => (
        {
          ...prev,
          [moduleName]: true
        }),
      {}
    )

    setPrimaryEstimateEditableStatus(editableStatus)
  }, [primaryEstimates, setPrimaryEstimateEditableStatus])

  const {
    data: spacesEstimatesData
  } = useQuery(
    'estimatesGetSpaces',
    () => getEstimatesByBuildingId('space', props.buildingId),
    { enabled: !!props.buildingId }
  )

  const {
    data: buildingElementsEstimatesData
  } = useQuery(
    'estimatesGetBuildingElements',
    () => getEstimatesByBuildingId('buildingElements', props.buildingId),
    { enabled: !!props.buildingId }
  )

  const {
    data: wopEstimatesData
  } = useQuery(
    'estimatesGetWop',
    () => getEstimatesByBuildingId('wop', props.buildingId),
    { enabled: !!props.buildingId }
  )


  const {
    data: profitabilityEstimatesData
  } = useQuery(
    'estimatesGetProf',
    () => getEstimatesByBuildingId('profitability', props.buildingId),
    { enabled: !!props.buildingId }
  )

  const {
    data: procurementElementsEstimatesData
  } = useQuery(
    'estimatesGetProc',
    () => getEstimatesByBuildingId('procurementElements', props.buildingId),
    { enabled: !!props.buildingId }
  )
  const {
    data: usageMaintenanceEstimatesData
  } = useQuery(
    'estimatesGetUsageMaintenance',
    () => getEstimatesByBuildingId('usageMaintenance', props.buildingId),
    { enabled: !!props.buildingId }
  )

  const {
    data: renovationProgramEstimatesData
  } = useQuery(
    'estimatesGetReno',
    () => getEstimatesByBuildingId('renovationProgram', props.buildingId),
    { enabled: !!props.buildingId }
  )

  const {
    data: locationEstimatesData
  } = useQuery(
    'estimatesGetLocation',
    () => getEstimatesByBuildingId('locan', props.siteId),
    { enabled: !!props.siteId }
  )

  const generateItems = (dataSet?: $PropertyType<TVDRequestResponse, 'data'>): TVDEstimateNameAndValue[] => {
    const estimates = dataSet?._embedded.estimates || []
    const dropDownItems = estimates?.map((estimate: TVDCalculation): TVDEstimateNameAndValue =>
      ({ localizedName: estimate.description, value: estimate.id }))
    return dropDownItems
  }

  const getEstimatesByModule = (moduleName: string): Array<TVDEstimateNameAndValue> | null => {
    switch (moduleName) {
      case SPACES:
        return spacesEstimatesData
      case LOCAN:
        return locationEstimatesData
      case WOP:
        return wopEstimatesData
      case ELEMENTS:
        return buildingElementsEstimatesData
      case PROFITABILITY:
        return profitabilityEstimatesData
      case PROCUREMENT_ELEMENTS:
        return procurementElementsEstimatesData
      case RENOVATION_PROGRAM:
        return renovationProgramEstimatesData
      case USAGE_AND_MAINTENANCE:
        return usageMaintenanceEstimatesData
      default:
        console.error(`Could not get estimates for module ${moduleName}`)
        return null
    }
  }

  const queryClient = useQueryClient()

  const primarySpacesEstimateMutation = useMutation('buildingGet', () => putPrimarySpacesEstimate(props.buildingId, primaryEstimateIds[SPACES]), {
    onSuccess: () => {
      // refetch buildingGet when primary estimates are modified
      dispatch(setPrimaryEstimateForModule(SPACES, primaryEstimateIds[SPACES]))
      queryClient.invalidateQueries('buildingGet')
    }
  })

  const primaryLocanEstimateMutation = useMutation('siteGet', () => putPrimaryLocanEstimate(props.siteId, primaryEstimateIds[LOCAN]), {
    onSuccess: () => {
      // refetch siteGet when primary estimates are modified
      queryClient.invalidateQueries('siteGet')
      dispatch(setPrimaryEstimateForModule(LOCAN, primaryEstimateIds[LOCAN]))
    }
  })

  const primaryWopEstimateMutation = useMutation('buildingGet', () => putPrimaryWopEstimate(props.buildingId, primaryEstimateIds[WOP]), {
    onSuccess: () => {
      // refetch buildingGet when primary estimates are modified
      queryClient.invalidateQueries('buildingGet')
      dispatch(setPrimaryEstimateForModule(WOP, primaryEstimateIds[WOP]))
    }
  })

  const primaryProfitabilityEstimateMutation = useMutation(
    'buildingGet',
    () => putPrimaryProfitabilityEstimate(props.buildingId, primaryEstimateIds[PROFITABILITY]), {
      onSuccess: () => {
      // refetch buildingGet when primary estimates are modified
        queryClient.invalidateQueries('buildingGet')
        dispatch(setPrimaryEstimateForModule(PROFITABILITY, primaryEstimateIds[PROFITABILITY]))
      }
    }
  )

  const primaryBuildingElementsEstimateMutation = useMutation(
    'buildingGet',
    () => putPrimaryBuildingElementsEstimate(props.buildingId, primaryEstimateIds[ELEMENTS]), {
      onSuccess: () => {
      // refetch buildingGet when primary estimates are modified
        queryClient.invalidateQueries('buildingGet')
        dispatch(setPrimaryEstimateForModule(ELEMENTS, primaryEstimateIds[ELEMENTS]))
        queryClient.invalidateQueries('')
      }
    }
  )

  const primaryProcurementElementsEstimateMutation = useMutation(
    'buildingGet',
    () => putPrimaryProcurementElementsEstimate(props.buildingId, primaryEstimateIds[PROCUREMENT_ELEMENTS]), {
      onSuccess: () => {
      // refetch buildingGet when primary estimates are modified
        queryClient.invalidateQueries('buildingGet')
        dispatch(setPrimaryEstimateForModule(PROCUREMENT_ELEMENTS, primaryEstimateIds[PROCUREMENT_ELEMENTS]))
        queryClient.invalidateQueries('')
      }
    }
  )

  const primaryRenovationProgramEstimateMutation = useMutation(
    'buildingGet',
    () => putPrimaryRenovationProgramEstimate(props.buildingId, primaryEstimateIds[RENOVATION_PROGRAM]), {
      onSuccess: () => {
        dispatch(setPrimaryEstimateForModule(RENOVATION_PROGRAM, primaryEstimateIds[RENOVATION_PROGRAM]))
      }
    }
  )

  const primaryUsageMaintenanceEstimateMutation = useMutation(
    'buildingGet',
    () => putPrimaryUsageMaintenanceEstimate(props.buildingId, primaryEstimateIds[USAGE_AND_MAINTENANCE]), {
      onSuccess: () => {
        dispatch(setPrimaryEstimateForModule(USAGE_AND_MAINTENANCE, primaryEstimateIds[USAGE_AND_MAINTENANCE]))
      }
    }
  )


  const getChangedEstimateIds = (): Object =>
    Object.keys(primaryEstimateIds).reduce((prev: Object, moduleName: string): Object => {
      if (primaryEstimateIds[moduleName] !== primaryEstimates[moduleName]) {
        return {
          ...prev,
          [moduleName]: primaryEstimateIds[moduleName]
        }
      }
      return prev
    }, {})

  const handleClose = () => {
    props.onClose()
  }

  const handleSave = () => {
    const changedEstimateIds = getChangedEstimateIds()
    Promise.all(Object.keys(changedEstimateIds).map((moduleName: string): Promise<any> | null => {
      switch (moduleName) {
        case LOCAN: { return primaryLocanEstimateMutation.mutateAsync() }
        case SPACES: { return primarySpacesEstimateMutation.mutateAsync() }
        case WOP: { return primaryWopEstimateMutation.mutateAsync() }
        case ELEMENTS: { return primaryBuildingElementsEstimateMutation.mutateAsync() }
        case PROFITABILITY: { return primaryProfitabilityEstimateMutation.mutateAsync() }
        case PROCUREMENT_ELEMENTS: { return primaryProcurementElementsEstimateMutation.mutateAsync() }
        case RENOVATION_PROGRAM: { return primaryRenovationProgramEstimateMutation.mutateAsync() }
        case USAGE_AND_MAINTENANCE: { return primaryUsageMaintenanceEstimateMutation.mutateAsync() }
        default: {
          console.error(`could not set primary estimate for module: ${moduleName}`)
          return null
        }
      }
    })).then(() => {
      queryClient.invalidateQueries('realEstateGet')
      queryClient.invalidateQueries('buildingGet')
      queryClient.invalidateQueries('keyFiguresGet')
      queryClient.invalidateQueries('siteGet')
      queryClient.invalidateQueries('primaryLocanLocationEstimateDataGet')
      queryClient.invalidateQueries('primaryProfitabilityEstimateGet')
      queryClient.invalidateQueries('primaryProcurementElementsEstimateGet')
      queryClient.invalidateQueries('primaryRenovationEstimateGet')
      queryClient.invalidateQueries('primaryUsageMaintenanceGet')
      props.onClose()
    })
  }

  const getModalContent = (): React$Element<any> => (
    <div>
      <div>
        <Message
          snackbar
          type='info_no_backgroundcolor'
          icon={<AlertInfoOutlinedIcon />}
          customContent={t('summaryView._INFO_ALERT_')} />
      </div>
      <div className={classes.dropdownWrapper}>
        {/* only rendering modules that are part of the current license */}
        {apps.map((app: Object): React$Element<'div'> | null => (
            moduleIds.includes(app.moduleId) ? (
              <div className={classes.dropdownMenu}>
                <DropdownMenu
                  testId='dropdown-menu'
                  disabled={!primaryEstimatesEditableStatus[app.name]}
                  items={generateItems(getEstimatesByModule(app.name))}
                  width='XL'
                  title={t(app.label)}
                  defaultValue={primaryEstimateIds[app.name]}
                  onChange={(value: any) => { setPrimaryEstimateIds({ ...primaryEstimateIds, [app.name]: value }) }} />
              </div>
            ) : null
        ))}
      </div>
    </div>
  )

  return (
    <div className={classes.wrapper}>
      <div className={classes.content}>
        <Grid
          alignItems='flex-start'
          direction='column'
          container
          spacing={2}>
          {getModalContent()}
        </Grid>
      </div>
      <Grid
        container
        spacing={2}
        justify='flex-end'
        direction='row'
        className={classes.buttonMargin}>
        <Grid item>
          <TextButton
            text={t('stepper._SAVE_')}
            disabled={Object.keys(getChangedEstimateIds()).length === 0}
            id='btn-save'
            variant='contained'
            onClick={handleSave} />
        </Grid>
        <Grid item>
          <TextButton
            text={t('buttons._CANCEL_')}
            variant='text'
            id='cancel'
            onClick={handleClose} />
        </Grid>
      </Grid>
    </div>

  )
}

export default PrimarySelectionModal

