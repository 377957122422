// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.

import React, { Component } from 'react'
import { find } from 'lodash'

import MenuItems from '../components/MenuItems/MenuItems'
import DropdownMenuCore, { type DropdownMenuWidths } from '../components/DropdownMenuCore/DropdownMenuCore'
import DropdownState from '../components/DropdownState/DropdownState'
import type { AlignInputText } from '../components/DropdownInput/DropdownInput'

export type DropdownMenuProps = {|
  items: Array<TVDMenuItem>, // items for the menu
  onChange: (selection: any) => void, // called with an event upon menu selection
  title?: string, // text displayed in the input element if the menu is not disabled
  id?: string, // for testing
  disabled?: boolean, // disables the menu
  disabledTitle?: string, // if the menu is disabled, show this label instead
  defaultValue?: string | number, // the value for an item that starts as the selected one
  width?: DropdownMenuWidths, // select item width
  required?: boolean, // if the menu is a required menu
  error?: boolean, // whether the component is in an error state
  minimalist?: boolean, // whether to use a minimalist version of the dropdownInput, mostly for datatables
  helperText?: string, // displayed under the menu
  placeholder?: string, // text for a placeholder selection
  fullHeight?: boolean, // set height for DropdownMenuCore to 100%
  fullWidth?: boolean, // optional to toggle 100% width of the form control. Defaults to true
  noSelectIcon?: boolean, // don't display select icon caret and style to fit specs without the icon
  allowReSelection?: boolean, // re-selecting selected option allowed
  alignInputText?: AlignInputText, // optional setting to align input text to left or right
  localizedNameParser?: Function, // runs localizedValue through this parser function
  value?: string | number, // optional value that makes the component controlled
  onDropdownOpen?: (event: SyntheticEvent<any>) => void, // optional callback for when the dropdown is opened
  renderSkeletons?: boolean, // show skeleton components instead of menu items
  hideArrow?: boolean, // whether to show dropdown arrow icon in the dropdownInput or not
  preventTooltip?: boolean, // should the tooltip be prevented (if example handled higher in the component tree)
  renderEmpty?: boolean, // if dropdown should render an empty value (despite having a valid data as selected value)
|}

type Props = {
  ...DropdownMenuProps,
}

export class DropdownMenu extends Component<Props> {
  static defaultProps = {
    onChange: () => {},
    title: '',
    disabled: false,
    width: 'M',
    disabledTitle: '',
    id: '',
    error: false,
    minimalist: false,
    helperText: '',
    required: false,
    placeholder: '',
    items: [],
    fullHeight: false,
    noSelectIcon: false,
    alignInputText: '',
    onDropdownOpen: undefined,
    renderSkeletons: false,
    fullWidth: true,
    hideArrow: false,
    preventTooltip: false
  }

  /*
  * Safeguards against defaultValues that do not exist in the current items. This would
  * result in MUI console errors of "out-of-range values".
  */
  isForceSelectValid(): boolean {
    const { defaultValue, items } = this.props
    return find(items, ['value', defaultValue])
  }

  render(): React$Element<any> {
    const {
      onChange,
      items,
      disabled,
      defaultValue,
      error,
      title,
      disabledTitle,
      width,
      id,
      minimalist,
      required,
      helperText,
      placeholder = '',
      fullHeight,
      fullWidth,
      noSelectIcon,
      allowReSelection,
      alignInputText,
      localizedNameParser,
      value,
      onDropdownOpen,
      renderSkeletons,
      hideArrow,
      preventTooltip,
      renderEmpty
    } = this.props
    const forcedSelection = this.isForceSelectValid() ? defaultValue : ''
    return (
      <DropdownState
        value={value}
        allowReSelection={allowReSelection}
        onChange={onChange}
        forceSelect={forcedSelection}
        placeholder={Boolean(placeholder)}>
        {({ selected, handleSelect }: Object) => (
          <MenuItems
            renderSkeletons={renderSkeletons}
            selected={selected}
            placeholder={placeholder}
            items={items}>
            {({ menuItems }: Object) => (
              <DropdownMenuCore
                preventTooltip={preventTooltip}
                onDropdownOpen={onDropdownOpen}
                alignInputText={alignInputText}
                items={items}
                noSelectIcon={noSelectIcon}
                fullHeight={fullHeight}
                fullWidth={fullWidth}
                helperText={helperText}
                required={required}
                minimalist={minimalist}
                id={id}
                width={width}
                error={error}
                onChange={handleSelect}
                title={title}
                selected={selected}
                disabled={disabled || !items || items.length === 0}
                disabledTitle={disabledTitle}
                localizedNameParser={localizedNameParser}
                renderEmpty={renderEmpty}
                hideArrow={hideArrow}>
                { menuItems }
              </DropdownMenuCore>
            )}
          </MenuItems>
        )}
      </DropdownState>
    )
  }
}

export default DropdownMenu
