// @flow
// Copyright © 2010–2023 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.
import React, { Component, type Node } from 'react'
import { omit } from 'lodash'

// Widget Contents
import CreateWidget from '../../containers/widgets/CreateWidget/CreateWidgetContainer'
import CreateActivity from '../../containers/widgets/CreateActivity/CreateActivityContainer'
import CreateOtherSpacesWidget from '../../containers/widgets/CreateOtherSpaces/CreateOtherSpacesContainer'
import DesignModelWidget from '../../containers/widgets/DesignModelWidget/DesignModelContainer'
import Element from '../../containers/widgets//Element/Element'
import EquipmentRegistry from '../../containers/widgets//EquipmentRegistry/EquipmentRegistry'
import Space from '../../containers/widgets/Space/Space'
import SpacesListContainer from '../../containers/list/SpacesList/SpacesListContainer'
import Assembly from '../../containers/widgets/Assembly/Assembly'
import SpacesAssembly from '../../containers/widgets/SpacesAssembly/SpacesAssembly'
import BuildingElementsTask from '../../containers/widgets/BuildingElementsTask/BuildingElementsTask'
import BuildingElementsSchedule from '../../containers/widgets/BuildingElementsSchedule/BuildingElementsSchedule'
import CreateFromPricing from '../../containers/widgets/CreateFromPricing/CreateFromPricing'
import ImportFromCalculation from '../../containers/widgets/ImportFromCalculation/ImportFromCalculation'
import CalculationProperties from '../../containers/widgets/CalculationProperties/CalculationProperties'
import StoryContainer from '../../containers/widgets/Story/StoryContainer'
import CommonFunctions from '../../containers/widgets/CommonFunctions/CommonFunctions'
import Renovation from '../../containers/widgets/Renovation/Renovation'
import ImportFromWopCalculation from '../../containers/widgets/ImportFromWopCalculation/ImportFromWopCalculation'
import WopSpaceSchedule from '../../containers/widgets/WopSpaceSchedule/WopSpaceSchedule'
import ProcessesAndActivities from '../../containers/widgets/ProcessesAndActivities/ProcessesAndActivities'
import Grouping from '../../containers/widgets/Grouping/Grouping'
import ActivityRegistry from '../../containers/widgets/ActivityRegistry/ActivityRegistry'
import MultiEditContainer from '../../containers/MultiEditContainer/MultiEditContainer'
// Modal Contents
import CalculationInformationContainer from '../../containers/CalculationInformationContainer/CalculationInformationContainer'
import AttributesEdit from '../AttributesEdit/AttributesEdit'
import EquipmentModalContainer from '../../containers/EquipmentModalContainer/EquipmentModalContainer'
import SurfaceModalContainer from '../../containers/SurfaceModalContainer/SurfaceModalContainer'
import SettingsContainer from '../../containers/SettingsContainer/SettingsContainer'
import Pricing from '../../containers/widgets/Pricing/Pricing'
import SingleInputModal from '../SingleInputModal/SingleInputModal'
import DeleteBuildingModal from '../DeleteBuildingModal/DeleteBuildingModal'
import DropdownModal from '../DropdownModal/DropdownModal'
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal'
import PropertiesModal from '../PropertiesModal/PropertiesModal'
import RenovationProfileModal from '../RenovationProfileModal/RenovationProfileModal'
import RenovationCopyProfile from '../../containers/widgets/Renovation/tabContents/RenovationCopyProfile'
import WOPGroupingMoveModalContainer from '../../containers/WOPGroupingMoveModalContainer/WOPGroupingMoveModalContainer'
import WOPGroupingRenameModalContainer from '../../containers/WOPGroupingRenameModalContainer/WOPGroupingRenameModalContainer'
import PropertyEditor from '../PropertyEditor/PropertyEditor'
import AccountSelectionModal from '../AccountSelectionModal/AccountSelectionModal'
import SubSystemInfoModal from '../SubSystemInfoModal/SubSystemInfoModal'
import UnitEditingModal from '../UnitEditingModal/UnitEditingModal'
import CreateReportModal from '../CreateReportModal/CreateReportModal'
import UserModalContainer from '../../containers/UserModalContainer/UserModalContainer'
import OperatingProfileSelectModal from '../OperatingProfileSelectModal/OperatingProfileSelectModal'
import MeasurePropertiesContainer from '../../containers/MeasurePropertiesContainer/MeasurePropertiesContainer'
import AddAccessRightModalContainer from '../../containers/AddAccessRightModalContainer/AddAccessRightModalContainer'
import ConfirmationModalPadded from '../ConfirmationModalPadded/ConfirmationModalPadded'
import AddUserModalContainer from '../../containers/AddUserModalContainer/AddUserModalContainer'
import AddUserToGroup from '../../containers/AccessUsersTab/components/AddUserToGroup'
import RenameProjectModal from '../../../ProjectManagement/features/RenameProjectModal/RenameProjectModal'
import BuildingSettingsDialog from '../../../ProjectManagement/features/BuildingSettingsDialog/BuildingSettingsDialog'
// Types
import * as types from '../../../constants/contentTypes'
import * as modalTypes from '../../../constants/modalConstants'
import {
  CIRCULATION,
  FACILITY_SERVICES,
  TECHNICAL_BUILDING_SERVICES,
  PROTECTED_CIRCULATION
} from '../../../constants/registryConstants'
import { runGAR } from '../../../utils/GARUtils'
import { dispatch } from '../../../store'
import { closeModal } from '../../../actions/modals'
import ExternalGeometryToggleModalContainer from '../../containers/ExternalGeomteryToggleModalContainer/ExternalGeometryToggleModalContainer'
import AccessTokenModalContainer from '../../containers/AccessTokenModalContainer/AccessTokenModalContainer'
import HaahtelaAdminLicenseModal from '../../common/HaahtelaAdminLicenseModal/HaahtelaAdminLicenseModal'
import PrimarySelection from '../../../ProjectManagement/features/PrimarySelectionModal/PrimarySelection'
import UnfreezeEstimateModal from '../../common/UnfreezeEstimateModal/UnfreezeEstimateModal'
import MaintenanceOutageModal from '../../common/MaintenanceOutageModal/MaintenanceOutageModal'
import ReleaseNotesModal from '../../common/ReleaseNotesModal/ReleaseNotesModal'
import TermsOfUseModal from '../../common/TermsOfUseModal/TermsOfUseModal'

type Props = {
  type: string, // defines which content should be rendered inside widget
  contentProps: Object, // Props object for AttributeEdit Modal
  modalId?: string, // id of the modal that the content is in
  onClose?: Function, // modal closing function
  isEstimateFrozen: $PropertyType<TVDApplicationStore, 'isEstimateFrozen'>, // if estimate is frozen
  isEstimateLockedToCurrentUser: $PropertyType<TVDApplicationStore, 'isEstimateLockedToCurrentUser'>, // if estimate is locked to the current user

}

class Content extends Component<Props> {
  static defaultProps = {
    contentProps: {},
    modalId: '',
    onClose: undefined
  }
  render(): Node {
    const { type } = this.props
    switch (type) {
      case types.BUILDING_ELEMENTS_TASK:
        return <BuildingElementsTask {...this.props} />
      case types.CREATE:
        return <CreateWidget {...this.props} />
      case types.CREATE_ACTIVITY:
        return <CreateActivity {...this.props} />
      case types.CREATE_OTHER_SPACES:
        return <CreateOtherSpacesWidget {...this.props} />
      case types.PLANNING:
        return <DesignModelWidget {...this.props} />
      case types.AREA_EQUIPMENT_REGISTRY:
        return <EquipmentRegistry {...this.props} />
      case types.LIST:
        return <SpacesListContainer {...this.props} />
      case types.SPACEGROUP:
      case types.SPACE:
        return <Space {...this.props} />
      case types.ASSEMBLY:
        return <Assembly {...this.props} />
      case types.SPACES_ASSEMBLY:
        return <SpacesAssembly {...this.props} />
      case types.CREATE_FROM_PRICING:
        return <CreateFromPricing {...this.props} />
      case types.IMPORT_FROM_CALCULATION:
        return <ImportFromCalculation {...this.props} />
      case types.PRICEITEM:
      case types.EQUIPMENT:
        return <Element {...this.props} />
      case types.CALCULATION_PROPERTIES:
        return <CalculationProperties {...this.props} />
      case types.FLOOR:
        return <StoryContainer {...this.props} />
      case types.BUILDING_ELEMENTS_SCHEDULE:
        return <BuildingElementsSchedule {...this.props} />
      case types.MULTI_EDIT_SPACE_WIDGET:
      case types.MULTI_EDIT_SPACE_GROUP_WIDGET:
        return <MultiEditContainer {...this.props} />
      case modalTypes.MODAL_TYPE_CALCULATION_INFORMATION:
        return <CalculationInformationContainer {...this.props} />
      case types.ADD_RENOVATION_PROFILE_MODAL:
        return <RenovationProfileModal {...this.props} />
      case types.COPY_RENOVATION_PROFILE_MODAL:
        return <RenovationCopyProfile {...this.props} />
      case modalTypes.MODAL_TYPE_CREATE_REPORT:
        return <CreateReportModal {...this.props.contentProps} {...this.props} />
      case modalTypes.MODAL_TYPE_PRICING_ATTRIBUTE_EDIT:
        return <AttributesEdit type={this.props.contentProps.type} {...this.props.contentProps} {...omit(this.props, 'type')} />
      case modalTypes.MODAL_TYPE_CREATE_USER_DEFINED_EQUIPMENT:
        return <EquipmentModalContainer {...this.props} />
      case modalTypes.MODAL_TYPE_CREATE_USER_DEFINED_SURFACE:
        return <SurfaceModalContainer {...this.props} />
      case modalTypes.MODAL_TYPE_SETTINGS:
        return <SettingsContainer {...this.props} {...this.props.contentProps} />
      case types.EQUIPMENT_PRICING:
      case types.SURFACE_PRICING:
        return <Pricing {...this.props} />
      case types.RENAME_MODAL:
      case types.CREATE_ITEM_MODAL:
        return <SingleInputModal {...this.props} />
      case types.DELETE_BUILDING_MODAL:
        return <DeleteBuildingModal {...this.props} />
      case types.MOVE_BUILDING_MODAL:
      case types.ADD_PRICE_ITEM_TO_ASSEMBLY_MODAL:
      case types.ATTACH_SPACE_MODAL:
      case types.ATTACH_SPACE_GROUP_MODAL:
      case types.IMPORT_FROM_WOP_MODAL:
        return <DropdownModal {...this.props} />
      case types.CONFIRMATION_MODAL:
        return <ConfirmationModal {...this.props} />
      case modalTypes.MODAL_TYPE_CONFIRMATION_MODAL_WITH_GARS: {
        const { contentProps, modalId } = this.props
        if (!modalId) {
          console.error(`No modalId provided when opening modal with type ${modalTypes.MODAL_TYPE_CONFIRMATION_MODAL_WITH_GARS}`)
          return <></>
        }

        const {
          onSaveRequestDefinitions,
          onSaveSuccessfulRequestDefinitions,
          ...restOfContentProps
        } = contentProps

        const successCb = onSaveSuccessfulRequestDefinitions ? () => { runGAR(onSaveSuccessfulRequestDefinitions) } : undefined

        return (
          <ConfirmationModal
            {...restOfContentProps}
            modalId={modalId}
            // TODO: replace defining onClose in content to serializable actions https://tools.haahtela.fi/jira/browse/TPA-1343
            onClose={() => { dispatch(closeModal(modalId)) }}
            onSave={() => { runGAR({ ...onSaveRequestDefinitions, successCb }) }} />
        )
      }
      case types.CONFIRMATION_MODAL_PADDED:
        return <ConfirmationModalPadded {...this.props} />
      case types.CREATE_CIRCULATION:
        return <CommonFunctions {...this.props} functionType={CIRCULATION} />
      case types.CREATE_FACILITY_SERVICES:
        return <CommonFunctions {...this.props} functionType={FACILITY_SERVICES} />
      case types.CREATE_TECHNICAL_BUILDING_SERVICES:
        return <CommonFunctions {...this.props} functionType={TECHNICAL_BUILDING_SERVICES} />
      case types.CREATE_PROTECTED_CIRCULATION:
        return <CommonFunctions {...this.props} functionType={PROTECTED_CIRCULATION} />
      case types.RENOVATION:
        return <Renovation {...this.props} />
      case types.IMPORT_FROM_WOP_CALCULATION:
        return (<ImportFromWopCalculation {...this.props} />)
      case types.PROCESSES_AND_ACTIVITIES:
        return (<ProcessesAndActivities {...this.props} />)
      case types.PROPERTIES_MODAL:
        return <PropertiesModal {...this.props} />
      case types.MEASURE_PROPERTIES_CONTAINER: {
        const { modalId, contentProps } = this.props
        return <MeasurePropertiesContainer modalId={modalId} {...contentProps} />
      }
      case types.WOP_SPACE_SCHEDULE: {
        const { contentProps } = this.props
        return <WopSpaceSchedule {...this.props} {...contentProps} />
      }
      case types.GROUPING_VIEW:
        return <Grouping {...this.props} />
      case types.ERROR:
        return (
          <div style={{ whiteSpace: 'pre-wrap' }}>
            {JSON.stringify(this.props.contentProps, null, 2)}
          </div>
        )
      case types.OPERATING_PROFILE_SELECT_MODAL: {
        const { modalId, contentProps } = this.props
        return <OperatingProfileSelectModal modalId={modalId} {...contentProps} />
      }
      case types.UNIT_EDITING_MODAL: {
        const { modalId, contentProps } = this.props
        return <UnitEditingModal {...contentProps} modalId={modalId} />
      }
      case types.WOP_GROUPING_RENAME_MODAL: {
        const { modalId, contentProps } = this.props
        return <WOPGroupingRenameModalContainer {...contentProps} modalId={modalId} />
      }
      case types.WOP_GROUPING_MOVE_MODAL: {
        const { modalId, contentProps } = this.props
        return <WOPGroupingMoveModalContainer {...contentProps} modalId={modalId} />
      }
      case types.ACTIVITY_REGISTRY_WIDGET: {
        return <ActivityRegistry {...this.props} />
      }
      case modalTypes.MODAL_TYPE_PROPERTY_EDITOR: {
        const { modalId, contentProps } = this.props
        return <PropertyEditor modalId={modalId} {...contentProps} />
      }
      case modalTypes.ACCOUNT_SELECTION_MODAL: {
        const { modalId, contentProps } = this.props
        return <AccountSelectionModal modalId={modalId} {...contentProps} />
      }
      case modalTypes.MODAL_TYPE_USER_MODAL: {
        return <UserModalContainer {...this.props} />
      }
      case modalTypes.MODAL_TYPE_ADD_ACCESS_RIGHT_MODAL: {
        const { modalId, contentProps } = this.props
        return <AddAccessRightModalContainer modalId={modalId} {...contentProps} />
      }
      case modalTypes.SUB_SYSTEM_INFO_MODAL: {
        const { contentProps } = this.props
        return <SubSystemInfoModal {...contentProps} />
      }
      case modalTypes.MODAL_TYPE_ADD_USER_MODAL: {
        return <AddUserModalContainer {...this.props} />
      }
      case modalTypes.MODAL_TYPE_ADD_USER_TO_GROUP_MODAL: {
        return <AddUserToGroup {...this.props} />
      }
      case modalTypes.MODAL_TYPE_TOGGLE_EXTERNAL_GEOMETRY: {
        const { modalId, contentProps } = this.props
        return <ExternalGeometryToggleModalContainer {...contentProps} modalId={modalId} />
      }
      case modalTypes.MODAL_TYPE_ACCESS_TOKEN: {
        const { modalId, contentProps } = this.props
        return <AccessTokenModalContainer modalId={modalId} {...contentProps} />
      }
      case modalTypes.MODAL_TYPE_HAAHTELA_ADMIN_LICENSE: {
        const { modalId, contentProps } = this.props
        return <HaahtelaAdminLicenseModal modalId={modalId} {...contentProps} />
      }
      case modalTypes.MODAL_TYPE_PRIMARY_SELECTION: {
        const { modalId, contentProps } = this.props
        return <PrimarySelection modalId={modalId} {...contentProps} />
      }
      case modalTypes.MODAL_TYPE_UNFREEZE_ESTIMATE: {
        return <UnfreezeEstimateModal />
      }
      case modalTypes.MAINTENANCE_OUTAGE_MODAL: {
        const { contentProps } = this.props
        return <MaintenanceOutageModal {...contentProps} />
      }
      case modalTypes.RELEASE_NOTES_MODAL: {
        const { contentProps } = this.props
        return <ReleaseNotesModal {...contentProps} />
      }
      case modalTypes.TERMS_OF_SERVICE: {
        const { modalId } = this.props
        return <TermsOfUseModal id={modalId || ''} />
      }
      case modalTypes.RENAME_PROJECT: {
        const { contentProps } = this.props
        return <RenameProjectModal {...contentProps} />
      }
      case modalTypes.BUILDING_SETTINGS: {
        const { contentProps } = this.props
        return <BuildingSettingsDialog {...contentProps} />
      }
      default:
        return <div>{this.props.type}</div>
    }
  }
}

export default Content
