// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.
import { getEnvVariable } from '../utils/envUtil'

export const API_BASE_URL = getEnvVariable('API_BASE_URL')
export const FEATURE_SET = getEnvVariable('FEATURE_SET')

export const SERVICE_PROVIDER_CLIENT_ID = getEnvVariable('SERVICE_PROVIDER_CLIENT_ID')
export const AUTH_SERVICE_URL = getEnvVariable('AUTH_SERVICE_URL')
export const AUTH_FLOW_TYPE = getEnvVariable('AUTH_FLOW_TYPE')

export const DATA_ACTION_DEBUG = getEnvVariable('DATA_ACTION_DEBUG')

export const RELEASE_VERSION = getEnvVariable('RELEASE_VERSION')

export const API_REALTIME_URL = getEnvVariable('API_REALTIME_URL')

// If we allow user to have access to feedback tools/widgets e.g freshdesk
export const ENABLE_FEEDBACK = getEnvVariable('ENABLE_FEEDBACK')

export const ALLOW_WITH_CREDENTIALS = getEnvVariable('ALLOW_WITH_CREDENTIALS')
