// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.
import React, { Component } from 'react'
import { map } from 'lodash'
import { withStyles } from '@material-ui/core/styles'
import TableRow from '@material-ui/core/TableRow'
import { typographyClasses } from 'frontend-assets'
import { compose } from 'redux'
import { connect } from 'react-redux'
import PropertyRow from '../PropertyRow/PropertyRow'
import CategoryRow from '../CategoryRow/CategoryRow'
import ListOpenerIcon from '../../../lists/common/ListOpenerIcon/ListOpenerIcon'
import ListRowCell from '../../../lists/common/ListRowCell/ListRowCell'
import DescriptionCell from '../../../lists/common/DescriptionCell/DescriptionCell'
import { CALCULATION_PROPERTIES, SPACE } from '../../../../../constants/contentTypes'
import { setCategoryRowOpenComponentPreference } from '../../../../../actions/componentPreferences'

const styles = ({ palette }: Object): Object => ({
  category: {
    marginTop: '10px'
  },
  categoryRow: {
    display: 'flex',
    flex: '1 1 auto',
    alignItems: 'center',
    color: palette.nevada,
    fontFamily: typographyClasses.bodyDefault,
    lineHeight: '40px',
    outline: 0,
  },
  categoryTitle: {
    fontSize: 16,
  },
  categoryCell: {
    display: 'flex',
    overflow: 'hidden',
    width: '100%',
    alignItems: 'center',
    color: palette.nevada,
    fontFamily: typographyClasses.bodyDefault,
    lineHeight: '40px',
    outline: 0,
  },
  categoryTitleCell: {
    fontSize: 16,
  },
  iconWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 10,
    width: 20,
  },
  propertyRow: {
    alignItems: 'center',
    color: palette.defaultText,
    fontFamily: typographyClasses.bodyDefault,
    overflow: 'hidden',
    height: '40px',
    '&:hover [data-visible_on_hover]': {
      visibility: 'visible',
    },
  },
})

type OwnProps = {|
  title: string, // CategoryRow title
  data: Object, // Data object
  onBlur: Function, // action executed from onBlur event
  onChange: Function, // action executed when value changes
  resourceId: string, // id of the widgets resource list item
  resourceListId: string, // listId of the widgets resource list item
  hierarchy: Object, // flat object that includes all categories so categoryrows can read their hierarchy level property easily
  level: number, // hierarchy level of categoryrow
  disabled: boolean, // flag to disable row input
  resetDefault: Function, // resets property to default value
  widgetType: string, // widget type
  actionText?: string, // optional actionText for userModifiedIcon to overwrite default text
  staticValue: boolean, // should the inputs be static instead of modifiable (set where PropertyContainer is called)
  onPropertyRowMount?: (row: TVDPropertiesListItem) => void, // optional cb for when row mounts
|}

type DispatchProps = {|
  dispatchSetCategoryRowOpenComponentPreference: (title: string, isOpen: boolean) => void, // sets single category row status to Redux Store if it is open or closed
|}

type MappedProps = {|
  isOpen: boolean // indicates if category is open
|}

type HOCProps = {|
  classes: Object, // withstyles classes object
|}

type Props = {|
  ...OwnProps,
  ...HOCProps,
  ...DispatchProps,
  ...MappedProps
|}

export class CategoryRowClass extends Component<Props> {
  static defaultProps = {
    actionText: '',
    onPropertyRowMount: undefined
  }

  componentDidMount() {
    const { title, dispatchSetCategoryRowOpenComponentPreference, isOpen } = this.props
    if (typeof isOpen === 'undefined') {
    // Open up the first level of rows
      if (this.props.level === undefined || this.props.level === 1 || this.props.level === 2) {
        dispatchSetCategoryRowOpenComponentPreference(title, true)
      }
      // In Calculation Properties and in Space widget
      // If the row has data objects that contain the 'unit' key, then they are inputrows and should be left closed
      if (this.props.widgetType === CALCULATION_PROPERTIES || this.props.widgetType === SPACE.toLowerCase()) {
        const dataKeys = Object.keys(this.props.data)
        if (Object.prototype.hasOwnProperty.call(this.props.data[dataKeys[0]], 'unit')) {
          dispatchSetCategoryRowOpenComponentPreference(title, false)
        }
      }
    }
  }

  get arrow(): React$Element<any> {
    const { classes, isOpen } = this.props
    return (
      <div className={classes.iconWrapper}>
        <ListOpenerIcon isOpen={isOpen} position='static' />
      </div>
    )
  }

  get hierarchy(): React$Element<any> {
    const {
      classes,
      title,
      data,
      onBlur,
      onChange,
      resetDefault,
      resourceId,
      resourceListId,
      hierarchy = {},
      level = 0,
      disabled,
      widgetType,
      actionText,
      staticValue,
      onPropertyRowMount,
      isOpen
    } = this.props

    let rows
    if (data && !Object.hasOwnProperty.call(data, 'undefined')) {
      rows = map(data, (value: Object, key: string) => {
        if (Object.hasOwnProperty.call(value, 'categories')) {
          return (
            <PropertyRow
              widgetType={widgetType}
              className={classes.propertyRow}
              key={`PropertyRow-${resourceId}-${key}`}
              parentLevel={level}
              rowText={value.localizedName}
              isSubRow={value.localizedName.startsWith('* ')}
              row={value}
              onBlur={onBlur}
              onChange={onChange}
              resetDefault={resetDefault}
              resourceId={resourceId}
              resourceListId={resourceListId}
              category={title}
              disabled={disabled || value.readOnly}
              placeholder={value.placeholder}
              actionText={actionText}
              staticValue={staticValue}
              onPropertyRowMount={onPropertyRowMount} />
          )
        }
        const row = hierarchy[key] || {}

        return (
          <CategoryRow
            widgetType={widgetType}
            className={row.level === 1 ? classes.categoryTitle : classes.categoryRow}
            key={`CategoryRow-${resourceId}-${key}`}
            title={key}
            level={row.level}
            hierarchy={hierarchy}
            data={value}
            onBlur={onBlur}
            onChange={onChange}
            resetDefault={resetDefault}
            resourceId={resourceId}
            resourceListId={resourceListId}
            disabled={disabled}
            actionText={actionText}
            staticValue={staticValue}
            onPropertyRowMount={onPropertyRowMount} />
        )
      })
    }
    const paddingLeft = level === 0 ? 15 : (level + 1) * 15
    const styleClasses = level === 0 ? `${classes.categoryCell} ${classes.categoryTitleCell}` : classes.categoryCell

    return (
      <>
        <TableRow>
          <ListRowCell>
            <div
              role='button'
              tabIndex={0}
              onClick={(e: Event) => {
                e.stopPropagation()
                this.onClick()
              }}
              className={styleClasses}
              style={{ paddingLeft }}>
              {this.arrow}<DescriptionCell text={title} />
            </div>
          </ListRowCell>
          {/* CategoryRow has only the DescriptionCell so this extra cell is added to fix row borderBottom & resizing */}
          <ListRowCell colSpan={2} />
        </TableRow>
        { isOpen && rows}
      </>
    )
  }

  onClick = (): void => {
    const {
      title,
      dispatchSetCategoryRowOpenComponentPreference,
      isOpen
    } = this.props
    dispatchSetCategoryRowOpenComponentPreference(title, !isOpen)
  }

  render(): React$Element<any> {
    return (this.hierarchy)
  }
}

const mapDispatchToProps = (dispatch: Function, props: OwnProps): DispatchProps => ({
  dispatchSetCategoryRowOpenComponentPreference: (title: string, isOpen: boolean) => {
    dispatch(setCategoryRowOpenComponentPreference(props.widgetType.toLowerCase(), title, isOpen))
  }
})

const mapStateToProps = ({ componentPreferences }: TVDReduxStore, { widgetType, title }: OwnProps): MappedProps => {
  const widgetComponentPreferences = componentPreferences[widgetType.toLowerCase()] || {}
  const categoryRowsComponentPreferences = widgetComponentPreferences.categoryRows || {}
  const currentCategoryRowComponentPreference = categoryRowsComponentPreferences[title]
  return {
    isOpen: currentCategoryRowComponentPreference,
  }
}

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(CategoryRowClass)
