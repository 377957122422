// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.

import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core'
import { useDispatch } from 'react-redux'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import InputField from '../../../components/common/InputField/InputField'
import LabeledInput from '../../common/LabeledInput/LabeledInput'
import DropdownMenu from '../../common/menus/DropdownMenu/DropdownMenu'
import CheckBox from '../../common/CheckBox/CheckBox'
import FooterButtons from '../../containers/widgets/FooterButtons/FooterButtons'
import { closeModal } from '../../../actions/modals'
import { MODAL_TYPE_HAAHTELA_ADMIN_LICENSE } from '../../../constants/modalConstants'
import { type TextButtonProps } from '../TextButton/TextButton'

const useStyles = makeStyles(({ palette, typography }: TVDTheme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column'
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'scroll',
    marginBottom: '45px',
    maxHeight: '500px'
  },
  firstTitle: {
    font: `16px ${typography.latoBoldItalic}`,
    color: palette.defaultText,
    paddingBottom: '32px'
  },
  title: {
    font: `16px ${typography.latoBoldItalic}`,
    color: palette.defaultText,
    paddingTop: '32px',
    paddingBottom: '32px'
  },
  checkboxLabel: {
    color: palette.nevada,
    paddingLeft: '5px',
    fontSize: '14px'
  },
  inputWrapper: {
    display: 'inline-flex',
    alignItems: 'center'
  },
  inputLabel: {
    marginRight: '30px'
  },
  descriptionWrapper: {
    display: 'flex',
    alignItems: 'center',
    maxWidth: '200px'
  },
  description: {
    color: palette.nevadavalueField,
    paddingLeft: '5px',
    fontSize: '14px',
    marginRight: '30px'
  },
  inputFieldWrapper: {
    width: '150px'
  }
}))

const GetCountry = (): React$Element<typeof LabeledInput> => {
  const items = [
    {
      localizedName: 'test-localized-name-1',
      value: '5'
    },
    {
      localizedName: 'test-localized-name-2',
      value: '7'
    }
  ]

  return (
    <DropdownMenu
      title='test-title'
      items={items}
      id='test-dropdown'
      defaultValue='test-dropdown'
      required
      disabled={items.length === 1}
      onChange={() => { console.log('test-dropdown') }} />
  )
}

const HaahtelaAdminLicenseModal = () => {
  const classes = useStyles()
  const [name, setName] = useState('')
  const [numberOfProperties, setNumberOfProperties] = useState()
  const [numberOfCalculations, setNumberOfCalculations] = useState()
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const getButtons = (): Array<TextButtonProps> => [
    {
      text: t('apps._LICENSE_'),
      onClick: () => { console.log('click-save') },
      id: 'footerButton-LICENSE'
    },
    {
      variant: 'text',
      text: t('buttons._CANCEL_'),
      onClick: () => { dispatch(closeModal(MODAL_TYPE_HAAHTELA_ADMIN_LICENSE)) },
      id: 'footerButton-CANCEL'
    },
  ]

  return (
    <div className={classes.wrapper}>
      <div className={classes.content}>
        <Typography className={classes.firstTitle}>Perustiedot</Typography>
        <LabeledInput
          dataType='string'
          handleChange={(e: SyntheticInputEvent<any>) => { setName(e.target.value) }}
          label='Nimi / kuvaus'
          labelAtTop
          value={name}
          required />
        <span className={classes.inputWrapper}>
          <span className={classes.inputLabel}>
            <LabeledInput
              dataType={'string' || 'number'}
              handleChange={(e: SyntheticInputEvent<any>) => { setNumberOfProperties(e.target.value) }}
              label='Kiinteistöjen määrä'
              labelAtTop
              value={numberOfProperties}
              required />
          </span>
          <LabeledInput
            dataType={'string' || 'number'}
            handleChange={(e: SyntheticInputEvent<any>) => { setNumberOfCalculations(e.target.value) }}
            label='Laskelmien määrä moduleissa (tyhjä = ei rajattu)'
            labelAtTop
            value={numberOfCalculations}
            required />
        </span>
        <GetCountry />

        <Typography className={classes.title}>Modulit</Typography>
        <Grid container item xs={12} spacing={3}>
          <Grid item xs={4}>
            <CheckBox
              onChange={() => { console.log('test-change') }} />
            <span
              key='test-2'
              role='presentation'
              className={classes.checkboxLabel}
              onClick={() => { console.log('test-check') }}>
              Tilat
            </span>
          </Grid>
          <Grid item xs={4}>
            <CheckBox
              onChange={() => { console.log('test-change') }} />
            <span
              key='test-3'
              role='presentation'
              className={classes.checkboxLabel}
              onClick={() => { console.log('test-check') }}>
              Tontti
            </span>
          </Grid>
          <Grid item xs={4}>
            <CheckBox
              onChange={() => { console.log('test-change') }} />
            <span
              key='test-3'
              role='presentation'
              className={classes.checkboxLabel}
              onClick={() => { console.log('test-check') }}>
              Tilamitoitus
            </span>
          </Grid>
        </Grid>

        <Typography className={classes.title}>Tulokset</Typography>
        <Grid container item xs={12} spacing={3}>
          <Grid item xs={4}>
            <CheckBox
              onChange={() => { console.log('test-change') }} />
            <span
              key='test-2'
              role='presentation'
              className={classes.checkboxLabel}
              onClick={() => { console.log('test-check') }}>
              Kannattavuus / hinnat
            </span>
          </Grid>
          <Grid item xs={4}>
            <CheckBox
              onChange={() => { console.log('test-change') }} />
            <span
              key='test-3'
              role='presentation'
              className={classes.checkboxLabel}
              onClick={() => { console.log('test-check') }}>
              CO2
            </span>
          </Grid>
        </Grid>

        <Typography className={classes.title}>Toimialat ja laajuudet</Typography>
        <div className={classes.descriptionWrapper}>
          <span className={classes.description}>Asuminen</span>
          <span className={classes.inputFieldWrapper}>
            <InputField
              dataType='number'
              onChange={() => { console.log('test-input') }}
              initialValue='1000'
              unit='kem2' />
          </span>
        </div>
        <div className={classes.descriptionWrapper}>
          <span className={classes.description}>Toimitilat</span>
          <span className={classes.inputFieldWrapper}>
            <InputField
              dataType='number'
              onChange={() => { console.log('test-input') }}
              initialValue='0'
              unit='kem2' />
          </span>
        </div>
        <div className={classes.descriptionWrapper}>
          <span className={classes.description}>Toimitilat</span>
          <span className={classes.inputFieldWrapper}>
            <InputField
              dataType='number'
              onChange={() => { console.log('test-input') }}
              initialValue='0'
              unit='kem2' />
          </span>
        </div>
      </div>
      <FooterButtons items={getButtons()} />
    </div>
  )
}

export default HaahtelaAdminLicenseModal
