// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.

import * as React from 'react'
import { connect } from 'react-redux'
import { Route, Redirect, withRouter } from 'react-router-dom'

import { isAuthenticatedUser } from '../../../actions/user'
import { authenticationFlowType } from '../../../utils/authUtil'
import Feature from '../../containers/Feature/Feature'

type Props = {
  user: Object,
  authenticateUser: Function,
  featureName?: string, // constant string given to <Feature /> as "name" prop
  state: Object, // additional variables passed down as props
}

export class PrivateRoute extends Route<Props> {
  static defaultProps = {
    featureName: ''
  }
  get redirect(): React$Element<typeof Redirect> {
    const { location, path } = this.props
    let pathname
    switch (true) {
      case path.startsWith('/account-management'): pathname = '/account-management/kirjaudu'; break
      case path.startsWith('/haahtela-admin'): pathname = '/haahtela-admin/kirjaudu'; break
      default: {
        pathname = '/kirjaudu'
      }
    }
    return <Redirect to={{ pathname, state: { from: location } }} />
  }

  render(): Route {
    const {
      component: Component,
      user,
      state,
      location: {
        pathname,
        search
      },
      ...rest
    } = this.props
    return (
      <Route
        {...rest}
        render={(props: Object) => {
        if (authenticationFlowType) {
          user.route = `${pathname}${search}`
          return isAuthenticatedUser(user) ? (
            <Feature
              name={this.props.featureName}
              onFeatureDisabled={() => this.redirect}>
              <Component {...props} state={state} />
            </Feature>) :
            this.redirect
            }
          return <Component {...props} state={state} />
      }} />
    )
  }
}

const mapStateToProps = ({ user }: TVDReduxStore): Object => ({ user })

export default connect(mapStateToProps)(withRouter(PrivateRoute))
