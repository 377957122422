// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { cloneDeep, reduce, get } from 'lodash'

import PropertiesDataTable from '../PropertiesDataTable/PropertiesDataTable'
import SentientHOC from '../../../../hocs/SentientHOC/SentientHOC'

import { setCalculationActive, setCalculationComplete } from '../../../../../actions/app'
import {
  editProperty,
  propertyResetDefault,
  closeWidget,
  setSelectedTab,
  setDropdownContents,
  markAsModified,
  clearProperties,
  setWidgetContentProps
} from '../../../../../actions/widgets'

import { parseCategorizedPropertyList } from '../../../../../utils/parseUtil'
import { getHierarchyLevels } from '../../../../../utils/listUtils'
import { getEnumFromEnumStore } from '../../../../../utils/reducers/enums'
import {
  CALCULATION_PROPERTIES, BUILDING_ELEMENTS_TASK, SPACEGROUP, PROPERTIES_MODAL, ADD_RENOVATION_PROFILE_MODAL
} from '../../../../../constants/contentTypes'
import { runGAR } from '../../../../../utils/GARUtils'
import { getReferenceSchedulePropertiesWithEstimateIdRequestDefinitions } from '../../../../../utils/generated-api-requests/buildingelements'
import { getRenovationSpacesProfilesPropertiesWithProfileIdRequestDefinitions } from '../../../../../utils/generated-api-requests/spaces_renovation'
import {
  getEstimatePropertiesWithEstimateIdRequestDefinitions,
  getSpaceScheduleSpaceGroupsPropertiesWithIdRequestDefinitions,
  getSpaceScheduleSpacesPropertiesWithIdRequestDefinitions
} from '../../../../../utils/generated-api-requests/spaces'

type DispatchProps = {|
  dispatchSetWidgetContentProps: (contentProps: Object) => void, // sets widget content to widget for the use of saving the mounted list
  dispatchSetSelectedTab: Function, // function to set selected tab in store
  dispatchSetLoading: Function, // function to activate loading spinner when loading content
  dispatchSetLoaded: Function, // function to deactivate loading spinner when content has been loaded
  dispatchSetDropdownContents: (Object) => void, // function to set containers' dropdown contents to store all at once
  dispatchCloseWidget: Function, // close widget function
  dispatchEditProperty: (string, string | number | boolean, userModified?: boolean) => void, // function to update property value to store
  dispatchResetDefault: Function, // resets property to default value
  dispatchClearProperties: () => void, // remove a properties list from proeprties store with store id
|}

type MappedProps = {|
  resourceId: string, // id of widgets resource list item
  tab: string, // current tab
  application: string, // current application
  propertiesStoreId: string, // key to the properties Store object where the properties is stored
  widgetId?: string, // widget's Id in store
  disableMarkAsModified?: boolean, // prevent widget from calling markAsModified upon editProperty
  editPropertyCb?: (string, string | number | boolean, userModified?: boolean) => void, // edit property callback for editing outside of save pattern
  enums: TVDEnumStore, // all fetched enums so far from API
  languageCode: string, // user selected language code
  spacesEstimateType: $PropertyType<TVDApplicationStore, 'spacesEstimateType'>, // currently selected space estimate type
|}


type Props = {|
  ...DispatchProps,
  ...MappedProps,
  data: Object, // data object
  modified: boolean, // modified flag
  propertiesStoreId: string, // key to the properties Store object where the properties is stored
  hideButtons: boolean, // hide footer buttons
  disabled: boolean, // flag to disable PropertyContainer rows
  type: string, // type of widget used to call proper request
  selectedTab: string, // name of selected tab
  sentient: TVDSentient, // Object providing helpers via SentientHOC
  actionText?: string, // optional actionText for userModifiedIcon to overwrite default text
  staticValue: boolean, // should the inputs be static instead of modifiable
|}

export class PropertyContainer extends Component<Props> {
  static defaultProps = {
    editPropertyCb: undefined,
    disableMarkAsModified: false,
    actionText: '',
    staticValue: false
  }
  componentDidMount() {
    const { widgetId, dispatchSetSelectedTab, dispatchSetWidgetContentProps } = this.props
    if (widgetId) {
      dispatchSetWidgetContentProps({ disableFooterActions: false })
      dispatchSetSelectedTab()
    }
    this.runSentientSetup()
  }

  componentDidUpdate(prevProps: Props) {
    const {
      selectedTab,
      dispatchSetSelectedTab,
      resourceId,
      languageCode,
      dispatchClearProperties,
      spacesEstimateType,
    } = this.props
    if (!selectedTab) dispatchSetSelectedTab()
    if (spacesEstimateType !== prevProps.spacesEstimateType || resourceId !== prevProps.resourceId) this.runSentientSetup()
    if (languageCode !== prevProps.languageCode) dispatchClearProperties()
  }

  get propertiesDataTable(): React$Element<any> {
    const {
      type,
      resourceId,
      propertiesStoreId,
      dispatchEditProperty,
      dispatchCloseWidget,
      dispatchResetDefault,
      data,
      modified,
      hideButtons,
      disabled,
      actionText,
      staticValue,
    } = this.props

    const listItems = parseCategorizedPropertyList(data)
    const copy = cloneDeep(listItems)
    const hierarchy = getHierarchyLevels(copy)
    const onChange = (propertyName: string, localizedName: string | number, shouldReset: boolean) => {
      dispatchEditProperty(propertyName, localizedName, shouldReset)
    }

    return (
      <PropertiesDataTable
        type={type}
        hideButtons={hideButtons}
        modified={modified}
        resourceId={resourceId}
        resourceListId={propertiesStoreId}
        closeWidget={dispatchCloseWidget}
        listItems={listItems}
        hierarchy={hierarchy}
        disabled={disabled}
        onChange={onChange}
        onBlur={onChange}
        resetDefault={dispatchResetDefault}
        actionText={actionText}
        staticValue={staticValue}
        onPropertyRowMount={this.onPropertyRowMount} />
    )
  }

  runSentientSetup = () => {
    const { sentient } = this.props
    sentient.runSetup()
  }

  onPropertyRowMount = (row: TVDPropertiesListItem) => {
    const {
      languageCode,
      dispatchSetDropdownContents,
      enums
    } = this.props
    const { enumRequestDefinitions, propertyName, options } = row
    if (options && options.length > 0) return
    if (enumRequestDefinitions) {
      const { basePath } = enumRequestDefinitions
      const bPath = basePath.replace('/', '')
      const existingEnum = getEnumFromEnumStore({
        basePath: bPath,
        languageCode,
        enumKey: propertyName,
        enums
      })
      if (existingEnum) {
        dispatchSetDropdownContents({ [propertyName]: existingEnum })
      } else {
        runGAR({
          ...enumRequestDefinitions,
          successCb: (enumData: Array<TVDEnum>) => {
            dispatchSetDropdownContents({ [propertyName]: enumData })
          }
        })
      }
    }
  }

  render(): React$Element<any> {
    return this.propertiesDataTable
  }
}

function mapStateToProps({
  widgets, properties, app, enums
}: TVDReduxStore, { widgetId, resourceId, propertiesStoreId }: Props): Object {
  const widget = widgets[widgetId] || {} // propertyContainer can also be in modal - default to empty object in that case
  const modified = get(widget, ['modified']) || false
  const selectedTab = get(widget, ['contentProps', 'selectedTab']) || {}

  const { [propertiesStoreId]: propertiesListItems } = properties
  const { calculation, application, languageCode } = app
  const modifiedPropertiesListItems = reduce(
    propertiesListItems,
    (result: Object, { modifiedValue, ...propertiesListItem }: TVDPropertiesListItem) => ({
      ...result,
      [propertiesListItem.propertyName]: {
        ...propertiesListItem,
        // as the component uses "value" to render, we use the modified value if found
        value: modifiedValue || propertiesListItem.valueLocalization || propertiesListItem.value
      }
    }), {}
  )

  return {
    application,
    modified,
    resourceId,
    selectedTab,
    data: modifiedPropertiesListItems,
    estimateId: calculation,
    enums,
    languageCode,
    spacesEstimateType: app.spacesEstimateType
  }
}

function mapDispatchToProps(dispatch: Function, {
  resourceId,
  tab,
  propertiesStoreId,
  widgetId: widgetIdProp,
  editPropertyCb,
  disableMarkAsModified
}: MappedProps): Object {
  // generally widgetId should be defined, but there are some legacy cases where it is not due to assumption that propertyContainer is always in a widget case example: front-end/src/components/common/PropertiesModal/PropertiesModal.jsx
  const widgetId = widgetIdProp || ''
  return {
    dispatchSetWidgetContentProps: (contentProps: Object) => { dispatch(setWidgetContentProps(widgetId, contentProps)) },
    dispatchResetDefault: (propertyName: string) => { dispatch(propertyResetDefault(propertiesStoreId, propertyName, tab, widgetId)) },
    dispatchCloseWidget: () => { dispatch(closeWidget(resourceId)) },
    dispatchSetDropdownContents: (data: Object) => { dispatch(setDropdownContents(data, propertiesStoreId, widgetId)) },
    dispatchSetLoading: () => { dispatch(setCalculationActive()) },
    dispatchSetLoaded: () => { dispatch(setCalculationComplete()) },
    dispatchSetSelectedTab: () => {
      if (tab) dispatch(setSelectedTab(tab, widgetId))
    },
    dispatchEditProperty: (propertyName: string, value: string | number | boolean, shouldReset?: boolean) => {
      if (editPropertyCb) editPropertyCb(propertyName, value, shouldReset)
      if (!disableMarkAsModified) dispatch(markAsModified(widgetId, tab))
      dispatch(editProperty(propertiesStoreId, propertyName, value))
    },
    dispatchClearProperties: () => { dispatch(clearProperties(propertiesStoreId)) }
  }
}

const sentientConfig: TVDSentientConfig = {
  getSetupRequestDefinitions: (store: Object, props: Props): TVDGARConfigs => {
    const { resourceId, propertiesStoreId, type } = props
    let getPropertiesDefinitions = getSpaceScheduleSpacesPropertiesWithIdRequestDefinitions({
      payload: { propertiesStoreId },
      requestArgs: { path: { id: resourceId } }
    })
    switch (true) {
      case type === CALCULATION_PROPERTIES: {
        getPropertiesDefinitions = getEstimatePropertiesWithEstimateIdRequestDefinitions({
          payload: { propertiesStoreId }
        })
        break
      }
      case type.toUpperCase() === SPACEGROUP: {
        getPropertiesDefinitions = getSpaceScheduleSpaceGroupsPropertiesWithIdRequestDefinitions({
          payload: { propertiesStoreId },
          requestArgs: { path: { id: resourceId } }
        })
        break
      }
      case type === BUILDING_ELEMENTS_TASK: {
        return {}
      }
      case type === PROPERTIES_MODAL: {
        getPropertiesDefinitions = getReferenceSchedulePropertiesWithEstimateIdRequestDefinitions({
          payload: { propertiesStoreId },
        })
        break
      }
      case type === ADD_RENOVATION_PROFILE_MODAL: {
        getPropertiesDefinitions = getRenovationSpacesProfilesPropertiesWithProfileIdRequestDefinitions({
          payload: { propertiesStoreId },
          requestArgs: { path: { profileId: resourceId } },
        })
        break
      }
      case type === 'SITEPROPERTIES': {
        return {}
      }
      default:
        break
    }
    return {
      getPropertiesDefinitions
    }
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(SentientHOC(PropertyContainer, sentientConfig))
