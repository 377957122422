// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.

import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { colors } from 'frontend-assets'
import MUIswitch from '@material-ui/core/Switch'

const styles = (): Object => ({
  switchBar: {
    opacity: 1,
    height: '12px',
    transform: 'translate(0, -1px)',
    backgroundColor: colors.dark40
  },
  switchIconChecked: {}, // not necessary but indicates that the class is infact in use
  switchBase: {
    color: colors.gray20,
    '&$switchIconChecked': { // switchIcon checked styles
      color: colors.primary100,
      '& + $switchBar': {
        opacity: 1,
        backgroundColor: colors.dark20
      }
    },
  },
  switchDisabled: {
    color: `${colors.gray20} !important`,
    '& + $switchBar': {
      opacity: '1 !important',
      backgroundColor: `${colors.gray60} !important`
    }
  }
})

export type SwitchProps = {|
  disabled: boolean, // if the component is disabled
  onChange: (state: boolean) => {}, // returns the component state when the component is clicked
  testId?: string, // for testing purposes
  checked: boolean, // true if switch is checked
  onClick?: (e: MouseEvent) => {} // function to access switch onClick event if needed
|}

type Props = {
  ...SwitchProps,
  classes: Object, // withStyles styles prop
}

export class Switch extends Component<Props> {
  static defaultProps = {
    testId: 'switch'
  }

  render(): React$Element<MUIswitch> {
    const {
      disabled,
      classes,
      testId,
      onChange,
      onClick,
      checked
    } = this.props
    return (
      <MUIswitch
        size='small'
        color='primary'
        checked={checked}
        data-testid={testId}
        classes={{
          switchBase: classes.switchBase,
          checked: classes.switchIconChecked,
          track: classes.switchBar,
          disabled: classes.switchDisabled,
        }}
        disabled={disabled}
        onClick={onClick}
        onChange={() => onChange(!checked)} />
    )
  }
}

export default withStyles(styles)(Switch)
