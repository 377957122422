// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.

import {
  OPEN_MODAL,
  CLOSE_MODAL,
  CLOSE_ALL_MODALS,
  INCREMENT_MODAL_COUNT,
  DECREMENT_MODAL_COUNT,
  CLEAR_MODAL_COUNT,
  SET_MODAL_TITLE
} from '../actions/modals'
import { RENAME_VALUE } from '../actions/elements'

const initialState = {
  openModals: {},
  modalCount: 0,
}

export default function modalsReducer(state: Object = initialState, action: Object = {}): Object {
  switch (action.type) {
    case OPEN_MODAL: {
      const {
        payload: {
          content,
          id,
        }
      } = action
      return {
        ...state,
        openModals: {
          ...state.openModals,
          [id]: {
            ...content,
            id,
            index: content.index || state.modalCount - 1,
          }
        }
      }
    }
    case CLOSE_MODAL: {
      const {
        payload: {
          id,
        }
      } = action
      const { openModals } = state
      const { [id]: omit, ...restModals } = openModals
      return {
        ...state,
        openModals: {
          ...restModals,
        }
      }
    }
    case CLOSE_ALL_MODALS: {
      return {
        ...state,
        openModals: {}
      }
    }
    case RENAME_VALUE: {
      const {
        payload: {
          id,
          value,
        }
      } = action
      return {
        ...state,
        openModals: {
          ...state.openModals,
          [id]: {
            ...state.openModals[id],
            newValue: value,
          }
        }
      }
    }
    case INCREMENT_MODAL_COUNT: {
      return {
        ...state,
        modalCount: state.modalCount + 1,
      }
    }
    case DECREMENT_MODAL_COUNT: {
      let newModalCount = state.modalCount - 1
      if (newModalCount < 0) {
        console.warn(`Trying to decrement modal count to below zero (${newModalCount}). Setting count to 0`)
        newModalCount = 0
      }
      return {
        ...state,
        modalCount: newModalCount
      }
    }
    case CLEAR_MODAL_COUNT: {
      return {
        ...state,
        modalCount: 0
      }
    }
    case SET_MODAL_TITLE: {
      const { payload: { title, modalId } } = action
      const modal = state.openModals[modalId]
      if (!modal) {
        console.error(`No modal found with ${modalId}`)
        return state
      }
      return {
        ...state,
        openModals: {
          ...state.openModals,
          [modalId]: {
            ...modal,
            title
          }
        }
      }
    }
    default:
      return state
  }
}
