// @flow
// Copyright © 2010–2023 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.
import React, { useEffect, useRef } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useSelector, useStore } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { getEstimateHistoryObj } from '../../../utils/urlUtil'
import { loadMFE, registeredMFEs } from '../../../utils/MFEUtils'
import { API_BASE_URL, ALLOW_WITH_CREDENTIALS } from '../../../constants/envConstants'
import { openGenericErrorModal } from '../../../utils/modalUtils'
import { FEATURE_LICENCE_TYPE_CHIP } from '../../../constants/features'
import { getIsFeatureEnabledInSet } from '../../../utils/features'
import { LOCAN } from '../../../constants/moduleConstants'
import { setActiveEstimate } from '../../../actions/activeEstimate'
import { refreshUserAccessToken } from '../../../actions/user'

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  }
}))

const LocationAnalysisMFEContainer = (): React$Element<'div'> => {
  const classes = useStyles()
  const history = useHistory()
  const { dispatch } = useStore()
  const moduleOnUnmountRef = useRef<$PropertyType<TVDMFEFunctionality, 'onUnmount'>>()
  const {
    realEstateId,
    accessToken,
    haahtelaApiUserToken,
    userId,
    languageCode,
    selectedAccountId,
    userPrivileges,
    licenseType,
    realEstateName,
    isEstimateLockedToCurrentUser,
    activeEstimate: activeLOCANEstimate,
    siteId
  } = useSelector(({
    activeEstimate,
    app,
    user,
  }: TVDReduxStore): {|
    languageCode: $PropertyType<TVDApplicationStore, 'languageCode'>,
    selectedAccountId: $PropertyType<TVDApplicationStore, 'selectedAccountId'>,
    accessToken: $PropertyType<TVDUserAuthorization, 'accessToken'>,
    haahtelaApiUserToken: $PropertyType<TVDUserClaims, 'haahtelaApiUserToken'>,
    userId: $PropertyType<TVDUserClaims, 'userId'>,
    userPrivileges: $PropertyType<TVDUserStore, 'userPrivileges'>,
    licenseType: $PropertyType<TVDApplicationStore, 'licenseType'>,
    realEstateName: $PropertyType<TVDApplicationStore, 'realEstateName'>,
    activeEstimate: TVDActiveEstimateStore,
    isEstimateLockedToCurrentUser: $PropertyType<TVDApplicationStore, 'isEstimateLockedToCurrentUser'>,
    realEstateId: $PropertyType<TVDApplicationStore, 'realEstateId'>,
    siteId: $PropertyType<TVDApplicationStore, 'siteId'>,
  |} => ({
    languageCode: app.languageCode,
    selectedAccountId: app.selectedAccountId,
    accessToken: user.authorization.accessToken,
    haahtelaApiUserToken: user.claims.haahtelaApiUserToken,
    userId: user.claims.userId,
    realEstateName: app.realEstateName,
    userPrivileges: user.userPrivileges,
    licenseType: app.licenseType,
    activeEstimate,
    realEstateId: app.realEstateId,
    isEstimateLockedToCurrentUser: app.isEstimateLockedToCurrentUser,
    siteId: app.siteId
  }))

  const navigateToLocanEstimate = React.useCallback((estimateId: string, beforeNavigation?: () => void): void => {
    if (beforeNavigation) {
      beforeNavigation()
    }
    history.push(getEstimateHistoryObj({
      app: LOCAN,
      accountId: selectedAccountId,
      estimateId,
      realEstateId,
      realEstateName
    }))
  }, [
    history,
    realEstateName,
    selectedAccountId,
    realEstateId
  ])

  useEffect(() => () => {
    if (moduleOnUnmountRef.current) {
      moduleOnUnmountRef.current()
    }
  }, [])

  const registeredLocationAnalysisModule = registeredMFEs['location-analysis-module']
  useEffect(() => {
    if (
      languageCode &&
      accessToken &&
      haahtelaApiUserToken &&
      userId &&
      selectedAccountId &&
      userPrivileges &&
      activeLOCANEstimate &&
      realEstateId &&
      siteId
    ) {
      const setActiveEstimateToStore = (estimate: TVDCalculation): void => {
        dispatch(setActiveEstimate(estimate))
      }
      const render = (module: TVDMFEFunctionality): void => {
        moduleOnUnmountRef.current = module.onUnmount
        const moduleRender = module.render || null
        if (moduleRender) {
          moduleRender(
            'location-analysis-module',
            {
              activeEstimate: activeLOCANEstimate,
              APIBaseURL: API_BASE_URL,
              languageCode,
              accessToken,
              haahtelaApiUserToken,
              userId,
              selectedAccountId,
              navigateToLocanEstimate,
              openGenericErrorModal,
              userPrivileges,
              ALLOW_WITH_CREDENTIALS,
              isEstimateLockedToCurrentUser,
              realEstateName,
              licenseType: getIsFeatureEnabledInSet(FEATURE_LICENCE_TYPE_CHIP) ? licenseType : null,
              realEstateId,
              siteId,
              setActiveEstimateToStore,
              refreshUserAccessToken
            }
          )
        }
      }
      if (registeredLocationAnalysisModule) {
        render(registeredLocationAnalysisModule)
      } else {
        loadMFE('location_analysis', 'location-analysis-module', (module: TVDMFEFunctionality): void => {
          render(module)
        })
      }
    }
  }, [
    languageCode,
    accessToken,
    haahtelaApiUserToken,
    userId,
    selectedAccountId,
    userPrivileges,
    registeredLocationAnalysisModule,
    activeLOCANEstimate,
    licenseType,
    realEstateName,
    realEstateId,
    siteId,
    navigateToLocanEstimate,
    isEstimateLockedToCurrentUser,
    dispatch
  ])
  return (
    <div className={classes.root} id='location-analysis-module' />
  )
}

export default LocationAnalysisMFEContainer
