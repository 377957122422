// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.
import React from 'react'
import type { Element } from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import SearchField from '../../SearchField/SearchField'
import { setListItemFilter, clearListItemsFilter } from '../../../../actions/list'


type OwnProps = {|
  +listId: string, // id that matches a lists key in Store
  +filteredTypes?: Array<string>, // optional set of types, e.g priceitem used in listReducer's list filter setting
  disableOnBlur?: boolean, // if the on blur event is not handled at all
  onSearch?: (searchString: string) => void, // optional onSearch callback that is run for all searches
|}

type StateProps = {|
  listFilter: string | null, // filter for particular list
|}

type DispatchProps = {|
  dispatchClearListItemsFilter: () => void, // clears list's filter from Store
  dispatchSetListItemFilter: (string) => void, //  sets list's filter to Store
|}

type Props = {
  ...StateProps,
  ...DispatchProps,
  ...OwnProps,
  t: Function, // translate fn
}

type State ={|
  searchInputValue: string, // current value of the search input
  lastSearchInputValue: string, // what was the last string used in creating a search
|}

const minSearchCharacters = 3

export class ListSearchField extends React.Component<Props, State> {
  static defaultProps = {
    disableOnBlur: false,
    onSearch: undefined
  }

  state = {
    searchInputValue: '',
    lastSearchInputValue: '',
  }

  search = () => {
    const { searchInputValue, lastSearchInputValue } = this.state
    const {
      dispatchSetListItemFilter,
      dispatchClearListItemsFilter,
      onSearch
    } = this.props

    if (!onSearch && searchInputValue.length === 0) {
      dispatchClearListItemsFilter()
    }

    if (!onSearch && searchInputValue.length < minSearchCharacters) {
      return
    }


    if (onSearch) {
      onSearch(searchInputValue)
      this.setState({ lastSearchInputValue: searchInputValue })
    } else if (searchInputValue !== lastSearchInputValue) {
      dispatchSetListItemFilter(this.state.searchInputValue)
      this.setState({ lastSearchInputValue: searchInputValue })
    }
  }

  updateSearchInput = (event: SyntheticEvent<any>) => {
    this.setState({ searchInputValue: event.currentTarget.value })
  }

  onKeyDown = (event: SyntheticKeyboardEvent<any>) => {
    if (event.key === 'Enter') this.search()
  }

  render(): Element<typeof SearchField> {
    const {
      t,
      listId,
      dispatchClearListItemsFilter,
      disableOnBlur,
      onSearch
    } = this.props
    const { lastSearchInputValue } = this.state

    return (
      <SearchField
        id={listId}
        label={t('widgets._SEARCH_')}
        isSearchActive={lastSearchInputValue !== ''}
        onClear={() => {
          dispatchClearListItemsFilter()
          this.setState({ searchInputValue: '', lastSearchInputValue: '' })
          if (onSearch) onSearch('')
        }}
        onBlur={!disableOnBlur ? this.search : undefined}
        onKeyDown={this.onKeyDown}
        onChange={this.updateSearchInput}
        value={this.state.searchInputValue} />
    )
  }
}

type Store = {|
  +list: Object // lists in Store
|}

const mapStateToProps = ({ list }: Store, { listId }: OwnProps) => ({
  listFilter: list[listId] && list[listId].listFilter ? list[listId].listFilter : null
})

const mapDispatchToProps = (dispatch: Function, { listId, filteredTypes = [] }: OwnProps): DispatchProps => ({
  dispatchClearListItemsFilter: () => dispatch(clearListItemsFilter(listId)),
  dispatchSetListItemFilter: (filterStr: string) => dispatch(setListItemFilter(listId, filterStr, filteredTypes)),
})

export default (withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(ListSearchField)))
