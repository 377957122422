// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.
export const SPACE = 'space'
export const BUILDINGELEMENTS = 'buildingElements'
export const WOP = 'wop'

export const apiEstimateTypes = [WOP, SPACE, BUILDINGELEMENTS]

export const TVD_TOKEN_USER_TYPE_USER: TVDTokenUserType = 'user'

export const TVD_USER_AUTH_PATHNAME = '/auth'

export const TVD_ALLOWED_DECODING_PATHNAMES = [TVD_USER_AUTH_PATHNAME]

export const TVD_PRIVILEGE_FILTER_ACCOUNT_MANAGER = 'account_manager'
export const TVD_PRIVILEGE_FILTER_REAL_ESTATE_MANAGER = 'real_estate_manager'

export const TVD_HEADER_ACCOUNT_ID = 'X-TakuPro-Account-Id'
export const TVD_HEADER_LANGUAGE = 'X-TakuPro-Language'
export const TVD_HEADER_REALESTATE_ID = 'X-TakuPro-RealEstate-ID'
