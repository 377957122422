// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.

import { reduce } from 'lodash'
import {
  parseListItems,
  parseProperties,
  parseListItemsWithoutParentIds,
  parseEstimates,
  parseUserClaims,
  getStories,
  parseBuilding,
  noParser,
  parseDocuments,
  parseActivities,
  parseWopItems,
  parseSubjectPermissions,
  parseAccountLicense
} from './requestParsers'
import {
  BUILDING_TASK_SCHEDULE_ITEM,
  ESTIMATE,
  BUILDING,
  VALUE_PROPERTY,
  ELEMENT_SCHEDULE_ITEM,
  ACTIVITY_SCHEDULE_ITEM,
  SCHEDULE_ITEM,
  ENUMERAL,
  DOCUMENT,
  HAL,
  SPACE_SCHEDULE_ITEM,
  OPERATING_PROFILE_SCHEDULE_ITEM,
  GROUPING_SCHEDULE_ITEM,
  USER,
  USER_REDUCED,
  USER_GROUP,
  SUBJECT_PERMISSIONS,
  LICENSE
} from '../constants/schemaKeys'

export type TVDParserParameters = {|
  HALParsedData: any, // data that has gone through the HAL parsers
  basePath?: string, // optional basePath from swagger files to determine what API context the request is designed for
  data?: any, // raw non HAL parsed data
|}

const getSchemaParserFn = (fnName: string, schemaKey?: string | null, isEmbedded?: boolean): Function | null | string => {
  switch (fnName) {
    case 'getEstimateEquipmentRegistryWithEstimateIdRequest':
    case 'getSpacesEquipmentItemsWithItemIdRequest':
    case 'getSpacesEquipmentRegistryWithSpaceIdRequest':
    case 'getSpacesEquipmentRegistryItemsWithItemIdRequest':
    case 'getSpacesSurfacesRegistryItemsWithItemIdRequest': return parseListItemsWithoutParentIds
    case 'getDesignModelStoriesWithEstimateIdRequest': return getStories
    case 'getUsersSelfRequest': return parseUserClaims
    case 'getDocumentsWithIdRequest': return parseDocuments
    case 'getUsersSelfModulesRequest':
    case 'getUsersModulesWithIdRequest': return noParser
    case 'getRenovationSpacesActionTopicsWithEstimateIdRequest': return parseProperties
    case 'getRegistryActivitiesRequest': return parseActivities
    default:
      break
  }
  if (!isEmbedded) {
    switch (schemaKey) {
      case BUILDING: return parseBuilding
      default: return (data: string) => data
    }
  }
  switch (schemaKey) {
    case GROUPING_SCHEDULE_ITEM:
    case ACTIVITY_SCHEDULE_ITEM: return parseWopItems
    case LICENSE: return parseAccountLicense
    case ESTIMATE: return parseEstimates
    case VALUE_PROPERTY: return parseProperties
    case ELEMENT_SCHEDULE_ITEM:
    case BUILDING_TASK_SCHEDULE_ITEM:
    case SPACE_SCHEDULE_ITEM:
    case OPERATING_PROFILE_SCHEDULE_ITEM:
    case SCHEDULE_ITEM: return parseListItems
    case SUBJECT_PERMISSIONS: return parseSubjectPermissions
      // TODO: replace parser for USER when API returns values for User schema key (TPYT-1125)
    case ENUMERAL:
    case DOCUMENT:
    case BUILDING:
    case USER:
    case USER_REDUCED:
    case USER_GROUP:
    case HAL: return noParser

    default: {
      if (schemaKey === null || typeof schemaKey === 'undefined') {
        console.error(`No parser found for parserKey with type "${typeof schemaKey}"`)
      } else {
        console.error(`No parser found for parserKey "${schemaKey}"`)
      }
      return null
    }
  }
}

const HAL_EMBEDDED = '_embedded'

const getHALParsedData = ({ data }: TVDRequestResponse, responseDataKey: string, isEmbedded: boolean): any => {
  if (isEmbedded) {
    try {
      const { [HAL_EMBEDDED]: embeddedData } = data
      const embeddedKeys = Object.keys(embeddedData)
      // TODO: create recursive embedded data parsing
      // current solution can't parse according to schema an embedded data within embedded
      if (embeddedData && embeddedKeys.length === 1) return embeddedData[embeddedKeys[0]]
      return embeddedData[responseDataKey]
    } catch (er) {
      console.error(er)
    }
  }
  return data
}

const getParsersByEmbeddedTypes = (embeddedTypes: Object, fnName: string, basePath: string) => (
  reduce(embeddedTypes, (result: Object, embeddedObject: TVDEmbeddedType, key: string) => {
    const { schemaKey } = embeddedObject
    const isEmbedded = key !== 'nonEmbedded'
    const schemaParserFn = getSchemaParserFn(fnName, schemaKey, isEmbedded)
    return {
      ...result,
      ...(typeof schemaParserFn === 'function' ? {
        [key]: (data: Object) => schemaParserFn({
          HALParsedData: getHALParsedData(data, key, isEmbedded),
          data,
          basePath
        })
      } : {})
    }
  }, {})
)

type ReduceResultType = {
  [nonHALDataKey: string]: any
}

export default (embeddedTypes: Object, fnName: string, basePath: string): Function => {
  // no need for PATCH, DELETE, PUT or POST result parsing
  if (fnName.startsWith('patch') || fnName.startsWith('delete') || fnName.startsWith('post') || fnName.startsWith('put')) return null

  const parsers = embeddedTypes ? getParsersByEmbeddedTypes(embeddedTypes, fnName, basePath) : { singleParser: getSchemaParserFn(fnName) }
  return (response: TVDRequestResponse) => {
    const { data } = response
    const parserCalls = reduce(parsers, (result: Object, parser: Function, parserKey: string) => ({ ...result, [parserKey]: parser(response) }), {})
    const firstParserName = Object.keys(parserCalls)[0]
    const firstParser = parserCalls[firstParserName]
    if (typeof data === 'string') return firstParser // as there can be only one parser for one type of response, we use the only parser available

    const nonHALDataKeys = Object.keys(data).filter((responseDataKey: string) => !responseDataKey.startsWith('_'))
    const nonHALData = nonHALDataKeys.reduce((result: ReduceResultType, nonHALDataKey: string) => ({
      ...result,
      [nonHALDataKey]: data[nonHALDataKey]
    }), {})

    const hasSingleParser = Object.keys(parserCalls).length === 1
    const hasNoHALData = !Object.keys(nonHALData).length
    if (hasSingleParser && hasNoHALData) return firstParser

    // if multiple parsers, the caller needs to know which *parsedResponse to use e.g. myGARFn({}, payload, (*parsedResponse) => { return parsedResponse.buildings }
    return {
      ...parserCalls,
      ...nonHALData
    }
  }
}
