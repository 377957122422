// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.
import axios from 'axios'

type GetJWTTokenDecoded = {|
  requestArgs: {|
    userType: TVDTokenUserType,
    token: string, // token of the user
  |},
  successCb?: (TVDDecodedToken) => void, // callback function called if requests is ok
  errorCb?: (Error) => void // callback function called if requests receives an error
|}
export const getJWTTokenDecoded = ({
  requestArgs: {
    token,
    userType,
  },
  successCb,
  errorCb
}: GetJWTTokenDecoded): Promise<any> => axios({
  method: 'get',
  url: `/jwtdecode/${userType}/${token}`,
}).then(({ data }: TVDRequestResponse): TVDDecodedToken => {
  if (successCb) successCb(data)
  return data
}).catch((error: Error) => {
  if (errorCb) errorCb(error)
  return null
})
