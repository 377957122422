// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.
// Add parsers made for requests as export const youParserName = () => ...

import { reduce, omitBy, isUndefined, map, find } from 'lodash'
import HALParser, { ALL_KEYS } from './HALParser'
import { isEnum } from './commonUtils'
import { ROWS_WITHOUT_CHILDREN } from '../constants/contentTypes'
import { getEnumGARDefinition } from './GARUtils'
import { type TVDParserParameters } from '../utils/parserMapper'
import { DRIVER } from '../constants/attributes'
import { getWOPListItemParentIds, getPrefixedWOPListItemId } from './listUtils'

const getValuePropertyEnumRequestDefinitions = (valueProperty: TVDSchemaValueProperty, basePath?: string): TVDGARConfig | null => {
  const { dataType } = valueProperty
  if (isEnum(dataType) && basePath) {
    const enumURL = HALParser.getLinksEnumHref(valueProperty)
    const [,, enumName,, category] = enumURL.split(/[/?=]/)
    return getEnumGARDefinition(basePath, enumName, category)
  }
  return null
}

export const parseProperties = ({ HALParsedData, basePath }: TVDParserParameters) => map(HALParsedData, (property: Object) => {
  const { dataType } = property
  // columns and properties both use parseProperties parser, there might be a situation where
  // column datatype is enum. In this situation we don't want to try to parse enumHref link
  const selected = isEnum(dataType) ? property.localizedName : undefined
  return omitBy({
    enumRequestDefinitions: getValuePropertyEnumRequestDefinitions(property, basePath) || undefined,
    selected,
    ...property
  }, isUndefined)
})

const parseMeasures = (measures: Array<TVDSchemaMeasure>) => measures.map((measure: TVDSchemaMeasure): TVDMeasure => {
  const properties = HALParser.getProperties({ data: measure })
  const { [ALL_KEYS.HAL_EMBEDDED]: embeddedMeasureProperties, ...restOfMeasure } = measure

  const parsedProperties = parseProperties({ HALParsedData: properties })
    .reduce((result: TVDPropertiesListItems, property: TVDPropertiesListItem): TVDPropertiesListItems =>
      ({ ...result, [property.propertyName]: property }), {})

  const { localizedName, value } = restOfMeasure
  return {
    localizedName,
    value,
    properties: parsedProperties
  }
})

const parseFilterIds = (measures: Array<TVDWOPSchemaFilterId>): Array<number> => measures.map(({ value }: TVDWOPSchemaFilterId): number => value)

const getListItemWithEmbedded = (listItem: TVDSchemaScheduleItem | TVDWOPSchemaScheduleItem) => {
  const { measures, filterIds } = HALParser.getEmbedded(listItem) || {}
  const { [ALL_KEYS.HAL_EMBEDDED]: embedded, ...plainListItem } = listItem

  if (measures) plainListItem.measures = parseMeasures(measures)
  if (filterIds) plainListItem.filterIds = parseFilterIds(filterIds)
  return plainListItem
}

export const parseListItems = ({ HALParsedData }: TVDParserParameters): TVDListItems => {
  const result = {}
  let { length } = HALParsedData

  while (length) {
    let listItem = HALParsedData[length - 1]
    const hierarchy = HALParser.getEmbedded(listItem)?.hierarchy || []
    const items = HALParser.getLinksItems(listItem)
    const canGetInfo = !!HALParser.getLinksInfo(listItem)
    listItem = getListItemWithEmbedded(listItem)

    if (items !== undefined && items !== null) {
      listItem = {
        ...listItem,
        isOpen: false,
        canHaveChildren: !ROWS_WITHOUT_CHILDREN.includes(listItem.type.toUpperCase()),
        canGetInfo
      }
    }
    result[listItem.id] = { ...listItem, canGetInfo, hierarchy }
    length -= 1
  }
  return result
}


export const parseListItemsWithoutParentIds = (listItems: Object) => reduce(parseListItems(listItems), (result: Object, item: Object) => {
  const { parentId, ...valuesExcludingParentId } = item
  if (!parentId) {
    return result
  }
  return {
    ...result,
    [item.id]: {
      ...valuesExcludingParentId,
    }
  }
}, {})

export const parseEstimates = ({ HALParsedData, data }: TVDParserParameters) => reduce(HALParsedData, (result: Object, estimate: Object) => {
  const { [ALL_KEYS.HAL_EMBEDDED]: embeddedData, ...newEstimate } = estimate
  const { estimateType, id } = estimate
  if (!result[estimateType]) {
    result[estimateType] = {}
  }
  const { [ALL_KEYS.PERMISSIONS]: permissions = [] } = embeddedData || {}
  const permissionsSubject = data?.config?.params?.permissionsSubject
  const permissionsSubjectPermissions = permissions.find((permission: TVDPermission): boolean => permission.subjectId === permissionsSubject)

  result[estimateType][id] = {
    ...newEstimate,
    permissionsSubjectPermissions: permissionsSubjectPermissions ? permissionsSubjectPermissions.permissions : []
  }
  return result
}, {})

export const parseUserClaims = ({ HALParsedData: userClaims }: TVDParserParameters) => {
  const {
    id,
    organizationId,
    email,
    phone,
    firstName,
    lastName,
    userLevel,
  } = userClaims

  const claims = {
    userId: id,
    organizationId,
    firstName,
    lastName,
    emailAddress: email,
    phone,
    userLevel,
  }
  return claims
}

export const getStories = ({ HALParsedData }: TVDParserParameters) => reduce(HALParsedData, (result: Object, story: Object) => {
  const { properties } = HALParser.getEmbedded(story)

  const parsedPropertyNamesAndValuesFromMetaData = properties.reduce((_result: Object, { propertyName, value }: Object) => ({
    ..._result,
    [propertyName]: value
  }), {})

  const parsedMetaData = properties.reduce((_result: Object, property: Object) => ({
    ..._result,
    [property.propertyName]: property
  }), {})

  return {
    ...result,
    propertyNameValues: [...result.propertyNameValues, { id: story.id, ...parsedPropertyNamesAndValuesFromMetaData }],
    propertyMetas: { ...result.propertyMetas, [story.Id]: parsedMetaData }
  }
}, { propertyNameValues: [], propertyMetas: {} })

export const parseBuilding = ({ HALParsedData }: TVDParserParameters) => {
  const {
    id,
    _links,
    location,
    ...rest
  } = HALParsedData
  return { ...location, ...rest, _links }
}

export const noParser = ({ HALParsedData }: TVDParserParameters) => HALParsedData // for non HAL standard responses

type TVDDocumentResponse = {| data: string |}
export const parseDocuments = ({ data }: TVDDocumentResponse): string => data

export const parseActivities = ({ HALParsedData }: TVDParserParameters): TVDListItems => {
  const result = {}
  let { length } = HALParsedData

  while (length) {
    const listItem = HALParsedData[length - 1]
    const drivers = HALParser.getDrivers(listItem)
    const id = getPrefixedWOPListItemId(listItem.value)
    result[id] = {
      id,
      registryItemId: listItem.value,
      columnData: {
        Description: listItem.localizedName,
        Driver: {
          ...find(drivers, (driver: TVDSchemaValueProperty) => driver.propertyName === DRIVER)
        }
      }
    }
    length -= 1
  }
  return result
}

export const parseWopItems = ({ HALParsedData }: TVDParserParameters): TVDListItems => {
  const result = {}
  let { length } = HALParsedData
  while (length) {
    const scheduleItem = HALParsedData[length - 1]
    const items = HALParser.getLinksItems(scheduleItem)

    // common listItem properties wheter or not the listItem has link to additional items
    const listItem = {
      ...scheduleItem,
      ...getListItemWithEmbedded(scheduleItem),
      id: getPrefixedWOPListItemId(scheduleItem.id),
      parentId: scheduleItem.parentId ? getPrefixedWOPListItemId(scheduleItem.parentId) : '',
      wopItemId: scheduleItem.id,
      parentWopItemId: scheduleItem.parentId,
      isOpen: false,
      parentIds: getWOPListItemParentIds(HALParsedData, scheduleItem.parentId),
      canGetInfo: !!HALParser.getLinksInfo(scheduleItem),
      hierarchy: HALParser.getEmbedded(scheduleItem)?.hierarchy || []
    }

    if (items !== undefined && items !== null) {
      // add canHaveChildren flag to items that have link to additional items
      listItem.canHaveChildren = !ROWS_WITHOUT_CHILDREN.includes(listItem.type.toUpperCase())
    }

    result[listItem.id] = listItem
    length -= 1
  }
  return result
}
export const parseAccountLicense = ({ data }: TVDParserParameters): Array<TVDLicensesResponse> => {
  if (!data) return []
  const { data: responseData } = data
  const {
    [ALL_KEYS.LICENSES]: licenses,
  } = HALParser.getEmbedded(responseData)

  const subjects = [...licenses]
  return subjects
}

export const parseSubjectPermissions = ({ data }: TVDParserParameters): Array<TVDParsedSubjectPermissions> => {
  if (!data) return []
  const { data: responseData } = data
  const {
    [ALL_KEYS.USERS]: users,
    [ALL_KEYS.PERMISSIONS]: permissions,
    [ALL_KEYS.USER_GROUPS]: userGroups
  } = HALParser.getEmbedded(responseData)

  // || [] prevents a problem when either of these is undefined
  const subjects = [...userGroups || [], ...users || []]

  if (!permissions) return subjects

  const result = subjects.map((subject: {|
        value?: string, // id of the user group
        id?: string, // id of the user
    |}): Object => ({
    ...subject,
    permissions: permissions.find((permission: TVDSchemaSubjectPermission): boolean =>
      permission.subjectId === (subject.id || subject.value))?.permissions || []
  }))

  return result
}
