// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.
import React, { Component } from 'react'
import { connect, batch } from 'react-redux'
import { compose } from 'redux'
import { withTranslation } from 'react-i18next'
import { withStyles } from '@material-ui/core/styles'
import uuidv4 from 'uuid/v4'

import HamburgerMenu from '../../menus/HamburgerMenu/HamburgerMenu'
import HierarchicalListContainer from '../../../containers/HierarchicalListContainer/HierarchicalListContainer'
import UserModifiedIcon from '../../../containers/infoComponents/UserModifiedIcon'
import DescriptionCell from '../../../common/lists/common/DescriptionCell/DescriptionCell'
import Feature from '../../../containers/Feature/Feature'
import ViewHeaderText from '../../ViewHeaderText/ViewHeaderText'
import InfoPopover from '../../../common/InfoPopover/InfoPopover'
import SentientHOC from '../../../hocs/SentientHOC/SentientHOC'

import { RENOVATION } from '../../../../constants/viewModeConstants'
import { FEATURE_RESET_SURFACES } from '../../../../constants/features'
import { SURFACE_PRICING, SURFACES, EQUIPMENT, ITEM, PRICEITEM, ASSEMBLY, SPACES_ASSEMBLY } from '../../../../constants/contentTypes'
import { DESCRIPTION, AMOUNT, SHARE, QUANTITY, QUANTITY_SURFACE_ADJUSTED, SHARE_OF_SURFACE_QUANTITY } from '../../../../constants/attributes'
import { SURFACES_TAB } from '../../../../constants/moduleConstants'
import { getIsUserModifiedContent } from '../../../../utils/listUtils'
import {
  deleteListItem,
  addListItem,
  modifyListItem,
  toggleAllListItemsOpen,
  setListItemOpen
} from '../../../../actions/list'
import { createUserDefinedSurfaceModal } from '../../../../actions/modals'
import {
  toggleWidgetModified,
  setSelectedTab,
  openContentWidget,
  setWidgetContentProps,
} from '../../../../actions/widgets'
import {
  getSpacesSurfacesItemsDefaultWithItemIdRequest,
  getSpacesSurfacesColumnsWithSpaceIdRequestDefinitions,
  getSpacesSurfacesWithSpaceIdRequestDefinitions,
} from '../../../../utils/generated-api-requests/spaces'
import { setScrollPositionComponentPreference } from '../../../../actions/componentPreferences'


const styles = {
  surfaces: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column'
  },
  row: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    padding: '20px'
  },
  cell: {
    display: 'flex',
  },
  headerRowContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    marginRight: 2,
    width: '100%',
    outline: 0,
  },
  pointer: {
    cursor: 'pointer',
  },
  iconsContainer: {
    lineHeight: 1,
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center'
  },
}

type HOCProps = {|
  t: Function, // translate function
  classes: Object, // withstyles classes object
  sentient: TVDSentient, // Object providing helpers via SentientHOC
|}

type DispatchProps = {|
  dispatchAddListItem: (Object, string, string, boolean) => void, // add list item to Store
  dispatchCreateSurface: (Function, Object) => void, // open create user-defined equipment modal
  dispatchToggleWidgetModified: (boolean) => void, // Marks the tab to have unsaved changes
  dispatchOpenContentWidget: (TVDOpenContentWidgetArguments) => void, // Open widget function
  dispatchOpenSurfaceRegistry: (string) => void, // Open widget function
  dispatchSetSelectedTab: Function, // sets selected tab to Store
  dispatchDeleteSurfaceRow: Function, // sets "isRemoved" status to the row in Store
  dispatchSetModifiedData: (Object) => void, // fn to set data for row, that can be saved with patch request
  dispatchSetWidgetContentProps: () => void, // sets widget content to widget for the use of saving the mounted list
  dispatchSetScrollPositionComponentPreference: (number) => void, // sets scroll position to component preferences
  dispatchToggleAllListItemsOpen: () => void, // toggles all list items open
  dispatchSetListItemOpen: (listItemId: string, isOpen: boolean) => void, // toggles list item open
|}

type MappedProps = {|
  application: string, // current application
  calculation: string, // ID of the calculation
  activeEdit: boolean, // flag to disable widget content when view is being edited
  isEstimateLockedToCurrentUser: $PropertyType<TVDApplicationStore, 'isEstimateLockedToCurrentUser'>, // if estimate is locked to current user
  isEstimateFrozen: $PropertyType<TVDApplicationStore, 'isEstimateFrozen'>, // if estimate is frozen
  spacesEstimateType: $PropertyType<TVDApplicationStore, 'spacesEstimateType'>, // spaces estimate type
  spacesResultView: $PropertyType<TVDApplicationStore, 'spacesResultView'>, // spaces result view
  componentPreferences: Object, // component preferences from Store for surfaces tab
|}

type ReceivedProps = {|
  disabled: boolean, // status of if the widget is disabled or not where the Equipments is rendered
  spaceId: string,
  listStoreId: string, // uuid or constant used as the key in list Store for the list
  widgetId: string, // widget id that is passed from <Content> from inside <Widget /> when <Surfaces />
|}

type Props = {|
  ...HOCProps,
  ...DispatchProps,
  ...MappedProps,
  ...ReceivedProps
|}

export class Surfaces extends Component<Props> {
  componentDidMount() {
    this.props.dispatchSetSelectedTab()
  }

  componentDidUpdate(prevProps: Props) {
    const { spacesEstimateType, spacesResultView, sentient } = this.props
    if (
      spacesResultView !== prevProps.spacesResultView ||
      spacesEstimateType !== prevProps.spacesEstimateType) {
      sentient.runSetup()
    }
  }

  showContextMenu = (): boolean => {
    const { isEstimateLockedToCurrentUser, isEstimateFrozen } = this.props
    return !!isEstimateLockedToCurrentUser && !isEstimateFrozen
  }

  openWidgetFromSurfaces(id: string, title: string, widgetType: string): Function {
    const { dispatchOpenContentWidget, spaceId, listStoreId } = this.props

    const itemsListStoreId = `${id}-items`
    const attributesPropertiesStoreId = `${id}-attributes`

    if (widgetType === ASSEMBLY.toLowerCase()) {
      dispatchOpenContentWidget({
        widgetId: id,
        widgetType: SPACES_ASSEMBLY,
        widgetTitle: title,
        contentProps: {
          spaceId,
          resourceId: id,
          itemsListStoreId,
          attributesPropertiesStoreId,
          listStoreId
        },
        widgetResultBarStoreSource: {
          listId: listStoreId,
          listItemId: id,
        }
      })
    }
    if (widgetType === EQUIPMENT.toLowerCase() || widgetType === PRICEITEM.toLowerCase() || widgetType === ITEM.toLowerCase()) {
      dispatchOpenContentWidget({
        widgetId: id,
        widgetType: EQUIPMENT,
        widgetTitle: title,
        contentProps: {
          spaceId,
          equipmentId: id,
          propertiesStoreId: attributesPropertiesStoreId,
          listStoreId
        },
      })
    }
  }

  getContextMenuItems(row: Object): Array<TVDMenuItem> {
    if (!this.showContextMenu()) return []
    const {
      t,
      dispatchAddListItem,
      dispatchCreateSurface,
      dispatchOpenSurfaceRegistry,
      dispatchDeleteSurfaceRow
    } = this.props
    const { parentId, id: rowId } = row

    const addItem = {
      localizedName: t('widgets._ADD_SURFACE_FROM_REGISTRY_'),
      onClick: () => dispatchOpenSurfaceRegistry(parentId || rowId), // using rowId if no parentId is available - this means it's the root item
      testId: 'ContextMenuItem-add-surface-from-registry'
    }
    const addUserDefinedItem = {
      localizedName: t('widgets._ADD_USER_DEFINED_ITEM_'),
      onClick: () => dispatchCreateSurface((listItem: Object) => dispatchAddListItem(listItem, uuidv4(), rowId, true), { parentId }),
      testId: 'ContextMenuItem-add-user-defined-item'
    }
    const deleteItem = {
      localizedName: t('buttons._DELETE_'),
      onClick: () => dispatchDeleteSurfaceRow(rowId),
      testId: 'ContextMenuItem-delete'
    }
    return parentId ? [addItem, deleteItem] : [addItem, addUserDefinedItem]
  }

  getHamburgerMenu(row: Object): React$Element<HamburgerMenu> {
    const { columnData: { Description: rowDescription } } = row
    return <HamburgerMenu id={rowDescription} items={this.getContextMenuItems(row)} visibleOnHover />
  }


  getUserModifiedIcon(row: Object): React$Element<Feature> {
    const { t } = this.props
    return (
      <Feature name={FEATURE_RESET_SURFACES}>
        <UserModifiedIcon
          actionText={t('userModifiedInfo._SPACES_RESET_DEFAULT_VALUE_')}
          id={row.columnData.Description}
          visible
          actionCb={() => { this.resetSurfaceToDefaults(row) }} />
      </Feature>
    )
  }

  resetSurfaceToDefaults(row: Object): Function {
    const { spaceId, listStoreId } = this.props

    getSpacesSurfacesItemsDefaultWithItemIdRequest(
      { path: { spaceId, itemId: row.id } },
      { listId: listStoreId, mergeOptions: { removeInitialListItemsWithParentIdOf: row.id } }, () => {
        this.props.dispatchSetModifiedData({
          listItemId: row.id,
          listId: listStoreId,
          columnName: 'userModifiedContent',
          value: false,
        })
      }
    )
  }

  getWrappedCells(): Object {
    const { classes } = this.props

    return {
      Description: ({ content, row }: Object) => (
        <div
          data-testid='open-widget'
          role='button'
          tabIndex={0}
          className={classes.headerRowContainer}
          onClick={() => this.getOpenWidgetFromSurfaces(row)}>
          <DescriptionCell highlighted={row.level > 0} text={content} />
          { this.getIconsContainer(row) }
        </div>
      )
    }
  }

  getIconsContainer(row: TVDListItem): React$Element<any> | null {
    const {
      classes,
      application,
      activeEdit,
      spaceId
    } = this.props
    if (activeEdit) return null

    return (
      <div data-testid='iconsContainer' className={classes.iconsContainer} data-visible_on_hover>
        { getIsUserModifiedContent(row) && this.getUserModifiedIcon(row) }
        {
          row.canGetInfo &&
          <InfoPopover
            id={`${row.id}-InfoPopover`}
            application={application}
            infoData={{
              spaceId,
              itemId: row.id,
              type: SURFACES,
              propertyName: row.id,
              heading: row.columnData.Description
            }} />
        }
        { this.showContextMenu() && this.getHamburgerMenu(row) }
      </div>
    )
  }

  getOpenWidgetFromSurfaces(row: Object): Function | void {
    const { activeEdit } = this.props

    if (!activeEdit && row.level > 0) {
      this.openWidgetFromSurfaces(row.id, row.columnData.Description, row.type.toLowerCase())
    }
  }

  isModified(isModified: boolean): Function {
    const { dispatchToggleWidgetModified } = this.props
    dispatchToggleWidgetModified(isModified)
  }

  viewHeaderText(): React$Element<ViewHeaderText> | null {
    const { spacesEstimateType, t } = this.props
    return spacesEstimateType === RENOVATION ?
      <ViewHeaderText inWidget>{t('renovation._SURFACES_TAB_VIEW_HEADER_TEXT_')}</ViewHeaderText> :
      null
  }

  render(): React$Element<any> {
    const {
      disabled,
      listStoreId,
      isEstimateLockedToCurrentUser,
      dispatchSetWidgetContentProps,
      sentient,
      isEstimateFrozen,
      dispatchSetScrollPositionComponentPreference,
      dispatchToggleAllListItemsOpen,
      componentPreferences,
      dispatchSetListItemOpen
    } = this.props
    return (
      <>
        { this.viewHeaderText() }
        <HierarchicalListContainer
          componentPreferencesId={SURFACES_TAB}
          onScrollCb={(scrollTop: number) => {
            dispatchSetScrollPositionComponentPreference(scrollTop)
          }}
          initialColumnWidths={{
            [DESCRIPTION]: 570
          }}
          isEstimateLockedToCurrentUser={isEstimateLockedToCurrentUser}
          isEstimateFrozen={isEstimateFrozen}
          disabled={disabled}
          contextMenuItems={this.getContextMenuItems}
          testId={SURFACES}
          listId={listStoreId}
          editableColumns={[AMOUNT, SHARE_OF_SURFACE_QUANTITY, QUANTITY, QUANTITY_SURFACE_ADJUSTED, SHARE]}
          onModifiedChange={this.isModified.bind(this)}
          rowShouldDisableOtherColumnsAfterEdit
          wrappedCellContents={this.getWrappedCells()}
          didMountCallback={() => {
          sentient.runSetup({
            getSpacesSurfacesWithSpaceIdRequestDefinitions: {
              successCb: () => {
                const { openRowIds } = componentPreferences || {}
                batch(() => {
                  if (!openRowIds) {
                    dispatchToggleAllListItemsOpen()
                  } else {
                    Object.keys(openRowIds).forEach((listItemId: string) => {
                      dispatchSetListItemOpen(listItemId, openRowIds[listItemId])
                    })
                  }
                  dispatchSetWidgetContentProps()
                })
              }
            }
          })
        }} />
      </>
    )
  }
}

function mapStateToProps({ app, componentPreferences: { [SURFACES_TAB]: componentPreferences } }: TVDReduxStore): MappedProps {
  const {
    application,
    calculation,
    isEstimateLockedToCurrentUser,
    activeEdit,
    isEstimateFrozen,
    spacesEstimateType,
    spacesResultView
  } = app
  return {
    application,
    activeEdit,
    calculation,
    isEstimateLockedToCurrentUser,
    isEstimateFrozen,
    spacesEstimateType,
    spacesResultView,
    componentPreferences
  }
}

function mapDispatchToProps(dispatch: Function, { spaceId, widgetId, listStoreId }: Props): DispatchProps {
  return {
    dispatchOpenContentWidget: (openContentWidgetArgs: TVDOpenContentWidgetArguments) => { dispatch(openContentWidget(openContentWidgetArgs)) },
    dispatchSetSelectedTab: () => { dispatch(setSelectedTab(SURFACES_TAB, widgetId)) },
    dispatchToggleWidgetModified: (isModified: boolean) => { dispatch(toggleWidgetModified(widgetId, SURFACES_TAB, isModified)) },
    dispatchDeleteSurfaceRow: (listItemId: string) => { dispatch(deleteListItem(listStoreId, listItemId)) },
    dispatchCreateSurface: (onSave: Function, listItem: Object) => { dispatch(createUserDefinedSurfaceModal(onSave, listItem)) },
    dispatchSetModifiedData: (payload: Object) => { dispatch(modifyListItem(payload)) },
    dispatchSetWidgetContentProps: () => {
      dispatch(setWidgetContentProps(widgetId, { surfacesListId: listStoreId, spaceId, disableFooterActions: false }))
    },
    dispatchAddListItem: (listItem: Object, rowId: string, parentId: string, isUserAdded?: boolean) => {
      dispatch(addListItem(listStoreId, listItem, parentId, isUserAdded))
    },
    dispatchOpenSurfaceRegistry: (itemId: string) => {
      dispatch(openContentWidget({
        contentProps: {
          itemId,
          spaceId,
          pricingListId: listStoreId,
          pricingType: 'surfaces',
          widgetLevelChild: true
        },
        widgetId: SURFACE_PRICING,
        widgetType: SURFACE_PRICING,
      }))
    },
    dispatchSetScrollPositionComponentPreference: (scrollPosition: number) => {
      dispatch(setScrollPositionComponentPreference(SURFACES_TAB, scrollPosition))
    },
    dispatchToggleAllListItemsOpen: () => {
      dispatch(toggleAllListItemsOpen(listStoreId))
    },
    dispatchSetListItemOpen: (listItemId: string, isOpen: boolean) => {
      dispatch(setListItemOpen(listStoreId, listItemId, isOpen, SURFACES_TAB))
    }
  }
}

const sentientConfig: TVDSentientConfig = {
  updateOnPropsChange: ({ spacesEstimateType, spacesResultView, listStoreId }: Props) => ({ listStoreId, spacesEstimateType, spacesResultView }),
  getSetupRequestDefinitions: (store: Object, { spaceId, listStoreId }: Props): TVDGARConfigs =>
    ({
      getSpacesSurfacesWithSpaceIdRequestDefinitions: getSpacesSurfacesWithSpaceIdRequestDefinitions({
        payload: {
          listStoreId,
          mergeOptions: {
            initialListItems: {},
          }
        },
        requestArgs: { path: { spaceId }, query: { listType: 'flat' } }
      }),
      getSpacesSurfacesColumnsWithSpaceIdRequestDefinitions: getSpacesSurfacesColumnsWithSpaceIdRequestDefinitions({
        payload: { listStoreId },
        requestArgs: { path: { spaceId } }
      }),
    })
}

export default compose(
  withStyles(styles),
  withTranslation('translations'),
  connect(mapStateToProps, mapDispatchToProps)
)(SentientHOC(Surfaces, sentientConfig))
