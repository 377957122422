// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.


export type ReduceSuccessfulEmbeddedActionsCallbackArguments = {|
  schemaKey: string, // schemaKey parsed from swagger specs via api fn generator
  action: TVDAction, // a Redux action
  newState: Object // the new state that can be returned to updated the old one found from store
|}
type ReduceSuccessfulEmbeddedActionsArguments = {|
  action: TVDAction, // a Redux action
  state: Object, // Redux store
  cb: (CbArgs: ReduceSuccessfulEmbeddedActionsCallbackArguments) => any, // callback that is applied on each embeddedType
  conditionFn: () => boolean, // extra check if we want to start reducing the embedded types from the request
|}

export const reduceSuccessfulEmbeddedActions: Object = ({
  action,
  state,
  cb,
  conditionFn,
}: ReduceSuccessfulEmbeddedActionsArguments) => {
  const { meta: { embeddedType } = {} } = action
  const condition = conditionFn ? conditionFn() : true
  if (embeddedType && action.type.endsWith('SUCCESSFUL') && condition) {
    return Object.keys(embeddedType).reduce((newState: Object, eTypeKey: string) =>
      (cb ? cb({ schemaKey: embeddedType[eTypeKey].schemaKey, action, newState }) : newState), { ...state })
  }
  return state
}
