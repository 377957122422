// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.
import { findIndex, cloneDeep, map, reduce, find } from 'lodash'
import {
  UPDATE_SLIDER,
  UPDATE_VERTICAL_SLIDER,
  MODIFY_STORY_PROPERTY,
  CLEAR_DESIGN_MODEL,
  SET_UPDATE_PROTECTED_CIRCULATION
} from '../actions/widgets/designModelWidget'
import {
  DATA_GET_DESIGN_MODEL_WITH_ESTIMATEID_SUCCESSFUL,
  DATA_GET_DESIGN_MODEL_STORIES_WITH_ESTIMATEID_SUCCESSFUL,
  DATA_GET_DESIGN_MODEL_STORIES_WITH_STORYID_SUCCESSFUL,
  DATA_GET_ESTIMATE_SETTINGS_WITH_ESTIMATEID_SUCCESSFUL,
} from '../utils/generated-api-requests/spaces'
import { SVG_HEIGHT } from '../components/containers/widgets/DesignModelWidget/Levels/Levels'

const initialState = {
  options: {
    updateProtectedCirculation: false,
    showStructures: false,
  }
}

const ARCHITECTURE_SLIDER_KEYS = [
  'QualityOfMassing',
  'QualityOfMaterialsAndDetails',
  'QualityOfYardArea'
]

const VERTICAL_SLIDER_KEYS = [
  'LevelOfConstructedGroundSurfaceM',
  'LevelOfLoadBearingSoilM',
  'LevelOfGroundwaterM',
  'LevelOfOriginalGroundSurfaceM'
]

export const DROPDOWN_KEYS = [
  'SoilTypeAtGroundSurface',
  'SoilTypeAtFoundationDepth'
]

export default function designModelReducer(state: Object = initialState, action: Object = {}): Object {
  switch (action.type) {
    case DATA_GET_DESIGN_MODEL_WITH_ESTIMATEID_SUCCESSFUL: {
      const { payload: { parsedResponse } } = action

      // converts array of designModel items into an object of key value pairs
      const designModel = {
        ...reduce(
          parsedResponse,
          (result: Object, property: Object) => ({
            ...result,
            [property.propertyName]: DROPDOWN_KEYS.includes(property.propertyName) ? property : property.value
          }),
          {}
        )
      }

      const {
        LevelOfConstructedGroundSurfaceM,
        LevelOfLoadBearingSoilM,
        LevelOfGroundwaterM,
        LevelOfOriginalGroundSurfaceM,
        ...editor
      } = designModel

      const architectureSliders = map(ARCHITECTURE_SLIDER_KEYS, (key: string, index: number) => ({
        id: `architecture-slider-${index}`,
        name: key,
        value: editor[key] // TODO: use more descriptive term for architecture sliders
      }))

      const verticalSliders = map(VERTICAL_SLIDER_KEYS, (key: string) => ({
        id: key,
        height: designModel[key] * SVG_HEIGHT / 100,
        value: designModel[key]
      }))

      return {
        ...state,
        editor,
        verticalSliders,
        architectureSliders
      }
    }

    case DATA_GET_DESIGN_MODEL_STORIES_WITH_ESTIMATEID_SUCCESSFUL: {
      const { payload: { parsedResponse: { propertyNameValues, propertyMetas } } } = action
      return {
        ...state,
        horizontalSliders: propertyNameValues,
        horizontalSliderMetas: propertyMetas
      }
    }
    case DATA_GET_DESIGN_MODEL_STORIES_WITH_STORYID_SUCCESSFUL: {
      const { payload: { parsedResponse } } = action
      return { ...state, openStory: { ...parsedResponse } }
    }
    case UPDATE_SLIDER: {
      const { payload } = action
      const { id, value, sliderGroup } = payload
      const result = cloneDeep(state)
      const sliderNumber = findIndex(result[sliderGroup], { id })
      result[sliderGroup][sliderNumber].RatioSpaceArea = value

      return result
    }
    case UPDATE_VERTICAL_SLIDER: {
      const { payload } = action
      const { sliderId, height, value } = payload
      const result = cloneDeep(state)
      const sliderNumber = findIndex(result.verticalSliders, { id: sliderId })
      result.verticalSliders[sliderNumber].height = height
      result.verticalSliders[sliderNumber].value = value
      return result
    }
    case MODIFY_STORY_PROPERTY: {
      const { payload } = action
      const { propertyName, value } = payload

      const modifiedProperties = state.openStory.properties.map((property: Object) => {
        if (property.propertyName === propertyName) {
          property.modifiedValue = value
        }
        return property
      })

      return {
        ...state,
        openStory: {
          ...state.openStory,
          properties: modifiedProperties
        }
      }
    }

    case SET_UPDATE_PROTECTED_CIRCULATION: {
      const { payload: { value } } = action
      return {
        ...state,
        options: {
          ...state.options,
          updateProtectedCirculation: value

        }
      }
    }

    case DATA_GET_ESTIMATE_SETTINGS_WITH_ESTIMATEID_SUCCESSFUL: {
      const { payload: { parsedResponse } } = action
      // TIUU-3938 21.09.2020
      // pick only showStructures property from response because at the moment backend returns a lot of properties
      // that are redundant from UI perspective
      const showStructures = find(parsedResponse, (setting: Object) => setting.propertyName === 'ShowStructures')
      return {
        ...state,
        options: {
          ...state.options,
          showStructures: showStructures.value
        }
      }
    }

    case CLEAR_DESIGN_MODEL: {
      return initialState
    }

    default:
      return state
  }
}
