// @flow
// Copyright © 2010–2023 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.
/* eslint max-len: ["error", { "code": 350 }] */
import requestFn from './../requests/request'
import { API_BASE_URL } from './../../constants/envConstants'
import parserMapper from './../parserMapper'
import { getState } from '../../store'

export const basePath = '/spaces-api'

export const DATA_PATCH_SPACE_SCHEDULE_BATCHMOVE_WITH_SPACESCHEDULEITEMTYPE_START = 'DATA_PATCH_SPACE_SCHEDULE_BATCHMOVE_WITH_SPACESCHEDULEITEMTYPE_START'
export const DATA_PATCH_SPACE_SCHEDULE_BATCHMOVE_WITH_SPACESCHEDULEITEMTYPE_SUCCESSFUL = 'DATA_PATCH_SPACE_SCHEDULE_BATCHMOVE_WITH_SPACESCHEDULEITEMTYPE_SUCCESSFUL'
export const DATA_PATCH_SPACE_SCHEDULE_BATCHMOVE_WITH_SPACESCHEDULEITEMTYPE_FAIL = 'DATA_PATCH_SPACE_SCHEDULE_BATCHMOVE_WITH_SPACESCHEDULEITEMTYPE_FAIL'

type patchSpaceSchedulebatchMoveWithSpaceScheduleItemTypeRequestSpacesArguments = {
  path: {|
    'spaceScheduleItemType': string, // Space schedule item type.
  |},
  body: Object // An object with keys parentId and itemIds, which are used to move items that have matching ids listed in itemIds under the item with id of parentId
}

// Batch move space schedule items.
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/spaces.yaml#/SpaceScheduleApi/patch__estimateId__space_schedule__spaceScheduleItemType_:batchMove
export const patchSpaceSchedulebatchMoveWithSpaceScheduleItemTypeRequest = (
  requestArguments: patchSpaceSchedulebatchMoveWithSpaceScheduleItemTypeRequestSpacesArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_PATCH_SPACE_SCHEDULE_BATCHMOVE_WITH_SPACESCHEDULEITEMTYPE_START',
      successful: 'DATA_PATCH_SPACE_SCHEDULE_BATCHMOVE_WITH_SPACESCHEDULEITEMTYPE_SUCCESSFUL',
      fail: 'DATA_PATCH_SPACE_SCHEDULE_BATCHMOVE_WITH_SPACESCHEDULEITEMTYPE_FAIL'
    },
    embeddedType: null,
    fnName: 'patchSpaceSchedulebatchMoveWithSpaceScheduleItemTypeRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/spaces-api`,
    method: 'PATCH',
    url: `/space-schedule/${options?.estimateId || store.app.calculation}/${requestArguments.path['spaceScheduleItemType']}:batchMove`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: requestArguments.body
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'patchSpaceSchedulebatchMoveWithSpaceScheduleItemTypeRequest', '/spaces-api'),
    actions: {
      start: {
        type: DATA_PATCH_SPACE_SCHEDULE_BATCHMOVE_WITH_SPACESCHEDULEITEMTYPE_START, payload, config, meta
      },
      successful: {
        type: DATA_PATCH_SPACE_SCHEDULE_BATCHMOVE_WITH_SPACESCHEDULEITEMTYPE_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_PATCH_SPACE_SCHEDULE_BATCHMOVE_WITH_SPACESCHEDULEITEMTYPE_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}

export const DATA_PUT_IMPORT_WOP_REFERENCE_ESTIMATE_START = 'DATA_PUT_IMPORT_WOP_REFERENCE_ESTIMATE_START'
export const DATA_PUT_IMPORT_WOP_REFERENCE_ESTIMATE_SUCCESSFUL = 'DATA_PUT_IMPORT_WOP_REFERENCE_ESTIMATE_SUCCESSFUL'
export const DATA_PUT_IMPORT_WOP_REFERENCE_ESTIMATE_FAIL = 'DATA_PUT_IMPORT_WOP_REFERENCE_ESTIMATE_FAIL'

// Import reference wop estimate functional sectors, estimate properties etc. content to spaces estimate
export const importWopReferenceEstimateRequest = (
  payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_PUT_IMPORT_WOP_REFERENCE_ESTIMATE_START',
      successful: 'DATA_PUT_IMPORT_WOP_REFERENCE_ESTIMATE_SUCCESSFUL',
      fail: 'DATA_PUT_IMPORT_WOP_REFERENCE_ESTIMATE_FAIL'
    },
    embeddedType: null,
    fnName: 'importWopReferenceEstimateRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/spaces-api`,
    method: 'PUT',
    url: `/v2/wop-reference-estimate/${options?.estimateId || store.app.calculation}/import`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'importWopReferenceEstimateRequest', '/spaces-api'),
    actions: {
      start: {
        type: DATA_PUT_IMPORT_WOP_REFERENCE_ESTIMATE_START, payload, config, meta
      },
      successful: {
        type: DATA_PUT_IMPORT_WOP_REFERENCE_ESTIMATE_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_PUT_IMPORT_WOP_REFERENCE_ESTIMATE_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}

export const DATA_PATCH_BUILDING_PROPERTIES_START = 'DATA_PATCH_BUILDING_PROPERTIES_START'
export const DATA_PATCH_BUILDING_PROPERTIES_SUCCESSFUL = 'DATA_PATCH_BUILDING_PROPERTIES_SUCCESSFUL'
export const DATA_PATCH_BUILDING_PROPERTIES_FAIL = 'DATA_PATCH_BUILDING_PROPERTIES_FAIL'

// save changes in building properties widget
export const patchBuildingProperties = (
  payload: {
    body: Object
  },
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: DATA_PATCH_BUILDING_PROPERTIES_START,
      successful: DATA_PATCH_BUILDING_PROPERTIES_SUCCESSFUL,
      fail: DATA_PATCH_BUILDING_PROPERTIES_FAIL
    },
    embeddedType: null,
    fnName: 'patchBuildingProperties'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/spaces-api`,
    method: 'PATCH',
    url: `/v2/building-properties/${options?.estimateId || store.app.calculation}`,
    params: {
      spacesEstimateType: options?.spacesEstimateType || store.app.spacesEstimateType,
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: payload.body
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'patchBuildingProperties', '/spaces-api'),
    actions: {
      start: {
        type: DATA_PATCH_BUILDING_PROPERTIES_START, payload, config, meta
      },
      successful: {
        type: DATA_PATCH_BUILDING_PROPERTIES_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_PATCH_BUILDING_PROPERTIES_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}

export const DATA_PATCH_SPACE_PROPERTIES_START = 'DATA_PATCH_SPACE_PROPERTIES_START'
export const DATA_PATCH_SPACE_PROPERTIES_SUCCESSFUL = 'DATA_PATCH_SPACE_PROPERTIES_SUCCESSFUL'
export const DATA_PATCH_SPACE_PROPERTIES_FAIL = 'DATA_PATCH_SPACE_PROPERTIES_FAIL'

// save changes in spaces widget properties tab
export const patchSpacesProperties = (
  requestArguments: {
    body: {
      spaces?: Array<{
        id: string,
        spaceProperties: Object,
        spaceEngineeringSolutions: Object
      }>,
      resetProperties?: Array<string>,
    },
  },
  payload: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: DATA_PATCH_SPACE_PROPERTIES_START,
      successful: DATA_PATCH_SPACE_PROPERTIES_SUCCESSFUL,
      fail: DATA_PATCH_SPACE_PROPERTIES_FAIL
    },
    embeddedType: null,
    fnName: 'patchSpacesProperties'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/spaces-api`,
    method: 'PATCH',
    url: `/v3/space-schedule/${options?.estimateId || store.app.calculation}/spaces/properties`,
    params: {
      spacesEstimateType: options?.spacesEstimateType || store.app.spacesEstimateType,
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: requestArguments.body
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'patchSpacesProperties', '/spaces-api'),
    actions: {
      start: {
        type: DATA_PATCH_SPACE_PROPERTIES_START, payload, config, meta
      },
      successful: {
        type: DATA_PATCH_SPACE_PROPERTIES_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_PATCH_SPACE_PROPERTIES_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}

