// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.
import React, { Component } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import axios from 'axios'
import { map } from 'lodash'

import DropdownModal from '../../common/DropdownModal/DropdownModal'

import { getSelectedListItemsByType } from '../../../utils/listUtils'
import { FUNCTIONGROUP, PROCESSGROUP, PROCESS } from '../../../constants/contentTypes'
import {
  getGroupingScheduleFunctionGroupsCanMoveToWithItemIdRequestDefinitions,
  getGroupingScheduleProcessGroupsCanMoveToWithItemIdRequestDefinitions,
  getGroupingScheduleProcessesCanMoveToWithItemIdRequestDefinitions,
  getGroupingScheduleWithEstimateIdRequest,
  patchGroupingScheduleFunctionGroupsWithItemIdRequest,
  patchGroupingScheduleProcessGroupsWithItemIdRequest,
  patchGroupingScheduleProcessesWithItemIdRequest
} from '../../../utils/generated-api-requests/wop'
import { setCalculationActive } from '../../../actions/app'
import { closeModal } from '../../../actions/modals'
import { postPolling } from '../../../actions/postPolling'

type OwnProps = {|
  +listStoreId: string, // id that matches a lists key in Store
|}

type ReceivedProps = {|
  modalId: string, // id of the modal that can be found in Store
  listItem: TVDWOPListItem, // list item the modal was opened from
  listStoreId: string, // list store id for the grouping list that is to be refreshed after moving an item
  message: string, // message from contentProps of the modal
  showEmptyHeadings: boolean, // boolean to indicate if list should be requested with includeEmpty query param
  successCb: Function, // function to be called on GAR onSuccess callback
|}

type MappedProps = {|
  listItems: Array<TVDWOPListItem> // array of grouping listItems
|}

type DispatchProps = {|
  dispatchCloseModal: () => void, // closes the current modal by removing the configurations from Store
  dispatchSetCalculationActive: () => void, // sets manually the apps state as loading
  dispatchPostPolling: () => void, // initiates post polling
|}

type Props = {
  ...ReceivedProps,
  ...MappedProps,
  ...DispatchProps,
  t: Function, // translation function
}

export class WOPGroupingMoveModalContainer extends Component<Props> {
  getGroupingSchedule = () => {
    const {
      listStoreId,
      dispatchPostPolling,
      showEmptyHeadings,
      successCb
    } = this.props

    getGroupingScheduleWithEstimateIdRequest(
      { query: { listType: 'flat', includeEmpty: showEmptyHeadings } },
      { listStoreId, mergeOptions: { initialListItems: {}, unselectAllListItems: true } },
      () => {
        dispatchPostPolling()
        if (typeof successCb === 'function') successCb()
      }
    )
  }

  moveWOPListItem = (WOPListItemId: number) => {
    const { listItem, listItems, dispatchSetCalculationActive } = this.props
    const { type, wopItemId } = listItem

    let selectedItems = getSelectedListItemsByType(listItems, type)

    if (selectedItems.length === 0) {
      selectedItems = [{ wopItemId, type }]
    }

    dispatchSetCalculationActive()

    axios.all(map(selectedItems, (item: TVDWOPListItem) => {
      const requestArguments = [
        { path: { itemId: item.wopItemId }, body: { parentId: WOPListItemId } },
        {},
        () => { this.getGroupingSchedule() },
        null,
        { disableSetCalculationActiveAndComplete: true }
      ]
      switch (item.type.toUpperCase()) {
        case PROCESSGROUP: {
          patchGroupingScheduleProcessGroupsWithItemIdRequest(...requestArguments)
          break
        }
        case FUNCTIONGROUP: {
          patchGroupingScheduleFunctionGroupsWithItemIdRequest(...requestArguments)
          break
        }
        case PROCESS: {
          patchGroupingScheduleProcessesWithItemIdRequest(...requestArguments)
          break
        }
        default: {
          console.error(`No support for PATCH with type ${type}`)
        }
      }
    }))
  }

  getDropdownContentProps = () => {
    const { listItem, t, message } = this.props
    const { type } = listItem
    const typeUpperCase = type.toUpperCase()

    const commonProps = { message, title: t(`groupingWidget._MOVE_${typeUpperCase}`) }
    const requestDefinitionArguments = {
      requestArgs: { path: { itemId: listItem.wopItemId } },
    }
    switch (typeUpperCase) {
      case PROCESSGROUP: {
        return {
          ...commonProps,
          requestDefinition: getGroupingScheduleProcessGroupsCanMoveToWithItemIdRequestDefinitions(requestDefinitionArguments)
        }
      }
      case FUNCTIONGROUP: {
        return {
          ...commonProps,
          requestDefinition: getGroupingScheduleFunctionGroupsCanMoveToWithItemIdRequestDefinitions(requestDefinitionArguments)
        }
      }
      case PROCESS: {
        return {
          ...commonProps,
          requestDefinition: getGroupingScheduleProcessesCanMoveToWithItemIdRequestDefinitions(requestDefinitionArguments)
        }
      }
      default: {
        console.error(`No support for moving type ${type}`)
        return {}
      }
    }
  }

  render(): React$Element<any> {
    const {
      t,
      dispatchCloseModal,
    } = this.props

    return (
      <DropdownModal
        contentProps={this.getDropdownContentProps()}
        saveButtonText={t('buttons._MOVE_')}
        onSave={this.moveWOPListItem}
        onClose={dispatchCloseModal} />
    )
  }
}

type ReduxState = {|
  +list: Object // lists in Store
|}

const mapStateToProps = ({ list }: ReduxState, { listStoreId }: OwnProps): MappedProps => ({
  listItems: list[listStoreId].filteredListItems || list[listStoreId].listItems
})


const mapDispatchToProps = (dispatch: Function, { modalId }: ReceivedProps): DispatchProps => ({
  dispatchCloseModal: () => { dispatch(closeModal(modalId)) },
  dispatchSetCalculationActive: () => { dispatch(setCalculationActive()) },
  dispatchPostPolling: () => { dispatch(postPolling()) }
})

export default compose(
  withTranslation('translations'),
  connect(mapStateToProps, mapDispatchToProps),
)(WOPGroupingMoveModalContainer)
