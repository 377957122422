// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.
import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withTranslation } from 'react-i18next'
import { openModal } from '../../../actions/modals'
import { MODAL_TYPE_ACCESS_TOKEN } from '../../../constants/modalConstants'

type HOCProps = {|
  t: Function, // withTranslations translate function
|}

type DispatchProps = {|
  dispatchOpenModal: (content: Object) => void // opens a modal displaying access token expiration status and interactions
|}

type MappedProps = {|
  exp: $PropertyType<TVDUserAuthorization, 'exp'>
|}

type Props = {|
  ...HOCProps,
  ...DispatchProps,
  ...MappedProps,
|}

type State = {|
  intervalId?: IntervalID, // an interval id from setInterval that handles checking if access token is about be expired
  expMs?: number, // access token expiration time in milliseconds
|}

// issuing warning when there is the amount of milliseconds left in the token expiration
const ACCESS_TOKEN_EXPIRATION_THRESHOLD_SECONDS = 30
// milliseconds how often the expiration check interval is run
const INTERVAL_CHECK_MILLISECONDS = 1000
// The time in milliseconds in which tokens are expected to be
export const TVD_TOKEN_EXPIRATION_TIME_MS = (60 * 60) * 1000

export class AccessTokenTimer extends React.Component<Props, State> {
  state = {
    expMs: undefined,
    intervalId: undefined
  }

  componentDidMount = () => {
    this.setExpMillisecondsToState()
    this.createTimer()
  }

  componentDidUpdate = (prevProps: Props) => {
    const { exp } = this.props
    if (exp !== prevProps.exp) {
      this.removeTimer()
      this.setExpMillisecondsToState()
      this.createTimer()
    }
  }

  componentWillUnmount = () => {
    const { intervalId } = this.state
    if (intervalId) this.removeTimer()
  }

  getAccessTokenModalContent = (secondsUntillExpiration: number, title: string): {|
    type: typeof MODAL_TYPE_ACCESS_TOKEN,
    contentProps: Object,
    disableBackdropClick: true,
    disableEscapeKeyDown: true,
    title: string,
    index: number
  |} => ({
    title,
    type: MODAL_TYPE_ACCESS_TOKEN,
    disableBackdropClick: true,
    disableEscapeKeyDown: true,
    index: 999,
    contentProps: {
      secondsUntillExpiration
    }
  })

  getIsTimeMsEqualToExpirationWithThreshold = (currentTimeMs: number): boolean => {
    const { expMs } = this.state
    if (expMs) {
      const currentTimeSeconds = Math.floor((currentTimeMs / 1000) + ACCESS_TOKEN_EXPIRATION_THRESHOLD_SECONDS)
      const expirationTimeWithThresholdSeconds = Math.floor(expMs / 1000)
      return currentTimeSeconds === expirationTimeWithThresholdSeconds
    }
    return false
  }

  checkAccessTokenExpiration = () => {
    const { dispatchOpenModal, t } = this.props
    const { expMs } = this.state
    const currentTimeMs = new Date().getTime()

    switch (true) {
      case (!!expMs && (currentTimeMs >= expMs)): {
        this.removeTimer()
        dispatchOpenModal(this.getAccessTokenModalContent(0, t('accessToken._EXPIRED_')))
        break
      }
      case this.getIsTimeMsEqualToExpirationWithThreshold(currentTimeMs): {
        this.removeTimer()
        dispatchOpenModal(this.getAccessTokenModalContent(ACCESS_TOKEN_EXPIRATION_THRESHOLD_SECONDS, t('accessToken._ABOUT_TO_EXPIRE_')))
        break
      }
      default: {
        break
      }
    }
  }

  createTimer = () => {
    const intervalId = setInterval(this.checkAccessTokenExpiration, INTERVAL_CHECK_MILLISECONDS)
    this.setState({ intervalId })
  }

  removeTimer = () => {
    const { intervalId } = this.state
    clearInterval(intervalId)
    this.setState({ intervalId: undefined, expMs: undefined })
  }

  isExpirationTimeValid = (): boolean => {
    const { exp } = this.props
    try {
      const expMs = this.secondsToMilliseconds(exp)
      const currentMs = new Date().getTime()
      if ((expMs - currentMs) > TVD_TOKEN_EXPIRATION_TIME_MS) {
        throw new Error([
          'Failed to validate access token exp',
          `Expecting currentMs - expMs to be less than ${TVD_TOKEN_EXPIRATION_TIME_MS}.`,
          `Instead got expMs ${expMs} ${new Date(expMs).toISOString()}`,
          `and currentMs ${currentMs} ${new Date(currentMs).toISOString()}.`,
        ].join('\n'))
      }
    } catch (error) {
      console.error(error)
      return false
    }
    return true
  }

  secondsToMilliseconds = (seconds: number): number => seconds * 1000

  setExpMillisecondsToState = () => {
    const { exp } = this.props
    if (this.isExpirationTimeValid()) {
      this.setState({ expMs: this.secondsToMilliseconds(exp) })
    } else {
      const fallbackExpMs = new Date().getTime() + TVD_TOKEN_EXPIRATION_TIME_MS
      this.setState({ expMs: fallbackExpMs })
    }
  }

  render = (): null => null
}

const mapDispatchToProps = (dispatch: Function): DispatchProps => ({
  dispatchOpenModal: (content: Object) => {
    dispatch(openModal(content, MODAL_TYPE_ACCESS_TOKEN))
  }
})

const mapStateToProps = ({ user: { authorization: { exp } } }: TVDReduxStore): MappedProps => ({
  exp
})

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation('translations')
)(AccessTokenTimer)
