// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.
import React from 'react'
import { withRouter } from 'react-router-dom'
import { compose } from 'redux'
import { withStyles } from '@material-ui/core/styles'
import ModuleHeader from '../../common/ModuleHeader/ModuleHeader'
import ActivityStructureContainer from '../../containers/list/ActivityStructure/ActivityStructureContainer'
import { headerHeight } from '../../../components/common/Header/Header'

type HOCProps = {|
  classes: Object, // classes-object generated by withStyles function
|}

type Props = {|
  ...HOCProps
|}

const styles = ({ palette }: TVDTheme) => ({
  layout: {
    display: 'flex',
    flexDirection: 'row',
    margin: '24px 32px 40px'
  },
  root: {
    paddingTop: headerHeight,
    height: '100%',
    width: '100%',
    background: palette.gray20,
  },
  innerContainer: {
    background: palette.white,
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  }
})

export class Wop extends React.Component<Props> {
  render(): React$Element<any> | null {
    const { classes } = this.props
    return (
      <div className={classes.root}>
        <div className={classes.innerContainer}>
          <ModuleHeader />
          <ActivityStructureContainer />
        </div>
      </div>
    )
  }
}

export default compose(
  withRouter,
  withStyles(styles),
)(Wop)

