// @flow
// Copyright © 2010–2022 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.

import {
  TOGGLE_DRAWER,
  SET_CONTEXT_MENU,
  SET_MAIN_VIEW_ACTIVE_INDEX
} from '../actions/view/actionTypes'

const initialState: TVDViewStore = {
  isDrawerOpen: false,
  contextMenu: {},
  mainViewActiveIndex: null
}

export default function viewReducer(state: TVDViewStore = initialState, action: TVDAction): TVDViewStore {
  switch (action.type) {
    case TOGGLE_DRAWER: {
      const { payload: isDrawerOpen } = action
      return {
        ...state,
        isDrawerOpen,
      }
    }

    case SET_CONTEXT_MENU: {
      const { payload } = action
      return {
        ...state,
        contextMenu: payload || {},
      }
    }

    case SET_MAIN_VIEW_ACTIVE_INDEX: {
      const { payload } = action
      return {
        ...state,
        mainViewActiveIndex: payload
      }
    }

    default:
      return state
  }
}
