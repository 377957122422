// @flow
// Copyright © 2010–2023 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.

import * as React from 'react'
import Input from '@material-ui/core/Input'
import { withStyles } from '@material-ui/core'
import { colors, typographyClasses } from 'frontend-assets'
import Validator from '../../Validator/Validator'
import { dotsToCommas, commasToDots, roundToPrecision } from '../../../../utils/commonUtils'

export type UserInputProps = {|
  id: string, // for testing purposes
  onSave: (value: string | number) => {}, // used to save user input
  closeInput: () => {}, // callback for when the input closes
  initialValue: string, // what is shown in the input when it mounts
  dataType: string, // datatype for the input, determines validation
  disabled: boolean, // whether the component is disabled
  placeholder: String, // placeholder text
|}

const styles = ({ palette }: TVDTheme): Object => ({
  inputFieldUnderline: {
    '&:after': {
      bottom: '0px'
    }
  },
  input: {
    ...typographyClasses.bodyNumbers,
    padding: '0',
    marginRight: '2px',
    height: '30px',
    cursor: 'pointer',
    '&::selection': {
      background: colors.primary40
    }
  },
  inputField: {
    ...typographyClasses.bodyNumbers,
    maxWidth: '250px',
    width: '100%',
    minWidth: '25px',
    color: palette.black,
    backgroundColor: palette.gray20,
    height: '30px',
    whiteSpace: 'nowrap',
    '&:before': {
      borderBottom: '2px solid transparent'
    },
    '&:after': {
      borderBottom: `2px solid ${palette.primary60}`
    }
  },
  error: {
    '&:after': {
      borderBottomColor: `${palette.error100}!important`
    }
  }
})

type Props = {
  ...UserInputProps,
  required: boolean, // if the input is a required field
  classes: Object, // style object
  alignLeft: boolean, // align InputField text to the left side of the container
  validityChangeCb?: (boolean) => void, // optional callback when the validity of the input changes
  customValidator?: () => boolean // custom validator for input
}

type State = {
  value: number | string,
  isValid: boolean
}

export class UserInput extends React.Component<Props, State> {
  static defaultProps = {
    id: 'InputField',
    disabled: false,
    validityChangeCb: undefined,
    customValidator: undefined
  }
  state = {
    value: '',
    isValid: true
  }

  componentDidMount() { this.setState({ value: this.formatInputField(this.props.initialValue) }) }

  /** Selects all of the text in the input when it's focused. */
  handleFocus = (e: SyntheticInputEvent<any>) => e.target.select()

  /** Handler for preventing a click from propagating. */
  stopPropagation = (e: SyntheticEvent<any>) => e.stopPropagation()

  /** Handles user input by calling the onChange prop function with the current event value. */
  handleChange = (event: SyntheticInputEvent<any>) => {
    const { value } = event.target
    this.setValue(value)
  }

  /** Calls the onSave prop with the event value if it's valid. */
  handleOnBlur = () => { if (this.state.isValid) { this.props.onSave(this.getFormattedCallbackValue()) } else { this.props.closeInput() } }

  /** Calls the onSave with the event value if the event.key is Enter. */
  handleOnKeyDown = (event: SyntheticKeyboardEvent<any>) => {
    if (event.key === 'Enter') {
      if (this.state.isValid) this.props.onSave(this.getFormattedCallbackValue())
      this.props.closeInput()
    }
  }

  setValidity = (validity: boolean) => {
    const { validityChangeCb } = this.props
    if (validityChangeCb && this.state.isValid !== validity) {
      validityChangeCb(validity)
    }
    this.setState({ isValid: validity })
  }

  setValue = (value: string) => this.setState({ value })

  formatInputField = (inputValue: string | number) => {
    const { dataType, placeholder } = this.props
    const maximumAmountOfDigitsToShow = 4

    // without this check the number and integer fields will force a zero into the input
    // which will prevent a placeholder from showing and will force an onChange callback
    if (inputValue === '' && placeholder) return inputValue

    switch (dataType) {
      case 'number':
        return dotsToCommas(roundToPrecision(Number(commasToDots(inputValue)), maximumAmountOfDigitsToShow))
      case 'integer':
        return dotsToCommas(inputValue)
      case 'string':
      case 'year':
      default:
        return inputValue
    }
  }

  getFormattedCallbackValue = (): number | string => {
    const { dataType, placeholder } = this.props
    const { value } = this.state

    // nothing has been changed and there is a placeholder to be displayed so
    // casting to number through the switch case would force an empty string to be zero which will
    // prevent the placeholder from showing
    if (placeholder && value === '') return value

    switch (dataType) {
      case 'number':
        return Number(commasToDots(value))
      case 'integer':
        return Number(commasToDots(value))
      case 'year':
        return Number(value)
      case 'string':
      default:
        return value
    }
  }

  render(): React$Element<any> {
    const {
      classes,
      dataType,
      disabled,
      required,
      id,
      placeholder,
      alignLeft,
      customValidator
    } = this.props

    const textAlign = alignLeft
      ? { justifyContent: 'flex-start', textAlign: 'left' }
      : { justifyContent: 'flex-end', textAlign: 'right' }

    return (
      <Validator
        customValidator={customValidator}
        value={dataType === 'string' ? this.state.value.toString() : this.state.value}
        dataType={dataType}
        isValidCb={this.setValidity}
        disabled={disabled}
        required={required}
        ignoreCharacters={[',. ']}
        render={(isValid: boolean) => (
          <Input
            error={!isValid}
            disabled={disabled}
            placeholder={placeholder}
            inputProps={{
              className: classes.input,
              style: { ...textAlign },
              'data-testid': `${id.replace(/ /g, '_')}-UserInput`
            }}
            classes={{
              underline: classes.inputFieldUnderline,
              error: classes.error
            }}
            className={classes.inputField}
            autoFocus
            value={this.state.value}
            onClick={this.stopPropagation}
            onFocus={this.handleFocus}
            onBlur={this.handleOnBlur}
            onKeyDown={this.handleOnKeyDown}
            onChange={this.handleChange} />)
      } />
    )
  }
}

export default withStyles(styles)(UserInput)
