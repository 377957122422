// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withTranslation } from 'react-i18next'
import ConfirmationModal from '../../common/ConfirmationModal/ConfirmationModal'
import { patchDesignModelWithEstimateIdRequest } from '../../../utils/generated-api-requests/spaces'
import { postPolling } from '../../../actions/postPolling'
import { type PatchDesignModelQueryParams } from '../widgets/DesignModelWidget/DesignModelContainer'
import { closeModal } from '../../../actions/modals'

type HOCProps = {|
  t: Function, // translate function
  classes: Object, // withstyles classes object
|}

type ReceivedProps = {|
  designModelPatchQueryParams?: PatchDesignModelQueryParams,
  toggleValue: boolean, // if we are toggling on/off the mkassing performed by user
  modalId: string, // id of the modal
|}

type DispatchProps = {|
  dispatchPostPolling: () => void, // initiates post polling
  dispatchCloseModal: () => void, // closes the current modal with modalId from props
|}

type Props = {|
  ...DispatchProps,
  ...HOCProps,
  ...ReceivedProps
|}

type State = {|
|}


export class ExternalGeometryToggleModalContainer extends Component<Props, State> {
  static defaultProps = {
    designModelPatchQueryParams: {}
  }

  getMessage = (): Array<string> => {
    const { t, toggleValue } = this.props
    if (toggleValue) {
      return [
        t('externalGeometry._ABOUT_TO_CHANGE_TO_SELF_GEOMETRY_'),
        t('externalGeometry._CHANGE_FLOOR_GEOMETRY_TO_ACTIVATE_'),
        t('externalGeometry._WARNING_NO_CHANGES_TO_FLOOR_COUNT_'),
        t('externalGeometry._ARE_YOU_SURE_TO_CONTINUE_'),
      ]
    }
    return [
      t('externalGeometry._ABOUT_TO_CHANGE_FROM_SELF_GEOMETRY_'),
      t('externalGeometry._WARNING_YOU_LOSE_SELF_GEOMETRY_'),
      t('externalGeometry._COPY_TO_PRESERVE_GEOMETRY_'),
      t('externalGeometry._ARE_YOU_SURE_TO_CONTINUE_'),
    ]
  }

  toggle = () => {
    const {
      designModelPatchQueryParams,
      toggleValue,
      dispatchPostPolling
    } = this.props
    patchDesignModelWithEstimateIdRequest({
      body: { MassingPerformedByUser: toggleValue },
      query: designModelPatchQueryParams
    }, {}, () => { dispatchPostPolling() })
  }

  render = (): React$Element<typeof ConfirmationModal> => {
    const { dispatchCloseModal, t } = this.props
    return (
      <ConfirmationModal
        message={this.getMessage()}
        onClose={dispatchCloseModal}
        onSave={this.toggle}
        saveButtonText={t('buttons._CONTINUE_')} />
    )
  }
}

const mapDispatchToProps = (dispatch: Function, { modalId }: ReceivedProps): DispatchProps => ({
  dispatchPostPolling: () => { dispatch(postPolling()) },
  dispatchCloseModal: () => { dispatch(closeModal(modalId)) }
})


export default compose(
  connect(null, mapDispatchToProps),
  withTranslation('translations'),
)(ExternalGeometryToggleModalContainer)
