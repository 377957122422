// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.

import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'
import { compose } from 'redux'
import moment from 'moment'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import fiLocale from 'date-fns/locale/fi'
import enLocale from 'date-fns/locale/en-US'
import svLocale from 'date-fns/locale/sv'
import Validator, { type ValidatorInformation } from '../Validator/Validator'
import theme from '../../../styles/theme'

const styles = ({ palette }: TVDTheme): Object => ({
  error: {
    '&:after': {
      borderBottomColor: `${palette.error100}!important`
    }
  },
  cssFormLabelError: {
    '&$cssFormLabelError': {
      color: palette.error120
    }
  },
  cssFormLabelDisabled: {
    '&$cssFormLabelDisabled': {
      color: palette.gray100
    }
  },
  cssFormLabelFocused: {
    '&$cssFormLabelFocused': {
      color: palette.dark80
    }
  },
  cssFormLabelRoot: {
    ...theme.typography.classes.bodyBig,
    color: palette.dark80
  },
  cssRoot: {
    color: palette.dark60,
    ...theme.typography.classes.bodyBig,
    '&:hover': {
      backgroundColor: palette.gray20,
      color: palette.dark60,
    },
    '&:hover fieldset': {
      borderBottom: 'none'
    },
  },
  cssUnderline: {
    '&:before': {
      borderBottomColor: palette.gray80
    },
    '&:after': {
      borderBottomColor: palette.primary60,
    },
    '&&&&:hover:before': {
      borderBottom: 'none'
    },
  },
  cssFocused: {
    color: `${palette.black} !important`,
    '& [class*="Mui-focused"]': {
      color: palette.dark80,
      backgroundColor: palette.white,
    },
    '&:after': {
      borderBottom: `2px solid ${palette.primary60}`,
    },
  },
  cssDisabled: {
    color: palette.gray100,
    backgroundColor: palette.white,
    borderBottom: `1px dashed ${palette.gray80}`,
    '&[class*="Mui-disabled:before"]': {
      borderBottom: 'none',
    },
  },
  XS: {
    width: '70px'
  },
  S: {
    width: '110px'
  },
  M: {
    width: '150px'
  },
  L: {
    width: '200px'
  },
  XL: {
    width: '280px'
  },
  XXL: {
    width: '360px'
  },
  XXXL: {
    width: '420px'
  },
  FULL: {
    width: '100%'
  },
  dateInput: {
    color: palette.dark60,
    '&:hover': {
      backgroundColor: palette.gray20
    },
    '& button': {
      color: palette.gray120,
      '&:hover': {
        color: palette.primary100,
        backgroundColor: 'transparent'
      }
    },
    '& input': {
      ...theme.typography.classes.bodyBig,
      '&:hover': {
        color: palette.dark60,
      },
      '&:hover fieldset': {
        borderBottom: 'none'
      },
      '&:focused': {
        color: palette.black
      }
    },
    '& [class*="MuiInput-underline"]': {
      '&:before': {
        borderBottomColor: palette.gray80
      },
      '&:after': {
        borderBottomColor: palette.primary60,
      },
      '&:hover:before': {
        borderBottom: `1px solid ${palette.gray80} `
      },
    }
  },
  popoverRoot: {
    zIndex: '1400 !important',
    '& [class*="MuiPickersSlideTransition-transitionContainer"] > *': {
      ...theme.typography.classes.bodyBigBold,
      color: palette.dark80,
    },
    '& [class*="MuiPickersCalendarHeader-daysHeader"] > *': {
      ...theme.typography.classes.bodySmallBold,
      color: palette.dark40
    },
    '& [class*="MuiPickersCalendar-transitionContainer"] p': {
      ...theme.typography.classes.bodyDefaultBold,
      color: palette.dark80
    },
    '& [class*="MuiPickersDay-daySelected"]': {
      backgroundColor: palette.primary100,
      '& p': {
        color: palette.white,
      }
    },
    '& [class*="MuiPickersCalendar-week"] button': {
      '&:hover': {
        backgroundColor: palette.gray20
      }
    },
    '& [class*="MuiPickersCalendarHeader-iconButton"]': {
      color: palette.gray120,
      '&:hover': {
        backgroundColor: palette.gray20
      }
    }
  }
})

export type InputFieldSizes = 'XS' | 'S' | 'M' | 'L' | 'XL' | 'FULL'
type InputFieldTypes = 'text' | 'password' | 'date' | 'number' | 'email' | 'phone'

export type LabeledInputProps = {|
  // NOTE: "dataType: name" should only be used in case of a firstName or a lastName of a person or a name of a company.
  dataType: string, // input datatype eg. int | string | name | date
  value: string | number, // current user input in the input
  size: InputFieldSizes, // width of the input element
  handleChange?: Function, // callback function for when the input value changes
  disabled?: boolean, // if the data in component is considered as locked by API and are not to be edited
  customValidator?: (ValidatorInformation) => boolean, // optional validator used instead if provided
|}

type InputTypeProps = {|
  password: InputFieldTypes, // whether the input will obscure the input
  date: InputFieldTypes, // if the input should show a date
  number: InputFieldTypes, // if the input type should be a number
  email: InputFieldTypes, // if the input should show email
  phone: InputFieldTypes, // if the input type should be a phone number
|}

type OptionalProps = {|
  ...InputTypeProps,
  disableValidation: boolean, // boolean to disable validation, in filter inputs etc.
  ignoreCharacters: Array<string>, // characters to be ignored in validation
  isValidCb: (boolean) => void, // callback FN triggered after validation, for inputs' container to do external logic with input validity
  id: string, // id for testing
  adornment: string | React$Element<any>, // adornment to be placed at the end of the input
  required: boolean, // if the field is required to be filled
  label: string, // the label for the input,
  disabled: boolean, // is the input in a disabled state
  helperText: string, // text to be placed under the input line
  labelAtTop: boolean, // true: fix label to the top, false: normal label
  multiline: boolean, // if the field should support multiline input
  focused: boolean, // if the field should be focused on mount
  placeholder: string, // placeholder text
  onKeyDown: Function, // cb fn to handle onKeyDown event
  onBlur: Function, // cb fn to handle onBlur event
  disableUnderline: boolean, // should underline be disabled
  showError?: boolean, // flag to show error message or not
  languageCode?: string, // user selected language code
|}

type Props = {
  ...LabeledInputProps,
  ...OptionalProps,
  classes: Object,
  t: Function, // translation
}

export class LabeledInput extends Component<Props> {
  static defaultProps = {
    label: '',
    multiline: false,
    helperText: '',
    password: false,
    date: false,
    labelAtTop: false,
    required: false,
    size: 'L',
    disabled: false,
    handleChange: () => {},
    adornment: null,
    disableUnderline: false,
    showError: false
  }

  focusInputField = (input: Object) => {
    if (input) {
      setTimeout(() => {
        input.focus()
        input.select()
      }, 100)
    }
  }

  selectedLanguage = () => {
    switch (this.props.languageCode) {
      case 'fi':
        return fiLocale
      case 'sv':
        return svLocale
      case 'en':
        return enLocale
      default:
        return fiLocale
    }
  }

  determineType(): InputFieldTypes {
    const {
      password, date, number, email, phone
    } = this.props
    if (password) {
      return 'password'
    } else if (date) {
      return 'date'
    } else if (number) {
      return 'number'
    } else if (email) {
      return 'email'
    } else if (phone) {
      return 'phone'
    }
    return 'text'
  }

  render(): React$Element<any> {
    const {
      focused,
      classes,
      labelAtTop,
      adornment,
      value,
      helperText,
      handleChange,
      id,
      label,
      required,
      size,
      disabled,
      multiline,
      placeholder,
      onKeyDown,
      onBlur,
      dataType,
      disableValidation,
      ignoreCharacters,
      isValidCb,
      disableUnderline,
      showError,
      t,
      customValidator
    } = this.props

    const inputType = this.determineType()
    // MUI has a bug where if the shrink property exists on the inputlabel even in 'false' mode it will mess up
    // labels for shrink=false versions as well so it needs to not exist in false- state
    const labelProps: Object = (labelAtTop || inputType === 'date') ? { shrink: true } : {}
    const endAdornment = adornment !== null ? <InputAdornment position='end'>{adornment}</InputAdornment> : null

    if (inputType === 'date') {
      return (
        <div className={classes.dateInput}>
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={this.selectedLanguage()}>
            <KeyboardDatePicker
              disabled={disabled}
              PopoverProps={{
                classes: {
                  root: classes.popoverRoot,
                  focused: classes.cssFocused,
                  disabled: classes.cssDisabled,
                  underline: classes.cssUnderline
                }
              }}
              disableToolbar
              variant='inline'
              format='dd.MM.yyyy'
              value={value}
              autoOk
              helperText={showError && t('errors._NOT_FOUND_INDEX_POINT_')}
              error={showError}
              onChange={(selectedDate: string) => {
                if (handleChange) {
                  handleChange({ target: { id, value: moment(selectedDate).format('YYYY-MM-DD') } })
                }
              }} />
          </MuiPickersUtilsProvider>
        </div>
      )
    }

    return (
      // validator renderProp to handle input validity
      <Validator
        customValidator={customValidator}
        // $FlowFixMe: flow cannot determine type of spread LabeledInputProps
        value={value}
        // $FlowFixMe: flow cannot determine type of spread LabeledInputProps
        dataType={dataType}
        disableValidation={disableValidation}
        ignoreCharacters={ignoreCharacters}
        isValidCb={isValidCb}
        required={required}
        disabled={disabled}
        render={(isValid: boolean) => (
          <TextField
            inputRef={focused ? this.focusInputField : null}
            onKeyDown={onKeyDown}
            onBlur={onBlur}
            multiline={multiline}
            data-testid={id}
            rowsMax={3}
            required={required}
            label={label}
            id={id}
            className={classes[size]}
            value={value}
            onChange={handleChange}
            helperText={helperText}
            type={inputType}
            disabled={disabled}
            error={!isValid}
            placeholder={placeholder}
            InputLabelProps={{
              error: !isValid,
              ...labelProps,
              classes: {
                root: classes.cssFormLabelRoot,
                error: classes.cssFormLabelError,
                disabled: classes.cssFormLabelDisabled,
                focused: classes.cssFormLabelFocused
              }
            }}
            InputProps={{
              disableUnderline,
              endAdornment,
              inputProps: { 'data-testid': id },
              classes: {
                root: classes.cssRoot,
                focused: classes.cssFocused,
                disabled: classes.cssDisabled,
                underline: classes.cssUnderline,
                error: classes.error
              },
            }} />)} />
    )
  }
}

export default compose(
  withStyles(styles),
  withTranslation('translations')
)(LabeledInput)
