// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.

import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import Grid from '@material-ui/core/Grid/Grid'
import { useMutation, useQueryClient } from 'react-query'
import { TextButton } from 'frontend-components'
import LabeledInput from '../../../components/common/LabeledInput/LabeledInput'
import { renameRealEstate } from '../../common/queries/realEstateMutations'
import { commonIgnoredCharacters } from '../../../constants/validationConstants'
import { closeModal } from '../../../actions/modals'
import { RENAME_PROJECT } from '../../../constants/modalConstants'
import { setRealEstateLastUpdatedTS } from '../../../actions/app'

const useStyles = makeStyles(() => ({
  content: {
    display: 'flex',
    flex: 2,
    margin: '24px 0',
  }
}))

type Props = {|
  nameToRename: string, // name of the project to be renamed
  realEstateId: string, // realestate id for the api call
|}

const RenameProjectModal = (props: Props): React$Element<'div'> => {
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const queryClient = useQueryClient()
  const [newName, setNewName] = useState(props.nameToRename)
  const realEstateMutation = useMutation('datatableRows', () => renameRealEstate(props.realEstateId, newName), {
    onSuccess: () => {
      // refetch projectManagement datatable rows after successful renaming
      queryClient.invalidateQueries('datatableRows')
      dispatch(setRealEstateLastUpdatedTS(Date.now()))
    },
    onError: () => {
      console.log('Mutation error')
      console.log(props.realEstateId)
    }
  })

  const handleRename = () => {
    realEstateMutation.mutate()
    dispatch(closeModal(RENAME_PROJECT))
  }

  const inputProps: TVDLabeledInput = {
    value: newName,
    initialValue: props.nameToRename,
    handleChange: (event: SyntheticInputEvent<any>) => { setNewName(event.target.value) },
    dataType: 'string',
    size: 'XL',
    alignLeft: true,
    label: t('widgets._DESCRIPTION_'),
    focused: true,
    id: 'labeled-input',
    ignoreCharacters: commonIgnoredCharacters,
  }

  return (
    <div>
      <div className={classes.content}>
        <LabeledInput
          {...inputProps} />
      </div>
      <Grid
        container
        spacing={2}
        justify='flex-end'
        direction='row'>
        <Grid item><TextButton text={t('buttons._RENAME_')} variant='contained' id='btn-rename' onClick={handleRename} /></Grid>
        <Grid item><TextButton
          text={t('buttons._CANCEL_')}
          variant='text'
          id='cancel'
          onClick={() => {
            dispatch(closeModal(RENAME_PROJECT))
          }} />
        </Grid>
      </Grid>
    </div>
  )
}

export default RenameProjectModal
