// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.

import React from 'react'
import { withStyles } from '@material-ui/core'
import TableCell from '@material-ui/core/TableCell'
import { typographyClasses, colors } from 'frontend-assets'
import ListOpenerIcon from '../ListOpenerIcon/ListOpenerIcon'
import ListRow from '../ListRow/ListRow'
import { combineStyleClassNames } from '../../../../../utils/styleUtils'

type Props = {
  children: string | number, // content is required
  alignRight?: Boolean, // if true, content is aligned right
  openerArrow?: Boolean, // whether the cell should have the opener arrow icon
  classes: Object, // withStyles style object
  style?: Object,
  onContextMenu: () => void, // action to display rows contextmenu
  isFirstColumn: boolean, // boolean on if the column is considered the first
  isSelected: boolean, // indicates if row is selected
  id: string, // id for the component that is currently only used as testid
  cellClassName?: string, // additional withStyles generated style class for TableCell
  colSpan?: number, // optional value to span the cell multiple columns
}

const styles = (): Object => ({
  root: {
    ...typographyClasses.bodyDefault,
    position: 'relative', // anchors the position: 'absolute' ListOpenerIcon
    borderBottom: `1px solid ${colors.gray40}`,
    paddingRight: '4px !important',
    whiteSpace: 'nowrap',
    color: colors.dark80,
    padding: '0px',
  },
  isSelected: {
    // backgroundColor: palette.black => Not sure what this does at the moment, temporarily removed
    borderBottom: `1px dashed ${colors.dark80}`
  },
  cellStyles: {
    // allows description column to be shrunk narrower than the minimum width of its content
    width: '1px',
    minWidth: '1px',
    maxWidth: '1px',
  }
})

function ListRowCell(props: Props): React$Element<typeof ListRow> {
  const {
    children,
    classes,
    alignRight,
    openerArrow,
    onContextMenu,
    style,
    isFirstColumn,
    isSelected,
    id,
    cellClassName,
    colSpan
  } = props
  const alignment = alignRight ? 'right' : 'left'
  const cellStyles = isSelected
    ? `${classes.root} ${classes.isSelected} ${classes.cellStyles}`
    : `${classes.root} ${classes.cellStyles}`
  return (
    <TableCell
      data-testid={`listRowCell-${isFirstColumn ? 'first-column-' : ''}${id}`}
      style={style}
      variant='body'
      align={alignment}
      onContextMenu={onContextMenu}
      colSpan={colSpan}
      className={combineStyleClassNames(cellStyles, cellClassName)}>
      {openerArrow ? <ListOpenerIcon /> : null}
      {children}
    </TableCell>
  )
}

ListRowCell.defaultProps = {
  alignRight: false,
  openerArrow: false,
  style: {},
  isFirstColumn: false,
  contentStyles: {},
  cellClassName: '',
  colSpan: 1
}

export default withStyles(styles)(ListRowCell)
