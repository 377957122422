// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.
/* eslint max-len: ["error", { "code": 350 }] */

import requestFn from './../requests/request'
import { API_BASE_URL } from './../../constants/envConstants'
import parserMapper from './../parserMapper'
import { getState } from '../../store'

export const basePath = '/profitability-api'


export const DATA_GET_PROFITABILITY_SPACE_RESULTS_SCHEDULE_FUNCTIONAL_SECTORS_WITH_ESTIMATE_ID_START = 'DATA_GET_PROFITABILITY_SPACE_RESULTS_SCHEDULE_FUNCTIONAL_SECTORS_WITH_ESTIMATE_ID_START '
export const DATA_GET_PROFITABILITY_SPACE_RESULTS_SCHEDULE_FUNCTIONAL_SECTORS_WITH_ESTIMATE_ID_SUCCESSFUL = 'DATA_GET_PROFITABILITY_SPACE_RESULTS_SCHEDULE_FUNCTIONAL_SECTORS_WITH_ESTIMATE_ID_SUCCESSFUL'
export const DATA_GET_PROFITABILITY_SPACE_RESULTS_SCHEDULE_FUNCTIONAL_SECTORS_WITH_ESTIMATE_ID_FAIL = 'DATA_GET_PROFITABILITY_SPACE_RESULTS_SCHEDULE_FUNCTIONAL_SECTORS_WITH_ESTIMATE_ID_FAIL'

export const getProfitabilitySpaceResultsScheduleFunctionalSectorsFunctionsWithEstimateId = (
  requestArguments: Object,
  payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_PROFITABILITY_SPACE_SCHEDULE_WITH_ESTIMATE_ID_START',
      successful: 'DATA_GET_PROFITABILITY_SPACE_SCHEDULE_WITH_ESTIMATE_ID_SUCCESSFUL',
      fail: 'DATA_GET_PROFITABILITY_SPACE_SCHEDULE_WITH_ESTIMATE_ID_FAIL'
    },
    embeddedType: {},
    fnName: 'getProfitabilitySpaceResultsScheduleFunctionalSectorsFunctionsWithEstimateId'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}${basePath}`,
    url: `/space-results-schedule/${requestArguments.path?.estimateId}/functional-sectors`,
    method: 'GET',
    params: {
      accountId: options?.selectedAccountId || store.app.selectedAccountId,
      language: options?.languageCode || store.app.languageCode,
      listType: requestArguments.query.listType,
      view: requestArguments?.query?.view,
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      nonEmbedded: {
        schemaPath: '#/definitions/ScheduleItem',
        schemaKey: 'ScheduleItem'
      }
    }, 'getProfitabilitySpaceResultsScheduleFunctionalSectorsFunctionsWithEstimateId', basePath),
    actions: {
      start: {
        type: DATA_GET_PROFITABILITY_SPACE_RESULTS_SCHEDULE_FUNCTIONAL_SECTORS_WITH_ESTIMATE_ID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_PROFITABILITY_SPACE_RESULTS_SCHEDULE_FUNCTIONAL_SECTORS_WITH_ESTIMATE_ID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_PROFITABILITY_SPACE_RESULTS_SCHEDULE_FUNCTIONAL_SECTORS_WITH_ESTIMATE_ID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}

export const DATA_GET_PROFITABILITY_SPACE_SCHEDULE_KEY_FIGURES_WITH_ESTIMATE_ID_START = 'DATA_GET_PROFITABILITY_SPACE_SCHEDULE_KEY_FIGURES_WITH_ESTIMATE_ID_START'
export const DATA_GET_PROFITABILITY_SPACE_SCHEDULE_KEY_FIGURES_WITH_ESTIMATE_ID_SUCCESSFUL = 'DATA_GET_PROFITABILITY_SPACE_SCHEDULE_KEY_FIGURES_WITH_ESTIMATE_ID_SUCCESSFUL'
export const DATA_GET_PROFITABILITY_SPACE_SCHEDULE_KEY_FIGURES_WITH_ESTIMATE_ID_FAIL = 'DATA_GET_PROFITABILITY_SPACE_SCHEDULE_KEY_FIGURES_WITH_ESTIMATE_ID_FAIL'

export const getProfitabilitySpaceScheduleKeyFiguresWithEstimateId = (
  requestArguments: Object,
  payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_PROFITABILITY_SPACE_SCHEDULE_KEY_FIGURES_WITH_ESTIMATE_ID_START',
      successful: 'DATA_GET_PROFITABILITY_SPACE_SCHEDULE_KEY_FIGURES_WITH_ESTIMATE_ID_SUCCESSFUL',
      fail: 'DATA_GET_PROFITABILITY_SPACE_SCHEDULE_KEY_FIGURES_WITH_ESTIMATE_ID_FAIL'
    },
    embeddedType: {},
    fnName: 'getProfitabilitySpaceScheduleKeyFiguresWithEstimateId'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}${basePath}`,
    url: `/space-schedule/${requestArguments.path?.estimateId}/key-figures`,
    method: 'GET',
    params: {
      accountId: options?.selectedAccountId || store.app.selectedAccountId,
      language: options?.languageCode || store.app.languageCode,
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      nonEmbedded: {
        schemaPath: '#/definitions/ValueProperty',
        schemaKey: 'ValueProperty'
      }
    }, 'getProfitabilitySpaceScheduleKeyFiguresWithEstimateId', basePath),
    actions: {
      start: {
        type: DATA_GET_PROFITABILITY_SPACE_SCHEDULE_KEY_FIGURES_WITH_ESTIMATE_ID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_PROFITABILITY_SPACE_SCHEDULE_KEY_FIGURES_WITH_ESTIMATE_ID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_PROFITABILITY_SPACE_SCHEDULE_KEY_FIGURES_WITH_ESTIMATE_ID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}

export const DATA_GET_PROFITABILITY_SPACE_RESULTS_SCHEDULE_COLUMNS_WITH_ESTIMATE_ID_START = 'DATA_GET_PROFITABILITY_SPACE_RESULTS_SCHEDULE_COLUMNS_WITH_ESTIMATE_ID_START'
export const DATA_GET_PROFITABILITY_SPACE_RESULTS_SCHEDULE_COLUMNS_WITH_ESTIMATE_ID_SUCCESSFUL = 'DATA_GET_PROFITABILITY_SPACE_RESULTS_SCHEDULE_COLUMNS_WITH_ESTIMATE_ID_SUCCESSFUL'
export const DATA_GET_PROFITABILITY_SPACE_RESULTS_SCHEDULE_COLUMNS_WITH_ESTIMATE_ID_FAIL = 'DATA_GET_PROFITABILITY_SPACE_RESULTS_SCHEDULE_COLUMNS_WITH_ESTIMATE_ID_FAIL'

export const getProfitabilitySpaceResultsScheduleColumnsWithEstimateId = (
  requestArguments: Object,
  payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_PROFITABILITY_SPACE_RESULTS_SCHEDULE_COLUMNS_WITH_ESTIMATE_ID_START',
      successful: 'DATA_GET_PROFITABILITY_SPACE_RESULTS_SCHEDULE_COLUMNS_WITH_ESTIMATE_ID_SUCCESSFUL',
      fail: 'DATA_GET_PROFITABILITY_SPACE_RESULTS_SCHEDULE_COLUMNS_WITH_ESTIMATE_ID_FAIL'
    },
    embeddedType: {},
    fnName: 'getProfitabilitySpaceResultsScheduleColumnsWithEstimateId'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}${basePath}`,
    url: `/space-results-schedule/${requestArguments.path?.estimateId}/columns`,
    method: 'GET',
    params: {
      accountId: options?.selectedAccountId || store.app.selectedAccountId,
      language: options?.languageCode || store.app.languageCode,
      view: requestArguments?.query?.view,
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      nonEmbedded: {
        schemaPath: '#/definitions/ValueProperty',
        schemaKey: 'ValueProperty'
      }
    }, 'getProfitabilitySpaceResultsScheduleColumnsWithEstimateId', basePath),
    actions: {
      start: {
        type: DATA_GET_PROFITABILITY_SPACE_RESULTS_SCHEDULE_COLUMNS_WITH_ESTIMATE_ID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_PROFITABILITY_SPACE_RESULTS_SCHEDULE_COLUMNS_WITH_ESTIMATE_ID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_PROFITABILITY_SPACE_RESULTS_SCHEDULE_COLUMNS_WITH_ESTIMATE_ID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}

