// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { getEstimateHistoryObj, createQueryParamsString } from '../../../utils/urlUtil'
import {
  REAL_ESTATES, summaryUrl,
} from '../../../constants/moduleConstants'

export const useAppSelection = () => {
  const history = useHistory() // https://reactrouter.com/web/api/Hooks/usehistory

  const {
    app: {
      selectedAccountId,
      primaryEstimates,
      realEstateId,
      realEstateName
    }
  } = useSelector((state: TVDReduxStore): TVDReduxStore => state)

  const [
    selectedApp,
    setSelectedApp
  ] = useState<string>('')

  useEffect(() => {
    const primaryEstimate = primaryEstimates[selectedApp]
    if (!selectedApp) return
    if (selectedApp === REAL_ESTATES) {
      history.push({
        pathname: summaryUrl,
        search: createQueryParamsString({ accountId: selectedAccountId, realEstateId })
      })
    } else if (primaryEstimate) {
      history.push(getEstimateHistoryObj({
        app: selectedApp,
        realEstateName,
        accountId: selectedAccountId,
        estimateId: primaryEstimate,
        realEstateId
      }))
    } else {
      console.error(`Could not find primary estimate for ${selectedApp}`)
    }
  }, [
    selectedApp,
    history,
    selectedAccountId,
    primaryEstimates,
    realEstateId,
    realEstateName,
  ])

  return [setSelectedApp]
}
