// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.

import isNumeric from 'validator/lib/isNumeric'
import { UNIT_PRICE, QUANTITY } from '../constants/attributes'

export const validateAttribute = (attrKey: string, attrValue: number | string, validationErrorCb?: Function) => {
  switch (attrKey) {
    case UNIT_PRICE:
    case QUANTITY: {
      const value = (isNaN(attrValue) ? attrValue : attrValue.toString())
      const isValid = isNumeric(value.toString().replace(',', '.'))
      if (!isValid && validationErrorCb) {
        validationErrorCb(attrKey, attrValue)
      }
      return isValid
    }
    default:
      console.warn(`No validation for attribute key ${attrKey}`)
      return false
  }
}

// Counterpart to the frontend-utilities validateName function
// as modules are refactored to micro frontends this function can be dropped from being used
export const validateName = (value: string | number): boolean => value === 0 || !!(!!value && value.toString().trim().length)
