// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.

import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import FormHelperText from '@material-ui/core/FormHelperText'
import theme from '../../../../../styles/theme'

const styles = ({ palette }: TVDTheme) => ({
  root: {
    ...theme.typography.classes.bodySmall,
    color: palette.dark80
  },
  error: {
    '&$error': {
      color: palette.error100
    }
  }
})

type Props = {
  classes: Object, // withStyles object
  error: Boolean, // error state
  children: String, // the text in the helper
}

export class HelperText extends Component<Props> {
  render(): React$Element<any> {
    // MUI passes its own props that need to be spread here
    const {
      classes,
      error,
      ...otherProps
    } = this.props

    return (
      <FormHelperText
        {...otherProps}
        error={error}
        classes={{
          root: classes.root,
          error: classes.error
        }}>
        {this.props.children}
      </FormHelperText>
    )
  }
}

export default withStyles(styles)(HelperText)
