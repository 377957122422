// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.

import React, { Component } from 'react'
import { withStyles, Typography } from '@material-ui/core'
import { withTranslation } from 'react-i18next'

import ModalForm from '../../common/ModalForm/ModalForm'

import { CONFIRMATION_MODAL } from '../../../constants/contentTypes'
import { combineStyleClassNames } from '../../../utils/styleUtils'
import { type TVDButtonVariants } from '../TextButton/TextButton'

export const TVD_MESSAGE_VARIANT_DANGER: 'danger' = 'danger'

export type TVDMessage = {|
  text: string,
  // for new types, continue variant type as union
  variant?: typeof TVD_MESSAGE_VARIANT_DANGER
|}

type Props = {|
  classes: Object, // withStyles styles prop
  t: Function, // translation function
  saveButtonText?: string, // optional text for save button given as translateKey
  onSave: () => void, // onSave callback
  onClose: () => void, // close modal
  message: string | Array<string | TVDMessage>, // translate key for text body
  hideCancel: boolean,
  disableCloseOnSave?: boolean, // does not fire onClose from props if set true
  saveButtonVariant?: TVDButtonVariants, // if the save button is colored to alarm the user for making potentially dangerous action on pressing it
  amount?: number
|}

const styles = ({ palette, typography }: TVDTheme): Object => ({
  message: {
    padding: '0 0 18px'
  },
  messages: {
    color: palette.nevada,
    fontFamily: typography.fontFamily,
    fontSize: 16,
    width: 500
  },
  danger: {
    color: palette.danger
  },
})

export type TVDConfirmModalContent = {|
  saveButtonText?: $PropertyType<Props, 'saveButtonText'>,
  onSave: $PropertyType<Props, 'onSave'>,
  onClose: $PropertyType<Props, 'onClose'>,
  type: typeof CONFIRMATION_MODAL,
  message: $PropertyType<Props, 'message'>,
|}

export class ConfirmationModal extends Component<Props> {
  static defaultProps = {
    disableCloseOnSave: false,
    saveButtonText: undefined,
    hideCancel: false,
    saveButtonVariant: 'contained'
  }

  parseMessageObject = (messageObj: TVDMessage): typeof Typography => {
    const { t, classes } = this.props
    const { text, variant } = messageObj

    const classNames = combineStyleClassNames(classes.messages, (variant === TVD_MESSAGE_VARIANT_DANGER) && classes.danger)
    return <Typography key={text} className={classNames}>{t(text)}</Typography>
  }

  getMessage = (): Array<typeof Typography> => {
    const {
      t,
      classes,
      message,
      amount
    } = this.props
    if (typeof message === 'string') {
      return [<Typography className={`${classes.message} ${classes.messages}`}>{t(message, { amount })}</Typography>]
    }

    if (Array.isArray(message)) {
      return message.map((messageContent: string | TVDMessage) => {
        if (React.isValidElement(messageContent)) return messageContent
        if (typeof messageContent === 'object') return this.parseMessageObject(messageContent)
        return (
          <Typography key={messageContent} className={classes.messages}>
            {t(messageContent)}
          </Typography>
        )
      })
    }
    // keep object check after array check or else arrays will go to the following case
    if (typeof message === 'object' && message !== null) {
      return [this.parseMessageObject(message)]
    }
    console.error(`No supported message typeof found for ${typeof message}`)
    return []
  }

  handleSave() {
    const { onSave, disableCloseOnSave, onClose } = this.props
    if (onSave) onSave()
    if (!disableCloseOnSave && onClose) onClose()
  }

  render(): React$Element<any> {
    const { saveButtonText, saveButtonVariant } = this.props
    return (
      <ModalForm
        hideCancel={this.props.hideCancel}
        testId='ConfirmationModal'
        items={this.getMessage()}
        valid
        onSave={() => this.handleSave()}
        onClose={() => this.props.onClose()}
        saveButtonVariant={saveButtonVariant}
        saveButtonText={saveButtonText} />
    )
  }
}

export default withTranslation('translations')(withStyles(styles)(ConfirmationModal))
