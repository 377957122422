// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.

import {
  BUILDING_ELEMENTS_TASK,
  CALCULATION_PROPERTIES,
  PLANNING,
  CREATE_OTHER_SPACES,
  CREATE_CIRCULATION,
  CREATE_FACILITY_SERVICES,
  CREATE_TECHNICAL_BUILDING_SERVICES,
  CREATE_PROTECTED_CIRCULATION,
  BUILDING_ELEMENTS_SCHEDULE,
  CREATE_ACTION_SPACES,
  WOP_SPACE_SCHEDULE,
  CARBON_FOOTPRINT,
  SHOW_RESULTS
} from './contentTypes'

export type WidgetKeys = {
  groupLabel: string,
  widgets: Array<string>
}

export const IMPORT_FROM_WOP_CALCULATION = 'IMPORT_FROM_WOP_CALCULATION'
export const IMPORT_FROM_ANOTHER_ESTIMATE = 'IMPORT_FROM_ANOTHER_ESTIMATE'
export const CREATE_FROM_REGISTRY = 'CREATE_FROM_REGISTRY'
export const OTHER_SPACES = 'OTHER_SPACES'
export const UPDATE_TECHNICAL_BUILDING_SERVICES = 'UPDATE_TECHNICAL_BUILDING_SERVICES'
export const TECHNICAL_BUILDING_SERVICES_TOGGLE = 'TECHNICAL_BUILDING_SERVICES_TOGGLE'
export const USE_DESIGN_MODEL_DEFAULTS = 'USE_DESIGN_MODEL_DEFAULTS'
export const UPDATE_PROTECTED_CIRCULATION = 'UPDATE_PROTECTED_CIRCULATION'
export const UPDATE_STRUCTURES = 'UPDATE_STRUCTURES'
export const CREATE_ACTIVITY = 'CREATE_ACTIVITY'
export const GROUPING_VIEW = 'GROUPING_VIEW'
export const REPORTS_AND_LISTS = 'REPORTS_AND_LISTS'
export const RENOVATION = 'RENOVATION'
export const SPECIFY_TARGET_DETAILS = 'SPECIFY_TARGET_DETAILS'
export const INSPECT_RESULT = 'INSPECT_RESULT'
export const CREATE_REPORT = 'CREATE_REPORT'
export const CREATE_WOP_REPORT = 'CREATE_WOP_REPORT'
export const GENERAL_FEATURES = 'GENERAL_FEATURES'
export const GENERAL_FEATURES_WOP = 'GENERAL_FEATURES_WOP'
export const NEW_CREATE_FROM_PRICING = 'NEW_CREATE_FROM_PRICING'

// SPACES APP
export const actionSpaces: WidgetKeys = {
  groupLabel: CREATE_ACTION_SPACES,
  widgets: [
    IMPORT_FROM_WOP_CALCULATION,
    IMPORT_FROM_ANOTHER_ESTIMATE,
    CREATE_FROM_REGISTRY,
  ]
}

export const otherSpaces: WidgetKeys = {
  groupLabel: OTHER_SPACES,
  widgets: [
    CREATE_OTHER_SPACES,
    CREATE_CIRCULATION,
    CREATE_FACILITY_SERVICES,
    CREATE_TECHNICAL_BUILDING_SERVICES,
    CREATE_PROTECTED_CIRCULATION
  ]
}

export const generalFeatures: WidgetKeys = {
  groupLabel: GENERAL_FEATURES,
  widgets: [
    CREATE_REPORT
  ]
}

export const targetProperties: WidgetKeys = {
  groupLabel: SPECIFY_TARGET_DETAILS,
  widgets: [
    CALCULATION_PROPERTIES,
    PLANNING,
  ]
}

export const showBuildingElements = {
  widget: BUILDING_ELEMENTS_SCHEDULE
}

export const renovation = {
  widget: RENOVATION
}

// WOP APP
export const generalFeaturesWOP: WidgetKeys = {
  groupLabel: GENERAL_FEATURES_WOP,
  widgets: [
    CREATE_WOP_REPORT
  ]
}

export const elementTask = {
  widget: BUILDING_ELEMENTS_TASK,
}

export const reportsAndLists = {
  widgets: WOP_SPACE_SCHEDULE
}

export const showResults: WidgetKeys = {
  groupLabel: SHOW_RESULTS,
  widgets: [
    BUILDING_ELEMENTS_SCHEDULE,
    CARBON_FOOTPRINT,
  ]
}

