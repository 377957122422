// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.

import React from 'react'
import { withStyles } from '@material-ui/core'
import OverflowTooltip from '../../../OverflowTooltip/OverflowTooltip'
import { combineStyleClassNames } from '../../../../../utils/styleUtils'
import Tooltip from '../../../Tooltip/Tooltip'

const styles = ({ palette }: TVDTheme) => ({
  description: {
    width: '100%',
    overflow: 'hidden',
    color: palette.dark80,
    outline: 'none',
  },
  highlighted: {
    color: palette.primary100,
    cursor: 'pointer'
  },
  noPointer: {
    cursor: 'initial'
  },
  bold: {
    fontWeight: 'bold'
  },
  innerWrapper: {
    display: 'flex'
  },
  disabled: {
    color: palette.manatee,
    '&:hover': {
      cursor: 'initial'
    }
  },
})

type Props = {|
  classes: Object, // MUI classes object
  text: string, // text content of DescriptionCell
  highlighted?: boolean, // should descriptionCell have highlighted style
  testId?: string, // data-testid if provided,
  marginLeft?: string, // the margin left of the component
  paddingLeft?: string, // the margin left of the component
  onClick?: Function, // optional onClick handler for description cell
  noPointer?: boolean, // if we overwrite the default pointer cursor pointer style
  fontSize?: string, // optional font size for the element
  tooltipText?: string, // optional to show tooltipText
  bold?: boolean, // optional to set the text content bolded
  adornment?: React$Element<any> | string | number,
  disabled?: boolean, // flag that the table can be edited or not
|}

export class DescriptionCell extends React.Component<Props> {
  static defaultProps = {
    testId: 'Description-text',
    marginLeft: '0px',
    paddingLeft: '0px',
    onClick: null,
    highlighted: false,
    noPointer: false,
    fontSize: null,
    tooltipText: '',
    bold: false,
    adornment: null,
    disabled: false,
    hasDropdownMenu: false
  }

  getCellStyles(): string {
    const {
      classes,
      highlighted,
      noPointer,
      bold,
      disabled
    } = this.props
    return combineStyleClassNames(
      classes.description,
      highlighted && classes.highlighted,
      noPointer && classes.noPointer,
      bold && classes.bold,
      disabled && classes.disabled
    )
  }

  render(): React$Element<'div'> {
    const {
      testId,
      text,
      marginLeft,
      onClick,
      fontSize,
      tooltipText,
      adornment,
      classes,
      paddingLeft,
      disabled
    } = this.props
    return (
      <div
        tabIndex={0}
        role='button'
        onClick={onClick}
        data-testid={testId}
        className={this.getCellStyles()}
        style={{ marginLeft, fontSize, paddingLeft }}
        disabled={disabled}>
        <div className={classes.innerWrapper}>
          {tooltipText ? <Tooltip text={tooltipText}><span>{text}</span></Tooltip> :
          <OverflowTooltip cursorPointer tooltipText={text}>{text}</OverflowTooltip>}
          {adornment}
        </div>
      </div>
    )
  }
}

export default withStyles(styles)(DescriptionCell)
