// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.
// @flow
import React, { Component } from 'react'
import { TextButton } from 'frontend-components'
import { type TextButtonProps } from '../../TextButton/TextButton'

export type FormContent = {|
  formName: string, // identifies this for the radio buttons in the parent FloatingForm component
  formLabel: string, // label for the form, used in the parent FloatingForm component to create a radio button
  onSave: (formName: string) => {}, // function fired when the save button is pressed, with formName as an argument
  addButtonLabel: string, // label for the save button
|}

type Props = {|
  ...FormContent,
  disabled: boolean, // disables the save button and passes the disabled prop to any child components
  saveDisabled: boolean, // disables the save button exclusively
  children: any, // Components to show in the  form.
|}

type State = {|
  formValid: boolean // whether the form is in a valid state - disables the addButton if not
|}

export class Form extends Component<Props, State> {
  static defaultProps = {
    disabled: false
  }

  state = { formValid: true }

  get addButton(): React$Element<any> {
    const { disabled, saveDisabled } = this.props

    const buttonProps: TextButtonProps = {
      text: this.props.addButtonLabel,
      onClick: this.handleSave,
      disabled: disabled || saveDisabled || !this.state.formValid,
      id: disabled || saveDisabled ? 'createButton-disabled' : 'createButton'
    }

    return (
      <div style={{ margin: 'auto 0 0 auto', paddingBottom: 16 }}>
        <TextButton {...buttonProps} />
      </div>
    )
  }

  setValidity = (formValid: boolean) => this.setState({ formValid })

  handleSave = () => this.props.onSave(this.props.formName)

  render(): React$Element<any> {
    const { children } = this.props

    // removes null and non-react component values from the children prop
    // a precausion for conditional renders in parent components: (ifSomething ? <LabeledInput /> : null)
    const kids = React.Children.toArray(children)

    return (
      <div style={{ display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          overflow: 'auto',
          marginBottom: '5px'
          }}>
          { React.Children.map(kids, (child: Object) => (
            <div style={{ display: 'flex', margin: '10px 0' }}>
              { React.cloneElement(child, {
                isValidCb: this.setValidity,
                }) }
            </div>
          ))}
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
          { this.addButton }
        </div>
      </div>
    )
  }
}

export default Form

