// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.

import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { boxShadows, } from 'frontend-assets'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import ListSubheader from '@material-ui/core/ListSubheader'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import DropdownInput, { type AlignInputText } from '../DropdownInput/DropdownInput'
import DropdownLabel from '../DropdownLabel/DropdownLabel'
import HelperText from '../HelperText/HelperText'
import MenuItem from '../MenuItems/MenuItem/MenuItem'
import { combineStyleClassNames } from '../../../../../utils/styleUtils'
import OverflowTooltip from '../../../OverflowTooltip/OverflowTooltip'
import { SELECTALLVALUE } from '../../DropdownMultipleSelectMenu/DropdownMultipleSelectMenu'

const { boxShadowComponent } = boxShadows

export const dropdownWidths = {
  FULL: {
    width: '100%'
  },
  SIZE50: {
    width: '50px'
  },
  XS: {
    width: '70px'
  },
  S: {
    width: '110px'
  },
  M: {
    width: '150px'
  },
  L: {
    width: '200px'
  },
  XL: {
    width: '280px'
  },
  XXL: {
    width: '360px'
  },
  XXXL: {
    width: '420px'
  }
}

const styles = ({ palette }: Object): Object => ({
  menu: {
    maxWidth: '420px',
    paddingRight: '0 !important'
  },
  minimalistSelect: {
    paddingLeft: '4px',
    '&:focus': {
      backgroundColor: 'unset'
    }
  },
  errorRoot: {
    // without this the warning icon will push the down arrow out of the select when there are multiple selections
    width: '77%'
  },
  select: {
    '&:after': {
      color: palette.black
    },
    '&:focus': {
      backgroundColor: palette.white,
      color: palette.black
    }
  },
  menuPaper: {
    boxShadow: boxShadowComponent,
    borderRadius: '2px',
    maxHeight: '100%'
  },
  ...dropdownWidths,
  icon: {
    color: palette.dark60,
  },
  fullHeight: {
    height: '100%',
    flexDirection: 'column',
    boxSizing: 'border-box',
    flexGrow: '1',
    paddingRight: '0px !important' // important due to MuiSelect-select.MuiSelect-select giving padding right
  },
  centerContent: {
    padding: '0px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%'
  },
  formControl: {
    width: '100%'
  },
  hidden: {
    visibility: 'hidden'
  },
})

export type DropdownMenuWidths = 'XS' | 'S' | 'M' | 'L' | 'XL' | 'XXL' | 'XXXL' | 'SIZE50' | 'FULL'

export type DropdownMenuCoreProps = {|
  onChange: (selectionValue: string) => void, // function that is dispatched upon dropdown change event
  selected: Array<string> | string, // array of selected menu items or a single menu item
  id: string, // used to build automation test ids
  title?: string, // dropdown's InputLabel component content
  disabled?: boolean, // boolean to disable dropdown
  disabledTitle?: string, // title to show on a disabled menu
  required?: boolean, // boolean to show required asterisk
  error?: boolean, // sets error -state for the component
  width?: DropdownMenuWidths, // one of set widths available for dropdowns
  subheader?: React$Element<ListSubheader>, // subheader for the component
  minimalist?: boolean, // whether to use a minimalist version of the dropdownInput, mostly for datatables
  helperText?: string, // text to be displayed under the select
  fullHeight?: boolean, // set 100% height for the component
  noSelectIcon?: boolean, // don't display select icon caret
  alignInputText?: AlignInputText, // optional setting to align input text to left or right
  fullWidth?: boolean, // optional to toggle 100% width of the form control. Defaults to true
  hideArrow?: boolean, // whether to show dropdown arrow icon in the dropdownInput or not
|}

type Props = {
  ...DropdownMenuCoreProps,
  children: Array<MenuItem>, // Array of menu items as children
  classes: Object, // classes object created by withStyles function
  theme: Object, // material-ui theme object
  multiple?: boolean, // defines if multiple options can be selected from the menu
  selectAllItemLabel: ?string, // if null a selectAll item won't be rendered
  items: Array<Object>, // items for the menu
  localizedNameParser: Function, // runs localizedValue through this parser function
  onDropdownOpen?: (event: SyntheticEvent<any>) => void, // optional callback for when the dropdown is opened
  preventTooltip: boolean, // should the tooltip be prevented (if example handled higher in the component tree)
  renderEmpty: boolean, // if dropdown should render an empty value (despite having a valid data as selected value)
}

type TVDDropDownIconProps = {| className: string, hideArrow?: boolean|}
type TVDSelectStyleClasses = {| root: string, select: string, icon: string |}

export class DropdownMenuCore extends Component<Props> {
  static defaultProps = {
    zIndex: null,
    multiple: false,
    disabled: false,
    disabledTitle: '',
    error: false,
    subheader: null,
    width: 'M',
    id: 'DropdownMenu',
    title: '',
    minimalist: false,
    fullHeight: false,
    noSelectIcon: false,
    items: [],
    alignInputText: '',
    onDropdownOpen: null,
    fullWidth: true,
    hideArrow: false
  }

  handleSelect = (event: SyntheticInputEvent<any>) => {
    this.props.onChange(event.target.value)
  }

  findMenuItemByValue = (itemValue: string): TVDMenuItem => this.props.items.find((item: Object) => item.value === itemValue)

  getListOfRenderValues = (value: Array<string>): string => {
    const { selectAllItemLabel } = this.props
    if (value.includes(SELECTALLVALUE)) return selectAllItemLabel || ''

    return value.map((valueKey: string): string =>
      (this.findMenuItemByValue(valueKey) ? this.findMenuItemByValue(valueKey).localizedName : valueKey)).join(', ')
  }

  getSingleRenderValue = (value: string): React$Element<any> | string => {
    const { renderEmpty, localizedNameParser, preventTooltip } = this.props
    const menuItem = this.findMenuItemByValue(value)
    let renderValue
    let tooltipText = menuItem ? menuItem.localizedName : value
    switch (true) {
      case renderEmpty: {
        renderValue = ''
        break
      }
      case !!(menuItem && menuItem.localizedNameSelected): {
        const { localizedNameSelected } = menuItem
        renderValue = localizedNameSelected
        tooltipText = localizedNameSelected
        break
      }
      case !!localizedNameParser: {
        const localizedText = menuItem ? menuItem.localizedName : value
        renderValue = localizedNameParser(localizedText)
        break
      }
      case !!(menuItem && menuItem.localizedName):
        renderValue = menuItem.localizedName
        break
      default: {
        renderValue = value
        break
      }
    }
    if (preventTooltip) return renderValue
    return (
      <OverflowTooltip tooltipText={tooltipText} renderAlways={localizedNameParser} >
        { renderValue }
      </OverflowTooltip>
    )
  }

  title = (): string => {
    const { title = '', disabledTitle = '' } = this.props
    if (this.props.disabled && disabledTitle) return disabledTitle
    return title
  }

  static getDropDownIcon({ className, hideArrow }: TVDDropDownIconProps): React$Element<ArrowDropDownIcon> | null {
    if (hideArrow) return null
    return <ArrowDropDownIcon className={className} data-visible_on_hover_id='hoveringMenuCell' />
  }

  getRenderValue(value: string): React$Element<any> | string {
    const { items } = this.props
    if (!items) return value
    return Array.isArray(value) ? this.getListOfRenderValues(value) : this.getSingleRenderValue(value)
  }

  getSelectStyleClasses(): TVDSelectStyleClasses {
    const {
      classes,
      minimalist,
      fullHeight,
      noSelectIcon,
      error
    } = this.props
    return {
      root: combineStyleClassNames(error && classes.errorRoot, fullHeight && classes.fullHeight, noSelectIcon && classes.centerContent),
      select: combineStyleClassNames(
        minimalist ? classes.minimalistSelect : classes.select,
        fullHeight && classes.fullHeight,
        noSelectIcon && classes.centerContent
      ),
      icon: combineStyleClassNames(classes.icon, noSelectIcon && classes.hidden),
    }
  }

  render(): React$Element<any> {
    const {
      id,
      disabled,
      classes,
      required,
      multiple,
      error,
      selected,
      subheader,
      width,
      children,
      minimalist,
      theme: { zIndex },
      fullHeight,
      alignInputText,
      onDropdownOpen,
      fullWidth,
      hideArrow
    } = this.props
    const placeholderMode = (selected.length === 1 && selected.includes('placeholder')) || selected === 'placeholder'

    // renderValue prop overrides normal selection mechanics and prevents placeholders from working correctly - they can't be used at the same time
    const renderValue = placeholderMode ? null : { renderValue: (value: string) => this.getRenderValue(value) }

    return (
      <FormControl
        onClick={(ev: SyntheticEvent<any>) => ev.stopPropagation()}
        required={required}
        className={combineStyleClassNames(fullWidth && classes.formControl, fullHeight && classes.fullHeight)}
        data-testid={id}
        disabled={disabled}>
        { minimalist ? null : <DropdownLabel error={error} htmlFor={id}>{this.title()}</DropdownLabel> }

        <Select
          onOpen={onDropdownOpen}
          IconComponent={(dropDownIconProps: TVDDropDownIconProps) => DropdownMenuCore.getDropDownIcon({ ...dropDownIconProps, hideArrow })}
          data-testid={id}
          multiple={multiple}
          onChange={this.handleSelect}
          value={selected}
          {...renderValue}
          MenuProps={{
            style: { zIndex: zIndex.topMost, maxHeight: fullHeight ? '100%' : '305px' },
            getContentAnchorEl: null,
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            PopoverClasses: { paper: classes.menuPaper },
            MenuListProps: {
              classes: { root: classes.menu },
              subheader
            }
          }}
          input={
            <DropdownInput
              alignInputText={alignInputText}
              id='select'
              fullHeight={fullHeight}
              minimalist={minimalist}
              error={error}
              placeholderMode={placeholderMode} />
          }
          className={combineStyleClassNames(classes[width], fullHeight && classes.fullHeight)}
          classes={this.getSelectStyleClasses()}>
          { children }
        </Select>
        { this.props.helperText && !minimalist ? <HelperText error={error}>{this.props.helperText}</HelperText> : null }
      </FormControl>
    )
  }
}

export default withStyles(styles, { withTheme: true })(DropdownMenuCore)
