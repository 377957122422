// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.
import React from 'react'
import { withStyles } from '@material-ui/core'
import { withTranslation } from 'react-i18next'
import { map, split, isEqual } from 'lodash'
import { compose } from 'redux'
import { connect } from 'react-redux'

import ModalForm from '../../common/ModalForm/ModalForm'
import LabeledInput from '../../common/LabeledInput/LabeledInput'
import Spinner from '../../common/Spinner/Spinner'
import { deleteUsersLockWithIdRequest, patchUsersWithIdRequest, putUsersLockWithIdRequest } from '../../../utils/generated-api-requests/users'
import { getUsersAPIBasePath } from '../../../utils/apiUtils'
import theme from '../../../styles/theme'

const styles = ({ palette }: TVDTheme) => ({
  profileWrapper: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0 32px',
    margin: '20px 0',
    width: '100%',
    flex: 1
  },
  userInfoTitle: {
    ...theme.typography.classes.bodyBigBold,
    color: palette.dark80
  },
  input: {
    marginTop: '20px'
  },
  modalFormWrapper: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  }
})

type MappedProps = {|
  userType: $PropertyType<TVDApplicationStore, 'userType'>,
|}

type Props = {|
  ...MappedProps,
  t: Function, // translate
  classes: Object, // withStyles classes object
  user: Object, // user information object
  onClose: Function, // closes the modal
  confirm: Function, // builds confirmation modal when executed
  refresh: Function, // refresh form information callback
|}

type State = {|
  firstName: string,
  firstNameValid: boolean,
  lastName: string,
  lastNameValid: boolean,
  email: string,
  emailValid: boolean,
  phone: string,
  phoneValid: boolean,
  modified: boolean, // if form has been modified
  isResourceLocked: boolean // boolean for tracking that request to lock user resource has been completed and user data can be patched
|}

export class Profile extends React.Component<Props, State> {
  state = {
    firstName: this.props.user.firstName,
    firstNameValid: true,
    lastName: this.props.user.lastName,
    lastNameValid: true,
    email: this.props.user.email,
    emailValid: true,
    phone: this.props.user.phone,
    phoneValid: true,
    modified: false,
    isResourceLocked: false
  }

  componentDidMount() {
    // add eventListener to beforeunload event, to delete user resource lock if page is refreshed
    // so the user is not locked out of the system
    window.addEventListener('beforeunload', (): void => {
      deleteUsersLockWithIdRequest(
        { path: { id: this.props.user.id } },
        {},
        null,
        null,
        this.getUsersAPIOptions()
      )
    })
    putUsersLockWithIdRequest(
      { path: { id: this.props.user.id }, body: {} },
      {}, () => { this.setState({ isResourceLocked: true }) },
      null,
      this.getUsersAPIOptions()
    )
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    const prevStateUser = {
      firstName: prevState.firstName,
      lastName: prevState.lastName,
      phone: prevState.phone
    }

    const thisStateUser = {
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      phone: this.state.phone
    }

    if (!isEqual(prevStateUser, thisStateUser) && !this.state.modified) {
      this.setState({ modified: true })
    }
  }

  componentWillUnmount() {
    deleteUsersLockWithIdRequest(
      { path: { id: this.props.user.id } },
      {},
      null,
      null,
      this.getUsersAPIOptions()
    )
  }

  getUsersAPIOptions = (): {|
    basePath: typeof undefined | string
  |} => {
    const { userType } = this.props
    return { basePath: userType && getUsersAPIBasePath(userType) }
  }

  basicFields = (stateKey: string) => ({
    isValid: this.state[`${stateKey}Valid`],
    disabled: false,
    id: `input-${stateKey}`,
    className: this.props.classes.input,
    value: this.state[stateKey],
    isValidCb: (isValid: boolean) => { this.setState({ [`${stateKey}Valid`]: isValid }) },
    handleChange: (e: SyntheticInputEvent<any>) => this.handleUserInput(e),
    label: this.props.t(`settings._${stateKey.toUpperCase()}_`),
    size: 'XL',
    dataType: 'string'
  })

  checkValidity = () => {
    const {
      firstNameValid, lastNameValid, phoneValid, emailValid
    } = this.state
    return Boolean(firstNameValid && lastNameValid && phoneValid && emailValid)
  }

  handleChange = (eventID: string, eventValue: string) => {
    const stateKey = split(eventID, '-')[1]
    this.setState({
      [stateKey]: eventValue,
    })
  }

  handleUserInput = (e: SyntheticInputEvent<any>) => {
    this.handleChange(e.target.id, e.target.value)
  }

  handleSave = () => {
    const fields = ['firstName', 'lastName', 'email', 'phone']
    let values = {}
    map(fields, (field: string) => {
      if (this.state[field] !== this.props.user[field]) {
        values = { ...values, [field]: this.state[field] }
      }
    })
    patchUsersWithIdRequest(
      {
        path: {
          id: this.props.user.id
        },
        body: {
          ...values
        }
      }, {}, () => {
        this.props.refresh()
        this.setState({ modified: false })
      },
      null,
      this.getUsersAPIOptions()
    )
  }

  handleClose = () => {
    if (this.state.modified) {
      this.props.confirm({ onSave: () => this.props.onClose() })
    } else {
      this.props.onClose()
    }
  }

  firstNameField(): React$Element<any> {
    const { classes } = this.props

    return (
      <div className={classes.input}>
        <LabeledInput {...this.basicFields('firstName')} dataType='name' />
      </div>
    )
  }

  lastNameField(): React$Element<any> {
    const { classes } = this.props

    return (
      <div className={classes.input}>
        <LabeledInput {...this.basicFields('lastName')} dataType='name' />
      </div>
    )
  }

  emailField(): React$Element<any> {
    const { classes } = this.props

    return (
      <div className={classes.input}>
        <LabeledInput email {...this.basicFields('email')} disabled />
      </div>
    )
  }

  phoneField(): React$Element<any> {
    const { classes } = this.props

    return (
      <div className={classes.input}>
        <LabeledInput {...this.basicFields('phone')} dataType='phone' />
      </div>
    )
  }

  inputs(): LabeledInput {
    return [this.firstNameField(),
      this.lastNameField(),
      this.emailField(),
      this.phoneField(),
    ]
  }

  modalForm(): React$Element<typeof ModalForm> {
    const { modified, isResourceLocked } = this.state

    return (
      <ModalForm
        items={this.inputs()}
        onSave={() => this.handleSave()}
        onClose={() => this.handleClose()}
        valid={modified && isResourceLocked && this.checkValidity()}
        modified={modified}
        saveButtonText='buttons._SAVE_' />
    )
  }

  render(): React$Element<ModalForm> {
    const { t, classes } = this.props
    const { isResourceLocked } = this.state
    return (
      <div id='ProfileWrapper' className={classes.profileWrapper}>
        {!isResourceLocked && <Spinner />}
        <span className={classes.userInfoTitle}>{t('settings._USER_INFO_TITLE_')}</span>
        <div className={classes.modalFormWrapper}>
          {this.modalForm()}
        </div>
      </div>
    )
  }
}

const mapStateToProps = ({ app: { userType } }: TVDReduxStore): MappedProps => ({
  userType
})

export default compose(
  withStyles(styles),
  withTranslation('translations'),
  connect(mapStateToProps)
)(Profile)
