// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.
import React, { useEffect, useCallback, useRef } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useSelector, useStore } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { loadMFE } from '../../../utils/MFEUtils'
import { API_BASE_URL, ALLOW_WITH_CREDENTIALS, FEATURE_SET } from '../../../constants/envConstants'
import { PROCUREMENT_ELEMENTS } from '../../../constants/moduleConstants'
import { getEstimateHistoryObj } from '../../../utils/urlUtil'
import { headerHeight } from '../../../components/common/Header/Header'
import { getIsFeatureEnabledInSet } from '../../../utils/features'
import { FEATURE_LICENCE_TYPE_CHIP } from '../../../constants/features'
import { openGenericErrorModal } from '../../../utils/modalUtils'
import getWebWorker from '../../../utils/getWebWorker'
import { refreshUserAccessToken } from '../../../actions/user'
import { setActiveEstimate } from '../../../actions/activeEstimate'

const WebWorker = getWebWorker()

const useStyles = makeStyles(({ palette }: TVDTheme) => ({
  root: {
    height: '100%',
    width: '100%',
    paddingTop: headerHeight,
    background: palette.gray20
  }
}))

const ProcurementElements = (): React$Element<'div'> => {
  const moduleOnUnmountRef = useRef<$PropertyType<TVDMFEFunctionality, 'onUnmount'>>()
  const classes = useStyles()
  const history = useHistory()
  const { dispatch } = useStore()
  const {
    realEstateId,
    accessToken,
    haahtelaApiUserToken,
    userId,
    languageCode,
    selectedAccountId,
    siteId,
    realEstateName,
    isEstimateLockedToCurrentUser,
    licenseType,
    application,
    activeEstimate: activePROCEstimate,
  } = useSelector(({
    app,
    user,
    activeEstimate
  }: TVDReduxStore): Object => ({
    application: app.application,
    languageCode: app.languageCode,
    selectedAccountId: app.selectedAccountId,
    activeEstimate,
    realEstateId: app.realEstateId,
    realEstateName: app.realEstateName,
    accessToken: user.authorization.accessToken,
    haahtelaApiUserToken: user.claims.haahtelaApiUserToken,
    userId: user.claims.userId,
    siteId: app.siteId,
    isEstimateLockedToCurrentUser: app.isEstimateLockedToCurrentUser,
    licenseType: app.licenseType
  }))

  const navigateToProcurementEstimate = useCallback((estimateId: string, beforeNavigation?: () => void): void => {
    if (beforeNavigation) {
      beforeNavigation()
    }
    history.push(getEstimateHistoryObj({
      app: PROCUREMENT_ELEMENTS,
      realEstateName,
      accountId: selectedAccountId,
      estimateId,
      realEstateId
    }))
  }, [
    history,
    realEstateName,
    selectedAccountId,
    realEstateId
  ])

  useEffect(() => () => {
    if (moduleOnUnmountRef.current) {
      moduleOnUnmountRef.current()
    }
  }, [application])


  useEffect(() => {
    loadMFE('procurement_elements', 'procurement-elements-module-new', (module: TVDMFEFunctionality) => {
      if (
        activePROCEstimate &&
        languageCode &&
        accessToken &&
        haahtelaApiUserToken &&
        userId &&
        selectedAccountId &&
        realEstateId &&
        siteId &&
        WebWorker
      ) {
        const setActiveEstimateToStore = (estimate: TVDCalculation): void => {
          dispatch(setActiveEstimate(estimate))
        }
        const render = module.render || null
        if (render) {
          moduleOnUnmountRef.current = module.onUnmount
          render(
            'procurement-elements-new',
            {
              APIBaseURL: API_BASE_URL,
              languageCode,
              accessToken,
              haahtelaApiUserToken,
              userId,
              selectedAccountId,
              activeEstimate: activePROCEstimate,
              realEstateId,
              siteId,
              navigateToProcurementEstimate,
              isEstimateLockedToCurrentUser,
              WebWorker,
              licenseType: getIsFeatureEnabledInSet(FEATURE_LICENCE_TYPE_CHIP) ? licenseType : null,
              ALLOW_WITH_CREDENTIALS,
              openGenericErrorModal,
              FEATURE_SET,
              realEstateName,
              refreshUserAccessToken,
              setActiveEstimateToStore
            }
          )
        }
      }
    })
  }, [
    languageCode,
    accessToken,
    haahtelaApiUserToken,
    userId,
    selectedAccountId,
    realEstateId,
    siteId,
    navigateToProcurementEstimate,
    isEstimateLockedToCurrentUser,
    licenseType,
    realEstateName,
    dispatch,
    activePROCEstimate
  ])
  return (
    <div className={classes.root} id='procurement-elements-new' />
  )
}

export default ProcurementElements

