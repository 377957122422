// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.

import { reduce } from 'lodash'
import {
  EDIT_PROPERTY,
  CLEAR_PROPERTIES,
  PROPERTY_RESET_DEFAULT,
  SET_DROPDOWN_CONTENTS,
  SET_MULTIPLE_EDIT_PROPERTIES,
  SET_PROPERTY_OPTIONS,
  UNDO_EDIT_PROPERTY
} from '../actions/widgets'
import { reduceSuccessfulEmbeddedActions, type ReduceSuccessfulEmbeddedActionsCallbackArguments } from '../utils/reducerMergeUtil'
import { VALUE_PROPERTY } from '../constants/schemaKeys'

const initialState = {
}

export default function propertiesReducer(state: Object = initialState, action: TVDAction): Object {
  switch (action.type) {
    case EDIT_PROPERTY: {
      const {
        propertiesStoreId,
        property,
        value,
      } = action.payload

      /*
        resetDefault must be explicitly set to "undefined" to counter resetDefault flag set by PROPERTY_RESET_DEFAULT if it has been called
        just before EDIT_PROPERTY and Save has not been executed in between.
        (otherwise resetDefault flag tells API to reset value to default when Save is executed)
      */
      return {
        ...state,
        [propertiesStoreId]: {
          ...state[propertiesStoreId],
          [property]: {
            ...state[propertiesStoreId][property],
            value,
            previousValue: state[propertiesStoreId][property].value,
            modifiedValue: value,
            userModified: true,
            resetDefault: undefined,
          }
        }
      }
    }

    case UNDO_EDIT_PROPERTY: {
      const {
        propertiesStoreId,
        property,
      } = action.payload

      return {
        ...state,
        [propertiesStoreId]: {
          ...state[propertiesStoreId],
          [property]: {
            ...state[propertiesStoreId][property],
            value: state[propertiesStoreId][property].previousValue,
            modifiedValue: undefined,
            userModified: state[propertiesStoreId][property].previousValue !== state[propertiesStoreId][property].defaultValue,
            previousValue: undefined
          }
        }
      }
    }

    case PROPERTY_RESET_DEFAULT: {
      const { propertiesStoreId, property } = action.payload

      return {
        ...state,
        [propertiesStoreId]: {
          ...state[propertiesStoreId],
          [property]: {
            ...state[propertiesStoreId][property],
            resetDefault: true,
            userModified: undefined // must be explicitly set to "undefined" to remove user modified icon before Save is executed.
          }
        }
      }
    }

    case CLEAR_PROPERTIES: {
      const {
        payload: {
          listId,
        }
      } = action

      return {
        ...reduce(state, (result: Object, propertyList: Object, key: string) => {
          if (key === listId) {
            return result
          }
          return {
            ...result,
            [key]: propertyList,
          }
        }, {}),
      }
    }

    case SET_DROPDOWN_CONTENTS: {
      const { payload: { data, resourceId } } = action
      const { [resourceId]: propertiesItems } = state
      if (!propertiesItems) return state
      return {
        ...state,
        [resourceId]: {
          ...state[resourceId],
          ...Object.keys(data).reduce((res: TVDPropertiesListItems, propertyName: string): TVDPropertiesListItems => {
            if (propertiesItems[propertyName]) {
              return {
                ...res,
                [propertyName]: {
                  ...propertiesItems[propertyName],
                  options: data[propertyName]
                }
              }
            }
            return res
          }, {})
        }
      }
    }

    case SET_MULTIPLE_EDIT_PROPERTIES: {
      const {
        payload: {
          data,
          resourceId
        }
      } = action
      return {
        ...state,
        [resourceId]: data,
      }
    }

    case SET_PROPERTY_OPTIONS: {
      const { payload: { propertyName, propertiesStoreId, options } } = action
      const propertiesStore = state[propertiesStoreId] || {}
      return {
        ...state,
        [propertiesStoreId]: {
          ...propertiesStore,
          [propertyName]: {
            ...propertiesStore[propertyName],
            options
          }
        }
      }
    }

    default:
      break
  }

  return reduceSuccessfulEmbeddedActions({
    action,
    state,
    conditionFn: () => action && action.payload && action.payload.propertiesStoreId,
    cb: ({ schemaKey, newState }: ReduceSuccessfulEmbeddedActionsCallbackArguments) => {
      const { payload: { propertiesStoreId, parsedResponse } } = action
      switch (schemaKey) {
        case VALUE_PROPERTY: {
          return {
            ...newState,
            [propertiesStoreId]: parsedResponse.reduce((result: Object, property: Object) => ({
              ...result,
              [property.propertyName]: {
                ...property,
                options: newState[propertiesStoreId] && newState[propertiesStoreId][property.propertyName] ?
                  newState[propertiesStoreId][property.propertyName].options : []
              }
            }), {})
          }
        }
        default:
          return newState
      }
    }
  })
}
