// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.
import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Tooltip from '../../../common/Tooltip/Tooltip'
import { formatResultBoxContent } from '../../../../utils/listUtils'
import theme from '../../../../styles/theme'

const styles = ({ palette }: Object): Object => ({
  box: {
    borderRight: `1px solid ${palette.gray80}`,
    '&:last-of-type': {
      borderRight: '0px',
    },
    padding: '0px 16px 0px 16px',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    minWidth: '100px',
    height: '100%',
  },
  header: {
    display: 'flex',
    flex: 6,
    ...theme.typography.classes.bodyBigMedium,
    color: palette.dark80,
    alignItems: 'center'
  },
  valuefield: {
    flex: 6,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
  value: {
    ...theme.typography.classes.h2,
    color: palette.dark80,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  unit: {
    ...theme.typography.classes.h2,
    color: palette.dark80,
    paddingLeft: '8px',
  }
})

export type ResultBoxProps = {|
  title: string, // translated title for the box
  value: number, // the amount shown in the box
  unit: string, // the unit for the amount (pcs, m², etc.)
  propertyName: string, // propertyname of the resultbox to distinguish each result box value
  resultBarFormatOptions?: TVDResultBarFormatOptions, // assign format options to result bar content
  disableFormatting?: boolean // if the resultboxes value should not be formatted
|}

type Props = {|
  result: ResultBoxProps, // Single result object separated from results array at <ResultBar />
  classes: Object, // withStyles classes object
  testId: string, // id string for e2e testing purposes
  resultBarFormatOptions?: TVDResultBarFormatOptions, // assign format options to result bar content
|}

const ResultBox = ({
  result,
  classes,
  testId,
  resultBarFormatOptions
}: Props) => {
  const header = () => {
    const { title } = result

    return (
      <div className={classes.header}>
        {title}
      </div>
    )
  }

  const resultElement = () => {
    const {
      value,
      unit,
      propertyName,
      disableFormatting
    } = result
    return (
      <Tooltip text={`${value} ${unit}`} placement='top-end'>
        <div className={classes.valuefield}>
          <div className={classes.value} data-testid={`${testId}-${propertyName}-value`}>
            {disableFormatting ? value : formatResultBoxContent(value, 'integer', resultBarFormatOptions)}
            <span className={classes.unit}>
              {unit}
            </span>
          </div>
        </div>
      </Tooltip>
    )
  }

  return (
    <div className={classes.box} data-testid={testId}>
      { header() }
      { resultElement() }
    </div>
  )
}

ResultBox.defaultProps = {
  resultBarFormatOptions: undefined,
}

export default withStyles(styles)(ResultBox)
