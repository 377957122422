// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.

import React, { Component } from 'react'
import { Divider } from '@material-ui/core'

import MultipleSelectMenuState, { type CheckedItems } from '../components/MultipleSelectMenuState/MultipleSelectMenuState'
import DropdownMenuCore from '../components/DropdownMenuCore/DropdownMenuCore'
import MenuItems from '../components/MenuItems/MenuItems'
import MenuItem from '../components/MenuItems/MenuItem/MenuItem'
import { type AlignInputText } from '../components/DropdownInput/DropdownInput'

export type MultipleSelectWidths = 'M' | 'L' | 'XL'

export type MultipleSelectMenuProps = {|
  items: Array<TVDMenuItem>,
  onChange?: (selection: CheckedItems) => void, // function fired when a selection is made in the dropdown, called with an array of currently selected values
  title?: string, // title for the dropdown
  defaultValues?: CheckedItems, // dropdown will be created with these values as selected
  selectAllItemLabel?: String | null, // if null a selectAll item won't be rendered
  disabled?: boolean, // whether the menu is disabled
  disabledTitle?: string, // title to show if menu is disabled
  error?: boolean, // error state for the menu
  width?: MultipleSelectWidths,
  id?: string, // for testing purposes
  minimalist?: boolean, // a minimalist version of the dropdown
  helperText?: string, // displayed under the menu
  placeholder?: string, // a placeholder selection to be shown in a menu without selections
  alignInputText?: AlignInputText, // optional setting to align input text to left or right
|}

type Props = {
  ...MultipleSelectMenuProps,
}

export const SELECTALLVALUE = 'SELECT_ALL'

export class DropdownMultipleSelectMenu extends Component<Props> {
  static defaultProps = {
    selectAllItem: null,
    disabled: false,
    disabledTitle: '',
    defaultValues: [],
    onClose: () => {},
    error: false,
    width: 'M',
    id: '',
    minimalist: false,
    helperText: '',
    placeholder: '',
    items: []
  }

  selectAllItem = () => {
    const { selectAllItemLabel, disabled } = this.props
    return (
      <MenuItem
        disabled={disabled}
        value={SELECTALLVALUE}
        localizedName={selectAllItemLabel}
        checkbox>
        { selectAllItemLabel }
      </MenuItem>
    )
  }


  render(): React$Element<any> {
    const {
      onChange,
      items,
      disabled,
      title,
      error,
      selectAllItemLabel,
      disabledTitle,
      defaultValues,
      width,
      id,
      minimalist,
      helperText,
      placeholder = '',
      alignInputText
    } = this.props

    const selectAllValue = selectAllItemLabel ? SELECTALLVALUE : ''

    return (
      <MultipleSelectMenuState
        items={items}
        onChange={onChange}
        forcedValues={defaultValues}
        placeholder={Boolean(placeholder)}
        selectAllItem={selectAllValue}>
        {({ selected, handleSelectItem }: Object) => (
          <MenuItems
            disabled={disabled}
            checkboxItems
            placeholder={placeholder}
            items={this.props.items}>
            {({ menuItems }: Object) => (
              <DropdownMenuCore
                items={items}
                alignInputText={alignInputText}
                helperText={helperText}
                minimalist={minimalist}
                id={id}
                width={width}
                error={error}
                onChange={handleSelectItem}
                title={title}
                selected={selected}
                disabled={disabled || items.length === 0}
                disabledTitle={disabledTitle}
                selectAllItemLabel={selectAllItemLabel}
                multiple>
                { selectAllItemLabel ? this.selectAllItem() : null }
                { selectAllItemLabel ? <Divider style={{ margin: '8px 0', color: '#939398' }} /> : null }
                { !!items && menuItems }
              </DropdownMenuCore>
            )}
          </MenuItems>
        )}
      </MultipleSelectMenuState>
    )
  }
}

export default DropdownMultipleSelectMenu
