// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.
import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'

import TabsPlain from '../../../common/TabsPlain/TabsPlain'
import RenovationSpaceGroups from './tabContents/RenovationSpaceGroups'
import RenovationBuilding from './tabContents/RenovationBuilding'
import RenovationSite from './tabContents/RenovationSite'
import RenovationSpaces from './tabContents/RenovationSpaces'

import {
  RENOVATION_SPACES,
  RENOVATION_BUILDING,
  RENOVATION_SITE,
  RENOVATION,
  RENOVATION_SPACEGROUPS
} from '../../../../constants/contentTypes'
import {
  RENOVATION_SPACEGROUPS_TAB,
  RENOVATION_BUILDING_TAB,
  RENOVATION_SITE_TAB,
  RENOVATION_SPACES_TAB,
} from '../../../../constants/moduleConstants'

const styles = {
  renovation: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    flex: '1',
    overflowY: 'auto',
    overflowX: 'hidden'
  },
}

type MappedProps = {|
  activeTab: string, // name of currently edited tab
  isEstimateLockedToCurrentUser: $PropertyType<TVDApplicationStore, 'isEstimateLockedToCurrentUser'>, // if the user owns the lock for the estimate
  isEstimateFrozen?: $PropertyType<TVDApplicationStore, 'isEstimateFrozen'>, // if estimate is frozen
|}

type ReceivedProps = {|
  disabled: boolean, // whether the widget is disabled,
|}

type Props = {|
  ...MappedProps,
  ...ReceivedProps,
  t: Function, // i18n translation functions
  classes: Object, // withStyles classes object
|}

export class Renovation extends Component<Props> {
  static defaultProps = {
    disabled: false,
  }

  get tabs(): Array<TVDTab> {
    const { t, } = this.props

    return [
      {
        props: {
          disabled: this.getIsTabDisabled(RENOVATION_SPACES_TAB),
          label: t('tabs._RENOVATION_SPACES_')
        },
        contentStyles: {
          overflow: 'hidden'
        },
        testId: 'RENOVATION_SPACES-tab',
        content: (
          <RenovationSpaces
            listStoreId={RENOVATION_SPACES}
            widgetId={RENOVATION}
            widgetTab={RENOVATION_SPACES}
            disabled={this.getIsTabContentDisabled()} />
        )
      },
      {
        props: {
          disabled: this.getIsTabDisabled(RENOVATION_SPACEGROUPS_TAB),
          label: t(`tabs._${RENOVATION_SPACEGROUPS_TAB}_`)
        },
        testId: `${RENOVATION_SPACEGROUPS_TAB}-tab`,
        content: <RenovationSpaceGroups
          listStoreId={RENOVATION_SPACEGROUPS}
          widgetId={RENOVATION}
          widgetTab={RENOVATION_SPACEGROUPS}
          disabled={this.getIsTabContentDisabled()} />
      },
      {
        props: {
          disabled: this.getIsTabDisabled(RENOVATION_BUILDING_TAB),
          label: t(`tabs._${RENOVATION_BUILDING_TAB}_`)
        },
        testId: `${RENOVATION_BUILDING_TAB}-tab`,
        content: (
          <RenovationBuilding
            listStoreId={RENOVATION_BUILDING}
            disabled={this.getIsTabContentDisabled()}
            widgetTab={RENOVATION_BUILDING_TAB}
            widgetId={RENOVATION} />
        )
      },
      {
        props: {
          disabled: this.getIsTabDisabled(RENOVATION_SITE_TAB),
          label: t(`tabs._${RENOVATION_SITE_TAB}_`)
        },
        testId: `${RENOVATION_SITE_TAB}-tab`,
        content: (
          <RenovationSite
            disabled={this.getIsTabContentDisabled()}
            listStoreId={RENOVATION_SITE}
            widgetTab={RENOVATION_SITE_TAB}
            widgetId={RENOVATION} />
        )
      }
    ]
  }

  getIsTabDisabled(widgetTab: string): boolean {
    const { disabled, activeTab, isEstimateFrozen } = this.props
    return (activeTab !== '' && (activeTab !== widgetTab)) || disabled || !!isEstimateFrozen
  }
  getIsTabContentDisabled(): boolean {
    const { disabled, isEstimateLockedToCurrentUser, isEstimateFrozen } = this.props
    return !isEstimateLockedToCurrentUser || disabled || !!isEstimateFrozen
  }

  render(): React$Element<any> {
    const { classes } = this.props

    return (
      <div className={classes.renovation}>
        <TabsPlain tabs={this.tabs} />
      </div>
    )
  }
}

const mapStateToProps = ({ app }: TVDReduxStore): MappedProps => {
  const { isEstimateLockedToCurrentUser, activeTab, isEstimateFrozen } = app
  return {
    isEstimateLockedToCurrentUser,
    isEstimateFrozen,
    activeTab,
  }
}

export default connect(mapStateToProps)(withTranslation('translations')(withStyles(styles)(Renovation)))
