// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.

import { keys, reduce } from 'lodash'
import { OPEN_CONTENT_WIDGET } from '../actions/widgets'
import { ASSEMBLY } from '../constants/contentTypes'
import { dispatch } from '../store'
import { setActiveWidgetAsTopmost } from '../actions/app'
import { ELEMENTS } from '../constants/moduleConstants'

export const defaultLocation = { right: 0, top: 181 }
export default ({ getState }: Object) => (next: Function) => (action: Object) => {
  const { widgets, app: { application } } = getState()

  function setWidgetAsTopmost(): Object {
    const { payload: { resourceId, widgetId } } = action
    if (!resourceId && !widgetId) {
      console.warn('Widget has no resourceId or widgetId: setting widget order for this widget is not possible')
    } else dispatch(setActiveWidgetAsTopmost(resourceId || widgetId))
  }

  function calculateParentLocation(newWidgetType: string): Object {
    const firstLevelWidgets = reduce(widgets, (result: Array<Object>, widget: Object) => {
      if (
        ((widget.contentProps && widget.contentProps.isParentWidget) ||
        (widget.contentProps && !widget.contentProps.widgetLevelChild) ||
        (application === ELEMENTS.toLowerCase() && widget.widgetType === ASSEMBLY))
      ) {
        result.push(widget)
      }
      return result
    }, [])

    const maxRight = Math.max(...firstLevelWidgets.map((obj: Object) => obj.widgetLocation.right))
    const maxTop = Math.max(...firstLevelWidgets.map((obj: Object) => obj.widgetLocation.top))
    const similarWidgetOpen = firstLevelWidgets.find((widget: Object) => widget.widgetType === newWidgetType)

    if (similarWidgetOpen) {
      return {
        top: similarWidgetOpen.widgetLocation.top,
        right: similarWidgetOpen.widgetLocation.right
      }
    }

    return {
      top: maxTop + 8,
      right: maxRight + 48
    }
  }

  function calculateChildLocation(parentWidget: Object): Object {
    return {
      ...parentWidget.widgetLocation,
      top: parentWidget.widgetLocation.top + 58,
    }
  }

  if (action && ((action.type === OPEN_CONTENT_WIDGET) || (action.payload && action.payload.widgetLevelChild))) setWidgetAsTopmost()

  const widgetLevelChild = action && action.payload && action.payload.contentProps && action.payload.contentProps.widgetLevelChild
  const widgetLevelParent = action && action.payload && action.payload.contentProps && !action.payload.contentProps.widgetLevelChild
  const isParentWidget = action && action.payload && action.payload.contentProps && action.payload.contentProps.isParentWidget
  if (action.type === OPEN_CONTENT_WIDGET) {
    if (widgetLevelParent || isParentWidget) {
      if (keys(widgets).length !== 0) {
        const parentLocation = calculateParentLocation(action.payload.widgetType)
        next({ ...action, payload: { ...action.payload, widgetLocation: parentLocation } })
      // Handle first Parent widget
      } else {
        const newLocation = defaultLocation
        next({ ...action, payload: { ...action.payload, widgetLocation: newLocation } })
      }
      // Handle Child widgets
    } else if (widgetLevelChild) {
      const parentName = action.payload.contentProps.spaceId
      const childLocation = calculateChildLocation(widgets[parentName])
      next({ ...action, payload: { ...action.payload, widgetLocation: childLocation } })
    }
  } else if (action) {
    next(action)
  }
}
