// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.
import React, { Component } from 'react'
import { connect, batch } from 'react-redux'
import { isEmpty } from 'lodash'
import Axios from 'axios'

import PropertyContainer from '../../../common/Tabs/Properties/PropertyContainer/PropertyContainer'

import { BUILDING_ELEMENTS_TASK } from '../../../../constants/contentTypes'
import { clearProperties, setPropertyOptions, undoEditProperty } from '../../../../actions/widgets'
import { setCalculationActive, setCalculationComplete } from '../../../../actions/app'
import { postPolling } from '../../../../actions/postPolling'
import { runGAR } from '../../../../utils/GARUtils'
import {
  getBuildingTasksPropertiesWithEstimateIdRequest,
  patchBuildingTasksPropertiesWithEstimateIdRequest,
} from '../../../../utils/generated-api-requests/buildingelements'


type DispatchProps = {|
  dispatchClearProperties: () => void, // clear a properties list from Store
  dispatchSetPropertyOptions: (propertyOptions: Array<TVDEnum>, propertyName: string) => void, // set enums to properties store
  dispatchSetCalculationActive: () => void, // set calculation active to true in Store to indicate loading happening
  dispatchSetCalculationComplete: () => void, // set calculation active to false in Store to indicate that loading is no longer happening
  dispatchPostPolling: () => void, // initiates post polling
  dispatchUndoEdit: (string) => void, // initiates post polling
|}

type ReceivedProps = {|
  taskIds: Array<string>, // array of selected building tasks
  disabled: boolean, // flag to disable propertyContainer events
  widgetId: string, // id set in the widget store
  propertiesStoreId: string, // key to the properties Store object where the properties is stored
  actionText?: string, // optional actionText for userModifiedIcon to overwrite default text
|}

type Props = {|
  ...DispatchProps,
  ...ReceivedProps,
|}

export class BuildingTaskPropertyDataContainer extends Component<Props> {
  componentDidMount() {
    const { taskIds } = this.props
    if (!isEmpty(taskIds)) {
      this.getBuildingTasksProperties()
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.taskIds.length !== prevProps.taskIds.length) {
      const { taskIds, dispatchClearProperties } = this.props
      if (!isEmpty(taskIds)) {
        this.getBuildingTasksProperties()
      } else {
        dispatchClearProperties()
      }
    }
  }

  patchProperty(propertyName: string, propertyValue: string | number | boolean, shouldReset: boolean, callBack?: Function) {
    const { dispatchPostPolling } = this.props
    const query = shouldReset ? { body: {}, query: { resetProperties: [propertyName] } } : {}
    patchBuildingTasksPropertiesWithEstimateIdRequest({ body: { [propertyName]: propertyValue }, ...query }, {}, () => {
      if (callBack) callBack()
      dispatchPostPolling()
    }, () => this.props.dispatchUndoEdit(propertyName))
  }

  getBuildingTasksProperties() {
    const {
      taskIds, propertiesStoreId, dispatchSetPropertyOptions, dispatchSetCalculationActive, dispatchSetCalculationComplete
    } = this.props
    dispatchSetCalculationActive()
    const propertyEnums = []
    getBuildingTasksPropertiesWithEstimateIdRequest(
      { query: { buildingTaskIds: taskIds } },
      { propertiesStoreId },
      (parsedResponse: Array<TVDPropertiesListItem>) => {
        Axios.all(parsedResponse.filter(({ dataType }: TVDPropertiesListItem) => dataType === 'enum')
          .map((propertyListItem: TVDPropertiesListItem): Promise<any> | null => {
            const { enumRequestDefinitions } = propertyListItem
            if (enumRequestDefinitions) {
              return runGAR({
                ...enumRequestDefinitions,
                successCb: (parsedEnumResponse: Array<TVDEnum>) => {
                  propertyEnums.push({ parsedEnumResponse, propertyName: propertyListItem.propertyName })
                },
                options: { disableSetCalculationActiveAndComplete: true }
              })
            }
            console.error(`No enum request definitions found from enum ${propertyListItem.propertyName}`)
            return null
          })).then(() => {
          batch(() => {
            propertyEnums.forEach(({ parsedEnumResponse, propertyName }: Object) => {
              dispatchSetPropertyOptions(parsedEnumResponse, propertyName)
            })
            dispatchSetCalculationComplete()
          })
        }).catch(({ message }: Error) => {
          console.error(message)
          dispatchSetCalculationComplete()
        })
      }, null,
    )
  }

  render(): React$Element<any> {
    const {
      disabled,
      widgetId,
      propertiesStoreId,
      actionText
    } = this.props

    return (
      <PropertyContainer
        widgetId={widgetId}
        type={BUILDING_ELEMENTS_TASK}
        resourceId={BUILDING_ELEMENTS_TASK}
        propertiesStoreId={propertiesStoreId}
        editPropertyCb={this.patchProperty.bind(this)}
        hideButtons
        disableMarkAsModified
        disabled={disabled}
        actionText={actionText} />
    )
  }
}

const mapDispatchToProps = (dispatch: Function, { propertiesStoreId }: ReceivedProps): DispatchProps => ({
  dispatchClearProperties: () => { dispatch(clearProperties(propertiesStoreId)) },
  dispatchSetCalculationActive: () => { dispatch(setCalculationActive()) },
  dispatchSetCalculationComplete: () => { dispatch(setCalculationComplete()) },
  dispatchPostPolling: () => { dispatch(postPolling()) },
  dispatchUndoEdit: (propertyName: string) => { dispatch(undoEditProperty(propertiesStoreId, propertyName)) },
  dispatchSetPropertyOptions: (propertyOptions: Array<TVDEnum>, propertyName: string) => {
    dispatch(setPropertyOptions(propertiesStoreId, propertyName, propertyOptions))
  },
})

export default connect(null, mapDispatchToProps)(BuildingTaskPropertyDataContainer)
