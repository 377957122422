// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.
import React, { Component } from 'react'
import { Table, TableBody, TableHead, TableRow } from '@material-ui/core'
import { map } from 'lodash'
import { STORY_PROPERTY } from '../../../../../constants/contentTypes'
import PropertyRow from '../../../../common/Tabs/Properties/PropertyRow/PropertyRow'
import ListHeaderCell from '../../../../common/lists/common/ListHeaderCell/ListHeaderCell'

type Props = {|
  storyProperties: Object, // story data
  onBlur: Function // Function triggered onBlur event
|}

export class StoryProperties extends Component<Props> {
  get propertyRows(): Array<PropertyRow> {
    const {
      onBlur,
      storyProperties
    } = this.props

    return map(storyProperties, (row: Object) => {
      const propertyShouldRender = !row.hidden
      const disabled = row.readOnly
      row.value = row.modifiedValue ? row.modifiedValue : row.value
      return (
        propertyShouldRender &&
        <PropertyRow
          disabled={disabled}
          key={row.propertyName}
          row={row}
          rowText={row.localizedName}
          onBlur={onBlur}
          parentLevel={-1}
          inputFieldProps={{ maxWidth: 70 }}
          widgetType={STORY_PROPERTY} />
      )
    })
  }


  render(): React$Element<any> {
    return (
      <Table>
        <TableHead>
          <TableRow>
            <ListHeaderCell
              initialWidth={150} />
            <ListHeaderCell
              initialWidth={100} />
          </TableRow>
        </TableHead>
        <TableBody>
          {this.propertyRows}
        </TableBody>
      </Table>
    )
  }
}

export default StoryProperties
