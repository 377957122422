// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.
/* eslint max-len: ["error", { "code": 350 }] */
/*
  Haahtela Real Estate Api 1.0.0
  Real estate services for Haahtela Api
  192.168.254.119:5023
*/

import requestFn from './../requests/request'
import { API_BASE_URL } from './../../constants/envConstants'
import parserMapper from './../parserMapper'
import { getState } from '../../store'

export const basePath = '/real-estate-api'

export const DATA_GET_REAL_ESTATES_START = 'DATA_GET_REAL_ESTATES_START'
export const DATA_GET_REAL_ESTATES_SUCCESSFUL = 'DATA_GET_REAL_ESTATES_SUCCESSFUL'
export const DATA_GET_REAL_ESTATES_FAIL = 'DATA_GET_REAL_ESTATES_FAIL'

// Returns an embedded list of real estates for an Account.
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/realestate.yaml#/Real%20estate/get_real_estates
export const getRealEstatesRequestDeprecated = (
  requestArguments: getRealEstatesRequestRealestateArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_REAL_ESTATES_START',
      successful: 'DATA_GET_REAL_ESTATES_SUCCESSFUL',
      fail: 'DATA_GET_REAL_ESTATES_FAIL'
    },
    embeddedType: {
      nonEmbedded: {
        schemaPath: '#/definitions/ListItemCollection',
        schemaKey: 'ListItemCollection'
      }
    },
    fnName: 'getRealEstatesRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/real-estate-api`,
    method: 'GET',
    url: '/real-estates',
    params: {
      accountId: options?.selectedAccountId || store.app.selectedAccountId,
      includeDeletedRealEstates: requestArguments.query?.['includeDeletedRealEstates'],
      language: options?.languageCode || store.app.languageCode
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      nonEmbedded: {
        schemaPath: '#/definitions/ListItemCollection',
        schemaKey: 'ListItemCollection'
      }
    }, 'getRealEstatesRequest', '/real-estate-api'),
    actions: {
      start: {
        type: DATA_GET_REAL_ESTATES_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_REAL_ESTATES_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_REAL_ESTATES_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getRealEstatesRequestDefinitions = (definitionsArguments: {| requestArgs: getRealEstatesRequestRealestateArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getRealEstatesRequestRealestateArguments, payload?: Object |} => ({
  fnName: 'getRealEstatesRequest',
  basePath: '/real-estate-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_POST_REAL_ESTATES_START = 'DATA_POST_REAL_ESTATES_START'
export const DATA_POST_REAL_ESTATES_SUCCESSFUL = 'DATA_POST_REAL_ESTATES_SUCCESSFUL'
export const DATA_POST_REAL_ESTATES_FAIL = 'DATA_POST_REAL_ESTATES_FAIL'

// Create a new real estate (creates also a site and a building for the created real estate)
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/realestate.yaml#/Real%20estate/post_real_estates
export const postRealEstatesRequest = (
  requestArguments: postRealEstatesRequestRealestateArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_POST_REAL_ESTATES_START',
      successful: 'DATA_POST_REAL_ESTATES_SUCCESSFUL',
      fail: 'DATA_POST_REAL_ESTATES_FAIL'
    },
    embeddedType: null,
    fnName: 'postRealEstatesRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/real-estate-api`,
    method: 'POST',
    url: '/real-estates',
    params: {
      accountId: options?.selectedAccountId || store.app.selectedAccountId,
      isPrivate: requestArguments.query?.['isPrivate'],
      language: options?.languageCode || store.app.languageCode
    },
    data: requestArguments.body
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'postRealEstatesRequest', '/real-estate-api'),
    actions: {
      start: {
        type: DATA_POST_REAL_ESTATES_START, payload, config, meta
      },
      successful: {
        type: DATA_POST_REAL_ESTATES_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_POST_REAL_ESTATES_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_GET_REAL_ESTATES_WITH_ACCOUNTID_START = 'DATA_GET_REAL_ESTATES_WITH_ACCOUNTID_START'
export const DATA_GET_REAL_ESTATES_WITH_ACCOUNTID_SUCCESSFUL = 'DATA_GET_REAL_ESTATES_WITH_ACCOUNTID_SUCCESSFUL'
export const DATA_GET_REAL_ESTATES_WITH_ACCOUNTID_FAIL = 'DATA_GET_REAL_ESTATES_WITH_ACCOUNTID_FAIL'

// Returns a list of real estates for an account.
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/realestate.yaml#/RealEstate/get__accountId__real_estates
export const getRealEstatesWithAccountIdRequest = (
  requestArguments: getRealEstatesWithAccountIdRequestRealestateArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_REAL_ESTATES_WITH_ACCOUNTID_START',
      successful: 'DATA_GET_REAL_ESTATES_WITH_ACCOUNTID_SUCCESSFUL',
      fail: 'DATA_GET_REAL_ESTATES_WITH_ACCOUNTID_FAIL'
    },
    embeddedType: null,
    fnName: 'getRealEstatesWithAccountIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/real-estate-api`,
    method: 'GET',
    url: `/${options?.selectedAccountId || store.app.selectedAccountId}/real-estates`,
    params: {
      includeDeletedRealEstates: requestArguments.query?.['includeDeletedRealEstates'],
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'getRealEstatesWithAccountIdRequest', '/real-estate-api'),
    actions: {
      start: {
        type: DATA_GET_REAL_ESTATES_WITH_ACCOUNTID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_REAL_ESTATES_WITH_ACCOUNTID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_REAL_ESTATES_WITH_ACCOUNTID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getRealEstatesWithAccountIdRequestDefinitions = (definitionsArguments: {| requestArgs: getRealEstatesWithAccountIdRequestRealestateArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getRealEstatesWithAccountIdRequestRealestateArguments, payload?: Object |} => ({
  fnName: 'getRealEstatesWithAccountIdRequest',
  basePath: '/real-estate-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_GET_REAL_ESTATES_WITH_REALESTATEID_START = 'DATA_GET_REAL_ESTATES_WITH_REALESTATEID_START'
export const DATA_GET_REAL_ESTATES_WITH_REALESTATEID_SUCCESSFUL = 'DATA_GET_REAL_ESTATES_WITH_REALESTATEID_SUCCESSFUL'
export const DATA_GET_REAL_ESTATES_WITH_REALESTATEID_FAIL = 'DATA_GET_REAL_ESTATES_WITH_REALESTATEID_FAIL'

// Returns the real estate
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/realestate.yaml#/Real%20estate/get_real_estates__realEstateId_
export const getRealEstatesWithRealEstateIdRequest = (
  requestArguments: getRealEstatesWithRealEstateIdRequestRealestateArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_REAL_ESTATES_WITH_REALESTATEID_START',
      successful: 'DATA_GET_REAL_ESTATES_WITH_REALESTATEID_SUCCESSFUL',
      fail: 'DATA_GET_REAL_ESTATES_WITH_REALESTATEID_FAIL'
    },
    embeddedType: {
      nonEmbedded: {
        schemaPath: '#/definitions/RealEstate',
        schemaKey: 'RealEstate'
      }
    },
    fnName: 'getRealEstatesWithRealEstateIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/real-estate-api`,
    method: 'GET',
    url: `/real-estates/${requestArguments.path['realEstateId']}`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      nonEmbedded: {
        schemaPath: '#/definitions/RealEstate',
        schemaKey: 'RealEstate'
      }
    }, 'getRealEstatesWithRealEstateIdRequest', '/real-estate-api'),
    actions: {
      start: {
        type: DATA_GET_REAL_ESTATES_WITH_REALESTATEID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_REAL_ESTATES_WITH_REALESTATEID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_REAL_ESTATES_WITH_REALESTATEID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getRealEstatesWithRealEstateIdRequestDefinitions = (definitionsArguments: {| requestArgs: getRealEstatesWithRealEstateIdRequestRealestateArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getRealEstatesWithRealEstateIdRequestRealestateArguments, payload?: Object |} => ({
  fnName: 'getRealEstatesWithRealEstateIdRequest',
  basePath: '/real-estate-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_PATCH_REAL_ESTATES_WITH_REALESTATEID_START = 'DATA_PATCH_REAL_ESTATES_WITH_REALESTATEID_START'
export const DATA_PATCH_REAL_ESTATES_WITH_REALESTATEID_SUCCESSFUL = 'DATA_PATCH_REAL_ESTATES_WITH_REALESTATEID_SUCCESSFUL'
export const DATA_PATCH_REAL_ESTATES_WITH_REALESTATEID_FAIL = 'DATA_PATCH_REAL_ESTATES_WITH_REALESTATEID_FAIL'

// Update real estate properties.
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/realestate.yaml#/Real%20estate/patch_real_estates__realEstateId_
export const patchRealEstatesWithRealEstateIdRequest = (
  requestArguments: patchRealEstatesWithRealEstateIdRequestRealestateArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_PATCH_REAL_ESTATES_WITH_REALESTATEID_START',
      successful: 'DATA_PATCH_REAL_ESTATES_WITH_REALESTATEID_SUCCESSFUL',
      fail: 'DATA_PATCH_REAL_ESTATES_WITH_REALESTATEID_FAIL'
    },
    embeddedType: null,
    fnName: 'patchRealEstatesWithRealEstateIdRequest'
  }
  const config = {
    headers: { 'Content-Type': 'application/json' },
    baseURL: `${API_BASE_URL}/real-estate-api`,
    method: 'PATCH',
    url: `/real-estates/${requestArguments.path['realEstateId']}`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: requestArguments.body
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'patchRealEstatesWithRealEstateIdRequest', '/real-estate-api'),
    actions: {
      start: {
        type: DATA_PATCH_REAL_ESTATES_WITH_REALESTATEID_START, payload, config, meta
      },
      successful: {
        type: DATA_PATCH_REAL_ESTATES_WITH_REALESTATEID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_PATCH_REAL_ESTATES_WITH_REALESTATEID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_DELETE_REAL_ESTATES_WITH_REALESTATEID_START = 'DATA_DELETE_REAL_ESTATES_WITH_REALESTATEID_START'
export const DATA_DELETE_REAL_ESTATES_WITH_REALESTATEID_SUCCESSFUL = 'DATA_DELETE_REAL_ESTATES_WITH_REALESTATEID_SUCCESSFUL'
export const DATA_DELETE_REAL_ESTATES_WITH_REALESTATEID_FAIL = 'DATA_DELETE_REAL_ESTATES_WITH_REALESTATEID_FAIL'

// Delete a real estate.
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/realestate.yaml#/Real%20estate/delete_real_estates__realEstateId_
export const deleteRealEstatesWithRealEstateIdRequest = (
  requestArguments: deleteRealEstatesWithRealEstateIdRequestRealestateArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_DELETE_REAL_ESTATES_WITH_REALESTATEID_START',
      successful: 'DATA_DELETE_REAL_ESTATES_WITH_REALESTATEID_SUCCESSFUL',
      fail: 'DATA_DELETE_REAL_ESTATES_WITH_REALESTATEID_FAIL'
    },
    embeddedType: null,
    fnName: 'deleteRealEstatesWithRealEstateIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/real-estate-api`,
    method: 'DELETE',
    url: `/real-estates/${requestArguments.path['realEstateId']}`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'deleteRealEstatesWithRealEstateIdRequest', '/real-estate-api'),
    actions: {
      start: {
        type: DATA_DELETE_REAL_ESTATES_WITH_REALESTATEID_START, payload, config, meta
      },
      successful: {
        type: DATA_DELETE_REAL_ESTATES_WITH_REALESTATEID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_DELETE_REAL_ESTATES_WITH_REALESTATEID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_GET_REAL_ESTATES_KEY_FIGURES_WITH_REALESTATEID_START = 'DATA_GET_REAL_ESTATES_KEY_FIGURES_WITH_REALESTATEID_START'
export const DATA_GET_REAL_ESTATES_KEY_FIGURES_WITH_REALESTATEID_SUCCESSFUL = 'DATA_GET_REAL_ESTATES_KEY_FIGURES_WITH_REALESTATEID_SUCCESSFUL'
export const DATA_GET_REAL_ESTATES_KEY_FIGURES_WITH_REALESTATEID_FAIL = 'DATA_GET_REAL_ESTATES_KEY_FIGURES_WITH_REALESTATEID_FAIL'

// Returns the key figures for the real estate
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/realestate.yaml#/Real%20estate/get_real_estates__realEstateId__key_figures
export const getRealEstatesKeyFiguresWithRealEstateIdRequest = (
  requestArguments: getRealEstatesKeyFiguresWithRealEstateIdRequestRealestateArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_REAL_ESTATES_KEY_FIGURES_WITH_REALESTATEID_START',
      successful: 'DATA_GET_REAL_ESTATES_KEY_FIGURES_WITH_REALESTATEID_SUCCESSFUL',
      fail: 'DATA_GET_REAL_ESTATES_KEY_FIGURES_WITH_REALESTATEID_FAIL'
    },
    embeddedType: {
      nonEmbedded: {
        schemaPath: '#/definitions/KeyFigures',
        schemaKey: 'KeyFigures'
      }
    },
    fnName: 'getRealEstatesKeyFiguresWithRealEstateIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/real-estate-api`,
    method: 'GET',
    url: `/real-estates/${requestArguments.path['realEstateId']}/key-figures`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      nonEmbedded: {
        schemaPath: '#/definitions/KeyFigures',
        schemaKey: 'KeyFigures'
      }
    }, 'getRealEstatesKeyFiguresWithRealEstateIdRequest', '/real-estate-api'),
    actions: {
      start: {
        type: DATA_GET_REAL_ESTATES_KEY_FIGURES_WITH_REALESTATEID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_REAL_ESTATES_KEY_FIGURES_WITH_REALESTATEID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_REAL_ESTATES_KEY_FIGURES_WITH_REALESTATEID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getRealEstatesKeyFiguresWithRealEstateIdRequestDefinitions = (definitionsArguments: {| requestArgs: getRealEstatesKeyFiguresWithRealEstateIdRequestRealestateArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getRealEstatesKeyFiguresWithRealEstateIdRequestRealestateArguments, payload?: Object |} => ({
  fnName: 'getRealEstatesKeyFiguresWithRealEstateIdRequest',
  basePath: '/real-estate-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_GET_REAL_ESTATES_PERMISSIONS_WITH_REALESTATEID_START = 'DATA_GET_REAL_ESTATES_PERMISSIONS_WITH_REALESTATEID_START'
export const DATA_GET_REAL_ESTATES_PERMISSIONS_WITH_REALESTATEID_SUCCESSFUL = 'DATA_GET_REAL_ESTATES_PERMISSIONS_WITH_REALESTATEID_SUCCESSFUL'
export const DATA_GET_REAL_ESTATES_PERMISSIONS_WITH_REALESTATEID_FAIL = 'DATA_GET_REAL_ESTATES_PERMISSIONS_WITH_REALESTATEID_FAIL'

// Gets permissions for Users and User Groups that have access to a Real Estate.
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/realestate.yaml#/Real%20estate/get_real_estates__realEstateId__permissions
export const getRealEstatesPermissionsWithRealEstateIdRequest = (
  requestArguments: getRealEstatesPermissionsWithRealEstateIdRequestRealestateArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_REAL_ESTATES_PERMISSIONS_WITH_REALESTATEID_START',
      successful: 'DATA_GET_REAL_ESTATES_PERMISSIONS_WITH_REALESTATEID_SUCCESSFUL',
      fail: 'DATA_GET_REAL_ESTATES_PERMISSIONS_WITH_REALESTATEID_FAIL'
    },
    embeddedType: {
      nonEmbedded: {
        schemaPath: '#/definitions/RealEstatePermissions',
        schemaKey: 'RealEstatePermissions'
      }
    },
    fnName: 'getRealEstatesPermissionsWithRealEstateIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/real-estate-api`,
    method: 'GET',
    url: `/real-estates/${requestArguments.path['realEstateId']}/permissions`,
    params: {
      directPermissions: requestArguments.query?.['directPermissions'],
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      nonEmbedded: {
        schemaPath: '#/definitions/RealEstatePermissions',
        schemaKey: 'RealEstatePermissions'
      }
    }, 'getRealEstatesPermissionsWithRealEstateIdRequest', '/real-estate-api'),
    actions: {
      start: {
        type: DATA_GET_REAL_ESTATES_PERMISSIONS_WITH_REALESTATEID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_REAL_ESTATES_PERMISSIONS_WITH_REALESTATEID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_REAL_ESTATES_PERMISSIONS_WITH_REALESTATEID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getRealEstatesPermissionsWithRealEstateIdRequestDefinitions = (definitionsArguments: {| requestArgs: getRealEstatesPermissionsWithRealEstateIdRequestRealestateArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getRealEstatesPermissionsWithRealEstateIdRequestRealestateArguments, payload?: Object |} => ({
  fnName: 'getRealEstatesPermissionsWithRealEstateIdRequest',
  basePath: '/real-estate-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_GET_REAL_ESTATES_PERMISSIONS_SELF_WITH_REALESTATEID_START = 'DATA_GET_REAL_ESTATES_PERMISSIONS_SELF_WITH_REALESTATEID_START'
export const DATA_GET_REAL_ESTATES_PERMISSIONS_SELF_WITH_REALESTATEID_SUCCESSFUL = 'DATA_GET_REAL_ESTATES_PERMISSIONS_SELF_WITH_REALESTATEID_SUCCESSFUL'
export const DATA_GET_REAL_ESTATES_PERMISSIONS_SELF_WITH_REALESTATEID_FAIL = 'DATA_GET_REAL_ESTATES_PERMISSIONS_SELF_WITH_REALESTATEID_FAIL'

// Gets permissions that the current User has on a Real Estate.
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/realestate.yaml#/Real%20estate/get_real_estates__realEstateId__permissions_self
export const getRealEstatesPermissionsSelfWithRealEstateIdRequest = (
  requestArguments: getRealEstatesPermissionsSelfWithRealEstateIdRequestRealestateArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_REAL_ESTATES_PERMISSIONS_SELF_WITH_REALESTATEID_START',
      successful: 'DATA_GET_REAL_ESTATES_PERMISSIONS_SELF_WITH_REALESTATEID_SUCCESSFUL',
      fail: 'DATA_GET_REAL_ESTATES_PERMISSIONS_SELF_WITH_REALESTATEID_FAIL'
    },
    embeddedType: {
      nonEmbedded: {
        schemaPath: '#/definitions/RealEstateSelfPermissions',
        schemaKey: 'RealEstateSelfPermissions'
      }
    },
    fnName: 'getRealEstatesPermissionsSelfWithRealEstateIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/real-estate-api`,
    method: 'GET',
    url: `/real-estates/${requestArguments.path['realEstateId']}/permissions/self`,
    params: {
      directPermissions: requestArguments.query?.['directPermissions'],
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      nonEmbedded: {
        schemaPath: '#/definitions/RealEstateSelfPermissions',
        schemaKey: 'RealEstateSelfPermissions'
      }
    }, 'getRealEstatesPermissionsSelfWithRealEstateIdRequest', '/real-estate-api'),
    actions: {
      start: {
        type: DATA_GET_REAL_ESTATES_PERMISSIONS_SELF_WITH_REALESTATEID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_REAL_ESTATES_PERMISSIONS_SELF_WITH_REALESTATEID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_REAL_ESTATES_PERMISSIONS_SELF_WITH_REALESTATEID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getRealEstatesPermissionsSelfWithRealEstateIdRequestDefinitions = (definitionsArguments: {| requestArgs: getRealEstatesPermissionsSelfWithRealEstateIdRequestRealestateArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getRealEstatesPermissionsSelfWithRealEstateIdRequestRealestateArguments, payload?: Object |} => ({
  fnName: 'getRealEstatesPermissionsSelfWithRealEstateIdRequest',
  basePath: '/real-estate-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_GET_REAL_ESTATES_USER_GROUPS_WITH_REALESTATEID_START = 'DATA_GET_REAL_ESTATES_USER_GROUPS_WITH_REALESTATEID_START'
export const DATA_GET_REAL_ESTATES_USER_GROUPS_WITH_REALESTATEID_SUCCESSFUL = 'DATA_GET_REAL_ESTATES_USER_GROUPS_WITH_REALESTATEID_SUCCESSFUL'
export const DATA_GET_REAL_ESTATES_USER_GROUPS_WITH_REALESTATEID_FAIL = 'DATA_GET_REAL_ESTATES_USER_GROUPS_WITH_REALESTATEID_FAIL'

// Gets User Groups created for a Real Estate.
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/realestate.yaml#/Real%20estate/get_real_estates__realEstateId__user_groups
export const getRealEstatesUserGroupsWithRealEstateIdRequest = (
  requestArguments: getRealEstatesUserGroupsWithRealEstateIdRequestRealestateArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_REAL_ESTATES_USER_GROUPS_WITH_REALESTATEID_START',
      successful: 'DATA_GET_REAL_ESTATES_USER_GROUPS_WITH_REALESTATEID_SUCCESSFUL',
      fail: 'DATA_GET_REAL_ESTATES_USER_GROUPS_WITH_REALESTATEID_FAIL'
    },
    embeddedType: {
      nonEmbedded: {
        schemaPath: '#/definitions/RealEstateUserGroups',
        schemaKey: 'RealEstateUserGroups'
      }
    },
    fnName: 'getRealEstatesUserGroupsWithRealEstateIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/real-estate-api`,
    method: 'GET',
    url: `/real-estates/${requestArguments.path['realEstateId']}/user-groups`,
    params: {
      embedPermissions: requestArguments.query?.['embedPermissions'],
      embedUsers: requestArguments.query?.['embedUsers'],
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      nonEmbedded: {
        schemaPath: '#/definitions/RealEstateUserGroups',
        schemaKey: 'RealEstateUserGroups'
      }
    }, 'getRealEstatesUserGroupsWithRealEstateIdRequest', '/real-estate-api'),
    actions: {
      start: {
        type: DATA_GET_REAL_ESTATES_USER_GROUPS_WITH_REALESTATEID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_REAL_ESTATES_USER_GROUPS_WITH_REALESTATEID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_REAL_ESTATES_USER_GROUPS_WITH_REALESTATEID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getRealEstatesUserGroupsWithRealEstateIdRequestDefinitions = (definitionsArguments: {| requestArgs: getRealEstatesUserGroupsWithRealEstateIdRequestRealestateArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getRealEstatesUserGroupsWithRealEstateIdRequestRealestateArguments, payload?: Object |} => ({
  fnName: 'getRealEstatesUserGroupsWithRealEstateIdRequest',
  basePath: '/real-estate-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_GET_REAL_ESTATES_USER_GROUPS_WITH_USERGROUPID_START = 'DATA_GET_REAL_ESTATES_USER_GROUPS_WITH_USERGROUPID_START'
export const DATA_GET_REAL_ESTATES_USER_GROUPS_WITH_USERGROUPID_SUCCESSFUL = 'DATA_GET_REAL_ESTATES_USER_GROUPS_WITH_USERGROUPID_SUCCESSFUL'
export const DATA_GET_REAL_ESTATES_USER_GROUPS_WITH_USERGROUPID_FAIL = 'DATA_GET_REAL_ESTATES_USER_GROUPS_WITH_USERGROUPID_FAIL'

// Returns a single User Group of a Real estate.
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/realestate.yaml#/Real%20estate/get_real_estates__realEstateId__user_groups__userGroupId_
export const getRealEstatesUserGroupsWithUserGroupIdRequest = (
  requestArguments: getRealEstatesUserGroupsWithUserGroupIdRequestRealestateArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_REAL_ESTATES_USER_GROUPS_WITH_USERGROUPID_START',
      successful: 'DATA_GET_REAL_ESTATES_USER_GROUPS_WITH_USERGROUPID_SUCCESSFUL',
      fail: 'DATA_GET_REAL_ESTATES_USER_GROUPS_WITH_USERGROUPID_FAIL'
    },
    embeddedType: {
      nonEmbedded: {
        schemaPath: '#/definitions/UserGroup',
        schemaKey: 'UserGroup'
      }
    },
    fnName: 'getRealEstatesUserGroupsWithUserGroupIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/real-estate-api`,
    method: 'GET',
    url: `/real-estates/${requestArguments.path['realEstateId']}/user-groups/${requestArguments.path['userGroupId']}`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      nonEmbedded: {
        schemaPath: '#/definitions/UserGroup',
        schemaKey: 'UserGroup'
      }
    }, 'getRealEstatesUserGroupsWithUserGroupIdRequest', '/real-estate-api'),
    actions: {
      start: {
        type: DATA_GET_REAL_ESTATES_USER_GROUPS_WITH_USERGROUPID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_REAL_ESTATES_USER_GROUPS_WITH_USERGROUPID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_REAL_ESTATES_USER_GROUPS_WITH_USERGROUPID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getRealEstatesUserGroupsWithUserGroupIdRequestDefinitions = (definitionsArguments: {| requestArgs: getRealEstatesUserGroupsWithUserGroupIdRequestRealestateArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getRealEstatesUserGroupsWithUserGroupIdRequestRealestateArguments, payload?: Object |} => ({
  fnName: 'getRealEstatesUserGroupsWithUserGroupIdRequest',
  basePath: '/real-estate-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_GET_REAL_ESTATES_USER_GROUPS_USERS_WITH_USERGROUPID_START = 'DATA_GET_REAL_ESTATES_USER_GROUPS_USERS_WITH_USERGROUPID_START'
export const DATA_GET_REAL_ESTATES_USER_GROUPS_USERS_WITH_USERGROUPID_SUCCESSFUL = 'DATA_GET_REAL_ESTATES_USER_GROUPS_USERS_WITH_USERGROUPID_SUCCESSFUL'
export const DATA_GET_REAL_ESTATES_USER_GROUPS_USERS_WITH_USERGROUPID_FAIL = 'DATA_GET_REAL_ESTATES_USER_GROUPS_USERS_WITH_USERGROUPID_FAIL'

// Returns Users of a Real estate User Group.
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/realestate.yaml#/Real%20estate/get_real_estates__realEstateId__user_groups__userGroupId__users
export const getRealEstatesUserGroupsUsersWithUserGroupIdRequest = (
  requestArguments: getRealEstatesUserGroupsUsersWithUserGroupIdRequestRealestateArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_REAL_ESTATES_USER_GROUPS_USERS_WITH_USERGROUPID_START',
      successful: 'DATA_GET_REAL_ESTATES_USER_GROUPS_USERS_WITH_USERGROUPID_SUCCESSFUL',
      fail: 'DATA_GET_REAL_ESTATES_USER_GROUPS_USERS_WITH_USERGROUPID_FAIL'
    },
    embeddedType: {
      nonEmbedded: {
        schemaKey: null
      }
    },
    fnName: 'getRealEstatesUserGroupsUsersWithUserGroupIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/real-estate-api`,
    method: 'GET',
    url: `/real-estates/${requestArguments.path['realEstateId']}/user-groups/${requestArguments.path['userGroupId']}/users`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      nonEmbedded: {
        schemaKey: null
      }
    }, 'getRealEstatesUserGroupsUsersWithUserGroupIdRequest', '/real-estate-api'),
    actions: {
      start: {
        type: DATA_GET_REAL_ESTATES_USER_GROUPS_USERS_WITH_USERGROUPID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_REAL_ESTATES_USER_GROUPS_USERS_WITH_USERGROUPID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_REAL_ESTATES_USER_GROUPS_USERS_WITH_USERGROUPID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getRealEstatesUserGroupsUsersWithUserGroupIdRequestDefinitions = (definitionsArguments: {| requestArgs: getRealEstatesUserGroupsUsersWithUserGroupIdRequestRealestateArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getRealEstatesUserGroupsUsersWithUserGroupIdRequestRealestateArguments, payload?: Object |} => ({
  fnName: 'getRealEstatesUserGroupsUsersWithUserGroupIdRequest',
  basePath: '/real-estate-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_POST_REAL_ESTATES_USER_GROUPS_USERS_WITH_USERID_START = 'DATA_POST_REAL_ESTATES_USER_GROUPS_USERS_WITH_USERID_START'
export const DATA_POST_REAL_ESTATES_USER_GROUPS_USERS_WITH_USERID_SUCCESSFUL = 'DATA_POST_REAL_ESTATES_USER_GROUPS_USERS_WITH_USERID_SUCCESSFUL'
export const DATA_POST_REAL_ESTATES_USER_GROUPS_USERS_WITH_USERID_FAIL = 'DATA_POST_REAL_ESTATES_USER_GROUPS_USERS_WITH_USERID_FAIL'

// Adds a User to a Real estate User Group.
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/realestate.yaml#/Real%20estate/post_real_estates__realEstateId__user_groups__userGroupId__users__userId_
export const postRealEstatesUserGroupsUsersWithUserIdRequest = (
  requestArguments: postRealEstatesUserGroupsUsersWithUserIdRequestRealestateArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_POST_REAL_ESTATES_USER_GROUPS_USERS_WITH_USERID_START',
      successful: 'DATA_POST_REAL_ESTATES_USER_GROUPS_USERS_WITH_USERID_SUCCESSFUL',
      fail: 'DATA_POST_REAL_ESTATES_USER_GROUPS_USERS_WITH_USERID_FAIL'
    },
    embeddedType: null,
    fnName: 'postRealEstatesUserGroupsUsersWithUserIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/real-estate-api`,
    method: 'POST',
    url: `/real-estates/${requestArguments.path['realEstateId']}/user-groups/${requestArguments.path['userGroupId']}/users/${requestArguments.path['userId']}`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'postRealEstatesUserGroupsUsersWithUserIdRequest', '/real-estate-api'),
    actions: {
      start: {
        type: DATA_POST_REAL_ESTATES_USER_GROUPS_USERS_WITH_USERID_START, payload, config, meta
      },
      successful: {
        type: DATA_POST_REAL_ESTATES_USER_GROUPS_USERS_WITH_USERID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_POST_REAL_ESTATES_USER_GROUPS_USERS_WITH_USERID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_DELETE_REAL_ESTATES_USER_GROUPS_USERS_WITH_USERID_START = 'DATA_DELETE_REAL_ESTATES_USER_GROUPS_USERS_WITH_USERID_START'
export const DATA_DELETE_REAL_ESTATES_USER_GROUPS_USERS_WITH_USERID_SUCCESSFUL = 'DATA_DELETE_REAL_ESTATES_USER_GROUPS_USERS_WITH_USERID_SUCCESSFUL'
export const DATA_DELETE_REAL_ESTATES_USER_GROUPS_USERS_WITH_USERID_FAIL = 'DATA_DELETE_REAL_ESTATES_USER_GROUPS_USERS_WITH_USERID_FAIL'

// Removes a User from a Real estate User Group.
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/realestate.yaml#/Real%20estate/delete_real_estates__realEstateId__user_groups__userGroupId__users__userId_
export const deleteRealEstatesUserGroupsUsersWithUserIdRequest = (
  requestArguments: deleteRealEstatesUserGroupsUsersWithUserIdRequestRealestateArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_DELETE_REAL_ESTATES_USER_GROUPS_USERS_WITH_USERID_START',
      successful: 'DATA_DELETE_REAL_ESTATES_USER_GROUPS_USERS_WITH_USERID_SUCCESSFUL',
      fail: 'DATA_DELETE_REAL_ESTATES_USER_GROUPS_USERS_WITH_USERID_FAIL'
    },
    embeddedType: null,
    fnName: 'deleteRealEstatesUserGroupsUsersWithUserIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/real-estate-api`,
    method: 'DELETE',
    url: `/real-estates/${requestArguments.path['realEstateId']}/user-groups/${requestArguments.path['userGroupId']}/users/${requestArguments.path['userId']}`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'deleteRealEstatesUserGroupsUsersWithUserIdRequest', '/real-estate-api'),
    actions: {
      start: {
        type: DATA_DELETE_REAL_ESTATES_USER_GROUPS_USERS_WITH_USERID_START, payload, config, meta
      },
      successful: {
        type: DATA_DELETE_REAL_ESTATES_USER_GROUPS_USERS_WITH_USERID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_DELETE_REAL_ESTATES_USER_GROUPS_USERS_WITH_USERID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_GET_REAL_ESTATES_USERS_WITH_REALESTATEID_START = 'DATA_GET_REAL_ESTATES_USERS_WITH_REALESTATEID_START'
export const DATA_GET_REAL_ESTATES_USERS_WITH_REALESTATEID_SUCCESSFUL = 'DATA_GET_REAL_ESTATES_USERS_WITH_REALESTATEID_SUCCESSFUL'
export const DATA_GET_REAL_ESTATES_USERS_WITH_REALESTATEID_FAIL = 'DATA_GET_REAL_ESTATES_USERS_WITH_REALESTATEID_FAIL'

// Returns Users of a Real Estate.
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/realestate.yaml#/Real%20estate/get_real_estates__realEstateId__users
export const getRealEstatesUsersWithRealEstateIdRequest = (
  requestArguments: getRealEstatesUsersWithRealEstateIdRequestRealestateArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_REAL_ESTATES_USERS_WITH_REALESTATEID_START',
      successful: 'DATA_GET_REAL_ESTATES_USERS_WITH_REALESTATEID_SUCCESSFUL',
      fail: 'DATA_GET_REAL_ESTATES_USERS_WITH_REALESTATEID_FAIL'
    },
    embeddedType: {
      nonEmbedded: {
        schemaKey: null
      }
    },
    fnName: 'getRealEstatesUsersWithRealEstateIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/real-estate-api`,
    method: 'GET',
    url: `/real-estates/${requestArguments.path['realEstateId']}/users`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      nonEmbedded: {
        schemaKey: null
      }
    }, 'getRealEstatesUsersWithRealEstateIdRequest', '/real-estate-api'),
    actions: {
      start: {
        type: DATA_GET_REAL_ESTATES_USERS_WITH_REALESTATEID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_REAL_ESTATES_USERS_WITH_REALESTATEID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_REAL_ESTATES_USERS_WITH_REALESTATEID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getRealEstatesUsersWithRealEstateIdRequestDefinitions = (definitionsArguments: {| requestArgs: getRealEstatesUsersWithRealEstateIdRequestRealestateArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getRealEstatesUsersWithRealEstateIdRequestRealestateArguments, payload?: Object |} => ({
  fnName: 'getRealEstatesUsersWithRealEstateIdRequest',
  basePath: '/real-estate-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_GET_REAL_ESTATES_LICENSE_WITH_REALESTATEID_START = 'DATA_GET_REAL_ESTATES_LICENSE_WITH_REALESTATEID_START'
export const DATA_GET_REAL_ESTATES_LICENSE_WITH_REALESTATEID_SUCCESSFUL = 'DATA_GET_REAL_ESTATES_LICENSE_WITH_REALESTATEID_SUCCESSFUL'
export const DATA_GET_REAL_ESTATES_LICENSE_WITH_REALESTATEID_FAIL = 'DATA_GET_REAL_ESTATES_LICENSE_WITH_REALESTATEID_FAIL'

// Returns the License of a Real Estate.
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/realestate.yaml#/Real%20estate/get_real_estates__realEstateId__license
export const getRealEstatesLicenseWithRealEstateIdRequest = (
  requestArguments: getRealEstatesLicenseWithRealEstateIdRequestRealestateArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_REAL_ESTATES_LICENSE_WITH_REALESTATEID_START',
      successful: 'DATA_GET_REAL_ESTATES_LICENSE_WITH_REALESTATEID_SUCCESSFUL',
      fail: 'DATA_GET_REAL_ESTATES_LICENSE_WITH_REALESTATEID_FAIL'
    },
    embeddedType: {
      nonEmbedded: {
        schemaPath: '#/definitions/RealEstateLicense',
        schemaKey: 'RealEstateLicense'
      }
    },
    fnName: 'getRealEstatesLicenseWithRealEstateIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/real-estate-api`,
    method: 'GET',
    url: `/real-estates/${requestArguments.path['realEstateId']}/license`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      nonEmbedded: {
        schemaPath: '#/definitions/RealEstateLicense',
        schemaKey: 'RealEstateLicense'
      }
    }, 'getRealEstatesLicenseWithRealEstateIdRequest', '/real-estate-api'),
    actions: {
      start: {
        type: DATA_GET_REAL_ESTATES_LICENSE_WITH_REALESTATEID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_REAL_ESTATES_LICENSE_WITH_REALESTATEID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_REAL_ESTATES_LICENSE_WITH_REALESTATEID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getRealEstatesLicenseWithRealEstateIdRequestDefinitions = (definitionsArguments: {| requestArgs: getRealEstatesLicenseWithRealEstateIdRequestRealestateArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getRealEstatesLicenseWithRealEstateIdRequestRealestateArguments, payload?: Object |} => ({
  fnName: 'getRealEstatesLicenseWithRealEstateIdRequest',
  basePath: '/real-estate-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_GET_REAL_ESTATES_MODULES_WITH_REALESTATEID_START = 'DATA_GET_REAL_ESTATES_MODULES_WITH_REALESTATEID_START'
export const DATA_GET_REAL_ESTATES_MODULES_WITH_REALESTATEID_SUCCESSFUL = 'DATA_GET_REAL_ESTATES_MODULES_WITH_REALESTATEID_SUCCESSFUL'
export const DATA_GET_REAL_ESTATES_MODULES_WITH_REALESTATEID_FAIL = 'DATA_GET_REAL_ESTATES_MODULES_WITH_REALESTATEID_FAIL'

// Returns a list of Modules that the Real Estate has a license to.
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/realestate.yaml#/Real%20estate/get_real_estates__realEstateId__modules
export const getRealEstatesModulesWithRealEstateIdRequest = (
  requestArguments: getRealEstatesModulesWithRealEstateIdRequestRealestateArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_REAL_ESTATES_MODULES_WITH_REALESTATEID_START',
      successful: 'DATA_GET_REAL_ESTATES_MODULES_WITH_REALESTATEID_SUCCESSFUL',
      fail: 'DATA_GET_REAL_ESTATES_MODULES_WITH_REALESTATEID_FAIL'
    },
    embeddedType: {
      nonEmbedded: {
        schemaKey: null
      }
    },
    fnName: 'getRealEstatesModulesWithRealEstateIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/real-estate-api`,
    method: 'GET',
    url: `/real-estates/${requestArguments.path['realEstateId']}/modules`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      nonEmbedded: {
        schemaKey: null
      }
    }, 'getRealEstatesModulesWithRealEstateIdRequest', '/real-estate-api'),
    actions: {
      start: {
        type: DATA_GET_REAL_ESTATES_MODULES_WITH_REALESTATEID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_REAL_ESTATES_MODULES_WITH_REALESTATEID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_REAL_ESTATES_MODULES_WITH_REALESTATEID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getRealEstatesModulesWithRealEstateIdRequestDefinitions = (definitionsArguments: {| requestArgs: getRealEstatesModulesWithRealEstateIdRequestRealestateArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getRealEstatesModulesWithRealEstateIdRequestRealestateArguments, payload?: Object |} => ({
  fnName: 'getRealEstatesModulesWithRealEstateIdRequest',
  basePath: '/real-estate-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_GET_REAL_ESTATES_MODULE_FEATURES_WITH_REALESTATEID_START = 'DATA_GET_REAL_ESTATES_MODULE_FEATURES_WITH_REALESTATEID_START'
export const DATA_GET_REAL_ESTATES_MODULE_FEATURES_WITH_REALESTATEID_SUCCESSFUL = 'DATA_GET_REAL_ESTATES_MODULE_FEATURES_WITH_REALESTATEID_SUCCESSFUL'
export const DATA_GET_REAL_ESTATES_MODULE_FEATURES_WITH_REALESTATEID_FAIL = 'DATA_GET_REAL_ESTATES_MODULE_FEATURES_WITH_REALESTATEID_FAIL'

// Returns a list of Module Features that the Real Estate has a license to.
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/realestate.yaml#/Real%20estate/get_real_estates__realEstateId__module_features
export const getRealEstatesModuleFeaturesWithRealEstateIdRequest = (
  requestArguments: getRealEstatesModuleFeaturesWithRealEstateIdRequestRealestateArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_REAL_ESTATES_MODULE_FEATURES_WITH_REALESTATEID_START',
      successful: 'DATA_GET_REAL_ESTATES_MODULE_FEATURES_WITH_REALESTATEID_SUCCESSFUL',
      fail: 'DATA_GET_REAL_ESTATES_MODULE_FEATURES_WITH_REALESTATEID_FAIL'
    },
    embeddedType: {
      nonEmbedded: {
        schemaKey: null
      }
    },
    fnName: 'getRealEstatesModuleFeaturesWithRealEstateIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/real-estate-api`,
    method: 'GET',
    url: `/real-estates/${requestArguments.path['realEstateId']}/module-features`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      nonEmbedded: {
        schemaKey: null
      }
    }, 'getRealEstatesModuleFeaturesWithRealEstateIdRequest', '/real-estate-api'),
    actions: {
      start: {
        type: DATA_GET_REAL_ESTATES_MODULE_FEATURES_WITH_REALESTATEID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_REAL_ESTATES_MODULE_FEATURES_WITH_REALESTATEID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_REAL_ESTATES_MODULE_FEATURES_WITH_REALESTATEID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getRealEstatesModuleFeaturesWithRealEstateIdRequestDefinitions = (definitionsArguments: {| requestArgs: getRealEstatesModuleFeaturesWithRealEstateIdRequestRealestateArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getRealEstatesModuleFeaturesWithRealEstateIdRequestRealestateArguments, payload?: Object |} => ({
  fnName: 'getRealEstatesModuleFeaturesWithRealEstateIdRequest',
  basePath: '/real-estate-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_GET_REAL_ESTATES_SIMULATION_RESULTS_WITH_REALESTATEID_START = 'DATA_GET_REAL_ESTATES_SIMULATION_RESULTS_WITH_REALESTATEID_START'
export const DATA_GET_REAL_ESTATES_SIMULATION_RESULTS_WITH_REALESTATEID_SUCCESSFUL = 'DATA_GET_REAL_ESTATES_SIMULATION_RESULTS_WITH_REALESTATEID_SUCCESSFUL'
export const DATA_GET_REAL_ESTATES_SIMULATION_RESULTS_WITH_REALESTATEID_FAIL = 'DATA_GET_REAL_ESTATES_SIMULATION_RESULTS_WITH_REALESTATEID_FAIL'

// Returns a list of Simulation Results that the Real Estate has a license to.
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/realestate.yaml#/Real%20estate/get_real_estates__realEstateId__simulation_results
export const getRealEstatesSimulationResultsWithRealEstateIdRequest = (
  requestArguments: getRealEstatesSimulationResultsWithRealEstateIdRequestRealestateArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_REAL_ESTATES_SIMULATION_RESULTS_WITH_REALESTATEID_START',
      successful: 'DATA_GET_REAL_ESTATES_SIMULATION_RESULTS_WITH_REALESTATEID_SUCCESSFUL',
      fail: 'DATA_GET_REAL_ESTATES_SIMULATION_RESULTS_WITH_REALESTATEID_FAIL'
    },
    embeddedType: {
      nonEmbedded: {
        schemaKey: null
      }
    },
    fnName: 'getRealEstatesSimulationResultsWithRealEstateIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/real-estate-api`,
    method: 'GET',
    url: `/real-estates/${requestArguments.path['realEstateId']}/simulation-results`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      nonEmbedded: {
        schemaKey: null
      }
    }, 'getRealEstatesSimulationResultsWithRealEstateIdRequest', '/real-estate-api'),
    actions: {
      start: {
        type: DATA_GET_REAL_ESTATES_SIMULATION_RESULTS_WITH_REALESTATEID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_REAL_ESTATES_SIMULATION_RESULTS_WITH_REALESTATEID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_REAL_ESTATES_SIMULATION_RESULTS_WITH_REALESTATEID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getRealEstatesSimulationResultsWithRealEstateIdRequestDefinitions = (definitionsArguments: {| requestArgs: getRealEstatesSimulationResultsWithRealEstateIdRequestRealestateArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getRealEstatesSimulationResultsWithRealEstateIdRequestRealestateArguments, payload?: Object |} => ({
  fnName: 'getRealEstatesSimulationResultsWithRealEstateIdRequest',
  basePath: '/real-estate-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_GET_REAL_ESTATES_FUNCTIONAL_SECTORS_WITH_REALESTATEID_START = 'DATA_GET_REAL_ESTATES_FUNCTIONAL_SECTORS_WITH_REALESTATEID_START'
export const DATA_GET_REAL_ESTATES_FUNCTIONAL_SECTORS_WITH_REALESTATEID_SUCCESSFUL = 'DATA_GET_REAL_ESTATES_FUNCTIONAL_SECTORS_WITH_REALESTATEID_SUCCESSFUL'
export const DATA_GET_REAL_ESTATES_FUNCTIONAL_SECTORS_WITH_REALESTATEID_FAIL = 'DATA_GET_REAL_ESTATES_FUNCTIONAL_SECTORS_WITH_REALESTATEID_FAIL'

// Returns a list of Functional Sectors that the Real Estate has a license to.
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/realestate.yaml#/Real%20estate/get_real_estates__realEstateId__functional_sectors
export const getRealEstatesFunctionalSectorsWithRealEstateIdRequest = (
  requestArguments: getRealEstatesFunctionalSectorsWithRealEstateIdRequestRealestateArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_REAL_ESTATES_FUNCTIONAL_SECTORS_WITH_REALESTATEID_START',
      successful: 'DATA_GET_REAL_ESTATES_FUNCTIONAL_SECTORS_WITH_REALESTATEID_SUCCESSFUL',
      fail: 'DATA_GET_REAL_ESTATES_FUNCTIONAL_SECTORS_WITH_REALESTATEID_FAIL'
    },
    embeddedType: {
      nonEmbedded: {
        schemaKey: null
      }
    },
    fnName: 'getRealEstatesFunctionalSectorsWithRealEstateIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/real-estate-api`,
    method: 'GET',
    url: `/real-estates/${requestArguments.path['realEstateId']}/functional-sectors`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      nonEmbedded: {
        schemaKey: null
      }
    }, 'getRealEstatesFunctionalSectorsWithRealEstateIdRequest', '/real-estate-api'),
    actions: {
      start: {
        type: DATA_GET_REAL_ESTATES_FUNCTIONAL_SECTORS_WITH_REALESTATEID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_REAL_ESTATES_FUNCTIONAL_SECTORS_WITH_REALESTATEID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_REAL_ESTATES_FUNCTIONAL_SECTORS_WITH_REALESTATEID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getRealEstatesFunctionalSectorsWithRealEstateIdRequestDefinitions = (definitionsArguments: {| requestArgs: getRealEstatesFunctionalSectorsWithRealEstateIdRequestRealestateArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getRealEstatesFunctionalSectorsWithRealEstateIdRequestRealestateArguments, payload?: Object |} => ({
  fnName: 'getRealEstatesFunctionalSectorsWithRealEstateIdRequest',
  basePath: '/real-estate-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_GET_REAL_ESTATE_AVAILABLE_FUNCTIONAL_SECTORS_START = 'DATA_GET_REAL_ESTATE_AVAILABLE_FUNCTIONAL_SECTORS_START'
export const DATA_GET_REAL_ESTATE_AVAILABLE_FUNCTIONAL_SECTORS_SUCCESSFUL = 'DATA_GET_REAL_ESTATE_AVAILABLE_FUNCTIONAL_SECTORS_SUCCESSFUL'
export const DATA_GET_REAL_ESTATE_AVAILABLE_FUNCTIONAL_SECTORS_FAIL = 'DATA_GET_REAL_ESTATE_AVAILABLE_FUNCTIONAL_SECTORS_FAIL'

// Returns functional sector available for a given account
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/realestate.yaml#/Real%20estate/get_real_estate_available_functional_sectors
export const getRealEstateAvailableFunctionalSectorsRequest = (
  payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_REAL_ESTATE_AVAILABLE_FUNCTIONAL_SECTORS_START',
      successful: 'DATA_GET_REAL_ESTATE_AVAILABLE_FUNCTIONAL_SECTORS_SUCCESSFUL',
      fail: 'DATA_GET_REAL_ESTATE_AVAILABLE_FUNCTIONAL_SECTORS_FAIL'
    },
    embeddedType: {
      nonEmbedded: {
        schemaKey: null
      }
    },
    fnName: 'getRealEstateAvailableFunctionalSectorsRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/real-estate-api`,
    method: 'GET',
    url: '/real-estate/available-functional-sectors',
    params: {
      accountId: options?.selectedAccountId || store.app.selectedAccountId,
      language: options?.languageCode || store.app.languageCode
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      nonEmbedded: {
        schemaKey: null
      }
    }, 'getRealEstateAvailableFunctionalSectorsRequest', '/real-estate-api'),
    actions: {
      start: {
        type: DATA_GET_REAL_ESTATE_AVAILABLE_FUNCTIONAL_SECTORS_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_REAL_ESTATE_AVAILABLE_FUNCTIONAL_SECTORS_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_REAL_ESTATE_AVAILABLE_FUNCTIONAL_SECTORS_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getRealEstateAvailableFunctionalSectorsRequestDefinitions = (definitionsArguments: {| payload?: Object |}): {|
  ...TVDGARConfigWithoutRequestArgs, payload?: Object |} => ({
  fnName: 'getRealEstateAvailableFunctionalSectorsRequest',
  basePath: '/real-estate-api',
  payload: definitionsArguments.payload,
})


export const DATA_GET_PROJECTS_START = 'DATA_GET_PROJECTS_START'
export const DATA_GET_PROJECTS_SUCCESSFUL = 'DATA_GET_PROJECTS_SUCCESSFUL'
export const DATA_GET_PROJECTS_FAIL = 'DATA_GET_PROJECTS_FAIL'

// Returns an embedded list of projects for an Account.
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/realestate.yaml#/Project/get_projects
export const getProjectsRequest = (
  payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_PROJECTS_START',
      successful: 'DATA_GET_PROJECTS_SUCCESSFUL',
      fail: 'DATA_GET_PROJECTS_FAIL'
    },
    embeddedType: {
      nonEmbedded: {
        schemaPath: '#/definitions/ListItemCollection',
        schemaKey: 'ListItemCollection'
      }
    },
    fnName: 'getProjectsRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/real-estate-api`,
    method: 'GET',
    url: '/projects',
    params: {
      accountId: options?.selectedAccountId || store.app.selectedAccountId,
      language: options?.languageCode || store.app.languageCode
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      nonEmbedded: {
        schemaPath: '#/definitions/ListItemCollection',
        schemaKey: 'ListItemCollection'
      }
    }, 'getProjectsRequest', '/real-estate-api'),
    actions: {
      start: {
        type: DATA_GET_PROJECTS_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_PROJECTS_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_PROJECTS_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getProjectsRequestDefinitions = (definitionsArguments: {| payload?: Object |}): {|
  ...TVDGARConfigWithoutRequestArgs, payload?: Object |} => ({
  fnName: 'getProjectsRequest',
  basePath: '/real-estate-api',
  payload: definitionsArguments.payload,
})


export const DATA_POST_PROJECTS_START = 'DATA_POST_PROJECTS_START'
export const DATA_POST_PROJECTS_SUCCESSFUL = 'DATA_POST_PROJECTS_SUCCESSFUL'
export const DATA_POST_PROJECTS_FAIL = 'DATA_POST_PROJECTS_FAIL'

// Create a new project (creates also a site and a building for the created project)
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/realestate.yaml#/Project/post_projects
export const postProjectsRequest = (
  requestArguments: postProjectsRequestRealestateArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_POST_PROJECTS_START',
      successful: 'DATA_POST_PROJECTS_SUCCESSFUL',
      fail: 'DATA_POST_PROJECTS_FAIL'
    },
    embeddedType: null,
    fnName: 'postProjectsRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/real-estate-api`,
    method: 'POST',
    url: '/projects',
    params: {
      accountId: options?.selectedAccountId || store.app.selectedAccountId,
      isPrivate: requestArguments.query?.['isPrivate'],
      language: options?.languageCode || store.app.languageCode
    },
    data: requestArguments.body
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'postProjectsRequest', '/real-estate-api'),
    actions: {
      start: {
        type: DATA_POST_PROJECTS_START, payload, config, meta
      },
      successful: {
        type: DATA_POST_PROJECTS_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_POST_PROJECTS_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_GET_PROJECTS_WITH_PROJECTID_START = 'DATA_GET_PROJECTS_WITH_PROJECTID_START'
export const DATA_GET_PROJECTS_WITH_PROJECTID_SUCCESSFUL = 'DATA_GET_PROJECTS_WITH_PROJECTID_SUCCESSFUL'
export const DATA_GET_PROJECTS_WITH_PROJECTID_FAIL = 'DATA_GET_PROJECTS_WITH_PROJECTID_FAIL'

// Returns the project
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/realestate.yaml#/Project/get_projects__projectId_
export const getProjectsWithProjectIdRequest = (
  requestArguments: getProjectsWithProjectIdRequestRealestateArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_PROJECTS_WITH_PROJECTID_START',
      successful: 'DATA_GET_PROJECTS_WITH_PROJECTID_SUCCESSFUL',
      fail: 'DATA_GET_PROJECTS_WITH_PROJECTID_FAIL'
    },
    embeddedType: {
      nonEmbedded: {
        schemaPath: '#/definitions/Project',
        schemaKey: 'Project'
      }
    },
    fnName: 'getProjectsWithProjectIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/real-estate-api`,
    method: 'GET',
    url: `/projects/${requestArguments.path['projectId']}`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      nonEmbedded: {
        schemaPath: '#/definitions/Project',
        schemaKey: 'Project'
      }
    }, 'getProjectsWithProjectIdRequest', '/real-estate-api'),
    actions: {
      start: {
        type: DATA_GET_PROJECTS_WITH_PROJECTID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_PROJECTS_WITH_PROJECTID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_PROJECTS_WITH_PROJECTID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getProjectsWithProjectIdRequestDefinitions = (definitionsArguments: {| requestArgs: getProjectsWithProjectIdRequestRealestateArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getProjectsWithProjectIdRequestRealestateArguments, payload?: Object |} => ({
  fnName: 'getProjectsWithProjectIdRequest',
  basePath: '/real-estate-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_PATCH_PROJECTS_WITH_PROJECTID_START = 'DATA_PATCH_PROJECTS_WITH_PROJECTID_START'
export const DATA_PATCH_PROJECTS_WITH_PROJECTID_SUCCESSFUL = 'DATA_PATCH_PROJECTS_WITH_PROJECTID_SUCCESSFUL'
export const DATA_PATCH_PROJECTS_WITH_PROJECTID_FAIL = 'DATA_PATCH_PROJECTS_WITH_PROJECTID_FAIL'

// Update project properties.
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/realestate.yaml#/Project/patch_projects__projectId_
export const patchProjectsWithProjectIdRequest = (
  requestArguments: patchProjectsWithProjectIdRequestRealestateArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_PATCH_PROJECTS_WITH_PROJECTID_START',
      successful: 'DATA_PATCH_PROJECTS_WITH_PROJECTID_SUCCESSFUL',
      fail: 'DATA_PATCH_PROJECTS_WITH_PROJECTID_FAIL'
    },
    embeddedType: null,
    fnName: 'patchProjectsWithProjectIdRequest'
  }
  const config = {
    headers: { 'Content-Type': 'application/json' },
    baseURL: `${API_BASE_URL}/real-estate-api`,
    method: 'PATCH',
    url: `/projects/${requestArguments.path['projectId']}`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: requestArguments.body
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'patchProjectsWithProjectIdRequest', '/real-estate-api'),
    actions: {
      start: {
        type: DATA_PATCH_PROJECTS_WITH_PROJECTID_START, payload, config, meta
      },
      successful: {
        type: DATA_PATCH_PROJECTS_WITH_PROJECTID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_PATCH_PROJECTS_WITH_PROJECTID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_DELETE_PROJECTS_WITH_PROJECTID_START = 'DATA_DELETE_PROJECTS_WITH_PROJECTID_START'
export const DATA_DELETE_PROJECTS_WITH_PROJECTID_SUCCESSFUL = 'DATA_DELETE_PROJECTS_WITH_PROJECTID_SUCCESSFUL'
export const DATA_DELETE_PROJECTS_WITH_PROJECTID_FAIL = 'DATA_DELETE_PROJECTS_WITH_PROJECTID_FAIL'

// Delete a project.
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/realestate.yaml#/Project/delete_projects__projectId_
export const deleteProjectsWithProjectIdRequest = (
  requestArguments: deleteProjectsWithProjectIdRequestRealestateArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_DELETE_PROJECTS_WITH_PROJECTID_START',
      successful: 'DATA_DELETE_PROJECTS_WITH_PROJECTID_SUCCESSFUL',
      fail: 'DATA_DELETE_PROJECTS_WITH_PROJECTID_FAIL'
    },
    embeddedType: null,
    fnName: 'deleteProjectsWithProjectIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/real-estate-api`,
    method: 'DELETE',
    url: `/projects/${requestArguments.path['projectId']}`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'deleteProjectsWithProjectIdRequest', '/real-estate-api'),
    actions: {
      start: {
        type: DATA_DELETE_PROJECTS_WITH_PROJECTID_START, payload, config, meta
      },
      successful: {
        type: DATA_DELETE_PROJECTS_WITH_PROJECTID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_DELETE_PROJECTS_WITH_PROJECTID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_GET_PROJECTS_KEY_FIGURES_WITH_PROJECTID_START = 'DATA_GET_PROJECTS_KEY_FIGURES_WITH_PROJECTID_START'
export const DATA_GET_PROJECTS_KEY_FIGURES_WITH_PROJECTID_SUCCESSFUL = 'DATA_GET_PROJECTS_KEY_FIGURES_WITH_PROJECTID_SUCCESSFUL'
export const DATA_GET_PROJECTS_KEY_FIGURES_WITH_PROJECTID_FAIL = 'DATA_GET_PROJECTS_KEY_FIGURES_WITH_PROJECTID_FAIL'

// Returns the key figures for the project
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/realestate.yaml#/Project/get_projects__projectId__key_figures
export const getProjectsKeyFiguresWithProjectIdRequest = (
  requestArguments: getProjectsKeyFiguresWithProjectIdRequestRealestateArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_PROJECTS_KEY_FIGURES_WITH_PROJECTID_START',
      successful: 'DATA_GET_PROJECTS_KEY_FIGURES_WITH_PROJECTID_SUCCESSFUL',
      fail: 'DATA_GET_PROJECTS_KEY_FIGURES_WITH_PROJECTID_FAIL'
    },
    embeddedType: {
      nonEmbedded: {
        schemaPath: '#/definitions/KeyFigures',
        schemaKey: 'KeyFigures'
      }
    },
    fnName: 'getProjectsKeyFiguresWithProjectIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/real-estate-api`,
    method: 'GET',
    url: `/projects/${requestArguments.path['projectId']}/key-figures`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      nonEmbedded: {
        schemaPath: '#/definitions/KeyFigures',
        schemaKey: 'KeyFigures'
      }
    }, 'getProjectsKeyFiguresWithProjectIdRequest', '/real-estate-api'),
    actions: {
      start: {
        type: DATA_GET_PROJECTS_KEY_FIGURES_WITH_PROJECTID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_PROJECTS_KEY_FIGURES_WITH_PROJECTID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_PROJECTS_KEY_FIGURES_WITH_PROJECTID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getProjectsKeyFiguresWithProjectIdRequestDefinitions = (definitionsArguments: {| requestArgs: getProjectsKeyFiguresWithProjectIdRequestRealestateArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getProjectsKeyFiguresWithProjectIdRequestRealestateArguments, payload?: Object |} => ({
  fnName: 'getProjectsKeyFiguresWithProjectIdRequest',
  basePath: '/real-estate-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_GET_REAL_ESTATES_COLUMNS_START = 'DATA_GET_REAL_ESTATES_COLUMNS_START'
export const DATA_GET_REAL_ESTATES_COLUMNS_SUCCESSFUL = 'DATA_GET_REAL_ESTATES_COLUMNS_SUCCESSFUL'
export const DATA_GET_REAL_ESTATES_COLUMNS_FAIL = 'DATA_GET_REAL_ESTATES_COLUMNS_FAIL'

// Get columns for real estate lists
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/realestate.yaml#/Meta/get_real_estates_columns
export const getRealEstatesColumnsRequest = (
  requestArguments: getRealEstatesColumnsRequestRealestateArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_REAL_ESTATES_COLUMNS_START',
      successful: 'DATA_GET_REAL_ESTATES_COLUMNS_SUCCESSFUL',
      fail: 'DATA_GET_REAL_ESTATES_COLUMNS_FAIL'
    },
    embeddedType: {
      nonEmbedded: {
        schemaPath: '#/definitions/Columns',
        schemaKey: 'Columns'
      }
    },
    fnName: 'getRealEstatesColumnsRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/real-estate-api`,
    method: 'GET',
    url: '/real-estates/columns',
    params: {
      view: requestArguments.query['view'],
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      nonEmbedded: {
        schemaPath: '#/definitions/Columns',
        schemaKey: 'Columns'
      }
    }, 'getRealEstatesColumnsRequest', '/real-estate-api'),
    actions: {
      start: {
        type: DATA_GET_REAL_ESTATES_COLUMNS_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_REAL_ESTATES_COLUMNS_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_REAL_ESTATES_COLUMNS_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getRealEstatesColumnsRequestDefinitions = (definitionsArguments: {| requestArgs: getRealEstatesColumnsRequestRealestateArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getRealEstatesColumnsRequestRealestateArguments, payload?: Object |} => ({
  fnName: 'getRealEstatesColumnsRequest',
  basePath: '/real-estate-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_GET_PROJECTS_COLUMNS_START = 'DATA_GET_PROJECTS_COLUMNS_START'
export const DATA_GET_PROJECTS_COLUMNS_SUCCESSFUL = 'DATA_GET_PROJECTS_COLUMNS_SUCCESSFUL'
export const DATA_GET_PROJECTS_COLUMNS_FAIL = 'DATA_GET_PROJECTS_COLUMNS_FAIL'

// Get columns for project lists
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/realestate.yaml#/Meta/get_projects_columns
export const getProjectsColumnsRequest = (
  requestArguments: getProjectsColumnsRequestRealestateArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_PROJECTS_COLUMNS_START',
      successful: 'DATA_GET_PROJECTS_COLUMNS_SUCCESSFUL',
      fail: 'DATA_GET_PROJECTS_COLUMNS_FAIL'
    },
    embeddedType: {
      nonEmbedded: {
        schemaPath: '#/definitions/Columns',
        schemaKey: 'Columns'
      }
    },
    fnName: 'getProjectsColumnsRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/real-estate-api`,
    method: 'GET',
    url: '/projects/columns',
    params: {
      view: requestArguments.query['view'],
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      nonEmbedded: {
        schemaPath: '#/definitions/Columns',
        schemaKey: 'Columns'
      }
    }, 'getProjectsColumnsRequest', '/real-estate-api'),
    actions: {
      start: {
        type: DATA_GET_PROJECTS_COLUMNS_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_PROJECTS_COLUMNS_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_PROJECTS_COLUMNS_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getProjectsColumnsRequestDefinitions = (definitionsArguments: {| requestArgs: getProjectsColumnsRequestRealestateArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getProjectsColumnsRequestRealestateArguments, payload?: Object |} => ({
  fnName: 'getProjectsColumnsRequest',
  basePath: '/real-estate-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_GET_BUILDINGS_WITH_BUILDINGID_START = 'DATA_GET_BUILDINGS_WITH_BUILDINGID_START'
export const DATA_GET_BUILDINGS_WITH_BUILDINGID_SUCCESSFUL = 'DATA_GET_BUILDINGS_WITH_BUILDINGID_SUCCESSFUL'
export const DATA_GET_BUILDINGS_WITH_BUILDINGID_FAIL = 'DATA_GET_BUILDINGS_WITH_BUILDINGID_FAIL'

// Returns the building for the real estate
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/realestate.yaml#/Building/get_buildings__buildingId_
export const getBuildingsWithBuildingIdRequest = (
  requestArguments: getBuildingsWithBuildingIdRequestRealestateArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_BUILDINGS_WITH_BUILDINGID_START',
      successful: 'DATA_GET_BUILDINGS_WITH_BUILDINGID_SUCCESSFUL',
      fail: 'DATA_GET_BUILDINGS_WITH_BUILDINGID_FAIL'
    },
    embeddedType: {
      nonEmbedded: {
        schemaPath: '#/definitions/Building',
        schemaKey: 'Building'
      }
    },
    fnName: 'getBuildingsWithBuildingIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/real-estate-api`,
    method: 'GET',
    url: `/buildings/${requestArguments.path['buildingId']}`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      nonEmbedded: {
        schemaPath: '#/definitions/Building',
        schemaKey: 'Building'
      }
    }, 'getBuildingsWithBuildingIdRequest', '/real-estate-api'),
    actions: {
      start: {
        type: DATA_GET_BUILDINGS_WITH_BUILDINGID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_BUILDINGS_WITH_BUILDINGID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_BUILDINGS_WITH_BUILDINGID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getBuildingsWithBuildingIdRequestDefinitions = (definitionsArguments: {| requestArgs: getBuildingsWithBuildingIdRequestRealestateArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getBuildingsWithBuildingIdRequestRealestateArguments, payload?: Object |} => ({
  fnName: 'getBuildingsWithBuildingIdRequest',
  basePath: '/real-estate-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_PUT_BUILDINGS_PRIMARY_ABE_ESTIMATE_ID_WITH_BUILDINGID_START = 'DATA_PUT_BUILDINGS_PRIMARY_ABE_ESTIMATE_ID_WITH_BUILDINGID_START'
export const DATA_PUT_BUILDINGS_PRIMARY_ABE_ESTIMATE_ID_WITH_BUILDINGID_SUCCESSFUL = 'DATA_PUT_BUILDINGS_PRIMARY_ABE_ESTIMATE_ID_WITH_BUILDINGID_SUCCESSFUL'
export const DATA_PUT_BUILDINGS_PRIMARY_ABE_ESTIMATE_ID_WITH_BUILDINGID_FAIL = 'DATA_PUT_BUILDINGS_PRIMARY_ABE_ESTIMATE_ID_WITH_BUILDINGID_FAIL'

// Set primary ABE estimate for building
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/realestate.yaml#/Building/put_buildings__buildingId__primary_abe_estimate_id
export const putBuildingsPrimaryAbeEstimateIdWithBuildingIdRequest = (
  requestArguments: putBuildingsPrimaryAbeEstimateIdWithBuildingIdRequestRealestateArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_PUT_BUILDINGS_PRIMARY_ABE_ESTIMATE_ID_WITH_BUILDINGID_START',
      successful: 'DATA_PUT_BUILDINGS_PRIMARY_ABE_ESTIMATE_ID_WITH_BUILDINGID_SUCCESSFUL',
      fail: 'DATA_PUT_BUILDINGS_PRIMARY_ABE_ESTIMATE_ID_WITH_BUILDINGID_FAIL'
    },
    embeddedType: null,
    fnName: 'putBuildingsPrimaryAbeEstimateIdWithBuildingIdRequest'
  }
  const config = {
    headers: { 'Content-Type': 'application/json' },
    baseURL: `${API_BASE_URL}/real-estate-api`,
    method: 'PUT',
    url: `/buildings/${requestArguments.path['buildingId']}/primary-abe-estimate/id`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: `"${requestArguments.body}"`
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'putBuildingsPrimaryAbeEstimateIdWithBuildingIdRequest', '/real-estate-api'),
    actions: {
      start: {
        type: DATA_PUT_BUILDINGS_PRIMARY_ABE_ESTIMATE_ID_WITH_BUILDINGID_START, payload, config, meta
      },
      successful: {
        type: DATA_PUT_BUILDINGS_PRIMARY_ABE_ESTIMATE_ID_WITH_BUILDINGID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_PUT_BUILDINGS_PRIMARY_ABE_ESTIMATE_ID_WITH_BUILDINGID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_PUT_BUILDINGS_PRIMARY_SPACES_ESTIMATE_ID_WITH_BUILDINGID_START = 'DATA_PUT_BUILDINGS_PRIMARY_SPACES_ESTIMATE_ID_WITH_BUILDINGID_START'
export const DATA_PUT_BUILDINGS_PRIMARY_SPACES_ESTIMATE_ID_WITH_BUILDINGID_SUCCESSFUL = 'DATA_PUT_BUILDINGS_PRIMARY_SPACES_ESTIMATE_ID_WITH_BUILDINGID_SUCCESSFUL'
export const DATA_PUT_BUILDINGS_PRIMARY_SPACES_ESTIMATE_ID_WITH_BUILDINGID_FAIL = 'DATA_PUT_BUILDINGS_PRIMARY_SPACES_ESTIMATE_ID_WITH_BUILDINGID_FAIL'

// Set primary spaces estimate for building
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/realestate.yaml#/Building/put_buildings__buildingId__primary_spaces_estimate_id
export const putBuildingsPrimarySpacesEstimateIdWithBuildingIdRequest = (
  requestArguments: putBuildingsPrimarySpacesEstimateIdWithBuildingIdRequestRealestateArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_PUT_BUILDINGS_PRIMARY_SPACES_ESTIMATE_ID_WITH_BUILDINGID_START',
      successful: 'DATA_PUT_BUILDINGS_PRIMARY_SPACES_ESTIMATE_ID_WITH_BUILDINGID_SUCCESSFUL',
      fail: 'DATA_PUT_BUILDINGS_PRIMARY_SPACES_ESTIMATE_ID_WITH_BUILDINGID_FAIL'
    },
    embeddedType: null,
    fnName: 'putBuildingsPrimarySpacesEstimateIdWithBuildingIdRequest'
  }
  const config = {
    headers: { 'Content-Type': 'application/json' },
    baseURL: `${API_BASE_URL}/real-estate-api`,
    method: 'PUT',
    url: `/buildings/${requestArguments.path['buildingId']}/primary-spaces-estimate/id`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: `"${requestArguments.body}"`
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'putBuildingsPrimarySpacesEstimateIdWithBuildingIdRequest', '/real-estate-api'),
    actions: {
      start: {
        type: DATA_PUT_BUILDINGS_PRIMARY_SPACES_ESTIMATE_ID_WITH_BUILDINGID_START, payload, config, meta
      },
      successful: {
        type: DATA_PUT_BUILDINGS_PRIMARY_SPACES_ESTIMATE_ID_WITH_BUILDINGID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_PUT_BUILDINGS_PRIMARY_SPACES_ESTIMATE_ID_WITH_BUILDINGID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_PUT_BUILDINGS_PRIMARY_BUILDING_ELEMENTS_ESTIMATE_ID_WITH_BUILDINGID_START = 'DATA_PUT_BUILDINGS_PRIMARY_BUILDING_ELEMENTS_ESTIMATE_ID_WITH_BUILDINGID_START'
export const DATA_PUT_BUILDINGS_PRIMARY_BUILDING_ELEMENTS_ESTIMATE_ID_WITH_BUILDINGID_SUCCESSFUL = 'DATA_PUT_BUILDINGS_PRIMARY_BUILDING_ELEMENTS_ESTIMATE_ID_WITH_BUILDINGID_SUCCESSFUL'
export const DATA_PUT_BUILDINGS_PRIMARY_BUILDING_ELEMENTS_ESTIMATE_ID_WITH_BUILDINGID_FAIL = 'DATA_PUT_BUILDINGS_PRIMARY_BUILDING_ELEMENTS_ESTIMATE_ID_WITH_BUILDINGID_FAIL'

// Set primary building elements estimate for building
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/realestate.yaml#/Building/put_buildings__buildingId__primary_building_elements_estimate_id
export const putBuildingsPrimaryBuildingElementsEstimateIdWithBuildingIdRequest = (
  requestArguments: putBuildingsPrimaryBuildingElementsEstimateIdWithBuildingIdRequestRealestateArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_PUT_BUILDINGS_PRIMARY_BUILDING_ELEMENTS_ESTIMATE_ID_WITH_BUILDINGID_START',
      successful: 'DATA_PUT_BUILDINGS_PRIMARY_BUILDING_ELEMENTS_ESTIMATE_ID_WITH_BUILDINGID_SUCCESSFUL',
      fail: 'DATA_PUT_BUILDINGS_PRIMARY_BUILDING_ELEMENTS_ESTIMATE_ID_WITH_BUILDINGID_FAIL'
    },
    embeddedType: null,
    fnName: 'putBuildingsPrimaryBuildingElementsEstimateIdWithBuildingIdRequest'
  }
  const config = {
    headers: { 'Content-Type': 'application/json' },
    baseURL: `${API_BASE_URL}/real-estate-api`,
    method: 'PUT',
    url: `/buildings/${requestArguments.path['buildingId']}/primary-building-elements-estimate/id`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: `"${requestArguments.body}"`
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'putBuildingsPrimaryBuildingElementsEstimateIdWithBuildingIdRequest', '/real-estate-api'),
    actions: {
      start: {
        type: DATA_PUT_BUILDINGS_PRIMARY_BUILDING_ELEMENTS_ESTIMATE_ID_WITH_BUILDINGID_START, payload, config, meta
      },
      successful: {
        type: DATA_PUT_BUILDINGS_PRIMARY_BUILDING_ELEMENTS_ESTIMATE_ID_WITH_BUILDINGID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_PUT_BUILDINGS_PRIMARY_BUILDING_ELEMENTS_ESTIMATE_ID_WITH_BUILDINGID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_PUT_BUILDINGS_PRIMARY_PROFITABILITY_ESTIMATE_ID_WITH_BUILDINGID_START = 'DATA_PUT_BUILDINGS_PRIMARY_PROFITABILITY_ESTIMATE_ID_WITH_BUILDINGID_START'
export const DATA_PUT_BUILDINGS_PRIMARY_PROFITABILITY_ESTIMATE_ID_WITH_BUILDINGID_SUCCESSFUL = 'DATA_PUT_BUILDINGS_PRIMARY_PROFITABILITY_ESTIMATE_ID_WITH_BUILDINGID_SUCCESSFUL'
export const DATA_PUT_BUILDINGS_PRIMARY_PROFITABILITY_ESTIMATE_ID_WITH_BUILDINGID_FAIL = 'DATA_PUT_BUILDINGS_PRIMARY_PROFITABILITY_ESTIMATE_ID_WITH_BUILDINGID_FAIL'

// Set primary profitability estimate for building
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/realestate.yaml#/Building/put_buildings__buildingId__primary_profitability_estimate_id
export const putBuildingsPrimaryProfitabilityEstimateIdWithBuildingIdRequest = (
  requestArguments: putBuildingsPrimaryProfitabilityEstimateIdWithBuildingIdRequestRealestateArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_PUT_BUILDINGS_PRIMARY_PROFITABILITY_ESTIMATE_ID_WITH_BUILDINGID_START',
      successful: 'DATA_PUT_BUILDINGS_PRIMARY_PROFITABILITY_ESTIMATE_ID_WITH_BUILDINGID_SUCCESSFUL',
      fail: 'DATA_PUT_BUILDINGS_PRIMARY_PROFITABILITY_ESTIMATE_ID_WITH_BUILDINGID_FAIL'
    },
    embeddedType: null,
    fnName: 'putBuildingsPrimaryProfitabilityEstimateIdWithBuildingIdRequest'
  }
  const config = {
    headers: { 'Content-Type': 'application/json' },
    baseURL: `${API_BASE_URL}/real-estate-api`,
    method: 'PUT',
    url: `/buildings/${requestArguments.path['buildingId']}/primary-profitability-estimate/id`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: `"${requestArguments.body}"`
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'putBuildingsPrimaryProfitabilityEstimateIdWithBuildingIdRequest', '/real-estate-api'),
    actions: {
      start: {
        type: DATA_PUT_BUILDINGS_PRIMARY_PROFITABILITY_ESTIMATE_ID_WITH_BUILDINGID_START, payload, config, meta
      },
      successful: {
        type: DATA_PUT_BUILDINGS_PRIMARY_PROFITABILITY_ESTIMATE_ID_WITH_BUILDINGID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_PUT_BUILDINGS_PRIMARY_PROFITABILITY_ESTIMATE_ID_WITH_BUILDINGID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_PUT_BUILDINGS_PRIMARY_PROCUREMENT_ESTIMATE_ID_WITH_BUILDINGID_START = 'DATA_PUT_BUILDINGS_PRIMARY_PROCUREMENT_ESTIMATE_ID_WITH_BUILDINGID_START'
export const DATA_PUT_BUILDINGS_PRIMARY_PROCUREMENT_ESTIMATE_ID_WITH_BUILDINGID_SUCCESSFUL = 'DATA_PUT_BUILDINGS_PRIMARY_PROCUREMENT_ESTIMATE_ID_WITH_BUILDINGID_SUCCESSFUL'
export const DATA_PUT_BUILDINGS_PRIMARY_PROCUREMENT_ESTIMATE_ID_WITH_BUILDINGID_FAIL = 'DATA_PUT_BUILDINGS_PRIMARY_PROCUREMENT_ESTIMATE_ID_WITH_BUILDINGID_FAIL'

// Set primary procurement estimate for building
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/realestate.yaml#/Building/put_buildings__buildingId__primary_procurement_estimate_id
export const putBuildingsPrimaryProcurementEstimateIdWithBuildingIdRequest = (
  requestArguments: putBuildingsPrimaryProcurementEstimateIdWithBuildingIdRequestRealestateArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_PUT_BUILDINGS_PRIMARY_PROCUREMENT_ESTIMATE_ID_WITH_BUILDINGID_START',
      successful: 'DATA_PUT_BUILDINGS_PRIMARY_PROCUREMENT_ESTIMATE_ID_WITH_BUILDINGID_SUCCESSFUL',
      fail: 'DATA_PUT_BUILDINGS_PRIMARY_PROCUREMENT_ESTIMATE_ID_WITH_BUILDINGID_FAIL'
    },
    embeddedType: null,
    fnName: 'putBuildingsPrimaryProcurementEstimateIdWithBuildingIdRequest'
  }
  const config = {
    headers: { 'Content-Type': 'application/json' },
    baseURL: `${API_BASE_URL}/real-estate-api`,
    method: 'PUT',
    url: `/buildings/${requestArguments.path['buildingId']}/primary-procurement-estimate/id`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: `"${requestArguments.body}"`
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'putBuildingsPrimaryProcurementEstimateIdWithBuildingIdRequest', '/real-estate-api'),
    actions: {
      start: {
        type: DATA_PUT_BUILDINGS_PRIMARY_PROCUREMENT_ESTIMATE_ID_WITH_BUILDINGID_START, payload, config, meta
      },
      successful: {
        type: DATA_PUT_BUILDINGS_PRIMARY_PROCUREMENT_ESTIMATE_ID_WITH_BUILDINGID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_PUT_BUILDINGS_PRIMARY_PROCUREMENT_ESTIMATE_ID_WITH_BUILDINGID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_PUT_BUILDINGS_PRIMARY_RENOVATION_PROGRAM_ESTIMATE_ID_WITH_BUILDINGID_START = 'DATA_PUT_BUILDINGS_PRIMARY_RENOVATION_PROGRAM_ESTIMATE_ID_WITH_BUILDINGID_START'
export const DATA_PUT_BUILDINGS_PRIMARY_RENOVATION_PROGRAM_ESTIMATE_ID_WITH_BUILDINGID_SUCCESSFUL = 'DATA_PUT_BUILDINGS_PRIMARY_RENOVATION_PROGRAM_ESTIMATE_ID_WITH_BUILDINGID_SUCCESSFUL'
export const DATA_PUT_BUILDINGS_PRIMARY_RENOVATION_PROGRAM_ESTIMATE_ID_WITH_BUILDINGID_FAIL = 'DATA_PUT_BUILDINGS_PRIMARY_RENOVATION_PROGRAM_ESTIMATE_ID_WITH_BUILDINGID_FAIL'

// Set primary renovation program estimate for building
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/realestate.yaml#/Building/put_buildings__buildingId__primary_renovation_program_estimate_id
export const putBuildingsPrimaryRenovationProgramEstimateIdWithBuildingIdRequest = (
  requestArguments: putBuildingsPrimaryRenovationProgramEstimateIdWithBuildingIdRequestRealestateArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_PUT_BUILDINGS_PRIMARY_RENOVATION_PROGRAM_ESTIMATE_ID_WITH_BUILDINGID_START',
      successful: 'DATA_PUT_BUILDINGS_PRIMARY_RENOVATION_PROGRAM_ESTIMATE_ID_WITH_BUILDINGID_SUCCESSFUL',
      fail: 'DATA_PUT_BUILDINGS_PRIMARY_RENOVATION_PROGRAM_ESTIMATE_ID_WITH_BUILDINGID_FAIL'
    },
    embeddedType: null,
    fnName: 'putBuildingsPrimaryRenovationProgramEstimateIdWithBuildingIdRequest'
  }
  const config = {
    headers: { 'Content-Type': 'application/json' },
    baseURL: `${API_BASE_URL}/real-estate-api`,
    method: 'PUT',
    url: `/buildings/${requestArguments.path['buildingId']}/primary-renovation-program-estimate/id`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: `"${requestArguments.body}"`
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'putBuildingsPrimaryRenovationProgramEstimateIdWithBuildingIdRequest', '/real-estate-api'),
    actions: {
      start: {
        type: DATA_PUT_BUILDINGS_PRIMARY_RENOVATION_PROGRAM_ESTIMATE_ID_WITH_BUILDINGID_START, payload, config, meta
      },
      successful: {
        type: DATA_PUT_BUILDINGS_PRIMARY_RENOVATION_PROGRAM_ESTIMATE_ID_WITH_BUILDINGID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_PUT_BUILDINGS_PRIMARY_RENOVATION_PROGRAM_ESTIMATE_ID_WITH_BUILDINGID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_PUT_BUILDINGS_PRIMARY_USAGE_MAINTENANCE_ESTIMATE_ID_WITH_BUILDINGID_START = 'DATA_PUT_BUILDINGS_PRIMARY_USAGE_MAINTENANCE_ESTIMATE_ID_WITH_BUILDINGID_START'
export const DATA_PUT_BUILDINGS_PRIMARY_USAGE_MAINTENANCE_ESTIMATE_ID_WITH_BUILDINGID_SUCCESSFUL = 'DATA_PUT_BUILDINGS_PRIMARY_USAGE_MAINTENANCE_ESTIMATE_ID_WITH_BUILDINGID_SUCCESSFUL'
export const DATA_PUT_BUILDINGS_PRIMARY_USAGE_MAINTENANCE_ESTIMATE_ID_WITH_BUILDINGID_FAIL = 'DATA_PUT_BUILDINGS_PRIMARY_USAGE_MAINTENANCE_ESTIMATE_ID_WITH_BUILDINGID_FAIL'

// Set primary usage &amp; maintenance estimate for building
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/realestate.yaml#/Building/put_buildings__buildingId__primary_usage_maintenance_estimate_id
export const putBuildingsPrimaryUsageMaintenanceEstimateIdWithBuildingIdRequest = (
  requestArguments: putBuildingsPrimaryUsageMaintenanceEstimateIdWithBuildingIdRequestRealestateArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_PUT_BUILDINGS_PRIMARY_USAGE_MAINTENANCE_ESTIMATE_ID_WITH_BUILDINGID_START',
      successful: 'DATA_PUT_BUILDINGS_PRIMARY_USAGE_MAINTENANCE_ESTIMATE_ID_WITH_BUILDINGID_SUCCESSFUL',
      fail: 'DATA_PUT_BUILDINGS_PRIMARY_USAGE_MAINTENANCE_ESTIMATE_ID_WITH_BUILDINGID_FAIL'
    },
    embeddedType: null,
    fnName: 'putBuildingsPrimaryUsageMaintenanceEstimateIdWithBuildingIdRequest'
  }
  const config = {
    headers: { 'Content-Type': 'application/json' },
    baseURL: `${API_BASE_URL}/real-estate-api`,
    method: 'PUT',
    url: `/buildings/${requestArguments.path['buildingId']}/primary-usage-maintenance-estimate/id`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: `"${requestArguments.body}"`
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'putBuildingsPrimaryUsageMaintenanceEstimateIdWithBuildingIdRequest', '/real-estate-api'),
    actions: {
      start: {
        type: DATA_PUT_BUILDINGS_PRIMARY_USAGE_MAINTENANCE_ESTIMATE_ID_WITH_BUILDINGID_START, payload, config, meta
      },
      successful: {
        type: DATA_PUT_BUILDINGS_PRIMARY_USAGE_MAINTENANCE_ESTIMATE_ID_WITH_BUILDINGID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_PUT_BUILDINGS_PRIMARY_USAGE_MAINTENANCE_ESTIMATE_ID_WITH_BUILDINGID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_GET_BUILDINGS_KEY_FIGURES_WITH_BUILDINGID_START = 'DATA_GET_BUILDINGS_KEY_FIGURES_WITH_BUILDINGID_START'
export const DATA_GET_BUILDINGS_KEY_FIGURES_WITH_BUILDINGID_SUCCESSFUL = 'DATA_GET_BUILDINGS_KEY_FIGURES_WITH_BUILDINGID_SUCCESSFUL'
export const DATA_GET_BUILDINGS_KEY_FIGURES_WITH_BUILDINGID_FAIL = 'DATA_GET_BUILDINGS_KEY_FIGURES_WITH_BUILDINGID_FAIL'

// Returns the key figures for the building
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/realestate.yaml#/Building/get_buildings__buildingId__key_figures
export const getBuildingsKeyFiguresWithBuildingIdRequest = (
  requestArguments: getBuildingsKeyFiguresWithBuildingIdRequestRealestateArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_BUILDINGS_KEY_FIGURES_WITH_BUILDINGID_START',
      successful: 'DATA_GET_BUILDINGS_KEY_FIGURES_WITH_BUILDINGID_SUCCESSFUL',
      fail: 'DATA_GET_BUILDINGS_KEY_FIGURES_WITH_BUILDINGID_FAIL'
    },
    embeddedType: {
      nonEmbedded: {
        schemaPath: '#/definitions/KeyFigures',
        schemaKey: 'KeyFigures'
      }
    },
    fnName: 'getBuildingsKeyFiguresWithBuildingIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/real-estate-api`,
    method: 'GET',
    url: `/buildings/${requestArguments.path['buildingId']}/key-figures`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      nonEmbedded: {
        schemaPath: '#/definitions/KeyFigures',
        schemaKey: 'KeyFigures'
      }
    }, 'getBuildingsKeyFiguresWithBuildingIdRequest', '/real-estate-api'),
    actions: {
      start: {
        type: DATA_GET_BUILDINGS_KEY_FIGURES_WITH_BUILDINGID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_BUILDINGS_KEY_FIGURES_WITH_BUILDINGID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_BUILDINGS_KEY_FIGURES_WITH_BUILDINGID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getBuildingsKeyFiguresWithBuildingIdRequestDefinitions = (definitionsArguments: {| requestArgs: getBuildingsKeyFiguresWithBuildingIdRequestRealestateArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getBuildingsKeyFiguresWithBuildingIdRequestRealestateArguments, payload?: Object |} => ({
  fnName: 'getBuildingsKeyFiguresWithBuildingIdRequest',
  basePath: '/real-estate-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_GET_SITES_WITH_SITEID_START = 'DATA_GET_SITES_WITH_SITEID_START'
export const DATA_GET_SITES_WITH_SITEID_SUCCESSFUL = 'DATA_GET_SITES_WITH_SITEID_SUCCESSFUL'
export const DATA_GET_SITES_WITH_SITEID_FAIL = 'DATA_GET_SITES_WITH_SITEID_FAIL'

// Returns the site for the real estate
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/realestate.yaml#/Site/get_sites__siteId_
export const getSitesWithSiteIdRequest = (
  requestArguments: getSitesWithSiteIdRequestRealestateArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_SITES_WITH_SITEID_START',
      successful: 'DATA_GET_SITES_WITH_SITEID_SUCCESSFUL',
      fail: 'DATA_GET_SITES_WITH_SITEID_FAIL'
    },
    embeddedType: {
      nonEmbedded: {
        schemaPath: '#/definitions/Site',
        schemaKey: 'Site'
      }
    },
    fnName: 'getSitesWithSiteIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/real-estate-api`,
    method: 'GET',
    url: `/sites/${requestArguments.path['siteId']}`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      nonEmbedded: {
        schemaPath: '#/definitions/Site',
        schemaKey: 'Site'
      }
    }, 'getSitesWithSiteIdRequest', '/real-estate-api'),
    actions: {
      start: {
        type: DATA_GET_SITES_WITH_SITEID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_SITES_WITH_SITEID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_SITES_WITH_SITEID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getSitesWithSiteIdRequestDefinitions = (definitionsArguments: {| requestArgs: getSitesWithSiteIdRequestRealestateArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getSitesWithSiteIdRequestRealestateArguments, payload?: Object |} => ({
  fnName: 'getSitesWithSiteIdRequest',
  basePath: '/real-estate-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_PUT_SITES_PRIMARY_LOCAN_ESTIMATE_ID_WITH_SITEID_START = 'DATA_PUT_SITES_PRIMARY_LOCAN_ESTIMATE_ID_WITH_SITEID_START'
export const DATA_PUT_SITES_PRIMARY_LOCAN_ESTIMATE_ID_WITH_SITEID_SUCCESSFUL = 'DATA_PUT_SITES_PRIMARY_LOCAN_ESTIMATE_ID_WITH_SITEID_SUCCESSFUL'
export const DATA_PUT_SITES_PRIMARY_LOCAN_ESTIMATE_ID_WITH_SITEID_FAIL = 'DATA_PUT_SITES_PRIMARY_LOCAN_ESTIMATE_ID_WITH_SITEID_FAIL'

// Set primary LOCAN estimate for site
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/realestate.yaml#/Site/put_sites__siteId__primary_locan_estimate_id
export const putSitesPrimaryLocanEstimateIdWithSiteIdRequest = (
  requestArguments: putSitesPrimaryLocanEstimateIdWithSiteIdRequestRealestateArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_PUT_SITES_PRIMARY_LOCAN_ESTIMATE_ID_WITH_SITEID_START',
      successful: 'DATA_PUT_SITES_PRIMARY_LOCAN_ESTIMATE_ID_WITH_SITEID_SUCCESSFUL',
      fail: 'DATA_PUT_SITES_PRIMARY_LOCAN_ESTIMATE_ID_WITH_SITEID_FAIL'
    },
    embeddedType: null,
    fnName: 'putSitesPrimaryLocanEstimateIdWithSiteIdRequest'
  }
  const config = {
    headers: { 'Content-Type': 'application/json' },
    baseURL: `${API_BASE_URL}/real-estate-api`,
    method: 'PUT',
    url: `/sites/${requestArguments.path['siteId']}/primary-locan-estimate/id`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: `"${requestArguments.body}"`
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'putSitesPrimaryLocanEstimateIdWithSiteIdRequest', '/real-estate-api'),
    actions: {
      start: {
        type: DATA_PUT_SITES_PRIMARY_LOCAN_ESTIMATE_ID_WITH_SITEID_START, payload, config, meta
      },
      successful: {
        type: DATA_PUT_SITES_PRIMARY_LOCAN_ESTIMATE_ID_WITH_SITEID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_PUT_SITES_PRIMARY_LOCAN_ESTIMATE_ID_WITH_SITEID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_GET_SITES_KEY_FIGURES_WITH_SITEID_START = 'DATA_GET_SITES_KEY_FIGURES_WITH_SITEID_START'
export const DATA_GET_SITES_KEY_FIGURES_WITH_SITEID_SUCCESSFUL = 'DATA_GET_SITES_KEY_FIGURES_WITH_SITEID_SUCCESSFUL'
export const DATA_GET_SITES_KEY_FIGURES_WITH_SITEID_FAIL = 'DATA_GET_SITES_KEY_FIGURES_WITH_SITEID_FAIL'

// Returns the site for the real estate
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/realestate.yaml#/Site/get_sites__siteId__key_figures
export const getSitesKeyFiguresWithSiteIdRequest = (
  requestArguments: getSitesKeyFiguresWithSiteIdRequestRealestateArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_SITES_KEY_FIGURES_WITH_SITEID_START',
      successful: 'DATA_GET_SITES_KEY_FIGURES_WITH_SITEID_SUCCESSFUL',
      fail: 'DATA_GET_SITES_KEY_FIGURES_WITH_SITEID_FAIL'
    },
    embeddedType: {
      nonEmbedded: {
        schemaPath: '#/definitions/KeyFigures',
        schemaKey: 'KeyFigures'
      }
    },
    fnName: 'getSitesKeyFiguresWithSiteIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/real-estate-api`,
    method: 'GET',
    url: `/sites/${requestArguments.path['siteId']}/key-figures`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      nonEmbedded: {
        schemaPath: '#/definitions/KeyFigures',
        schemaKey: 'KeyFigures'
      }
    }, 'getSitesKeyFiguresWithSiteIdRequest', '/real-estate-api'),
    actions: {
      start: {
        type: DATA_GET_SITES_KEY_FIGURES_WITH_SITEID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_SITES_KEY_FIGURES_WITH_SITEID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_SITES_KEY_FIGURES_WITH_SITEID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getSitesKeyFiguresWithSiteIdRequestDefinitions = (definitionsArguments: {| requestArgs: getSitesKeyFiguresWithSiteIdRequestRealestateArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getSitesKeyFiguresWithSiteIdRequestRealestateArguments, payload?: Object |} => ({
  fnName: 'getSitesKeyFiguresWithSiteIdRequest',
  basePath: '/real-estate-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_GET_ENUM_WITH_ENUM_START = 'DATA_GET_ENUM_WITH_ENUM_START'
export const DATA_GET_ENUM_WITH_ENUM_SUCCESSFUL = 'DATA_GET_ENUM_WITH_ENUM_SUCCESSFUL'
export const DATA_GET_ENUM_WITH_ENUM_FAIL = 'DATA_GET_ENUM_WITH_ENUM_FAIL'

// Returns members of given enum as embedded Enumerals
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/realestate.yaml#/Enum/get_enum__enum_
export const getEnumWithEnumRequest = (
  requestArguments: getEnumWithEnumRequestRealestateArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_ENUM_WITH_ENUM_START',
      successful: 'DATA_GET_ENUM_WITH_ENUM_SUCCESSFUL',
      fail: 'DATA_GET_ENUM_WITH_ENUM_FAIL'
    },
    embeddedType: {
      nonEmbedded: {
        schemaPath: '#/definitions/Enumerals',
        schemaKey: 'Enumerals'
      }
    },
    fnName: 'getEnumWithEnumRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/real-estate-api`,
    method: 'GET',
    url: `/enum/${requestArguments.path['enumParam']}`,
    params: {
      category: requestArguments.query?.['category'],
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      nonEmbedded: {
        schemaPath: '#/definitions/Enumerals',
        schemaKey: 'Enumerals'
      }
    }, 'getEnumWithEnumRequest', '/real-estate-api'),
    actions: {
      start: {
        type: DATA_GET_ENUM_WITH_ENUM_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_ENUM_WITH_ENUM_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_ENUM_WITH_ENUM_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getEnumWithEnumRequestDefinitions = (definitionsArguments: {| requestArgs: getEnumWithEnumRequestRealestateArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getEnumWithEnumRequestRealestateArguments, payload?: Object |} => ({
  fnName: 'getEnumWithEnumRequest',
  basePath: '/real-estate-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_GET_QUEUE_WITH_ID_START = 'DATA_GET_QUEUE_WITH_ID_START'
export const DATA_GET_QUEUE_WITH_ID_SUCCESSFUL = 'DATA_GET_QUEUE_WITH_ID_SUCCESSFUL'
export const DATA_GET_QUEUE_WITH_ID_FAIL = 'DATA_GET_QUEUE_WITH_ID_FAIL'

// Returns status of queued operation
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/realestate.yaml#/Queue/get_queue__id_
export const getQueueWithIdRequest = (
  requestArguments: getQueueWithIdRequestRealestateArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_QUEUE_WITH_ID_START',
      successful: 'DATA_GET_QUEUE_WITH_ID_SUCCESSFUL',
      fail: 'DATA_GET_QUEUE_WITH_ID_FAIL'
    },
    embeddedType: {
      nonEmbedded: {
        schemaPath: '#/definitions/QueueStatus',
        schemaKey: 'QueueStatus'
      }
    },
    fnName: 'getQueueWithIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/real-estate-api`,
    method: 'GET',
    url: `/queue/${requestArguments.path['id']}`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      nonEmbedded: {
        schemaPath: '#/definitions/QueueStatus',
        schemaKey: 'QueueStatus'
      }
    }, 'getQueueWithIdRequest', '/real-estate-api'),
    actions: {
      start: {
        type: DATA_GET_QUEUE_WITH_ID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_QUEUE_WITH_ID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_QUEUE_WITH_ID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getQueueWithIdRequestDefinitions = (definitionsArguments: {| requestArgs: getQueueWithIdRequestRealestateArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getQueueWithIdRequestRealestateArguments, payload?: Object |} => ({
  fnName: 'getQueueWithIdRequest',
  basePath: '/real-estate-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})
