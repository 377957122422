// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.
import React, { Component } from 'react'
import { withStyles } from '@material-ui/core'
import { map, times, isEqual } from 'lodash'
import * as colors from '../../../../../../node_modules/frontend-assets/src/theme/colors'
import theme from '../../../../../styles/theme'

const CARET_WIDTH = 20
const PADDING = 4
const STEP_WIDTH = 54
export const valueKeys = {
  horizontalSliders: 'RatioSpaceArea',
  architectureSliders: 'value'
}
export const SVG_MAX_WIDTH = 216

const styles = () => ({
  grid: { stroke: colors.gray80 },
  rect: { fill: colors.black, opacity: 0.12 },
  segmentLine: { stroke: colors.white },
  sliderContainer: { display: 'flex', width: '50%' },
  sliderArea: { position: 'relative' },
})

type State = {
  onDragWidth: number, // Width of slider while user is dragging
  relativeX: number, // Difference between old width and current width during drag()
  draggedStepsWidth: number // Width of stepped slider
}

type Props = {
  slider: Object, // All data of slider
  classes: Object, // Classes object created by withstyles function
  updateSlider: Function, // Update slider action
  patchSlider: Function, // action to make patch request with updated slider value
  disabled: boolean, // Indicates if slider is disabled
  sliderGroup: string, // indicates the current slider group eg. horizontalSliders or architectureSliders
  stepped?: boolean, // flag to determine wether to use 5-step slider
}

class SVGSlider extends Component<Props, State> {
  static defaultProps = {
    stepped: false,
  }

  state = {
    onDragWidth: 0,
    relativeX: 0,
    draggedStepsWidth: 0,
  }

  HEIGHT = this.props.slider.HeightIncludingSlabM * theme.dimensions.designModelEditorRowHeightToPxMultiplier || 41
  STEP = STEP_WIDTH * this.props.slider[valueKeys[this.props.sliderGroup]]
  horizontalSliderInitialWidth = (
    SVG_MAX_WIDTH * (this.props.slider[valueKeys[this.props.sliderGroup]] + this.props.slider.RatioAreaOpeningsHighSpacesAndSlabFactor)
  )


  componentDidMount() {
    if (this.props.sliderGroup === 'horizontalSliders') this.setState({ onDragWidth: this.horizontalSliderInitialWidth })
    else this.setState({ onDragWidth: this.STEP, draggedStepsWidth: this.STEP })
  }

  componentDidUpdate(prevProps: Props) {
    const { slider, sliderGroup } = this.props
    if (sliderGroup === 'horizontalSliders' && !isEqual(prevProps.slider, this.props.slider)) {
      this.setState({
        onDragWidth: (
          SVG_MAX_WIDTH * (this.props.slider[valueKeys[this.props.sliderGroup]] + this.props.slider.RatioAreaOpeningsHighSpacesAndSlabFactor)
        )
      })
    }
    if (this.props.sliderGroup === 'architectureSliders' && !isEqual(prevProps.slider, this.props.slider)) {
      this.setState({ draggedStepsWidth: this.props.slider[valueKeys[this.props.sliderGroup]] * STEP_WIDTH })
    }
    this.HEIGHT = slider.HeightIncludingSlabM * theme.dimensions.designModelEditorRowHeightToPxMultiplier || 41
  }

  /*
  p1 - p5 = aperture polyline points
  P1 - P5 = slider polyline points

  P1____________________P2
   |            _______ \
   |         p1|    p2 \ \
   |           |        \ \ P3
   |           |      p3/ /
   |         p5|_____p4/ /
   | ___________________/
    P5                 P4
  */

  get aperture(): React$Element<any> | null {
    const { onDragWidth } = this.state
    const { id, RatioAreaOpeningsHighSpacesAndSlabFactor, HeightIncludingSlabM } = this.props.slider

    if (!(RatioAreaOpeningsHighSpacesAndSlabFactor > 0)) {
      return null
    }

    const storyHeight = this.HEIGHT
    const sliderHeight = storyHeight - (PADDING * 2)
    const apertureMaxHeight = sliderHeight - 2


    // apertureLength converts RatioAreaOpeningsblabla into pixels within active slider area, NOTE: CARET_WIDTH indicating grey block in this instance
    const apertureLength = SVG_MAX_WIDTH * RatioAreaOpeningsHighSpacesAndSlabFactor
    const horizontalPadding = 1 // aperture offset from the tip of the slider
    const apertureCaretLength = CARET_WIDTH - horizontalPadding

    const offsetNormalizer = HeightIncludingSlabM < 4 ? 1 : 0 // Hack to provide (almost) pixel perfect aperture alignment
    const onlyTip = apertureLength <= apertureCaretLength // aperture is drawn only inside the tip of the "wedge" of the slider
    const apertureLengthAdjusted = onlyTip ? apertureLength - offsetNormalizer : apertureCaretLength
    const apertureArmLength = onlyTip ? 0 : apertureLength - CARET_WIDTH - horizontalPadding

    const tangent = sliderHeight / CARET_WIDTH
    const calculatedApertureCaretHeight = tangent * apertureLength
    const verticalPadding = onlyTip
      ? ((apertureMaxHeight - calculatedApertureCaretHeight) / 2) + (PADDING + 2)
      : (storyHeight - apertureMaxHeight) / 2

    const apertureTip = onDragWidth + CARET_WIDTH - (horizontalPadding * 2)

    const p1 = `${apertureTip - apertureLengthAdjusted - apertureArmLength},  ${verticalPadding}`
    const p2 = `${apertureTip - apertureLengthAdjusted},                      ${verticalPadding}`
    const p3 = `${apertureTip},                                               ${storyHeight / 2}`
    const p4 = `${apertureTip - apertureLengthAdjusted},                      ${storyHeight - (verticalPadding)}`
    const p5 = `${apertureTip - apertureLengthAdjusted - apertureArmLength},  ${storyHeight - (verticalPadding)}`

    return (
      <polyline
        points={`${p1}, ${p2}, ${p3}, ${p4}, ${p5}`}
        fill={colors.white}
        onMouseDown={!this.props.disabled ? this.drag : null}
        id={`${id}-aperture`} />
    )
  }

  get slider(): React$Element<any> {
    const { stepped, disabled, slider: { id } } = this.props
    const { draggedStepsWidth } = this.state
    const height = this.HEIGHT
    let width
    if (stepped) {
      width = draggedStepsWidth
    } else {
      width = this.state.onDragWidth
        ? this.state.onDragWidth
        : this.props.slider[valueKeys[this.props.sliderGroup]] + this.props.slider.RatioAreaOpeningsHighSpacesAndSlabFactor
    }

    const P1 = `0, ${PADDING}`
    const P2 = `${width}, ${PADDING}`
    const P3 = `${width + CARET_WIDTH}, ${height / 2}`
    const P4 = `${width}, ${height - PADDING}`
    const P5 = `0, ${height - PADDING}`
    const standardSliderColor = !disabled ? colors.primary80 : colors.gray80
    const steppedSliderColor = !disabled ? colors.designModelBrown : colors.gray80
    return (
      <polyline
        id={`${id}-slider-base`}
        onMouseDown={!disabled ? this.drag : null}
        points={`${P1}, ${P2}, ${P3}, ${P4}, ${P5}`}
        fill={stepped ? steppedSliderColor : standardSliderColor} />
    )
  }

  get segments(): React$Element<any> | null {
    const { SectionsPcs, RatioAreaOpeningsHighSpacesAndSlabFactor } = this.props.slider
    if (SectionsPcs === 1 || SectionsPcs > 5) return null
    const y1 = PADDING + 2 // top padding + 2px so the dashing adjusts nicely on the slider with default height
    const y2 = (this.HEIGHT) - PADDING

    return times(SectionsPcs, (idx: number) => {
      if (idx === 0) return null
      const x = ((this.state.onDragWidth + RatioAreaOpeningsHighSpacesAndSlabFactor) / SectionsPcs * idx) + CARET_WIDTH
      return (
        <line
          id='segment-line'
          key={idx}
          className={this.props.classes.segmentLine}
          strokeWidth={1}
          strokeDasharray='4'
          y1={y1}
          x1={x}
          y2={y2}
          x2={x} />
      )
    })
  }

  get grid(): React$Element<any> {
    const { classes } = this.props
    const y1 = 0
    const y2 = (this.HEIGHT)

    const positions = [0.25, 0.5, 0.75, 1]
    return map(positions, (position: number, idx: number) => {
      const x = (SVG_MAX_WIDTH) * position + CARET_WIDTH
      return (
        <line
          key={idx}
          className={classes.grid}
          strokeWidth={1}
          x1={x}
          y1={y1}
          x2={x}
          y2={y2} />
      )
    })
  }

  get caretGreyBlock(): React$Element<any> {
    return <rect className={this.props.classes.rect} width={CARET_WIDTH} height='100%' onMouseDown={!this.props.disabled ? this.drag : null} />
  }

  get background(): React$Element<any> {
    return (
      <g>
        {this.grid}
      </g>
    )
  }

  drag = (e: SyntheticMouseEvent<any>) => {
    const {
      updateSlider,
      patchSlider,
      slider,
      sliderGroup,
      stepped
    } = this.props

    e.preventDefault()
    this.setState({ relativeX: e.pageX })

    window.onmousemove = (event: SyntheticMouseEvent<any>) => {
      const delta = event.pageX - this.state.relativeX
      let width = this.state.onDragWidth + delta
      const apertureLength = this.props.slider.RatioAreaOpeningsHighSpacesAndSlabFactor * SVG_MAX_WIDTH
      const sliderMaxWidth = SVG_MAX_WIDTH + apertureLength

      if (stepped) {
        if (width > SVG_MAX_WIDTH) width = SVG_MAX_WIDTH
        if (width < 0.01) width = 0.01
        let draggedStep = Math.round(width / STEP_WIDTH) // calculate how many steps has been dragged
        if (draggedStep >= SVG_MAX_WIDTH) draggedStep = SVG_MAX_WIDTH // force width to SVG_MAX_WIDTH if value is over SVG_MAX_WIDTH
        this.setState({ relativeX: event.pageX, onDragWidth: width, draggedStepsWidth: draggedStep * STEP_WIDTH }) // draggedStep * STEP_WIDTH = slider width in pixels
      } else {
        if (width > sliderMaxWidth) width = sliderMaxWidth
        if (width < 0.01 + apertureLength) width = 0.01 + apertureLength // Floor area can not be 0
        this.setState({ relativeX: event.pageX, onDragWidth: width })
        updateSlider(
          slider.id,
          ((this.state.onDragWidth - (SVG_MAX_WIDTH * this.props.slider.RatioAreaOpeningsHighSpacesAndSlabFactor)) / SVG_MAX_WIDTH),
          sliderGroup
        )
      }
    }
    window.onmouseup = () => {
      if (sliderGroup === 'horizontalSliders') {
        const newValue = (
          ((this.state.onDragWidth - (SVG_MAX_WIDTH * this.props.slider.RatioAreaOpeningsHighSpacesAndSlabFactor)) / SVG_MAX_WIDTH).toFixed(2) // convert value to %
        )
        patchSlider(slider, newValue, sliderGroup)
      } else {
        patchSlider(slider, Math.round(this.state.onDragWidth / STEP_WIDTH), sliderGroup) // convert value to steps in range of 0-4
      }
      window.onmousemove = undefined
      window.onmouseup = undefined
    }
  }

  render(): React$Element<any> {
    const { classes, slider } = this.props
    return (
      <svg className={classes.sliderContainer} height={this.HEIGHT} id={slider.id}>
        <g className={this.props.classes.sliderArea}>
          {this.background}
          {this.slider}
          {this.caretGreyBlock}
          {this.aperture}
          {this.segments}
        </g>
      </svg>
    )
  }
}

export default withStyles(styles)(SVGSlider)
