// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withTranslation } from 'react-i18next'
import { isEmpty, map, filter, concat, includes, cloneDeep, reduce } from 'lodash'
import Axios from 'axios'
import { withStyles } from '@material-ui/core/styles'

import TabsPlain from '../../common/TabsPlain/TabsPlain'
import Message from '../../common/Messages/Message'
import PropertiesDataTable from '../../common/Tabs/Properties/PropertiesDataTable/PropertiesDataTable'

import { multiEditContainerTypes, SPACE, SPACEGROUP } from '../../../constants/contentTypes'
import { getSelectedListItemsByType, getHierarchyLevels } from '../../../utils/listUtils'
import { parseCategorizedPropertyList } from '../../../utils/parseUtil'
import { runGAR } from '../../../utils/GARUtils'
import {
  setMultipleEditProperties,
  setDropdownContents,
  markAsModified,
  editProperty,
  clearProperties
} from '../../../actions/widgets'
import {
  getSpaceScheduleSpacesPropertiesWithIdRequest,
  getSpaceScheduleSpaceGroupsPropertiesWithIdRequest
} from '../../../utils/generated-api-requests/spaces'
import { getEnumFromEnumStore } from '../../../utils/reducers/enums'
import Spinner from '../../common/Spinner/Spinner'
// $FlowFixMe
import { ReactComponent as AlertInfoOutlinedIcon } from '../../../../node_modules/frontend-assets/static/assets/images/icons/Alert Info outlined.svg'

type HOCProps = {|
  t: Function,
  classes: Object, // withStyles classes object that holds classNames
|}

type MappedProps = {|
  selectedRows: Array<Object>, // array of rows that are currently selected in resource list
  containerType: string, // indicates what type of rows are being edited in multiEdit widget
  application: string, // currently selected module
  data: Object, // properties data object
  languageCode: string, // user selected language code
  enums: TVDEnumStore, // all fetched enums so far from API
  type: string, // Type of the widget
|}

type DispatchProps = {|
  dispatchSetDropdownContents: (Object) => void, // action to set dropdown options to redux store after all properties have been loaded
  dispatchSetMultipleEditProperties: (Object) => void, // action to set all properties to redux store after fetch
  dispatchEditProperty: (string, string | number) => void, // action to change property's value in redux store
  dispatchClearProperties: () => void, // remove a properties list from proeprties store with store id
|}

type ReceivedProps = {|
  resourceId: string, // id of widgets resource row, properties in redux store can be accessed with this id
  propertiesStoreId: string, // key used to access "properties" from Store
|}


type Props = {|
  ...HOCProps,
  ...MappedProps,
  ...DispatchProps,
  ...ReceivedProps,
|}

type State = {|
  showMessage: boolean,
  isLoading: boolean
|}

const styles = (): Object => ({
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1',
    minHeight: 0,
    height: '100%'
  }
})

export class MultiEditContainer extends Component<Props, State> {
  state = {
    showMessage: true,
    isLoading: true
  }

  componentDidMount() {
    const { selectedRows, containerType } = this.props
    // filter selectedRows that match with containerType into array
    const items = filter(selectedRows, (item: Object) => item.type.toLowerCase() === containerType.toLowerCase())
    // don't proceed if no items with dataType 'enum' are found
    if (isEmpty(items)) return

    // use containerType to define which GAR to use
    let TVDRequestAction
    if (containerType === SPACE.toLowerCase()) TVDRequestAction = getSpaceScheduleSpacesPropertiesWithIdRequest
    if (containerType === SPACEGROUP.toLowerCase()) TVDRequestAction = getSpaceScheduleSpaceGroupsPropertiesWithIdRequest

    // create array of getProperties requests for each item found in selectedRows filter operation
    const requests = map(items, (item: Object) => TVDRequestAction({ path: { id: item.id } },))

    // execute all requests then begin to combine all fetched properties into one object
    Axios.all(requests).then((allPropertyArrays: Array<Array<Object>>) => this.combineProperties(allPropertyArrays))
  }

  componentDidUpdate(prevProps: Props) {
    const { languageCode, dispatchClearProperties } = this.props
    if (languageCode !== prevProps.languageCode) dispatchClearProperties()
  }

  editProperty = (propertyName: string, value: string | number) => { this.props.dispatchEditProperty(propertyName, value) }
  closeMessage = () => { this.setState({ showMessage: false }) }

  onPropertyRowMount = (row: TVDPropertiesListItem) => {
    const {
      languageCode,
      dispatchSetDropdownContents,
      enums
    } = this.props
    const { enumRequestDefinitions, propertyName, options } = row
    if (options && options.length > 0) return
    if (enumRequestDefinitions) {
      const { basePath } = enumRequestDefinitions
      const bPath = basePath.replace('/', '')
      const existingEnum = getEnumFromEnumStore({
        basePath: bPath,
        languageCode,
        enumKey: propertyName,
        enums
      })
      if (existingEnum) {
        dispatchSetDropdownContents({ [propertyName]: existingEnum })
      } else {
        runGAR({
          ...enumRequestDefinitions,
          successCb: (enumData: Array<TVDEnum>) => {
            dispatchSetDropdownContents({ [propertyName]: enumData })
          }
        })
      }
    }
  }

  getContent(): React$Element<PropertiesDataTable> {
    const {
      resourceId,
      propertiesStoreId,
      data,
      t,
      containerType,
      classes,
      type
    } = this.props

    const { showMessage, isLoading } = this.state

    const listItems = parseCategorizedPropertyList(data)
    const copy = cloneDeep(listItems)
    const hierarchy = getHierarchyLevels(copy)

    const translationKey = containerType && containerType.toUpperCase()
    return (
      <div className={classes.contentWrapper}>
        { showMessage && <Message
          message={t(`widgets._MULTI_EDIT_${translationKey}_INFO_MESSAGE_`)}
          snackbar
          type='info'
          icon={<AlertInfoOutlinedIcon />}
          close={this.closeMessage} /> }
        <div style={{
          overflow: 'auto',
          display: 'flex',
          flexDirection: 'column',
          flex: '1 1 0%'
        }}>
          { isLoading && <Spinner /> }
          <PropertiesDataTable
            disabled={isLoading}
            type={type}
            resourceId={resourceId}
            resourceListId={propertiesStoreId}
            listItems={listItems}
            hierarchy={hierarchy}
            onChange={this.editProperty}
            onBlur={this.editProperty}
            onPropertyRowMount={this.onPropertyRowMount}
            resetDefault={() => {}} />
        </div>
      </div>
    )
  }

  getTabs(): Array<TVDTab> {
    const { containerType, t } = this.props
    switch (containerType) {
      case SPACE.toLowerCase(): {
        return [{
          testId: 'space-properties-tab',
          props: {
            label: t('tabs._PROPERTIES_'),
            disabled: false
          },
          content: this.getContent()
        },
        {
          testId: 'space-equipment-tab',
          props: {
            label: t('tabs._EQUIPMENT_'),
            disabled: true
          },
          content: null
        },
        {
          testId: 'space-surfaces-tab',
          props: {
            label: t('tabs._SURFACES_'),
            disabled: true
          },
          content: null
        }]
      }
      case SPACEGROUP.toLowerCase(): {
        return [{
          testId: 'spacegroup-properties-tab',
          props: {
            label: t('tabs._PROPERTIES_'),
            disabled: false
          },
          content: this.getContent()
        }]
      }
      default:
        return []
    }
  }


  combineProperties(allPropertyArrays: Array<Array<Object>>) {
    const { t } = this.props
    // concat allPropertyArrays to one array
    const allProperties = concat([], ...allPropertyArrays)
    // reduce all properties to one object
    const combinedProperties = reduce(allProperties, (result: Object, property: Object) => {
      const { propertyName, value, dataType } = property
      // if property with current propertyName is not found in result, add it to result
      if (!result[propertyName]) {
        result[propertyName] = {
          ...property,
        }
        // if current propertyName is found in result and result does not have values array and current property value is conflicting with property value in result
        // create a values array, erase property value (so placeholder is displayed instead) and add a placeholder for property
        // this placeholder will inform user that multiple values for same property has been found
      } else if (result[propertyName] && !result[propertyName].values && result[propertyName].value !== value) {
        result[propertyName] = {
          ...result[propertyName],
          ...property,
          values: [result[propertyName].value, value],
          value: '',
          placeholder: dataType === 'enum' ? t('inputs._MULTIPLE_SELECTIONS_') : t('inputs._MULTIPLE_VALUES_'),
          booleanTooltipText: t('inputs._INDETERMINATE_CHECKBOX_')
        }
        // if current propertyName is found in result and result has values array and current property value is not found in values array
        // add current value to values array
      } else if (result[propertyName] && result[propertyName].values && !includes(result[propertyName].values, value)) {
        result[propertyName] = {
          ...result[propertyName],
          ...property,
          value: '',
          values: [...result[propertyName].values, value],
          booleanTooltipText: t('inputs._INDETERMINATE_CHECKBOX_')
        }
      }
      return result
    }, {})
    // dispatch action to set combined properties to redux store
    this.props.dispatchSetMultipleEditProperties(combinedProperties)
    // call method to load data for properties with enum dataType (dropdowns)
    this.setState({ isLoading: false })
  }

  render(): React$Element<any> {
    return <TabsPlain tabs={this.getTabs()} />
  }
}

type OwnProps = {|
  listStoreId: string, // key used to access "list" from Store
  modified: boolean, // widget modified value from Store's widget passed to content and this component
  propertiesStoreId: string, // key used to access "properties" from Store
  widgetId: string, // widget Id from contentProps and Content
  type: string, // widgetType from openContentWidget and Store
  overwittenSelectedRows: TVDListItems, // list items that overwrite ones in the store if given
|}

function mapStateToProps({
  app,
  list,
  properties,
  enums
}: TVDReduxStore, {
  propertiesStoreId,
  listStoreId,
  type,
  overwittenSelectedRows
}: OwnProps): Object {
  // containerType indicates what type of rows are being edited in multiEditContainer (currently only space and spacegroup types are supported)
  const containerType = multiEditContainerTypes[type]
  const { listItems } = list[listStoreId] || {}
  const selectedRows = overwittenSelectedRows || getSelectedListItemsByType(listItems, containerType)

  const { [propertiesStoreId]: propertiesListItems } = properties
  const modifiedPropertiesListItems = reduce(
    propertiesListItems,
    (result: Object, { modifiedValue, ...propertiesListItem }: TVDPropertiesListItem) => ({
      ...result,
      [propertiesListItem.propertyName]: {
        ...propertiesListItem,
        // as the component uses "value" to render, we use the modified value if found
        value: modifiedValue || propertiesListItem.value
      }
    }), {}
  )

  return {
    selectedRows,
    data: modifiedPropertiesListItems,
    application: app.application,
    calculation: app.calculation,
    languageCode: app.languageCode,
    enums,
    containerType
  }
}

function mapDispatchToProps(dispatch: Function, {
  propertiesStoreId,
  modified,
  widgetId
}: OwnProps): Object {
  return {
    dispatchSetMultipleEditProperties: (data: Array<Object>) => { dispatch(setMultipleEditProperties(data, propertiesStoreId)) },
    dispatchSetDropdownContents: (data: Object) => { dispatch(setDropdownContents(data, propertiesStoreId, widgetId)) },
    dispatchEditProperty: (propertyName: string, value: string) => {
      dispatch(editProperty(propertiesStoreId, propertyName, value))
      if (!modified) dispatch(markAsModified(widgetId))
    },
    dispatchClearProperties: () => { dispatch(clearProperties(propertiesStoreId)) }
  }
}

export default compose(
  withStyles(styles),
  withTranslation('translations'),
  connect(mapStateToProps, mapDispatchToProps)
)(MultiEditContainer)
