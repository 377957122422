// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.
import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'

import Checkbox from '../CheckBox/CheckBox'

const styles = ({ typography, palette }: TVDTheme) => ({
  simpleCheckboxRow: {
    display: 'flex',
    fontSize: '14px',
    color: palette.nevada,
    fontFamily: typography.fontFamilyBase,
    height: '40px',
    alignItems: 'center',
    borderBottom: `1px solid ${palette.porcelain}`,
    '&:first-child': {
      borderTop: `1px solid ${palette.porcelain}`,
    }
  },
  checkboxWrapper: {
    display: 'flex',
    padding: '0 15px',
    alignSelf: 'center'
  },
  descriptionWrapper: {
    display: 'flex',
  }
})

type Props = {|
  ...TVDCheckboxRowProps,
  t: Function, // i18n translate function
  classes: Object, // material-ui classes object
|}

export class CheckboxRow extends Component<Props> {
  render(): React$Element<any> {
    const {
      t,
      classes,
      description,
      checked,
      disabled,
      selectAllChild
    } = this.props

    return (
      <div className={classes.simpleCheckboxRow}>
        <div className={classes.checkboxWrapper}>
          <Checkbox selectAllChild={selectAllChild} checked={checked} disabled={disabled} />
        </div>
        <div className={classes.descriptionWrapper} id='CheckboxRow-description'>
          { t(`createOtherSpaces._${description.toUpperCase()}_`) }
        </div>
      </div>
    )
  }
}

export default withTranslation('translations')(withStyles(styles)(CheckboxRow))
