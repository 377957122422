// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.
import React from 'react'
import { connect } from 'react-redux'
import { buildInfoModal } from '../../../actions/modals'
import { getEstimateLockWithEstimateIdRequest, putEstimateLockWithEstimateIdRequest } from '../../../utils/generated-api-requests/spaces'

const POLLING_INTERVAL_MILLISECONDS = 60000 // default 1 minute
const LOCK_SECONDS_TIME_LIMIT = 65 // show session timeout modal when this limit is hit

type MapDispatch = {|
  dispatchBuildInfoModal: (Object) => void, // build info modal
|}

type Props = {|
  calculation: string, // current estimate id
  isEstimateLockedToCurrentUser: boolean, // indicates if estimate is locked to current user
  isRefreshingAccessToken: $PropertyType<TVDApplicationStore, 'isRefreshingAccessToken'>,
  ...MapDispatch,
|}

type State = {|
  lockSecondsRemaining: number, // remaining lock time
  timeoutModalOpen: boolean, // indicates if timeout modal is open
|}

export class EstimateLockManager extends React.Component<Props, State> {
  state = {
    lockSecondsRemaining: 1800,
    timeoutModalOpen: false
  }

  pollInterval: Function

  componentDidMount() {
    if (this.props.isEstimateLockedToCurrentUser) this.startInterval()
    else {
      this.buildCalculationLockedModal()
    }
  }

  componentDidUpdate() {
    const { lockSecondsRemaining, timeoutModalOpen } = this.state
    const { isEstimateLockedToCurrentUser } = this.props

    if (isEstimateLockedToCurrentUser && !timeoutModalOpen && (LOCK_SECONDS_TIME_LIMIT >= lockSecondsRemaining)) {
      this.stopInterval()
      this.props.dispatchBuildInfoModal({
        title: 'dialog._SESSION_TIMEOUT_TITLE_',
        saveButtonText: 'dialog._CONTINUE_EDITING_',
        onSave: this.onContinue
      })
      this.setState({ timeoutModalOpen: true })
    }
  }

  componentWillUnmount() {
    this.stopInterval()
  }

  startInterval = () => {
    this.setState({ lockSecondsRemaining: 1800, timeoutModalOpen: false }, () => {
      this.pollInterval = setInterval(this.getLock, POLLING_INTERVAL_MILLISECONDS)
    })
  }

  stopInterval = () => {
    clearInterval(this.pollInterval)
  }

  onContinue = () => {
    putEstimateLockWithEstimateIdRequest(
      { body: {} },
      {},
      this.startInterval,
      this.buildCalculationLockedModal,
      { estimateId: this.props.calculation }
    )
  }

  buildCalculationLockedModal = () => {
    this.props.dispatchBuildInfoModal({
      id: 'calculationLockedDialog',
      title: 'calculationLockedDialog._TITLE_',
      message: 'calculationLockedDialog._MESSAGE_',
      saveButtonText: 'calculationLockedDialog._BUTTON_',
      onSave: () => {}
    })
  }

  getLock = () => {
    const { isRefreshingAccessToken } = this.props
    // ignoring estimate lock GET if we happen to be amid of refreshing the token to avoid
    // requesting lock with a invalid token
    if (!isRefreshingAccessToken) {
      getEstimateLockWithEstimateIdRequest({}, (res: Object) => {
        this.setState({ lockSecondsRemaining: res.secondsRemaining })
      })
    }
  }

  render(): null {
    return null
  }
}

const mapDispatchToProps = (dispatch: Function): MapDispatch => ({
  dispatchBuildInfoModal: (content: Object) => dispatch(buildInfoModal(content)),
})

const mapStateToProps = ({ app: { isRefreshingAccessToken, calculation, isEstimateLockedToCurrentUser } }: Object) => ({
  calculation,
  isEstimateLockedToCurrentUser,
  isRefreshingAccessToken
})

export default connect(mapStateToProps, mapDispatchToProps)(EstimateLockManager)
