// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.

import { ESTIMATE_TYPE_SPACE, ESTIMATE_TYPE_BUILDING_ELEMENTS, ESTIMATE_TYPE_WOP } from '../../constants/moduleConstants'
import { postEstimatesRequest, getEstimatesRequest } from '../../utils/generated-api-requests/estimates'
import { getPathNameFromURL } from '../../utils/apiUtils'
import { getEstimateWithEstimateIdRequest as elementsGetEstimateWithEstimateIdRequest } from '../../utils/generated-api-requests/buildingelements'
import { getEstimateWithEstimateIdRequest as spacesGetEstimateWithEstimateIdRequest } from '../../utils/generated-api-requests/spaces'
import { getEstimateWithEstimateIdRequest as wopGetEstimateWithEstimateIdRequest } from '../../utils/generated-api-requests/wop'

export const CLEAR_CALCULATIONS = 'CLEAR_CALCULATIONS'
export function clearCalculations(): Object {
  return {
    type: CLEAR_CALCULATIONS,
  }
}

export function copyCalculation(id: string, appKey: string, buildingId: string): Object {
  return (dispatch: Function, getState: () => TVDReduxStore) => {
    const {
      user: {
        claims: {
          userId
        }
      }
    } = getState()
    postEstimatesRequest({
      body: {},
      query: {
        parentId: buildingId,
        copyId: id
      }
    }, {}, ({ headers: { location } }: TVDRequestResponse) => {
      const pathName = getPathNameFromURL(location)
      const newId = pathName.split('/').pop()
      const cb = () => {
        getEstimatesRequest({ query: { estimateType: appKey, buildingId, permissionsSubject: userId } })
      }
      switch (appKey) {
        case ESTIMATE_TYPE_SPACE:
          spacesGetEstimateWithEstimateIdRequest({}, cb, null, { accountId: newId })
          break
        case ESTIMATE_TYPE_BUILDING_ELEMENTS:
          elementsGetEstimateWithEstimateIdRequest({}, cb, null, { accountId: newId })
          break
        case ESTIMATE_TYPE_WOP:
          wopGetEstimateWithEstimateIdRequest({}, cb, null, { accountId: newId })
          break
        default:
          break
      }
    })
  }
}
