// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.

import { Component } from 'react'
import { connect } from 'react-redux'

import { getIsFeatureDisabled } from '../../../utils/features'

type MappedProps = {|
  features: TVDFeatureSettings
|}

type Props = {|
  ...MappedProps,
  name: string, // name of the feature
  onFeatureDisabled?: (Object) => any, // fn that returns what will be rendered on feature being disabled, takes props as param
  children: any, // children that Feature will render if the feature is not disabled
|}

type State = {|
  isFeatureDisabled: boolean, // decides if feature is disabled
|}

export class Feature extends Component<Props, State> {
  static defaultProps = {
    onFeatureDisabled: null,
  }

  state = {
    isFeatureDisabled: false
  }

  componentDidMount() {
    const { name, features } = this.props
    const isFeatureDisabled = getIsFeatureDisabled(name, features.disabled)
    this.setState({ isFeatureDisabled })
  }

  get disabledFeature(): any {
    const { onFeatureDisabled } = this.props
    if (onFeatureDisabled) return onFeatureDisabled(this.props)
    return null
  }

  render(): React$Element<any> {
    return this.state.isFeatureDisabled ? this.disabledFeature : this.props.children
  }
}

type ReduxState = {|
  app: {|
      features: TVDFeatureSettings,
    |}
|}

const mapStateToProps = ({ app: { features } }: ReduxState): MappedProps => ({
  features,
})

export default connect(mapStateToProps)(Feature)
