// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.

import { API_BASE_URL } from '../constants/envConstants'
import { basePath as basePathSpaces } from './generated-api-requests/spaces'
import { basePath as basePathElements } from './generated-api-requests/buildingelements'
import { basePath as basePathEstimates } from './generated-api-requests/estimates'
import { basePath as basePathWop } from './generated-api-requests/wop'
import {
  TVD_TOKEN_USER_TYPE_USER,
  TVD_USER_AUTH_PATHNAME
} from '../constants/apiConstants'
import {
  spacesUrl,
  elementsUrl,
  wopUrl,
  locanUrl,
  profitabilityUrl,
  summaryUrl,
  procurementElementsUrl,
  renovationProgramUrl,
  usageAndMaintenanceUrl,
  scenarioNewConstructionUrl,
  scenarioRepairConstructionUrl
} from '../constants/moduleConstants'

type URLConfigs = {
  baseURL: string,
  url: string,
  params?: Object,
}

export function getApiUrl(api: string): $PropertyType<URLConfigs, 'url'> {
  switch (api) {
    case 'wop':
      return `${API_BASE_URL}${basePathWop}`
    case 'spaces':
      return `${API_BASE_URL}${basePathSpaces}`
    case 'pricelist':
    case 'elements':
      return `${API_BASE_URL}${basePathElements}`
    case 'calculations':
      return `${API_BASE_URL}${basePathEstimates}`
    default:
      console.warn(`getApiUrl does not contain a case for ${api}`)
      return ''
  }
}

export function getPathNameFromURL(url: string): string {
  // We need to use a dummy base URL for URLs that are relative. Otherwise we get 'TypeError: Failed to construct 'URL': Invalid URL' upon constructing an URL object from a relative URL.
  // If the given URL is an absolute URL, the dummy base URL doesn't change the given URL in any way.
  return new URL(url, 'http://foobar:9999').pathname
}

export async function wait(ms: number): Promise<any> {
  return new Promise((resolve: Function) => {
    setTimeout(resolve, ms)
  })
}

export const getUserTypeFromPathname = (pathname: string): TVDTokenUserType | typeof undefined => {
  switch (true) {
    case pathname.startsWith(spacesUrl):
    case pathname.startsWith(elementsUrl):
    case pathname.startsWith(wopUrl):
    case pathname.startsWith(TVD_USER_AUTH_PATHNAME):
    case pathname.startsWith(locanUrl):
    case pathname.startsWith(profitabilityUrl):
    case pathname.startsWith(summaryUrl):
    case pathname.startsWith(procurementElementsUrl):
    case pathname.startsWith(renovationProgramUrl):
    case pathname.startsWith(usageAndMaintenanceUrl):
    case pathname.startsWith(scenarioNewConstructionUrl):
    case pathname.startsWith(scenarioRepairConstructionUrl):
    case pathname === '/': return TVD_TOKEN_USER_TYPE_USER
    default: {
      console.error(`No user type could be resolved from pathname ${pathname}`)
      return undefined
    }
  }
}

// This is very useless as is, but due to the fact that there is the possibility that we might require this sort of feature
// again, let's keep it until we can be sure that there will not be need for this
export const getUsersAPIBasePath = (userType: TVDTokenUserType): string | typeof undefined => {
  switch (userType) {
    default: return undefined
  }
}

// This is very useless as is, but due to the fact that there is the possibility that we might require this sort of feature
// again, let's keep it until we can be sure that there will not be need for this
export const getEstimatesAPIBasePath = (userType: TVDTokenUserType): string | typeof undefined => {
  switch (userType) {
    default: return undefined
  }
}

// This is very useless as is, but due to the fact that there is the possibility that we might require this sort of feature
// again, let's keep it until we can be sure that there will not be need for this
export const getUserPrivilegeFilter = (userType: TVDTokenUserType): string | typeof undefined => {
  switch (userType) {
    default: return undefined
  }
}

