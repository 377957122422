// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.
// @flow
import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { reduce, mapValues, includes, every, find, size, filter, assign } from 'lodash'

import RelationalColumn from './components/RelationalColumn'
import Form from './components/Form'
import FloatingForm from './components/FloatingForm'
import TextButton from '../TextButton/TextButton'
import ControlBar from '../ControlBar/ControlBar'

const styles = (): Object => ({
  headerRight: {
    display: 'flex',
    flex: '1',
    alignItems: 'center',
    flexDirection: 'row-reverse',
  },
  columnContainer: {
    display: 'flex',
    overflow: 'auto',
    width: '100%',
    height: '100%',
    padding: '0 16px 0 16px'
  }
})

export type RelationalSelectorProps = {|
  onChange: (selectedCells: Object) => void, // any user induced change fires this function and returns the current user selections
  onAllClear: () => {}, // function fired when the clear button is pressed
  disabled: boolean, // flag to disable the columns
  checkboxes: Array<Object>, // checkboxes to show in the ControlBar
  clearButtonLabel: string, // text for the clear-button in the ControlBar
  isLoading: boolean, // alternate way to disable all children
|}

type Props = {|
  ...RelationalSelectorProps,
  classes: Object, // withStyles styles prop
  children: any, // children for the component, use the Compound Components in this component
  disabled: boolean, // whether the component is completely disabled
|}

type State = {|
  columns: Object, // generated object that will contain all the columns provided to the component and their states, names etc.
|}

export class RelationalSelector extends Component<Props, State> {
  static defaultProps = {
    disabled: false,
    onChange: () => {},
    onAllClear: null
  }

  state = {
    // Children.toArray takes care of the eventuality of a single child (= not an array of children -> error)
    columns: reduce(React.Children.toArray(this.props.children), (obj: any, child: Object, index: number) => ({
      ...obj,
      [child.props.columnName]: {
        columnName: child.props.columnName,
        mode: 'basic',
        userSelected: false,
        focused: undefined,
        index
      }
    }), {}),
  }

  get header(): React$Element<any> {
    const { classes } = this.props
    return (
      <div>
        <ControlBar checkboxes={this.props.checkboxes}>
          <div className={classes.headerRight}>
            <TextButton
              disabled={this.props.disabled}
              id='Clear-selected'
              variant='clearSelected'
              text={this.props.clearButtonLabel}
              onClick={this.handleClearAll} />
          </div>
        </ControlBar>
      </div>
    )
  }

  /** Returns the column's userSelected status. Hence will skip on a return of true (= column has a user selection).
   * @param {Object} item The column item, checks for the value of a *userSelected* property inside this object.
  */
  skipUserSelected = (item: Object) => item.userSelected

  /** A curried function that takes an array of strings that represent column modes and returns a function that compares this array to
   *  the second argument which is the item (= column) being checked against. Returns true if the column mode is included in the modes array.
   * @param {Array<string>} modes An array of strings, representing column modes to be skipped. ex. ['focused', 'active'] would return true on a column with
   *  a matching mode
   * @param {Object} item Parameter for the returned function. It is the item to check against the **modes** array.
   */
  skipModes = (modes: Array<string>) => (item: Object) => includes(modes, item.mode)

  /** A curried function that returns true if the item being checked is in the modes given in the modes array argument or if it's userSelected. A combined
   *  function of skipUserSelected() and skipModes().
   * @param {Array<string>} modes An array of strings, representing column modes to be skipped. ex. ['focused', 'active'] would return true on a column with
   *  a matching mode
   * @param {Object} item Parameter for the returned function. It is the item to check against the **modes** array.
   */
  skipModesAndUserSelected = (modes: Array<string>) => (item: Object) => Boolean(includes(modes, item.mode) || item.userSelected)

  /** Mode determination
   * Documentation about selection / unselect logic: https://tools.haahtela.fi/confluence/pages/viewpage.action?pageId=41203069
  */
  determineColumnModesOnSelection = ({ selectedColumn, selectedColumnIndex, selectedCellID }: Object) => {
    const { columns } = this.state
    const selectedColumnCurrentMode = columns[selectedColumn].mode
    let newState = assign({}, this.state.columns)
    switch (selectedColumnCurrentMode) {
      /** Clicking on a basic column means the Selector was in the initial state. */
      case 'basic':
        /** Set all columns before this to focusing, they might need user interaction. If they only receive a single item RelationalColumn will handle it. */
        newState = this.getNewColumnModes({
          oldColumns: newState,
          startingIndex: 0,
          endIndex: selectedColumnIndex,
          changes: { mode: 'focusing' },
        })

        /** Set the selected item to be active and userSelected, save the cellID. It's the only selection because columns can only be in
         * 'basic' mode when nothing is selected.
         */
        newState[selectedColumn].mode = 'active'
        newState[selectedColumn].userSelected = true
        newState[selectedColumn].focused = selectedCellID

        /** Set columns after the selection to 'focused'. They're not userSelected tho, so they won't get the closing X. */
        newState = this.getNewColumnModes({
          oldColumns: newState,
          startingIndex: selectedColumnIndex + 1,
          endIndex: size(newState),
          changes: { mode: 'focused' },
        })
        break

      case 'focused':
        /** If the user clicked on a focused and previously userSelected column it means we need to reverse the selections to this column. */
        if (newState[selectedColumn].userSelected) {
          // The clicked column becomes the active column.
          newState[selectedColumn].mode = 'active'

          /** This function clears all userSelected flags from columns that follow the selected column. This is what reverses the selection to this column. */
          newState = this.clearUserSelectionsBetweenIndexes({
            oldColumns: newState,
            startIndex: selectedColumnIndex + 1, // +1 to index, we don't want to unselect this column
            endIndex: size(newState)
          })

          // As with all selections that are the most precise at the time, every column after the selected column needs to go into 'focused' mode.
          newState = this.getNewColumnModes({
            oldColumns: newState,
            startingIndex: selectedColumnIndex + 1,
            endIndex: size(newState),
            changes: { mode: 'focused', focused: undefined },
          })
          break
        }

        /** If the focused column wasn't userSelected before then it existed after a previous selection.
         *  The skipUserSelected handler is used to skip columns with active userSelections in the index range we're affecting.
         *  Other columns are changed to 'focusing' because they might've received new items and require user interaction. RelationalColumn will
         *  handle them if they are precise (only contain one item).
         */
        newState = this.getNewColumnModes({
          oldColumns: newState,
          startingIndex: 0,
          endIndex: selectedColumnIndex,
          changes: { mode: 'focusing' },
          exception: this.skipUserSelected
        })

        /** The previous action changed all previous columns to 'focusing' but skipped userSelections (you can't have a userSelected column in 'focusing').
         *  This action now goes back again but changes columns to "focused" but skips the 'focusing' columns set in the previous step.
         *  This results in userSelected columns changing to 'focused' like userSelections behind the active selection should be.
        */
        newState = this.getNewColumnModes({
          oldColumns: newState,
          startingIndex: 0,
          endIndex: selectedColumnIndex,
          changes: { mode: 'focused' },
          exception: this.skipModes(['focusing'])
        })

        // Clicking on a non userSelected focused column should move the active selection forward to that column. That is set here.
        newState[selectedColumn].mode = 'active'
        newState[selectedColumn].userSelected = true
        newState[selectedColumn].focused = selectedCellID
        break
      case 'focusing':
        // Clicking on a column requiring focus should focus that column and make it userSelected.
        newState[selectedColumn].mode = 'focused'
        newState[selectedColumn].userSelected = true
        newState[selectedColumn].focused = selectedCellID
        break
      case 'active':
        break
      default:
        break
    }
    return newState
  }

  determineColumnModesOnUnselect = ({ unselectedColumn, unselectedColumnIndex }: Object) => {
    const { columns } = this.state
    const unselectedColumnCurrentMode = columns[unselectedColumn].mode

    let newState = assign({}, this.state.columns)
    switch (unselectedColumnCurrentMode) {
      case 'focused':
        /** Unselecting a focused userSelection means the columns behind the unselect turn to 'focusing'  */
        newState = this.getNewColumnModes({
          oldColumns: newState,
          startingIndex: 0,
          endIndex: unselectedColumnIndex,
          changes: { mode: 'focusing' },
          exception: this.skipModesAndUserSelected(['focused'])
        })

        // Set the column back to 'focusing'. Remove userSelection.
        newState[unselectedColumn].mode = 'focusing'
        newState[unselectedColumn].userSelected = false

        /** After removing a focused selection the states of columns to the left of the rightmost selection (currently active)
         * needs to be changed to 'focusing'. This action however skips already focused as well as userSelected columns - they need to stay as they are.
         */
        newState = this.getNewColumnModes({
          oldColumns: newState,
          startingIndex: unselectedColumnIndex + 1,
          endIndex: this.findRightmostSelection(newState),
          changes: { mode: 'focusing' },
          exception: this.skipModesAndUserSelected(['focused'])
        })

        newState = this.getNewColumnModes({
          oldColumns: newState,
          startingIndex: this.findRightmostSelection(newState) + 1,
          endIndex: size(newState),
          changes: { mode: 'focused' },
          exception: this.skipUserSelected
        })
        break
      case 'active':
        // Unselect the column and clear its focused info.
        newState[unselectedColumn].userSelected = false
        newState[unselectedColumn].focused = undefined

        // Find the new rightmost selection and set its mode to 'active'. It's the new most precise selection.
        newState[this.getColumnByIndex(newState, this.findRightmostSelection(newState)).columnName].mode = 'active'

        /** Find the new rightmost selection (now 'active') and set all columns after it to 'focused' as you do with selections. */
        newState = this.getNewColumnModes({
          oldColumns: newState,
          startingIndex: this.findRightmostSelection(newState) + 1,
          endIndex: size(newState),
          changes: { mode: 'focused' }
        })
        break
      default:
        break
    }
    return newState
  }

  /** Sets the modes of items inside the oldColumns parameter. Used to handle column state changes between indexes which are
   *  given as startingIndex (inclusive) - endIndex(excluded).
   * @param {Object} args.oldColumns - The set of columns that will have their modes altered.
   * @param {number} args.startingIndex - The column index from which to begin the changes from. Inclusive - the mode of the column at this index is changed.
   * @param {number} args.endIndex - The column index to end changes at. Exlusive - the mode of this column at this index is not changed.
   * @param {Object} args.changes - The changes to be made into the column properties between the indexes.
   * @param {Function} args.exception - The exception function. This is called with the current column for every item between the given indexes. If it returns true the column is skipped for changes
   * @param {Object} args - The argument object.
   */
  getNewColumnModes = ({
    oldColumns,
    startingIndex,
    endIndex,
    changes,
    exception = () => false // default false, every column in range gets the changes applied
  }: Object) => {
    const newColumns = mapValues(oldColumns, (col: Object) => {
      // check if the column is between the indexes we want to affect
      if (this.isBetween(startingIndex, endIndex, col.index)) {
        // To note: the exception function is called here with the current column under inspection as an argument.
        // If it returns true the column is returned with no changes.
        if (exception(col)) return col
        // if calling exception with the column resulted in false then a new column object is created
        // with the changes object providing overrides for column properties
        return { ...col, ...changes }
      }
      // no changes made to columns outside the wanted index range
      return col
    })

    return newColumns
  }

  /** Finds the current rightmost column in the columns argument. This means the one with userSelected = true and the highest index.
   * @param {Object} columns - the columns object, the same structure as the columns object in this component's state
   */
  findRightmostSelection = (columns: Object) => {
    const result = reduce(columns, (highestIndex: number, current: Object) => {
      if (current.userSelected && current.index > highestIndex) return current.index
      return highestIndex
    }, -1)
    return result
  }

  /** Finds the current leftmost column in the columns argument. This means the one with userSelected = true and the lowest index.
   * @param {Object} columns - the columns object, the same structure as the columns object in this component's state
   */
  findLeftmostSelection = (columns: Array<Object>) => {
    const result = reduce(columns, (highestIndex: number, current: Object) => {
      if (current.userSelected && current.index < highestIndex) return current.index
      return highestIndex
    }, size(columns) + 1)
    return result
  }

  /** Returns the column object that matches the index.
   * @param {Object} columns - the columns object
   * @param {number} index - the index to search for
   */
  getColumnByIndex = (columns: Array<Object>, index: number) => find(columns, ['index', index])

  /** Sets all userSelected flags to false between the indexes.
   * @param {Object} args.oldColumns - the columns obect, the same structure as the state column object
   * @param {number} args.startIndex - the column index to begin the operation from
   * @param {number} args.endIndex - the column index to stop the operation to, excluded from the operation
   */
  clearUserSelectionsBetweenIndexes = ({ oldColumns, startIndex, endIndex }: Object) => {
    const newColumns = oldColumns
    return mapValues(newColumns, (col: Object) => ({
      ...col,
      userSelected: this.isBetween(startIndex, endIndex, col.index) ? false : col.userSelected // maintain userSelections outside the given index range
    }))
  }

  /** Checks if the path to the index is unambiguous. Used to determine whether the children of the RelationalColumn should be shown (usually a Floating Form).
   * @param {number} upToIndex - the index up to which the check is made, includes the column at this index - just in case.
   */
  isPathUnambiguous = (upToIndex: number) => {
    const { columns } = this.state
    const columnsInPath = filter(columns, (col: Object) => col.index <= upToIndex)
    return every(columnsInPath, (col: Object) => Boolean(col.focused)) // if the column has a cell id in the focused state property -> it's at max focus and OK
  }

  /** Handles selecting an item in a column. Calls the onChange prop function after the state change with user selected cellIDs.
   * @param {string} columnName - name of the column where the selection happened
   * @param {string} cellID - a unique id for the cell that the user selected in the column
   * @param {number} index - the column index that indicates its location in the selector
   */
  handleSelect = (columnName: string, cellID: string, index: number) => {
    const newColumnsAndFocused = this.determineColumnModesOnSelection({
      selectedColumn: columnName,
      selectedColumnIndex: index,
      selectedCellID: cellID
    })

    this.setState((state: State) => ({
      ...state,
      columns: newColumnsAndFocused
    }), () => this.triggerOnChange())
  }

  /** Handles removing selections in columns. Calls the onChange prop function after the state change with user selected cellIDs.
   * @param {string} columnName - name of the column where the selection happened
   * @param {number} index - the column index that indicates its location in the selector
  */
  handleUnselect = (columnName: string, index: number) => {
    const { columns } = this.state


    // if the selection was the leftmost and rightmost selection then it is the only selection
    const unselectedColumnWasOnlySelection = this.findRightmostSelection(columns) === this.findLeftmostSelection(columns)
    const unselectedColumnCurrentMode = columns[columnName].mode

    // OPTIMIZATION for unselecting the only selection, with column data caching in the container, using
    // the onAllClear callback.
    if (unselectedColumnWasOnlySelection && unselectedColumnCurrentMode === 'active') {
      this.handleClearAll()
      return
    }

    const newColumnsAndFocused = this.determineColumnModesOnUnselect({ unselectedColumn: columnName, unselectedColumnIndex: index })

    this.setState((state: State) => ({
      ...state,
      columns: newColumnsAndFocused
    }), () => {
      this.triggerOnChange()
    })
  }

  /** Handles focusing actions in columns. These happen when a column is in 'focusing' mode and receives only a single item making it max focused.
   *  This is not directly connect to user actions in the column in question.
   *  Calls the onChange prop if the update is in a column that is userSelected.
   * @param {string} columnName - name of the column where the selection happened
   * @param {number} cellID - the unique identifier for the cell that is now the only item in the column
   */
  handleFocus = (columnName: string, cellID: string) => {
    this.setState((state: State) => ({
      ...state,
      columns: {
        ...state.columns,
        [columnName]: {
          ...state.columns[columnName],
          focused: cellID
        }
      }
    }), () => {
      // if the focusing update happened in a user selected column, it needs to be updated
      if (this.state.columns[columnName].userSelected) this.triggerOnChange()
    })
  }

  /** Utility method for triggering the onChange prop with userSelected items. */
  triggerOnChange = () => this.props.onChange(this.getUserSelected())

  /** Clears all selections in columns, sets them in basic mode and removes all focusings. Basically returns the component to the starting state.
   *  If no custom onAllClear prop is specified runs the onChange callback with the new (empty) selections
   */
  handleClearAll = () => {
    this.setState((state: State) => ({
      ...state,
      columns: this.setAllColumnStatesTo({ mode: 'basic', userSelected: false, focused: undefined })
    }), () => {
      // in case of a custom clear function, run this
      if (this.props.onAllClear) {
        this.props.onAllClear()
      } else {
        // otherwise a normal user selection update with zero selections - as set in the setState above
        this.triggerOnChange()
      }
    })
  }

  // utilities
  setAllColumnStatesTo = ({ mode, userSelected, focused }: Object) => {
    const { columns } = this.state
    return mapValues(columns, (col: Object) => ({
      ...col,
      mode,
      userSelected,
      focused
    }))
  }

  /** Returns all user selected items from component state. This is done by finding all columns in state with the userSelected flag set to true and then
   * returning their focused value.
   */
  getUserSelected = () => {
    const { columns } = this.state
    return mapValues(columns, (col: Object) => {
      if (col.userSelected) {
        return col.focused
      }
      return undefined
    })
  }

  /** Returns true or false depending on if the *i* parameter is between *start* and *end*. *end* is excluded from the result.
   * @param {number} start - starting index for the range, included
   * @param {number} end - ending index that is excluded from the check
   * @param {number} i - the number to check
   */
  isBetween = (start: number, end: number, i: number) => Boolean(start <= i && i < end)

  // COMPOUND COMPONENTS
  static Column = (props: Object) => <RelationalColumn {...props} />

  static FloatingForm = (props: Object) => <FloatingForm {...props} />

  static Form = (props: Object) => <Form {...props} />

  render(): React$Element<any> {
    const {
      classes,
      disabled,
      isLoading,
      children
    } = this.props
    const { columns } = this.state

    // removes non-components, in case of optional renders in the parent
    const kids = React.Children.toArray(children)

    return (
      <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
        {this.header}
        <div className={classes.columnContainer}>
          { React.Children.map(kids, (child: Object, index: number) => React.cloneElement(child, {
              disabled: disabled || isLoading,
              ...child.props,
              onUserSelect: this.handleSelect,
              onSingleItemFocus: this.handleFocus,
              onUnselect: this.handleUnselect,
              isPathClear: this.isPathUnambiguous(index),
              focusedId: columns[child.props.columnName].focused,
              mode: columns[child.props.columnName].mode,
              userSelected: columns[child.props.columnName].userSelected,
              index,
            }))}
        </div>
      </div>
    )
  }
}

export default withStyles(styles)(RelationalSelector)
