// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withTranslation } from 'react-i18next'
import { withStyles } from '@material-ui/core/styles'
import { Table, TableBody } from '@material-ui/core'
import { map } from 'lodash'
import { typographyClasses, colors } from 'frontend-assets'

import HierarchicalListContainer from '../../HierarchicalListContainer/HierarchicalListContainer'
import PropertyRow from '../../../common/Tabs/Properties/PropertyRow/PropertyRow'
import HamburgerMenu from '../../../common/menus/HamburgerMenu/HamburgerMenu'
import UserModifiedIcon from '../../../containers/infoComponents/UserModifiedIcon'
import DescriptionCell from '../../../common/lists/common/DescriptionCell/DescriptionCell'
import SentientHOC from '../../../hocs/SentientHOC/SentientHOC'
import FeaturesHOC from '../../../hocs/FeaturesHOC/FeaturesHOC'
import { type TVDConfirmModalContent } from '../../../common/ConfirmationModal/ConfirmationModal'


import { UNIT_PRICE, QUANTITY, DESCRIPTION, TOTAL_PRICE_IN_CURRENCY } from '../../../../constants/attributes'
import { PRICEITEM, CONFIRMATION_MODAL, HEADING } from '../../../../constants/contentTypes'
import { ASSEMBLY_ATTRIBUTES } from '../../../../constants/features'
import { openContentWidget } from '../../../../actions/widgets'
import { postPolling } from '../../../../actions/postPolling'
import {
  buildDeleteModal,
  buildRenameModal,
  buildAttachItemModal,
  closeModal,
  openModal
} from '../../../../actions/modals'
import {
  getAssemblyPropertiesWithIdRequestDefinitions,
  getAssemblyItemsWithIdRequestDefinitions,
  patchPriceitemWithIdRequest,
  patchAssemblyPropertiesWithIdRequest,
  getAssemblyWithIdRequestDefinitions,
} from '../../../../utils/generated-api-requests/buildingelements'
import { isListItemTypeBold } from '../../../../utils/listUtils'
import ViewHeaderText from '../../../common/ViewHeaderText/ViewHeaderText'

const { h4 } = typographyClasses
const { dark80 } = colors

const styles = ({ palette }: TVDTheme): Object => ({
  root: {
    display: 'flex',
    flex: '1',
    flexDirection: 'column',
    position: 'relative',
    overflow: 'hidden'
  },
  attributesTable: {
    borderTop: `1px solid ${palette.ui07}`,
  },
  referenceCellContent: {
    alignItems: 'center',
    cursor: 'pointer',
    color: palette.lochmara,
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    outline: 0
  },
  disabledCell: {
    color: palette.manatee
  },
  title: {
    ...h4,
    color: dark80,
    padding: '32px 24px 24px'
  },
})

type DispatchProps = {|
  dispatchDeleteItem: (string) => void, // function to delete referenceTable priceitem from store, request is dispatched from widgets save button
  dispatchOpenPriceItemWidget: (TVDListItem) => void, // opens widget containing priceitem data
  dispatchPostPolling: () => void, // initiating post polling process
  dispatchBuildDeleteModal: (string) => void,
  dispatchBuildRenameModal: (Object) => void, // open rename list item modal
  dispatchBuildAttachItemModal: (string) => void, // open attach priceitem modal
  dispatchOpenConfirmResetModal: (content: TVDConfirmModalContent, id: string) => void, // open a modal with custom content and type
  dispatchCloseModal: (id: string) => void // remove a modal from openModals in Redux Store and close it in the UI
|}

type MappedProps = {|
  properties: { [propertyName: string]: TVDPropertiesListItem }, // Object of property objects for assembly's attribute list
  application: string, // module name e.g elements or spaces
  activeEdit: boolean, // flag to disable widget content when view is being edited
  isEstimateFrozen: $PropertyType<TVDApplicationStore, 'isEstimateFrozen'>,
  isEstimateLockedToCurrentUser: $PropertyType<TVDApplicationStore, 'isEstimateLockedToCurrentUser'>
|}

type ReceivedProps = {|
  attributesPropertiesStoreId: string, // store id for properties that are shown in assembly as attributes
  itemsListStoreId: string, // store id for listItems that are shown in assembly as items
  disabled: boolean, // flag to disable widget content if other widgets are beign modified
  assemblyId: string, // uuid of the resource
  widgetId: string, // widget's id used in Store
  modified: boolean, // if widget is considered modified
|}

type HOCProps = {|
  classes: Object, // withStyles classes object
  features: TVDFeatureHOCProps, // features from Store and helper functions from the HOC
  sentient: TVDSentient, // Object providing helpers via SentientHOC
  t: Function, // translate function
|}
type Props = {|
  ...DispatchProps,
  ...MappedProps,
  ...ReceivedProps,
  ...HOCProps,
|}

export class Assembly extends Component<Props> {
  get attributesTable(): Array<React$Element<PropertyRow>> {
    const {
      features,
      properties,
      isEstimateLockedToCurrentUser,
      isEstimateFrozen
    } = this.props
    const isEstimateNotLockedOrFrozen = !isEstimateLockedToCurrentUser || isEstimateFrozen
    return map(properties, (property: TVDPropertiesListItem): React$Element<PropertyRow> => (
      <PropertyRow
        key={property.propertyName}
        row={property}
        disabled={features.getIsFeatureDisabled(ASSEMBLY_ATTRIBUTES) || this.getIsDisabled() || isEstimateNotLockedOrFrozen}
        paddingLeftRow={56}
        rowText={property.localizedName}
        onBlur={this.patchProperty.bind(this)} />))
  }
  // As there is no endpoint we use static columns with Assembly component
  get staticColumns(): Array<TVDListItemColumn> {
    const { t } = this.props
    return [
      {
        localizedName: t('widgets._DESCRIPTION_'),
        propertyName: DESCRIPTION,
        dataType: 'string'
      },
      {
        localizedName: t('widgets._QUANTITY_'),
        propertyName: QUANTITY,
        dataType: 'number'
      },
      {
        localizedName: t('widgets._UNITPRICE_'),
        propertyName: UNIT_PRICE,
        dataType: 'number'
      },
      {
        localizedName: t('widgets._TOTAL_PRICE_IN_CURRENCY_'),
        propertyName: TOTAL_PRICE_IN_CURRENCY,
        dataType: 'integer'
      },
      {
        localizedName: '',
        propertyName: 'UserModifiedIconColumn',
        dataType: ''
      },
    ]
  }

  get wrappedCellContents(): TVDWrappedCells {
    const { classes, dispatchOpenPriceItemWidget } = this.props
    return {
      Description: ({ content, row }: TVDWrappedCellCallbackParameters) => {
        const isHeading = row.type === HEADING.toLowerCase()
        const descriptionCellStyles = this.getIsDisabled() || isHeading
          ? `${classes.referenceCellContent} ${classes.disabledCell}`
          : classes.referenceCellContent
        return (
          <div
            className={descriptionCellStyles}
            role='button'
            tabIndex={0}>
            <DescriptionCell
              noPointer={isHeading}
              onClick={!isHeading ? (event: SyntheticMouseEvent<any>) => {
                dispatchOpenPriceItemWidget(row)
                event.stopPropagation()
              } : null}
              highlighted={isHeading ? false : !this.getIsDisabled()}
              text={content}
              bold={isListItemTypeBold(row.type)} />
            {
              this.showContextMenu() && !isHeading && (
                <div data-visible_on_hover>
                  <HamburgerMenu id='assemblyPriceitemHamburgerMenu' items={this.getContextMenuItems(row)} />
                </div>
              )
            }
          </div>
        )
      },
      UserModifiedIconColumn: ({ row: { id, userModifiedPricing } }: TVDWrappedCellCallbackParameters) => {
        const { t } = this.props
        return (
          userModifiedPricing ?
            <UserModifiedIcon
              actionCb={() => this.openConfirmResetModal(id)}
              text={t('userModifiedInfo._USER_MODIFIED_PRICING_')}
              actionText={t('userModifiedInfo._REVERT_TO_PRICING_')} /> :
            <div />
        )
      }
    }
  }

  get referenceLevelTable(): React$Element<any> {
    const {
      itemsListStoreId,
      sentient,
      isEstimateFrozen,
      isEstimateLockedToCurrentUser
    } = this.props
    return (
      <HierarchicalListContainer
        initialColumnWidths={{
          [DESCRIPTION]: 530,
          UserModifiedIconColumn: 40
        }}
        isEstimateFrozen={isEstimateFrozen}
        isEstimateLockedToCurrentUser={isEstimateLockedToCurrentUser}
        disabled={this.getIsDisabled()}
        listId={itemsListStoreId}
        testId='assemblyList'
        addedColumns={this.staticColumns}
        editableColumns={[QUANTITY, UNIT_PRICE, TOTAL_PRICE_IN_CURRENCY]}
        editableColumnCheck={(row: TVDListItem, column: TVDListItemColumn) => {
          const type = row.type.toUpperCase()
          if (type === HEADING) return false
          return [QUANTITY, UNIT_PRICE, TOTAL_PRICE_IN_CURRENCY].includes(column.propertyName)
        }}
        didMountCallback={() => { sentient.runSetup() }}
        wrappedCellContents={this.wrappedCellContents}
        onModifiedChange={(listItem: TVDListItem) => { if (listItem) { this.onModify(listItem.id, listItem.modifiedColumnData) } }} />
    )
  }

  showContextMenu = (): boolean => {
    const {
      isEstimateFrozen,
      isEstimateLockedToCurrentUser
    } = this.props

    return !isEstimateFrozen && !!isEstimateLockedToCurrentUser
  }


  getIsDisabled(): boolean {
    const { disabled, activeEdit, } = this.props
    return disabled || activeEdit
  }

  openConfirmResetModal(id: string) {
    const { dispatchOpenConfirmResetModal, dispatchCloseModal, t } = this.props
    const modalId = `confirm-reset-${id}`
    const content: TVDConfirmModalContent = {
      saveButtonText: 'buttons._RESET_TO_PRICING_',
      message: [t('listItemDialog._RESET_TO_PRICING_CONFIRM'), t('listItemDialog._RESET_TO_PRICING_CONFIRM_EXPLANATION')],
      type: CONFIRMATION_MODAL,
      onSave: () => { this.resetPriceItem(id) },
      onClose: () => { dispatchCloseModal(modalId) }
    }
    dispatchOpenConfirmResetModal(content, modalId)
  }

  getContextMenuItems(row: TVDListItem): Array<TVDMenuItem> {
    const {
      t,
      dispatchBuildAttachItemModal,
      dispatchBuildDeleteModal,
      dispatchBuildRenameModal,
    } = this.props

    const menuItems = [
      {
        localizedName: t('buttons._ATTACH_ITEM_'),
        onClick: () => { dispatchBuildAttachItemModal(row.id) },
        testId: 'ContextMenuItem-attach-item'
      }, {
        localizedName: t('buttons._RENAME_'),
        onClick: () => { dispatchBuildRenameModal(row) },
        testId: 'ContextMenuItem-rename'
      }, {
        localizedName: t('buttons._DELETE_'),
        onClick: () => { dispatchBuildDeleteModal(row.id) },
        testId: 'ContextMenuItem-delete'
      }
    ]

    const resetValueFromPricingMenuItem = {
      localizedName: t('buttons._RESET_VALUE_FROM_PRICING_'),
      onClick: () => { this.openConfirmResetModal(row.id) },
      testId: 'ContextMenuItem-reset-pricing'
    }

    if (row.userModifiedPricing) menuItems.push(resetValueFromPricingMenuItem)

    return menuItems
  }

  onModify(id: string, modifiedColumnData: Object) {
    patchPriceitemWithIdRequest({ path: { id }, body: modifiedColumnData }, {}, () => {
      this.props.dispatchPostPolling()
    })
  }

  resetPriceItem(rowId: string) {
    patchPriceitemWithIdRequest({ path: { id: rowId }, query: { resetPricing: true }, body: {} }, {}, () => {
      this.props.dispatchPostPolling()
    })
  }

  patchProperty(propertyName: string, value: string | number) {
    const { assemblyId, dispatchPostPolling } = this.props
    patchAssemblyPropertiesWithIdRequest({
      path: { id: assemblyId },
      body: { [propertyName]: value }
    }, {}, () => { dispatchPostPolling() })
  }

  render(): React$Element<any> {
    const { classes, t } = this.props

    return (
      <div className={classes.root}>
        <ViewHeaderText inWidget>{t('widgets._ASSEMBLY_AMOUNT_')}</ViewHeaderText>
        <Table className={classes.attributesTable}>
          <TableBody>
            {this.attributesTable}
          </TableBody>
        </Table>
        <div className={classes.title}>{t('widgets._ASSEMBLY_ITEMS_')}</div>
        {this.referenceLevelTable}
      </div>
    )
  }
}

const mapStateToProps = ({ properties, app }: TVDReduxStore, props: ReceivedProps): MappedProps => {
  const {
    application,
    activeEdit,
    isEstimateFrozen,
    isEstimateLockedToCurrentUser
  } = app
  const { attributesPropertiesStoreId } = props
  return {
    properties: properties[attributesPropertiesStoreId],
    application,
    activeEdit,
    isEstimateFrozen,
    isEstimateLockedToCurrentUser
  }
}

function mapDispatchToProps(dispatch: Function, props: ReceivedProps): Object {
  const { assemblyId, itemsListStoreId } = props

  return {
    dispatchOpenPriceItemWidget: (row: TVDListItem) => dispatch(openContentWidget({
      widgetId: row.id,
      widgetType: PRICEITEM,
      widgetTitle: row.columnData.Description,
      contentProps: {
        priceItemId: row.id,
        propertiesStoreId: row.id,
        listStoreId: assemblyId
      },
    })),
    dispatchPostPolling: () => dispatch(postPolling()),
    dispatchBuildDeleteModal: (rowId: string) => dispatch(buildDeleteModal(rowId, itemsListStoreId, '_DELETE_')),
    dispatchBuildRenameModal: (row: TVDListItem) => dispatch(buildRenameModal({ row, listId: itemsListStoreId, useSavePattern: true })),
    dispatchBuildAttachItemModal: (rowId: string) => dispatch(buildAttachItemModal(rowId, assemblyId)),
    dispatchOpenConfirmResetModal: (content: TVDOpenContentWidgetArguments, modalId: string) => { dispatch(openModal(content, modalId)) },
    dispatchCloseModal: (modalId: string) => { dispatch(closeModal(modalId)) },
  }
}

const sentientConfig: TVDSentientConfig = {
  getSetupRequestDefinitions: (store: Object, {
    attributesPropertiesStoreId,
    itemsListStoreId,
    assemblyId,
    widgetId
  }: Props): TVDGARConfigs =>
    ({
      getAssemblyWithIdRequest: getAssemblyWithIdRequestDefinitions({
        payload: { widgetId },
        requestArgs: { path: { id: assemblyId } }
      }),
      getAssemblyPropertiesWithIdRequest: getAssemblyPropertiesWithIdRequestDefinitions({
        payload: { propertiesStoreId: attributesPropertiesStoreId },
        requestArgs: { path: { id: assemblyId } }
      }),
      getAssemblyItemsWithIdRequest: getAssemblyItemsWithIdRequestDefinitions({
        payload: { listStoreId: itemsListStoreId, mergeOptions: { initialListItems: {} } },
        requestArgs: { path: { id: assemblyId } }
      }),
    })
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation('translations'),
  withStyles(styles),
  FeaturesHOC
)(SentientHOC(Assembly, sentientConfig))
