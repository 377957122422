// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.
import React, { Component } from 'react'
import { connect, batch } from 'react-redux'
import { compose } from 'redux'
import { reduce, filter, pickBy } from 'lodash'
import { withTranslation } from 'react-i18next'
import { withStyles } from '@material-ui/core/styles'
import SentientHOC from '../../../../hocs/SentientHOC/SentientHOC'
import {
  getRenovationSpacesProfilesWithEstimateIdRequestDefinitions, getRenovationSpacesProfilesPropertiesWithProfileIdRequest,

} from '../../../../../utils/generated-api-requests/spaces_renovation'
import {
  traverseListItems,
  getListItemParentIds,
  getRootListItems,
  mergeModifiedColumnData,
  getUserModifiedDataFromColumnMeta
} from '../../../../../utils/listUtils'
import { modifyListItem, setRenovationProfile } from '../../../../../actions/list'
import FeaturesHOC from '../../../../hocs/FeaturesHOC/FeaturesHOC'

import type { TextButtonProps } from '../../../../common/TextButton/TextButton'
import SimpleHierarchicalList from '../../../../common/lists/SimpleHierarchicalList/SimpleHierarchicalList'
import FooterButtons from '../../../../containers/widgets/FooterButtons/FooterButtons'
import { SPACE } from '../../../../../constants/contentTypes'
import { createPatchOperation, createPatchOperationParameter } from '../../../../../utils/patchOperationUtil'
import { TVD_PATCH_OPERATION_TYPE_UPDATE, patchOperationRenovationProfileBasePath } from '../../../../../constants/patchOperationConstants'
import {
  storePatchOperation,
  storePatchOperationParameter,
  type StorePatchOperationParameterOptions
} from '../../../../../actions/patchOperations'
import { setWidgetActive } from '../../../../../actions/app'

const styles = ({ palette, typography }: Object) => ({
  header: {
    paddingLeft: '30px',
    width: '100%',
    color: palette.manatee,
    fontSize: '16px',
    lineHeight: '19px',
    fontFamily: typography.latoBoldItalic,
    paddingTop: '32px',
  },
  properties: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'auto',

  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '65vh',
  },
  footer: {
    width: '100%',
    display: 'flex',
    padding: '25px',
    alignSelf: 'flex-end'
  },
  buttons: {
    display: 'flex',
    flex: '1',
    justifyContent: 'flex-end'
  }
})

type ReceivedProps = {|
  listStoreId: string, // unique id for the HLC instance in "list" ReduxStore
  onClose: () => void, // close modal
  row: TVDListItem, // array of data in table body
  widgetId: string, // id of widget
  widgetTab: string, // widget tab name where the component is located in
  copyOnlyProfile?: boolean, // boolean to copy only the values that belong to renovation profile
  columnPropertyName?: string // name of the clicked column
|}

type MappedProps = {|
  listItems: Object, // list items for copy renovation modal
  patchOperations: TVDPatchOperations, // set of patch operations under an id
|}

type DispatchProps = {|
  dispatchModifyListItem: (listItemId: string, columnName: string, value: string) => void, // modifies list item in Store and assigns list as modified
  dispatchStorePatchOperation: (patchOperation: TVDPatchOperation, storePatchOperationParameterOptions?: StorePatchOperationParameterOptions) => void, // stores a patch operation
  dispatchStorePatchOperationParameter: (
    patchOperationParameterObject: TVDPatchOperationParameterObject,
  ) => void, // stores a patch operation parameter
  dispatchSetWidgetActive: () => void, // sets current widget active
  dispatchSetRenovationProfile: (Object, string) => void // action to set renovation profile data for row
|}

type Props = {|
  ...ReceivedProps,
  ...MappedProps,
  ...DispatchProps,
  t: (string) => string, // i18n translation function
  classes: Object, // withStyles classes object
|}

type State = {
  listItems: TVDListItems, // list items object
}

export class RenovationCopyProfile extends Component<Props, State> {
  static defaultProps = {
    wrappedCellContents: {}
  }

  state = {}

  componentDidMount() {
    const { listItems } = this.props
    this.setState({ listItems })
  }

  openRow = ({ id }: TVDListItem): void => {
    const { listItems } = this.state
    const cb = (listItem: TVDListItem) => {
      if (listItem.id === id && listItem.canHaveChildren) {
        return {
          ...listItem,
          isOpen: !listItem.isOpen
        }
      }
      return listItem
    }
    const newListItems: TVDListItems = this.traverseListItem(listItems, listItems[id], cb)
      .reduce((result: TVDListItems, resultListItem: TVDListItem): TVDListItems => ({ ...result, [resultListItem.id]: resultListItem }), {})
    this.setState({
      listItems: {
        ...listItems,
        // $FlowFixMe
        ...newListItems
      }
    })
  }

  onCopy = () => {
    const {
      row,
      onClose,
      dispatchModifyListItem,
      patchOperations,
      dispatchStorePatchOperation,
      dispatchStorePatchOperationParameter,
      dispatchSetWidgetActive,
      columnPropertyName
    } = this.props

    const { listItems } = this.state
    const {
      id,
      columnData: {
        Description,
        AreaTotalM2,
        ...restOfColumnData
      },
      modifiedColumnData: {
        Description: mDescription,
        AreaTotalM2: mAreaTotalM2,
        ...restOfModifiedColumnData
      } = {}
    } = row
    const selectedListItems = filter(listItems, (listItem: TVDListItem) => listItem.selected && listItem.type === SPACE.toLowerCase())
    const { [id]: resourcePatchOperations = [] } = patchOperations
    const { operationParameters = {} } = resourcePatchOperations
      .find(({ operationType }: TVDPatchOperation) => operationType === TVD_PATCH_OPERATION_TYPE_UPDATE) || {}
    selectedListItems.forEach((listItem: TVDListItem) => {
      const allColumnData = {
        ...restOfColumnData,
        // $FlowFixMe
        ...restOfModifiedColumnData,
      }
      const copyColumndata = pickBy(allColumnData, (value: string, key: string) => key === columnPropertyName)
      const columnData = columnPropertyName !== undefined ? copyColumndata : allColumnData
      Object.keys(columnData).forEach((columnDataKey: string) => {
        const main = createPatchOperation({
          resourceId: listItem.id,
          basePath: `columnData/${columnDataKey}`,
          value: allColumnData[columnDataKey],
          operationType: TVD_PATCH_OPERATION_TYPE_UPDATE
        })
        const existingOperationParameters = Object.keys(operationParameters)
          .reduce((allParameterObjects: Array<TVDPatchOperationParameterObject>, operationParameterPath: string) => {
            const bPath = `columnData/${columnDataKey}/`
            if (operationParameterPath.startsWith(bPath)) {
              const operationParameterObj = createPatchOperationParameter({
                resourceId: listItem.id,
                basePath: `columnData/${columnDataKey}`,
                resourcePath: operationParameterPath.split(bPath)[1],
                value: operationParameters[operationParameterPath],
                operationType: TVD_PATCH_OPERATION_TYPE_UPDATE
              })
              return [...allParameterObjects, operationParameterObj]
            }
            return allParameterObjects
          }, [])
        batch(() => {
          dispatchSetWidgetActive()
          dispatchModifyListItem(listItem.id, columnDataKey, allColumnData[columnDataKey])
          dispatchStorePatchOperation(main, {
            removeExistingPatchOperationParametersBeginningWith: `columnData/${columnDataKey}/`
          })
          existingOperationParameters.forEach((existingObjectParameter: TVDPatchOperationParameterObject) => {
            dispatchStorePatchOperationParameter(existingObjectParameter)
          })
        })
      }, [])
    })
    onClose()
  }

  onProfileCopy = () => {
    const {
      row,
      onClose,
      dispatchStorePatchOperation,
      dispatchSetWidgetActive,
      dispatchSetRenovationProfile
    } = this.props

    const { columnData: { RenovationProfileId } } = row
    if (RenovationProfileId) {
      const { listItems } = this.state
      const selectedListItems = filter(listItems, (listItem: TVDListItem) => listItem.selected && listItem.type === SPACE.toLowerCase())

      selectedListItems.forEach((listItem: TVDListItem) => {
        const userModifiedColumnData = getUserModifiedDataFromColumnMeta(listItem)

        getRenovationSpacesProfilesPropertiesWithProfileIdRequest({ path: { profileId: RenovationProfileId } }, {}, (resp: Object) => {
          const renovationProfileData = reduce(resp, (result: Object, measure: Object): Object => ({
            ...result, [measure.propertyName]: measure.value
          }), {})

          const newColumnData = {
            ...renovationProfileData,
            ...userModifiedColumnData,
            RenovationProfileId
          }

          const patchOperation = createPatchOperation({
            resourceId: listItem.id,
            value: RenovationProfileId,
            basePath: patchOperationRenovationProfileBasePath,
            operationType: TVD_PATCH_OPERATION_TYPE_UPDATE,
          })
          batch(() => {
            dispatchSetWidgetActive()
            dispatchStorePatchOperation(patchOperation)
            dispatchSetRenovationProfile(newColumnData, listItem.id)
          })
        })
      })
    }
    onClose()
  }

  getFooterButtonItems = (): TextButtonProps[] => {
    const { t, onClose, copyOnlyProfile } = this.props
    const copyAction = copyOnlyProfile ? this.onProfileCopy : this.onCopy
    const footerButtonItems: TextButtonProps[] = [
      {
        id: 'renovation-copy',
        onClick: () => copyAction(),
        text: t('copyRenovationModal._COPY_'),
      },
      {
        id: 'renovation-cancel',
        onClick: onClose,
        text: t('widgets._CANCEL_'),
        variant: 'text'
      }
    ]
    return footerButtonItems
  }

  getSimpleHierarchicalListColumns = () => [
    { propertyName: 'Description', localizedName: '', dataType: 'string' },
  ]

  traverseListItem = (listItems: TVDListItems, rootListItem: TVDListItem, listItemCb?: (listItem: TVDListItem) => TVDListItem) => {
    if (rootListItem.isRemoved) return []
    let childCount = 0

    const children = filter(listItems, (listItem: TVDListItem) => {
      const child = listItem.parentId === rootListItem.id && !listItem.isRemoved
      if (child) childCount += 1
      return child && rootListItem.isOpen
    })

    const childrenWithComputedProperties = children.map((child: TVDListItem) => ({
      ...child,
      columnData: mergeModifiedColumnData(child),
      level: rootListItem.level + 1
    }))

    const rootListItemWithComputedProperties = {
      ...rootListItem,
      columnData: mergeModifiedColumnData(rootListItem),
      canHaveChildren: rootListItem.canHaveChildren || childCount > 0
    }
    return reduce(childrenWithComputedProperties, (result: Array<TVDListItem>, child: TVDListItem) => [
      ...result,
      ...this.traverseListItem(listItems, child, listItemCb)
    ], [listItemCb ? listItemCb(rootListItemWithComputedProperties) : rootListItemWithComputedProperties])
  }

  toggleListItemSelected = (listItemId: string) => {
    const { listItems } = this.state
    const isItemCurrentlySelected = listItems[listItemId].selected || false
    const updatedListItems = traverseListItems(listItems, (listItem: Object) => {
      const parents = getListItemParentIds(listItems, listItem)
      return {
        [listItem.id]: {
          ...listItem,
          ...{ selected: parents.includes(listItemId) || listItem.id === listItemId ? !isItemCurrentlySelected : listItem.selected }
        }
      }
    })
    this.setState({
      listItems: updatedListItems
    })
  }

  getListHierarchy(): Array<TVDListItem> {
    const { listItems } = this.state
    const { row } = this.props
    return reduce(getRootListItems(listItems), (result: Array<TVDListItem>, rootListItem: TVDListItem) => [
      ...result,
      ...this.traverseListItem(listItems, rootListItem, (listItem: TVDListItem): TVDListItem => {
        if (listItem.id === row.id) {
          return {
            ...listItem,
            checkboxDisabled: true,
            selected: true,
          }
        }
        return listItem
      })
    ], [])
  }

  render(): React$Element<any> {
    const { classes, t } = this.props

    return (
      <div className={classes.root} >
        <div className={classes.header}>
          {t('copyRenovationModal._SELECT_MODE_')}
        </div>
        <div className={classes.properties}>
          <SimpleHierarchicalList
            data={this.getListHierarchy()}
            onRowClick={this.openRow}
            onCheckBoxClick={({ id: listItemId }: TVDListItem) => this.toggleListItemSelected(listItemId)}
            columns={this.getSimpleHierarchicalListColumns()}
            displayCheckBoxes
            displayCheckBoxesAlways />
        </div>
        <div className={classes.footer}>
          <div className={classes.buttons}>
            <FooterButtons items={this.getFooterButtonItems()} />
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = ({ list, patchOperations }: TVDReduxStore, { listStoreId }: ReceivedProps): MappedProps => {
  const { listItems } = list[listStoreId] || {}
  return {
    listItems,
    patchOperations: patchOperations[listStoreId] || {}
  }
}

const mapDispatchToProps = (dispatch: Function, { listStoreId, widgetId, widgetTab }: ReceivedProps): DispatchProps => ({
  dispatchModifyListItem: (listItemId: string, columnName: string, value: string) => {
    dispatch(modifyListItem({
      listItemId,
      columnName,
      listId: listStoreId,
      value
    }))
  },
  dispatchStorePatchOperation: (patchOperation: TVDPatchOperation, storePatchOperationParameterOptions?: StorePatchOperationParameterOptions) => {
    dispatch(storePatchOperation(listStoreId, patchOperation, storePatchOperationParameterOptions))
  },
  dispatchStorePatchOperationParameter: (patchOperationParameterObject: TVDPatchOperationParameterObject) => {
    dispatch(storePatchOperationParameter(
      listStoreId,
      patchOperationParameterObject,
    ))
  },
  dispatchSetWidgetActive: () => { dispatch(setWidgetActive(widgetId, widgetTab)) },
  dispatchSetRenovationProfile: (newColumnData: Object, rowId: string) => { dispatch(setRenovationProfile(newColumnData, listStoreId, rowId)) }
})

const sentientConfig: TVDSentientConfig = {
  getSetupRequestDefinitions: (): TVDGARConfigs =>
    ({
      getRenovationSpacesProfilesWithEstimateIdRequestDefinitions: getRenovationSpacesProfilesWithEstimateIdRequestDefinitions({
        payload: {},
      })
    })
}


export default compose(
  withTranslation('translations'),
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
  FeaturesHOC
)(SentientHOC(RenovationCopyProfile, sentientConfig))
