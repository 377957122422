// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.

export const MODAL_TYPE_CALCULATION_INFORMATION = 'MODAL_TYPE_CALCULATION_INFORMATION'
export const MODAL_TYPE_PRICING_ATTRIBUTE_EDIT = 'MODAL_TYPE_PRICING_ATTRIBUTE_EDIT'
export const MODAL_TYPE_CREATE_USER_DEFINED_EQUIPMENT = 'MODAL_TYPE_CREATE_USER_DEFINED_EQUIPMENT'
export const MODAL_TYPE_CREATE_USER_DEFINED_SURFACE = 'MODAL_TYPE_CREATE_USER_DEFINED_SURFACE'
export const MODAL_TYPE_SETTINGS = 'MODAL_TYPE_SETTINGS'
export const MODAL_TYPE_PROPERTY_EDITOR = 'MODAL_TYPE_PROPERTY_EDITOR'
export const ACCOUNT_SELECTION_MODAL = 'ACCOUNT_SELECTION_MODAL'
export const MODAL_TYPE_HAAHTELA_ADMIN_LICENSE = 'MODAL_TYPE_HAAHTELA_ADMIN_LICENSE'
export const MODAL_TYPE_CREATE_REPORT = 'MODAL_TYPE_CREATE_REPORT'
export const MODAL_TYPE_USER_MODAL = 'MODAL_TYPE_USER_MODAL'
export const MODAL_TYPE_ADD_ACCESS_RIGHT_MODAL = 'MODAL_TYPE_ADD_ACCESS_RIGHT_MODAL'
export const SUB_SYSTEM_INFO_MODAL = 'SUB_SYSTEM_INFO_MODAL'
export const MODAL_TYPE_ADD_USER_MODAL = 'MODAL_TYPE_ADD_USER_MODAL'
export const MODAL_TYPE_ADD_USER_TO_GROUP_MODAL = 'MODAL_TYPE_ADD_USER_TO_GROUP_MODAL'
export const MODAL_TYPE_CONFIRMATION_MODAL_WITH_GARS = 'MODAL_TYPE_CONFIRMATION_MODAL_WITH_GARS'
export const MODAL_TYPE_ACCOUNT_SUSPEND_MODAL = 'MODAL_TYPE_ACCOUNT_SUSPEND_MODAL'
export const MODAL_TYPE_TOGGLE_EXTERNAL_GEOMETRY = 'MODAL_TYPE_TOGGLE_EXTERNAL_GEOMETRY'
export const MODAL_TYPE_ACCESS_TOKEN: 'MODAL_TYPE_ACCESS_TOKEN' = 'MODAL_TYPE_ACCESS_TOKEN'
export const MODAL_TYPE_PRIMARY_SELECTION: 'MODAL_TYPE_PRIMARY_SELECTION' = 'MODAL_TYPE_PRIMARY_SELECTION'
export const MODAL_TYPE_UNFREEZE_ESTIMATE: 'MODAL_TYPE_UNFREEZE_ESTIMATE' = 'MODAL_TYPE_UNFREEZE_ESTIMATE'
export const MAINTENANCE_OUTAGE_MODAL = 'MAINTENANCE_OUTAGE_MODAL'
export const RELEASE_NOTES_MODAL = 'RELEASE_NOTES_MODAL'
export const TERMS_OF_SERVICE = 'TERMS_OF_SERVICE'
export const DELETE_PROJECT = 'DELETE_PROJECT'
export const RENAME_PROJECT = 'RENAME_PROJECT'
export const BUILDING_SETTINGS = 'BUILDING_SETTINGS'
