// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.

import React, { Component } from 'react'
import type { Element } from 'react'
import { withStyles } from '@material-ui/core/styles'
import Checkbox from '@material-ui/core/Checkbox'
import Icon from '@material-ui/core/Icon'
import CheckBoxOutlined from '@material-ui/icons/CheckBoxOutlined'

const styles = ({ palette }: TVDTheme) => ({
  checkboxRoot: {
    width: 14,
    height: 14,
    padding: 0,
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  noLeftPadding: {
    paddingLeft: '0px'
  },
  checkboxIcon: {
    color: palette.gray120,
  },
  checked: {
    '&:hover': {
      backgroundColor: 'transparent! important'
    }
  },
  checkedIcon: {
    color: palette.primary100,

  },
  checkBoxOutlinedDisabled: {
    color: palette.gray80,
  },
  disabledIcon: {
    color: palette.gray80,
  }
})

const ICON_SIZE = 18 // to make the actual checkbox 14px

type Props = {
  ...TVDCheckBoxProps,
  classes: Object, // withStyles classes object
}

type State = {
  indeterminate: boolean
}

class CheckBox extends Component<Props, State> {
  static defaultProps = {
    id: '',
    disabled: false,
    indeterminate: false,
  }
  state = {
    indeterminate: this.props.indeterminate || false
  }

  componentDidUpdate(prevProps: Object) {
    if (prevProps.indeterminate !== this.props.indeterminate) this.setState({ indeterminate: this.props.indeterminate })
  }

  handleClick = (ev: SyntheticEvent<any>) => {
    if (this.props.stopPropagation) ev.stopPropagation()
    if (this.state.indeterminate) this.setState({ indeterminate: false })
  }

  render(): Element<typeof Checkbox> {
    const {
      classes,
      checked,
      name,
      disabled,
      id,
      onChange,
      selectAllChild
    } = this.props

    const { indeterminate } = this.state

    const checkedIcon = (): React$Element<any> => {
      switch (true) {
        case selectAllChild: return <CheckBoxOutlined style={{ fontSize: ICON_SIZE }} className={classes.checkBoxOutlinedDisabled} />
        case disabled: return <Icon style={{ fontSize: ICON_SIZE }} className={classes.disabledIcon}>check_box</Icon>
        default: return <Icon style={{ fontSize: ICON_SIZE }} className={classes.checkedIcon}>check_box</Icon>
      }
    }


    const checkboxProps = {
      icon: <Icon style={{ fontSize: ICON_SIZE }} className={disabled ? classes.disabledIcon : classes.checkboxIcon}>check_box_outline_blank</Icon>,
      checkedIcon: checkedIcon(),
      indeterminateIcon: (
        <Icon
          style={{ fontSize: ICON_SIZE }}
          className={disabled ? classes.disabledIcon : classes.checkboxIcon}>indeterminate_check_box
        </Icon>
      ),
      value: name,
      onChange,
      checked,
      disabled,
      indeterminate
    }
    const description = id || ''
    return (
      <Checkbox
        {...checkboxProps}
        onClick={this.handleClick}
        disableRipple
        classes={{ root: classes.checkboxRoot, checked: classes.checked }}
        data-testid={`CheckBox-${description}`} />
    )
  }
}

export default withStyles(styles)(CheckBox)
