// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.
import Axios from 'axios'
import { reduce, includes } from 'lodash'

import { dispatch, getState } from '../../store'
import { getPathNameFromURL } from '../apiUtils'
import { setCalculationActive, setCalculationComplete, refreshEstimateLock } from '../../actions/app'
import HALParser from '../HALParser'
import { openModal, closeModal, buildInfoModal } from '../../actions/modals'
import { CONFIRMATION_MODAL_PADDED } from '../../constants/contentTypes'
import { postPolling } from '../../actions/postPolling'
import { getMockResponse, hasMockImplementation } from './mocks'
import { DATA_GET_ESTIMATE_LOCK_WITH_ESTIMATEID_FAIL } from '../generated-api-requests/buildingelements'
import { TVD_HEADER_ACCOUNT_ID, TVD_HEADER_LANGUAGE } from '../../constants/apiConstants'
import { ALLOW_WITH_CREDENTIALS } from '../../constants/envConstants'

export const MAX_POLLS_COUNT = 150

const POLLING_TIMEOUT = 200
const POLLING_STATUS = 202
const CHANGES_DONE = 204
const UNAUTHORIZED_STATUS = 401
export const SUCCESSFUL_STATUS = 200
export const FORBIDDEN_STATUS = 403
const UNPROCESSABLE_ENTITY = 422
export const RESOURCE_CONFLICT_STATUS = 409
export const RESOURCE_LOCKED_STATUS = 423

const HTTP_300_ERROR_MESSAGE = 'Request failed with status code 300'
const noErrorMessagesActionFails = [DATA_GET_ESTIMATE_LOCK_WITH_ESTIMATEID_FAIL]

// make type Callbacks = [Function, Function] to force both successful and error callbacks
export type Callbacks = [Function] | [Function, Function]
export const getCallBacks = (cbArray: Callbacks): Array<Function> =>
  cbArray.map((cb: Function) => (response: TVDRequestResponse) => cb(response))

type RequestArgs = {|
  actions: TVDActions,
  config: TVDRequestConfig,
  successCb?: Function,
  errorCb?: Function,
  parser?: Function,
  resolve: Function,
  reject: Function,
  options?: Object,
|}

class Request {
  config: $PropertyType<RequestArgs, 'config'>
  actions: Object
  successCb: Function
  errorCb: Function
  pollingCount: number
  pollingTimeoutID: TimeoutID
  parser: Function | null
  pollingConfig: TVDRequestConfig | null
  isPolling: boolean
  options: Object

  constructor(args: RequestArgs) {
    const {
      user: {
        authorization: {
          accessToken,
        },
        claims: {
          haahtelaApiUserToken,
          userId
        }
      },
      app: {
        selectedAccountId,
        languageCode,
        realEstateId
      }
    } = getState()
    const defaultConfigs = {
      // https://github.com/axios/axios#handling-errors
      // if false, validateStatus will throw an error and the error will be caught in catch
      validateStatus: (status: number): boolean => status >= 200 && status < 400,
      data: {},
      // Get OAuth access token from the user store and set it as the bearer token in the Authorization header.
      // Otherwise you will get HTTP 401 (unauthorized) response when accessing the Haahtela API through WSO2 API Manager.
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'X-TakuPro-User-ID': haahtelaApiUserToken.length > 0 ? haahtelaApiUserToken : userId,
        [TVD_HEADER_ACCOUNT_ID]: args.options?.selectedAccountId || selectedAccountId,
        [TVD_HEADER_LANGUAGE]: languageCode,
        ...(realEstateId ? { 'X-TakuPro-RealEstate-ID': realEstateId } : {})
      },
      paramsSerializer: (params: Object) => reduce(params, (result: Object, value: any, key: string) => {
        // serializing array of strings
        if (Array.isArray(value) && value.every((arrayItem: any) => typeof arrayItem === 'string')) {
          return `${result}${`&${key}=${value.join(',')}`}`
        }
        if (typeof value !== 'undefined') {
          return `${result}&${key}=${value}`
        }
        return result
      }, '').replace('&', '')
    }
    this.isPolling = false
    this.pollingConfig = null
    this.parser = typeof args.parser === 'function' ? args.parser : null
    this.actions = args.actions
    this.pollingCount = 0
    this.config = {
      ...defaultConfigs,
      ...args.config,
      headers: {
        ...defaultConfigs.headers,
        ...(args.config.headers || {}),
        ...(args.options?.headers || {})
      }
    }
    this.options = {
      disableSetCalculationActiveAndComplete: false,
      ...args.options
    }

    this.successCb = (response: TVDRequestResponse, addedMeta?: Object = {}) => {
      const { app: { isPostPolling, isEstimateLockedToCurrentUser } } = getState()
      Request.checkResponseMessages(response)
      if (
        !this.isPolling &&
        !isPostPolling &&
        !this.options.disableSetCalculationActiveAndComplete) {
        dispatch(setCalculationComplete())
      }
      this.isPolling = false
      clearTimeout(this.pollingTimeoutID)

      const { payload = {}, meta } = this.actions.successful
      const allMeta = { ...addedMeta, ...meta }
      const responseKey: string = this.parser ? 'parsedResponse' : 'response'
      const newResponse = this.parser ? this.parser(response, allMeta) : response
      const responseObject = { [responseKey]: newResponse }

      dispatch({ ...this.actions.successful, payload: Object.assign({}, payload, responseObject), meta: allMeta })
      const startCalculationMethods = ['POST', 'PATCH', 'PUT', 'DELETE']
      if (
        !includes(this.config.url, 'lock') &&
        isEstimateLockedToCurrentUser &&
        startCalculationMethods.includes(this.config.method) &&
        !this.options.disableRefreshEstimateLock) {
        dispatch(refreshEstimateLock())
      }
      if (args.successCb) args.successCb(newResponse, response)
      args.resolve(newResponse, response)
      return response
    }

    this.errorCb = (error: Object) => {
      this.isPolling = false
      clearTimeout(this.pollingTimeoutID)
      const { response: { status, data } = {} } = error
      if (status === UNAUTHORIZED_STATUS) window.location.href = '/kirjaudu'
      // we allow 300 but cannot use validateStatus for that
      if (error.message !== HTTP_300_ERROR_MESSAGE && !args.errorCb && !noErrorMessagesActionFails.includes(this.TVDRequestActions.fail.type)) {
        console.error('Request', error)
      }
      const { payload = {} } = this.TVDRequestActions.fail
      dispatch({ ...this.TVDRequestActions.fail, payload: { ...payload, error: { status, data } } })
      if (args.errorCb) args.resolve(args.errorCb(error))
      args.reject(error)
      return error
    }
  }

  getPollingActionType(action: 'start' | 'successful' | 'fail'): string {
    let actionType = this.actions[action] ? this.actions[action].type : 'UNCONFIGURED_ACTION'
    if (actionType.includes('SUCCESSFUL')) {
      actionType = actionType.replace('SUCCESSFUL', 'POLLING')
    } else {
      actionType = `${actionType}_POLLING`
    }

    return `${actionType}${this.pollingCount > 0 ? `_${this.pollingCount}` : ''}_${action.toUpperCase()}`
  }

  async sendPollingRequest(): Object {
    this.isPolling = true
    this.pollingCount += 1
    if (this.pollingCount > MAX_POLLS_COUNT) {
      throw new Error(`Max polling count (${MAX_POLLS_COUNT}) reached`)
    } else {
      clearTimeout(this.pollingTimeoutID)
      this.pollingTimeoutID = setTimeout(async () => {
        await this.send()
      }, POLLING_TIMEOUT)
    }
  }

  setPollingConfigs(url: string) {
    const { baseURL, headers } = this.config
    const TVDRequestConfig = {
      method: 'GET',
      url,
      baseURL,
      headers: {}
    }
    if (headers) {
      TVDRequestConfig.headers = headers
    }
    this.pollingConfig = TVDRequestConfig
  }

  get pollingActions(): Object {
    return ['start', 'successful', 'fail'].reduce((result: Object, actionName: TVDActionKey) => ({
      ...result,
      // since flow version 0.111 union cannot be used as a computed property due to flow performance issues
      // if massive union is used. Skipped here because only three possible values shouldn't cause issues.
      // $FlowFixMe
      [actionName]: {
        type: this.getPollingActionType(actionName),
        config: this.pollingConfig,
      }
    }), {})
  }

  get TVDRequestConfigs(): Object {
    return this.isPolling ? this.pollingConfig : this.config
  }

  get TVDRequestActions(): Object {
    return this.isPolling ? this.pollingActions : this.actions
  }

  dispatchPollingSuccessful(response: TVDRequestResponse) {
    dispatch({ type: this.getPollingActionType('successful'), config: this.TVDRequestConfigs, response })
  }

  static checkResponseMessages(response: TVDRequestResponse) {
    const { data, status } = response
    const embeddedData = HALParser.getEmbedded(data) || {}
    const { warnings, info, errors } = embeddedData

    const hasWarnings = warnings && warnings.length > 0
    const hasInfo = info && info.length > 0
    const hasErrors = errors && errors.length > 0

    switch (true) {
      case status === RESOURCE_CONFLICT_STATUS:
      case status === RESOURCE_LOCKED_STATUS: {
        break
      }
      case status.toString()[0] === '5': {
        const modalId = `messages-${new Date().getTime()}-500`
        dispatch(openModal(
          {
            type: CONFIRMATION_MODAL_PADDED,
            messageTranslationKey: 'general._ERROR_',
            messageType: 'error',
            onClose: () => dispatch(closeModal(modalId)),
            disablePadding: true,
            index: 9999
          },
          modalId
        ))
        break
      }
      case status === FORBIDDEN_STATUS: {
        const modalId = `messages-${new Date().getTime()}-403`
        dispatch(buildInfoModal({
          id: modalId,
          title: 'insufficientUserAccessModal._INSUFFICIENT_USER_ACCESS_',
          message: 'insufficientUserAccessModal._INSUFFICIENT_USER_ACCESS_MESSAGE_',
          saveButtonText: 'buttons._CONTINUE_',
          onSave: () => dispatch(closeModal(modalId))
        }))
        break
      }
      case hasWarnings || hasErrors || hasInfo: {
        if (status === UNPROCESSABLE_ENTITY) {
          dispatch(postPolling())
        }
        let msgKey
        switch (true) {
          case hasWarnings:
            msgKey = 'warnings'
            break
          case hasErrors:
            msgKey = 'errors'
            break
          case hasInfo:
            msgKey = 'info'
            break
          default:
            break
        }
        embeddedData[msgKey].forEach((message: Object, index: number) => {
          const modalId = `messages-${new Date().getTime()}-${index}`
          dispatch(openModal(
            {
              type: CONFIRMATION_MODAL_PADDED,
              message: message.detail || message.Detail,
              messageTranslationKey: 'general._ERROR_',
              messageType: msgKey,
              disablePadding: true,
              index: 9999
            },
            modalId
          ))
        })
        break
      }
      default:
        break
    }
  }

  async send(): Object {
    try {
      dispatch(this.TVDRequestActions.start)
      const response = await Axios({
        ...this.TVDRequestConfigs,
        ...(ALLOW_WITH_CREDENTIALS ? { withCredentials: true } : {})
      })
      const { status } = response
      switch (true) {
        case this.pollingTimeoutID !== undefined: {
          const { data: { status: pollingStatus } } = response
          if (pollingStatus === 'pending' || pollingStatus === 'inProgress') {
            this.dispatchPollingSuccessful(response)
            await this.sendPollingRequest()
          } else {
            this.successCb(response)
          }
          break
        }
        case status === POLLING_STATUS: {
          const { headers: { location } } = response
          this.setPollingConfigs(getPathNameFromURL(location))
          await this.sendPollingRequest()
          break
        }
        case status === CHANGES_DONE:
        default:
          this.successCb(response)
          break
      }
    } catch (error) {
      const { message } = error
      const { app: { features: { mockAPIResponses } } } = getState()
      switch (true) {
        // TODO: try to allow 300 responses other than using this hack in catch or in errorCb
        case message === HTTP_300_ERROR_MESSAGE: {
          this.dispatchPollingSuccessful(error.response)
          this.successCb(error.response)
          break
        }
        case hasMockImplementation(this.TVDRequestActions) && mockAPIResponses: {
          this.successCb(getMockResponse(this.TVDRequestActions), { isMockResponse: true })
          break
        }
        default: {
          dispatch(setCalculationComplete())
          if (this.options.disableErrorMessageCheck) {
            this.errorCb(error)
            return
          }
          if (!noErrorMessagesActionFails.includes(this.TVDRequestActions.fail.type) && error.response) {
            Request.checkResponseMessages(error.response)
          }
          this.errorCb(error)
        }
      }
    }
  }
}

type RequestFnArguments = {|
    actions: TVDActions,
    config: TVDRequestConfig,
    callbacks: {|
      successCb?: Function,
      errorCb?: Function
    |},
    parser?: Function,
    options?: Object,
|}


export default async function requestFn({
  actions,
  config,
  callbacks,
  parser,
  options = {},
}: RequestFnArguments): Object {
  const { successCb, errorCb } = callbacks

  const startCalculationMethods = ['POST', 'PATCH', 'PUT', 'DELETE']
  if (startCalculationMethods.includes(config.method) && !options.disableSetCalculationActiveAndComplete) {
    dispatch(setCalculationActive())
  }

  if (options.responseType) {
    config.responseType = options.responseType
  }


  return new Promise((resolve: Function, reject: Function) => {
    const request = new Request({
      actions,
      config,
      successCb,
      errorCb,
      parser,
      resolve,
      reject,
      options,
    })
    request.send()
  })
}
