// Copyright © 2010–2022 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.

import i18n from 'i18next'
import Backend from 'i18next-fetch-backend'

export default i18n
  .use(Backend)
  .init({
    backend: {
      loadPath: '/../assets/locales/{{lng}}/{{ns}}.json',
      // addPath: '../assets/locales/add/{{lng}}/{{ns}}.missing.json' uses POST to post missing translations
    },
    lng: '',
    debug: false,
    saveMissing: false, // whether to save missing keys, needs POST to work -> webpack problem?

    // have a common namespace used around the full app
    ns: ['translations', 'user-access', 'LicenseTypeChip', 'Chip'],
    defaultNS: 'translations',
    availableLanguages: ['fi', 'en', 'sv'], // used for the LanguagePicker component, not a part of i18next
    fallbackLng: {
      default: ['fi']
    },

    nsSeparator: '::', // allows translations to contain colon (:)
    keySeparator: '.',

    interpolation: {
      escapeValue: false, // not needed for react!!
      formatSeparator: ','
    },

    react: {
      wait: true,
      useSuspense: false
    }
  })
