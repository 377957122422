// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.
export const formatDate = (date: string | number) => {
  if (date.toString().length < 2) return `0${date}`
  return date
}

// Get current date in mui datepicker suitable format (YYYY-MM-DD)
export const dateToday = () => {
  const date = new Date()
  const day = formatDate(date.getDate())
  const month = formatDate(date.getMonth() + 1)
  const year = date.getFullYear()

  return `${year}-${month}-${day}`
}
