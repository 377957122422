// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.
import React from 'react'
import { withStyles } from '@material-ui/core'
import { InfoOutlined } from '@material-ui/icons'
import { withTranslation } from 'react-i18next'
import { split, find, map, filter } from 'lodash'

import OverflowTooltip from '../../common/OverflowTooltip/OverflowTooltip'
import ModalForm from '../../common/ModalForm/ModalForm'
import LabeledInput from '../../common/LabeledInput/LabeledInput'
import CheckBox from '../../common/CheckBox/CheckBox'
import AutoCompleteMenu, { type AutoCompleteMenuItem } from '../../common/menus/AutoCompleteMenu/AutoCompleteMenu'
import DropdownMenu from '../../../components/common/menus/DropdownMenu/DropdownMenu'
import { getUsersAPIBasePath } from '../../../utils/apiUtils'
import {
  TVD_TOKEN_USER_TYPE_USER
} from '../../../constants/apiConstants'
import {
  TVD_USER_GROUP_TYPE_ID_GUEST_USERS,
  TVD_USER_GROUP_TYPE_ID_ACCOUNT_MANAGERS,
  TVD_USER_GROUP_TYPE_ID_ACCOUNT_ADMINISTRATORS,
  TVD_USER_GROUP_TYPE_ID_NEW_DEFAULT_USERS_GROUP,
  TVD_USER_GROUP_TYPE_ID_CUSTOM_GROUP,
  TVD_USER_GROUP_TYPE_ID_PROPERTY_MANAGER
} from '../../../constants/userGroupTypeIds'

import {
  deleteUsersLockWithIdRequest,
  getUserGroupsRequest,
  getUsersRequest,
  putUsersLockWithIdRequest,
} from '../../../utils/generated-api-requests/users'
import HALParser from '../../../utils/HALParser'

const styles = ({ typography, palette }: TVDTheme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0 32px',
    margin: '20px 0'
  },
  input: {
    marginTop: '20px'
  },
  label: {
    marginLeft: '15px',
    color: palette.nevada,
    size: '16px',
    fontFamily: typography.fontFamilyBase
  },
  checkbox: {
    display: 'flex',
    alignItems: 'center',
    '& > p': {
      margin: 0,
      marginLeft: 15
    },
    '&:first-of-type': {
      marginBottom: 8
    }
  },
  icon: {
    color: palette.primary100,
    fontSize: 16,
    marginLeft: 8,
    display: 'flex'
  }
})

export type UserDataObject = {|
  firstName: string, // first name of the user
  lastName: string, // last name of the user
  email: string, // email of the user
  phone: string, // phone number of the user
  isGuestUser: boolean, // flag to indicate if user has only guest user level privileges
  isUserActive: boolean, // flag to indicate if user profile is active or inactive
  userAddedToGroups: Array<AutoCompleteMenuItem>, // Array of groups that is being added to
  userRemovedFromGroups: Array<AutoCompleteMenuItem>, // Array of groups that user is being removed from
  selectedRealEstateUserGroupId?: string, // when adding user, what real estate group we are adding them initially to
|}

type HOCProps = {|
  t: Function, // translation function
  classes: Object, // withStyles classes object
|}

type ReceivedProps = {|
  userId: string, // Id of the user, used to fetch user data
  onClose: () => void, // closes the modal
  confirm: (Object) => void, // function to display confirmation modal if from is modified and closed
  onSave: (UserDataObject) => void,
  disableEdit?: boolean, // if we disable editing user information but allow showing it
  userType: $PropertyType<TVDApplicationStore, 'userType'>, // specifies the type of user
  groups: any,
  showCheckBoxes?: boolean // if the checkboxes should be visible, this is for LIMA project to show the checkboxes in Account Information's Users tab
|}

type Props = {|
  ...HOCProps,
  ...ReceivedProps
|}

type State = {|
  selectedRealEstateUserGroupId: string,
  firstName: string, // first name of the user
  lastName: string, // last name of the user
  email: string, // email of the user
  phone: string, // phone number of the user
  isGuestUser: ?boolean, // flag to indicate if user has only guest user level privileges
  isUserActive: boolean, // flag to indicate if user profile is active or inactive
  firstNameValid: boolean, // is first name given in valid format
  lastNameValid: boolean, // is last name given in valid format
  emailValid: boolean, // is email given in valid format
  phoneValid: boolean, // is given phone number in valid format
  modified: boolean, // if form has been modified
  userGroupsList: Array<AutoCompleteMenuItem>, // array of user groups received from backend
  isResourceLocked: boolean, // flag to indicate that lock has been put on user resource so it is safe to patch
  groups: Array<AutoCompleteMenuItem>, // currently selected usergroups in AutoCompleteMenu component.
  initialGroups: Array<AutoCompleteMenuItem>, // array of usergroups that were received initially for user. Contents of this array shouldn't change during lifecycle
  guestUserGroup?: AutoCompleteMenuItem, // resolved menu item of the guest user group
  isLoadingUserRequest: boolean, // if GET user request is still loading
  isLoadingUserGroupsRequest: boolean, // if GET user groups request is still loading
  propertyManagerGroup?: TVDUserGroup, // the user group that has userGroupTypeId that matches the property manager group constant one
  accountAdministratorGroup?: TVDUserGroup, // the user group that has userGroupTypeId that matches the account administrator group constant one
  accountManagerGroup?: TVDUserGroup, // the user group that has userGroupTypeId that matches the account manager group constant one
  newDefaultUserGroup?: TVDUserGroup, // the user group that has userGroupTypeId that matches the new default user group one
  customGroup?: TVDUserGroup, // the user group that has userGroupTypeId that matches the custom group one
  isUserAccountManager?: boolean, // is user should be toggled from being an account manager or not
  isUserPropertyManager?: boolean, // is user should be toggled from being a property manager or not
|}

export class UserInfo extends React.Component<Props, State> {
  static defaultProps = {
    disableEdit: false
  }

  state = {
    firstName: '',
    firstNameValid: false,
    lastName: '',
    lastNameValid: false,
    email: '',
    emailValid: false,
    phone: '',
    phoneValid: false,
    groups: [],
    initialGroups: [],
    userGroupsList: [],
    modified: false,
    isGuestUser: null,
    isUserActive: false,
    isResourceLocked: false,
    guestUserGroup: undefined,
    isLoadingUserRequest: true,
    isLoadingUserGroupsRequest: true,
    accountAdministratorGroup: undefined,
    accountManagerGroup: undefined,
    propertyManagerGroup: undefined,
    newDefaultUserGroup: undefined,
    customGroup: undefined,
    isUserAccountManager: undefined,
    isUserPropertyManager: undefined,
    selectedRealEstateUserGroupId: ''
  }

  componentDidMount() {
    const { userId } = this.props
    if (userId) {
      getUsersRequest(
        { query: { includeGroups: true } },
        {},
        (users: Array<TVDUser>) => {
          const user = find(users, (_user: TVDUser) => _user.id === this.props.userId)
          const groups = this.getGroups(user)
          this.setState({
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email,
            phone: user.phone,
            groups,
            initialGroups: groups,
            isUserActive: user.status === 'active',
            isLoadingUserRequest: false
          })
        },
        null,
        this.getUsersAPIOptions()
      )
      // add eventListener to beforeunload event, to delete user resource lock if page is refreshed
      // so the user is not locked out of the system
      window.addEventListener('beforeunload', (): void => { deleteUsersLockWithIdRequest({ path: { id: this.props.userId } }) })
      // user resource needs to be locked before data can be patched
      putUsersLockWithIdRequest(
        { path: { id: this.props.userId }, body: {} },
        {},
        () => {
          this.setState({ isResourceLocked: true })
        },
        null,
        this.getUsersAPIOptions()
      )
    }

    getUserGroupsRequest({}, (groups: Array<TVDUserGroup>) => {
      let guestUserGroup
      let accountManagerGroup
      let accountAdministratorGroup
      let newDefaultUserGroup
      let customGroup
      let propertyManagerGroup
      const groupOptions = map(groups, (group: TVDUserGroup): AutoCompleteMenuItem => {
        if (group.userGroupTypeId === TVD_USER_GROUP_TYPE_ID_GUEST_USERS) {
          guestUserGroup = group
        }
        if (group.userGroupTypeId === TVD_USER_GROUP_TYPE_ID_ACCOUNT_MANAGERS) {
          accountManagerGroup = group
        }
        if (group.userGroupTypeId === TVD_USER_GROUP_TYPE_ID_ACCOUNT_ADMINISTRATORS) {
          accountAdministratorGroup = group
        }
        if (group.userGroupTypeId === TVD_USER_GROUP_TYPE_ID_NEW_DEFAULT_USERS_GROUP) {
          newDefaultUserGroup = group
        }
        if (group.userGroupTypeId === TVD_USER_GROUP_TYPE_ID_CUSTOM_GROUP) {
          customGroup = group
        }
        if (group.userGroupTypeId === TVD_USER_GROUP_TYPE_ID_PROPERTY_MANAGER) {
          propertyManagerGroup = group
        }
        return { name: group.localizedDescription, id: group.id }
      })
      // Using warn here due to new user access control not really requiring these to be resolved at the time (PPR-321)
      if (!guestUserGroup) {
        console.warn('Could not resolve guest user group')
      }
      if (!accountManagerGroup) {
        console.warn('Could not resolve account manager group')
      }
      if (!accountAdministratorGroup) {
        console.warn('Could not resolve account administrator group')
      }
      if (!newDefaultUserGroup) {
        console.warn('Could not resolve new default user group')
      }
      if (!customGroup) {
        console.warn('Could not resolve custom group')
      }

      this.setState({
        propertyManagerGroup,
        accountAdministratorGroup,
        accountManagerGroup,
        newDefaultUserGroup,
        customGroup,
        userGroupsList: groupOptions,
        guestUserGroup,
        isLoadingUserGroupsRequest: false
      })
    }, null, this.getUsersAPIOptions())
  }

  componentDidUpdate() {
    const currentGuestUserGroup = this.state.guestUserGroup

    if (
      this.props.userId &&
      currentGuestUserGroup &&
      this.state.isGuestUser === null
    ) {
      this.setState({
        isGuestUser: !!this.state.groups.find(({ id }: AutoCompleteMenuItem): boolean => id === currentGuestUserGroup.id)
      })
    }
  }

  componentWillUnmount() {
    const { userId } = this.props
    if (userId) {
      deleteUsersLockWithIdRequest(
        { path: { id: this.props.userId } },
        {},
        null,
        null,
        this.getUsersAPIOptions()
      )
    }
  }

  getUsersAPIOptions = (): {|
    basePath: typeof undefined | string
  |} => {
    const { userType } = this.props
    return { basePath: userType && getUsersAPIBasePath(userType) }
  }

  getGroups = (user: TVDUser): Array<AutoCompleteMenuItem> => {
    const userGroups = HALParser.getUserGroups(user)
    const result = map(userGroups, (group: TVDEnum): AutoCompleteMenuItem =>
      ({ name: group.localizedName, id: group.value }))
    return result
  }


  basicFields = (stateKey: string) => ({
    isValid: this.state[`${stateKey}Valid`],
    disabled: this.props.disableEdit,
    id: `input-${stateKey}`,
    value: this.state[stateKey],
    handleChange: (e: SyntheticInputEvent<any>) => { this.handleUserInput(e) },
    isValidCb: (isValid: boolean) => { this.setState({ [`${stateKey}Valid`]: isValid }) },
    label: this.props.t(`userModal._${stateKey.toUpperCase()}_`),
    size: 'XL',
    dataType: 'string'
  })

  handleChange = (eventID: string, eventValue: string) => {
    const stateKey = split(eventID, '-')[1]
    this.setState({
      [stateKey]: eventValue,
      modified: true
    })
  }

  handleUserInput = (e: SyntheticInputEvent<any>) => {
    this.handleChange(e.target.id, e.target.value)
  }

  getAddedUserGroups = (): Array<AutoCompleteMenuItem> => {
    const {
      guestUserGroup,
      isGuestUser,
      isUserAccountManager,
      isUserPropertyManager,
      propertyManagerGroup,
      accountManagerGroup
    } = this.state
    const { userId } = this.props
    // handling scenario when we are creating new user that is guest user
    // guest users can only belong to the guest user group
    // existing groups are planned to be removed by API when adding user to guest user - no need to manually delete the user from them
    if (!userId && isGuestUser && guestUserGroup) return [guestUserGroup]
    const userAddedToGroups = filter(this.state.groups, (group: AutoCompleteMenuItem): Array<AutoCompleteMenuItem> =>
      !find(this.state.initialGroups, (initialGroup: AutoCompleteMenuItem): boolean => initialGroup.id === group.id))
    if (isUserAccountManager) userAddedToGroups.push(accountManagerGroup)
    if (isUserPropertyManager) userAddedToGroups.push(propertyManagerGroup)
    return userAddedToGroups
  }

  getRemovedUserGroups = (): Array<AutoCompleteMenuItem> => {
    const {
      isUserAccountManager,
      isUserPropertyManager,
      propertyManagerGroup,
      accountManagerGroup,
      initialGroups,
      groups
    } = this.state

    const userRemovedFromGroups = filter(initialGroups, (initialGroup: AutoCompleteMenuItem): boolean =>
      !find(groups, (group: AutoCompleteMenuItem): boolean => initialGroup.id === group.id) ||
      (isUserAccountManager === false && initialGroup.id === accountManagerGroup?.id) ||
      (isUserPropertyManager === false && initialGroup.id === propertyManagerGroup?.id))

    return userRemovedFromGroups
  }

  handleSave = () => {
    const userData = {
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      email: this.state.email,
      phone: this.state.phone,
      isUserActive: this.state.isUserActive,
      isGuestUser: !!this.state.isGuestUser,
      userAddedToGroups: this.getAddedUserGroups(),
      userRemovedFromGroups: this.getRemovedUserGroups(),
      selectedRealEstateUserGroupId: this.state.selectedRealEstateUserGroupId
    }
    this.props.onSave(userData)
  }

  handleClose = () => {
    if (this.state.modified) {
      this.props.confirm({ onSave: () => this.props.onClose() })
    } else {
      this.props.onClose()
    }
  }

  checkValidity = () => {
    const { userId, userType } = this.props
    const {
      firstNameValid,
      lastNameValid,
      emailValid,
      phoneValid,
      isResourceLocked,
      selectedRealEstateUserGroupId
    } = this.state
    const isValidWithUserId = Boolean(isResourceLocked && firstNameValid && phoneValid && lastNameValid && emailValid)
    const isRealEstateUserGroupIdValid = !userId && userType === TVD_TOKEN_USER_TYPE_USER ? !!selectedRealEstateUserGroupId : true

    const isValid = Boolean(firstNameValid &&
      lastNameValid &&
      phoneValid &&
      emailValid &&
      isRealEstateUserGroupIdValid)
    return this.props.userId ? isValidWithUserId : isValid
  }

  getUserGroupsListAsAccountManager = (userGroupsList: Array<AutoCompleteMenuItem>): Array<AutoCompleteMenuItem> => {
    const {
      accountManagerGroup,
      accountAdministratorGroup
    } = this.state
    return userGroupsList.map((menuItem: AutoCompleteMenuItem): AutoCompleteMenu => ({
      ...menuItem,
      disabled: !((menuItem.id === accountManagerGroup?.id) || (menuItem.id === accountAdministratorGroup?.id))
    }))
  }

  realEstatePermissionSelection = (): React$Element<'div'> => {
    const { groups, t, classes } = this.props
    return (
      <div className={classes.input}>
        <DropdownMenu
          required
          width='XL'
          title={t('addAccessRightModal._SELECT_USERS_PERMISSION_')}
          onChange={(id: string): void => {
          this.setState({ selectedRealEstateUserGroupId: id })
      }}
          items={groups}
          disabled={groups?.length === 0}
          defaultValue='' />
      </div>
    )
  }


  firstNameField(): React$Element<any> {
    const { classes } = this.props

    return (
      <div className={classes.input}>
        <LabeledInput {...this.basicFields('firstName')} dataType='name' required />
      </div>
    )
  }

  lastNameField(): React$Element<any> {
    const { classes } = this.props

    return (
      <div className={classes.input}>
        <LabeledInput {...this.basicFields('lastName')} dataType='name' required />
      </div>
    )
  }

  emailField(): React$Element<any> {
    const { classes, userId } = this.props

    return (
      <div className={classes.input}>
        <LabeledInput
          email
          {...this.basicFields('email')}
          disabled={!!userId}
          dataType='email'
          required />
      </div>
    )
  }

  phoneField(): React$Element<any> {
    const { classes } = this.props

    return (
      <div className={classes.input}>
        <LabeledInput {...this.basicFields('phone')} dataType='phone' required />
      </div>
    )
  }

  checkBoxes(): Array<React$Element<typeof CheckBox>> {
    const {
      classes,
      t,
      disableEdit,
      showCheckBoxes
    } = this.props
    const {
      propertyManagerGroup,
      accountManagerGroup,
      isUserPropertyManager,
      isUserAccountManager,
      initialGroups
    } = this.state

    const isUserPropertyManagerInitially = initialGroups.find((group: any): boolean =>
      group.id === propertyManagerGroup?.id)

    const isUserAccountManagerInitially = initialGroups.find((group: any): boolean =>
      group.id === accountManagerGroup?.id)

    const propertyManagerCheckbox = (
      <div className={classes.checkbox} key='propertyManager-checkbox'>
        <CheckBox
          disabled={disableEdit}
          testid='checkbox-propertyManager'
          checked={typeof isUserPropertyManager === 'boolean' ? isUserPropertyManager : !!isUserPropertyManagerInitially}
          name='isUserPropertyManager'
          onChange={() => {
            this.setState((prevState: State) => ({
              isUserPropertyManager: typeof isUserPropertyManager === 'boolean' ? !prevState.isUserPropertyManager : !isUserPropertyManagerInitially,
              modified: true
            }))
          }} />
        <p className={classes.label}>{t('userModal._PROPERTY_MANAGER_')}</p>
        <OverflowTooltip
          renderAlways
          tooltipText={t('userModal._PROPERTY_MANAGER_TOOLTIP_')}>
          <InfoOutlined classes={{ root: classes.icon }} />
        </OverflowTooltip>
      </div>
    )

    const accountManagerCheckbox = (
      <div className={classes.checkbox} key='accountManager-checkbox'>
        <CheckBox
          disabled={disableEdit}
          testid='checkbox-accountManager'
          checked={typeof isUserAccountManager === 'boolean' ? isUserAccountManager : !!isUserAccountManagerInitially}
          name='isUserAccountManager'
          onChange={() => {
            this.setState((prevState: State) => ({
              isUserAccountManager: typeof isUserAccountManager === 'boolean' ? !prevState.isUserAccountManager : !isUserAccountManagerInitially,
              modified: true
            }))
          }} />
        <p className={classes.label}>{t('userModal._ACCOUNT_MANAGER_')}</p>
        <OverflowTooltip
          renderAlways
          tooltipText={t('userModal._ACCOUNT_MANAGER_TOOLTIP_')}>
          <InfoOutlined classes={{ root: classes.icon }} />
        </OverflowTooltip>
      </div>
    )

    if (showCheckBoxes) {
      return [accountManagerCheckbox, propertyManagerCheckbox]
    }

    return []
  }

  inputs(): LabeledInput {
    const { userId, userType } = this.props
    return [
      this.firstNameField(),
      this.lastNameField(),
      this.emailField(),
      this.phoneField(),
      !userId && userType === TVD_TOKEN_USER_TYPE_USER ? this.realEstatePermissionSelection() : null,
      this.checkBoxes()
    ]
  }

  modalForm(): React$Element<typeof ModalForm> {
    const { disableEdit } = this.props
    const { modified } = this.state
    return (
      <ModalForm
        hideSave={disableEdit}
        items={this.inputs()}
        onSave={() => this.handleSave()}
        onClose={() => this.handleClose()}
        valid={modified && this.checkValidity()}
        modified={modified}
        saveButtonText='buttons._SAVE_' />
    )
  }

  render(): React$Element<ModalForm> {
    const { classes } = this.props
    return (
      <div className={classes.wrapper}>
        {this.modalForm()}
      </div>
    )
  }
}

export default withStyles(styles)(withTranslation('translations')(UserInfo))
