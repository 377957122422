// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.

// Account related user group type ids
// DEPRECATED
export const TVD_USER_GROUP_TYPE_ID_ACCOUNT_ADMINISTRATORS = 33
// VALID
export const TVD_USER_GROUP_TYPE_ID_ACCOUNT_MANAGERS = 201
// VALID
export const TVD_USER_GROUP_TYPE_ID_PROPERTY_MANAGER = 202
// VALID Real Estate Estimate Read Only
export const TVD_USER_GROUP_TYPE_ID_REAL_ESTATE_ESTIMATE_READ_ONLY = 303

// Guest Users group
// DEPRECATED
export const TVD_USER_GROUP_TYPE_ID_GUEST_USERS = 39

// Building related user group type ids
// DEPRECATED
export const TVD_USER_GROUP_TYPE_ID_USERS_BUILDING_OWNERS = 34
// DEPRECATED
export const TVD_USER_GROUP_TYPE_ID_USERS_BUILDING_MANAGERS = 35

// Estimate related user group type ids
// DEPRECATED
export const TVD_USER_GROUP_TYPE_ID_USERS_ESTIMATE_OWNERS = 36
// DEPRECATED
export const TVD_USER_GROUP_TYPE_ID_USERS_ESTIMATE_WRITERS = 37

// other
// DEPRECATED
export const TVD_USER_GROUP_TYPE_ID_CUSTOM_GROUP = 30
// DEPRECATED
export const TVD_USER_GROUP_TYPE_ID_READ_ONLY_USERS = 38
// DEPRECATED
export const TVD_USER_GROUP_TYPE_ID_NEW_DEFAULT_USERS_GROUP = 1
