// @flow
// Copyright © 2010–2022 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.

import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import { colors, typographyClasses } from 'frontend-assets'
import { login } from '../../../actions/user'
import { authenticationFlowType } from '../../../utils/authUtil'
import { combineStyleClassNames } from '../../../utils/styleUtils'

const useStyles = makeStyles(() => ({
  container: {
    ...typographyClasses.h2,
    top: '50%',
    left: '50%',
    position: 'absolute',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    color: colors.dark80,
  },
  redirectDots: {
    animation: '$flashing 1.5s infinite',
    opacity: 0.2
  },
  dot2: {
    animationDelay: '0.25s',
  },
  dot3: {
    animationDelay: '0.5s',
  },
  '@keyframes flashing': {
    '0%': {
      display: 'block',
      opacity: 0.2
    },
    '50%': {
      display: 'block',
      opacity: 0.6
    },
    '100%': {
      display: 'block',
      opacity: 1
    }
  }
}))

export const Redirect = () => {
  const classes = useStyles()
  const { route } = useSelector(({ user }: TVDReduxStore): TVDUserStore => user)

  useEffect(() => {
    if (authenticationFlowType) {
      login(route)
    }
  }, [route])

  const getMessage = (): string => {
    if (navigator.language.toLowerCase() === 'fi') return 'Ohjataan'
    if (navigator.language.toLowerCase() === 'se') return 'Omdirigering'
    return 'Redirecting'
  }

  return (
    <div className={classes.container}>
      <span
        data-testid='Redirect'>
        {getMessage()}
      </span>
      <span
        className={classes.redirectDots}>
        .
      </span>
      <span
        className={combineStyleClassNames(classes.redirectDots, classes.dot2)}>
        .
      </span>
      <span className={combineStyleClassNames(classes.redirectDots, classes.dot3)}>
        .
      </span>
    </div>
  )
}

export default Redirect
