// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.
import React from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { compose } from 'redux'
import { withRouter } from 'react-router-dom'

import ModalForm from '../../common/ModalForm/ModalForm'
import TabsPlain from '../../common/TabsPlain/TabsPlain'
import Profile from '../Profile/Profile'

import { closeModal, buildConfirmationModal } from '../../../actions/modals'
import { getUsersSelfRequest } from '../../../utils/generated-api-requests/users'
import SettingsBuildings from './TabContents/SettingsBuildings'
import SettingsEstimates from './TabContents/SettingsEstimates'
import { SETTINGS_BUILDING_TAB, SETTINGS_ESTIMATES_TAB, USERS } from '../../../constants/moduleConstants'
import {
  FEATURE_BUILDINGS_ACCESS_RIGHTS,
  FEATURE_ESTIMATES_ACCESS_RIGHTS,
} from '../../../constants/features'
import FeaturesHOC from '../../hocs/FeaturesHOC/FeaturesHOC'
import { getUsersAPIBasePath } from '../../../utils/apiUtils'

type HOCProps = {|
  t: Function, // translate
  features: TVDFeatureHOCProps, // features HOC with utility functions that can e.g filter disabled features out of an array of objects
  location: Object // withRouter location object
|}

type ReceivedProps = {|
  id: string, // modal id used to close the modal
  appType: string, // type of app to display correct content
|}

type DispatchProps = {|
  dispatchCloseModal: Function, // close modal function
  dispatchBuildConfirmationModal: (Object) => void // function to open confirmation modal if modal is closed but modified
|}

type MappedProps = {|
  user: Object, // User object
  userType: $PropertyType<TVDApplicationStore, 'userType'>,
  initialSelectedTab?: number, // the nth tab that should be selected when opening the TabsPlain
|}

type Props = {|
  ...HOCProps,
  ...DispatchProps,
  ...MappedProps,
  ...ReceivedProps
|}

export class SettingsContainer extends React.Component<Props> {
  static defaultProps = {
    appType: '',
    initialSelectedTab: 0
  }

  componentDidMount() {
    this.getUserInformation()
  }

  getUserInformation = () => {
    const { userType } = this.props
    const options = { basePath: userType && getUsersAPIBasePath(userType) }
    getUsersSelfRequest({}, null, null, options)
  }

  tabs = (): Array<Object> => {
    const {
      t,
      dispatchCloseModal,
      features,
      appType,
      dispatchBuildConfirmationModal,
    } = this.props
    const contentStyles = {
      minHeight: '610px',
      maxHeight: '610px',
      display: 'flex',
      flex: 1,
      flexDirection: 'column'
    }
    const userTabs = [{
      props: {
        label: t('settings._PROFILE_'),
      },
      testId: 'profile-tab',
      contentStyles,
      content: (
        <Profile
          user={this.props.user}
          onClose={dispatchCloseModal}
          confirm={dispatchBuildConfirmationModal}
          refresh={this.getUserInformation} />
      )
    },
    ]

    const generalTabs = [
      {
        featureName: FEATURE_BUILDINGS_ACCESS_RIGHTS,
        props: {
          label: t('settings._SETTINGS_BUILDINGS'),
        },
        testId: `${SETTINGS_BUILDING_TAB}-tab`,
        contentStyles,
        content: (
          <SettingsBuildings />
        ),
      }, {
        featureName: FEATURE_ESTIMATES_ACCESS_RIGHTS,
        props: {
          label: t('settings._SETTINGS_ESTIMATES'),
        },
        testId: `${SETTINGS_ESTIMATES_TAB}-tab`,
        contentStyles,
        content: (
          <SettingsEstimates />
        ),
      }
    ]

    switch (appType) {
      case USERS: {
        const tabs = features.getEnabledFeatures(userTabs)
        return tabs
      }
      default:
        return features.getEnabledFeatures(userTabs.concat(generalTabs))
    }
  }

  render(): React$Element<ModalForm> {
    const { initialSelectedTab } = this.props
    const tabs = this.tabs()
    return (
      <div id='settingsContainer'>
        { !!this.props.user && <TabsPlain initialSelectedTab={initialSelectedTab} tabs={tabs} /> }
      </div>
    )
  }
}

const mapStateToProps = ({ user: { claims }, app: { userType } }: TVDReduxStore): MappedProps => ({
  user: claims,
  userType
})

const mapDispatchToProps = (dispatch: Function, { id }: ReceivedProps) => ({
  dispatchCloseModal: () => { dispatch(closeModal(id)) },
  dispatchBuildConfirmationModal: (content: Object) => { dispatch(buildConfirmationModal(content)) },
})

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation('translations'),
  FeaturesHOC,
  withRouter
)(SettingsContainer)
