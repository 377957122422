// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.
import React from 'react'
import type { Element } from 'react'
import Icon from '@material-ui/core/Icon'
import { withStyles } from '@material-ui/core/styles'
import { colors } from 'frontend-assets'
import LabeledInput from '../LabeledInput/LabeledInput'
// $FlowFixMe
import { ReactComponent as SVGIconOtherSearch } from '../../../../node_modules/frontend-assets/static/assets/images/icons/Other Search.svg'
// $FlowFixMe
import { ReactComponent as SVGIconOtherClose } from '../../../../node_modules/frontend-assets/static/assets/images/icons/Other Close.svg'

const styles = (): Object => ({
  Icon: {
    fontSize: '23px',
    marginLeft: '5px',
    marginBottom: '2px',
    '& path': {
      fill: colors.dark80
    }
  },
  IconClickable: {
    cursor: 'pointer',
    '&:hover path': {
      fill: colors.primary100
    }
  },
  IconNonClickable: {
    pointerEvents: 'none'
  }
})

type Props = {|
  classes: Object, // withStyles object
  id: string, // id for testing
  onBlur: Function, // functionality for onBlur event
  label: string, // label of search input
  onKeyDown?: Function, // execute on Enter key
  onClear?: Function, // fn to run when pressing the X-icon
  isSearchActive?: boolean, // toggle showing search icon adornment
  onChange?: Function, // input onChange fn - very useful for controller inputs
  value?: any, // input value - useful for controller inputs
  disableUnderline: ?boolean, // should underline be disabled
  placeholder: string | null, // should searchfield have placeholder
  size?: string, // should searchfield have custom width
|}

class SearchField extends React.Component<Props> {
  static defaultProps = {
    isSearchActive: false,
    onChange: null,
    value: null,
    onClear: null,
    onBlur: null,
    onKeyDown: null,
    disableUnderline: false,
    placeholder: null,
    size: undefined
  }
  render(): Element<typeof LabeledInput> {
    const {
      label,
      id,
      onBlur,
      onKeyDown,
      onClear,
      classes,
      isSearchActive,
      onChange,
      value,
      disableUnderline,
      placeholder,
      size
    } = this.props
    return (
      <LabeledInput
        disableUnderline={disableUnderline}
        disableValidation
        placeholder={placeholder}
        id={`${id}-SearchField`}
        label={label}
        size={size}
        onBlur={onBlur}
        onKeyDown={onKeyDown}
        adornment={
          <Icon
            className={isSearchActive ? `${classes.Icon} ${classes.IconClickable}` : `${classes.Icon} ${classes.IconNonClickable}`}
            onClick={() => { if (isSearchActive && onClear) onClear() }}>
            {!isSearchActive ? <SVGIconOtherSearch /> : <SVGIconOtherClose />}
          </Icon>}
        handleChange={onChange}
        value={value} />
    )
  }
}

export default withStyles(styles)(SearchField)
