// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.
import { map, keys as keysFunction, set, isUndefined, isObject, reduce, filter, replace, toNumber, isEmpty, intersection } from 'lodash'
import { WOP_ITEM_PREFIX } from '../constants/moduleConstants'
import {
  GENERATED_ITEM,
  FUNCTIONALSECTORGROUP,
  GROUPING_VIEW,
  HEADING
} from '../constants/contentTypes'
import { dotsToCommas, roundToPrecision } from './commonUtils'
import { DESCRIPTION } from '../constants/attributes'

export const getSelectedListItemsByType = (listItems: Object, type: string) => (
  filter(listItems, (listItem: Object) => listItem.selected && listItem.type.toLowerCase() === type)
)

// Get contextmenu actions by row type
export const getRowActionsByType = (type: string, listItems: Object) => {
  const basicActions = ['_RENAME_', '_COPY_', '_DELETE_']

  const multipleSpacesSelected = getSelectedListItemsByType(listItems, 'space').length > 1
  const multipleSpaceGroupsSelected = getSelectedListItemsByType(listItems, 'spacegroup').length > 1
  const multipleFunctionsSelected = getSelectedListItemsByType(listItems, 'function').length > 1

  const itemsByRowType = {
    functionalsector: [...basicActions, '_CREATE_', '_CREATE_ROOT_'],
    process: [...basicActions],
    activitygroup: [...basicActions],
    activity: [...basicActions],
    function: [
      ...basicActions, '_CREATE_',
      ...multipleFunctionsSelected ? ['_MOVE_FUNCTIONS_'] : ['_MOVE_'],
    ],
    spacegroup: [
      ...basicActions,
      ...multipleSpacesSelected ? ['_MOVE_SPACES_'] : [],
      ...multipleSpaceGroupsSelected ? ['_MOVE_SPACE_GROUPS_', '_EDIT_SPACE_GROUPS_'] : [],
      ...!multipleSpaceGroupsSelected ? ['_MOVE_'] : [],
    ],
    space: [
      ...basicActions,
      ...multipleSpacesSelected ? ['_MOVE_SPACES_', '_EDIT_SPACES_'] : [],
      ...multipleSpaceGroupsSelected ? ['_MOVE_SPACE_GROUPS_'] : [],
      ...!multipleSpacesSelected ? ['_MOVE_'] : [],
    ],
    heading: [...basicActions, '_CREATE_ITEM_', '_CREATE_HEADING_'],
    priceitem: [...basicActions, '_CREATE_ITEM_', '_CREATE_HEADING_', '_MOVE_'],
    assembly: [...basicActions, '_CREATE_'],
    assemblyPriceitem: ['_ATTACH_ITEM_', '_OPEN_', '_RENAME_', '_DELETE_'],
    item: ['_CREATE_FROM_PRICING_'],
    nonloadbearingstructures: null,
    areaspacedifferencemassingbyuser: null,
    exteriorwalls: null,
    loadbearingstructures: null
  }
  if (itemsByRowType[type.toLowerCase()] === undefined) {
    console.warn(`getRowActionsByType: key corresponding to argument "type" not found. Found "${type}" instead`)
  }
  return itemsByRowType[type.toLowerCase()]
}

export const getHierarchyLevels = (data: Object, result: Object = {}, level: number = 0): Object => {
  for (const key of keysFunction(data)) {
    const item = data[key]
    // only loop through items with no value, because items with value are propertyrows
    // and only categoryrows need level information - also skip everything that has a level so we don't iterate over data for no reason
    if (isObject(item) && isUndefined(item.value) && isUndefined(item.level)) {
      item.level = level
      set(result, key, item)
      result = { ...result, ...getHierarchyLevels(item, result, level + 1) }
    }
  }
  return result
}

export const getRootListItems = (listItems: TVDListItems, listType?: string | null) => {
  const rootListItems = filter(listItems, ({ parentId, type }: TVDListItem) => !parentId || (listType === GROUPING_VIEW &&
     type === FUNCTIONALSECTORGROUP.toLowerCase()))
  const rootListItemsWithLevel = rootListItems.map((rootListItem: TVDListItem) => ({ ...rootListItem, level: 0 }))
  return rootListItemsWithLevel
}

export const traverseListItem = (
  listItems: Object,
  currentListItem: Object,
  traverseCallback?: (currentListItem: Object) => Object,
  childCb?: (childListItem: Object) => any,
) => {
  const children = filter(listItems, (listItem: Object) => listItem.parentId === currentListItem.id)
  if (childCb) children.forEach((child: Object) => childCb(child))
  return reduce(children, (result: Object, child: Object) => ({
    ...result,
    ...traverseListItem(listItems, child, traverseCallback)
  }), { ...(traverseCallback ? traverseCallback(currentListItem) : { [currentListItem.id]: currentListItem }) })
}

export const traverseListItems = (
  listItems: Object,
  traverseCallback?: Function,
  parentId?: string,
  childCb?: (childListItem: TVDListItem) => any
) => {
  const rootListItems = filter(listItems, (listItem: Object) => {
    if (parentId) return listItem.id === parentId
    return !listItem.parentId
  })
  return reduce(rootListItems, (result: Object, rootListItem: Object) => ({
    ...result,
    ...traverseListItem(listItems, rootListItem, traverseCallback, childCb)
  }), {})
}

export const getListItemParentIds = (listItems: Object, listItem: Object): Array<string> => {
  const parents = []
  let { parentId: currentParentId } = listItem
  while (currentParentId) {
    parents.push(currentParentId)
    currentParentId = listItems[currentParentId].parentId
  }
  return parents
}


export const mergeModifiedColumnData = (listItem: TVDListItem) => {
  const hasModifiedColumns = listItem.modifiedColumnData && Object.keys(listItem.modifiedColumnData).length > 0

  if (!hasModifiedColumns) return listItem.columnData
  return { ...listItem.columnData, ...listItem.modifiedColumnData }
}

// utility to get listItems with modifiedValue - attribute
export const getModifiedListItems = (listItems: Object) => reduce(listItems, (result: Object, item: Object): Object => {
  if (isEmpty(item.modifiedColumnData)) return result

  return {
    ...result,
    [item.id]: item.modifiedColumnData,
  }
}, {})

// utility to get properties with modifiedValue - attribute
export const getModifiedProperties = (properties: Object) => reduce(properties, (result: Object, property: Object): Object => {
  switch (true) {
    case property.modifiedValue !== undefined: {
      return {
        ...result,
        [property.propertyName]: property.modifiedValue,
      }
    }
    default: {
      return result
    }
  }
}, {})

export const getResetDefaultProperties = (properties: Object) => {
  const resetProperties: Array<string> = []
  map(properties, (prop: Object) => {
    if (prop.resetDefault !== undefined) resetProperties.push(prop.propertyName)
  })
  return { resetProperties }
}

export function thousandSeparator(value: string): string {
  // use regex pattern to separate thousands with spaces
  // \B assert position where \b does not match
  // ?= positive lookahead
  // \d{3} matches a digit (equal to [0-9]), {3} Quantifier — Matches exactly 3 times
  // + Quantifier - Matches between one and unlimited times, as many times as possible
  // ?!\d: Negative Lookahead (Assert that the Regex does not match a digit (equal to [0-9])
  // g modifier: global. All matches (don't return after first match)
  return replace(value, /\B(?=(\d{3})+(?!\d))/g, ' ')
}

export function formatCellContent(cellContent: string | number, dataType: string): string | number {
  if (!cellContent) return ''

  switch (dataType) {
    case 'integer':
      return thousandSeparator(dotsToCommas(roundToPrecision(Number(cellContent), 0)))
    case 'number':
      if (Number.isInteger(roundToPrecision(Number(cellContent), 1))) {
        return thousandSeparator(dotsToCommas(roundToPrecision(Number(cellContent), 1))).concat(',0')
      }
      return thousandSeparator(dotsToCommas(roundToPrecision(Number(cellContent), 1)))
    case 'string':
    default:
      return cellContent
  }
}

export function formatResultBoxContent(
  resultBoxContent: string | number,
  dataType: string,
  formatOptions?: TVDResultBarFormatOptions
): string | number {
  if (resultBoxContent === undefined || resultBoxContent === 0) return '0'
  const {
    disableRounding,
    decimalsToShow
  } = formatOptions || {}

  switch (dataType) {
    case 'integer': {
      let content = Number(resultBoxContent)
      const decimals = decimalsToShow || 0
      switch (true) {
        case !disableRounding: {
          content = roundToPrecision(content, decimals).toFixed(decimals)
          break
        }
        default: {
          break
        }
      }
      return thousandSeparator(dotsToCommas(content))
    }
    case 'number': {
      const decimals = decimalsToShow || 1
      if (Number.isInteger(roundToPrecision(Number(resultBoxContent), decimals))) {
        return thousandSeparator(dotsToCommas(roundToPrecision(Number(resultBoxContent), decimals))).concat(',0')
      }
      return thousandSeparator(dotsToCommas(roundToPrecision(Number(resultBoxContent), decimals)))
    }
    case 'string':
    default:
      return resultBoxContent
  }
}

export function formatValue(value: string | number, dataType: string): string | number {
  let formattedValue = value
  if (dataType === 'number' || dataType === 'integer') {
    if (!value) return formattedValue
    const valueCommasToDots = replace(value, ',', '.')
    const valueWithoutWhiteSpace = replace(valueCommasToDots, / /g, '')
    formattedValue = toNumber(valueWithoutWhiteSpace)
  }
  return formattedValue
}

export function getIsUserModifiedContent(listItem: TVDListItem): boolean {
  const { userModifiedContent, modifiedColumnData: { userModifiedContent: modifiedColumnDataUserModifiedContent } = {} } = listItem
  if (typeof modifiedColumnDataUserModifiedContent !== 'undefined') return modifiedColumnDataUserModifiedContent
  if (typeof userModifiedContent !== 'undefined') return userModifiedContent
  if (listItem.modifiedColumnData) return !!listItem.modifiedColumnData
  return false
}

type GetPatchOperationListItemsFromValueProperties = {|
  properties: TVDPropertiesListItems,
  parentId: string, // parentId for the generated list item
|}

export const getPatchOperationListItemsFromValueProperties = ({
  properties,
  parentId,
}: GetPatchOperationListItemsFromValueProperties): TVDListItems =>
  Object.keys(properties).reduce((generatedListItems: TVDListItems, propertyName: string): TVDListItems => {
    const {
      localizedName,
      defaultValue: propertyDefaultValue,
    } = properties[propertyName]
    const id = `${parentId}-${propertyName}`
    return {
      ...generatedListItems,
      [id]: {
        type: GENERATED_ITEM,
        id,
        parentId,
        columnData: {
          [DESCRIPTION]: localizedName,
          [propertyName]: propertyDefaultValue,
        },
        modifiedColumnData: {
          [propertyName]: propertyDefaultValue
        },
        canGetInfo: false,
      }
    }
  }, {})

export function reverseTraverseListItems(items: TVDListItems, reverseTraverseConfig: TVDReverseTraverseConfig): TVDListItems {
  const { filterItem, alterItem } = reverseTraverseConfig
  return Object.keys(items).reduce((result: TVDListItems, listItemId: string) => {
    const listItem = filterItem(items[listItemId]) ? items[listItemId] : null
    if (listItem) {
      let { parentId } = listItem
      const newParentListItems = {}
      while (parentId) {
        newParentListItems[parentId] = alterItem(items[parentId])
        const { parentId: nextParentId } = items[parentId]
        parentId = nextParentId
      }
      return { ...result, ...newParentListItems, [listItemId]: alterItem(listItem) }
    }
    return {
      ...result,
      [listItemId]: items[listItemId]
    }
  }, {})
}

export const getDirectParentsOfItem = (item: TVDListItem | TVDWOPListItem, listItems: Object, open: boolean, itemCallBack: Function): void => {
  let parent = listItems[item.parentId]
  while (parent) {
    itemCallBack(parent)
    parent = listItems[parent.parentId] // assign current parents parent for while loop to continue until no parent found
  }
}

export const filterListByActivities = (wopSpaceScheduleItems: Object, activityGroupIds: Array<number>, openRows: boolean = true) => {
  // filter the rows of which filterIds match any id(s) in the activityGroupIds array
  const filteredByActivityGroupIds = filter(wopSpaceScheduleItems, (listItem: Object) =>
    Boolean(intersection(activityGroupIds, listItem.filterIds).length))
  const result = {}

  const appendToResult = (row: TVDListItem): void => { result[row.id] = { ...row, isOpen: openRows } }
  filteredByActivityGroupIds.forEach((listItem: TVDListItem) => {
    getDirectParentsOfItem(listItem, wopSpaceScheduleItems, openRows, appendToResult)
    traverseListItem(wopSpaceScheduleItems, listItem, appendToResult)
  })
  return result
}

export const filterListBySpaces = (activityStructureItems: Object, filterIds: Array<number> = [], openRows: boolean = true) => {
  const filteredActivities = filter(activityStructureItems, (listItem: Object) => filterIds.includes(listItem.wopItemId)) // filter the rows of which id match any number in the filterIds
  const result = {}

  const appendToResult = (row: TVDWOPListItem): void => { result[row.id] = { ...row, isOpen: openRows } }
  filteredActivities.forEach((listItem: TVDWOPListItem) => {
    getDirectParentsOfItem(listItem, activityStructureItems, openRows, appendToResult)
    traverseListItem(activityStructureItems, listItem, appendToResult)
  })
  return result
}

// create object from rows columnData that has been modified by user
// userModified values are those values in columnMeta that have userModified status
export const getUserModifiedDataFromColumnMeta = (listItem: TVDListItem): { [metaDataKey: string]: string } => {
  if (listItem.columnMeta) {
    return Object.keys(listItem.columnMeta)
      .reduce((result: { [metaDataKey: string]: string }, metaDataKey: string) => {
        const { columnMeta: { [metaDataKey]: { userModified } = {} } = {} } = listItem
        if (userModified) {
          return {
            ...result,
            [metaDataKey]: listItem.columnData[metaDataKey]
          }
        }
        return result
      }, {})
  }
  return {}
}

// Check an object ancestry for the outcome of checkerFn
export const checkAncestryFor = (listItems: Object, row: Object, checkerFn: Function): boolean => {
  if (!row.parentId) return false

  const parent = listItems[row.parentId]
  if (checkerFn(parent)) return true
  return checkAncestryFor(listItems, parent, checkerFn)
}

export const getSearchFilteredListItems = (
  listItems: TVDListItems, // existing listItems that are going to be filtered
  listFilter: string, // filter string that is applied when filtering Description columnData
  filteredTypes?: Array<string>, // types of the listItems that will get filtered if argument is given
  filterKey?: string, // optional key that will overwrite the default Description key usage
): TVDListItems => {
  const filteredListItems = traverseListItems(listItems, (listItem: Object) => {
    const { type, columnData: { Description = '' } } = listItem
    const filterByTypes = filteredTypes && filteredTypes.length > 0 ? filteredTypes.includes(type) : true
    const filterByDescription = Description.toLowerCase().includes(listFilter.toLowerCase())

    const filteredKeyData = listItem[filterKey] === listFilter
    const filteredData = filterKey ? filteredKeyData : filterByDescription
    if (filterByTypes && !!filteredData) {
      let currentParentId = listItem.parentId
      const parents = {}

      while (currentParentId) {
        const parentListItem = listItems[currentParentId]
        parents[currentParentId] = parentListItem
        currentParentId = parentListItem.parentId
      }

      // counter reverse the order because our while loop added the parent rows in a reversed manner
      const orderedAndOpenedParents = reduce(Object.keys(parents).reverse(), (result: Object, id: string) =>
        ({ ...result, [id]: { ...listItems[id], isOpen: true } }), {})
      return { ...orderedAndOpenedParents, [listItem.id]: listItem }
    }
    return {}
  })
  return filteredListItems
}

export const getColumnDataFilteredListItems = (
  listItems: TVDListItems, // existing listItems that are going to be filtered
  filterColumnDataKey: string, // column data key which is used to get the value to compare with filterColumnDataValue
  filterColumnDataValue: string | number | boolean // column data value that used with filtering
) => Object.keys(listItems).reduce((result: TVDListItems, listItemId: string) => {
  const listItem = listItems[listItemId]
  if (listItem.columnData[filterColumnDataKey] === filterColumnDataValue) {
    return { ...result, [listItem.id]: listItem }
  }
  return result
}, {})

export const sortListItemsAlphabetically = (listItems: Array<TVDListItem>): Array<TVDListItem> =>
  listItems.sort((prevListItem: TVDListItem, nextListItem: TVDListItem) => {
    const prevName = prevListItem.columnData[DESCRIPTION].toLowerCase()
    const nextName = nextListItem.columnData[DESCRIPTION].toLowerCase()
    if (prevName < nextName) {
      return -1
    }
    if (prevName > nextName) {
      return 1
    }
    return 0
  })

export const isListItemTypeBold = (type: string): boolean => {
  switch (type.toUpperCase()) {
    case HEADING: return true
    default: return false
  }
}

export const measureHasEditableProperties = (properties?: Array<TVDSchemaValueProperty>): boolean => {
  if (!properties || properties.length < 2) return false
  return true
}

export const getPrefixedWOPListItemId = (id: number): string => `${WOP_ITEM_PREFIX}${id}`

// support for parentIds is atm only added to WOPListitems but can be added to TVDListItems in case needed
export const getListItemsWithoutBranch = (listItems: TVDWOPListItems, id: string): TVDWOPListItems =>
  reduce(listItems, (result: TVDWOPListItems, nextListItem: TVDWOPListItem) => {
    const { parentIds } = nextListItem
    if (parentIds && parentIds.includes(id)) return result
    return {
      ...result,
      [nextListItem.id]: nextListItem
    }
  }, {})


export const getWOPListItemParentIds = (listItems: Array<TVDWOPSchemaScheduleItem>, listItemParentId: number): Array<string> => {
  const parentIds = []
  let prevParentId = listItemParentId
  while (prevParentId) {
    parentIds.push(getPrefixedWOPListItemId(prevParentId))
    // eslint-disable-next-line no-loop-func
    const nextParent = listItems.find((item: TVDWOPSchemaScheduleItem): boolean => item.id === prevParentId)
    prevParentId = nextParent && nextParent.parentId ? nextParent.parentId : undefined
  }
  return parentIds
}

export const getLatestListUpdateTime = (list: TVDListStore, listStoreIds: Array<string>): number | typeof undefined =>
  listStoreIds.reduce((prevListLastUpdated: number = 0, watchListStoreId: string): number => {
    const nextListState = list[watchListStoreId]
    if (!nextListState) return prevListLastUpdated
    const nextListLastUpdate = nextListState.listLastUpdated || 0
    return prevListLastUpdated < nextListLastUpdate ? nextListLastUpdate : prevListLastUpdated
  }, undefined) || undefined

export const scrollToElement = (scrollToId: string) => {
  const el = document.querySelector(`[id*="${scrollToId}"]`)
  if (el) {
    el.scrollIntoView({ block: 'end' })
  } else {
    console.error(`Could not scroll to estimate with scrollToId of ${scrollToId}`)
  }
}
