// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withStyles, Typography } from '@material-ui/core'
import { withTranslation } from 'react-i18next'

import { TextButton } from 'frontend-components'

import { closeModal } from '../../../actions/modals'

type DispatchProps = {|
  dispatchCloseModal: () => void, // closing modal action with the id given as props
|}

type Props = {|
  classes: Object, // withStyles styles prop
  t: Function, // translation function
  message?: string, // str message that component tires to translate for display
  messageTranslationKey?: string, // key that translate() fn uses to translate if it is provided and no message prop is given
  onClose: Function, // custom cb from openModal content for onClose that replaces default closing
  ...DispatchProps,
|}

const styles = ({ palette, typography }: TVDTheme): Object => ({
  message: {
    color: palette.nevada,
    fontFamily: typography.fontFamily,
    fontSize: '16px',
    padding: '61px 58px 30px 32px',
    lineHeight: '20px',
    letterSpacing: '0.5px',
    wordBreak: 'break-word',
  },
  content: {
    width: '553px',
    display: 'flex',
    flexDirection: 'column',
  },
  footer: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingRight: '30px',
    paddingBottom: '23px',
  }
})

export class ConfirmationModalPadded extends Component<Props> {
  static defaultProps = {
    message: '',
    messageTranslationKey: ''
  }
  get message(): React$Element<typeof Typography> {
    const {
      classes,
      t,
      message,
      messageTranslationKey
    } = this.props
    const translatedMessage = message || t(messageTranslationKey)
    return (
      <Typography className={classes.message}>{translatedMessage}</Typography>
    )
  }

  get footer(): React$Element<any> {
    const {
      classes,
      dispatchCloseModal,
      t,
      onClose
    } = this.props
    return (
      <div className={classes.footer}>
        <TextButton
          id='ConfirmationModalPadded-confirm'
          text={t('buttons._OK_')}
          onClick={() => {
            if (onClose) onClose()
            else dispatchCloseModal()
            }} />
      </div>
    )
  }

  render(): React$Element<any> {
    const { classes } = this.props
    return (
      <div className={classes.content}>
        {this.message}
        {this.footer}
      </div>
    )
  }
}

function mapDispatchToProps(dispatch: Function, { id }: Object): DispatchProps {
  return {
    dispatchCloseModal: () => dispatch(closeModal(id))
  }
}


export default withTranslation('translations')(connect(null, mapDispatchToProps)(withStyles(styles)(ConfirmationModalPadded)))
