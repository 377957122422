// @flow
// Copyright © 2010–2022 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.
import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useSelector } from 'react-redux'
import { loadMFE } from '../../../utils/MFEUtils'
import { API_BASE_URL, ALLOW_WITH_CREDENTIALS } from '../../../constants/envConstants'
import { USAGE_AND_MAINTENANCE } from '../../../constants/moduleConstants'

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    height: '100%'
  }
}))

const UsageAndMaintenanceSummary = (): React$Element<'div'> => {
  const classes = useStyles()
  const {
    realEstateId,
    accessToken,
    haahtelaApiUserToken,
    userId,
    languageCode,
    selectedAccountId,
    realEstateName,
    primaryEstimates: {
      [USAGE_AND_MAINTENANCE]: UMAPrimaryEstimateId
    },
  } = useSelector(({
    app,
    user,
  }: TVDReduxStore): {|
    primaryEstimates: $PropertyType<TVDApplicationStore, 'primaryEstimates'>,
    languageCode: $PropertyType<TVDApplicationStore, 'languageCode'>,
    selectedAccountId: $PropertyType<TVDApplicationStore, 'selectedAccountId'>,
    realEstateId: $PropertyType<TVDApplicationStore, 'realEstateId'>,
    realEstateName: $PropertyType<TVDApplicationStore, 'realEstateName'>,
    accessToken: $PropertyType<TVDUserAuthorization, 'accessToken'>,
    haahtelaApiUserToken: $PropertyType<TVDUserClaims, 'haahtelaApiUserToken'>,
    userId: $PropertyType<TVDUserClaims, 'userId'>,
  |} => ({
    languageCode: app.languageCode,
    selectedAccountId: app.selectedAccountId,
    realEstateId: app.realEstateId,
    realEstateName: app.realEstateName,
    accessToken: user.authorization.accessToken,
    haahtelaApiUserToken: user.claims.haahtelaApiUserToken,
    userId: user.claims.userId,
    primaryEstimates: app.primaryEstimates
  }))

  useEffect(() => {
    loadMFE('usage_maintenance', 'usage-maintenance-summary', (module: TVDMFEFunctionality): void => {
      if (
        languageCode &&
        accessToken &&
        haahtelaApiUserToken &&
        userId &&
        selectedAccountId &&
        realEstateId
      ) {
        const render = module.render || null
        if (render) {
          render(
            'usage-maintenance-summary',
            {
              APIBaseURL: API_BASE_URL,
              languageCode,
              accessToken,
              haahtelaApiUserToken,
              userId,
              selectedAccountId,
              realEstateId,
              realEstateName,
              estimateId: UMAPrimaryEstimateId,
              ALLOW_WITH_CREDENTIALS
            }
          )
        }
      }
    })
  }, [
    languageCode,
    accessToken,
    haahtelaApiUserToken,
    userId,
    selectedAccountId,
    realEstateId,
    realEstateName,
    UMAPrimaryEstimateId
  ])
  return (
    <div className={classes.root} id='usage-maintenance-summary' />
  )
}

export default UsageAndMaintenanceSummary

