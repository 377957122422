// @flow
// Copyright © 2010–2023 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.

import React from 'react'
import Drawer from '@material-ui/core/Drawer'
import { makeStyles } from '@material-ui/core/styles'
import { colors } from 'frontend-assets'
import IconButton from '../IconButton/IconButton'
import { headerHeight } from '../../../../components/common/Header/Header'

export const drawerWidth = {
  side: 90,
  buttonSide: 82,
  horizontal: '100%',
}

const { gray20, dark120 } = colors

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    width: drawerWidth.side
  },
  drawerPaper: {
    background: gray20,
    paddingTop: headerHeight,
    width: drawerWidth.side
  },
  sideDrawer: {
    width: drawerWidth.side,
    flexShrink: 0,
    '& > div > div > div': {
      width: drawerWidth.buttonSide
    },
  },
  horizontalDrawer: {
    width: drawerWidth.horizontal,
    flexShrink: 1,
    '& > div > div': {
      width: drawerWidth.horizontal,
      '& > div': {
        display: 'flex',
      }
    }
  },
  paperAnchorDockedLeft: {
    border: 'none'
  },
  actionBarFiller: {
    backgroundColor: dark120,
    flexGrow: 1
  }
}))

type Props = {
  children: Array<React$Element<typeof IconButton>>,
  anchor: 'top' | 'right' | 'bottom' | 'left',
}

const ActionBar = (props: Props) => {
  const classes = useStyles()

  const getStyles = () => {
    switch (props.anchor) {
      case 'top':
      case 'bottom':
        return classes.horizontalDrawer
      case 'right':
      case 'left':
        return classes.sideDrawer
      default:
        return classes.sideDrawer
    }
  }

  return (
    <div className={classes.root}>
      <nav className={getStyles()} aria-label='Action Bar'>
        <Drawer
          anchor={props.anchor}
          classes={{
            paper: classes.drawerPaper,
            paperAnchorDockedLeft: classes.paperAnchorDockedLeft
            }}
          variant='permanent'>
          {props.children}
          <div
            className={classes.actionBarFiller}
            data-testid='actionbar-filler' />
        </Drawer>
      </nav>
    </div>
  )
}

export default ActionBar
