// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.

import React from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core'
import { useDispatch } from 'react-redux'
import { typographyClasses, colors } from 'frontend-assets'
import { openModal } from '../../../actions/modals'
import { MODAL_TYPE_UNFREEZE_ESTIMATE } from '../../../constants/modalConstants'
// $FlowFixMe
import { ReactComponent as AlertInfoOutlinedIcon } from '../../../../node_modules/frontend-assets/static/assets/images/icons/Alert Info outlined.svg'

const useStyles = makeStyles(() => ({
  wrapper: {
    ...typographyClasses.bodySmall,
    display: 'inline-flex',
    backgroundColor: colors.gray20,
    width: '99%', // 99% is because we have overflow hidden in <Spaces /> which will hide away part of the notification if we put 100%
    padding: '8px 16px',
    margin: '8px',
    alignItems: 'center',
    borderRadius: '0px',
    color: colors.dark120,
    gap: 8,
  },
  open: {
    ...typographyClasses.bodySmallSemiBoldUnderline,
    cursor: 'pointer',
    color: colors.primary100
  }
}))

type FrozenNotificationProps = {|
  disabled?: boolean, // to hide or show the frozen notification
|}

const FrozenNotification = (props: FrozenNotificationProps): React$Element<'div'> | null => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation()

  if (props.disabled) return null

  const openUnfreezeModal = () => {
    dispatch(openModal({
      type: MODAL_TYPE_UNFREEZE_ESTIMATE,
    }, MODAL_TYPE_UNFREEZE_ESTIMATE))
  }

  return (
    <div className={classes.wrapper}>
      <AlertInfoOutlinedIcon />
      <div>
        <span className={classes.instruction}>{t('freezingNotification._INSTRUCTION_')}</span>
        &nbsp;
        <span
          tabIndex={0}
          role='button'
          onClick={openUnfreezeModal}
          className={classes.open}>
          {t('freezingNotification._OPEN_ESTIMATE_AND_UPDATE_')}
        </span>
        &nbsp;
        <span>{t('freezingNotification._RESULTS_WILL_BE_UPDATE_')}</span>
      </div>
    </div>
  )
}

FrozenNotification.defaultProps = { disabled: false }
export default FrozenNotification
