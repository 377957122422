// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withTranslation } from 'react-i18next'
import { withStyles } from '@material-ui/core/styles'
import { Icon } from '@material-ui/core'
import { map } from 'lodash'
import { TextButton } from 'frontend-components'

import SimpleDataTable, { type TVDSimpleDataTableColumn } from '../../common/lists/SimpleDataTable/SimpleDataTable'
import Spinner from '../../../components/common/Spinner/Spinner'
import DescriptionCell from '../../common/lists/common/DescriptionCell/DescriptionCell'
import HamburgerMenu from '../../common/menus/HamburgerMenu/HamburgerMenu'
import InfoPopover, { ESTIMATE_IS_PRIVATE_INFO } from '../../common/InfoPopover/InfoPopover'
import CheckBox from '../../common/CheckBox/CheckBox'
import DropdownMenu from '../../common/menus/DropdownMenu/DropdownMenu'
import Feature from '../Feature/Feature'
import { FEATURE_IS_ESTIMATE_PRIVATE_CHECKBOX } from '../../../constants/features'
import { NAME, EMAIL, USER_ACCESS } from '../../../constants/attributes'
import { MODAL_TYPE_ADD_ACCESS_RIGHT_MODAL, MODAL_TYPE_USER_MODAL } from '../../../constants/modalConstants'
import { USER, USER_GROUP } from '../../../constants/schemaKeys'
import { ACCESS_CONTROL_USER_GROUP, CONFIRMATION_MODAL } from '../../../constants/contentTypes'
import { READ, WRITE, OWNER, PERMISSION_ORDER, MANAGER } from '../../../constants/permissions'
import { closeModal, openModal } from '../../../actions/modals'
import {
  getEstimatesPermissionsUsersWithEstimateIdRequest,
  getEstimatesPermissionsUserGroupsWithEstimateIdRequest,
  deleteEstimatesPermissionsWithEstimateIdRequest,
  postEstimatesPermissionsWithEstimateIdRequest,
  putEstimatesPermissionsWithEstimateIdRequest
} from '../../../utils/generated-api-requests/estimates'

const styles = ({ palette, typography }: TVDTheme): Object => ({
  wrapper: {
    height: '700px',
    display: 'flex',
    flexDirection: 'column'
  },
  addAccessRightButtonContainer: {
    marginTop: '29px',
    marginLeft: '30px',
    marginBottom: '38px',
    display: 'flex',
    alignItems: 'center'
  },
  alignLeft: {
    justifyContent: 'flex-start',
    textAlign: 'left'
  },
  groupIcon: {
    marginLeft: '3px',
    color: palette.manatee,
  },
  descriptionWrapper: {
    display: 'flex',
    '&:hover [data-visible_on_hover_id="hamburgerMenu"]': {
      visibility: 'visible'
    }
  },
  hamburgerWrapper: {
    paddingLeft: '5px'
  },
  highlightedMessage: {
    color: palette.primary100,
    fontFamily: typography.fontFamilySemild,
    '&:after': {
      content: '"?"',
      color: palette.nevada
    }
  },
  checkboxWrapper: {
    '&:hover [data-visible_on_hover]': {
      visibility: 'visible'
    },
    display: 'flex',
    alignItems: 'center',
    marginRight: '30px'
  },
  label: {
    color: palette.nevada,
    marginLeft: '17px'
  },
  infoPopover: {
    marginLeft: '10px',
    paddingTop: '1px'
  },
  permissionColumn: {
    '& [class*="MuiFormControl-root"]': {
      verticalAlign: 'middle'
    }
  }
})

type HOCProps = {|
  t: Function, // translate function
  classes: Object, // withstyles classes object
  testId: string, // id string for testing purposes
|}

type ReceivedProps = {|
  estimateId: string, // the estimate that is being viewed
  buildingId: string, // the building's id that the estimate belongs to
  disableEditUserInformation?: boolean, // if we want the user modal to be preview only by disabling all inputs and saving
  showIsPrivateCheckbox?: boolean, // flag to show check box
  realEstateId: $PropertyType<TVDApplicationStore, 'realEstateId'>, // current selected realEstateId
|}

type DispatchProps = {|
  dispatchOpenUserModal: (Object, string) => void, // function to open user modal to display user information and permissions
  dispatchOpenAddAccessRightModal: (Object, string) => void, // function to open modal for adding access rights for estimates and buildings
  dispatchCloseConfirmationModal: () => void, // action to close confirmation modal
  dispatchBuildConfirmDeleteModal: (Object) => void, // build modal to confirm deletion of access right from estimate
  dispatchPrivateCheckBoxConfirmationModal: (Object) => void // modal to confirm changing to private estimate
|}

type Props = {|
  ...DispatchProps,
  ...HOCProps,
  ...ReceivedProps
|}

type UserRow = {|
  id: string, // uuid of the user
  text: string, // text shown in the user interface
  type: USER | USER_GROUP,
  adornment?: React$Element<any>, // adornment element rendered at the end of the row
  isOwner?: boolean, // is the user row has the permission "owner" in their permissinos
|}

type Row = [UserRow, string, string]

type State = {|
  rows: Array<Row>, // array of rows where each array has column value by index
  isLoading: boolean, // if we are loading estimate permissions and want to show a spinner
  isPrivate: boolean, // boolean to make estimate private so only the estimate creator can see it
|}

const IS_PRIVATE = 'isPrivate'

export class EstimateAccessRights extends Component<Props, State> {
  static defaultProps = {
    disableEditUserInformation: false
  }

  state = {
    rows: [],
    isLoading: true,
    isPrivate: false,
  }

  componentDidMount = () => {
    this.getEstimatesPermissions()
  }

  componentDidUpdate = (prevProps: Props, prevState: State) => {
    const { isPrivate, isLoading } = this.state
    if (isPrivate !== prevState.isPrivate && !isLoading) {
      this.getEstimatesPermissions()
    }
  }

  getEstimatesPermissions = () => {
    const { estimateId } = this.props
    this.setState({ isLoading: true })
    getEstimatesPermissionsUserGroupsWithEstimateIdRequest(
      {
        query: {
          includeUserGroups: true,
          directPermissions: false
        }
      },
      {},
      (userGroups: any[]) => {
        const isPrivate = userGroups.length === 2
        getEstimatesPermissionsUsersWithEstimateIdRequest(
          {
            query: {
              includeUsers: true,
              directPermissions: isPrivate
            }
          },
          {},
          (users: any[]) => {
            const usersWithReadWriteOrManager = users.filter((user: TVDUserReducedWithPermissions): boolean =>
              user.permissions.includes(READ) ||
                  user.permissions.includes(WRITE) ||
                  user.permissions.includes(MANAGER) ||
                  user.permissions.includes(OWNER))

            this.setState({
              rows: this.formatRows(usersWithReadWriteOrManager, USER),
              isLoading: false,
              isPrivate,
            })
          },
          null,
          { estimateId }
        )
      },
      null,
      { estimateId }
    )
  }

  getColumns = (): Array<TVDSimpleDataTableColumn> => {
    const { t } = this.props
    return [
      {
        key: NAME,
        localizedName: t('listColumns._NAME_')
      },
      {
        key: EMAIL,
        localizedName: t('listColumns._EMAIL_')
      },
      {
        key: USER_ACCESS,
        localizedName: t('listColumns._USER_ACCESS_')
      }
    ]
  }

  getTranslatedPermissions = (permissions: Array<TVDSchemaSubjectPermissionEnum>): string => {
    const { t } = this.props
    return permissions.map((permission: TVDSchemaSubjectPermissionEnum): string =>
      t(`permissions._${permission.toUpperCase()}_`)).join(', ')
  }

  sortUserAlphabetically = (aUser: any, bUser: any): number => {
    switch (true) {
      case aUser[0].text > bUser[0].text: return 1
      case bUser[0].textName > aUser[0].text: return -1
      default: return 0
    }
  }

  formatRows = (
    response: Array<TVDUserReducedWithPermissions>,
    type: string
  ): Row[] => {
    const { classes } = this.props
    const createDescription = (subject: Object) => {
      switch (type) {
        case (USER_GROUP):
          return {
            text: subject.localizedName,
            id: subject.value,
            adornment: <Icon className={classes.groupIcon}>group</Icon>,
            type
          }
        case (USER):
          return {
            text: `${subject.firstName} ${subject.lastName}`,
            id: subject.id,
            type,
            isOwner: subject.permissions.includes(OWNER)
          }
        default:
          return {}
      }
    }
    const getHighestPermission = (permissions: any) => {
      switch (true) {
        case permissions.includes(OWNER): { return OWNER }
        case permissions.includes(MANAGER): { return MANAGER }
        case permissions.includes(WRITE): { return WRITE }
        case permissions.includes(READ): { return READ }
        default: return READ
      }
    }

    const usersByGroup = map(response, (subject: Object) => [
      createDescription(subject),
      subject.email || '',
      { highestPermission: getHighestPermission(subject.permissions), userId: subject.id }
    ]).reduce((result: any, userWithHighestPermission: any[]) => {
      const { highestPermission } = userWithHighestPermission[2]
      switch (highestPermission) {
        case OWNER: {
          return { ...result, owners: [...result.owners, userWithHighestPermission].sort(this.sortUserAlphabetically) }
        }
        case MANAGER: {
          return { ...result, managers: [...result.managers, userWithHighestPermission].sort(this.sortUserAlphabetically) }
        }
        case WRITE: {
          return { ...result, writers: [...result.writers, userWithHighestPermission].sort(this.sortUserAlphabetically) }
        }
        case READ: {
          return { ...result, readers: [...result.readers, userWithHighestPermission].sort(this.sortUserAlphabetically) }
        }
        default: return { ...result, others: [...result.others, userWithHighestPermission].sort(this.sortUserAlphabetically) }
      }
    }, {
      owners: [], managers: [], writers: [], readers: [], others: []
    })

    return [
      ...usersByGroup.owners,
      ...usersByGroup.managers,
      ...usersByGroup.writers,
      ...usersByGroup.readers,
      ...usersByGroup.others
    ]
  }

  getCellClassNames = (): { [columnKey: string]: string } => {
    const { classes } = this.props
    const columns = this.getColumns()
    return columns.reduce((result: { [columnKey: string]: string }, column: TVDSimpleDataTableColumn) => ({
      ...result,
      [column.key]: classes.alignLeft
    }), {})
  }

  openAddAccessRightModal = (): void => {
    const {
      t,
      estimateId,
      buildingId,
      dispatchOpenAddAccessRightModal,
      realEstateId
    } = this.props
    const { rows } = this.state
    const content = {
      type: MODAL_TYPE_ADD_ACCESS_RIGHT_MODAL,
      disablePadding: true,
      title: t('addAccessRightModal._NEW_ACCESS_RIGHT_'),
      index: 1400,
      contentProps: {
        estimateId,
        buildingId,
        onAddAccessRightSuccessful: () => { this.getEstimatesPermissions() },
        defaultIsLoading: false,
        usersToExclude: rows.map((row: Row) => row[0].id),
        realEstateId
      }
    }
    dispatchOpenAddAccessRightModal(content, MODAL_TYPE_ADD_ACCESS_RIGHT_MODAL)
  }

  openUserModal = (user: Object): void => {
    const { disableEditUserInformation, dispatchOpenUserModal } = this.props
    const content = {
      type: MODAL_TYPE_USER_MODAL,
      index: 10,
      containerType: 'dialog',
      estimateDescription: user.text,
      userId: user.id,
      patchUserSuccessCallBack: this.getEstimatesPermissions,
      disableEdit: disableEditUserInformation
    }
    dispatchOpenUserModal(content, MODAL_TYPE_USER_MODAL)
  }

  openUserGroupModal = (): void => {
    // TODO
  }

  openInfoModal = (row: UserRow): void => {
    switch (row.type) {
      case USER:
        this.openUserModal(row)
        break
      case USER_GROUP:
        this.openUserGroupModal()
        break
      default:
        break
    }
  }

  getHamburgerMenuItems = (userId: string): Array<TVDMenuItem> | null => {
    const {
      t,
      classes,
      estimateId,
      dispatchBuildConfirmDeleteModal,
      dispatchCloseConfirmationModal
    } = this.props

    const {
      rows
    } = this.state

    const menuItems: Array<TVDMenuItem> = [
      {
        localizedName: t('calculationInformation._DELETE_ACCESS_RIGHT_'),
        testId: 'ContextMenuItem-remove',
        onClick: () => {
          // description object containing descrpition cell text, subject id and type information
          const subjectInfo = rows.find((row: Row) => row[0].id === userId)?.[0]

          if (typeof subjectInfo === 'object' && subjectInfo.id && subjectInfo.text) {
            const content = {
              onSave: () => {
                deleteEstimatesPermissionsWithEstimateIdRequest(
                  { body: [{ subjectId: subjectInfo.id, permissions: [MANAGER, READ, WRITE] }] },
                  {},
                  () => { this.getEstimatesPermissions() },
                  null,
                  { estimateId }
                )
              },
              type: CONFIRMATION_MODAL,
              message: [
                t('calculationInformation._CONFIRM_DELETE_ACCESS_RIGHT_'),
                <span className={classes.highlightedMessage}>{subjectInfo.text}</span>
              ],
              saveButtonText: 'buttons._DELETE_',
              onClose: () => dispatchCloseConfirmationModal(),
            }
            dispatchBuildConfirmDeleteModal(content)
          } else console.error('Cannot execute delete request: no subject id or text found')
        }
      }]
    return menuItems
  }

  getUserGroupIcon = (subject: Object): React$Element<'div'> => {
    const { classes } = this.props
    return (
      <div className={classes.groupIcon}>
        <InfoPopover
          id={`${subject.value}-InfoPopover`}
          icon={{
            name: 'group',
            size: '20px',
            classes: classes.groupIcon
          }}
          infoData={{
            userGroupId: subject.id,
            type: ACCESS_CONTROL_USER_GROUP
          }} />
      </div>
    )
  }

  privateCheckBoxConfirmationDialog = () => {
    const {
      t,
      dispatchCloseConfirmationModal,
      dispatchPrivateCheckBoxConfirmationModal,
      estimateId
    } = this.props

    const { isPrivate } = this.state

    const contentDefault = {
      type: CONFIRMATION_MODAL,
      onClose: () => dispatchCloseConfirmationModal()
    }

    const contentPrivate = {
      ...contentDefault,
      onSave: () => {
        putEstimatesPermissionsWithEstimateIdRequest(
          {
            query: { resetPermissions: 'private' }
          },
          {},
          () => {
            this.setState({ isPrivate: true })
          },
          null,
          { estimateId }
        )
      },
      title: 'settings._IS_PRIVATE_',
      message: t('settings._PRIVATE_CALCULATION_'),
      saveButtonText: 'buttons._CONFIRM_PRIVATE_',

    }

    const contentPublic = {
      ...contentDefault,
      onSave: () => {
        putEstimatesPermissionsWithEstimateIdRequest(
          {
            query: { resetPermissions: 'public' }
          },
          {},
          () => {
            this.setState({ isPrivate: false })
          },
          null,
          { estimateId }
        )
      },
      title: 'settings._IS_PUBLIC_',
      message: t('settings._PUBLIC_CALCULATION_'),
      saveButtonText: 'buttons._CONFIRM_PUBLIC_',
    }

    dispatchPrivateCheckBoxConfirmationModal(!isPrivate ? contentPrivate : contentPublic)
  }

  isPrivateCheckBox = (): React$Element<'div'> => {
    const {
      classes,
      t,
      testId
    } = this.props

    return (
      <div className={classes.checkboxWrapper}>
        <Feature name={FEATURE_IS_ESTIMATE_PRIVATE_CHECKBOX}>
          <CheckBox
            testid={`checkbox-${IS_PRIVATE}`}
            checked={this.state.isPrivate}
            name={IS_PRIVATE}
            onChange={() => {
              this.privateCheckBoxConfirmationDialog()
              }} />
          <div className={classes.label}>{t('calculationInformation._IS_PRIVATE_')}</div>
          <div data-visible_on_hover className={classes.infoPopover}>
            <InfoPopover
              infoData={{
              propertyName: IS_PRIVATE,
              type: ESTIMATE_IS_PRIVATE_INFO
            }}
              id={`${testId}-${IS_PRIVATE}`} />
          </div>
        </Feature>
      </div>
    )
  }

  getHamburgerMenu = (userId: string): React$Element<HamburgerMenu> =>
    <HamburgerMenu items={this.getHamburgerMenuItems(userId)} visibleOnHoverId='hamburgerMenu' />

  render = (): React$Element<'div'> => {
    const { classes, t, estimateId } = this.props
    const { rows, isLoading, isPrivate } = this.state

    return (
      <div className={classes.wrapper}>
        {isLoading && <Spinner />}
        <div className={classes.addAccessRightButtonContainer}>
          <div className={classes.isPrivateWrapper}>
            {this.isPrivateCheckBox()}
          </div>
          {isPrivate && (
          <TextButton text={t('calculationInformation._ADD_ACCESS_RIGHT_')} onClick={this.openAddAccessRightModal} />
          )}
        </div>
        <div>
          <SimpleDataTable
            rows={isPrivate ? rows.filter((row: Row): boolean => !row[0].isOwner) : rows}
            headerCellInitialWidth={207}
            cellClassNames={this.getCellClassNames()}
            fullWidthPusher
            wrappedCellContents={{
            index0: ({ content }: TVDSimpleDataTableWrappedCellCallbackParameters): React$Element<'div'> => {
              const isContentObj = typeof content === 'object'
              const text = isContentObj ? content.text : content
              const includeAdornment = isContentObj ? content.adornment : false

              return (
                <div className={classes.descriptionWrapper}>
                  <DescriptionCell
                    adornment={includeAdornment ? this.getUserGroupIcon(content) : null}
                    paddingLeft='32px'
                    onClick={() => (isPrivate ? this.openInfoModal(content) : null)}
                    text={text}
                    fontSize='14px'
                    highlighted
                    disabled={!isPrivate} />
                  {isPrivate &&
                  <div className={classes.hamburgerWrapper} data-visible_on_hover>{ this.getHamburgerMenu(content.id) }</div>
                  }
                </div>
              )
            },
            index2: ({ content }: TVDSimpleDataTableWrappedCellCallbackParameters) => {
              const translatedPermission = () => [...PERMISSION_ORDER].map((permission: string) => ({
                value: permission,
                localizedName: t(`permissions._${permission.toUpperCase()}_`),
              }))


              const handleDropdownChange = (newHighestPermission: string) => {
                deleteEstimatesPermissionsWithEstimateIdRequest(
                  {
                    body: [{
                      subjectId: content.userId,
                      permissions: [MANAGER, WRITE, READ]
                    }]
                  },
                  {},
                  () => {
                    let permissionToBeAdded = []
                    switch (newHighestPermission) {
                      case READ: {
                        permissionToBeAdded = [READ]
                        break
                      }

                      case WRITE: {
                        permissionToBeAdded = [READ, WRITE]
                        break
                      }

                      case MANAGER: {
                        permissionToBeAdded = [READ, WRITE, MANAGER]
                        break
                      }

                      case OWNER: {
                        permissionToBeAdded = []
                        break
                      }

                      default: {
                        console.error(`Could not get permissions to add with selection ${content.highestPermission}`)
                        break
                      }
                    }

                    postEstimatesPermissionsWithEstimateIdRequest(
                      {
                        body: [{ subjectId: content.userId, permissions: permissionToBeAdded }]
                      },
                      {},
                      () => { this.getEstimatesPermissions() },
                      null,
                      { estimateId }
                    )
                  },
                  null,
                  { estimateId }
                )
              }

              return (
                <div className={classes.permissionColumn}>
                  {isPrivate &&
                    <DropdownMenu
                      minimalist
                      items={translatedPermission()}
                      defaultValue={content.highestPermission}
                      required
                      onChange={(value: string) => {
                        handleDropdownChange(value)
                      }}
                      id='test-id' />
                  }
                  <span>{!isPrivate ? this.getTranslatedPermissions([content.highestPermission]) : ''}</span>
                </div>
              )
            }
          }}
            columns={this.getColumns()} />
        </div>
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch: Function) => ({
  dispatchOpenUserModal: (content: Object, id: string) => { dispatch(openModal(content, id)) },
  dispatchOpenAddAccessRightModal: (content: Object, id: string) => { dispatch(openModal(content, id)) },
  dispatchCloseConfirmationModal: () => { dispatch(closeModal(CONFIRMATION_MODAL)) },
  dispatchBuildConfirmDeleteModal: (content: Object) => { dispatch(openModal(content, CONFIRMATION_MODAL)) },
  dispatchPrivateCheckBoxConfirmationModal: (content: Object) => { dispatch(openModal(content, CONFIRMATION_MODAL)) }
})


export default compose(
  connect(null, mapDispatchToProps),
  withStyles(styles),
  withTranslation('translations'),

)(EstimateAccessRights)
