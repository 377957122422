// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withStyles } from '@material-ui/core'
import { withTranslation } from 'react-i18next'
import { v4 } from 'uuid'

import ModalForm from '../../common/ModalForm/ModalForm'
import LabeledInput from '../../common/LabeledInput/LabeledInput'

import { closeModal, buildConfirmationModal } from '../../../actions/modals'
import { commonIgnoredCharacters } from '../../../constants/validationConstants'

const styles = ({
  container: {
    width: '100%',
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '7px 32px 24px 0px'
  }
})

type DispatchProps = {|
  confirm: (Object) => void, // function to create confirmationDialog if needed
  onClose: () => void, // function bound to cancel / close button in FooterButtons
|}

type Props = {|
  ...DispatchProps,
  calculation: string, // calculation ID
  id: string, // id
  onSave: Function,
  row: Object,
  t: Function, // translate
  testId: string, // test id
|}

type State = {|
  quantity: string, // string from quantity field
  quantityValid: boolean, // indicates if quantity is valid
  formModified: boolean, // indicates if form has been modified
  formValid: boolean, // status of form validity for the SAVE button
  name: string, // string from name field
  nameValid: boolean, // indicates if name is valid
  price: string, // string from price field
  priceValid: boolean, // indicates if price is valid
|}

export class EquipmentModalContainer extends Component<Props, State> {
  state = {
    quantity: '',
    quantityValid: true,
    formModified: false,
    formValid: true,
    name: '',
    nameValid: true,
    price: '',
    priceValid: true,
  }

  checkValidity = () => {
    const {
      nameValid, quantityValid, priceValid, formModified
    } = this.state
    return Boolean(nameValid && quantityValid && priceValid && formModified)
  }

  handleUserInput = (event: SyntheticInputEvent<any>, stateKey: string) => {
    this.handleChange(stateKey, event.target.value)
  }

  handleChange = (stateKey: string, eventValue: string) => {
    this.setState({
      [stateKey]: eventValue,
      formModified: true
    })
  }

  handleSave = (formValues: Object) => {
    const {
      onSave,
      onClose,
      t,
      row: {
        parentId,
        type,
      }
    } = this.props

    const addedRow = {
      parentId,
      id: v4(),
      type,
      columnData: {
        Quantity: formValues.quantity,
        Description: formValues.name,
        UnitPrice: formValues.price,
      },
      columnUnits: {
        Quantity: t('equipmentModal._UNIT_')
      }
    }
    onSave(addedRow)
    onClose()
  }

  handleClose = () => {
    if (this.state.formModified) {
      this.props.confirm({ onSave: this.props.onClose })
    } else {
      this.props.onClose()
    }
  }

  name(): React$Element<typeof LabeledInput> {
    const { t, testId } = this.props
    const stateKey = 'name'
    const inputProps: TVDLabeledInput = {
      dataType: 'string',
      value: this.state[stateKey],
      handleChange: (event: SyntheticInputEvent<any>) => this.handleUserInput(event, stateKey),
      size: 'XL',
    }
    return (
      <LabeledInput
        focused
        testId={`${testId}-${stateKey}`}
        {...inputProps}
        ignoreCharacters={commonIgnoredCharacters}
        label={t('equipmentModal._NAME_')}
        id={`${this.props.id}-${stateKey}`}
        isValidCb={(isValid: boolean) => this.setState({ [`${stateKey}Valid`]: isValid })}
        required />
    )
  }

  quantity(): React$Element<typeof LabeledInput> {
    const { t, testId } = this.props
    const stateKey = 'quantity'
    const inputProps: TVDLabeledInput = {
      dataType: 'number',
      value: this.state[stateKey],
      handleChange: (event: SyntheticInputEvent<any>) => this.handleUserInput(event, stateKey),
      size: 'XL',
    }
    return (
      <LabeledInput
        testId={`${testId}-${stateKey}`}
        {...inputProps}
        label={t('equipmentModal._QUANTITY_')}
        id={`${this.props.id}-${stateKey}`}
        isValidCb={(isValid: boolean) => this.setState({ [`${stateKey}Valid`]: isValid })}
        number
        required />
    )
  }

  price(): React$Element<typeof LabeledInput> {
    const { t, testId } = this.props
    const stateKey = 'price'
    const inputProps: TVDLabeledInput = {
      dataType: 'number',
      value: this.state[stateKey],
      handleChange: (event: SyntheticInputEvent<any>) => this.handleUserInput(event, stateKey),
      size: 'XL',
    }
    return (
      <LabeledInput
        testId={`${testId}-${stateKey}`}
        {...inputProps}
        label={t('equipmentModal._PRICE_')}
        id={`${this.props.id}-${stateKey}`}
        isValidCb={(isValid: boolean) => this.setState({ [`${stateKey}Valid`]: isValid })}
        number
        required />
    )
  }

  inputs(): LabeledInput {
    return [this.name(), this.quantity(), this.price()]
  }

  modalForm(): React$Element<typeof ModalForm> {
    const {
      nameValid, quantityValid, priceValid, formValid, formModified, ...formValues
    } = this.state

    return (<ModalForm
      testId='createUserDefinedEquipmentModal'
      items={this.inputs()}
      onSave={() => this.handleSave(formValues)}
      onClose={this.handleClose}
      valid={this.checkValidity()}
      modified={this.state.formModified}
      saveButtonText='buttons._SAVE_' />)
  }

  render(): React$Element<ModalForm> {
    return this.modalForm()
  }
}

type ownProps = {
  id: string
}

const mapStateToProps = ({ app: { calculation } }: Object): Object => ({ calculation })

const mapDispatchToProps = (dispatch: Function, { id }: ownProps) => ({
  confirm: (content: Object) => dispatch(buildConfirmationModal(content)),
  onClose: () => dispatch(closeModal(id)),
})

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
  withTranslation('translations')
)(EquipmentModalContainer)

