// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.

import React, { Component } from 'react'
import { compose } from 'redux'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import {
  patchGroupingScheduleFunctionGroupsWithItemIdRequest,
  patchGroupingScheduleFunctionalSectorGroupsWithItemIdRequest,
  patchGroupingScheduleProcessGroupsWithItemIdRequest,
} from '../../../utils/generated-api-requests/wop'
import {
  FUNCTIONALSECTORGROUP,
  FUNCTIONGROUP,
  PROCESSGROUP,
} from '../../../constants/contentTypes'
import { DESCRIPTION } from '../../../constants/attributes'
import SingleInputModal from '../../common/SingleInputModal/SingleInputModal'
import { closeModal } from '../../../actions/modals'
import { postPolling } from '../../../actions/postPolling'

type ReceivedProps = {|
  modalId: string, // id of the modal that can be found in Store
  listStoreId: string, // id of the list that the modal will be editing. Used to update list after successful PATCH.
  listItem: TVDWOPListItem, // list item retrieved from Store via listId and listItemId
|}

type DispatchProps = {|
  dispatchCloseModal: () => void, // closes the current modal by removing the configurations from Store
  dispatchPostPolling: () => void, // start post polling
|}

type Props = {
  ...ReceivedProps,
  ...DispatchProps,
  t: Function, // translation function
}

export class WOPGroupingRenameModalContainer extends Component<Props> {
  getDefaultValue = (): string => {
    const {
      listItem: {
        columnData: {
          [DESCRIPTION]: description
        }
      }
    } = this.props
    return description
  }

  onSaveSuccessCb = () => {
    const { dispatchCloseModal, dispatchPostPolling } = this.props
    dispatchPostPolling()
    dispatchCloseModal()
  }

  onSave = (newDescription: string) => {
    const { listStoreId, listItem } = this.props
    const { type, wopItemId, } = listItem
    const requestArguments = {
      path: { itemId: wopItemId },
      body: {
        [DESCRIPTION]: newDescription
      }
    }
    const modifiedListItem = {
      ...listItem,
      columnData: {
        [DESCRIPTION]: newDescription
      }
    }

    const GARArguments = [
      requestArguments,
      { listId: listStoreId, modifiedListItem, mergeOptions: { addListLastUpdated: true } },
      this.onSaveSuccessCb
    ]
    switch (type.toUpperCase()) {
      case FUNCTIONALSECTORGROUP: {
        patchGroupingScheduleFunctionalSectorGroupsWithItemIdRequest(...GARArguments)
        break
      }
      case PROCESSGROUP: {
        patchGroupingScheduleProcessGroupsWithItemIdRequest(...GARArguments)
        break
      }
      case FUNCTIONGROUP: {
        patchGroupingScheduleFunctionGroupsWithItemIdRequest(...GARArguments)
        break
      }
      default: {
        console.error(`Renaming not supported for type ${type}`)
        break
      }
    }
  }

  render(): React$Element<typeof SingleInputModal> {
    const {
      t,
      dispatchCloseModal,
    } = this.props

    return (
      <SingleInputModal
        defaultValue={this.getDefaultValue()}
        description={t('singleInputModal._DESCRIPTION_')}
        saveButtonText={t('buttons._RENAME_')}
        onSave={this.onSave}
        onClose={dispatchCloseModal} />
    )
  }
}

const mapDispatchToProps = (dispatch: Function, { modalId }: ReceivedProps): DispatchProps => ({
  dispatchCloseModal: () => { dispatch(closeModal(modalId)) },
  dispatchPostPolling: () => { dispatch(postPolling()) }
})

export default compose(
  withTranslation('translations'),
  connect(null, mapDispatchToProps),
)(WOPGroupingRenameModalContainer)
