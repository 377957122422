// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.

import { CLOSE_WIDGET_SUCCESSFUL } from '../actions/widgets'
import {
  removeComponentPreferences,
  removeAllComponentPreferences,
  setOpenRowIdsComponentPreference
} from '../actions/componentPreferences'
import { spacesEquipmentComponentPreferencesId } from '../components/containers/SpacesEquipmentTabMFEContainer/SpacesEquipmentTabMFEContainer'
import { SPACE } from '../constants/contentTypes'
import { TOGGLE_LIST_ITEM_OPEN } from '../actions/list'

export default (store: Object) => (next: Function) => (action: TVDAction) => {
  const { dispatch, getState } = store
  if (action.type === TOGGLE_LIST_ITEM_OPEN) {
    const {
      payload: {
        listItemId,
        listId,
        filteredListItems,
        componentPreferencesId
      }
    } = action
    const listItemsKey = filteredListItems ? 'filteredListItems' : 'listItems'
    const state = getState()
    const listItems: TVDListItems = state.list[listId]?.[listItemsKey]
    const listItem = listItems[listItemId]
    const storeListItemIsOpenStatuses = Object.keys(listItems).reduce((isOpenStatuses: { [key: string]: boolean }, storeListItemId: string) => ({
      ...isOpenStatuses,
      [storeListItemId]: listItems[storeListItemId].isOpen
    }), {})
    const { openRowIds = {} } = state.componentPreferences?.[componentPreferencesId] || {}

    if (listItem) {
      const { isOpen } = listItem
      const newOpenRowIds = {
        ...storeListItemIsOpenStatuses,
        ...openRowIds
      }
      if (isOpen) {
        newOpenRowIds[listItemId] = false
      } else {
        newOpenRowIds[listItemId] = true
      }
      if (Object.keys(newOpenRowIds).length > 0) {
        dispatch(setOpenRowIdsComponentPreference(componentPreferencesId, newOpenRowIds))
      }
    }
  }

  if (action.type === CLOSE_WIDGET_SUCCESSFUL) {
    const {
      payload: {
        widgetId
      }
    } = action
    const {
      // the empty initialization when destruturing the widgetType is to ensure the destruturing
      // not breaking the application due to a widgetId which could not be found from the widgets
      widgets: { [widgetId]: { widgetType } = {} },
    } = getState()

    if (widgetType) {
      dispatch(removeComponentPreferences(widgetType))
    }
    if (widgetType === SPACE) {
      dispatch(removeComponentPreferences(spacesEquipmentComponentPreferencesId))
    }
  }

  if (action && action.type === '@@router/LOCATION_CHANGE') {
    dispatch(removeAllComponentPreferences())
  }

  if (action) {
    next(action)
  }
}

