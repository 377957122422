// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.

// Keys with the prefix of "_"
const HAL_KEYS = {
  HAL_ITEMS: '_items',
  HAL_LINKS: '_links',
  HAL_EMBEDDED: '_embedded',
}
// All keys
export const ALL_KEYS = {
  ...HAL_KEYS,
  BUILDINGS: 'buildings',
  COLUMN_DATA: 'columnData',
  COLUMN_UNITS: 'columnUnits',
  COLUMNS: 'columns',
  ENUM: 'enum',
  ENUMERAL: 'enumeral',
  ESTIMATES: 'estimates',
  HREF: 'href',
  ID: 'id',
  ITEMS: 'items',
  PARENTID: 'parentId',
  PROPERTIES: 'properties',
  SELF: 'self',
  TYPE: 'type',
  VALUES: 'values',
  STORIES: 'stories',
  INFO: 'info',
  DRIVERS: 'drivers',
  USERS: 'users',
  USER_GROUPS: 'usergroups',
  PERMISSIONS: 'permissions',
  LICENSES: 'licenses',
  STATUS: 'status'
}


type HALKeys = $Values<typeof ALL_KEYS>


type cbType = Function | null | typeof undefined

type callBackTypes = {
  onlyData?: boolean, // returns only the data directly under response when set true
  key?: HALKeys, // key string that is used to get a key from "data"
  cb?: cbType, // function applied to data either to all of the data or each of the data's items if "useMapCb" is true
  isEmbedded?: boolean, // if the "key" is found under embedded key ("_embedded")
  useMapCb?: boolean, // whether "mapCb" should try to loop the data found with key and apply "cb" to each item
  data: Object, // the object from which data is retrieved with "key"
}


const mapCb = (dataArray: Array<any> | Object, cb: Function): Array<any> => {
  if (Array.isArray(dataArray)) {
    return dataArray.map((value: any, index: number) => cb(value, index))
  }
  console.warn(`HALParser: Trying to loop and apply custom callback for type ${typeof dataArray}`)
  return []
}

const callBack = ({
  key,
  cb,
  isEmbedded = false,
  useMapCb = false,
  data,
  onlyData = false,
}: callBackTypes): any => {
  if (onlyData) return data
  const { HAL_EMBEDDED } = HAL_KEYS
  const keyData = isEmbedded ? data[HAL_EMBEDDED]?.[key] : data[key]
  if (typeof cb === 'function') {
    if (useMapCb) {
      return mapCb(keyData, (el: any, i: number) => cb(el, i))
    }
    return cb(keyData)
  }
  return keyData
}

const getItems = ({ data }: Object, cb: cbType) => {
  const { ITEMS } = ALL_KEYS
  return callBack({
    data,
    key: ITEMS,
    cb,
    isEmbedded: true
  })
}

const getItemsMap = ({ data }: Object, cb: cbType) => {
  const { ITEMS } = ALL_KEYS
  return callBack({
    data,
    key: ITEMS,
    cb,
    isEmbedded: true,
    useMapCb: true,
  })
}

const getEmbedded = (data: Object, cb: cbType) => {
  const { HAL_EMBEDDED } = ALL_KEYS
  return callBack({
    data,
    key: HAL_EMBEDDED,
    cb,
  })
}

const getProperties = ({ data }: Object, cb: cbType): Array<TVDSchemaValueProperty> => {
  const { PROPERTIES } = ALL_KEYS
  return callBack({
    data,
    key: PROPERTIES,
    cb,
    isEmbedded: true
  })
}

const getPropertiesMap = ({ data }: Object, cb: cbType): Object => {
  const { PROPERTIES } = ALL_KEYS
  return callBack({
    data,
    key: PROPERTIES,
    cb,
    useMapCb: true,
    isEmbedded: true
  })
}

const getColumnData = (data: Object, cb: cbType) => {
  const { COLUMN_DATA } = ALL_KEYS
  return callBack({
    data,
    key: COLUMN_DATA,
    cb,
  })
}

const getColumnUnits = (data: Object, cb: cbType) => {
  const { COLUMN_UNITS } = ALL_KEYS
  return callBack({
    data,
    key: COLUMN_UNITS,
    cb,
  })
}

const getLinksEnumHref = (data: Object) => {
  const { HAL_LINKS, ENUM, HREF } = ALL_KEYS
  return callBack({
    data,
    key: HAL_LINKS,
    cb: ({ [ENUM]: { [HREF]: href } }: Object) => href
  })
}

const getLinksItemsHref = (data: Object) => {
  const {
    HAL_LINKS,
    HREF,
    ITEMS,
  } = ALL_KEYS
  return callBack({
    data,
    key: HAL_LINKS,
    cb: (cbData: Object) => ((cbData && cbData[ITEMS]) ? cbData[ITEMS][HREF] : null)
  })
}

const getLinksSelfHref = (data: Object) => {
  const { HAL_LINKS, SELF, HREF } = ALL_KEYS
  return callBack({
    data,
    key: HAL_LINKS,
    cb: ({ [SELF]: { [HREF]: href } }: Object) => href
  })
}

const getEnumeral = ({ data }: Object) => {
  const { ENUMERAL } = ALL_KEYS
  return callBack({
    data,
    isEmbedded: true,
    key: ENUMERAL,
  })
}

const getEstimates = ({ data }: Object) => {
  const { ESTIMATES } = ALL_KEYS
  return callBack({
    data,
    isEmbedded: true,
    key: ESTIMATES,
  })
}

const getBuildings = (data: Object) => {
  const { BUILDINGS } = ALL_KEYS
  return callBack({
    data,
    isEmbedded: true,
    key: BUILDINGS,
  })
}

const getLinksColumnHref = (data: Object) => {
  const { HAL_LINKS, COLUMNS, HREF } = ALL_KEYS
  return callBack({
    data,
    key: HAL_LINKS,
    cb: ({ [COLUMNS]: { [HREF]: href } }: Object) => href
  })
}

const getLinksItems = (data: Object) => {
  const { HAL_LINKS, ITEMS } = ALL_KEYS
  return callBack({
    data,
    key: HAL_LINKS,
    cb: (result: Object) => (result ? result[ITEMS] : null)
  })
}

const getValues = ({ data }: Object) => {
  const { VALUES } = ALL_KEYS
  return callBack({
    data,
    isEmbedded: true,
    key: VALUES
  })
}

const getStories = ({ data }: Object) => {
  const { STORIES } = ALL_KEYS
  return callBack({
    data,
    isEmbedded: true,
    key: STORIES
  })
}

const getData = ({ data }: Object) => callBack({
  data,
  onlyData: true
})

const getLinksInfo = (data: Object) => {
  const { HAL_LINKS, INFO } = ALL_KEYS
  return callBack({
    data,
    key: HAL_LINKS,
    cb: (result?: Object) => (result ? result[INFO] : null)
  })
}

const getDrivers = (data: Object) => {
  const { DRIVERS } = ALL_KEYS
  return callBack({
    data,
    isEmbedded: true,
    key: DRIVERS
  })
}

const getUserGroups = (data: TVDUser): Array<TVDEnum> => {
  const { USER_GROUPS } = ALL_KEYS
  return callBack({
    data,
    key: USER_GROUPS,
    useMapCb: true,
    isEmbedded: true
  })
}
const getUserGroupEnums = (data: TVDUser): Array<TVDEnum> => {
  const { USER_GROUPS } = ALL_KEYS
  return callBack({
    data,
    key: USER_GROUPS,
    useMapCb: true,
    isEmbedded: true
  })
}

const getAccountStatus = (data: TVDEnum): Array<TVDAccountStatus> => {
  const { STATUS } = ALL_KEYS
  return callBack({
    data,
    key: STATUS,
    isEmbedded: true
  })
}

const getLinks = (data: Object): Object => {
  const { HAL_LINKS } = ALL_KEYS
  return callBack({
    data,
    key: HAL_LINKS,
  })
}


export default {
  getColumnData,
  getColumnUnits,
  getEmbedded,
  getEnumeral,
  getEstimates,
  getItems,
  getItemsMap,
  getLinksEnumHref,
  getLinksItemsHref,
  getLinksSelfHref,
  getProperties,
  getPropertiesMap,
  getLinksColumnHref,
  getBuildings,
  getLinksItems,
  getValues,
  getStories,
  getData,
  getLinksInfo,
  getDrivers,
  getUserGroups,
  getUserGroupEnums,
  getAccountStatus,
  getLinks
}
