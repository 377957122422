// @flow
// Copyright © 2010–2022 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.
import React, { useEffect, useCallback, useRef } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useSelector, useStore } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { loadMFE } from '../../../utils/MFEUtils'
import { API_BASE_URL, ALLOW_WITH_CREDENTIALS } from '../../../constants/envConstants'
import { USAGE_AND_MAINTENANCE } from '../../../constants/moduleConstants'
import { getEstimateHistoryObj } from '../../../utils/urlUtil'
import { headerHeight } from '../../../components/common/Header/Header'
import { openGenericErrorModal } from '../../../utils/modalUtils'
import ModalsContainer from '../../../components/containers/ModalsContainer/ModalsContainer'
import { getIsFeatureEnabledInSet } from '../../../utils/features'
import { FEATURE_LICENCE_TYPE_CHIP } from '../../../constants/features'
import { setActiveEstimate } from '../../../actions/activeEstimate'
import { getWSConnection } from '../../../utils/websocketUtils'
import { refreshUserAccessToken } from '../../../actions/user'

const useStyles = makeStyles(({ palette }: TVDTheme) => ({
  root: {
    height: '100%',
    width: '100%',
    paddingTop: headerHeight,
    paddingRight: '8px',
    background: palette.gray20
  }
}))

const UsageAndMaintenance = (): React$Element<typeof React.Fragment> => {
  const classes = useStyles()
  const { dispatch } = useStore()
  const history = useHistory()
  const moduleOnUnmountRef = useRef<$PropertyType<TVDMFEFunctionality, 'onUnmount'>>()
  const {
    realEstateId,
    accessToken,
    haahtelaApiUserToken,
    userId,
    languageCode,
    selectedAccountId,
    realEstateName,
    isEstimateLockedToCurrentUser,
    licenseType,
    activeEstimate: activeUMAEstimate
  } = useSelector(({
    app,
    user,
    activeEstimate
  }: TVDReduxStore): {|
    languageCode: $PropertyType<TVDApplicationStore, 'languageCode'>,
    selectedAccountId: $PropertyType<TVDApplicationStore, 'selectedAccountId'>,
    realEstateId: $PropertyType<TVDApplicationStore, 'realEstateId'>,
    realEstateName: $PropertyType<TVDApplicationStore, 'realEstateName'>,
    accessToken: $PropertyType<TVDUserAuthorization, 'accessToken'>,
    haahtelaApiUserToken: $PropertyType<TVDUserClaims, 'haahtelaApiUserToken'>,
    userId: $PropertyType<TVDUserClaims, 'userId'>,
    isEstimateLockedToCurrentUser: $PropertyType<TVDApplicationStore, 'isEstimateLockedToCurrentUser'>,
    licenseType: $PropertyType<TVDApplicationStore, 'licenseType'>,
    activeEstimate: TVDActiveEstimateStore
  |} => ({
    languageCode: app.languageCode,
    selectedAccountId: app.selectedAccountId,
    realEstateId: app.realEstateId,
    realEstateName: app.realEstateName,
    accessToken: user.authorization.accessToken,
    haahtelaApiUserToken: user.claims.haahtelaApiUserToken,
    userId: user.claims.userId,
    isEstimateLockedToCurrentUser: app.isEstimateLockedToCurrentUser,
    licenseType: app.licenseType,
    activeEstimate
  }))

  const navigateToUsageManagementEstimate = useCallback((estimateId: string, beforeNavigation?: () => void): void => {
    if (beforeNavigation) {
      beforeNavigation()
    }
    history.push(getEstimateHistoryObj({
      app: USAGE_AND_MAINTENANCE,
      realEstateName,
      accountId: selectedAccountId,
      estimateId,
      realEstateId
    }))
  }, [
    history,
    realEstateName,
    selectedAccountId,
    realEstateId
  ])

  const WSConnection = getWSConnection()

  useEffect(() => {
    loadMFE('usage_maintenance', 'usage-maintenance-module', (module: TVDMFEFunctionality): void => {
      if (
        languageCode &&
        accessToken &&
        haahtelaApiUserToken &&
        userId &&
        selectedAccountId &&
        realEstateId &&
        activeUMAEstimate
      ) {
        moduleOnUnmountRef.current = module.onUnmount
        const setActiveEstimateToStore = (estimate: TVDCalculation): void => {
          dispatch(setActiveEstimate(estimate))
        }
        const render = module.render || null
        if (render) {
          render(
            'usage-maintenance',
            {
              APIBaseURL: API_BASE_URL,
              languageCode,
              accessToken,
              haahtelaApiUserToken,
              userId,
              selectedAccountId,
              realEstateId,
              isEstimateLockedToCurrentUser,
              navigateToUsageManagementEstimate,
              realEstateName,
              openGenericErrorModal,
              licenseType: getIsFeatureEnabledInSet(FEATURE_LICENCE_TYPE_CHIP) ? licenseType : null,
              activeEstimate: activeUMAEstimate,
              ALLOW_WITH_CREDENTIALS,
              setActiveEstimateToStore,
              WSConnection,
              refreshUserAccessToken
            }
          )
        }
      }
    })
    return () => {
      if (moduleOnUnmountRef.current) {
        moduleOnUnmountRef.current()
      }
    }
  }, [
    languageCode,
    accessToken,
    haahtelaApiUserToken,
    userId,
    selectedAccountId,
    realEstateId,
    navigateToUsageManagementEstimate,
    isEstimateLockedToCurrentUser,
    realEstateName,
    licenseType,
    activeUMAEstimate,
    dispatch,
    WSConnection
  ])
  return (
    <>
      <div className={classes.root} id='usage-maintenance' />
      <ModalsContainer />
    </>
  )
}

export default UsageAndMaintenance

