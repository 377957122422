// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.

import * as React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { getIsFeatureDisabled, getEnabledFeatures } from '../../../utils/features'

type MapProps = {|
  features: TVDFeatureSettings, // feature settings from Store e.g what are disabled features
  isAccountAdministrator: boolean, // if used has the privileges to use the application as an account administrator
|}

type Props = {|
  ...MapProps,
|}

const FeaturesHOC = (WrappedComponent: React.AbstractComponent<any>) => class extends React.Component<Props> {
  getIsFeatureDisabled(featureName: string): boolean {
    const { features } = this.props
    return getIsFeatureDisabled(featureName, features.disabled)
  }

  getEnabledFeatures(list: Array<Object>, key?: string): Array<Object> {
    // To ignore this function when unit testing, define "props = { ...props, features: { getEnabledFeatures: feature => feature } }"
    const { features, isAccountAdministrator } = this.props
    return getEnabledFeatures(
      list,
      [
        ...features.disabled,
        ...(isAccountAdministrator ? [] : features.accountAdministrator)
      ],
      key
    )
  }

  render(): React.Node {
    const { features } = this.props
    const featureProps: TVDFeatureHOCProps = {
      ...features,
      getIsFeatureDisabled: this.getIsFeatureDisabled.bind(this),
      getEnabledFeatures: this.getEnabledFeatures.bind(this),
    }
    const newProps = {
      ...this.props,
      features: featureProps,
    }
    return <WrappedComponent {...newProps} />
  }
}

const mapStateToProps = ({ app: { features }, user: { isAccountAdministrator } }: TVDReduxStore): MapProps => ({
  features,
  isAccountAdministrator
})

export default compose(connect(mapStateToProps), FeaturesHOC)
