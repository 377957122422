// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.

import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import InputLabel from '@material-ui/core/InputLabel'
import theme from '../../../../../styles/theme'

const styles = ({ palette }: TVDTheme) => ({
  root: {
    ...theme.typography.classes.bodySmall,
    color: palette.dark80,
  },
  focused: {
    '&$focused': {
      color: palette.primary100
    }
  },
  error: {
    '&$error': {
      color: palette.error120
    }
  },
  errorFocused: {
    '&$focused': {
      color: palette.error120
    }
  },
  asterisk: {
    '&$asterisk': {
      color: palette.error120
    }
  },
  inputLabelShrink: {
    ...theme.typography.classes.bodySmall,
    transform: 'scale(1)'
  }
})

type Props = {
  classes: Object, // withStyles object
  error: Boolean, // error state
  required: Boolean, // if the label is in the "required" state
}

export class DropdownLabel extends Component<Props> {
  render(): React$Element<any> {
    // MUI passes its own props that need to be spread here
    const {
      classes,
      error,
      required,
      ...otherProps
    } = this.props

    const LabelClasses = {
      root: classes.root,
      focused: classes.focused,
      asterisk: classes.asterisk,
      shrink: classes.inputLabelShrink

    }

    const errorClasses = {
      root: classes.root,
      focused: classes.errorFocused,
      error: classes.error
    }

    // MUI has a bug where if the shrink property exists on the inputlabel even in 'false' mode it will mess up
    // labels for shrink=false versions as well so it needs to not exist in false- state
    const shrink = error ? { shrink: true } : null

    return (
      <InputLabel
        {...otherProps}
        {...shrink}
        error={error}
        classes={error ? errorClasses : LabelClasses} />
    )
  }
}

export default withStyles(styles)(DropdownLabel)
