// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.
import { dispatch, getState } from '../../store'
import { getSelectedListItemsByType, getModifiedProperties } from '../listUtils'
import { postPolling } from '../../actions/postPolling'
import { SPACE, SPACEGROUP } from '../../constants/contentTypes'
import {
  patchSpaceScheduleSpaceGroupsPropertiesWithEstimateIdRequest,
  patchSpaceScheduleSpacesPropertiesWithEstimateIdRequest
} from '../generated-api-requests/spaces'
import { TVD_PATCH_OPERATION_TYPE_UPDATE } from '../../constants/patchOperationConstants'

export type SaveMultiEditProperties = {|
  listStoreId: string, // id to access list data in redux store
  propertiesStoreId: string, // id to access properties data in redux store
  containerType: string, // information which type of rows are being edited to target correct endpoints on save
  callback?: Function, // function that is called after saving is done
  overwittenSelectedRows?: TVDListItems, // list items that overwrite ones in the store if given
|}

export const saveMultiEditProperties = async ({
  listStoreId,
  propertiesStoreId,
  containerType,
  callback,
  overwittenSelectedRows
}: SaveMultiEditProperties) => {
  const multiEditPatchRequests = {
    [SPACEGROUP.toLowerCase()]: patchSpaceScheduleSpaceGroupsPropertiesWithEstimateIdRequest,
    [SPACE.toLowerCase()]: patchSpaceScheduleSpacesPropertiesWithEstimateIdRequest
  }

  const { properties, list } = getState()
  const { listItems } = list[listStoreId] || {}
  const selectedRows = getSelectedListItemsByType(listItems, containerType)
  const overwrittenSelectedRowsList = overwittenSelectedRows ? Object.values(overwittenSelectedRows) : null

  const requestbody = (overwrittenSelectedRowsList || selectedRows).map((row: Object) => ({
    resourceId: row.id,
    OperationParameters: getModifiedProperties(properties[propertiesStoreId]),
    operationType: TVD_PATCH_OPERATION_TYPE_UPDATE
  }))

  if (requestbody.length > 0) {
    multiEditPatchRequests[containerType]({ body: requestbody }, {}, () => { dispatch(postPolling()) }, {}, { disableRefreshEstimateLock: true })
  }

  if (callback) callback()
}
