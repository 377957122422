// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.

import * as units from './attributes'

// WidgetTypes
export const CARBON_FOOTPRINT = 'CARBON_FOOTPRINT'
export const CREATE_FROM_PRICING = 'CREATE_FROM_PRICING'
export const IMPORT_FROM_CALCULATION = 'IMPORT_FROM_CALCULATION'
export const CREATE_BUILDING_ELEMENTS = 'CREATE_BUILDING_ELEMENTS'
export const BUILDING_ELEMENTS_SCHEDULE = 'BUILDING_ELEMENTS_SCHEDULE'
export const BUILDING_ELEMENTS_TASK = 'BUILDING_ELEMENTS_TASK'
export const EQUIPMENT_REGISTRY = 'EQUIPMENT_REGISTRY'
export const AREA_EQUIPMENT_REGISTRY = 'AREA_EQUIPMENT_REGISTRY'
export const CREATE = 'CREATE_FROM_REGISTRY'
export const CREATE_ACTIVITY = 'CREATE_ACTIVITY'
export const PLANNING = 'PLANNING_MODEL'
export const CREATE_CIRCULATION = 'CREATE_CIRCULATION'
export const CREATE_FACILITY_SERVICES = 'CREATE_FACILITY_SERVICES'
export const CREATE_TECHNICAL_BUILDING_SERVICES = 'CREATE_TECHNICAL_BUILDING_SERVICES'
export const CREATE_PROTECTED_CIRCULATION = 'CREATE_PROTECTED_CIRCULATION'
export const CREATE_ACTION_SPACES = 'CREATE_ACTION_SPACES'
export const LIST = 'LIST'
export const CREATE_FROM_REGISTRY = 'CREATE_FROM_REGISTRY'
export const SPACES_ASSEMBLY = 'SPACES_ASSEMBLY'
export const MULTI_EDIT_SPACE_GROUP_WIDGET = 'MULTI_EDIT_SPACE_GROUP_WIDGET'
export const OTHER_SPACES = 'OTHER_SPACES'
export const CREATE_OTHER_SPACES = 'CREATE_OTHER_SPACES'
export const MULTI_EDIT_SPACE_WIDGET = 'MULTI_EDIT_SPACE_WIDGET'
export const RENOVATION = 'RENOVATION'
export const PROCESSES_AND_ACTIVITIES = 'PROCESSES_AND_ACTIVITIES'
export const GROUPING_VIEW = 'GROUPING_VIEW'
export const ACTIVITY_REGISTRY_WIDGET = 'ACTIVITY_REGISTRY_WIDGET'
export const ACTIVITY_STRUCTURE = 'ACTIVITY_STRUCTURE'

export const FLOOR = 'FLOOR'
export const CALCULATION_PROPERTIES = 'CALCULATION_PROPERTIES'
export const SURFACE_PRICING = 'SURFACE_PRICING'
export const EQUIPMENT_PRICING = 'EQUIPMENT_PRICING'
export const IMPORT_FROM_WOP_CALCULATION = 'IMPORT_FROM_WOP_CALCULATION'
export const WOP_SPACE_SCHEDULE = 'WOP_SPACE_SCHEDULE'
export const ACCESS_CONTROL_USER_GROUP = 'ACCESS_CONTROL_USER_GROUP'
export const ORGANIZATION_ACCOUNT = 'ORGANIZATION_ACCOUNT'
export const SHOW_RESULTS = 'SHOW_RESULTS'

// ModalTypes
export const CREATE_ITEM_MODAL = 'CREATE_ITEM_MODAL'
export const RENAME_MODAL = 'RENAME_MODAL'
export const DELETE_BUILDING_MODAL = 'DELETE_BUILDING_MODAL'
export const MOVE_BUILDING_MODAL = 'MOVE_BUILDING_MODAL'
export const ADD_PRICE_ITEM_TO_ASSEMBLY_MODAL = 'ADD_PRICE_ITEM_TO_ASSEMBLY_MODAL'
export const CONFIRMATION_MODAL: 'CONFIRMATION_MODAL' = 'CONFIRMATION_MODAL'
export const CONFIRMATION_MODAL_PADDED = 'CONFIRMATION_MODAL_PADDED'
export const PROPERTIES_MODAL = 'PROPERTIES_MODAL'
export const UNIT_EDITING_MODAL = 'UNIT_EDITING_MODAL'
export const ATTACH_SPACE_MODAL = 'ATTACH_SPACE_MODAL'
export const IMPORT_FROM_WOP_MODAL = 'IMPORT_FROM_WOP_MODAL'
export const ATTACH_SPACE_GROUP_MODAL = 'ATTACH_SPACE_GROUP_MODAL'
export const WOP_GROUPING_RENAME_MODAL = 'WOP_GROUPING_RENAME_MODAL'
export const WOP_GROUPING_MOVE_MODAL = 'WOP_GROUPING_MOVE_MODAL'
export const OPERATING_PROFILE_SELECT_MODAL = 'OPERATING_PROFILE_SELECT_MODAL'
export const ADD_RENOVATION_PROFILE_MODAL = 'ADD_RENOVATION_PROFILE_MODAL'
export const COPY_RENOVATION_PROFILE_MODAL = 'COPY_RENOVATION_PROFILE_MODAL'

// ColumnTypes
export const FUNCTIONALSECTOR = 'FUNCTIONALSECTOR'
export const FUNCTION = 'FUNCTION'
export const SPACEGROUP = 'SPACEGROUP'
export const SPACE = 'SPACE'

// ListTypes
export const BUILDING_ELEMENTS = BUILDING_ELEMENTS_SCHEDULE
export const SPACES = 'SPACES'
export const TEST_LIST_TYPE = 'TEST_LIST_TYPE'
export const PROCESSES = 'PROCESSES'
export const ACTIVITIES = 'ACTIVITIES'
export const ACCESS_CONTROL_ESTIMATES = 'ACCESS_CONTROL_ESTIMATES'
export const ACCESS_CONTROL_BUILDINGS = 'ACCESS_CONTROL_BUILDINGS'
export const ACCESS_CONTROL_ADD_PERMISSIONS = 'ACCESS_CONTROL_ADD_PERMISSIONS'
export const ACCESS_CONTROL_USER_BUILDINGS = 'ACCESS_CONTROL_USER_BUILDINGS'
export const ACCOUNT_MANAGEMENT_BILLING_INFO = 'ACCOUNT_MANAGEMENT_BILLING_INFO'
export const ACCESS_CONTROL_USER_RIGHTS = 'ACCESS_CONTROL_USER_RIGHTS'
export const ACCESS_CONTROL_USER_GROUP_RIGHTS = 'ACCESS_CONTROL_USER_GROUP_RIGHTS'
export const RENOVATION_SPACES = 'RENOVATION_SPACES'
export const RENOVATION_SPACEGROUPS = 'RENOVATION_SPACEGROUPS'
export const RENOVATION_BUILDING = 'RENOVATION_BUILDING'
export const RENOVATION_SITE = 'RENOVATION_SITE'
export const ACCOUNT_MANAGEMENT_CONTACT_INFO = 'ACCOUNT_MANAGEMENT_CONTACT_INFO'
export const MATRIX_LIST_LEFT = 'MATRIX_LIST_LEFT'
export const WOP_GROUPING_GROUPINGSCHEDULE = 'WOP_GROUPING_GROUPINGSCHEDULE'
export const WOP_GROUPING_SPACESCHEDULE = 'WOP_GROUPING_SPACESCHEDULE'

// RowTypes
export const ASSEMBLY = 'ASSEMBLY'
export const PRICEITEM = 'PRICEITEM'
export const EQUIPMENT = 'EQUIPMENT'
export const AREA_EQUIPMENT = 'AREA_EQUIPMENT'
export const ITEM = 'ITEM'
export const FUNCTIONALSECTORGROUP = 'FUNCTIONALSECTORGROUP'
// UI generated item to be used before a data is saved and replaced after data is saved with the actual ITEM row
export const GENERATED_ITEM = 'GENERATED_ITEM'
export const HEADING = 'HEADING'
export const SURFACES = 'SURFACES'
export const GENERATED = 'GENERATED'
export const PROCESS = 'PROCESS'
export const ACTIVITYGROUP = 'ACTIVITYGROUP'
export const WOPACTIVITYGROUP = 'activityGroup'
export const ACTIVITY = 'ACTIVITY'
export const PROCESSGROUP = 'PROCESSGROUP'
export const FUNCTIONGROUP = 'FUNCTIONGROUP'
export const PROCESSACTIVITY = 'PROCESSACTIVITY'
export const BUILDING = 'BUILDING'
export const ESTIMATE = 'ESTIMATE'
export const USER = 'USER'
export const USER_GROUP = 'USERGROUP'
export const STORY_PROPERTY = 'STORY_PROPERTY'
export const GROUPINGFUNCTIONALSECTORGROUP = 'functionalSectorGroup'
export const GROUPINGFUNCTIONGROUP = 'functionGroup'
export const GROUPINGPROCESSGROUP = 'processGroup'

export const ERROR = 'ERROR'

export const rowsWithYearInput = ['YearOfAgeDeductionForPropertyTaxationY']

export const childWidgets = [FLOOR, EQUIPMENT_PRICING, SURFACE_PRICING, AREA_EQUIPMENT_REGISTRY, PROCESSES_AND_ACTIVITIES]
export const ROWS_WITHOUT_CHILDREN = [ASSEMBLY, 'ROW_WITHOUT_CHILDREN_TEST_ROW']

// list cell units to hide according to lists "listType" -property
export const listColumnUnitsToHide = {
  [BUILDING_ELEMENTS_SCHEDULE]: [units.UNIT_PRICE],
  [SPACES]: ['all'],
  [WOP_SPACE_SCHEDULE]: [units.QUANTITYPCS, units.AREAM2PERPC, units.AREATOTALM2],
  [CREATE_OTHER_SPACES]: ['all'],
  [PROCESSACTIVITY]: [units.AREAHUM2],

  [TEST_LIST_TYPE]: [units.NEW_PRICE_TOTAL]
}

// Multiedit container types
export const multiEditContainerTypes = {
  MULTI_EDIT_SPACE_GROUP_WIDGET: 'spacegroup',
  MULTI_EDIT_SPACE_WIDGET: 'space'
}

export const MEASURE_PROPERTIES_CONTAINER = 'MEASURE_PROPERTIES_CONTAINER'
