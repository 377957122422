// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withTranslation } from 'react-i18next'
import { v4 } from 'uuid'

import ModalForm from '../../common/ModalForm/ModalForm'
import LabeledInput from '../../common/LabeledInput/LabeledInput'

import { closeModal, buildConfirmationModal } from '../../../actions/modals'
import { commonIgnoredCharacters } from '../../../constants/validationConstants'


type HOCProps = {|
  t: Function, // translate
|}

type MappedProps = {|
  calculation: string, // calculation ID
|}

type DispatchProps = {|
  dispatchBuildConfirmationModal: (Object) => void, // function to create confirmationDialog if needed
  dispatchCloseModal: () => void, // function bound to cancel / close button in FooterButtons
|}

type ReceivedProps = {|
  id: string, // id
  onSave: Function,
  row: Object,
  testId: string, // test id
|}

type Props = {|
  ...HOCProps,
  ...MappedProps,
  ...DispatchProps,
  ...ReceivedProps,
|}

type State = {|
  description: string, // string from description field
  descriptionValid: boolean, // indicates if description is valid
  formModified: boolean, // indicates if form has been modified
  share: string, // string from share field
  shareValid: boolean, // indicates if share is valid
  unitPrice: string, // string from unitPrice field
  unitPriceValid: boolean // indicates if unitPrice is valid
|}

export class SurfaceModalContainer extends Component<Props, State> {
  state = {
    description: '',
    descriptionValid: true,
    formModified: false,
    share: '',
    shareValid: true,
    unitPrice: '',
    unitPriceValid: true,
  }

  checkValidity = () => {
    const {
      descriptionValid,
      shareValid,
      unitPriceValid,
      formModified
    } = this.state
    return Boolean(formModified &&
      descriptionValid &&
      shareValid &&
      unitPriceValid)
  }

  handleUserInput = (event: SyntheticInputEvent<any>, stateKey: string) => {
    this.handleChange(stateKey, event.target.value)
  }

  handleChange = (stateKey: string, eventValue: string) => {
    this.setState({
      [stateKey]: eventValue,
      formModified: true
    })
  }

  handleSave = (formValues: Object) => {
    const {
      dispatchCloseModal,
      onSave,
      row: { parentId, type }
    } = this.props

    const addedRow = {
      parentId,
      type,
      id: v4(),
      columnData: {
        ShareOfSurfaceQuantityP: formValues.share,
        Description: formValues.description,
        UnitPrice: formValues.unitPrice
      },
      columnUnits: {
        QuantitySpaceSurfaceShareAdjusted: formValues.unit,
        ShareOfSurfaceQuantityP: '%'
      }
    }
    onSave(addedRow)
    dispatchCloseModal()
  }

  handleClose = () => {
    const { dispatchBuildConfirmationModal, dispatchCloseModal } = this.props

    if (this.state.formModified) {
      dispatchBuildConfirmationModal({ onSave: dispatchCloseModal })
    } else {
      dispatchCloseModal()
    }
  }

  description(): React$Element<typeof LabeledInput> {
    const { t, testId } = this.props

    const stateKey = 'description'
    const inputProps: TVDLabeledInput = {
      value: this.state[stateKey],
      handleChange: (event: SyntheticInputEvent<any>) => this.handleUserInput(event, stateKey),
      size: 'XL',
      dataType: 'string'
    }
    return (
      <LabeledInput
        focused
        testId={`${testId}-${stateKey}`}
        {...inputProps}
        ignoreCharacters={commonIgnoredCharacters}
        label={t('surfaceModal._DESCRIPTION_')}
        id={`${this.props.id}-${stateKey}`}
        isValidCb={(isValid: boolean) => this.setState({ [`${stateKey}Valid`]: isValid })}
        required />
    )
  }

  share(): React$Element<typeof LabeledInput> {
    const { t, testId } = this.props

    const stateKey = 'share'
    const inputProps: TVDLabeledInput = {
      value: this.state[stateKey],
      handleChange: (event: SyntheticInputEvent<any>) => this.handleUserInput(event, stateKey),
      size: 'XL',
      dataType: 'integer'
    }
    return (
      <LabeledInput
        testId={`${testId}-${stateKey}`}
        number
        {...inputProps}
        label={t('surfaceModal._SHARE_')}
        id={`${this.props.id}-${stateKey}`}
        isValidCb={(isValid: boolean) => this.setState({ [`${stateKey}Valid`]: isValid })}
        required />
    )
  }

  unitPrice(): React$Element<typeof LabeledInput> {
    const { t, testId } = this.props

    const stateKey = 'unitPrice'
    const inputProps: TVDLabeledInput = {
      value: this.state[stateKey],
      handleChange: (event: SyntheticInputEvent<any>) => this.handleUserInput(event, stateKey),
      size: 'XL',
      dataType: 'number'
    }
    return (
      <LabeledInput
        testId={`${testId}-${stateKey}`}
        number
        {...inputProps}
        label={t('surfaceModal._UNIT_PRICE_')}
        id={`${this.props.id}-${stateKey}`}
        isValidCb={(isValid: boolean) => this.setState({ [`${stateKey}Valid`]: isValid })}
        required />
    )
  }

  inputs(): LabeledInput {
    return [this.description(), this.share(), this.unitPrice()]
  }

  modalForm(): React$Element<typeof ModalForm> {
    const { formModified, ...formValues } = this.state

    return (<ModalForm
      testId='createUserDefinedSurfaceModal'
      items={this.inputs()}
      onSave={() => this.handleSave(formValues)}
      onClose={this.handleClose}
      valid={this.checkValidity()}
      modified={this.state.formModified}
      saveButtonText='buttons._SAVE_' />)
  }

  render(): React$Element<ModalForm> {
    return this.modalForm()
  }
}

type ownProps = {
  id: string
}

const mapStateToProps = ({ app: { calculation } }: Object): Object => ({ calculation })

const mapDispatchToProps = (dispatch: Function, { id }: ownProps) => ({
  dispatchBuildConfirmationModal: (content: Object) => { dispatch(buildConfirmationModal(content)) },
  dispatchCloseModal: () => { dispatch(closeModal(id)) },
})

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation('translations')
)(SurfaceModalContainer)
