// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.

export const getEnumFromEnumStore = (props: {|
  basePath: string, // api base path
  languageCode: string, // lanaguage code e.g sv, en, fi
  enumKey: string, // key that is used to store the enum which often with ValueProperties is propertyName,
  enums: TVDEnumStore, // all available enums from Store
|}): Array<TVDEnum> | null | typeof undefined => {
  const {
    basePath,
    languageCode,
    enumKey,
    enums
  } = props
  let enumValue
  try {
    enumValue = enums[basePath][languageCode][enumKey]
  } catch (er) {
    enumValue = null
  }
  return enumValue
}
