// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.
import React, { Component } from 'react'
import { map } from 'lodash'
import { Grid } from '@material-ui/core'
import { withTranslation } from 'react-i18next'
import { withStyles } from '@material-ui/core/styles'
import FooterButtons from '../../containers/widgets/FooterButtons/FooterButtons'
import type { TextButtonProps, TVDButtonVariants } from '../../common/TextButton/TextButton'

const styles = ({ palette }: TVDTheme) => ({
  footerContainer: {
    display: 'flex',
    marginTop: 40,
    flex: 1,
  },
  footerText: {
    display: 'flex',
    justifyContent: 'flex-start',
    fontSize: '12px',
    width: '330px',
    color: palette.nevada
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    flex: '1'
  },
})

type HOCProps = {|
  t: Function, // translation function
  classes: Object // withStyles classes object
|}

type ReceivedProps = {|
  valid: boolean, // flag to indicate if form values are valid so "save" button may be disabled
  modified: boolean, // flag to indicate if form has been modified
  items: Array<React$Element<any>>, // array of form elements
  onSave: Function, // click event bound to saveButton in footerButtons
  hideCancel: boolean, // flag to hide cancel button from FooterButtons
  onClose: Function, // click event bound to closeButton in footerButtons
  testId: string, // id string for testing purposes,
  saveButtonText: ?string, // custom translation key for save button
  activeCalculation?: boolean, // flag that indicates if calculation is running
  saveButtonVariant?: TVDButtonVariants, // optional variant for the save button e.g when doing a dangerous operation one might want to style it differenty
  footerText?: string, // custom helpertext displayed in modalForm footer
  hideSave?: boolean, // if we want the save button to be hidden
|}

type Props = {|
  ...HOCProps,
  ...ReceivedProps
|}

export class ModalForm extends Component<Props> {
  static defaultProps = {
    saveButtonText: 'widgets._SAVE_',
    activeCalculation: false,
    saveButtonVariant: 'contained',
    footerText: undefined,
    hideSave: false,
  }
  get formContent(): React$Element<any> {
    const {
      classes,
      hideCancel,
      t,
      saveButtonText,
      activeCalculation,
      saveButtonVariant,
      footerText,
      hideSave
    } = this.props

    const footerButtonItems: TextButtonProps[] = [
    ]
    if (!hideSave) {
      footerButtonItems.push({
        id: `${this.props.testId}-Save`,
        disabled: !this.props.valid,
        onClick: this.props.onSave,
        text: t(saveButtonText),
        showSpinner: activeCalculation,
        variant: saveButtonVariant,
      })
    }


    if (!hideCancel) {
      footerButtonItems.push({
        id: `${this.props.testId}-Cancel`,
        onClick: this.props.onClose,
        text: this.props.modified || this.props.valid ? t('widgets._CANCEL_') : t('widgets._CLOSE_'),
        variant: 'text'
      })
    }

    return (
      <React.Fragment>
        <Grid
          container
          direction='column'
          justify='center'
          alignItems='flex-start'
          spacing={2}>
          {
            map(this.props.items, (item: Function, index: number) => (
              <Grid item key={index}>
                {item}
              </Grid>
            ))
          }
        </Grid>
        <div className={classes.footerContainer}>
          {footerText && <div className={classes.footerText}>{footerText}</div>}
          <div className={classes.buttons}>
            <FooterButtons items={footerButtonItems} />
          </div>
        </div>
      </React.Fragment>
    )
  }


  render(): React$Element<any> {
    return this.formContent
  }
}

export default withTranslation('translations')(withStyles(styles)(ModalForm))
