// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.
import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'

import CreateOtherSpacesList from './CreateOtherSpacesList/CreateOtherSpacesList'

import {
  CIRCULATION,
  FACILITY_SERVICES,
  TECHNICAL_BUILDING_SERVICES,
  PROTECTED_CIRCULATION
} from '../../../../constants/registryConstants'
import ViewHeaderText from '../../../common/ViewHeaderText/ViewHeaderText'

const styles = {
  createOtherSpacesWidget: {
    display: 'flex',
    flexDirection: 'column',
  }
}

const commonFunctions: Array<TVDCheckboxRowProps> = [
  { description: CIRCULATION, checked: true, disabled: true },
  { description: FACILITY_SERVICES, checked: true, disabled: true },
  { description: TECHNICAL_BUILDING_SERVICES, checked: true, disabled: true },
  { description: PROTECTED_CIRCULATION, checked: true, disabled: true }
]

type Props = {
  classes: Object, // Material-ui classes object
  t: Function, // translate function
}

export class CreateOtherSpacesContainer extends Component<Props> {
  render(): React$Element<any> {
    const { classes, t } = this.props
    return (
      <div className={classes.createOtherSpacesWidget}>
        <ViewHeaderText inWidget>{t('createOtherSpaces._HEADER_')}</ViewHeaderText>
        <CreateOtherSpacesList checkboxRows={commonFunctions} />
      </div>
    )
  }
}

export default withTranslation('translations')(withStyles(styles)(CreateOtherSpacesContainer))
