// @flow
// Copyright © 2010–2023 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.
import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useSelector, useStore } from 'react-redux'
import { boxShadows } from 'frontend-assets'
import Header from '../../../components/common/Header/Header'
import { SHOW_LANDING_PAGE } from '../../../constants/localPersistence'
import HeaderTabs from '../HeaderTabs/HeaderTabs'
import { setMainViewActiveIndex } from '../../../actions/view/actions'
import useLocalStorage from '../../common/hooks/useLocalStorage'

const useStyles = makeStyles(({ palette }: TVDTheme) => ({
  splashScreenContainer: {
    backgroundColor: palette.white,
    display: 'flex',
    flexDirection: 'column',
    height: '168px',
    alignItems: 'center',
    justifyContent: 'center',
    animation: '$slideInTop 2s',
    position: 'absolute',
    width: '100%',
    zIndex: 1300,
  },
  headerWrapper: {
    backgroundColor: palette.white,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'absolute',
    width: '100%',
    zIndex: 1200,
  },
  logoHeaderContainer: {
    position: 'absolute'
  },
  logoHeader: {
    marginBottom: '32px',
    height: 20
  },
  headerContainer: {
    position: 'fixed',
    top: '0',
    animation: '$appearHeader 2.5s',
    width: '100%'
  },
  splashScreenLogo: {
    width: '100%',
    height: '517px',
    backgroundColor: palette.white
  },
  splashScreenLogoContainer: {
    width: '100%',
    position: 'absolute',
    textAlign: 'center',
  },
  '@keyframes slideInTop': {
    '0%': {
      height: '100%'
    },
    '30%': {
      height: '100%'
    },
    '100%': {
      height: '168px'
    }
  },
  '@keyframes appearHeader': {
    '0%, 70%': {
      opacity: '0'
    }
  },
  outerWrapper: {
    height: '98px',
    width: '100%',
    display: 'flex',
    position: 'absolute',
    marginTop: 60
  },
  headerTabsWrapper: {
    display: 'flex',
    position: 'absolute',
    zIndex: 1200,
    bottom: 0,
    boxShadow: boxShadows.boxShadowComponent,
    clipPath: 'inset(0px -5px -8px -5px)',
    width: '100%'
  }
}))

export const splashScreenImageSource = (): string => 'assets/images/realaizer/Realaizer_splashscreen.svg'
export const logoImageSource = (): string => 'assets/images/realaizer/Realaizer_logo.svg'

const HeaderContainer = ({
  showTabs
}: {
  showTabs?: boolean // if we should show tabs in the header
}): React$Element<'div'> => {
  const classes = useStyles()
  const { dispatch } = useStore()
  return (
    <div className={classes.outerWrapper}>
      <div className={classes.headerWrapper}>
        <div className={classes.logoHeaderContainer}>
          <img alt='logo' className={classes.logoHeader} src={logoImageSource()} />
        </div>
      </div>
      { showTabs && (
        <div className={classes.headerTabsWrapper}>
          <HeaderTabs
            onChange={(selectedTabIndex: number): void => {
              dispatch(setMainViewActiveIndex(selectedTabIndex))
            }} />
        </div>
      )}
    </div>
  )
}

const SplashScreenContainer = ({
  realEstateName
}: {
  realEstateName: $PropertyType<TVDApplicationStore, 'realEstateName'>
}): React$Element<'div'> => {
  const classes = useStyles()
  return (
    <div className={classes.splashScreenContainer}>
      <div className={classes.headerContainer}>
        <Header selectedRealEstate={realEstateName} />
      </div>
      <div className={classes.splashScreenLogoContainer}>
        <img alt='logo' className={classes.splashScreenLogo} src={splashScreenImageSource()} />
      </div>
    </div>
  )
}


const SplashScreen = ({
  showTabs
}: {
  showTabs?: boolean, // if we should allow HeaderContainer to show tabs
}): React$Element<typeof React.Fragment> => {
  const { languageCode, realEstateName } = useSelector(({ app }: TVDReduxStore): TVDApplicationStore => app)
  const [showLandingPageValue, setShowLandingPageValue] = useLocalStorage(SHOW_LANDING_PAGE, 'true')
  const [isSettingLandingPageValue, setIsSettingLandingPageValue] = useState(false)
  useEffect(() => {
    if (showLandingPageValue && !isSettingLandingPageValue) {
      setIsSettingLandingPageValue(true)
      setTimeout(() => { setShowLandingPageValue('false') }, 1600)
    }
  }, [showLandingPageValue, setShowLandingPageValue, isSettingLandingPageValue])

  return (
    <>
      {showLandingPageValue === 'true' ?
        <SplashScreenContainer realEstateName={realEstateName} languageCode={languageCode} /> :
        <HeaderContainer languageCode={languageCode} showTabs={showTabs} />
      }
    </>
  )
}

export default SplashScreen
