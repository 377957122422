// @flow
// Copyright © 2010–2023 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.

import * as React from 'react'
import { withStyles } from '@material-ui/core'
import { typographyClasses } from 'frontend-assets'
import { dotsToCommas, commasToDots, roundToPrecision } from '../../../../utils/commonUtils'
import { thousandSeparator } from '../../../../utils/listUtils'

export type ValueFieldProps = {|
  id: string, // for testing purposes
  onClick: () => {}, // callback for when the user clicks on the valuefield
  children: string | number, // the content for the valuefield
  dataType: string, // datatype for the valuefield content, used for formatting
  disabled: boolean, // whether the ValueField is in a disabled state
  placeholder: string, // placehold
  alignLeft: boolean, // align InputField text to the left side of the container
|}

const styles = ({ palette }: TVDTheme): Object => ({
  valueField: {
    ...typographyClasses.bodyNumbers,
    display: 'flex',
    alignItems: 'center',
    color: palette.black,
    width: '100%',
    maxWidth: '250px',
    minWidth: '4px', // testcafe wont click less than 4 width elements
    height: '29px',
    borderBottom: '1px solid transparent',
    cursor: 'pointer',
    marginRight: '2px',
    whiteSpace: 'nowrap',
    '&:hover': {
      borderBottom: `1px dashed ${palette.dark80}`
    },
    '&:focus': {
      outline: '0px solid transparent'
    }
  },
  disabled: {
    color: palette.dark60,
    pointerEvents: 'none',
  },
  placeholder: {
    color: palette.dark80
  }
})

type Props = {
  ...ValueFieldProps,
  classes: Object, // style object
}

/** Component for showing user input. */
export class ValueField extends React.Component<Props> {
  static defaultProps = {
    id: 'ValueField',
    children: '',
    required: false,
    disabled: false,
    ignoreCharacters: [],
    locale: ['sv-SE'] // default to sv-SE because fi-Fi is not an option in UserInput.js
  }

  handleOnClick = (event: SyntheticEvent<any>) => {
    event.stopPropagation()
    if (!this.props.disabled) this.props.onClick()
  }

  formatValueField = (children: string | number) => {
    const { dataType } = this.props
    if (children !== 0 && !children) return ''
    const maximumAmountOfValueFieldDigitsToShow = 1
    switch (dataType) {
      case 'number':
        // if the input accidentally gets a string initialValue that is a number it will attempt to rectify the situation
        if (typeof children === 'string') {
          return thousandSeparator(dotsToCommas(roundToPrecision(Number(commasToDots(children)), maximumAmountOfValueFieldDigitsToShow)))
        }
        if (Number.isInteger(roundToPrecision(Number(children), maximumAmountOfValueFieldDigitsToShow))) {
          return thousandSeparator(dotsToCommas(roundToPrecision(Number(children), maximumAmountOfValueFieldDigitsToShow))).concat(',0')
        }
        return thousandSeparator(dotsToCommas(roundToPrecision(Number(children), maximumAmountOfValueFieldDigitsToShow)))
      case 'integer':
        return thousandSeparator(dotsToCommas(roundToPrecision(Number(children), 0)))
      case 'string':
      case 'year':
      default:
        return children
    }
  }

  render(): React$Element<any> {
    const {
      classes,
      id,
      disabled,
      children,
      placeholder,
      alignLeft
    } = this.props

    const valueFieldClass = disabled ? `${classes.valueField} ${classes.disabled}` : classes.valueField
    const textAlign = alignLeft
      ? { justifyContent: 'flex-start', textAlign: 'left' }
      : { justifyContent: 'flex-end', textAlign: 'right' }

    return (
      <div
        key={id}
        style={{ ...textAlign }}
        className={valueFieldClass}
        data-testid={`${id.replace(/ /g, '_')}-ValueField`}
        role='button'
        tabIndex={0}
        onClick={this.handleOnClick} >
        {children !== '' ? this.formatValueField(children) : <span className={classes.placeholder}>{placeholder}</span>}
      </div>
    )
  }
}

export default withStyles(styles)(ValueField)
