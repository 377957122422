// @flow
// Copyright © 2010–2022 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.

export const SET_ACTIVE_ESTIMATE = 'SET_ACTIVE_ESTIMATE'
export function setActiveEstimate(activeEstimate: TVDCalculation): TVDAction {
  return {
    type: SET_ACTIVE_ESTIMATE,
    payload: { activeEstimate }
  }
}

export const RESET_ACTIVE_ESTIMATE = 'RESET_ACTIVE_ESTIMATE'
export function resetActiveEstimate(): TVDAction {
  return {
    type: RESET_ACTIVE_ESTIMATE,
    payload: {}
  }
}

