// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.

import * as React from 'react'

import { validateUserInput } from '../../../utils/commonUtils'

export type ValidatorProps = {|
  // NOTE: "dataType: name" should only be used in case of a firstName or a lastName of a person or a name of a company.
  dataType: string, // input datatype eg. int | string | name | date.
  value: string | number, // current user input in the input
|}

export type ValidatorInformation = {|
  dataType: string, // type of the input e.g integer, int, date, text
  value: string | number, // value of the input
  ignoreCharacters: Array<string>, // black listed characters
  locale: Array<string>, // locale information usefd in isAlphanumeric validation
  required: boolean, // if input is required or not
  isValid: boolean, // the returned value from default validator
|}

type Props = {
  ...ValidatorProps,
  render: (boolean) => React$Element<any>, // function prop that a component uses to know what to render.
  isValidCb: Function, // callBack function triggered after validation
  disableValidation: boolean, // flag to disable validation when not needed (filters inputs etc.)
  ignoreCharacters: Array<string>, // array of characters to be ignored in validation
  locale: Array<string>, // locale used for validation purposes
  required: boolean, // flag to indicate if value for component received in render prop is required
  disabled: boolean, // flag to indicate if component received in render prop disabled
  customValidator?: (ValidatorInformation) => boolean, // optional validator used instead if provided
}

export class Validator extends React.Component<Props> {
  static defaultProps = {
    required: false,
    disableValidation: false,
    disabled: false,
    ignoreCharacters: [],
    locale: ['sv-SE'], // default to sv-SE because fi-Fi is not an option in validator.js
    customValidator: undefined
  }

  componentDidUpdate(prevProps: Object) {
    if (prevProps.value !== this.props.value && this.props.isValidCb) this.props.isValidCb(this.validateInput())
  }

  validateInput = () => {
    const {
      value,
      dataType,
      ignoreCharacters,
      locale,
      required,
      disableValidation,
      disabled,
      customValidator
    } = this.props

    if (disableValidation || disabled) return true

    const validatedInput = validateUserInput(dataType, value, ignoreCharacters, locale, required)

    if (customValidator) {
      return customValidator({
        dataType,
        value,
        ignoreCharacters,
        locale,
        required,
        isValid: validatedInput
      })
    }

    return validatedInput
  }

  render(): React$Element<any> {
    return this.props.render(this.validateInput())
  }
}

export default Validator
