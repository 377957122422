// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.
import * as React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withStyles } from '@material-ui/core/styles'
import { withRouter } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import Typography from '@material-ui/core/Typography'
import Header from '../../common/Header/Header'

import DrawerRight from '../../common/DrawerRight/DrawerRight'
import AppsList from './AppsList'
import ModalsContainer from '../../containers/ModalsContainer/ModalsContainer'

import { USERS, ACCOUNTS, usersUrl, accountUrl } from '../../../constants/moduleConstants'
import { MODAL_TYPE_SETTINGS, ACCOUNT_SELECTION_MODAL } from '../../../constants/modalConstants'
import { openModal } from '../../../actions/modals'
import AccountSelectionHOC from '../../hocs/AccountSelectionHOC/AccountSelectionHOC'
import { storeCountries, storeMunicipalities } from '../../../actions/app'

const styles = ({ palette, typography }: Object): Object => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    backgroundColor: palette.white
  },
  contentWrapper: {
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  selectedAccountName: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    color: palette.primary100,
    marginTop: '6vh',
    marginBottom: '90px',
    zIndex: '1'
  },
  selectedAccountNameText: {
    fontFamily: typography.latoBoldItalic,
    fontSize: '26px',
    color: palette.nevada,
    marginLeft: '9px',
    paddingTop: '2px'
  }
})

type HOCProps = {|
  t: Function, // i18n translate function
  classes: Object, // withStyles classes object
|}

type MappedProps = {|
  selectedAccountName?: string, // the name of the currently selected account
|}

type DispatchProps = {|
  dispatchOpenModal: (content: Object, id: string) => void, // open modal by adding required modal content to Store
  dispatchStoreCountries: (countries: Array<TVDEnum>) => void, // stores available country enums to Store
  dispatchStoreMunicipalities: (municipalities: Array<TVDEnum>, countryId: string) => void, // stores available municipalities to Store by their country id
|}

type ReceivedProps = {|
|}

type Props = {|
  ...HOCProps,
  ...MappedProps,
  ...DispatchProps,
  ...ReceivedProps
|}

export class AccountManager extends React.Component<Props> {
  static defaultProps = {
    selectedAccountName: ''
  }

  handleAppSelect = (url: string) => {
    const { dispatchOpenModal, t } = this.props

    switch (url) {
      case accountUrl:
        dispatchOpenModal({
          type: ACCOUNT_SELECTION_MODAL,
          title: t('drawer._CHOOSE_ACCOUNT_'),
          disablePadding: true,
          contentProps: {
            hideMoreOptions: false,
            includeStatus: true
          }
        }, ACCOUNT_SELECTION_MODAL)
        break

      case usersUrl:
        dispatchOpenModal({
          type: MODAL_TYPE_SETTINGS,
          title: 'settings._SETTINGS_',
          containerType: 'dialog',
          contentProps: {
            hideMoreOptions: true,
            appType: USERS,
            initialSelectedTab: 1
          }
        }, MODAL_TYPE_SETTINGS)
        break

      default:
        break
    }
  }

  getSelectedAccountElement(): React$Element<'div'> {
    const { classes, selectedAccountName } = this.props
    return (
      <div className={classes.selectedAccountName}>
        <img src='/assets/images/Tili_small.svg' alt='account_icon' />
        <Typography className={classes.selectedAccountNameText}>
          {selectedAccountName}
        </Typography>
      </div>
    )
  }

  render(): React$Element<'div'> {
    const {
      classes,
    } = this.props

    const apps = [ACCOUNTS, USERS]

    return (
      <div className={classes.root}>
        <Header />
        <DrawerRight />
        <div className={classes.contentWrapper}>
          <AppsList
            displayApps={apps}
            onAppClick={(url: string) => { this.handleAppSelect(url) }}
            availableAppIds={apps} />
          {this.getSelectedAccountElement()}
        </div>
        <ModalsContainer />
      </div>
    )
  }
}

const mapStateToProps = ({ app: { selectedAccountName } }: TVDReduxStore): MappedProps => ({
  selectedAccountName
})

const mapDispatchToProps = (dispatch: Function): DispatchProps => ({
  dispatchOpenModal: (content: Object, id: string) => { dispatch(openModal(content, id)) },
  dispatchStoreCountries: (countries: Array<TVDEnum>) => { dispatch(storeCountries(countries)) },
  dispatchStoreMunicipalities: (municipalities: Array<TVDEnum>, countryId: string) => { dispatch(storeMunicipalities(municipalities, countryId)) }
})

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation('translations'),
  withStyles(styles),
  AccountSelectionHOC
)(AccountManager)
