// @flow
// Copyright © 2010–2022 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import RealEstateManagement from '../components/containers/RealEstateManagement/RealEstateManagement'
import { headerHeight } from '../components/common/Header/Header'

const useStyles = makeStyles(() => ({
  root: {
    margin: `${headerHeight + 112}px auto 32px auto`,
    height: '100%'
  }
}))

const RealEstateManagementMFEContainer = (): React$Element<'div'> => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <RealEstateManagement />
    </div>
  )
}

export default RealEstateManagementMFEContainer
