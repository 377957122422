// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.
import { getResultBarDataFromValueProperties } from '../utils/resultbarUtils'

const initialState = {}
export default function resultBarReducer(state: Object = initialState, action: TVDAction): Object {
  const { payload: { resultBarKey, parsedResponse } = {}, type } = action
  if (resultBarKey && type.includes('SUCCESSFUL') && Array.isArray(parsedResponse) && parsedResponse.length) {
    return {
      ...state,
      [resultBarKey]: getResultBarDataFromValueProperties(parsedResponse)
    }
  }
  return state
}
