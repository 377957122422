
// @flow
// Copyright © 2010–2022 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.
import { dispatch } from '../store'
import { openModal, closeModal } from '../actions/modals'
import { CONFIRMATION_MODAL_PADDED } from '../constants/contentTypes'

export const openGenericErrorModal = (error?: Error, message?: string): void => {
  if (error) {
    console.error(error)
  }
  const modalId = Date.now().toString()
  dispatch(openModal({
    type: CONFIRMATION_MODAL_PADDED,
    message,
    messageTranslationKey: 'general._ERROR_',
    messageType: 'error',
    onClose: (): void => { dispatch(closeModal(modalId)) },
    disablePadding: true,
    index: 9999
  }, modalId))
}
