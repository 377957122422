// @flow
// Copyright © 2010–2022 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.
import {
  OWNER,
  MANAGER,
  WRITE,
  READ
} from '../constants/permissions'

// The lower the value, the higher the priority
// Meant to tell difference between levels or permissions when comparing permissions
const permissionPriority: {
  [permissionPriorityKey: TVDSchemaSubjectPermissionEnum]: number
} = {
  [OWNER]: 1,
  [MANAGER]: 2,
  [WRITE]: 3,
  [READ]: 4
}

export const getHighestPermission = (permissions: TVDSchemaSubjectPermissionEnum[]): TVDSchemaSubjectPermissionEnum => {
  switch (true) {
    case permissions.includes(OWNER): { return OWNER }
    case permissions.includes(MANAGER): { return MANAGER }
    case permissions.includes(WRITE): { return WRITE }
    case permissions.includes(READ): { return READ }
    default: return READ
  }
}

export const getIsPermissionHigher = (
  permissionA: TVDSchemaSubjectPermissionEnum,
  permissionB: TVDSchemaSubjectPermissionEnum,
): boolean => permissionPriority[permissionA] < permissionPriority[permissionB]


type getHighestPermissionFromGroupsReturnType = {
  permission?: TVDSchemaSubjectPermissionEnum | null,
  group?: TVDUserGroup | null
}

export const getHighestPermissionFromGroups = (userGroups: TVDUserGroup[]): getHighestPermissionFromGroupsReturnType => {
  const { permission, group }: getHighestPermissionFromGroupsReturnType = userGroups
    .reduce((
      prev: getHighestPermissionFromGroupsReturnType,
      userGroup: TVDUserGroup
    ) => {
      const { _embedded: { permissions = [] } = {} } = userGroup
      const userGroupHighestPermission = getHighestPermission(permissions)
      const isPermissionHigher = prev.permission ? getIsPermissionHigher(userGroupHighestPermission, prev.permission) : true
      return {
        permission: isPermissionHigher ? userGroupHighestPermission : prev.permission,
        group: isPermissionHigher ? userGroup : prev.group
      }
    }, { permission: null, group: null })
  return {
    permission,
    group
  }
}
