// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.

import { ALLOWED_TYPES_FOR_PATCH_OPERATIONS_BY_ID } from '../constants/patchOperationConstants'

type CreatePatchOperationParameters = {|
  resourceId: string, // main resourceId used as a TVDPatchOperation key value
  value: string | number, // value for the operation parameter
  operationType: TVDPatchOperationType,
  basePath: string, // the beginning of the operation which is commonly shared among other operation parameters e.g columnData/Amount
|}

type CreatePatchOperationParameter = {|
  resourceId: $PropertyType<TVDPatchOperationParameterObject, 'resourceId'>,
  basePath: $PropertyType<CreatePatchOperationParameters, 'basePath'>,
  operationType: $PropertyType<CreatePatchOperationParameters, 'operationType'>,
  resourcePath: string, // a required sub resource path concatenated to the basePath
  value: string | number, // value for the operation parameter path
|}

export const createPatchOperationParameter = ({
  resourceId,
  basePath,
  resourcePath,
  value,
  operationType,
}: CreatePatchOperationParameter): TVDPatchOperationParameterObject => ({
  resourceId,
  operationType,
  parameter: { [`${basePath}/${resourcePath}`]: value },
})


export const createPatchOperation = ({
  resourceId,
  value,
  operationType,
  basePath,
}: CreatePatchOperationParameters): TVDPatchOperation => {
  const patchOperation = {
    resourceId,
    operationType,
    operationParameters: {
      [basePath]: value,
    }
  }
  return patchOperation
}

export const patchOperationsToArray = (patchOperations: {[resourceId: string]: Array<TVDPatchOperation>}): Array<TVDPatchOperation> =>
  Object.keys(patchOperations).reduce((result: Array<TVDPatchOperation>, patchOperationResourceId: string): Array<TVDPatchOperation> =>
    [...result, ...patchOperations[patchOperationResourceId]], [])

export const isTypeAllowedForPatchOperations = (listItemType: string, id: string) => {
  const { [id]: types = [] } = ALLOWED_TYPES_FOR_PATCH_OPERATIONS_BY_ID
  return types.includes(listItemType.toLowerCase())
}


export const removeExistingPatchOperationParametersFn = (
  operation: TVDPatchOperation,
  removeExistingPatchOperationParametersBeginningWith: string
) => {
  const operationParameters = Object.keys(operation.operationParameters)
    .reduce((result: TVDPatchOperationParameters, parameterKey: string) => {
      if (!parameterKey.startsWith(removeExistingPatchOperationParametersBeginningWith)) {
        return {
          ...result,
          [parameterKey]: operation.operationParameters[parameterKey]
        }
      }
      return result
    }, {})
  return {
    ...operation,
    operationParameters
  }
}

type TVDGetExistingPatchOperationsReturn = {|
  existingOperationIndex: number | typeof undefined,
  existingOperation: TVDPatchOperation | typeof undefined,
  allOperations: Array<TVDPatchOperation>
|}

export const getExistingPatchOperations = (
  existingOperations: Array<TVDPatchOperation>,
  desiredOperationType?: TVDPatchOperationType
): TVDGetExistingPatchOperationsReturn => {
  let existingOperationIndex
  const matchingOperations = existingOperations
    .filter(({ operationType }: TVDPatchOperation, index: number) => {
      const matchingType = operationType === desiredOperationType
      if (matchingType && typeof existingOperationIndex === 'undefined') { existingOperationIndex = index }
      return matchingType
    })
  return {
    existingOperationIndex,
    existingOperation: matchingOperations[0],
    allOperations: matchingOperations
  }
}

export const clearMatchingOperationParametersByType = (
  patchOperation: TVDPatchOperation,
  patchOperations: Array<TVDPatchOperation>,
  operationTypeToClear: string
): Array<TVDPatchOperation> =>
  patchOperations
    .reduce((result: Array<TVDPatchOperation>, newPatchOperation: TVDPatchOperation): Array<TVDPatchOperation> => {
      if (newPatchOperation.operationType === operationTypeToClear) {
        const { operationParameters: matchingTypeOperationParameters } = newPatchOperation
        const allNewMatchingTypeOperationParameters: TVDPatchOperationParameters = Object.keys(matchingTypeOperationParameters)
          .reduce((newResetOperationParams: Object, resetOperationParamPath: string) => {
            const updatedOperationParameterPaths = Object.keys(patchOperation.operationParameters)
            const hasMatch = updatedOperationParameterPaths
              .filter((updatedOperationParameterPath: string) => updatedOperationParameterPath === resetOperationParamPath)
            if (hasMatch.length > 0) return newResetOperationParams
            return {
              ...newResetOperationParams,
              [resetOperationParamPath]: matchingTypeOperationParameters[resetOperationParamPath]
            }
          }, {})
        const noParams = Object.keys(allNewMatchingTypeOperationParameters).length === 0
        if (noParams) return result
        return [
          ...result,
          {
            ...newPatchOperation,
            operationParameters: allNewMatchingTypeOperationParameters
          }
        ]
      }
      return [
        ...result,
        newPatchOperation
      ]
    }, [])
