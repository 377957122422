// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.

import React, { Component } from 'react'
import MenuCore, { type MenuCoreProps, type PlacementOptions } from '../components/MenuCore/MenuCore'
import MenuItems from '../components/MenuItems/MenuItems'

export type MenuProps = {|
  items: Array<TVDMenuItem>, // items for the menu
  placement?: PlacementOptions, // for placing the menu, corresponds to MUI spec
  toolMenu?: boolean, // if this menu is in a toolButton, forces a certain width for the menu
  noCloseOnSelect?: boolean, // whether the menu should close itself when a selection is made
|}

type Props = {
  ...MenuProps,
  children: React$Element<any>, // the React element that is placed as the anchor element for this menu
  disablePortal?: boolean, // most menus needs true, context menus need false. Portal disconnects the menu from the root component in the DOM
  renderSkeletons?: boolean, // indicates if we need to render placeholder Skeletons
  menuTitle: ?React$Element<any>, // should the menuItems have a title on top of them
  onToggleChange?: Function, // to add the class when row is active or focus
  onToggleClose?: Function, // to remove the class when the row is not active or focus
  hasStyle?: boolean, // flag to add override styles
  selected?: string, // value of the selected menu item
}

export class Menu extends Component<Props> {
  static defaultProps = {
    placement: 'bottom',
    noCloseOnSelect: false,
    toolMenu: false,
    disablePortal: true,
    renderSkeletons: false,
    menuTitle: undefined,
    onToggleChange: undefined,
    onToggleClose: undefined,
    hasStyle: false,
    selected: ''
  }

  render(): React$Element<MenuCore> {
    const {
      toolMenu,
      placement,
      noCloseOnSelect,
      children,
      renderSkeletons,
      menuTitle,
      disablePortal,
      onToggleChange,
      onToggleClose,
      hasStyle,
      selected
    } = this.props

    const props: MenuCoreProps = {
      rootItem: children,
      placement,
      noCloseOnSelect,
      width: toolMenu ? '258px' : 'auto'
    }

    return (
      <MenuCore {...props} hasStyle={hasStyle} disablePortal={disablePortal} onToggleChange={onToggleChange} onToggleClose={onToggleClose}>
        {({ handleClose }: Function) => (
          <MenuItems
            renderSkeletons={renderSkeletons}
            items={this.props.items}
            menuTitle={menuTitle}
            handleClose={handleClose}
            selected={selected}>
            {({ menuItems }: Function) => menuItems }
          </MenuItems>
        )}
      </MenuCore>
    )
  }
}

export default Menu
