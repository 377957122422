// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.

import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { boxShadows } from 'frontend-assets'
import Popper from '@material-ui/core/Popper'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import MenuList from '@material-ui/core/MenuList'
import Paper from '@material-ui/core/Paper'
import Fade from '@material-ui/core/Fade'
import { combineStyleClassNames } from '../../../../../utils/styleUtils'

const { boxShadowComponent } = boxShadows

const styles = (): Object => ({
  menuPaper: {
    boxShadow: boxShadowComponent,
    borderRadius: '2px',
  },
  menuWrapper: {
    marginLeft: '5px'
  }
})

// https://material-ui.com/utils/popper/#positioned-popper
export type PlacementOptions = 'top-start' | 'top' | 'top-end' | 'right-start' | 'right' | 'right-end'
  | 'bottom-end' | 'bottom' | 'bottom-start' | 'left-end' | 'left' | 'left-start'

export type MenuCoreProps = {|
  rootItem: React$Element<any>, // the element this menu will use as the anchor
  placement?: PlacementOptions, // placement for the menu, according to MUI spec
  noCloseOnSelect?: boolean, // if true, the menu will not close itself upon a selection
  width?: string,
|}

type Props = {|
  ...MenuCoreProps,
  children: Function,
  classes: Object, // withStyles styles prop
  disablePortal: boolean, // changes the way the menu is rendered. disable for contextMenus, otherwise leave on
onToggleChange?: (open?: boolean) => void, // to add the class when row is active or focus
  onToggleClose?: () => void, // to remove the class when the row is not active or focus
  hasStyle?: boolean, // flag to add override styles
|}

type State = {|
  anchorEl: null | Object, // the element the dropdown uses as its anchor
  open: boolean, // whether the menu is open
|}

export class MenuCore extends Component<Props, State> {
  static defaultProps = {
    onClose: null,
    width: 'auto',
    disablePortal: true,
    onToggleChange: undefined,
    onToggleClose: undefined,
    hasStyle: false
  }

  state = {
    anchorEl: null,
    open: false
  }


  /**
   * handleToggle is passed to the root element and toggles the open state and sets the anchorElement.
   * @param {SyntheticMouseEvent} event - the event from clicking on the root element
   */
  handleToggle = (event: SyntheticMouseEvent<any>) => {
    const { open } = this.state
    const { onToggleChange } = this.props
    event.stopPropagation()
    const { currentTarget } = event
    this.setState((state: Object) => ({ anchorEl: currentTarget, open: !state.open }))
    if (onToggleChange) { onToggleChange(!open) }
  }

  /**
   * handleClose is triggered by the ClickAwayListener wrapping the actual menu. It checks if the click happened on
   * the anchorElement and does nothing if so. This prevents this method from first closing the menu and the handleToggle
   * method from immediately reopening it.
   * @param {SyntheticMouseEvent} - the event from clicking anywhere outside the menu component
   */
  handleClose = (event: SyntheticMouseEvent<any>) => {
    const { anchorEl } = this.state
    if (anchorEl !== null && anchorEl.contains(event.target)) return
    this.closeMenu()
    if (this.props.onToggleClose) { this.props.onToggleClose() }
  }

  stopPropagation = (event: SyntheticMouseEvent<any>) => event.stopPropagation()

  closeMenu = () => {
    this.setState({ anchorEl: null, open: false })
  }

  render(): React$Element<any> {
    const {
      classes,
      placement,
      width,
      noCloseOnSelect,
      disablePortal,
      hasStyle
    } = this.props
    const { anchorEl, open } = this.state

    return (
      <>
        { React.cloneElement(this.props.rootItem, { onClick: this.handleToggle }) }
        <Popper
          open={open}
          anchorEl={anchorEl}
          transition
          style={{ zIndex: '9001' }}
          placement={placement}
          disablePortal={disablePortal}>
          {({ TransitionProps }: Object) => (
            <Fade
              {...TransitionProps} >
              <Paper
                classes={{ root: hasStyle ? combineStyleClassNames(classes.menuPaper, classes.menuWrapper) : classes.menuPaper }}
                style={{ width }}
                onClick={this.stopPropagation}>
                <ClickAwayListener onClickAway={this.handleClose} mouseEvent='onMouseDown'>
                  <MenuList>
                    {this.props.children({ handleClose: !noCloseOnSelect ? this.closeMenu : null })}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Fade>)}
        </Popper>
      </>
    )
  }
}

export default withStyles(styles)(MenuCore)
