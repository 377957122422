// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.
import React, { Component, Fragment } from 'react'
import { withStyles } from '@material-ui/core'
import { includes, find } from 'lodash'
import { designModelSliderColors, designModelSliderColorsDisabled } from '../../../../../constants/moduleConstants'
import { formatValue } from '../../../../../utils/listUtils'
import { catskillWhite } from '../../../../../../node_modules/frontend-assets/src/theme/colors'


const WIDTH = 65
const CARET_HEIGHT = 20
const PADDING = 10

const styles = {
  sliderFill: {
    display: 'flex',
  },
  svgWrapper: {
    display: 'flex',
    alignItems: 'flex-end',
    width: '75px',
  }
}

/*
Vertical slider polyline points
           p4
           /\
          /  \
      p5 /    \  p3
        |      |
        |      |
        |      |
        |      |
     p1 |______| p2

*/

type State = {
  userHeight: number, // height of the slider while user is dragging
  relativeY: number, // difference between old height and current height during drag()
}

type Props = {
  classes: Object, // classes object created by withstyles function
  id: string, // string to identify which slider is edited,
  svgHeight: number, // maximum height (+ or -) for slider
  baselineY: number, // defines the height of the slider
  height: number, // current height of slider
  update: Function, // function to handle slider value changes
  patchSlider: Function, // fucntion to patch sliders value
  miniEditor: React$Element<any>, // editor that is floating next to slider
  sliders: Array<TVDSVGVerticalSlider>, // verticalSlider
  origoLevel: number, // origo line location Y
  graphHeight: number, // graphHeight to align faderBlock and maximum height of sliderContainer
  disabled: boolean, // if slider is considered disabled, no dragging and different disabled color is applied
}

class SVGVerticalSlider extends Component<Props, State> {
  state = {
    userHeight: this.props.height,
    relativeY: 0,
  }

  BASE_STRUCTURE_THICKNESS_IN_METERS = 0.5
  BASE_STRUCTURE_THICKNESS = this.BASE_STRUCTURE_THICKNESS_IN_METERS / 100 * this.props.svgHeight

  componentDidMount() {
    this.setState({ userHeight: this.props.height })
  }

  componentDidUpdate(prevProps: Object) {
    if (includes([this.props.sliders[0].id, this.props.sliders[3].id], this.props.id)) {
      this.realignFirstSlider(prevProps)
    }
    if (this.props.height !== prevProps.height) {
      this.setState({ userHeight: this.props.height })
    }
  }

  get slider(): React$Element<any> {
    const {
      classes,
      miniEditor,
      id,
      baselineY,
      origoLevel,
      disabled,
      graphHeight
    } = this.props

    const { baselineY: y } = this.props
    const x = 5

    const p1 = `${x + PADDING}, ${y}`
    const p2 = `${x - PADDING + WIDTH}, ${y}`
    const { p3, p4, p5 } = this.getCaret(x, y)

    const caretTip = p4.split(',')[1]
    const caretBase = p5.split(',')[1]
    const graphTopBorder = 0
    const graphBottomBorder = graphHeight
    const sliderPointingUp = this.state.userHeight >= 0
    const sliderPointingDown = !sliderPointingUp

    const outOfGraph = (anchor: string): boolean => (
      parseFloat(anchor) < graphTopBorder ||
        parseFloat(anchor) > graphBottomBorder
    )

    const miniEditorOrigoLevel = origoLevel - 30
    let miniEditorPos = sliderPointingUp
      ? baselineY - this.state.userHeight - 30
      : baselineY + Math.abs(this.state.userHeight)

    // If the tip of the slider is approaching the border of the graph, align miniEditor with origo line
    if (caretTip < 20 || caretTip > graphBottomBorder - 20) {
      miniEditorPos = sliderPointingUp ? baselineY + 5 : miniEditorOrigoLevel
      // given the above situation, if the base of the slider is above origo line, align miniEditor with slider base instead of origo line
      if (baselineY < origoLevel) {
        miniEditorPos = baselineY - 30

        // first slider should align below origo line due to its unique behaviour
        if (id === 'LevelOfConstructedGroundSurfaceM') miniEditorPos = origoLevel + 5
      }
    }
    // if the first sliders tip is below the origo line, align minieditor at miniEditorOrigoLevel
    if (id === 'LevelOfConstructedGroundSurfaceM' && caretTip > origoLevel) miniEditorPos = miniEditorOrigoLevel

    // if the tip of the slider is out of graph, align miniEditor with origoLevel
    if (caretTip < 20 && sliderPointingDown) {
      miniEditorPos = origoLevel + 5
    }

    return (
      <Fragment>
        <foreignObject
          id={`${id}-slider-miniEditor`}
          onMouseDown={this.drag}
          x={10}
          y={miniEditorPos}
          width='60'
          height='30'>
          {miniEditor}
        </foreignObject>
        <polyline
          className={classes.sliderFill}
          onMouseDown={this.drag}
          points={`${p1}, ${p2}, ${p3}, ${p4}, ${p5}`}
          fill={disabled ? designModelSliderColorsDisabled[id] : designModelSliderColors[id]} />
        {
            outOfGraph(caretBase) &&
            this.getFaderBlock(sliderPointingUp ? 0 : graphBottomBorder - 10)
          }
      </Fragment>
    )
  }

  get content(): React$Element<any> {
    return <g className={this.props.classes.svgWrapper} style={{ position: 'relative' }}>{this.slider}</g>
  }

  calcFirstSliderMinHeight = () => { // Calculates the minimum allowed height of ConstructedGroundLevel slider
    const originalGroundSlider = this.props.sliders[3]
    const BASE = this.BASE_STRUCTURE_THICKNESS

    if (originalGroundSlider.height < (-BASE)) return Math.abs(originalGroundSlider.height) - BASE
    return -(originalGroundSlider.height + BASE)
  }

  drag = (e: SyntheticMouseEvent<any>) => {
    e.preventDefault()

    const {
      svgHeight,
      id,
      sliders,
      disabled
    } = this.props

    if (disabled) return

    const calculateValueFromHeight = (height: number) => formatValue(height / svgHeight * 100, 'number')

    const updateLevels = () => {
      if (id === sliders[3].id) {
        this.props.update(id, this.state.userHeight, calculateValueFromHeight(this.state.userHeight))
      } else if (id === sliders[0].id) {
        this.props.update(id, this.state.userHeight, calculateValueFromHeight(this.state.userHeight))
      } else {
        const result = this.state.userHeight > 0 ? 0 : this.state.userHeight
        this.setState({ userHeight: result }, () => this.props.update(id, result, calculateValueFromHeight(result)))
      }
    }

    window.onmousemove = (mouseEvent: SyntheticMouseEvent<any>) => {
      const delta = mouseEvent.pageY - (this.state.relativeY)
      let height = this.state.userHeight - delta

      if (height < -svgHeight) height = -svgHeight
      if (height > svgHeight) height = svgHeight
      this.setState({
        relativeY: mouseEvent.pageY,
        userHeight: height
      })
      updateLevels()
    }

    window.onmouseup = () => {
      window.onmousemove = undefined
      window.onmouseup = undefined

      const slider = find(sliders, (s: TVDSVGVerticalSlider) => s.id === this.props.id)
      if (calculateValueFromHeight(this.state.userHeight) !== slider.value) {
        this.props.update(this.props.id, this.state.userHeight, calculateValueFromHeight(this.state.userHeight))
        this.props.patchSlider(slider, calculateValueFromHeight(this.state.userHeight), 'verticalSliders')
      }
    }

    this.setState({ relativeY: e.pageY })
  }

  getFaderBlock = (faderPosY: number): React$Element<any> => {
    const pathCoordinates = `
      M  5,  0   0,  5
      M 10,  0   0, 10
      M  0, 15  15,  0
      M  5, 15  20,  0
      M 10, 15  25,  0
      M 15, 15  30,  0
      M 20, 15  35,  0
      M 25, 15  40,  0
      M 30, 15  45,  0
      M 35, 15  45,  5
      M 40, 15  45, 10
      `

    return (
      <svg height='10' width='70' x={PADDING + 5} y={faderPosY}>
        <path
          d={pathCoordinates}
          fill='none'
          stroke={catskillWhite}
          style={{ strokeWidth: 2 }} />
      </svg>
    )
  }

  getCaret(x: number, y: number): Object {
    let { userHeight } = this.state

    let p3
    const p4 = `${x + WIDTH / 2}, ${y - userHeight}`
    let p5
    if (userHeight > 0) {
      if (userHeight < CARET_HEIGHT) userHeight = CARET_HEIGHT

      p3 = `${x - PADDING + WIDTH}, ${y - userHeight + CARET_HEIGHT}`
      p5 = `${x + PADDING}, ${y - userHeight + CARET_HEIGHT}`
    } else {
      if (Math.abs(userHeight) < CARET_HEIGHT) userHeight = -CARET_HEIGHT

      p3 = `${x - PADDING + WIDTH},${y - userHeight - CARET_HEIGHT}`
      p5 = `${x + PADDING},${y - userHeight - CARET_HEIGHT}`
    }
    return { p3, p4, p5 }
  }

  realignFirstSlider(prevProps: Object) {
    const minimumHeight = this.calcFirstSliderMinHeight()
    const BASE = this.BASE_STRUCTURE_THICKNESS
    const slider1 = this.props.sliders[0].id

    if (prevProps.height !== this.props.height) this.setState({ userHeight: this.props.height }) // If this.props.height has changed compared to prevProps height -> update sliders

    if (this.props.sliders[0].height < minimumHeight) {
      const sliderValue = -this.BASE_STRUCTURE_THICKNESS_IN_METERS - this.props.sliders[3].value // calculate the difference between 1st slider minimum value and 3rd slider value
      this.props.update(slider1, minimumHeight, sliderValue) // Makes sure 1st slider isn't dragged lower than its current possible minimum
    }

    if (prevProps.sliders[3].height < this.props.sliders[3].height
          && this.props.sliders[3].height < (-BASE)
          && this.props.sliders[0].height + prevProps.sliders[3].height === (-BASE)) {
      this.props.update(slider1, minimumHeight)
    }

    // 4th slider crosses the base level in upward direction --/\--
    if ((prevProps.sliders[3].height < (-BASE) && this.props.sliders[3].height >= (-BASE))
    ) {
      this.props.update(slider1, 0, 0) // 1st resets to zero and clamps to 4th slider
    }
  }

  render(): React$Element<any> {
    const { classes, id, graphHeight } = this.props

    return (
      <svg className={classes.svgWrapper} style={{ height: graphHeight }} id={id}>
        {this.content}
      </svg>
    )
  }
}

export default withStyles(styles)(SVGVerticalSlider)
