// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.
import { findKey, isEmpty, filter } from 'lodash'
import { renameItem, deleteItem, moveItem, createItem, createWopItem, createWopRootItem, modifyListItem } from '../list'
import {
  MODAL_TYPE_CREATE_USER_DEFINED_EQUIPMENT,
  MODAL_TYPE_PRICING_ATTRIBUTE_EDIT,
  MODAL_TYPE_SETTINGS,
  MODAL_TYPE_CREATE_USER_DEFINED_SURFACE,
  MODAL_TYPE_CREATE_REPORT,
} from '../../constants/modalConstants'
import { postPolling } from '../../actions/postPolling'
import {
  postAssemblyWithIdRequest,
  postPriceitemWithIdRequest,
  getPriceitemCanMoveToWithIdRequestDefinitions,
  getHeadingCanMoveToWithIdRequestDefinitions,
  getAssemblyCanMoveToWithIdRequestDefinitions,
  getAssemblyPricelistWithIdRequestDefinitions,
  getReportsRequest as buildingelementsGetReportsRequest,
  getReportsWithReportTypeRequest as buildingelementsGetReportsWithReportTypeRequest
} from '../../utils/generated-api-requests/buildingelements'
import {
  getSpaceScheduleSpacesCanMoveToWithIdRequestDefinitions,
  getSpaceScheduleSpaceGroupsCanMoveToWithIdRequestDefinitions,
  getSpaceScheduleFunctionsCanMoveToWithIdRequestDefinitions,
  getImportScheduleItemsCanAttachToWithItemIdRequestDefinitions,
  postSpaceScheduleWithEstimateIdRequest,
  postSpacesSurfacesAssemblyItemsWithAssemblyIdRequest,
  getSpacesSurfacesPricelistWithAssemblyIdRequestDefinitions,
  getReportsRequest as spacesGetReportsRequest,
  getReportsWithReportTypeRequest as spacesGetReportsWithReportTypeRequest,
} from '../../utils/generated-api-requests/spaces'
import {
  getReportsRequest as wopGetReportsRequest,
  getReportsWithReportTypeRequest as wopGetReportsWithReportTypeRequest
} from '../../utils/generated-api-requests/wop'
import {
  getReportsRequest as estimatesGetReportsRequest,
  getReportsWithReportTypeRequest as estimatesGetReportsWithReportTypeRequest
} from '../../utils/generated-api-requests/estimates'
import { SPACES, ELEMENTS, WOP } from '../../constants/moduleConstants'
import {
  MOVE_BUILDING_MODAL,
  ADD_PRICE_ITEM_TO_ASSEMBLY_MODAL,
  ATTACH_SPACE_MODAL,
  CONFIRMATION_MODAL,
  PROPERTIES_MODAL,
  PRICEITEM,
  HEADING,
  SPACEGROUP,
  ASSEMBLY,
  SPACE,
  FUNCTION,
  ATTACH_SPACE_GROUP_MODAL,
  IMPORT_FROM_WOP_MODAL
} from '../../constants/contentTypes'

import { type PropertiesModalContentProps } from '../../components/common/PropertiesModal/PropertiesModal'

export const OPEN_MODAL = 'OPEN_MODAL'
export function openModal(content: Object, id: string): Object {
  return {
    type: OPEN_MODAL,
    payload: { content, id }
  }
}

export const CLOSE_MODAL = 'CLOSE_MODAL'
export function closeModal(id: string): Object {
  return {
    type: CLOSE_MODAL,
    payload: {
      id,
    }
  }
}

export const CLOSE_ALL_MODALS = 'CLOSE_ALL_MODALS'
export function closeAllModals(): Object {
  return {
    type: CLOSE_ALL_MODALS,
  }
}

function getConfirmationModalContent(action: Object): Function {
  return (dispatch: Function) => ({
    title: 'dialog._CONFIRMATION_DIALOG_TITLE_',
    message: 'dialog._CONFIRMATION_DIALOG_MESSAGE_',
    saveButtonText: 'dialog._DISCARD_CHANGES_',
    type: CONFIRMATION_MODAL,
    onClose: () => dispatch(closeModal('confirmationModal')),
    onSave: () => action.onSave(),
  })
}

export function buildConfirmationModal(action: Object): Function {
  return (dispatch: Function) => {
    const content = dispatch(getConfirmationModalContent(action)) || {}
    dispatch(openModal(content, 'confirmationModal'))
  }
}

function getInfoModalContent(content: Object): Function {
  const {
    id,
    title,
    saveButtonText,
    message,
    onSave,
  } = content
  return (dispatch: Function) => ({
    id,
    title,
    saveButtonText,
    message,
    type: CONFIRMATION_MODAL,
    hideCancel: true,
    onSave,
    onClose: () => dispatch(closeModal(id || CONFIRMATION_MODAL)),
  })
}

export function buildInfoModal(content: Object): Function {
  return (dispatch: Function) => {
    const infoModalContent = dispatch(getInfoModalContent(content)) || {}
    dispatch(openModal(infoModalContent, content.id || CONFIRMATION_MODAL))
  }
}

function getRenameModalContent(row: Object, listId: string, modalId: string, useSavePattern?: boolean): Function {
  return (dispatch: Function) => {
    const content = {
      id: modalId,
      type: 'RENAME_MODAL',
      title: 'buttons._RENAME_',
      description: 'singleInputModal._DESCRIPTION_',
      defaultValue: row.columnData.Description || '',
      saveButtonText: 'buttons._RENAME_',
      onSave: (input: string, onClose: Function) => {
        if (useSavePattern) {
          dispatch(modifyListItem({
            listItemId: row.id,
            listId,
            columnName: 'Description',
            value: input
          }))
          onClose()
        } else {
          dispatch(renameItem(row.id, listId, input, onClose))
        }
      },
      onClose: () => dispatch(closeModal(modalId)),
      confirm: () => dispatch(buildConfirmationModal({ onSave: () => dispatch(closeModal(modalId)) })),
    }
    return content
  }
}

export function buildRenameModal(renameModalParams: Object): Function {
  const { row, listId, useSavePattern } = renameModalParams

  return (dispatch: Function) => {
    const modalId = `rename-${listId}-${row.id}`
    const content = dispatch(getRenameModalContent(row, listId, modalId, useSavePattern)) || {}
    dispatch(openModal(content, modalId))
  }
}

export function openReportModal(key: string): Function {
  let requestType
  if (key === SPACES) {
    requestType = {
      getReportsRequestFn: spacesGetReportsRequest,
      getReportsWithReportTypeRequestFn: spacesGetReportsWithReportTypeRequest
    }
  } else if (key === ELEMENTS) {
    requestType = {
      getReportsRequestFn: buildingelementsGetReportsRequest,
      getReportsWithReportTypeRequestFn: buildingelementsGetReportsWithReportTypeRequest
    }
  } else if (key === WOP) {
    requestType = {
      getReportsRequestFn: wopGetReportsRequest,
      getReportsWithReportTypeRequestFn: wopGetReportsWithReportTypeRequest
    }
  } else if (key === '') { // no application set in the landing page for estimates report getting
    requestType = {
      getReportsRequestFn: estimatesGetReportsRequest,
      getReportsWithReportTypeRequestFn: estimatesGetReportsWithReportTypeRequest
    }
  }
  return (dispatch: Function) => {
    const getContent = (modalId: string) => ({
      id: modalId,
      type: MODAL_TYPE_CREATE_REPORT,
      title: 'widgets._CREATE_REPORT_',
      description: 'reportModal._SELECT_REPORTS_TO_DOWNLOAD_',
      saveButtonText: 'buttons._DOWNLOAD_',
      onClose: () => dispatch(closeModal(modalId)),
      contentProps: requestType
    })

    const modalId = 'SpacesReportModal'
    dispatch(openModal(getContent(modalId), modalId))
  }
}

function getCreateModalContent(rowType: string, parentId: string, modalId: string): Function {
  return (dispatch: Function) => {
    const content = {
      id: modalId,
      type: 'CREATE_ITEM_MODAL',
      title: `singleInputModal._CREATE_${rowType.toUpperCase()}_TITLE_`,
      description: 'singleInputModal._DESCRIPTION_',
      defaultValue: `singleInputModal._NEW_${rowType.toUpperCase()}_`,
      saveButtonText: 'buttons._ADD_',
      onSave: (value: string, onClose: Function) => dispatch(createItem(rowType, parentId, value, onClose)),
      onClose: () => dispatch(closeModal(modalId)),
      confirm: () => dispatch(buildConfirmationModal({ onSave: () => dispatch(closeModal(modalId)) })),
    }
    return content
  }
}
export function buildCreateModal(rowType: string, parentId: string, listId: string): Function {
  return (dispatch: Function) => {
    const modalId = `createModal-${listId}-${parentId || rowType}`
    const content = dispatch(getCreateModalContent(rowType, parentId, modalId)) || {}
    dispatch(openModal(content, modalId))
  }
}

function getWopCreateModalContent(rowType: string, parentId: number, modalId: string, onSaveCb: Function): Function {
  return (dispatch: Function) => {
    const content = {
      id: modalId,
      type: 'CREATE_ITEM_MODAL',
      title: `singleInputModal._CREATE_${rowType.toUpperCase()}_TITLE_`,
      description: 'singleInputModal._DESCRIPTION_',
      defaultValue: `singleInputModal._NEW_${rowType.toUpperCase()}_`,
      saveButtonText: 'buttons._ADD_',
      onSave: parentId
        ? (value: string, onClose: Function) => { dispatch(createWopItem(rowType, parentId, value, onClose, onSaveCb)) }
        : (value: string, onClose: Function) => { dispatch(createWopRootItem(rowType, value, onClose, onSaveCb)) },
      onClose: () => dispatch(closeModal(modalId)),
      confirm: () => dispatch(buildConfirmationModal({ onSave: () => { dispatch(closeModal(modalId)) } })),
    }
    return content
  }
}
export function buildWopCreateModal(rowType: string, parentId: number, listId: string, onSaveCb: Function): Function {
  return (dispatch: Function) => {
    const modalId = `createModal-${listId}-${parentId || rowType}`
    const content = dispatch(getWopCreateModalContent(rowType, parentId, modalId, onSaveCb)) || {}
    dispatch(openModal(content, modalId))
  }
}

function getAttachItemModalContent(rowId: string, assemblyId: string, modalId: string): Function {
  return (dispatch: Function) => {
    const content = {
      title: 'listItemDialog._CONFIRMADD_PRICEITEM_',
      type: ADD_PRICE_ITEM_TO_ASSEMBLY_MODAL,
      saveButtonText: 'buttons._ATTACH_ITEM_',
      onClose: () => dispatch(closeModal(modalId)),
      onSave: (pricelistItemId: string) => {
        postPriceitemWithIdRequest({ path: { id: rowId }, body: {}, query: { pricelistItemId, assemblyId } }, {}, () => {
          dispatch(postPolling())
        })
      },
      contentProps: {
        requestDefinition: getAssemblyPricelistWithIdRequestDefinitions({ requestArgs: { path: { id: assemblyId } } })
      }
    }
    return content
  }
}

export function buildAttachItemModal(rowId: string, assemblyId: string): Function {
  return (dispatch: Function) => {
    const modalId = `attach-${rowId}`
    const content = dispatch(getAttachItemModalContent(rowId, assemblyId, modalId)) || {}
    dispatch(openModal(content, modalId))
  }
}

function getAttachSpaceModalContent(row: Object, modalId: string): Function {
  return (dispatch: Function) => {
    switch (row.type) {
      case 'function': {
        const content = {
          title: 'importFromWopCalculation._IMPORT_SPACE_',
          type: IMPORT_FROM_WOP_MODAL,
          saveButtonText: 'importFromWopCalculation._IMPORT_SINGLE_WOP_FUNCTION_',
          onClose: () => dispatch(closeModal(modalId)),
          onSave: (value: string) => {
            postSpaceScheduleWithEstimateIdRequest({ body: { selectedIds: [row.id] }, query: { attachToId: value } }, {}, () => {
              dispatch(postPolling())
            })
          },
          contentProps: {
            requestDefinition: getImportScheduleItemsCanAttachToWithItemIdRequestDefinitions({ requestArgs: { path: { itemId: row.id } } }),
            message: 'importFromWopCalculation._IMPORT_SPACE_DESCRIPTION_'
          }
        }
        return content
      }
      case 'spaceGroup': {
        const content = {
          title: 'importFromWopCalculation._ATTACH_SPACE_GROUP_',
          type: ATTACH_SPACE_GROUP_MODAL,
          saveButtonText: 'importFromWopCalculation._IMPORT_FROM_WOP_ESTIMATE_',
          onClose: () => dispatch(closeModal(modalId)),
          onSave: (value: string) => {
            postSpaceScheduleWithEstimateIdRequest({ body: { selectedIds: [row.id] }, query: { attachToId: value } }, {}, () => {
              dispatch(postPolling())
            })
          },
          contentProps: {
            requestDefinition: getImportScheduleItemsCanAttachToWithItemIdRequestDefinitions({ requestArgs: { path: { itemId: row.id } } }),
            message: 'importFromWopCalculation._ATTACH_SPACE_GROUP_MESSAGE_'
          }
        }
        return content
      }
      case 'space': {
        const content = {
          title: 'importFromWopCalculation._ATTACH_SPACE_',
          type: ATTACH_SPACE_MODAL,
          saveButtonText: 'importFromWopCalculation._IMPORT_FROM_WOP_ESTIMATE_',
          onClose: () => dispatch(closeModal(modalId)),
          onSave: (value: string) => {
            postSpaceScheduleWithEstimateIdRequest({ body: { selectedIds: [row.id] }, query: { attachToId: value } }, {}, () => {
              dispatch(postPolling())
            })
          },
          contentProps: {
            requestDefinition: getImportScheduleItemsCanAttachToWithItemIdRequestDefinitions({ requestArgs: { path: { itemId: row.id } } }),
            message: 'importFromWopCalculation._ATTACH_SPACE_MESSAGE_'
          }
        }
        return content
      }
      default: {
        return null
      }
    }
  }
}

export function buildAttachSpaceModal(row: Object): Function {
  return (dispatch: Function) => {
    const modalId = `attach-${row.id}`
    const content = dispatch(getAttachSpaceModalContent(row, modalId)) || {}
    dispatch(openModal(content, modalId))
  }
}

function getAddItemSpaceAssembly(rowId: string, spaceId: string, assemblyId: string, listItems: TVDListItems, modalId: string): Function {
  return (dispatch: Function) => {
    const content = {
      title: 'listItemDialog._SPACES_ASSEMBLY_ADD_PRICEITEM_',
      type: ADD_PRICE_ITEM_TO_ASSEMBLY_MODAL,
      saveButtonText: 'listItemDialog._SPACES_ASSEMBLY_ADD_PRICEITEM_',
      onClose: () => dispatch(closeModal(modalId)),
      onSave: (pricelistItemId: string, selectedValue: Object) => {
        const surfaceId = listItems[assemblyId].parentId
        if (!surfaceId) {
          console.error(`Could not find parentId to be used as surfaceId with assemblyId of ${assemblyId}`)
          return
        }
        postSpacesSurfacesAssemblyItemsWithAssemblyIdRequest(
          {
            path: { spaceId, assemblyId, },
            query: { pricelistItemId: selectedValue.value, surfaceId },
            body: {}
          },
          {},
          () => { dispatch(postPolling()) }
        )
      },
      contentProps: {
        requestDefinition: getSpacesSurfacesPricelistWithAssemblyIdRequestDefinitions({ requestArgs: { path: { spaceId, assemblyId } } })
      }
    }
    return content
  }
}

export function buildAddItemSpaceAssembly(rowId: string, spaceId: string, assemblyId: string, listItems: TVDListItems): Function {
  return (dispatch: Function) => {
    const modalId = `add-${rowId}`
    const content = dispatch(getAddItemSpaceAssembly(rowId, spaceId, assemblyId, listItems, modalId)) || {}
    dispatch(openModal(content, modalId))
  }
}

function getDeleteModalContent(
  rowId: string | number,
  listId: string,
  modalId: string,
  application?: string,
  updateSentientIds?: Array<string>, // list of sentient ids that will be updated after GARs are run
  disablePostPolling?: boolean, // to disable post polling from running after GARs are done
): Function {
  return (dispatch: Function, getState: Function) => {
    const { list } = getState()
    const { listItems } = list[listId]
    const listHasSelectedRows = findKey(listItems, 'selected')
    const row = listItems[rowId]
    const amount = (filter(listItems, (obj: Object) => obj.selected).length)
    const { type } = row
    const confirmationTranslateKey = listHasSelectedRows ? 'MULTIPLE' : type.toUpperCase()
    const content = {
      saveButtonText: 'buttons._DELETE_',
      message: `listItemDialog._CONFIRMDELETE_${confirmationTranslateKey}_`,
      amount,
      type: CONFIRMATION_MODAL,
      onClose: () => dispatch(closeModal(modalId)),
      onSave: () => {
        dispatch(deleteItem(rowId, type, listId, application, updateSentientIds, disablePostPolling))
      }

    }
    return content
  }
}

export function buildDeleteModal(
  rowId: string | number,
  listId: string, application?: string,
  updateSentientIds?: Array<string>, // list of sentient ids that will be updated after GARs are run
  disablePostPolling?: boolean, // to disable post polling from running after GARs are done
): Function {
  return (dispatch: Function) => {
    const modalId = `deleteModal-${listId}-${rowId}`
    const content = dispatch(getDeleteModalContent(rowId, listId, modalId, application, updateSentientIds, disablePostPolling)) || {}
    if (!isEmpty(content)) {
      dispatch(openModal(content, modalId))
    }
  }
}

export function createUserDefinedEquipmentModal(onSave: Function, row: Object): Function {
  return (dispatch: Function) => {
    const modalId = 'createUserDefinedEquipment'
    const content = {
      title: 'equipmentModal._CREATE_EQUIPMENT_',
      type: MODAL_TYPE_CREATE_USER_DEFINED_EQUIPMENT,
      onSave,
      row,
    }
    dispatch(openModal(content, modalId))
  }
}

export function createUserDefinedSurfaceModal(onSave: Function, row: Object): Function {
  return (dispatch: Function) => {
    const modalId = 'createUserDefinedSurface'
    const content = {
      title: 'surfaceModal._CREATE_SURFACE_',
      type: MODAL_TYPE_CREATE_USER_DEFINED_SURFACE,
      onSave,
      row,
    }
    dispatch(openModal(content, modalId))
  }
}

export function buildSettingsModal(): Function {
  return (dispatch: Function) => {
    const modalId = MODAL_TYPE_SETTINGS
    const content = {
      title: 'settings._SETTINGS_',
      type: MODAL_TYPE_SETTINGS,
      containerType: 'dialog'
    }
    dispatch(openModal(content, modalId))
  }
}

function getCanMoveToRequestDefinitionsByApplication(application: string, calculation: string, type: string, id: string): Function | null {
  const noMatchingCaseCb = () => {
    console.error(`No can-move-to url for application: "${application}" and type "${type}"`)
    return null
  }
  switch (application) {
    case ELEMENTS:
      switch (type.toLowerCase()) {
        case HEADING.toLowerCase(): {
          return getHeadingCanMoveToWithIdRequestDefinitions({ requestArgs: { path: { id } } })
        }
        case PRICEITEM.toLowerCase(): {
          return getPriceitemCanMoveToWithIdRequestDefinitions({ requestArgs: { path: { id } } })
        }
        case ASSEMBLY.toLowerCase(): {
          return getAssemblyCanMoveToWithIdRequestDefinitions({ requestArgs: { path: { id } } })
        }
        default: return noMatchingCaseCb()
      }
    case SPACES:
      switch (type.toLowerCase()) {
        case SPACEGROUP.toLowerCase(): {
          return getSpaceScheduleSpaceGroupsCanMoveToWithIdRequestDefinitions({ requestArgs: { path: { id } } })
        }
        case SPACE.toLowerCase(): {
          return getSpaceScheduleSpacesCanMoveToWithIdRequestDefinitions({ requestArgs: { path: { id } } })
        }
        case FUNCTION.toLowerCase(): {
          return getSpaceScheduleFunctionsCanMoveToWithIdRequestDefinitions({ requestArgs: { path: { id } } })
        }
        default: return noMatchingCaseCb()
      }
    default:
      return noMatchingCaseCb()
  }
}

export function buildMoveModal({ row, listId, multiple }: Object): Function {
  const { id, type } = row
  const modalId = `moveRow-${type}`
  const translateKey = multiple ? `${row.type}s`.toUpperCase() : row.type.toUpperCase()
  return (dispatch: Function, getState: Function) => {
    const { app } = getState()
    const { calculation, application } = app
    const content = {
      title: `listItemDialog._MOVE_${translateKey}_`,
      message: `listItemDialog._SELECT_${type.toUpperCase()}_TARGET_`,
      saveButtonText: 'buttons._MOVE_',
      type: MOVE_BUILDING_MODAL,
      onClose: () => dispatch(closeModal(modalId)),
      onSave: (selected: string) =>
        dispatch(moveItem(
          type,
          selected,
          id,
          calculation,
          listId
        )),
      contentProps: {
        message: `listItemDialog._SELECT_${type.toUpperCase()}_TARGET_`,
        requestDefinition: getCanMoveToRequestDefinitionsByApplication(application, calculation, type, id),
        func: (selected: string) =>
          dispatch(moveItem(
            type,
            selected,
            id,
            calculation,
            listId
          ))
      }
    }

    dispatch(openModal(content, modalId))
  }
}

export const INCREMENT_MODAL_COUNT = 'INCREMENT_MODAL_COUNT'
export function incrementModalCount(): Object {
  return {
    type: INCREMENT_MODAL_COUNT
  }
}

export const DECREMENT_MODAL_COUNT = 'DECREMENT_MODAL_COUNT'
export function decrementModalCount(): Object {
  return {
    type: DECREMENT_MODAL_COUNT
  }
}

export const CLEAR_MODAL_COUNT = 'CLEAR_MODAL_COUNT'
export function clearModalCount(): Object {
  return {
    type: CLEAR_MODAL_COUNT
  }
}

export function buildOpenPricingAttributeEdit(contentProps: Object, title: string): Function {
  return (dispatch: Function) => {
    dispatch(openModal({
      type: MODAL_TYPE_PRICING_ATTRIBUTE_EDIT,
      containerType: 'modal',
      contentProps: {
        ...contentProps,
        close: () => dispatch(closeModal(contentProps.id)),
        save: (requestArgs: {| data: Object |}) => {
          // atm only posting assemblies from <CreateFromPricing/> with the help of <AttributeEdit />
          postAssemblyWithIdRequest({
            path: {
              id: contentProps.parentId
            },
            query: {
              pricelistItemId: contentProps.pricelistItemId
            },
            body: requestArgs.data
          }, {}, () => {
            dispatch(closeModal(contentProps.id))
            dispatch(postPolling())
          })
        }
      },
      title,
    }, contentProps.id))
  }
}

export function buildPropertiesModal(contentProps: PropertiesModalContentProps, modalProps: Object): Function {
  return (dispatch: Function) => {
    const content = {
      ...contentProps,
      ...modalProps
    }
    dispatch(openModal(content, PROPERTIES_MODAL))
  }
}

export const SET_MODAL_TITLE = 'SET_MODAL_TITLE'
export const setModalTitle = (title: string, modalId: string) => ({
  type: SET_MODAL_TITLE,
  payload: { title, modalId }
})
