// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.
import React, { Component } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { filter, includes, find, map } from 'lodash'
import { withTranslation } from 'react-i18next'
import { withStyles } from '@material-ui/core'
import { typographyClasses, colors } from 'frontend-assets'
import Checkbox from '../CheckBox/CheckBox'
import type { TextButtonProps } from '../TextButton/TextButton'
import FooterButtons from '../../containers/widgets/FooterButtons/FooterButtons'
import { RESOURCE_CONFLICT_STATUS } from '../../../utils/requests/request'
import { openModal, closeModal } from '../../../actions/modals'
import { CONFIRMATION_MODAL } from '../../../constants/contentTypes'

const styles = () => ({
  reportModalWrapper: {
    display: 'flex',
    flexDirection: 'column'
  },
  description: {
    marginTop: '0',
    fontWeight: 400,
    ...typographyClasses.bodyBig,
    color: colors.dark80,
    marginBottom: '10px'
  },
  checkboxesWrapper: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '20px'
  },
  checkboxRow: {
    ...typographyClasses.bodyBig,
    display: 'flex',
    alignItems: 'center',
  },
  label: {
    ...typographyClasses.bodyBig,
    paddingLeft: '10px',
    margin: '5px 0',
    color: colors.dark80
  },
})
type HOCProps = {|
  t: Function, // translate
  classes: Object, // withStyles classes object
|}

type ReceivedProps = {|
  id: string, // id
  description: string, // text for subtitle
  getReportsRequestFn: (Object, Function) => void, // request function to get report types from API
  getReportsWithReportTypeRequestFn: (Object, Object, Function, Function | null, Object) => Promise<any>, // function to get reporsrts from
  onClose: Function, // close modal callback
|}

type DispatchProps = {|
  dispatchOpenModal: (content: Object, id: string) => void, // open a modal with desired content
  dispatchCloseModal: (id: string) => void, // function to close user modal
|}


type Props = {|
  ...HOCProps,
  ...ReceivedProps,
  ...DispatchProps,
|}

type State = {|
  reportTypeOptions: Array<TVDEnum>,
  selections: Array<string>
|}

const CREATE_REPORT_FAILED_MODAL_ID = 'CREATE_REPORT_FAILED_MODAL_ID'

export class CreateReportModal extends Component<Props, State> {
  static defaultProps = {
  }
  state = {
    reportTypeOptions: [],
    selections: []
  }

  componentDidMount = (): void => {
    const { getReportsRequestFn } = this.props
    getReportsRequestFn({}, (reportTypeOptions: Array<TVDEnum>) => {
      this.setState({ reportTypeOptions })
    })
  }

  getFailedReportsList = (failedReports: Array<TVDEnum>): React$Element<'div'> => (
    <div style={{ color: colors.defaultText }}>
      {
          failedReports.map((report: TVDEnum): React$Element<'div'> => <div key={report.value}>* {report.localizedName}</div>)
        }
    </div>
  )

  onSave = (): void => {
    const {
      getReportsWithReportTypeRequestFn,
      dispatchOpenModal,
      dispatchCloseModal,
      t
    } = this.props
    const { selections, reportTypeOptions } = this.state
    Promise.all(map(selections, (selection: string): Promise<any> => getReportsWithReportTypeRequestFn(
      { path: { reportType: selection } },
      {},
      (response: TVDRequestResponse) => {
        const { localizedName: pdfFileName } = find(reportTypeOptions, (option: TVDEnum) => option.value === selection)
        // to trigger browser actually downloading the pdf file we have to define a hidden link element
        // with href pointing to ObjectUrl built from response data blob
        const hiddenLinkElement = document.createElement('a')
        const ObjectUrl = URL.createObjectURL(response.data)
        hiddenLinkElement.href = ObjectUrl
        hiddenLinkElement.download = `${pdfFileName}.pdf` // declare name and extension of the file that is downloaded
        hiddenLinkElement.click() // initiate download sequence
        URL.revokeObjectURL(ObjectUrl)
      },
      null,
      // set responseType to blob to parse response into blob Object that can be downloaded by browser
      // API returns Content-Type: application/pdf in response headers so blob automatically uses that file format
      { responseType: 'blob' }
    ).catch((er: TVDErrorResponse): TVDErrorResponse => er))).then((responses: Array<TVDErrorResponse | TVDRequestResponse>) => {
      const failedReports = responses.reduce((result: any, response: TVDErrorResponse | TVDRequestResponse): Array<any> => {
        if (response.response && response.response.status === RESOURCE_CONFLICT_STATUS) {
          const failedReport = reportTypeOptions.find((reportType: TVDEnum): boolean => response.response.config.url.includes(reportType.value))
          return [...result, failedReport]
        }
        return result
      }, [])

      if (failedReports.length > 0) {
        const failedReportsList = this.getFailedReportsList(failedReports)
        dispatchOpenModal({
          type: CONFIRMATION_MODAL,
          saveButtonText: 'buttons._OK_',
          hideCancel: true,
          onSave: () => { dispatchCloseModal(CREATE_REPORT_FAILED_MODAL_ID) },
          title: 'createReportsFailed._ERROR_TITLE_',
          message: [
            t('createReportsFailed._ERROR_MESSAGE_'),
            failedReportsList
          ]
        }, CREATE_REPORT_FAILED_MODAL_ID)
      }
    })
    this.props.onClose()
  }

  handleChange = (value: string): void => {
    if (includes(this.state.selections, value)) {
      this.setState({ selections: filter(this.state.selections, (selection: string) => selection !== value) })
    } else {
      this.setState({ selections: [...this.state.selections, value] })
    }
  }

  getCheckBoxes = (): Array<React$Element<'div'>> => {
    const { classes } = this.props
    return map(this.state.reportTypeOptions, (option: TVDEnum) => (
      <div className={classes.checkboxRow} key={option.value}>
        <Checkbox name={option.localizedName} checked={this.state.selections[option.value]} onChange={() => { this.handleChange(option.value) }} />
        <p className={classes.label}>
          {option.localizedName}
        </p>
      </div>))
  }

  getButtons = (): Array<$Shape<TextButtonProps & { testId: string }>> => {
    const { t } = this.props
    return [
      {
        disabled: this.state.selections.length === 0,
        text: t('buttons._DOWNLOAD_REPORTS_'),
        onClick: this.onSave,
        id: 'footerButton-DOWNLOAD_REPORTS'
      },
      {
        variant: 'text',
        text: t('buttons._CANCEL_'),
        onClick: this.props.onClose,
        id: 'footerButton-CANCEL'
      },
    ]
  }

  render(): React$Element<any> {
    const { t, classes } = this.props
    return (
      <div className={classes.reportModalWrapper}>
        <h3 className={classes.description}>{t(this.props.description)}</h3>
        <div className={classes.checkboxesWrapper}>
          {this.getCheckBoxes()}
        </div>
        <FooterButtons items={this.getButtons()} />
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch: Function): DispatchProps => ({
  dispatchOpenModal: (content: Object, id: string) => {
    dispatch(openModal(content, id))
  },
  dispatchCloseModal: (id: string) => { dispatch(closeModal(id)) },
})

export default compose(
  withStyles(styles),
  withTranslation('translations'),
  connect(null, mapDispatchToProps)
)(CreateReportModal)
