// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.
/* eslint max-len: ["error", { "code": 350 }] */
/*
  Haahtela Spaces Renovation Api 30.0.4
  Renovation endpoints for Haahtela Spaces Api
  192.168.254.119:5010
*/

import requestFn from './../requests/request'
import { API_BASE_URL } from './../../constants/envConstants'
import parserMapper from './../parserMapper'
import { getState } from '../../store'

export const basePath = '/spaces-api/renovations'

export const DATA_GET_RENOVATION_RENOVATIONINFO_WITH_ESTIMATEID_START = 'DATA_GET_RENOVATION_RENOVATIONINFO_WITH_ESTIMATEID_START'
export const DATA_GET_RENOVATION_RENOVATIONINFO_WITH_ESTIMATEID_SUCCESSFUL = 'DATA_GET_RENOVATION_RENOVATIONINFO_WITH_ESTIMATEID_SUCCESSFUL'
export const DATA_GET_RENOVATION_RENOVATIONINFO_WITH_ESTIMATEID_FAIL = 'DATA_GET_RENOVATION_RENOVATIONINFO_WITH_ESTIMATEID_FAIL'

// Provides information about renovation.
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/spaces.yaml#/Renovation/get_renovation__estimateId__renovationInfo
export const getRenovationRenovationInfoWithEstimateIdRequest = (
  payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_RENOVATION_RENOVATIONINFO_WITH_ESTIMATEID_START',
      successful: 'DATA_GET_RENOVATION_RENOVATIONINFO_WITH_ESTIMATEID_SUCCESSFUL',
      fail: 'DATA_GET_RENOVATION_RENOVATIONINFO_WITH_ESTIMATEID_FAIL'
    },
    embeddedType: {
      nonEmbedded: {
        schemaPath: '#/definitions/HAL',
        schemaKey: 'HAL'
      }
    },
    fnName: 'getRenovationRenovationInfoWithEstimateIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/spaces-api/renovations`,
    method: 'GET',
    url: `/renovation/${options?.estimateId || store.app.calculation}/renovationInfo`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      nonEmbedded: {
        schemaPath: '#/definitions/HAL',
        schemaKey: 'HAL'
      }
    }, 'getRenovationRenovationInfoWithEstimateIdRequest', '/spaces-api/renovations'),
    actions: {
      start: {
        type: DATA_GET_RENOVATION_RENOVATIONINFO_WITH_ESTIMATEID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_RENOVATION_RENOVATIONINFO_WITH_ESTIMATEID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_RENOVATION_RENOVATIONINFO_WITH_ESTIMATEID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getRenovationRenovationInfoWithEstimateIdRequestDefinitions = (definitionsArguments: {| payload?: Object |}): {|
  ...TVDGARConfigWithoutRequestArgs, payload?: Object |} => ({
  fnName: 'getRenovationRenovationInfoWithEstimateIdRequest',
  basePath: '/spaces-api/renovations',
  payload: definitionsArguments.payload,
})


export const DATA_GET_RENOVATION_BUILDING_WITH_ESTIMATEID_START = 'DATA_GET_RENOVATION_BUILDING_WITH_ESTIMATEID_START'
export const DATA_GET_RENOVATION_BUILDING_WITH_ESTIMATEID_SUCCESSFUL = 'DATA_GET_RENOVATION_BUILDING_WITH_ESTIMATEID_SUCCESSFUL'
export const DATA_GET_RENOVATION_BUILDING_WITH_ESTIMATEID_FAIL = 'DATA_GET_RENOVATION_BUILDING_WITH_ESTIMATEID_FAIL'

// Returns a list of measure topics that currently have renovation measures for a building.
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/spaces.yaml#/Renovation/get_renovation__estimateId__building
export const getRenovationBuildingWithEstimateIdRequest = (
  requestArguments: getRenovationBuildingWithEstimateIdRequestSpacesArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_RENOVATION_BUILDING_WITH_ESTIMATEID_START',
      successful: 'DATA_GET_RENOVATION_BUILDING_WITH_ESTIMATEID_SUCCESSFUL',
      fail: 'DATA_GET_RENOVATION_BUILDING_WITH_ESTIMATEID_FAIL'
    },
    embeddedType: {
      items: {
        schemaPath: '#/definitions/ScheduleItem',
        schemaKey: 'ScheduleItem'
      }
    },
    fnName: 'getRenovationBuildingWithEstimateIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/spaces-api/renovations`,
    method: 'GET',
    url: `/renovation/${options?.estimateId || store.app.calculation}/building`,
    params: {
      includeAll: requestArguments.query?.['includeAll'],
      embedMeasures: requestArguments.query?.['embedMeasures'],
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      items: {
        schemaPath: '#/definitions/ScheduleItem',
        schemaKey: 'ScheduleItem'
      }
    }, 'getRenovationBuildingWithEstimateIdRequest', '/spaces-api/renovations'),
    actions: {
      start: {
        type: DATA_GET_RENOVATION_BUILDING_WITH_ESTIMATEID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_RENOVATION_BUILDING_WITH_ESTIMATEID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_RENOVATION_BUILDING_WITH_ESTIMATEID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getRenovationBuildingWithEstimateIdRequestDefinitions = (definitionsArguments: {| requestArgs: getRenovationBuildingWithEstimateIdRequestSpacesArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getRenovationBuildingWithEstimateIdRequestSpacesArguments, payload?: Object |} => ({
  fnName: 'getRenovationBuildingWithEstimateIdRequest',
  basePath: '/spaces-api/renovations',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_PATCH_RENOVATION_BUILDING_WITH_ESTIMATEID_START = 'DATA_PATCH_RENOVATION_BUILDING_WITH_ESTIMATEID_START'
export const DATA_PATCH_RENOVATION_BUILDING_WITH_ESTIMATEID_SUCCESSFUL = 'DATA_PATCH_RENOVATION_BUILDING_WITH_ESTIMATEID_SUCCESSFUL'
export const DATA_PATCH_RENOVATION_BUILDING_WITH_ESTIMATEID_FAIL = 'DATA_PATCH_RENOVATION_BUILDING_WITH_ESTIMATEID_FAIL'

// Update renovation column data for multiple measure topics.
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/spaces.yaml#/Renovation/patch_renovation__estimateId__building
export const patchRenovationBuildingWithEstimateIdRequest = (
  requestArguments: patchRenovationBuildingWithEstimateIdRequestSpacesArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_PATCH_RENOVATION_BUILDING_WITH_ESTIMATEID_START',
      successful: 'DATA_PATCH_RENOVATION_BUILDING_WITH_ESTIMATEID_SUCCESSFUL',
      fail: 'DATA_PATCH_RENOVATION_BUILDING_WITH_ESTIMATEID_FAIL'
    },
    embeddedType: null,
    fnName: 'patchRenovationBuildingWithEstimateIdRequest'
  }
  const config = {
    headers: { 'Content-Type': 'application/json' },
    baseURL: `${API_BASE_URL}/spaces-api/renovations`,
    method: 'PATCH',
    url: `/renovation/${options?.estimateId || store.app.calculation}/building`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: requestArguments.body
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'patchRenovationBuildingWithEstimateIdRequest', '/spaces-api/renovations'),
    actions: {
      start: {
        type: DATA_PATCH_RENOVATION_BUILDING_WITH_ESTIMATEID_START, payload, config, meta
      },
      successful: {
        type: DATA_PATCH_RENOVATION_BUILDING_WITH_ESTIMATEID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_PATCH_RENOVATION_BUILDING_WITH_ESTIMATEID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_GET_RENOVATION_BUILDING_WITH_ID_START = 'DATA_GET_RENOVATION_BUILDING_WITH_ID_START'
export const DATA_GET_RENOVATION_BUILDING_WITH_ID_SUCCESSFUL = 'DATA_GET_RENOVATION_BUILDING_WITH_ID_SUCCESSFUL'
export const DATA_GET_RENOVATION_BUILDING_WITH_ID_FAIL = 'DATA_GET_RENOVATION_BUILDING_WITH_ID_FAIL'

// Returns a single measure topic from building renovation list
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/spaces.yaml#/Renovation/get_renovation__estimateId__building__id_
export const getRenovationBuildingWithIdRequest = (
  requestArguments: getRenovationBuildingWithIdRequestSpacesArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_RENOVATION_BUILDING_WITH_ID_START',
      successful: 'DATA_GET_RENOVATION_BUILDING_WITH_ID_SUCCESSFUL',
      fail: 'DATA_GET_RENOVATION_BUILDING_WITH_ID_FAIL'
    },
    embeddedType: {
      nonEmbedded: {
        schemaPath: '#/definitions/ScheduleItem',
        schemaKey: 'ScheduleItem'
      }
    },
    fnName: 'getRenovationBuildingWithIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/spaces-api/renovations`,
    method: 'GET',
    url: `/renovation/${options?.estimateId || store.app.calculation}/building/${requestArguments.path['id']}`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      nonEmbedded: {
        schemaPath: '#/definitions/ScheduleItem',
        schemaKey: 'ScheduleItem'
      }
    }, 'getRenovationBuildingWithIdRequest', '/spaces-api/renovations'),
    actions: {
      start: {
        type: DATA_GET_RENOVATION_BUILDING_WITH_ID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_RENOVATION_BUILDING_WITH_ID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_RENOVATION_BUILDING_WITH_ID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getRenovationBuildingWithIdRequestDefinitions = (definitionsArguments: {| requestArgs: getRenovationBuildingWithIdRequestSpacesArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getRenovationBuildingWithIdRequestSpacesArguments, payload?: Object |} => ({
  fnName: 'getRenovationBuildingWithIdRequest',
  basePath: '/spaces-api/renovations',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_PATCH_RENOVATION_BUILDING_WITH_ID_START = 'DATA_PATCH_RENOVATION_BUILDING_WITH_ID_START'
export const DATA_PATCH_RENOVATION_BUILDING_WITH_ID_SUCCESSFUL = 'DATA_PATCH_RENOVATION_BUILDING_WITH_ID_SUCCESSFUL'
export const DATA_PATCH_RENOVATION_BUILDING_WITH_ID_FAIL = 'DATA_PATCH_RENOVATION_BUILDING_WITH_ID_FAIL'

// Update renovation column data for a measure topic
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/spaces.yaml#/Renovation/patch_renovation__estimateId__building__id_
export const patchRenovationBuildingWithIdRequest = (
  requestArguments: patchRenovationBuildingWithIdRequestSpacesArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_PATCH_RENOVATION_BUILDING_WITH_ID_START',
      successful: 'DATA_PATCH_RENOVATION_BUILDING_WITH_ID_SUCCESSFUL',
      fail: 'DATA_PATCH_RENOVATION_BUILDING_WITH_ID_FAIL'
    },
    embeddedType: null,
    fnName: 'patchRenovationBuildingWithIdRequest'
  }
  const config = {
    headers: { 'Content-Type': 'application/json' },
    baseURL: `${API_BASE_URL}/spaces-api/renovations`,
    method: 'PATCH',
    url: `/renovation/${options?.estimateId || store.app.calculation}/building/${requestArguments.path['id']}`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: requestArguments.body
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'patchRenovationBuildingWithIdRequest', '/spaces-api/renovations'),
    actions: {
      start: {
        type: DATA_PATCH_RENOVATION_BUILDING_WITH_ID_START, payload, config, meta
      },
      successful: {
        type: DATA_PATCH_RENOVATION_BUILDING_WITH_ID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_PATCH_RENOVATION_BUILDING_WITH_ID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_GET_RENOVATION_SITE_WITH_ESTIMATEID_START = 'DATA_GET_RENOVATION_SITE_WITH_ESTIMATEID_START'
export const DATA_GET_RENOVATION_SITE_WITH_ESTIMATEID_SUCCESSFUL = 'DATA_GET_RENOVATION_SITE_WITH_ESTIMATEID_SUCCESSFUL'
export const DATA_GET_RENOVATION_SITE_WITH_ESTIMATEID_FAIL = 'DATA_GET_RENOVATION_SITE_WITH_ESTIMATEID_FAIL'

// Returns a list of measure topics that currently have renovation values for the site.
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/spaces.yaml#/Renovation/get_renovation__estimateId__site
export const getRenovationSiteWithEstimateIdRequest = (
  requestArguments: getRenovationSiteWithEstimateIdRequestSpacesArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_RENOVATION_SITE_WITH_ESTIMATEID_START',
      successful: 'DATA_GET_RENOVATION_SITE_WITH_ESTIMATEID_SUCCESSFUL',
      fail: 'DATA_GET_RENOVATION_SITE_WITH_ESTIMATEID_FAIL'
    },
    embeddedType: {
      items: {
        schemaPath: '#/definitions/ScheduleItem',
        schemaKey: 'ScheduleItem'
      }
    },
    fnName: 'getRenovationSiteWithEstimateIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/spaces-api/renovations`,
    method: 'GET',
    url: `/renovation/${options?.estimateId || store.app.calculation}/site`,
    params: {
      includeAll: requestArguments.query?.['includeAll'],
      embedMeasures: requestArguments.query?.['embedMeasures'],
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      items: {
        schemaPath: '#/definitions/ScheduleItem',
        schemaKey: 'ScheduleItem'
      }
    }, 'getRenovationSiteWithEstimateIdRequest', '/spaces-api/renovations'),
    actions: {
      start: {
        type: DATA_GET_RENOVATION_SITE_WITH_ESTIMATEID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_RENOVATION_SITE_WITH_ESTIMATEID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_RENOVATION_SITE_WITH_ESTIMATEID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getRenovationSiteWithEstimateIdRequestDefinitions = (definitionsArguments: {| requestArgs: getRenovationSiteWithEstimateIdRequestSpacesArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getRenovationSiteWithEstimateIdRequestSpacesArguments, payload?: Object |} => ({
  fnName: 'getRenovationSiteWithEstimateIdRequest',
  basePath: '/spaces-api/renovations',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_PATCH_RENOVATION_SITE_WITH_ESTIMATEID_START = 'DATA_PATCH_RENOVATION_SITE_WITH_ESTIMATEID_START'
export const DATA_PATCH_RENOVATION_SITE_WITH_ESTIMATEID_SUCCESSFUL = 'DATA_PATCH_RENOVATION_SITE_WITH_ESTIMATEID_SUCCESSFUL'
export const DATA_PATCH_RENOVATION_SITE_WITH_ESTIMATEID_FAIL = 'DATA_PATCH_RENOVATION_SITE_WITH_ESTIMATEID_FAIL'

// Update renovation column data for multiple site measure topics.
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/spaces.yaml#/Renovation/patch_renovation__estimateId__site
export const patchRenovationSiteWithEstimateIdRequest = (
  requestArguments: patchRenovationSiteWithEstimateIdRequestSpacesArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_PATCH_RENOVATION_SITE_WITH_ESTIMATEID_START',
      successful: 'DATA_PATCH_RENOVATION_SITE_WITH_ESTIMATEID_SUCCESSFUL',
      fail: 'DATA_PATCH_RENOVATION_SITE_WITH_ESTIMATEID_FAIL'
    },
    embeddedType: null,
    fnName: 'patchRenovationSiteWithEstimateIdRequest'
  }
  const config = {
    headers: { 'Content-Type': 'application/json' },
    baseURL: `${API_BASE_URL}/spaces-api/renovations`,
    method: 'PATCH',
    url: `/renovation/${options?.estimateId || store.app.calculation}/site`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: requestArguments.body
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'patchRenovationSiteWithEstimateIdRequest', '/spaces-api/renovations'),
    actions: {
      start: {
        type: DATA_PATCH_RENOVATION_SITE_WITH_ESTIMATEID_START, payload, config, meta
      },
      successful: {
        type: DATA_PATCH_RENOVATION_SITE_WITH_ESTIMATEID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_PATCH_RENOVATION_SITE_WITH_ESTIMATEID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_GET_RENOVATION_SITE_WITH_ID_START = 'DATA_GET_RENOVATION_SITE_WITH_ID_START'
export const DATA_GET_RENOVATION_SITE_WITH_ID_SUCCESSFUL = 'DATA_GET_RENOVATION_SITE_WITH_ID_SUCCESSFUL'
export const DATA_GET_RENOVATION_SITE_WITH_ID_FAIL = 'DATA_GET_RENOVATION_SITE_WITH_ID_FAIL'

// Returns a single measure topic from site's renovation list
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/spaces.yaml#/Renovation/get_renovation__estimateId__site__id_
export const getRenovationSiteWithIdRequest = (
  requestArguments: getRenovationSiteWithIdRequestSpacesArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_RENOVATION_SITE_WITH_ID_START',
      successful: 'DATA_GET_RENOVATION_SITE_WITH_ID_SUCCESSFUL',
      fail: 'DATA_GET_RENOVATION_SITE_WITH_ID_FAIL'
    },
    embeddedType: {
      nonEmbedded: {
        schemaPath: '#/definitions/ScheduleItem',
        schemaKey: 'ScheduleItem'
      }
    },
    fnName: 'getRenovationSiteWithIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/spaces-api/renovations`,
    method: 'GET',
    url: `/renovation/${options?.estimateId || store.app.calculation}/site/${requestArguments.path['id']}`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      nonEmbedded: {
        schemaPath: '#/definitions/ScheduleItem',
        schemaKey: 'ScheduleItem'
      }
    }, 'getRenovationSiteWithIdRequest', '/spaces-api/renovations'),
    actions: {
      start: {
        type: DATA_GET_RENOVATION_SITE_WITH_ID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_RENOVATION_SITE_WITH_ID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_RENOVATION_SITE_WITH_ID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getRenovationSiteWithIdRequestDefinitions = (definitionsArguments: {| requestArgs: getRenovationSiteWithIdRequestSpacesArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getRenovationSiteWithIdRequestSpacesArguments, payload?: Object |} => ({
  fnName: 'getRenovationSiteWithIdRequest',
  basePath: '/spaces-api/renovations',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_PATCH_RENOVATION_SITE_WITH_ID_START = 'DATA_PATCH_RENOVATION_SITE_WITH_ID_START'
export const DATA_PATCH_RENOVATION_SITE_WITH_ID_SUCCESSFUL = 'DATA_PATCH_RENOVATION_SITE_WITH_ID_SUCCESSFUL'
export const DATA_PATCH_RENOVATION_SITE_WITH_ID_FAIL = 'DATA_PATCH_RENOVATION_SITE_WITH_ID_FAIL'

// Update renovation column data for a measure topic
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/spaces.yaml#/Renovation/patch_renovation__estimateId__site__id_
export const patchRenovationSiteWithIdRequest = (
  requestArguments: patchRenovationSiteWithIdRequestSpacesArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_PATCH_RENOVATION_SITE_WITH_ID_START',
      successful: 'DATA_PATCH_RENOVATION_SITE_WITH_ID_SUCCESSFUL',
      fail: 'DATA_PATCH_RENOVATION_SITE_WITH_ID_FAIL'
    },
    embeddedType: null,
    fnName: 'patchRenovationSiteWithIdRequest'
  }
  const config = {
    headers: { 'Content-Type': 'application/json' },
    baseURL: `${API_BASE_URL}/spaces-api/renovations`,
    method: 'PATCH',
    url: `/renovation/${options?.estimateId || store.app.calculation}/site/${requestArguments.path['id']}`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: requestArguments.body
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'patchRenovationSiteWithIdRequest', '/spaces-api/renovations'),
    actions: {
      start: {
        type: DATA_PATCH_RENOVATION_SITE_WITH_ID_START, payload, config, meta
      },
      successful: {
        type: DATA_PATCH_RENOVATION_SITE_WITH_ID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_PATCH_RENOVATION_SITE_WITH_ID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_GET_RENOVATION_SPACES_WITH_ESTIMATEID_START = 'DATA_GET_RENOVATION_SPACES_WITH_ESTIMATEID_START'
export const DATA_GET_RENOVATION_SPACES_WITH_ESTIMATEID_SUCCESSFUL = 'DATA_GET_RENOVATION_SPACES_WITH_ESTIMATEID_SUCCESSFUL'
export const DATA_GET_RENOVATION_SPACES_WITH_ESTIMATEID_FAIL = 'DATA_GET_RENOVATION_SPACES_WITH_ESTIMATEID_FAIL'

// Returns a renovation schedule for spaces.
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/spaces.yaml#/Renovation/get_renovation__estimateId__spaces
export const getRenovationSpacesWithEstimateIdRequest = (
  payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_RENOVATION_SPACES_WITH_ESTIMATEID_START',
      successful: 'DATA_GET_RENOVATION_SPACES_WITH_ESTIMATEID_SUCCESSFUL',
      fail: 'DATA_GET_RENOVATION_SPACES_WITH_ESTIMATEID_FAIL'
    },
    embeddedType: {
      items: {
        schemaPath: '#/definitions/ScheduleItem',
        schemaKey: 'ScheduleItem'
      }
    },
    fnName: 'getRenovationSpacesWithEstimateIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/spaces-api/renovations`,
    method: 'GET',
    url: `/renovation/${options?.estimateId || store.app.calculation}/spaces`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      items: {
        schemaPath: '#/definitions/ScheduleItem',
        schemaKey: 'ScheduleItem'
      }
    }, 'getRenovationSpacesWithEstimateIdRequest', '/spaces-api/renovations'),
    actions: {
      start: {
        type: DATA_GET_RENOVATION_SPACES_WITH_ESTIMATEID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_RENOVATION_SPACES_WITH_ESTIMATEID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_RENOVATION_SPACES_WITH_ESTIMATEID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getRenovationSpacesWithEstimateIdRequestDefinitions = (definitionsArguments: {| payload?: Object |}): {|
  ...TVDGARConfigWithoutRequestArgs, payload?: Object |} => ({
  fnName: 'getRenovationSpacesWithEstimateIdRequest',
  basePath: '/spaces-api/renovations',
  payload: definitionsArguments.payload,
})


export const DATA_PATCH_RENOVATION_SPACES_WITH_ESTIMATEID_START = 'DATA_PATCH_RENOVATION_SPACES_WITH_ESTIMATEID_START'
export const DATA_PATCH_RENOVATION_SPACES_WITH_ESTIMATEID_SUCCESSFUL = 'DATA_PATCH_RENOVATION_SPACES_WITH_ESTIMATEID_SUCCESSFUL'
export const DATA_PATCH_RENOVATION_SPACES_WITH_ESTIMATEID_FAIL = 'DATA_PATCH_RENOVATION_SPACES_WITH_ESTIMATEID_FAIL'

// Update renovation measures for multiple spaces at once.
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/spaces.yaml#/Renovation/patch_renovation__estimateId__spaces
export const patchRenovationSpacesWithEstimateIdRequest = (
  requestArguments: patchRenovationSpacesWithEstimateIdRequestSpacesArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_PATCH_RENOVATION_SPACES_WITH_ESTIMATEID_START',
      successful: 'DATA_PATCH_RENOVATION_SPACES_WITH_ESTIMATEID_SUCCESSFUL',
      fail: 'DATA_PATCH_RENOVATION_SPACES_WITH_ESTIMATEID_FAIL'
    },
    embeddedType: null,
    fnName: 'patchRenovationSpacesWithEstimateIdRequest'
  }
  const config = {
    headers: { 'Content-Type': 'application/json' },
    baseURL: `${API_BASE_URL}/spaces-api/renovations`,
    method: 'PATCH',
    url: `/renovation/${options?.estimateId || store.app.calculation}/spaces`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: requestArguments.body
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'patchRenovationSpacesWithEstimateIdRequest', '/spaces-api/renovations'),
    actions: {
      start: {
        type: DATA_PATCH_RENOVATION_SPACES_WITH_ESTIMATEID_START, payload, config, meta
      },
      successful: {
        type: DATA_PATCH_RENOVATION_SPACES_WITH_ESTIMATEID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_PATCH_RENOVATION_SPACES_WITH_ESTIMATEID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_GET_RENOVATION_SPACES_WITH_ID_START = 'DATA_GET_RENOVATION_SPACES_WITH_ID_START'
export const DATA_GET_RENOVATION_SPACES_WITH_ID_SUCCESSFUL = 'DATA_GET_RENOVATION_SPACES_WITH_ID_SUCCESSFUL'
export const DATA_GET_RENOVATION_SPACES_WITH_ID_FAIL = 'DATA_GET_RENOVATION_SPACES_WITH_ID_FAIL'

// Returns a space that can be renovated.
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/spaces.yaml#/Renovation/get_renovation__estimateId__spaces__id_
export const getRenovationSpacesWithIdRequest = (
  requestArguments: getRenovationSpacesWithIdRequestSpacesArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_RENOVATION_SPACES_WITH_ID_START',
      successful: 'DATA_GET_RENOVATION_SPACES_WITH_ID_SUCCESSFUL',
      fail: 'DATA_GET_RENOVATION_SPACES_WITH_ID_FAIL'
    },
    embeddedType: {
      nonEmbedded: {
        schemaPath: '#/definitions/ScheduleItem',
        schemaKey: 'ScheduleItem'
      }
    },
    fnName: 'getRenovationSpacesWithIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/spaces-api/renovations`,
    method: 'GET',
    url: `/renovation/${options?.estimateId || store.app.calculation}/spaces/${requestArguments.path['id']}`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      nonEmbedded: {
        schemaPath: '#/definitions/ScheduleItem',
        schemaKey: 'ScheduleItem'
      }
    }, 'getRenovationSpacesWithIdRequest', '/spaces-api/renovations'),
    actions: {
      start: {
        type: DATA_GET_RENOVATION_SPACES_WITH_ID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_RENOVATION_SPACES_WITH_ID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_RENOVATION_SPACES_WITH_ID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getRenovationSpacesWithIdRequestDefinitions = (definitionsArguments: {| requestArgs: getRenovationSpacesWithIdRequestSpacesArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getRenovationSpacesWithIdRequestSpacesArguments, payload?: Object |} => ({
  fnName: 'getRenovationSpacesWithIdRequest',
  basePath: '/spaces-api/renovations',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_PATCH_RENOVATION_SPACES_WITH_ID_START = 'DATA_PATCH_RENOVATION_SPACES_WITH_ID_START'
export const DATA_PATCH_RENOVATION_SPACES_WITH_ID_SUCCESSFUL = 'DATA_PATCH_RENOVATION_SPACES_WITH_ID_SUCCESSFUL'
export const DATA_PATCH_RENOVATION_SPACES_WITH_ID_FAIL = 'DATA_PATCH_RENOVATION_SPACES_WITH_ID_FAIL'

// Update renovation column data for a space.
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/spaces.yaml#/Renovation/patch_renovation__estimateId__spaces__id_
export const patchRenovationSpacesWithIdRequest = (
  requestArguments: patchRenovationSpacesWithIdRequestSpacesArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_PATCH_RENOVATION_SPACES_WITH_ID_START',
      successful: 'DATA_PATCH_RENOVATION_SPACES_WITH_ID_SUCCESSFUL',
      fail: 'DATA_PATCH_RENOVATION_SPACES_WITH_ID_FAIL'
    },
    embeddedType: null,
    fnName: 'patchRenovationSpacesWithIdRequest'
  }
  const config = {
    headers: { 'Content-Type': 'application/json' },
    baseURL: `${API_BASE_URL}/spaces-api/renovations`,
    method: 'PATCH',
    url: `/renovation/${options?.estimateId || store.app.calculation}/spaces/${requestArguments.path['id']}`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: requestArguments.body
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'patchRenovationSpacesWithIdRequest', '/spaces-api/renovations'),
    actions: {
      start: {
        type: DATA_PATCH_RENOVATION_SPACES_WITH_ID_START, payload, config, meta
      },
      successful: {
        type: DATA_PATCH_RENOVATION_SPACES_WITH_ID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_PATCH_RENOVATION_SPACES_WITH_ID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_GET_RENOVATION_SPACES_MEASURE_TOPICS_WITH_ID_START = 'DATA_GET_RENOVATION_SPACES_MEASURE_TOPICS_WITH_ID_START'
export const DATA_GET_RENOVATION_SPACES_MEASURE_TOPICS_WITH_ID_SUCCESSFUL = 'DATA_GET_RENOVATION_SPACES_MEASURE_TOPICS_WITH_ID_SUCCESSFUL'
export const DATA_GET_RENOVATION_SPACES_MEASURE_TOPICS_WITH_ID_FAIL = 'DATA_GET_RENOVATION_SPACES_MEASURE_TOPICS_WITH_ID_FAIL'

// Returns list of measure topics as Enumerals that can be applied to a particular space
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/spaces.yaml#/Renovation/get_renovation__estimateId__spaces__id__measure_topics
export const getRenovationSpacesMeasureTopicsWithIdRequest = (
  requestArguments: getRenovationSpacesMeasureTopicsWithIdRequestSpacesArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_RENOVATION_SPACES_MEASURE_TOPICS_WITH_ID_START',
      successful: 'DATA_GET_RENOVATION_SPACES_MEASURE_TOPICS_WITH_ID_SUCCESSFUL',
      fail: 'DATA_GET_RENOVATION_SPACES_MEASURE_TOPICS_WITH_ID_FAIL'
    },
    embeddedType: {
      topics: {
        schemaPath: '#/definitions/Enumeral',
        schemaKey: 'Enumeral'
      }
    },
    fnName: 'getRenovationSpacesMeasureTopicsWithIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/spaces-api/renovations`,
    method: 'GET',
    url: `/renovation/${options?.estimateId || store.app.calculation}/spaces/${requestArguments.path['id']}/measure-topics`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      topics: {
        schemaPath: '#/definitions/Enumeral',
        schemaKey: 'Enumeral'
      }
    }, 'getRenovationSpacesMeasureTopicsWithIdRequest', '/spaces-api/renovations'),
    actions: {
      start: {
        type: DATA_GET_RENOVATION_SPACES_MEASURE_TOPICS_WITH_ID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_RENOVATION_SPACES_MEASURE_TOPICS_WITH_ID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_RENOVATION_SPACES_MEASURE_TOPICS_WITH_ID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getRenovationSpacesMeasureTopicsWithIdRequestDefinitions = (definitionsArguments: {| requestArgs: getRenovationSpacesMeasureTopicsWithIdRequestSpacesArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getRenovationSpacesMeasureTopicsWithIdRequestSpacesArguments, payload?: Object |} => ({
  fnName: 'getRenovationSpacesMeasureTopicsWithIdRequest',
  basePath: '/spaces-api/renovations',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_GET_RENOVATION_SPACES_MEASURE_TOPICS_MEASURES_WITH_TOPICID_START = 'DATA_GET_RENOVATION_SPACES_MEASURE_TOPICS_MEASURES_WITH_TOPICID_START'
export const DATA_GET_RENOVATION_SPACES_MEASURE_TOPICS_MEASURES_WITH_TOPICID_SUCCESSFUL = 'DATA_GET_RENOVATION_SPACES_MEASURE_TOPICS_MEASURES_WITH_TOPICID_SUCCESSFUL'
export const DATA_GET_RENOVATION_SPACES_MEASURE_TOPICS_MEASURES_WITH_TOPICID_FAIL = 'DATA_GET_RENOVATION_SPACES_MEASURE_TOPICS_MEASURES_WITH_TOPICID_FAIL'

// Returns renovation measures under a measure topic for a space
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/spaces.yaml#/Renovation/get_renovation__estimateId__spaces__id__measure_topics__topicId__measures
export const getRenovationSpacesMeasureTopicsMeasuresWithTopicIdRequest = (
  requestArguments: getRenovationSpacesMeasureTopicsMeasuresWithTopicIdRequestSpacesArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_RENOVATION_SPACES_MEASURE_TOPICS_MEASURES_WITH_TOPICID_START',
      successful: 'DATA_GET_RENOVATION_SPACES_MEASURE_TOPICS_MEASURES_WITH_TOPICID_SUCCESSFUL',
      fail: 'DATA_GET_RENOVATION_SPACES_MEASURE_TOPICS_MEASURES_WITH_TOPICID_FAIL'
    },
    embeddedType: {
      nonEmbedded: {
        schemaPath: '#/definitions/Enumeral',
        schemaKey: 'Enumeral'
      }
    },
    fnName: 'getRenovationSpacesMeasureTopicsMeasuresWithTopicIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/spaces-api/renovations`,
    method: 'GET',
    url: `/renovation/${options?.estimateId || store.app.calculation}/spaces/${requestArguments.path['id']}/measure-topics/${requestArguments.path['topicId']}/measures`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      nonEmbedded: {
        schemaPath: '#/definitions/Enumeral',
        schemaKey: 'Enumeral'
      }
    }, 'getRenovationSpacesMeasureTopicsMeasuresWithTopicIdRequest', '/spaces-api/renovations'),
    actions: {
      start: {
        type: DATA_GET_RENOVATION_SPACES_MEASURE_TOPICS_MEASURES_WITH_TOPICID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_RENOVATION_SPACES_MEASURE_TOPICS_MEASURES_WITH_TOPICID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_RENOVATION_SPACES_MEASURE_TOPICS_MEASURES_WITH_TOPICID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getRenovationSpacesMeasureTopicsMeasuresWithTopicIdRequestDefinitions = (definitionsArguments: {| requestArgs: getRenovationSpacesMeasureTopicsMeasuresWithTopicIdRequestSpacesArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getRenovationSpacesMeasureTopicsMeasuresWithTopicIdRequestSpacesArguments, payload?: Object |} => ({
  fnName: 'getRenovationSpacesMeasureTopicsMeasuresWithTopicIdRequest',
  basePath: '/spaces-api/renovations',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_GET_RENOVATION_SPACES_MEASURE_TOPICS_MEASURES_PROPERTIES_WITH_MEASUREID_START = 'DATA_GET_RENOVATION_SPACES_MEASURE_TOPICS_MEASURES_PROPERTIES_WITH_MEASUREID_START'
export const DATA_GET_RENOVATION_SPACES_MEASURE_TOPICS_MEASURES_PROPERTIES_WITH_MEASUREID_SUCCESSFUL = 'DATA_GET_RENOVATION_SPACES_MEASURE_TOPICS_MEASURES_PROPERTIES_WITH_MEASUREID_SUCCESSFUL'
export const DATA_GET_RENOVATION_SPACES_MEASURE_TOPICS_MEASURES_PROPERTIES_WITH_MEASUREID_FAIL = 'DATA_GET_RENOVATION_SPACES_MEASURE_TOPICS_MEASURES_PROPERTIES_WITH_MEASUREID_FAIL'

// Returns additional properties for a renovation measure of a measure topic.
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/spaces.yaml#/Renovation/get_renovation__estimateId__spaces__id__measure_topics__topicId__measures__measureId__properties
export const getRenovationSpacesMeasureTopicsMeasuresPropertiesWithMeasureIdRequest = (
  requestArguments: getRenovationSpacesMeasureTopicsMeasuresPropertiesWithMeasureIdRequestSpacesArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_RENOVATION_SPACES_MEASURE_TOPICS_MEASURES_PROPERTIES_WITH_MEASUREID_START',
      successful: 'DATA_GET_RENOVATION_SPACES_MEASURE_TOPICS_MEASURES_PROPERTIES_WITH_MEASUREID_SUCCESSFUL',
      fail: 'DATA_GET_RENOVATION_SPACES_MEASURE_TOPICS_MEASURES_PROPERTIES_WITH_MEASUREID_FAIL'
    },
    embeddedType: {
      properties: {
        schemaPath: '#/definitions/ValueProperty',
        schemaKey: 'ValueProperty'
      }
    },
    fnName: 'getRenovationSpacesMeasureTopicsMeasuresPropertiesWithMeasureIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/spaces-api/renovations`,
    method: 'GET',
    url: `/renovation/${options?.estimateId || store.app.calculation}/spaces/${requestArguments.path['id']}/measure-topics/${requestArguments.path['topicId']}/measures/${requestArguments.path['measureId']}/properties`,
    params: {
      profileId: requestArguments.query?.['profileId'],
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      properties: {
        schemaPath: '#/definitions/ValueProperty',
        schemaKey: 'ValueProperty'
      }
    }, 'getRenovationSpacesMeasureTopicsMeasuresPropertiesWithMeasureIdRequest', '/spaces-api/renovations'),
    actions: {
      start: {
        type: DATA_GET_RENOVATION_SPACES_MEASURE_TOPICS_MEASURES_PROPERTIES_WITH_MEASUREID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_RENOVATION_SPACES_MEASURE_TOPICS_MEASURES_PROPERTIES_WITH_MEASUREID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_RENOVATION_SPACES_MEASURE_TOPICS_MEASURES_PROPERTIES_WITH_MEASUREID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getRenovationSpacesMeasureTopicsMeasuresPropertiesWithMeasureIdRequestDefinitions = (definitionsArguments: {| requestArgs: getRenovationSpacesMeasureTopicsMeasuresPropertiesWithMeasureIdRequestSpacesArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getRenovationSpacesMeasureTopicsMeasuresPropertiesWithMeasureIdRequestSpacesArguments, payload?: Object |} => ({
  fnName: 'getRenovationSpacesMeasureTopicsMeasuresPropertiesWithMeasureIdRequest',
  basePath: '/spaces-api/renovations',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_PATCH_RENOVATION_SPACES_MEASURE_TOPICS_MEASURES_PROPERTIES_WITH_MEASUREID_START = 'DATA_PATCH_RENOVATION_SPACES_MEASURE_TOPICS_MEASURES_PROPERTIES_WITH_MEASUREID_START'
export const DATA_PATCH_RENOVATION_SPACES_MEASURE_TOPICS_MEASURES_PROPERTIES_WITH_MEASUREID_SUCCESSFUL = 'DATA_PATCH_RENOVATION_SPACES_MEASURE_TOPICS_MEASURES_PROPERTIES_WITH_MEASUREID_SUCCESSFUL'
export const DATA_PATCH_RENOVATION_SPACES_MEASURE_TOPICS_MEASURES_PROPERTIES_WITH_MEASUREID_FAIL = 'DATA_PATCH_RENOVATION_SPACES_MEASURE_TOPICS_MEASURES_PROPERTIES_WITH_MEASUREID_FAIL'

// Update properties of a renovation measure
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/spaces.yaml#/Renovation/patch_renovation__estimateId__spaces__id__measure_topics__topicId__measures__measureId__properties
export const patchRenovationSpacesMeasureTopicsMeasuresPropertiesWithMeasureIdRequest = (
  requestArguments: patchRenovationSpacesMeasureTopicsMeasuresPropertiesWithMeasureIdRequestSpacesArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_PATCH_RENOVATION_SPACES_MEASURE_TOPICS_MEASURES_PROPERTIES_WITH_MEASUREID_START',
      successful: 'DATA_PATCH_RENOVATION_SPACES_MEASURE_TOPICS_MEASURES_PROPERTIES_WITH_MEASUREID_SUCCESSFUL',
      fail: 'DATA_PATCH_RENOVATION_SPACES_MEASURE_TOPICS_MEASURES_PROPERTIES_WITH_MEASUREID_FAIL'
    },
    embeddedType: null,
    fnName: 'patchRenovationSpacesMeasureTopicsMeasuresPropertiesWithMeasureIdRequest'
  }
  const config = {
    headers: { 'Content-Type': 'application/json' },
    baseURL: `${API_BASE_URL}/spaces-api/renovations`,
    method: 'PATCH',
    url: `/renovation/${options?.estimateId || store.app.calculation}/spaces/${requestArguments.path['id']}/measure-topics/${requestArguments.path['topicId']}/measures/${requestArguments.path['measureId']}/properties`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: requestArguments.body
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'patchRenovationSpacesMeasureTopicsMeasuresPropertiesWithMeasureIdRequest', '/spaces-api/renovations'),
    actions: {
      start: {
        type: DATA_PATCH_RENOVATION_SPACES_MEASURE_TOPICS_MEASURES_PROPERTIES_WITH_MEASUREID_START, payload, config, meta
      },
      successful: {
        type: DATA_PATCH_RENOVATION_SPACES_MEASURE_TOPICS_MEASURES_PROPERTIES_WITH_MEASUREID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_PATCH_RENOVATION_SPACES_MEASURE_TOPICS_MEASURES_PROPERTIES_WITH_MEASUREID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_GET_RENOVATION_SPACES_MEASURE_TOPICS_WITH_ESTIMATEID_START = 'DATA_GET_RENOVATION_SPACES_MEASURE_TOPICS_WITH_ESTIMATEID_START'
export const DATA_GET_RENOVATION_SPACES_MEASURE_TOPICS_WITH_ESTIMATEID_SUCCESSFUL = 'DATA_GET_RENOVATION_SPACES_MEASURE_TOPICS_WITH_ESTIMATEID_SUCCESSFUL'
export const DATA_GET_RENOVATION_SPACES_MEASURE_TOPICS_WITH_ESTIMATEID_FAIL = 'DATA_GET_RENOVATION_SPACES_MEASURE_TOPICS_WITH_ESTIMATEID_FAIL'

// Returns a list of measure topics as enumerals that can be used to renovate spaces
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/spaces.yaml#/Renovation/get_renovation__estimateId__spaces_measure_topics
export const getRenovationSpacesMeasureTopicsWithEstimateIdRequest = (
  requestArguments: getRenovationSpacesMeasureTopicsWithEstimateIdRequestSpacesArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_RENOVATION_SPACES_MEASURE_TOPICS_WITH_ESTIMATEID_START',
      successful: 'DATA_GET_RENOVATION_SPACES_MEASURE_TOPICS_WITH_ESTIMATEID_SUCCESSFUL',
      fail: 'DATA_GET_RENOVATION_SPACES_MEASURE_TOPICS_WITH_ESTIMATEID_FAIL'
    },
    embeddedType: {
      topics: {
        schemaPath: '#/definitions/Enumeral',
        schemaKey: 'Enumeral'
      }
    },
    fnName: 'getRenovationSpacesMeasureTopicsWithEstimateIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/spaces-api/renovations`,
    method: 'GET',
    url: `/renovation/${options?.estimateId || store.app.calculation}/spaces/measure-topics`,
    params: {
      embedMeasures: requestArguments.query?.['embedMeasures'],
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      topics: {
        schemaPath: '#/definitions/Enumeral',
        schemaKey: 'Enumeral'
      }
    }, 'getRenovationSpacesMeasureTopicsWithEstimateIdRequest', '/spaces-api/renovations'),
    actions: {
      start: {
        type: DATA_GET_RENOVATION_SPACES_MEASURE_TOPICS_WITH_ESTIMATEID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_RENOVATION_SPACES_MEASURE_TOPICS_WITH_ESTIMATEID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_RENOVATION_SPACES_MEASURE_TOPICS_WITH_ESTIMATEID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getRenovationSpacesMeasureTopicsWithEstimateIdRequestDefinitions = (definitionsArguments: {| requestArgs: getRenovationSpacesMeasureTopicsWithEstimateIdRequestSpacesArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getRenovationSpacesMeasureTopicsWithEstimateIdRequestSpacesArguments, payload?: Object |} => ({
  fnName: 'getRenovationSpacesMeasureTopicsWithEstimateIdRequest',
  basePath: '/spaces-api/renovations',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_GET_RENOVATION_SPACES_PROFILES_WITH_ESTIMATEID_START = 'DATA_GET_RENOVATION_SPACES_PROFILES_WITH_ESTIMATEID_START'
export const DATA_GET_RENOVATION_SPACES_PROFILES_WITH_ESTIMATEID_SUCCESSFUL = 'DATA_GET_RENOVATION_SPACES_PROFILES_WITH_ESTIMATEID_SUCCESSFUL'
export const DATA_GET_RENOVATION_SPACES_PROFILES_WITH_ESTIMATEID_FAIL = 'DATA_GET_RENOVATION_SPACES_PROFILES_WITH_ESTIMATEID_FAIL'

// Returns a list renovation profiles that can be applied to a space
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/spaces.yaml#/Renovation/get_renovation__estimateId__spaces_profiles
export const getRenovationSpacesProfilesWithEstimateIdRequest = (
  payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_RENOVATION_SPACES_PROFILES_WITH_ESTIMATEID_START',
      successful: 'DATA_GET_RENOVATION_SPACES_PROFILES_WITH_ESTIMATEID_SUCCESSFUL',
      fail: 'DATA_GET_RENOVATION_SPACES_PROFILES_WITH_ESTIMATEID_FAIL'
    },
    embeddedType: {
      profiles: {
        schemaPath: '#/definitions/Enumeral',
        schemaKey: 'Enumeral'
      }
    },
    fnName: 'getRenovationSpacesProfilesWithEstimateIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/spaces-api/renovations`,
    method: 'GET',
    url: `/renovation/${options?.estimateId || store.app.calculation}/spaces/profiles`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      profiles: {
        schemaPath: '#/definitions/Enumeral',
        schemaKey: 'Enumeral'
      }
    }, 'getRenovationSpacesProfilesWithEstimateIdRequest', '/spaces-api/renovations'),
    actions: {
      start: {
        type: DATA_GET_RENOVATION_SPACES_PROFILES_WITH_ESTIMATEID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_RENOVATION_SPACES_PROFILES_WITH_ESTIMATEID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_RENOVATION_SPACES_PROFILES_WITH_ESTIMATEID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getRenovationSpacesProfilesWithEstimateIdRequestDefinitions = (definitionsArguments: {| payload?: Object |}): {|
  ...TVDGARConfigWithoutRequestArgs, payload?: Object |} => ({
  fnName: 'getRenovationSpacesProfilesWithEstimateIdRequest',
  basePath: '/spaces-api/renovations',
  payload: definitionsArguments.payload,
})


export const DATA_GET_RENOVATION_SPACES_PROFILES_PROPERTIES_WITH_PROFILEID_START = 'DATA_GET_RENOVATION_SPACES_PROFILES_PROPERTIES_WITH_PROFILEID_START'
export const DATA_GET_RENOVATION_SPACES_PROFILES_PROPERTIES_WITH_PROFILEID_SUCCESSFUL = 'DATA_GET_RENOVATION_SPACES_PROFILES_PROPERTIES_WITH_PROFILEID_SUCCESSFUL'
export const DATA_GET_RENOVATION_SPACES_PROFILES_PROPERTIES_WITH_PROFILEID_FAIL = 'DATA_GET_RENOVATION_SPACES_PROFILES_PROPERTIES_WITH_PROFILEID_FAIL'

// Returns a list of measure topics as properties that the profile will apply to a space
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/spaces.yaml#/Renovation/get_renovation__estimateId__spaces_profiles__profileId__properties
export const getRenovationSpacesProfilesPropertiesWithProfileIdRequest = (
  requestArguments: getRenovationSpacesProfilesPropertiesWithProfileIdRequestSpacesArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_RENOVATION_SPACES_PROFILES_PROPERTIES_WITH_PROFILEID_START',
      successful: 'DATA_GET_RENOVATION_SPACES_PROFILES_PROPERTIES_WITH_PROFILEID_SUCCESSFUL',
      fail: 'DATA_GET_RENOVATION_SPACES_PROFILES_PROPERTIES_WITH_PROFILEID_FAIL'
    },
    embeddedType: {
      properties: {
        schemaPath: '#/definitions/ValueProperty',
        schemaKey: 'ValueProperty'
      }
    },
    fnName: 'getRenovationSpacesProfilesPropertiesWithProfileIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/spaces-api/renovations`,
    method: 'GET',
    url: `/renovation/${options?.estimateId || store.app.calculation}/spaces/profiles/${requestArguments.path['profileId']}/properties`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      properties: {
        schemaPath: '#/definitions/ValueProperty',
        schemaKey: 'ValueProperty'
      }
    }, 'getRenovationSpacesProfilesPropertiesWithProfileIdRequest', '/spaces-api/renovations'),
    actions: {
      start: {
        type: DATA_GET_RENOVATION_SPACES_PROFILES_PROPERTIES_WITH_PROFILEID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_RENOVATION_SPACES_PROFILES_PROPERTIES_WITH_PROFILEID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_RENOVATION_SPACES_PROFILES_PROPERTIES_WITH_PROFILEID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getRenovationSpacesProfilesPropertiesWithProfileIdRequestDefinitions = (definitionsArguments: {| requestArgs: getRenovationSpacesProfilesPropertiesWithProfileIdRequestSpacesArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getRenovationSpacesProfilesPropertiesWithProfileIdRequestSpacesArguments, payload?: Object |} => ({
  fnName: 'getRenovationSpacesProfilesPropertiesWithProfileIdRequest',
  basePath: '/spaces-api/renovations',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_GET_RENOVATION_SPACE_GROUPS_WITH_ESTIMATEID_START = 'DATA_GET_RENOVATION_SPACE_GROUPS_WITH_ESTIMATEID_START'
export const DATA_GET_RENOVATION_SPACE_GROUPS_WITH_ESTIMATEID_SUCCESSFUL = 'DATA_GET_RENOVATION_SPACE_GROUPS_WITH_ESTIMATEID_SUCCESSFUL'
export const DATA_GET_RENOVATION_SPACE_GROUPS_WITH_ESTIMATEID_FAIL = 'DATA_GET_RENOVATION_SPACE_GROUPS_WITH_ESTIMATEID_FAIL'

// Returns a renovation schedule for space groups.
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/spaces.yaml#/Renovation/get_renovation__estimateId__space_groups
export const getRenovationSpaceGroupsWithEstimateIdRequest = (
  payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_RENOVATION_SPACE_GROUPS_WITH_ESTIMATEID_START',
      successful: 'DATA_GET_RENOVATION_SPACE_GROUPS_WITH_ESTIMATEID_SUCCESSFUL',
      fail: 'DATA_GET_RENOVATION_SPACE_GROUPS_WITH_ESTIMATEID_FAIL'
    },
    embeddedType: {
      items: {
        schemaPath: '#/definitions/ScheduleItem',
        schemaKey: 'ScheduleItem'
      }
    },
    fnName: 'getRenovationSpaceGroupsWithEstimateIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/spaces-api/renovations`,
    method: 'GET',
    url: `/renovation/${options?.estimateId || store.app.calculation}/space-groups`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      items: {
        schemaPath: '#/definitions/ScheduleItem',
        schemaKey: 'ScheduleItem'
      }
    }, 'getRenovationSpaceGroupsWithEstimateIdRequest', '/spaces-api/renovations'),
    actions: {
      start: {
        type: DATA_GET_RENOVATION_SPACE_GROUPS_WITH_ESTIMATEID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_RENOVATION_SPACE_GROUPS_WITH_ESTIMATEID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_RENOVATION_SPACE_GROUPS_WITH_ESTIMATEID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getRenovationSpaceGroupsWithEstimateIdRequestDefinitions = (definitionsArguments: {| payload?: Object |}): {|
  ...TVDGARConfigWithoutRequestArgs, payload?: Object |} => ({
  fnName: 'getRenovationSpaceGroupsWithEstimateIdRequest',
  basePath: '/spaces-api/renovations',
  payload: definitionsArguments.payload,
})


export const DATA_PATCH_RENOVATION_SPACE_GROUPS_WITH_ESTIMATEID_START = 'DATA_PATCH_RENOVATION_SPACE_GROUPS_WITH_ESTIMATEID_START'
export const DATA_PATCH_RENOVATION_SPACE_GROUPS_WITH_ESTIMATEID_SUCCESSFUL = 'DATA_PATCH_RENOVATION_SPACE_GROUPS_WITH_ESTIMATEID_SUCCESSFUL'
export const DATA_PATCH_RENOVATION_SPACE_GROUPS_WITH_ESTIMATEID_FAIL = 'DATA_PATCH_RENOVATION_SPACE_GROUPS_WITH_ESTIMATEID_FAIL'

// Update renovation measures for multiple space groups at once.
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/spaces.yaml#/Renovation/patch_renovation__estimateId__space_groups
export const patchRenovationSpaceGroupsWithEstimateIdRequest = (
  requestArguments: patchRenovationSpaceGroupsWithEstimateIdRequestSpacesArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_PATCH_RENOVATION_SPACE_GROUPS_WITH_ESTIMATEID_START',
      successful: 'DATA_PATCH_RENOVATION_SPACE_GROUPS_WITH_ESTIMATEID_SUCCESSFUL',
      fail: 'DATA_PATCH_RENOVATION_SPACE_GROUPS_WITH_ESTIMATEID_FAIL'
    },
    embeddedType: null,
    fnName: 'patchRenovationSpaceGroupsWithEstimateIdRequest'
  }
  const config = {
    headers: { 'Content-Type': 'application/json' },
    baseURL: `${API_BASE_URL}/spaces-api/renovations`,
    method: 'PATCH',
    url: `/renovation/${options?.estimateId || store.app.calculation}/space-groups`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: requestArguments.body
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'patchRenovationSpaceGroupsWithEstimateIdRequest', '/spaces-api/renovations'),
    actions: {
      start: {
        type: DATA_PATCH_RENOVATION_SPACE_GROUPS_WITH_ESTIMATEID_START, payload, config, meta
      },
      successful: {
        type: DATA_PATCH_RENOVATION_SPACE_GROUPS_WITH_ESTIMATEID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_PATCH_RENOVATION_SPACE_GROUPS_WITH_ESTIMATEID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_GET_RENOVATION_SPACE_GROUPS_WITH_ID_START = 'DATA_GET_RENOVATION_SPACE_GROUPS_WITH_ID_START'
export const DATA_GET_RENOVATION_SPACE_GROUPS_WITH_ID_SUCCESSFUL = 'DATA_GET_RENOVATION_SPACE_GROUPS_WITH_ID_SUCCESSFUL'
export const DATA_GET_RENOVATION_SPACE_GROUPS_WITH_ID_FAIL = 'DATA_GET_RENOVATION_SPACE_GROUPS_WITH_ID_FAIL'

// Returns a space group that can be renovated.
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/spaces.yaml#/Renovation/get_renovation__estimateId__space_groups__id_
export const getRenovationSpaceGroupsWithIdRequest = (
  requestArguments: getRenovationSpaceGroupsWithIdRequestSpacesArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_RENOVATION_SPACE_GROUPS_WITH_ID_START',
      successful: 'DATA_GET_RENOVATION_SPACE_GROUPS_WITH_ID_SUCCESSFUL',
      fail: 'DATA_GET_RENOVATION_SPACE_GROUPS_WITH_ID_FAIL'
    },
    embeddedType: {
      nonEmbedded: {
        schemaPath: '#/definitions/ScheduleItem',
        schemaKey: 'ScheduleItem'
      }
    },
    fnName: 'getRenovationSpaceGroupsWithIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/spaces-api/renovations`,
    method: 'GET',
    url: `/renovation/${options?.estimateId || store.app.calculation}/space-groups/${requestArguments.path['id']}`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      nonEmbedded: {
        schemaPath: '#/definitions/ScheduleItem',
        schemaKey: 'ScheduleItem'
      }
    }, 'getRenovationSpaceGroupsWithIdRequest', '/spaces-api/renovations'),
    actions: {
      start: {
        type: DATA_GET_RENOVATION_SPACE_GROUPS_WITH_ID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_RENOVATION_SPACE_GROUPS_WITH_ID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_RENOVATION_SPACE_GROUPS_WITH_ID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getRenovationSpaceGroupsWithIdRequestDefinitions = (definitionsArguments: {| requestArgs: getRenovationSpaceGroupsWithIdRequestSpacesArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getRenovationSpaceGroupsWithIdRequestSpacesArguments, payload?: Object |} => ({
  fnName: 'getRenovationSpaceGroupsWithIdRequest',
  basePath: '/spaces-api/renovations',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_PATCH_RENOVATION_SPACE_GROUPS_WITH_ID_START = 'DATA_PATCH_RENOVATION_SPACE_GROUPS_WITH_ID_START'
export const DATA_PATCH_RENOVATION_SPACE_GROUPS_WITH_ID_SUCCESSFUL = 'DATA_PATCH_RENOVATION_SPACE_GROUPS_WITH_ID_SUCCESSFUL'
export const DATA_PATCH_RENOVATION_SPACE_GROUPS_WITH_ID_FAIL = 'DATA_PATCH_RENOVATION_SPACE_GROUPS_WITH_ID_FAIL'

// Update renovation column data for a space group.
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/spaces.yaml#/Renovation/patch_renovation__estimateId__space_groups__id_
export const patchRenovationSpaceGroupsWithIdRequest = (
  requestArguments: patchRenovationSpaceGroupsWithIdRequestSpacesArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_PATCH_RENOVATION_SPACE_GROUPS_WITH_ID_START',
      successful: 'DATA_PATCH_RENOVATION_SPACE_GROUPS_WITH_ID_SUCCESSFUL',
      fail: 'DATA_PATCH_RENOVATION_SPACE_GROUPS_WITH_ID_FAIL'
    },
    embeddedType: null,
    fnName: 'patchRenovationSpaceGroupsWithIdRequest'
  }
  const config = {
    headers: { 'Content-Type': 'application/json' },
    baseURL: `${API_BASE_URL}/spaces-api/renovations`,
    method: 'PATCH',
    url: `/renovation/${options?.estimateId || store.app.calculation}/space-groups/${requestArguments.path['id']}`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: requestArguments.body
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'patchRenovationSpaceGroupsWithIdRequest', '/spaces-api/renovations'),
    actions: {
      start: {
        type: DATA_PATCH_RENOVATION_SPACE_GROUPS_WITH_ID_START, payload, config, meta
      },
      successful: {
        type: DATA_PATCH_RENOVATION_SPACE_GROUPS_WITH_ID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_PATCH_RENOVATION_SPACE_GROUPS_WITH_ID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_GET_RENOVATION_SPACE_GROUPS_MEASURE_TOPICS_WITH_ID_START = 'DATA_GET_RENOVATION_SPACE_GROUPS_MEASURE_TOPICS_WITH_ID_START'
export const DATA_GET_RENOVATION_SPACE_GROUPS_MEASURE_TOPICS_WITH_ID_SUCCESSFUL = 'DATA_GET_RENOVATION_SPACE_GROUPS_MEASURE_TOPICS_WITH_ID_SUCCESSFUL'
export const DATA_GET_RENOVATION_SPACE_GROUPS_MEASURE_TOPICS_WITH_ID_FAIL = 'DATA_GET_RENOVATION_SPACE_GROUPS_MEASURE_TOPICS_WITH_ID_FAIL'

// Returns list of measure topics as Enumerals that have a renovation measure value for a particular space group
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/spaces.yaml#/Renovation/get_renovation__estimateId__space_groups__id__measure_topics
export const getRenovationSpaceGroupsMeasureTopicsWithIdRequest = (
  requestArguments: getRenovationSpaceGroupsMeasureTopicsWithIdRequestSpacesArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_RENOVATION_SPACE_GROUPS_MEASURE_TOPICS_WITH_ID_START',
      successful: 'DATA_GET_RENOVATION_SPACE_GROUPS_MEASURE_TOPICS_WITH_ID_SUCCESSFUL',
      fail: 'DATA_GET_RENOVATION_SPACE_GROUPS_MEASURE_TOPICS_WITH_ID_FAIL'
    },
    embeddedType: {
      topics: {
        schemaPath: '#/definitions/Enumeral',
        schemaKey: 'Enumeral'
      }
    },
    fnName: 'getRenovationSpaceGroupsMeasureTopicsWithIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/spaces-api/renovations`,
    method: 'GET',
    url: `/renovation/${options?.estimateId || store.app.calculation}/space-groups/${requestArguments.path['id']}/measure-topics`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      topics: {
        schemaPath: '#/definitions/Enumeral',
        schemaKey: 'Enumeral'
      }
    }, 'getRenovationSpaceGroupsMeasureTopicsWithIdRequest', '/spaces-api/renovations'),
    actions: {
      start: {
        type: DATA_GET_RENOVATION_SPACE_GROUPS_MEASURE_TOPICS_WITH_ID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_RENOVATION_SPACE_GROUPS_MEASURE_TOPICS_WITH_ID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_RENOVATION_SPACE_GROUPS_MEASURE_TOPICS_WITH_ID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getRenovationSpaceGroupsMeasureTopicsWithIdRequestDefinitions = (definitionsArguments: {| requestArgs: getRenovationSpaceGroupsMeasureTopicsWithIdRequestSpacesArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getRenovationSpaceGroupsMeasureTopicsWithIdRequestSpacesArguments, payload?: Object |} => ({
  fnName: 'getRenovationSpaceGroupsMeasureTopicsWithIdRequest',
  basePath: '/spaces-api/renovations',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_GET_RENOVATION_SPACE_GROUPS_MEASURE_TOPICS_MEASURES_WITH_TOPICID_START = 'DATA_GET_RENOVATION_SPACE_GROUPS_MEASURE_TOPICS_MEASURES_WITH_TOPICID_START'
export const DATA_GET_RENOVATION_SPACE_GROUPS_MEASURE_TOPICS_MEASURES_WITH_TOPICID_SUCCESSFUL = 'DATA_GET_RENOVATION_SPACE_GROUPS_MEASURE_TOPICS_MEASURES_WITH_TOPICID_SUCCESSFUL'
export const DATA_GET_RENOVATION_SPACE_GROUPS_MEASURE_TOPICS_MEASURES_WITH_TOPICID_FAIL = 'DATA_GET_RENOVATION_SPACE_GROUPS_MEASURE_TOPICS_MEASURES_WITH_TOPICID_FAIL'

// Returns the measures under a measure topics for a space group
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/spaces.yaml#/Renovation/get_renovation__estimateId__space_groups__id__measure_topics__topicId__measures
export const getRenovationSpaceGroupsMeasureTopicsMeasuresWithTopicIdRequest = (
  requestArguments: getRenovationSpaceGroupsMeasureTopicsMeasuresWithTopicIdRequestSpacesArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_RENOVATION_SPACE_GROUPS_MEASURE_TOPICS_MEASURES_WITH_TOPICID_START',
      successful: 'DATA_GET_RENOVATION_SPACE_GROUPS_MEASURE_TOPICS_MEASURES_WITH_TOPICID_SUCCESSFUL',
      fail: 'DATA_GET_RENOVATION_SPACE_GROUPS_MEASURE_TOPICS_MEASURES_WITH_TOPICID_FAIL'
    },
    embeddedType: {
      measures: {
        schemaPath: '#/definitions/Enumeral',
        schemaKey: 'Enumeral'
      }
    },
    fnName: 'getRenovationSpaceGroupsMeasureTopicsMeasuresWithTopicIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/spaces-api/renovations`,
    method: 'GET',
    url: `/renovation/${options?.estimateId || store.app.calculation}/space-groups/${requestArguments.path['id']}/measure-topics/${requestArguments.path['topicId']}/measures`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      measures: {
        schemaPath: '#/definitions/Enumeral',
        schemaKey: 'Enumeral'
      }
    }, 'getRenovationSpaceGroupsMeasureTopicsMeasuresWithTopicIdRequest', '/spaces-api/renovations'),
    actions: {
      start: {
        type: DATA_GET_RENOVATION_SPACE_GROUPS_MEASURE_TOPICS_MEASURES_WITH_TOPICID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_RENOVATION_SPACE_GROUPS_MEASURE_TOPICS_MEASURES_WITH_TOPICID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_RENOVATION_SPACE_GROUPS_MEASURE_TOPICS_MEASURES_WITH_TOPICID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getRenovationSpaceGroupsMeasureTopicsMeasuresWithTopicIdRequestDefinitions = (definitionsArguments: {| requestArgs: getRenovationSpaceGroupsMeasureTopicsMeasuresWithTopicIdRequestSpacesArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getRenovationSpaceGroupsMeasureTopicsMeasuresWithTopicIdRequestSpacesArguments, payload?: Object |} => ({
  fnName: 'getRenovationSpaceGroupsMeasureTopicsMeasuresWithTopicIdRequest',
  basePath: '/spaces-api/renovations',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_GET_RENOVATION_SPACE_GROUPS_MEASURE_TOPICS_MEASURES_PROPERTIES_WITH_MEASUREID_START = 'DATA_GET_RENOVATION_SPACE_GROUPS_MEASURE_TOPICS_MEASURES_PROPERTIES_WITH_MEASUREID_START'
export const DATA_GET_RENOVATION_SPACE_GROUPS_MEASURE_TOPICS_MEASURES_PROPERTIES_WITH_MEASUREID_SUCCESSFUL = 'DATA_GET_RENOVATION_SPACE_GROUPS_MEASURE_TOPICS_MEASURES_PROPERTIES_WITH_MEASUREID_SUCCESSFUL'
export const DATA_GET_RENOVATION_SPACE_GROUPS_MEASURE_TOPICS_MEASURES_PROPERTIES_WITH_MEASUREID_FAIL = 'DATA_GET_RENOVATION_SPACE_GROUPS_MEASURE_TOPICS_MEASURES_PROPERTIES_WITH_MEASUREID_FAIL'

// Returns additional properties for a renovation measure of an measure topics.
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/spaces.yaml#/Renovation/get_renovation__estimateId__space_groups__id__measure_topics__topicId__measures__measureId__properties
export const getRenovationSpaceGroupsMeasureTopicsMeasuresPropertiesWithMeasureIdRequest = (
  requestArguments: getRenovationSpaceGroupsMeasureTopicsMeasuresPropertiesWithMeasureIdRequestSpacesArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_RENOVATION_SPACE_GROUPS_MEASURE_TOPICS_MEASURES_PROPERTIES_WITH_MEASUREID_START',
      successful: 'DATA_GET_RENOVATION_SPACE_GROUPS_MEASURE_TOPICS_MEASURES_PROPERTIES_WITH_MEASUREID_SUCCESSFUL',
      fail: 'DATA_GET_RENOVATION_SPACE_GROUPS_MEASURE_TOPICS_MEASURES_PROPERTIES_WITH_MEASUREID_FAIL'
    },
    embeddedType: {
      properties: {
        schemaPath: '#/definitions/ValueProperty',
        schemaKey: 'ValueProperty'
      }
    },
    fnName: 'getRenovationSpaceGroupsMeasureTopicsMeasuresPropertiesWithMeasureIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/spaces-api/renovations`,
    method: 'GET',
    url: `/renovation/${options?.estimateId || store.app.calculation}/space-groups/${requestArguments.path['id']}/measure-topics/${requestArguments.path['topicId']}/measures/${requestArguments.path['measureId']}/properties`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      properties: {
        schemaPath: '#/definitions/ValueProperty',
        schemaKey: 'ValueProperty'
      }
    }, 'getRenovationSpaceGroupsMeasureTopicsMeasuresPropertiesWithMeasureIdRequest', '/spaces-api/renovations'),
    actions: {
      start: {
        type: DATA_GET_RENOVATION_SPACE_GROUPS_MEASURE_TOPICS_MEASURES_PROPERTIES_WITH_MEASUREID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_RENOVATION_SPACE_GROUPS_MEASURE_TOPICS_MEASURES_PROPERTIES_WITH_MEASUREID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_RENOVATION_SPACE_GROUPS_MEASURE_TOPICS_MEASURES_PROPERTIES_WITH_MEASUREID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getRenovationSpaceGroupsMeasureTopicsMeasuresPropertiesWithMeasureIdRequestDefinitions = (definitionsArguments: {| requestArgs: getRenovationSpaceGroupsMeasureTopicsMeasuresPropertiesWithMeasureIdRequestSpacesArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getRenovationSpaceGroupsMeasureTopicsMeasuresPropertiesWithMeasureIdRequestSpacesArguments, payload?: Object |} => ({
  fnName: 'getRenovationSpaceGroupsMeasureTopicsMeasuresPropertiesWithMeasureIdRequest',
  basePath: '/spaces-api/renovations',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_PATCH_RENOVATION_SPACE_GROUPS_MEASURE_TOPICS_MEASURES_PROPERTIES_WITH_MEASUREID_START = 'DATA_PATCH_RENOVATION_SPACE_GROUPS_MEASURE_TOPICS_MEASURES_PROPERTIES_WITH_MEASUREID_START'
export const DATA_PATCH_RENOVATION_SPACE_GROUPS_MEASURE_TOPICS_MEASURES_PROPERTIES_WITH_MEASUREID_SUCCESSFUL = 'DATA_PATCH_RENOVATION_SPACE_GROUPS_MEASURE_TOPICS_MEASURES_PROPERTIES_WITH_MEASUREID_SUCCESSFUL'
export const DATA_PATCH_RENOVATION_SPACE_GROUPS_MEASURE_TOPICS_MEASURES_PROPERTIES_WITH_MEASUREID_FAIL = 'DATA_PATCH_RENOVATION_SPACE_GROUPS_MEASURE_TOPICS_MEASURES_PROPERTIES_WITH_MEASUREID_FAIL'

// Update properties of a renovation measure
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/spaces.yaml#/Renovation/patch_renovation__estimateId__space_groups__id__measure_topics__topicId__measures__measureId__properties
export const patchRenovationSpaceGroupsMeasureTopicsMeasuresPropertiesWithMeasureIdRequest = (
  requestArguments: patchRenovationSpaceGroupsMeasureTopicsMeasuresPropertiesWithMeasureIdRequestSpacesArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_PATCH_RENOVATION_SPACE_GROUPS_MEASURE_TOPICS_MEASURES_PROPERTIES_WITH_MEASUREID_START',
      successful: 'DATA_PATCH_RENOVATION_SPACE_GROUPS_MEASURE_TOPICS_MEASURES_PROPERTIES_WITH_MEASUREID_SUCCESSFUL',
      fail: 'DATA_PATCH_RENOVATION_SPACE_GROUPS_MEASURE_TOPICS_MEASURES_PROPERTIES_WITH_MEASUREID_FAIL'
    },
    embeddedType: null,
    fnName: 'patchRenovationSpaceGroupsMeasureTopicsMeasuresPropertiesWithMeasureIdRequest'
  }
  const config = {
    headers: { 'Content-Type': 'application/json' },
    baseURL: `${API_BASE_URL}/spaces-api/renovations`,
    method: 'PATCH',
    url: `/renovation/${options?.estimateId || store.app.calculation}/space-groups/${requestArguments.path['id']}/measure-topics/${requestArguments.path['topicId']}/measures/${requestArguments.path['measureId']}/properties`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: requestArguments.body
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'patchRenovationSpaceGroupsMeasureTopicsMeasuresPropertiesWithMeasureIdRequest', '/spaces-api/renovations'),
    actions: {
      start: {
        type: DATA_PATCH_RENOVATION_SPACE_GROUPS_MEASURE_TOPICS_MEASURES_PROPERTIES_WITH_MEASUREID_START, payload, config, meta
      },
      successful: {
        type: DATA_PATCH_RENOVATION_SPACE_GROUPS_MEASURE_TOPICS_MEASURES_PROPERTIES_WITH_MEASUREID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_PATCH_RENOVATION_SPACE_GROUPS_MEASURE_TOPICS_MEASURES_PROPERTIES_WITH_MEASUREID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_GET_RENOVATION_SPACE_GROUPS_MEASURE_TOPICS_WITH_ESTIMATEID_START = 'DATA_GET_RENOVATION_SPACE_GROUPS_MEASURE_TOPICS_WITH_ESTIMATEID_START'
export const DATA_GET_RENOVATION_SPACE_GROUPS_MEASURE_TOPICS_WITH_ESTIMATEID_SUCCESSFUL = 'DATA_GET_RENOVATION_SPACE_GROUPS_MEASURE_TOPICS_WITH_ESTIMATEID_SUCCESSFUL'
export const DATA_GET_RENOVATION_SPACE_GROUPS_MEASURE_TOPICS_WITH_ESTIMATEID_FAIL = 'DATA_GET_RENOVATION_SPACE_GROUPS_MEASURE_TOPICS_WITH_ESTIMATEID_FAIL'

// Returns a list of measure topics as enumerals that can be used to renovate space groups
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/spaces.yaml#/Renovation/get_renovation__estimateId__space_groups_measure_topics
export const getRenovationSpaceGroupsMeasureTopicsWithEstimateIdRequest = (
  requestArguments: getRenovationSpaceGroupsMeasureTopicsWithEstimateIdRequestSpacesArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_RENOVATION_SPACE_GROUPS_MEASURE_TOPICS_WITH_ESTIMATEID_START',
      successful: 'DATA_GET_RENOVATION_SPACE_GROUPS_MEASURE_TOPICS_WITH_ESTIMATEID_SUCCESSFUL',
      fail: 'DATA_GET_RENOVATION_SPACE_GROUPS_MEASURE_TOPICS_WITH_ESTIMATEID_FAIL'
    },
    embeddedType: {
      topics: {
        schemaPath: '#/definitions/Enumeral',
        schemaKey: 'Enumeral'
      }
    },
    fnName: 'getRenovationSpaceGroupsMeasureTopicsWithEstimateIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/spaces-api/renovations`,
    method: 'GET',
    url: `/renovation/${options?.estimateId || store.app.calculation}/space-groups/measure-topics`,
    params: {
      embedMeasures: requestArguments.query?.['embedMeasures'],
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      topics: {
        schemaPath: '#/definitions/Enumeral',
        schemaKey: 'Enumeral'
      }
    }, 'getRenovationSpaceGroupsMeasureTopicsWithEstimateIdRequest', '/spaces-api/renovations'),
    actions: {
      start: {
        type: DATA_GET_RENOVATION_SPACE_GROUPS_MEASURE_TOPICS_WITH_ESTIMATEID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_RENOVATION_SPACE_GROUPS_MEASURE_TOPICS_WITH_ESTIMATEID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_RENOVATION_SPACE_GROUPS_MEASURE_TOPICS_WITH_ESTIMATEID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getRenovationSpaceGroupsMeasureTopicsWithEstimateIdRequestDefinitions = (definitionsArguments: {| requestArgs: getRenovationSpaceGroupsMeasureTopicsWithEstimateIdRequestSpacesArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getRenovationSpaceGroupsMeasureTopicsWithEstimateIdRequestSpacesArguments, payload?: Object |} => ({
  fnName: 'getRenovationSpaceGroupsMeasureTopicsWithEstimateIdRequest',
  basePath: '/spaces-api/renovations',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_GET_RENOVATION_MEASURE_TOPICS_WITH_ID_START = 'DATA_GET_RENOVATION_MEASURE_TOPICS_WITH_ID_START'
export const DATA_GET_RENOVATION_MEASURE_TOPICS_WITH_ID_SUCCESSFUL = 'DATA_GET_RENOVATION_MEASURE_TOPICS_WITH_ID_SUCCESSFUL'
export const DATA_GET_RENOVATION_MEASURE_TOPICS_WITH_ID_FAIL = 'DATA_GET_RENOVATION_MEASURE_TOPICS_WITH_ID_FAIL'

// Returns a measure topic as an Enumeral
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/spaces.yaml#/Renovation/get_renovation__estimateId__measure_topics__id_
export const getRenovationMeasureTopicsWithIdRequest = (
  requestArguments: getRenovationMeasureTopicsWithIdRequestSpacesArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_RENOVATION_MEASURE_TOPICS_WITH_ID_START',
      successful: 'DATA_GET_RENOVATION_MEASURE_TOPICS_WITH_ID_SUCCESSFUL',
      fail: 'DATA_GET_RENOVATION_MEASURE_TOPICS_WITH_ID_FAIL'
    },
    embeddedType: {
      nonEmbedded: {
        schemaPath: '#/definitions/Enumeral',
        schemaKey: 'Enumeral'
      }
    },
    fnName: 'getRenovationMeasureTopicsWithIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/spaces-api/renovations`,
    method: 'GET',
    url: `/renovation/${options?.estimateId || store.app.calculation}/measure-topics/${requestArguments.path['id']}`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      nonEmbedded: {
        schemaPath: '#/definitions/Enumeral',
        schemaKey: 'Enumeral'
      }
    }, 'getRenovationMeasureTopicsWithIdRequest', '/spaces-api/renovations'),
    actions: {
      start: {
        type: DATA_GET_RENOVATION_MEASURE_TOPICS_WITH_ID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_RENOVATION_MEASURE_TOPICS_WITH_ID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_RENOVATION_MEASURE_TOPICS_WITH_ID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getRenovationMeasureTopicsWithIdRequestDefinitions = (definitionsArguments: {| requestArgs: getRenovationMeasureTopicsWithIdRequestSpacesArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getRenovationMeasureTopicsWithIdRequestSpacesArguments, payload?: Object |} => ({
  fnName: 'getRenovationMeasureTopicsWithIdRequest',
  basePath: '/spaces-api/renovations',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_GET_RENOVATION_MEASURE_TOPICS_MEASURES_WITH_ID_START = 'DATA_GET_RENOVATION_MEASURE_TOPICS_MEASURES_WITH_ID_START'
export const DATA_GET_RENOVATION_MEASURE_TOPICS_MEASURES_WITH_ID_SUCCESSFUL = 'DATA_GET_RENOVATION_MEASURE_TOPICS_MEASURES_WITH_ID_SUCCESSFUL'
export const DATA_GET_RENOVATION_MEASURE_TOPICS_MEASURES_WITH_ID_FAIL = 'DATA_GET_RENOVATION_MEASURE_TOPICS_MEASURES_WITH_ID_FAIL'

// Returns renovation measures for an measure topics as Enumerals
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/spaces.yaml#/Renovation/get_renovation__estimateId__measure_topics__id__measures
export const getRenovationMeasureTopicsMeasuresWithIdRequest = (
  requestArguments: getRenovationMeasureTopicsMeasuresWithIdRequestSpacesArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_RENOVATION_MEASURE_TOPICS_MEASURES_WITH_ID_START',
      successful: 'DATA_GET_RENOVATION_MEASURE_TOPICS_MEASURES_WITH_ID_SUCCESSFUL',
      fail: 'DATA_GET_RENOVATION_MEASURE_TOPICS_MEASURES_WITH_ID_FAIL'
    },
    embeddedType: {
      measures: {
        schemaPath: '#/definitions/Enumeral',
        schemaKey: 'Enumeral'
      }
    },
    fnName: 'getRenovationMeasureTopicsMeasuresWithIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/spaces-api/renovations`,
    method: 'GET',
    url: `/renovation/${options?.estimateId || store.app.calculation}/measure-topics/${requestArguments.path['id']}/measures`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      measures: {
        schemaPath: '#/definitions/Enumeral',
        schemaKey: 'Enumeral'
      }
    }, 'getRenovationMeasureTopicsMeasuresWithIdRequest', '/spaces-api/renovations'),
    actions: {
      start: {
        type: DATA_GET_RENOVATION_MEASURE_TOPICS_MEASURES_WITH_ID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_RENOVATION_MEASURE_TOPICS_MEASURES_WITH_ID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_RENOVATION_MEASURE_TOPICS_MEASURES_WITH_ID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getRenovationMeasureTopicsMeasuresWithIdRequestDefinitions = (definitionsArguments: {| requestArgs: getRenovationMeasureTopicsMeasuresWithIdRequestSpacesArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getRenovationMeasureTopicsMeasuresWithIdRequestSpacesArguments, payload?: Object |} => ({
  fnName: 'getRenovationMeasureTopicsMeasuresWithIdRequest',
  basePath: '/spaces-api/renovations',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_GET_RENOVATION_MEASURE_TOPICS_MEASURES_PROPERTIES_WITH_MEASUREID_START = 'DATA_GET_RENOVATION_MEASURE_TOPICS_MEASURES_PROPERTIES_WITH_MEASUREID_START'
export const DATA_GET_RENOVATION_MEASURE_TOPICS_MEASURES_PROPERTIES_WITH_MEASUREID_SUCCESSFUL = 'DATA_GET_RENOVATION_MEASURE_TOPICS_MEASURES_PROPERTIES_WITH_MEASUREID_SUCCESSFUL'
export const DATA_GET_RENOVATION_MEASURE_TOPICS_MEASURES_PROPERTIES_WITH_MEASUREID_FAIL = 'DATA_GET_RENOVATION_MEASURE_TOPICS_MEASURES_PROPERTIES_WITH_MEASUREID_FAIL'

// Returns additional properties that can be set for a renovation measure.
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/spaces.yaml#/Renovation/get_renovation__estimateId__measure_topics__id__measures__measureId__properties
export const getRenovationMeasureTopicsMeasuresPropertiesWithMeasureIdRequest = (
  requestArguments: getRenovationMeasureTopicsMeasuresPropertiesWithMeasureIdRequestSpacesArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_RENOVATION_MEASURE_TOPICS_MEASURES_PROPERTIES_WITH_MEASUREID_START',
      successful: 'DATA_GET_RENOVATION_MEASURE_TOPICS_MEASURES_PROPERTIES_WITH_MEASUREID_SUCCESSFUL',
      fail: 'DATA_GET_RENOVATION_MEASURE_TOPICS_MEASURES_PROPERTIES_WITH_MEASUREID_FAIL'
    },
    embeddedType: {
      properties: {
        schemaPath: '#/definitions/ValueProperty',
        schemaKey: 'ValueProperty'
      }
    },
    fnName: 'getRenovationMeasureTopicsMeasuresPropertiesWithMeasureIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/spaces-api/renovations`,
    method: 'GET',
    url: `/renovation/${options?.estimateId || store.app.calculation}/measure-topics/${requestArguments.path['id']}/measures/${requestArguments.path['measureId']}/properties`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      properties: {
        schemaPath: '#/definitions/ValueProperty',
        schemaKey: 'ValueProperty'
      }
    }, 'getRenovationMeasureTopicsMeasuresPropertiesWithMeasureIdRequest', '/spaces-api/renovations'),
    actions: {
      start: {
        type: DATA_GET_RENOVATION_MEASURE_TOPICS_MEASURES_PROPERTIES_WITH_MEASUREID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_RENOVATION_MEASURE_TOPICS_MEASURES_PROPERTIES_WITH_MEASUREID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_RENOVATION_MEASURE_TOPICS_MEASURES_PROPERTIES_WITH_MEASUREID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getRenovationMeasureTopicsMeasuresPropertiesWithMeasureIdRequestDefinitions = (definitionsArguments: {| requestArgs: getRenovationMeasureTopicsMeasuresPropertiesWithMeasureIdRequestSpacesArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getRenovationMeasureTopicsMeasuresPropertiesWithMeasureIdRequestSpacesArguments, payload?: Object |} => ({
  fnName: 'getRenovationMeasureTopicsMeasuresPropertiesWithMeasureIdRequest',
  basePath: '/spaces-api/renovations',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})
