// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.
import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { map } from 'lodash'
import { TextButton } from 'frontend-components'
import type { TextButtonProps } from '../../../common/TextButton/TextButton'
import { combineStyleClassNames } from '../../../../utils/styleUtils'

const styles = {
  footerButtons: {
    display: 'flex',
    alignSelf: 'flex-end'
  },
  buttonWrapper: {
    marginLeft: '10px'
  },
  alignRight: {
    justifyContent: 'flex-end'
  }
}

type Props = {
  classes: Object, // withstyles classes object
  items: Array<TextButtonProps>, // list of TextButtonProps
  align?: 'right'
}

export class FooterButtons extends Component<Props> {
  static defaultProps = {
    align: undefined
  }

  get buttons(): Array<React$Element<typeof TextButton>> {
    const { classes, items } = this.props

    return map(items, (item: TextButtonProps, index: number) => (
      // eslint-disable-next-line react/no-array-index-key
      <div key={`FooterButtons-${index}`} className={classes.buttonWrapper} >
        <TextButton
          {...item}
          testId={item.id}
          onClick={(ev: SyntheticEvent<any>) => {
            ev.stopPropagation()
            if (item.onClick) {
              item.onClick()
            }
          }} />
      </div>
    ))
  }

  getAlignmentClassName = (): string => {
    const { classes, align } = this.props
    switch (align) {
      case 'right': {
        return classes.alignRight
      }
      default: {
        return ''
      }
    }
  }

  render(): React$Element<any> {
    const { classes } = this.props
    return (
      <div data-testid='footer-buttons' className={combineStyleClassNames(classes.footerButtons, this.getAlignmentClassName())}>
        {this.buttons}
      </div>
    )
  }
}

export default withStyles(styles)(FooterButtons)
