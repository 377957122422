// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { reduce, filter } from 'lodash'

import SimpleHierarchicalList from '../../common/lists/SimpleHierarchicalList/SimpleHierarchicalList'
import InputField from '../../common/InputField/InputField'
import FeaturesHOC from '../../hocs/FeaturesHOC/FeaturesHOC'

import { FEATURE_HLC_ROW_CHECKBOXES } from '../../../constants/features'
import { getRootListItems, reverseTraverseListItems } from '../../../utils/listUtils'
import {
  toggleListItemOpen,
  toggleListItemSelected,
  modifyListItem,
  clearList
} from '../../../actions/list'
import { AREAM2PERPC, QUANTITYPCS } from '../../../constants/attributes'
import { validateUserInputNoNegatives } from '../../../utils/commonUtils'
import { ACTIVITY_STRUCTURE } from '../../../constants/contentTypes'

type HOCProps = {|
  features: TVDFeatureHOCProps, // features from Store and helper functions from the HOC
|}

type DispatchProps = {|
  dispatchToggleListItemOpen: (listItemId: string) => void, // toggles isOpen key in Store for the specific listItemId
  dispatchModifyListItem: (listItemId: string, columnName: string, value: string | number) => void, // store modified key value pair to the listItem
  dispatchToggleListItemSelected: (listItemId: string, shouldUnselectParents: boolean) => void, // toggling the selected boolean value of a row in Store
  dispatchClearList: (listId: string) => void, // clears list items
|}

type MappedProps = {|
  listItems: Object, // listItems from Store after running getList
  columns: Array<Object> | Object, // columns from Store after running getColumns
  listFilter?: string, // search string applied to the list found from Store
  isListModified?: boolean, // boolean to tell if list has been modified
  filteredListItems: TVDListItems, // filtered version (by listFilter) of listItems from Store
  activeFilterSource: Object, // filter and marker for wopActivitySchedule/wopSpaceSchedule filtering source list
  initialScrollPosition?: number // initial scroll position for the list
|}

type ReceivedProps = {|
  testId?: string, // testid to be passed to SimpleHierarchicalList
  listId: string, // uuid for the list, found in Store
  listType: string, // type or name of a list
  contextMenuItems: Object, // ContextMenu items
  editableColumns?: Array<string>, // names of columns that can be edited
  wrappedCellContents?: Object, // optional cell wrappers for SimpleHierarchicalList
  displayCheckBoxes?: boolean, // boolean to if we show and keep track of check boxes on each row
  columnsVisibleOnHover?: Array<string>, // column property names that are shown when row is hovered
  disabled?: boolean, // disabled status for the whole list, continues to list's cells, making them disabled
  checkboxDisabled?: boolean, // specifically disable checkbox
  rowShouldDisableOtherColumnsAfterEdit: boolean, // boolean for cases where row should disable other columns after cell has been edited (Surfaces.jsx)
  isEstimateLockedToCurrentUser: $PropertyType<TVDApplicationStore, 'isEstimateLockedToCurrentUser'>, // if the user owns the lock for the estimate
  widgetType: ?string, // widgetType
  hiddenColumns: Array<string>, // list of constant strings that are propertyNames of columns not to be shown in UI
  editableColumnUnits?: Array<string>, // array of editable column names that should display unit
  listRowCellClassNames?: { [string]: string }, // object of classNames that is passed to customize listRowCell
  listHeaderCellClassNames?: { [string]: string }, // object of classNames that is passed to customize listHeaderCell
  resizeDisabledColumns?: Array<string>, // column propertyNames that will not have resizable handle
  allowOverflow?: boolean, // allows wrapped cell content to have overflowing content e.g :before and :after css effects
  reverseTraverse?: TVDReverseTraverseConfig, // optional configs if we want to alter parents of a certain filtered items
  initialColumnWidths?: { [columnName: string]: string | number }, // assign initial column widths
  onRowClick?: Function, // optional callback for when clicking the row
  didMountCallback: (string) => void, // Fn used to do data requests for the list
  didUpdateCallback?: Function, // optional callback for componentDidUpdate - useful for e.g regetting data for list
  editableColumnCheck?: (TVDListItem) => boolean, // optional check up fn to determine if a column should render as an editable one
  onModifiedChange?: (TVDListItem | typeof undefined) => boolean, // returns the status if the list is modified or not
  onUnitClick?: (row: TVDListItem, propertyName: string) => Function, // a way to retrieve what should be ran if unit of a particular row and cell is editable
  sortFn?: (filteredListItems: TVDListItems) => Array<TVDListItem>, // optional sorting function to make the list e.g alphabetical
  hideListOpenerIcon?: boolean, // does not render list opener icon
  allowOnRowClick?: boolean, // flag to allow user to click on the row
  isEstimateFrozen?: $PropertyType<TVDApplicationStore, 'isEstimateFrozen'>, // if estimate is frozen
  onScrollCb?: (scrollTop: number) => void, // callback function for onScroll event that provides access to the scroll position
  componentPreferencesId?: string, // id to be used for getting component preferences for this list
  addedColumns?: Array<Object>, // additional columns to be added to the list
  fullHeightColumns?: Array<string>, // columns that should have full height
|}

type Props = {|
  ...HOCProps,
  ...DispatchProps,
  ...MappedProps,
  ...ReceivedProps
|}

type State = {|
  listId: string,
|}

export class HierarchicalListContainer extends Component<Props, State> {
  static defaultProps = {
    testId: 'missing-testid',
    onRowClick: null,
    isModified: false,
    onModifiedChange: null,
    wrappedCellContents: {},
    editableColumns: [],
    displayCheckBoxes: false,
    columnsVisibleOnHover: [],
    addedColumns: null,
    didUpdateCallback: null,
    disabled: false,
    isEstimateLockedToCurrentUser: true,
    hiddenColumns: [],
    editableColumnUnits: [],
    listRowCellClassNames: {},
    listHeaderCellClassNames: {},
    resizeDisabledColumns: [],
    isListModified: false,
    editableColumnCheck: undefined,
    checkboxDisabled: false,
    allowOverflow: false,
    reverseTraverse: null,
    initialColumnWidths: {},
    onUnitClick: undefined,
    sortFn: undefined,
    activeFilterSource: undefined,
    hideListOpenerIcon: false,
    isEstimateFrozen: false
  }
  state = {
    listId: this.props.listId
  }
  rowsCountRef: { current: null | number } = { current: 0 }

  componentDidMount() {
    const { didMountCallback } = this.props
    const { listId } = this.state
    if (didMountCallback) {
      didMountCallback(listId)
    }
  }

  componentDidUpdate(prevProps: Props) {
    const {
      onModifiedChange,
      listItems,
      didUpdateCallback,
      isListModified,
      listId,
      dispatchClearList
    } = this.props

    if (onModifiedChange && (prevProps.isListModified !== isListModified)) {
      onModifiedChange(this.getFirstModifiedListItem(listItems))
    }
    if (didUpdateCallback) {
      didUpdateCallback(prevProps, this.props)
    }
    if (listId !== prevProps.listId) {
      dispatchClearList(prevProps.listId)
    }
  }

  componentWillUnmount() {
    const { listId, dispatchClearList } = this.props
    dispatchClearList(listId)
  }

  traverseListItem = (listItems: Object, rootListItem: Object) => {
    if (rootListItem.isRemoved) return []
    let rootListItemChildrenCount = 0

    const children = filter(listItems, (listItem: Object) => {
      const rootListItemChild = listItem.parentId === rootListItem.id && !listItem.isRemoved
      if (rootListItemChild) rootListItemChildrenCount += 1
      return rootListItemChild && rootListItem.isOpen
    }).map((child: Object) => ({ ...this.getListItemModified(child), level: rootListItem.level + 1 }))

    return reduce(children, (result: Array<any>, child: Object) => [
      ...result,
      ...this.traverseListItem(listItems, child)
    ], [this.getListItemModified(rootListItem, rootListItemChildrenCount > 0)])
  }

  getListItemModified = (listItem: Object, canHaveChildren?: boolean) => {
    if (listItem.modifiedColumnData && Object.keys(listItem.modifiedColumnData).length > 0) {
      return {
        ...listItem,
        canHaveChildren,
        columnData: {
          ...listItem.columnData,
          ...listItem.modifiedColumnData
        }
      }
    }
    return { ...listItem, canHaveChildren: listItem.canHaveChildren || canHaveChildren }
  }

  getFirstModifiedListItem = (listItems: Object): TVDListItem | typeof undefined => {
    const modifiedListItemId = Object.keys(listItems).find((listItemId: string): boolean => listItems[listItemId].isRemoved ||
    listItems[listItemId].isAdded ||
    listItems[listItemId].isUserAdded ||
    Object.keys(listItems[listItemId].modifiedColumnData || {}).length > 0)
    return listItems[modifiedListItemId]
  }

  isInputDisabled(rowId: string, columnName: string, content: string): boolean {
    const {
      disabled,
      isEstimateLockedToCurrentUser,
      rowShouldDisableOtherColumnsAfterEdit,
      listItems,
      isEstimateFrozen
    } = this.props
    if (disabled || !isEstimateLockedToCurrentUser || content === undefined || isEstimateFrozen) return true
    if (rowShouldDisableOtherColumnsAfterEdit && listItems[rowId]) {
      // if prop is true disable other columns than modifiedColumn
      const { modifiedColumn } = listItems[rowId]

      if (modifiedColumn && modifiedColumn !== columnName) return true
    }
    return false
  }

  editableColumns(): Object {
    const {
      editableColumns,
      dispatchModifyListItem,
    } = this.props

    return reduce(editableColumns, (result: Object, editableColumn: Object) => ({
      ...result,
      [editableColumn]: ({
        content,
        dataType,
        row: { id: rowId, columnData: { Description } }
      }: Object) => (
        <InputField
          width='100%'
          dataType={dataType}
          disabled={this.isInputDisabled(rowId, editableColumn, content)}
          initialValue={content}
          id={`${Description}-${editableColumn}`}
          onChange={(value: string | number) => dispatchModifyListItem(rowId, editableColumn, value)}
          customValidator={
            (editableColumn === AREAM2PERPC || editableColumn === QUANTITYPCS) ?
            validateUserInputNoNegatives :
            undefined
          } />)
    }), {})
  }

  filteredListItems(): Array<TVDListItem> {
    const {
      listItems = {},
      filteredListItems,
      reverseTraverse,
      listType,
      sortFn
    } = this.props

    let items = listItems

    if (filteredListItems) items = filteredListItems
    if (reverseTraverse) items = reverseTraverseListItems(items, reverseTraverse)

    const rootListItems = getRootListItems(items, listType)
    const resultedItems = reduce(rootListItems, (result: Array<TVDListItem>, rootListItem: TVDListItem) => [
      ...result,
      ...this.traverseListItem(items, rootListItem)
    ], [])

    return sortFn ? sortFn(resultedItems) : resultedItems
  }

  render(): React$Element<any> | null {
    const {
      columns = {},
      wrappedCellContents,
      dispatchToggleListItemOpen,
      onRowClick,
      displayCheckBoxes,
      dispatchToggleListItemSelected,
      columnsVisibleOnHover,
      testId,
      listType,
      disabled,
      checkboxDisabled,
      features,
      widgetType,
      hiddenColumns,
      contextMenuItems,
      editableColumnUnits,
      listRowCellClassNames,
      listHeaderCellClassNames,
      resizeDisabledColumns,
      editableColumnCheck,
      allowOverflow,
      initialColumnWidths,
      onUnitClick,
      activeFilterSource,
      hideListOpenerIcon,
      allowOnRowClick,
      onScrollCb,
      initialScrollPosition,
      fullHeightColumns
    } = this.props

    const {
      listId
    } = this.state

    const filteredListItems = this.filteredListItems()
    const optionalProps = widgetType ? { widgetType } : {}
    const filteredColumns = filter(columns, (({ propertyName }: Object) => !hiddenColumns.includes(propertyName)))
    let scrollPosition
    if (!!filteredListItems && filteredListItems.length > 0 && this.rowsCountRef.current === 0 && initialScrollPosition) {
      scrollPosition = filteredListItems.length > 0 && initialScrollPosition ? initialScrollPosition : null
      this.rowsCountRef.current = filteredListItems.length
    }
    return (
      filteredColumns.length > 0 ? <SimpleHierarchicalList
        fullHeightColumns={fullHeightColumns}
        scrollPosition={scrollPosition}
        onScrollCb={onScrollCb}
        hideListOpenerIcon={hideListOpenerIcon}
        resizeDisabledColumns={resizeDisabledColumns}
        contextMenuItems={contextMenuItems}
        disabled={disabled}
        checkboxDisabled={checkboxDisabled}
        activeFilterSource={activeFilterSource}
        testId={testId}
        listType={listType}
        columnsVisibleOnHover={columnsVisibleOnHover}
        editableColumnUnits={editableColumnUnits}
        displayCheckBoxes={
          features.getIsFeatureDisabled(FEATURE_HLC_ROW_CHECKBOXES) ?
            false :
            displayCheckBoxes
        }
        listRowCellClassNames={listRowCellClassNames}
        listHeaderCellClassNames={listHeaderCellClassNames}
        wrappedCellContents={wrappedCellContents}
        editableColumns={this.editableColumns()}
        editableColumnCheck={editableColumnCheck}
        data={filteredListItems}
        columns={filteredColumns}
        onCheckBoxClick={({ id: listItemId }: Object) => { dispatchToggleListItemSelected(listItemId, listId === ACTIVITY_STRUCTURE) }}
        onRowClick={(row: Object) => {
          if (row.canHaveChildren) dispatchToggleListItemOpen(row.id)
          if (onRowClick) onRowClick(row)
        }}
        {...optionalProps}
        initialColumnWidths={initialColumnWidths}
        onUnitClick={onUnitClick}
        allowOverflow={allowOverflow}
        allowOnRowClick={allowOnRowClick} /> : null
    )
  }
}

const mapStateToProps = (
  {
    list,
    componentPreferences
  }: TVDReduxStore,
  {
    listId,
    addedColumns,
    componentPreferencesId
  }: ReceivedProps
): MappedProps => {
  const {
    listItems = {},
    columns = [],
    listFilter,
    isListModified,
    filteredListItems,
    activeFilterSource
  } = list[listId] || {}

  const {
    scrollPosition: initialScrollPosition
  } = componentPreferencesId && componentPreferences[componentPreferencesId] ? componentPreferences[componentPreferencesId] : {}

  return {
    listItems,
    columns: addedColumns ? reduce(columns, (result: Array<Object>, column: Object) => [...result, column], []).concat(addedColumns) : columns,
    listFilter,
    isListModified,
    filteredListItems,
    activeFilterSource,
    initialScrollPosition
  }
}

const mapDispatchToProps = (dispatch: Function, { listId, componentPreferencesId }: ReceivedProps): DispatchProps => ({
  dispatchToggleListItemOpen: (listItemId: string) => { dispatch(toggleListItemOpen(listItemId, listId, componentPreferencesId)) },
  dispatchToggleListItemSelected: (listItemId: string, shouldUnselectParents?: boolean) => {
    dispatch(toggleListItemSelected(listItemId, listId, shouldUnselectParents))
  },
  dispatchClearList: (_listId: string) => { dispatch(clearList(_listId)) },
  dispatchModifyListItem: (listItemId: string, columnName: string, value: string | number) => {
    dispatch(modifyListItem({
      listId, listItemId, columnName, value
    }))
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(FeaturesHOC(HierarchicalListContainer))
