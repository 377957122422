// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.
export const NEWCONSTRUCTION = 'NewConstruction'
export const SPACESCHEDULE = 'SpaceSchedule'
export const RENOVATION = 'Renovation'
export const NEWRENOVATION = 'Renovation'
export const NEWSPACESCHEDULE = 'SpaceSchedule'
export const PRICE = 'Price'
export const CARBONFOOTPRINT = 'CarbonFootprint'
export const USAGEMAINTENANCE = 'UsageMaintenance'

// view query parameters for the /columns endpoint in Spaces
export const viewMode = {
  SPACESCHEDULE,
  NEWCONSTRUCTION,
  RENOVATION
}

// view query parameters for /columns endpoint in ImportFromCalculation widget
export const importViewModes = {
  NEWCONSTRUCTION,
  RENOVATION,
}

// Spaces Estimate types
export const spacesEstimateTypes = {
  NEWCONSTRUCTION,
  NEWRENOVATION,
  USAGEMAINTENANCE
}

// Spaces Result View Type
export const spacesResultViewTypes = {
  NEWSPACESCHEDULE,
  PRICE,
  CARBONFOOTPRINT
}
