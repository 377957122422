// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.

import React, { Component } from 'react'
import { Autocomplete } from '@material-ui/lab'
import { TextField, withStyles } from '@material-ui/core'
import { withTranslation } from 'react-i18next'

import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import Fade from '@material-ui/core/Fade'

import { dropdownWidths, type DropdownMenuWidths } from '../../menus/components/DropdownMenuCore/DropdownMenuCore'
import MenuItem, { type MenuItemProps } from '../../menus/components/MenuItems/MenuItem/MenuItem'
import HelperText from '../../menus/components/HelperText/HelperText'

import { combineStyleClassNames } from '../../../../utils/styleUtils'
import theme from '../../../../styles/theme'
// $FlowFixMe
import { ReactComponent as SearchIcon } from '../../../../../node_modules/frontend-assets/static/assets/images/icons/Other Search.svg'

const LEFT_OFFSET = '15px'
const RIGHT_OFFSET = '15px'
const INPUT_WIDTH = `calc(100% - ${LEFT_OFFSET} - ${RIGHT_OFFSET})`

const styles = ({ palette }: TVDTheme) => ({
  popper: {
    zIndex: '9001'
  },
  listbox: {
    maxHeight: '245px',
    paddingTop: 0,
    marginTop: '5px'
  },
  option: {
    overflow: 'inherit',
    minHeight: '40px',
    display: 'flex',
    alignItems: 'center',
    padding: '0px',
    '&[aria-selected="true"]': {
      backgroundColor: 'transparent'
    },
    color: palette.dark80,
    '&:hover': {
      backgroundColor: palette.gray20
    },
    '&:active': {
      backgroundColor: palette.primary10,
      '&>div': {
        backgroundColor: palette.primary10
      }
    }
  },
  noOptions: {
    padding: '0px 16px'
  },
  asterisk: {
    color: palette.dark80
  },
  inputUnderline: {
    ...theme.typography.classes.bodyBig,
    '&:hover': {
      backgroundColor: palette.gray20,
      color: palette.dark60
    },
    // needs to override a triple :not rule of MUI
    // https://github.com/mui-org/material-ui/issues/12192
    '&&&&:hover input': {
      cursor: 'pointer'
    },
    '&&&&:hover:before': {
      color: palette.gray60,
      borderBottom: 'none',
      cursor: 'pointer'
    },
    '&&:before': {
      borderBottomStyle: 'solid'
    },
    '&:after': {
      borderBottom: `2px solid ${palette.primary60}`,
      color: palette.black
    }
  },
  inputUnderlineToggle: {
    ...theme.typography.classes.bodyBig,
    height: 32,
    borderBottom: `1px solid ${palette.gray80}`,
    '&:hover': {
      backgroundColor: palette.gray20,
      color: palette.dark60,
      borderBottom: 'none'
    },
    '&&:hover input': {
      cursor: 'pointer',
    },
    '&&:hover:before': {
      borderBottom: 'none',
      cursor: 'pointer'
    },
    '&&:before': {
      borderBottom: 'none'
    },
    '&:after': {
      borderBottom: `1px solid ${palette.gray80}`
    }
  },
  inputAutoCompleteUnderline: {
    '&&&&:hover:before': {
      borderBottom: `2px solid ${palette.primary60}`,
      width: INPUT_WIDTH
    },
    '&:before': {
      borderBottom: `2px solid ${palette.primary60}`,
    },
    '&:after': {
      borderBottom: `2px solid ${palette.primary60}`,
      width: INPUT_WIDTH,
      marginLeft: LEFT_OFFSET
    },
    '& > *::selection': {
      background: palette.primary40
    }
  },
  inputRoot: {
    '&&$inputRoot': {
      paddingRight: RIGHT_OFFSET,
      paddingLeft: LEFT_OFFSET
    }
  },
  searchIcon: {
    color: palette.dark60
  },
  autoCompleteBody: {
    borderTopLeftRadius: '4px',
    borderTopRightRadius: '4px',
    boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.4)',
    paddingBottom: '9px',
    paddingTop: '9px',
    position: 'fixed',
    zIndex: 2,
    backgroundColor: palette.white
  },
  inputAutoComplete: {
    color: palette.black,
    '&:before': {
      width: INPUT_WIDTH,
      left: LEFT_OFFSET,
      borderBottomWidth: '2px',
      paddingRight: '4px'
    }
  },
  inputAutoCompleteLabel: {
    color: palette.dark80,
    marginLeft: LEFT_OFFSET
  },
  inputLabelRoot: {
    '&$inputLabelRoot': {
      color: palette.dark80,
      ...theme.typography.classes.bodySmall
    }
  },
  icon: {
    color: palette.dark60
  },
  inputLabelFocused: {
    '&$inputLabelFocused': {
      color: palette.dark80
    }
  },
  inputFocused: {
    color: palette.dark80,
    '&$inputFocused': {
      textOverflow: 'ellipsis',
      borderBottom: 'none'
    }
  },
  inputDisabled: {
    borderBottom: `1px dotted ${palette.gray80}`,
    color: palette.gray80,
    backgroundColor: palette.white
  },
  paper: {
    marginTop: '8px',
    borderRadius: '0px 0px 4px 4px',
    boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.4)',
    clipPath: 'inset(0px -6px -6px -6px)'
  },
  inputEllipsis: {
    textOverflow: 'ellipsis'
  },
  inputEllipsisToggle: {
    textOverflow: 'ellipsis',
    paddingBottom: '6px'
  },
  ...dropdownWidths
})
type AutocompleteInputParams = {|
  id: string, // generated id for the input
  disabled: boolean, // if the input should be considered disabled
  InputLabelProps: {|
    id: string, // generated id for the <label /> element
    htmlFor: string // for attribute value
  |},
  InputProps: {|
    className: string, // className string prop for the input
    startAdornment: React$Element<any>, // element at the beginning of the input
    endAdornment: React$Element<any>, // element at the end of the input
    ref: Function, // react ref
  |},
  // https://material-ui.com/api/text-field/
  inputProps: Object // Attributes applied to the input element.
|}

export const ON_CHANGE_REASON_CLEAR: 'clear' = 'clear'
export const ON_CHANGE_REASON_SELECT_OPTION: 'select-option' = 'select-option'
// https://material-ui.com/api/autocomplete/ constants for onChange functions third parameter "reason"
export type TVDOnChangeReason = 'create-option' | typeof ON_CHANGE_REASON_SELECT_OPTION | 'remove-option' | 'blur' | typeof ON_CHANGE_REASON_CLEAR

export type ReceivedProps = {|
  items: Array<MenuItemProps>, // array of items to show in the menu
  onChange: (
    value: $PropertyType<MenuItemProps, 'value'>,
    item: MenuItemProps,
    reason?: TVDOnChangeReason,
    event?: SyntheticInputEvent<any>) => void, // onChange callback when a selection is changed
  error?: boolean, // if we display the component in error styles
  required?: boolean, // if we consider the input being required and display component with required styles and content e.g with asterisk
  testId?: string, // data-testid attribute value mainly for e2e tests
  value?: string, // A value of one of the items given to this component and used to choose the selected item
  onClear?: () => void, // callback function for when Autocomplete's onChange does a change with the reason "clear"
  disableClearable?: boolean, // if true, the input can't be cleared
  width?: DropdownMenuWidths, // one of set widths available for dropdowns
  disabled?: boolean, // whether the menu is disabled
  title?: string, // shown as the input label
  defaultValue?: string | number, // the value for an item that starts as the selected one
  placeholder?: string, // text for a placeholder selection
  required?: boolean, // if the menu is a required menu
  disabledTitle?: string, // custom title shown if component is disabled and the prop is provided
  helperText?: string, // displayed under the menu
  minimalist?: boolean, // a minimalist version of the dropdown
|}

type Props = {|
  ...ReceivedProps,
  classes: Object, // withStyles object
  t: (translationKey: string) => string, // HOC withTranslation translate function
|}

type State = {|
  value: MenuItemProps | null, // Autocomplete component's controlled value for prop value
  toggle: boolean // toggle state of component
|}

export class DropdownSearchMenu extends Component<Props, State> {
  static defaultProps = {
    testId: null,
    value: null,
    width: 'M',
    title: null
  }

  state = {
    value: null,
    toggle: false,
  }

  componentDidMount() {
    const { value, defaultValue } = this.props
    const val = value || defaultValue
    if (val) this.setState({ value: this.getItemByValue(val) })
  }

  componentDidUpdate(prevProps: Props) {
    const stateValue = this.state.value?.value
    const propsValue = this.props.value
    const prevPropsValue = prevProps.value
    if (propsValue !== stateValue && prevPropsValue !== propsValue) {
      this.setState({ value: propsValue ? this.getItemByValue(propsValue) : null })
    }
  }

  static getOptionLabel({ localizedName = '' }: MenuItemProps): string {
    return localizedName
  }

  static getOptionSelected(item: MenuItemProps, selectedItem: MenuItemProps): boolean {
    return item.localizedName === selectedItem.localizedName && item.value === selectedItem.value
  }

  static getItem(itemProps: MenuItemProps): React$Element<typeof MenuItem> {
    return <MenuItem {...itemProps} allowPropagation component={itemProps.component || 'div'} />
  }

  getItemByValue(value: string | number): MenuItemProps | null {
    return this.props.items.find((item: MenuItemProps): boolean => item.value === value) || null
  }

  getInput(params: AutocompleteInputParams): React$Element<typeof TextField> {
    const {
      classes,
      error,
      title,
      t,
      disabled,
      disabledTitle,
    } = this.props
    const label = disabled && disabledTitle ? disabledTitle : title
    return (
      <TextField
        {...params}
        placeholder={t('general._SEARCH_')}
        InputProps={{
          ...params.InputProps,
          endAdornment: <SearchIcon />,
          classes: {
            root: combineStyleClassNames(classes.inputAutoComplete),
            underline: combineStyleClassNames(classes.inputAutoCompleteUnderline)
          },
        }}
        InputLabelProps={{
            classes: {
              root: classes.inputAutoCompleteLabel,
              focused: classes.inputLabelFocused
            }
          }}
        error={error}
        label={label} />
    )
  }

  onChange(event: SyntheticInputEvent<any>, item: MenuItemProps, reason: TVDOnChangeReason) {
    const { onChange, onClear } = this.props
    switch (reason) {
      case ON_CHANGE_REASON_CLEAR: {
        if (onClear) onClear()
        this.setState({ value: null })
        break
      }
      case ON_CHANGE_REASON_SELECT_OPTION: {
        onChange(item.value, item, reason, event)
        this.setState({ value: item })
        break
      }
      default: {
        if (onChange) onChange(item.value, item, reason, event)
      }
    }
  }

  getWidth(width?: DropdownMenuWidths): string {
    const { classes } = this.props
    if (classes[width]) return classes[width]

    console.error(`Invalid width prop provided with value of "${width || typeof width}" - reverting to default`)
    return classes[DropdownSearchMenu.defaultProps.width]
  }

  autoCompleteBody(): React$Element<typeof Autocomplete> {
    const {
      items,
      classes,
      t,
      testId,
      disableClearable,
      width,
    } = this.props

    return (
      <div className={combineStyleClassNames(classes[width], classes.autoCompleteBody)} style={{ minWidth: this.getWidth(width) }}>
        <Autocomplete
          open
          disableClearable={disableClearable}
          data-testid={testId}
          noOptionsText={t('dropdowns._NO_OPTIONS_')}
          classes={{
            popper: classes.popper,
            listbox: classes.listbox,
            root: this.getWidth(width),
            option: classes.option,
            noOptions: combineStyleClassNames(classes.option, classes.noOptions),
            inputRoot: classes.inputRoot,
            paper: combineStyleClassNames(classes.paper, classes[width])
          }}
          getOptionSelected={DropdownSearchMenu.getOptionSelected}
          onChange={this.onChange.bind(this)}
          options={items}
          openOnFocus
          renderInput={this.getInput.bind(this)}
          renderOption={DropdownSearchMenu.getItem}
          getOptionLabel={DropdownSearchMenu.getOptionLabel} />
      </div>

    )
  }

  render(): React$Element<any> {
    const {
      error,
      classes,
      title,
      helperText,
      width,
      minimalist,
      required,
      placeholder,
      t,
      disabled
    } = this.props
    const { value } = this.state

    const checkDisabledUnderLine = disabled ? null : combineStyleClassNames(this.state.toggle ? classes.inputUnderlineToggle
      : classes.inputUnderline, classes[width])
    return (
      <ClickAwayListener onClickAway={() => {
        if (!disabled) this.setState({ toggle: false })
        }}>
        <div>
          <TextField
            disabled={disabled}
            required={required}
            placeholder={placeholder || t('general._SEARCH_')}
            className={this.state.toggle ? combineStyleClassNames(classes[width], classes.textField) : ''}
            value={!value ? '' : value.localizedName}
            error={error}
            label={title}
            InputLabelProps={{
              shrink: true,
              classes: {
                root: classes.inputLabelRoot,
                focused: classes.inputLabelFocused,
                asterisk: classes.asterisk
              },
            }}
            InputProps={{
              readOnly: true,
              endAdornment: <ArrowDropDownIcon className={classes.icon} style={{ cursor: 'pointer' }} />,
              classes: {
                root: combineStyleClassNames(minimalist && classes.inputUnderlineToggle),
                focused: classes.inputFocused,
                disabled: classes.inputDisabled,
                underline: checkDisabledUnderLine,
                input: combineStyleClassNames(this.state.toggle ? classes.inputEllipsisToggle : classes.inputEllipsis, classes[width])
              },
            }}
            onClick={() => {
              if (!disabled) {
                this.setState({ toggle: !this.state.toggle })
              }
            }} />
          <Fade in={this.state.toggle}>{this.state.toggle ? this.autoCompleteBody() : <div />}</Fade>
          { helperText && <HelperText error={error}>{helperText}</HelperText> }
        </div>
      </ClickAwayListener>
    )
  }
}

export default withTranslation('translations')(withStyles(styles)(DropdownSearchMenu))
