// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.

export const getResultBarDataFromValueProperties = (propertiesListItems: Array<TVDPropertiesListItem>): Array<TVDResultBarData> =>
  propertiesListItems.map(({
    propertyName, localizedName, value, unit
  }: TVDPropertiesListItem): TVDResultBarData => ({
    propertyName,
    title: localizedName,
    value: parseFloat(value),
    unit
  }))
