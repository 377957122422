
import {
  TVD_HEADER_ACCOUNT_ID,
  TVD_HEADER_REALESTATE_ID,
  TVD_HEADER_LANGUAGE
} from '../../../constants/apiConstants'
import { store } from '../../../store'

const { getState } = store

export const getDefaultRequestHeaders = () => {
  const {
    user: {
      authorization: {
        accessToken,
      },
      claims: {
        haahtelaApiUserToken,
        userId
      }
    },
    app: {
      selectedAccountId,
      realEstateId,
      languageCode
    }
  } = getState()

  return {
    Authorization: `Bearer ${accessToken}`,
    'X-TakuPro-User-ID': haahtelaApiUserToken.length > 0 ? haahtelaApiUserToken : userId,
    [TVD_HEADER_LANGUAGE]: languageCode,
    [TVD_HEADER_ACCOUNT_ID]: selectedAccountId,
    ...(realEstateId ? { [TVD_HEADER_REALESTATE_ID]: realEstateId } : {})
  }
}
