// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.
import React, { useEffect, useCallback, useRef } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { loadMFE } from '../../../utils/MFEUtils'
import { API_BASE_URL, API_REALTIME_URL, ALLOW_WITH_CREDENTIALS } from '../../../constants/envConstants'
import { RENOVATION_PROGRAM } from '../../../constants/moduleConstants'
import { getEstimateHistoryObj } from '../../../utils/urlUtil'
import { openGenericErrorModal } from '../../../utils/modalUtils'
import { headerHeight } from '../../../components/common/Header/Header'
import { getWSConnection } from '../../../utils/websocketUtils'
import { getIsFeatureEnabledInSet } from '../../../utils/features'
import { FEATURE_LICENCE_TYPE_CHIP } from '../../../constants/features'
import { refreshUserAccessToken } from '../../../actions/user'

const useStyles = makeStyles(({ palette }: TVDTheme) => ({
  root: {
    height: '100%',
    width: '100%',
    paddingTop: headerHeight,
    paddingRight: '8px',
    background: palette.gray20
  }
}))

const RenovationProgram = (): React$Element<'div'> => {
  const classes = useStyles()
  const history = useHistory()
  const moduleOnUnmountRef = useRef<$PropertyType<TVDMFEFunctionality, 'onUnmount'>>()
  const {
    calculation,
    buildingId,
    realEstateId,
    accessToken,
    haahtelaApiUserToken,
    userId,
    languageCode,
    selectedAccountId,
    realEstateName,
    isEstimateLockedToCurrentUser,
    isEstimateFrozen,
    licenseType,
    activeEstimate: activeRENOEstimate
  } = useSelector(({
    app,
    user,
    activeEstimate
  }: TVDReduxStore): {|
    languageCode: $PropertyType<TVDApplicationStore, 'languageCode'>,
    buildingId: $PropertyType<TVDApplicationStore, 'buildingId'>,
    selectedAccountId: $PropertyType<TVDApplicationStore, 'selectedAccountId'>,
    calculation: $PropertyType<TVDApplicationStore, 'calculation'>,
    realEstateId: $PropertyType<TVDApplicationStore, 'realEstateId'>,
    realEstateName: $PropertyType<TVDApplicationStore, 'realEstateName'>,
    accessToken: $PropertyType<TVDUserAuthorization, 'accessToken'>,
    haahtelaApiUserToken: $PropertyType<TVDUserClaims, 'haahtelaApiUserToken'>,
    userId: $PropertyType<TVDUserClaims, 'userId'>,
    isEstimateLockedToCurrentUser: $PropertyType<TVDApplicationStore, 'isEstimateLockedToCurrentUser'>,
    isEstimateFrozen: $PropertyType<TVDApplicationStore, 'isEstimateFrozen'>,
    licenseType: $PropertyType<TVDApplicationStore, 'licenseType'>,
    activeEstimate: TVDActiveEstimateStore
  |} => ({
    languageCode: app.languageCode,
    buildingId: app.buildingId,
    selectedAccountId: app.selectedAccountId,
    calculation: app.calculation,
    realEstateId: app.realEstateId,
    realEstateName: app.realEstateName,
    accessToken: user.authorization.accessToken,
    haahtelaApiUserToken: user.claims.haahtelaApiUserToken,
    userId: user.claims.userId,
    isEstimateLockedToCurrentUser: app.isEstimateLockedToCurrentUser,
    isEstimateFrozen: app.isEstimateFrozen,
    licenseType: app.licenseType,
    activeEstimate
  }))

  const navigateToRenovationEstimate = useCallback((estimateId: string, beforeNavigation?: () => void): void => {
    if (beforeNavigation) {
      beforeNavigation()
    }
    history.push(getEstimateHistoryObj({
      app: RENOVATION_PROGRAM,
      realEstateName,
      accountId: selectedAccountId,
      estimateId,
      realEstateId
    }))
  }, [
    history,
    realEstateName,
    selectedAccountId,
    realEstateId
  ])

  const WSConnection = getWSConnection()

  useEffect(() => () => {
    if (moduleOnUnmountRef.current) {
      moduleOnUnmountRef.current()
    }
  }, [])

  useEffect(() => {
    loadMFE('renovation_program', 'renovation-program-module', (module: TVDMFEFunctionality): void => {
      if (
        calculation &&
        languageCode &&
        accessToken &&
        haahtelaApiUserToken &&
        userId &&
        selectedAccountId &&
        realEstateId &&
        buildingId &&
        WSConnection &&
        activeRENOEstimate
      ) {
        moduleOnUnmountRef.current = module.onUnmount
        const render = module.render || null
        if (render) {
          render(
            'renovation-program',
            {
              APIBaseURL: API_BASE_URL,
              APIRealtimeURL: API_REALTIME_URL,
              languageCode,
              accessToken,
              haahtelaApiUserToken,
              userId,
              selectedAccountId,
              estimateId: calculation,
              realEstateId,
              buildingId,
              isEstimateFrozen,
              isEstimateLockedToCurrentUser,
              navigateToRenovationEstimate,
              realEstateName,
              WSConnection,
              licenseType: getIsFeatureEnabledInSet(FEATURE_LICENCE_TYPE_CHIP) ? licenseType : null,
              ALLOW_WITH_CREDENTIALS,
              activeEstimate: activeRENOEstimate,
              openGenericErrorModal,
              refreshUserAccessToken
            }
          )
        }
      }
    })
  }, [
    languageCode,
    accessToken,
    haahtelaApiUserToken,
    userId,
    selectedAccountId,
    calculation,
    realEstateId,
    buildingId,
    navigateToRenovationEstimate,
    isEstimateLockedToCurrentUser,
    isEstimateFrozen,
    realEstateName,
    WSConnection,
    licenseType,
    activeRENOEstimate
  ])
  return (
    <div className={classes.root} id='renovation-program' />
  )
}

export default RenovationProgram

