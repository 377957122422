// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.
/* eslint max-len: ["error", { "code": 350 }] */
/*
  Haahtela Spaces Api 30.2.2
  Spaces services for Haahtela Api  - this file is to be deprecated, please use openapi.yaml instead!
  192.168.254.119:5010
*/

import requestFn from './../requests/request'
import { API_BASE_URL } from './../../constants/envConstants'
import parserMapper from './../parserMapper'
import { getState } from '../../store'

export const basePath = '/spaces-api'

export const DATA_GET_SPACE_SCHEDULE_WITH_ESTIMATEID_START = 'DATA_GET_SPACE_SCHEDULE_WITH_ESTIMATEID_START'
export const DATA_GET_SPACE_SCHEDULE_WITH_ESTIMATEID_SUCCESSFUL = 'DATA_GET_SPACE_SCHEDULE_WITH_ESTIMATEID_SUCCESSFUL'
export const DATA_GET_SPACE_SCHEDULE_WITH_ESTIMATEID_FAIL = 'DATA_GET_SPACE_SCHEDULE_WITH_ESTIMATEID_FAIL'

// Returns space schedule root level or full if given listType=flat
export const getSpaceScheduleWithEstimateIdRequest = (
  requestArguments: getSpaceScheduleWithEstimateIdRequestSpacesArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_SPACE_SCHEDULE_WITH_ESTIMATEID_START',
      successful: 'DATA_GET_SPACE_SCHEDULE_WITH_ESTIMATEID_SUCCESSFUL',
      fail: 'DATA_GET_SPACE_SCHEDULE_WITH_ESTIMATEID_FAIL'
    },
    embeddedType: {
      items: {
        schemaPath: '#/definitions/ScheduleItem',
        schemaKey: 'ScheduleItem'
      }
    },
    fnName: 'getSpaceScheduleWithEstimateIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/spaces-api`,
    method: 'GET',
    url: `/space-schedule/${options?.estimateId || store.app.calculation}`,
    params: {
      spacesEstimateType: options?.spacesEstimateType || store.app.spacesEstimateType,
      spacesResultView: options?.spacesResultView || store.app.spacesResultView,
      listType: requestArguments.query['listType'],
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      items: {
        schemaPath: '#/definitions/ScheduleItem',
        schemaKey: 'ScheduleItem'
      }
    }, 'getSpaceScheduleWithEstimateIdRequest', '/spaces-api'),
    actions: {
      start: {
        type: DATA_GET_SPACE_SCHEDULE_WITH_ESTIMATEID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_SPACE_SCHEDULE_WITH_ESTIMATEID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_SPACE_SCHEDULE_WITH_ESTIMATEID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getSpaceScheduleWithEstimateIdRequestDefinitions = (definitionsArguments: {| requestArgs: getSpaceScheduleWithEstimateIdRequestSpacesArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getSpaceScheduleWithEstimateIdRequestSpacesArguments, payload?: Object |} => ({
  fnName: 'getSpaceScheduleWithEstimateIdRequest',
  basePath: '/spaces-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_POST_SPACE_SCHEDULE_WITH_ESTIMATEID_START = 'DATA_POST_SPACE_SCHEDULE_WITH_ESTIMATEID_START'
export const DATA_POST_SPACE_SCHEDULE_WITH_ESTIMATEID_SUCCESSFUL = 'DATA_POST_SPACE_SCHEDULE_WITH_ESTIMATEID_SUCCESSFUL'
export const DATA_POST_SPACE_SCHEDULE_WITH_ESTIMATEID_FAIL = 'DATA_POST_SPACE_SCHEDULE_WITH_ESTIMATEID_FAIL'

// Imports all spaces from current WOP import estimate or selected items only if selectedIds body parameter is given
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/spaces.yaml#/Space%20Schedule/post_space_schedule__estimateId_
export const postSpaceScheduleWithEstimateIdRequest = (
  requestArguments: postSpaceScheduleWithEstimateIdRequestSpacesArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_POST_SPACE_SCHEDULE_WITH_ESTIMATEID_START',
      successful: 'DATA_POST_SPACE_SCHEDULE_WITH_ESTIMATEID_SUCCESSFUL',
      fail: 'DATA_POST_SPACE_SCHEDULE_WITH_ESTIMATEID_FAIL'
    },
    embeddedType: null,
    fnName: 'postSpaceScheduleWithEstimateIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/spaces-api`,
    method: 'POST',
    url: `/space-schedule/${options?.estimateId || store.app.calculation}`,
    params: {
      attachToId: requestArguments.query?.['attachToId'],
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: requestArguments.body
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'postSpaceScheduleWithEstimateIdRequest', '/spaces-api'),
    actions: {
      start: {
        type: DATA_POST_SPACE_SCHEDULE_WITH_ESTIMATEID_START, payload, config, meta
      },
      successful: {
        type: DATA_POST_SPACE_SCHEDULE_WITH_ESTIMATEID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_POST_SPACE_SCHEDULE_WITH_ESTIMATEID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_GET_SPACE_SCHEDULE_COLUMNS_WITH_ESTIMATEID_START = 'DATA_GET_SPACE_SCHEDULE_COLUMNS_WITH_ESTIMATEID_START'
export const DATA_GET_SPACE_SCHEDULE_COLUMNS_WITH_ESTIMATEID_SUCCESSFUL = 'DATA_GET_SPACE_SCHEDULE_COLUMNS_WITH_ESTIMATEID_SUCCESSFUL'
export const DATA_GET_SPACE_SCHEDULE_COLUMNS_WITH_ESTIMATEID_FAIL = 'DATA_GET_SPACE_SCHEDULE_COLUMNS_WITH_ESTIMATEID_FAIL'

// Get possible columns for space schedule
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/spaces.yaml#/Space%20Schedule/get_space_schedule__estimateId__columns
export const getSpaceScheduleColumnsWithEstimateIdRequest = (
  payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_SPACE_SCHEDULE_COLUMNS_WITH_ESTIMATEID_START',
      successful: 'DATA_GET_SPACE_SCHEDULE_COLUMNS_WITH_ESTIMATEID_SUCCESSFUL',
      fail: 'DATA_GET_SPACE_SCHEDULE_COLUMNS_WITH_ESTIMATEID_FAIL'
    },
    embeddedType: {
      properties: {
        schemaPath: '#/definitions/ValueProperty',
        schemaKey: 'ValueProperty'
      }
    },
    fnName: 'getSpaceScheduleColumnsWithEstimateIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/spaces-api`,
    method: 'GET',
    url: `/space-schedule/${options?.estimateId || store.app.calculation}/columns`,
    params: {
      spacesEstimateType: options?.spacesEstimateType || store.app.spacesEstimateType,
      spacesResultView: options?.spacesResultView || store.app.spacesResultView,
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      properties: {
        schemaPath: '#/definitions/ValueProperty',
        schemaKey: 'ValueProperty'
      }
    }, 'getSpaceScheduleColumnsWithEstimateIdRequest', '/spaces-api'),
    actions: {
      start: {
        type: DATA_GET_SPACE_SCHEDULE_COLUMNS_WITH_ESTIMATEID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_SPACE_SCHEDULE_COLUMNS_WITH_ESTIMATEID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_SPACE_SCHEDULE_COLUMNS_WITH_ESTIMATEID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getSpaceScheduleColumnsWithEstimateIdRequestDefinitions = (definitionsArguments: {| payload?: Object |}): {|
  ...TVDGARConfigWithoutRequestArgs, payload?: Object |} => ({
  fnName: 'getSpaceScheduleColumnsWithEstimateIdRequest',
  basePath: '/spaces-api',
  payload: definitionsArguments.payload,
})


export const DATA_GET_SPACE_SCHEDULE_VALUES_WITH_ESTIMATEID_START = 'DATA_GET_SPACE_SCHEDULE_VALUES_WITH_ESTIMATEID_START'
export const DATA_GET_SPACE_SCHEDULE_VALUES_WITH_ESTIMATEID_SUCCESSFUL = 'DATA_GET_SPACE_SCHEDULE_VALUES_WITH_ESTIMATEID_SUCCESSFUL'
export const DATA_GET_SPACE_SCHEDULE_VALUES_WITH_ESTIMATEID_FAIL = 'DATA_GET_SPACE_SCHEDULE_VALUES_WITH_ESTIMATEID_FAIL'

// Returns values calculated for space schedule
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/spaces.yaml#/Space%20Schedule/get_space_schedule__estimateId__values
export const getSpaceScheduleValuesWithEstimateIdRequest = (
  payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_SPACE_SCHEDULE_VALUES_WITH_ESTIMATEID_START',
      successful: 'DATA_GET_SPACE_SCHEDULE_VALUES_WITH_ESTIMATEID_SUCCESSFUL',
      fail: 'DATA_GET_SPACE_SCHEDULE_VALUES_WITH_ESTIMATEID_FAIL'
    },
    embeddedType: {
      values: {
        schemaPath: '#/definitions/ValueProperty',
        schemaKey: 'ValueProperty'
      }
    },
    fnName: 'getSpaceScheduleValuesWithEstimateIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/spaces-api`,
    method: 'GET',
    url: `/space-schedule/${options?.estimateId || store.app.calculation}/values`,
    params: {
      spacesEstimateType: options?.spacesEstimateType || store.app.spacesEstimateType,
      spacesResultView: options?.spacesResultView || store.app.spacesResultView,
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      values: {
        schemaPath: '#/definitions/ValueProperty',
        schemaKey: 'ValueProperty'
      }
    }, 'getSpaceScheduleValuesWithEstimateIdRequest', '/spaces-api'),
    actions: {
      start: {
        type: DATA_GET_SPACE_SCHEDULE_VALUES_WITH_ESTIMATEID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_SPACE_SCHEDULE_VALUES_WITH_ESTIMATEID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_SPACE_SCHEDULE_VALUES_WITH_ESTIMATEID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getSpaceScheduleValuesWithEstimateIdRequestDefinitions = (definitionsArguments: {| payload?: Object |}): {|
  ...TVDGARConfigWithoutRequestArgs, payload?: Object |} => ({
  fnName: 'getSpaceScheduleValuesWithEstimateIdRequest',
  basePath: '/spaces-api',
  payload: definitionsArguments.payload,
})


export const DATA_GET_SPACE_SCHEDULE_KEY_FIGURES_WITH_ESTIMATEID_START = 'DATA_GET_SPACE_SCHEDULE_KEY_FIGURES_WITH_ESTIMATEID_START'
export const DATA_GET_SPACE_SCHEDULE_KEY_FIGURES_WITH_ESTIMATEID_SUCCESSFUL = 'DATA_GET_SPACE_SCHEDULE_KEY_FIGURES_WITH_ESTIMATEID_SUCCESSFUL'
export const DATA_GET_SPACE_SCHEDULE_KEY_FIGURES_WITH_ESTIMATEID_FAIL = 'DATA_GET_SPACE_SCHEDULE_KEY_FIGURES_WITH_ESTIMATEID_FAIL'

// Returns key figures for space schedule.
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/spaces.yaml#/SpaceScheduleApi/get_space_schedule__estimateId__key_figures
export const getSpaceScheduleKeyFiguresWithEstimateIdRequest = (
  payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_SPACE_SCHEDULE_KEY_FIGURES_WITH_ESTIMATEID_START',
      successful: 'DATA_GET_SPACE_SCHEDULE_KEY_FIGURES_WITH_ESTIMATEID_SUCCESSFUL',
      fail: 'DATA_GET_SPACE_SCHEDULE_KEY_FIGURES_WITH_ESTIMATEID_FAIL'
    },
    embeddedType: null,
    fnName: 'getSpaceScheduleKeyFiguresWithEstimateIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/spaces-api`,
    method: 'GET',
    url: `/space-schedule/${options?.estimateId || store.app.calculation}/key-figures`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'getSpaceScheduleKeyFiguresWithEstimateIdRequest', '/spaces-api'),
    actions: {
      start: {
        type: DATA_GET_SPACE_SCHEDULE_KEY_FIGURES_WITH_ESTIMATEID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_SPACE_SCHEDULE_KEY_FIGURES_WITH_ESTIMATEID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_SPACE_SCHEDULE_KEY_FIGURES_WITH_ESTIMATEID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getSpaceScheduleKeyFiguresWithEstimateIdRequestDefinitions = (definitionsArguments: {| payload?: Object |}): {|
  ...TVDGARConfigWithoutRequestArgs, payload?: Object |} => ({
  fnName: 'getSpaceScheduleKeyFiguresWithEstimateIdRequest',
  basePath: '/spaces-api',
  payload: definitionsArguments.payload,
})


export const DATA_PATCH_SPACE_SCHEDULE_BATCHMOVE_WITH_SPACESCHEDULEITEMTYPE_START = 'DATA_PATCH_SPACE_SCHEDULE_BATCHMOVE_WITH_SPACESCHEDULEITEMTYPE_START'
export const DATA_PATCH_SPACE_SCHEDULE_BATCHMOVE_WITH_SPACESCHEDULEITEMTYPE_SUCCESSFUL = 'DATA_PATCH_SPACE_SCHEDULE_BATCHMOVE_WITH_SPACESCHEDULEITEMTYPE_SUCCESSFUL'
export const DATA_PATCH_SPACE_SCHEDULE_BATCHMOVE_WITH_SPACESCHEDULEITEMTYPE_FAIL = 'DATA_PATCH_SPACE_SCHEDULE_BATCHMOVE_WITH_SPACESCHEDULEITEMTYPE_FAIL'

// Batch move space schedule items.
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/spaces.yaml#/SpaceScheduleApi/patch__estimateId__space_schedule__spaceScheduleItemType_:batchMove
export const patchSpaceSchedulebatchMoveWithSpaceScheduleItemTypeRequest = (
  requestArguments: patchSpaceSchedulebatchMoveWithSpaceScheduleItemTypeRequestSpacesArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_PATCH_SPACE_SCHEDULE_BATCHMOVE_WITH_SPACESCHEDULEITEMTYPE_START',
      successful: 'DATA_PATCH_SPACE_SCHEDULE_BATCHMOVE_WITH_SPACESCHEDULEITEMTYPE_SUCCESSFUL',
      fail: 'DATA_PATCH_SPACE_SCHEDULE_BATCHMOVE_WITH_SPACESCHEDULEITEMTYPE_FAIL'
    },
    embeddedType: null,
    fnName: 'patchSpaceSchedulebatchMoveWithSpaceScheduleItemTypeRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/spaces-api`,
    method: 'PATCH',
    url: `/${options?.estimateId || store.app.calculation}/space-schedule/${requestArguments.path['spaceScheduleItemType']}:batchMove`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: requestArguments.body
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'patchSpaceSchedulebatchMoveWithSpaceScheduleItemTypeRequest', '/spaces-api'),
    actions: {
      start: {
        type: DATA_PATCH_SPACE_SCHEDULE_BATCHMOVE_WITH_SPACESCHEDULEITEMTYPE_START, payload, config, meta
      },
      successful: {
        type: DATA_PATCH_SPACE_SCHEDULE_BATCHMOVE_WITH_SPACESCHEDULEITEMTYPE_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_PATCH_SPACE_SCHEDULE_BATCHMOVE_WITH_SPACESCHEDULEITEMTYPE_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_POST_SPACE_SCHEDULE_FUNCTIONAL_SECTORS_WITH_ESTIMATEID_START = 'DATA_POST_SPACE_SCHEDULE_FUNCTIONAL_SECTORS_WITH_ESTIMATEID_START'
export const DATA_POST_SPACE_SCHEDULE_FUNCTIONAL_SECTORS_WITH_ESTIMATEID_SUCCESSFUL = 'DATA_POST_SPACE_SCHEDULE_FUNCTIONAL_SECTORS_WITH_ESTIMATEID_SUCCESSFUL'
export const DATA_POST_SPACE_SCHEDULE_FUNCTIONAL_SECTORS_WITH_ESTIMATEID_FAIL = 'DATA_POST_SPACE_SCHEDULE_FUNCTIONAL_SECTORS_WITH_ESTIMATEID_FAIL'

// Adds a new functional sector to space schedule
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/spaces.yaml#/Space%20Schedule/post_space_schedule__estimateId__functional_sectors
export const postSpaceScheduleFunctionalSectorsWithEstimateIdRequest = (
  requestArguments: postSpaceScheduleFunctionalSectorsWithEstimateIdRequestSpacesArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_POST_SPACE_SCHEDULE_FUNCTIONAL_SECTORS_WITH_ESTIMATEID_START',
      successful: 'DATA_POST_SPACE_SCHEDULE_FUNCTIONAL_SECTORS_WITH_ESTIMATEID_SUCCESSFUL',
      fail: 'DATA_POST_SPACE_SCHEDULE_FUNCTIONAL_SECTORS_WITH_ESTIMATEID_FAIL'
    },
    embeddedType: null,
    fnName: 'postSpaceScheduleFunctionalSectorsWithEstimateIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/spaces-api`,
    method: 'POST',
    url: `/space-schedule/${options?.estimateId || store.app.calculation}/functional-sectors`,
    params: {
      copyId: requestArguments.query?.['copyId'],
      registryId: requestArguments.query?.['registryId'],
      updateCirculation: requestArguments.query?.['updateCirculation'],
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: requestArguments.body
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'postSpaceScheduleFunctionalSectorsWithEstimateIdRequest', '/spaces-api'),
    actions: {
      start: {
        type: DATA_POST_SPACE_SCHEDULE_FUNCTIONAL_SECTORS_WITH_ESTIMATEID_START, payload, config, meta
      },
      successful: {
        type: DATA_POST_SPACE_SCHEDULE_FUNCTIONAL_SECTORS_WITH_ESTIMATEID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_POST_SPACE_SCHEDULE_FUNCTIONAL_SECTORS_WITH_ESTIMATEID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_DELETE_SPACE_SCHEDULE_FUNCTIONAL_SECTORS_WITH_ESTIMATEID_START = 'DATA_DELETE_SPACE_SCHEDULE_FUNCTIONAL_SECTORS_WITH_ESTIMATEID_START'
export const DATA_DELETE_SPACE_SCHEDULE_FUNCTIONAL_SECTORS_WITH_ESTIMATEID_SUCCESSFUL = 'DATA_DELETE_SPACE_SCHEDULE_FUNCTIONAL_SECTORS_WITH_ESTIMATEID_SUCCESSFUL'
export const DATA_DELETE_SPACE_SCHEDULE_FUNCTIONAL_SECTORS_WITH_ESTIMATEID_FAIL = 'DATA_DELETE_SPACE_SCHEDULE_FUNCTIONAL_SECTORS_WITH_ESTIMATEID_FAIL'

// Delete multiple functional sectors in batch operation.
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/spaces.yaml#/Functional%20Sectors/delete_space_schedule__estimateId__functional_sectors
export const deleteSpaceScheduleFunctionalSectorsWithEstimateIdRequest = (
  requestArguments: deleteSpaceScheduleFunctionalSectorsWithEstimateIdRequestSpacesArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_DELETE_SPACE_SCHEDULE_FUNCTIONAL_SECTORS_WITH_ESTIMATEID_START',
      successful: 'DATA_DELETE_SPACE_SCHEDULE_FUNCTIONAL_SECTORS_WITH_ESTIMATEID_SUCCESSFUL',
      fail: 'DATA_DELETE_SPACE_SCHEDULE_FUNCTIONAL_SECTORS_WITH_ESTIMATEID_FAIL'
    },
    embeddedType: null,
    fnName: 'deleteSpaceScheduleFunctionalSectorsWithEstimateIdRequest'
  }
  const config = {
    headers: { 'Content-Type': 'application/json' },
    baseURL: `${API_BASE_URL}/spaces-api`,
    method: 'DELETE',
    url: `/space-schedule/${options?.estimateId || store.app.calculation}/functional-sectors`,
    params: {
      ids: requestArguments.query?.['ids'],
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'deleteSpaceScheduleFunctionalSectorsWithEstimateIdRequest', '/spaces-api'),
    actions: {
      start: {
        type: DATA_DELETE_SPACE_SCHEDULE_FUNCTIONAL_SECTORS_WITH_ESTIMATEID_START, payload, config, meta
      },
      successful: {
        type: DATA_DELETE_SPACE_SCHEDULE_FUNCTIONAL_SECTORS_WITH_ESTIMATEID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_DELETE_SPACE_SCHEDULE_FUNCTIONAL_SECTORS_WITH_ESTIMATEID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_GET_SPACE_SCHEDULE_FUNCTIONAL_SECTORS_WITH_ID_START = 'DATA_GET_SPACE_SCHEDULE_FUNCTIONAL_SECTORS_WITH_ID_START'
export const DATA_GET_SPACE_SCHEDULE_FUNCTIONAL_SECTORS_WITH_ID_SUCCESSFUL = 'DATA_GET_SPACE_SCHEDULE_FUNCTIONAL_SECTORS_WITH_ID_SUCCESSFUL'
export const DATA_GET_SPACE_SCHEDULE_FUNCTIONAL_SECTORS_WITH_ID_FAIL = 'DATA_GET_SPACE_SCHEDULE_FUNCTIONAL_SECTORS_WITH_ID_FAIL'

// Returns a functional sector for space schedule
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/spaces.yaml#/Functional%20Sectors/get_space_schedule__estimateId__functional_sectors__id_
export const getSpaceScheduleFunctionalSectorsWithIdRequest = (
  requestArguments: getSpaceScheduleFunctionalSectorsWithIdRequestSpacesArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_SPACE_SCHEDULE_FUNCTIONAL_SECTORS_WITH_ID_START',
      successful: 'DATA_GET_SPACE_SCHEDULE_FUNCTIONAL_SECTORS_WITH_ID_SUCCESSFUL',
      fail: 'DATA_GET_SPACE_SCHEDULE_FUNCTIONAL_SECTORS_WITH_ID_FAIL'
    },
    embeddedType: {
      nonEmbedded: {
        schemaPath: '#/definitions/ScheduleItem',
        schemaKey: 'ScheduleItem'
      }
    },
    fnName: 'getSpaceScheduleFunctionalSectorsWithIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/spaces-api`,
    method: 'GET',
    url: `/space-schedule/${options?.estimateId || store.app.calculation}/functional-sectors/${requestArguments.path['id']}`,
    params: {
      spacesEstimateType: options?.spacesEstimateType || store.app.spacesEstimateType,
      spacesResultView: options?.spacesResultView || store.app.spacesResultView,
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      nonEmbedded: {
        schemaPath: '#/definitions/ScheduleItem',
        schemaKey: 'ScheduleItem'
      }
    }, 'getSpaceScheduleFunctionalSectorsWithIdRequest', '/spaces-api'),
    actions: {
      start: {
        type: DATA_GET_SPACE_SCHEDULE_FUNCTIONAL_SECTORS_WITH_ID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_SPACE_SCHEDULE_FUNCTIONAL_SECTORS_WITH_ID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_SPACE_SCHEDULE_FUNCTIONAL_SECTORS_WITH_ID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getSpaceScheduleFunctionalSectorsWithIdRequestDefinitions = (definitionsArguments: {| requestArgs: getSpaceScheduleFunctionalSectorsWithIdRequestSpacesArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getSpaceScheduleFunctionalSectorsWithIdRequestSpacesArguments, payload?: Object |} => ({
  fnName: 'getSpaceScheduleFunctionalSectorsWithIdRequest',
  basePath: '/spaces-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_DELETE_SPACE_SCHEDULE_FUNCTIONAL_SECTORS_WITH_ID_START = 'DATA_DELETE_SPACE_SCHEDULE_FUNCTIONAL_SECTORS_WITH_ID_START'
export const DATA_DELETE_SPACE_SCHEDULE_FUNCTIONAL_SECTORS_WITH_ID_SUCCESSFUL = 'DATA_DELETE_SPACE_SCHEDULE_FUNCTIONAL_SECTORS_WITH_ID_SUCCESSFUL'
export const DATA_DELETE_SPACE_SCHEDULE_FUNCTIONAL_SECTORS_WITH_ID_FAIL = 'DATA_DELETE_SPACE_SCHEDULE_FUNCTIONAL_SECTORS_WITH_ID_FAIL'

// Delete a functional sector. Will queue calculation if needed
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/spaces.yaml#/Functional%20Sectors/delete_space_schedule__estimateId__functional_sectors__id_
export const deleteSpaceScheduleFunctionalSectorsWithIdRequest = (
  requestArguments: deleteSpaceScheduleFunctionalSectorsWithIdRequestSpacesArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_DELETE_SPACE_SCHEDULE_FUNCTIONAL_SECTORS_WITH_ID_START',
      successful: 'DATA_DELETE_SPACE_SCHEDULE_FUNCTIONAL_SECTORS_WITH_ID_SUCCESSFUL',
      fail: 'DATA_DELETE_SPACE_SCHEDULE_FUNCTIONAL_SECTORS_WITH_ID_FAIL'
    },
    embeddedType: null,
    fnName: 'deleteSpaceScheduleFunctionalSectorsWithIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/spaces-api`,
    method: 'DELETE',
    url: `/space-schedule/${options?.estimateId || store.app.calculation}/functional-sectors/${requestArguments.path['id']}`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'deleteSpaceScheduleFunctionalSectorsWithIdRequest', '/spaces-api'),
    actions: {
      start: {
        type: DATA_DELETE_SPACE_SCHEDULE_FUNCTIONAL_SECTORS_WITH_ID_START, payload, config, meta
      },
      successful: {
        type: DATA_DELETE_SPACE_SCHEDULE_FUNCTIONAL_SECTORS_WITH_ID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_DELETE_SPACE_SCHEDULE_FUNCTIONAL_SECTORS_WITH_ID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_PATCH_SPACE_SCHEDULE_FUNCTIONAL_SECTORS_WITH_ID_START = 'DATA_PATCH_SPACE_SCHEDULE_FUNCTIONAL_SECTORS_WITH_ID_START'
export const DATA_PATCH_SPACE_SCHEDULE_FUNCTIONAL_SECTORS_WITH_ID_SUCCESSFUL = 'DATA_PATCH_SPACE_SCHEDULE_FUNCTIONAL_SECTORS_WITH_ID_SUCCESSFUL'
export const DATA_PATCH_SPACE_SCHEDULE_FUNCTIONAL_SECTORS_WITH_ID_FAIL = 'DATA_PATCH_SPACE_SCHEDULE_FUNCTIONAL_SECTORS_WITH_ID_FAIL'

// Update column data of a functional sector
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/spaces.yaml#/Space%20Schedule/patch_space_schedule__estimateId__functional_sectors__id_
export const patchSpaceScheduleFunctionalSectorsWithIdRequest = (
  requestArguments: patchSpaceScheduleFunctionalSectorsWithIdRequestSpacesArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_PATCH_SPACE_SCHEDULE_FUNCTIONAL_SECTORS_WITH_ID_START',
      successful: 'DATA_PATCH_SPACE_SCHEDULE_FUNCTIONAL_SECTORS_WITH_ID_SUCCESSFUL',
      fail: 'DATA_PATCH_SPACE_SCHEDULE_FUNCTIONAL_SECTORS_WITH_ID_FAIL'
    },
    embeddedType: null,
    fnName: 'patchSpaceScheduleFunctionalSectorsWithIdRequest'
  }
  const config = {
    headers: { 'Content-Type': 'application/json' },
    baseURL: `${API_BASE_URL}/spaces-api`,
    method: 'PATCH',
    url: `/space-schedule/${options?.estimateId || store.app.calculation}/functional-sectors/${requestArguments.path['id']}`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: requestArguments.body
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'patchSpaceScheduleFunctionalSectorsWithIdRequest', '/spaces-api'),
    actions: {
      start: {
        type: DATA_PATCH_SPACE_SCHEDULE_FUNCTIONAL_SECTORS_WITH_ID_START, payload, config, meta
      },
      successful: {
        type: DATA_PATCH_SPACE_SCHEDULE_FUNCTIONAL_SECTORS_WITH_ID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_PATCH_SPACE_SCHEDULE_FUNCTIONAL_SECTORS_WITH_ID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_GET_SPACE_SCHEDULE_FUNCTIONAL_SECTORS_FUNCTIONS_WITH_ID_START = 'DATA_GET_SPACE_SCHEDULE_FUNCTIONAL_SECTORS_FUNCTIONS_WITH_ID_START'
export const DATA_GET_SPACE_SCHEDULE_FUNCTIONAL_SECTORS_FUNCTIONS_WITH_ID_SUCCESSFUL = 'DATA_GET_SPACE_SCHEDULE_FUNCTIONAL_SECTORS_FUNCTIONS_WITH_ID_SUCCESSFUL'
export const DATA_GET_SPACE_SCHEDULE_FUNCTIONAL_SECTORS_FUNCTIONS_WITH_ID_FAIL = 'DATA_GET_SPACE_SCHEDULE_FUNCTIONAL_SECTORS_FUNCTIONS_WITH_ID_FAIL'

// Returns functions of a functional sector
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/spaces.yaml#/Space%20Schedule/get_space_schedule__estimateId__functional_sectors__id__functions
export const getSpaceScheduleFunctionalSectorsFunctionsWithIdRequest = (
  requestArguments: getSpaceScheduleFunctionalSectorsFunctionsWithIdRequestSpacesArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_SPACE_SCHEDULE_FUNCTIONAL_SECTORS_FUNCTIONS_WITH_ID_START',
      successful: 'DATA_GET_SPACE_SCHEDULE_FUNCTIONAL_SECTORS_FUNCTIONS_WITH_ID_SUCCESSFUL',
      fail: 'DATA_GET_SPACE_SCHEDULE_FUNCTIONAL_SECTORS_FUNCTIONS_WITH_ID_FAIL'
    },
    embeddedType: {
      items: {
        schemaPath: '#/definitions/ScheduleItem',
        schemaKey: 'ScheduleItem'
      }
    },
    fnName: 'getSpaceScheduleFunctionalSectorsFunctionsWithIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/spaces-api`,
    method: 'GET',
    url: `/space-schedule/${options?.estimateId || store.app.calculation}/functional-sectors/${requestArguments.path['id']}/functions`,
    params: {
      spacesEstimateType: options?.spacesEstimateType || store.app.spacesEstimateType,
      spacesResultView: options?.spacesResultView || store.app.spacesResultView,
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      items: {
        schemaPath: '#/definitions/ScheduleItem',
        schemaKey: 'ScheduleItem'
      }
    }, 'getSpaceScheduleFunctionalSectorsFunctionsWithIdRequest', '/spaces-api'),
    actions: {
      start: {
        type: DATA_GET_SPACE_SCHEDULE_FUNCTIONAL_SECTORS_FUNCTIONS_WITH_ID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_SPACE_SCHEDULE_FUNCTIONAL_SECTORS_FUNCTIONS_WITH_ID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_SPACE_SCHEDULE_FUNCTIONAL_SECTORS_FUNCTIONS_WITH_ID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getSpaceScheduleFunctionalSectorsFunctionsWithIdRequestDefinitions = (definitionsArguments: {| requestArgs: getSpaceScheduleFunctionalSectorsFunctionsWithIdRequestSpacesArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getSpaceScheduleFunctionalSectorsFunctionsWithIdRequestSpacesArguments, payload?: Object |} => ({
  fnName: 'getSpaceScheduleFunctionalSectorsFunctionsWithIdRequest',
  basePath: '/spaces-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_DELETE_SPACE_SCHEDULE_FUNCTIONS_WITH_ESTIMATEID_START = 'DATA_DELETE_SPACE_SCHEDULE_FUNCTIONS_WITH_ESTIMATEID_START'
export const DATA_DELETE_SPACE_SCHEDULE_FUNCTIONS_WITH_ESTIMATEID_SUCCESSFUL = 'DATA_DELETE_SPACE_SCHEDULE_FUNCTIONS_WITH_ESTIMATEID_SUCCESSFUL'
export const DATA_DELETE_SPACE_SCHEDULE_FUNCTIONS_WITH_ESTIMATEID_FAIL = 'DATA_DELETE_SPACE_SCHEDULE_FUNCTIONS_WITH_ESTIMATEID_FAIL'

// Delete multiple functions in batch operation.
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/spaces.yaml#/Functions/delete_space_schedule__estimateId__functions
export const deleteSpaceScheduleFunctionsWithEstimateIdRequest = (
  requestArguments: deleteSpaceScheduleFunctionsWithEstimateIdRequestSpacesArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_DELETE_SPACE_SCHEDULE_FUNCTIONS_WITH_ESTIMATEID_START',
      successful: 'DATA_DELETE_SPACE_SCHEDULE_FUNCTIONS_WITH_ESTIMATEID_SUCCESSFUL',
      fail: 'DATA_DELETE_SPACE_SCHEDULE_FUNCTIONS_WITH_ESTIMATEID_FAIL'
    },
    embeddedType: null,
    fnName: 'deleteSpaceScheduleFunctionsWithEstimateIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/spaces-api`,
    method: 'DELETE',
    url: `/space-schedule/${options?.estimateId || store.app.calculation}/functions`,
    params: {
      ids: requestArguments.query?.['ids'],
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'deleteSpaceScheduleFunctionsWithEstimateIdRequest', '/spaces-api'),
    actions: {
      start: {
        type: DATA_DELETE_SPACE_SCHEDULE_FUNCTIONS_WITH_ESTIMATEID_START, payload, config, meta
      },
      successful: {
        type: DATA_DELETE_SPACE_SCHEDULE_FUNCTIONS_WITH_ESTIMATEID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_DELETE_SPACE_SCHEDULE_FUNCTIONS_WITH_ESTIMATEID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_GET_SPACE_SCHEDULE_FUNCTIONS_WITH_ID_START = 'DATA_GET_SPACE_SCHEDULE_FUNCTIONS_WITH_ID_START'
export const DATA_GET_SPACE_SCHEDULE_FUNCTIONS_WITH_ID_SUCCESSFUL = 'DATA_GET_SPACE_SCHEDULE_FUNCTIONS_WITH_ID_SUCCESSFUL'
export const DATA_GET_SPACE_SCHEDULE_FUNCTIONS_WITH_ID_FAIL = 'DATA_GET_SPACE_SCHEDULE_FUNCTIONS_WITH_ID_FAIL'

// Returns a function for space schedule
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/spaces.yaml#/Functions/get_space_schedule__estimateId__functions__id_
export const getSpaceScheduleFunctionsWithIdRequest = (
  requestArguments: getSpaceScheduleFunctionsWithIdRequestSpacesArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_SPACE_SCHEDULE_FUNCTIONS_WITH_ID_START',
      successful: 'DATA_GET_SPACE_SCHEDULE_FUNCTIONS_WITH_ID_SUCCESSFUL',
      fail: 'DATA_GET_SPACE_SCHEDULE_FUNCTIONS_WITH_ID_FAIL'
    },
    embeddedType: {
      nonEmbedded: {
        schemaPath: '#/definitions/ScheduleItem',
        schemaKey: 'ScheduleItem'
      }
    },
    fnName: 'getSpaceScheduleFunctionsWithIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/spaces-api`,
    method: 'GET',
    url: `/space-schedule/${options?.estimateId || store.app.calculation}/functions/${requestArguments.path['id']}`,
    params: {
      spacesEstimateType: options?.spacesEstimateType || store.app.spacesEstimateType,
      spacesResultView: options?.spacesResultView || store.app.spacesResultView,
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      nonEmbedded: {
        schemaPath: '#/definitions/ScheduleItem',
        schemaKey: 'ScheduleItem'
      }
    }, 'getSpaceScheduleFunctionsWithIdRequest', '/spaces-api'),
    actions: {
      start: {
        type: DATA_GET_SPACE_SCHEDULE_FUNCTIONS_WITH_ID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_SPACE_SCHEDULE_FUNCTIONS_WITH_ID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_SPACE_SCHEDULE_FUNCTIONS_WITH_ID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getSpaceScheduleFunctionsWithIdRequestDefinitions = (definitionsArguments: {| requestArgs: getSpaceScheduleFunctionsWithIdRequestSpacesArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getSpaceScheduleFunctionsWithIdRequestSpacesArguments, payload?: Object |} => ({
  fnName: 'getSpaceScheduleFunctionsWithIdRequest',
  basePath: '/spaces-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_DELETE_SPACE_SCHEDULE_FUNCTIONS_WITH_ID_START = 'DATA_DELETE_SPACE_SCHEDULE_FUNCTIONS_WITH_ID_START'
export const DATA_DELETE_SPACE_SCHEDULE_FUNCTIONS_WITH_ID_SUCCESSFUL = 'DATA_DELETE_SPACE_SCHEDULE_FUNCTIONS_WITH_ID_SUCCESSFUL'
export const DATA_DELETE_SPACE_SCHEDULE_FUNCTIONS_WITH_ID_FAIL = 'DATA_DELETE_SPACE_SCHEDULE_FUNCTIONS_WITH_ID_FAIL'

// Delete a function. Will queue calculation if needed
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/spaces.yaml#/Functions/delete_space_schedule__estimateId__functions__id_
export const deleteSpaceScheduleFunctionsWithIdRequest = (
  requestArguments: deleteSpaceScheduleFunctionsWithIdRequestSpacesArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_DELETE_SPACE_SCHEDULE_FUNCTIONS_WITH_ID_START',
      successful: 'DATA_DELETE_SPACE_SCHEDULE_FUNCTIONS_WITH_ID_SUCCESSFUL',
      fail: 'DATA_DELETE_SPACE_SCHEDULE_FUNCTIONS_WITH_ID_FAIL'
    },
    embeddedType: null,
    fnName: 'deleteSpaceScheduleFunctionsWithIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/spaces-api`,
    method: 'DELETE',
    url: `/space-schedule/${options?.estimateId || store.app.calculation}/functions/${requestArguments.path['id']}`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'deleteSpaceScheduleFunctionsWithIdRequest', '/spaces-api'),
    actions: {
      start: {
        type: DATA_DELETE_SPACE_SCHEDULE_FUNCTIONS_WITH_ID_START, payload, config, meta
      },
      successful: {
        type: DATA_DELETE_SPACE_SCHEDULE_FUNCTIONS_WITH_ID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_DELETE_SPACE_SCHEDULE_FUNCTIONS_WITH_ID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_PATCH_SPACE_SCHEDULE_FUNCTIONS_WITH_ID_START = 'DATA_PATCH_SPACE_SCHEDULE_FUNCTIONS_WITH_ID_START'
export const DATA_PATCH_SPACE_SCHEDULE_FUNCTIONS_WITH_ID_SUCCESSFUL = 'DATA_PATCH_SPACE_SCHEDULE_FUNCTIONS_WITH_ID_SUCCESSFUL'
export const DATA_PATCH_SPACE_SCHEDULE_FUNCTIONS_WITH_ID_FAIL = 'DATA_PATCH_SPACE_SCHEDULE_FUNCTIONS_WITH_ID_FAIL'

// Update column data of schedule item
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/spaces.yaml#/Functions/patch_space_schedule__estimateId__functions__id_
export const patchSpaceScheduleFunctionsWithIdRequest = (
  requestArguments: patchSpaceScheduleFunctionsWithIdRequestSpacesArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_PATCH_SPACE_SCHEDULE_FUNCTIONS_WITH_ID_START',
      successful: 'DATA_PATCH_SPACE_SCHEDULE_FUNCTIONS_WITH_ID_SUCCESSFUL',
      fail: 'DATA_PATCH_SPACE_SCHEDULE_FUNCTIONS_WITH_ID_FAIL'
    },
    embeddedType: null,
    fnName: 'patchSpaceScheduleFunctionsWithIdRequest'
  }
  const config = {
    headers: { 'Content-Type': 'application/json' },
    baseURL: `${API_BASE_URL}/spaces-api`,
    method: 'PATCH',
    url: `/space-schedule/${options?.estimateId || store.app.calculation}/functions/${requestArguments.path['id']}`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: requestArguments.body
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'patchSpaceScheduleFunctionsWithIdRequest', '/spaces-api'),
    actions: {
      start: {
        type: DATA_PATCH_SPACE_SCHEDULE_FUNCTIONS_WITH_ID_START, payload, config, meta
      },
      successful: {
        type: DATA_PATCH_SPACE_SCHEDULE_FUNCTIONS_WITH_ID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_PATCH_SPACE_SCHEDULE_FUNCTIONS_WITH_ID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_POST_SPACE_SCHEDULE_FUNCTIONS_WITH_ID_START = 'DATA_POST_SPACE_SCHEDULE_FUNCTIONS_WITH_ID_START'
export const DATA_POST_SPACE_SCHEDULE_FUNCTIONS_WITH_ID_SUCCESSFUL = 'DATA_POST_SPACE_SCHEDULE_FUNCTIONS_WITH_ID_SUCCESSFUL'
export const DATA_POST_SPACE_SCHEDULE_FUNCTIONS_WITH_ID_FAIL = 'DATA_POST_SPACE_SCHEDULE_FUNCTIONS_WITH_ID_FAIL'

// Adds a new function under a functional sector
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/spaces.yaml#/Functions/post_space_schedule__estimateId__functions__id_
export const postSpaceScheduleFunctionsWithIdRequest = (
  requestArguments: postSpaceScheduleFunctionsWithIdRequestSpacesArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_POST_SPACE_SCHEDULE_FUNCTIONS_WITH_ID_START',
      successful: 'DATA_POST_SPACE_SCHEDULE_FUNCTIONS_WITH_ID_SUCCESSFUL',
      fail: 'DATA_POST_SPACE_SCHEDULE_FUNCTIONS_WITH_ID_FAIL'
    },
    embeddedType: null,
    fnName: 'postSpaceScheduleFunctionsWithIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/spaces-api`,
    method: 'POST',
    url: `/space-schedule/${options?.estimateId || store.app.calculation}/functions/${requestArguments.path['id']}`,
    params: {
      copyId: requestArguments.query?.['copyId'],
      registryId: requestArguments.query?.['registryId'],
      updateCirculation: requestArguments.query?.['updateCirculation'],
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: requestArguments.body
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'postSpaceScheduleFunctionsWithIdRequest', '/spaces-api'),
    actions: {
      start: {
        type: DATA_POST_SPACE_SCHEDULE_FUNCTIONS_WITH_ID_START, payload, config, meta
      },
      successful: {
        type: DATA_POST_SPACE_SCHEDULE_FUNCTIONS_WITH_ID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_POST_SPACE_SCHEDULE_FUNCTIONS_WITH_ID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_GET_SPACE_SCHEDULE_FUNCTIONS_SPACE_GROUPS_WITH_ID_START = 'DATA_GET_SPACE_SCHEDULE_FUNCTIONS_SPACE_GROUPS_WITH_ID_START'
export const DATA_GET_SPACE_SCHEDULE_FUNCTIONS_SPACE_GROUPS_WITH_ID_SUCCESSFUL = 'DATA_GET_SPACE_SCHEDULE_FUNCTIONS_SPACE_GROUPS_WITH_ID_SUCCESSFUL'
export const DATA_GET_SPACE_SCHEDULE_FUNCTIONS_SPACE_GROUPS_WITH_ID_FAIL = 'DATA_GET_SPACE_SCHEDULE_FUNCTIONS_SPACE_GROUPS_WITH_ID_FAIL'

// Returns space groups for a function
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/spaces.yaml#/Functions/get_space_schedule__estimateId__functions__id__space_groups
export const getSpaceScheduleFunctionsSpaceGroupsWithIdRequest = (
  requestArguments: getSpaceScheduleFunctionsSpaceGroupsWithIdRequestSpacesArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_SPACE_SCHEDULE_FUNCTIONS_SPACE_GROUPS_WITH_ID_START',
      successful: 'DATA_GET_SPACE_SCHEDULE_FUNCTIONS_SPACE_GROUPS_WITH_ID_SUCCESSFUL',
      fail: 'DATA_GET_SPACE_SCHEDULE_FUNCTIONS_SPACE_GROUPS_WITH_ID_FAIL'
    },
    embeddedType: {
      items: {
        schemaPath: '#/definitions/ScheduleItem',
        schemaKey: 'ScheduleItem'
      }
    },
    fnName: 'getSpaceScheduleFunctionsSpaceGroupsWithIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/spaces-api`,
    method: 'GET',
    url: `/space-schedule/${options?.estimateId || store.app.calculation}/functions/${requestArguments.path['id']}/space-groups`,
    params: {
      spacesEstimateType: options?.spacesEstimateType || store.app.spacesEstimateType,
      spacesResultView: options?.spacesResultView || store.app.spacesResultView,
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      items: {
        schemaPath: '#/definitions/ScheduleItem',
        schemaKey: 'ScheduleItem'
      }
    }, 'getSpaceScheduleFunctionsSpaceGroupsWithIdRequest', '/spaces-api'),
    actions: {
      start: {
        type: DATA_GET_SPACE_SCHEDULE_FUNCTIONS_SPACE_GROUPS_WITH_ID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_SPACE_SCHEDULE_FUNCTIONS_SPACE_GROUPS_WITH_ID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_SPACE_SCHEDULE_FUNCTIONS_SPACE_GROUPS_WITH_ID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getSpaceScheduleFunctionsSpaceGroupsWithIdRequestDefinitions = (definitionsArguments: {| requestArgs: getSpaceScheduleFunctionsSpaceGroupsWithIdRequestSpacesArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getSpaceScheduleFunctionsSpaceGroupsWithIdRequestSpacesArguments, payload?: Object |} => ({
  fnName: 'getSpaceScheduleFunctionsSpaceGroupsWithIdRequest',
  basePath: '/spaces-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_GET_SPACE_SCHEDULE_FUNCTIONS_CAN_MOVE_TO_WITH_ID_START = 'DATA_GET_SPACE_SCHEDULE_FUNCTIONS_CAN_MOVE_TO_WITH_ID_START'
export const DATA_GET_SPACE_SCHEDULE_FUNCTIONS_CAN_MOVE_TO_WITH_ID_SUCCESSFUL = 'DATA_GET_SPACE_SCHEDULE_FUNCTIONS_CAN_MOVE_TO_WITH_ID_SUCCESSFUL'
export const DATA_GET_SPACE_SCHEDULE_FUNCTIONS_CAN_MOVE_TO_WITH_ID_FAIL = 'DATA_GET_SPACE_SCHEDULE_FUNCTIONS_CAN_MOVE_TO_WITH_ID_FAIL'

// Get id and description for functional sectors to which the function can be moved to
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/spaces.yaml#/Functions/get_space_schedule__estimateId__functions__id__can_move_to
export const getSpaceScheduleFunctionsCanMoveToWithIdRequest = (
  requestArguments: getSpaceScheduleFunctionsCanMoveToWithIdRequestSpacesArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_SPACE_SCHEDULE_FUNCTIONS_CAN_MOVE_TO_WITH_ID_START',
      successful: 'DATA_GET_SPACE_SCHEDULE_FUNCTIONS_CAN_MOVE_TO_WITH_ID_SUCCESSFUL',
      fail: 'DATA_GET_SPACE_SCHEDULE_FUNCTIONS_CAN_MOVE_TO_WITH_ID_FAIL'
    },
    embeddedType: {
      items: {
        schemaPath: '#/definitions/Enumeral',
        schemaKey: 'Enumeral'
      }
    },
    fnName: 'getSpaceScheduleFunctionsCanMoveToWithIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/spaces-api`,
    method: 'GET',
    url: `/space-schedule/${options?.estimateId || store.app.calculation}/functions/${requestArguments.path['id']}/can-move-to`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      items: {
        schemaPath: '#/definitions/Enumeral',
        schemaKey: 'Enumeral'
      }
    }, 'getSpaceScheduleFunctionsCanMoveToWithIdRequest', '/spaces-api'),
    actions: {
      start: {
        type: DATA_GET_SPACE_SCHEDULE_FUNCTIONS_CAN_MOVE_TO_WITH_ID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_SPACE_SCHEDULE_FUNCTIONS_CAN_MOVE_TO_WITH_ID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_SPACE_SCHEDULE_FUNCTIONS_CAN_MOVE_TO_WITH_ID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getSpaceScheduleFunctionsCanMoveToWithIdRequestDefinitions = (definitionsArguments: {| requestArgs: getSpaceScheduleFunctionsCanMoveToWithIdRequestSpacesArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getSpaceScheduleFunctionsCanMoveToWithIdRequestSpacesArguments, payload?: Object |} => ({
  fnName: 'getSpaceScheduleFunctionsCanMoveToWithIdRequest',
  basePath: '/spaces-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_DELETE_SPACE_SCHEDULE_SPACE_GROUPS_WITH_ESTIMATEID_START = 'DATA_DELETE_SPACE_SCHEDULE_SPACE_GROUPS_WITH_ESTIMATEID_START'
export const DATA_DELETE_SPACE_SCHEDULE_SPACE_GROUPS_WITH_ESTIMATEID_SUCCESSFUL = 'DATA_DELETE_SPACE_SCHEDULE_SPACE_GROUPS_WITH_ESTIMATEID_SUCCESSFUL'
export const DATA_DELETE_SPACE_SCHEDULE_SPACE_GROUPS_WITH_ESTIMATEID_FAIL = 'DATA_DELETE_SPACE_SCHEDULE_SPACE_GROUPS_WITH_ESTIMATEID_FAIL'

// Delete multiple space groups in batch operation.
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/spaces.yaml#/Space%20Groups/delete_space_schedule__estimateId__space_groups
export const deleteSpaceScheduleSpaceGroupsWithEstimateIdRequest = (
  requestArguments: deleteSpaceScheduleSpaceGroupsWithEstimateIdRequestSpacesArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_DELETE_SPACE_SCHEDULE_SPACE_GROUPS_WITH_ESTIMATEID_START',
      successful: 'DATA_DELETE_SPACE_SCHEDULE_SPACE_GROUPS_WITH_ESTIMATEID_SUCCESSFUL',
      fail: 'DATA_DELETE_SPACE_SCHEDULE_SPACE_GROUPS_WITH_ESTIMATEID_FAIL'
    },
    embeddedType: null,
    fnName: 'deleteSpaceScheduleSpaceGroupsWithEstimateIdRequest'
  }
  const config = {
    headers: { 'Content-Type': 'application/json' },
    baseURL: `${API_BASE_URL}/spaces-api`,
    method: 'DELETE',
    url: `/space-schedule/${options?.estimateId || store.app.calculation}/space-groups`,
    params: {
      ids: requestArguments.query?.['ids'],
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'deleteSpaceScheduleSpaceGroupsWithEstimateIdRequest', '/spaces-api'),
    actions: {
      start: {
        type: DATA_DELETE_SPACE_SCHEDULE_SPACE_GROUPS_WITH_ESTIMATEID_START, payload, config, meta
      },
      successful: {
        type: DATA_DELETE_SPACE_SCHEDULE_SPACE_GROUPS_WITH_ESTIMATEID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_DELETE_SPACE_SCHEDULE_SPACE_GROUPS_WITH_ESTIMATEID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_DELETE_SPACE_SCHEDULE_SPACE_GROUPS_WITH_ID_START = 'DATA_DELETE_SPACE_SCHEDULE_SPACE_GROUPS_WITH_ID_START'
export const DATA_DELETE_SPACE_SCHEDULE_SPACE_GROUPS_WITH_ID_SUCCESSFUL = 'DATA_DELETE_SPACE_SCHEDULE_SPACE_GROUPS_WITH_ID_SUCCESSFUL'
export const DATA_DELETE_SPACE_SCHEDULE_SPACE_GROUPS_WITH_ID_FAIL = 'DATA_DELETE_SPACE_SCHEDULE_SPACE_GROUPS_WITH_ID_FAIL'

// Deletes a space group.
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/spaces.yaml#/Space%20Groups/delete_space_schedule__estimateId__space_groups__id_
export const deleteSpaceScheduleSpaceGroupsWithIdRequest = (
  requestArguments: deleteSpaceScheduleSpaceGroupsWithIdRequestSpacesArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_DELETE_SPACE_SCHEDULE_SPACE_GROUPS_WITH_ID_START',
      successful: 'DATA_DELETE_SPACE_SCHEDULE_SPACE_GROUPS_WITH_ID_SUCCESSFUL',
      fail: 'DATA_DELETE_SPACE_SCHEDULE_SPACE_GROUPS_WITH_ID_FAIL'
    },
    embeddedType: null,
    fnName: 'deleteSpaceScheduleSpaceGroupsWithIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/spaces-api`,
    method: 'DELETE',
    url: `/space-schedule/${options?.estimateId || store.app.calculation}/space-groups/${requestArguments.path['id']}`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'deleteSpaceScheduleSpaceGroupsWithIdRequest', '/spaces-api'),
    actions: {
      start: {
        type: DATA_DELETE_SPACE_SCHEDULE_SPACE_GROUPS_WITH_ID_START, payload, config, meta
      },
      successful: {
        type: DATA_DELETE_SPACE_SCHEDULE_SPACE_GROUPS_WITH_ID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_DELETE_SPACE_SCHEDULE_SPACE_GROUPS_WITH_ID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_POST_SPACE_SCHEDULE_SPACE_GROUPS_WITH_ID_START = 'DATA_POST_SPACE_SCHEDULE_SPACE_GROUPS_WITH_ID_START'
export const DATA_POST_SPACE_SCHEDULE_SPACE_GROUPS_WITH_ID_SUCCESSFUL = 'DATA_POST_SPACE_SCHEDULE_SPACE_GROUPS_WITH_ID_SUCCESSFUL'
export const DATA_POST_SPACE_SCHEDULE_SPACE_GROUPS_WITH_ID_FAIL = 'DATA_POST_SPACE_SCHEDULE_SPACE_GROUPS_WITH_ID_FAIL'

// Create a new space group under a function
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/spaces.yaml#/Space%20Groups/post_space_schedule__estimateId__space_groups__id_
export const postSpaceScheduleSpaceGroupsWithIdRequest = (
  requestArguments: postSpaceScheduleSpaceGroupsWithIdRequestSpacesArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_POST_SPACE_SCHEDULE_SPACE_GROUPS_WITH_ID_START',
      successful: 'DATA_POST_SPACE_SCHEDULE_SPACE_GROUPS_WITH_ID_SUCCESSFUL',
      fail: 'DATA_POST_SPACE_SCHEDULE_SPACE_GROUPS_WITH_ID_FAIL'
    },
    embeddedType: null,
    fnName: 'postSpaceScheduleSpaceGroupsWithIdRequest'
  }
  const config = {
    headers: { 'Content-Type': 'application/json' },
    baseURL: `${API_BASE_URL}/spaces-api`,
    method: 'POST',
    url: `/space-schedule/${options?.estimateId || store.app.calculation}/space-groups/${requestArguments.path['id']}`,
    params: {
      copyId: requestArguments.query?.['copyId'],
      registryId: requestArguments.query?.['registryId'],
      updateCirculation: requestArguments.query?.['updateCirculation'],
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: requestArguments.body
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'postSpaceScheduleSpaceGroupsWithIdRequest', '/spaces-api'),
    actions: {
      start: {
        type: DATA_POST_SPACE_SCHEDULE_SPACE_GROUPS_WITH_ID_START, payload, config, meta
      },
      successful: {
        type: DATA_POST_SPACE_SCHEDULE_SPACE_GROUPS_WITH_ID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_POST_SPACE_SCHEDULE_SPACE_GROUPS_WITH_ID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_GET_SPACE_SCHEDULE_SPACE_GROUPS_WITH_ID_START = 'DATA_GET_SPACE_SCHEDULE_SPACE_GROUPS_WITH_ID_START'
export const DATA_GET_SPACE_SCHEDULE_SPACE_GROUPS_WITH_ID_SUCCESSFUL = 'DATA_GET_SPACE_SCHEDULE_SPACE_GROUPS_WITH_ID_SUCCESSFUL'
export const DATA_GET_SPACE_SCHEDULE_SPACE_GROUPS_WITH_ID_FAIL = 'DATA_GET_SPACE_SCHEDULE_SPACE_GROUPS_WITH_ID_FAIL'

// Returns a space group for space schedule
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/spaces.yaml#/Space%20Groups/get_space_schedule__estimateId__space_groups__id_
export const getSpaceScheduleSpaceGroupsWithIdRequest = (
  requestArguments: getSpaceScheduleSpaceGroupsWithIdRequestSpacesArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_SPACE_SCHEDULE_SPACE_GROUPS_WITH_ID_START',
      successful: 'DATA_GET_SPACE_SCHEDULE_SPACE_GROUPS_WITH_ID_SUCCESSFUL',
      fail: 'DATA_GET_SPACE_SCHEDULE_SPACE_GROUPS_WITH_ID_FAIL'
    },
    embeddedType: {
      nonEmbedded: {
        schemaPath: '#/definitions/ScheduleItem',
        schemaKey: 'ScheduleItem'
      }
    },
    fnName: 'getSpaceScheduleSpaceGroupsWithIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/spaces-api`,
    method: 'GET',
    url: `/space-schedule/${options?.estimateId || store.app.calculation}/space-groups/${requestArguments.path['id']}`,
    params: {
      spacesEstimateType: options?.spacesEstimateType || store.app.spacesEstimateType,
      spacesResultView: options?.spacesResultView || store.app.spacesResultView,
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      nonEmbedded: {
        schemaPath: '#/definitions/ScheduleItem',
        schemaKey: 'ScheduleItem'
      }
    }, 'getSpaceScheduleSpaceGroupsWithIdRequest', '/spaces-api'),
    actions: {
      start: {
        type: DATA_GET_SPACE_SCHEDULE_SPACE_GROUPS_WITH_ID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_SPACE_SCHEDULE_SPACE_GROUPS_WITH_ID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_SPACE_SCHEDULE_SPACE_GROUPS_WITH_ID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getSpaceScheduleSpaceGroupsWithIdRequestDefinitions = (definitionsArguments: {| requestArgs: getSpaceScheduleSpaceGroupsWithIdRequestSpacesArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getSpaceScheduleSpaceGroupsWithIdRequestSpacesArguments, payload?: Object |} => ({
  fnName: 'getSpaceScheduleSpaceGroupsWithIdRequest',
  basePath: '/spaces-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_PATCH_SPACE_SCHEDULE_SPACE_GROUPS_WITH_ID_START = 'DATA_PATCH_SPACE_SCHEDULE_SPACE_GROUPS_WITH_ID_START'
export const DATA_PATCH_SPACE_SCHEDULE_SPACE_GROUPS_WITH_ID_SUCCESSFUL = 'DATA_PATCH_SPACE_SCHEDULE_SPACE_GROUPS_WITH_ID_SUCCESSFUL'
export const DATA_PATCH_SPACE_SCHEDULE_SPACE_GROUPS_WITH_ID_FAIL = 'DATA_PATCH_SPACE_SCHEDULE_SPACE_GROUPS_WITH_ID_FAIL'

// Update space group schedule item properties. Will return a queue location header if calculation is needed.
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/spaces.yaml#/Space%20Groups/patch_space_schedule__estimateId__space_groups__id_
export const patchSpaceScheduleSpaceGroupsWithIdRequest = (
  requestArguments: patchSpaceScheduleSpaceGroupsWithIdRequestSpacesArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_PATCH_SPACE_SCHEDULE_SPACE_GROUPS_WITH_ID_START',
      successful: 'DATA_PATCH_SPACE_SCHEDULE_SPACE_GROUPS_WITH_ID_SUCCESSFUL',
      fail: 'DATA_PATCH_SPACE_SCHEDULE_SPACE_GROUPS_WITH_ID_FAIL'
    },
    embeddedType: null,
    fnName: 'patchSpaceScheduleSpaceGroupsWithIdRequest'
  }
  const config = {
    headers: { 'Content-Type': 'application/json' },
    baseURL: `${API_BASE_URL}/spaces-api`,
    method: 'PATCH',
    url: `/space-schedule/${options?.estimateId || store.app.calculation}/space-groups/${requestArguments.path['id']}`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: requestArguments.body
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'patchSpaceScheduleSpaceGroupsWithIdRequest', '/spaces-api'),
    actions: {
      start: {
        type: DATA_PATCH_SPACE_SCHEDULE_SPACE_GROUPS_WITH_ID_START, payload, config, meta
      },
      successful: {
        type: DATA_PATCH_SPACE_SCHEDULE_SPACE_GROUPS_WITH_ID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_PATCH_SPACE_SCHEDULE_SPACE_GROUPS_WITH_ID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_GET_SPACE_SCHEDULE_SPACE_GROUPS_SPACES_WITH_ID_START = 'DATA_GET_SPACE_SCHEDULE_SPACE_GROUPS_SPACES_WITH_ID_START'
export const DATA_GET_SPACE_SCHEDULE_SPACE_GROUPS_SPACES_WITH_ID_SUCCESSFUL = 'DATA_GET_SPACE_SCHEDULE_SPACE_GROUPS_SPACES_WITH_ID_SUCCESSFUL'
export const DATA_GET_SPACE_SCHEDULE_SPACE_GROUPS_SPACES_WITH_ID_FAIL = 'DATA_GET_SPACE_SCHEDULE_SPACE_GROUPS_SPACES_WITH_ID_FAIL'

// Gets spaces associated with a space group
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/spaces.yaml#/Space%20Groups/get_space_schedule__estimateId__space_groups__id__spaces
export const getSpaceScheduleSpaceGroupsSpacesWithIdRequest = (
  requestArguments: getSpaceScheduleSpaceGroupsSpacesWithIdRequestSpacesArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_SPACE_SCHEDULE_SPACE_GROUPS_SPACES_WITH_ID_START',
      successful: 'DATA_GET_SPACE_SCHEDULE_SPACE_GROUPS_SPACES_WITH_ID_SUCCESSFUL',
      fail: 'DATA_GET_SPACE_SCHEDULE_SPACE_GROUPS_SPACES_WITH_ID_FAIL'
    },
    embeddedType: {
      items: {
        schemaPath: '#/definitions/ScheduleItem',
        schemaKey: 'ScheduleItem'
      }
    },
    fnName: 'getSpaceScheduleSpaceGroupsSpacesWithIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/spaces-api`,
    method: 'GET',
    url: `/space-schedule/${options?.estimateId || store.app.calculation}/space-groups/${requestArguments.path['id']}/spaces`,
    params: {
      spacesEstimateType: options?.spacesEstimateType || store.app.spacesEstimateType,
      spacesResultView: options?.spacesResultView || store.app.spacesResultView,
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      items: {
        schemaPath: '#/definitions/ScheduleItem',
        schemaKey: 'ScheduleItem'
      }
    }, 'getSpaceScheduleSpaceGroupsSpacesWithIdRequest', '/spaces-api'),
    actions: {
      start: {
        type: DATA_GET_SPACE_SCHEDULE_SPACE_GROUPS_SPACES_WITH_ID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_SPACE_SCHEDULE_SPACE_GROUPS_SPACES_WITH_ID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_SPACE_SCHEDULE_SPACE_GROUPS_SPACES_WITH_ID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getSpaceScheduleSpaceGroupsSpacesWithIdRequestDefinitions = (definitionsArguments: {| requestArgs: getSpaceScheduleSpaceGroupsSpacesWithIdRequestSpacesArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getSpaceScheduleSpaceGroupsSpacesWithIdRequestSpacesArguments, payload?: Object |} => ({
  fnName: 'getSpaceScheduleSpaceGroupsSpacesWithIdRequest',
  basePath: '/spaces-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_GET_SPACE_SCHEDULE_SPACE_GROUPS_PROPERTIES_INFO_WITH_PROPERTYNAME_START = 'DATA_GET_SPACE_SCHEDULE_SPACE_GROUPS_PROPERTIES_INFO_WITH_PROPERTYNAME_START'
export const DATA_GET_SPACE_SCHEDULE_SPACE_GROUPS_PROPERTIES_INFO_WITH_PROPERTYNAME_SUCCESSFUL = 'DATA_GET_SPACE_SCHEDULE_SPACE_GROUPS_PROPERTIES_INFO_WITH_PROPERTYNAME_SUCCESSFUL'
export const DATA_GET_SPACE_SCHEDULE_SPACE_GROUPS_PROPERTIES_INFO_WITH_PROPERTYNAME_FAIL = 'DATA_GET_SPACE_SCHEDULE_SPACE_GROUPS_PROPERTIES_INFO_WITH_PROPERTYNAME_FAIL'

// Get short information text for a space group property
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/spaces.yaml#/Space%20Groups/get_space_schedule__estimateId__space_groups__id__properties_info__propertyName_
export const getSpaceScheduleSpaceGroupsPropertiesInfoWithPropertyNameRequest = (
  requestArguments: getSpaceScheduleSpaceGroupsPropertiesInfoWithPropertyNameRequestSpacesArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_SPACE_SCHEDULE_SPACE_GROUPS_PROPERTIES_INFO_WITH_PROPERTYNAME_START',
      successful: 'DATA_GET_SPACE_SCHEDULE_SPACE_GROUPS_PROPERTIES_INFO_WITH_PROPERTYNAME_SUCCESSFUL',
      fail: 'DATA_GET_SPACE_SCHEDULE_SPACE_GROUPS_PROPERTIES_INFO_WITH_PROPERTYNAME_FAIL'
    },
    embeddedType: null,
    fnName: 'getSpaceScheduleSpaceGroupsPropertiesInfoWithPropertyNameRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/spaces-api`,
    method: 'GET',
    url: `/space-schedule/${options?.estimateId || store.app.calculation}/space-groups/${requestArguments.path['id']}/properties/info/${requestArguments.path['propertyName']}`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'getSpaceScheduleSpaceGroupsPropertiesInfoWithPropertyNameRequest', '/spaces-api'),
    actions: {
      start: {
        type: DATA_GET_SPACE_SCHEDULE_SPACE_GROUPS_PROPERTIES_INFO_WITH_PROPERTYNAME_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_SPACE_SCHEDULE_SPACE_GROUPS_PROPERTIES_INFO_WITH_PROPERTYNAME_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_SPACE_SCHEDULE_SPACE_GROUPS_PROPERTIES_INFO_WITH_PROPERTYNAME_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getSpaceScheduleSpaceGroupsPropertiesInfoWithPropertyNameRequestDefinitions = (definitionsArguments: {| requestArgs: getSpaceScheduleSpaceGroupsPropertiesInfoWithPropertyNameRequestSpacesArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getSpaceScheduleSpaceGroupsPropertiesInfoWithPropertyNameRequestSpacesArguments, payload?: Object |} => ({
  fnName: 'getSpaceScheduleSpaceGroupsPropertiesInfoWithPropertyNameRequest',
  basePath: '/spaces-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_GET_SPACE_SCHEDULE_SPACE_GROUPS_PROPERTIES_WITH_ID_START = 'DATA_GET_SPACE_SCHEDULE_SPACE_GROUPS_PROPERTIES_WITH_ID_START'
export const DATA_GET_SPACE_SCHEDULE_SPACE_GROUPS_PROPERTIES_WITH_ID_SUCCESSFUL = 'DATA_GET_SPACE_SCHEDULE_SPACE_GROUPS_PROPERTIES_WITH_ID_SUCCESSFUL'
export const DATA_GET_SPACE_SCHEDULE_SPACE_GROUPS_PROPERTIES_WITH_ID_FAIL = 'DATA_GET_SPACE_SCHEDULE_SPACE_GROUPS_PROPERTIES_WITH_ID_FAIL'

// Returns all properties of a space group
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/spaces.yaml#/Space%20Groups/get_space_schedule__estimateId__space_groups__id__properties
export const getSpaceScheduleSpaceGroupsPropertiesWithIdRequest = (
  requestArguments: getSpaceScheduleSpaceGroupsPropertiesWithIdRequestSpacesArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_SPACE_SCHEDULE_SPACE_GROUPS_PROPERTIES_WITH_ID_START',
      successful: 'DATA_GET_SPACE_SCHEDULE_SPACE_GROUPS_PROPERTIES_WITH_ID_SUCCESSFUL',
      fail: 'DATA_GET_SPACE_SCHEDULE_SPACE_GROUPS_PROPERTIES_WITH_ID_FAIL'
    },
    embeddedType: {
      properties: {
        schemaPath: '#/definitions/ValueProperty',
        schemaKey: 'ValueProperty'
      }
    },
    fnName: 'getSpaceScheduleSpaceGroupsPropertiesWithIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/spaces-api`,
    method: 'GET',
    url: `/space-schedule/${options?.estimateId || store.app.calculation}/space-groups/${requestArguments.path['id']}/properties`,
    params: {
      spacesEstimateType: options?.spacesEstimateType || store.app.spacesEstimateType,
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      properties: {
        schemaPath: '#/definitions/ValueProperty',
        schemaKey: 'ValueProperty'
      }
    }, 'getSpaceScheduleSpaceGroupsPropertiesWithIdRequest', '/spaces-api'),
    actions: {
      start: {
        type: DATA_GET_SPACE_SCHEDULE_SPACE_GROUPS_PROPERTIES_WITH_ID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_SPACE_SCHEDULE_SPACE_GROUPS_PROPERTIES_WITH_ID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_SPACE_SCHEDULE_SPACE_GROUPS_PROPERTIES_WITH_ID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getSpaceScheduleSpaceGroupsPropertiesWithIdRequestDefinitions = (definitionsArguments: {| requestArgs: getSpaceScheduleSpaceGroupsPropertiesWithIdRequestSpacesArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getSpaceScheduleSpaceGroupsPropertiesWithIdRequestSpacesArguments, payload?: Object |} => ({
  fnName: 'getSpaceScheduleSpaceGroupsPropertiesWithIdRequest',
  basePath: '/spaces-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_PATCH_SPACE_SCHEDULE_SPACE_GROUPS_PROPERTIES_WITH_ID_START = 'DATA_PATCH_SPACE_SCHEDULE_SPACE_GROUPS_PROPERTIES_WITH_ID_START'
export const DATA_PATCH_SPACE_SCHEDULE_SPACE_GROUPS_PROPERTIES_WITH_ID_SUCCESSFUL = 'DATA_PATCH_SPACE_SCHEDULE_SPACE_GROUPS_PROPERTIES_WITH_ID_SUCCESSFUL'
export const DATA_PATCH_SPACE_SCHEDULE_SPACE_GROUPS_PROPERTIES_WITH_ID_FAIL = 'DATA_PATCH_SPACE_SCHEDULE_SPACE_GROUPS_PROPERTIES_WITH_ID_FAIL'

// Update space group properties. Will return a queue location header if calculation is needed.
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/spaces.yaml#/Space%20Groups/patch_space_schedule__estimateId__space_groups__id__properties
export const patchSpaceScheduleSpaceGroupsPropertiesWithIdRequest = (
  requestArguments: patchSpaceScheduleSpaceGroupsPropertiesWithIdRequestSpacesArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_PATCH_SPACE_SCHEDULE_SPACE_GROUPS_PROPERTIES_WITH_ID_START',
      successful: 'DATA_PATCH_SPACE_SCHEDULE_SPACE_GROUPS_PROPERTIES_WITH_ID_SUCCESSFUL',
      fail: 'DATA_PATCH_SPACE_SCHEDULE_SPACE_GROUPS_PROPERTIES_WITH_ID_FAIL'
    },
    embeddedType: null,
    fnName: 'patchSpaceScheduleSpaceGroupsPropertiesWithIdRequest'
  }
  const config = {
    headers: { 'Content-Type': 'application/json' },
    baseURL: `${API_BASE_URL}/spaces-api`,
    method: 'PATCH',
    url: `/space-schedule/${options?.estimateId || store.app.calculation}/space-groups/${requestArguments.path['id']}/properties`,
    params: {
      spacesEstimateType: options?.spacesEstimateType || store.app.spacesEstimateType,
      resetProperties: requestArguments.query?.['resetProperties'],
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: requestArguments.body
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'patchSpaceScheduleSpaceGroupsPropertiesWithIdRequest', '/spaces-api'),
    actions: {
      start: {
        type: DATA_PATCH_SPACE_SCHEDULE_SPACE_GROUPS_PROPERTIES_WITH_ID_START, payload, config, meta
      },
      successful: {
        type: DATA_PATCH_SPACE_SCHEDULE_SPACE_GROUPS_PROPERTIES_WITH_ID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_PATCH_SPACE_SCHEDULE_SPACE_GROUPS_PROPERTIES_WITH_ID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_PATCH_SPACE_SCHEDULE_SPACE_GROUPS_PROPERTIES_WITH_ESTIMATEID_START = 'DATA_PATCH_SPACE_SCHEDULE_SPACE_GROUPS_PROPERTIES_WITH_ESTIMATEID_START'
export const DATA_PATCH_SPACE_SCHEDULE_SPACE_GROUPS_PROPERTIES_WITH_ESTIMATEID_SUCCESSFUL = 'DATA_PATCH_SPACE_SCHEDULE_SPACE_GROUPS_PROPERTIES_WITH_ESTIMATEID_SUCCESSFUL'
export const DATA_PATCH_SPACE_SCHEDULE_SPACE_GROUPS_PROPERTIES_WITH_ESTIMATEID_FAIL = 'DATA_PATCH_SPACE_SCHEDULE_SPACE_GROUPS_PROPERTIES_WITH_ESTIMATEID_FAIL'

// Update properties of multiple space groups simultaneously.
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/spaces.yaml#/Spaces/patch_space_schedule__estimateId__space_groups_properties
export const patchSpaceScheduleSpaceGroupsPropertiesWithEstimateIdRequest = (
  requestArguments: patchSpaceScheduleSpaceGroupsPropertiesWithEstimateIdRequestSpacesArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_PATCH_SPACE_SCHEDULE_SPACE_GROUPS_PROPERTIES_WITH_ESTIMATEID_START',
      successful: 'DATA_PATCH_SPACE_SCHEDULE_SPACE_GROUPS_PROPERTIES_WITH_ESTIMATEID_SUCCESSFUL',
      fail: 'DATA_PATCH_SPACE_SCHEDULE_SPACE_GROUPS_PROPERTIES_WITH_ESTIMATEID_FAIL'
    },
    embeddedType: null,
    fnName: 'patchSpaceScheduleSpaceGroupsPropertiesWithEstimateIdRequest'
  }
  const config = {
    headers: { 'Content-Type': 'application/json' },
    baseURL: `${API_BASE_URL}/spaces-api`,
    method: 'PATCH',
    url: `/space-schedule/${options?.estimateId || store.app.calculation}/space-groups/properties`,
    params: {
      spacesEstimateType: options?.spacesEstimateType || store.app.spacesEstimateType,
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: requestArguments.body
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'patchSpaceScheduleSpaceGroupsPropertiesWithEstimateIdRequest', '/spaces-api'),
    actions: {
      start: {
        type: DATA_PATCH_SPACE_SCHEDULE_SPACE_GROUPS_PROPERTIES_WITH_ESTIMATEID_START, payload, config, meta
      },
      successful: {
        type: DATA_PATCH_SPACE_SCHEDULE_SPACE_GROUPS_PROPERTIES_WITH_ESTIMATEID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_PATCH_SPACE_SCHEDULE_SPACE_GROUPS_PROPERTIES_WITH_ESTIMATEID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_GET_SPACE_SCHEDULE_SPACE_GROUPS_VALUES_WITH_ID_START = 'DATA_GET_SPACE_SCHEDULE_SPACE_GROUPS_VALUES_WITH_ID_START'
export const DATA_GET_SPACE_SCHEDULE_SPACE_GROUPS_VALUES_WITH_ID_SUCCESSFUL = 'DATA_GET_SPACE_SCHEDULE_SPACE_GROUPS_VALUES_WITH_ID_SUCCESSFUL'
export const DATA_GET_SPACE_SCHEDULE_SPACE_GROUPS_VALUES_WITH_ID_FAIL = 'DATA_GET_SPACE_SCHEDULE_SPACE_GROUPS_VALUES_WITH_ID_FAIL'

// Returns space group key figures
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/spaces.yaml#/Space%20Groups%20Key%20Figures/get_space_schedule__estimateId__space_groups__id__values
export const getSpaceScheduleSpaceGroupsValuesWithIdRequest = (
  requestArguments: getSpaceScheduleSpaceGroupsValuesWithIdRequestSpacesArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_SPACE_SCHEDULE_SPACE_GROUPS_VALUES_WITH_ID_START',
      successful: 'DATA_GET_SPACE_SCHEDULE_SPACE_GROUPS_VALUES_WITH_ID_SUCCESSFUL',
      fail: 'DATA_GET_SPACE_SCHEDULE_SPACE_GROUPS_VALUES_WITH_ID_FAIL'
    },
    embeddedType: {
      values: {
        schemaPath: '#/definitions/ValueProperty',
        schemaKey: 'ValueProperty'
      }
    },
    fnName: 'getSpaceScheduleSpaceGroupsValuesWithIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/spaces-api`,
    method: 'GET',
    url: `/space-schedule/${options?.estimateId || store.app.calculation}/space-groups/${requestArguments.path['id']}/values`,
    params: {
      spacesEstimateType: options?.spacesEstimateType || store.app.spacesEstimateType,
      spacesResultView: options?.spacesResultView || store.app.spacesResultView,
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      values: {
        schemaPath: '#/definitions/ValueProperty',
        schemaKey: 'ValueProperty'
      }
    }, 'getSpaceScheduleSpaceGroupsValuesWithIdRequest', '/spaces-api'),
    actions: {
      start: {
        type: DATA_GET_SPACE_SCHEDULE_SPACE_GROUPS_VALUES_WITH_ID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_SPACE_SCHEDULE_SPACE_GROUPS_VALUES_WITH_ID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_SPACE_SCHEDULE_SPACE_GROUPS_VALUES_WITH_ID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getSpaceScheduleSpaceGroupsValuesWithIdRequestDefinitions = (definitionsArguments: {| requestArgs: getSpaceScheduleSpaceGroupsValuesWithIdRequestSpacesArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getSpaceScheduleSpaceGroupsValuesWithIdRequestSpacesArguments, payload?: Object |} => ({
  fnName: 'getSpaceScheduleSpaceGroupsValuesWithIdRequest',
  basePath: '/spaces-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_GET_SPACE_SCHEDULE_SPACE_GROUPS_CAN_MOVE_TO_WITH_ID_START = 'DATA_GET_SPACE_SCHEDULE_SPACE_GROUPS_CAN_MOVE_TO_WITH_ID_START'
export const DATA_GET_SPACE_SCHEDULE_SPACE_GROUPS_CAN_MOVE_TO_WITH_ID_SUCCESSFUL = 'DATA_GET_SPACE_SCHEDULE_SPACE_GROUPS_CAN_MOVE_TO_WITH_ID_SUCCESSFUL'
export const DATA_GET_SPACE_SCHEDULE_SPACE_GROUPS_CAN_MOVE_TO_WITH_ID_FAIL = 'DATA_GET_SPACE_SCHEDULE_SPACE_GROUPS_CAN_MOVE_TO_WITH_ID_FAIL'

// Get id and description of functions to which the space group can be moved to
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/spaces.yaml#/Space%20Groups/get_space_schedule__estimateId__space_groups__id__can_move_to
export const getSpaceScheduleSpaceGroupsCanMoveToWithIdRequest = (
  requestArguments: getSpaceScheduleSpaceGroupsCanMoveToWithIdRequestSpacesArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_SPACE_SCHEDULE_SPACE_GROUPS_CAN_MOVE_TO_WITH_ID_START',
      successful: 'DATA_GET_SPACE_SCHEDULE_SPACE_GROUPS_CAN_MOVE_TO_WITH_ID_SUCCESSFUL',
      fail: 'DATA_GET_SPACE_SCHEDULE_SPACE_GROUPS_CAN_MOVE_TO_WITH_ID_FAIL'
    },
    embeddedType: {
      items: {
        schemaPath: '#/definitions/Enumeral',
        schemaKey: 'Enumeral'
      }
    },
    fnName: 'getSpaceScheduleSpaceGroupsCanMoveToWithIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/spaces-api`,
    method: 'GET',
    url: `/space-schedule/${options?.estimateId || store.app.calculation}/space-groups/${requestArguments.path['id']}/can-move-to`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      items: {
        schemaPath: '#/definitions/Enumeral',
        schemaKey: 'Enumeral'
      }
    }, 'getSpaceScheduleSpaceGroupsCanMoveToWithIdRequest', '/spaces-api'),
    actions: {
      start: {
        type: DATA_GET_SPACE_SCHEDULE_SPACE_GROUPS_CAN_MOVE_TO_WITH_ID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_SPACE_SCHEDULE_SPACE_GROUPS_CAN_MOVE_TO_WITH_ID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_SPACE_SCHEDULE_SPACE_GROUPS_CAN_MOVE_TO_WITH_ID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getSpaceScheduleSpaceGroupsCanMoveToWithIdRequestDefinitions = (definitionsArguments: {| requestArgs: getSpaceScheduleSpaceGroupsCanMoveToWithIdRequestSpacesArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getSpaceScheduleSpaceGroupsCanMoveToWithIdRequestSpacesArguments, payload?: Object |} => ({
  fnName: 'getSpaceScheduleSpaceGroupsCanMoveToWithIdRequest',
  basePath: '/spaces-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_DELETE_SPACE_SCHEDULE_SPACES_WITH_ESTIMATEID_START = 'DATA_DELETE_SPACE_SCHEDULE_SPACES_WITH_ESTIMATEID_START'
export const DATA_DELETE_SPACE_SCHEDULE_SPACES_WITH_ESTIMATEID_SUCCESSFUL = 'DATA_DELETE_SPACE_SCHEDULE_SPACES_WITH_ESTIMATEID_SUCCESSFUL'
export const DATA_DELETE_SPACE_SCHEDULE_SPACES_WITH_ESTIMATEID_FAIL = 'DATA_DELETE_SPACE_SCHEDULE_SPACES_WITH_ESTIMATEID_FAIL'

// Delete multiple spaces in batch operation.
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/spaces.yaml#/Spaces/delete_space_schedule__estimateId__spaces
export const deleteSpaceScheduleSpacesWithEstimateIdRequest = (
  requestArguments: deleteSpaceScheduleSpacesWithEstimateIdRequestSpacesArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_DELETE_SPACE_SCHEDULE_SPACES_WITH_ESTIMATEID_START',
      successful: 'DATA_DELETE_SPACE_SCHEDULE_SPACES_WITH_ESTIMATEID_SUCCESSFUL',
      fail: 'DATA_DELETE_SPACE_SCHEDULE_SPACES_WITH_ESTIMATEID_FAIL'
    },
    embeddedType: null,
    fnName: 'deleteSpaceScheduleSpacesWithEstimateIdRequest'
  }
  const config = {
    headers: { 'Content-Type': 'application/json' },
    baseURL: `${API_BASE_URL}/spaces-api`,
    method: 'DELETE',
    url: `/space-schedule/${options?.estimateId || store.app.calculation}/spaces`,
    params: {
      ids: requestArguments.query?.['ids'],
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'deleteSpaceScheduleSpacesWithEstimateIdRequest', '/spaces-api'),
    actions: {
      start: {
        type: DATA_DELETE_SPACE_SCHEDULE_SPACES_WITH_ESTIMATEID_START, payload, config, meta
      },
      successful: {
        type: DATA_DELETE_SPACE_SCHEDULE_SPACES_WITH_ESTIMATEID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_DELETE_SPACE_SCHEDULE_SPACES_WITH_ESTIMATEID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_DELETE_SPACE_SCHEDULE_SPACES_WITH_ID_START = 'DATA_DELETE_SPACE_SCHEDULE_SPACES_WITH_ID_START'
export const DATA_DELETE_SPACE_SCHEDULE_SPACES_WITH_ID_SUCCESSFUL = 'DATA_DELETE_SPACE_SCHEDULE_SPACES_WITH_ID_SUCCESSFUL'
export const DATA_DELETE_SPACE_SCHEDULE_SPACES_WITH_ID_FAIL = 'DATA_DELETE_SPACE_SCHEDULE_SPACES_WITH_ID_FAIL'

// Deletes a space
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/spaces.yaml#/Spaces/delete_space_schedule__estimateId__spaces__id_
export const deleteSpaceScheduleSpacesWithIdRequest = (
  requestArguments: deleteSpaceScheduleSpacesWithIdRequestSpacesArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_DELETE_SPACE_SCHEDULE_SPACES_WITH_ID_START',
      successful: 'DATA_DELETE_SPACE_SCHEDULE_SPACES_WITH_ID_SUCCESSFUL',
      fail: 'DATA_DELETE_SPACE_SCHEDULE_SPACES_WITH_ID_FAIL'
    },
    embeddedType: null,
    fnName: 'deleteSpaceScheduleSpacesWithIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/spaces-api`,
    method: 'DELETE',
    url: `/space-schedule/${options?.estimateId || store.app.calculation}/spaces/${requestArguments.path['id']}`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'deleteSpaceScheduleSpacesWithIdRequest', '/spaces-api'),
    actions: {
      start: {
        type: DATA_DELETE_SPACE_SCHEDULE_SPACES_WITH_ID_START, payload, config, meta
      },
      successful: {
        type: DATA_DELETE_SPACE_SCHEDULE_SPACES_WITH_ID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_DELETE_SPACE_SCHEDULE_SPACES_WITH_ID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_GET_SPACE_SCHEDULE_SPACES_WITH_ID_START = 'DATA_GET_SPACE_SCHEDULE_SPACES_WITH_ID_START'
export const DATA_GET_SPACE_SCHEDULE_SPACES_WITH_ID_SUCCESSFUL = 'DATA_GET_SPACE_SCHEDULE_SPACES_WITH_ID_SUCCESSFUL'
export const DATA_GET_SPACE_SCHEDULE_SPACES_WITH_ID_FAIL = 'DATA_GET_SPACE_SCHEDULE_SPACES_WITH_ID_FAIL'

// Returns a space for space schedule
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/spaces.yaml#/Spaces/get_space_schedule__estimateId__spaces__id_
export const getSpaceScheduleSpacesWithIdRequest = (
  requestArguments: getSpaceScheduleSpacesWithIdRequestSpacesArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_SPACE_SCHEDULE_SPACES_WITH_ID_START',
      successful: 'DATA_GET_SPACE_SCHEDULE_SPACES_WITH_ID_SUCCESSFUL',
      fail: 'DATA_GET_SPACE_SCHEDULE_SPACES_WITH_ID_FAIL'
    },
    embeddedType: {
      nonEmbedded: {
        schemaPath: '#/definitions/ScheduleItem',
        schemaKey: 'ScheduleItem'
      }
    },
    fnName: 'getSpaceScheduleSpacesWithIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/spaces-api`,
    method: 'GET',
    url: `/space-schedule/${options?.estimateId || store.app.calculation}/spaces/${requestArguments.path['id']}`,
    params: {
      spacesEstimateType: options?.spacesEstimateType || store.app.spacesEstimateType,
      spacesResultView: options?.spacesResultView || store.app.spacesResultView,
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      nonEmbedded: {
        schemaPath: '#/definitions/ScheduleItem',
        schemaKey: 'ScheduleItem'
      }
    }, 'getSpaceScheduleSpacesWithIdRequest', '/spaces-api'),
    actions: {
      start: {
        type: DATA_GET_SPACE_SCHEDULE_SPACES_WITH_ID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_SPACE_SCHEDULE_SPACES_WITH_ID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_SPACE_SCHEDULE_SPACES_WITH_ID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getSpaceScheduleSpacesWithIdRequestDefinitions = (definitionsArguments: {| requestArgs: getSpaceScheduleSpacesWithIdRequestSpacesArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getSpaceScheduleSpacesWithIdRequestSpacesArguments, payload?: Object |} => ({
  fnName: 'getSpaceScheduleSpacesWithIdRequest',
  basePath: '/spaces-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_PATCH_SPACE_SCHEDULE_SPACES_WITH_ID_START = 'DATA_PATCH_SPACE_SCHEDULE_SPACES_WITH_ID_START'
export const DATA_PATCH_SPACE_SCHEDULE_SPACES_WITH_ID_SUCCESSFUL = 'DATA_PATCH_SPACE_SCHEDULE_SPACES_WITH_ID_SUCCESSFUL'
export const DATA_PATCH_SPACE_SCHEDULE_SPACES_WITH_ID_FAIL = 'DATA_PATCH_SPACE_SCHEDULE_SPACES_WITH_ID_FAIL'

// Update resource properties. Will return a queue location header if calculation is needed.
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/spaces.yaml#/Spaces/patch_space_schedule__estimateId__spaces__id_
export const patchSpaceScheduleSpacesWithIdRequest = (
  requestArguments: patchSpaceScheduleSpacesWithIdRequestSpacesArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_PATCH_SPACE_SCHEDULE_SPACES_WITH_ID_START',
      successful: 'DATA_PATCH_SPACE_SCHEDULE_SPACES_WITH_ID_SUCCESSFUL',
      fail: 'DATA_PATCH_SPACE_SCHEDULE_SPACES_WITH_ID_FAIL'
    },
    embeddedType: null,
    fnName: 'patchSpaceScheduleSpacesWithIdRequest'
  }
  const config = {
    headers: { 'Content-Type': 'application/json' },
    baseURL: `${API_BASE_URL}/spaces-api`,
    method: 'PATCH',
    url: `/space-schedule/${options?.estimateId || store.app.calculation}/spaces/${requestArguments.path['id']}`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: requestArguments.body
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'patchSpaceScheduleSpacesWithIdRequest', '/spaces-api'),
    actions: {
      start: {
        type: DATA_PATCH_SPACE_SCHEDULE_SPACES_WITH_ID_START, payload, config, meta
      },
      successful: {
        type: DATA_PATCH_SPACE_SCHEDULE_SPACES_WITH_ID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_PATCH_SPACE_SCHEDULE_SPACES_WITH_ID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_POST_SPACE_SCHEDULE_SPACES_WITH_ID_START = 'DATA_POST_SPACE_SCHEDULE_SPACES_WITH_ID_START'
export const DATA_POST_SPACE_SCHEDULE_SPACES_WITH_ID_SUCCESSFUL = 'DATA_POST_SPACE_SCHEDULE_SPACES_WITH_ID_SUCCESSFUL'
export const DATA_POST_SPACE_SCHEDULE_SPACES_WITH_ID_FAIL = 'DATA_POST_SPACE_SCHEDULE_SPACES_WITH_ID_FAIL'

// Create a new space under a space group
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/spaces.yaml#/Spaces/post_space_schedule__estimateId__spaces__id_
export const postSpaceScheduleSpacesWithIdRequest = (
  requestArguments: postSpaceScheduleSpacesWithIdRequestSpacesArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_POST_SPACE_SCHEDULE_SPACES_WITH_ID_START',
      successful: 'DATA_POST_SPACE_SCHEDULE_SPACES_WITH_ID_SUCCESSFUL',
      fail: 'DATA_POST_SPACE_SCHEDULE_SPACES_WITH_ID_FAIL'
    },
    embeddedType: null,
    fnName: 'postSpaceScheduleSpacesWithIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/spaces-api`,
    method: 'POST',
    url: `/space-schedule/${options?.estimateId || store.app.calculation}/spaces/${requestArguments.path['id']}`,
    params: {
      copyId: requestArguments.query?.['copyId'],
      registryId: requestArguments.query?.['registryId'],
      updateCirculation: requestArguments.query?.['updateCirculation'],
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: requestArguments.body
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'postSpaceScheduleSpacesWithIdRequest', '/spaces-api'),
    actions: {
      start: {
        type: DATA_POST_SPACE_SCHEDULE_SPACES_WITH_ID_START, payload, config, meta
      },
      successful: {
        type: DATA_POST_SPACE_SCHEDULE_SPACES_WITH_ID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_POST_SPACE_SCHEDULE_SPACES_WITH_ID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_GET_SPACE_SCHEDULE_SPACES_CAN_MOVE_TO_WITH_ID_START = 'DATA_GET_SPACE_SCHEDULE_SPACES_CAN_MOVE_TO_WITH_ID_START'
export const DATA_GET_SPACE_SCHEDULE_SPACES_CAN_MOVE_TO_WITH_ID_SUCCESSFUL = 'DATA_GET_SPACE_SCHEDULE_SPACES_CAN_MOVE_TO_WITH_ID_SUCCESSFUL'
export const DATA_GET_SPACE_SCHEDULE_SPACES_CAN_MOVE_TO_WITH_ID_FAIL = 'DATA_GET_SPACE_SCHEDULE_SPACES_CAN_MOVE_TO_WITH_ID_FAIL'

// Get id and description of space groups to which the space can be moved to
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/spaces.yaml#/Spaces/get_space_schedule__estimateId__spaces__id__can_move_to
export const getSpaceScheduleSpacesCanMoveToWithIdRequest = (
  requestArguments: getSpaceScheduleSpacesCanMoveToWithIdRequestSpacesArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_SPACE_SCHEDULE_SPACES_CAN_MOVE_TO_WITH_ID_START',
      successful: 'DATA_GET_SPACE_SCHEDULE_SPACES_CAN_MOVE_TO_WITH_ID_SUCCESSFUL',
      fail: 'DATA_GET_SPACE_SCHEDULE_SPACES_CAN_MOVE_TO_WITH_ID_FAIL'
    },
    embeddedType: {
      items: {
        schemaPath: '#/definitions/Enumeral',
        schemaKey: 'Enumeral'
      }
    },
    fnName: 'getSpaceScheduleSpacesCanMoveToWithIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/spaces-api`,
    method: 'GET',
    url: `/space-schedule/${options?.estimateId || store.app.calculation}/spaces/${requestArguments.path['id']}/can-move-to`,
    params: {
      estimateId: options?.estimateId || store.app.calculation,
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      items: {
        schemaPath: '#/definitions/Enumeral',
        schemaKey: 'Enumeral'
      }
    }, 'getSpaceScheduleSpacesCanMoveToWithIdRequest', '/spaces-api'),
    actions: {
      start: {
        type: DATA_GET_SPACE_SCHEDULE_SPACES_CAN_MOVE_TO_WITH_ID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_SPACE_SCHEDULE_SPACES_CAN_MOVE_TO_WITH_ID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_SPACE_SCHEDULE_SPACES_CAN_MOVE_TO_WITH_ID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getSpaceScheduleSpacesCanMoveToWithIdRequestDefinitions = (definitionsArguments: {| requestArgs: getSpaceScheduleSpacesCanMoveToWithIdRequestSpacesArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getSpaceScheduleSpacesCanMoveToWithIdRequestSpacesArguments, payload?: Object |} => ({
  fnName: 'getSpaceScheduleSpacesCanMoveToWithIdRequest',
  basePath: '/spaces-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_GET_SPACE_SCHEDULE_SPACES_PROPERTIES_INFO_WITH_PROPERTYNAME_START = 'DATA_GET_SPACE_SCHEDULE_SPACES_PROPERTIES_INFO_WITH_PROPERTYNAME_START'
export const DATA_GET_SPACE_SCHEDULE_SPACES_PROPERTIES_INFO_WITH_PROPERTYNAME_SUCCESSFUL = 'DATA_GET_SPACE_SCHEDULE_SPACES_PROPERTIES_INFO_WITH_PROPERTYNAME_SUCCESSFUL'
export const DATA_GET_SPACE_SCHEDULE_SPACES_PROPERTIES_INFO_WITH_PROPERTYNAME_FAIL = 'DATA_GET_SPACE_SCHEDULE_SPACES_PROPERTIES_INFO_WITH_PROPERTYNAME_FAIL'

// Get short information text for a space property
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/spaces.yaml#/Spaces/get_space_schedule__estimateId__spaces__id__properties_info__propertyName_
export const getSpaceScheduleSpacesPropertiesInfoWithPropertyNameRequest = (
  requestArguments: getSpaceScheduleSpacesPropertiesInfoWithPropertyNameRequestSpacesArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_SPACE_SCHEDULE_SPACES_PROPERTIES_INFO_WITH_PROPERTYNAME_START',
      successful: 'DATA_GET_SPACE_SCHEDULE_SPACES_PROPERTIES_INFO_WITH_PROPERTYNAME_SUCCESSFUL',
      fail: 'DATA_GET_SPACE_SCHEDULE_SPACES_PROPERTIES_INFO_WITH_PROPERTYNAME_FAIL'
    },
    embeddedType: null,
    fnName: 'getSpaceScheduleSpacesPropertiesInfoWithPropertyNameRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/spaces-api`,
    method: 'GET',
    url: `/space-schedule/${options?.estimateId || store.app.calculation}/spaces/${requestArguments.path['id']}/properties/info/${requestArguments.path['propertyName']}`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'getSpaceScheduleSpacesPropertiesInfoWithPropertyNameRequest', '/spaces-api'),
    actions: {
      start: {
        type: DATA_GET_SPACE_SCHEDULE_SPACES_PROPERTIES_INFO_WITH_PROPERTYNAME_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_SPACE_SCHEDULE_SPACES_PROPERTIES_INFO_WITH_PROPERTYNAME_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_SPACE_SCHEDULE_SPACES_PROPERTIES_INFO_WITH_PROPERTYNAME_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getSpaceScheduleSpacesPropertiesInfoWithPropertyNameRequestDefinitions = (definitionsArguments: {| requestArgs: getSpaceScheduleSpacesPropertiesInfoWithPropertyNameRequestSpacesArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getSpaceScheduleSpacesPropertiesInfoWithPropertyNameRequestSpacesArguments, payload?: Object |} => ({
  fnName: 'getSpaceScheduleSpacesPropertiesInfoWithPropertyNameRequest',
  basePath: '/spaces-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_GET_SPACE_SCHEDULE_SPACES_PROPERTIES_WITH_ID_START = 'DATA_GET_SPACE_SCHEDULE_SPACES_PROPERTIES_WITH_ID_START'
export const DATA_GET_SPACE_SCHEDULE_SPACES_PROPERTIES_WITH_ID_SUCCESSFUL = 'DATA_GET_SPACE_SCHEDULE_SPACES_PROPERTIES_WITH_ID_SUCCESSFUL'
export const DATA_GET_SPACE_SCHEDULE_SPACES_PROPERTIES_WITH_ID_FAIL = 'DATA_GET_SPACE_SCHEDULE_SPACES_PROPERTIES_WITH_ID_FAIL'

// Returns all properties of a space
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/spaces.yaml#/Spaces/get_space_schedule__estimateId__spaces__id__properties
export const getSpaceScheduleSpacesPropertiesWithIdRequest = (
  requestArguments: getSpaceScheduleSpacesPropertiesWithIdRequestSpacesArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_SPACE_SCHEDULE_SPACES_PROPERTIES_WITH_ID_START',
      successful: 'DATA_GET_SPACE_SCHEDULE_SPACES_PROPERTIES_WITH_ID_SUCCESSFUL',
      fail: 'DATA_GET_SPACE_SCHEDULE_SPACES_PROPERTIES_WITH_ID_FAIL'
    },
    embeddedType: {
      properties: {
        schemaPath: '#/definitions/ValueProperty',
        schemaKey: 'ValueProperty'
      }
    },
    fnName: 'getSpaceScheduleSpacesPropertiesWithIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/spaces-api`,
    method: 'GET',
    url: `/space-schedule/${options?.estimateId || store.app.calculation}/spaces/${requestArguments.path['id']}/properties`,
    params: {
      spacesEstimateType: options?.spacesEstimateType || store.app.spacesEstimateType,
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      properties: {
        schemaPath: '#/definitions/ValueProperty',
        schemaKey: 'ValueProperty'
      }
    }, 'getSpaceScheduleSpacesPropertiesWithIdRequest', '/spaces-api'),
    actions: {
      start: {
        type: DATA_GET_SPACE_SCHEDULE_SPACES_PROPERTIES_WITH_ID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_SPACE_SCHEDULE_SPACES_PROPERTIES_WITH_ID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_SPACE_SCHEDULE_SPACES_PROPERTIES_WITH_ID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getSpaceScheduleSpacesPropertiesWithIdRequestDefinitions = (definitionsArguments: {| requestArgs: getSpaceScheduleSpacesPropertiesWithIdRequestSpacesArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getSpaceScheduleSpacesPropertiesWithIdRequestSpacesArguments, payload?: Object |} => ({
  fnName: 'getSpaceScheduleSpacesPropertiesWithIdRequest',
  basePath: '/spaces-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_PATCH_SPACE_SCHEDULE_SPACES_PROPERTIES_WITH_ID_START = 'DATA_PATCH_SPACE_SCHEDULE_SPACES_PROPERTIES_WITH_ID_START'
export const DATA_PATCH_SPACE_SCHEDULE_SPACES_PROPERTIES_WITH_ID_SUCCESSFUL = 'DATA_PATCH_SPACE_SCHEDULE_SPACES_PROPERTIES_WITH_ID_SUCCESSFUL'
export const DATA_PATCH_SPACE_SCHEDULE_SPACES_PROPERTIES_WITH_ID_FAIL = 'DATA_PATCH_SPACE_SCHEDULE_SPACES_PROPERTIES_WITH_ID_FAIL'

// Update space properties. Will return a queue location header if calculation is needed.
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/spaces.yaml#/Spaces/patch_space_schedule__estimateId__spaces__id__properties
export const patchSpaceScheduleSpacesPropertiesWithIdRequest = (
  requestArguments: patchSpaceScheduleSpacesPropertiesWithIdRequestSpacesArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_PATCH_SPACE_SCHEDULE_SPACES_PROPERTIES_WITH_ID_START',
      successful: 'DATA_PATCH_SPACE_SCHEDULE_SPACES_PROPERTIES_WITH_ID_SUCCESSFUL',
      fail: 'DATA_PATCH_SPACE_SCHEDULE_SPACES_PROPERTIES_WITH_ID_FAIL'
    },
    embeddedType: null,
    fnName: 'patchSpaceScheduleSpacesPropertiesWithIdRequest'
  }
  const config = {
    headers: { 'Content-Type': 'application/json' },
    baseURL: `${API_BASE_URL}/spaces-api`,
    method: 'PATCH',
    url: `/space-schedule/${options?.estimateId || store.app.calculation}/spaces/${requestArguments.path['id']}/properties`,
    params: {
      spacesEstimateType: options?.spacesEstimateType || store.app.spacesEstimateType,
      resetProperties: requestArguments.query?.['resetProperties'],
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: requestArguments.body
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'patchSpaceScheduleSpacesPropertiesWithIdRequest', '/spaces-api'),
    actions: {
      start: {
        type: DATA_PATCH_SPACE_SCHEDULE_SPACES_PROPERTIES_WITH_ID_START, payload, config, meta
      },
      successful: {
        type: DATA_PATCH_SPACE_SCHEDULE_SPACES_PROPERTIES_WITH_ID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_PATCH_SPACE_SCHEDULE_SPACES_PROPERTIES_WITH_ID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_PATCH_SPACE_SCHEDULE_SPACES_PROPERTIES_WITH_ESTIMATEID_START = 'DATA_PATCH_SPACE_SCHEDULE_SPACES_PROPERTIES_WITH_ESTIMATEID_START'
export const DATA_PATCH_SPACE_SCHEDULE_SPACES_PROPERTIES_WITH_ESTIMATEID_SUCCESSFUL = 'DATA_PATCH_SPACE_SCHEDULE_SPACES_PROPERTIES_WITH_ESTIMATEID_SUCCESSFUL'
export const DATA_PATCH_SPACE_SCHEDULE_SPACES_PROPERTIES_WITH_ESTIMATEID_FAIL = 'DATA_PATCH_SPACE_SCHEDULE_SPACES_PROPERTIES_WITH_ESTIMATEID_FAIL'

// Update properties of multiple spaces simultaneously.
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/spaces.yaml#/Spaces/patch_space_schedule__estimateId__spaces_properties
export const patchSpaceScheduleSpacesPropertiesWithEstimateIdRequest = (
  requestArguments: patchSpaceScheduleSpacesPropertiesWithEstimateIdRequestSpacesArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_PATCH_SPACE_SCHEDULE_SPACES_PROPERTIES_WITH_ESTIMATEID_START',
      successful: 'DATA_PATCH_SPACE_SCHEDULE_SPACES_PROPERTIES_WITH_ESTIMATEID_SUCCESSFUL',
      fail: 'DATA_PATCH_SPACE_SCHEDULE_SPACES_PROPERTIES_WITH_ESTIMATEID_FAIL'
    },
    embeddedType: null,
    fnName: 'patchSpaceScheduleSpacesPropertiesWithEstimateIdRequest'
  }
  const config = {
    headers: { 'Content-Type': 'application/json' },
    baseURL: `${API_BASE_URL}/spaces-api`,
    method: 'PATCH',
    url: `/space-schedule/${options?.estimateId || store.app.calculation}/spaces/properties`,
    params: {
      spacesEstimateType: options?.spacesEstimateType || store.app.spacesEstimateType,
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: requestArguments.body
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'patchSpaceScheduleSpacesPropertiesWithEstimateIdRequest', '/spaces-api'),
    actions: {
      start: {
        type: DATA_PATCH_SPACE_SCHEDULE_SPACES_PROPERTIES_WITH_ESTIMATEID_START, payload, config, meta
      },
      successful: {
        type: DATA_PATCH_SPACE_SCHEDULE_SPACES_PROPERTIES_WITH_ESTIMATEID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_PATCH_SPACE_SCHEDULE_SPACES_PROPERTIES_WITH_ESTIMATEID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_GET_SPACE_SCHEDULE_SPACES_VALUES_WITH_ID_START = 'DATA_GET_SPACE_SCHEDULE_SPACES_VALUES_WITH_ID_START'
export const DATA_GET_SPACE_SCHEDULE_SPACES_VALUES_WITH_ID_SUCCESSFUL = 'DATA_GET_SPACE_SCHEDULE_SPACES_VALUES_WITH_ID_SUCCESSFUL'
export const DATA_GET_SPACE_SCHEDULE_SPACES_VALUES_WITH_ID_FAIL = 'DATA_GET_SPACE_SCHEDULE_SPACES_VALUES_WITH_ID_FAIL'

// Returns space key figures
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/spaces.yaml#/Space%20Key%20Figures/get_space_schedule__estimateId__spaces__id__values
export const getSpaceScheduleSpacesValuesWithIdRequest = (
  requestArguments: getSpaceScheduleSpacesValuesWithIdRequestSpacesArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_SPACE_SCHEDULE_SPACES_VALUES_WITH_ID_START',
      successful: 'DATA_GET_SPACE_SCHEDULE_SPACES_VALUES_WITH_ID_SUCCESSFUL',
      fail: 'DATA_GET_SPACE_SCHEDULE_SPACES_VALUES_WITH_ID_FAIL'
    },
    embeddedType: {
      values: {
        schemaPath: '#/definitions/ValueProperty',
        schemaKey: 'ValueProperty'
      }
    },
    fnName: 'getSpaceScheduleSpacesValuesWithIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/spaces-api`,
    method: 'GET',
    url: `/space-schedule/${options?.estimateId || store.app.calculation}/spaces/${requestArguments.path['id']}/values`,
    params: {
      spacesEstimateType: options?.spacesEstimateType || store.app.spacesEstimateType,
      spacesResultView: options?.spacesResultView || store.app.spacesResultView,
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      values: {
        schemaPath: '#/definitions/ValueProperty',
        schemaKey: 'ValueProperty'
      }
    }, 'getSpaceScheduleSpacesValuesWithIdRequest', '/spaces-api'),
    actions: {
      start: {
        type: DATA_GET_SPACE_SCHEDULE_SPACES_VALUES_WITH_ID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_SPACE_SCHEDULE_SPACES_VALUES_WITH_ID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_SPACE_SCHEDULE_SPACES_VALUES_WITH_ID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getSpaceScheduleSpacesValuesWithIdRequestDefinitions = (definitionsArguments: {| requestArgs: getSpaceScheduleSpacesValuesWithIdRequestSpacesArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getSpaceScheduleSpacesValuesWithIdRequestSpacesArguments, payload?: Object |} => ({
  fnName: 'getSpaceScheduleSpacesValuesWithIdRequest',
  basePath: '/spaces-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_GET_BUILDING_ELEMENTS_SCHEDULE_WITH_ESTIMATEID_START = 'DATA_GET_BUILDING_ELEMENTS_SCHEDULE_WITH_ESTIMATEID_START'
export const DATA_GET_BUILDING_ELEMENTS_SCHEDULE_WITH_ESTIMATEID_SUCCESSFUL = 'DATA_GET_BUILDING_ELEMENTS_SCHEDULE_WITH_ESTIMATEID_SUCCESSFUL'
export const DATA_GET_BUILDING_ELEMENTS_SCHEDULE_WITH_ESTIMATEID_FAIL = 'DATA_GET_BUILDING_ELEMENTS_SCHEDULE_WITH_ESTIMATEID_FAIL'

// Returns a Spaces view of building elements schedule, root or full list depending on listType parameter
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/spaces.yaml#/Building%20Elements%20Schedule/get_building_elements_schedule__estimateId_
export const getBuildingElementsScheduleWithEstimateIdRequest = (
  requestArguments: getBuildingElementsScheduleWithEstimateIdRequestSpacesArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_BUILDING_ELEMENTS_SCHEDULE_WITH_ESTIMATEID_START',
      successful: 'DATA_GET_BUILDING_ELEMENTS_SCHEDULE_WITH_ESTIMATEID_SUCCESSFUL',
      fail: 'DATA_GET_BUILDING_ELEMENTS_SCHEDULE_WITH_ESTIMATEID_FAIL'
    },
    embeddedType: {
      items: {
        schemaPath: '#/definitions/ScheduleItem',
        schemaKey: 'ScheduleItem'
      }
    },
    fnName: 'getBuildingElementsScheduleWithEstimateIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/spaces-api`,
    method: 'GET',
    url: `/building-elements-schedule/${options?.estimateId || store.app.calculation}`,
    params: {
      spacesEstimateType: options?.spacesEstimateType || store.app.spacesEstimateType,
      spacesResultView: options?.spacesResultView || store.app.spacesResultView,
      listType: requestArguments.query['listType'],
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      items: {
        schemaPath: '#/definitions/ScheduleItem',
        schemaKey: 'ScheduleItem'
      }
    }, 'getBuildingElementsScheduleWithEstimateIdRequest', '/spaces-api'),
    actions: {
      start: {
        type: DATA_GET_BUILDING_ELEMENTS_SCHEDULE_WITH_ESTIMATEID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_BUILDING_ELEMENTS_SCHEDULE_WITH_ESTIMATEID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_BUILDING_ELEMENTS_SCHEDULE_WITH_ESTIMATEID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getBuildingElementsScheduleWithEstimateIdRequestDefinitions = (definitionsArguments: {| requestArgs: getBuildingElementsScheduleWithEstimateIdRequestSpacesArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getBuildingElementsScheduleWithEstimateIdRequestSpacesArguments, payload?: Object |} => ({
  fnName: 'getBuildingElementsScheduleWithEstimateIdRequest',
  basePath: '/spaces-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_GET_BUILDING_ELEMENTS_SCHEDULE_COLUMNS_WITH_ESTIMATEID_START = 'DATA_GET_BUILDING_ELEMENTS_SCHEDULE_COLUMNS_WITH_ESTIMATEID_START'
export const DATA_GET_BUILDING_ELEMENTS_SCHEDULE_COLUMNS_WITH_ESTIMATEID_SUCCESSFUL = 'DATA_GET_BUILDING_ELEMENTS_SCHEDULE_COLUMNS_WITH_ESTIMATEID_SUCCESSFUL'
export const DATA_GET_BUILDING_ELEMENTS_SCHEDULE_COLUMNS_WITH_ESTIMATEID_FAIL = 'DATA_GET_BUILDING_ELEMENTS_SCHEDULE_COLUMNS_WITH_ESTIMATEID_FAIL'

// Returns columns metadata for the building elements schedule
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/spaces.yaml#/Building%20Elements%20Schedule/get_building_elements_schedule__estimateId__columns
export const getBuildingElementsScheduleColumnsWithEstimateIdRequest = (
  payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_BUILDING_ELEMENTS_SCHEDULE_COLUMNS_WITH_ESTIMATEID_START',
      successful: 'DATA_GET_BUILDING_ELEMENTS_SCHEDULE_COLUMNS_WITH_ESTIMATEID_SUCCESSFUL',
      fail: 'DATA_GET_BUILDING_ELEMENTS_SCHEDULE_COLUMNS_WITH_ESTIMATEID_FAIL'
    },
    embeddedType: {
      properties: {
        schemaPath: '#/definitions/ValueProperty',
        schemaKey: 'ValueProperty'
      }
    },
    fnName: 'getBuildingElementsScheduleColumnsWithEstimateIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/spaces-api`,
    method: 'GET',
    url: `/building-elements-schedule/${options?.estimateId || store.app.calculation}/columns`,
    params: {
      spacesEstimateType: options?.spacesEstimateType || store.app.spacesEstimateType,
      spacesResultView: options?.spacesResultView || store.app.spacesResultView,
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      properties: {
        schemaPath: '#/definitions/ValueProperty',
        schemaKey: 'ValueProperty'
      }
    }, 'getBuildingElementsScheduleColumnsWithEstimateIdRequest', '/spaces-api'),
    actions: {
      start: {
        type: DATA_GET_BUILDING_ELEMENTS_SCHEDULE_COLUMNS_WITH_ESTIMATEID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_BUILDING_ELEMENTS_SCHEDULE_COLUMNS_WITH_ESTIMATEID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_BUILDING_ELEMENTS_SCHEDULE_COLUMNS_WITH_ESTIMATEID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getBuildingElementsScheduleColumnsWithEstimateIdRequestDefinitions = (definitionsArguments: {| payload?: Object |}): {|
  ...TVDGARConfigWithoutRequestArgs, payload?: Object |} => ({
  fnName: 'getBuildingElementsScheduleColumnsWithEstimateIdRequest',
  basePath: '/spaces-api',
  payload: definitionsArguments.payload,
})


export const DATA_GET_BUILDING_ELEMENTS_SCHEDULE_VALUES_WITH_ESTIMATEID_START = 'DATA_GET_BUILDING_ELEMENTS_SCHEDULE_VALUES_WITH_ESTIMATEID_START'
export const DATA_GET_BUILDING_ELEMENTS_SCHEDULE_VALUES_WITH_ESTIMATEID_SUCCESSFUL = 'DATA_GET_BUILDING_ELEMENTS_SCHEDULE_VALUES_WITH_ESTIMATEID_SUCCESSFUL'
export const DATA_GET_BUILDING_ELEMENTS_SCHEDULE_VALUES_WITH_ESTIMATEID_FAIL = 'DATA_GET_BUILDING_ELEMENTS_SCHEDULE_VALUES_WITH_ESTIMATEID_FAIL'

// Returns values calculated for building elements schedule
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/spaces.yaml#/Building%20Elements%20Schedule/get_building_elements_schedule__estimateId__values
export const getBuildingElementsScheduleValuesWithEstimateIdRequest = (
  payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_BUILDING_ELEMENTS_SCHEDULE_VALUES_WITH_ESTIMATEID_START',
      successful: 'DATA_GET_BUILDING_ELEMENTS_SCHEDULE_VALUES_WITH_ESTIMATEID_SUCCESSFUL',
      fail: 'DATA_GET_BUILDING_ELEMENTS_SCHEDULE_VALUES_WITH_ESTIMATEID_FAIL'
    },
    embeddedType: {
      values: {
        schemaPath: '#/definitions/ValueProperty',
        schemaKey: 'ValueProperty'
      }
    },
    fnName: 'getBuildingElementsScheduleValuesWithEstimateIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/spaces-api`,
    method: 'GET',
    url: `/building-elements-schedule/${options?.estimateId || store.app.calculation}/values`,
    params: {
      spacesEstimateType: options?.spacesEstimateType || store.app.spacesEstimateType,
      spacesResultView: options?.spacesResultView || store.app.spacesResultView,
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      values: {
        schemaPath: '#/definitions/ValueProperty',
        schemaKey: 'ValueProperty'
      }
    }, 'getBuildingElementsScheduleValuesWithEstimateIdRequest', '/spaces-api'),
    actions: {
      start: {
        type: DATA_GET_BUILDING_ELEMENTS_SCHEDULE_VALUES_WITH_ESTIMATEID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_BUILDING_ELEMENTS_SCHEDULE_VALUES_WITH_ESTIMATEID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_BUILDING_ELEMENTS_SCHEDULE_VALUES_WITH_ESTIMATEID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getBuildingElementsScheduleValuesWithEstimateIdRequestDefinitions = (definitionsArguments: {| payload?: Object |}): {|
  ...TVDGARConfigWithoutRequestArgs, payload?: Object |} => ({
  fnName: 'getBuildingElementsScheduleValuesWithEstimateIdRequest',
  basePath: '/spaces-api',
  payload: definitionsArguments.payload,
})


export const DATA_GET_BUILDING_ELEMENTS_SCHEDULE_ITEM_WITH_ITEMID_START = 'DATA_GET_BUILDING_ELEMENTS_SCHEDULE_ITEM_WITH_ITEMID_START'
export const DATA_GET_BUILDING_ELEMENTS_SCHEDULE_ITEM_WITH_ITEMID_SUCCESSFUL = 'DATA_GET_BUILDING_ELEMENTS_SCHEDULE_ITEM_WITH_ITEMID_SUCCESSFUL'
export const DATA_GET_BUILDING_ELEMENTS_SCHEDULE_ITEM_WITH_ITEMID_FAIL = 'DATA_GET_BUILDING_ELEMENTS_SCHEDULE_ITEM_WITH_ITEMID_FAIL'

// Returns a building element schedule item
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/spaces.yaml#/Building%20Elements%20Schedule/get_building_elements_schedule__estimateId__item__itemId_
export const getBuildingElementsScheduleItemWithItemIdRequest = (
  requestArguments: getBuildingElementsScheduleItemWithItemIdRequestSpacesArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_BUILDING_ELEMENTS_SCHEDULE_ITEM_WITH_ITEMID_START',
      successful: 'DATA_GET_BUILDING_ELEMENTS_SCHEDULE_ITEM_WITH_ITEMID_SUCCESSFUL',
      fail: 'DATA_GET_BUILDING_ELEMENTS_SCHEDULE_ITEM_WITH_ITEMID_FAIL'
    },
    embeddedType: {
      items: {
        schemaPath: '#/definitions/ScheduleItem',
        schemaKey: 'ScheduleItem'
      }
    },
    fnName: 'getBuildingElementsScheduleItemWithItemIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/spaces-api`,
    method: 'GET',
    url: `/building-elements-schedule/${options?.estimateId || store.app.calculation}/item/${requestArguments.path['itemId']}`,
    params: {
      spacesEstimateType: options?.spacesEstimateType || store.app.spacesEstimateType,
      spacesResultView: options?.spacesResultView || store.app.spacesResultView,
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      items: {
        schemaPath: '#/definitions/ScheduleItem',
        schemaKey: 'ScheduleItem'
      }
    }, 'getBuildingElementsScheduleItemWithItemIdRequest', '/spaces-api'),
    actions: {
      start: {
        type: DATA_GET_BUILDING_ELEMENTS_SCHEDULE_ITEM_WITH_ITEMID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_BUILDING_ELEMENTS_SCHEDULE_ITEM_WITH_ITEMID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_BUILDING_ELEMENTS_SCHEDULE_ITEM_WITH_ITEMID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getBuildingElementsScheduleItemWithItemIdRequestDefinitions = (definitionsArguments: {| requestArgs: getBuildingElementsScheduleItemWithItemIdRequestSpacesArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getBuildingElementsScheduleItemWithItemIdRequestSpacesArguments, payload?: Object |} => ({
  fnName: 'getBuildingElementsScheduleItemWithItemIdRequest',
  basePath: '/spaces-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_PATCH_BUILDING_ELEMENTS_SCHEDULE_ITEM_WITH_ITEMID_START = 'DATA_PATCH_BUILDING_ELEMENTS_SCHEDULE_ITEM_WITH_ITEMID_START'
export const DATA_PATCH_BUILDING_ELEMENTS_SCHEDULE_ITEM_WITH_ITEMID_SUCCESSFUL = 'DATA_PATCH_BUILDING_ELEMENTS_SCHEDULE_ITEM_WITH_ITEMID_SUCCESSFUL'
export const DATA_PATCH_BUILDING_ELEMENTS_SCHEDULE_ITEM_WITH_ITEMID_FAIL = 'DATA_PATCH_BUILDING_ELEMENTS_SCHEDULE_ITEM_WITH_ITEMID_FAIL'

// Update item Change column value
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/spaces.yaml#/Building%20Elements%20Schedule/patch_building_elements_schedule__estimateId__item__itemId_
export const patchBuildingElementsScheduleItemWithItemIdRequest = (
  requestArguments: patchBuildingElementsScheduleItemWithItemIdRequestSpacesArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_PATCH_BUILDING_ELEMENTS_SCHEDULE_ITEM_WITH_ITEMID_START',
      successful: 'DATA_PATCH_BUILDING_ELEMENTS_SCHEDULE_ITEM_WITH_ITEMID_SUCCESSFUL',
      fail: 'DATA_PATCH_BUILDING_ELEMENTS_SCHEDULE_ITEM_WITH_ITEMID_FAIL'
    },
    embeddedType: null,
    fnName: 'patchBuildingElementsScheduleItemWithItemIdRequest'
  }
  const config = {
    headers: { 'Content-Type': 'application/json' },
    baseURL: `${API_BASE_URL}/spaces-api`,
    method: 'PATCH',
    url: `/building-elements-schedule/${options?.estimateId || store.app.calculation}/item/${requestArguments.path['itemId']}`,
    params: {
      spacesEstimateType: options?.spacesEstimateType || store.app.spacesEstimateType,
      spacesResultView: options?.spacesResultView || store.app.spacesResultView,
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: requestArguments.body
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'patchBuildingElementsScheduleItemWithItemIdRequest', '/spaces-api'),
    actions: {
      start: {
        type: DATA_PATCH_BUILDING_ELEMENTS_SCHEDULE_ITEM_WITH_ITEMID_START, payload, config, meta
      },
      successful: {
        type: DATA_PATCH_BUILDING_ELEMENTS_SCHEDULE_ITEM_WITH_ITEMID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_PATCH_BUILDING_ELEMENTS_SCHEDULE_ITEM_WITH_ITEMID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_GET_BUILDING_ELEMENTS_SCHEDULE_ITEM_ITEMS_WITH_ITEMID_START = 'DATA_GET_BUILDING_ELEMENTS_SCHEDULE_ITEM_ITEMS_WITH_ITEMID_START'
export const DATA_GET_BUILDING_ELEMENTS_SCHEDULE_ITEM_ITEMS_WITH_ITEMID_SUCCESSFUL = 'DATA_GET_BUILDING_ELEMENTS_SCHEDULE_ITEM_ITEMS_WITH_ITEMID_SUCCESSFUL'
export const DATA_GET_BUILDING_ELEMENTS_SCHEDULE_ITEM_ITEMS_WITH_ITEMID_FAIL = 'DATA_GET_BUILDING_ELEMENTS_SCHEDULE_ITEM_ITEMS_WITH_ITEMID_FAIL'

// Returns schedule items under a parent item
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/spaces.yaml#/Building%20Elements%20Schedule/get_building_elements_schedule__estimateId__item__itemId__items
export const getBuildingElementsScheduleItemItemsWithItemIdRequest = (
  requestArguments: getBuildingElementsScheduleItemItemsWithItemIdRequestSpacesArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_BUILDING_ELEMENTS_SCHEDULE_ITEM_ITEMS_WITH_ITEMID_START',
      successful: 'DATA_GET_BUILDING_ELEMENTS_SCHEDULE_ITEM_ITEMS_WITH_ITEMID_SUCCESSFUL',
      fail: 'DATA_GET_BUILDING_ELEMENTS_SCHEDULE_ITEM_ITEMS_WITH_ITEMID_FAIL'
    },
    embeddedType: {
      items: {
        schemaPath: '#/definitions/ScheduleItem',
        schemaKey: 'ScheduleItem'
      }
    },
    fnName: 'getBuildingElementsScheduleItemItemsWithItemIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/spaces-api`,
    method: 'GET',
    url: `/building-elements-schedule/${options?.estimateId || store.app.calculation}/item/${requestArguments.path['itemId']}/items`,
    params: {
      spacesEstimateType: options?.spacesEstimateType || store.app.spacesEstimateType,
      spacesResultView: options?.spacesResultView || store.app.spacesResultView,
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      items: {
        schemaPath: '#/definitions/ScheduleItem',
        schemaKey: 'ScheduleItem'
      }
    }, 'getBuildingElementsScheduleItemItemsWithItemIdRequest', '/spaces-api'),
    actions: {
      start: {
        type: DATA_GET_BUILDING_ELEMENTS_SCHEDULE_ITEM_ITEMS_WITH_ITEMID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_BUILDING_ELEMENTS_SCHEDULE_ITEM_ITEMS_WITH_ITEMID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_BUILDING_ELEMENTS_SCHEDULE_ITEM_ITEMS_WITH_ITEMID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getBuildingElementsScheduleItemItemsWithItemIdRequestDefinitions = (definitionsArguments: {| requestArgs: getBuildingElementsScheduleItemItemsWithItemIdRequestSpacesArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getBuildingElementsScheduleItemItemsWithItemIdRequestSpacesArguments, payload?: Object |} => ({
  fnName: 'getBuildingElementsScheduleItemItemsWithItemIdRequest',
  basePath: '/spaces-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_GET_BUILDING_ELEMENTS_SCHEDULE_ITEM_INFO_WITH_ITEMID_START = 'DATA_GET_BUILDING_ELEMENTS_SCHEDULE_ITEM_INFO_WITH_ITEMID_START'
export const DATA_GET_BUILDING_ELEMENTS_SCHEDULE_ITEM_INFO_WITH_ITEMID_SUCCESSFUL = 'DATA_GET_BUILDING_ELEMENTS_SCHEDULE_ITEM_INFO_WITH_ITEMID_SUCCESSFUL'
export const DATA_GET_BUILDING_ELEMENTS_SCHEDULE_ITEM_INFO_WITH_ITEMID_FAIL = 'DATA_GET_BUILDING_ELEMENTS_SCHEDULE_ITEM_INFO_WITH_ITEMID_FAIL'

// Returns information text about a schedule item
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/spaces.yaml#/Building%20Elements%20Schedule/get_building_elements_schedule__estimateId__item__itemId__info
export const getBuildingElementsScheduleItemInfoWithItemIdRequest = (
  requestArguments: getBuildingElementsScheduleItemInfoWithItemIdRequestSpacesArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_BUILDING_ELEMENTS_SCHEDULE_ITEM_INFO_WITH_ITEMID_START',
      successful: 'DATA_GET_BUILDING_ELEMENTS_SCHEDULE_ITEM_INFO_WITH_ITEMID_SUCCESSFUL',
      fail: 'DATA_GET_BUILDING_ELEMENTS_SCHEDULE_ITEM_INFO_WITH_ITEMID_FAIL'
    },
    embeddedType: null,
    fnName: 'getBuildingElementsScheduleItemInfoWithItemIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/spaces-api`,
    method: 'GET',
    url: `/building-elements-schedule/${options?.estimateId || store.app.calculation}/item/${requestArguments.path['itemId']}/info`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'getBuildingElementsScheduleItemInfoWithItemIdRequest', '/spaces-api'),
    actions: {
      start: {
        type: DATA_GET_BUILDING_ELEMENTS_SCHEDULE_ITEM_INFO_WITH_ITEMID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_BUILDING_ELEMENTS_SCHEDULE_ITEM_INFO_WITH_ITEMID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_BUILDING_ELEMENTS_SCHEDULE_ITEM_INFO_WITH_ITEMID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getBuildingElementsScheduleItemInfoWithItemIdRequestDefinitions = (definitionsArguments: {| requestArgs: getBuildingElementsScheduleItemInfoWithItemIdRequestSpacesArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getBuildingElementsScheduleItemInfoWithItemIdRequestSpacesArguments, payload?: Object |} => ({
  fnName: 'getBuildingElementsScheduleItemInfoWithItemIdRequest',
  basePath: '/spaces-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_PUT_COMMON_FUNCTIONS_WITH_ESTIMATEID_START = 'DATA_PUT_COMMON_FUNCTIONS_WITH_ESTIMATEID_START'
export const DATA_PUT_COMMON_FUNCTIONS_WITH_ESTIMATEID_SUCCESSFUL = 'DATA_PUT_COMMON_FUNCTIONS_WITH_ESTIMATEID_SUCCESSFUL'
export const DATA_PUT_COMMON_FUNCTIONS_WITH_ESTIMATEID_FAIL = 'DATA_PUT_COMMON_FUNCTIONS_WITH_ESTIMATEID_FAIL'

// Creates all common functions with default selections
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/spaces.yaml#/Common%20Functions/put_common_functions__estimateId_
export const putCommonFunctionsWithEstimateIdRequest = (
  requestArguments: putCommonFunctionsWithEstimateIdRequestSpacesArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_PUT_COMMON_FUNCTIONS_WITH_ESTIMATEID_START',
      successful: 'DATA_PUT_COMMON_FUNCTIONS_WITH_ESTIMATEID_SUCCESSFUL',
      fail: 'DATA_PUT_COMMON_FUNCTIONS_WITH_ESTIMATEID_FAIL'
    },
    embeddedType: null,
    fnName: 'putCommonFunctionsWithEstimateIdRequest'
  }
  const config = {
    headers: { 'Content-Type': 'application/json' },
    baseURL: `${API_BASE_URL}/spaces-api`,
    method: 'PUT',
    url: `/common-functions/${options?.estimateId || store.app.calculation}`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: requestArguments.body
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'putCommonFunctionsWithEstimateIdRequest', '/spaces-api'),
    actions: {
      start: {
        type: DATA_PUT_COMMON_FUNCTIONS_WITH_ESTIMATEID_START, payload, config, meta
      },
      successful: {
        type: DATA_PUT_COMMON_FUNCTIONS_WITH_ESTIMATEID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_PUT_COMMON_FUNCTIONS_WITH_ESTIMATEID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_GET_COMMON_FUNCTIONS_WITH_FUNCTIONTYPE_START = 'DATA_GET_COMMON_FUNCTIONS_WITH_FUNCTIONTYPE_START'
export const DATA_GET_COMMON_FUNCTIONS_WITH_FUNCTIONTYPE_SUCCESSFUL = 'DATA_GET_COMMON_FUNCTIONS_WITH_FUNCTIONTYPE_SUCCESSFUL'
export const DATA_GET_COMMON_FUNCTIONS_WITH_FUNCTIONTYPE_FAIL = 'DATA_GET_COMMON_FUNCTIONS_WITH_FUNCTIONTYPE_FAIL'

// Returns a list for creating common functions such as building services or circulation
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/spaces.yaml#/Common%20Functions/get_common_functions__estimateId___functionType_
export const getCommonFunctionsWithFunctionTypeRequest = (
  requestArguments: getCommonFunctionsWithFunctionTypeRequestSpacesArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_COMMON_FUNCTIONS_WITH_FUNCTIONTYPE_START',
      successful: 'DATA_GET_COMMON_FUNCTIONS_WITH_FUNCTIONTYPE_SUCCESSFUL',
      fail: 'DATA_GET_COMMON_FUNCTIONS_WITH_FUNCTIONTYPE_FAIL'
    },
    embeddedType: {
      items: {
        schemaPath: '#/definitions/ScheduleItem',
        schemaKey: 'ScheduleItem'
      }
    },
    fnName: 'getCommonFunctionsWithFunctionTypeRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/spaces-api`,
    method: 'GET',
    url: `/common-functions/${options?.estimateId || store.app.calculation}/${requestArguments.path['functionType']}`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      items: {
        schemaPath: '#/definitions/ScheduleItem',
        schemaKey: 'ScheduleItem'
      }
    }, 'getCommonFunctionsWithFunctionTypeRequest', '/spaces-api'),
    actions: {
      start: {
        type: DATA_GET_COMMON_FUNCTIONS_WITH_FUNCTIONTYPE_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_COMMON_FUNCTIONS_WITH_FUNCTIONTYPE_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_COMMON_FUNCTIONS_WITH_FUNCTIONTYPE_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getCommonFunctionsWithFunctionTypeRequestDefinitions = (definitionsArguments: {| requestArgs: getCommonFunctionsWithFunctionTypeRequestSpacesArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getCommonFunctionsWithFunctionTypeRequestSpacesArguments, payload?: Object |} => ({
  fnName: 'getCommonFunctionsWithFunctionTypeRequest',
  basePath: '/spaces-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_POST_COMMON_FUNCTIONS_WITH_FUNCTIONTYPE_START = 'DATA_POST_COMMON_FUNCTIONS_WITH_FUNCTIONTYPE_START'
export const DATA_POST_COMMON_FUNCTIONS_WITH_FUNCTIONTYPE_SUCCESSFUL = 'DATA_POST_COMMON_FUNCTIONS_WITH_FUNCTIONTYPE_SUCCESSFUL'
export const DATA_POST_COMMON_FUNCTIONS_WITH_FUNCTIONTYPE_FAIL = 'DATA_POST_COMMON_FUNCTIONS_WITH_FUNCTIONTYPE_FAIL'

// Post selected reference ids to add common function spaces to space schedule
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/spaces.yaml#/Common%20Functions/post_common_functions__estimateId___functionType_
export const postCommonFunctionsWithFunctionTypeRequest = (
  requestArguments: postCommonFunctionsWithFunctionTypeRequestSpacesArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_POST_COMMON_FUNCTIONS_WITH_FUNCTIONTYPE_START',
      successful: 'DATA_POST_COMMON_FUNCTIONS_WITH_FUNCTIONTYPE_SUCCESSFUL',
      fail: 'DATA_POST_COMMON_FUNCTIONS_WITH_FUNCTIONTYPE_FAIL'
    },
    embeddedType: null,
    fnName: 'postCommonFunctionsWithFunctionTypeRequest'
  }
  const config = {
    headers: { 'Content-Type': 'application/json' },
    baseURL: `${API_BASE_URL}/spaces-api`,
    method: 'POST',
    url: `/common-functions/${options?.estimateId || store.app.calculation}/${requestArguments.path['functionType']}`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: requestArguments.body
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'postCommonFunctionsWithFunctionTypeRequest', '/spaces-api'),
    actions: {
      start: {
        type: DATA_POST_COMMON_FUNCTIONS_WITH_FUNCTIONTYPE_START, payload, config, meta
      },
      successful: {
        type: DATA_POST_COMMON_FUNCTIONS_WITH_FUNCTIONTYPE_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_POST_COMMON_FUNCTIONS_WITH_FUNCTIONTYPE_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_PUT_COMMON_FUNCTIONS_WITH_FUNCTIONTYPE_START = 'DATA_PUT_COMMON_FUNCTIONS_WITH_FUNCTIONTYPE_START'
export const DATA_PUT_COMMON_FUNCTIONS_WITH_FUNCTIONTYPE_SUCCESSFUL = 'DATA_PUT_COMMON_FUNCTIONS_WITH_FUNCTIONTYPE_SUCCESSFUL'
export const DATA_PUT_COMMON_FUNCTIONS_WITH_FUNCTIONTYPE_FAIL = 'DATA_PUT_COMMON_FUNCTIONS_WITH_FUNCTIONTYPE_FAIL'

// Put selected reference ids to replace common function spaces in space schedule
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/spaces.yaml#/Common%20Functions/put_common_functions__estimateId___functionType_
export const putCommonFunctionsWithFunctionTypeRequest = (
  requestArguments: putCommonFunctionsWithFunctionTypeRequestSpacesArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_PUT_COMMON_FUNCTIONS_WITH_FUNCTIONTYPE_START',
      successful: 'DATA_PUT_COMMON_FUNCTIONS_WITH_FUNCTIONTYPE_SUCCESSFUL',
      fail: 'DATA_PUT_COMMON_FUNCTIONS_WITH_FUNCTIONTYPE_FAIL'
    },
    embeddedType: null,
    fnName: 'putCommonFunctionsWithFunctionTypeRequest'
  }
  const config = {
    headers: { 'Content-Type': 'application/json' },
    baseURL: `${API_BASE_URL}/spaces-api`,
    method: 'PUT',
    url: `/common-functions/${options?.estimateId || store.app.calculation}/${requestArguments.path['functionType']}`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: requestArguments.body
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'putCommonFunctionsWithFunctionTypeRequest', '/spaces-api'),
    actions: {
      start: {
        type: DATA_PUT_COMMON_FUNCTIONS_WITH_FUNCTIONTYPE_START, payload, config, meta
      },
      successful: {
        type: DATA_PUT_COMMON_FUNCTIONS_WITH_FUNCTIONTYPE_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_PUT_COMMON_FUNCTIONS_WITH_FUNCTIONTYPE_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_GET_COMMON_FUNCTIONS_COLUMNS_WITH_FUNCTIONTYPE_START = 'DATA_GET_COMMON_FUNCTIONS_COLUMNS_WITH_FUNCTIONTYPE_START'
export const DATA_GET_COMMON_FUNCTIONS_COLUMNS_WITH_FUNCTIONTYPE_SUCCESSFUL = 'DATA_GET_COMMON_FUNCTIONS_COLUMNS_WITH_FUNCTIONTYPE_SUCCESSFUL'
export const DATA_GET_COMMON_FUNCTIONS_COLUMNS_WITH_FUNCTIONTYPE_FAIL = 'DATA_GET_COMMON_FUNCTIONS_COLUMNS_WITH_FUNCTIONTYPE_FAIL'

// Get columns for common functions list
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/spaces.yaml#/Common%20Functions/get_common_functions__estimateId___functionType__columns
export const getCommonFunctionsColumnsWithFunctionTypeRequest = (
  requestArguments: getCommonFunctionsColumnsWithFunctionTypeRequestSpacesArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_COMMON_FUNCTIONS_COLUMNS_WITH_FUNCTIONTYPE_START',
      successful: 'DATA_GET_COMMON_FUNCTIONS_COLUMNS_WITH_FUNCTIONTYPE_SUCCESSFUL',
      fail: 'DATA_GET_COMMON_FUNCTIONS_COLUMNS_WITH_FUNCTIONTYPE_FAIL'
    },
    embeddedType: {
      properties: {
        schemaPath: '#/definitions/ValueProperty',
        schemaKey: 'ValueProperty'
      }
    },
    fnName: 'getCommonFunctionsColumnsWithFunctionTypeRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/spaces-api`,
    method: 'GET',
    url: `/common-functions/${options?.estimateId || store.app.calculation}/${requestArguments.path['functionType']}/columns`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      properties: {
        schemaPath: '#/definitions/ValueProperty',
        schemaKey: 'ValueProperty'
      }
    }, 'getCommonFunctionsColumnsWithFunctionTypeRequest', '/spaces-api'),
    actions: {
      start: {
        type: DATA_GET_COMMON_FUNCTIONS_COLUMNS_WITH_FUNCTIONTYPE_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_COMMON_FUNCTIONS_COLUMNS_WITH_FUNCTIONTYPE_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_COMMON_FUNCTIONS_COLUMNS_WITH_FUNCTIONTYPE_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getCommonFunctionsColumnsWithFunctionTypeRequestDefinitions = (definitionsArguments: {| requestArgs: getCommonFunctionsColumnsWithFunctionTypeRequestSpacesArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getCommonFunctionsColumnsWithFunctionTypeRequestSpacesArguments, payload?: Object |} => ({
  fnName: 'getCommonFunctionsColumnsWithFunctionTypeRequest',
  basePath: '/spaces-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_GET_DESIGN_MODEL_WITH_ESTIMATEID_START = 'DATA_GET_DESIGN_MODEL_WITH_ESTIMATEID_START'
export const DATA_GET_DESIGN_MODEL_WITH_ESTIMATEID_SUCCESSFUL = 'DATA_GET_DESIGN_MODEL_WITH_ESTIMATEID_SUCCESSFUL'
export const DATA_GET_DESIGN_MODEL_WITH_ESTIMATEID_FAIL = 'DATA_GET_DESIGN_MODEL_WITH_ESTIMATEID_FAIL'

// Returns design model properties for an estimate
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/spaces.yaml#/Design%20Model/get_design_model__estimateId_
export const getDesignModelWithEstimateIdRequest = (
  payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_DESIGN_MODEL_WITH_ESTIMATEID_START',
      successful: 'DATA_GET_DESIGN_MODEL_WITH_ESTIMATEID_SUCCESSFUL',
      fail: 'DATA_GET_DESIGN_MODEL_WITH_ESTIMATEID_FAIL'
    },
    embeddedType: {
      properties: {
        schemaPath: '#/definitions/ValueProperty',
        schemaKey: 'ValueProperty'
      }
    },
    fnName: 'getDesignModelWithEstimateIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/spaces-api`,
    method: 'GET',
    url: `/design-model/${options?.estimateId || store.app.calculation}`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      properties: {
        schemaPath: '#/definitions/ValueProperty',
        schemaKey: 'ValueProperty'
      }
    }, 'getDesignModelWithEstimateIdRequest', '/spaces-api'),
    actions: {
      start: {
        type: DATA_GET_DESIGN_MODEL_WITH_ESTIMATEID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_DESIGN_MODEL_WITH_ESTIMATEID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_DESIGN_MODEL_WITH_ESTIMATEID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getDesignModelWithEstimateIdRequestDefinitions = (definitionsArguments: {| payload?: Object |}): {|
  ...TVDGARConfigWithoutRequestArgs, payload?: Object |} => ({
  fnName: 'getDesignModelWithEstimateIdRequest',
  basePath: '/spaces-api',
  payload: definitionsArguments.payload,
})


export const DATA_PATCH_DESIGN_MODEL_WITH_ESTIMATEID_START = 'DATA_PATCH_DESIGN_MODEL_WITH_ESTIMATEID_START'
export const DATA_PATCH_DESIGN_MODEL_WITH_ESTIMATEID_SUCCESSFUL = 'DATA_PATCH_DESIGN_MODEL_WITH_ESTIMATEID_SUCCESSFUL'
export const DATA_PATCH_DESIGN_MODEL_WITH_ESTIMATEID_FAIL = 'DATA_PATCH_DESIGN_MODEL_WITH_ESTIMATEID_FAIL'

// Update design model properties
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/spaces.yaml#/Design%20Model/patch_design_model__estimateId_
export const patchDesignModelWithEstimateIdRequest = (
  requestArguments: patchDesignModelWithEstimateIdRequestSpacesArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_PATCH_DESIGN_MODEL_WITH_ESTIMATEID_START',
      successful: 'DATA_PATCH_DESIGN_MODEL_WITH_ESTIMATEID_SUCCESSFUL',
      fail: 'DATA_PATCH_DESIGN_MODEL_WITH_ESTIMATEID_FAIL'
    },
    embeddedType: null,
    fnName: 'patchDesignModelWithEstimateIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/spaces-api`,
    method: 'PATCH',
    url: `/design-model/${options?.estimateId || store.app.calculation}`,
    params: {
      updateProtectedCirculation: requestArguments.query?.['updateProtectedCirculation'],
      updateStructures: requestArguments.query?.['updateStructures'],
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: requestArguments.body
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'patchDesignModelWithEstimateIdRequest', '/spaces-api'),
    actions: {
      start: {
        type: DATA_PATCH_DESIGN_MODEL_WITH_ESTIMATEID_START, payload, config, meta
      },
      successful: {
        type: DATA_PATCH_DESIGN_MODEL_WITH_ESTIMATEID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_PATCH_DESIGN_MODEL_WITH_ESTIMATEID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_GET_DESIGN_MODEL_STORIES_WITH_ESTIMATEID_START = 'DATA_GET_DESIGN_MODEL_STORIES_WITH_ESTIMATEID_START'
export const DATA_GET_DESIGN_MODEL_STORIES_WITH_ESTIMATEID_SUCCESSFUL = 'DATA_GET_DESIGN_MODEL_STORIES_WITH_ESTIMATEID_SUCCESSFUL'
export const DATA_GET_DESIGN_MODEL_STORIES_WITH_ESTIMATEID_FAIL = 'DATA_GET_DESIGN_MODEL_STORIES_WITH_ESTIMATEID_FAIL'

// Returns stories of a design model
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/spaces.yaml#/Design%20Model/get_design_model__estimateId__stories
export const getDesignModelStoriesWithEstimateIdRequest = (
  payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_DESIGN_MODEL_STORIES_WITH_ESTIMATEID_START',
      successful: 'DATA_GET_DESIGN_MODEL_STORIES_WITH_ESTIMATEID_SUCCESSFUL',
      fail: 'DATA_GET_DESIGN_MODEL_STORIES_WITH_ESTIMATEID_FAIL'
    },
    embeddedType: {
      stories: {
        schemaPath: '#/definitions/HAL',
        schemaKey: 'HAL'
      }
    },
    fnName: 'getDesignModelStoriesWithEstimateIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/spaces-api`,
    method: 'GET',
    url: `/design-model/${options?.estimateId || store.app.calculation}/stories`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      stories: {
        schemaPath: '#/definitions/HAL',
        schemaKey: 'HAL'
      }
    }, 'getDesignModelStoriesWithEstimateIdRequest', '/spaces-api'),
    actions: {
      start: {
        type: DATA_GET_DESIGN_MODEL_STORIES_WITH_ESTIMATEID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_DESIGN_MODEL_STORIES_WITH_ESTIMATEID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_DESIGN_MODEL_STORIES_WITH_ESTIMATEID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getDesignModelStoriesWithEstimateIdRequestDefinitions = (definitionsArguments: {| payload?: Object |}): {|
  ...TVDGARConfigWithoutRequestArgs, payload?: Object |} => ({
  fnName: 'getDesignModelStoriesWithEstimateIdRequest',
  basePath: '/spaces-api',
  payload: definitionsArguments.payload,
})


export const DATA_POST_DESIGN_MODEL_STORIES_WITH_ESTIMATEID_START = 'DATA_POST_DESIGN_MODEL_STORIES_WITH_ESTIMATEID_START'
export const DATA_POST_DESIGN_MODEL_STORIES_WITH_ESTIMATEID_SUCCESSFUL = 'DATA_POST_DESIGN_MODEL_STORIES_WITH_ESTIMATEID_SUCCESSFUL'
export const DATA_POST_DESIGN_MODEL_STORIES_WITH_ESTIMATEID_FAIL = 'DATA_POST_DESIGN_MODEL_STORIES_WITH_ESTIMATEID_FAIL'

// Add a new story to design model
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/spaces.yaml#/Design%20Model/post_design_model__estimateId__stories
export const postDesignModelStoriesWithEstimateIdRequest = (
  requestArguments: postDesignModelStoriesWithEstimateIdRequestSpacesArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_POST_DESIGN_MODEL_STORIES_WITH_ESTIMATEID_START',
      successful: 'DATA_POST_DESIGN_MODEL_STORIES_WITH_ESTIMATEID_SUCCESSFUL',
      fail: 'DATA_POST_DESIGN_MODEL_STORIES_WITH_ESTIMATEID_FAIL'
    },
    embeddedType: null,
    fnName: 'postDesignModelStoriesWithEstimateIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/spaces-api`,
    method: 'POST',
    url: `/design-model/${options?.estimateId || store.app.calculation}/stories`,
    params: {
      updateProtectedCirculation: requestArguments.query?.['updateProtectedCirculation'],
      updateStructures: requestArguments.query?.['updateStructures'],
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: requestArguments.body
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'postDesignModelStoriesWithEstimateIdRequest', '/spaces-api'),
    actions: {
      start: {
        type: DATA_POST_DESIGN_MODEL_STORIES_WITH_ESTIMATEID_START, payload, config, meta
      },
      successful: {
        type: DATA_POST_DESIGN_MODEL_STORIES_WITH_ESTIMATEID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_POST_DESIGN_MODEL_STORIES_WITH_ESTIMATEID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_GET_DESIGN_MODEL_STORIES_WITH_STORYID_START = 'DATA_GET_DESIGN_MODEL_STORIES_WITH_STORYID_START'
export const DATA_GET_DESIGN_MODEL_STORIES_WITH_STORYID_SUCCESSFUL = 'DATA_GET_DESIGN_MODEL_STORIES_WITH_STORYID_SUCCESSFUL'
export const DATA_GET_DESIGN_MODEL_STORIES_WITH_STORYID_FAIL = 'DATA_GET_DESIGN_MODEL_STORIES_WITH_STORYID_FAIL'

// Returns a single story for a design model
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/spaces.yaml#/Design%20Model/get_design_model__estimateId__stories__storyId_
export const getDesignModelStoriesWithStoryIdRequest = (
  requestArguments: getDesignModelStoriesWithStoryIdRequestSpacesArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_DESIGN_MODEL_STORIES_WITH_STORYID_START',
      successful: 'DATA_GET_DESIGN_MODEL_STORIES_WITH_STORYID_SUCCESSFUL',
      fail: 'DATA_GET_DESIGN_MODEL_STORIES_WITH_STORYID_FAIL'
    },
    embeddedType: {
      properties: {
        schemaPath: '#/definitions/ValueProperty',
        schemaKey: 'ValueProperty'
      }
    },
    fnName: 'getDesignModelStoriesWithStoryIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/spaces-api`,
    method: 'GET',
    url: `/design-model/${options?.estimateId || store.app.calculation}/stories/${requestArguments.path['storyId']}`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      properties: {
        schemaPath: '#/definitions/ValueProperty',
        schemaKey: 'ValueProperty'
      }
    }, 'getDesignModelStoriesWithStoryIdRequest', '/spaces-api'),
    actions: {
      start: {
        type: DATA_GET_DESIGN_MODEL_STORIES_WITH_STORYID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_DESIGN_MODEL_STORIES_WITH_STORYID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_DESIGN_MODEL_STORIES_WITH_STORYID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getDesignModelStoriesWithStoryIdRequestDefinitions = (definitionsArguments: {| requestArgs: getDesignModelStoriesWithStoryIdRequestSpacesArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getDesignModelStoriesWithStoryIdRequestSpacesArguments, payload?: Object |} => ({
  fnName: 'getDesignModelStoriesWithStoryIdRequest',
  basePath: '/spaces-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_PATCH_DESIGN_MODEL_STORIES_WITH_STORYID_START = 'DATA_PATCH_DESIGN_MODEL_STORIES_WITH_STORYID_START'
export const DATA_PATCH_DESIGN_MODEL_STORIES_WITH_STORYID_SUCCESSFUL = 'DATA_PATCH_DESIGN_MODEL_STORIES_WITH_STORYID_SUCCESSFUL'
export const DATA_PATCH_DESIGN_MODEL_STORIES_WITH_STORYID_FAIL = 'DATA_PATCH_DESIGN_MODEL_STORIES_WITH_STORYID_FAIL'

// Update design model properties
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/spaces.yaml#/Design%20Model/patch_design_model__estimateId__stories__storyId_
export const patchDesignModelStoriesWithStoryIdRequest = (
  requestArguments: patchDesignModelStoriesWithStoryIdRequestSpacesArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_PATCH_DESIGN_MODEL_STORIES_WITH_STORYID_START',
      successful: 'DATA_PATCH_DESIGN_MODEL_STORIES_WITH_STORYID_SUCCESSFUL',
      fail: 'DATA_PATCH_DESIGN_MODEL_STORIES_WITH_STORYID_FAIL'
    },
    embeddedType: null,
    fnName: 'patchDesignModelStoriesWithStoryIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/spaces-api`,
    method: 'PATCH',
    url: `/design-model/${options?.estimateId || store.app.calculation}/stories/${requestArguments.path['storyId']}`,
    params: {
      updateProtectedCirculation: requestArguments.query?.['updateProtectedCirculation'],
      updateStructures: requestArguments.query?.['updateStructures'],
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: requestArguments.body
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'patchDesignModelStoriesWithStoryIdRequest', '/spaces-api'),
    actions: {
      start: {
        type: DATA_PATCH_DESIGN_MODEL_STORIES_WITH_STORYID_START, payload, config, meta
      },
      successful: {
        type: DATA_PATCH_DESIGN_MODEL_STORIES_WITH_STORYID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_PATCH_DESIGN_MODEL_STORIES_WITH_STORYID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_DELETE_DESIGN_MODEL_STORIES_WITH_STORYID_START = 'DATA_DELETE_DESIGN_MODEL_STORIES_WITH_STORYID_START'
export const DATA_DELETE_DESIGN_MODEL_STORIES_WITH_STORYID_SUCCESSFUL = 'DATA_DELETE_DESIGN_MODEL_STORIES_WITH_STORYID_SUCCESSFUL'
export const DATA_DELETE_DESIGN_MODEL_STORIES_WITH_STORYID_FAIL = 'DATA_DELETE_DESIGN_MODEL_STORIES_WITH_STORYID_FAIL'

// Remove a story from a design model
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/spaces.yaml#/Design%20Model/delete_design_model__estimateId__stories__storyId_
export const deleteDesignModelStoriesWithStoryIdRequest = (
  requestArguments: deleteDesignModelStoriesWithStoryIdRequestSpacesArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_DELETE_DESIGN_MODEL_STORIES_WITH_STORYID_START',
      successful: 'DATA_DELETE_DESIGN_MODEL_STORIES_WITH_STORYID_SUCCESSFUL',
      fail: 'DATA_DELETE_DESIGN_MODEL_STORIES_WITH_STORYID_FAIL'
    },
    embeddedType: null,
    fnName: 'deleteDesignModelStoriesWithStoryIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/spaces-api`,
    method: 'DELETE',
    url: `/design-model/${options?.estimateId || store.app.calculation}/stories/${requestArguments.path['storyId']}`,
    params: {
      updateProtectedCirculation: requestArguments.query?.['updateProtectedCirculation'],
      updateStructures: requestArguments.query?.['updateStructures'],
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'deleteDesignModelStoriesWithStoryIdRequest', '/spaces-api'),
    actions: {
      start: {
        type: DATA_DELETE_DESIGN_MODEL_STORIES_WITH_STORYID_START, payload, config, meta
      },
      successful: {
        type: DATA_DELETE_DESIGN_MODEL_STORIES_WITH_STORYID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_DELETE_DESIGN_MODEL_STORIES_WITH_STORYID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_GET_DESIGN_MODEL_STORIES_PROPERTIES_INFO_WITH_PROPERTYNAME_START = 'DATA_GET_DESIGN_MODEL_STORIES_PROPERTIES_INFO_WITH_PROPERTYNAME_START'
export const DATA_GET_DESIGN_MODEL_STORIES_PROPERTIES_INFO_WITH_PROPERTYNAME_SUCCESSFUL = 'DATA_GET_DESIGN_MODEL_STORIES_PROPERTIES_INFO_WITH_PROPERTYNAME_SUCCESSFUL'
export const DATA_GET_DESIGN_MODEL_STORIES_PROPERTIES_INFO_WITH_PROPERTYNAME_FAIL = 'DATA_GET_DESIGN_MODEL_STORIES_PROPERTIES_INFO_WITH_PROPERTYNAME_FAIL'

// Get short information text for a story property
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/spaces.yaml#/Story/get_design_model_stories_properties_info__propertyName_
export const getDesignModelStoriesPropertiesInfoWithPropertyNameRequest = (
  requestArguments: getDesignModelStoriesPropertiesInfoWithPropertyNameRequestSpacesArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_DESIGN_MODEL_STORIES_PROPERTIES_INFO_WITH_PROPERTYNAME_START',
      successful: 'DATA_GET_DESIGN_MODEL_STORIES_PROPERTIES_INFO_WITH_PROPERTYNAME_SUCCESSFUL',
      fail: 'DATA_GET_DESIGN_MODEL_STORIES_PROPERTIES_INFO_WITH_PROPERTYNAME_FAIL'
    },
    embeddedType: null,
    fnName: 'getDesignModelStoriesPropertiesInfoWithPropertyNameRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/spaces-api`,
    method: 'GET',
    url: `/design-model/stories/properties/info/${requestArguments.path['propertyName']}`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'getDesignModelStoriesPropertiesInfoWithPropertyNameRequest', '/spaces-api'),
    actions: {
      start: {
        type: DATA_GET_DESIGN_MODEL_STORIES_PROPERTIES_INFO_WITH_PROPERTYNAME_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_DESIGN_MODEL_STORIES_PROPERTIES_INFO_WITH_PROPERTYNAME_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_DESIGN_MODEL_STORIES_PROPERTIES_INFO_WITH_PROPERTYNAME_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getDesignModelStoriesPropertiesInfoWithPropertyNameRequestDefinitions = (definitionsArguments: {| requestArgs: getDesignModelStoriesPropertiesInfoWithPropertyNameRequestSpacesArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getDesignModelStoriesPropertiesInfoWithPropertyNameRequestSpacesArguments, payload?: Object |} => ({
  fnName: 'getDesignModelStoriesPropertiesInfoWithPropertyNameRequest',
  basePath: '/spaces-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_GET_REGISTRY_FUNCTIONAL_SECTORS_START = 'DATA_GET_REGISTRY_FUNCTIONAL_SECTORS_START'
export const DATA_GET_REGISTRY_FUNCTIONAL_SECTORS_SUCCESSFUL = 'DATA_GET_REGISTRY_FUNCTIONAL_SECTORS_SUCCESSFUL'
export const DATA_GET_REGISTRY_FUNCTIONAL_SECTORS_FAIL = 'DATA_GET_REGISTRY_FUNCTIONAL_SECTORS_FAIL'

// Gets functional sectors from registy
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/spaces.yaml#/Functional%20Sectors/get_registry_functional_sectors
export const getRegistryFunctionalSectorsRequest = (
  requestArguments: getRegistryFunctionalSectorsRequestSpacesArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_REGISTRY_FUNCTIONAL_SECTORS_START',
      successful: 'DATA_GET_REGISTRY_FUNCTIONAL_SECTORS_SUCCESSFUL',
      fail: 'DATA_GET_REGISTRY_FUNCTIONAL_SECTORS_FAIL'
    },
    embeddedType: {
      items: {
        schemaPath: '#/definitions/Enumeral',
        schemaKey: 'Enumeral'
      }
    },
    fnName: 'getRegistryFunctionalSectorsRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/spaces-api`,
    method: 'GET',
    url: '/registry/functional-sectors',
    params: {
      classificationId: requestArguments.query?.['classificationId'],
      functionId: requestArguments.query?.['functionId'],
      spacegroupId: requestArguments.query?.['spacegroupId'],
      spaceId: requestArguments.query?.['spaceId'],
      selectedId: requestArguments.query?.['selectedId'],
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      items: {
        schemaPath: '#/definitions/Enumeral',
        schemaKey: 'Enumeral'
      }
    }, 'getRegistryFunctionalSectorsRequest', '/spaces-api'),
    actions: {
      start: {
        type: DATA_GET_REGISTRY_FUNCTIONAL_SECTORS_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_REGISTRY_FUNCTIONAL_SECTORS_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_REGISTRY_FUNCTIONAL_SECTORS_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getRegistryFunctionalSectorsRequestDefinitions = (definitionsArguments: {| requestArgs: getRegistryFunctionalSectorsRequestSpacesArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getRegistryFunctionalSectorsRequestSpacesArguments, payload?: Object |} => ({
  fnName: 'getRegistryFunctionalSectorsRequest',
  basePath: '/spaces-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_GET_REGISTRY_FUNCTIONAL_SECTORS_DRIVERS_WITH_ID_START = 'DATA_GET_REGISTRY_FUNCTIONAL_SECTORS_DRIVERS_WITH_ID_START'
export const DATA_GET_REGISTRY_FUNCTIONAL_SECTORS_DRIVERS_WITH_ID_SUCCESSFUL = 'DATA_GET_REGISTRY_FUNCTIONAL_SECTORS_DRIVERS_WITH_ID_SUCCESSFUL'
export const DATA_GET_REGISTRY_FUNCTIONAL_SECTORS_DRIVERS_WITH_ID_FAIL = 'DATA_GET_REGISTRY_FUNCTIONAL_SECTORS_DRIVERS_WITH_ID_FAIL'

// Get drivers for a registry functional sector
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/spaces.yaml#/Functional%20Sectors/get_registry_functional_sectors__id__drivers
export const getRegistryFunctionalSectorsDriversWithIdRequest = (
  requestArguments: getRegistryFunctionalSectorsDriversWithIdRequestSpacesArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_REGISTRY_FUNCTIONAL_SECTORS_DRIVERS_WITH_ID_START',
      successful: 'DATA_GET_REGISTRY_FUNCTIONAL_SECTORS_DRIVERS_WITH_ID_SUCCESSFUL',
      fail: 'DATA_GET_REGISTRY_FUNCTIONAL_SECTORS_DRIVERS_WITH_ID_FAIL'
    },
    embeddedType: {
      drivers: {
        schemaPath: '#/definitions/ValueProperty',
        schemaKey: 'ValueProperty'
      },
      area: {
        schemaPath: '#/definitions/ValueProperty',
        schemaKey: 'ValueProperty'
      }
    },
    fnName: 'getRegistryFunctionalSectorsDriversWithIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/spaces-api`,
    method: 'GET',
    url: `/registry/functional-sectors/${requestArguments.path['id']}/drivers`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      drivers: {
        schemaPath: '#/definitions/ValueProperty',
        schemaKey: 'ValueProperty'
      },
      area: {
        schemaPath: '#/definitions/ValueProperty',
        schemaKey: 'ValueProperty'
      }
    }, 'getRegistryFunctionalSectorsDriversWithIdRequest', '/spaces-api'),
    actions: {
      start: {
        type: DATA_GET_REGISTRY_FUNCTIONAL_SECTORS_DRIVERS_WITH_ID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_REGISTRY_FUNCTIONAL_SECTORS_DRIVERS_WITH_ID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_REGISTRY_FUNCTIONAL_SECTORS_DRIVERS_WITH_ID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getRegistryFunctionalSectorsDriversWithIdRequestDefinitions = (definitionsArguments: {| requestArgs: getRegistryFunctionalSectorsDriversWithIdRequestSpacesArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getRegistryFunctionalSectorsDriversWithIdRequestSpacesArguments, payload?: Object |} => ({
  fnName: 'getRegistryFunctionalSectorsDriversWithIdRequest',
  basePath: '/spaces-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_GET_REGISTRY_FUNCTIONS_START = 'DATA_GET_REGISTRY_FUNCTIONS_START'
export const DATA_GET_REGISTRY_FUNCTIONS_SUCCESSFUL = 'DATA_GET_REGISTRY_FUNCTIONS_SUCCESSFUL'
export const DATA_GET_REGISTRY_FUNCTIONS_FAIL = 'DATA_GET_REGISTRY_FUNCTIONS_FAIL'

// Gets functions from registry
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/spaces.yaml#/Functions/get_registry_functions
export const getRegistryFunctionsRequest = (
  requestArguments: getRegistryFunctionsRequestSpacesArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_REGISTRY_FUNCTIONS_START',
      successful: 'DATA_GET_REGISTRY_FUNCTIONS_SUCCESSFUL',
      fail: 'DATA_GET_REGISTRY_FUNCTIONS_FAIL'
    },
    embeddedType: {
      items: {
        schemaPath: '#/definitions/Enumeral',
        schemaKey: 'Enumeral'
      }
    },
    fnName: 'getRegistryFunctionsRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/spaces-api`,
    method: 'GET',
    url: '/registry/functions',
    params: {
      classificationId: requestArguments.query?.['classificationId'],
      functionalsectorId: requestArguments.query?.['functionalsectorId'],
      spacegroupId: requestArguments.query?.['spacegroupId'],
      spaceId: requestArguments.query?.['spaceId'],
      selectedId: requestArguments.query?.['selectedId'],
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      items: {
        schemaPath: '#/definitions/Enumeral',
        schemaKey: 'Enumeral'
      }
    }, 'getRegistryFunctionsRequest', '/spaces-api'),
    actions: {
      start: {
        type: DATA_GET_REGISTRY_FUNCTIONS_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_REGISTRY_FUNCTIONS_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_REGISTRY_FUNCTIONS_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getRegistryFunctionsRequestDefinitions = (definitionsArguments: {| requestArgs: getRegistryFunctionsRequestSpacesArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getRegistryFunctionsRequestSpacesArguments, payload?: Object |} => ({
  fnName: 'getRegistryFunctionsRequest',
  basePath: '/spaces-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_GET_REGISTRY_FUNCTIONS_DRIVERS_WITH_ID_START = 'DATA_GET_REGISTRY_FUNCTIONS_DRIVERS_WITH_ID_START'
export const DATA_GET_REGISTRY_FUNCTIONS_DRIVERS_WITH_ID_SUCCESSFUL = 'DATA_GET_REGISTRY_FUNCTIONS_DRIVERS_WITH_ID_SUCCESSFUL'
export const DATA_GET_REGISTRY_FUNCTIONS_DRIVERS_WITH_ID_FAIL = 'DATA_GET_REGISTRY_FUNCTIONS_DRIVERS_WITH_ID_FAIL'

// Get drivers for a registry function
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/spaces.yaml#/Functions/get_registry_functions__id__drivers
export const getRegistryFunctionsDriversWithIdRequest = (
  requestArguments: getRegistryFunctionsDriversWithIdRequestSpacesArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_REGISTRY_FUNCTIONS_DRIVERS_WITH_ID_START',
      successful: 'DATA_GET_REGISTRY_FUNCTIONS_DRIVERS_WITH_ID_SUCCESSFUL',
      fail: 'DATA_GET_REGISTRY_FUNCTIONS_DRIVERS_WITH_ID_FAIL'
    },
    embeddedType: {
      drivers: {
        schemaPath: '#/definitions/ValueProperty',
        schemaKey: 'ValueProperty'
      },
      area: {
        schemaPath: '#/definitions/ValueProperty',
        schemaKey: 'ValueProperty'
      }
    },
    fnName: 'getRegistryFunctionsDriversWithIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/spaces-api`,
    method: 'GET',
    url: `/registry/functions/${requestArguments.path['id']}/drivers`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      drivers: {
        schemaPath: '#/definitions/ValueProperty',
        schemaKey: 'ValueProperty'
      },
      area: {
        schemaPath: '#/definitions/ValueProperty',
        schemaKey: 'ValueProperty'
      }
    }, 'getRegistryFunctionsDriversWithIdRequest', '/spaces-api'),
    actions: {
      start: {
        type: DATA_GET_REGISTRY_FUNCTIONS_DRIVERS_WITH_ID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_REGISTRY_FUNCTIONS_DRIVERS_WITH_ID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_REGISTRY_FUNCTIONS_DRIVERS_WITH_ID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getRegistryFunctionsDriversWithIdRequestDefinitions = (definitionsArguments: {| requestArgs: getRegistryFunctionsDriversWithIdRequestSpacesArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getRegistryFunctionsDriversWithIdRequestSpacesArguments, payload?: Object |} => ({
  fnName: 'getRegistryFunctionsDriversWithIdRequest',
  basePath: '/spaces-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_GET_REGISTRY_FUNCTIONS_CAN_ATTACH_TO_START = 'DATA_GET_REGISTRY_FUNCTIONS_CAN_ATTACH_TO_START'
export const DATA_GET_REGISTRY_FUNCTIONS_CAN_ATTACH_TO_SUCCESSFUL = 'DATA_GET_REGISTRY_FUNCTIONS_CAN_ATTACH_TO_SUCCESSFUL'
export const DATA_GET_REGISTRY_FUNCTIONS_CAN_ATTACH_TO_FAIL = 'DATA_GET_REGISTRY_FUNCTIONS_CAN_ATTACH_TO_FAIL'

// Get id (value) and description (localizedName) for rows to which the registry function can be attached to
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/spaces.yaml#/Functions/get_registry_functions_can_attach_to
export const getRegistryFunctionsCanAttachToRequest = (
  payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_REGISTRY_FUNCTIONS_CAN_ATTACH_TO_START',
      successful: 'DATA_GET_REGISTRY_FUNCTIONS_CAN_ATTACH_TO_SUCCESSFUL',
      fail: 'DATA_GET_REGISTRY_FUNCTIONS_CAN_ATTACH_TO_FAIL'
    },
    embeddedType: {
      items: {
        schemaPath: '#/definitions/Enumeral',
        schemaKey: 'Enumeral'
      }
    },
    fnName: 'getRegistryFunctionsCanAttachToRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/spaces-api`,
    method: 'GET',
    url: '/registry/functions/can-attach-to',
    params: {
      estimateId: options?.estimateId || store.app.calculation,
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      items: {
        schemaPath: '#/definitions/Enumeral',
        schemaKey: 'Enumeral'
      }
    }, 'getRegistryFunctionsCanAttachToRequest', '/spaces-api'),
    actions: {
      start: {
        type: DATA_GET_REGISTRY_FUNCTIONS_CAN_ATTACH_TO_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_REGISTRY_FUNCTIONS_CAN_ATTACH_TO_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_REGISTRY_FUNCTIONS_CAN_ATTACH_TO_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getRegistryFunctionsCanAttachToRequestDefinitions = (definitionsArguments: {| payload?: Object |}): {|
  ...TVDGARConfigWithoutRequestArgs, payload?: Object |} => ({
  fnName: 'getRegistryFunctionsCanAttachToRequest',
  basePath: '/spaces-api',
  payload: definitionsArguments.payload,
})


export const DATA_GET_REGISTRY_SPACE_GROUPS_START = 'DATA_GET_REGISTRY_SPACE_GROUPS_START'
export const DATA_GET_REGISTRY_SPACE_GROUPS_SUCCESSFUL = 'DATA_GET_REGISTRY_SPACE_GROUPS_SUCCESSFUL'
export const DATA_GET_REGISTRY_SPACE_GROUPS_FAIL = 'DATA_GET_REGISTRY_SPACE_GROUPS_FAIL'

// Gets space groups from registry
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/spaces.yaml#/Space%20Groups/get_registry_space_groups
export const getRegistrySpaceGroupsRequest = (
  requestArguments: getRegistrySpaceGroupsRequestSpacesArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_REGISTRY_SPACE_GROUPS_START',
      successful: 'DATA_GET_REGISTRY_SPACE_GROUPS_SUCCESSFUL',
      fail: 'DATA_GET_REGISTRY_SPACE_GROUPS_FAIL'
    },
    embeddedType: {
      items: {
        schemaPath: '#/definitions/Enumeral',
        schemaKey: 'Enumeral'
      }
    },
    fnName: 'getRegistrySpaceGroupsRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/spaces-api`,
    method: 'GET',
    url: '/registry/space-groups',
    params: {
      classificationId: requestArguments.query?.['classificationId'],
      functionalsectorId: requestArguments.query?.['functionalsectorId'],
      functionId: requestArguments.query?.['functionId'],
      spaceId: requestArguments.query?.['spaceId'],
      selectedId: requestArguments.query?.['selectedId'],
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      items: {
        schemaPath: '#/definitions/Enumeral',
        schemaKey: 'Enumeral'
      }
    }, 'getRegistrySpaceGroupsRequest', '/spaces-api'),
    actions: {
      start: {
        type: DATA_GET_REGISTRY_SPACE_GROUPS_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_REGISTRY_SPACE_GROUPS_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_REGISTRY_SPACE_GROUPS_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getRegistrySpaceGroupsRequestDefinitions = (definitionsArguments: {| requestArgs: getRegistrySpaceGroupsRequestSpacesArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getRegistrySpaceGroupsRequestSpacesArguments, payload?: Object |} => ({
  fnName: 'getRegistrySpaceGroupsRequest',
  basePath: '/spaces-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_GET_REGISTRY_SPACE_GROUPS_DRIVERS_WITH_ID_START = 'DATA_GET_REGISTRY_SPACE_GROUPS_DRIVERS_WITH_ID_START'
export const DATA_GET_REGISTRY_SPACE_GROUPS_DRIVERS_WITH_ID_SUCCESSFUL = 'DATA_GET_REGISTRY_SPACE_GROUPS_DRIVERS_WITH_ID_SUCCESSFUL'
export const DATA_GET_REGISTRY_SPACE_GROUPS_DRIVERS_WITH_ID_FAIL = 'DATA_GET_REGISTRY_SPACE_GROUPS_DRIVERS_WITH_ID_FAIL'

// Get drivers for a registry space group
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/spaces.yaml#/Space%20Groups/get_registry_space_groups__id__drivers
export const getRegistrySpaceGroupsDriversWithIdRequest = (
  requestArguments: getRegistrySpaceGroupsDriversWithIdRequestSpacesArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_REGISTRY_SPACE_GROUPS_DRIVERS_WITH_ID_START',
      successful: 'DATA_GET_REGISTRY_SPACE_GROUPS_DRIVERS_WITH_ID_SUCCESSFUL',
      fail: 'DATA_GET_REGISTRY_SPACE_GROUPS_DRIVERS_WITH_ID_FAIL'
    },
    embeddedType: {
      drivers: {
        schemaPath: '#/definitions/ValueProperty',
        schemaKey: 'ValueProperty'
      },
      area: {
        schemaPath: '#/definitions/ValueProperty',
        schemaKey: 'ValueProperty'
      }
    },
    fnName: 'getRegistrySpaceGroupsDriversWithIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/spaces-api`,
    method: 'GET',
    url: `/registry/space-groups/${requestArguments.path['id']}/drivers`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      drivers: {
        schemaPath: '#/definitions/ValueProperty',
        schemaKey: 'ValueProperty'
      },
      area: {
        schemaPath: '#/definitions/ValueProperty',
        schemaKey: 'ValueProperty'
      }
    }, 'getRegistrySpaceGroupsDriversWithIdRequest', '/spaces-api'),
    actions: {
      start: {
        type: DATA_GET_REGISTRY_SPACE_GROUPS_DRIVERS_WITH_ID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_REGISTRY_SPACE_GROUPS_DRIVERS_WITH_ID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_REGISTRY_SPACE_GROUPS_DRIVERS_WITH_ID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getRegistrySpaceGroupsDriversWithIdRequestDefinitions = (definitionsArguments: {| requestArgs: getRegistrySpaceGroupsDriversWithIdRequestSpacesArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getRegistrySpaceGroupsDriversWithIdRequestSpacesArguments, payload?: Object |} => ({
  fnName: 'getRegistrySpaceGroupsDriversWithIdRequest',
  basePath: '/spaces-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_GET_REGISTRY_SPACE_GROUPS_CAN_ATTACH_TO_START = 'DATA_GET_REGISTRY_SPACE_GROUPS_CAN_ATTACH_TO_START'
export const DATA_GET_REGISTRY_SPACE_GROUPS_CAN_ATTACH_TO_SUCCESSFUL = 'DATA_GET_REGISTRY_SPACE_GROUPS_CAN_ATTACH_TO_SUCCESSFUL'
export const DATA_GET_REGISTRY_SPACE_GROUPS_CAN_ATTACH_TO_FAIL = 'DATA_GET_REGISTRY_SPACE_GROUPS_CAN_ATTACH_TO_FAIL'

// Get id (value) and description (localizedName) of rows to which the space group can be attached to
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/spaces.yaml#/Space%20Groups/get_registry_space_groups_can_attach_to
export const getRegistrySpaceGroupsCanAttachToRequest = (
  payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_REGISTRY_SPACE_GROUPS_CAN_ATTACH_TO_START',
      successful: 'DATA_GET_REGISTRY_SPACE_GROUPS_CAN_ATTACH_TO_SUCCESSFUL',
      fail: 'DATA_GET_REGISTRY_SPACE_GROUPS_CAN_ATTACH_TO_FAIL'
    },
    embeddedType: {
      items: {
        schemaPath: '#/definitions/Enumeral',
        schemaKey: 'Enumeral'
      }
    },
    fnName: 'getRegistrySpaceGroupsCanAttachToRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/spaces-api`,
    method: 'GET',
    url: '/registry/space-groups/can-attach-to',
    params: {
      estimateId: options?.estimateId || store.app.calculation,
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      items: {
        schemaPath: '#/definitions/Enumeral',
        schemaKey: 'Enumeral'
      }
    }, 'getRegistrySpaceGroupsCanAttachToRequest', '/spaces-api'),
    actions: {
      start: {
        type: DATA_GET_REGISTRY_SPACE_GROUPS_CAN_ATTACH_TO_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_REGISTRY_SPACE_GROUPS_CAN_ATTACH_TO_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_REGISTRY_SPACE_GROUPS_CAN_ATTACH_TO_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getRegistrySpaceGroupsCanAttachToRequestDefinitions = (definitionsArguments: {| payload?: Object |}): {|
  ...TVDGARConfigWithoutRequestArgs, payload?: Object |} => ({
  fnName: 'getRegistrySpaceGroupsCanAttachToRequest',
  basePath: '/spaces-api',
  payload: definitionsArguments.payload,
})


export const DATA_GET_REGISTRY_SPACES_START = 'DATA_GET_REGISTRY_SPACES_START'
export const DATA_GET_REGISTRY_SPACES_SUCCESSFUL = 'DATA_GET_REGISTRY_SPACES_SUCCESSFUL'
export const DATA_GET_REGISTRY_SPACES_FAIL = 'DATA_GET_REGISTRY_SPACES_FAIL'

// Gets spaces from registry
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/spaces.yaml#/Spaces/get_registry_spaces
export const getRegistrySpacesRequest = (
  requestArguments: getRegistrySpacesRequestSpacesArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_REGISTRY_SPACES_START',
      successful: 'DATA_GET_REGISTRY_SPACES_SUCCESSFUL',
      fail: 'DATA_GET_REGISTRY_SPACES_FAIL'
    },
    embeddedType: {
      items: {
        schemaPath: '#/definitions/Enumeral',
        schemaKey: 'Enumeral'
      }
    },
    fnName: 'getRegistrySpacesRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/spaces-api`,
    method: 'GET',
    url: '/registry/spaces',
    params: {
      classificationId: requestArguments.query?.['classificationId'],
      functionalsectorId: requestArguments.query?.['functionalsectorId'],
      functionId: requestArguments.query?.['functionId'],
      spacegroupId: requestArguments.query?.['spacegroupId'],
      selectedId: requestArguments.query?.['selectedId'],
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      items: {
        schemaPath: '#/definitions/Enumeral',
        schemaKey: 'Enumeral'
      }
    }, 'getRegistrySpacesRequest', '/spaces-api'),
    actions: {
      start: {
        type: DATA_GET_REGISTRY_SPACES_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_REGISTRY_SPACES_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_REGISTRY_SPACES_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getRegistrySpacesRequestDefinitions = (definitionsArguments: {| requestArgs: getRegistrySpacesRequestSpacesArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getRegistrySpacesRequestSpacesArguments, payload?: Object |} => ({
  fnName: 'getRegistrySpacesRequest',
  basePath: '/spaces-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_GET_REGISTRY_SPACES_DRIVERS_WITH_ID_START = 'DATA_GET_REGISTRY_SPACES_DRIVERS_WITH_ID_START'
export const DATA_GET_REGISTRY_SPACES_DRIVERS_WITH_ID_SUCCESSFUL = 'DATA_GET_REGISTRY_SPACES_DRIVERS_WITH_ID_SUCCESSFUL'
export const DATA_GET_REGISTRY_SPACES_DRIVERS_WITH_ID_FAIL = 'DATA_GET_REGISTRY_SPACES_DRIVERS_WITH_ID_FAIL'

// Get drivers for a registry space
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/spaces.yaml#/Spaces/get_registry_spaces__id__drivers
export const getRegistrySpacesDriversWithIdRequest = (
  requestArguments: getRegistrySpacesDriversWithIdRequestSpacesArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_REGISTRY_SPACES_DRIVERS_WITH_ID_START',
      successful: 'DATA_GET_REGISTRY_SPACES_DRIVERS_WITH_ID_SUCCESSFUL',
      fail: 'DATA_GET_REGISTRY_SPACES_DRIVERS_WITH_ID_FAIL'
    },
    embeddedType: {
      drivers: {
        schemaPath: '#/definitions/ValueProperty',
        schemaKey: 'ValueProperty'
      },
      area: {
        schemaPath: '#/definitions/ValueProperty',
        schemaKey: 'ValueProperty'
      }
    },
    fnName: 'getRegistrySpacesDriversWithIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/spaces-api`,
    method: 'GET',
    url: `/registry/spaces/${requestArguments.path['id']}/drivers`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      drivers: {
        schemaPath: '#/definitions/ValueProperty',
        schemaKey: 'ValueProperty'
      },
      area: {
        schemaPath: '#/definitions/ValueProperty',
        schemaKey: 'ValueProperty'
      }
    }, 'getRegistrySpacesDriversWithIdRequest', '/spaces-api'),
    actions: {
      start: {
        type: DATA_GET_REGISTRY_SPACES_DRIVERS_WITH_ID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_REGISTRY_SPACES_DRIVERS_WITH_ID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_REGISTRY_SPACES_DRIVERS_WITH_ID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getRegistrySpacesDriversWithIdRequestDefinitions = (definitionsArguments: {| requestArgs: getRegistrySpacesDriversWithIdRequestSpacesArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getRegistrySpacesDriversWithIdRequestSpacesArguments, payload?: Object |} => ({
  fnName: 'getRegistrySpacesDriversWithIdRequest',
  basePath: '/spaces-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_GET_REGISTRY_SPACES_CAN_ATTACH_TO_START = 'DATA_GET_REGISTRY_SPACES_CAN_ATTACH_TO_START'
export const DATA_GET_REGISTRY_SPACES_CAN_ATTACH_TO_SUCCESSFUL = 'DATA_GET_REGISTRY_SPACES_CAN_ATTACH_TO_SUCCESSFUL'
export const DATA_GET_REGISTRY_SPACES_CAN_ATTACH_TO_FAIL = 'DATA_GET_REGISTRY_SPACES_CAN_ATTACH_TO_FAIL'

// Get id (value) and description (localizedName) of rows to which the space can be attached to
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/spaces.yaml#/Spaces/get_registry_spaces_can_attach_to
export const getRegistrySpacesCanAttachToRequest = (
  payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_REGISTRY_SPACES_CAN_ATTACH_TO_START',
      successful: 'DATA_GET_REGISTRY_SPACES_CAN_ATTACH_TO_SUCCESSFUL',
      fail: 'DATA_GET_REGISTRY_SPACES_CAN_ATTACH_TO_FAIL'
    },
    embeddedType: {
      items: {
        schemaPath: '#/definitions/Enumeral',
        schemaKey: 'Enumeral'
      }
    },
    fnName: 'getRegistrySpacesCanAttachToRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/spaces-api`,
    method: 'GET',
    url: '/registry/spaces/can-attach-to',
    params: {
      estimateId: options?.estimateId || store.app.calculation,
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      items: {
        schemaPath: '#/definitions/Enumeral',
        schemaKey: 'Enumeral'
      }
    }, 'getRegistrySpacesCanAttachToRequest', '/spaces-api'),
    actions: {
      start: {
        type: DATA_GET_REGISTRY_SPACES_CAN_ATTACH_TO_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_REGISTRY_SPACES_CAN_ATTACH_TO_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_REGISTRY_SPACES_CAN_ATTACH_TO_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getRegistrySpacesCanAttachToRequestDefinitions = (definitionsArguments: {| payload?: Object |}): {|
  ...TVDGARConfigWithoutRequestArgs, payload?: Object |} => ({
  fnName: 'getRegistrySpacesCanAttachToRequest',
  basePath: '/spaces-api',
  payload: definitionsArguments.payload,
})


export const DATA_GET_SPACES_EQUIPMENT_WITH_SPACEID_START = 'DATA_GET_SPACES_EQUIPMENT_WITH_SPACEID_START'
export const DATA_GET_SPACES_EQUIPMENT_WITH_SPACEID_SUCCESSFUL = 'DATA_GET_SPACES_EQUIPMENT_WITH_SPACEID_SUCCESSFUL'
export const DATA_GET_SPACES_EQUIPMENT_WITH_SPACEID_FAIL = 'DATA_GET_SPACES_EQUIPMENT_WITH_SPACEID_FAIL'

// Returns equipment list for a space
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/spaces.yaml#/Spaces/get_spaces__spaceId__equipment
export const getSpacesEquipmentWithSpaceIdRequest = (
  requestArguments: getSpacesEquipmentWithSpaceIdRequestSpacesArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_SPACES_EQUIPMENT_WITH_SPACEID_START',
      successful: 'DATA_GET_SPACES_EQUIPMENT_WITH_SPACEID_SUCCESSFUL',
      fail: 'DATA_GET_SPACES_EQUIPMENT_WITH_SPACEID_FAIL'
    },
    embeddedType: {
      items: {
        schemaPath: '#/definitions/ScheduleItem',
        schemaKey: 'ScheduleItem'
      }
    },
    fnName: 'getSpacesEquipmentWithSpaceIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/spaces-api`,
    method: 'GET',
    url: `/spaces/${requestArguments.path['spaceId']}/equipment`,
    params: {
      estimateId: options?.estimateId || store.app.calculation,
      spacesEstimateType: options?.spacesEstimateType || store.app.spacesEstimateType,
      spacesResultView: options?.spacesResultView || store.app.spacesResultView,
      listType: requestArguments.query['listType'],
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      items: {
        schemaPath: '#/definitions/ScheduleItem',
        schemaKey: 'ScheduleItem'
      }
    }, 'getSpacesEquipmentWithSpaceIdRequest', '/spaces-api'),
    actions: {
      start: {
        type: DATA_GET_SPACES_EQUIPMENT_WITH_SPACEID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_SPACES_EQUIPMENT_WITH_SPACEID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_SPACES_EQUIPMENT_WITH_SPACEID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getSpacesEquipmentWithSpaceIdRequestDefinitions = (definitionsArguments: {| requestArgs: getSpacesEquipmentWithSpaceIdRequestSpacesArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getSpacesEquipmentWithSpaceIdRequestSpacesArguments, payload?: Object |} => ({
  fnName: 'getSpacesEquipmentWithSpaceIdRequest',
  basePath: '/spaces-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_GET_SPACES_EQUIPMENT_COLUMNS_WITH_SPACEID_START = 'DATA_GET_SPACES_EQUIPMENT_COLUMNS_WITH_SPACEID_START'
export const DATA_GET_SPACES_EQUIPMENT_COLUMNS_WITH_SPACEID_SUCCESSFUL = 'DATA_GET_SPACES_EQUIPMENT_COLUMNS_WITH_SPACEID_SUCCESSFUL'
export const DATA_GET_SPACES_EQUIPMENT_COLUMNS_WITH_SPACEID_FAIL = 'DATA_GET_SPACES_EQUIPMENT_COLUMNS_WITH_SPACEID_FAIL'

// Get columns for equipment list
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/spaces.yaml#/Spaces/get_spaces__spaceId__equipment_columns
export const getSpacesEquipmentColumnsWithSpaceIdRequest = (
  requestArguments: getSpacesEquipmentColumnsWithSpaceIdRequestSpacesArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_SPACES_EQUIPMENT_COLUMNS_WITH_SPACEID_START',
      successful: 'DATA_GET_SPACES_EQUIPMENT_COLUMNS_WITH_SPACEID_SUCCESSFUL',
      fail: 'DATA_GET_SPACES_EQUIPMENT_COLUMNS_WITH_SPACEID_FAIL'
    },
    embeddedType: {
      properties: {
        schemaPath: '#/definitions/ValueProperty',
        schemaKey: 'ValueProperty'
      }
    },
    fnName: 'getSpacesEquipmentColumnsWithSpaceIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/spaces-api`,
    method: 'GET',
    url: `/spaces/${requestArguments.path['spaceId']}/equipment/columns`,
    params: {
      estimateId: options?.estimateId || store.app.calculation,
      spacesEstimateType: options?.spacesEstimateType || store.app.spacesEstimateType,
      spacesResultView: options?.spacesResultView || store.app.spacesResultView,
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      properties: {
        schemaPath: '#/definitions/ValueProperty',
        schemaKey: 'ValueProperty'
      }
    }, 'getSpacesEquipmentColumnsWithSpaceIdRequest', '/spaces-api'),
    actions: {
      start: {
        type: DATA_GET_SPACES_EQUIPMENT_COLUMNS_WITH_SPACEID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_SPACES_EQUIPMENT_COLUMNS_WITH_SPACEID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_SPACES_EQUIPMENT_COLUMNS_WITH_SPACEID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getSpacesEquipmentColumnsWithSpaceIdRequestDefinitions = (definitionsArguments: {| requestArgs: getSpacesEquipmentColumnsWithSpaceIdRequestSpacesArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getSpacesEquipmentColumnsWithSpaceIdRequestSpacesArguments, payload?: Object |} => ({
  fnName: 'getSpacesEquipmentColumnsWithSpaceIdRequest',
  basePath: '/spaces-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_GET_SPACES_EQUIPMENT_REGISTRY_WITH_SPACEID_START = 'DATA_GET_SPACES_EQUIPMENT_REGISTRY_WITH_SPACEID_START'
export const DATA_GET_SPACES_EQUIPMENT_REGISTRY_WITH_SPACEID_SUCCESSFUL = 'DATA_GET_SPACES_EQUIPMENT_REGISTRY_WITH_SPACEID_SUCCESSFUL'
export const DATA_GET_SPACES_EQUIPMENT_REGISTRY_WITH_SPACEID_FAIL = 'DATA_GET_SPACES_EQUIPMENT_REGISTRY_WITH_SPACEID_FAIL'

// Gets registry list of space equipment
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/spaces.yaml#/Spaces/get_spaces__spaceId__equipment_registry
export const getSpacesEquipmentRegistryWithSpaceIdRequest = (
  requestArguments: getSpacesEquipmentRegistryWithSpaceIdRequestSpacesArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_SPACES_EQUIPMENT_REGISTRY_WITH_SPACEID_START',
      successful: 'DATA_GET_SPACES_EQUIPMENT_REGISTRY_WITH_SPACEID_SUCCESSFUL',
      fail: 'DATA_GET_SPACES_EQUIPMENT_REGISTRY_WITH_SPACEID_FAIL'
    },
    embeddedType: {
      items: {
        schemaPath: '#/definitions/ScheduleItem',
        schemaKey: 'ScheduleItem'
      }
    },
    fnName: 'getSpacesEquipmentRegistryWithSpaceIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/spaces-api`,
    method: 'GET',
    url: `/spaces/${requestArguments.path['spaceId']}/equipment/registry`,
    params: {
      estimateId: options?.estimateId || store.app.calculation,
      spacesEstimateType: options?.spacesEstimateType || store.app.spacesEstimateType,
      spacesResultView: options?.spacesResultView || store.app.spacesResultView,
      listType: requestArguments.query['listType'],
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      items: {
        schemaPath: '#/definitions/ScheduleItem',
        schemaKey: 'ScheduleItem'
      }
    }, 'getSpacesEquipmentRegistryWithSpaceIdRequest', '/spaces-api'),
    actions: {
      start: {
        type: DATA_GET_SPACES_EQUIPMENT_REGISTRY_WITH_SPACEID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_SPACES_EQUIPMENT_REGISTRY_WITH_SPACEID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_SPACES_EQUIPMENT_REGISTRY_WITH_SPACEID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getSpacesEquipmentRegistryWithSpaceIdRequestDefinitions = (definitionsArguments: {| requestArgs: getSpacesEquipmentRegistryWithSpaceIdRequestSpacesArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getSpacesEquipmentRegistryWithSpaceIdRequestSpacesArguments, payload?: Object |} => ({
  fnName: 'getSpacesEquipmentRegistryWithSpaceIdRequest',
  basePath: '/spaces-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_GET_SPACES_EQUIPMENT_REGISTRY_COLUMNS_WITH_SPACEID_START = 'DATA_GET_SPACES_EQUIPMENT_REGISTRY_COLUMNS_WITH_SPACEID_START'
export const DATA_GET_SPACES_EQUIPMENT_REGISTRY_COLUMNS_WITH_SPACEID_SUCCESSFUL = 'DATA_GET_SPACES_EQUIPMENT_REGISTRY_COLUMNS_WITH_SPACEID_SUCCESSFUL'
export const DATA_GET_SPACES_EQUIPMENT_REGISTRY_COLUMNS_WITH_SPACEID_FAIL = 'DATA_GET_SPACES_EQUIPMENT_REGISTRY_COLUMNS_WITH_SPACEID_FAIL'

// Get columns for space equipment registry
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/spaces.yaml#/Spaces/get_spaces__spaceId__equipment_registry_columns
export const getSpacesEquipmentRegistryColumnsWithSpaceIdRequest = (
  requestArguments: getSpacesEquipmentRegistryColumnsWithSpaceIdRequestSpacesArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_SPACES_EQUIPMENT_REGISTRY_COLUMNS_WITH_SPACEID_START',
      successful: 'DATA_GET_SPACES_EQUIPMENT_REGISTRY_COLUMNS_WITH_SPACEID_SUCCESSFUL',
      fail: 'DATA_GET_SPACES_EQUIPMENT_REGISTRY_COLUMNS_WITH_SPACEID_FAIL'
    },
    embeddedType: {
      properties: {
        schemaPath: '#/definitions/ValueProperty',
        schemaKey: 'ValueProperty'
      }
    },
    fnName: 'getSpacesEquipmentRegistryColumnsWithSpaceIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/spaces-api`,
    method: 'GET',
    url: `/spaces/${requestArguments.path['spaceId']}/equipment/registry/columns`,
    params: {
      estimateId: options?.estimateId || store.app.calculation,
      spacesEstimateType: options?.spacesEstimateType || store.app.spacesEstimateType,
      spacesResultView: options?.spacesResultView || store.app.spacesResultView,
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      properties: {
        schemaPath: '#/definitions/ValueProperty',
        schemaKey: 'ValueProperty'
      }
    }, 'getSpacesEquipmentRegistryColumnsWithSpaceIdRequest', '/spaces-api'),
    actions: {
      start: {
        type: DATA_GET_SPACES_EQUIPMENT_REGISTRY_COLUMNS_WITH_SPACEID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_SPACES_EQUIPMENT_REGISTRY_COLUMNS_WITH_SPACEID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_SPACES_EQUIPMENT_REGISTRY_COLUMNS_WITH_SPACEID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getSpacesEquipmentRegistryColumnsWithSpaceIdRequestDefinitions = (definitionsArguments: {| requestArgs: getSpacesEquipmentRegistryColumnsWithSpaceIdRequestSpacesArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getSpacesEquipmentRegistryColumnsWithSpaceIdRequestSpacesArguments, payload?: Object |} => ({
  fnName: 'getSpacesEquipmentRegistryColumnsWithSpaceIdRequest',
  basePath: '/spaces-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_GET_SPACES_EQUIPMENT_REGISTRY_ITEMS_WITH_ITEMID_START = 'DATA_GET_SPACES_EQUIPMENT_REGISTRY_ITEMS_WITH_ITEMID_START'
export const DATA_GET_SPACES_EQUIPMENT_REGISTRY_ITEMS_WITH_ITEMID_SUCCESSFUL = 'DATA_GET_SPACES_EQUIPMENT_REGISTRY_ITEMS_WITH_ITEMID_SUCCESSFUL'
export const DATA_GET_SPACES_EQUIPMENT_REGISTRY_ITEMS_WITH_ITEMID_FAIL = 'DATA_GET_SPACES_EQUIPMENT_REGISTRY_ITEMS_WITH_ITEMID_FAIL'

// Gets registry list of equipment under a parent item
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/spaces.yaml#/Spaces/get_spaces__spaceId__equipment_registry__itemId__items
export const getSpacesEquipmentRegistryItemsWithItemIdRequest = (
  requestArguments: getSpacesEquipmentRegistryItemsWithItemIdRequestSpacesArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_SPACES_EQUIPMENT_REGISTRY_ITEMS_WITH_ITEMID_START',
      successful: 'DATA_GET_SPACES_EQUIPMENT_REGISTRY_ITEMS_WITH_ITEMID_SUCCESSFUL',
      fail: 'DATA_GET_SPACES_EQUIPMENT_REGISTRY_ITEMS_WITH_ITEMID_FAIL'
    },
    embeddedType: {
      items: {
        schemaPath: '#/definitions/ScheduleItem',
        schemaKey: 'ScheduleItem'
      }
    },
    fnName: 'getSpacesEquipmentRegistryItemsWithItemIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/spaces-api`,
    method: 'GET',
    url: `/spaces/${requestArguments.path['spaceId']}/equipment/registry/${requestArguments.path['itemId']}/items`,
    params: {
      estimateId: options?.estimateId || store.app.calculation,
      spacesEstimateType: options?.spacesEstimateType || store.app.spacesEstimateType,
      spacesResultView: options?.spacesResultView || store.app.spacesResultView,
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      items: {
        schemaPath: '#/definitions/ScheduleItem',
        schemaKey: 'ScheduleItem'
      }
    }, 'getSpacesEquipmentRegistryItemsWithItemIdRequest', '/spaces-api'),
    actions: {
      start: {
        type: DATA_GET_SPACES_EQUIPMENT_REGISTRY_ITEMS_WITH_ITEMID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_SPACES_EQUIPMENT_REGISTRY_ITEMS_WITH_ITEMID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_SPACES_EQUIPMENT_REGISTRY_ITEMS_WITH_ITEMID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getSpacesEquipmentRegistryItemsWithItemIdRequestDefinitions = (definitionsArguments: {| requestArgs: getSpacesEquipmentRegistryItemsWithItemIdRequestSpacesArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getSpacesEquipmentRegistryItemsWithItemIdRequestSpacesArguments, payload?: Object |} => ({
  fnName: 'getSpacesEquipmentRegistryItemsWithItemIdRequest',
  basePath: '/spaces-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_GET_SPACES_EQUIPMENT_WITH_ID_START = 'DATA_GET_SPACES_EQUIPMENT_WITH_ID_START'
export const DATA_GET_SPACES_EQUIPMENT_WITH_ID_SUCCESSFUL = 'DATA_GET_SPACES_EQUIPMENT_WITH_ID_SUCCESSFUL'
export const DATA_GET_SPACES_EQUIPMENT_WITH_ID_FAIL = 'DATA_GET_SPACES_EQUIPMENT_WITH_ID_FAIL'

// Gets a piece of equipment associated with a space
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/spaces.yaml#/Spaces/get_spaces__spaceId__equipment__id_
export const getSpacesEquipmentWithIdRequest = (
  requestArguments: getSpacesEquipmentWithIdRequestSpacesArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_SPACES_EQUIPMENT_WITH_ID_START',
      successful: 'DATA_GET_SPACES_EQUIPMENT_WITH_ID_SUCCESSFUL',
      fail: 'DATA_GET_SPACES_EQUIPMENT_WITH_ID_FAIL'
    },
    embeddedType: {
      nonEmbedded: {
        schemaPath: '#/definitions/ScheduleItem',
        schemaKey: 'ScheduleItem'
      }
    },
    fnName: 'getSpacesEquipmentWithIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/spaces-api`,
    method: 'GET',
    url: `/spaces/${requestArguments.path['spaceId']}/equipment/${requestArguments.path['id']}`,
    params: {
      estimateId: options?.estimateId || store.app.calculation,
      spacesEstimateType: options?.spacesEstimateType || store.app.spacesEstimateType,
      spacesResultView: options?.spacesResultView || store.app.spacesResultView,
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      nonEmbedded: {
        schemaPath: '#/definitions/ScheduleItem',
        schemaKey: 'ScheduleItem'
      }
    }, 'getSpacesEquipmentWithIdRequest', '/spaces-api'),
    actions: {
      start: {
        type: DATA_GET_SPACES_EQUIPMENT_WITH_ID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_SPACES_EQUIPMENT_WITH_ID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_SPACES_EQUIPMENT_WITH_ID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getSpacesEquipmentWithIdRequestDefinitions = (definitionsArguments: {| requestArgs: getSpacesEquipmentWithIdRequestSpacesArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getSpacesEquipmentWithIdRequestSpacesArguments, payload?: Object |} => ({
  fnName: 'getSpacesEquipmentWithIdRequest',
  basePath: '/spaces-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_PATCH_SPACES_EQUIPMENT_WITH_ID_START = 'DATA_PATCH_SPACES_EQUIPMENT_WITH_ID_START'
export const DATA_PATCH_SPACES_EQUIPMENT_WITH_ID_SUCCESSFUL = 'DATA_PATCH_SPACES_EQUIPMENT_WITH_ID_SUCCESSFUL'
export const DATA_PATCH_SPACES_EQUIPMENT_WITH_ID_FAIL = 'DATA_PATCH_SPACES_EQUIPMENT_WITH_ID_FAIL'

// Update equipment column data. Will return a queue location header if calculation is needed.
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/spaces.yaml#/Spaces/patch_spaces__spaceId__equipment__id_
export const patchSpacesEquipmentWithIdRequest = (
  requestArguments: patchSpacesEquipmentWithIdRequestSpacesArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_PATCH_SPACES_EQUIPMENT_WITH_ID_START',
      successful: 'DATA_PATCH_SPACES_EQUIPMENT_WITH_ID_SUCCESSFUL',
      fail: 'DATA_PATCH_SPACES_EQUIPMENT_WITH_ID_FAIL'
    },
    embeddedType: null,
    fnName: 'patchSpacesEquipmentWithIdRequest'
  }
  const config = {
    headers: { 'Content-Type': 'application/json' },
    baseURL: `${API_BASE_URL}/spaces-api`,
    method: 'PATCH',
    url: `/spaces/${requestArguments.path['spaceId']}/equipment/${requestArguments.path['id']}`,
    params: {
      estimateId: options?.estimateId || store.app.calculation,
      spacesEstimateType: options?.spacesEstimateType || store.app.spacesEstimateType,
      spacesResultView: options?.spacesResultView || store.app.spacesResultView,
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: requestArguments.body
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'patchSpacesEquipmentWithIdRequest', '/spaces-api'),
    actions: {
      start: {
        type: DATA_PATCH_SPACES_EQUIPMENT_WITH_ID_START, payload, config, meta
      },
      successful: {
        type: DATA_PATCH_SPACES_EQUIPMENT_WITH_ID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_PATCH_SPACES_EQUIPMENT_WITH_ID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_DELETE_SPACES_EQUIPMENT_WITH_ID_START = 'DATA_DELETE_SPACES_EQUIPMENT_WITH_ID_START'
export const DATA_DELETE_SPACES_EQUIPMENT_WITH_ID_SUCCESSFUL = 'DATA_DELETE_SPACES_EQUIPMENT_WITH_ID_SUCCESSFUL'
export const DATA_DELETE_SPACES_EQUIPMENT_WITH_ID_FAIL = 'DATA_DELETE_SPACES_EQUIPMENT_WITH_ID_FAIL'

// Deletes a piece of equipment
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/spaces.yaml#/Spaces/delete_spaces__spaceId__equipment__id_
export const deleteSpacesEquipmentWithIdRequest = (
  requestArguments: deleteSpacesEquipmentWithIdRequestSpacesArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_DELETE_SPACES_EQUIPMENT_WITH_ID_START',
      successful: 'DATA_DELETE_SPACES_EQUIPMENT_WITH_ID_SUCCESSFUL',
      fail: 'DATA_DELETE_SPACES_EQUIPMENT_WITH_ID_FAIL'
    },
    embeddedType: null,
    fnName: 'deleteSpacesEquipmentWithIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/spaces-api`,
    method: 'DELETE',
    url: `/spaces/${requestArguments.path['spaceId']}/equipment/${requestArguments.path['id']}`,
    params: {
      estimateId: options?.estimateId || store.app.calculation,
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'deleteSpacesEquipmentWithIdRequest', '/spaces-api'),
    actions: {
      start: {
        type: DATA_DELETE_SPACES_EQUIPMENT_WITH_ID_START, payload, config, meta
      },
      successful: {
        type: DATA_DELETE_SPACES_EQUIPMENT_WITH_ID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_DELETE_SPACES_EQUIPMENT_WITH_ID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_POST_SPACES_EQUIPMENT_WITH_ID_START = 'DATA_POST_SPACES_EQUIPMENT_WITH_ID_START'
export const DATA_POST_SPACES_EQUIPMENT_WITH_ID_SUCCESSFUL = 'DATA_POST_SPACES_EQUIPMENT_WITH_ID_SUCCESSFUL'
export const DATA_POST_SPACES_EQUIPMENT_WITH_ID_FAIL = 'DATA_POST_SPACES_EQUIPMENT_WITH_ID_FAIL'

// Add a new space equipment
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/spaces.yaml#/Spaces/post_spaces__spaceId__equipment__id_
export const postSpacesEquipmentWithIdRequest = (
  requestArguments: postSpacesEquipmentWithIdRequestSpacesArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_POST_SPACES_EQUIPMENT_WITH_ID_START',
      successful: 'DATA_POST_SPACES_EQUIPMENT_WITH_ID_SUCCESSFUL',
      fail: 'DATA_POST_SPACES_EQUIPMENT_WITH_ID_FAIL'
    },
    embeddedType: null,
    fnName: 'postSpacesEquipmentWithIdRequest'
  }
  const config = {
    headers: { 'Content-Type': 'application/json' },
    baseURL: `${API_BASE_URL}/spaces-api`,
    method: 'POST',
    url: `/spaces/${requestArguments.path['spaceId']}/equipment/${requestArguments.path['id']}`,
    params: {
      estimateId: options?.estimateId || store.app.calculation,
      spacesEstimateType: options?.spacesEstimateType || store.app.spacesEstimateType,
      spacesResultView: options?.spacesResultView || store.app.spacesResultView,
      registryItemId: requestArguments.query?.['registryItemId'],
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: requestArguments.body
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'postSpacesEquipmentWithIdRequest', '/spaces-api'),
    actions: {
      start: {
        type: DATA_POST_SPACES_EQUIPMENT_WITH_ID_START, payload, config, meta
      },
      successful: {
        type: DATA_POST_SPACES_EQUIPMENT_WITH_ID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_POST_SPACES_EQUIPMENT_WITH_ID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_GET_SPACES_EQUIPMENT_INFO_WITH_ITEMID_START = 'DATA_GET_SPACES_EQUIPMENT_INFO_WITH_ITEMID_START'
export const DATA_GET_SPACES_EQUIPMENT_INFO_WITH_ITEMID_SUCCESSFUL = 'DATA_GET_SPACES_EQUIPMENT_INFO_WITH_ITEMID_SUCCESSFUL'
export const DATA_GET_SPACES_EQUIPMENT_INFO_WITH_ITEMID_FAIL = 'DATA_GET_SPACES_EQUIPMENT_INFO_WITH_ITEMID_FAIL'

// Returns information text about an equipment list item (typically a heading item)
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/spaces.yaml#/Spaces/get_spaces__spaceId__equipment__itemId__info
export const getSpacesEquipmentInfoWithItemIdRequest = (
  requestArguments: getSpacesEquipmentInfoWithItemIdRequestSpacesArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_SPACES_EQUIPMENT_INFO_WITH_ITEMID_START',
      successful: 'DATA_GET_SPACES_EQUIPMENT_INFO_WITH_ITEMID_SUCCESSFUL',
      fail: 'DATA_GET_SPACES_EQUIPMENT_INFO_WITH_ITEMID_FAIL'
    },
    embeddedType: null,
    fnName: 'getSpacesEquipmentInfoWithItemIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/spaces-api`,
    method: 'GET',
    url: `/spaces/${requestArguments.path['spaceId']}/equipment/${requestArguments.path['itemId']}/info`,
    params: {
      estimateId: options?.estimateId || store.app.calculation,
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'getSpacesEquipmentInfoWithItemIdRequest', '/spaces-api'),
    actions: {
      start: {
        type: DATA_GET_SPACES_EQUIPMENT_INFO_WITH_ITEMID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_SPACES_EQUIPMENT_INFO_WITH_ITEMID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_SPACES_EQUIPMENT_INFO_WITH_ITEMID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getSpacesEquipmentInfoWithItemIdRequestDefinitions = (definitionsArguments: {| requestArgs: getSpacesEquipmentInfoWithItemIdRequestSpacesArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getSpacesEquipmentInfoWithItemIdRequestSpacesArguments, payload?: Object |} => ({
  fnName: 'getSpacesEquipmentInfoWithItemIdRequest',
  basePath: '/spaces-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_GET_SPACES_EQUIPMENT_ITEMS_WITH_ITEMID_START = 'DATA_GET_SPACES_EQUIPMENT_ITEMS_WITH_ITEMID_START'
export const DATA_GET_SPACES_EQUIPMENT_ITEMS_WITH_ITEMID_SUCCESSFUL = 'DATA_GET_SPACES_EQUIPMENT_ITEMS_WITH_ITEMID_SUCCESSFUL'
export const DATA_GET_SPACES_EQUIPMENT_ITEMS_WITH_ITEMID_FAIL = 'DATA_GET_SPACES_EQUIPMENT_ITEMS_WITH_ITEMID_FAIL'

// Returns equipment under an equipment list item
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/spaces.yaml#/Spaces/get_spaces__spaceId__equipment__itemId__items
export const getSpacesEquipmentItemsWithItemIdRequest = (
  requestArguments: getSpacesEquipmentItemsWithItemIdRequestSpacesArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_SPACES_EQUIPMENT_ITEMS_WITH_ITEMID_START',
      successful: 'DATA_GET_SPACES_EQUIPMENT_ITEMS_WITH_ITEMID_SUCCESSFUL',
      fail: 'DATA_GET_SPACES_EQUIPMENT_ITEMS_WITH_ITEMID_FAIL'
    },
    embeddedType: {
      items: {
        schemaPath: '#/definitions/ScheduleItem',
        schemaKey: 'ScheduleItem'
      }
    },
    fnName: 'getSpacesEquipmentItemsWithItemIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/spaces-api`,
    method: 'GET',
    url: `/spaces/${requestArguments.path['spaceId']}/equipment/${requestArguments.path['itemId']}/items`,
    params: {
      estimateId: options?.estimateId || store.app.calculation,
      spacesEstimateType: options?.spacesEstimateType || store.app.spacesEstimateType,
      spacesResultView: options?.spacesResultView || store.app.spacesResultView,
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      items: {
        schemaPath: '#/definitions/ScheduleItem',
        schemaKey: 'ScheduleItem'
      }
    }, 'getSpacesEquipmentItemsWithItemIdRequest', '/spaces-api'),
    actions: {
      start: {
        type: DATA_GET_SPACES_EQUIPMENT_ITEMS_WITH_ITEMID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_SPACES_EQUIPMENT_ITEMS_WITH_ITEMID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_SPACES_EQUIPMENT_ITEMS_WITH_ITEMID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getSpacesEquipmentItemsWithItemIdRequestDefinitions = (definitionsArguments: {| requestArgs: getSpacesEquipmentItemsWithItemIdRequestSpacesArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getSpacesEquipmentItemsWithItemIdRequestSpacesArguments, payload?: Object |} => ({
  fnName: 'getSpacesEquipmentItemsWithItemIdRequest',
  basePath: '/spaces-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_GET_SPACES_EQUIPMENT_ITEMS_DEFAULT_WITH_ITEMID_START = 'DATA_GET_SPACES_EQUIPMENT_ITEMS_DEFAULT_WITH_ITEMID_START'
export const DATA_GET_SPACES_EQUIPMENT_ITEMS_DEFAULT_WITH_ITEMID_SUCCESSFUL = 'DATA_GET_SPACES_EQUIPMENT_ITEMS_DEFAULT_WITH_ITEMID_SUCCESSFUL'
export const DATA_GET_SPACES_EQUIPMENT_ITEMS_DEFAULT_WITH_ITEMID_FAIL = 'DATA_GET_SPACES_EQUIPMENT_ITEMS_DEFAULT_WITH_ITEMID_FAIL'

// Returns computed default equipment under a surfaces list item
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/spaces.yaml#/Spaces/get_spaces__spaceId__equipment__itemId__items_default
export const getSpacesEquipmentItemsDefaultWithItemIdRequest = (
  requestArguments: getSpacesEquipmentItemsDefaultWithItemIdRequestSpacesArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_SPACES_EQUIPMENT_ITEMS_DEFAULT_WITH_ITEMID_START',
      successful: 'DATA_GET_SPACES_EQUIPMENT_ITEMS_DEFAULT_WITH_ITEMID_SUCCESSFUL',
      fail: 'DATA_GET_SPACES_EQUIPMENT_ITEMS_DEFAULT_WITH_ITEMID_FAIL'
    },
    embeddedType: {
      items: {
        schemaPath: '#/definitions/ScheduleItem',
        schemaKey: 'ScheduleItem'
      }
    },
    fnName: 'getSpacesEquipmentItemsDefaultWithItemIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/spaces-api`,
    method: 'GET',
    url: `/spaces/${requestArguments.path['spaceId']}/equipment/${requestArguments.path['itemId']}/items/default`,
    params: {
      estimateId: options?.estimateId || store.app.calculation,
      spacesEstimateType: options?.spacesEstimateType || store.app.spacesEstimateType,
      spacesResultView: options?.spacesResultView || store.app.spacesResultView,
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      items: {
        schemaPath: '#/definitions/ScheduleItem',
        schemaKey: 'ScheduleItem'
      }
    }, 'getSpacesEquipmentItemsDefaultWithItemIdRequest', '/spaces-api'),
    actions: {
      start: {
        type: DATA_GET_SPACES_EQUIPMENT_ITEMS_DEFAULT_WITH_ITEMID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_SPACES_EQUIPMENT_ITEMS_DEFAULT_WITH_ITEMID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_SPACES_EQUIPMENT_ITEMS_DEFAULT_WITH_ITEMID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getSpacesEquipmentItemsDefaultWithItemIdRequestDefinitions = (definitionsArguments: {| requestArgs: getSpacesEquipmentItemsDefaultWithItemIdRequestSpacesArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getSpacesEquipmentItemsDefaultWithItemIdRequestSpacesArguments, payload?: Object |} => ({
  fnName: 'getSpacesEquipmentItemsDefaultWithItemIdRequest',
  basePath: '/spaces-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_GET_SPACES_EQUIPMENT_PROPERTIES_INFO_WITH_PROPERTYNAME_START = 'DATA_GET_SPACES_EQUIPMENT_PROPERTIES_INFO_WITH_PROPERTYNAME_START'
export const DATA_GET_SPACES_EQUIPMENT_PROPERTIES_INFO_WITH_PROPERTYNAME_SUCCESSFUL = 'DATA_GET_SPACES_EQUIPMENT_PROPERTIES_INFO_WITH_PROPERTYNAME_SUCCESSFUL'
export const DATA_GET_SPACES_EQUIPMENT_PROPERTIES_INFO_WITH_PROPERTYNAME_FAIL = 'DATA_GET_SPACES_EQUIPMENT_PROPERTIES_INFO_WITH_PROPERTYNAME_FAIL'

// Get short information text for a space equipment property
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/spaces.yaml#/Spaces/get_spaces__spaceId__equipment__id__properties_info__propertyName_
export const getSpacesEquipmentPropertiesInfoWithPropertyNameRequest = (
  requestArguments: getSpacesEquipmentPropertiesInfoWithPropertyNameRequestSpacesArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_SPACES_EQUIPMENT_PROPERTIES_INFO_WITH_PROPERTYNAME_START',
      successful: 'DATA_GET_SPACES_EQUIPMENT_PROPERTIES_INFO_WITH_PROPERTYNAME_SUCCESSFUL',
      fail: 'DATA_GET_SPACES_EQUIPMENT_PROPERTIES_INFO_WITH_PROPERTYNAME_FAIL'
    },
    embeddedType: null,
    fnName: 'getSpacesEquipmentPropertiesInfoWithPropertyNameRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/spaces-api`,
    method: 'GET',
    url: `/spaces/${requestArguments.path['spaceId']}/equipment/${requestArguments.path['id']}/properties/info/${requestArguments.path['propertyName']}`,
    params: {
      estimateId: options?.estimateId || store.app.calculation,
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'getSpacesEquipmentPropertiesInfoWithPropertyNameRequest', '/spaces-api'),
    actions: {
      start: {
        type: DATA_GET_SPACES_EQUIPMENT_PROPERTIES_INFO_WITH_PROPERTYNAME_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_SPACES_EQUIPMENT_PROPERTIES_INFO_WITH_PROPERTYNAME_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_SPACES_EQUIPMENT_PROPERTIES_INFO_WITH_PROPERTYNAME_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getSpacesEquipmentPropertiesInfoWithPropertyNameRequestDefinitions = (definitionsArguments: {| requestArgs: getSpacesEquipmentPropertiesInfoWithPropertyNameRequestSpacesArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getSpacesEquipmentPropertiesInfoWithPropertyNameRequestSpacesArguments, payload?: Object |} => ({
  fnName: 'getSpacesEquipmentPropertiesInfoWithPropertyNameRequest',
  basePath: '/spaces-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_GET_SPACES_EQUIPMENT_PROPERTIES_WITH_ID_START = 'DATA_GET_SPACES_EQUIPMENT_PROPERTIES_WITH_ID_START'
export const DATA_GET_SPACES_EQUIPMENT_PROPERTIES_WITH_ID_SUCCESSFUL = 'DATA_GET_SPACES_EQUIPMENT_PROPERTIES_WITH_ID_SUCCESSFUL'
export const DATA_GET_SPACES_EQUIPMENT_PROPERTIES_WITH_ID_FAIL = 'DATA_GET_SPACES_EQUIPMENT_PROPERTIES_WITH_ID_FAIL'

// Gets properties of a piece of equipment
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/spaces.yaml#/Spaces/get_spaces__spaceId__equipment__id__properties
export const getSpacesEquipmentPropertiesWithIdRequest = (
  requestArguments: getSpacesEquipmentPropertiesWithIdRequestSpacesArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_SPACES_EQUIPMENT_PROPERTIES_WITH_ID_START',
      successful: 'DATA_GET_SPACES_EQUIPMENT_PROPERTIES_WITH_ID_SUCCESSFUL',
      fail: 'DATA_GET_SPACES_EQUIPMENT_PROPERTIES_WITH_ID_FAIL'
    },
    embeddedType: {
      properties: {
        schemaPath: '#/definitions/ValueProperty',
        schemaKey: 'ValueProperty'
      }
    },
    fnName: 'getSpacesEquipmentPropertiesWithIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/spaces-api`,
    method: 'GET',
    url: `/spaces/${requestArguments.path['spaceId']}/equipment/${requestArguments.path['id']}/properties`,
    params: {
      estimateId: options?.estimateId || store.app.calculation,
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      properties: {
        schemaPath: '#/definitions/ValueProperty',
        schemaKey: 'ValueProperty'
      }
    }, 'getSpacesEquipmentPropertiesWithIdRequest', '/spaces-api'),
    actions: {
      start: {
        type: DATA_GET_SPACES_EQUIPMENT_PROPERTIES_WITH_ID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_SPACES_EQUIPMENT_PROPERTIES_WITH_ID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_SPACES_EQUIPMENT_PROPERTIES_WITH_ID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getSpacesEquipmentPropertiesWithIdRequestDefinitions = (definitionsArguments: {| requestArgs: getSpacesEquipmentPropertiesWithIdRequestSpacesArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getSpacesEquipmentPropertiesWithIdRequestSpacesArguments, payload?: Object |} => ({
  fnName: 'getSpacesEquipmentPropertiesWithIdRequest',
  basePath: '/spaces-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_PATCH_SPACES_EQUIPMENT_PROPERTIES_WITH_ID_START = 'DATA_PATCH_SPACES_EQUIPMENT_PROPERTIES_WITH_ID_START'
export const DATA_PATCH_SPACES_EQUIPMENT_PROPERTIES_WITH_ID_SUCCESSFUL = 'DATA_PATCH_SPACES_EQUIPMENT_PROPERTIES_WITH_ID_SUCCESSFUL'
export const DATA_PATCH_SPACES_EQUIPMENT_PROPERTIES_WITH_ID_FAIL = 'DATA_PATCH_SPACES_EQUIPMENT_PROPERTIES_WITH_ID_FAIL'

// Update equipment properties. Will return a queue location header if calculation is needed.
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/spaces.yaml#/Spaces/patch_spaces__spaceId__equipment__id__properties
export const patchSpacesEquipmentPropertiesWithIdRequest = (
  requestArguments: patchSpacesEquipmentPropertiesWithIdRequestSpacesArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_PATCH_SPACES_EQUIPMENT_PROPERTIES_WITH_ID_START',
      successful: 'DATA_PATCH_SPACES_EQUIPMENT_PROPERTIES_WITH_ID_SUCCESSFUL',
      fail: 'DATA_PATCH_SPACES_EQUIPMENT_PROPERTIES_WITH_ID_FAIL'
    },
    embeddedType: null,
    fnName: 'patchSpacesEquipmentPropertiesWithIdRequest'
  }
  const config = {
    headers: { 'Content-Type': 'application/json' },
    baseURL: `${API_BASE_URL}/spaces-api`,
    method: 'PATCH',
    url: `/spaces/${requestArguments.path['spaceId']}/equipment/${requestArguments.path['id']}/properties`,
    params: {
      estimateId: options?.estimateId || store.app.calculation,
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: requestArguments.body
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'patchSpacesEquipmentPropertiesWithIdRequest', '/spaces-api'),
    actions: {
      start: {
        type: DATA_PATCH_SPACES_EQUIPMENT_PROPERTIES_WITH_ID_START, payload, config, meta
      },
      successful: {
        type: DATA_PATCH_SPACES_EQUIPMENT_PROPERTIES_WITH_ID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_PATCH_SPACES_EQUIPMENT_PROPERTIES_WITH_ID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_GET_SPACES_SURFACES_WITH_SPACEID_START = 'DATA_GET_SPACES_SURFACES_WITH_SPACEID_START'
export const DATA_GET_SPACES_SURFACES_WITH_SPACEID_SUCCESSFUL = 'DATA_GET_SPACES_SURFACES_WITH_SPACEID_SUCCESSFUL'
export const DATA_GET_SPACES_SURFACES_WITH_SPACEID_FAIL = 'DATA_GET_SPACES_SURFACES_WITH_SPACEID_FAIL'

// Returns surfaces list for a space
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/spaces.yaml#/Spaces/get_spaces__spaceId__surfaces
export const getSpacesSurfacesWithSpaceIdRequest = (
  requestArguments: getSpacesSurfacesWithSpaceIdRequestSpacesArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_SPACES_SURFACES_WITH_SPACEID_START',
      successful: 'DATA_GET_SPACES_SURFACES_WITH_SPACEID_SUCCESSFUL',
      fail: 'DATA_GET_SPACES_SURFACES_WITH_SPACEID_FAIL'
    },
    embeddedType: {
      items: {
        schemaPath: '#/definitions/ScheduleItem',
        schemaKey: 'ScheduleItem'
      }
    },
    fnName: 'getSpacesSurfacesWithSpaceIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/spaces-api`,
    method: 'GET',
    url: `/spaces/${requestArguments.path['spaceId']}/surfaces`,
    params: {
      estimateId: options?.estimateId || store.app.calculation,
      spacesEstimateType: options?.spacesEstimateType || store.app.spacesEstimateType,
      spacesResultView: options?.spacesResultView || store.app.spacesResultView,
      listType: requestArguments.query['listType'],
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      items: {
        schemaPath: '#/definitions/ScheduleItem',
        schemaKey: 'ScheduleItem'
      }
    }, 'getSpacesSurfacesWithSpaceIdRequest', '/spaces-api'),
    actions: {
      start: {
        type: DATA_GET_SPACES_SURFACES_WITH_SPACEID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_SPACES_SURFACES_WITH_SPACEID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_SPACES_SURFACES_WITH_SPACEID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getSpacesSurfacesWithSpaceIdRequestDefinitions = (definitionsArguments: {| requestArgs: getSpacesSurfacesWithSpaceIdRequestSpacesArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getSpacesSurfacesWithSpaceIdRequestSpacesArguments, payload?: Object |} => ({
  fnName: 'getSpacesSurfacesWithSpaceIdRequest',
  basePath: '/spaces-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_GET_SPACES_SURFACES_COLUMNS_WITH_SPACEID_START = 'DATA_GET_SPACES_SURFACES_COLUMNS_WITH_SPACEID_START'
export const DATA_GET_SPACES_SURFACES_COLUMNS_WITH_SPACEID_SUCCESSFUL = 'DATA_GET_SPACES_SURFACES_COLUMNS_WITH_SPACEID_SUCCESSFUL'
export const DATA_GET_SPACES_SURFACES_COLUMNS_WITH_SPACEID_FAIL = 'DATA_GET_SPACES_SURFACES_COLUMNS_WITH_SPACEID_FAIL'

// Get columns for surfaces list
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/spaces.yaml#/Spaces/get_spaces__spaceId__surfaces_columns
export const getSpacesSurfacesColumnsWithSpaceIdRequest = (
  requestArguments: getSpacesSurfacesColumnsWithSpaceIdRequestSpacesArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_SPACES_SURFACES_COLUMNS_WITH_SPACEID_START',
      successful: 'DATA_GET_SPACES_SURFACES_COLUMNS_WITH_SPACEID_SUCCESSFUL',
      fail: 'DATA_GET_SPACES_SURFACES_COLUMNS_WITH_SPACEID_FAIL'
    },
    embeddedType: {
      properties: {
        schemaPath: '#/definitions/ValueProperty',
        schemaKey: 'ValueProperty'
      }
    },
    fnName: 'getSpacesSurfacesColumnsWithSpaceIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/spaces-api`,
    method: 'GET',
    url: `/spaces/${requestArguments.path['spaceId']}/surfaces/columns`,
    params: {
      estimateId: options?.estimateId || store.app.calculation,
      spacesEstimateType: options?.spacesEstimateType || store.app.spacesEstimateType,
      spacesResultView: options?.spacesResultView || store.app.spacesResultView,
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      properties: {
        schemaPath: '#/definitions/ValueProperty',
        schemaKey: 'ValueProperty'
      }
    }, 'getSpacesSurfacesColumnsWithSpaceIdRequest', '/spaces-api'),
    actions: {
      start: {
        type: DATA_GET_SPACES_SURFACES_COLUMNS_WITH_SPACEID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_SPACES_SURFACES_COLUMNS_WITH_SPACEID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_SPACES_SURFACES_COLUMNS_WITH_SPACEID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getSpacesSurfacesColumnsWithSpaceIdRequestDefinitions = (definitionsArguments: {| requestArgs: getSpacesSurfacesColumnsWithSpaceIdRequestSpacesArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getSpacesSurfacesColumnsWithSpaceIdRequestSpacesArguments, payload?: Object |} => ({
  fnName: 'getSpacesSurfacesColumnsWithSpaceIdRequest',
  basePath: '/spaces-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_GET_SPACES_SURFACES_REGISTRY_WITH_SPACEID_START = 'DATA_GET_SPACES_SURFACES_REGISTRY_WITH_SPACEID_START'
export const DATA_GET_SPACES_SURFACES_REGISTRY_WITH_SPACEID_SUCCESSFUL = 'DATA_GET_SPACES_SURFACES_REGISTRY_WITH_SPACEID_SUCCESSFUL'
export const DATA_GET_SPACES_SURFACES_REGISTRY_WITH_SPACEID_FAIL = 'DATA_GET_SPACES_SURFACES_REGISTRY_WITH_SPACEID_FAIL'

// Gets registry list of space surfaces
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/spaces.yaml#/Spaces/get_spaces__spaceId__surfaces_registry
export const getSpacesSurfacesRegistryWithSpaceIdRequest = (
  requestArguments: getSpacesSurfacesRegistryWithSpaceIdRequestSpacesArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_SPACES_SURFACES_REGISTRY_WITH_SPACEID_START',
      successful: 'DATA_GET_SPACES_SURFACES_REGISTRY_WITH_SPACEID_SUCCESSFUL',
      fail: 'DATA_GET_SPACES_SURFACES_REGISTRY_WITH_SPACEID_FAIL'
    },
    embeddedType: {
      items: {
        schemaPath: '#/definitions/ScheduleItem',
        schemaKey: 'ScheduleItem'
      }
    },
    fnName: 'getSpacesSurfacesRegistryWithSpaceIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/spaces-api`,
    method: 'GET',
    url: `/spaces/${requestArguments.path['spaceId']}/surfaces/registry`,
    params: {
      estimateId: options?.estimateId || store.app.calculation,
      spacesEstimateType: options?.spacesEstimateType || store.app.spacesEstimateType,
      spacesResultView: options?.spacesResultView || store.app.spacesResultView,
      listType: requestArguments.query['listType'],
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      items: {
        schemaPath: '#/definitions/ScheduleItem',
        schemaKey: 'ScheduleItem'
      }
    }, 'getSpacesSurfacesRegistryWithSpaceIdRequest', '/spaces-api'),
    actions: {
      start: {
        type: DATA_GET_SPACES_SURFACES_REGISTRY_WITH_SPACEID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_SPACES_SURFACES_REGISTRY_WITH_SPACEID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_SPACES_SURFACES_REGISTRY_WITH_SPACEID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getSpacesSurfacesRegistryWithSpaceIdRequestDefinitions = (definitionsArguments: {| requestArgs: getSpacesSurfacesRegistryWithSpaceIdRequestSpacesArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getSpacesSurfacesRegistryWithSpaceIdRequestSpacesArguments, payload?: Object |} => ({
  fnName: 'getSpacesSurfacesRegistryWithSpaceIdRequest',
  basePath: '/spaces-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_GET_SPACES_SURFACES_REGISTRY_COLUMNS_WITH_SPACEID_START = 'DATA_GET_SPACES_SURFACES_REGISTRY_COLUMNS_WITH_SPACEID_START'
export const DATA_GET_SPACES_SURFACES_REGISTRY_COLUMNS_WITH_SPACEID_SUCCESSFUL = 'DATA_GET_SPACES_SURFACES_REGISTRY_COLUMNS_WITH_SPACEID_SUCCESSFUL'
export const DATA_GET_SPACES_SURFACES_REGISTRY_COLUMNS_WITH_SPACEID_FAIL = 'DATA_GET_SPACES_SURFACES_REGISTRY_COLUMNS_WITH_SPACEID_FAIL'

// Get columns for space surface registry
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/spaces.yaml#/Spaces/get_spaces__spaceId__surfaces_registry_columns
export const getSpacesSurfacesRegistryColumnsWithSpaceIdRequest = (
  requestArguments: getSpacesSurfacesRegistryColumnsWithSpaceIdRequestSpacesArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_SPACES_SURFACES_REGISTRY_COLUMNS_WITH_SPACEID_START',
      successful: 'DATA_GET_SPACES_SURFACES_REGISTRY_COLUMNS_WITH_SPACEID_SUCCESSFUL',
      fail: 'DATA_GET_SPACES_SURFACES_REGISTRY_COLUMNS_WITH_SPACEID_FAIL'
    },
    embeddedType: {
      properties: {
        schemaPath: '#/definitions/ValueProperty',
        schemaKey: 'ValueProperty'
      }
    },
    fnName: 'getSpacesSurfacesRegistryColumnsWithSpaceIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/spaces-api`,
    method: 'GET',
    url: `/spaces/${requestArguments.path['spaceId']}/surfaces/registry/columns`,
    params: {
      estimateId: options?.estimateId || store.app.calculation,
      spacesEstimateType: options?.spacesEstimateType || store.app.spacesEstimateType,
      spacesResultView: options?.spacesResultView || store.app.spacesResultView,
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      properties: {
        schemaPath: '#/definitions/ValueProperty',
        schemaKey: 'ValueProperty'
      }
    }, 'getSpacesSurfacesRegistryColumnsWithSpaceIdRequest', '/spaces-api'),
    actions: {
      start: {
        type: DATA_GET_SPACES_SURFACES_REGISTRY_COLUMNS_WITH_SPACEID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_SPACES_SURFACES_REGISTRY_COLUMNS_WITH_SPACEID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_SPACES_SURFACES_REGISTRY_COLUMNS_WITH_SPACEID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getSpacesSurfacesRegistryColumnsWithSpaceIdRequestDefinitions = (definitionsArguments: {| requestArgs: getSpacesSurfacesRegistryColumnsWithSpaceIdRequestSpacesArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getSpacesSurfacesRegistryColumnsWithSpaceIdRequestSpacesArguments, payload?: Object |} => ({
  fnName: 'getSpacesSurfacesRegistryColumnsWithSpaceIdRequest',
  basePath: '/spaces-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_GET_SPACES_SURFACES_REGISTRY_ITEMS_WITH_ITEMID_START = 'DATA_GET_SPACES_SURFACES_REGISTRY_ITEMS_WITH_ITEMID_START'
export const DATA_GET_SPACES_SURFACES_REGISTRY_ITEMS_WITH_ITEMID_SUCCESSFUL = 'DATA_GET_SPACES_SURFACES_REGISTRY_ITEMS_WITH_ITEMID_SUCCESSFUL'
export const DATA_GET_SPACES_SURFACES_REGISTRY_ITEMS_WITH_ITEMID_FAIL = 'DATA_GET_SPACES_SURFACES_REGISTRY_ITEMS_WITH_ITEMID_FAIL'

// Gets registry list of surfaces under a parent item
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/spaces.yaml#/Spaces/get_spaces__spaceId__surfaces_registry__itemId__items
export const getSpacesSurfacesRegistryItemsWithItemIdRequest = (
  requestArguments: getSpacesSurfacesRegistryItemsWithItemIdRequestSpacesArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_SPACES_SURFACES_REGISTRY_ITEMS_WITH_ITEMID_START',
      successful: 'DATA_GET_SPACES_SURFACES_REGISTRY_ITEMS_WITH_ITEMID_SUCCESSFUL',
      fail: 'DATA_GET_SPACES_SURFACES_REGISTRY_ITEMS_WITH_ITEMID_FAIL'
    },
    embeddedType: {
      items: {
        schemaPath: '#/definitions/ScheduleItem',
        schemaKey: 'ScheduleItem'
      }
    },
    fnName: 'getSpacesSurfacesRegistryItemsWithItemIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/spaces-api`,
    method: 'GET',
    url: `/spaces/${requestArguments.path['spaceId']}/surfaces/registry/${requestArguments.path['itemId']}/items`,
    params: {
      estimateId: options?.estimateId || store.app.calculation,
      spacesEstimateType: options?.spacesEstimateType || store.app.spacesEstimateType,
      spacesResultView: options?.spacesResultView || store.app.spacesResultView,
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      items: {
        schemaPath: '#/definitions/ScheduleItem',
        schemaKey: 'ScheduleItem'
      }
    }, 'getSpacesSurfacesRegistryItemsWithItemIdRequest', '/spaces-api'),
    actions: {
      start: {
        type: DATA_GET_SPACES_SURFACES_REGISTRY_ITEMS_WITH_ITEMID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_SPACES_SURFACES_REGISTRY_ITEMS_WITH_ITEMID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_SPACES_SURFACES_REGISTRY_ITEMS_WITH_ITEMID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getSpacesSurfacesRegistryItemsWithItemIdRequestDefinitions = (definitionsArguments: {| requestArgs: getSpacesSurfacesRegistryItemsWithItemIdRequestSpacesArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getSpacesSurfacesRegistryItemsWithItemIdRequestSpacesArguments, payload?: Object |} => ({
  fnName: 'getSpacesSurfacesRegistryItemsWithItemIdRequest',
  basePath: '/spaces-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_GET_SPACES_SURFACES_WITH_ID_START = 'DATA_GET_SPACES_SURFACES_WITH_ID_START'
export const DATA_GET_SPACES_SURFACES_WITH_ID_SUCCESSFUL = 'DATA_GET_SPACES_SURFACES_WITH_ID_SUCCESSFUL'
export const DATA_GET_SPACES_SURFACES_WITH_ID_FAIL = 'DATA_GET_SPACES_SURFACES_WITH_ID_FAIL'

// Gets a surface associated with a space
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/spaces.yaml#/Spaces/get_spaces__spaceId__surfaces__id_
export const getSpacesSurfacesWithIdRequest = (
  requestArguments: getSpacesSurfacesWithIdRequestSpacesArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_SPACES_SURFACES_WITH_ID_START',
      successful: 'DATA_GET_SPACES_SURFACES_WITH_ID_SUCCESSFUL',
      fail: 'DATA_GET_SPACES_SURFACES_WITH_ID_FAIL'
    },
    embeddedType: {
      nonEmbedded: {
        schemaPath: '#/definitions/ScheduleItem',
        schemaKey: 'ScheduleItem'
      }
    },
    fnName: 'getSpacesSurfacesWithIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/spaces-api`,
    method: 'GET',
    url: `/spaces/${requestArguments.path['spaceId']}/surfaces/${requestArguments.path['id']}`,
    params: {
      estimateId: options?.estimateId || store.app.calculation,
      spacesEstimateType: options?.spacesEstimateType || store.app.spacesEstimateType,
      spacesResultView: options?.spacesResultView || store.app.spacesResultView,
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      nonEmbedded: {
        schemaPath: '#/definitions/ScheduleItem',
        schemaKey: 'ScheduleItem'
      }
    }, 'getSpacesSurfacesWithIdRequest', '/spaces-api'),
    actions: {
      start: {
        type: DATA_GET_SPACES_SURFACES_WITH_ID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_SPACES_SURFACES_WITH_ID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_SPACES_SURFACES_WITH_ID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getSpacesSurfacesWithIdRequestDefinitions = (definitionsArguments: {| requestArgs: getSpacesSurfacesWithIdRequestSpacesArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getSpacesSurfacesWithIdRequestSpacesArguments, payload?: Object |} => ({
  fnName: 'getSpacesSurfacesWithIdRequest',
  basePath: '/spaces-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_PATCH_SPACES_SURFACES_WITH_ID_START = 'DATA_PATCH_SPACES_SURFACES_WITH_ID_START'
export const DATA_PATCH_SPACES_SURFACES_WITH_ID_SUCCESSFUL = 'DATA_PATCH_SPACES_SURFACES_WITH_ID_SUCCESSFUL'
export const DATA_PATCH_SPACES_SURFACES_WITH_ID_FAIL = 'DATA_PATCH_SPACES_SURFACES_WITH_ID_FAIL'

// Update surface column data. Will return a queue location header if calculation is needed.
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/spaces.yaml#/Spaces/patch_spaces__spaceId__surfaces__id_
export const patchSpacesSurfacesWithIdRequest = (
  requestArguments: patchSpacesSurfacesWithIdRequestSpacesArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_PATCH_SPACES_SURFACES_WITH_ID_START',
      successful: 'DATA_PATCH_SPACES_SURFACES_WITH_ID_SUCCESSFUL',
      fail: 'DATA_PATCH_SPACES_SURFACES_WITH_ID_FAIL'
    },
    embeddedType: null,
    fnName: 'patchSpacesSurfacesWithIdRequest'
  }
  const config = {
    headers: { 'Content-Type': 'application/json' },
    baseURL: `${API_BASE_URL}/spaces-api`,
    method: 'PATCH',
    url: `/spaces/${requestArguments.path['spaceId']}/surfaces/${requestArguments.path['id']}`,
    params: {
      estimateId: options?.estimateId || store.app.calculation,
      spacesEstimateType: options?.spacesEstimateType || store.app.spacesEstimateType,
      spacesResultView: options?.spacesResultView || store.app.spacesResultView,
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: requestArguments.body
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'patchSpacesSurfacesWithIdRequest', '/spaces-api'),
    actions: {
      start: {
        type: DATA_PATCH_SPACES_SURFACES_WITH_ID_START, payload, config, meta
      },
      successful: {
        type: DATA_PATCH_SPACES_SURFACES_WITH_ID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_PATCH_SPACES_SURFACES_WITH_ID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_DELETE_SPACES_SURFACES_WITH_ID_START = 'DATA_DELETE_SPACES_SURFACES_WITH_ID_START'
export const DATA_DELETE_SPACES_SURFACES_WITH_ID_SUCCESSFUL = 'DATA_DELETE_SPACES_SURFACES_WITH_ID_SUCCESSFUL'
export const DATA_DELETE_SPACES_SURFACES_WITH_ID_FAIL = 'DATA_DELETE_SPACES_SURFACES_WITH_ID_FAIL'

// Deletes a surface
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/spaces.yaml#/Spaces/delete_spaces__spaceId__surfaces__id_
export const deleteSpacesSurfacesWithIdRequest = (
  requestArguments: deleteSpacesSurfacesWithIdRequestSpacesArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_DELETE_SPACES_SURFACES_WITH_ID_START',
      successful: 'DATA_DELETE_SPACES_SURFACES_WITH_ID_SUCCESSFUL',
      fail: 'DATA_DELETE_SPACES_SURFACES_WITH_ID_FAIL'
    },
    embeddedType: null,
    fnName: 'deleteSpacesSurfacesWithIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/spaces-api`,
    method: 'DELETE',
    url: `/spaces/${requestArguments.path['spaceId']}/surfaces/${requestArguments.path['id']}`,
    params: {
      estimateId: options?.estimateId || store.app.calculation,
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'deleteSpacesSurfacesWithIdRequest', '/spaces-api'),
    actions: {
      start: {
        type: DATA_DELETE_SPACES_SURFACES_WITH_ID_START, payload, config, meta
      },
      successful: {
        type: DATA_DELETE_SPACES_SURFACES_WITH_ID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_DELETE_SPACES_SURFACES_WITH_ID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_POST_SPACES_SURFACES_WITH_ID_START = 'DATA_POST_SPACES_SURFACES_WITH_ID_START'
export const DATA_POST_SPACES_SURFACES_WITH_ID_SUCCESSFUL = 'DATA_POST_SPACES_SURFACES_WITH_ID_SUCCESSFUL'
export const DATA_POST_SPACES_SURFACES_WITH_ID_FAIL = 'DATA_POST_SPACES_SURFACES_WITH_ID_FAIL'

// Add a new space surface
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/spaces.yaml#/Spaces/post_spaces__spaceId__surfaces__id_
export const postSpacesSurfacesWithIdRequest = (
  requestArguments: postSpacesSurfacesWithIdRequestSpacesArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_POST_SPACES_SURFACES_WITH_ID_START',
      successful: 'DATA_POST_SPACES_SURFACES_WITH_ID_SUCCESSFUL',
      fail: 'DATA_POST_SPACES_SURFACES_WITH_ID_FAIL'
    },
    embeddedType: null,
    fnName: 'postSpacesSurfacesWithIdRequest'
  }
  const config = {
    headers: { 'Content-Type': 'application/json' },
    baseURL: `${API_BASE_URL}/spaces-api`,
    method: 'POST',
    url: `/spaces/${requestArguments.path['spaceId']}/surfaces/${requestArguments.path['id']}`,
    params: {
      estimateId: options?.estimateId || store.app.calculation,
      spacesEstimateType: options?.spacesEstimateType || store.app.spacesEstimateType,
      spacesResultView: options?.spacesResultView || store.app.spacesResultView,
      registryItemId: requestArguments.query?.['registryItemId'],
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: requestArguments.body
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'postSpacesSurfacesWithIdRequest', '/spaces-api'),
    actions: {
      start: {
        type: DATA_POST_SPACES_SURFACES_WITH_ID_START, payload, config, meta
      },
      successful: {
        type: DATA_POST_SPACES_SURFACES_WITH_ID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_POST_SPACES_SURFACES_WITH_ID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_GET_SPACES_SURFACES_INFO_WITH_ITEMID_START = 'DATA_GET_SPACES_SURFACES_INFO_WITH_ITEMID_START'
export const DATA_GET_SPACES_SURFACES_INFO_WITH_ITEMID_SUCCESSFUL = 'DATA_GET_SPACES_SURFACES_INFO_WITH_ITEMID_SUCCESSFUL'
export const DATA_GET_SPACES_SURFACES_INFO_WITH_ITEMID_FAIL = 'DATA_GET_SPACES_SURFACES_INFO_WITH_ITEMID_FAIL'

// Returns information text about a surfaces list item (typically a heading item)
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/spaces.yaml#/Spaces/get_spaces__spaceId__surfaces__itemId__info
export const getSpacesSurfacesInfoWithItemIdRequest = (
  requestArguments: getSpacesSurfacesInfoWithItemIdRequestSpacesArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_SPACES_SURFACES_INFO_WITH_ITEMID_START',
      successful: 'DATA_GET_SPACES_SURFACES_INFO_WITH_ITEMID_SUCCESSFUL',
      fail: 'DATA_GET_SPACES_SURFACES_INFO_WITH_ITEMID_FAIL'
    },
    embeddedType: null,
    fnName: 'getSpacesSurfacesInfoWithItemIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/spaces-api`,
    method: 'GET',
    url: `/spaces/${requestArguments.path['spaceId']}/surfaces/${requestArguments.path['itemId']}/info`,
    params: {
      estimateId: options?.estimateId || store.app.calculation,
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'getSpacesSurfacesInfoWithItemIdRequest', '/spaces-api'),
    actions: {
      start: {
        type: DATA_GET_SPACES_SURFACES_INFO_WITH_ITEMID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_SPACES_SURFACES_INFO_WITH_ITEMID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_SPACES_SURFACES_INFO_WITH_ITEMID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getSpacesSurfacesInfoWithItemIdRequestDefinitions = (definitionsArguments: {| requestArgs: getSpacesSurfacesInfoWithItemIdRequestSpacesArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getSpacesSurfacesInfoWithItemIdRequestSpacesArguments, payload?: Object |} => ({
  fnName: 'getSpacesSurfacesInfoWithItemIdRequest',
  basePath: '/spaces-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_GET_SPACES_SURFACES_ITEMS_WITH_ITEMID_START = 'DATA_GET_SPACES_SURFACES_ITEMS_WITH_ITEMID_START'
export const DATA_GET_SPACES_SURFACES_ITEMS_WITH_ITEMID_SUCCESSFUL = 'DATA_GET_SPACES_SURFACES_ITEMS_WITH_ITEMID_SUCCESSFUL'
export const DATA_GET_SPACES_SURFACES_ITEMS_WITH_ITEMID_FAIL = 'DATA_GET_SPACES_SURFACES_ITEMS_WITH_ITEMID_FAIL'

// Returns items under a surfaces heading
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/spaces.yaml#/Spaces/get_spaces__spaceId__surfaces__itemId__items
export const getSpacesSurfacesItemsWithItemIdRequest = (
  requestArguments: getSpacesSurfacesItemsWithItemIdRequestSpacesArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_SPACES_SURFACES_ITEMS_WITH_ITEMID_START',
      successful: 'DATA_GET_SPACES_SURFACES_ITEMS_WITH_ITEMID_SUCCESSFUL',
      fail: 'DATA_GET_SPACES_SURFACES_ITEMS_WITH_ITEMID_FAIL'
    },
    embeddedType: {
      properties: {
        schemaPath: '#/definitions/ScheduleItem',
        schemaKey: 'ScheduleItem'
      }
    },
    fnName: 'getSpacesSurfacesItemsWithItemIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/spaces-api`,
    method: 'GET',
    url: `/spaces/${requestArguments.path['spaceId']}/surfaces/${requestArguments.path['itemId']}/items`,
    params: {
      estimateId: options?.estimateId || store.app.calculation,
      spacesEstimateType: options?.spacesEstimateType || store.app.spacesEstimateType,
      spacesResultView: options?.spacesResultView || store.app.spacesResultView,
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      properties: {
        schemaPath: '#/definitions/ScheduleItem',
        schemaKey: 'ScheduleItem'
      }
    }, 'getSpacesSurfacesItemsWithItemIdRequest', '/spaces-api'),
    actions: {
      start: {
        type: DATA_GET_SPACES_SURFACES_ITEMS_WITH_ITEMID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_SPACES_SURFACES_ITEMS_WITH_ITEMID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_SPACES_SURFACES_ITEMS_WITH_ITEMID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getSpacesSurfacesItemsWithItemIdRequestDefinitions = (definitionsArguments: {| requestArgs: getSpacesSurfacesItemsWithItemIdRequestSpacesArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getSpacesSurfacesItemsWithItemIdRequestSpacesArguments, payload?: Object |} => ({
  fnName: 'getSpacesSurfacesItemsWithItemIdRequest',
  basePath: '/spaces-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_GET_SPACES_SURFACES_ASSEMBLY_ITEMS_WITH_ASSEMBLYID_START = 'DATA_GET_SPACES_SURFACES_ASSEMBLY_ITEMS_WITH_ASSEMBLYID_START'
export const DATA_GET_SPACES_SURFACES_ASSEMBLY_ITEMS_WITH_ASSEMBLYID_SUCCESSFUL = 'DATA_GET_SPACES_SURFACES_ASSEMBLY_ITEMS_WITH_ASSEMBLYID_SUCCESSFUL'
export const DATA_GET_SPACES_SURFACES_ASSEMBLY_ITEMS_WITH_ASSEMBLYID_FAIL = 'DATA_GET_SPACES_SURFACES_ASSEMBLY_ITEMS_WITH_ASSEMBLYID_FAIL'

// Returns price items belonging to a surface assembly
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/spaces.yaml#/Spaces/get_spaces__spaceId__surfaces__assemblyId__assembly_items
export const getSpacesSurfacesAssemblyItemsWithAssemblyIdRequest = (
  requestArguments: getSpacesSurfacesAssemblyItemsWithAssemblyIdRequestSpacesArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_SPACES_SURFACES_ASSEMBLY_ITEMS_WITH_ASSEMBLYID_START',
      successful: 'DATA_GET_SPACES_SURFACES_ASSEMBLY_ITEMS_WITH_ASSEMBLYID_SUCCESSFUL',
      fail: 'DATA_GET_SPACES_SURFACES_ASSEMBLY_ITEMS_WITH_ASSEMBLYID_FAIL'
    },
    embeddedType: {
      properties: {
        schemaPath: '#/definitions/ScheduleItem',
        schemaKey: 'ScheduleItem'
      }
    },
    fnName: 'getSpacesSurfacesAssemblyItemsWithAssemblyIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/spaces-api`,
    method: 'GET',
    url: `/spaces/${requestArguments.path['spaceId']}/surfaces/${requestArguments.path['assemblyId']}/assembly-items`,
    params: {
      estimateId: options?.estimateId || store.app.calculation,
      spacesEstimateType: options?.spacesEstimateType || store.app.spacesEstimateType,
      spacesResultView: options?.spacesResultView || store.app.spacesResultView,
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      properties: {
        schemaPath: '#/definitions/ScheduleItem',
        schemaKey: 'ScheduleItem'
      }
    }, 'getSpacesSurfacesAssemblyItemsWithAssemblyIdRequest', '/spaces-api'),
    actions: {
      start: {
        type: DATA_GET_SPACES_SURFACES_ASSEMBLY_ITEMS_WITH_ASSEMBLYID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_SPACES_SURFACES_ASSEMBLY_ITEMS_WITH_ASSEMBLYID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_SPACES_SURFACES_ASSEMBLY_ITEMS_WITH_ASSEMBLYID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getSpacesSurfacesAssemblyItemsWithAssemblyIdRequestDefinitions = (definitionsArguments: {| requestArgs: getSpacesSurfacesAssemblyItemsWithAssemblyIdRequestSpacesArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getSpacesSurfacesAssemblyItemsWithAssemblyIdRequestSpacesArguments, payload?: Object |} => ({
  fnName: 'getSpacesSurfacesAssemblyItemsWithAssemblyIdRequest',
  basePath: '/spaces-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_POST_SPACES_SURFACES_ASSEMBLY_ITEMS_WITH_ASSEMBLYID_START = 'DATA_POST_SPACES_SURFACES_ASSEMBLY_ITEMS_WITH_ASSEMBLYID_START'
export const DATA_POST_SPACES_SURFACES_ASSEMBLY_ITEMS_WITH_ASSEMBLYID_SUCCESSFUL = 'DATA_POST_SPACES_SURFACES_ASSEMBLY_ITEMS_WITH_ASSEMBLYID_SUCCESSFUL'
export const DATA_POST_SPACES_SURFACES_ASSEMBLY_ITEMS_WITH_ASSEMBLYID_FAIL = 'DATA_POST_SPACES_SURFACES_ASSEMBLY_ITEMS_WITH_ASSEMBLYID_FAIL'

// Adds a price item to a space surface assembly
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/spaces.yaml#/Spaces/post_spaces__spaceId__surfaces__assemblyId__assembly_items
export const postSpacesSurfacesAssemblyItemsWithAssemblyIdRequest = (
  requestArguments: postSpacesSurfacesAssemblyItemsWithAssemblyIdRequestSpacesArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_POST_SPACES_SURFACES_ASSEMBLY_ITEMS_WITH_ASSEMBLYID_START',
      successful: 'DATA_POST_SPACES_SURFACES_ASSEMBLY_ITEMS_WITH_ASSEMBLYID_SUCCESSFUL',
      fail: 'DATA_POST_SPACES_SURFACES_ASSEMBLY_ITEMS_WITH_ASSEMBLYID_FAIL'
    },
    embeddedType: null,
    fnName: 'postSpacesSurfacesAssemblyItemsWithAssemblyIdRequest'
  }
  const config = {
    headers: { 'Content-Type': 'application/json' },
    baseURL: `${API_BASE_URL}/spaces-api`,
    method: 'POST',
    url: `/spaces/${requestArguments.path['spaceId']}/surfaces/${requestArguments.path['assemblyId']}/assembly-items`,
    params: {
      estimateId: options?.estimateId || store.app.calculation,
      surfaceId: requestArguments.query['surfaceId'],
      pricelistItemId: requestArguments.query['pricelistItemId'],
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: requestArguments.body
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'postSpacesSurfacesAssemblyItemsWithAssemblyIdRequest', '/spaces-api'),
    actions: {
      start: {
        type: DATA_POST_SPACES_SURFACES_ASSEMBLY_ITEMS_WITH_ASSEMBLYID_START, payload, config, meta
      },
      successful: {
        type: DATA_POST_SPACES_SURFACES_ASSEMBLY_ITEMS_WITH_ASSEMBLYID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_POST_SPACES_SURFACES_ASSEMBLY_ITEMS_WITH_ASSEMBLYID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_DELETE_SPACES_SURFACES_ASSEMBLY_ITEMS_WITH_ITEMID_START = 'DATA_DELETE_SPACES_SURFACES_ASSEMBLY_ITEMS_WITH_ITEMID_START'
export const DATA_DELETE_SPACES_SURFACES_ASSEMBLY_ITEMS_WITH_ITEMID_SUCCESSFUL = 'DATA_DELETE_SPACES_SURFACES_ASSEMBLY_ITEMS_WITH_ITEMID_SUCCESSFUL'
export const DATA_DELETE_SPACES_SURFACES_ASSEMBLY_ITEMS_WITH_ITEMID_FAIL = 'DATA_DELETE_SPACES_SURFACES_ASSEMBLY_ITEMS_WITH_ITEMID_FAIL'

// Removes a price item from surface assembly
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/spaces.yaml#/Spaces/delete_spaces__spaceId__surfaces__assemblyId__assembly_items__itemId_
export const deleteSpacesSurfacesAssemblyItemsWithItemIdRequest = (
  requestArguments: deleteSpacesSurfacesAssemblyItemsWithItemIdRequestSpacesArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_DELETE_SPACES_SURFACES_ASSEMBLY_ITEMS_WITH_ITEMID_START',
      successful: 'DATA_DELETE_SPACES_SURFACES_ASSEMBLY_ITEMS_WITH_ITEMID_SUCCESSFUL',
      fail: 'DATA_DELETE_SPACES_SURFACES_ASSEMBLY_ITEMS_WITH_ITEMID_FAIL'
    },
    embeddedType: null,
    fnName: 'deleteSpacesSurfacesAssemblyItemsWithItemIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/spaces-api`,
    method: 'DELETE',
    url: `/spaces/${requestArguments.path['spaceId']}/surfaces/${requestArguments.path['assemblyId']}/assembly-items/${requestArguments.path['itemId']}`,
    params: {
      estimateId: options?.estimateId || store.app.calculation,
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'deleteSpacesSurfacesAssemblyItemsWithItemIdRequest', '/spaces-api'),
    actions: {
      start: {
        type: DATA_DELETE_SPACES_SURFACES_ASSEMBLY_ITEMS_WITH_ITEMID_START, payload, config, meta
      },
      successful: {
        type: DATA_DELETE_SPACES_SURFACES_ASSEMBLY_ITEMS_WITH_ITEMID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_DELETE_SPACES_SURFACES_ASSEMBLY_ITEMS_WITH_ITEMID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_GET_SPACES_SURFACES_PRICELIST_WITH_ASSEMBLYID_START = 'DATA_GET_SPACES_SURFACES_PRICELIST_WITH_ASSEMBLYID_START'
export const DATA_GET_SPACES_SURFACES_PRICELIST_WITH_ASSEMBLYID_SUCCESSFUL = 'DATA_GET_SPACES_SURFACES_PRICELIST_WITH_ASSEMBLYID_SUCCESSFUL'
export const DATA_GET_SPACES_SURFACES_PRICELIST_WITH_ASSEMBLYID_FAIL = 'DATA_GET_SPACES_SURFACES_PRICELIST_WITH_ASSEMBLYID_FAIL'

// Returns price items as Enumerals that can be added to this surface assembly.
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/spaces.yaml#/Spaces/get_spaces__spaceId__surfaces__assemblyId__pricelist
export const getSpacesSurfacesPricelistWithAssemblyIdRequest = (
  requestArguments: getSpacesSurfacesPricelistWithAssemblyIdRequestSpacesArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_SPACES_SURFACES_PRICELIST_WITH_ASSEMBLYID_START',
      successful: 'DATA_GET_SPACES_SURFACES_PRICELIST_WITH_ASSEMBLYID_SUCCESSFUL',
      fail: 'DATA_GET_SPACES_SURFACES_PRICELIST_WITH_ASSEMBLYID_FAIL'
    },
    embeddedType: {
      items: {
        schemaPath: '#/definitions/Enumeral',
        schemaKey: 'Enumeral'
      }
    },
    fnName: 'getSpacesSurfacesPricelistWithAssemblyIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/spaces-api`,
    method: 'GET',
    url: `/spaces/${requestArguments.path['spaceId']}/surfaces/${requestArguments.path['assemblyId']}/pricelist`,
    params: {
      estimateId: options?.estimateId || store.app.calculation,
      spacesEstimateType: options?.spacesEstimateType || store.app.spacesEstimateType,
      spacesResultView: options?.spacesResultView || store.app.spacesResultView,
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      items: {
        schemaPath: '#/definitions/Enumeral',
        schemaKey: 'Enumeral'
      }
    }, 'getSpacesSurfacesPricelistWithAssemblyIdRequest', '/spaces-api'),
    actions: {
      start: {
        type: DATA_GET_SPACES_SURFACES_PRICELIST_WITH_ASSEMBLYID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_SPACES_SURFACES_PRICELIST_WITH_ASSEMBLYID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_SPACES_SURFACES_PRICELIST_WITH_ASSEMBLYID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getSpacesSurfacesPricelistWithAssemblyIdRequestDefinitions = (definitionsArguments: {| requestArgs: getSpacesSurfacesPricelistWithAssemblyIdRequestSpacesArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getSpacesSurfacesPricelistWithAssemblyIdRequestSpacesArguments, payload?: Object |} => ({
  fnName: 'getSpacesSurfacesPricelistWithAssemblyIdRequest',
  basePath: '/spaces-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_GET_SPACES_SURFACES_ITEMS_DEFAULT_WITH_ITEMID_START = 'DATA_GET_SPACES_SURFACES_ITEMS_DEFAULT_WITH_ITEMID_START'
export const DATA_GET_SPACES_SURFACES_ITEMS_DEFAULT_WITH_ITEMID_SUCCESSFUL = 'DATA_GET_SPACES_SURFACES_ITEMS_DEFAULT_WITH_ITEMID_SUCCESSFUL'
export const DATA_GET_SPACES_SURFACES_ITEMS_DEFAULT_WITH_ITEMID_FAIL = 'DATA_GET_SPACES_SURFACES_ITEMS_DEFAULT_WITH_ITEMID_FAIL'

// Returns computed default surfaces under a surfaces heading
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/spaces.yaml#/Spaces/get_spaces__spaceId__surfaces__itemId__items_default
export const getSpacesSurfacesItemsDefaultWithItemIdRequest = (
  requestArguments: getSpacesSurfacesItemsDefaultWithItemIdRequestSpacesArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_SPACES_SURFACES_ITEMS_DEFAULT_WITH_ITEMID_START',
      successful: 'DATA_GET_SPACES_SURFACES_ITEMS_DEFAULT_WITH_ITEMID_SUCCESSFUL',
      fail: 'DATA_GET_SPACES_SURFACES_ITEMS_DEFAULT_WITH_ITEMID_FAIL'
    },
    embeddedType: {
      properties: {
        schemaPath: '#/definitions/ScheduleItem',
        schemaKey: 'ScheduleItem'
      }
    },
    fnName: 'getSpacesSurfacesItemsDefaultWithItemIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/spaces-api`,
    method: 'GET',
    url: `/spaces/${requestArguments.path['spaceId']}/surfaces/${requestArguments.path['itemId']}/items/default`,
    params: {
      estimateId: options?.estimateId || store.app.calculation,
      spacesEstimateType: options?.spacesEstimateType || store.app.spacesEstimateType,
      spacesResultView: options?.spacesResultView || store.app.spacesResultView,
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      properties: {
        schemaPath: '#/definitions/ScheduleItem',
        schemaKey: 'ScheduleItem'
      }
    }, 'getSpacesSurfacesItemsDefaultWithItemIdRequest', '/spaces-api'),
    actions: {
      start: {
        type: DATA_GET_SPACES_SURFACES_ITEMS_DEFAULT_WITH_ITEMID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_SPACES_SURFACES_ITEMS_DEFAULT_WITH_ITEMID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_SPACES_SURFACES_ITEMS_DEFAULT_WITH_ITEMID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getSpacesSurfacesItemsDefaultWithItemIdRequestDefinitions = (definitionsArguments: {| requestArgs: getSpacesSurfacesItemsDefaultWithItemIdRequestSpacesArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getSpacesSurfacesItemsDefaultWithItemIdRequestSpacesArguments, payload?: Object |} => ({
  fnName: 'getSpacesSurfacesItemsDefaultWithItemIdRequest',
  basePath: '/spaces-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_GET_SPACES_SURFACES_PROPERTIES_INFO_WITH_PROPERTYNAME_START = 'DATA_GET_SPACES_SURFACES_PROPERTIES_INFO_WITH_PROPERTYNAME_START'
export const DATA_GET_SPACES_SURFACES_PROPERTIES_INFO_WITH_PROPERTYNAME_SUCCESSFUL = 'DATA_GET_SPACES_SURFACES_PROPERTIES_INFO_WITH_PROPERTYNAME_SUCCESSFUL'
export const DATA_GET_SPACES_SURFACES_PROPERTIES_INFO_WITH_PROPERTYNAME_FAIL = 'DATA_GET_SPACES_SURFACES_PROPERTIES_INFO_WITH_PROPERTYNAME_FAIL'

// Get short information text for a space surface property
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/spaces.yaml#/Spaces/get_spaces__spaceId__surfaces__id__properties_info__propertyName_
export const getSpacesSurfacesPropertiesInfoWithPropertyNameRequest = (
  requestArguments: getSpacesSurfacesPropertiesInfoWithPropertyNameRequestSpacesArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_SPACES_SURFACES_PROPERTIES_INFO_WITH_PROPERTYNAME_START',
      successful: 'DATA_GET_SPACES_SURFACES_PROPERTIES_INFO_WITH_PROPERTYNAME_SUCCESSFUL',
      fail: 'DATA_GET_SPACES_SURFACES_PROPERTIES_INFO_WITH_PROPERTYNAME_FAIL'
    },
    embeddedType: null,
    fnName: 'getSpacesSurfacesPropertiesInfoWithPropertyNameRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/spaces-api`,
    method: 'GET',
    url: `/spaces/${requestArguments.path['spaceId']}/surfaces/${requestArguments.path['id']}/properties/info/${requestArguments.path['propertyName']}`,
    params: {
      estimateId: options?.estimateId || store.app.calculation,
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'getSpacesSurfacesPropertiesInfoWithPropertyNameRequest', '/spaces-api'),
    actions: {
      start: {
        type: DATA_GET_SPACES_SURFACES_PROPERTIES_INFO_WITH_PROPERTYNAME_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_SPACES_SURFACES_PROPERTIES_INFO_WITH_PROPERTYNAME_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_SPACES_SURFACES_PROPERTIES_INFO_WITH_PROPERTYNAME_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getSpacesSurfacesPropertiesInfoWithPropertyNameRequestDefinitions = (definitionsArguments: {| requestArgs: getSpacesSurfacesPropertiesInfoWithPropertyNameRequestSpacesArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getSpacesSurfacesPropertiesInfoWithPropertyNameRequestSpacesArguments, payload?: Object |} => ({
  fnName: 'getSpacesSurfacesPropertiesInfoWithPropertyNameRequest',
  basePath: '/spaces-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_GET_SPACES_SURFACES_PROPERTIES_WITH_ID_START = 'DATA_GET_SPACES_SURFACES_PROPERTIES_WITH_ID_START'
export const DATA_GET_SPACES_SURFACES_PROPERTIES_WITH_ID_SUCCESSFUL = 'DATA_GET_SPACES_SURFACES_PROPERTIES_WITH_ID_SUCCESSFUL'
export const DATA_GET_SPACES_SURFACES_PROPERTIES_WITH_ID_FAIL = 'DATA_GET_SPACES_SURFACES_PROPERTIES_WITH_ID_FAIL'

// Gets properties of a surface
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/spaces.yaml#/Spaces/get_spaces__spaceId__surfaces__id__properties
export const getSpacesSurfacesPropertiesWithIdRequest = (
  requestArguments: getSpacesSurfacesPropertiesWithIdRequestSpacesArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_SPACES_SURFACES_PROPERTIES_WITH_ID_START',
      successful: 'DATA_GET_SPACES_SURFACES_PROPERTIES_WITH_ID_SUCCESSFUL',
      fail: 'DATA_GET_SPACES_SURFACES_PROPERTIES_WITH_ID_FAIL'
    },
    embeddedType: {
      properties: {
        schemaPath: '#/definitions/ValueProperty',
        schemaKey: 'ValueProperty'
      }
    },
    fnName: 'getSpacesSurfacesPropertiesWithIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/spaces-api`,
    method: 'GET',
    url: `/spaces/${requestArguments.path['spaceId']}/surfaces/${requestArguments.path['id']}/properties`,
    params: {
      estimateId: options?.estimateId || store.app.calculation,
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      properties: {
        schemaPath: '#/definitions/ValueProperty',
        schemaKey: 'ValueProperty'
      }
    }, 'getSpacesSurfacesPropertiesWithIdRequest', '/spaces-api'),
    actions: {
      start: {
        type: DATA_GET_SPACES_SURFACES_PROPERTIES_WITH_ID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_SPACES_SURFACES_PROPERTIES_WITH_ID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_SPACES_SURFACES_PROPERTIES_WITH_ID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getSpacesSurfacesPropertiesWithIdRequestDefinitions = (definitionsArguments: {| requestArgs: getSpacesSurfacesPropertiesWithIdRequestSpacesArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getSpacesSurfacesPropertiesWithIdRequestSpacesArguments, payload?: Object |} => ({
  fnName: 'getSpacesSurfacesPropertiesWithIdRequest',
  basePath: '/spaces-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_PATCH_SPACES_SURFACES_PROPERTIES_WITH_ID_START = 'DATA_PATCH_SPACES_SURFACES_PROPERTIES_WITH_ID_START'
export const DATA_PATCH_SPACES_SURFACES_PROPERTIES_WITH_ID_SUCCESSFUL = 'DATA_PATCH_SPACES_SURFACES_PROPERTIES_WITH_ID_SUCCESSFUL'
export const DATA_PATCH_SPACES_SURFACES_PROPERTIES_WITH_ID_FAIL = 'DATA_PATCH_SPACES_SURFACES_PROPERTIES_WITH_ID_FAIL'

// Update surface properties. Will return a queue location header if calculation is needed.
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/spaces.yaml#/Spaces/patch_spaces__spaceId__surfaces__id__properties
export const patchSpacesSurfacesPropertiesWithIdRequest = (
  requestArguments: patchSpacesSurfacesPropertiesWithIdRequestSpacesArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_PATCH_SPACES_SURFACES_PROPERTIES_WITH_ID_START',
      successful: 'DATA_PATCH_SPACES_SURFACES_PROPERTIES_WITH_ID_SUCCESSFUL',
      fail: 'DATA_PATCH_SPACES_SURFACES_PROPERTIES_WITH_ID_FAIL'
    },
    embeddedType: null,
    fnName: 'patchSpacesSurfacesPropertiesWithIdRequest'
  }
  const config = {
    headers: { 'Content-Type': 'application/json' },
    baseURL: `${API_BASE_URL}/spaces-api`,
    method: 'PATCH',
    url: `/spaces/${requestArguments.path['spaceId']}/surfaces/${requestArguments.path['id']}/properties`,
    params: {
      estimateId: options?.estimateId || store.app.calculation,
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: requestArguments.body
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'patchSpacesSurfacesPropertiesWithIdRequest', '/spaces-api'),
    actions: {
      start: {
        type: DATA_PATCH_SPACES_SURFACES_PROPERTIES_WITH_ID_START, payload, config, meta
      },
      successful: {
        type: DATA_PATCH_SPACES_SURFACES_PROPERTIES_WITH_ID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_PATCH_SPACES_SURFACES_PROPERTIES_WITH_ID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_GET_ESTIMATE_WITH_ESTIMATEID_START = 'DATA_GET_ESTIMATE_WITH_ESTIMATEID_START'
export const DATA_GET_ESTIMATE_WITH_ESTIMATEID_SUCCESSFUL = 'DATA_GET_ESTIMATE_WITH_ESTIMATEID_SUCCESSFUL'
export const DATA_GET_ESTIMATE_WITH_ESTIMATEID_FAIL = 'DATA_GET_ESTIMATE_WITH_ESTIMATEID_FAIL'

// Returns Space estimate properties
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/spaces.yaml#/Space%20Estimate/get_estimate__estimateId_
export const getEstimateWithEstimateIdRequest = (
  payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_ESTIMATE_WITH_ESTIMATEID_START',
      successful: 'DATA_GET_ESTIMATE_WITH_ESTIMATEID_SUCCESSFUL',
      fail: 'DATA_GET_ESTIMATE_WITH_ESTIMATEID_FAIL'
    },
    embeddedType: {
      nonEmbedded: {
        schemaPath: '#/definitions/Estimate',
        schemaKey: 'Estimate'
      }
    },
    fnName: 'getEstimateWithEstimateIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/spaces-api`,
    method: 'GET',
    url: `/estimate/${options?.estimateId || store.app.calculation}`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      nonEmbedded: {
        schemaPath: '#/definitions/Estimate',
        schemaKey: 'Estimate'
      }
    }, 'getEstimateWithEstimateIdRequest', '/spaces-api'),
    actions: {
      start: {
        type: DATA_GET_ESTIMATE_WITH_ESTIMATEID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_ESTIMATE_WITH_ESTIMATEID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_ESTIMATE_WITH_ESTIMATEID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getEstimateWithEstimateIdRequestDefinitions = (definitionsArguments: {| payload?: Object |}): {|
  ...TVDGARConfigWithoutRequestArgs, payload?: Object |} => ({
  fnName: 'getEstimateWithEstimateIdRequest',
  basePath: '/spaces-api',
  payload: definitionsArguments.payload,
})


export const DATA_GET_ESTIMATE_LOCK_WITH_ESTIMATEID_START = 'DATA_GET_ESTIMATE_LOCK_WITH_ESTIMATEID_START'
export const DATA_GET_ESTIMATE_LOCK_WITH_ESTIMATEID_SUCCESSFUL = 'DATA_GET_ESTIMATE_LOCK_WITH_ESTIMATEID_SUCCESSFUL'
export const DATA_GET_ESTIMATE_LOCK_WITH_ESTIMATEID_FAIL = 'DATA_GET_ESTIMATE_LOCK_WITH_ESTIMATEID_FAIL'

// If resource is locked, returns information (owner, expiration) about the current lock
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/spaces.yaml#/Space%20Estimate/get_estimate__estimateId__lock
export const getEstimateLockWithEstimateIdRequest = (
  payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_ESTIMATE_LOCK_WITH_ESTIMATEID_START',
      successful: 'DATA_GET_ESTIMATE_LOCK_WITH_ESTIMATEID_SUCCESSFUL',
      fail: 'DATA_GET_ESTIMATE_LOCK_WITH_ESTIMATEID_FAIL'
    },
    embeddedType: {
      nonEmbedded: {
        schemaPath: '#/definitions/ResourceLock',
        schemaKey: 'ResourceLock'
      }
    },
    fnName: 'getEstimateLockWithEstimateIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/spaces-api`,
    method: 'GET',
    url: `/estimate/${options?.estimateId || store.app.calculation}/lock`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      nonEmbedded: {
        schemaPath: '#/definitions/ResourceLock',
        schemaKey: 'ResourceLock'
      }
    }, 'getEstimateLockWithEstimateIdRequest', '/spaces-api'),
    actions: {
      start: {
        type: DATA_GET_ESTIMATE_LOCK_WITH_ESTIMATEID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_ESTIMATE_LOCK_WITH_ESTIMATEID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_ESTIMATE_LOCK_WITH_ESTIMATEID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getEstimateLockWithEstimateIdRequestDefinitions = (definitionsArguments: {| payload?: Object |}): {|
  ...TVDGARConfigWithoutRequestArgs, payload?: Object |} => ({
  fnName: 'getEstimateLockWithEstimateIdRequest',
  basePath: '/spaces-api',
  payload: definitionsArguments.payload,
})


export const DATA_PUT_ESTIMATE_LOCK_WITH_ESTIMATEID_START = 'DATA_PUT_ESTIMATE_LOCK_WITH_ESTIMATEID_START'
export const DATA_PUT_ESTIMATE_LOCK_WITH_ESTIMATEID_SUCCESSFUL = 'DATA_PUT_ESTIMATE_LOCK_WITH_ESTIMATEID_SUCCESSFUL'
export const DATA_PUT_ESTIMATE_LOCK_WITH_ESTIMATEID_FAIL = 'DATA_PUT_ESTIMATE_LOCK_WITH_ESTIMATEID_FAIL'

// Obtain a lock to a resource for editing. Edits are not permitted before first obtaining a lock. If another user has already locked the resource, Resource Locked Error (423) is returned.
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/spaces.yaml#/Space%20Estimate/put_estimate__estimateId__lock
export const putEstimateLockWithEstimateIdRequest = (
  requestArguments: putEstimateLockWithEstimateIdRequestSpacesArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_PUT_ESTIMATE_LOCK_WITH_ESTIMATEID_START',
      successful: 'DATA_PUT_ESTIMATE_LOCK_WITH_ESTIMATEID_SUCCESSFUL',
      fail: 'DATA_PUT_ESTIMATE_LOCK_WITH_ESTIMATEID_FAIL'
    },
    embeddedType: null,
    fnName: 'putEstimateLockWithEstimateIdRequest'
  }
  const config = {
    headers: { 'Content-Type': 'application/json' },
    baseURL: `${API_BASE_URL}/spaces-api`,
    method: 'PUT',
    url: `/estimate/${options?.estimateId || store.app.calculation}/lock`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: requestArguments.body
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'putEstimateLockWithEstimateIdRequest', '/spaces-api'),
    actions: {
      start: {
        type: DATA_PUT_ESTIMATE_LOCK_WITH_ESTIMATEID_START, payload, config, meta
      },
      successful: {
        type: DATA_PUT_ESTIMATE_LOCK_WITH_ESTIMATEID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_PUT_ESTIMATE_LOCK_WITH_ESTIMATEID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_DELETE_ESTIMATE_LOCK_WITH_ESTIMATEID_START = 'DATA_DELETE_ESTIMATE_LOCK_WITH_ESTIMATEID_START'
export const DATA_DELETE_ESTIMATE_LOCK_WITH_ESTIMATEID_SUCCESSFUL = 'DATA_DELETE_ESTIMATE_LOCK_WITH_ESTIMATEID_SUCCESSFUL'
export const DATA_DELETE_ESTIMATE_LOCK_WITH_ESTIMATEID_FAIL = 'DATA_DELETE_ESTIMATE_LOCK_WITH_ESTIMATEID_FAIL'

// Deletes a resource lock. If the lock was created by another user, 423 error is returned.
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/spaces.yaml#/Space%20Estimate/delete_estimate__estimateId__lock
export const deleteEstimateLockWithEstimateIdRequest = (
  payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_DELETE_ESTIMATE_LOCK_WITH_ESTIMATEID_START',
      successful: 'DATA_DELETE_ESTIMATE_LOCK_WITH_ESTIMATEID_SUCCESSFUL',
      fail: 'DATA_DELETE_ESTIMATE_LOCK_WITH_ESTIMATEID_FAIL'
    },
    embeddedType: null,
    fnName: 'deleteEstimateLockWithEstimateIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/spaces-api`,
    method: 'DELETE',
    url: `/estimate/${options?.estimateId || store.app.calculation}/lock`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'deleteEstimateLockWithEstimateIdRequest', '/spaces-api'),
    actions: {
      start: {
        type: DATA_DELETE_ESTIMATE_LOCK_WITH_ESTIMATEID_START, payload, config, meta
      },
      successful: {
        type: DATA_DELETE_ESTIMATE_LOCK_WITH_ESTIMATEID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_DELETE_ESTIMATE_LOCK_WITH_ESTIMATEID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_GET_ESTIMATE_PROPERTIES_INFO_WITH_PROPERTYNAME_START = 'DATA_GET_ESTIMATE_PROPERTIES_INFO_WITH_PROPERTYNAME_START'
export const DATA_GET_ESTIMATE_PROPERTIES_INFO_WITH_PROPERTYNAME_SUCCESSFUL = 'DATA_GET_ESTIMATE_PROPERTIES_INFO_WITH_PROPERTYNAME_SUCCESSFUL'
export const DATA_GET_ESTIMATE_PROPERTIES_INFO_WITH_PROPERTYNAME_FAIL = 'DATA_GET_ESTIMATE_PROPERTIES_INFO_WITH_PROPERTYNAME_FAIL'

// Get short information text for a estimate property
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/spaces.yaml#/Space%20Estimate/get_estimate__estimateId__properties_info__propertyName_
export const getEstimatePropertiesInfoWithPropertyNameRequest = (
  requestArguments: getEstimatePropertiesInfoWithPropertyNameRequestSpacesArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_ESTIMATE_PROPERTIES_INFO_WITH_PROPERTYNAME_START',
      successful: 'DATA_GET_ESTIMATE_PROPERTIES_INFO_WITH_PROPERTYNAME_SUCCESSFUL',
      fail: 'DATA_GET_ESTIMATE_PROPERTIES_INFO_WITH_PROPERTYNAME_FAIL'
    },
    embeddedType: null,
    fnName: 'getEstimatePropertiesInfoWithPropertyNameRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/spaces-api`,
    method: 'GET',
    url: `/estimate/${options?.estimateId || store.app.calculation}/properties/info/${requestArguments.path['propertyName']}`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'getEstimatePropertiesInfoWithPropertyNameRequest', '/spaces-api'),
    actions: {
      start: {
        type: DATA_GET_ESTIMATE_PROPERTIES_INFO_WITH_PROPERTYNAME_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_ESTIMATE_PROPERTIES_INFO_WITH_PROPERTYNAME_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_ESTIMATE_PROPERTIES_INFO_WITH_PROPERTYNAME_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getEstimatePropertiesInfoWithPropertyNameRequestDefinitions = (definitionsArguments: {| requestArgs: getEstimatePropertiesInfoWithPropertyNameRequestSpacesArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getEstimatePropertiesInfoWithPropertyNameRequestSpacesArguments, payload?: Object |} => ({
  fnName: 'getEstimatePropertiesInfoWithPropertyNameRequest',
  basePath: '/spaces-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_GET_ESTIMATE_PROPERTIES_WITH_ESTIMATEID_START = 'DATA_GET_ESTIMATE_PROPERTIES_WITH_ESTIMATEID_START'
export const DATA_GET_ESTIMATE_PROPERTIES_WITH_ESTIMATEID_SUCCESSFUL = 'DATA_GET_ESTIMATE_PROPERTIES_WITH_ESTIMATEID_SUCCESSFUL'
export const DATA_GET_ESTIMATE_PROPERTIES_WITH_ESTIMATEID_FAIL = 'DATA_GET_ESTIMATE_PROPERTIES_WITH_ESTIMATEID_FAIL'

// Returns properties of a space estimate
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/spaces.yaml#/Space%20Estimate/get_estimate__estimateId__properties
export const getEstimatePropertiesWithEstimateIdRequest = (
  payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_ESTIMATE_PROPERTIES_WITH_ESTIMATEID_START',
      successful: 'DATA_GET_ESTIMATE_PROPERTIES_WITH_ESTIMATEID_SUCCESSFUL',
      fail: 'DATA_GET_ESTIMATE_PROPERTIES_WITH_ESTIMATEID_FAIL'
    },
    embeddedType: {
      properties: {
        schemaPath: '#/definitions/ValueProperty',
        schemaKey: 'ValueProperty'
      }
    },
    fnName: 'getEstimatePropertiesWithEstimateIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/spaces-api`,
    method: 'GET',
    url: `/estimate/${options?.estimateId || store.app.calculation}/properties`,
    params: {
      spacesEstimateType: options?.spacesEstimateType || store.app.spacesEstimateType,
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      properties: {
        schemaPath: '#/definitions/ValueProperty',
        schemaKey: 'ValueProperty'
      }
    }, 'getEstimatePropertiesWithEstimateIdRequest', '/spaces-api'),
    actions: {
      start: {
        type: DATA_GET_ESTIMATE_PROPERTIES_WITH_ESTIMATEID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_ESTIMATE_PROPERTIES_WITH_ESTIMATEID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_ESTIMATE_PROPERTIES_WITH_ESTIMATEID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getEstimatePropertiesWithEstimateIdRequestDefinitions = (definitionsArguments: {| payload?: Object |}): {|
  ...TVDGARConfigWithoutRequestArgs, payload?: Object |} => ({
  fnName: 'getEstimatePropertiesWithEstimateIdRequest',
  basePath: '/spaces-api',
  payload: definitionsArguments.payload,
})


export const DATA_PATCH_ESTIMATE_PROPERTIES_WITH_ESTIMATEID_START = 'DATA_PATCH_ESTIMATE_PROPERTIES_WITH_ESTIMATEID_START'
export const DATA_PATCH_ESTIMATE_PROPERTIES_WITH_ESTIMATEID_SUCCESSFUL = 'DATA_PATCH_ESTIMATE_PROPERTIES_WITH_ESTIMATEID_SUCCESSFUL'
export const DATA_PATCH_ESTIMATE_PROPERTIES_WITH_ESTIMATEID_FAIL = 'DATA_PATCH_ESTIMATE_PROPERTIES_WITH_ESTIMATEID_FAIL'

// Update properties of a space estimate
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/spaces.yaml#/Space%20Estimate/patch_estimate__estimateId__properties
export const patchEstimatePropertiesWithEstimateIdRequest = (
  requestArguments: patchEstimatePropertiesWithEstimateIdRequestSpacesArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_PATCH_ESTIMATE_PROPERTIES_WITH_ESTIMATEID_START',
      successful: 'DATA_PATCH_ESTIMATE_PROPERTIES_WITH_ESTIMATEID_SUCCESSFUL',
      fail: 'DATA_PATCH_ESTIMATE_PROPERTIES_WITH_ESTIMATEID_FAIL'
    },
    embeddedType: null,
    fnName: 'patchEstimatePropertiesWithEstimateIdRequest'
  }
  const config = {
    headers: { 'Content-Type': 'application/json' },
    baseURL: `${API_BASE_URL}/spaces-api`,
    method: 'PATCH',
    url: `/estimate/${options?.estimateId || store.app.calculation}/properties`,
    params: {
      spacesEstimateType: options?.spacesEstimateType || store.app.spacesEstimateType,
      resetProperties: requestArguments.query?.['resetProperties'],
      updateBuildingServices: requestArguments.query?.['updateBuildingServices'],
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: requestArguments.body
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'patchEstimatePropertiesWithEstimateIdRequest', '/spaces-api'),
    actions: {
      start: {
        type: DATA_PATCH_ESTIMATE_PROPERTIES_WITH_ESTIMATEID_START, payload, config, meta
      },
      successful: {
        type: DATA_PATCH_ESTIMATE_PROPERTIES_WITH_ESTIMATEID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_PATCH_ESTIMATE_PROPERTIES_WITH_ESTIMATEID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_GET_ESTIMATE_SETTINGS_WITH_ESTIMATEID_START = 'DATA_GET_ESTIMATE_SETTINGS_WITH_ESTIMATEID_START'
export const DATA_GET_ESTIMATE_SETTINGS_WITH_ESTIMATEID_SUCCESSFUL = 'DATA_GET_ESTIMATE_SETTINGS_WITH_ESTIMATEID_SUCCESSFUL'
export const DATA_GET_ESTIMATE_SETTINGS_WITH_ESTIMATEID_FAIL = 'DATA_GET_ESTIMATE_SETTINGS_WITH_ESTIMATEID_FAIL'

// Returns settings of a space estimate
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/spaces.yaml#/Space%20Estimate/get_estimate__estimateId__settings
export const getEstimateSettingsWithEstimateIdRequest = (
  payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_ESTIMATE_SETTINGS_WITH_ESTIMATEID_START',
      successful: 'DATA_GET_ESTIMATE_SETTINGS_WITH_ESTIMATEID_SUCCESSFUL',
      fail: 'DATA_GET_ESTIMATE_SETTINGS_WITH_ESTIMATEID_FAIL'
    },
    embeddedType: {
      properties: {
        schemaPath: '#/definitions/ValueProperty',
        schemaKey: 'ValueProperty'
      }
    },
    fnName: 'getEstimateSettingsWithEstimateIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/spaces-api`,
    method: 'GET',
    url: `/estimate/${options?.estimateId || store.app.calculation}/settings`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      properties: {
        schemaPath: '#/definitions/ValueProperty',
        schemaKey: 'ValueProperty'
      }
    }, 'getEstimateSettingsWithEstimateIdRequest', '/spaces-api'),
    actions: {
      start: {
        type: DATA_GET_ESTIMATE_SETTINGS_WITH_ESTIMATEID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_ESTIMATE_SETTINGS_WITH_ESTIMATEID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_ESTIMATE_SETTINGS_WITH_ESTIMATEID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getEstimateSettingsWithEstimateIdRequestDefinitions = (definitionsArguments: {| payload?: Object |}): {|
  ...TVDGARConfigWithoutRequestArgs, payload?: Object |} => ({
  fnName: 'getEstimateSettingsWithEstimateIdRequest',
  basePath: '/spaces-api',
  payload: definitionsArguments.payload,
})


export const DATA_PATCH_ESTIMATE_SETTINGS_WITH_ESTIMATEID_START = 'DATA_PATCH_ESTIMATE_SETTINGS_WITH_ESTIMATEID_START'
export const DATA_PATCH_ESTIMATE_SETTINGS_WITH_ESTIMATEID_SUCCESSFUL = 'DATA_PATCH_ESTIMATE_SETTINGS_WITH_ESTIMATEID_SUCCESSFUL'
export const DATA_PATCH_ESTIMATE_SETTINGS_WITH_ESTIMATEID_FAIL = 'DATA_PATCH_ESTIMATE_SETTINGS_WITH_ESTIMATEID_FAIL'

// Update settings of a space estimate
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/spaces.yaml#/Space%20Estimate/patch_estimate__estimateId__settings
export const patchEstimateSettingsWithEstimateIdRequest = (
  requestArguments: patchEstimateSettingsWithEstimateIdRequestSpacesArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_PATCH_ESTIMATE_SETTINGS_WITH_ESTIMATEID_START',
      successful: 'DATA_PATCH_ESTIMATE_SETTINGS_WITH_ESTIMATEID_SUCCESSFUL',
      fail: 'DATA_PATCH_ESTIMATE_SETTINGS_WITH_ESTIMATEID_FAIL'
    },
    embeddedType: null,
    fnName: 'patchEstimateSettingsWithEstimateIdRequest'
  }
  const config = {
    headers: { 'Content-Type': 'application/json' },
    baseURL: `${API_BASE_URL}/spaces-api`,
    method: 'PATCH',
    url: `/estimate/${options?.estimateId || store.app.calculation}/settings`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: requestArguments.body
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'patchEstimateSettingsWithEstimateIdRequest', '/spaces-api'),
    actions: {
      start: {
        type: DATA_PATCH_ESTIMATE_SETTINGS_WITH_ESTIMATEID_START, payload, config, meta
      },
      successful: {
        type: DATA_PATCH_ESTIMATE_SETTINGS_WITH_ESTIMATEID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_PATCH_ESTIMATE_SETTINGS_WITH_ESTIMATEID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_GET_ESTIMATE_EQUIPMENT_WITH_ESTIMATEID_START = 'DATA_GET_ESTIMATE_EQUIPMENT_WITH_ESTIMATEID_START'
export const DATA_GET_ESTIMATE_EQUIPMENT_WITH_ESTIMATEID_SUCCESSFUL = 'DATA_GET_ESTIMATE_EQUIPMENT_WITH_ESTIMATEID_SUCCESSFUL'
export const DATA_GET_ESTIMATE_EQUIPMENT_WITH_ESTIMATEID_FAIL = 'DATA_GET_ESTIMATE_EQUIPMENT_WITH_ESTIMATEID_FAIL'

// Returns equipment list for a estimate
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/spaces.yaml#/Space%20Estimate/get_estimate__estimateId__equipment
export const getEstimateEquipmentWithEstimateIdRequest = (
  requestArguments: getEstimateEquipmentWithEstimateIdRequestSpacesArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_ESTIMATE_EQUIPMENT_WITH_ESTIMATEID_START',
      successful: 'DATA_GET_ESTIMATE_EQUIPMENT_WITH_ESTIMATEID_SUCCESSFUL',
      fail: 'DATA_GET_ESTIMATE_EQUIPMENT_WITH_ESTIMATEID_FAIL'
    },
    embeddedType: {
      items: {
        schemaPath: '#/definitions/ScheduleItem',
        schemaKey: 'ScheduleItem'
      }
    },
    fnName: 'getEstimateEquipmentWithEstimateIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/spaces-api`,
    method: 'GET',
    url: `/estimate/${options?.estimateId || store.app.calculation}/equipment`,
    params: {
      spacesEstimateType: options?.spacesEstimateType || store.app.spacesEstimateType,
      spacesResultView: options?.spacesResultView || store.app.spacesResultView,
      listType: requestArguments.query['listType'],
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      items: {
        schemaPath: '#/definitions/ScheduleItem',
        schemaKey: 'ScheduleItem'
      }
    }, 'getEstimateEquipmentWithEstimateIdRequest', '/spaces-api'),
    actions: {
      start: {
        type: DATA_GET_ESTIMATE_EQUIPMENT_WITH_ESTIMATEID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_ESTIMATE_EQUIPMENT_WITH_ESTIMATEID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_ESTIMATE_EQUIPMENT_WITH_ESTIMATEID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getEstimateEquipmentWithEstimateIdRequestDefinitions = (definitionsArguments: {| requestArgs: getEstimateEquipmentWithEstimateIdRequestSpacesArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getEstimateEquipmentWithEstimateIdRequestSpacesArguments, payload?: Object |} => ({
  fnName: 'getEstimateEquipmentWithEstimateIdRequest',
  basePath: '/spaces-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_GET_ESTIMATE_EQUIPMENT_COLUMNS_WITH_ESTIMATEID_START = 'DATA_GET_ESTIMATE_EQUIPMENT_COLUMNS_WITH_ESTIMATEID_START'
export const DATA_GET_ESTIMATE_EQUIPMENT_COLUMNS_WITH_ESTIMATEID_SUCCESSFUL = 'DATA_GET_ESTIMATE_EQUIPMENT_COLUMNS_WITH_ESTIMATEID_SUCCESSFUL'
export const DATA_GET_ESTIMATE_EQUIPMENT_COLUMNS_WITH_ESTIMATEID_FAIL = 'DATA_GET_ESTIMATE_EQUIPMENT_COLUMNS_WITH_ESTIMATEID_FAIL'

// Get columns for estimate equipment list
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/spaces.yaml#/Space%20Estimate/get_estimate__estimateId__equipment_columns
export const getEstimateEquipmentColumnsWithEstimateIdRequest = (
  payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_ESTIMATE_EQUIPMENT_COLUMNS_WITH_ESTIMATEID_START',
      successful: 'DATA_GET_ESTIMATE_EQUIPMENT_COLUMNS_WITH_ESTIMATEID_SUCCESSFUL',
      fail: 'DATA_GET_ESTIMATE_EQUIPMENT_COLUMNS_WITH_ESTIMATEID_FAIL'
    },
    embeddedType: {
      properties: {
        schemaPath: '#/definitions/ValueProperty',
        schemaKey: 'ValueProperty'
      }
    },
    fnName: 'getEstimateEquipmentColumnsWithEstimateIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/spaces-api`,
    method: 'GET',
    url: `/estimate/${options?.estimateId || store.app.calculation}/equipment/columns`,
    params: {
      spacesEstimateType: options?.spacesEstimateType || store.app.spacesEstimateType,
      spacesResultView: options?.spacesResultView || store.app.spacesResultView,
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      properties: {
        schemaPath: '#/definitions/ValueProperty',
        schemaKey: 'ValueProperty'
      }
    }, 'getEstimateEquipmentColumnsWithEstimateIdRequest', '/spaces-api'),
    actions: {
      start: {
        type: DATA_GET_ESTIMATE_EQUIPMENT_COLUMNS_WITH_ESTIMATEID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_ESTIMATE_EQUIPMENT_COLUMNS_WITH_ESTIMATEID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_ESTIMATE_EQUIPMENT_COLUMNS_WITH_ESTIMATEID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getEstimateEquipmentColumnsWithEstimateIdRequestDefinitions = (definitionsArguments: {| payload?: Object |}): {|
  ...TVDGARConfigWithoutRequestArgs, payload?: Object |} => ({
  fnName: 'getEstimateEquipmentColumnsWithEstimateIdRequest',
  basePath: '/spaces-api',
  payload: definitionsArguments.payload,
})


export const DATA_GET_ESTIMATE_EQUIPMENT_REGISTRY_WITH_ESTIMATEID_START = 'DATA_GET_ESTIMATE_EQUIPMENT_REGISTRY_WITH_ESTIMATEID_START'
export const DATA_GET_ESTIMATE_EQUIPMENT_REGISTRY_WITH_ESTIMATEID_SUCCESSFUL = 'DATA_GET_ESTIMATE_EQUIPMENT_REGISTRY_WITH_ESTIMATEID_SUCCESSFUL'
export const DATA_GET_ESTIMATE_EQUIPMENT_REGISTRY_WITH_ESTIMATEID_FAIL = 'DATA_GET_ESTIMATE_EQUIPMENT_REGISTRY_WITH_ESTIMATEID_FAIL'

// Gets registry list of equipment
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/spaces.yaml#/Space%20Estimate/get_estimate__estimateId__equipment_registry
export const getEstimateEquipmentRegistryWithEstimateIdRequest = (
  requestArguments: getEstimateEquipmentRegistryWithEstimateIdRequestSpacesArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_ESTIMATE_EQUIPMENT_REGISTRY_WITH_ESTIMATEID_START',
      successful: 'DATA_GET_ESTIMATE_EQUIPMENT_REGISTRY_WITH_ESTIMATEID_SUCCESSFUL',
      fail: 'DATA_GET_ESTIMATE_EQUIPMENT_REGISTRY_WITH_ESTIMATEID_FAIL'
    },
    embeddedType: {
      items: {
        schemaPath: '#/definitions/ScheduleItem',
        schemaKey: 'ScheduleItem'
      }
    },
    fnName: 'getEstimateEquipmentRegistryWithEstimateIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/spaces-api`,
    method: 'GET',
    url: `/estimate/${options?.estimateId || store.app.calculation}/equipment/registry`,
    params: {
      spacesEstimateType: options?.spacesEstimateType || store.app.spacesEstimateType,
      spacesResultView: options?.spacesResultView || store.app.spacesResultView,
      listType: requestArguments.query['listType'],
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      items: {
        schemaPath: '#/definitions/ScheduleItem',
        schemaKey: 'ScheduleItem'
      }
    }, 'getEstimateEquipmentRegistryWithEstimateIdRequest', '/spaces-api'),
    actions: {
      start: {
        type: DATA_GET_ESTIMATE_EQUIPMENT_REGISTRY_WITH_ESTIMATEID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_ESTIMATE_EQUIPMENT_REGISTRY_WITH_ESTIMATEID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_ESTIMATE_EQUIPMENT_REGISTRY_WITH_ESTIMATEID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getEstimateEquipmentRegistryWithEstimateIdRequestDefinitions = (definitionsArguments: {| requestArgs: getEstimateEquipmentRegistryWithEstimateIdRequestSpacesArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getEstimateEquipmentRegistryWithEstimateIdRequestSpacesArguments, payload?: Object |} => ({
  fnName: 'getEstimateEquipmentRegistryWithEstimateIdRequest',
  basePath: '/spaces-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_GET_ESTIMATE_EQUIPMENT_REGISTRY_WITH_ITEMID_START = 'DATA_GET_ESTIMATE_EQUIPMENT_REGISTRY_WITH_ITEMID_START'
export const DATA_GET_ESTIMATE_EQUIPMENT_REGISTRY_WITH_ITEMID_SUCCESSFUL = 'DATA_GET_ESTIMATE_EQUIPMENT_REGISTRY_WITH_ITEMID_SUCCESSFUL'
export const DATA_GET_ESTIMATE_EQUIPMENT_REGISTRY_WITH_ITEMID_FAIL = 'DATA_GET_ESTIMATE_EQUIPMENT_REGISTRY_WITH_ITEMID_FAIL'

// Gets registry list of equipment under a parent item
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/spaces.yaml#/Space%20Estimate/get_estimate__estimateId__equipment_registry__itemId_
export const getEstimateEquipmentRegistryWithItemIdRequest = (
  requestArguments: getEstimateEquipmentRegistryWithItemIdRequestSpacesArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_ESTIMATE_EQUIPMENT_REGISTRY_WITH_ITEMID_START',
      successful: 'DATA_GET_ESTIMATE_EQUIPMENT_REGISTRY_WITH_ITEMID_SUCCESSFUL',
      fail: 'DATA_GET_ESTIMATE_EQUIPMENT_REGISTRY_WITH_ITEMID_FAIL'
    },
    embeddedType: {
      items: {
        schemaPath: '#/definitions/ScheduleItem',
        schemaKey: 'ScheduleItem'
      }
    },
    fnName: 'getEstimateEquipmentRegistryWithItemIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/spaces-api`,
    method: 'GET',
    url: `/estimate/${options?.estimateId || store.app.calculation}/equipment/registry/${requestArguments.path['itemId']}`,
    params: {
      spacesEstimateType: options?.spacesEstimateType || store.app.spacesEstimateType,
      spacesResultView: options?.spacesResultView || store.app.spacesResultView,
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      items: {
        schemaPath: '#/definitions/ScheduleItem',
        schemaKey: 'ScheduleItem'
      }
    }, 'getEstimateEquipmentRegistryWithItemIdRequest', '/spaces-api'),
    actions: {
      start: {
        type: DATA_GET_ESTIMATE_EQUIPMENT_REGISTRY_WITH_ITEMID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_ESTIMATE_EQUIPMENT_REGISTRY_WITH_ITEMID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_ESTIMATE_EQUIPMENT_REGISTRY_WITH_ITEMID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getEstimateEquipmentRegistryWithItemIdRequestDefinitions = (definitionsArguments: {| requestArgs: getEstimateEquipmentRegistryWithItemIdRequestSpacesArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getEstimateEquipmentRegistryWithItemIdRequestSpacesArguments, payload?: Object |} => ({
  fnName: 'getEstimateEquipmentRegistryWithItemIdRequest',
  basePath: '/spaces-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_GET_ESTIMATE_EQUIPMENT_WITH_EQUIPMENTID_START = 'DATA_GET_ESTIMATE_EQUIPMENT_WITH_EQUIPMENTID_START'
export const DATA_GET_ESTIMATE_EQUIPMENT_WITH_EQUIPMENTID_SUCCESSFUL = 'DATA_GET_ESTIMATE_EQUIPMENT_WITH_EQUIPMENTID_SUCCESSFUL'
export const DATA_GET_ESTIMATE_EQUIPMENT_WITH_EQUIPMENTID_FAIL = 'DATA_GET_ESTIMATE_EQUIPMENT_WITH_EQUIPMENTID_FAIL'

// Returns a single estimate equipment item
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/spaces.yaml#/Space%20Estimate/get_estimate__estimateId__equipment__equipmentId_
export const getEstimateEquipmentWithEquipmentIdRequest = (
  requestArguments: getEstimateEquipmentWithEquipmentIdRequestSpacesArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_ESTIMATE_EQUIPMENT_WITH_EQUIPMENTID_START',
      successful: 'DATA_GET_ESTIMATE_EQUIPMENT_WITH_EQUIPMENTID_SUCCESSFUL',
      fail: 'DATA_GET_ESTIMATE_EQUIPMENT_WITH_EQUIPMENTID_FAIL'
    },
    embeddedType: {
      nonEmbedded: {
        schemaPath: '#/definitions/ScheduleItem',
        schemaKey: 'ScheduleItem'
      }
    },
    fnName: 'getEstimateEquipmentWithEquipmentIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/spaces-api`,
    method: 'GET',
    url: `/estimate/${options?.estimateId || store.app.calculation}/equipment/${requestArguments.path['equipmentId']}`,
    params: {
      spacesEstimateType: options?.spacesEstimateType || store.app.spacesEstimateType,
      spacesResultView: options?.spacesResultView || store.app.spacesResultView,
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      nonEmbedded: {
        schemaPath: '#/definitions/ScheduleItem',
        schemaKey: 'ScheduleItem'
      }
    }, 'getEstimateEquipmentWithEquipmentIdRequest', '/spaces-api'),
    actions: {
      start: {
        type: DATA_GET_ESTIMATE_EQUIPMENT_WITH_EQUIPMENTID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_ESTIMATE_EQUIPMENT_WITH_EQUIPMENTID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_ESTIMATE_EQUIPMENT_WITH_EQUIPMENTID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getEstimateEquipmentWithEquipmentIdRequestDefinitions = (definitionsArguments: {| requestArgs: getEstimateEquipmentWithEquipmentIdRequestSpacesArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getEstimateEquipmentWithEquipmentIdRequestSpacesArguments, payload?: Object |} => ({
  fnName: 'getEstimateEquipmentWithEquipmentIdRequest',
  basePath: '/spaces-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_DELETE_ESTIMATE_EQUIPMENT_WITH_EQUIPMENTID_START = 'DATA_DELETE_ESTIMATE_EQUIPMENT_WITH_EQUIPMENTID_START'
export const DATA_DELETE_ESTIMATE_EQUIPMENT_WITH_EQUIPMENTID_SUCCESSFUL = 'DATA_DELETE_ESTIMATE_EQUIPMENT_WITH_EQUIPMENTID_SUCCESSFUL'
export const DATA_DELETE_ESTIMATE_EQUIPMENT_WITH_EQUIPMENTID_FAIL = 'DATA_DELETE_ESTIMATE_EQUIPMENT_WITH_EQUIPMENTID_FAIL'

// Removes an estimate equipment item
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/spaces.yaml#/Space%20Estimate/delete_estimate__estimateId__equipment__equipmentId_
export const deleteEstimateEquipmentWithEquipmentIdRequest = (
  requestArguments: deleteEstimateEquipmentWithEquipmentIdRequestSpacesArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_DELETE_ESTIMATE_EQUIPMENT_WITH_EQUIPMENTID_START',
      successful: 'DATA_DELETE_ESTIMATE_EQUIPMENT_WITH_EQUIPMENTID_SUCCESSFUL',
      fail: 'DATA_DELETE_ESTIMATE_EQUIPMENT_WITH_EQUIPMENTID_FAIL'
    },
    embeddedType: null,
    fnName: 'deleteEstimateEquipmentWithEquipmentIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/spaces-api`,
    method: 'DELETE',
    url: `/estimate/${options?.estimateId || store.app.calculation}/equipment/${requestArguments.path['equipmentId']}`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'deleteEstimateEquipmentWithEquipmentIdRequest', '/spaces-api'),
    actions: {
      start: {
        type: DATA_DELETE_ESTIMATE_EQUIPMENT_WITH_EQUIPMENTID_START, payload, config, meta
      },
      successful: {
        type: DATA_DELETE_ESTIMATE_EQUIPMENT_WITH_EQUIPMENTID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_DELETE_ESTIMATE_EQUIPMENT_WITH_EQUIPMENTID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_PATCH_ESTIMATE_EQUIPMENT_WITH_EQUIPMENTID_START = 'DATA_PATCH_ESTIMATE_EQUIPMENT_WITH_EQUIPMENTID_START'
export const DATA_PATCH_ESTIMATE_EQUIPMENT_WITH_EQUIPMENTID_SUCCESSFUL = 'DATA_PATCH_ESTIMATE_EQUIPMENT_WITH_EQUIPMENTID_SUCCESSFUL'
export const DATA_PATCH_ESTIMATE_EQUIPMENT_WITH_EQUIPMENTID_FAIL = 'DATA_PATCH_ESTIMATE_EQUIPMENT_WITH_EQUIPMENTID_FAIL'

// Updates estimate equipment item column data
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/spaces.yaml#/Space%20Estimate/patch_estimate__estimateId__equipment__equipmentId_
export const patchEstimateEquipmentWithEquipmentIdRequest = (
  requestArguments: patchEstimateEquipmentWithEquipmentIdRequestSpacesArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_PATCH_ESTIMATE_EQUIPMENT_WITH_EQUIPMENTID_START',
      successful: 'DATA_PATCH_ESTIMATE_EQUIPMENT_WITH_EQUIPMENTID_SUCCESSFUL',
      fail: 'DATA_PATCH_ESTIMATE_EQUIPMENT_WITH_EQUIPMENTID_FAIL'
    },
    embeddedType: null,
    fnName: 'patchEstimateEquipmentWithEquipmentIdRequest'
  }
  const config = {
    headers: { 'Content-Type': 'application/json' },
    baseURL: `${API_BASE_URL}/spaces-api`,
    method: 'PATCH',
    url: `/estimate/${options?.estimateId || store.app.calculation}/equipment/${requestArguments.path['equipmentId']}`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: requestArguments.body
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'patchEstimateEquipmentWithEquipmentIdRequest', '/spaces-api'),
    actions: {
      start: {
        type: DATA_PATCH_ESTIMATE_EQUIPMENT_WITH_EQUIPMENTID_START, payload, config, meta
      },
      successful: {
        type: DATA_PATCH_ESTIMATE_EQUIPMENT_WITH_EQUIPMENTID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_PATCH_ESTIMATE_EQUIPMENT_WITH_EQUIPMENTID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_POST_ESTIMATE_EQUIPMENT_WITH_EQUIPMENTID_START = 'DATA_POST_ESTIMATE_EQUIPMENT_WITH_EQUIPMENTID_START'
export const DATA_POST_ESTIMATE_EQUIPMENT_WITH_EQUIPMENTID_SUCCESSFUL = 'DATA_POST_ESTIMATE_EQUIPMENT_WITH_EQUIPMENTID_SUCCESSFUL'
export const DATA_POST_ESTIMATE_EQUIPMENT_WITH_EQUIPMENTID_FAIL = 'DATA_POST_ESTIMATE_EQUIPMENT_WITH_EQUIPMENTID_FAIL'

// Create a new equipment item
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/spaces.yaml#/Space%20Estimate/post_estimate__estimateId__equipment__equipmentId_
export const postEstimateEquipmentWithEquipmentIdRequest = (
  requestArguments: postEstimateEquipmentWithEquipmentIdRequestSpacesArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_POST_ESTIMATE_EQUIPMENT_WITH_EQUIPMENTID_START',
      successful: 'DATA_POST_ESTIMATE_EQUIPMENT_WITH_EQUIPMENTID_SUCCESSFUL',
      fail: 'DATA_POST_ESTIMATE_EQUIPMENT_WITH_EQUIPMENTID_FAIL'
    },
    embeddedType: null,
    fnName: 'postEstimateEquipmentWithEquipmentIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/spaces-api`,
    method: 'POST',
    url: `/estimate/${options?.estimateId || store.app.calculation}/equipment/${requestArguments.path['equipmentId']}`,
    params: {
      spacesEstimateType: options?.spacesEstimateType || store.app.spacesEstimateType,
      spacesResultView: options?.spacesResultView || store.app.spacesResultView,
      registryItemId: requestArguments.query?.['registryItemId'],
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: requestArguments.body
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'postEstimateEquipmentWithEquipmentIdRequest', '/spaces-api'),
    actions: {
      start: {
        type: DATA_POST_ESTIMATE_EQUIPMENT_WITH_EQUIPMENTID_START, payload, config, meta
      },
      successful: {
        type: DATA_POST_ESTIMATE_EQUIPMENT_WITH_EQUIPMENTID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_POST_ESTIMATE_EQUIPMENT_WITH_EQUIPMENTID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_GET_ESTIMATE_EQUIPMENT_INFO_WITH_ITEMID_START = 'DATA_GET_ESTIMATE_EQUIPMENT_INFO_WITH_ITEMID_START'
export const DATA_GET_ESTIMATE_EQUIPMENT_INFO_WITH_ITEMID_SUCCESSFUL = 'DATA_GET_ESTIMATE_EQUIPMENT_INFO_WITH_ITEMID_SUCCESSFUL'
export const DATA_GET_ESTIMATE_EQUIPMENT_INFO_WITH_ITEMID_FAIL = 'DATA_GET_ESTIMATE_EQUIPMENT_INFO_WITH_ITEMID_FAIL'

// Returns information text about a site equipment list item (typically a heading item)
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/spaces.yaml#/Space%20Estimate/get_estimate__estimateId__equipment__itemId__info
export const getEstimateEquipmentInfoWithItemIdRequest = (
  requestArguments: getEstimateEquipmentInfoWithItemIdRequestSpacesArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_ESTIMATE_EQUIPMENT_INFO_WITH_ITEMID_START',
      successful: 'DATA_GET_ESTIMATE_EQUIPMENT_INFO_WITH_ITEMID_SUCCESSFUL',
      fail: 'DATA_GET_ESTIMATE_EQUIPMENT_INFO_WITH_ITEMID_FAIL'
    },
    embeddedType: null,
    fnName: 'getEstimateEquipmentInfoWithItemIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/spaces-api`,
    method: 'GET',
    url: `/estimate/${options?.estimateId || store.app.calculation}/equipment/${requestArguments.path['itemId']}/info`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'getEstimateEquipmentInfoWithItemIdRequest', '/spaces-api'),
    actions: {
      start: {
        type: DATA_GET_ESTIMATE_EQUIPMENT_INFO_WITH_ITEMID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_ESTIMATE_EQUIPMENT_INFO_WITH_ITEMID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_ESTIMATE_EQUIPMENT_INFO_WITH_ITEMID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getEstimateEquipmentInfoWithItemIdRequestDefinitions = (definitionsArguments: {| requestArgs: getEstimateEquipmentInfoWithItemIdRequestSpacesArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getEstimateEquipmentInfoWithItemIdRequestSpacesArguments, payload?: Object |} => ({
  fnName: 'getEstimateEquipmentInfoWithItemIdRequest',
  basePath: '/spaces-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_GET_ESTIMATE_EQUIPMENT_ITEMS_WITH_ITEMID_START = 'DATA_GET_ESTIMATE_EQUIPMENT_ITEMS_WITH_ITEMID_START'
export const DATA_GET_ESTIMATE_EQUIPMENT_ITEMS_WITH_ITEMID_SUCCESSFUL = 'DATA_GET_ESTIMATE_EQUIPMENT_ITEMS_WITH_ITEMID_SUCCESSFUL'
export const DATA_GET_ESTIMATE_EQUIPMENT_ITEMS_WITH_ITEMID_FAIL = 'DATA_GET_ESTIMATE_EQUIPMENT_ITEMS_WITH_ITEMID_FAIL'

// Returns equipment under a parent item
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/spaces.yaml#/Space%20Estimate/get_estimate__estimateId__equipment__itemId__items
export const getEstimateEquipmentItemsWithItemIdRequest = (
  requestArguments: getEstimateEquipmentItemsWithItemIdRequestSpacesArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_ESTIMATE_EQUIPMENT_ITEMS_WITH_ITEMID_START',
      successful: 'DATA_GET_ESTIMATE_EQUIPMENT_ITEMS_WITH_ITEMID_SUCCESSFUL',
      fail: 'DATA_GET_ESTIMATE_EQUIPMENT_ITEMS_WITH_ITEMID_FAIL'
    },
    embeddedType: {
      items: {
        schemaPath: '#/definitions/ScheduleItem',
        schemaKey: 'ScheduleItem'
      }
    },
    fnName: 'getEstimateEquipmentItemsWithItemIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/spaces-api`,
    method: 'GET',
    url: `/estimate/${options?.estimateId || store.app.calculation}/equipment/${requestArguments.path['itemId']}/items`,
    params: {
      spacesEstimateType: options?.spacesEstimateType || store.app.spacesEstimateType,
      spacesResultView: options?.spacesResultView || store.app.spacesResultView,
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      items: {
        schemaPath: '#/definitions/ScheduleItem',
        schemaKey: 'ScheduleItem'
      }
    }, 'getEstimateEquipmentItemsWithItemIdRequest', '/spaces-api'),
    actions: {
      start: {
        type: DATA_GET_ESTIMATE_EQUIPMENT_ITEMS_WITH_ITEMID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_ESTIMATE_EQUIPMENT_ITEMS_WITH_ITEMID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_ESTIMATE_EQUIPMENT_ITEMS_WITH_ITEMID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getEstimateEquipmentItemsWithItemIdRequestDefinitions = (definitionsArguments: {| requestArgs: getEstimateEquipmentItemsWithItemIdRequestSpacesArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getEstimateEquipmentItemsWithItemIdRequestSpacesArguments, payload?: Object |} => ({
  fnName: 'getEstimateEquipmentItemsWithItemIdRequest',
  basePath: '/spaces-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_GET_ESTIMATE_EQUIPMENT_ITEMS_DEFAULT_WITH_ITEMID_START = 'DATA_GET_ESTIMATE_EQUIPMENT_ITEMS_DEFAULT_WITH_ITEMID_START'
export const DATA_GET_ESTIMATE_EQUIPMENT_ITEMS_DEFAULT_WITH_ITEMID_SUCCESSFUL = 'DATA_GET_ESTIMATE_EQUIPMENT_ITEMS_DEFAULT_WITH_ITEMID_SUCCESSFUL'
export const DATA_GET_ESTIMATE_EQUIPMENT_ITEMS_DEFAULT_WITH_ITEMID_FAIL = 'DATA_GET_ESTIMATE_EQUIPMENT_ITEMS_DEFAULT_WITH_ITEMID_FAIL'

// Returns default equipment under a parent item
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/spaces.yaml#/Space%20Estimate/get_estimate__estimateId__equipment__itemId__items_default
export const getEstimateEquipmentItemsDefaultWithItemIdRequest = (
  requestArguments: getEstimateEquipmentItemsDefaultWithItemIdRequestSpacesArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_ESTIMATE_EQUIPMENT_ITEMS_DEFAULT_WITH_ITEMID_START',
      successful: 'DATA_GET_ESTIMATE_EQUIPMENT_ITEMS_DEFAULT_WITH_ITEMID_SUCCESSFUL',
      fail: 'DATA_GET_ESTIMATE_EQUIPMENT_ITEMS_DEFAULT_WITH_ITEMID_FAIL'
    },
    embeddedType: {
      items: {
        schemaPath: '#/definitions/ScheduleItem',
        schemaKey: 'ScheduleItem'
      }
    },
    fnName: 'getEstimateEquipmentItemsDefaultWithItemIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/spaces-api`,
    method: 'GET',
    url: `/estimate/${options?.estimateId || store.app.calculation}/equipment/${requestArguments.path['itemId']}/items/default`,
    params: {
      spacesEstimateType: options?.spacesEstimateType || store.app.spacesEstimateType,
      spacesResultView: options?.spacesResultView || store.app.spacesResultView,
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      items: {
        schemaPath: '#/definitions/ScheduleItem',
        schemaKey: 'ScheduleItem'
      }
    }, 'getEstimateEquipmentItemsDefaultWithItemIdRequest', '/spaces-api'),
    actions: {
      start: {
        type: DATA_GET_ESTIMATE_EQUIPMENT_ITEMS_DEFAULT_WITH_ITEMID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_ESTIMATE_EQUIPMENT_ITEMS_DEFAULT_WITH_ITEMID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_ESTIMATE_EQUIPMENT_ITEMS_DEFAULT_WITH_ITEMID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getEstimateEquipmentItemsDefaultWithItemIdRequestDefinitions = (definitionsArguments: {| requestArgs: getEstimateEquipmentItemsDefaultWithItemIdRequestSpacesArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getEstimateEquipmentItemsDefaultWithItemIdRequestSpacesArguments, payload?: Object |} => ({
  fnName: 'getEstimateEquipmentItemsDefaultWithItemIdRequest',
  basePath: '/spaces-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_GET_ESTIMATE_EQUIPMENT_PROPERTIES_INFO_WITH_PROPERTYNAME_START = 'DATA_GET_ESTIMATE_EQUIPMENT_PROPERTIES_INFO_WITH_PROPERTYNAME_START'
export const DATA_GET_ESTIMATE_EQUIPMENT_PROPERTIES_INFO_WITH_PROPERTYNAME_SUCCESSFUL = 'DATA_GET_ESTIMATE_EQUIPMENT_PROPERTIES_INFO_WITH_PROPERTYNAME_SUCCESSFUL'
export const DATA_GET_ESTIMATE_EQUIPMENT_PROPERTIES_INFO_WITH_PROPERTYNAME_FAIL = 'DATA_GET_ESTIMATE_EQUIPMENT_PROPERTIES_INFO_WITH_PROPERTYNAME_FAIL'

// Get short information text for a site equipment property
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/spaces.yaml#/Space%20Estimate/get_estimate__estimateId__equipment__equipmentId__properties_info__propertyName_
export const getEstimateEquipmentPropertiesInfoWithPropertyNameRequest = (
  requestArguments: getEstimateEquipmentPropertiesInfoWithPropertyNameRequestSpacesArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_ESTIMATE_EQUIPMENT_PROPERTIES_INFO_WITH_PROPERTYNAME_START',
      successful: 'DATA_GET_ESTIMATE_EQUIPMENT_PROPERTIES_INFO_WITH_PROPERTYNAME_SUCCESSFUL',
      fail: 'DATA_GET_ESTIMATE_EQUIPMENT_PROPERTIES_INFO_WITH_PROPERTYNAME_FAIL'
    },
    embeddedType: null,
    fnName: 'getEstimateEquipmentPropertiesInfoWithPropertyNameRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/spaces-api`,
    method: 'GET',
    url: `/estimate/${options?.estimateId || store.app.calculation}/equipment/${requestArguments.path['equipmentId']}/properties/info/${requestArguments.path['propertyName']}`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'getEstimateEquipmentPropertiesInfoWithPropertyNameRequest', '/spaces-api'),
    actions: {
      start: {
        type: DATA_GET_ESTIMATE_EQUIPMENT_PROPERTIES_INFO_WITH_PROPERTYNAME_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_ESTIMATE_EQUIPMENT_PROPERTIES_INFO_WITH_PROPERTYNAME_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_ESTIMATE_EQUIPMENT_PROPERTIES_INFO_WITH_PROPERTYNAME_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getEstimateEquipmentPropertiesInfoWithPropertyNameRequestDefinitions = (definitionsArguments: {| requestArgs: getEstimateEquipmentPropertiesInfoWithPropertyNameRequestSpacesArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getEstimateEquipmentPropertiesInfoWithPropertyNameRequestSpacesArguments, payload?: Object |} => ({
  fnName: 'getEstimateEquipmentPropertiesInfoWithPropertyNameRequest',
  basePath: '/spaces-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_GET_ESTIMATE_EQUIPMENT_PROPERTIES_WITH_EQUIPMENTID_START = 'DATA_GET_ESTIMATE_EQUIPMENT_PROPERTIES_WITH_EQUIPMENTID_START'
export const DATA_GET_ESTIMATE_EQUIPMENT_PROPERTIES_WITH_EQUIPMENTID_SUCCESSFUL = 'DATA_GET_ESTIMATE_EQUIPMENT_PROPERTIES_WITH_EQUIPMENTID_SUCCESSFUL'
export const DATA_GET_ESTIMATE_EQUIPMENT_PROPERTIES_WITH_EQUIPMENTID_FAIL = 'DATA_GET_ESTIMATE_EQUIPMENT_PROPERTIES_WITH_EQUIPMENTID_FAIL'

// Gets properties of a piece of equipment
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/spaces.yaml#/Space%20Estimate/get_estimate__estimateId__equipment__equipmentId__properties
export const getEstimateEquipmentPropertiesWithEquipmentIdRequest = (
  requestArguments: getEstimateEquipmentPropertiesWithEquipmentIdRequestSpacesArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_ESTIMATE_EQUIPMENT_PROPERTIES_WITH_EQUIPMENTID_START',
      successful: 'DATA_GET_ESTIMATE_EQUIPMENT_PROPERTIES_WITH_EQUIPMENTID_SUCCESSFUL',
      fail: 'DATA_GET_ESTIMATE_EQUIPMENT_PROPERTIES_WITH_EQUIPMENTID_FAIL'
    },
    embeddedType: {
      properties: {
        schemaPath: '#/definitions/ValueProperty',
        schemaKey: 'ValueProperty'
      }
    },
    fnName: 'getEstimateEquipmentPropertiesWithEquipmentIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/spaces-api`,
    method: 'GET',
    url: `/estimate/${options?.estimateId || store.app.calculation}/equipment/${requestArguments.path['equipmentId']}/properties`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      properties: {
        schemaPath: '#/definitions/ValueProperty',
        schemaKey: 'ValueProperty'
      }
    }, 'getEstimateEquipmentPropertiesWithEquipmentIdRequest', '/spaces-api'),
    actions: {
      start: {
        type: DATA_GET_ESTIMATE_EQUIPMENT_PROPERTIES_WITH_EQUIPMENTID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_ESTIMATE_EQUIPMENT_PROPERTIES_WITH_EQUIPMENTID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_ESTIMATE_EQUIPMENT_PROPERTIES_WITH_EQUIPMENTID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getEstimateEquipmentPropertiesWithEquipmentIdRequestDefinitions = (definitionsArguments: {| requestArgs: getEstimateEquipmentPropertiesWithEquipmentIdRequestSpacesArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getEstimateEquipmentPropertiesWithEquipmentIdRequestSpacesArguments, payload?: Object |} => ({
  fnName: 'getEstimateEquipmentPropertiesWithEquipmentIdRequest',
  basePath: '/spaces-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_PATCH_ESTIMATE_EQUIPMENT_PROPERTIES_WITH_EQUIPMENTID_START = 'DATA_PATCH_ESTIMATE_EQUIPMENT_PROPERTIES_WITH_EQUIPMENTID_START'
export const DATA_PATCH_ESTIMATE_EQUIPMENT_PROPERTIES_WITH_EQUIPMENTID_SUCCESSFUL = 'DATA_PATCH_ESTIMATE_EQUIPMENT_PROPERTIES_WITH_EQUIPMENTID_SUCCESSFUL'
export const DATA_PATCH_ESTIMATE_EQUIPMENT_PROPERTIES_WITH_EQUIPMENTID_FAIL = 'DATA_PATCH_ESTIMATE_EQUIPMENT_PROPERTIES_WITH_EQUIPMENTID_FAIL'

// Update equipment properties. Will return a queue location header if calculation is needed.
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/spaces.yaml#/Space%20Estimate/patch_estimate__estimateId__equipment__equipmentId__properties
export const patchEstimateEquipmentPropertiesWithEquipmentIdRequest = (
  requestArguments: patchEstimateEquipmentPropertiesWithEquipmentIdRequestSpacesArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_PATCH_ESTIMATE_EQUIPMENT_PROPERTIES_WITH_EQUIPMENTID_START',
      successful: 'DATA_PATCH_ESTIMATE_EQUIPMENT_PROPERTIES_WITH_EQUIPMENTID_SUCCESSFUL',
      fail: 'DATA_PATCH_ESTIMATE_EQUIPMENT_PROPERTIES_WITH_EQUIPMENTID_FAIL'
    },
    embeddedType: null,
    fnName: 'patchEstimateEquipmentPropertiesWithEquipmentIdRequest'
  }
  const config = {
    headers: { 'Content-Type': 'application/json' },
    baseURL: `${API_BASE_URL}/spaces-api`,
    method: 'PATCH',
    url: `/estimate/${options?.estimateId || store.app.calculation}/equipment/${requestArguments.path['equipmentId']}/properties`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: requestArguments.body
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'patchEstimateEquipmentPropertiesWithEquipmentIdRequest', '/spaces-api'),
    actions: {
      start: {
        type: DATA_PATCH_ESTIMATE_EQUIPMENT_PROPERTIES_WITH_EQUIPMENTID_START, payload, config, meta
      },
      successful: {
        type: DATA_PATCH_ESTIMATE_EQUIPMENT_PROPERTIES_WITH_EQUIPMENTID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_PATCH_ESTIMATE_EQUIPMENT_PROPERTIES_WITH_EQUIPMENTID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_GET_IMPORT_SCHEDULE_WITH_ESTIMATEID_START = 'DATA_GET_IMPORT_SCHEDULE_WITH_ESTIMATEID_START'
export const DATA_GET_IMPORT_SCHEDULE_WITH_ESTIMATEID_SUCCESSFUL = 'DATA_GET_IMPORT_SCHEDULE_WITH_ESTIMATEID_SUCCESSFUL'
export const DATA_GET_IMPORT_SCHEDULE_WITH_ESTIMATEID_FAIL = 'DATA_GET_IMPORT_SCHEDULE_WITH_ESTIMATEID_FAIL'

// Returns the import schedule for a space estimate
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/spaces.yaml#/Import%20From%20WOP/get_import_schedule__estimateId_
export const getImportScheduleWithEstimateIdRequest = (
  requestArguments: getImportScheduleWithEstimateIdRequestSpacesArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_IMPORT_SCHEDULE_WITH_ESTIMATEID_START',
      successful: 'DATA_GET_IMPORT_SCHEDULE_WITH_ESTIMATEID_SUCCESSFUL',
      fail: 'DATA_GET_IMPORT_SCHEDULE_WITH_ESTIMATEID_FAIL'
    },
    embeddedType: {
      items: {
        schemaPath: '#/definitions/ScheduleItem',
        schemaKey: 'ScheduleItem'
      }
    },
    fnName: 'getImportScheduleWithEstimateIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/spaces-api`,
    method: 'GET',
    url: `/import-schedule/${options?.estimateId || store.app.calculation}`,
    params: {
      listType: requestArguments.query['listType'],
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      items: {
        schemaPath: '#/definitions/ScheduleItem',
        schemaKey: 'ScheduleItem'
      }
    }, 'getImportScheduleWithEstimateIdRequest', '/spaces-api'),
    actions: {
      start: {
        type: DATA_GET_IMPORT_SCHEDULE_WITH_ESTIMATEID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_IMPORT_SCHEDULE_WITH_ESTIMATEID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_IMPORT_SCHEDULE_WITH_ESTIMATEID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getImportScheduleWithEstimateIdRequestDefinitions = (definitionsArguments: {| requestArgs: getImportScheduleWithEstimateIdRequestSpacesArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getImportScheduleWithEstimateIdRequestSpacesArguments, payload?: Object |} => ({
  fnName: 'getImportScheduleWithEstimateIdRequest',
  basePath: '/spaces-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_GET_IMPORT_SCHEDULE_ITEMS_CAN_ATTACH_TO_WITH_ITEMID_START = 'DATA_GET_IMPORT_SCHEDULE_ITEMS_CAN_ATTACH_TO_WITH_ITEMID_START'
export const DATA_GET_IMPORT_SCHEDULE_ITEMS_CAN_ATTACH_TO_WITH_ITEMID_SUCCESSFUL = 'DATA_GET_IMPORT_SCHEDULE_ITEMS_CAN_ATTACH_TO_WITH_ITEMID_SUCCESSFUL'
export const DATA_GET_IMPORT_SCHEDULE_ITEMS_CAN_ATTACH_TO_WITH_ITEMID_FAIL = 'DATA_GET_IMPORT_SCHEDULE_ITEMS_CAN_ATTACH_TO_WITH_ITEMID_FAIL'

// Get id (value) and description (localizedName) of space schedule rows to which the import schedule row can be imported to
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/spaces.yaml#/Import%20From%20WOP/get_import_schedule__estimateId__items__itemId__can_attach_to
export const getImportScheduleItemsCanAttachToWithItemIdRequest = (
  requestArguments: getImportScheduleItemsCanAttachToWithItemIdRequestSpacesArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_IMPORT_SCHEDULE_ITEMS_CAN_ATTACH_TO_WITH_ITEMID_START',
      successful: 'DATA_GET_IMPORT_SCHEDULE_ITEMS_CAN_ATTACH_TO_WITH_ITEMID_SUCCESSFUL',
      fail: 'DATA_GET_IMPORT_SCHEDULE_ITEMS_CAN_ATTACH_TO_WITH_ITEMID_FAIL'
    },
    embeddedType: {
      items: {
        schemaPath: '#/definitions/Enumeral',
        schemaKey: 'Enumeral'
      }
    },
    fnName: 'getImportScheduleItemsCanAttachToWithItemIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/spaces-api`,
    method: 'GET',
    url: `/import-schedule/${options?.estimateId || store.app.calculation}/items/${requestArguments.path['itemId']}/can-attach-to`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      items: {
        schemaPath: '#/definitions/Enumeral',
        schemaKey: 'Enumeral'
      }
    }, 'getImportScheduleItemsCanAttachToWithItemIdRequest', '/spaces-api'),
    actions: {
      start: {
        type: DATA_GET_IMPORT_SCHEDULE_ITEMS_CAN_ATTACH_TO_WITH_ITEMID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_IMPORT_SCHEDULE_ITEMS_CAN_ATTACH_TO_WITH_ITEMID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_IMPORT_SCHEDULE_ITEMS_CAN_ATTACH_TO_WITH_ITEMID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getImportScheduleItemsCanAttachToWithItemIdRequestDefinitions = (definitionsArguments: {| requestArgs: getImportScheduleItemsCanAttachToWithItemIdRequestSpacesArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getImportScheduleItemsCanAttachToWithItemIdRequestSpacesArguments, payload?: Object |} => ({
  fnName: 'getImportScheduleItemsCanAttachToWithItemIdRequest',
  basePath: '/spaces-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_GET_IMPORT_SCHEDULE_COLUMNS_WITH_ESTIMATEID_START = 'DATA_GET_IMPORT_SCHEDULE_COLUMNS_WITH_ESTIMATEID_START'
export const DATA_GET_IMPORT_SCHEDULE_COLUMNS_WITH_ESTIMATEID_SUCCESSFUL = 'DATA_GET_IMPORT_SCHEDULE_COLUMNS_WITH_ESTIMATEID_SUCCESSFUL'
export const DATA_GET_IMPORT_SCHEDULE_COLUMNS_WITH_ESTIMATEID_FAIL = 'DATA_GET_IMPORT_SCHEDULE_COLUMNS_WITH_ESTIMATEID_FAIL'

// Gets import schedule columns
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/spaces.yaml#/Import%20From%20WOP/get_import_schedule__estimateId__columns
export const getImportScheduleColumnsWithEstimateIdRequest = (
  payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_IMPORT_SCHEDULE_COLUMNS_WITH_ESTIMATEID_START',
      successful: 'DATA_GET_IMPORT_SCHEDULE_COLUMNS_WITH_ESTIMATEID_SUCCESSFUL',
      fail: 'DATA_GET_IMPORT_SCHEDULE_COLUMNS_WITH_ESTIMATEID_FAIL'
    },
    embeddedType: {
      properties: {
        schemaPath: '#/definitions/ValueProperty',
        schemaKey: 'ValueProperty'
      }
    },
    fnName: 'getImportScheduleColumnsWithEstimateIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/spaces-api`,
    method: 'GET',
    url: `/import-schedule/${options?.estimateId || store.app.calculation}/columns`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      properties: {
        schemaPath: '#/definitions/ValueProperty',
        schemaKey: 'ValueProperty'
      }
    }, 'getImportScheduleColumnsWithEstimateIdRequest', '/spaces-api'),
    actions: {
      start: {
        type: DATA_GET_IMPORT_SCHEDULE_COLUMNS_WITH_ESTIMATEID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_IMPORT_SCHEDULE_COLUMNS_WITH_ESTIMATEID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_IMPORT_SCHEDULE_COLUMNS_WITH_ESTIMATEID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getImportScheduleColumnsWithEstimateIdRequestDefinitions = (definitionsArguments: {| payload?: Object |}): {|
  ...TVDGARConfigWithoutRequestArgs, payload?: Object |} => ({
  fnName: 'getImportScheduleColumnsWithEstimateIdRequest',
  basePath: '/spaces-api',
  payload: definitionsArguments.payload,
})


export const DATA_GET_IMPORT_SCHEDULE_VALUES_WITH_ESTIMATEID_START = 'DATA_GET_IMPORT_SCHEDULE_VALUES_WITH_ESTIMATEID_START'
export const DATA_GET_IMPORT_SCHEDULE_VALUES_WITH_ESTIMATEID_SUCCESSFUL = 'DATA_GET_IMPORT_SCHEDULE_VALUES_WITH_ESTIMATEID_SUCCESSFUL'
export const DATA_GET_IMPORT_SCHEDULE_VALUES_WITH_ESTIMATEID_FAIL = 'DATA_GET_IMPORT_SCHEDULE_VALUES_WITH_ESTIMATEID_FAIL'

// Gets current import schedule values
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/spaces.yaml#/Import%20From%20WOP/get_import_schedule__estimateId__values
export const getImportScheduleValuesWithEstimateIdRequest = (
  payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_IMPORT_SCHEDULE_VALUES_WITH_ESTIMATEID_START',
      successful: 'DATA_GET_IMPORT_SCHEDULE_VALUES_WITH_ESTIMATEID_SUCCESSFUL',
      fail: 'DATA_GET_IMPORT_SCHEDULE_VALUES_WITH_ESTIMATEID_FAIL'
    },
    embeddedType: {
      properties: {
        schemaPath: '#/definitions/ValueProperty',
        schemaKey: 'ValueProperty'
      }
    },
    fnName: 'getImportScheduleValuesWithEstimateIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/spaces-api`,
    method: 'GET',
    url: `/import-schedule/${options?.estimateId || store.app.calculation}/values`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      properties: {
        schemaPath: '#/definitions/ValueProperty',
        schemaKey: 'ValueProperty'
      }
    }, 'getImportScheduleValuesWithEstimateIdRequest', '/spaces-api'),
    actions: {
      start: {
        type: DATA_GET_IMPORT_SCHEDULE_VALUES_WITH_ESTIMATEID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_IMPORT_SCHEDULE_VALUES_WITH_ESTIMATEID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_IMPORT_SCHEDULE_VALUES_WITH_ESTIMATEID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getImportScheduleValuesWithEstimateIdRequestDefinitions = (definitionsArguments: {| payload?: Object |}): {|
  ...TVDGARConfigWithoutRequestArgs, payload?: Object |} => ({
  fnName: 'getImportScheduleValuesWithEstimateIdRequest',
  basePath: '/spaces-api',
  payload: definitionsArguments.payload,
})


export const DATA_GET_IMPORT_SCHEDULE_ID_WITH_ESTIMATEID_START = 'DATA_GET_IMPORT_SCHEDULE_ID_WITH_ESTIMATEID_START'
export const DATA_GET_IMPORT_SCHEDULE_ID_WITH_ESTIMATEID_SUCCESSFUL = 'DATA_GET_IMPORT_SCHEDULE_ID_WITH_ESTIMATEID_SUCCESSFUL'
export const DATA_GET_IMPORT_SCHEDULE_ID_WITH_ESTIMATEID_FAIL = 'DATA_GET_IMPORT_SCHEDULE_ID_WITH_ESTIMATEID_FAIL'

// Returns id of WOP estimate that was used to generate current import schedule
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/spaces.yaml#/Import%20From%20WOP/get_import_schedule__estimateId__id
export const getImportScheduleIdWithEstimateIdRequest = (
  payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_IMPORT_SCHEDULE_ID_WITH_ESTIMATEID_START',
      successful: 'DATA_GET_IMPORT_SCHEDULE_ID_WITH_ESTIMATEID_SUCCESSFUL',
      fail: 'DATA_GET_IMPORT_SCHEDULE_ID_WITH_ESTIMATEID_FAIL'
    },
    embeddedType: {
      nonEmbedded: {
        schemaKey: null
      }
    },
    fnName: 'getImportScheduleIdWithEstimateIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/spaces-api`,
    method: 'GET',
    url: `/import-schedule/${options?.estimateId || store.app.calculation}/id`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      nonEmbedded: {
        schemaKey: null
      }
    }, 'getImportScheduleIdWithEstimateIdRequest', '/spaces-api'),
    actions: {
      start: {
        type: DATA_GET_IMPORT_SCHEDULE_ID_WITH_ESTIMATEID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_IMPORT_SCHEDULE_ID_WITH_ESTIMATEID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_IMPORT_SCHEDULE_ID_WITH_ESTIMATEID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getImportScheduleIdWithEstimateIdRequestDefinitions = (definitionsArguments: {| payload?: Object |}): {|
  ...TVDGARConfigWithoutRequestArgs, payload?: Object |} => ({
  fnName: 'getImportScheduleIdWithEstimateIdRequest',
  basePath: '/spaces-api',
  payload: definitionsArguments.payload,
})


export const DATA_PUT_IMPORT_SCHEDULE_ID_WITH_ESTIMATEID_START = 'DATA_PUT_IMPORT_SCHEDULE_ID_WITH_ESTIMATEID_START'
export const DATA_PUT_IMPORT_SCHEDULE_ID_WITH_ESTIMATEID_SUCCESSFUL = 'DATA_PUT_IMPORT_SCHEDULE_ID_WITH_ESTIMATEID_SUCCESSFUL'
export const DATA_PUT_IMPORT_SCHEDULE_ID_WITH_ESTIMATEID_FAIL = 'DATA_PUT_IMPORT_SCHEDULE_ID_WITH_ESTIMATEID_FAIL'

// Set id of import schedule. This will generate a new import schedule
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/spaces.yaml#/Import%20From%20WOP/put_import_schedule__estimateId__id
export const putImportScheduleIdWithEstimateIdRequest = (
  requestArguments: putImportScheduleIdWithEstimateIdRequestSpacesArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_PUT_IMPORT_SCHEDULE_ID_WITH_ESTIMATEID_START',
      successful: 'DATA_PUT_IMPORT_SCHEDULE_ID_WITH_ESTIMATEID_SUCCESSFUL',
      fail: 'DATA_PUT_IMPORT_SCHEDULE_ID_WITH_ESTIMATEID_FAIL'
    },
    embeddedType: null,
    fnName: 'putImportScheduleIdWithEstimateIdRequest'
  }
  const config = {
    headers: { 'Content-Type': 'application/json' },
    baseURL: `${API_BASE_URL}/spaces-api`,
    method: 'PUT',
    url: `/import-schedule/${options?.estimateId || store.app.calculation}/id`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: `"${requestArguments.body}"`
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'putImportScheduleIdWithEstimateIdRequest', '/spaces-api'),
    actions: {
      start: {
        type: DATA_PUT_IMPORT_SCHEDULE_ID_WITH_ESTIMATEID_START, payload, config, meta
      },
      successful: {
        type: DATA_PUT_IMPORT_SCHEDULE_ID_WITH_ESTIMATEID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_PUT_IMPORT_SCHEDULE_ID_WITH_ESTIMATEID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_GET_IMPORT_SCHEDULE_ESTIMATES_WITH_ESTIMATEID_START = 'DATA_GET_IMPORT_SCHEDULE_ESTIMATES_WITH_ESTIMATEID_START'
export const DATA_GET_IMPORT_SCHEDULE_ESTIMATES_WITH_ESTIMATEID_SUCCESSFUL = 'DATA_GET_IMPORT_SCHEDULE_ESTIMATES_WITH_ESTIMATEID_SUCCESSFUL'
export const DATA_GET_IMPORT_SCHEDULE_ESTIMATES_WITH_ESTIMATEID_FAIL = 'DATA_GET_IMPORT_SCHEDULE_ESTIMATES_WITH_ESTIMATEID_FAIL'

// Returns a list of WOP estimates that can be used to import spaces
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/spaces.yaml#/Import%20From%20WOP/get_import_schedule__estimateId__estimates
export const getImportScheduleEstimatesWithEstimateIdRequest = (
  payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_IMPORT_SCHEDULE_ESTIMATES_WITH_ESTIMATEID_START',
      successful: 'DATA_GET_IMPORT_SCHEDULE_ESTIMATES_WITH_ESTIMATEID_SUCCESSFUL',
      fail: 'DATA_GET_IMPORT_SCHEDULE_ESTIMATES_WITH_ESTIMATEID_FAIL'
    },
    embeddedType: {
      items: {
        schemaPath: '#/definitions/Enumeral',
        schemaKey: 'Enumeral'
      }
    },
    fnName: 'getImportScheduleEstimatesWithEstimateIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/spaces-api`,
    method: 'GET',
    url: `/import-schedule/${options?.estimateId || store.app.calculation}/estimates`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      items: {
        schemaPath: '#/definitions/Enumeral',
        schemaKey: 'Enumeral'
      }
    }, 'getImportScheduleEstimatesWithEstimateIdRequest', '/spaces-api'),
    actions: {
      start: {
        type: DATA_GET_IMPORT_SCHEDULE_ESTIMATES_WITH_ESTIMATEID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_IMPORT_SCHEDULE_ESTIMATES_WITH_ESTIMATEID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_IMPORT_SCHEDULE_ESTIMATES_WITH_ESTIMATEID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getImportScheduleEstimatesWithEstimateIdRequestDefinitions = (definitionsArguments: {| payload?: Object |}): {|
  ...TVDGARConfigWithoutRequestArgs, payload?: Object |} => ({
  fnName: 'getImportScheduleEstimatesWithEstimateIdRequest',
  basePath: '/spaces-api',
  payload: definitionsArguments.payload,
})


export const DATA_POST_VALIDATE_START = 'DATA_POST_VALIDATE_START'
export const DATA_POST_VALIDATE_SUCCESSFUL = 'DATA_POST_VALIDATE_SUCCESSFUL'
export const DATA_POST_VALIDATE_FAIL = 'DATA_POST_VALIDATE_FAIL'

// Validate spaces estimate initial data
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/spaces.yaml#/Estimate/post_validate
export const postValidateRequest = (
  requestArguments: postValidateRequestSpacesArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_POST_VALIDATE_START',
      successful: 'DATA_POST_VALIDATE_SUCCESSFUL',
      fail: 'DATA_POST_VALIDATE_FAIL'
    },
    embeddedType: null,
    fnName: 'postValidateRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/spaces-api`,
    method: 'POST',
    url: '/validate',
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: requestArguments.body
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'postValidateRequest', '/spaces-api'),
    actions: {
      start: {
        type: DATA_POST_VALIDATE_START, payload, config, meta
      },
      successful: {
        type: DATA_POST_VALIDATE_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_POST_VALIDATE_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_GET_REPORTS_START = 'DATA_GET_REPORTS_START'
export const DATA_GET_REPORTS_SUCCESSFUL = 'DATA_GET_REPORTS_SUCCESSFUL'
export const DATA_GET_REPORTS_FAIL = 'DATA_GET_REPORTS_FAIL'

// Returns a list of available report types as Enumerals with reportType as value and a localized name for the type.
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/spaces.yaml#/Reports/get_reports
export const getReportsRequest = (
  payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_REPORTS_START',
      successful: 'DATA_GET_REPORTS_SUCCESSFUL',
      fail: 'DATA_GET_REPORTS_FAIL'
    },
    embeddedType: {
      items: {
        schemaPath: '#/definitions/Enumeral',
        schemaKey: 'Enumeral'
      }
    },
    fnName: 'getReportsRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/spaces-api`,
    method: 'GET',
    url: '/reports',
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      items: {
        schemaPath: '#/definitions/Enumeral',
        schemaKey: 'Enumeral'
      }
    }, 'getReportsRequest', '/spaces-api'),
    actions: {
      start: {
        type: DATA_GET_REPORTS_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_REPORTS_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_REPORTS_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getReportsRequestDefinitions = (definitionsArguments: {| payload?: Object |}): {|
  ...TVDGARConfigWithoutRequestArgs, payload?: Object |} => ({
  fnName: 'getReportsRequest',
  basePath: '/spaces-api',
  payload: definitionsArguments.payload,
})


export const DATA_GET_REPORTS_WITH_REPORTTYPE_START = 'DATA_GET_REPORTS_WITH_REPORTTYPE_START'
export const DATA_GET_REPORTS_WITH_REPORTTYPE_SUCCESSFUL = 'DATA_GET_REPORTS_WITH_REPORTTYPE_SUCCESSFUL'
export const DATA_GET_REPORTS_WITH_REPORTTYPE_FAIL = 'DATA_GET_REPORTS_WITH_REPORTTYPE_FAIL'

// Returns a pdf report of given type for requested estimate.
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/spaces.yaml#/Reports/get_reports__reportType___estimateId_
export const getReportsWithReportTypeRequest = (
  requestArguments: getReportsWithReportTypeRequestSpacesArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_REPORTS_WITH_REPORTTYPE_START',
      successful: 'DATA_GET_REPORTS_WITH_REPORTTYPE_SUCCESSFUL',
      fail: 'DATA_GET_REPORTS_WITH_REPORTTYPE_FAIL'
    },
    embeddedType: null,
    fnName: 'getReportsWithReportTypeRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/spaces-api`,
    method: 'GET',
    url: `/reports/${requestArguments.path['reportType']}/${options?.estimateId || store.app.calculation}`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'getReportsWithReportTypeRequest', '/spaces-api'),
    actions: {
      start: {
        type: DATA_GET_REPORTS_WITH_REPORTTYPE_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_REPORTS_WITH_REPORTTYPE_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_REPORTS_WITH_REPORTTYPE_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getReportsWithReportTypeRequestDefinitions = (definitionsArguments: {| requestArgs: getReportsWithReportTypeRequestSpacesArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getReportsWithReportTypeRequestSpacesArguments, payload?: Object |} => ({
  fnName: 'getReportsWithReportTypeRequest',
  basePath: '/spaces-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_GET_QUEUE_WITH_ID_START = 'DATA_GET_QUEUE_WITH_ID_START'
export const DATA_GET_QUEUE_WITH_ID_SUCCESSFUL = 'DATA_GET_QUEUE_WITH_ID_SUCCESSFUL'
export const DATA_GET_QUEUE_WITH_ID_FAIL = 'DATA_GET_QUEUE_WITH_ID_FAIL'

// Returns status of queued operation
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/spaces.yaml#/Queue/get_queue__id_
export const getQueueWithIdRequest = (
  requestArguments: getQueueWithIdRequestSpacesArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_QUEUE_WITH_ID_START',
      successful: 'DATA_GET_QUEUE_WITH_ID_SUCCESSFUL',
      fail: 'DATA_GET_QUEUE_WITH_ID_FAIL'
    },
    embeddedType: {
      nonEmbedded: {
        schemaPath: '#/definitions/QueueStatus',
        schemaKey: 'QueueStatus'
      }
    },
    fnName: 'getQueueWithIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/spaces-api`,
    method: 'GET',
    url: `/queue/${requestArguments.path['id']}`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      nonEmbedded: {
        schemaPath: '#/definitions/QueueStatus',
        schemaKey: 'QueueStatus'
      }
    }, 'getQueueWithIdRequest', '/spaces-api'),
    actions: {
      start: {
        type: DATA_GET_QUEUE_WITH_ID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_QUEUE_WITH_ID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_QUEUE_WITH_ID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getQueueWithIdRequestDefinitions = (definitionsArguments: {| requestArgs: getQueueWithIdRequestSpacesArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getQueueWithIdRequestSpacesArguments, payload?: Object |} => ({
  fnName: 'getQueueWithIdRequest',
  basePath: '/spaces-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_DELETE_QUEUE_WITH_ID_START = 'DATA_DELETE_QUEUE_WITH_ID_START'
export const DATA_DELETE_QUEUE_WITH_ID_SUCCESSFUL = 'DATA_DELETE_QUEUE_WITH_ID_SUCCESSFUL'
export const DATA_DELETE_QUEUE_WITH_ID_FAIL = 'DATA_DELETE_QUEUE_WITH_ID_FAIL'

// Deletes an operation from the queue
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/spaces.yaml#/Queue/delete_queue__id_
export const deleteQueueWithIdRequest = (
  requestArguments: deleteQueueWithIdRequestSpacesArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_DELETE_QUEUE_WITH_ID_START',
      successful: 'DATA_DELETE_QUEUE_WITH_ID_SUCCESSFUL',
      fail: 'DATA_DELETE_QUEUE_WITH_ID_FAIL'
    },
    embeddedType: null,
    fnName: 'deleteQueueWithIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/spaces-api`,
    method: 'DELETE',
    url: `/queue/${requestArguments.path['id']}`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'deleteQueueWithIdRequest', '/spaces-api'),
    actions: {
      start: {
        type: DATA_DELETE_QUEUE_WITH_ID_START, payload, config, meta
      },
      successful: {
        type: DATA_DELETE_QUEUE_WITH_ID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_DELETE_QUEUE_WITH_ID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_GET_ENUM_WITH_ENUM_START = 'DATA_GET_ENUM_WITH_ENUM_START'
export const DATA_GET_ENUM_WITH_ENUM_SUCCESSFUL = 'DATA_GET_ENUM_WITH_ENUM_SUCCESSFUL'
export const DATA_GET_ENUM_WITH_ENUM_FAIL = 'DATA_GET_ENUM_WITH_ENUM_FAIL'

// Returns members of given enum as embedded Enumerals
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/spaces.yaml#/Enum/get_enum__enum_
export const getEnumWithEnumRequest = (
  requestArguments: getEnumWithEnumRequestSpacesArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_ENUM_WITH_ENUM_START',
      successful: 'DATA_GET_ENUM_WITH_ENUM_SUCCESSFUL',
      fail: 'DATA_GET_ENUM_WITH_ENUM_FAIL'
    },
    embeddedType: {
      enumeral: {
        schemaPath: '#/definitions/Enumeral',
        schemaKey: 'Enumeral'
      }
    },
    fnName: 'getEnumWithEnumRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/spaces-api`,
    method: 'GET',
    url: `/enum/${requestArguments.path['enumParam']}`,
    params: {
      category: requestArguments.query?.['category'],
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      enumeral: {
        schemaPath: '#/definitions/Enumeral',
        schemaKey: 'Enumeral'
      }
    }, 'getEnumWithEnumRequest', '/spaces-api'),
    actions: {
      start: {
        type: DATA_GET_ENUM_WITH_ENUM_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_ENUM_WITH_ENUM_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_ENUM_WITH_ENUM_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getEnumWithEnumRequestDefinitions = (definitionsArguments: {| requestArgs: getEnumWithEnumRequestSpacesArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getEnumWithEnumRequestSpacesArguments, payload?: Object |} => ({
  fnName: 'getEnumWithEnumRequest',
  basePath: '/spaces-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_GET_SWAGGER_START = 'DATA_GET_SWAGGER_START'
export const DATA_GET_SWAGGER_SUCCESSFUL = 'DATA_GET_SWAGGER_SUCCESSFUL'
export const DATA_GET_SWAGGER_FAIL = 'DATA_GET_SWAGGER_FAIL'

// Returns Swagger specification of the API
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/spaces.yaml#/Swagger/get_swagger
export const getSwaggerRequest = (
  payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_SWAGGER_START',
      successful: 'DATA_GET_SWAGGER_SUCCESSFUL',
      fail: 'DATA_GET_SWAGGER_FAIL'
    },
    embeddedType: null,
    fnName: 'getSwaggerRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/spaces-api`,
    method: 'GET',
    url: '/swagger',
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'getSwaggerRequest', '/spaces-api'),
    actions: {
      start: {
        type: DATA_GET_SWAGGER_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_SWAGGER_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_SWAGGER_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getSwaggerRequestDefinitions = (definitionsArguments: {| payload?: Object |}): {|
  ...TVDGARConfigWithoutRequestArgs, payload?: Object |} => ({
  fnName: 'getSwaggerRequest',
  basePath: '/spaces-api',
  payload: definitionsArguments.payload,
})


export const DATA_GET_SWAGGER_WITH_NODE_START = 'DATA_GET_SWAGGER_WITH_NODE_START'
export const DATA_GET_SWAGGER_WITH_NODE_SUCCESSFUL = 'DATA_GET_SWAGGER_WITH_NODE_SUCCESSFUL'
export const DATA_GET_SWAGGER_WITH_NODE_FAIL = 'DATA_GET_SWAGGER_WITH_NODE_FAIL'

// Returns a first level node from the Swagger specification of the API
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/spaces.yaml#/Swagger/get_swagger__node_
export const getSwaggerWithNodeRequest = (
  requestArguments: getSwaggerWithNodeRequestSpacesArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_SWAGGER_WITH_NODE_START',
      successful: 'DATA_GET_SWAGGER_WITH_NODE_SUCCESSFUL',
      fail: 'DATA_GET_SWAGGER_WITH_NODE_FAIL'
    },
    embeddedType: null,
    fnName: 'getSwaggerWithNodeRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/spaces-api`,
    method: 'GET',
    url: `/swagger/${requestArguments.path['node']}`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'getSwaggerWithNodeRequest', '/spaces-api'),
    actions: {
      start: {
        type: DATA_GET_SWAGGER_WITH_NODE_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_SWAGGER_WITH_NODE_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_SWAGGER_WITH_NODE_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getSwaggerWithNodeRequestDefinitions = (definitionsArguments: {| requestArgs: getSwaggerWithNodeRequestSpacesArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getSwaggerWithNodeRequestSpacesArguments, payload?: Object |} => ({
  fnName: 'getSwaggerWithNodeRequest',
  basePath: '/spaces-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_GET_SWAGGER_WITH_CHILDNODE_START = 'DATA_GET_SWAGGER_WITH_CHILDNODE_START'
export const DATA_GET_SWAGGER_WITH_CHILDNODE_SUCCESSFUL = 'DATA_GET_SWAGGER_WITH_CHILDNODE_SUCCESSFUL'
export const DATA_GET_SWAGGER_WITH_CHILDNODE_FAIL = 'DATA_GET_SWAGGER_WITH_CHILDNODE_FAIL'

// Returns a second level node from the Swagger specification of the API
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/spaces.yaml#/Swagger/get_swagger__node___childNode_
export const getSwaggerWithChildNodeRequest = (
  requestArguments: getSwaggerWithChildNodeRequestSpacesArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_SWAGGER_WITH_CHILDNODE_START',
      successful: 'DATA_GET_SWAGGER_WITH_CHILDNODE_SUCCESSFUL',
      fail: 'DATA_GET_SWAGGER_WITH_CHILDNODE_FAIL'
    },
    embeddedType: null,
    fnName: 'getSwaggerWithChildNodeRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/spaces-api`,
    method: 'GET',
    url: `/swagger/${requestArguments.path['node']}/${requestArguments.path['childNode']}`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'getSwaggerWithChildNodeRequest', '/spaces-api'),
    actions: {
      start: {
        type: DATA_GET_SWAGGER_WITH_CHILDNODE_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_SWAGGER_WITH_CHILDNODE_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_SWAGGER_WITH_CHILDNODE_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getSwaggerWithChildNodeRequestDefinitions = (definitionsArguments: {| requestArgs: getSwaggerWithChildNodeRequestSpacesArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getSwaggerWithChildNodeRequestSpacesArguments, payload?: Object |} => ({
  fnName: 'getSwaggerWithChildNodeRequest',
  basePath: '/spaces-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})
