// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.

/*
** NOTE: This container is to be used only if DropDown has to make a request to get its items
*/

import React, { Component } from 'react'
import { connect } from 'react-redux'

import DropdownMenu, { type DropdownMenuProps } from '../../common/menus/DropdownMenu/DropdownMenu'
import type { MenuItemProps } from '../../common/menus/components/MenuItems/MenuItem/MenuItem'

import DropdownSearchMenu from '../../common/menus/DropdownSearchMenu/DropdownSearchMenu'
import { parseMenuItemTooltips } from '../../../utils/parseUtil'
import { runGAR } from '../../../utils/GARUtils'

type State = {
  options: Array<TVDMenuItem>, // array of dropdown options
}

type StateProps = {|
  application: string, // currently active application
|}

type Props = {|
  ...DropdownMenuProps,
  ...StateProps,
  onDropDownChange: Function, // function bound to dropdown onChange event
  defaultValue: string, // default value that is selected in the dropdown on mount
  searchable?: boolean, // indicates if DropdownSearchMenu component is used
  requestDefinition: TVDGARConfig, // request definitions for a GAR that is exectued on didMount and result shown in the options of the dropdown
|}

export class DropDownContainer extends Component<Props, State> {
  static defaultProps = {
    onDropDownChange: () => {},
    searchable: false
  }

  state = {
    options: [],
  }

  _mounted: boolean

  componentDidMount() {
    this._mounted = true
    const { requestDefinition } = this.props

    runGAR({
      ...requestDefinition,
      successCb: (parsedResponse: Array<MenuItemProps>) => {
        this.setState({ options: parsedResponse })
      }
    })
  }

  componentWillUnmount() {
    this._mounted = false
  }

  handleChange = (selection: string, item: TVDMenuItem) => {
    this.props.onDropDownChange(selection, item)
  }

  render(): React$Element<any> {
    const {
      defaultValue,
      disabled,
      id,
      disabledTitle,
      width,
      required,
      minimalist,
      searchable,
      title
    } = this.props
    const { options } = this.state
    const items = parseMenuItemTooltips(options)

    const props: DropdownMenuProps = {
      items,
      defaultValue,
      disabled,
      id,
      onChange: this.handleChange,
      disabledTitle,
      width,
      required,
      minimalist,
      title
    }

    return (
      <div>
        { searchable ? <DropdownSearchMenu {...props} /> : <DropdownMenu {...props} /> }
      </div>
    )
  }
}

function mapStateToProps(state: Object): Object {
  const { app: { application } } = state
  return {
    application
  }
}

export default connect(mapStateToProps)(DropDownContainer)
