// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.

import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import Icon from '@material-ui/core/Icon'

const styles = () => ({
  iconWrapper: {
    display: 'flex',
    alignItems: 'center'
  }
})

type Props = {
  classes: Object, // withStyles classes object
  id: string,
  truthy: boolean, // statement is truthy
  truthyIcon: string, // Icon to show if truthy prop is true
  falsyIcon: string, // Icon to show if truthy prop is false
}

class SwitchIcon extends Component<Props> {
  static defaultProps = {
    id: 'SwitchIcon'
  }

  render(): React$Element<any> {
    const {
      truthy,
      truthyIcon,
      falsyIcon,
      id
    } = this.props

    const iconStyles = { fontSize: '20px' }

    return truthy
      ? <Icon id={`${id}-truthy`} style={iconStyles}>{truthyIcon}</Icon>
      : <Icon id={`${id}-falsy`} style={iconStyles}>{falsyIcon}</Icon>
  }
}

export default withStyles(styles)(SwitchIcon)
