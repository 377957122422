// Copyright © 2010–2022 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.
import { useEffect, useState } from 'react';
var useSubscriptionChanges = function (_a) {
    var connection = _a.connection;
    var _b = useState(null), notification = _b[0], setNotification = _b[1];
    useEffect(function () {
        connection.on('ReceiveStateChangeNotification', function (notificationData) {
            setNotification(notificationData);
        });
    }, []);
    return { notification: notification };
};
export default useSubscriptionChanges;
