// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.

import { reduce } from 'lodash'
import { getListItemsWithoutBranch } from '../../listUtils'

const modifyListItems = (state: Object, listId: string, columns: Object, options: Object, cb: Function) => {
  const list = state[listId] || {}
  const { addListLastUpdated } = options
  const { filteredListItems, listItems } = list
  return {
    ...state,
    [listId]: {
      ...list,
      ...((columns ? { columns } : {}): { columns?: Object }),
      ...((addListLastUpdated ? { listLastUpdated: new Date().getTime() } : {}): { listLastUpdated?: number }),
      listItems: cb(listItems || {}),
      ...(filteredListItems ? { filteredListItems: cb(filteredListItems) } : {})
    }
  }
}

const isMergedListItemOpen = (
  listItem: TVDListItem | TVDWOPListItem,
  newListItem: TVDListItem | TVDWOPListItem,
  options: TVDMergeOptions
): boolean => {
  const { openAllListItems, useExpanded } = options
  switch (true) {
    case !!useExpanded: return !!(newListItem.expanded || listItem.expanded)
    case openAllListItems: return true
    case options.useNewListItemOpenStatus: return !!newListItem.isOpen
    default: return !!listItem.isOpen
  }
}

const getMergedListItem = (listItem: TVDListItem, newListItem: TVDListItem, parent: Object, options: TVDMergeOptions) => {
  const parentIdObj = {}
  if (options.removeParentIds) parentIdObj.parentId = null
  if (options.newParentId) parentIdObj.parentId = options.newParentId
  return {
    ...listItem,
    ...newListItem,
    ...parentIdObj,
    isOpen: isMergedListItemOpen(listItem, newListItem, options),
    selected: newListItem.selected || listItem.selected,
    columnData: {
      ...listItem.columnData,
      ...newListItem.columnData,
      ...options.addedColumnData
    }
  }
}

const isFlatList = (config: Object) => config && config.params && config.params.listType === 'flat'

const getReducedInitialListItemsWithParentId = (listItems: Object, parentId: string) =>
  reduce(listItems, (result: Object, nextInitialListItem: Object) =>
    (nextInitialListItem.parentId === parentId ? result : { ...result, [nextInitialListItem.id]: nextInitialListItem }), {})

const getInitialListItems = ({ listItems, options, config }: Object) => {
  const {
    removeInitialListItemsWithParentIdOf,
    initialListItems,
    removeInitialListItemsBranchWithIdOf,
    keepOldList,
  } = options
  let items = {}
  switch (true) {
    case typeof initialListItems === 'object': { items = initialListItems; break }
    case isFlatList(config) && !keepOldList: { items = {}; break }
    default: { items = listItems; break }
  }

  switch (true) {
    case !!removeInitialListItemsWithParentIdOf: {
      return getReducedInitialListItemsWithParentId(listItems, removeInitialListItemsWithParentIdOf)
    }
    case !!removeInitialListItemsBranchWithIdOf: {
      return getListItemsWithoutBranch(listItems, removeInitialListItemsBranchWithIdOf)
    }
    default: {
      return items
    }
  }
}

// intentionally only syncing if found direct counter part from listItems and not taking in account if parent is selected -> design team choice
const getSelectedStatus = (id: string, newListItems: Object = {}, listItems: Object = {}, initialListItems: Object = {}): boolean => {
  const listItem = listItems[id]
  const newListItem = newListItems[id]
  const initialListItem = initialListItems[id]
  return !!listItem?.selected ||
      !!newListItem?.selected ||
      !!initialListItem?.selected
}

type MergeListItemsArguments = {|
  state: Object,
  listId: string,
  newListItems: Object,
  config?: Object,
  options?: TVDMergeOptions,
  columns?: Object
|}

export const mergeListItems = ({
  state = {},
  listId,
  newListItems,
  config = {},
  options = {},
  columns,
}: MergeListItemsArguments): Object => {
  const defaultOptions = { addedColumnData: {} }
  const opts = { ...defaultOptions, ...options }

  return modifyListItems(state, listId, columns, opts, (listItems: Object) => {
    // we keep old listItems if the new list items is not a flat list
    const result = { ...getInitialListItems({ listItems, options: opts, config }) }
    const newListItemIds = Object.keys(newListItems)
    let { length } = newListItemIds
    while (length) {
      const id = newListItemIds[length - 1]
      const parent = result[newListItems[id].parentId] || {}
      const oldListItem = result[id]
      if (oldListItem) {
        result[id] = getMergedListItem(oldListItem, newListItems[id], parent, opts)
      } else {
        const parentIdObj = {}
        const isOpenWithUseExpanded = opts.useExpanded ? newListItems[id].expanded : false
        if (options.removeParentIds) parentIdObj.parentId = null
        if (options.newParentId) parentIdObj.parentId = options.newParentId
        result[id] = {
          ...newListItems[id],
          ...parentIdObj,
          columnData: {
            ...newListItems[id].columnData,
            ...opts.addedColumnData,
          },
          isOpen: isOpenWithUseExpanded || opts.openAllListItems || newListItems[id].isOpen || !!(listItems[id] || {}).isOpen,
          selected: opts.unselectAllListItems ? false : getSelectedStatus(id, newListItems, listItems)
        }
      }

      length -= 1
    }
    return result
  })
}
