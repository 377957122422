// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.
import { applyMiddleware, createStore, combineReducers } from 'redux'
import { routerMiddleware, connectRouter } from 'connected-react-router'
import { createBrowserHistory } from 'history'
import thunk from 'redux-thunk'
import * as reducers from './reducers'
import locationChangeMiddleware from './middlewares/locationChangeMiddleware'
import modalMiddleware from './middlewares/modalMiddleware'
import activityMiddleware from './middlewares/activityMiddleware'
import widgetLocationMiddleware from './middlewares/widgetLocationMiddleware'
import listMiddleware from './middlewares/listMiddleware'
import localPersistenceMiddleware from './middlewares/localPersistenceMiddleware'
import componentPreferencesMiddleware from './middlewares/componentPreferencesMiddleware'

export const history = createBrowserHistory()

const createStoreWithMiddleware = applyMiddleware(
  thunk,
  locationChangeMiddleware,
  modalMiddleware,
  activityMiddleware,
  widgetLocationMiddleware,
  listMiddleware,
  localPersistenceMiddleware,
  routerMiddleware(history),
  componentPreferencesMiddleware
)(createStore)
const reducer = combineReducers({
  router: connectRouter(history),
  ...reducers
})

export const createAppStore = (initialState = {}) => createStoreWithMiddleware(
  reducer,
  initialState,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
)

export const store = createAppStore()

export const { dispatch, getState } = store
