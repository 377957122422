// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.
import { reduce, join, map, set, omitBy, isUndefined, isEmpty, has } from 'lodash'
import { v4 } from 'uuid'
import HALParser, { ALL_KEYS } from './HALParser'
import { isEnum } from './commonUtils'
import { USER_GROUP, USER, ESTIMATE } from '../constants/contentTypes'

// Data: Data object destructured from http request response
// Level: Level of items that are currently parsed
// ItemKey: View based key for items that should be parsed from data. e.g. scheduleitem, catalogitem etc...
export const parseHierarchicalList = (response, level) => {
  const item = HALParser.getItems(response)
  return reduce(item, (obj, param) => {
    const { id } = param
    obj[id] = param
    obj[id].level = level
    return obj
  }, {})
}

export const parseSurfacesData = response => HALParser
  .getItemsMap(response.data, ({
    [ALL_KEYS.COLUMN_DATA]: columnData,
    [ALL_KEYS.ID]: id,
    [ALL_KEYS.TYPE]: type,
  }) => ({
    columnData,
    id,
    type,
  }))

export const parseTabRowOptions = ({ data }) => {
  const options = HALParser.getEmbedded(data).enumeral
  return map(options, option => ({ value: option.value, localizedText: option.localizedName }))
}

export const parseCategorizedPropertyList = (data) => {
  const result = {}
  map(data, (item) => {
    const { categories, propertyName } = item
    set(result, `${join(categories, '.')}.${propertyName}`, item)
  })
  return result
}

export const parseProperties = response => HALParser.getPropertiesMap(response, (property) => {
  const { dataType } = property
  const url = isEnum(dataType) ? HALParser.getLinksEnumHref(property) : undefined
  const selected = isEnum(dataType) ? property.localizedValue : undefined
  return omitBy({
    url,
    selected,
    ...property
  }, isUndefined)
})

export const parsePricelistItems = response => HALParser.getItems(response).map((item) => {
  const { type } = item
  const url = isEnum(type) ? HALParser.getLinksEnumHref(item) : undefined
  const selected = isEnum(type) ? type.localizedValue : undefined
  return omitBy({
    url,
    selected,
    ...item
  }, isUndefined)
})

export const parsePropertyDropDown = data => map(HALParser.getEnumeral(data), item => ({
  value: item.value,
  localizedName: item.localizedName
}))

export const parseAssemblyElements = response => (
  reduce(HALParser.getItems(response), (obj, param) => {
    if (param === null) {
      return obj
    }
    const { id } = param
    obj[id] = HALParser.getColumnData(param)
    obj[id].self = HALParser.getLinksSelfHref(param)
    return obj
  }, {})
)

export const parseHierarchyTooltip = hierarchyArray => join(map(hierarchyArray, hierarchyLevel => hierarchyLevel.localizedName), ' › ')

export const parseMenuItemTooltips = options => options.map((option) => {
  if (!has(option, '_embedded') || Object.keys(option._embedded).length === 0) return option
  const embedded = HALParser.getEmbedded(option)
  const embeddedName = Object.keys(embedded)[0]
  const tooltip = embeddedName === 'hierarchy' ? parseHierarchyTooltip(embedded[embeddedName]) : embedded[embeddedName]
  const { _embedded, ...rest } = option
  return { ...rest, tooltip }
})

export const parseRegistryItems = response =>
  map(response, item => ({
    value: item.value,
    localizedName: item.localizedName,
    tooltip: parseHierarchyTooltip(((HALParser.getEmbedded(item) || {}).hierarchy) || '')
  }))

export const parseCanMoveToItems = response => map(HALParser.getItems(response), (item) => {
  if (!isEmpty(HALParser.getEmbedded(item))) {
    return {
      value: item.value,
      localizedName: item.localizedName,
      tooltip: parseHierarchyTooltip(HALParser.getEmbedded(item).hierarchy) || ''
    }
  }
  return item
})

export const parseFloors = ({ data }) => HALParser.getEmbedded(data).stories

export const parseCalculations = ({ data }) => HALParser.getEmbedded(data).estimates

export const parseUser = ({ data }) => data

export const parseUserApps = ({ data }) => HALParser.getEmbedded(data).applicationIds

export const parseApps = ({ data }) => HALParser.getEmbedded(data).enumeral

export const parseOrganizationsDropdown = ({ data }) => {
  const parsed = data instanceof Array ? data : [data] // [data] -> if single organization, wrap it in Array (will be changed when api is ready)
  const modifiedAttributes = map(parsed, (organization) => {
    const { id, name, ...rest } = organization
    return {
      localizedName: name,
      value: id,
      ...rest
    }
  })
  return modifiedAttributes
}

export const parseEstimates = response => reduce(HALParser.getEstimates(response), (result, estimate) => {
  const { estimateType, id } = estimate
  if (!result[estimateType]) {
    result[estimateType] = {}
  }
  result[estimateType][id] = estimate
  return result
}, {})

export const parseBuildingsIntoListItems = buildingList =>
  reduce(buildingList, (result, building) => {
    const {
      buildingName, id, accountId, realEstateId
    } = building
    const userRights = building._embedded?.permissions ? building._embedded.permissions[0].permissions : []
    return {
      ...result,
      [building.id]: {
        id,
        accountId,
        realEstateId,
        type: 'building',
        canHaveChildren: true, // buildings always have primary estimates, so they can always fetch their primary estimates as children
        columnData: { Description: buildingName, UserRights: userRights }
      }
    }
  }, {})

export const parseEstimatesIntoListItems = estimatesList =>
  reduce(estimatesList, (result, estimate) => {
    const {
      buildingId,
      estimateType,
      description,
      permissionsSubjectPermissions,
      ...restOfEstimate
    } = estimate
    const userRights = permissionsSubjectPermissions
    return {
      ...result,
      [restOfEstimate.id]: {
        ...restOfEstimate,
        parentId: buildingId,
        type: ESTIMATE.toLowerCase(),
        columnData: { Description: description, UserRights: userRights, EstimateType: estimateType }
      }
    }
  }, {})

export const parseUserPermissions = (PermissionsResponse, parentId) =>
  PermissionsResponse.users.reduce((result, user) => {
    const { permissions = [] } = PermissionsResponse.permissions.find(permission => permission.id === user.id) || {}
    const id = v4()
    return {
      ...result,
      [id]: {
        id,
        subjectId: user.id,
        parentId,
        type: USER.toLowerCase(),
        columnData: {
          Description: `${user.firstName} ${user.lastName}`,
          UserRights: permissions
        },
      }
    }
  }, {})


export const parseUserGroupPermissions = (PermissionsResponse, parentId) =>
  PermissionsResponse.usergroups.reduce((result, usergroup) => {
    const { permissions = [] } = PermissionsResponse.permissions.find(permission => permission.id === usergroup.value) || {}
    const id = v4()
    return {
      ...result,
      [id]: {
        id,
        subjectId: usergroup.value,
        parentId,
        type: USER_GROUP.toLowerCase(),
        columnData: {
          Description: usergroup.localizedName,
          UserRights: permissions
        },
      }
    }
  }, {})
