// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withTranslation } from 'react-i18next'
import { withStyles } from '@material-ui/core/styles'
import { Table, TableBody } from '@material-ui/core'
import { map } from 'lodash'
import { typographyClasses, colors } from 'frontend-assets'

import SentientHOC from '../../../hocs/SentientHOC/SentientHOC'
import FeaturesHOC from '../../../hocs/FeaturesHOC/FeaturesHOC'
import HierarchicalListContainer from '../../HierarchicalListContainer/HierarchicalListContainer'
import DescriptionCell from '../../../common/lists/common/DescriptionCell/DescriptionCell'
import PropertyRow from '../../../common/Tabs/Properties/PropertyRow/PropertyRow'
import HamburgerMenu from '../../../common/menus/HamburgerMenu/HamburgerMenu'

import { toggleWidgetModified, markAsModified, editProperty, openContentWidget } from '../../../../actions/widgets'
import { postPolling } from '../../../../actions/postPolling'
import { buildAddItemSpaceAssembly, openModal, closeModal } from '../../../../actions/modals'
import { ASSEMBLY_ATTRIBUTES } from '../../../../constants/features'
import { EQUIPMENT, PRICEITEM, CONFIRMATION_MODAL, HEADING } from '../../../../constants/contentTypes'
import { DESCRIPTION, QUANTITYTOTAL } from '../../../../constants/attributes'
import { formatValue, isListItemTypeBold } from '../../../../utils/listUtils'
import {
  getSpacesSurfacesItemsWithItemIdRequestDefinitions,
  getSpacesSurfacesPropertiesWithIdRequestDefinitions,
  deleteSpacesSurfacesAssemblyItemsWithItemIdRequest
} from '../../../../utils/generated-api-requests/spaces'
import ViewHeaderText from '../../../common/ViewHeaderText/ViewHeaderText'

const { h4 } = typographyClasses
const { dark80 } = colors

const styles = ({ palette }: Object): Object => ({
  root: {
    display: 'flex',
    flex: '1',
    flexDirection: 'column',
    position: 'relative'
  },
  attributesTable: {
    marginLeft: '15px',
    borderTop: `1px solid ${palette.ui07}`,
  },
  referenceCellContent: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    marginRight: 10,
    width: '100%',
    outline: 0
  },
  title: {
    ...h4,
    color: dark80,
    padding: '32px 24px 24px',
    alignSelf: 'flex-start'
  }
})

type DispatchProps = {|
  dispatchToggleWidgetModified: (boolean) => void, // function to toggle widget modified in reducer
  dispatchEditProperty: (string, string | number, string) => void, // posts attribute's new value to API
  dispatchOpenContentWidget: (openContentWidgetArgs: TVDOpenContentWidgetArguments) => void, // opens a content widget
  dispatchPostPolling: () => void, // start post polling process to update all sentient components
  dispatchBuildAddItemSpaceAssemblyModal: (string, string, string, Object) => void, // open attach priceitem modal
  dispatchDeleteLItemSpaceAssembly: (content: Object) => void, // delete price item row action
  dispatchCloseModal: Function, // closing modal action
|}

type ReceivedProps = {|
  spaceId: string, // id for the space in question
  disabled: boolean, // flag to disable widget content if other widgets are beign modified
  itemsListStoreId: string, // assembly widget list id
  attributesPropertiesStoreId: string, // properties id to correspond one found in Redux Store's properties
  resourceId: string, // id of the resource
  listStoreId: string, // id used to access list items from store
  widgetId: string, // id of the widget received from the <Content /> props spread
  modified: string, // modified status of the widget received from the <Content /> props spread
|}

type MappedProps = {|
  listItems: TVDListItems, // list items for the hierarchical list
  isEstimateFrozen: $PropertyType<TVDApplicationStore, 'isEstimateFrozen'>,
  isEstimateLockedToCurrentUser: $PropertyType<TVDApplicationStore, 'isEstimateLockedToCurrentUser'>,
  activeEdit: boolean, // whether the app is in active edit mode
  resourceId: string, // id for the assembly
  properties: { [propertyName: string]: TVDPropertiesListItem }, // array of property objects for assembly's attribute list
|}

type HOCProps = {|
  t: Function, // translate function
  classes: Object, // withStyles classes object
  sentient: TVDSentient, // Object providing helpers via SentientHOC
  features: TVDFeatureHOCProps, // features from Store and helper functions from the HOC
|}

// TODO fetch column data from proper endpoint
const defaultColumns = [
  {
    propertyName: DESCRIPTION,
    localizedName: 'Selite',
    dataType: 'string'
  },
  {
    propertyName: QUANTITYTOTAL,
    localizedName: 'Määrä',
    dataType: 'number',
  }
]

type Props = {|
  ...DispatchProps,
  ...ReceivedProps,
  ...MappedProps,
  ...HOCProps,
|}

export class SpacesAssembly extends Component<Props> {
  static defaultProps = {
    isEstimateLockedToCurrentUser: false,
  }

  showContextMenu = (): boolean => {
    const {
      isEstimateFrozen,
      isEstimateLockedToCurrentUser
    } = this.props

    return !isEstimateFrozen && !!isEstimateLockedToCurrentUser
  }

  getContextMenuItems(row: TVDListItem): Array<TVDMenuItem> {
    const {
      t,
      dispatchPostPolling,
      dispatchBuildAddItemSpaceAssemblyModal,
      dispatchDeleteLItemSpaceAssembly,
      dispatchCloseModal,
      spaceId,
      resourceId,
      listItems
    } = this.props

    if (!this.showContextMenu()) return []

    const content = {
      type: CONFIRMATION_MODAL,
      saveButtonText: 'buttons._DELETE_',
      onSave: () => {
        deleteSpacesSurfacesAssemblyItemsWithItemIdRequest(
          { path: { spaceId, assemblyId: resourceId, itemId: row.id } },
          {},
          () => { dispatchPostPolling() }
        )
      },
      onClose: () => dispatchCloseModal(),
      message: 'listItemDialog._CONFIRMDELETE_PRICEITEM_'
    }

    const addItem = {
      localizedName: t('widgets._ADD_PRICE_ITEM_SPACES_ASSEMBLY_'),
      onClick: () => { dispatchBuildAddItemSpaceAssemblyModal(row.id, spaceId, resourceId, listItems) },
      testId: 'ContextMenuItem-add'
    }

    const deleteItem = {
      localizedName: t('buttons._DELETE_'),
      onClick: () => dispatchDeleteLItemSpaceAssembly(content),
      testId: 'ContextMenuItem-delete'
    }

    return [addItem, deleteItem]
  }

  getHamburgerMenu(row: Object): React$Element<HamburgerMenu> | null {
    if (!this.showContextMenu()) return null
    const { columnData: { Description: rowDescription } = {} } = row || {}
    const menuItems = this.getContextMenuItems(row)

    return <HamburgerMenu visibleOnHover id={rowDescription} items={menuItems} />
  }

  attributesTable(): React$Element<any> {
    const {
      attributesPropertiesStoreId,
      dispatchEditProperty,
      features,
      properties,
      t,
      isEstimateFrozen,
      isEstimateLockedToCurrentUser
    } = this.props
    const isEstimateNotLockedOrFrozen = !isEstimateLockedToCurrentUser || isEstimateFrozen

    return map(properties, (property: Object) => (
      <PropertyRow
        key={property.propertyName}
        row={property}
        actionText={t('userModifiedInfo._SPACES_RESET_DEFAULT_VALUE_')}
        disabled={features.getIsFeatureDisabled(ASSEMBLY_ATTRIBUTES) || isEstimateNotLockedOrFrozen}
        rowText={property.localizedName}
        onBlur={(propertyName: string, value: string | number) => {
          dispatchEditProperty(propertyName, formatValue(value, property.dataType), attributesPropertiesStoreId)
        }}
        onAccept={(propertyName: string, value: string | number) => {
          dispatchEditProperty(propertyName, formatValue(value, property.dataType), attributesPropertiesStoreId)
        }} />
    ))
  }

  referenceLevelTable(): React$Element<any> {
    const {
      classes,
      disabled,
      itemsListStoreId,
      dispatchToggleWidgetModified,
      sentient,
      isEstimateFrozen,
      isEstimateLockedToCurrentUser
    } = this.props

    return (
      <HierarchicalListContainer
        initialColumnWidths={{
          [DESCRIPTION]: 790,
        }}
        isEstimateFrozen={isEstimateFrozen}
        isEstimateLockedToCurrentUser={isEstimateLockedToCurrentUser}
        disabled={disabled}
        listId={itemsListStoreId}
        testId='assemblyList'
        editableColumns={[QUANTITYTOTAL]}
        editableColumnCheck={(row: TVDListItem, column: TVDListItemColumn): boolean => {
          const isHeading = row.type === HEADING.toLowerCase()
          if (isHeading) return false
          return column.propertyName === QUANTITYTOTAL
        }}
        didMountCallback={() => { sentient.runSetup() }}
        wrappedCellContents={{
          Description: ({ content, row }: TVDWrappedCellCallbackParameters): React$Element<any> => {
            const isHeading = row.type === HEADING.toLowerCase()
            return (
              <div
                role='button'
                tabIndex={0}
                className={classes.referenceCellContent}>
                <DescriptionCell
                  noPointer={isHeading}
                  onClick={!isHeading ? () => { this.getOpenWidgetFromSurfaces(row) } : null}
                  bold={isListItemTypeBold(row.type)}
                  data-testid='test-description-cell'
                  text={content}
                  highlighted={!isHeading} />
                {this.getHamburgerMenu(row)}
              </div>
            )
}
        }}
        onModifiedChange={(isModified: boolean) => {
          if (isModified && dispatchToggleWidgetModified) {
            dispatchToggleWidgetModified(isModified)
          }
        }} />
    )
  }

  getOpenWidgetFromSurfaces(row: Object) {
    const { activeEdit } = this.props
    if (!activeEdit) this.openWidgetFromSurfaces(row.id, row.columnData.Description, row.type)
  }

  openWidgetFromSurfaces(id: string, title: string, widgetType: string): Function {
    const attributesPropertiesStoreId = `${id}-attributes`
    const { dispatchOpenContentWidget, spaceId, itemsListStoreId } = this.props
    if ((widgetType.toLowerCase() === EQUIPMENT.toLowerCase()) || (widgetType.toLowerCase() === PRICEITEM.toLowerCase())) {
      dispatchOpenContentWidget({
        widgetId: id,
        widgetType: EQUIPMENT,
        widgetTitle: title,
        contentProps: {
          listStoreId: itemsListStoreId,
          spaceId,
          equipmentId: id,
          propertiesStoreId: attributesPropertiesStoreId
        },
      })
    }
  }

  render(): React$Element<any> {
    const { classes, t } = this.props

    return (
      <div className={classes.root}>
        <ViewHeaderText inWidget>{t('widgets._ASSEMBLY_AMOUNT_')}</ViewHeaderText>
        <Table className={classes.attributesTable}>
          <TableBody>
            {this.attributesTable()}
          </TableBody>
        </Table>
        <div className={classes.title}>{t('widgets._ASSEMBLY_ITEMS_')}</div>
        {this.referenceLevelTable()}
      </div>
    )
  }
}

const mapStateToProps = ({ properties, app, list }: TVDReduxStore, props: ReceivedProps): MappedProps => {
  const { attributesPropertiesStoreId, resourceId, listStoreId } = props
  const { activeEdit, isEstimateFrozen, isEstimateLockedToCurrentUser } = app
  return {
    properties: properties[attributesPropertiesStoreId],
    activeEdit,
    resourceId,
    listItems: list[listStoreId].listItems,
    isEstimateFrozen,
    isEstimateLockedToCurrentUser
  }
}

function mapDispatchToProps(dispatch: Function, props: ReceivedProps): Object {
  const { widgetId, modified } = props
  return {
    dispatchOpenContentWidget: (openContentWidgetArgs: TVDOpenContentWidgetArguments) => {
      dispatch(openContentWidget(openContentWidgetArgs))
    },
    dispatchEditProperty: (propertyName: string, value: string | number, listId: string) => {
      dispatch(editProperty(listId, propertyName, value))
      if (!modified) dispatch(markAsModified(widgetId))
    },
    dispatchToggleWidgetModified: (isModified: boolean) => {
      dispatch(toggleWidgetModified(widgetId, '', isModified))
    },
    dispatchBuildAddItemSpaceAssemblyModal: (rowId: string, spaceId: string, assemblyId: string, listItems: Object) => {
      dispatch(buildAddItemSpaceAssembly(rowId, spaceId, assemblyId, listItems))
    },
    dispatchDeleteLItemSpaceAssembly: (content: Object) => { dispatch(openModal(content, CONFIRMATION_MODAL)) },
    dispatchCloseModal: () => { dispatch(closeModal(CONFIRMATION_MODAL)) },
    dispatchPostPolling: () => {
      dispatch(postPolling())
    }
  }
}

type SentientProps = {|
  ...Props,
  resourceId: string, // id of opened widget
  spaceId: string, // id of opened space
|}

const sentientConfig: TVDSentientConfig = {
  getSetupRequestDefinitions: (store: Object, {
    resourceId,
    spaceId,
    itemsListStoreId,
    attributesPropertiesStoreId
  }: SentientProps): TVDGARConfigs =>
    ({
      getSpacesSurfacesItemsWithItemIdRequestDefinitions: getSpacesSurfacesItemsWithItemIdRequestDefinitions({
        payload: { listId: itemsListStoreId, mergeOptions: { removeParentIds: true, initialListItems: {} }, columns: defaultColumns },
        requestArgs: { path: { itemId: resourceId, spaceId } },
      }),
      getSpacesSurfacesPropertiesWithIdRequestDefinitions: getSpacesSurfacesPropertiesWithIdRequestDefinitions({
        payload: { propertiesStoreId: attributesPropertiesStoreId },
        requestArgs: { path: { spaceId, id: resourceId } }
      })
    })
}

export default SentientHOC(compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation('translations'),
  withStyles(styles),
  FeaturesHOC
)(SpacesAssembly), sentientConfig)
