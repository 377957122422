// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.

import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import Input from '@material-ui/core/Input'
import Icon from '@material-ui/core/Icon'
import InputAdornment from '@material-ui/core/InputAdornment'
import { combineStyleClassNames } from '../../../../../utils/styleUtils'
import theme from '../../../../../styles/theme'

const styles = ({ palette }: TVDTheme) => ({
  root: {
    ...theme.typography.classes.bodyBig,
    color: palette.dark80,
    maxHeight: '30px',
    '&$focused': {
      backgroundColor: palette.white,
      borderBottom: 'none'
    },
    '&:hover': {
      backgroundColor: palette.gray20,
      color: palette.dark80,
      borderBottom: 'none'
    },
    '&&&$disabled': {
      backgroundColor: palette.white
    }
  },
  placeholderMode: {
    color: palette.dark60
  },
  underline: {
    '&::before': {
      borderBottomColor: palette.gray80
    },
    '&::after': {
      borderBottom: `2px solid ${palette.primary60} !important`,
    },
    // needs to override a triple :not rule of MUI
    // https://github.com/mui-org/material-ui/issues/12192
    '&&&&:hover:before': {
      borderBottom: 'none'
    },
    '&$focused': {
      color: palette.black
    },
    '&$error:after': {
      borderBottomColor: palette.error100
    },
    '&$disabled': {
      borderBottom: 'none'
    },
  },
  '[class*="MuiInput-underline"]:before': {
    borderBottomColor: 'none'
  },
  disabled: {
    borderBottom: `1px dashed ${palette.gray80}`,
    // we need to override the specificity of the above rule so a disabled select doesn't get a blue underline hover
    '&&&$disabled:before': {
      borderBottom: 'none',
    }
  },
  focused: {
    '&$focused': {
      backgroundColor: palette.white
    },
    '&:after': {
      color: palette.black,
      borderBottom: `2px solid ${palette.primary60}`,
    },
  },
  error: {
    '&$underline:before': {
      borderBottomColor: palette.error100
    }
  },
  errorUnderline: {
    // needs to override a triple :not rule of MUI
    // https://github.com/mui-org/material-ui/issues/12192
    '&&&&:hover:before': {
      borderBottom: `2px solid ${palette.error100}`
    }
  },
  minimalistRoot: {
    ...theme.typography.classes.bodyDefault,
    color: palette.black,
    borderBottom: 'none',
    marginTop: '0px !important', // overrides a mui reservation for the input label that is disabled in the minimalist version
    maxHeight: '28px',
    '&:active': {
      backgroundColor: palette.white
    },
    '&:hover': {
      backgroundColor: palette.gray20
    }
  },
  minimalistDisabled: {
    '&:hover': {
      backgroundColor: 'transparent',
      color: palette.gray100
    }
  },
  minimalistFocused: {
    '&:active': {
      backgroundColor: palette.white
    },
    '&$focused': {
      backgroundColor: palette.white
    }
  },
  errorIcon: {
    fontSize: '16px',
    paddingRight: '10px',
    color: palette.messageError
  },
  fullHeight: {
    height: '100%',
    maxHeight: '100%'
  },
  leftAlignedInputText: {
    textAlign: 'left'
  },
  rightAlignedInputText: {
    textAlign: 'right'
  },
  textOverflowEllipsis: {
    textOverflow: 'ellipsis'
  }
})

export type AlignInputText = 'left' | 'right' | ''

type Props = {|
  classes: Object, // withStyles object
  id: string, // test id for input
  minimalist?: boolean, // whether to use a minimalist version of the dropdownInput
  error?: boolean, // whether this input is in an error state
  placeholderMode?: boolean, // used to select alternative styles when a placeholder is being shown
  fullHeight?: boolean, // set height for DropdownInput to 100%
  alignInputText?: AlignInputText, // optional setting to align input text to left or right
|}

export class DropdownInput extends Component<Props> {
  static defaultProps = {
    fullHeight: false,
    placeholderMode: false,
    minimalist: false,
    error: false,
    alignInputText: ''
  }
  render(): React$Element<Input> {
    // MUI passes its own props that need to be spread here
    const {
      classes,
      id,
      minimalist,
      error,
      placeholderMode,
      fullHeight,
      alignInputText,
      ...otherProps
    } = this.props
    const alignInputTextClass = alignInputText ? classes[`${alignInputText}AlignedInputText`] : null
    const normalClasses = {
      root: combineStyleClassNames(classes.root, fullHeight && classes.fullHeight, alignInputTextClass),
      input: combineStyleClassNames(classes.textOverflowEllipsis, placeholderMode && classes.placeholderMode, alignInputTextClass),
      disabled: classes.disabled,
      underline: classes.underline,
      error: classes.error,
      focused: classes.focused
    }

    const minimalistClasses = {
      ...normalClasses,
      root: combineStyleClassNames(classes.minimalistRoot, fullHeight && classes.fullHeight),
      disabled: classes.minimalistDisabled,
      focused: classes.minimalistFocused
    }

    return (
      <Input
        {...otherProps}
        startAdornment={error ? <InputAdornment position='start'><Icon className={classes.errorIcon}>warning</Icon></InputAdornment> : null}
        error={error}
        disableUnderline={minimalist}
        data-testid={id}
        classes={minimalist ? minimalistClasses : normalClasses} />
    )
  }
}

export default withStyles(styles)(DropdownInput)
