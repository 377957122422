// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withTranslation } from 'react-i18next'

import PermissionsListContainer from '../SettingsContainer/PermissionsListContainer/PermissionsListContainer'
import TabsPlain from '../../common/TabsPlain/TabsPlain'
import UserInfo, { type UserDataObject } from '../UserInfo/UserInfo'
import { type AutoCompleteMenuItem } from '../../common/menus/AutoCompleteMenu/AutoCompleteMenu'

import { buildConfirmationModal, closeModal } from '../../../actions/modals'
import { MODAL_TYPE_USER_MODAL } from '../../../constants/modalConstants'
import { USER_RIGHTS, DESCRIPTION, ESTIMATE_TYPE } from '../../../constants/attributes'
import { ACCESS_CONTROL_USER_RIGHTS } from '../../../constants/contentTypes'
import {
  deleteUserGroupsUsersWithUserIdRequest,
  patchUsersWithIdRequest,
  postUserGroupsUsersWithUserIdRequest
} from '../../../utils/generated-api-requests/users'
import { getUsersAPIBasePath } from '../../../utils/apiUtils'


type HOCProps = {|
  t: Function, // translate,
|}

type DispatchProps = {|
  dispatchCloseModal: () => void, // function to close user modal
  dispatchBuildConfirmationModal: (Object) => void, // function to create confirmationDialog if form is modified and closed
|}

type ReceivedProps = {|
  userId: string,
  patchUserSuccessCallBack: () => void, // callback function triggered when patch request to save user data returns successful status
  disableEdit?: boolean, // disable editing of user information
  modalId: string, // id of the modal the container belongs to
  showCheckBoxes?: boolean // if the checkboxes should be visible, this is for LIMA project to show the checkboxes in Account Information's Users tab
|}

type MappedProps = {|
  userType: $PropertyType<TVDApplicationStore, 'userType'>,
  isAccountAdministrator: $PropertyType<TVDUserStore, 'isAccountAdministrator'>
|}

type Props = {|
  ...HOCProps,
  ...MappedProps,
  ...DispatchProps,
  ...ReceivedProps
|}

export class UserModalContainer extends Component<Props> {
  static defaultProps = {
    disableEdit: false
  }

  handleSave = (userData: UserDataObject): Promise<any> => {
    const {
      dispatchCloseModal,
      userId,
      patchUserSuccessCallBack,
    } = this.props

    const {
      firstName,
      lastName,
      email,
      isUserActive,
      phone,
      userAddedToGroups,
      userRemovedFromGroups
    } = userData

    return Promise.all([
      patchUsersWithIdRequest(
        {
          path: { id: userId },
          body: {
            firstName,
            lastName,
            email,
            status: isUserActive ? 'active' : 'inactive',
            phone
          }
        },
        {},
        () => {
          patchUserSuccessCallBack()
        },
        null,
        this.getUsersAPIOptions()
      ),
      ...userAddedToGroups.map((group: AutoCompleteMenuItem): Promise<any> =>
        postUserGroupsUsersWithUserIdRequest(
          {
            path: {
              id: group.id,
              userId
            }
          },
          {},
          null,
          null,
          this.getUsersAPIOptions()
        )),
      ...userRemovedFromGroups.map((group: AutoCompleteMenuItem): Promise<any> =>
        deleteUserGroupsUsersWithUserIdRequest(
          {
            path: {
              id: group.id,
              userId
            }
          },
          {},
          null,
          null,
          this.getUsersAPIOptions()
        ))
    ]).then(() => {
      dispatchCloseModal()
    })
  }

  getTabs = (): Array<TVDTab> => {
    const {
      t,
      dispatchCloseModal,
      userId,
      dispatchBuildConfirmationModal,
      disableEdit,
      userType,
      showCheckBoxes
    } = this.props
    const currentPath = window.location.pathname

    let tabs = [
      {
        props: {
          label: t('userModal._USER_INFORMATION_'),
        },
        content: (<UserInfo
          isAccountAdministrator
          userType={userType}
          showCheckBoxes={showCheckBoxes}
          disableEdit={disableEdit}
          userId={userId}
          onClose={dispatchCloseModal}
          onSave={this.handleSave}
          confirm={dispatchBuildConfirmationModal} />),
      }
    ]

    if (!currentPath.includes('account-management')) {
      tabs = [...tabs,
        {
          props: {
            label: t('userModal._USER_PERMISSIONS_'),
          },
          contentStyles: {
            minHeight: '700px',
            maxHeight: '700px'
          },
          content: (
            <div id={`${ACCESS_CONTROL_USER_RIGHTS}-wrapper`} style={{ minHeight: '100%', maxHeight: '100%', display: 'flex' }}>
              <PermissionsListContainer
                modalId={MODAL_TYPE_USER_MODAL}
                subjectId={userId}
                listId={ACCESS_CONTROL_USER_RIGHTS}
                listType={ACCESS_CONTROL_USER_RIGHTS}
                testid={ACCESS_CONTROL_USER_RIGHTS}
                columns={[
                  { propertyName: DESCRIPTION, localizedName: t('settings._DESCRIPTION_'), dataType: 'string' },
                  { propertyName: USER_RIGHTS, localizedName: t('settings._USER_RIGHTS_'), dataType: 'string' },
                  { propertyName: ESTIMATE_TYPE, localizedName: t('settings._ESTIMATE_TYPE_'), dataType: 'string' },
                ]} />
            </div>
          )
        }
      ]
    }

    return tabs
  }

  getUsersAPIOptions = (): {|
    basePath: typeof undefined | string
  |} => {
    const { userType } = this.props
    return { basePath: userType && getUsersAPIBasePath(userType) }
  }

  render(): React$Element<TabsPlain> {
    return (
      <TabsPlain tabs={this.getTabs()} />
    )
  }
}

const mapStateToProps = ({ app: { userType }, user: { isAccountAdministrator } }: TVDReduxStore): MappedProps => ({
  userType,
  isAccountAdministrator
})

const mapDispatchToProps = (dispatch: Function, { modalId }: ReceivedProps): DispatchProps => ({
  dispatchBuildConfirmationModal: (content: Object) => dispatch(buildConfirmationModal(content)),
  dispatchCloseModal: () => { dispatch(closeModal(modalId)) }
})

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation('translations')
)(UserModalContainer)
