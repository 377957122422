// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import { forEach, keys, omit, reduce, map, mapValues, every, values, isEqual } from 'lodash'
import { withTranslation } from 'react-i18next'

import RelationalSelector from '../../../common/RelationalSelector/RelationalSelector'
import DropdownMenu from '../../../common/menus/DropdownMenu/DropdownMenu'
import LabeledInput from '../../../common/LabeledInput/LabeledInput'

import { postPolling as actionPostPolling } from '../../../../actions/postPolling'
import { parseRegistryItems } from '../../../../utils/parseUtil'
import {
  getRegistryFunctionsRequest,
  getRegistryFunctionalSectorsRequest,
  getRegistrySpaceGroupsRequest,
  getRegistrySpacesRequest,
  getRegistryFunctionsCanAttachToRequest,
  getRegistrySpaceGroupsCanAttachToRequest,
  getRegistrySpacesCanAttachToRequest,
  postSpaceScheduleFunctionalSectorsWithEstimateIdRequest,
  postSpaceScheduleFunctionsWithIdRequest,
  postSpaceScheduleSpaceGroupsWithIdRequest,
  postSpaceScheduleSpacesWithIdRequest,
  getRegistryFunctionalSectorsDriversWithIdRequest,
  getRegistryFunctionsDriversWithIdRequest,
  getRegistrySpaceGroupsDriversWithIdRequest,
  getRegistrySpacesDriversWithIdRequest,
  basePath
} from '../../../../utils/generated-api-requests/spaces'
import { runGAR, getEnumGARDefinition } from '../../../../utils/GARUtils'
import { SPACE, FUNCTION, SPACEGROUP } from '../../../../constants/contentTypes'
import { DRIVER, CODRIVER } from '../../../../constants/attributes'

const styles = () => ({
  content: {
    display: 'flex',
    width: '100%',
    height: '100%',
    padding: '0 0 16px 0px'
  },
  headerRight: {
    display: 'flex',
    flex: '1',
    alignItems: 'center',
    flexDirection: 'row-reverse',
  },
  sizingText: {
    position: 'relative',
    top: '1px'
  },
})

type HOCProps = {|
  t: Function, // translation function
  classes: Object, // classes-object created by withstyles function
|}

type MappedProps = {|
  application: string, // current application
  calculation: string, // current calculation id
  spacesListId: string, // currently opened spaces list id
  isEstimateLockedToCurrentUser: $PropertyType<TVDApplicationStore, 'isEstimateLockedToCurrentUser'>, // if the user owns the lock for the estimate
  activeCalculation: boolean, // flag that indicates if calculation is running
  isEstimateFrozen: $PropertyType<TVDApplicationStore, 'isEstimateFrozen'>, // if estimate is frozen
  languageCode: $PropertyType<TVDApplicationStore, 'languageCode'>,
|}

type DispatchProps = {|
  postPolling: () => void // postpolling function
|}

type ReceivedProps = {|
  id: string, // top level id for test id generation, otherwise generator appends undefined string to id
  disabled: boolean, // flag to disable widget content
|}

type Props = {|
  ...HOCProps,
  ...MappedProps,
  ...DispatchProps,
  ...ReceivedProps
|}

type State = {|
  toggleSizing: boolean, // 'mitoitus' checkbox status
  area: Object, // api provided inputs for the area form in FloatingForm, empty to begin with
  driver: Object, // api provided inputs for the driver form in FloatingForm, empty to begin with
  columns: Object, // column states for the four columns in the widget
  selected: Object, // currently selected items in the columns
  classificationDropdown: Object, // current selection, items and disabled status for the classification dropdown
  loadingInProgress: boolean, // optimization to disable the columns while fetching data
|}

export class CreateWidget extends Component<Props, State> {
  static defaultProps = {
    id: 'create',
    isEstimateLockedToCurrentUser: true,
  }

  state = {
    columns: {
      functionalsector: {
        cells: [],
        isLoading: false,
        addressingOptions: [],
        addressingId: '',
        columnName: 'functionalsector'
      },
      function: {
        cells: [],
        isLoading: false,
        addressingOptions: [],
        addressingId: '',
        columnName: 'function'
      },
      spacegroup: {
        cells: [],
        isLoading: false,
        addressingOptions: [],
        addressingId: '',
        columnName: 'spacegroup'
      },
      space: {
        cells: [],
        isLoading: false,
        addressingOptions: [],
        addressingId: '',
        columnName: 'space'
      },
    },
    classificationDropdown: {
      items: [],
      selection: undefined,
      disabled: false
    },
    toggleSizing: true,
    selected: {},
    loadingInProgress: true,
    area: {},
    driver: {},
  }

  _initialColumns: Object

  componentDidMount() {
    this.getAllColumns()
    this.getDropdownContent()
  }

  componentDidUpdate(prevProps: Object) {
    const { languageCode } = this.props
    if (languageCode !== prevProps.languageCode) {
      this.getAllColumns()
      this.getDropdownContent()
    }
  }

  createColumn = (columnName: string) => {
    const { t } = this.props
    const { columns } = this.state

    const saveColumn = (formName: string) => {
      this.handleSave(columnName, formName)
    }
    const isFunctionalsector = columnName === 'functionalsector'
    const translationKey = `widgets._${columnName.toUpperCase()}_`
    const dropdownMenu = isFunctionalsector ?
      (<DropdownMenu
        disabled={this.state.classificationDropdown.disabled}
        items={this.state.classificationDropdown.items}
        onChange={this.handleDropDownChange}
        defaultValue={this.state.classificationDropdown.selection} />)
      : null

    return (
      <RelationalSelector.Column
        focusingTooltip={`${t(translationKey)} ${t('floatingForm._NOT_SELECTED_')}`}
        cells={columns[columnName].cells}
        columnName={columnName}
        title={t(translationKey)}
        searchFieldLabel={t('general._SEARCH_')}
        dropdown={dropdownMenu} >
        <RelationalSelector.FloatingForm
          mountCallback={isFunctionalsector ? this.getRegistryDrivers : this.getDriversAndAddressing}
          radioControlLabel={t('floatingForm._SIZING_APPROACH_')} >
          <RelationalSelector.Form
            formName='area'
            formLabel={t('floatingForm.area')}
            addButtonLabel={t('floatingForm._ADD_')}
            onSave={saveColumn} >
            { this.areaInputs() }
            { !isFunctionalsector && this.addressingDropdown(columnName) }
          </RelationalSelector.Form>
          <RelationalSelector.Form
            formName='driver'
            formLabel={t('floatingForm._DRIVER_')}
            addButtonLabel={t('floatingForm._ADD_')}
            onSave={saveColumn}>
            { this.driverInputs() }
            { !isFunctionalsector && this.addressingDropdown(columnName) }
          </RelationalSelector.Form>
        </RelationalSelector.FloatingForm>
      </RelationalSelector.Column>
    )
  }

  addressingDropdown = (columnName: string) => {
    const { t } = this.props
    const { addressingOptions, addressingId } = this.state.columns[columnName]

    const firstItem = addressingOptions && addressingOptions.length > 0 ? addressingOptions[0].value : undefined

    const dropdownStateHandler = (id: string) => this.updateColumn(columnName, { addressingId: id })

    return (
      <DropdownMenu
        onChange={dropdownStateHandler}
        title={t('floatingForm._ADDRESSING_')}
        defaultValue={addressingId || firstItem}
        items={addressingOptions} />
    )
  }

  handleAreaInput = (e: SyntheticInputEvent<any>, inputName: string) => {
    const { value } = e.currentTarget

    this.setState((state: State) => ({
      area: {
        ...state.area,
        [inputName]: {
          ...state.area[inputName],
          value
        }
      }
    }))
  }

  handleDriverInput = (e: SyntheticInputEvent<any>, inputName: string) => {
    const { value } = e.currentTarget

    this.setState((state: State) => ({
      driver: {
        ...state.driver,
        [inputName]: {
          ...state.driver[inputName],
          value
        }
      }
    }))
  }

  getDriversAndAddressing = (columnName: string, cellID: number) => {
    this.getRegistryDrivers(columnName, cellID)
    this.getAddressingOptions(columnName)
  }

  // FIX Horrible hack that needs to go as soon as possible 3.2.2020
  isCoDriverDisabled = (driverUnit: string) => driverUnit === 'ei käytössä' || driverUnit === 'not in use' || driverUnit === 'Ur bruk'

  handleSave = (columnName: string, formName: string) => {
    const { selected, columns } = this.state

    const driverData = mapValues(this.state[formName], (input: Object) => input.value)
    this.createFromRegistry({
      columnName,
      addressingId: columns[columnName].addressingId,
      cellID: selected[columnName],
      driverData,
      updateCirculation: this.state.toggleSizing
    })
  }

  getAllColumns = () => {
    forEach(keys(this.state.columns), (value: string) => {
      this.getColumnCells(value)
    })
  }

  updateColumn = (columnName: string, columnUpdate: Object) => {
    if (!this._initialColumns) this._initialColumns = {}

    this.setState((prevState: Object) => ({
      ...prevState,
      columns: {
        ...prevState.columns,
        [columnName]: {
          ...prevState.columns[columnName],
          ...columnUpdate
        }
      }
    }), () => {
      if (!this._initialColumns[columnName]) {
        this._initialColumns[columnName] = this.state.columns[columnName]
      }
    })
  }

  getAddressingOptions = (columnName: string) => {
    const { columns } = this.state
    if (columnName === 'functionalsector') return

    const successCb = (response: TVDRequestResponse) => {
      const addressingOptions = parseRegistryItems(response)
      // conditionally adding the new addressingId if needed. otherwise updateColumn will just use the current value
      const newColumn = {
        addressingId: columns[columnName].addressingId || addressingOptions[0].value,
        addressingOptions,
      }
      this.updateColumn(columnName, { ...newColumn })
    }
    switch (columnName.toUpperCase()) {
      case SPACE: getRegistrySpacesCanAttachToRequest({}, successCb); break
      case FUNCTION: getRegistryFunctionsCanAttachToRequest({}, successCb); break
      case SPACEGROUP: getRegistrySpaceGroupsCanAttachToRequest({}, successCb); break
      default: {
        console.error(`Could not get attach to options with column name ${columnName}`)
        break
      }
    }
  }

  /** Checks if even one column, except the specified one, is in loading state. */
  areOtherColumnsLoading = (columnName: string) => {
    const { columns } = this.state

    const columnLoadingStatuses = map(columns, (column: Object) => {
      if (columnName === column.columnName) return false
      return column.isLoading
    })
    return columnLoadingStatuses.some((isLoading: boolean) => isLoading)
  }


  getColumnCells = (columnName: string) => {
    const { selected } = this.state

    const callback = (cells: Array<Object>) => {
      this.setState((state: Object) => ({
        ...state,
        loadingInProgress: this.areOtherColumnsLoading(columnName),
        columns: { ...state.columns, [columnName]: { ...state.columns[columnName], isLoading: false } }
      }), () => {
        this.updateColumn(columnName, { cells })
      })
    }

    const query = {
      classificationId: this.state.classificationDropdown.selection,
      functionalsectorId: selected.functionalsector,
      spacegroupId: selected.spacegroup,
      spaceId: selected.space,
      functionId: selected.function,
    }

    switch (columnName) {
      case 'function':
        getRegistryFunctionsRequest({
          query: {
            ...omit(query, `${columnName}Id`),
            selectedId: selected[columnName]
          }
        }, {}, callback)
        break
      case 'functionalsector':
        getRegistryFunctionalSectorsRequest({
          query: {
            ...omit(query, `${columnName}Id`),
            selectedId: selected[columnName]
          }
        }, {}, callback)
        break
      case 'spacegroup':
        getRegistrySpaceGroupsRequest({
          query: {
            ...omit(query, `${columnName}Id`),
            selectedId: selected[columnName]
          }
        }, {}, callback)
        break
      case 'space':
        getRegistrySpacesRequest({
          query: {
            ...omit(query, `${columnName}Id`),
            selectedId: selected[columnName]
          }
        }, {}, callback)
        break
      default:
        break
    }
  }

  getDropdownContent = () => {
    runGAR({
      ...getEnumGARDefinition(basePath, 'buildingclassification'),
      successCb: (enums: Array<TVDEnum>) => {
        this.setclassificationDropdown({ items: enums })
      }
    })
  }

  setclassificationDropdown = (newStateSlice: Object) => {
    const newState = {
      ...this.state.classificationDropdown,
      ...newStateSlice
    }
    this.setState({ classificationDropdown: newState })
  }

  /**
   * Sets createWidget to initial state
   */
  clearAll = () => {
    this.setState((state: Object) => ({
      ...state,
      classificationDropdown: {
        ...state.classificationDropdown,
        selection: undefined,
        disabled: false,
      },
      toggleSizing: true,
      selected: {},
      addressingOptions: [],
      addressingId: undefined
    }), () => {
      this.getAllColumns()
    })
  }

  createFromRegistry = ({
    columnName,
    addressingId,
    driverData,
    cellID,
    updateCirculation
  }: Object) => {
    if (columnName !== 'functionalsector' && !addressingId) {
      this.updateColumn(columnName, { saveDisabled: true })
    } else {
      const itemArguments = {
        path: { id: addressingId },
        body: { ...driverData },
        query: { registryId: cellID, updateCirculation }
      }

      const aftermath = () => {
        this.getAddressingOptions(columnName)
        this.props.postPolling()
      }

      switch (columnName) {
        case 'functionalsector':
          postSpaceScheduleFunctionalSectorsWithEstimateIdRequest(omit(itemArguments, 'path'), {}, aftermath)
          break
        case 'function':
          postSpaceScheduleFunctionsWithIdRequest(itemArguments, {}, aftermath)
          break
        case 'spacegroup':
          postSpaceScheduleSpaceGroupsWithIdRequest(itemArguments, {}, aftermath)
          break
        case 'space':
          postSpaceScheduleSpacesWithIdRequest(itemArguments, {}, aftermath)
          break
        default: console.error(`No createFromRegistry switch case for column: ${columnName}`)
      }
    }
  }

  handleDropDownChange = (selectionValue: string) => {
    this.setState((state: Object) => ({
      classificationDropdown: {
        ...state.classificationDropdown,
        selection: selectionValue
      }
    }), this.getAllColumns)
  }

  handleSelection = (selected: Object) => {
    const { selected: prevSelected } = this.state
    if (!isEqual(prevSelected, selected)) {
      this.setState((state: Object) => ({ ...state, selected, loadingInProgress: true }), () => {
        this.getAllColumns()
        // somehing was selected and the classificationDropdown isn't disabled so disable it
        if (!this.state.classificationDropdown.disabled) this.setclassificationDropdown({ disabled: true })
        // last item was unchecked (selected returned as an object full of undefined properties), enable the classificationDropdown again
        if (every(values(selected), (item: any) => !item)) this.setclassificationDropdown({ disabled: false })
      })
    }
  }

  parseDrivers = (drivers: Array<Object>) => reduce(drivers, (result: Object, driver: Object) => ({ ...result, [driver.propertyName]: driver }), {})

  getRegistryDrivers = (columnName: string, cellId: number) => {
    const setDrivers = (parsedResponse: Object) => {
      this.setState({
        driver: this.parseDrivers(parsedResponse.drivers),
        area: this.parseDrivers(parsedResponse.area),
      })
    }

    switch (columnName.toLowerCase()) {
      case 'functionalsector':
        getRegistryFunctionalSectorsDriversWithIdRequest({ path: { id: cellId } }, {}, (parsedResponse: Object) => setDrivers(parsedResponse))
        break
      case 'function':
        getRegistryFunctionsDriversWithIdRequest({ path: { id: cellId } }, {}, (parsedResponse: Object) => setDrivers(parsedResponse))
        break
      case 'spacegroup':
        getRegistrySpaceGroupsDriversWithIdRequest({ path: { id: cellId } }, {}, (parsedResponse: Object) => setDrivers(parsedResponse))
        break
      case 'space':
        getRegistrySpacesDriversWithIdRequest({ path: { id: cellId } }, {}, (parsedResponse: Object) => setDrivers(parsedResponse))
        break
      default:
        console.log(`No switch case found for columnName: "${columnName}"`)
    }
  }

  disabled(): boolean {
    const {
      disabled,
      isEstimateLockedToCurrentUser,
      activeCalculation,
      isEstimateFrozen
    } = this.props
    return disabled ||
      !isEstimateLockedToCurrentUser ||
      activeCalculation ||
      !!isEstimateFrozen
  }

  checkboxes(): Array<TVDCheckBoxProps> {
    const { t } = this.props
    const { toggleSizing } = this.state
    return [
      {
        disabled: this.disabled(),
        name: 'toggleSizing',
        checked: toggleSizing,
        label: t('widgets._TOGGLE_SIZING_'),
        onChange: () => this.setState((state: State) => ({ toggleSizing: !state.toggleSizing }))
      }
    ]
  }

  areaInputs(): Array<any> {
    const { t } = this.props
    const { area } = this.state
    return map(area, (input: Object, index: number) => (
      <LabeledInput
        key={index}
        dataType={input.dataType}
        required
        number
        id='FloatingForm-AreaField'
        size='M'
        handleChange={(e: SyntheticInputEvent<any>) => this.handleAreaInput(e, input.propertyName)}
        value={input.value}
        label={t(`floatingForm.${input.propertyName?.toLowerCase()}`)}
        helperText={input.unit} />
    ))
  }

  driverInputs(): Array<any> {
    const { t } = this.props
    const { driver } = this.state
    const driverTranslations = {
      [DRIVER]: t('floatingForm.sizing'),
      [CODRIVER]: t('floatingForm.secondarySizing')
    }
    return map(driver, (input: Object, index: number) => {
      // FIX Horrible hack for a horrible way to indicate an input is disabled
      const disabledCoDriver = Boolean(input.propertyName === 'CoDriver' && this.isCoDriverDisabled(input.unit))

      return (
        <LabeledInput
          key={index}
          dataType={input.dataType}
          disabled={disabledCoDriver}
          required
          number
          id={`${input.propertyName}Field`}
          size='M'
          handleChange={(e: SyntheticInputEvent<any>) => this.handleDriverInput(e, input.propertyName)}
          label={disabledCoDriver ? '' : driverTranslations[input.propertyName]}
          value={disabledCoDriver ? '' : input.value}
          helperText={input.unit} />
      )
    })
  }

  render(): React$Element<any> {
    const { classes, t, id } = this.props

    return (
      <>
        <div className={classes.content} id={id}>
          <RelationalSelector
            clearButtonLabel={t('widgets._CLEAR_SELECTIONS_')}
            checkboxes={this.checkboxes()}
            onChange={this.handleSelection}
            isLoading={this.state.loadingInProgress}
            disabled={this.disabled()}
            onAllClear={this.clearAll} >
            { this.createColumn('functionalsector') }
            { this.createColumn('function') }
            { this.createColumn('spacegroup') }
            { this.createColumn('space') }
          </RelationalSelector>
        </div>
      </>
    )
  }
}

const mapStateToProps = ({ app }: TVDReduxStore): MappedProps => {
  const {
    spacesListId,
    isEstimateLockedToCurrentUser,
    activeCalculation,
    application,
    calculation,
    isEstimateFrozen,
    languageCode
  } = app

  return {
    application,
    spacesListId,
    isEstimateLockedToCurrentUser,
    activeCalculation,
    calculation,
    isEstimateFrozen,
    languageCode
  }
}

function mapDispatchToProps(dispatch: Function): DispatchProps {
  return {
    postPolling: () => { dispatch(actionPostPolling()) },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('translations')(withStyles(styles)(CreateWidget)))
