// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.
import React from 'react'
import { withStyles } from '@material-ui/core'
import Icon from '@material-ui/core/Icon'

export const iconSize = 24
type PositionProp = 'static' | 'relative' | 'absolute'
type Props = {
  id: string,
  isOpen: boolean,
  classes: Object, // withStyles style object
  position: PositionProp, // custom inline position style for the Icon
}

const styles = ({ palette }: Object): Object => ({
  root: {
    fontSize: iconSize,
    color: palette.dark60,
    left: 0,
    display: 'flex'
  }
})

function ListOpenerIcon(props: Props): React$Element<Icon> {
  const {
    id, isOpen, classes, position,
  } = props
  return (
    <Icon
      style={{
        position,
      }}
      className={classes.root}
      data-testid={id}>
      {isOpen ? 'arrow_drop_down' : 'arrow_right'}
    </Icon>
  )
}

ListOpenerIcon.defaultProps = {
  isOpen: false,
  position: 'absolute',
}

export default withStyles(styles)(ListOpenerIcon)
