// @flow
// Copyright © 2010–2023 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.

import {
  FEATURE_AREA_MODULE,
  FEATURE_FUNCTIONS_MODULE,
  FEATURE_PRODUCTION_DIVISION_MODULE,
  FEATURE_REAL_ESTATE_MANAGEMENT_MODULE,
  FEATURE_ADMIN_MODULE,
  // FEATURE_LOCATION_ANALYSIS_MFE,
  FEATURE_BUILDING_ELEMENTS_TASKS_WIDGET_MFE,
  FEATURE_ESTIMATES_ACCESS_RIGHTS,
  // FEATURE_BE_MFE_ESTMAN,
  // FEATURE_SCENARIO_LCA,
  // FEATURE_SITE_EQUIPMENT_TAB,
  // FEATURE_SPACES_EQUIPMENT_TAB,
  // FEATURE_SPACES_SURFACES_TAB,
  FEATURE_SITE_EQUIPMENT_TAB_OLD,
  FEATURE_SPACES_EQUIPMENT_TAB_OLD,
  FEATURE_SPACES_SURFACES_TAB_OLD,
  FEATURE_BUILDING_ELEMENTS_PRODUCT_ASSEMBLY_WIDGET,
  FEATURE_BUILDING_ELEMENTS_PRICING,
  FEATURE_BUILDING_ELEMENTS_CUSTOM_ASSEMBLY

} from './features'

export const featuresAllowedInTesting = []

/**
 * Keys that determine what feature toggle set is used
 * Create new ones for each set
 */

export const FEATURE_SET_PRODUCTION = 'production'
export const FEATURE_SET_DEVELOPMENT = 'development'


/**
 * List of feature names that are to be only accessable if user has account administrator privileges
 *
 */

const accountAdministrator = []

/**
 *  Add new set of feature settings by const mySettingsName: TVDFeatureSettings = {..}
 *  Add your feature settings const to allFeatureToggles object
 */

export const allFeatureSets = {
  [FEATURE_SET_DEVELOPMENT]: {
    disabled: [
      FEATURE_FUNCTIONS_MODULE,
      FEATURE_AREA_MODULE,
      FEATURE_PRODUCTION_DIVISION_MODULE,
      FEATURE_REAL_ESTATE_MANAGEMENT_MODULE,
      FEATURE_ESTIMATES_ACCESS_RIGHTS,
      FEATURE_SITE_EQUIPMENT_TAB_OLD,
      FEATURE_SPACES_EQUIPMENT_TAB_OLD,
      FEATURE_SPACES_SURFACES_TAB_OLD
    ],
    accountAdministrator,
    mockAPIResponses: true,
  },
  [FEATURE_SET_PRODUCTION]: {
    disabled: [
      FEATURE_ADMIN_MODULE,
      FEATURE_FUNCTIONS_MODULE,
      FEATURE_AREA_MODULE,
      FEATURE_PRODUCTION_DIVISION_MODULE,
      FEATURE_REAL_ESTATE_MANAGEMENT_MODULE,
      // FEATURE_LOCATION_ANALYSIS_MFE,
      FEATURE_BUILDING_ELEMENTS_TASKS_WIDGET_MFE,
      FEATURE_ESTIMATES_ACCESS_RIGHTS,
      // FEATURE_BE_MFE_ESTMAN,
      // FEATURE_SCENARIO_LCA,
      // FEATURE_SITE_EQUIPMENT_TAB, // new implementation of the site equipment tab
      // FEATURE_SPACES_EQUIPMENT_TAB, // new implementation of the spaces equipment tab
      // FEATURE_SPACES_SURFACES_TAB, // new implementation of the spaces surfaces tab
      FEATURE_SITE_EQUIPMENT_TAB_OLD,
      FEATURE_SPACES_EQUIPMENT_TAB_OLD,
      FEATURE_SPACES_SURFACES_TAB_OLD,
      FEATURE_BUILDING_ELEMENTS_PRODUCT_ASSEMBLY_WIDGET,
      FEATURE_BUILDING_ELEMENTS_PRICING,
      FEATURE_BUILDING_ELEMENTS_CUSTOM_ASSEMBLY
    ],
    accountAdministrator,
    mockAPIResponses: false,
  },
}
