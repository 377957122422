// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'

import PermissionsListContainer from '../PermissionsListContainer/PermissionsListContainer'

import { USER_RIGHTS, DESCRIPTION } from '../../../../constants/attributes'
import { ACCESS_CONTROL_BUILDINGS } from '../../../../constants/contentTypes'
import { MODAL_TYPE_SETTINGS } from '../../../../constants/modalConstants'

const styles = (): Object => ({
  buildingsTabContent: {
    display: 'flex',
    minHeight: '100%',
    maxHeight: '100%'
  },
})

type HOCProps = {|
  t: Function, // i18n translate function
  classes: Object, // withStyles classes object
|}

type ReceivedProps = {|

|}

type MappedProps = {|

|}

type DispatchProps = {|
|}

type Props = {|
  ...ReceivedProps,
  ...MappedProps,
  ...DispatchProps,
  ...HOCProps,
|}

export class SettingsBuildings extends Component<Props> {
  getColumns = (): Array<TVDListItemColumn> => {
    const { t } = this.props
    return [
      { propertyName: DESCRIPTION, localizedName: t('settings._DESCRIPTION_'), dataType: 'string' },
      { propertyName: USER_RIGHTS, localizedName: t('settings._USER_RIGHTS_'), dataType: 'string' },
    ]
  }

  render(): React$Element<'div'> {
    const { classes } = this.props
    return (
      <div className={classes.buildingsTabContent}>
        <PermissionsListContainer
          modalId={MODAL_TYPE_SETTINGS}
          listId={ACCESS_CONTROL_BUILDINGS}
          columns={this.getColumns()} />
      </div>
    )
  }
}


export default compose(
  withTranslation('translations'),
  connect(null, {}),
  withStyles(styles),
)(SettingsBuildings)
