// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.

import requestFn from '../requests/request'
import { API_BASE_URL } from '../../constants/envConstants'
import HALParser from '../HALParser'
import { basePath } from '../generated-api-requests/estimates'


const DATA_GET_MUNICIPALITIES = 'DATA_GET_MUNICIPALITIES'
export const DATA_GET_MUNICIPALITIES_SUCCESSFUL = 'DATA_GET_MUNICIPALITIES_SUCCESSFUL'
const DATA_GET_MUNICIPALITIES_FAIL = 'DATA_GET_MUNICIPALITIES_FAIL'
export const getMunicipalitiesRequest = (successCb?: Function, options: Object) => requestFn({
  config: {
    baseURL: `${API_BASE_URL}${options?.basePath || basePath}`,
    url: '/enum/Municipalities',
    method: 'GET',
  },
  parser: (response: TVDRequestResponse) => HALParser.getEnumeral(response),
  actions: {
    start: { type: DATA_GET_MUNICIPALITIES },
    successful: { type: DATA_GET_MUNICIPALITIES_SUCCESSFUL },
    fail: { type: DATA_GET_MUNICIPALITIES_FAIL }
  },
  callbacks: {
    successCb,
  }
})

const DATA_GET_COUNTRIES = 'DATA_GET_COUNTRIES'
export const DATA_GET_COUNTRIES_SUCCESSFUL = 'DATA_GET_COUNTRIES_SUCCESSFUL'
export const DATA_GET_COUNTRIES_FAIL = 'DATA_GET_COUNTRIES_FAIL'
export const getCountriesRequest = (successCb?: Function) => requestFn({
  config: {
    baseURL: `${API_BASE_URL}${basePath}`,
    url: '/enum/Countries',
    method: 'GET',
  },
  parser: (response: TVDRequestResponse) => HALParser.getEnumeral(response),
  actions: {
    start: { type: DATA_GET_COUNTRIES },
    successful: { type: DATA_GET_COUNTRIES_SUCCESSFUL },
    fail: { type: DATA_GET_COUNTRIES_FAIL }
  },
  callbacks: {
    successCb,
  }
})
