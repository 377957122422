// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.
import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withStyles } from '@material-ui/core/styles'

import ResultBarContainer from '../../containers/ResultBar/ResultBarContainer'
import ModuleHeader from '../../common/ModuleHeader/ModuleHeader'
import ElementsListContainer from '../../containers/list/ElementsList/ElementsListContainer'

import { ELEMENTS } from '../../../constants/moduleConstants'
import { ResultBarHeight } from '../../containers/ResultBar/ResultBar/ResultBar'
import FrozenNotification from '../../common/FrozenNotification/FrozenNotification'
import { headerHeight } from '../../../components/common/Header/Header'
import {
  buildingElementsTasksWidgetMFERootId
} from '../../containers/BuildingElementsTasksWidgetMFEContainer/BuildingElementsTasksWidgetMFEContainer'
import { buildingElementsEstManMFERootId } from '../../containers/BuildingElementsEstManMFEContainer/BuildingElementsEstManMFEContainer'
import {
  buildingElementsProductAssemblyMFERootId
} from '../../containers/BuildingElementsProductAssemblyMFEContainer/BuildingElementsProductAssemblyMFEContainer'
import { buildingElementsAssemblyPriceListMFERootId } from
  '../../containers/BuildingElementsAssemblyPriceListMFEContainer/BuildingElementsAssemblyPriceListMFEContainer'
import { customBuildingElementAssemblyModalMFERootId } from
  '../../containers/CustomBuildingElementAssemblyModalMFEContainer/CustomBuildingElementAssemblyModalMFEContainer'

type MappedProps = {|
  buildingElementsListId: $PropertyType<TVDApplicationStore, 'buildingElementsListId'>,
  isEstimateFrozen: $PropertyType<TVDApplicationStore, 'isEstimateFrozen'>,
  isEstimateLockedToCurrentUser: $PropertyType<TVDApplicationStore, 'isEstimateLockedToCurrentUser'>
|}

type HOCProps = {|
  classes: Object, // classes-object generated by withStyles function
|}

type Props = {|
  ...MappedProps,
  ...HOCProps,
  buildingElementsListId: string, // id for building elements list
|}

const styles = ({ palette }: TVDTheme) => ({
  layout: {
    display: 'flex',
    flexDirection: 'row',
    margin: '24px 32px 40px'
  },
  root: {
    paddingTop: headerHeight,
    height: '100%',
    width: '100%',
    background: palette.gray20,
  },
  innerContainer: {
    background: palette.white,
    display: 'flex',
    flexDirection: 'column',
    height: `calc(100% - ${ResultBarHeight}px)`
  }
})

export class BuildingElements extends React.Component<Props> {
  render(): React$Element<any> {
    const {
      buildingElementsListId,
      isEstimateFrozen,
      isEstimateLockedToCurrentUser,
      classes
    } = this.props

    return (
      <div className={classes.root}>
        <div className={classes.innerContainer}>
          <div id={buildingElementsTasksWidgetMFERootId} />
          <div id={buildingElementsEstManMFERootId} />
          <div id={buildingElementsProductAssemblyMFERootId} />
          <div id={buildingElementsAssemblyPriceListMFERootId} />
          <div id={customBuildingElementAssemblyModalMFERootId} />
          <ModuleHeader />
          <FrozenNotification disabled={!isEstimateFrozen || !isEstimateLockedToCurrentUser} />
          <ElementsListContainer listId={buildingElementsListId} />
        </div>
        <ResultBarContainer resultBarKey={ELEMENTS} />
      </div>
    )
  }
}

const mapStateToProps = ({ app }: TVDReduxStore): MappedProps => {
  const {
    buildingElementsListId,
    isEstimateFrozen,
    isEstimateLockedToCurrentUser
  } = app
  return {
    buildingElementsListId,
    isEstimateFrozen,
    isEstimateLockedToCurrentUser
  }
}

export default compose(
  withStyles(styles),
  connect(mapStateToProps),
)(BuildingElements)

