// @flow
// Copyright © 2010–2023 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.

import { includes, map, each, isEmpty, reduce, filter, find, uniq } from 'lodash'
import axios from 'axios'
import { batch } from 'react-redux'
import { postPolling } from '../postPolling'

import { getSelectedListItemsByType, getRowActionsByType } from '../../utils/listUtils'
import { getArgsWithOptions } from '../../utils/requests/list'
import { createPatchOperation } from '../../utils/patchOperationUtil'
import { storePatchOperation } from '../patchOperations'
import {
  patchOperationRenovationProfileBasePath,
  TVD_PATCH_OPERATION_TYPE_UPDATE,
} from '../../constants/patchOperationConstants'
import { WOP, SPACES } from '../../constants/moduleConstants'
import {
  FUNCTIONALSECTOR,
  FUNCTION,
  SPACEGROUP,
  SPACE,
  PRICEITEM,
  HEADING,
  ITEM,
  ASSEMBLY,
  PROCESS,
  ACTIVITYGROUP,
  ACTIVITY,
  FUNCTIONGROUP,
  PROCESSGROUP
} from '../../constants/contentTypes'

import {
  postSpaceScheduleFunctionalSectorsWithEstimateIdRequest,
  postSpaceScheduleSpaceGroupsWithIdRequest,
  postSpaceScheduleFunctionsWithIdRequest,
  postSpaceScheduleSpacesWithIdRequest,
  deleteSpaceScheduleSpaceGroupsWithIdRequest,
  deleteSpaceScheduleSpacesWithIdRequest,
  patchSpaceScheduleFunctionsWithIdRequest,
  patchSpaceScheduleFunctionalSectorsWithIdRequest,
  patchSpaceScheduleSpaceGroupsWithIdRequest,
  patchSpaceScheduleSpacesWithIdRequest,
  deleteSpaceScheduleFunctionsWithIdRequest,
  deleteSpaceScheduleFunctionalSectorsWithIdRequest,
  deleteSpaceScheduleSpaceGroupsWithEstimateIdRequest,
  deleteSpaceScheduleSpacesWithEstimateIdRequest,
  deleteSpaceScheduleFunctionalSectorsWithEstimateIdRequest,
  deleteSpaceScheduleFunctionsWithEstimateIdRequest,
} from '../../utils/generated-api-requests/spaces'
import {
  patchSpaceSchedulebatchMoveWithSpaceScheduleItemTypeRequest
} from '../../utils/ungenerated-api-requests/spaces'

import {
  postPriceitemWithIdRequest,
  postHeadingWithIdRequest,
  deletePriceitemWithIdRequest,
  deleteHeadingWithIdRequest,
  deleteAssemblyWithIdRequest,
  patchPriceitemWithIdRequest,
  patchHeadingWithIdRequest,
  patchAssemblyWithIdRequest,
  postAssemblyWithIdRequest
} from '../../utils/generated-api-requests/buildingelements'
import {
  patchActivityScheduleFunctionalSectorsWithItemIdRequest,
  patchActivityScheduleFunctionsWithItemIdRequest,
  patchActivityScheduleProcessesWithItemIdRequest,
  patchActivityScheduleActivityGroupsWithItemIdRequest,
  postActivityScheduleFunctionalSectorsWithEstimateIdRequest,
  postActivityScheduleFunctionsWithItemIdRequest,
  postActivityScheduleProcessesWithItemIdRequest,
  postActivityScheduleActivityGroupsWithItemIdRequest,
  postActivityScheduleActivitiesWithItemIdRequest,
  deleteActivityScheduleFunctionalSectorsWithItemIdRequest,
  deleteActivityScheduleFunctionsWithItemIdRequest,
  deleteActivityScheduleProcessesWithItemIdRequest,
  deleteActivityScheduleActivityGroupsWithItemIdRequest,
  deleteActivityScheduleActivitiesWithItemIdRequest,
  postGroupingScheduleFunctionalSectorGroupsWithEstimateIdRequest,
  postGroupingScheduleFunctionGroupsWithItemIdRequest,
  postGroupingScheduleProcessGroupsWithItemIdRequest
} from '../../utils/generated-api-requests/wop'
import { updateSentient } from '../sentients'
import { setCalculationActive } from '../app'
import { refreshUserAccessToken } from '../user'

export function copyItem(
  row: TVDListItem,
  listId: string,
  application?: string,
  updateSentientIds?: Array<string>, // list of sentient ids that will be updated after GARs are run
  disablePostPolling?: boolean, // to disable post polling from running after GARs are done
): Function {
  return (dispatch: Function, getState: Function) => {
    const { [listId]: { listItems } } = getState().list
    const checkedListItems = filter(listItems, (item: TVDListItem) => item.selected)
    const itemsToCopy = checkedListItems.length ? checkedListItems : [row]

    axios.all(itemsToCopy.map((listItem: Object) => {
      const {
        id, wopItemId, parentWopItemId, type, parentId
      } = listItem
      switch (type.toLowerCase()) {
        case FUNCTIONALSECTOR.toLowerCase(): {
          if (application === WOP) {
            return postActivityScheduleFunctionalSectorsWithEstimateIdRequest(
              { query: { copyId: wopItemId }, body: {} },
              {}, null, null, { disableRefreshEstimateLock: true }
            )
          }
          return postSpaceScheduleFunctionalSectorsWithEstimateIdRequest(
            { query: { copyId: id }, body: {} },
            {}, null, null, { disableRefreshEstimateLock: true }
          )
        }
        case FUNCTION.toLowerCase(): {
          if (application === WOP) {
            return postActivityScheduleFunctionsWithItemIdRequest(
              { path: { itemId: parentWopItemId }, query: { copyId: wopItemId }, body: {} },
              {}, null, null, { disableRefreshEstimateLock: true }
            )
          }
          return postSpaceScheduleFunctionsWithIdRequest(
            { path: { id }, query: { copyId: id }, body: {} },
            {}, null, null, { disableRefreshEstimateLock: true }
          )
        }
        case SPACEGROUP.toLowerCase(): {
          return postSpaceScheduleSpaceGroupsWithIdRequest(
            { path: { id }, query: { copyId: id }, body: {} },
            {}, null, null, { disableRefreshEstimateLock: true }
          )
        }
        case SPACE.toLowerCase(): {
          return postSpaceScheduleSpacesWithIdRequest(
            { path: { id }, query: { copyId: id }, body: {} },
            {}, null, null, { disableRefreshEstimateLock: true }
          )
        }
        case PRICEITEM.toLowerCase(): {
          return postPriceitemWithIdRequest(
            { path: { id }, query: { copyId: id }, body: {} },
            {}, null, null, { disableRefreshEstimateLock: true }
          )
        }
        case HEADING.toLowerCase(): {
          return postHeadingWithIdRequest(
            { path: { id: parentId }, query: { copyId: id }, body: {} },
            {}, null, null, { disableRefreshEstimateLock: true }
          )
        }
        case ASSEMBLY.toLowerCase(): {
          return postAssemblyWithIdRequest(
            { path: { id }, query: { copyId: id }, body: {} },
            {}, null, null, { disableRefreshEstimateLock: true }
          )
        }
        case PROCESS.toLowerCase(): {
          return postActivityScheduleProcessesWithItemIdRequest(
            { path: { itemId: parentWopItemId }, query: { copyId: wopItemId }, body: {} },
            {}, null, null, { disableRefreshEstimateLock: true }
          )
        }
        case ACTIVITYGROUP.toLowerCase(): {
          return postActivityScheduleActivityGroupsWithItemIdRequest(
            { path: { itemId: parentWopItemId }, query: { copyId: wopItemId }, body: {} },
            {}, null, null, { disableRefreshEstimateLock: true }
          )
        }
        case ACTIVITY.toLowerCase(): {
          return postActivityScheduleActivitiesWithItemIdRequest(
            { path: { itemId: parentWopItemId }, query: { copyId: wopItemId }, body: {} },
            {}, null, null, { disableRefreshEstimateLock: true }
          )
        }
        default: {
          console.error(`No copy case for listItem of type: ${type}`)
          return null
        }
      }
    })).then(() => {
      const callbackUpdateSentients = (): void => {
        if (updateSentientIds) {
          updateSentientIds.forEach((sentientId: string) => { dispatch(updateSentient(sentientId)) })
        }
      }
      batch(() => {
        if (!disablePostPolling) {
          dispatch(postPolling())
          callbackUpdateSentients()
        } else {
          refreshUserAccessToken(() => {
            callbackUpdateSentients()
          })
        }
      })
    })
  }
}

export function renameItem(id: string, listId: string, input: string, closeModal: Function): Object {
  return (dispatch: Function, getState: Function) => {
    const { list, app: { application } } = getState()
    const { listItems } = list[listId]
    const item = listItems[id]
    const { type } = item

    const args = { path: { id }, body: { Description: input } }
    const wopArgs = { path: { itemId: item.wopItemId }, body: { Description: input } }
    const payload = { listId, modifiedListItem: { id, columnData: { Description: input } } }

    switch (type.toLowerCase()) {
      case FUNCTIONALSECTOR.toLowerCase(): {
        if (application === WOP) patchActivityScheduleFunctionalSectorsWithItemIdRequest(wopArgs, payload, () => closeModal())
        else patchSpaceScheduleFunctionalSectorsWithIdRequest(args, payload, () => closeModal())
        break
      }
      case FUNCTION.toLowerCase():
        if (application === WOP) patchActivityScheduleFunctionsWithItemIdRequest(wopArgs, payload, () => closeModal())
        else patchSpaceScheduleFunctionsWithIdRequest(args, payload, () => closeModal())
        break
      case SPACEGROUP.toLowerCase(): {
        patchSpaceScheduleSpaceGroupsWithIdRequest(args, payload, () => closeModal())
        break
      }
      case SPACE.toLowerCase(): {
        patchSpaceScheduleSpacesWithIdRequest(args, payload, () => closeModal())
        break
      }
      case PRICEITEM.toLowerCase(): {
        patchPriceitemWithIdRequest(args, payload, () => closeModal())
        break
      }
      case HEADING.toLowerCase(): {
        patchHeadingWithIdRequest({ ...args, query: {} }, payload, () => closeModal())
        break
      }
      case ASSEMBLY.toLowerCase(): {
        patchAssemblyWithIdRequest(args, payload, () => closeModal())
        break
      }
      case PROCESS.toLowerCase(): {
        patchActivityScheduleProcessesWithItemIdRequest(wopArgs, payload, () => closeModal())
        break
      }
      case ACTIVITYGROUP.toLowerCase(): {
        patchActivityScheduleActivityGroupsWithItemIdRequest(wopArgs, payload, () => closeModal())
        break
      }
      default:
        console.error(`No rename case for listItem of type: ${type}`)
        break
    }
  }
}

type DeleteRowsType = {|
  rowsToBeRemoved: Array<TVDListItem>, // array of row objects that are to be removed
  callback?: Function, // function that is called after all requests are done
  applicationType?: string // application where row is deleted
|}


const deleteRows = async ({
  rowsToBeRemoved,
  callback,
  applicationType
}: DeleteRowsType) => {
  // delete GAR for each row type that can be deleted
  const multiDeleteRequests = {
    [PRICEITEM.toLowerCase()]: deletePriceitemWithIdRequest,
    [ASSEMBLY.toLowerCase()]: deleteAssemblyWithIdRequest,
    [FUNCTION.toLowerCase()]: deleteSpaceScheduleFunctionsWithIdRequest,
    [FUNCTIONALSECTOR.toLowerCase()]: deleteSpaceScheduleFunctionalSectorsWithIdRequest,
    [SPACEGROUP.toLowerCase()]: deleteSpaceScheduleSpaceGroupsWithIdRequest,
    [SPACE.toLowerCase()]: deleteSpaceScheduleSpacesWithIdRequest,
    [HEADING.toLowerCase()]: deleteHeadingWithIdRequest,
  }
  const batchDeleteRequests = {
    [FUNCTION.toLowerCase()]: deleteSpaceScheduleFunctionsWithEstimateIdRequest,
    [FUNCTIONALSECTOR.toLowerCase()]: deleteSpaceScheduleFunctionalSectorsWithEstimateIdRequest,
    [SPACEGROUP.toLowerCase()]: deleteSpaceScheduleSpaceGroupsWithEstimateIdRequest,
    [SPACE.toLowerCase()]: deleteSpaceScheduleSpacesWithEstimateIdRequest,
  }
  const wopDeleteRequests = {
    [FUNCTIONALSECTOR.toLowerCase()]: deleteActivityScheduleFunctionalSectorsWithItemIdRequest,
    [FUNCTION.toLowerCase()]: deleteActivityScheduleFunctionsWithItemIdRequest,
    [PROCESS.toLowerCase()]: deleteActivityScheduleProcessesWithItemIdRequest,
    [ACTIVITYGROUP.toLowerCase()]: deleteActivityScheduleActivityGroupsWithItemIdRequest,
    [ACTIVITY.toLowerCase()]: deleteActivityScheduleActivitiesWithItemIdRequest,
  }

  if (applicationType === SPACES) {
    const requestIds = []
    map(rowsToBeRemoved, (row: Object) => (
      requestIds.push(row.id)
    ))
    const deleteRequest = {
      getFn: () => (batchDeleteRequests[rowsToBeRemoved[0].type.toLowerCase()]), // get GAR according to row type
      getArgs: () => ([ // define request arguments for GAR, arguments are read inside getArgsWithOptions function
        { query: { ids: requestIds } }, {}, null, null
      ])
    }
    if (multiDeleteRequests[rowsToBeRemoved[0].type.toLowerCase()]) {
      // eslint-disable-next-line no-await-in-loop
      await deleteRequest.getFn()(...getArgsWithOptions(deleteRequest))
    }
  }
  if (applicationType !== SPACES) {
    for (const row of rowsToBeRemoved) {
      const isWop = applicationType === WOP
      const deleteRequest = {
        getFn: () => (isWop ? wopDeleteRequests[row.type.toLowerCase()] : multiDeleteRequests[row.type.toLowerCase()]), // get GAR according to row type
        getArgs: (item: Object) => (isWop ? [ // define request arguments for GAR, arguments are read inside getArgsWithOptions function
          { path: { itemId: item.id }, }, {}, false, false, {}
        ] : [
          { path: { id: item.id }, }, {}, false, false, {}
        ])
      }
      if (multiDeleteRequests[row.type.toLowerCase()] || wopDeleteRequests[row.type.toLowerCase()]) {
      // eslint-disable-next-line no-await-in-loop
        await deleteRequest.getFn()(...getArgsWithOptions(deleteRequest, row))
      }
    }
  }

  if (callback) callback()
}

export function findOutmostParents(rowsToDelete: Array<TVDListItem>): Array<TVDListItem> {
  const listOfParentIds = uniq(map(rowsToDelete, (row: TVDListItem) => row.parentId)) // array of all parentIds - without duplicates

  const outmostParents = reduce(listOfParentIds, (result: Array<TVDListItem>, parentId: string) => {
    // if parentId does not match any row to delete id - it is outmost item in hierarchy
    const isOutMostItem = !find(rowsToDelete, (row: TVDListItem) => row.id === parentId)
    if (isOutMostItem) {
      const outmostRows = filter(rowsToDelete, (row: TVDListItem) => row.parentId === parentId)
      // add each row into result array if it's not already found
      each(outmostRows, (outmostRow: TVDListItem) => {
        const notAlreadyAdded = !find(result, (resultRow: TVDListItem) => resultRow.id === outmostRow.id)
        if (notAlreadyAdded) {
          result = [...result, outmostRow]
        }
      })
    }
    return result
  }, [])

  return outmostParents
}

export const DELETE_LIST_ROW = 'DELETE_LIST_ROW'
export function deleteItem(
  rowId: string | number,
  rowType: string,
  listId: string,
  application?: string,
  updateSentientIds?: Array<string>, // list of sentient ids that will be updated after GARs are run
  disablePostPolling?: boolean, // to disable post polling from running after GARs are done

): Function {
  return (dispatch: Function, getState: Function) => {
    const { list } = getState()
    const { listItems } = list[listId]
    const isWop = application === WOP

    let rowsToBeRemoved = (filter(listItems, (obj: Object) => obj.selected))

    if (rowsToBeRemoved.length === 0) {
      const listItem = listItems[rowId]
      rowsToBeRemoved = isWop ? [{ id: listItem.wopItemId, type: rowType }] : [{ id: rowId, type: rowType }]
    }

    const deletableRows = filter(rowsToBeRemoved, (row: Object) => includes(getRowActionsByType(row.type.toLowerCase(), listItems), '_DELETE_'))

    // Filter out child rows to remove excess calls to API
    const outmostParents = findOutmostParents(deletableRows)

    // dispatch DELETE_LIST_ROW action with lineId as id, so listMiddleware can check if row has open widgets and close them if found
    dispatch({ type: DELETE_LIST_ROW, payload: { id: rowId, listId } })

    deleteRows({
      rowsToBeRemoved: outmostParents,
      callback: () => {
        const callbackUpdateSentients = (): void => {
          if (updateSentientIds) {
            updateSentientIds.forEach((sentientId: string) => { dispatch(updateSentient(sentientId)) })
          }
        }
        if (!disablePostPolling) {
          dispatch(postPolling())
          callbackUpdateSentients()
        } else {
          refreshUserAccessToken(() => {
            callbackUpdateSentients()
          })
        }
      },
      applicationType: application
    })
  }
}

export function moveItem(type: string, parentId: string, id: string, calculation: string, listId: string): Object {
  return (dispatch: Function, getState: Function) => {
    const { list: { [listId]: list } } = getState()
    let selectedItems = getSelectedListItemsByType(list.listItems, type)

    if (selectedItems.length === 0) {
      selectedItems = [{ id, type }]
    }

    const requestCb = () => {
      dispatch(postPolling())
    }

    let spaceScheduleItemType = null

    switch (type.toLowerCase()) {
      case SPACEGROUP.toLowerCase(): {
        spaceScheduleItemType = 'spaceGroup'
        break
      }
      case SPACE.toLowerCase(): {
        spaceScheduleItemType = 'space'
        break
      }
      default: {
        break
      }
    }

    if (spaceScheduleItemType) {
      patchSpaceSchedulebatchMoveWithSpaceScheduleItemTypeRequest(
        {
          path: {
            spaceScheduleItemType
          },
          body: {
            parentId,
            itemIds: selectedItems.map((selectedItem: Object): string => selectedItem.id)
          }
        },
        {},
        requestCb
      )
    } else {
      dispatch(setCalculationActive())
      axios.all(map(selectedItems, (item: Object) => {
        switch (item.type.toLowerCase()) {
          case FUNCTION.toLowerCase(): {
            return patchSpaceScheduleFunctionsWithIdRequest(
              { path: { id: item.id }, body: { parentId } },
              { listId, modifiedListItem: { id: item.id } },
              false, false, { disableRefreshEstimateLock: true, disableSetCalculationActiveAndComplete: true }
            )
          }
          case PRICEITEM.toLowerCase(): {
            return patchPriceitemWithIdRequest(
              { path: { id: item.id }, body: { parentId } },
              { listId, modifiedListItem: { id: item.id } },
              false, false, { disableRefreshEstimateLock: true, disableSetCalculationActiveAndComplete: true }
            )
          }
          case ASSEMBLY.toLowerCase(): {
            return patchAssemblyWithIdRequest(
              { path: { id: item.id }, body: { parentId } },
              { listId, modifiedListItem: { id: item.id } },
              false, false, { disableRefreshEstimateLock: true, disableSetCalculationActiveAndComplete: true }
            )
          }
          case HEADING.toLowerCase(): {
            return patchHeadingWithIdRequest(
              { path: { id: item.id }, body: { parentId } },
              { listId, modifiedListItem: { id: item.id } },
              false, false, { disableRefreshEstimateLock: true, disableSetCalculationActiveAndComplete: true }
            )
          }
          default:
            return null
        }
      })).then(() => {
        dispatch(postPolling())
      })
    }
  }
}

export const RENAME_VALUE = 'RENAME_VALUE'
export function renameValue(value: string, id: string): Object {
  return {
    type: RENAME_VALUE,
    payload: { value, id }
  }
}

export function createItem(type: string, parentId: string, value: string, closeModal: Function): Function {
  return (dispatch: Function) => {
    const newItemData = { Description: value }
    const defaultArguments = {
      body: newItemData,
      path: { id: parentId }
    }

    switch (type.toLowerCase()) {
      case FUNCTIONALSECTOR.toLowerCase(): {
        postSpaceScheduleFunctionalSectorsWithEstimateIdRequest({ body: newItemData }, {}, () => {
          dispatch(postPolling())
          closeModal()
        })
        break
      }
      case FUNCTION.toLowerCase():
        postSpaceScheduleFunctionsWithIdRequest({ ...defaultArguments }, {}, () => {
          dispatch(postPolling())
          closeModal()
        })
        break
      case SPACEGROUP.toLowerCase(): {
        postSpaceScheduleSpaceGroupsWithIdRequest({ ...defaultArguments }, {}, () => {
          dispatch(postPolling())
          closeModal()
        })
        break
      }
      case SPACE.toLowerCase(): {
        postSpaceScheduleSpacesWithIdRequest({ ...defaultArguments }, {}, () => {
          dispatch(postPolling())
          closeModal()
        })
        break
      }
      case ITEM.toLowerCase():
      case PRICEITEM.toLowerCase(): {
        const newPriceItemData = {
          ...newItemData,
          Quantity: 1,
          UnitPrice: 0,
          TotalPriceInCurrency: 0
        }
        postPriceitemWithIdRequest({ path: { id: parentId }, body: newPriceItemData }, {}, () => {
          dispatch(postPolling())
          closeModal()
        })
        break
      }
      case HEADING.toLowerCase(): {
        const newHeadingData = {
          ...newItemData,
          TotalPriceInCurrency: 0
        }
        postHeadingWithIdRequest({ path: { id: parentId }, body: newHeadingData }, {}, () => {
          dispatch(postPolling())
          closeModal()
        })
        break
      }
      default:
        break
    }
  }
}

export function createWopRootItem(type: string, value: string, closeModal: Function, onSave?: Function): Function {
  return (dispatch: Function) => {
    postGroupingScheduleFunctionalSectorGroupsWithEstimateIdRequest({ path: {}, body: { Description: value } }, {}, () => {
      dispatch(postPolling())
      if (typeof onSave === 'function') onSave()
      closeModal()
    })
  }
}

export function createWopItem(type: string, parentId: number, value: string, closeModal: Function, onSave?: Function): Function {
  return (dispatch: Function) => {
    const newItemData = { Description: value }

    const successCb = () => {
      dispatch(postPolling())
      if (typeof onSave === 'function') onSave()
      closeModal()
    }

    switch (type.toLowerCase()) {
      case FUNCTIONGROUP.toLowerCase(): {
        postGroupingScheduleFunctionGroupsWithItemIdRequest({ path: { itemId: parentId }, body: newItemData }, {}, successCb)
        break
      }
      case PROCESSGROUP.toLowerCase(): {
        postGroupingScheduleProcessGroupsWithItemIdRequest({ path: { itemId: parentId }, body: newItemData }, {}, successCb)
        break
      }
      default:
        break
    }
  }
}

// searches items with no parentId from given data collection
export function getRootItems(data: Object): Array<string> {
  const rootItemIds = []
  each(data, (item: Object) => {
    if (!item.parentId) {
      rootItemIds.push(item.id)
    }
  })
  return rootItemIds
}

// generator function that traverses through all items and their children
export function* traverse(item: Object, data: Object): Object {
  yield item
  if (item && !isEmpty(item.children)) {
    const childs = item.children.slice()
    for (const childId of childs) {
      const dataChild = data[childId]
      yield* traverse(dataChild, data)
    }
  }
}

// generator function that traverses through opened items and their children
export function* traverseOpenRows(item: Object, data: Object): Object {
  yield item
  if (item && !isEmpty(item.children) && item.isOpen) {
    const childs = item.children.slice()
    for (const childId of childs) {
      const dataChild = data[childId]
      yield* traverseOpenRows(dataChild, data)
    }
  }
}


// Searches for rootItems from data, then traverses through their hierarchy
// and pushes opened rows to list array
export function buildList(data: Object = {}): Array<Object> {
  const list = []
  const rootItems = getRootItems(data)
  each(rootItems, (rootItem: string) => {
    if (data[rootItem] && !data[rootItem].isOpen) {
      list.push(data[rootItem])
    }

    if (data[rootItem]) {
      each(data[rootItem].children, (childId: Object) => {
        const { parentId } = data[childId]
        const parentItem = data[parentId] || {}
        for (const item of traverseOpenRows(data[parentId], data)) {
          if (parentItem.isOpen && item) {
            list.push(item)
          }
        }
      })
    }
  })
  return list
}

// Build parent-child hierarchy from parentIds
export function buildHierarchy(data: Array<Object>): Object {
  const hierarchy = reduce(data, (result: Object, item: Object) => {
    const { id } = item
    result[id] = {
      ...item,
      level: !item.parentId ? 0 : result[item.parentId].level + 1,
      children: item.children || []
    }

    for (const itemId in result) {
      if (result[itemId]) {
        const dataItem = result[itemId]
        // If the item is not at the root level, add it to its parent array of children.
        if (dataItem.parentId && !includes(result[dataItem.parentId].children, dataItem.id)) {
          result[dataItem.parentId].canHaveChildren = true
          result[dataItem.parentId].children.push(dataItem.id)
        }
      }
    }

    return result
  }, {})

  return hierarchy
}

export const SET_LIST_ITEM_LOADED = 'SET_LIST_ITEM_LOADED'
export function setListItemLoaded(listItemId: string, listId: string): Object {
  return {
    type: SET_LIST_ITEM_LOADED,
    payload: { listItemId, listId }
  }
}

export const TOGGLE_LIST_ITEM_OPEN = 'TOGGLE_LIST_ITEM_OPEN'
export function toggleListItemOpen(listItemId: string, listId: string, componentPreferencesId?: string): Object {
  return {
    type: TOGGLE_LIST_ITEM_OPEN,
    payload: { listItemId, listId, componentPreferencesId }
  }
}

export const TOGGLE_ALL_LIST_ITEMS_OPEN = 'TOGGLE_ALL_LIST_ITEMS_OPEN'
export function toggleAllListItemsOpen(listId: string): Object {
  return {
    type: TOGGLE_ALL_LIST_ITEMS_OPEN,
    payload: { listId }
  }
}

export const TOGGLE_LIST_ITEM_SELECTED = 'TOGGLE_LIST_ITEM_SELECTED'
export function toggleListItemSelected(listItemId: string, listId: string, shouldUnselectParents?: boolean): Object {
  return {
    type: TOGGLE_LIST_ITEM_SELECTED,
    payload: { listItemId, listId, shouldUnselectParents }
  }
}


export type TVDModifyListItemOptions = {
  showModifiedIndicator?: boolean, // option to toggle the showing of modified indicator for column in question
}

export const MODIFY_LIST_ITEM = 'MODIFY_LIST_ITEM'
export type TVDModifyListItemPayload = {|
  listItemId: string | number,
  listId: string,
  columnName: string,
  value: string | number | boolean,
  options?: TVDModifyListItemOptions
|}

export function modifyListItem(modifyListItemPayload: TVDModifyListItemPayload): Object {
  return {
    type: MODIFY_LIST_ITEM,
    payload: modifyListItemPayload,
  }
}

export const UNDO_MODIFY_LIST_ITEM = 'UNDO_MODIFY_LIST_ITEM'
type UndoModifyListItemPayload = {|
  listId: string,
  listItem: TVDListItem | TVDWOPListItem,
|}

export function undoModifyListItem(undoModifyListItemPayload: UndoModifyListItemPayload): Object {
  return {
    type: UNDO_MODIFY_LIST_ITEM,
    payload: undoModifyListItemPayload,
  }
}

export const CLEAR_LIST = 'CLEAR_LIST'
export function clearList(listId: string): Object {
  return {
    type: CLEAR_LIST,
    payload: { listId },
  }
}

export const CLEAR_SINGLE_ROW = 'CLEAR_SINGLE_ROW'
export function clearSingleRow(listId: string, listItemId: string, propertyNames: Array<string>): Object {
  return {
    type: CLEAR_SINGLE_ROW,
    payload: { listId, listItemId, propertyNames }
  }
}

export const DELETE_LIST_ITEM = 'DELETE_LIST_ITEM'
export function deleteListItem(listId: string, listItemId: string): Object {
  return {
    type: DELETE_LIST_ITEM,
    payload: { listId, listItemId },
  }
}

export const DELETE_LIST_ITEM_ROW = 'DELETE_LIST_ITEM_ROW'
export function deleteListItemRow(listId: string, listItemId: string): Object {
  return {
    type: DELETE_LIST_ITEM_ROW,
    payload: { listId, listItemId },
  }
}

export const ADD_LIST_ITEM = 'ADD_LIST_ITEM'
export function addListItem(listId: string, listItem: Object, parentId: string, isUserAdded?: boolean): Object {
  return {
    type: ADD_LIST_ITEM,
    payload: {
      listId, listItem, parentId, isUserAdded
    },
  }
}

export const SET_LIST_ITEM_FILTER = 'SET_LIST_ITEM_FILTER'
export function setListItemFilter(listId: string, listFilter: string | null, filteredTypes?: Array<string>, filterKey?: string): Object {
  return {
    type: SET_LIST_ITEM_FILTER,
    payload: {
      listId,
      listFilter,
      filteredTypes,
      filterKey
    },
  }
}

export const SAVE_LIST = 'SAVE_LIST'
export function saveList(): Object {
  return {
    type: SAVE_LIST,
  }
}
export const SAVE_LIST_DONE = 'SAVE_LIST_DONE'
export function saveListDone(listId: string): Object {
  return {
    type: SAVE_LIST_DONE,
    payload: { listId }
  }
}

export const SET_MOCK_LIST_TO_STORE = 'SET_MOCK_LIST_TO_STORE'
export const setMockListToStore = (listStoreId: string, listItems: Object, columns: Array<Object>): Object => ({
  type: SET_MOCK_LIST_TO_STORE,
  payload: { listStoreId, listItems, columns }
})

export const SET_LIST_COLUMNS = 'SET_LIST_COLUMNS'
export const setListColumns = (listStoreId: string, columns: Array<TVDListItemColumn>) => ({
  type: SET_LIST_COLUMNS,
  payload: { listStoreId, columns }
})

export const SET_RENOVATION_PROFILE = 'SET_RENOVATION_PROFILE'
export function setRenovationProfile(newColumnData: Object, listStoreId: string, rowId: string): Object {
  return {
    type: SET_RENOVATION_PROFILE,
    payload: { newColumnData, listStoreId, rowId }
  }
}

export function buildRenovationProfile(modalId: string, listStoreId: string, rowIds: Array<string>, profileId: string): Object {
  return (dispatch: Function, getState: Function) => {
    const profileMeasures = getState().properties[modalId]
    const newColumnData = reduce(profileMeasures, (result: Object, measure: Object): Object => ({
      ...result, [measure.propertyName]: measure.value
    }), { RenovationProfileId: profileId })

    batch(() => {
      rowIds.forEach((rowId: string) => {
        const patchOperation = createPatchOperation({
          resourceId: rowId,
          value: profileId,
          basePath: patchOperationRenovationProfileBasePath,
          operationType: TVD_PATCH_OPERATION_TYPE_UPDATE,
        })
        dispatch(storePatchOperation(listStoreId, patchOperation))
        dispatch(setRenovationProfile(newColumnData, listStoreId, rowId))
      })
    })
  }
}

export const MERGE_LIST_ITEMS = 'MERGE_LIST_ITEMS'
export const mergeListItems = (listStoreId: string, listItems: TVDListItems, mergeOptions?: TVDMergeOptions) => ({
  type: MERGE_LIST_ITEMS,
  payload: {
    listStoreId,
    listItems,
    mergeOptions
  }
})

export const SET_LIST_ITEM_DEFAULTS = 'SET_LIST_ITEM_DEFAULTS'
export const setListItemDefaults = (listStoreId: string, listItemId: string, defaults: Object): Object => ({
  type: SET_LIST_ITEM_DEFAULTS,
  payload: {
    listStoreId,
    listItemId,
    defaults
  }
})

export const FILTER_LIST_ITEMS_BY_COLUMN_DATA = 'FILTER_LIST_ITEMS_BY_COLUMN_DATA'
export const filterListItemsByColumnData = (
  listStoreId: string,
  filterColumnDataKey: string,
  filterColumnDataValue: string | number | boolean
): TVDAction => ({
  type: FILTER_LIST_ITEMS_BY_COLUMN_DATA,
  payload: {
    listStoreId,
    filterColumnDataKey,
    filterColumnDataValue
  }
})

export const CLEAR_LIST_ITEMS_COLUMN_DATA_FILTER = 'CLEAR_LIST_ITEMS_COLUMN_DATA_FILTER'
export const clearListItemsColumnDataFilter = (listStoreId: string): TVDAction => ({
  type: CLEAR_LIST_ITEMS_COLUMN_DATA_FILTER,
  payload: {
    listStoreId,
  }
})

export const CLEAR_LIST_ITEMS_FILTER = 'CLEAR_LIST_ITEMS_FILTER'
export const clearListItemsFilter = (listStoreId: string): TVDAction => ({
  type: CLEAR_LIST_ITEMS_FILTER,
  payload: {
    listStoreId,
  }
})

export const FILTER_LIST_ITEMS_WITH_FILTER_FN = 'FILTER_LIST_ITEMS_WITH_FILTER_FN'
export const filterListItemsWithFilterFn = (
  listStoreId: string,
  filterFn: (listItem: TVDWOPListItem) => boolean
): TVDAction => ({
  type: FILTER_LIST_ITEMS_WITH_FILTER_FN,
  payload: {
    listStoreId,
    filterFn
  }
})

export const SET_ACTIVITY_FILTER = 'SET_ACTIVITY_FILTER'
export const setActivityFilter = (row: TVDWOPListItem | TVDListItem, sourceListId: string, filteredListId: string) => ({
  type: SET_ACTIVITY_FILTER,
  payload: { row, sourceListId, filteredListId }
})

export const FILTER_WOP_ACTIVITY_STRUCTURE_BY_SPACES = 'FILTER_WOP_ACTIVITY_STRUCTURE_BY_SPACES'
export const filterWopActivityStructureBySpaces = () => ({
  type: FILTER_WOP_ACTIVITY_STRUCTURE_BY_SPACES,
})

export const FILTER_WOP_SPACE_SCHEDULE_BY_ACTIVITIES = 'FILTER_WOP_SPACE_SCHEDULE_BY_ACTIVITIES'
export const filterWopSpaceScheduleByActivities = () => ({
  type: FILTER_WOP_SPACE_SCHEDULE_BY_ACTIVITIES
})

export const CLEAR_ACTIVITY_FILTERS = 'CLEAR_ACTIVITY_FILTERS'
export const clearActivityFilters = () => ({
  type: CLEAR_ACTIVITY_FILTERS
})

export const ADD_FROM_LIST_ITEMS_TO_FILTERED = 'ADD_FROM_LIST_ITEMS_TO_FILTERED'
export const addFromListItemsToFiltered = (
  listStoreId: string,
  addFn: (listItem: TVDWOPListItem) => boolean
): TVDAction => ({
  type: ADD_FROM_LIST_ITEMS_TO_FILTERED,
  payload: {
    listStoreId,
    addFn
  }
})

export const SET_PROCESS_ACTIVITY_ITEMS = 'SET_PROCESS_ACTIVITY_ITEMS'
export const setProcessActivityItems = (listStoreId: string, listItems: Object, columns?: Array<Object>): Object => ({
  type: SET_PROCESS_ACTIVITY_ITEMS,
  payload: { listStoreId, listItems, columns }
})

export const SET_BUILDINGS_LIST_TO_STORE = 'SET_BUILDINGS_LIST_TO_STORE'
export const setBuildingsListToStore = (listStoreId: string, buildingsList: Array<TVDBuilding>, columns?: Array<Object>): Object => ({
  type: SET_BUILDINGS_LIST_TO_STORE,
  payload: { listStoreId, buildingsList, columns }
})

export const APPLY_LIST_ITEM_MODIFICATIONS = 'APPLY_LIST_ITEM_MODIFICATIONS'

export function applyListItemModifications(
  listItemId: string,
  listStoreId: string,
): TVDAction {
  return {
    type: APPLY_LIST_ITEM_MODIFICATIONS,
    payload: {
      listItemId,
      listStoreId
    }
  }
}

export const UPDATE_LIST_LAST_UPDATED = 'UPDATE_LIST_LAST_UPDATED'
export const updateListLastUpdated = (listStoreId: string): TVDAction => ({
  type: UPDATE_LIST_LAST_UPDATED,
  payload: {
    listStoreId
  }
})

export const CLOSE_ALL_LIST_ITEMS = 'CLOSE_ALL_LIST_ITEMS'
export const closeAllListItems = (listStoreId: string): TVDAction => ({
  type: CLOSE_ALL_LIST_ITEMS,
  payload: {
    listStoreId
  }
})

export const REMOVE_ADDED_LIST_ITEMS = 'REMOVE_ADDED_LIST_ITEMS'
export const removeAddedListItems = (listStoreId: string): TVDAction => ({
  type: REMOVE_ADDED_LIST_ITEMS,
  payload: {
    listStoreId
  }
})

export const SET_LIST_ITEM_OPEN = 'SET_LIST_ITEM_OPEN'
export const setListItemOpen = (listStoreId: string, listItemId: string, isOpen: boolean, componentPreferencesId?: string): TVDAction => ({
  type: SET_LIST_ITEM_OPEN,
  payload: {
    listStoreId,
    listItemId,
    isOpen,
    componentPreferencesId
  }
})

