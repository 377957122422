// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.

const initialState = {}

export default function enumsReducer(state: TVDEnumStore = initialState, action: TVDAction): Object {
  if (action.type.includes('ENUM_SUCCESSFUL')) {
    const {
      config: {
        baseURL,
        url,
        params: {
          language
        } = {}
      } = {},
      payload: {
        parsedResponse
      }
    } = action
    const [basePath] = baseURL.split('/').slice(-1)
    const [enumName] = url.split('/').slice(-1)
    const APISpecificEnums = state[basePath] || {}
    const APILanguageSpecificEnums = APISpecificEnums[language] || {}
    return {
      ...state,
      [basePath]: {
        ...APISpecificEnums,
        [language]: {
          ...APILanguageSpecificEnums,
          // TODO: remove substring when C# convention is removed from API
          // removing first character of enum as they are written with C# E-prefix e.g EMyEnumName vs MyEnumName
          [enumName.substring(1)]: parsedResponse
        }
      }

    }
  }
  return state
}
