// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.
import * as React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withStyles } from '@material-ui/core/styles'
import { withRouter } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import Typography from '@material-ui/core/Typography'
import Header from '../../common/Header/Header'

import DrawerRight from '../../common/DrawerRight/DrawerRight'
import AppsList from './AppsList'
import { ADD_BUILDING, ADD_CALCULATION } from '../../../constants/moduleConstants'

const styles = ({ palette, typography }: TVDTheme): Object => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    backgroundColor: palette.white
  },
  contentWrapper: {
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  currentProject: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    color: palette.primary100,
    marginTop: '6vh',
    marginBottom: '90px',
    zIndex: '1'
  },
  projectText: {
    fontFamily: typography.latoBoldItalic,
    fontSize: '26px',
    color: palette.nevada,
    marginLeft: '9px',
    paddingTop: '2px'
  }
})

type HOCProps = {|
  t: Function, // i18n translate function
  classes: Object, // withStyles classes object
|}

type MappedProps = {|

|}

type DispatchProps = {|

|}

type ReceivedProps = {|

|}

type Props = {|
  ...HOCProps,
  ...MappedProps,
  ...DispatchProps,
  ...ReceivedProps
|}

type State = {|
  availableAppIds: Array<number>, // available modules
|}

export class AdminPage extends React.Component<Props, State> {
  state = {
    availableAppIds: []
  }

  currentProject = (): React$Element<'div'> => {
    const { classes } = this.props
    return (
      <div className={classes.currentProject}>
        <img src='/assets/images/Tili_small.svg' alt='account_icon' />
        <Typography className={classes.projectText} />
      </div>
    )
  }

  handleAppSelect = () => {

  }

  render(): React$Element<'div'> {
    const {
      classes,
    } = this.props
    return (
      <div className={classes.root}>
        <Header />
        <DrawerRight />
        <div className={classes.contentWrapper}>
          <AppsList displayApps={[ADD_BUILDING, ADD_CALCULATION]} onAppClick={this.handleAppSelect} availableAppIds={this.state.availableAppIds} />
          {this.currentProject()}
        </div>
      </div>
    )
  }
}


export default compose(
  withRouter,
  connect(null, null),
  withTranslation('translations'),
  withStyles(styles)
)(AdminPage)
