// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.

const allLanguageCodes = Object.freeze({
  af: 'af',
  'af-na': 'af-na',
  'af-ZA': 'af-ZA',
  agq: 'agq',
  'agq-CM': 'agq-CM',
  ak: 'ak',
  'ak-GH': 'ak-GH',
  am: 'am',
  'am-ET': 'am-ET',
  ar: 'ar',
  'ar-001': 'ar-001',
  'ar-AE': 'ar-AE',
  'ar-BH': 'ar-BH',
  'ar-DJ': 'ar-DJ',
  'ar-DZ': 'ar-DZ',
  'ar-EG': 'ar-EG',
  'ar-ER': 'ar-ER',
  'ar-EH': 'ar-EH',
  'ar-IL': 'ar-IL',
  'ar-IQ': 'ar-IQ',
  'ar-JO': 'ar-JO',
  'ar-KM': 'ar-KM',
  'ar-KW': 'ar-KW',
  'ar-LB': 'ar-LB',
  'ar-LY': 'ar-LY',
  'ar-MA': 'ar-MA',
  'ar-MR': 'ar-MR',
  'ar-OM': 'ar-OM',
  'ar-PS': 'ar-PS',
  'ar-QA': 'ar-QA',
  'ar-SA': 'ar-SA',
  'ar-SD': 'ar-SD',
  'ar-SO': 'ar-SO',
  'ar-SS': 'ar-SS',
  'ar-SY': 'ar-SY',
  'ar-TD': 'ar-TD',
  'ar-TN': 'ar-TN',
  'ar-YE': 'ar-YE',
  as: 'as',
  'as-IN': 'as-IN',
  asa: 'asa',
  'asa-TZ': 'asa-TZ',
  ast: 'ast',
  'ast-ES': 'ast-ES',
  az: 'az',
  'az-Cyrl': 'az-Cyrl',
  'az-Cyrl-AZ': 'az-Cyrl-AZ',
  'az-Latn': 'az-Latn',
  'az-Latn-AZ': 'az-Latn-AZ',
  bas: 'bas',
  'bas-CM': 'bas-CM',
  be: 'be',
  'be-BY': 'be-BY',
  bem: 'bem',
  'bem-ZM': 'bem-ZM',
  bez: 'bez',
  'bez-TZ': 'bez-TZ',
  bg: 'bg',
  'bg-BG': 'bg-BG',
  bm: 'bm',
  'bm-ML': 'bm-ML',
  bn: 'bn',
  'bn-BD': 'bn-BD',
  'bn-IN': 'bn-IN',
  bo: 'bo',
  'bo-CN': 'bo-CN',
  'bo-IN': 'bo-IN',
  br: 'br',
  'br-FR': 'br-FR',
  brx: 'brx',
  'brx-IN': 'brx-IN',
  bs: 'bs',
  'bs-Cyrl': 'bs-Cyrl',
  'bs-Cyrl-BA': 'bs-Cyrl-BA',
  'bs-Latn': 'bs-Latn',
  'bs-Latn-BA': 'bs-Latn-BA',
  ca: 'ca',
  'ca-AD': 'ca-AD',
  'ca-ES': 'ca-ES',
  'ca-ES-VALENCIA': 'ca-ES-VALENCIA',
  'ca-FR': 'ca-FR',
  'ca-IT': 'ca-IT',
  ccp: 'ccp',
  'ccp-BD': 'ccp-BD',
  'ccp-IN': 'ccp-IN',
  ce: 'ce',
  'ce-RU': 'ce-RU',
  ceb: 'ceb',
  'ceb-PH': 'ceb-PH',
  cgg: 'cgg',
  'cgg-UG': 'cgg-UG',
  chr: 'chr',
  'chr-US': 'chr-US',
  ckb: 'ckb',
  'ckb-IQ': 'ckb-IQ',
  'ckb-IR': 'ckb-IR',
  cs: 'cs',
  'cs-CZ': 'cs-CZ',
  cu: 'cu',
  'cu-RU': 'cu-RU',
  cy: 'cy',
  'cy-GB': 'cy-GB',
  da: 'da',
  'da-DK': 'da-DK',
  'da-GL': 'da-GL',
  dav: 'dav',
  'dav-KE': 'dav-KE',
  de: 'de',
  'de-AT': 'de-AT',
  'de-BE': 'de-BE',
  'de-CH': 'de-CH',
  'de-DE': 'de-DE',
  'de-IT': 'de-IT',
  'de-LI': 'de-LI',
  'de-LU': 'de-LU',
  dje: 'dje',
  'dje-NE': 'dje-NE',
  dsb: 'dsb',
  'dsb-DE': 'dsb-DE',
  dua: 'dua',
  'dua-CM': 'dua-CM',
  dyo: 'dyo',
  'dyo-SN': 'dyo-SN',
  dz: 'dz',
  'dz-BT': 'dz-BT',
  ebu: 'ebu',
  'ebu-KE': 'ebu-KE',
  ee: 'ee',
  'ee-GH': 'ee-GH',
  'ee-TG': 'ee-TG',
  el: 'el',
  'el-CY': 'el-CY',
  'el-GR': 'el-GR',
  en: 'en',
  'en-001': 'en-001',
  'en-150': 'en-150',
  'en-AE': 'en-AE',
  'en-AG': 'en-AG',
  'en-AI': 'en-AI',
  'en-AS': 'en-AS',
  'en-AT': 'en-AT',
  'en-AU': 'en-AU',
  'en-BB': 'en-BB',
  'en-BE': 'en-BE',
  'en-BI': 'en-BI',
  'en-BM': 'en-BM',
  'en-BS': 'en-BS',
  'en-BW': 'en-BW',
  'en-BZ': 'en-BZ',
  'en-CA': 'en-CA',
  'en-CC': 'en-CC',
  'en-CH': 'en-CH',
  'en-CK': 'en-CK',
  'en-CM': 'en-CM',
  'en-CX': 'en-CX',
  'en-CY': 'en-CY',
  'en-DE': 'en-DE',
  'en-DG': 'en-DG',
  'en-DK': 'en-DK',
  'en-DM': 'en-DM',
  'en-ER': 'en-ER',
  'en-FI': 'en-FI',
  'en-FJ': 'en-FJ',
  'en-FK': 'en-FK',
  'en-FM': 'en-FM',
  'en-GB': 'en-GB',
  'en-GD': 'en-GD',
  'en-GG': 'en-GG',
  'en-GH': 'en-GH',
  'en-GI': 'en-GI',
  'en-GM': 'en-GM',
  'en-GU': 'en-GU',
  'en-GY': 'en-GY',
  'en-HK': 'en-HK',
  'en-IE': 'en-IE',
  'en-IL': 'en-IL',
  'en-IM': 'en-IM',
  'en-IN': 'en-IN',
  'en-IO': 'en-IO',
  'en-JE': 'en-JE',
  'en-JM': 'en-JM',
  'en-KE': 'en-KE',
  'en-KI': 'en-KI',
  'en-KN': 'en-KN',
  'en-KY': 'en-KY',
  'en-LC': 'en-LC',
  'en-LR': 'en-LR',
  'en-LS': 'en-LS',
  'en-MG': 'en-MG',
  'en-MH': 'en-MH',
  'en-MO': 'en-MO',
  'en-MP': 'en-MP',
  'en-MS': 'en-MS',
  'en-MT': 'en-MT',
  'en-MU': 'en-MU',
  'en-MW': 'en-MW',
  'en-MY': 'en-MY',
  'en-NA': 'en-NA',
  'en-NF': 'en-NF',
  'en-NG': 'en-NG',
  'en-NL': 'en-NL',
  'en-NR': 'en-NR',
  'en-NU': 'en-NU',
  'en-NZ': 'en-NZ',
  'en-PG': 'en-PG',
  'en-PH': 'en-PH',
  'en-PK': 'en-PK',
  'en-PN': 'en-PN',
  'en-PR': 'en-PR',
  'en-PW': 'en-PW',
  'en-RW': 'en-RW',
  'en-SB': 'en-SB',
  'en-SC': 'en-SC',
  'en-SD': 'en-SD',
  'en-SE': 'en-SE',
  'en-SG': 'en-SG',
  'en-SH': 'en-SH',
  'en-SI': 'en-SI',
  'en-SL': 'en-SL',
  'en-SS': 'en-SS',
  'en-SX': 'en-SX',
  'en-SZ': 'en-SZ',
  'en-TC': 'en-TC',
  'en-TK': 'en-TK',
  'en-TO': 'en-TO',
  'en-TT': 'en-TT',
  'en-TV': 'en-TV',
  'en-TZ': 'en-TZ',
  'en-UG': 'en-UG',
  'en-UM': 'en-UM',
  'en-US': 'en-US',
  'en-US-POSIX': 'en-US-POSIX',
  'en-VC': 'en-VC',
  'en-VG': 'en-VG',
  'en-VI': 'en-VI',
  'en-VU': 'en-VU',
  'en-WS': 'en-WS',
  'en-ZA': 'en-ZA',
  'en-ZM': 'en-ZM',
  'en-ZW': 'en-ZW',
  eo: 'eo',
  'eo-001': 'eo-001',
  es: 'es',
  'es-419': 'es-419',
  'es-AR': 'es-AR',
  'es-BO': 'es-BO',
  'es-BR': 'es-BR',
  'es-BZ': 'es-BZ',
  'es-CL': 'es-CL',
  'es-CO': 'es-CO',
  'es-CR': 'es-CR',
  'es-CU': 'es-CU',
  'es-DO': 'es-DO',
  'es-EA': 'es-EA',
  'es-EC': 'es-EC',
  'es-ES': 'es-ES',
  'es-GQ': 'es-GQ',
  'es-GT': 'es-GT',
  'es-HN': 'es-HN',
  'es-IC': 'es-IC',
  'es-MX': 'es-MX',
  'es-NI': 'es-NI',
  'es-PA': 'es-PA',
  'es-PE': 'es-PE',
  'es-PH': 'es-PH',
  'es-PR': 'es-PR',
  'es-PY': 'es-PY',
  'es-SV': 'es-SV',
  'es-US': 'es-US',
  'es-UY': 'es-UY',
  'es-VE': 'es-VE',
  et: 'et',
  'et-EE': 'et-EE',
  eu: 'eu',
  'eu-ES': 'eu-ES',
  ewo: 'ewo',
  'ewo-CM': 'ewo-CM',
  fa: 'fa',
  'fa-AF': 'fa-AF',
  'fa-IR': 'fa-IR',
  ff: 'ff',
  'ff-Adlm': 'ff-Adlm',
  'ff-Adlm-BF': 'ff-Adlm-BF',
  'ff-Adlm-CM': 'ff-Adlm-CM',
  'ff-Adlm-GH': 'ff-Adlm-GH',
  'ff-Adlm-GM': 'ff-Adlm-GM',
  'ff-Adlm-GN': 'ff-Adlm-GN',
  'ff-Adlm-GW': 'ff-Adlm-GW',
  'ff-Adlm-LR': 'ff-Adlm-LR',
  'ff-Adlm-MR': 'ff-Adlm-MR',
  'ff-Adlm-NE': 'ff-Adlm-NE',
  'ff-Adlm-NG': 'ff-Adlm-NG',
  'ff-Adlm-SL': 'ff-Adlm-SL',
  'ff-Adlm-SN': 'ff-Adlm-SN',
  'ff-Latn': 'ff-Latn',
  'ff-Latn-BF': 'ff-Latn-BF',
  'ff-Latn-CM': 'ff-Latn-CM',
  'ff-Latn-GH': 'ff-Latn-GH',
  'ff-Latn-GM': 'ff-Latn-GM',
  'ff-Latn-GN': 'ff-Latn-GN',
  'ff-Latn-GW': 'ff-Latn-GW',
  'ff-Latn-LR': 'ff-Latn-LR',
  'ff-Latn-MR': 'ff-Latn-MR',
  'ff-Latn-NE': 'ff-Latn-NE',
  'ff-Latn-NG': 'ff-Latn-NG',
  'ff-Latn-SL': 'ff-Latn-SL',
  'ff-Latn-SN': 'ff-Latn-SN',
  fi: 'fi',
  'fi-FI': 'fi-FI',
  fil: 'fil',
  'fil-PH': 'fil-PH',
  fo: 'fo',
  'fo-DK': 'fo-DK',
  'fo-FO': 'fo-FO',
  fr: 'fr',
  'fr-BE': 'fr-BE',
  'fr-BF': 'fr-BF',
  'fr-BI': 'fr-BI',
  'fr-BJ': 'fr-BJ',
  'fr-BL': 'fr-BL',
  'fr-CA': 'fr-CA',
  'fr-CD': 'fr-CD',
  'fr-CF': 'fr-CF',
  'fr-CG': 'fr-CG',
  'fr-CH': 'fr-CH',
  'fr-CI': 'fr-CI',
  'fr-CM': 'fr-CM',
  'fr-DJ': 'fr-DJ',
  'fr-DZ': 'fr-DZ',
  'fr-FR': 'fr-FR',
  'fr-GA': 'fr-GA',
  'fr-GF': 'fr-GF',
  'fr-GN': 'fr-GN',
  'fr-GP': 'fr-GP',
  'fr-GQ': 'fr-GQ',
  'fr-HT': 'fr-HT',
  'fr-KM': 'fr-KM',
  'fr-LU': 'fr-LU',
  'fr-MA': 'fr-MA',
  'fr-MC': 'fr-MC',
  'fr-MF': 'fr-MF',
  'fr-MG': 'fr-MG',
  'fr-ML': 'fr-ML',
  'fr-MQ': 'fr-MQ',
  'fr-MR': 'fr-MR',
  'fr-MU': 'fr-MU',
  'fr-NC': 'fr-NC',
  'fr-NE': 'fr-NE',
  'fr-PF': 'fr-PF',
  'fr-PM': 'fr-PM',
  'fr-RE': 'fr-RE',
  'fr-RW': 'fr-RW',
  'fr-SC': 'fr-SC',
  'fr-SN': 'fr-SN',
  'fr-SY': 'fr-SY',
  'fr-TD': 'fr-TD',
  'fr-TG': 'fr-TG',
  'fr-TN': 'fr-TN',
  'fr-VU': 'fr-VU',
  'fr-WF': 'fr-WF',
  'fr-YT': 'fr-YT',
  fur: 'fur',
  'fur-IT': 'fur-IT',
  fy: 'fy',
  'fy-NL': 'fy-NL',
  ga: 'ga',
  'ga-GB': 'ga-GB',
  'ga-IE': 'ga-IE',
  gd: 'gd',
  'gd-GB': 'gd-GB',
  gl: 'gl',
  'gl-ES': 'gl-ES',
  gsw: 'gsw',
  'gsw-CH': 'gsw-CH',
  'gsw-FR': 'gsw-FR',
  'gsw-LI': 'gsw-LI',
  gu: 'gu',
  'gu-IN': 'gu-IN',
  guz: 'guz',
  'guz-KE': 'guz-KE',
  gv: 'gv',
  'gv-IM': 'gv-IM',
  ha: 'ha',
  'ha-GH': 'ha-GH',
  'ha-NE': 'ha-NE',
  'ha-NG': 'ha-NG',
  haw: 'haw',
  'haw-US': 'haw-US',
  he: 'he',
  'he-IL': 'he-IL',
  hi: 'hi',
  'hi-IN': 'hi-IN',
  hr: 'hr',
  'hr-BA': 'hr-BA',
  'hr-HR': 'hr-HR',
  hsb: 'hsb',
  'hsb-DE': 'hsb-DE',
  hu: 'hu',
  'hu-HU': 'hu-HU',
  hy: 'hy',
  'hy-AM': 'hy-AM',
  ia: 'ia',
  'ia-001': 'ia-001',
  id: 'id',
  'id-ID': 'id-ID',
  ig: 'ig',
  'ig-NG': 'ig-NG',
  ii: 'ii',
  'ii-CN': 'ii-CN',
  is: 'is',
  'is-IS': 'is-IS',
  it: 'it',
  'it-CH': 'it-CH',
  'it-IT': 'it-IT',
  'it-SM': 'it-SM',
  'it-VA': 'it-VA',
  ja: 'ja',
  'ja-JP': 'ja-JP',
  jgo: 'jgo',
  'jgo-CM': 'jgo-CM',
  jmc: 'jmc',
  'jmc-TZ': 'jmc-TZ',
  jv: 'jv',
  'jv-ID': 'jv-ID',
  ka: 'ka',
  'ka-GE': 'ka-GE',
  kab: 'kab',
  'kab-DZ': 'kab-DZ',
  kam: 'kam',
  'kam-KE': 'kam-KE',
  kde: 'kde',
  'kde-TZ': 'kde-TZ',
  kea: 'kea',
  'kea-CV': 'kea-CV',
  khq: 'khq',
  'khq-ML': 'khq-ML',
  ki: 'ki',
  'ki-KE': 'ki-KE',
  kk: 'kk',
  'kk-KZ': 'kk-KZ',
  kkj: 'kkj',
  'kkj-CM': 'kkj-CM',
  kl: 'kl',
  'kl-GL': 'kl-GL',
  kln: 'kln',
  'kln-KE': 'kln-KE',
  km: 'km',
  'km-KH': 'km-KH',
  kn: 'kn',
  'kn-IN': 'kn-IN',
  ko: 'ko',
  'ko-KP': 'ko-KP',
  'ko-KR': 'ko-KR',
  kok: 'kok',
  'kok-IN': 'kok-IN',
  ks: 'ks',
  'ks-Arab': 'ks-Arab',
  'ks-Arab-IN': 'ks-Arab-IN',
  ksb: 'ksb',
  'ksb-TZ': 'ksb-TZ',
  ksf: 'ksf',
  'ksf-CM': 'ksf-CM',
  ksh: 'ksh',
  'ksh-DE': 'ksh-DE',
  ku: 'ku',
  'ku-TR': 'ku-TR',
  kw: 'kw',
  'kw-GB': 'kw-GB',
  ky: 'ky',
  'ky-KG': 'ky-KG',
  lag: 'lag',
  'lag-TZ': 'lag-TZ',
  lb: 'lb',
  'lb-LU': 'lb-LU',
  lg: 'lg',
  'lg-UG': 'lg-UG',
  lkt: 'lkt',
  'lkt-US': 'lkt-US',
  ln: 'ln',
  'ln-AO': 'ln-AO',
  'ln-CD': 'ln-CD',
  'ln-CF': 'ln-CF',
  'ln-CG': 'ln-CG',
  lo: 'lo',
  'lo-LA': 'lo-LA',
  lrc: 'lrc',
  'lrc-IQ': 'lrc-IQ',
  'lrc-IR': 'lrc-IR',
  lt: 'lt',
  'lt-LT': 'lt-LT',
  lu: 'lu',
  'lu-CD': 'lu-CD',
  luo: 'luo',
  'luo-KE': 'luo-KE',
  luy: 'luy',
  'luy-KE': 'luy-KE',
  lv: 'lv',
  'lv-LV': 'lv-LV',
  mai: 'mai',
  'mai-IN': 'mai-IN',
  mas: 'mas',
  'mas-KE': 'mas-KE',
  'mas-TZ': 'mas-TZ',
  mer: 'mer',
  'mer-KE': 'mer-KE',
  mfe: 'mfe',
  'mfe-MU': 'mfe-MU',
  mg: 'mg',
  'mg-MG': 'mg-MG',
  mgh: 'mgh',
  'mgh-MZ': 'mgh-MZ',
  mgo: 'mgo',
  'mgo-CM': 'mgo-CM',
  mi: 'mi',
  'mi-NZ': 'mi-NZ',
  mk: 'mk',
  'mk-MK': 'mk-MK',
  ml: 'ml',
  'ml-IN': 'ml-IN',
  mn: 'mn',
  'mn-MN': 'mn-MN',
  mni: 'mni',
  'mni-Beng': 'mni-Beng',
  'mni-Beng-IN': 'mni-Beng-IN',
  mr: 'mr',
  'mr-IN': 'mr-IN',
  ms: 'ms',
  'ms-BN': 'ms-BN',
  'ms-ID': 'ms-ID',
  'ms-MY': 'ms-MY',
  'ms-SG': 'ms-SG',
  mt: 'mt',
  'mt-MT': 'mt-MT',
  mua: 'mua',
  'mua-CM': 'mua-CM',
  my: 'my',
  'my-MM': 'my-MM',
  mzn: 'mzn',
  'mzn-IR': 'mzn-IR',
  naq: 'naq',
  'naq-NA': 'naq-NA',
  nb: 'nb',
  'nb-NO': 'nb-NO',
  'nb-SJ': 'nb-SJ',
  nd: 'nd',
  'nd-ZW': 'nd-ZW',
  nds: 'nds',
  'nds-DE': 'nds-DE',
  'nds-NL': 'nds-NL',
  ne: 'ne',
  'ne-IN': 'ne-IN',
  'ne-NP': 'ne-NP',
  nl: 'nl',
  'nl-AW': 'nl-AW',
  'nl-BE': 'nl-BE',
  'nl-BQ': 'nl-BQ',
  'nl-CW': 'nl-CW',
  'nl-NL': 'nl-NL',
  'nl-SR': 'nl-SR',
  'nl-SX': 'nl-SX',
  nmg: 'nmg',
  'nmg-CM': 'nmg-CM',
  nn: 'nn',
  'nn-NO': 'nn-NO',
  nnh: 'nnh',
  'nnh-CM': 'nnh-CM',
  nus: 'nus',
  'nus-SS': 'nus-SS',
  nyn: 'nyn',
  'nyn-UG': 'nyn-UG',
  om: 'om',
  'om-ET': 'om-ET',
  'om-KE': 'om-KE',
  or: 'or',
  'or-IN': 'or-IN',
  os: 'os',
  'os-GE': 'os-GE',
  'os-RU': 'os-RU',
  pa: 'pa',
  'pa-Arab': 'pa-Arab',
  'pa-Arab-PK': 'pa-Arab-PK',
  'pa-Guru': 'pa-Guru',
  'pa-Guru-IN': 'pa-Guru-IN',
  pcm: 'pcm',
  'pcm-NG': 'pcm-NG',
  pl: 'pl',
  'pl-PL': 'pl-PL',
  prg: 'prg',
  'prg-001': 'prg-001',
  ps: 'ps',
  'ps-AF': 'ps-AF',
  'ps-PK': 'ps-PK',
  pt: 'pt',
  'pt-AO': 'pt-AO',
  'pt-BR': 'pt-BR',
  'pt-CH': 'pt-CH',
  'pt-CV': 'pt-CV',
  'pt-GQ': 'pt-GQ',
  'pt-GW': 'pt-GW',
  'pt-LU': 'pt-LU',
  'pt-MO': 'pt-MO',
  'pt-MZ': 'pt-MZ',
  'pt-PT': 'pt-PT',
  'pt-ST': 'pt-ST',
  'pt-TL': 'pt-TL',
  qu: 'qu',
  'qu-BO': 'qu-BO',
  'qu-EC': 'qu-EC',
  'qu-PE': 'qu-PE',
  rm: 'rm',
  'rm-CH': 'rm-CH',
  rn: 'rn',
  'rn-BI': 'rn-BI',
  ro: 'ro',
  'ro-MD': 'ro-MD',
  'ro-RO': 'ro-RO',
  rof: 'rof',
  'rof-TZ': 'rof-TZ',
  root: 'root',
  ru: 'ru',
  'ru-BY': 'ru-BY',
  'ru-KG': 'ru-KG',
  'ru-KZ': 'ru-KZ',
  'ru-MD': 'ru-MD',
  'ru-RU': 'ru-RU',
  'ru-UA': 'ru-UA',
  rw: 'rw',
  'rw-RW': 'rw-RW',
  rwk: 'rwk',
  'rwk-TZ': 'rwk-TZ',
  sah: 'sah',
  'sah-RU': 'sah-RU',
  saq: 'saq',
  'saq-KE': 'saq-KE',
  sat: 'sat',
  'sat-Olck': 'sat-Olck',
  'sat-Olck-IN': 'sat-Olck-IN',
  sbp: 'sbp',
  'sbp-TZ': 'sbp-TZ',
  sd: 'sd',
  'sd-Arab': 'sd-Arab',
  'sd-Arab-PK': 'sd-Arab-PK',
  'sd-Deva': 'sd-Deva',
  'sd-Deva-IN': 'sd-Deva-IN',
  se: 'se',
  'se-FI': 'se-FI',
  'se-NO': 'se-NO',
  'se-SE': 'se-SE',
  seh: 'seh',
  'seh-MZ': 'seh-MZ',
  ses: 'ses',
  'ses-ML': 'ses-ML',
  sg: 'sg',
  'sg-CF': 'sg-CF',
  shi: 'shi',
  'shi-Latn': 'shi-Latn',
  'shi-Latn-MA': 'shi-Latn-MA',
  'shi-Tfng': 'shi-Tfng',
  'shi-Tfng-MA': 'shi-Tfng-MA',
  si: 'si',
  'si-LK': 'si-LK',
  sk: 'sk',
  'sk-SK': 'sk-SK',
  sl: 'sl',
  'sl-SI': 'sl-SI',
  smn: 'smn',
  'smn-FI': 'smn-FI',
  sn: 'sn',
  'sn-ZW': 'sn-ZW',
  so: 'so',
  'so-DJ': 'so-DJ',
  'so-ET': 'so-ET',
  'so-KE': 'so-KE',
  'so-SO': 'so-SO',
  sq: 'sq',
  'sq-AL': 'sq-AL',
  'sq-MK': 'sq-MK',
  'sq-XK': 'sq-XK',
  sr: 'sr',
  'sr-Cyrl': 'sr-Cyrl',
  'sr-Cyrl-BA': 'sr-Cyrl-BA',
  'sr-Cyrl-ME': 'sr-Cyrl-ME',
  'sr-Cyrl-RS': 'sr-Cyrl-RS',
  'sr-Cyrl-XK': 'sr-Cyrl-XK',
  'sr-Latn': 'sr-Latn',
  'sr-Latn-BA': 'sr-Latn-BA',
  'sr-Latn-ME': 'sr-Latn-ME',
  'sr-Latn-RS': 'sr-Latn-RS',
  'sr-Latn-XK': 'sr-Latn-XK',
  su: 'su',
  'su-Latn': 'su-Latn',
  'su-Latn-ID': 'su-Latn-ID',
  sv: 'sv',
  'sv-AX': 'sv-AX',
  'sv-FI': 'sv-FI',
  'sv-SE': 'sv-SE',
  sw: 'sw',
  'sw-CD': 'sw-CD',
  'sw-KE': 'sw-KE',
  'sw-TZ': 'sw-TZ',
  'sw-UG': 'sw-UG',
  ta: 'ta',
  'ta-IN': 'ta-IN',
  'ta-LK': 'ta-LK',
  'ta-MY': 'ta-MY',
  'ta-SG': 'ta-SG',
  te: 'te',
  'te-IN': 'te-IN',
  teo: 'teo',
  'teo-KE': 'teo-KE',
  'teo-UG': 'teo-UG',
  tg: 'tg',
  'tg-TJ': 'tg-TJ',
  th: 'th',
  'th-TH': 'th-TH',
  ti: 'ti',
  'ti-ER': 'ti-ER',
  'ti-ET': 'ti-ET',
  tk: 'tk',
  'tk-TM': 'tk-TM',
  to: 'to',
  'to-TO': 'to-TO',
  tr: 'tr',
  'tr-CY': 'tr-CY',
  'tr-TR': 'tr-TR',
  tt: 'tt',
  'tt-RU': 'tt-RU',
  twq: 'twq',
  'twq-NE': 'twq-NE',
  tzm: 'tzm',
  'tzm-MA': 'tzm-MA',
  ug: 'ug',
  'ug-CN': 'ug-CN',
  uk: 'uk',
  'uk-UA': 'uk-UA',
  ur: 'ur',
  'ur-IN': 'ur-IN',
  'ur-PK': 'ur-PK',
  uz: 'uz',
  'uz-Arab': 'uz-Arab',
  'uz-Arab-AF': 'uz-Arab-AF',
  'uz-Cyrl': 'uz-Cyrl',
  'uz-Cyrl-UZ': 'uz-Cyrl-UZ',
  'uz-Latn': 'uz-Latn',
  'uz-Latn-UZ': 'uz-Latn-UZ',
  vai: 'vai',
  'vai-Latn': 'vai-Latn',
  'vai-Latn-LR': 'vai-Latn-LR',
  'vai-Vaii': 'vai-Vaii',
  'vai-Vaii-LR': 'vai-Vaii-LR',
  vi: 'vi',
  'vi-VN': 'vi-VN',
  vo: 'vo',
  'vo-001': 'vo-001',
  vun: 'vun',
  'vun-TZ': 'vun-TZ',
  wae: 'wae',
  'wae-CH': 'wae-CH',
  wo: 'wo',
  'wo-SN': 'wo-SN',
  xh: 'xh',
  'xh-ZA': 'xh-ZA',
  xog: 'xog',
  'xog-UG': 'xog-UG',
  yav: 'yav',
  'yav-CM': 'yav-CM',
  yi: 'yi',
  'yi-001': 'yi-001',
  yo: 'yo',
  'yo-BJ': 'yo-BJ',
  'yo-NG': 'yo-NG',
  yue: 'yue',
  'yue-Hans': 'yue-Hans',
  'yue-Hans-CN': 'yue-Hans-CN',
  'yue-Hant': 'yue-Hant',
  'yue-Hant-HK': 'yue-Hant-HK',
  zgh: 'zgh',
  'zgh-MA': 'zgh-MA',
  zh: 'zh',
  'zh-Hans': 'zh-Hans',
  'zh-Hans-CN': 'zh-Hans-CN',
  'zh-Hans-HK': 'zh-Hans-HK',
  'zh-Hans-MO': 'zh-Hans-MO',
  'zh-Hans-SG': 'zh-Hans-SG',
  'zh-Hant': 'zh-Hant',
  'zh-Hant-HK': 'zh-Hant-HK',
  'zh-Hant-MO': 'zh-Hant-MO',
  'zh-Hant-TW': 'zh-Hant-TW',
  zu: 'zu',
  'zu-ZA': 'zu-ZA',
})

export const supportedLanguageCodes = Object.freeze({
  [allLanguageCodes.fi]: allLanguageCodes.fi,
  [allLanguageCodes.sv]: allLanguageCodes.sv,
  [allLanguageCodes.en]: allLanguageCodes.en,
})

export const supportedTOSLanguageCodes = Object.freeze({
  [allLanguageCodes.fi]: allLanguageCodes.fi,
  [allLanguageCodes.en]: allLanguageCodes.en,
})

export type TVDSupportedLanguageCodes = $Keys<typeof supportedLanguageCodes>
