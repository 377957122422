// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.

export const UNIT_PRICE = 'UnitPrice'
export const AMOUNT = 'Amount'
export const CHANGE = 'Change'
export const SHARE = 'Share'
export const AREAM2PERPC = 'AreaM2PerPc'
export const QUANTITY = 'Quantity'
export const QUANTITYPCS = 'QuantityPcs'
export const QUANTITY_SPACES_PCS = 'QuantitySpacesPcs'
export const QUANTITYTOTAL = 'QuantityTotal'
export const QUANTITY_SURFACE_ADJUSTED = 'QuantitySpaceSurfaceShareAdjusted'
export const AREA = 'AreaM2PerPc'
export const ADD_BUTTON = 'addButtonColumn'
export const DESCRIPTION = 'Description'
export const SHARE_OF_SURFACE_QUANTITY = 'ShareOfSurfaceQuantityP'
export const NEW_PRICE_TOTAL = 'NewPriceTotal'
export const TOTAL_PRICE = 'TotalPrice'
export const TOTAL_PRICE_IN_CURRENCY = 'TotalPriceInCurrency'
export const MODIFICATION_NEW_BUILD_IN_CURRENCY = 'ModificationNewBuildInCurrency'
export const RENOVATION_MEASURE_ID = 'RenovationMeasureId'
export const RENOVATION_MEASURE = 'RenovationMeasure'
export const RENOVATION_PROFILE = 'RenovationProfile'
export const RENOVATION_PROFILE_ID = 'RenovationProfileId'
export const RATIO = 'Ratio'
export const DRIVER = 'Driver'
export const USER_MODIFIED = 'userModified'
export const AREATOTALM2 = 'AreaTotalM2'
export const CODRIVER = 'CoDriver'
export const SPACE_DRIVER = 'SpaceDriver'
export const OPERATING_PROFILE = 'OperatingProfile'
export const WEEKS_IN_A_YEAR = 'WeeksInAYear'
export const DAYS_IN_A_WEEK = 'DaysInAWeek'
export const HOURS_IN_A_DAY = 'HoursInADay'
export const USEAGE_DEGREE_GOAL = 'UseageDegreeGoal'
export const TOPIC = 'Topic'
export const ACTIVITY = 'Activity'
export const ACTIVITY_LOAD = 'ActivityLoad'
export const ACTIVITY_LOAD_PERIOD = 'ActivityLoadPeriod'
export const ACTIVITY_LOAD_LENGTH = 'ActivityLoadLength'
export const USAGE_TASKS_OCCUPANT_PRESENCE_P_TARGET = 'UsageTasksOccupantPresencePTarget'
export const UNIT_POST_FIX = '_unit'
export const AREAHUM2 = 'Area'
export const EMAIL = 'Email'
export const INVOICE_PAPER = 'paper'
export const INVOICE_ELECTRONIC = 'electronic'
export const USER_ACCESS = 'UserAccess'
export const NAME = 'Name'
export const GROUPS = 'Groups'
export const STATUS = 'Status'
export const USER_RIGHTS = 'UserRights'
export const ESTIMATE_TYPE = 'EstimateType'
export const USER_PERMISSIONS_TO_ADD = 'UserPermissionsToAdd'
export const PRICE_TOTAL_NEW_BUILD_IN_CURRENCY = 'PriceTotalNewBuildInCurrency'
export const PERMITTED_BUILDING_AREA_M2 = 'PermittedBuildingAreaM2'
export const PERMITTED_BUILDING_AREA_PRICE = 'PermittedBuildingAreaPrice'
export const AREA_TOTAL_ALONG_EXTERIOR_WALL_OUTSIDES_NO_LARGE_OPENINGS_M2 = 'AreaTotalAlongExteriorWallOutsidesNoLargeOpeningsM2'
export const PRICE_TOTAL_IN_CURRENCY = 'PriceTotalInCurrency'
export const LOT_AREA_M2 = 'LotAreaM2'
export const TOTAL_PERMITTED_BUILDING_AREA_PRICE = 'TotalPermittedBuildingAreaPrice'
