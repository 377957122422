// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.

import { reduce } from 'lodash'
import {
  CLEAR_CALCULATIONS,
} from '../actions/calculations'
import { elementTypeByApp } from '../utils/commonUtils'
import { DATA_GET_ESTIMATES_SUCCESSFUL, DATA_DELETE_ESTIMATES_WITH_ESTIMATEID_SUCCESSFUL } from '../utils/generated-api-requests/estimates'

const initialState = {
  moduleCalculations: {}
}

export default function calculationReducer(state: Object = initialState, action: Object = {}): Object {
  switch (action.type) {
    case DATA_GET_ESTIMATES_SUCCESSFUL: {
      const {
        payload: {
          parsedResponse,
          shouldSkipUpdate
        }
      } = action
      if (shouldSkipUpdate) {
        return {
          ...state
        }
      }
      return {
        ...state,
        moduleCalculations: {
          ...parsedResponse,
        }
      }
    }
    case CLEAR_CALCULATIONS: {
      return {
        ...state,
        moduleCalculations: {},
      }
    }

    case DATA_DELETE_ESTIMATES_WITH_ESTIMATEID_SUCCESSFUL: {
      const {
        payload: {
          app,
          estimateId,
        }
      } = action
      const { moduleCalculations } = state
      const moduleName = elementTypeByApp(app)
      return {
        ...state,
        moduleCalculations: {
          ...moduleCalculations,
          [moduleName]: reduce(moduleCalculations[moduleName], (prev: Object, cur: Object) =>
            (cur.id === estimateId ? prev : { ...prev, [cur.id]: cur }), {})
        }
      }
    }
    default:
      return state
  }
}
