// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'

import HierarchicalListContainer from '../../HierarchicalListContainer/HierarchicalListContainer'
import DropdownMultipleSelectMenu from '../../../common/menus/DropdownMultipleSelectMenu/DropdownMultipleSelectMenu'
import FooterButtons from '../../widgets/FooterButtons/FooterButtons'
import ListOpenerIcon from '../../../common/lists/common/ListOpenerIcon/ListOpenerIcon'
import InfoPopover from '../../../common/InfoPopover/InfoPopover'
import Spinner from '../../../common/Spinner/Spinner'
import OverflowTooltip from '../../../common/OverflowTooltip/OverflowTooltip'
import {
  ACCESS_CONTROL_BUILDINGS,
  ACCESS_CONTROL_USER_RIGHTS,
  BUILDING,
  ACCESS_CONTROL_USER_GROUP
} from '../../../../constants/contentTypes'
import { USER_PERMISSIONS_TO_ADD, DESCRIPTION, USER_RIGHTS, ESTIMATE_TYPE } from '../../../../constants/attributes'
import {
  parseBuildingsIntoListItems
} from '../../../../utils/parseUtil'
import { setBuildingsListToStore, mergeListItems, setListItemLoaded } from '../../../../actions/list'
import { closeModal } from '../../../../actions/modals'
import {
  getBuildingsPermissionsWithBuildingIdRequest,
  getBuildingsRequest,
  getEstimatesRequest
} from '../../../../utils/generated-api-requests/estimates'
import { combineStyleClassNames } from '../../../../utils/styleUtils'
import { getEstimatesAPIBasePath } from '../../../../utils/apiUtils'
import { getHighestPermission } from '../../../../utils/permissionsUtils'
import { TVD_HEADER_REALESTATE_ID } from '../../../../constants/apiConstants'

const styles = ({ palette }: TVDTheme): Object => ({
  permissionsListWrapper: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '35px',
    flexGrow: 1,
  },
  descriptionText: {
    overflow: 'hidden',
    marginRight: '10px',
  },
  highlight: {
    color: palette.primary100
  },
  wrappedDescriptionCell: {
    display: 'flex',
    alignItems: 'center'
  },
  usergroupsIcon: {
    color: palette.cadetBlue,
    fontSize: '23px',
  },
  cellContentLeft: { // is also used in simpleHierarchicalList column header through listHeaderCellClassNames property
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  innerContentLeft: {
    '&>*': {
      justifyContent: 'flex-start'
    }
  },
  userRightsHeaderCell: {
    borderBottom: 0,
    justifyContent: 'flex-start'
  },
  listOpenerWrapper: {
    marginRight: '10px',
  },
  visiblityHidden: {
    visibility: 'hidden'
  },
  footerButtonsWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '16px',
    flexGrow: 1
  }
})

type HOCProps = {|
  t: Function, // i18n translate function
  classes: Object, // withStyles classes object
|}

type ReceivedProps = {|
  modalId: string, // id of the modal where the list is mounted in
  listId: string, // id of list
  subjectId?: string, // id of the subject to get subject dependant permissions
  columns: Array<TVDListItemColumn>, // columns for hierarchicalList
  onPermissionDropdownChange: (Object, Array<string>) => void, // function triggered when permission dropdown value changes
  showListOpenerIconFn?: (TVDListItem) => boolean, // function to determine if a row should have a list opener icon or not
|}

type MappedProps = {|
  userType: $PropertyType<TVDApplicationStore, 'userType'>,
|}

type DispatchProps = {|
  dispatchSetBuildingsListToStore: (listStoreId: string, listItems: Array<TVDBuilding>, columns: Array<TVDListItemColumn>) => void, // action to populate target list with buildings and columns
  dispatchSetListItemsToStore: (listStoreId: string, listItems: Object) => void, // action to populate target list with new list items
  dispatchCloseModal: (string) => void, // closes the modal where the list is mounted
|}

type Props = {|
  ...ReceivedProps,
  ...DispatchProps,
  ...MappedProps,
  ...HOCProps,
|}

type State = {|
  loading: boolean, // boolean to determine if should show spinner
|}

export class PermissionsListContainer extends Component<Props, State> {
  static defaultProps = {
    hideListOpenerIcon: false,
    showListOpenerIconFn: undefined,
  }

  state = {
    loading: true
  }

  componentDidMount() {
    const {
      listId,
      subjectId,
      columns,
      dispatchSetBuildingsListToStore,
    } = this.props

    const requestArguments = subjectId ? { query: { permissionsSubject: subjectId } } : {}
    getBuildingsRequest(
      requestArguments,
      { listId, ignoreStore: true },
      (buildingsList: Array<TVDBuildingResult>) => {
        // Dispatch building items to store when fetched, they act as a root items in permissions list
        const buildingsListItems = parseBuildingsIntoListItems(buildingsList)
        dispatchSetBuildingsListToStore(listId, buildingsListItems, columns)
        this.setState({ loading: false })
      },
      () => {
        this.setState({ loading: false })
      },
      this.getEstimatesAPIOptions()
    )
  }

  getEstimatesAPIOptions = (): {|
    basePath: typeof undefined | string
  |} => {
    const { userType } = this.props

    return { basePath: userType && getEstimatesAPIBasePath(userType) }
  }

  getAllEstimatesForBuilding = (rowId: string, realEstateId?: string): void => {
    this.setState({ loading: true })
    const { subjectId, dispatchSetListItemsToStore, listId } = this.props
    const requestArguments = subjectId
      ? { query: { permissionsSubject: subjectId, buildingId: rowId } }
      : { query: { buildingId: rowId } }

    getEstimatesRequest(
      requestArguments,
      {},
      (res: {[moduleName: string]: TVDEstimates}): void => {
        const allEstimates = Object.keys(res).reduce((prev: TVDListItems, moduleName: string) => {
          const moduleEstimates = res[moduleName]
          const estimates = Object
            .keys(moduleEstimates)
            .reduce((estimateScheduleItems: TVDListItems, estimateId: string): TVDListItems => ({
              ...estimateScheduleItems,
              [estimateId]: {
                id: estimateId,
                parentId: moduleEstimates[estimateId].buildingId,
                type: moduleEstimates[estimateId].estimateType,
                columnData: {
                  Description: moduleEstimates[estimateId].description,
                  [ESTIMATE_TYPE]: moduleEstimates[estimateId].estimateType,
                  [USER_RIGHTS]: moduleEstimates[estimateId].permissionsSubjectPermissions
                }
              }
            }), {})
          return ({
            ...prev,
            // $FlowFixMe merging listItem objects causes index issue with flow
            ...estimates
          })
        }, {})
        dispatchSetListItemsToStore(listId, allEstimates)
        this.setState({ loading: false })
      },
      (): void => {
        this.setState({ loading: false })
      },
      {
        ...this.getEstimatesAPIOptions(),
        headers: { [TVD_HEADER_REALESTATE_ID]: realEstateId }
      }
    )
  }

  onRowClick = (row: TVDListItem) => {
    const {
      listId,
      dispatchSetListItemsToStore
    } = this.props
    const {
      type,
      canHaveChildren,
      isOpen
    } = row
    if (!isOpen && canHaveChildren) {
      switch (type) {
        case BUILDING.toLowerCase(): {
          if (listId === ACCESS_CONTROL_BUILDINGS) {
            getBuildingsPermissionsWithBuildingIdRequest(
              {
                path: { buildingId: row.id },
                query: { directPermissions: true }
              },
              {},
              (res: TVDPermissionsResponse) => {
                const { usergroups, users, permissions } = res
                const userGroupListItems: TVDListItems = usergroups.reduce((prev: TVDListItems, next: TVDEnum): TVDListItems => (
                  {
                    ...prev,
                    [next.value]: {
                      id: next.value,
                      canHaveChildren: false,
                      parentId: row.id,
                      type: 'usergroup',
                      columnData: {
                        Description: next.localizedName
                      }
                    }
                  }
                ), {})
                const usersListItems: TVDListItems = users.reduce((prev: TVDListItems, next: TVDPermissionsResponseUser): TVDListItems => ({
                  ...prev,
                  [next.id]: {
                    id: next.id,
                    canHaveChildren: false,
                    parentId: row.id,
                    type: 'user',
                    columnData: {
                      Description: `${next.firstName} ${next.lastName}`,
                      [USER_RIGHTS]: permissions.find((permission: TVDUsersResponse | TVDPermission): boolean =>
                        (permission.id ? permission.id === next.id : false))?.permissions
                    }
                  }
                }), {})

                dispatchSetListItemsToStore(listId, {
                  ...usersListItems,
                  // $FlowFixMe merging listItem objects causes index issue with flow
                  ...userGroupListItems
                })
              },
              null,
            )
          } else {
            this.getAllEstimatesForBuilding(row.id, row.realEstateId)
          }
          break
        }
        default: break
      }
    }
  }

  getHighestPermissionLocalized = (permissions: Array<TVDSchemaSubjectPermissionEnum>): string => {
    const { t } = this.props
    const highestPermission = getHighestPermission(permissions)
    return t(`user-access::permissions._${highestPermission.toUpperCase()}_`)
  }

  getUserGroupIcon = (userGroupId: string): React$Element<'div'> => {
    const { classes } = this.props
    return (
      <div className={classes.groupIcon}>
        <InfoPopover
          id={`${userGroupId}-InfoPopover`}
          icon={{
            name: 'group',
            size: '20px',
            classes: classes.usergroupsIcon
          }}
          infoData={{
            userGroupId,
            type: ACCESS_CONTROL_USER_GROUP
          }} />
      </div>
    )
  }

  canHaveChildren = (listItem: TVDListItem): boolean => {
    const { type } = listItem
    if (type === 'usergroup' || type === 'user') return false
    return !!listItem.canHaveChildren
  }

  getWrappedCellContents = (): TVDWrappedCells => {
    const {
      classes,
      t,
      showListOpenerIconFn,
      onPermissionDropdownChange,
      listId
    } = this.props
    return {
      [DESCRIPTION]: ({ row }: TVDWrappedCellCallbackParameters): React$Element<'div'> => {
        const renderListOpenerIcon = showListOpenerIconFn ? showListOpenerIconFn(row) : this.canHaveChildren(row)
        return (
          <div className={classes.wrappedDescriptionCell}>
            <div
              className={combineStyleClassNames(classes.listOpenerWrapper, !renderListOpenerIcon && classes.visiblityHidden)}>
              <ListOpenerIcon id='ListOpenerIcon' position='relative' isOpen={row.isOpen} />
            </div>
            <div className={combineStyleClassNames(
              classes.descriptionText,
              listId !== ACCESS_CONTROL_USER_RIGHTS && row.type === 'building' && classes.highlight
              )}>
              <OverflowTooltip tooltipText={row.columnData.Description}>
                { row.columnData.Description }
              </OverflowTooltip>
            </div>
            { row.type === 'usergroup' && this.getUserGroupIcon(row.id) }
          </div>
        )
      },
      [USER_RIGHTS]: ({ row }: TVDWrappedCellCallbackParameters): React$Element<'div'> => {
        if (listId === ACCESS_CONTROL_BUILDINGS && row.type === 'building') return <div />
        const permissions = row.columnData[USER_RIGHTS]
        const text = permissions && permissions.length > 0 ? this.getHighestPermissionLocalized(permissions) : ''
        return (
          <div className={classes.cellContentLeft}>
            <OverflowTooltip tooltipText={text}>
              {text}
            </OverflowTooltip>
          </div>
        )
      },
      [ESTIMATE_TYPE]: ({ row }: TVDWrappedCellCallbackParameters): React$Element<'div'> => (
        <div className={classes.cellContentLeft}>
          {row.columnData[ESTIMATE_TYPE] && t(`settings._${row.columnData[ESTIMATE_TYPE].toUpperCase()}_`)}
        </div>
      ),
      [USER_PERMISSIONS_TO_ADD]: ({ row }: TVDWrappedCellCallbackParameters) => {
        const items = [
          { localizedName: t('permissions._READ_'), value: 'read' },
          { localizedName: t('permissions._WRITE_'), value: 'write' }
        ]

        return (
          <DropdownMultipleSelectMenu
            items={row.type !== 'building' ? items : [...items, { localizedName: t('permissions._ADD_'), value: 'add' }]}
            minimalist
            alignInputText='left'
            onChange={(value: Array<string>): void => {
              onPermissionDropdownChange(row, value)
            }} />
        )
      }
    }
  }

  getFooterButtons = (): React$Element<'div'> | null => {
    const {
      t, classes, modalId, dispatchCloseModal
    } = this.props
    if (!modalId) return null

    const buttons = [{
      id: `${modalId}-close`,
      onClick: () => { dispatchCloseModal(modalId) },
      text: t('widgets._CLOSE_'),
      variant: 'text'
    }]

    return (
      <div className={classes.footerButtonsWrapper}>
        <FooterButtons items={buttons} />
      </div>
    )
  }

  render(): React$Element<'div'> {
    const { classes, listId } = this.props
    return (
      <div className={classes.permissionsListWrapper}>
        { this.state.loading && <Spinner zIndex={2} />}
        <HierarchicalListContainer
          disabled={this.state.loading}
          hideListOpenerIcon
          wrappedCellContents={this.getWrappedCellContents()}
          listHeaderCellClassNames={{
            [USER_RIGHTS]: classes.innerContentLeft,
            [ESTIMATE_TYPE]: classes.innerContentLeft,
            [USER_PERMISSIONS_TO_ADD]: classes.innerContentLeft
          }}
          initialColumnWidths={{
            [DESCRIPTION]: 280,
            [USER_RIGHTS]: 210,
            [ESTIMATE_TYPE]: 210,
            [USER_PERMISSIONS_TO_ADD]: 245,
          }}
          listId={listId}
          listType={listId}
          testId={listId}
          onRowClick={this.onRowClick} />
        { this.getFooterButtons()}
      </div>
    )
  }
}

const mapStateToProps = ({ app: { userType } }: TVDReduxStore): MappedProps => ({
  userType
})

const mapDispatchToProps = (dispatch: Function) => ({
  dispatchCloseModal: (modalId: string) => { dispatch(closeModal(modalId)) },
  dispatchSetListItemsToStore: (listStoreId: string, listItems: Object) => {
    dispatch(mergeListItems(listStoreId, listItems))
  },
  dispatchSetBuildingsListToStore: (listStoreId: string, buildingsList: Array<TVDBuilding>, columns: Array<TVDListItemColumn>) => {
    dispatch(setBuildingsListToStore(listStoreId, buildingsList, columns))
  },
  dispatchSetListItemLoaded: (id: string, listStoreId: string) => { dispatch(setListItemLoaded(id, listStoreId)) }
})

export default compose(
  withTranslation(['translations', 'user-access']),
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
)(PermissionsListContainer)
