// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.

export const READ: 'read' = 'read'
export const WRITE: 'write' = 'write'
export const OWNER: 'owner' = 'owner'
export const DELETE: 'delete' = 'delete'
export const ADD: 'add' = 'add'
export const LIMITED_READ: 'limitedRead' = 'limitedRead'
export const MANAGER: 'manager' = 'manager'
export const ADD_REAL_ESTATE: 'add_real_estate' = 'add_real_estate'

export const PERMISSION_ORDER: Array<TVDSchemaSubjectPermissionEnum> = [MANAGER, WRITE, READ]

// List of all the permissions that can be assigned by a user to another one
export const ASSIGNABLE_PERMISSIONS: Array<TVDSchemaSubjectPermissionEnum> = [READ, WRITE, MANAGER]
// Equals to the 'Hallinta' that user can choose when assigning access rights
export const MANAGEMENT_PERMISSIONS_SET: Array<TVDSchemaSubjectPermissionEnum> = [READ, WRITE, MANAGER]
// Equals to the 'Luku' that user can choose when assigning access rights
export const READ_PERMISSIONS_SET: Array<TVDSchemaSubjectPermissionEnum> = [READ, WRITE]
