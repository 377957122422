// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withTranslation } from 'react-i18next'

import TabsPlain from '../../common/TabsPlain/TabsPlain'
import CalculationInformation, { type CalculationInformationProps } from './components/CalculationInformation'
import { FEATURE_CALCULATION_INFORMATION_USER_RIGHTS_TAB } from '../../../constants/features'
import FeaturesHOC from '../../hocs/FeaturesHOC/FeaturesHOC'

import { getEstimatesRequest, patchEstimatesWithEstimateIdRequest } from '../../../utils/generated-api-requests/estimates'
import { deleteEstimateLockWithEstimateIdRequest } from '../../../utils/generated-api-requests/spaces'
import { closeModal } from '../../../actions/modals'
import EstimateAccessRights from '../../containers/EstimateAccessRights/EstimateAccessRights'
import { postPolling } from '../../../actions/postPolling'

type HOCProps = {|
  t: Function, // translate,
  features: TVDFeatureHOCProps, // features from Store and helper functions from the HOC
|}

type DispatchProps = {|
  dispatchCloseModal: (string) => void, // action to close modal after patchCalculationRequest
  dispatchPostPolling: () => void, // initiates post polling
|}

type MappedProps = {|
  buildingId: string, // building id
  userId: string, // uuid of the signed in user
  realEstateId: $PropertyType<TVDApplicationStore, 'realEstateId'>, // current selected realEstateId
  activeEstimate: TVDActiveEstimateStore, // currently active estimate object that we receive from API
|}

type ReceivedProps = {|
  estimateDescription: string, // string from estimateDescription field
  assessmentDate: string, // string from assessmentDate field
  priceLevelChangeP: number, // price level change integer
  indexNumberFinal: string, // index points integer
  estimateType: string, // type of application which estimate is created for
  id: string, // calculation id
  hasLock?: boolean, // if the data in component is considered as locked by API and are not to be edited
  isFrozen?: boolean, // if the estimate is frozen
  isUserOwnerOrManager?: boolean // if user has the "owner" or "manager" permissions for the estimate
|}

type Props = {|
  ...HOCProps,
  ...MappedProps,
  ...DispatchProps,
  ...ReceivedProps
|}

type FormValues = {|
  estimateDescription: string,
  assessmentDate: string,
  priceLevelChangeP: number,
  assesmentDateValid: boolean,
  estimateDescriptionValid: boolean,
  priceLevelChangePValid: boolean,
  isPrivate: boolean,
  indexPoints: string
|}

export class CalculationInformationContainer extends Component<Props> {
  static defaultProps = {
    hasLock: false,
    isFrozen: false,
    isUserOwnerOrManager: false
  }

  getCalculationInfromationProps = (): CalculationInformationProps => {
    const {
      estimateDescription,
      assessmentDate,
      priceLevelChangeP,
      indexNumberFinal,
      id,
      hasLock,
      isFrozen
    } = this.props

    return {
      estimateDescription,
      assessmentDate,
      priceLevelChangeP,
      indexNumberFinal,
      id,
      testId: 'EditCalculation',
      isEditing: true,
      hasLock: hasLock || isFrozen
    }
  }

  getCalculationInformationTab = (): TVDTab => {
    const {
      estimateType,
      t,
      hasLock,
      isFrozen
    } = this.props
    return ({
      props: {
        label: t('calculationInformation._CALCULATION_INFORMATION_'),
      },
      content: (
        <CalculationInformation
          {...this.getCalculationInfromationProps()}
          estimateType={estimateType}
          hasLock={hasLock}
          isFrozen={isFrozen}
          onSave={this.onSave} />)
    })
  }

  getUsersRightsTab = (): TVDTab => {
    const {
      t,
      buildingId,
      id,
      realEstateId
    } = this.props
    return {
      featureName: FEATURE_CALCULATION_INFORMATION_USER_RIGHTS_TAB,
      props: {
        label: t('calculationInformation._USER_RIGHTS_'),
      },
      content: (
        <EstimateAccessRights
          realEstateId={realEstateId}
          showIsPrivateCheckbox
          disableEditUserInformation
          estimateId={id}
          buildingId={buildingId} />
      )
    }
  }

  onSave = (formValues: FormValues) => {
    const {
      estimateType,
      buildingId,
      dispatchCloseModal,
      id,
      userId,
      dispatchPostPolling,
      activeEstimate
    } = this.props

    const body = estimateType !== 'wop'
      ? {
        Description: formValues.estimateDescription,
        AssessmentDate: formValues.assessmentDate,
        PriceLevelChangeP: formValues.priceLevelChangeP,
      }
      : {
        Description: formValues.estimateDescription,
      }

    const isPatchingActiveEstimate = activeEstimate?.id === id
    const payload = isPatchingActiveEstimate ? {
      Description: body.Description,
      indexNumberOnAssessmentDate: formValues.indexPoints,
    } : {}

    patchEstimatesWithEstimateIdRequest(
      {
        path: { estimateId: id },
        body
      },
      payload,
      () => {
        getEstimatesRequest({ query: { estimateType, buildingId, permissionsSubject: userId } })
        deleteEstimateLockWithEstimateIdRequest({}, null, null, { estimateId: id })
        dispatchCloseModal(id)
        dispatchPostPolling()
      }, null, { estimateId: id }
    )
  }

  getTabs(): Array<TVDTab> {
    const {
      features: { getEnabledFeatures },
      isUserOwnerOrManager,
    } = this.props
    const tabs = [this.getCalculationInformationTab()]

    if (isUserOwnerOrManager) tabs.push(this.getUsersRightsTab())
    return getEnabledFeatures(tabs)
  }

  render(): React$Element<TabsPlain> {
    return (
      <TabsPlain
        tabs={this.getTabs()} />
    )
  }
}

const mapStateToProps = ({ activeEstimate, app: { buildingId, realEstateId }, user: { claims: { userId } } }: TVDReduxStore): MappedProps => ({
  buildingId,
  userId,
  realEstateId,
  activeEstimate
})

const mapDispatchToProps = (dispatch: Function): DispatchProps => ({
  dispatchCloseModal: (id: string) => { dispatch(closeModal(id)) },
  dispatchPostPolling: () => { dispatch(postPolling()) }
})

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation('translations'),
  FeaturesHOC
)(CalculationInformationContainer)
