// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.
/* eslint max-len: ["error", { "code": 350 }] */
/*
  Haahtela WOP Api 23.0.0
  Haahtela Api for WOP functionality - this file is to be deprecated, refer to openapi.yaml instead!
  192.168.254.119:5002
*/

import requestFn from './../requests/request'
import { API_BASE_URL } from './../../constants/envConstants'
import parserMapper from './../parserMapper'
import { getState } from '../../store'

export const basePath = '/wop-api'

export const DATA_GET_SPACE_SCHEDULE_WITH_ESTIMATEID_START = 'DATA_GET_SPACE_SCHEDULE_WITH_ESTIMATEID_START'
export const DATA_GET_SPACE_SCHEDULE_WITH_ESTIMATEID_SUCCESSFUL = 'DATA_GET_SPACE_SCHEDULE_WITH_ESTIMATEID_SUCCESSFUL'
export const DATA_GET_SPACE_SCHEDULE_WITH_ESTIMATEID_FAIL = 'DATA_GET_SPACE_SCHEDULE_WITH_ESTIMATEID_FAIL'

// Returns space schedule root level or full if given listType=flat
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/wop.yaml#/Space%20Schedule/get_space_schedule__estimateId_
export const getSpaceScheduleWithEstimateIdRequest = (
  requestArguments: getSpaceScheduleWithEstimateIdRequestWopArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_SPACE_SCHEDULE_WITH_ESTIMATEID_START',
      successful: 'DATA_GET_SPACE_SCHEDULE_WITH_ESTIMATEID_SUCCESSFUL',
      fail: 'DATA_GET_SPACE_SCHEDULE_WITH_ESTIMATEID_FAIL'
    },
    embeddedType: {
      items: {
        schemaPath: '#/definitions/SpaceScheduleItem',
        schemaKey: 'SpaceScheduleItem'
      }
    },
    fnName: 'getSpaceScheduleWithEstimateIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/wop-api`,
    method: 'GET',
    url: `/space-schedule/${options?.estimateId || store.app.calculation}`,
    params: {
      listType: requestArguments.query['listType'],
      processId: requestArguments.query['processId'],
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      items: {
        schemaPath: '#/definitions/SpaceScheduleItem',
        schemaKey: 'SpaceScheduleItem'
      }
    }, 'getSpaceScheduleWithEstimateIdRequest', '/wop-api'),
    actions: {
      start: {
        type: DATA_GET_SPACE_SCHEDULE_WITH_ESTIMATEID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_SPACE_SCHEDULE_WITH_ESTIMATEID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_SPACE_SCHEDULE_WITH_ESTIMATEID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getSpaceScheduleWithEstimateIdRequestDefinitions = (definitionsArguments: {| requestArgs: getSpaceScheduleWithEstimateIdRequestWopArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getSpaceScheduleWithEstimateIdRequestWopArguments, payload?: Object |} => ({
  fnName: 'getSpaceScheduleWithEstimateIdRequest',
  basePath: '/wop-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_GET_SPACE_SCHEDULE_COLUMNS_WITH_ESTIMATEID_START = 'DATA_GET_SPACE_SCHEDULE_COLUMNS_WITH_ESTIMATEID_START'
export const DATA_GET_SPACE_SCHEDULE_COLUMNS_WITH_ESTIMATEID_SUCCESSFUL = 'DATA_GET_SPACE_SCHEDULE_COLUMNS_WITH_ESTIMATEID_SUCCESSFUL'
export const DATA_GET_SPACE_SCHEDULE_COLUMNS_WITH_ESTIMATEID_FAIL = 'DATA_GET_SPACE_SCHEDULE_COLUMNS_WITH_ESTIMATEID_FAIL'

// Get possible columns for space schedule
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/wop.yaml#/Space%20Schedule/get_space_schedule__estimateId__columns
export const getSpaceScheduleColumnsWithEstimateIdRequest = (
  payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_SPACE_SCHEDULE_COLUMNS_WITH_ESTIMATEID_START',
      successful: 'DATA_GET_SPACE_SCHEDULE_COLUMNS_WITH_ESTIMATEID_SUCCESSFUL',
      fail: 'DATA_GET_SPACE_SCHEDULE_COLUMNS_WITH_ESTIMATEID_FAIL'
    },
    embeddedType: {
      properties: {
        schemaPath: '#/definitions/ValueProperty',
        schemaKey: 'ValueProperty'
      }
    },
    fnName: 'getSpaceScheduleColumnsWithEstimateIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/wop-api`,
    method: 'GET',
    url: `/space-schedule/${options?.estimateId || store.app.calculation}/columns`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      properties: {
        schemaPath: '#/definitions/ValueProperty',
        schemaKey: 'ValueProperty'
      }
    }, 'getSpaceScheduleColumnsWithEstimateIdRequest', '/wop-api'),
    actions: {
      start: {
        type: DATA_GET_SPACE_SCHEDULE_COLUMNS_WITH_ESTIMATEID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_SPACE_SCHEDULE_COLUMNS_WITH_ESTIMATEID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_SPACE_SCHEDULE_COLUMNS_WITH_ESTIMATEID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getSpaceScheduleColumnsWithEstimateIdRequestDefinitions = (definitionsArguments: {| payload?: Object |}): {|
  ...TVDGARConfigWithoutRequestArgs, payload?: Object |} => ({
  fnName: 'getSpaceScheduleColumnsWithEstimateIdRequest',
  basePath: '/wop-api',
  payload: definitionsArguments.payload,
})


export const DATA_GET_SPACE_SCHEDULE_VALUES_WITH_ESTIMATEID_START = 'DATA_GET_SPACE_SCHEDULE_VALUES_WITH_ESTIMATEID_START'
export const DATA_GET_SPACE_SCHEDULE_VALUES_WITH_ESTIMATEID_SUCCESSFUL = 'DATA_GET_SPACE_SCHEDULE_VALUES_WITH_ESTIMATEID_SUCCESSFUL'
export const DATA_GET_SPACE_SCHEDULE_VALUES_WITH_ESTIMATEID_FAIL = 'DATA_GET_SPACE_SCHEDULE_VALUES_WITH_ESTIMATEID_FAIL'

// Returns values calculated for space schedule
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/wop.yaml#/Space%20Schedule/get_space_schedule__estimateId__values
export const getSpaceScheduleValuesWithEstimateIdRequest = (
  requestArguments: getSpaceScheduleValuesWithEstimateIdRequestWopArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_SPACE_SCHEDULE_VALUES_WITH_ESTIMATEID_START',
      successful: 'DATA_GET_SPACE_SCHEDULE_VALUES_WITH_ESTIMATEID_SUCCESSFUL',
      fail: 'DATA_GET_SPACE_SCHEDULE_VALUES_WITH_ESTIMATEID_FAIL'
    },
    embeddedType: {
      values: {
        schemaPath: '#/definitions/ValueProperty',
        schemaKey: 'ValueProperty'
      }
    },
    fnName: 'getSpaceScheduleValuesWithEstimateIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/wop-api`,
    method: 'GET',
    url: `/space-schedule/${options?.estimateId || store.app.calculation}/values`,
    params: {
      processId: requestArguments.query?.['processId'],
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      values: {
        schemaPath: '#/definitions/ValueProperty',
        schemaKey: 'ValueProperty'
      }
    }, 'getSpaceScheduleValuesWithEstimateIdRequest', '/wop-api'),
    actions: {
      start: {
        type: DATA_GET_SPACE_SCHEDULE_VALUES_WITH_ESTIMATEID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_SPACE_SCHEDULE_VALUES_WITH_ESTIMATEID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_SPACE_SCHEDULE_VALUES_WITH_ESTIMATEID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getSpaceScheduleValuesWithEstimateIdRequestDefinitions = (definitionsArguments: {| requestArgs: getSpaceScheduleValuesWithEstimateIdRequestWopArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getSpaceScheduleValuesWithEstimateIdRequestWopArguments, payload?: Object |} => ({
  fnName: 'getSpaceScheduleValuesWithEstimateIdRequest',
  basePath: '/wop-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_GET_SPACE_SCHEDULE_FUNCTIONAL_SECTORS_WITH_ITEMID_START = 'DATA_GET_SPACE_SCHEDULE_FUNCTIONAL_SECTORS_WITH_ITEMID_START'
export const DATA_GET_SPACE_SCHEDULE_FUNCTIONAL_SECTORS_WITH_ITEMID_SUCCESSFUL = 'DATA_GET_SPACE_SCHEDULE_FUNCTIONAL_SECTORS_WITH_ITEMID_SUCCESSFUL'
export const DATA_GET_SPACE_SCHEDULE_FUNCTIONAL_SECTORS_WITH_ITEMID_FAIL = 'DATA_GET_SPACE_SCHEDULE_FUNCTIONAL_SECTORS_WITH_ITEMID_FAIL'

// Returns a functional sector for space schedule
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/wop.yaml#/Functional%20Sectors/get_space_schedule__estimateId__functional_sectors__itemId_
export const getSpaceScheduleFunctionalSectorsWithItemIdRequest = (
  requestArguments: getSpaceScheduleFunctionalSectorsWithItemIdRequestWopArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_SPACE_SCHEDULE_FUNCTIONAL_SECTORS_WITH_ITEMID_START',
      successful: 'DATA_GET_SPACE_SCHEDULE_FUNCTIONAL_SECTORS_WITH_ITEMID_SUCCESSFUL',
      fail: 'DATA_GET_SPACE_SCHEDULE_FUNCTIONAL_SECTORS_WITH_ITEMID_FAIL'
    },
    embeddedType: {
      nonEmbedded: {
        schemaPath: '#/definitions/SpaceScheduleItem',
        schemaKey: 'SpaceScheduleItem'
      }
    },
    fnName: 'getSpaceScheduleFunctionalSectorsWithItemIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/wop-api`,
    method: 'GET',
    url: `/space-schedule/${options?.estimateId || store.app.calculation}/functional-sectors/${requestArguments.path['itemId']}`,
    params: {
      processId: requestArguments.query?.['processId'],
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      nonEmbedded: {
        schemaPath: '#/definitions/SpaceScheduleItem',
        schemaKey: 'SpaceScheduleItem'
      }
    }, 'getSpaceScheduleFunctionalSectorsWithItemIdRequest', '/wop-api'),
    actions: {
      start: {
        type: DATA_GET_SPACE_SCHEDULE_FUNCTIONAL_SECTORS_WITH_ITEMID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_SPACE_SCHEDULE_FUNCTIONAL_SECTORS_WITH_ITEMID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_SPACE_SCHEDULE_FUNCTIONAL_SECTORS_WITH_ITEMID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getSpaceScheduleFunctionalSectorsWithItemIdRequestDefinitions = (definitionsArguments: {| requestArgs: getSpaceScheduleFunctionalSectorsWithItemIdRequestWopArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getSpaceScheduleFunctionalSectorsWithItemIdRequestWopArguments, payload?: Object |} => ({
  fnName: 'getSpaceScheduleFunctionalSectorsWithItemIdRequest',
  basePath: '/wop-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_GET_SPACE_SCHEDULE_FUNCTIONAL_SECTORS_FUNCTIONS_WITH_ITEMID_START = 'DATA_GET_SPACE_SCHEDULE_FUNCTIONAL_SECTORS_FUNCTIONS_WITH_ITEMID_START'
export const DATA_GET_SPACE_SCHEDULE_FUNCTIONAL_SECTORS_FUNCTIONS_WITH_ITEMID_SUCCESSFUL = 'DATA_GET_SPACE_SCHEDULE_FUNCTIONAL_SECTORS_FUNCTIONS_WITH_ITEMID_SUCCESSFUL'
export const DATA_GET_SPACE_SCHEDULE_FUNCTIONAL_SECTORS_FUNCTIONS_WITH_ITEMID_FAIL = 'DATA_GET_SPACE_SCHEDULE_FUNCTIONAL_SECTORS_FUNCTIONS_WITH_ITEMID_FAIL'

// Returns functions of a functional sector
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/wop.yaml#/Functional%20Sectors/get_space_schedule__estimateId__functional_sectors__itemId__functions
export const getSpaceScheduleFunctionalSectorsFunctionsWithItemIdRequest = (
  requestArguments: getSpaceScheduleFunctionalSectorsFunctionsWithItemIdRequestWopArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_SPACE_SCHEDULE_FUNCTIONAL_SECTORS_FUNCTIONS_WITH_ITEMID_START',
      successful: 'DATA_GET_SPACE_SCHEDULE_FUNCTIONAL_SECTORS_FUNCTIONS_WITH_ITEMID_SUCCESSFUL',
      fail: 'DATA_GET_SPACE_SCHEDULE_FUNCTIONAL_SECTORS_FUNCTIONS_WITH_ITEMID_FAIL'
    },
    embeddedType: {
      items: {
        schemaPath: '#/definitions/SpaceScheduleItem',
        schemaKey: 'SpaceScheduleItem'
      }
    },
    fnName: 'getSpaceScheduleFunctionalSectorsFunctionsWithItemIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/wop-api`,
    method: 'GET',
    url: `/space-schedule/${options?.estimateId || store.app.calculation}/functional-sectors/${requestArguments.path['itemId']}/functions`,
    params: {
      processId: requestArguments.query?.['processId'],
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      items: {
        schemaPath: '#/definitions/SpaceScheduleItem',
        schemaKey: 'SpaceScheduleItem'
      }
    }, 'getSpaceScheduleFunctionalSectorsFunctionsWithItemIdRequest', '/wop-api'),
    actions: {
      start: {
        type: DATA_GET_SPACE_SCHEDULE_FUNCTIONAL_SECTORS_FUNCTIONS_WITH_ITEMID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_SPACE_SCHEDULE_FUNCTIONAL_SECTORS_FUNCTIONS_WITH_ITEMID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_SPACE_SCHEDULE_FUNCTIONAL_SECTORS_FUNCTIONS_WITH_ITEMID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getSpaceScheduleFunctionalSectorsFunctionsWithItemIdRequestDefinitions = (definitionsArguments: {| requestArgs: getSpaceScheduleFunctionalSectorsFunctionsWithItemIdRequestWopArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getSpaceScheduleFunctionalSectorsFunctionsWithItemIdRequestWopArguments, payload?: Object |} => ({
  fnName: 'getSpaceScheduleFunctionalSectorsFunctionsWithItemIdRequest',
  basePath: '/wop-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_GET_SPACE_SCHEDULE_FUNCTIONS_WITH_ITEMID_START = 'DATA_GET_SPACE_SCHEDULE_FUNCTIONS_WITH_ITEMID_START'
export const DATA_GET_SPACE_SCHEDULE_FUNCTIONS_WITH_ITEMID_SUCCESSFUL = 'DATA_GET_SPACE_SCHEDULE_FUNCTIONS_WITH_ITEMID_SUCCESSFUL'
export const DATA_GET_SPACE_SCHEDULE_FUNCTIONS_WITH_ITEMID_FAIL = 'DATA_GET_SPACE_SCHEDULE_FUNCTIONS_WITH_ITEMID_FAIL'

// Returns a function for space schedule
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/wop.yaml#/Functions/get_space_schedule__estimateId__functions__itemId_
export const getSpaceScheduleFunctionsWithItemIdRequest = (
  requestArguments: getSpaceScheduleFunctionsWithItemIdRequestWopArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_SPACE_SCHEDULE_FUNCTIONS_WITH_ITEMID_START',
      successful: 'DATA_GET_SPACE_SCHEDULE_FUNCTIONS_WITH_ITEMID_SUCCESSFUL',
      fail: 'DATA_GET_SPACE_SCHEDULE_FUNCTIONS_WITH_ITEMID_FAIL'
    },
    embeddedType: {
      nonEmbedded: {
        schemaPath: '#/definitions/SpaceScheduleItem',
        schemaKey: 'SpaceScheduleItem'
      }
    },
    fnName: 'getSpaceScheduleFunctionsWithItemIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/wop-api`,
    method: 'GET',
    url: `/space-schedule/${options?.estimateId || store.app.calculation}/functions/${requestArguments.path['itemId']}`,
    params: {
      processId: requestArguments.query?.['processId'],
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      nonEmbedded: {
        schemaPath: '#/definitions/SpaceScheduleItem',
        schemaKey: 'SpaceScheduleItem'
      }
    }, 'getSpaceScheduleFunctionsWithItemIdRequest', '/wop-api'),
    actions: {
      start: {
        type: DATA_GET_SPACE_SCHEDULE_FUNCTIONS_WITH_ITEMID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_SPACE_SCHEDULE_FUNCTIONS_WITH_ITEMID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_SPACE_SCHEDULE_FUNCTIONS_WITH_ITEMID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getSpaceScheduleFunctionsWithItemIdRequestDefinitions = (definitionsArguments: {| requestArgs: getSpaceScheduleFunctionsWithItemIdRequestWopArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getSpaceScheduleFunctionsWithItemIdRequestWopArguments, payload?: Object |} => ({
  fnName: 'getSpaceScheduleFunctionsWithItemIdRequest',
  basePath: '/wop-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_GET_SPACE_SCHEDULE_FUNCTIONS_SPACE_GROUPS_WITH_ITEMID_START = 'DATA_GET_SPACE_SCHEDULE_FUNCTIONS_SPACE_GROUPS_WITH_ITEMID_START'
export const DATA_GET_SPACE_SCHEDULE_FUNCTIONS_SPACE_GROUPS_WITH_ITEMID_SUCCESSFUL = 'DATA_GET_SPACE_SCHEDULE_FUNCTIONS_SPACE_GROUPS_WITH_ITEMID_SUCCESSFUL'
export const DATA_GET_SPACE_SCHEDULE_FUNCTIONS_SPACE_GROUPS_WITH_ITEMID_FAIL = 'DATA_GET_SPACE_SCHEDULE_FUNCTIONS_SPACE_GROUPS_WITH_ITEMID_FAIL'

// Returns space groups of a function
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/wop.yaml#/Functions/get_space_schedule__estimateId__functions__itemId__space_groups
export const getSpaceScheduleFunctionsSpaceGroupsWithItemIdRequest = (
  requestArguments: getSpaceScheduleFunctionsSpaceGroupsWithItemIdRequestWopArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_SPACE_SCHEDULE_FUNCTIONS_SPACE_GROUPS_WITH_ITEMID_START',
      successful: 'DATA_GET_SPACE_SCHEDULE_FUNCTIONS_SPACE_GROUPS_WITH_ITEMID_SUCCESSFUL',
      fail: 'DATA_GET_SPACE_SCHEDULE_FUNCTIONS_SPACE_GROUPS_WITH_ITEMID_FAIL'
    },
    embeddedType: {
      items: {
        schemaPath: '#/definitions/SpaceScheduleItem',
        schemaKey: 'SpaceScheduleItem'
      }
    },
    fnName: 'getSpaceScheduleFunctionsSpaceGroupsWithItemIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/wop-api`,
    method: 'GET',
    url: `/space-schedule/${options?.estimateId || store.app.calculation}/functions/${requestArguments.path['itemId']}/space-groups`,
    params: {
      processId: requestArguments.query?.['processId'],
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      items: {
        schemaPath: '#/definitions/SpaceScheduleItem',
        schemaKey: 'SpaceScheduleItem'
      }
    }, 'getSpaceScheduleFunctionsSpaceGroupsWithItemIdRequest', '/wop-api'),
    actions: {
      start: {
        type: DATA_GET_SPACE_SCHEDULE_FUNCTIONS_SPACE_GROUPS_WITH_ITEMID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_SPACE_SCHEDULE_FUNCTIONS_SPACE_GROUPS_WITH_ITEMID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_SPACE_SCHEDULE_FUNCTIONS_SPACE_GROUPS_WITH_ITEMID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getSpaceScheduleFunctionsSpaceGroupsWithItemIdRequestDefinitions = (definitionsArguments: {| requestArgs: getSpaceScheduleFunctionsSpaceGroupsWithItemIdRequestWopArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getSpaceScheduleFunctionsSpaceGroupsWithItemIdRequestWopArguments, payload?: Object |} => ({
  fnName: 'getSpaceScheduleFunctionsSpaceGroupsWithItemIdRequest',
  basePath: '/wop-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_GET_SPACE_SCHEDULE_SPACE_GROUPS_WITH_ITEMID_START = 'DATA_GET_SPACE_SCHEDULE_SPACE_GROUPS_WITH_ITEMID_START'
export const DATA_GET_SPACE_SCHEDULE_SPACE_GROUPS_WITH_ITEMID_SUCCESSFUL = 'DATA_GET_SPACE_SCHEDULE_SPACE_GROUPS_WITH_ITEMID_SUCCESSFUL'
export const DATA_GET_SPACE_SCHEDULE_SPACE_GROUPS_WITH_ITEMID_FAIL = 'DATA_GET_SPACE_SCHEDULE_SPACE_GROUPS_WITH_ITEMID_FAIL'

// Returns a space group for space schedule
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/wop.yaml#/Space%20Groups/get_space_schedule__estimateId__space_groups__itemId_
export const getSpaceScheduleSpaceGroupsWithItemIdRequest = (
  requestArguments: getSpaceScheduleSpaceGroupsWithItemIdRequestWopArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_SPACE_SCHEDULE_SPACE_GROUPS_WITH_ITEMID_START',
      successful: 'DATA_GET_SPACE_SCHEDULE_SPACE_GROUPS_WITH_ITEMID_SUCCESSFUL',
      fail: 'DATA_GET_SPACE_SCHEDULE_SPACE_GROUPS_WITH_ITEMID_FAIL'
    },
    embeddedType: {
      nonEmbedded: {
        schemaPath: '#/definitions/SpaceScheduleItem',
        schemaKey: 'SpaceScheduleItem'
      }
    },
    fnName: 'getSpaceScheduleSpaceGroupsWithItemIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/wop-api`,
    method: 'GET',
    url: `/space-schedule/${options?.estimateId || store.app.calculation}/space-groups/${requestArguments.path['itemId']}`,
    params: {
      processId: requestArguments.query?.['processId'],
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      nonEmbedded: {
        schemaPath: '#/definitions/SpaceScheduleItem',
        schemaKey: 'SpaceScheduleItem'
      }
    }, 'getSpaceScheduleSpaceGroupsWithItemIdRequest', '/wop-api'),
    actions: {
      start: {
        type: DATA_GET_SPACE_SCHEDULE_SPACE_GROUPS_WITH_ITEMID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_SPACE_SCHEDULE_SPACE_GROUPS_WITH_ITEMID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_SPACE_SCHEDULE_SPACE_GROUPS_WITH_ITEMID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getSpaceScheduleSpaceGroupsWithItemIdRequestDefinitions = (definitionsArguments: {| requestArgs: getSpaceScheduleSpaceGroupsWithItemIdRequestWopArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getSpaceScheduleSpaceGroupsWithItemIdRequestWopArguments, payload?: Object |} => ({
  fnName: 'getSpaceScheduleSpaceGroupsWithItemIdRequest',
  basePath: '/wop-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_GET_SPACE_SCHEDULE_SPACE_GROUPS_SPACES_WITH_ITEMID_START = 'DATA_GET_SPACE_SCHEDULE_SPACE_GROUPS_SPACES_WITH_ITEMID_START'
export const DATA_GET_SPACE_SCHEDULE_SPACE_GROUPS_SPACES_WITH_ITEMID_SUCCESSFUL = 'DATA_GET_SPACE_SCHEDULE_SPACE_GROUPS_SPACES_WITH_ITEMID_SUCCESSFUL'
export const DATA_GET_SPACE_SCHEDULE_SPACE_GROUPS_SPACES_WITH_ITEMID_FAIL = 'DATA_GET_SPACE_SCHEDULE_SPACE_GROUPS_SPACES_WITH_ITEMID_FAIL'

// Returns spaces of a space group
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/wop.yaml#/Space%20Groups/get_space_schedule__estimateId__space_groups__itemId__spaces
export const getSpaceScheduleSpaceGroupsSpacesWithItemIdRequest = (
  requestArguments: getSpaceScheduleSpaceGroupsSpacesWithItemIdRequestWopArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_SPACE_SCHEDULE_SPACE_GROUPS_SPACES_WITH_ITEMID_START',
      successful: 'DATA_GET_SPACE_SCHEDULE_SPACE_GROUPS_SPACES_WITH_ITEMID_SUCCESSFUL',
      fail: 'DATA_GET_SPACE_SCHEDULE_SPACE_GROUPS_SPACES_WITH_ITEMID_FAIL'
    },
    embeddedType: {
      items: {
        schemaPath: '#/definitions/SpaceScheduleItem',
        schemaKey: 'SpaceScheduleItem'
      }
    },
    fnName: 'getSpaceScheduleSpaceGroupsSpacesWithItemIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/wop-api`,
    method: 'GET',
    url: `/space-schedule/${options?.estimateId || store.app.calculation}/space-groups/${requestArguments.path['itemId']}/spaces`,
    params: {
      processId: requestArguments.query?.['processId'],
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      items: {
        schemaPath: '#/definitions/SpaceScheduleItem',
        schemaKey: 'SpaceScheduleItem'
      }
    }, 'getSpaceScheduleSpaceGroupsSpacesWithItemIdRequest', '/wop-api'),
    actions: {
      start: {
        type: DATA_GET_SPACE_SCHEDULE_SPACE_GROUPS_SPACES_WITH_ITEMID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_SPACE_SCHEDULE_SPACE_GROUPS_SPACES_WITH_ITEMID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_SPACE_SCHEDULE_SPACE_GROUPS_SPACES_WITH_ITEMID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getSpaceScheduleSpaceGroupsSpacesWithItemIdRequestDefinitions = (definitionsArguments: {| requestArgs: getSpaceScheduleSpaceGroupsSpacesWithItemIdRequestWopArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getSpaceScheduleSpaceGroupsSpacesWithItemIdRequestWopArguments, payload?: Object |} => ({
  fnName: 'getSpaceScheduleSpaceGroupsSpacesWithItemIdRequest',
  basePath: '/wop-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_GET_SPACE_SCHEDULE_SPACES_WITH_ITEMID_START = 'DATA_GET_SPACE_SCHEDULE_SPACES_WITH_ITEMID_START'
export const DATA_GET_SPACE_SCHEDULE_SPACES_WITH_ITEMID_SUCCESSFUL = 'DATA_GET_SPACE_SCHEDULE_SPACES_WITH_ITEMID_SUCCESSFUL'
export const DATA_GET_SPACE_SCHEDULE_SPACES_WITH_ITEMID_FAIL = 'DATA_GET_SPACE_SCHEDULE_SPACES_WITH_ITEMID_FAIL'

// Returns a space for space schedule
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/wop.yaml#/Spaces/get_space_schedule__estimateId__spaces__itemId_
export const getSpaceScheduleSpacesWithItemIdRequest = (
  requestArguments: getSpaceScheduleSpacesWithItemIdRequestWopArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_SPACE_SCHEDULE_SPACES_WITH_ITEMID_START',
      successful: 'DATA_GET_SPACE_SCHEDULE_SPACES_WITH_ITEMID_SUCCESSFUL',
      fail: 'DATA_GET_SPACE_SCHEDULE_SPACES_WITH_ITEMID_FAIL'
    },
    embeddedType: {
      nonEmbedded: {
        schemaPath: '#/definitions/SpaceScheduleItem',
        schemaKey: 'SpaceScheduleItem'
      }
    },
    fnName: 'getSpaceScheduleSpacesWithItemIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/wop-api`,
    method: 'GET',
    url: `/space-schedule/${options?.estimateId || store.app.calculation}/spaces/${requestArguments.path['itemId']}`,
    params: {
      processId: requestArguments.query?.['processId'],
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      nonEmbedded: {
        schemaPath: '#/definitions/SpaceScheduleItem',
        schemaKey: 'SpaceScheduleItem'
      }
    }, 'getSpaceScheduleSpacesWithItemIdRequest', '/wop-api'),
    actions: {
      start: {
        type: DATA_GET_SPACE_SCHEDULE_SPACES_WITH_ITEMID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_SPACE_SCHEDULE_SPACES_WITH_ITEMID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_SPACE_SCHEDULE_SPACES_WITH_ITEMID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getSpaceScheduleSpacesWithItemIdRequestDefinitions = (definitionsArguments: {| requestArgs: getSpaceScheduleSpacesWithItemIdRequestWopArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getSpaceScheduleSpacesWithItemIdRequestWopArguments, payload?: Object |} => ({
  fnName: 'getSpaceScheduleSpacesWithItemIdRequest',
  basePath: '/wop-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_GET_SPACE_SCHEDULE_SPACES_PROCESSES_WITH_ITEMID_START = 'DATA_GET_SPACE_SCHEDULE_SPACES_PROCESSES_WITH_ITEMID_START'
export const DATA_GET_SPACE_SCHEDULE_SPACES_PROCESSES_WITH_ITEMID_SUCCESSFUL = 'DATA_GET_SPACE_SCHEDULE_SPACES_PROCESSES_WITH_ITEMID_SUCCESSFUL'
export const DATA_GET_SPACE_SCHEDULE_SPACES_PROCESSES_WITH_ITEMID_FAIL = 'DATA_GET_SPACE_SCHEDULE_SPACES_PROCESSES_WITH_ITEMID_FAIL'

// Returns properties about processes for a space
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/wop.yaml#/Spaces/get_space_schedule__estimateId__spaces__itemId__processes
export const getSpaceScheduleSpacesProcessesWithItemIdRequest = (
  requestArguments: getSpaceScheduleSpacesProcessesWithItemIdRequestWopArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_SPACE_SCHEDULE_SPACES_PROCESSES_WITH_ITEMID_START',
      successful: 'DATA_GET_SPACE_SCHEDULE_SPACES_PROCESSES_WITH_ITEMID_SUCCESSFUL',
      fail: 'DATA_GET_SPACE_SCHEDULE_SPACES_PROCESSES_WITH_ITEMID_FAIL'
    },
    embeddedType: {
      processes: {
        schemaPath: '#/definitions/ValueProperty',
        schemaKey: 'ValueProperty'
      }
    },
    fnName: 'getSpaceScheduleSpacesProcessesWithItemIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/wop-api`,
    method: 'GET',
    url: `/space-schedule/${options?.estimateId || store.app.calculation}/spaces/${requestArguments.path['itemId']}/processes`,
    params: {
      activityGroupIds: requestArguments.query['activityGroupIds'],
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      processes: {
        schemaPath: '#/definitions/ValueProperty',
        schemaKey: 'ValueProperty'
      }
    }, 'getSpaceScheduleSpacesProcessesWithItemIdRequest', '/wop-api'),
    actions: {
      start: {
        type: DATA_GET_SPACE_SCHEDULE_SPACES_PROCESSES_WITH_ITEMID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_SPACE_SCHEDULE_SPACES_PROCESSES_WITH_ITEMID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_SPACE_SCHEDULE_SPACES_PROCESSES_WITH_ITEMID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getSpaceScheduleSpacesProcessesWithItemIdRequestDefinitions = (definitionsArguments: {| requestArgs: getSpaceScheduleSpacesProcessesWithItemIdRequestWopArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getSpaceScheduleSpacesProcessesWithItemIdRequestWopArguments, payload?: Object |} => ({
  fnName: 'getSpaceScheduleSpacesProcessesWithItemIdRequest',
  basePath: '/wop-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_GET_SPACE_SCHEDULE_SPACES_ACTIVITIES_WITH_ITEMID_START = 'DATA_GET_SPACE_SCHEDULE_SPACES_ACTIVITIES_WITH_ITEMID_START'
export const DATA_GET_SPACE_SCHEDULE_SPACES_ACTIVITIES_WITH_ITEMID_SUCCESSFUL = 'DATA_GET_SPACE_SCHEDULE_SPACES_ACTIVITIES_WITH_ITEMID_SUCCESSFUL'
export const DATA_GET_SPACE_SCHEDULE_SPACES_ACTIVITIES_WITH_ITEMID_FAIL = 'DATA_GET_SPACE_SCHEDULE_SPACES_ACTIVITIES_WITH_ITEMID_FAIL'

// Returns activities for a space as a list of activitySchedule items. Schedule has no hierarchy so list type is always flat.
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/wop.yaml#/Spaces/get_space_schedule__estimateId__spaces__itemId__activities
export const getSpaceScheduleSpacesActivitiesWithItemIdRequest = (
  requestArguments: getSpaceScheduleSpacesActivitiesWithItemIdRequestWopArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_SPACE_SCHEDULE_SPACES_ACTIVITIES_WITH_ITEMID_START',
      successful: 'DATA_GET_SPACE_SCHEDULE_SPACES_ACTIVITIES_WITH_ITEMID_SUCCESSFUL',
      fail: 'DATA_GET_SPACE_SCHEDULE_SPACES_ACTIVITIES_WITH_ITEMID_FAIL'
    },
    embeddedType: {
      activities: {
        schemaPath: '#/definitions/ScheduleItem',
        schemaKey: 'ScheduleItem'
      }
    },
    fnName: 'getSpaceScheduleSpacesActivitiesWithItemIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/wop-api`,
    method: 'GET',
    url: `/space-schedule/${options?.estimateId || store.app.calculation}/spaces/${requestArguments.path['itemId']}/activities`,
    params: {
      activityGroupIds: requestArguments.query['activityGroupIds'],
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      activities: {
        schemaPath: '#/definitions/ScheduleItem',
        schemaKey: 'ScheduleItem'
      }
    }, 'getSpaceScheduleSpacesActivitiesWithItemIdRequest', '/wop-api'),
    actions: {
      start: {
        type: DATA_GET_SPACE_SCHEDULE_SPACES_ACTIVITIES_WITH_ITEMID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_SPACE_SCHEDULE_SPACES_ACTIVITIES_WITH_ITEMID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_SPACE_SCHEDULE_SPACES_ACTIVITIES_WITH_ITEMID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getSpaceScheduleSpacesActivitiesWithItemIdRequestDefinitions = (definitionsArguments: {| requestArgs: getSpaceScheduleSpacesActivitiesWithItemIdRequestWopArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getSpaceScheduleSpacesActivitiesWithItemIdRequestWopArguments, payload?: Object |} => ({
  fnName: 'getSpaceScheduleSpacesActivitiesWithItemIdRequest',
  basePath: '/wop-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_GET_SPACE_SCHEDULE_SPACES_ACTIVITIES_COLUMNS_WITH_ITEMID_START = 'DATA_GET_SPACE_SCHEDULE_SPACES_ACTIVITIES_COLUMNS_WITH_ITEMID_START'
export const DATA_GET_SPACE_SCHEDULE_SPACES_ACTIVITIES_COLUMNS_WITH_ITEMID_SUCCESSFUL = 'DATA_GET_SPACE_SCHEDULE_SPACES_ACTIVITIES_COLUMNS_WITH_ITEMID_SUCCESSFUL'
export const DATA_GET_SPACE_SCHEDULE_SPACES_ACTIVITIES_COLUMNS_WITH_ITEMID_FAIL = 'DATA_GET_SPACE_SCHEDULE_SPACES_ACTIVITIES_COLUMNS_WITH_ITEMID_FAIL'

// Returns columns for activity schedule of a space
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/wop.yaml#/Spaces/get_space_schedule__estimateId__spaces__itemId__activities_columns
export const getSpaceScheduleSpacesActivitiesColumnsWithItemIdRequest = (
  requestArguments: getSpaceScheduleSpacesActivitiesColumnsWithItemIdRequestWopArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_SPACE_SCHEDULE_SPACES_ACTIVITIES_COLUMNS_WITH_ITEMID_START',
      successful: 'DATA_GET_SPACE_SCHEDULE_SPACES_ACTIVITIES_COLUMNS_WITH_ITEMID_SUCCESSFUL',
      fail: 'DATA_GET_SPACE_SCHEDULE_SPACES_ACTIVITIES_COLUMNS_WITH_ITEMID_FAIL'
    },
    embeddedType: {
      columns: {
        schemaPath: '#/definitions/ValueProperty',
        schemaKey: 'ValueProperty'
      }
    },
    fnName: 'getSpaceScheduleSpacesActivitiesColumnsWithItemIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/wop-api`,
    method: 'GET',
    url: `/space-schedule/${options?.estimateId || store.app.calculation}/spaces/${requestArguments.path['itemId']}/activities/columns`,
    params: {
      processId: requestArguments.query?.['processId'],
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      columns: {
        schemaPath: '#/definitions/ValueProperty',
        schemaKey: 'ValueProperty'
      }
    }, 'getSpaceScheduleSpacesActivitiesColumnsWithItemIdRequest', '/wop-api'),
    actions: {
      start: {
        type: DATA_GET_SPACE_SCHEDULE_SPACES_ACTIVITIES_COLUMNS_WITH_ITEMID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_SPACE_SCHEDULE_SPACES_ACTIVITIES_COLUMNS_WITH_ITEMID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_SPACE_SCHEDULE_SPACES_ACTIVITIES_COLUMNS_WITH_ITEMID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getSpaceScheduleSpacesActivitiesColumnsWithItemIdRequestDefinitions = (definitionsArguments: {| requestArgs: getSpaceScheduleSpacesActivitiesColumnsWithItemIdRequestWopArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getSpaceScheduleSpacesActivitiesColumnsWithItemIdRequestWopArguments, payload?: Object |} => ({
  fnName: 'getSpaceScheduleSpacesActivitiesColumnsWithItemIdRequest',
  basePath: '/wop-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_GET_ACTIVITY_SCHEDULE_WITH_ESTIMATEID_START = 'DATA_GET_ACTIVITY_SCHEDULE_WITH_ESTIMATEID_START'
export const DATA_GET_ACTIVITY_SCHEDULE_WITH_ESTIMATEID_SUCCESSFUL = 'DATA_GET_ACTIVITY_SCHEDULE_WITH_ESTIMATEID_SUCCESSFUL'
export const DATA_GET_ACTIVITY_SCHEDULE_WITH_ESTIMATEID_FAIL = 'DATA_GET_ACTIVITY_SCHEDULE_WITH_ESTIMATEID_FAIL'

// Returns activity schedule root level or full if given listType=flat
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/wop.yaml#/Activity%20Schedule/get_activity_schedule__estimateId_
export const getActivityScheduleWithEstimateIdRequest = (
  requestArguments: getActivityScheduleWithEstimateIdRequestWopArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_ACTIVITY_SCHEDULE_WITH_ESTIMATEID_START',
      successful: 'DATA_GET_ACTIVITY_SCHEDULE_WITH_ESTIMATEID_SUCCESSFUL',
      fail: 'DATA_GET_ACTIVITY_SCHEDULE_WITH_ESTIMATEID_FAIL'
    },
    embeddedType: {
      items: {
        schemaPath: '#/definitions/ActivityScheduleItem',
        schemaKey: 'ActivityScheduleItem'
      }
    },
    fnName: 'getActivityScheduleWithEstimateIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/wop-api`,
    method: 'GET',
    url: `/activity-schedule/${options?.estimateId || store.app.calculation}`,
    params: {
      listType: requestArguments.query['listType'],
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      items: {
        schemaPath: '#/definitions/ActivityScheduleItem',
        schemaKey: 'ActivityScheduleItem'
      }
    }, 'getActivityScheduleWithEstimateIdRequest', '/wop-api'),
    actions: {
      start: {
        type: DATA_GET_ACTIVITY_SCHEDULE_WITH_ESTIMATEID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_ACTIVITY_SCHEDULE_WITH_ESTIMATEID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_ACTIVITY_SCHEDULE_WITH_ESTIMATEID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getActivityScheduleWithEstimateIdRequestDefinitions = (definitionsArguments: {| requestArgs: getActivityScheduleWithEstimateIdRequestWopArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getActivityScheduleWithEstimateIdRequestWopArguments, payload?: Object |} => ({
  fnName: 'getActivityScheduleWithEstimateIdRequest',
  basePath: '/wop-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_GET_ACTIVITY_SCHEDULE_COLUMNS_WITH_ESTIMATEID_START = 'DATA_GET_ACTIVITY_SCHEDULE_COLUMNS_WITH_ESTIMATEID_START'
export const DATA_GET_ACTIVITY_SCHEDULE_COLUMNS_WITH_ESTIMATEID_SUCCESSFUL = 'DATA_GET_ACTIVITY_SCHEDULE_COLUMNS_WITH_ESTIMATEID_SUCCESSFUL'
export const DATA_GET_ACTIVITY_SCHEDULE_COLUMNS_WITH_ESTIMATEID_FAIL = 'DATA_GET_ACTIVITY_SCHEDULE_COLUMNS_WITH_ESTIMATEID_FAIL'

// Get possible columns for activity schedule
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/wop.yaml#/Activity%20Schedule/get_activity_schedule__estimateId__columns
export const getActivityScheduleColumnsWithEstimateIdRequest = (
  payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_ACTIVITY_SCHEDULE_COLUMNS_WITH_ESTIMATEID_START',
      successful: 'DATA_GET_ACTIVITY_SCHEDULE_COLUMNS_WITH_ESTIMATEID_SUCCESSFUL',
      fail: 'DATA_GET_ACTIVITY_SCHEDULE_COLUMNS_WITH_ESTIMATEID_FAIL'
    },
    embeddedType: {
      properties: {
        schemaPath: '#/definitions/ValueProperty',
        schemaKey: 'ValueProperty'
      }
    },
    fnName: 'getActivityScheduleColumnsWithEstimateIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/wop-api`,
    method: 'GET',
    url: `/activity-schedule/${options?.estimateId || store.app.calculation}/columns`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      properties: {
        schemaPath: '#/definitions/ValueProperty',
        schemaKey: 'ValueProperty'
      }
    }, 'getActivityScheduleColumnsWithEstimateIdRequest', '/wop-api'),
    actions: {
      start: {
        type: DATA_GET_ACTIVITY_SCHEDULE_COLUMNS_WITH_ESTIMATEID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_ACTIVITY_SCHEDULE_COLUMNS_WITH_ESTIMATEID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_ACTIVITY_SCHEDULE_COLUMNS_WITH_ESTIMATEID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getActivityScheduleColumnsWithEstimateIdRequestDefinitions = (definitionsArguments: {| payload?: Object |}): {|
  ...TVDGARConfigWithoutRequestArgs, payload?: Object |} => ({
  fnName: 'getActivityScheduleColumnsWithEstimateIdRequest',
  basePath: '/wop-api',
  payload: definitionsArguments.payload,
})


export const DATA_POST_ACTIVITY_SCHEDULE_FUNCTIONAL_SECTORS_WITH_ESTIMATEID_START = 'DATA_POST_ACTIVITY_SCHEDULE_FUNCTIONAL_SECTORS_WITH_ESTIMATEID_START'
export const DATA_POST_ACTIVITY_SCHEDULE_FUNCTIONAL_SECTORS_WITH_ESTIMATEID_SUCCESSFUL = 'DATA_POST_ACTIVITY_SCHEDULE_FUNCTIONAL_SECTORS_WITH_ESTIMATEID_SUCCESSFUL'
export const DATA_POST_ACTIVITY_SCHEDULE_FUNCTIONAL_SECTORS_WITH_ESTIMATEID_FAIL = 'DATA_POST_ACTIVITY_SCHEDULE_FUNCTIONAL_SECTORS_WITH_ESTIMATEID_FAIL'

// Adds a new functional sector to activity schedule. Only copying or adding via registryId is allowed.
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/wop.yaml#/Functional%20Sectors/post_activity_schedule__estimateId__functional_sectors
export const postActivityScheduleFunctionalSectorsWithEstimateIdRequest = (
  requestArguments: postActivityScheduleFunctionalSectorsWithEstimateIdRequestWopArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_POST_ACTIVITY_SCHEDULE_FUNCTIONAL_SECTORS_WITH_ESTIMATEID_START',
      successful: 'DATA_POST_ACTIVITY_SCHEDULE_FUNCTIONAL_SECTORS_WITH_ESTIMATEID_SUCCESSFUL',
      fail: 'DATA_POST_ACTIVITY_SCHEDULE_FUNCTIONAL_SECTORS_WITH_ESTIMATEID_FAIL'
    },
    embeddedType: null,
    fnName: 'postActivityScheduleFunctionalSectorsWithEstimateIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/wop-api`,
    method: 'POST',
    url: `/activity-schedule/${options?.estimateId || store.app.calculation}/functional-sectors`,
    params: {
      copyId: requestArguments.query?.['copyId'],
      registryId: requestArguments.query?.['registryId'],
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: requestArguments.body
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'postActivityScheduleFunctionalSectorsWithEstimateIdRequest', '/wop-api'),
    actions: {
      start: {
        type: DATA_POST_ACTIVITY_SCHEDULE_FUNCTIONAL_SECTORS_WITH_ESTIMATEID_START, payload, config, meta
      },
      successful: {
        type: DATA_POST_ACTIVITY_SCHEDULE_FUNCTIONAL_SECTORS_WITH_ESTIMATEID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_POST_ACTIVITY_SCHEDULE_FUNCTIONAL_SECTORS_WITH_ESTIMATEID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_GET_ACTIVITY_SCHEDULE_FUNCTIONAL_SECTORS_WITH_ITEMID_START = 'DATA_GET_ACTIVITY_SCHEDULE_FUNCTIONAL_SECTORS_WITH_ITEMID_START'
export const DATA_GET_ACTIVITY_SCHEDULE_FUNCTIONAL_SECTORS_WITH_ITEMID_SUCCESSFUL = 'DATA_GET_ACTIVITY_SCHEDULE_FUNCTIONAL_SECTORS_WITH_ITEMID_SUCCESSFUL'
export const DATA_GET_ACTIVITY_SCHEDULE_FUNCTIONAL_SECTORS_WITH_ITEMID_FAIL = 'DATA_GET_ACTIVITY_SCHEDULE_FUNCTIONAL_SECTORS_WITH_ITEMID_FAIL'

// Returns a functional sector for activity schedule
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/wop.yaml#/Functional%20Sectors/get_activity_schedule__estimateId__functional_sectors__itemId_
export const getActivityScheduleFunctionalSectorsWithItemIdRequest = (
  requestArguments: getActivityScheduleFunctionalSectorsWithItemIdRequestWopArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_ACTIVITY_SCHEDULE_FUNCTIONAL_SECTORS_WITH_ITEMID_START',
      successful: 'DATA_GET_ACTIVITY_SCHEDULE_FUNCTIONAL_SECTORS_WITH_ITEMID_SUCCESSFUL',
      fail: 'DATA_GET_ACTIVITY_SCHEDULE_FUNCTIONAL_SECTORS_WITH_ITEMID_FAIL'
    },
    embeddedType: {
      nonEmbedded: {
        schemaPath: '#/definitions/ActivityScheduleItem',
        schemaKey: 'ActivityScheduleItem'
      }
    },
    fnName: 'getActivityScheduleFunctionalSectorsWithItemIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/wop-api`,
    method: 'GET',
    url: `/activity-schedule/${options?.estimateId || store.app.calculation}/functional-sectors/${requestArguments.path['itemId']}`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      nonEmbedded: {
        schemaPath: '#/definitions/ActivityScheduleItem',
        schemaKey: 'ActivityScheduleItem'
      }
    }, 'getActivityScheduleFunctionalSectorsWithItemIdRequest', '/wop-api'),
    actions: {
      start: {
        type: DATA_GET_ACTIVITY_SCHEDULE_FUNCTIONAL_SECTORS_WITH_ITEMID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_ACTIVITY_SCHEDULE_FUNCTIONAL_SECTORS_WITH_ITEMID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_ACTIVITY_SCHEDULE_FUNCTIONAL_SECTORS_WITH_ITEMID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getActivityScheduleFunctionalSectorsWithItemIdRequestDefinitions = (definitionsArguments: {| requestArgs: getActivityScheduleFunctionalSectorsWithItemIdRequestWopArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getActivityScheduleFunctionalSectorsWithItemIdRequestWopArguments, payload?: Object |} => ({
  fnName: 'getActivityScheduleFunctionalSectorsWithItemIdRequest',
  basePath: '/wop-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_PATCH_ACTIVITY_SCHEDULE_FUNCTIONAL_SECTORS_WITH_ITEMID_START = 'DATA_PATCH_ACTIVITY_SCHEDULE_FUNCTIONAL_SECTORS_WITH_ITEMID_START'
export const DATA_PATCH_ACTIVITY_SCHEDULE_FUNCTIONAL_SECTORS_WITH_ITEMID_SUCCESSFUL = 'DATA_PATCH_ACTIVITY_SCHEDULE_FUNCTIONAL_SECTORS_WITH_ITEMID_SUCCESSFUL'
export const DATA_PATCH_ACTIVITY_SCHEDULE_FUNCTIONAL_SECTORS_WITH_ITEMID_FAIL = 'DATA_PATCH_ACTIVITY_SCHEDULE_FUNCTIONAL_SECTORS_WITH_ITEMID_FAIL'

// Update column data of a fuctional sector
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/wop.yaml#/Functional%20Sectors/patch_activity_schedule__estimateId__functional_sectors__itemId_
export const patchActivityScheduleFunctionalSectorsWithItemIdRequest = (
  requestArguments: patchActivityScheduleFunctionalSectorsWithItemIdRequestWopArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_PATCH_ACTIVITY_SCHEDULE_FUNCTIONAL_SECTORS_WITH_ITEMID_START',
      successful: 'DATA_PATCH_ACTIVITY_SCHEDULE_FUNCTIONAL_SECTORS_WITH_ITEMID_SUCCESSFUL',
      fail: 'DATA_PATCH_ACTIVITY_SCHEDULE_FUNCTIONAL_SECTORS_WITH_ITEMID_FAIL'
    },
    embeddedType: null,
    fnName: 'patchActivityScheduleFunctionalSectorsWithItemIdRequest'
  }
  const config = {
    headers: { 'Content-Type': 'application/json' },
    baseURL: `${API_BASE_URL}/wop-api`,
    method: 'PATCH',
    url: `/activity-schedule/${options?.estimateId || store.app.calculation}/functional-sectors/${requestArguments.path['itemId']}`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: requestArguments.body
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'patchActivityScheduleFunctionalSectorsWithItemIdRequest', '/wop-api'),
    actions: {
      start: {
        type: DATA_PATCH_ACTIVITY_SCHEDULE_FUNCTIONAL_SECTORS_WITH_ITEMID_START, payload, config, meta
      },
      successful: {
        type: DATA_PATCH_ACTIVITY_SCHEDULE_FUNCTIONAL_SECTORS_WITH_ITEMID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_PATCH_ACTIVITY_SCHEDULE_FUNCTIONAL_SECTORS_WITH_ITEMID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_DELETE_ACTIVITY_SCHEDULE_FUNCTIONAL_SECTORS_WITH_ITEMID_START = 'DATA_DELETE_ACTIVITY_SCHEDULE_FUNCTIONAL_SECTORS_WITH_ITEMID_START'
export const DATA_DELETE_ACTIVITY_SCHEDULE_FUNCTIONAL_SECTORS_WITH_ITEMID_SUCCESSFUL = 'DATA_DELETE_ACTIVITY_SCHEDULE_FUNCTIONAL_SECTORS_WITH_ITEMID_SUCCESSFUL'
export const DATA_DELETE_ACTIVITY_SCHEDULE_FUNCTIONAL_SECTORS_WITH_ITEMID_FAIL = 'DATA_DELETE_ACTIVITY_SCHEDULE_FUNCTIONAL_SECTORS_WITH_ITEMID_FAIL'

// Delete a functional sector
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/wop.yaml#/Functional%20Sectors/delete_activity_schedule__estimateId__functional_sectors__itemId_
export const deleteActivityScheduleFunctionalSectorsWithItemIdRequest = (
  requestArguments: deleteActivityScheduleFunctionalSectorsWithItemIdRequestWopArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_DELETE_ACTIVITY_SCHEDULE_FUNCTIONAL_SECTORS_WITH_ITEMID_START',
      successful: 'DATA_DELETE_ACTIVITY_SCHEDULE_FUNCTIONAL_SECTORS_WITH_ITEMID_SUCCESSFUL',
      fail: 'DATA_DELETE_ACTIVITY_SCHEDULE_FUNCTIONAL_SECTORS_WITH_ITEMID_FAIL'
    },
    embeddedType: null,
    fnName: 'deleteActivityScheduleFunctionalSectorsWithItemIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/wop-api`,
    method: 'DELETE',
    url: `/activity-schedule/${options?.estimateId || store.app.calculation}/functional-sectors/${requestArguments.path['itemId']}`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'deleteActivityScheduleFunctionalSectorsWithItemIdRequest', '/wop-api'),
    actions: {
      start: {
        type: DATA_DELETE_ACTIVITY_SCHEDULE_FUNCTIONAL_SECTORS_WITH_ITEMID_START, payload, config, meta
      },
      successful: {
        type: DATA_DELETE_ACTIVITY_SCHEDULE_FUNCTIONAL_SECTORS_WITH_ITEMID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_DELETE_ACTIVITY_SCHEDULE_FUNCTIONAL_SECTORS_WITH_ITEMID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_GET_ACTIVITY_SCHEDULE_FUNCTIONAL_SECTORS_FUNCTIONS_WITH_ITEMID_START = 'DATA_GET_ACTIVITY_SCHEDULE_FUNCTIONAL_SECTORS_FUNCTIONS_WITH_ITEMID_START'
export const DATA_GET_ACTIVITY_SCHEDULE_FUNCTIONAL_SECTORS_FUNCTIONS_WITH_ITEMID_SUCCESSFUL = 'DATA_GET_ACTIVITY_SCHEDULE_FUNCTIONAL_SECTORS_FUNCTIONS_WITH_ITEMID_SUCCESSFUL'
export const DATA_GET_ACTIVITY_SCHEDULE_FUNCTIONAL_SECTORS_FUNCTIONS_WITH_ITEMID_FAIL = 'DATA_GET_ACTIVITY_SCHEDULE_FUNCTIONAL_SECTORS_FUNCTIONS_WITH_ITEMID_FAIL'

// Returns functions of a functional sector
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/wop.yaml#/Functional%20Sectors/get_activity_schedule__estimateId__functional_sectors__itemId__functions
export const getActivityScheduleFunctionalSectorsFunctionsWithItemIdRequest = (
  requestArguments: getActivityScheduleFunctionalSectorsFunctionsWithItemIdRequestWopArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_ACTIVITY_SCHEDULE_FUNCTIONAL_SECTORS_FUNCTIONS_WITH_ITEMID_START',
      successful: 'DATA_GET_ACTIVITY_SCHEDULE_FUNCTIONAL_SECTORS_FUNCTIONS_WITH_ITEMID_SUCCESSFUL',
      fail: 'DATA_GET_ACTIVITY_SCHEDULE_FUNCTIONAL_SECTORS_FUNCTIONS_WITH_ITEMID_FAIL'
    },
    embeddedType: {
      items: {
        schemaPath: '#/definitions/ActivityScheduleItem',
        schemaKey: 'ActivityScheduleItem'
      }
    },
    fnName: 'getActivityScheduleFunctionalSectorsFunctionsWithItemIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/wop-api`,
    method: 'GET',
    url: `/activity-schedule/${options?.estimateId || store.app.calculation}/functional-sectors/${requestArguments.path['itemId']}/functions`,
    params: {
      listType: requestArguments.query?.['listType'],
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      items: {
        schemaPath: '#/definitions/ActivityScheduleItem',
        schemaKey: 'ActivityScheduleItem'
      }
    }, 'getActivityScheduleFunctionalSectorsFunctionsWithItemIdRequest', '/wop-api'),
    actions: {
      start: {
        type: DATA_GET_ACTIVITY_SCHEDULE_FUNCTIONAL_SECTORS_FUNCTIONS_WITH_ITEMID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_ACTIVITY_SCHEDULE_FUNCTIONAL_SECTORS_FUNCTIONS_WITH_ITEMID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_ACTIVITY_SCHEDULE_FUNCTIONAL_SECTORS_FUNCTIONS_WITH_ITEMID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getActivityScheduleFunctionalSectorsFunctionsWithItemIdRequestDefinitions = (definitionsArguments: {| requestArgs: getActivityScheduleFunctionalSectorsFunctionsWithItemIdRequestWopArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getActivityScheduleFunctionalSectorsFunctionsWithItemIdRequestWopArguments, payload?: Object |} => ({
  fnName: 'getActivityScheduleFunctionalSectorsFunctionsWithItemIdRequest',
  basePath: '/wop-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_GET_ACTIVITY_SCHEDULE_FUNCTIONS_WITH_ITEMID_START = 'DATA_GET_ACTIVITY_SCHEDULE_FUNCTIONS_WITH_ITEMID_START'
export const DATA_GET_ACTIVITY_SCHEDULE_FUNCTIONS_WITH_ITEMID_SUCCESSFUL = 'DATA_GET_ACTIVITY_SCHEDULE_FUNCTIONS_WITH_ITEMID_SUCCESSFUL'
export const DATA_GET_ACTIVITY_SCHEDULE_FUNCTIONS_WITH_ITEMID_FAIL = 'DATA_GET_ACTIVITY_SCHEDULE_FUNCTIONS_WITH_ITEMID_FAIL'

// Returns a function for activity schedule
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/wop.yaml#/Functions/get_activity_schedule__estimateId__functions__itemId_
export const getActivityScheduleFunctionsWithItemIdRequest = (
  requestArguments: getActivityScheduleFunctionsWithItemIdRequestWopArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_ACTIVITY_SCHEDULE_FUNCTIONS_WITH_ITEMID_START',
      successful: 'DATA_GET_ACTIVITY_SCHEDULE_FUNCTIONS_WITH_ITEMID_SUCCESSFUL',
      fail: 'DATA_GET_ACTIVITY_SCHEDULE_FUNCTIONS_WITH_ITEMID_FAIL'
    },
    embeddedType: {
      nonEmbedded: {
        schemaPath: '#/definitions/ActivityScheduleItem',
        schemaKey: 'ActivityScheduleItem'
      }
    },
    fnName: 'getActivityScheduleFunctionsWithItemIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/wop-api`,
    method: 'GET',
    url: `/activity-schedule/${options?.estimateId || store.app.calculation}/functions/${requestArguments.path['itemId']}`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      nonEmbedded: {
        schemaPath: '#/definitions/ActivityScheduleItem',
        schemaKey: 'ActivityScheduleItem'
      }
    }, 'getActivityScheduleFunctionsWithItemIdRequest', '/wop-api'),
    actions: {
      start: {
        type: DATA_GET_ACTIVITY_SCHEDULE_FUNCTIONS_WITH_ITEMID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_ACTIVITY_SCHEDULE_FUNCTIONS_WITH_ITEMID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_ACTIVITY_SCHEDULE_FUNCTIONS_WITH_ITEMID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getActivityScheduleFunctionsWithItemIdRequestDefinitions = (definitionsArguments: {| requestArgs: getActivityScheduleFunctionsWithItemIdRequestWopArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getActivityScheduleFunctionsWithItemIdRequestWopArguments, payload?: Object |} => ({
  fnName: 'getActivityScheduleFunctionsWithItemIdRequest',
  basePath: '/wop-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_POST_ACTIVITY_SCHEDULE_FUNCTIONS_WITH_ITEMID_START = 'DATA_POST_ACTIVITY_SCHEDULE_FUNCTIONS_WITH_ITEMID_START'
export const DATA_POST_ACTIVITY_SCHEDULE_FUNCTIONS_WITH_ITEMID_SUCCESSFUL = 'DATA_POST_ACTIVITY_SCHEDULE_FUNCTIONS_WITH_ITEMID_SUCCESSFUL'
export const DATA_POST_ACTIVITY_SCHEDULE_FUNCTIONS_WITH_ITEMID_FAIL = 'DATA_POST_ACTIVITY_SCHEDULE_FUNCTIONS_WITH_ITEMID_FAIL'

// Adds a new function to activity schedule. Only copying or adding via registryId is allowed.
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/wop.yaml#/Functions/post_activity_schedule__estimateId__functions__itemId_
export const postActivityScheduleFunctionsWithItemIdRequest = (
  requestArguments: postActivityScheduleFunctionsWithItemIdRequestWopArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_POST_ACTIVITY_SCHEDULE_FUNCTIONS_WITH_ITEMID_START',
      successful: 'DATA_POST_ACTIVITY_SCHEDULE_FUNCTIONS_WITH_ITEMID_SUCCESSFUL',
      fail: 'DATA_POST_ACTIVITY_SCHEDULE_FUNCTIONS_WITH_ITEMID_FAIL'
    },
    embeddedType: null,
    fnName: 'postActivityScheduleFunctionsWithItemIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/wop-api`,
    method: 'POST',
    url: `/activity-schedule/${options?.estimateId || store.app.calculation}/functions/${requestArguments.path['itemId']}`,
    params: {
      copyId: requestArguments.query?.['copyId'],
      registryId: requestArguments.query?.['registryId'],
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: requestArguments.body
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'postActivityScheduleFunctionsWithItemIdRequest', '/wop-api'),
    actions: {
      start: {
        type: DATA_POST_ACTIVITY_SCHEDULE_FUNCTIONS_WITH_ITEMID_START, payload, config, meta
      },
      successful: {
        type: DATA_POST_ACTIVITY_SCHEDULE_FUNCTIONS_WITH_ITEMID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_POST_ACTIVITY_SCHEDULE_FUNCTIONS_WITH_ITEMID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_PATCH_ACTIVITY_SCHEDULE_FUNCTIONS_WITH_ITEMID_START = 'DATA_PATCH_ACTIVITY_SCHEDULE_FUNCTIONS_WITH_ITEMID_START'
export const DATA_PATCH_ACTIVITY_SCHEDULE_FUNCTIONS_WITH_ITEMID_SUCCESSFUL = 'DATA_PATCH_ACTIVITY_SCHEDULE_FUNCTIONS_WITH_ITEMID_SUCCESSFUL'
export const DATA_PATCH_ACTIVITY_SCHEDULE_FUNCTIONS_WITH_ITEMID_FAIL = 'DATA_PATCH_ACTIVITY_SCHEDULE_FUNCTIONS_WITH_ITEMID_FAIL'

// Update column data of a function
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/wop.yaml#/Functions/patch_activity_schedule__estimateId__functions__itemId_
export const patchActivityScheduleFunctionsWithItemIdRequest = (
  requestArguments: patchActivityScheduleFunctionsWithItemIdRequestWopArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_PATCH_ACTIVITY_SCHEDULE_FUNCTIONS_WITH_ITEMID_START',
      successful: 'DATA_PATCH_ACTIVITY_SCHEDULE_FUNCTIONS_WITH_ITEMID_SUCCESSFUL',
      fail: 'DATA_PATCH_ACTIVITY_SCHEDULE_FUNCTIONS_WITH_ITEMID_FAIL'
    },
    embeddedType: null,
    fnName: 'patchActivityScheduleFunctionsWithItemIdRequest'
  }
  const config = {
    headers: { 'Content-Type': 'application/json' },
    baseURL: `${API_BASE_URL}/wop-api`,
    method: 'PATCH',
    url: `/activity-schedule/${options?.estimateId || store.app.calculation}/functions/${requestArguments.path['itemId']}`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: requestArguments.body
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'patchActivityScheduleFunctionsWithItemIdRequest', '/wop-api'),
    actions: {
      start: {
        type: DATA_PATCH_ACTIVITY_SCHEDULE_FUNCTIONS_WITH_ITEMID_START, payload, config, meta
      },
      successful: {
        type: DATA_PATCH_ACTIVITY_SCHEDULE_FUNCTIONS_WITH_ITEMID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_PATCH_ACTIVITY_SCHEDULE_FUNCTIONS_WITH_ITEMID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_DELETE_ACTIVITY_SCHEDULE_FUNCTIONS_WITH_ITEMID_START = 'DATA_DELETE_ACTIVITY_SCHEDULE_FUNCTIONS_WITH_ITEMID_START'
export const DATA_DELETE_ACTIVITY_SCHEDULE_FUNCTIONS_WITH_ITEMID_SUCCESSFUL = 'DATA_DELETE_ACTIVITY_SCHEDULE_FUNCTIONS_WITH_ITEMID_SUCCESSFUL'
export const DATA_DELETE_ACTIVITY_SCHEDULE_FUNCTIONS_WITH_ITEMID_FAIL = 'DATA_DELETE_ACTIVITY_SCHEDULE_FUNCTIONS_WITH_ITEMID_FAIL'

// Delete a function
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/wop.yaml#/Functions/delete_activity_schedule__estimateId__functions__itemId_
export const deleteActivityScheduleFunctionsWithItemIdRequest = (
  requestArguments: deleteActivityScheduleFunctionsWithItemIdRequestWopArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_DELETE_ACTIVITY_SCHEDULE_FUNCTIONS_WITH_ITEMID_START',
      successful: 'DATA_DELETE_ACTIVITY_SCHEDULE_FUNCTIONS_WITH_ITEMID_SUCCESSFUL',
      fail: 'DATA_DELETE_ACTIVITY_SCHEDULE_FUNCTIONS_WITH_ITEMID_FAIL'
    },
    embeddedType: null,
    fnName: 'deleteActivityScheduleFunctionsWithItemIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/wop-api`,
    method: 'DELETE',
    url: `/activity-schedule/${options?.estimateId || store.app.calculation}/functions/${requestArguments.path['itemId']}`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'deleteActivityScheduleFunctionsWithItemIdRequest', '/wop-api'),
    actions: {
      start: {
        type: DATA_DELETE_ACTIVITY_SCHEDULE_FUNCTIONS_WITH_ITEMID_START, payload, config, meta
      },
      successful: {
        type: DATA_DELETE_ACTIVITY_SCHEDULE_FUNCTIONS_WITH_ITEMID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_DELETE_ACTIVITY_SCHEDULE_FUNCTIONS_WITH_ITEMID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_GET_ACTIVITY_SCHEDULE_FUNCTIONS_PROCESSES_WITH_ITEMID_START = 'DATA_GET_ACTIVITY_SCHEDULE_FUNCTIONS_PROCESSES_WITH_ITEMID_START'
export const DATA_GET_ACTIVITY_SCHEDULE_FUNCTIONS_PROCESSES_WITH_ITEMID_SUCCESSFUL = 'DATA_GET_ACTIVITY_SCHEDULE_FUNCTIONS_PROCESSES_WITH_ITEMID_SUCCESSFUL'
export const DATA_GET_ACTIVITY_SCHEDULE_FUNCTIONS_PROCESSES_WITH_ITEMID_FAIL = 'DATA_GET_ACTIVITY_SCHEDULE_FUNCTIONS_PROCESSES_WITH_ITEMID_FAIL'

// Returns processes of a function
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/wop.yaml#/Functions/get_activity_schedule__estimateId__functions__itemId__processes
export const getActivityScheduleFunctionsProcessesWithItemIdRequest = (
  requestArguments: getActivityScheduleFunctionsProcessesWithItemIdRequestWopArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_ACTIVITY_SCHEDULE_FUNCTIONS_PROCESSES_WITH_ITEMID_START',
      successful: 'DATA_GET_ACTIVITY_SCHEDULE_FUNCTIONS_PROCESSES_WITH_ITEMID_SUCCESSFUL',
      fail: 'DATA_GET_ACTIVITY_SCHEDULE_FUNCTIONS_PROCESSES_WITH_ITEMID_FAIL'
    },
    embeddedType: {
      items: {
        schemaPath: '#/definitions/ActivityScheduleItem',
        schemaKey: 'ActivityScheduleItem'
      }
    },
    fnName: 'getActivityScheduleFunctionsProcessesWithItemIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/wop-api`,
    method: 'GET',
    url: `/activity-schedule/${options?.estimateId || store.app.calculation}/functions/${requestArguments.path['itemId']}/processes`,
    params: {
      listType: requestArguments.query?.['listType'],
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      items: {
        schemaPath: '#/definitions/ActivityScheduleItem',
        schemaKey: 'ActivityScheduleItem'
      }
    }, 'getActivityScheduleFunctionsProcessesWithItemIdRequest', '/wop-api'),
    actions: {
      start: {
        type: DATA_GET_ACTIVITY_SCHEDULE_FUNCTIONS_PROCESSES_WITH_ITEMID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_ACTIVITY_SCHEDULE_FUNCTIONS_PROCESSES_WITH_ITEMID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_ACTIVITY_SCHEDULE_FUNCTIONS_PROCESSES_WITH_ITEMID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getActivityScheduleFunctionsProcessesWithItemIdRequestDefinitions = (definitionsArguments: {| requestArgs: getActivityScheduleFunctionsProcessesWithItemIdRequestWopArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getActivityScheduleFunctionsProcessesWithItemIdRequestWopArguments, payload?: Object |} => ({
  fnName: 'getActivityScheduleFunctionsProcessesWithItemIdRequest',
  basePath: '/wop-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_GET_ACTIVITY_SCHEDULE_PROCESSES_WITH_ITEMID_START = 'DATA_GET_ACTIVITY_SCHEDULE_PROCESSES_WITH_ITEMID_START'
export const DATA_GET_ACTIVITY_SCHEDULE_PROCESSES_WITH_ITEMID_SUCCESSFUL = 'DATA_GET_ACTIVITY_SCHEDULE_PROCESSES_WITH_ITEMID_SUCCESSFUL'
export const DATA_GET_ACTIVITY_SCHEDULE_PROCESSES_WITH_ITEMID_FAIL = 'DATA_GET_ACTIVITY_SCHEDULE_PROCESSES_WITH_ITEMID_FAIL'

// Returns a process for activity schedule
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/wop.yaml#/Processes/get_activity_schedule__estimateId__processes__itemId_
export const getActivityScheduleProcessesWithItemIdRequest = (
  requestArguments: getActivityScheduleProcessesWithItemIdRequestWopArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_ACTIVITY_SCHEDULE_PROCESSES_WITH_ITEMID_START',
      successful: 'DATA_GET_ACTIVITY_SCHEDULE_PROCESSES_WITH_ITEMID_SUCCESSFUL',
      fail: 'DATA_GET_ACTIVITY_SCHEDULE_PROCESSES_WITH_ITEMID_FAIL'
    },
    embeddedType: {
      nonEmbedded: {
        schemaPath: '#/definitions/ActivityScheduleItem',
        schemaKey: 'ActivityScheduleItem'
      }
    },
    fnName: 'getActivityScheduleProcessesWithItemIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/wop-api`,
    method: 'GET',
    url: `/activity-schedule/${options?.estimateId || store.app.calculation}/processes/${requestArguments.path['itemId']}`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      nonEmbedded: {
        schemaPath: '#/definitions/ActivityScheduleItem',
        schemaKey: 'ActivityScheduleItem'
      }
    }, 'getActivityScheduleProcessesWithItemIdRequest', '/wop-api'),
    actions: {
      start: {
        type: DATA_GET_ACTIVITY_SCHEDULE_PROCESSES_WITH_ITEMID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_ACTIVITY_SCHEDULE_PROCESSES_WITH_ITEMID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_ACTIVITY_SCHEDULE_PROCESSES_WITH_ITEMID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getActivityScheduleProcessesWithItemIdRequestDefinitions = (definitionsArguments: {| requestArgs: getActivityScheduleProcessesWithItemIdRequestWopArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getActivityScheduleProcessesWithItemIdRequestWopArguments, payload?: Object |} => ({
  fnName: 'getActivityScheduleProcessesWithItemIdRequest',
  basePath: '/wop-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_POST_ACTIVITY_SCHEDULE_PROCESSES_WITH_ITEMID_START = 'DATA_POST_ACTIVITY_SCHEDULE_PROCESSES_WITH_ITEMID_START'
export const DATA_POST_ACTIVITY_SCHEDULE_PROCESSES_WITH_ITEMID_SUCCESSFUL = 'DATA_POST_ACTIVITY_SCHEDULE_PROCESSES_WITH_ITEMID_SUCCESSFUL'
export const DATA_POST_ACTIVITY_SCHEDULE_PROCESSES_WITH_ITEMID_FAIL = 'DATA_POST_ACTIVITY_SCHEDULE_PROCESSES_WITH_ITEMID_FAIL'

// Adds a new process to activity schedule. Only copying or adding via registryId is allowed.
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/wop.yaml#/Processes/post_activity_schedule__estimateId__processes__itemId_
export const postActivityScheduleProcessesWithItemIdRequest = (
  requestArguments: postActivityScheduleProcessesWithItemIdRequestWopArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_POST_ACTIVITY_SCHEDULE_PROCESSES_WITH_ITEMID_START',
      successful: 'DATA_POST_ACTIVITY_SCHEDULE_PROCESSES_WITH_ITEMID_SUCCESSFUL',
      fail: 'DATA_POST_ACTIVITY_SCHEDULE_PROCESSES_WITH_ITEMID_FAIL'
    },
    embeddedType: null,
    fnName: 'postActivityScheduleProcessesWithItemIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/wop-api`,
    method: 'POST',
    url: `/activity-schedule/${options?.estimateId || store.app.calculation}/processes/${requestArguments.path['itemId']}`,
    params: {
      copyId: requestArguments.query?.['copyId'],
      registryId: requestArguments.query?.['registryId'],
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: requestArguments.body
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'postActivityScheduleProcessesWithItemIdRequest', '/wop-api'),
    actions: {
      start: {
        type: DATA_POST_ACTIVITY_SCHEDULE_PROCESSES_WITH_ITEMID_START, payload, config, meta
      },
      successful: {
        type: DATA_POST_ACTIVITY_SCHEDULE_PROCESSES_WITH_ITEMID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_POST_ACTIVITY_SCHEDULE_PROCESSES_WITH_ITEMID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_PATCH_ACTIVITY_SCHEDULE_PROCESSES_WITH_ITEMID_START = 'DATA_PATCH_ACTIVITY_SCHEDULE_PROCESSES_WITH_ITEMID_START'
export const DATA_PATCH_ACTIVITY_SCHEDULE_PROCESSES_WITH_ITEMID_SUCCESSFUL = 'DATA_PATCH_ACTIVITY_SCHEDULE_PROCESSES_WITH_ITEMID_SUCCESSFUL'
export const DATA_PATCH_ACTIVITY_SCHEDULE_PROCESSES_WITH_ITEMID_FAIL = 'DATA_PATCH_ACTIVITY_SCHEDULE_PROCESSES_WITH_ITEMID_FAIL'

// Update column data of a process
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/wop.yaml#/Processes/patch_activity_schedule__estimateId__processes__itemId_
export const patchActivityScheduleProcessesWithItemIdRequest = (
  requestArguments: patchActivityScheduleProcessesWithItemIdRequestWopArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_PATCH_ACTIVITY_SCHEDULE_PROCESSES_WITH_ITEMID_START',
      successful: 'DATA_PATCH_ACTIVITY_SCHEDULE_PROCESSES_WITH_ITEMID_SUCCESSFUL',
      fail: 'DATA_PATCH_ACTIVITY_SCHEDULE_PROCESSES_WITH_ITEMID_FAIL'
    },
    embeddedType: null,
    fnName: 'patchActivityScheduleProcessesWithItemIdRequest'
  }
  const config = {
    headers: { 'Content-Type': 'application/json' },
    baseURL: `${API_BASE_URL}/wop-api`,
    method: 'PATCH',
    url: `/activity-schedule/${options?.estimateId || store.app.calculation}/processes/${requestArguments.path['itemId']}`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: requestArguments.body
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'patchActivityScheduleProcessesWithItemIdRequest', '/wop-api'),
    actions: {
      start: {
        type: DATA_PATCH_ACTIVITY_SCHEDULE_PROCESSES_WITH_ITEMID_START, payload, config, meta
      },
      successful: {
        type: DATA_PATCH_ACTIVITY_SCHEDULE_PROCESSES_WITH_ITEMID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_PATCH_ACTIVITY_SCHEDULE_PROCESSES_WITH_ITEMID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_DELETE_ACTIVITY_SCHEDULE_PROCESSES_WITH_ITEMID_START = 'DATA_DELETE_ACTIVITY_SCHEDULE_PROCESSES_WITH_ITEMID_START'
export const DATA_DELETE_ACTIVITY_SCHEDULE_PROCESSES_WITH_ITEMID_SUCCESSFUL = 'DATA_DELETE_ACTIVITY_SCHEDULE_PROCESSES_WITH_ITEMID_SUCCESSFUL'
export const DATA_DELETE_ACTIVITY_SCHEDULE_PROCESSES_WITH_ITEMID_FAIL = 'DATA_DELETE_ACTIVITY_SCHEDULE_PROCESSES_WITH_ITEMID_FAIL'

// Delete a process
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/wop.yaml#/Processes/delete_activity_schedule__estimateId__processes__itemId_
export const deleteActivityScheduleProcessesWithItemIdRequest = (
  requestArguments: deleteActivityScheduleProcessesWithItemIdRequestWopArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_DELETE_ACTIVITY_SCHEDULE_PROCESSES_WITH_ITEMID_START',
      successful: 'DATA_DELETE_ACTIVITY_SCHEDULE_PROCESSES_WITH_ITEMID_SUCCESSFUL',
      fail: 'DATA_DELETE_ACTIVITY_SCHEDULE_PROCESSES_WITH_ITEMID_FAIL'
    },
    embeddedType: null,
    fnName: 'deleteActivityScheduleProcessesWithItemIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/wop-api`,
    method: 'DELETE',
    url: `/activity-schedule/${options?.estimateId || store.app.calculation}/processes/${requestArguments.path['itemId']}`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'deleteActivityScheduleProcessesWithItemIdRequest', '/wop-api'),
    actions: {
      start: {
        type: DATA_DELETE_ACTIVITY_SCHEDULE_PROCESSES_WITH_ITEMID_START, payload, config, meta
      },
      successful: {
        type: DATA_DELETE_ACTIVITY_SCHEDULE_PROCESSES_WITH_ITEMID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_DELETE_ACTIVITY_SCHEDULE_PROCESSES_WITH_ITEMID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_GET_ACTIVITY_SCHEDULE_PROCESSES_ACTIVITY_GROUPS_WITH_ITEMID_START = 'DATA_GET_ACTIVITY_SCHEDULE_PROCESSES_ACTIVITY_GROUPS_WITH_ITEMID_START'
export const DATA_GET_ACTIVITY_SCHEDULE_PROCESSES_ACTIVITY_GROUPS_WITH_ITEMID_SUCCESSFUL = 'DATA_GET_ACTIVITY_SCHEDULE_PROCESSES_ACTIVITY_GROUPS_WITH_ITEMID_SUCCESSFUL'
export const DATA_GET_ACTIVITY_SCHEDULE_PROCESSES_ACTIVITY_GROUPS_WITH_ITEMID_FAIL = 'DATA_GET_ACTIVITY_SCHEDULE_PROCESSES_ACTIVITY_GROUPS_WITH_ITEMID_FAIL'

// Returns activity groups under a process
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/wop.yaml#/Processes/get_activity_schedule__estimateId__processes__itemId__activity_groups
export const getActivityScheduleProcessesActivityGroupsWithItemIdRequest = (
  requestArguments: getActivityScheduleProcessesActivityGroupsWithItemIdRequestWopArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_ACTIVITY_SCHEDULE_PROCESSES_ACTIVITY_GROUPS_WITH_ITEMID_START',
      successful: 'DATA_GET_ACTIVITY_SCHEDULE_PROCESSES_ACTIVITY_GROUPS_WITH_ITEMID_SUCCESSFUL',
      fail: 'DATA_GET_ACTIVITY_SCHEDULE_PROCESSES_ACTIVITY_GROUPS_WITH_ITEMID_FAIL'
    },
    embeddedType: {
      items: {
        schemaPath: '#/definitions/ActivityScheduleItem',
        schemaKey: 'ActivityScheduleItem'
      }
    },
    fnName: 'getActivityScheduleProcessesActivityGroupsWithItemIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/wop-api`,
    method: 'GET',
    url: `/activity-schedule/${options?.estimateId || store.app.calculation}/processes/${requestArguments.path['itemId']}/activity-groups`,
    params: {
      listType: requestArguments.query?.['listType'],
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      items: {
        schemaPath: '#/definitions/ActivityScheduleItem',
        schemaKey: 'ActivityScheduleItem'
      }
    }, 'getActivityScheduleProcessesActivityGroupsWithItemIdRequest', '/wop-api'),
    actions: {
      start: {
        type: DATA_GET_ACTIVITY_SCHEDULE_PROCESSES_ACTIVITY_GROUPS_WITH_ITEMID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_ACTIVITY_SCHEDULE_PROCESSES_ACTIVITY_GROUPS_WITH_ITEMID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_ACTIVITY_SCHEDULE_PROCESSES_ACTIVITY_GROUPS_WITH_ITEMID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getActivityScheduleProcessesActivityGroupsWithItemIdRequestDefinitions = (definitionsArguments: {| requestArgs: getActivityScheduleProcessesActivityGroupsWithItemIdRequestWopArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getActivityScheduleProcessesActivityGroupsWithItemIdRequestWopArguments, payload?: Object |} => ({
  fnName: 'getActivityScheduleProcessesActivityGroupsWithItemIdRequest',
  basePath: '/wop-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_GET_ACTIVITY_SCHEDULE_ACTIVITY_GROUPS_WITH_ITEMID_START = 'DATA_GET_ACTIVITY_SCHEDULE_ACTIVITY_GROUPS_WITH_ITEMID_START'
export const DATA_GET_ACTIVITY_SCHEDULE_ACTIVITY_GROUPS_WITH_ITEMID_SUCCESSFUL = 'DATA_GET_ACTIVITY_SCHEDULE_ACTIVITY_GROUPS_WITH_ITEMID_SUCCESSFUL'
export const DATA_GET_ACTIVITY_SCHEDULE_ACTIVITY_GROUPS_WITH_ITEMID_FAIL = 'DATA_GET_ACTIVITY_SCHEDULE_ACTIVITY_GROUPS_WITH_ITEMID_FAIL'

// Returns a activity group for activity schedule
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/wop.yaml#/Activity%20Groups/get_activity_schedule__estimateId__activity_groups__itemId_
export const getActivityScheduleActivityGroupsWithItemIdRequest = (
  requestArguments: getActivityScheduleActivityGroupsWithItemIdRequestWopArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_ACTIVITY_SCHEDULE_ACTIVITY_GROUPS_WITH_ITEMID_START',
      successful: 'DATA_GET_ACTIVITY_SCHEDULE_ACTIVITY_GROUPS_WITH_ITEMID_SUCCESSFUL',
      fail: 'DATA_GET_ACTIVITY_SCHEDULE_ACTIVITY_GROUPS_WITH_ITEMID_FAIL'
    },
    embeddedType: {
      nonEmbedded: {
        schemaPath: '#/definitions/ActivityScheduleItem',
        schemaKey: 'ActivityScheduleItem'
      }
    },
    fnName: 'getActivityScheduleActivityGroupsWithItemIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/wop-api`,
    method: 'GET',
    url: `/activity-schedule/${options?.estimateId || store.app.calculation}/activity-groups/${requestArguments.path['itemId']}`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      nonEmbedded: {
        schemaPath: '#/definitions/ActivityScheduleItem',
        schemaKey: 'ActivityScheduleItem'
      }
    }, 'getActivityScheduleActivityGroupsWithItemIdRequest', '/wop-api'),
    actions: {
      start: {
        type: DATA_GET_ACTIVITY_SCHEDULE_ACTIVITY_GROUPS_WITH_ITEMID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_ACTIVITY_SCHEDULE_ACTIVITY_GROUPS_WITH_ITEMID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_ACTIVITY_SCHEDULE_ACTIVITY_GROUPS_WITH_ITEMID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getActivityScheduleActivityGroupsWithItemIdRequestDefinitions = (definitionsArguments: {| requestArgs: getActivityScheduleActivityGroupsWithItemIdRequestWopArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getActivityScheduleActivityGroupsWithItemIdRequestWopArguments, payload?: Object |} => ({
  fnName: 'getActivityScheduleActivityGroupsWithItemIdRequest',
  basePath: '/wop-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_POST_ACTIVITY_SCHEDULE_ACTIVITY_GROUPS_WITH_ITEMID_START = 'DATA_POST_ACTIVITY_SCHEDULE_ACTIVITY_GROUPS_WITH_ITEMID_START'
export const DATA_POST_ACTIVITY_SCHEDULE_ACTIVITY_GROUPS_WITH_ITEMID_SUCCESSFUL = 'DATA_POST_ACTIVITY_SCHEDULE_ACTIVITY_GROUPS_WITH_ITEMID_SUCCESSFUL'
export const DATA_POST_ACTIVITY_SCHEDULE_ACTIVITY_GROUPS_WITH_ITEMID_FAIL = 'DATA_POST_ACTIVITY_SCHEDULE_ACTIVITY_GROUPS_WITH_ITEMID_FAIL'

// Adds a new activity group to activity schedule. Only copying or adding via registryId is allowed.
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/wop.yaml#/Activity%20Groups/post_activity_schedule__estimateId__activity_groups__itemId_
export const postActivityScheduleActivityGroupsWithItemIdRequest = (
  requestArguments: postActivityScheduleActivityGroupsWithItemIdRequestWopArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_POST_ACTIVITY_SCHEDULE_ACTIVITY_GROUPS_WITH_ITEMID_START',
      successful: 'DATA_POST_ACTIVITY_SCHEDULE_ACTIVITY_GROUPS_WITH_ITEMID_SUCCESSFUL',
      fail: 'DATA_POST_ACTIVITY_SCHEDULE_ACTIVITY_GROUPS_WITH_ITEMID_FAIL'
    },
    embeddedType: null,
    fnName: 'postActivityScheduleActivityGroupsWithItemIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/wop-api`,
    method: 'POST',
    url: `/activity-schedule/${options?.estimateId || store.app.calculation}/activity-groups/${requestArguments.path['itemId']}`,
    params: {
      copyId: requestArguments.query?.['copyId'],
      registryId: requestArguments.query?.['registryId'],
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: requestArguments.body
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'postActivityScheduleActivityGroupsWithItemIdRequest', '/wop-api'),
    actions: {
      start: {
        type: DATA_POST_ACTIVITY_SCHEDULE_ACTIVITY_GROUPS_WITH_ITEMID_START, payload, config, meta
      },
      successful: {
        type: DATA_POST_ACTIVITY_SCHEDULE_ACTIVITY_GROUPS_WITH_ITEMID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_POST_ACTIVITY_SCHEDULE_ACTIVITY_GROUPS_WITH_ITEMID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_PATCH_ACTIVITY_SCHEDULE_ACTIVITY_GROUPS_WITH_ITEMID_START = 'DATA_PATCH_ACTIVITY_SCHEDULE_ACTIVITY_GROUPS_WITH_ITEMID_START'
export const DATA_PATCH_ACTIVITY_SCHEDULE_ACTIVITY_GROUPS_WITH_ITEMID_SUCCESSFUL = 'DATA_PATCH_ACTIVITY_SCHEDULE_ACTIVITY_GROUPS_WITH_ITEMID_SUCCESSFUL'
export const DATA_PATCH_ACTIVITY_SCHEDULE_ACTIVITY_GROUPS_WITH_ITEMID_FAIL = 'DATA_PATCH_ACTIVITY_SCHEDULE_ACTIVITY_GROUPS_WITH_ITEMID_FAIL'

// Update column data of an activity group
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/wop.yaml#/Activity%20Groups/patch_activity_schedule__estimateId__activity_groups__itemId_
export const patchActivityScheduleActivityGroupsWithItemIdRequest = (
  requestArguments: patchActivityScheduleActivityGroupsWithItemIdRequestWopArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_PATCH_ACTIVITY_SCHEDULE_ACTIVITY_GROUPS_WITH_ITEMID_START',
      successful: 'DATA_PATCH_ACTIVITY_SCHEDULE_ACTIVITY_GROUPS_WITH_ITEMID_SUCCESSFUL',
      fail: 'DATA_PATCH_ACTIVITY_SCHEDULE_ACTIVITY_GROUPS_WITH_ITEMID_FAIL'
    },
    embeddedType: null,
    fnName: 'patchActivityScheduleActivityGroupsWithItemIdRequest'
  }
  const config = {
    headers: { 'Content-Type': 'application/json' },
    baseURL: `${API_BASE_URL}/wop-api`,
    method: 'PATCH',
    url: `/activity-schedule/${options?.estimateId || store.app.calculation}/activity-groups/${requestArguments.path['itemId']}`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: requestArguments.body
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'patchActivityScheduleActivityGroupsWithItemIdRequest', '/wop-api'),
    actions: {
      start: {
        type: DATA_PATCH_ACTIVITY_SCHEDULE_ACTIVITY_GROUPS_WITH_ITEMID_START, payload, config, meta
      },
      successful: {
        type: DATA_PATCH_ACTIVITY_SCHEDULE_ACTIVITY_GROUPS_WITH_ITEMID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_PATCH_ACTIVITY_SCHEDULE_ACTIVITY_GROUPS_WITH_ITEMID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_DELETE_ACTIVITY_SCHEDULE_ACTIVITY_GROUPS_WITH_ITEMID_START = 'DATA_DELETE_ACTIVITY_SCHEDULE_ACTIVITY_GROUPS_WITH_ITEMID_START'
export const DATA_DELETE_ACTIVITY_SCHEDULE_ACTIVITY_GROUPS_WITH_ITEMID_SUCCESSFUL = 'DATA_DELETE_ACTIVITY_SCHEDULE_ACTIVITY_GROUPS_WITH_ITEMID_SUCCESSFUL'
export const DATA_DELETE_ACTIVITY_SCHEDULE_ACTIVITY_GROUPS_WITH_ITEMID_FAIL = 'DATA_DELETE_ACTIVITY_SCHEDULE_ACTIVITY_GROUPS_WITH_ITEMID_FAIL'

// Delete an activity group
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/wop.yaml#/Activity%20Groups/delete_activity_schedule__estimateId__activity_groups__itemId_
export const deleteActivityScheduleActivityGroupsWithItemIdRequest = (
  requestArguments: deleteActivityScheduleActivityGroupsWithItemIdRequestWopArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_DELETE_ACTIVITY_SCHEDULE_ACTIVITY_GROUPS_WITH_ITEMID_START',
      successful: 'DATA_DELETE_ACTIVITY_SCHEDULE_ACTIVITY_GROUPS_WITH_ITEMID_SUCCESSFUL',
      fail: 'DATA_DELETE_ACTIVITY_SCHEDULE_ACTIVITY_GROUPS_WITH_ITEMID_FAIL'
    },
    embeddedType: null,
    fnName: 'deleteActivityScheduleActivityGroupsWithItemIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/wop-api`,
    method: 'DELETE',
    url: `/activity-schedule/${options?.estimateId || store.app.calculation}/activity-groups/${requestArguments.path['itemId']}`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'deleteActivityScheduleActivityGroupsWithItemIdRequest', '/wop-api'),
    actions: {
      start: {
        type: DATA_DELETE_ACTIVITY_SCHEDULE_ACTIVITY_GROUPS_WITH_ITEMID_START, payload, config, meta
      },
      successful: {
        type: DATA_DELETE_ACTIVITY_SCHEDULE_ACTIVITY_GROUPS_WITH_ITEMID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_DELETE_ACTIVITY_SCHEDULE_ACTIVITY_GROUPS_WITH_ITEMID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_GET_ACTIVITY_SCHEDULE_ACTIVITY_GROUPS_ACTIVITIES_WITH_ITEMID_START = 'DATA_GET_ACTIVITY_SCHEDULE_ACTIVITY_GROUPS_ACTIVITIES_WITH_ITEMID_START'
export const DATA_GET_ACTIVITY_SCHEDULE_ACTIVITY_GROUPS_ACTIVITIES_WITH_ITEMID_SUCCESSFUL = 'DATA_GET_ACTIVITY_SCHEDULE_ACTIVITY_GROUPS_ACTIVITIES_WITH_ITEMID_SUCCESSFUL'
export const DATA_GET_ACTIVITY_SCHEDULE_ACTIVITY_GROUPS_ACTIVITIES_WITH_ITEMID_FAIL = 'DATA_GET_ACTIVITY_SCHEDULE_ACTIVITY_GROUPS_ACTIVITIES_WITH_ITEMID_FAIL'

// Returns activities under a activity group
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/wop.yaml#/Activity%20Groups/get_activity_schedule__estimateId__activity_groups__itemId__activities
export const getActivityScheduleActivityGroupsActivitiesWithItemIdRequest = (
  requestArguments: getActivityScheduleActivityGroupsActivitiesWithItemIdRequestWopArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_ACTIVITY_SCHEDULE_ACTIVITY_GROUPS_ACTIVITIES_WITH_ITEMID_START',
      successful: 'DATA_GET_ACTIVITY_SCHEDULE_ACTIVITY_GROUPS_ACTIVITIES_WITH_ITEMID_SUCCESSFUL',
      fail: 'DATA_GET_ACTIVITY_SCHEDULE_ACTIVITY_GROUPS_ACTIVITIES_WITH_ITEMID_FAIL'
    },
    embeddedType: {
      items: {
        schemaPath: '#/definitions/ActivityScheduleItem',
        schemaKey: 'ActivityScheduleItem'
      }
    },
    fnName: 'getActivityScheduleActivityGroupsActivitiesWithItemIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/wop-api`,
    method: 'GET',
    url: `/activity-schedule/${options?.estimateId || store.app.calculation}/activity-groups/${requestArguments.path['itemId']}/activities`,
    params: {
      listType: requestArguments.query?.['listType'],
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      items: {
        schemaPath: '#/definitions/ActivityScheduleItem',
        schemaKey: 'ActivityScheduleItem'
      }
    }, 'getActivityScheduleActivityGroupsActivitiesWithItemIdRequest', '/wop-api'),
    actions: {
      start: {
        type: DATA_GET_ACTIVITY_SCHEDULE_ACTIVITY_GROUPS_ACTIVITIES_WITH_ITEMID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_ACTIVITY_SCHEDULE_ACTIVITY_GROUPS_ACTIVITIES_WITH_ITEMID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_ACTIVITY_SCHEDULE_ACTIVITY_GROUPS_ACTIVITIES_WITH_ITEMID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getActivityScheduleActivityGroupsActivitiesWithItemIdRequestDefinitions = (definitionsArguments: {| requestArgs: getActivityScheduleActivityGroupsActivitiesWithItemIdRequestWopArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getActivityScheduleActivityGroupsActivitiesWithItemIdRequestWopArguments, payload?: Object |} => ({
  fnName: 'getActivityScheduleActivityGroupsActivitiesWithItemIdRequest',
  basePath: '/wop-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_GET_ACTIVITY_SCHEDULE_ACTIVITIES_WITH_ITEMID_START = 'DATA_GET_ACTIVITY_SCHEDULE_ACTIVITIES_WITH_ITEMID_START'
export const DATA_GET_ACTIVITY_SCHEDULE_ACTIVITIES_WITH_ITEMID_SUCCESSFUL = 'DATA_GET_ACTIVITY_SCHEDULE_ACTIVITIES_WITH_ITEMID_SUCCESSFUL'
export const DATA_GET_ACTIVITY_SCHEDULE_ACTIVITIES_WITH_ITEMID_FAIL = 'DATA_GET_ACTIVITY_SCHEDULE_ACTIVITIES_WITH_ITEMID_FAIL'

// Returns a activity group for activity schedule
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/wop.yaml#/Activities/get_activity_schedule__estimateId__activities__itemId_
export const getActivityScheduleActivitiesWithItemIdRequest = (
  requestArguments: getActivityScheduleActivitiesWithItemIdRequestWopArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_ACTIVITY_SCHEDULE_ACTIVITIES_WITH_ITEMID_START',
      successful: 'DATA_GET_ACTIVITY_SCHEDULE_ACTIVITIES_WITH_ITEMID_SUCCESSFUL',
      fail: 'DATA_GET_ACTIVITY_SCHEDULE_ACTIVITIES_WITH_ITEMID_FAIL'
    },
    embeddedType: {
      nonEmbedded: {
        schemaPath: '#/definitions/ActivityScheduleItem',
        schemaKey: 'ActivityScheduleItem'
      }
    },
    fnName: 'getActivityScheduleActivitiesWithItemIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/wop-api`,
    method: 'GET',
    url: `/activity-schedule/${options?.estimateId || store.app.calculation}/activities/${requestArguments.path['itemId']}`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      nonEmbedded: {
        schemaPath: '#/definitions/ActivityScheduleItem',
        schemaKey: 'ActivityScheduleItem'
      }
    }, 'getActivityScheduleActivitiesWithItemIdRequest', '/wop-api'),
    actions: {
      start: {
        type: DATA_GET_ACTIVITY_SCHEDULE_ACTIVITIES_WITH_ITEMID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_ACTIVITY_SCHEDULE_ACTIVITIES_WITH_ITEMID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_ACTIVITY_SCHEDULE_ACTIVITIES_WITH_ITEMID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getActivityScheduleActivitiesWithItemIdRequestDefinitions = (definitionsArguments: {| requestArgs: getActivityScheduleActivitiesWithItemIdRequestWopArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getActivityScheduleActivitiesWithItemIdRequestWopArguments, payload?: Object |} => ({
  fnName: 'getActivityScheduleActivitiesWithItemIdRequest',
  basePath: '/wop-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_POST_ACTIVITY_SCHEDULE_ACTIVITIES_WITH_ITEMID_START = 'DATA_POST_ACTIVITY_SCHEDULE_ACTIVITIES_WITH_ITEMID_START'
export const DATA_POST_ACTIVITY_SCHEDULE_ACTIVITIES_WITH_ITEMID_SUCCESSFUL = 'DATA_POST_ACTIVITY_SCHEDULE_ACTIVITIES_WITH_ITEMID_SUCCESSFUL'
export const DATA_POST_ACTIVITY_SCHEDULE_ACTIVITIES_WITH_ITEMID_FAIL = 'DATA_POST_ACTIVITY_SCHEDULE_ACTIVITIES_WITH_ITEMID_FAIL'

// Adds a new activity to activity schedule. Only copying or adding via registryId is allowed.
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/wop.yaml#/Activities/post_activity_schedule__estimateId__activities__itemId_
export const postActivityScheduleActivitiesWithItemIdRequest = (
  requestArguments: postActivityScheduleActivitiesWithItemIdRequestWopArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_POST_ACTIVITY_SCHEDULE_ACTIVITIES_WITH_ITEMID_START',
      successful: 'DATA_POST_ACTIVITY_SCHEDULE_ACTIVITIES_WITH_ITEMID_SUCCESSFUL',
      fail: 'DATA_POST_ACTIVITY_SCHEDULE_ACTIVITIES_WITH_ITEMID_FAIL'
    },
    embeddedType: null,
    fnName: 'postActivityScheduleActivitiesWithItemIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/wop-api`,
    method: 'POST',
    url: `/activity-schedule/${options?.estimateId || store.app.calculation}/activities/${requestArguments.path['itemId']}`,
    params: {
      copyId: requestArguments.query?.['copyId'],
      registryId: requestArguments.query?.['registryId'],
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: requestArguments.body
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'postActivityScheduleActivitiesWithItemIdRequest', '/wop-api'),
    actions: {
      start: {
        type: DATA_POST_ACTIVITY_SCHEDULE_ACTIVITIES_WITH_ITEMID_START, payload, config, meta
      },
      successful: {
        type: DATA_POST_ACTIVITY_SCHEDULE_ACTIVITIES_WITH_ITEMID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_POST_ACTIVITY_SCHEDULE_ACTIVITIES_WITH_ITEMID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_PATCH_ACTIVITY_SCHEDULE_ACTIVITIES_WITH_ITEMID_START = 'DATA_PATCH_ACTIVITY_SCHEDULE_ACTIVITIES_WITH_ITEMID_START'
export const DATA_PATCH_ACTIVITY_SCHEDULE_ACTIVITIES_WITH_ITEMID_SUCCESSFUL = 'DATA_PATCH_ACTIVITY_SCHEDULE_ACTIVITIES_WITH_ITEMID_SUCCESSFUL'
export const DATA_PATCH_ACTIVITY_SCHEDULE_ACTIVITIES_WITH_ITEMID_FAIL = 'DATA_PATCH_ACTIVITY_SCHEDULE_ACTIVITIES_WITH_ITEMID_FAIL'

// Update column data of an activity
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/wop.yaml#/Activities/patch_activity_schedule__estimateId__activities__itemId_
export const patchActivityScheduleActivitiesWithItemIdRequest = (
  requestArguments: patchActivityScheduleActivitiesWithItemIdRequestWopArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_PATCH_ACTIVITY_SCHEDULE_ACTIVITIES_WITH_ITEMID_START',
      successful: 'DATA_PATCH_ACTIVITY_SCHEDULE_ACTIVITIES_WITH_ITEMID_SUCCESSFUL',
      fail: 'DATA_PATCH_ACTIVITY_SCHEDULE_ACTIVITIES_WITH_ITEMID_FAIL'
    },
    embeddedType: null,
    fnName: 'patchActivityScheduleActivitiesWithItemIdRequest'
  }
  const config = {
    headers: { 'Content-Type': 'application/json' },
    baseURL: `${API_BASE_URL}/wop-api`,
    method: 'PATCH',
    url: `/activity-schedule/${options?.estimateId || store.app.calculation}/activities/${requestArguments.path['itemId']}`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: requestArguments.body
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'patchActivityScheduleActivitiesWithItemIdRequest', '/wop-api'),
    actions: {
      start: {
        type: DATA_PATCH_ACTIVITY_SCHEDULE_ACTIVITIES_WITH_ITEMID_START, payload, config, meta
      },
      successful: {
        type: DATA_PATCH_ACTIVITY_SCHEDULE_ACTIVITIES_WITH_ITEMID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_PATCH_ACTIVITY_SCHEDULE_ACTIVITIES_WITH_ITEMID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_DELETE_ACTIVITY_SCHEDULE_ACTIVITIES_WITH_ITEMID_START = 'DATA_DELETE_ACTIVITY_SCHEDULE_ACTIVITIES_WITH_ITEMID_START'
export const DATA_DELETE_ACTIVITY_SCHEDULE_ACTIVITIES_WITH_ITEMID_SUCCESSFUL = 'DATA_DELETE_ACTIVITY_SCHEDULE_ACTIVITIES_WITH_ITEMID_SUCCESSFUL'
export const DATA_DELETE_ACTIVITY_SCHEDULE_ACTIVITIES_WITH_ITEMID_FAIL = 'DATA_DELETE_ACTIVITY_SCHEDULE_ACTIVITIES_WITH_ITEMID_FAIL'

// Delete an activity
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/wop.yaml#/Activities/delete_activity_schedule__estimateId__activities__itemId_
export const deleteActivityScheduleActivitiesWithItemIdRequest = (
  requestArguments: deleteActivityScheduleActivitiesWithItemIdRequestWopArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_DELETE_ACTIVITY_SCHEDULE_ACTIVITIES_WITH_ITEMID_START',
      successful: 'DATA_DELETE_ACTIVITY_SCHEDULE_ACTIVITIES_WITH_ITEMID_SUCCESSFUL',
      fail: 'DATA_DELETE_ACTIVITY_SCHEDULE_ACTIVITIES_WITH_ITEMID_FAIL'
    },
    embeddedType: null,
    fnName: 'deleteActivityScheduleActivitiesWithItemIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/wop-api`,
    method: 'DELETE',
    url: `/activity-schedule/${options?.estimateId || store.app.calculation}/activities/${requestArguments.path['itemId']}`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'deleteActivityScheduleActivitiesWithItemIdRequest', '/wop-api'),
    actions: {
      start: {
        type: DATA_DELETE_ACTIVITY_SCHEDULE_ACTIVITIES_WITH_ITEMID_START, payload, config, meta
      },
      successful: {
        type: DATA_DELETE_ACTIVITY_SCHEDULE_ACTIVITIES_WITH_ITEMID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_DELETE_ACTIVITY_SCHEDULE_ACTIVITIES_WITH_ITEMID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_GET_REGISTRY_FUNCTIONAL_SECTORS_START = 'DATA_GET_REGISTRY_FUNCTIONAL_SECTORS_START'
export const DATA_GET_REGISTRY_FUNCTIONAL_SECTORS_SUCCESSFUL = 'DATA_GET_REGISTRY_FUNCTIONAL_SECTORS_SUCCESSFUL'
export const DATA_GET_REGISTRY_FUNCTIONAL_SECTORS_FAIL = 'DATA_GET_REGISTRY_FUNCTIONAL_SECTORS_FAIL'

// Gets functional sectors from registy
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/wop.yaml#/Functional%20Sectors/get_registry_functional_sectors
export const getRegistryFunctionalSectorsRequest = (
  requestArguments: getRegistryFunctionalSectorsRequestWopArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_REGISTRY_FUNCTIONAL_SECTORS_START',
      successful: 'DATA_GET_REGISTRY_FUNCTIONAL_SECTORS_SUCCESSFUL',
      fail: 'DATA_GET_REGISTRY_FUNCTIONAL_SECTORS_FAIL'
    },
    embeddedType: {
      items: {
        schemaPath: '#/definitions/Enumeral',
        schemaKey: 'Enumeral'
      }
    },
    fnName: 'getRegistryFunctionalSectorsRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/wop-api`,
    method: 'GET',
    url: '/registry/functional-sectors',
    params: {
      classificationId: requestArguments.query?.['classificationId'],
      functionId: requestArguments.query?.['functionId'],
      processId: requestArguments.query?.['processId'],
      activityGroupId: requestArguments.query?.['activityGroupId'],
      selectedId: requestArguments.query?.['selectedId'],
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      items: {
        schemaPath: '#/definitions/Enumeral',
        schemaKey: 'Enumeral'
      }
    }, 'getRegistryFunctionalSectorsRequest', '/wop-api'),
    actions: {
      start: {
        type: DATA_GET_REGISTRY_FUNCTIONAL_SECTORS_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_REGISTRY_FUNCTIONAL_SECTORS_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_REGISTRY_FUNCTIONAL_SECTORS_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getRegistryFunctionalSectorsRequestDefinitions = (definitionsArguments: {| requestArgs: getRegistryFunctionalSectorsRequestWopArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getRegistryFunctionalSectorsRequestWopArguments, payload?: Object |} => ({
  fnName: 'getRegistryFunctionalSectorsRequest',
  basePath: '/wop-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_GET_REGISTRY_FUNCTIONAL_SECTORS_DRIVERS_WITH_ID_START = 'DATA_GET_REGISTRY_FUNCTIONAL_SECTORS_DRIVERS_WITH_ID_START'
export const DATA_GET_REGISTRY_FUNCTIONAL_SECTORS_DRIVERS_WITH_ID_SUCCESSFUL = 'DATA_GET_REGISTRY_FUNCTIONAL_SECTORS_DRIVERS_WITH_ID_SUCCESSFUL'
export const DATA_GET_REGISTRY_FUNCTIONAL_SECTORS_DRIVERS_WITH_ID_FAIL = 'DATA_GET_REGISTRY_FUNCTIONAL_SECTORS_DRIVERS_WITH_ID_FAIL'

// Get drivers for a registry functional sector
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/wop.yaml#/Functional%20Sectors/get_registry_functional_sectors__id__drivers
export const getRegistryFunctionalSectorsDriversWithIdRequest = (
  requestArguments: getRegistryFunctionalSectorsDriversWithIdRequestWopArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_REGISTRY_FUNCTIONAL_SECTORS_DRIVERS_WITH_ID_START',
      successful: 'DATA_GET_REGISTRY_FUNCTIONAL_SECTORS_DRIVERS_WITH_ID_SUCCESSFUL',
      fail: 'DATA_GET_REGISTRY_FUNCTIONAL_SECTORS_DRIVERS_WITH_ID_FAIL'
    },
    embeddedType: {
      drivers: {
        schemaPath: '#/definitions/ValueProperty',
        schemaKey: 'ValueProperty'
      },
      area: {
        schemaPath: '#/definitions/ValueProperty',
        schemaKey: 'ValueProperty'
      }
    },
    fnName: 'getRegistryFunctionalSectorsDriversWithIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/wop-api`,
    method: 'GET',
    url: `/registry/functional-sectors/${requestArguments.path['id']}/drivers`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      drivers: {
        schemaPath: '#/definitions/ValueProperty',
        schemaKey: 'ValueProperty'
      },
      area: {
        schemaPath: '#/definitions/ValueProperty',
        schemaKey: 'ValueProperty'
      }
    }, 'getRegistryFunctionalSectorsDriversWithIdRequest', '/wop-api'),
    actions: {
      start: {
        type: DATA_GET_REGISTRY_FUNCTIONAL_SECTORS_DRIVERS_WITH_ID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_REGISTRY_FUNCTIONAL_SECTORS_DRIVERS_WITH_ID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_REGISTRY_FUNCTIONAL_SECTORS_DRIVERS_WITH_ID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getRegistryFunctionalSectorsDriversWithIdRequestDefinitions = (definitionsArguments: {| requestArgs: getRegistryFunctionalSectorsDriversWithIdRequestWopArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getRegistryFunctionalSectorsDriversWithIdRequestWopArguments, payload?: Object |} => ({
  fnName: 'getRegistryFunctionalSectorsDriversWithIdRequest',
  basePath: '/wop-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_GET_REGISTRY_FUNCTIONS_START = 'DATA_GET_REGISTRY_FUNCTIONS_START'
export const DATA_GET_REGISTRY_FUNCTIONS_SUCCESSFUL = 'DATA_GET_REGISTRY_FUNCTIONS_SUCCESSFUL'
export const DATA_GET_REGISTRY_FUNCTIONS_FAIL = 'DATA_GET_REGISTRY_FUNCTIONS_FAIL'

// Gets functions from registry
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/wop.yaml#/Functions/get_registry_functions
export const getRegistryFunctionsRequest = (
  requestArguments: getRegistryFunctionsRequestWopArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_REGISTRY_FUNCTIONS_START',
      successful: 'DATA_GET_REGISTRY_FUNCTIONS_SUCCESSFUL',
      fail: 'DATA_GET_REGISTRY_FUNCTIONS_FAIL'
    },
    embeddedType: {
      items: {
        schemaPath: '#/definitions/Enumeral',
        schemaKey: 'Enumeral'
      }
    },
    fnName: 'getRegistryFunctionsRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/wop-api`,
    method: 'GET',
    url: '/registry/functions',
    params: {
      classificationId: requestArguments.query?.['classificationId'],
      functionalsectorId: requestArguments.query?.['functionalsectorId'],
      processId: requestArguments.query?.['processId'],
      activityGroupId: requestArguments.query?.['activityGroupId'],
      selectedId: requestArguments.query?.['selectedId'],
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      items: {
        schemaPath: '#/definitions/Enumeral',
        schemaKey: 'Enumeral'
      }
    }, 'getRegistryFunctionsRequest', '/wop-api'),
    actions: {
      start: {
        type: DATA_GET_REGISTRY_FUNCTIONS_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_REGISTRY_FUNCTIONS_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_REGISTRY_FUNCTIONS_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getRegistryFunctionsRequestDefinitions = (definitionsArguments: {| requestArgs: getRegistryFunctionsRequestWopArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getRegistryFunctionsRequestWopArguments, payload?: Object |} => ({
  fnName: 'getRegistryFunctionsRequest',
  basePath: '/wop-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_GET_REGISTRY_FUNCTIONS_DRIVERS_WITH_ID_START = 'DATA_GET_REGISTRY_FUNCTIONS_DRIVERS_WITH_ID_START'
export const DATA_GET_REGISTRY_FUNCTIONS_DRIVERS_WITH_ID_SUCCESSFUL = 'DATA_GET_REGISTRY_FUNCTIONS_DRIVERS_WITH_ID_SUCCESSFUL'
export const DATA_GET_REGISTRY_FUNCTIONS_DRIVERS_WITH_ID_FAIL = 'DATA_GET_REGISTRY_FUNCTIONS_DRIVERS_WITH_ID_FAIL'

// Get drivers for a registry function
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/wop.yaml#/Functions/get_registry_functions__id__drivers
export const getRegistryFunctionsDriversWithIdRequest = (
  requestArguments: getRegistryFunctionsDriversWithIdRequestWopArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_REGISTRY_FUNCTIONS_DRIVERS_WITH_ID_START',
      successful: 'DATA_GET_REGISTRY_FUNCTIONS_DRIVERS_WITH_ID_SUCCESSFUL',
      fail: 'DATA_GET_REGISTRY_FUNCTIONS_DRIVERS_WITH_ID_FAIL'
    },
    embeddedType: {
      drivers: {
        schemaPath: '#/definitions/ValueProperty',
        schemaKey: 'ValueProperty'
      },
      area: {
        schemaPath: '#/definitions/ValueProperty',
        schemaKey: 'ValueProperty'
      }
    },
    fnName: 'getRegistryFunctionsDriversWithIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/wop-api`,
    method: 'GET',
    url: `/registry/functions/${requestArguments.path['id']}/drivers`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      drivers: {
        schemaPath: '#/definitions/ValueProperty',
        schemaKey: 'ValueProperty'
      },
      area: {
        schemaPath: '#/definitions/ValueProperty',
        schemaKey: 'ValueProperty'
      }
    }, 'getRegistryFunctionsDriversWithIdRequest', '/wop-api'),
    actions: {
      start: {
        type: DATA_GET_REGISTRY_FUNCTIONS_DRIVERS_WITH_ID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_REGISTRY_FUNCTIONS_DRIVERS_WITH_ID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_REGISTRY_FUNCTIONS_DRIVERS_WITH_ID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getRegistryFunctionsDriversWithIdRequestDefinitions = (definitionsArguments: {| requestArgs: getRegistryFunctionsDriversWithIdRequestWopArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getRegistryFunctionsDriversWithIdRequestWopArguments, payload?: Object |} => ({
  fnName: 'getRegistryFunctionsDriversWithIdRequest',
  basePath: '/wop-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_GET_REGISTRY_FUNCTIONS_CAN_ATTACH_TO_START = 'DATA_GET_REGISTRY_FUNCTIONS_CAN_ATTACH_TO_START'
export const DATA_GET_REGISTRY_FUNCTIONS_CAN_ATTACH_TO_SUCCESSFUL = 'DATA_GET_REGISTRY_FUNCTIONS_CAN_ATTACH_TO_SUCCESSFUL'
export const DATA_GET_REGISTRY_FUNCTIONS_CAN_ATTACH_TO_FAIL = 'DATA_GET_REGISTRY_FUNCTIONS_CAN_ATTACH_TO_FAIL'

// Get id (value) and description (localizedName) for rows to which the registry function can be attached to
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/wop.yaml#/Functions/get_registry_functions_can_attach_to
export const getRegistryFunctionsCanAttachToRequest = (
  payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_REGISTRY_FUNCTIONS_CAN_ATTACH_TO_START',
      successful: 'DATA_GET_REGISTRY_FUNCTIONS_CAN_ATTACH_TO_SUCCESSFUL',
      fail: 'DATA_GET_REGISTRY_FUNCTIONS_CAN_ATTACH_TO_FAIL'
    },
    embeddedType: {
      items: {
        schemaPath: '#/definitions/Enumeral',
        schemaKey: 'Enumeral'
      }
    },
    fnName: 'getRegistryFunctionsCanAttachToRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/wop-api`,
    method: 'GET',
    url: '/registry/functions/can-attach-to',
    params: {
      estimateId: options?.estimateId || store.app.calculation,
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      items: {
        schemaPath: '#/definitions/Enumeral',
        schemaKey: 'Enumeral'
      }
    }, 'getRegistryFunctionsCanAttachToRequest', '/wop-api'),
    actions: {
      start: {
        type: DATA_GET_REGISTRY_FUNCTIONS_CAN_ATTACH_TO_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_REGISTRY_FUNCTIONS_CAN_ATTACH_TO_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_REGISTRY_FUNCTIONS_CAN_ATTACH_TO_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getRegistryFunctionsCanAttachToRequestDefinitions = (definitionsArguments: {| payload?: Object |}): {|
  ...TVDGARConfigWithoutRequestArgs, payload?: Object |} => ({
  fnName: 'getRegistryFunctionsCanAttachToRequest',
  basePath: '/wop-api',
  payload: definitionsArguments.payload,
})


export const DATA_GET_REGISTRY_PROCESSES_START = 'DATA_GET_REGISTRY_PROCESSES_START'
export const DATA_GET_REGISTRY_PROCESSES_SUCCESSFUL = 'DATA_GET_REGISTRY_PROCESSES_SUCCESSFUL'
export const DATA_GET_REGISTRY_PROCESSES_FAIL = 'DATA_GET_REGISTRY_PROCESSES_FAIL'

// Gets processes from registry
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/wop.yaml#/Processes/get_registry_processes
export const getRegistryProcessesRequest = (
  requestArguments: getRegistryProcessesRequestWopArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_REGISTRY_PROCESSES_START',
      successful: 'DATA_GET_REGISTRY_PROCESSES_SUCCESSFUL',
      fail: 'DATA_GET_REGISTRY_PROCESSES_FAIL'
    },
    embeddedType: {
      items: {
        schemaPath: '#/definitions/Enumeral',
        schemaKey: 'Enumeral'
      }
    },
    fnName: 'getRegistryProcessesRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/wop-api`,
    method: 'GET',
    url: '/registry/processes',
    params: {
      classificationId: requestArguments.query?.['classificationId'],
      functionalsectorId: requestArguments.query?.['functionalsectorId'],
      functionId: requestArguments.query?.['functionId'],
      activityGroupId: requestArguments.query?.['activityGroupId'],
      selectedId: requestArguments.query?.['selectedId'],
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      items: {
        schemaPath: '#/definitions/Enumeral',
        schemaKey: 'Enumeral'
      }
    }, 'getRegistryProcessesRequest', '/wop-api'),
    actions: {
      start: {
        type: DATA_GET_REGISTRY_PROCESSES_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_REGISTRY_PROCESSES_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_REGISTRY_PROCESSES_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getRegistryProcessesRequestDefinitions = (definitionsArguments: {| requestArgs: getRegistryProcessesRequestWopArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getRegistryProcessesRequestWopArguments, payload?: Object |} => ({
  fnName: 'getRegistryProcessesRequest',
  basePath: '/wop-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_GET_REGISTRY_PROCESSES_DRIVERS_WITH_ID_START = 'DATA_GET_REGISTRY_PROCESSES_DRIVERS_WITH_ID_START'
export const DATA_GET_REGISTRY_PROCESSES_DRIVERS_WITH_ID_SUCCESSFUL = 'DATA_GET_REGISTRY_PROCESSES_DRIVERS_WITH_ID_SUCCESSFUL'
export const DATA_GET_REGISTRY_PROCESSES_DRIVERS_WITH_ID_FAIL = 'DATA_GET_REGISTRY_PROCESSES_DRIVERS_WITH_ID_FAIL'

// Get drivers for a registry process
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/wop.yaml#/Processes/get_registry_processes__id__drivers
export const getRegistryProcessesDriversWithIdRequest = (
  requestArguments: getRegistryProcessesDriversWithIdRequestWopArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_REGISTRY_PROCESSES_DRIVERS_WITH_ID_START',
      successful: 'DATA_GET_REGISTRY_PROCESSES_DRIVERS_WITH_ID_SUCCESSFUL',
      fail: 'DATA_GET_REGISTRY_PROCESSES_DRIVERS_WITH_ID_FAIL'
    },
    embeddedType: {
      drivers: {
        schemaPath: '#/definitions/ValueProperty',
        schemaKey: 'ValueProperty'
      },
      area: {
        schemaPath: '#/definitions/ValueProperty',
        schemaKey: 'ValueProperty'
      }
    },
    fnName: 'getRegistryProcessesDriversWithIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/wop-api`,
    method: 'GET',
    url: `/registry/processes/${requestArguments.path['id']}/drivers`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      drivers: {
        schemaPath: '#/definitions/ValueProperty',
        schemaKey: 'ValueProperty'
      },
      area: {
        schemaPath: '#/definitions/ValueProperty',
        schemaKey: 'ValueProperty'
      }
    }, 'getRegistryProcessesDriversWithIdRequest', '/wop-api'),
    actions: {
      start: {
        type: DATA_GET_REGISTRY_PROCESSES_DRIVERS_WITH_ID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_REGISTRY_PROCESSES_DRIVERS_WITH_ID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_REGISTRY_PROCESSES_DRIVERS_WITH_ID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getRegistryProcessesDriversWithIdRequestDefinitions = (definitionsArguments: {| requestArgs: getRegistryProcessesDriversWithIdRequestWopArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getRegistryProcessesDriversWithIdRequestWopArguments, payload?: Object |} => ({
  fnName: 'getRegistryProcessesDriversWithIdRequest',
  basePath: '/wop-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_GET_REGISTRY_PROCESSES_CAN_ATTACH_TO_START = 'DATA_GET_REGISTRY_PROCESSES_CAN_ATTACH_TO_START'
export const DATA_GET_REGISTRY_PROCESSES_CAN_ATTACH_TO_SUCCESSFUL = 'DATA_GET_REGISTRY_PROCESSES_CAN_ATTACH_TO_SUCCESSFUL'
export const DATA_GET_REGISTRY_PROCESSES_CAN_ATTACH_TO_FAIL = 'DATA_GET_REGISTRY_PROCESSES_CAN_ATTACH_TO_FAIL'

// Get id (value) and description (localizedName) of rows to which the process can be attached to
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/wop.yaml#/Processes/get_registry_processes_can_attach_to
export const getRegistryProcessesCanAttachToRequest = (
  payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_REGISTRY_PROCESSES_CAN_ATTACH_TO_START',
      successful: 'DATA_GET_REGISTRY_PROCESSES_CAN_ATTACH_TO_SUCCESSFUL',
      fail: 'DATA_GET_REGISTRY_PROCESSES_CAN_ATTACH_TO_FAIL'
    },
    embeddedType: {
      items: {
        schemaPath: '#/definitions/Enumeral',
        schemaKey: 'Enumeral'
      }
    },
    fnName: 'getRegistryProcessesCanAttachToRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/wop-api`,
    method: 'GET',
    url: '/registry/processes/can-attach-to',
    params: {
      estimateId: options?.estimateId || store.app.calculation,
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      items: {
        schemaPath: '#/definitions/Enumeral',
        schemaKey: 'Enumeral'
      }
    }, 'getRegistryProcessesCanAttachToRequest', '/wop-api'),
    actions: {
      start: {
        type: DATA_GET_REGISTRY_PROCESSES_CAN_ATTACH_TO_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_REGISTRY_PROCESSES_CAN_ATTACH_TO_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_REGISTRY_PROCESSES_CAN_ATTACH_TO_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getRegistryProcessesCanAttachToRequestDefinitions = (definitionsArguments: {| payload?: Object |}): {|
  ...TVDGARConfigWithoutRequestArgs, payload?: Object |} => ({
  fnName: 'getRegistryProcessesCanAttachToRequest',
  basePath: '/wop-api',
  payload: definitionsArguments.payload,
})


export const DATA_GET_REGISTRY_ACTIVITY_GROUPS_START = 'DATA_GET_REGISTRY_ACTIVITY_GROUPS_START'
export const DATA_GET_REGISTRY_ACTIVITY_GROUPS_SUCCESSFUL = 'DATA_GET_REGISTRY_ACTIVITY_GROUPS_SUCCESSFUL'
export const DATA_GET_REGISTRY_ACTIVITY_GROUPS_FAIL = 'DATA_GET_REGISTRY_ACTIVITY_GROUPS_FAIL'

// Gets activity groups from registry
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/wop.yaml#/Activity%20Groups/get_registry_activity_groups
export const getRegistryActivityGroupsRequest = (
  requestArguments: getRegistryActivityGroupsRequestWopArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_REGISTRY_ACTIVITY_GROUPS_START',
      successful: 'DATA_GET_REGISTRY_ACTIVITY_GROUPS_SUCCESSFUL',
      fail: 'DATA_GET_REGISTRY_ACTIVITY_GROUPS_FAIL'
    },
    embeddedType: {
      items: {
        schemaPath: '#/definitions/Enumeral',
        schemaKey: 'Enumeral'
      }
    },
    fnName: 'getRegistryActivityGroupsRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/wop-api`,
    method: 'GET',
    url: '/registry/activity-groups',
    params: {
      classificationId: requestArguments.query?.['classificationId'],
      functionalsectorId: requestArguments.query?.['functionalsectorId'],
      functionId: requestArguments.query?.['functionId'],
      processId: requestArguments.query?.['processId'],
      selectedId: requestArguments.query?.['selectedId'],
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      items: {
        schemaPath: '#/definitions/Enumeral',
        schemaKey: 'Enumeral'
      }
    }, 'getRegistryActivityGroupsRequest', '/wop-api'),
    actions: {
      start: {
        type: DATA_GET_REGISTRY_ACTIVITY_GROUPS_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_REGISTRY_ACTIVITY_GROUPS_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_REGISTRY_ACTIVITY_GROUPS_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getRegistryActivityGroupsRequestDefinitions = (definitionsArguments: {| requestArgs: getRegistryActivityGroupsRequestWopArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getRegistryActivityGroupsRequestWopArguments, payload?: Object |} => ({
  fnName: 'getRegistryActivityGroupsRequest',
  basePath: '/wop-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_GET_REGISTRY_ACTIVITY_GROUPS_DRIVERS_WITH_ID_START = 'DATA_GET_REGISTRY_ACTIVITY_GROUPS_DRIVERS_WITH_ID_START'
export const DATA_GET_REGISTRY_ACTIVITY_GROUPS_DRIVERS_WITH_ID_SUCCESSFUL = 'DATA_GET_REGISTRY_ACTIVITY_GROUPS_DRIVERS_WITH_ID_SUCCESSFUL'
export const DATA_GET_REGISTRY_ACTIVITY_GROUPS_DRIVERS_WITH_ID_FAIL = 'DATA_GET_REGISTRY_ACTIVITY_GROUPS_DRIVERS_WITH_ID_FAIL'

// Get drivers for a registry activity group
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/wop.yaml#/Activity%20Groups/get_registry_activity_groups__id__drivers
export const getRegistryActivityGroupsDriversWithIdRequest = (
  requestArguments: getRegistryActivityGroupsDriversWithIdRequestWopArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_REGISTRY_ACTIVITY_GROUPS_DRIVERS_WITH_ID_START',
      successful: 'DATA_GET_REGISTRY_ACTIVITY_GROUPS_DRIVERS_WITH_ID_SUCCESSFUL',
      fail: 'DATA_GET_REGISTRY_ACTIVITY_GROUPS_DRIVERS_WITH_ID_FAIL'
    },
    embeddedType: {
      drivers: {
        schemaPath: '#/definitions/ValueProperty',
        schemaKey: 'ValueProperty'
      },
      area: {
        schemaPath: '#/definitions/ValueProperty',
        schemaKey: 'ValueProperty'
      }
    },
    fnName: 'getRegistryActivityGroupsDriversWithIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/wop-api`,
    method: 'GET',
    url: `/registry/activity-groups/${requestArguments.path['id']}/drivers`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      drivers: {
        schemaPath: '#/definitions/ValueProperty',
        schemaKey: 'ValueProperty'
      },
      area: {
        schemaPath: '#/definitions/ValueProperty',
        schemaKey: 'ValueProperty'
      }
    }, 'getRegistryActivityGroupsDriversWithIdRequest', '/wop-api'),
    actions: {
      start: {
        type: DATA_GET_REGISTRY_ACTIVITY_GROUPS_DRIVERS_WITH_ID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_REGISTRY_ACTIVITY_GROUPS_DRIVERS_WITH_ID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_REGISTRY_ACTIVITY_GROUPS_DRIVERS_WITH_ID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getRegistryActivityGroupsDriversWithIdRequestDefinitions = (definitionsArguments: {| requestArgs: getRegistryActivityGroupsDriversWithIdRequestWopArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getRegistryActivityGroupsDriversWithIdRequestWopArguments, payload?: Object |} => ({
  fnName: 'getRegistryActivityGroupsDriversWithIdRequest',
  basePath: '/wop-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_GET_REGISTRY_ACTIVITY_GROUPS_CAN_ATTACH_TO_START = 'DATA_GET_REGISTRY_ACTIVITY_GROUPS_CAN_ATTACH_TO_START'
export const DATA_GET_REGISTRY_ACTIVITY_GROUPS_CAN_ATTACH_TO_SUCCESSFUL = 'DATA_GET_REGISTRY_ACTIVITY_GROUPS_CAN_ATTACH_TO_SUCCESSFUL'
export const DATA_GET_REGISTRY_ACTIVITY_GROUPS_CAN_ATTACH_TO_FAIL = 'DATA_GET_REGISTRY_ACTIVITY_GROUPS_CAN_ATTACH_TO_FAIL'

// Get id (value) and description (localizedName) of rows to which the activity group can be attached to
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/wop.yaml#/Activity%20Groups/get_registry_activity_groups_can_attach_to
export const getRegistryActivityGroupsCanAttachToRequest = (
  payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_REGISTRY_ACTIVITY_GROUPS_CAN_ATTACH_TO_START',
      successful: 'DATA_GET_REGISTRY_ACTIVITY_GROUPS_CAN_ATTACH_TO_SUCCESSFUL',
      fail: 'DATA_GET_REGISTRY_ACTIVITY_GROUPS_CAN_ATTACH_TO_FAIL'
    },
    embeddedType: {
      items: {
        schemaPath: '#/definitions/Enumeral',
        schemaKey: 'Enumeral'
      }
    },
    fnName: 'getRegistryActivityGroupsCanAttachToRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/wop-api`,
    method: 'GET',
    url: '/registry/activity-groups/can-attach-to',
    params: {
      estimateId: options?.estimateId || store.app.calculation,
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      items: {
        schemaPath: '#/definitions/Enumeral',
        schemaKey: 'Enumeral'
      }
    }, 'getRegistryActivityGroupsCanAttachToRequest', '/wop-api'),
    actions: {
      start: {
        type: DATA_GET_REGISTRY_ACTIVITY_GROUPS_CAN_ATTACH_TO_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_REGISTRY_ACTIVITY_GROUPS_CAN_ATTACH_TO_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_REGISTRY_ACTIVITY_GROUPS_CAN_ATTACH_TO_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getRegistryActivityGroupsCanAttachToRequestDefinitions = (definitionsArguments: {| payload?: Object |}): {|
  ...TVDGARConfigWithoutRequestArgs, payload?: Object |} => ({
  fnName: 'getRegistryActivityGroupsCanAttachToRequest',
  basePath: '/wop-api',
  payload: definitionsArguments.payload,
})


export const DATA_GET_REGISTRY_ACTIVITIES_START = 'DATA_GET_REGISTRY_ACTIVITIES_START'
export const DATA_GET_REGISTRY_ACTIVITIES_SUCCESSFUL = 'DATA_GET_REGISTRY_ACTIVITIES_SUCCESSFUL'
export const DATA_GET_REGISTRY_ACTIVITIES_FAIL = 'DATA_GET_REGISTRY_ACTIVITIES_FAIL'

// Gets activities from registry
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/wop.yaml#/Activities/get_registry_activities
export const getRegistryActivitiesRequest = (
  requestArguments: getRegistryActivitiesRequestWopArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_REGISTRY_ACTIVITIES_START',
      successful: 'DATA_GET_REGISTRY_ACTIVITIES_SUCCESSFUL',
      fail: 'DATA_GET_REGISTRY_ACTIVITIES_FAIL'
    },
    embeddedType: {
      items: {
        schemaPath: '#/definitions/Enumeral',
        schemaKey: 'Enumeral'
      }
    },
    fnName: 'getRegistryActivitiesRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/wop-api`,
    method: 'GET',
    url: '/registry/activities',
    params: {
      classificationId: requestArguments.query?.['classificationId'],
      functionalsectorId: requestArguments.query?.['functionalsectorId'],
      functionId: requestArguments.query?.['functionId'],
      processId: requestArguments.query?.['processId'],
      selectedId: requestArguments.query?.['selectedId'],
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      items: {
        schemaPath: '#/definitions/Enumeral',
        schemaKey: 'Enumeral'
      }
    }, 'getRegistryActivitiesRequest', '/wop-api'),
    actions: {
      start: {
        type: DATA_GET_REGISTRY_ACTIVITIES_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_REGISTRY_ACTIVITIES_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_REGISTRY_ACTIVITIES_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getRegistryActivitiesRequestDefinitions = (definitionsArguments: {| requestArgs: getRegistryActivitiesRequestWopArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getRegistryActivitiesRequestWopArguments, payload?: Object |} => ({
  fnName: 'getRegistryActivitiesRequest',
  basePath: '/wop-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_GET_GROUPING_SCHEDULE_WITH_ESTIMATEID_START = 'DATA_GET_GROUPING_SCHEDULE_WITH_ESTIMATEID_START'
export const DATA_GET_GROUPING_SCHEDULE_WITH_ESTIMATEID_SUCCESSFUL = 'DATA_GET_GROUPING_SCHEDULE_WITH_ESTIMATEID_SUCCESSFUL'
export const DATA_GET_GROUPING_SCHEDULE_WITH_ESTIMATEID_FAIL = 'DATA_GET_GROUPING_SCHEDULE_WITH_ESTIMATEID_FAIL'

// Returns grouping schedule root level or full if given listType=flat
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/wop.yaml#/Grouping%20Schedule/get_grouping_schedule__estimateId_
export const getGroupingScheduleWithEstimateIdRequest = (
  requestArguments: getGroupingScheduleWithEstimateIdRequestWopArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_GROUPING_SCHEDULE_WITH_ESTIMATEID_START',
      successful: 'DATA_GET_GROUPING_SCHEDULE_WITH_ESTIMATEID_SUCCESSFUL',
      fail: 'DATA_GET_GROUPING_SCHEDULE_WITH_ESTIMATEID_FAIL'
    },
    embeddedType: {
      items: {
        schemaPath: '#/definitions/GroupingScheduleItem',
        schemaKey: 'GroupingScheduleItem'
      }
    },
    fnName: 'getGroupingScheduleWithEstimateIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/wop-api`,
    method: 'GET',
    url: `/grouping-schedule/${options?.estimateId || store.app.calculation}`,
    params: {
      listType: requestArguments.query['listType'],
      includeEmpty: requestArguments.query['includeEmpty'],
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      items: {
        schemaPath: '#/definitions/GroupingScheduleItem',
        schemaKey: 'GroupingScheduleItem'
      }
    }, 'getGroupingScheduleWithEstimateIdRequest', '/wop-api'),
    actions: {
      start: {
        type: DATA_GET_GROUPING_SCHEDULE_WITH_ESTIMATEID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_GROUPING_SCHEDULE_WITH_ESTIMATEID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_GROUPING_SCHEDULE_WITH_ESTIMATEID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getGroupingScheduleWithEstimateIdRequestDefinitions = (definitionsArguments: {| requestArgs: getGroupingScheduleWithEstimateIdRequestWopArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getGroupingScheduleWithEstimateIdRequestWopArguments, payload?: Object |} => ({
  fnName: 'getGroupingScheduleWithEstimateIdRequest',
  basePath: '/wop-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_GET_GROUPING_SCHEDULE_COLUMNS_WITH_ESTIMATEID_START = 'DATA_GET_GROUPING_SCHEDULE_COLUMNS_WITH_ESTIMATEID_START'
export const DATA_GET_GROUPING_SCHEDULE_COLUMNS_WITH_ESTIMATEID_SUCCESSFUL = 'DATA_GET_GROUPING_SCHEDULE_COLUMNS_WITH_ESTIMATEID_SUCCESSFUL'
export const DATA_GET_GROUPING_SCHEDULE_COLUMNS_WITH_ESTIMATEID_FAIL = 'DATA_GET_GROUPING_SCHEDULE_COLUMNS_WITH_ESTIMATEID_FAIL'

// Get possible columns for grouping schedule
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/wop.yaml#/Grouping%20Schedule/get_grouping_schedule__estimateId__columns
export const getGroupingScheduleColumnsWithEstimateIdRequest = (
  payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_GROUPING_SCHEDULE_COLUMNS_WITH_ESTIMATEID_START',
      successful: 'DATA_GET_GROUPING_SCHEDULE_COLUMNS_WITH_ESTIMATEID_SUCCESSFUL',
      fail: 'DATA_GET_GROUPING_SCHEDULE_COLUMNS_WITH_ESTIMATEID_FAIL'
    },
    embeddedType: {
      properties: {
        schemaPath: '#/definitions/ValueProperty',
        schemaKey: 'ValueProperty'
      }
    },
    fnName: 'getGroupingScheduleColumnsWithEstimateIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/wop-api`,
    method: 'GET',
    url: `/grouping-schedule/${options?.estimateId || store.app.calculation}/columns`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      properties: {
        schemaPath: '#/definitions/ValueProperty',
        schemaKey: 'ValueProperty'
      }
    }, 'getGroupingScheduleColumnsWithEstimateIdRequest', '/wop-api'),
    actions: {
      start: {
        type: DATA_GET_GROUPING_SCHEDULE_COLUMNS_WITH_ESTIMATEID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_GROUPING_SCHEDULE_COLUMNS_WITH_ESTIMATEID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_GROUPING_SCHEDULE_COLUMNS_WITH_ESTIMATEID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getGroupingScheduleColumnsWithEstimateIdRequestDefinitions = (definitionsArguments: {| payload?: Object |}): {|
  ...TVDGARConfigWithoutRequestArgs, payload?: Object |} => ({
  fnName: 'getGroupingScheduleColumnsWithEstimateIdRequest',
  basePath: '/wop-api',
  payload: definitionsArguments.payload,
})


export const DATA_POST_GROUPING_SCHEDULE_FUNCTIONAL_SECTOR_GROUPS_WITH_ESTIMATEID_START = 'DATA_POST_GROUPING_SCHEDULE_FUNCTIONAL_SECTOR_GROUPS_WITH_ESTIMATEID_START'
export const DATA_POST_GROUPING_SCHEDULE_FUNCTIONAL_SECTOR_GROUPS_WITH_ESTIMATEID_SUCCESSFUL = 'DATA_POST_GROUPING_SCHEDULE_FUNCTIONAL_SECTOR_GROUPS_WITH_ESTIMATEID_SUCCESSFUL'
export const DATA_POST_GROUPING_SCHEDULE_FUNCTIONAL_SECTOR_GROUPS_WITH_ESTIMATEID_FAIL = 'DATA_POST_GROUPING_SCHEDULE_FUNCTIONAL_SECTOR_GROUPS_WITH_ESTIMATEID_FAIL'

// Add a new functional sector group to the groupin schedule
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/wop.yaml#/Grouping%20Schedule/post_grouping_schedule__estimateId__functional_sector_groups
export const postGroupingScheduleFunctionalSectorGroupsWithEstimateIdRequest = (
  requestArguments: postGroupingScheduleFunctionalSectorGroupsWithEstimateIdRequestWopArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_POST_GROUPING_SCHEDULE_FUNCTIONAL_SECTOR_GROUPS_WITH_ESTIMATEID_START',
      successful: 'DATA_POST_GROUPING_SCHEDULE_FUNCTIONAL_SECTOR_GROUPS_WITH_ESTIMATEID_SUCCESSFUL',
      fail: 'DATA_POST_GROUPING_SCHEDULE_FUNCTIONAL_SECTOR_GROUPS_WITH_ESTIMATEID_FAIL'
    },
    embeddedType: null,
    fnName: 'postGroupingScheduleFunctionalSectorGroupsWithEstimateIdRequest'
  }
  const config = {
    headers: { 'Content-Type': 'application/json' },
    baseURL: `${API_BASE_URL}/wop-api`,
    method: 'POST',
    url: `/grouping-schedule/${options?.estimateId || store.app.calculation}/functional-sector-groups`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: requestArguments.body
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'postGroupingScheduleFunctionalSectorGroupsWithEstimateIdRequest', '/wop-api'),
    actions: {
      start: {
        type: DATA_POST_GROUPING_SCHEDULE_FUNCTIONAL_SECTOR_GROUPS_WITH_ESTIMATEID_START, payload, config, meta
      },
      successful: {
        type: DATA_POST_GROUPING_SCHEDULE_FUNCTIONAL_SECTOR_GROUPS_WITH_ESTIMATEID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_POST_GROUPING_SCHEDULE_FUNCTIONAL_SECTOR_GROUPS_WITH_ESTIMATEID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_GET_GROUPING_SCHEDULE_FUNCTIONAL_SECTOR_GROUPS_WITH_ITEMID_START = 'DATA_GET_GROUPING_SCHEDULE_FUNCTIONAL_SECTOR_GROUPS_WITH_ITEMID_START'
export const DATA_GET_GROUPING_SCHEDULE_FUNCTIONAL_SECTOR_GROUPS_WITH_ITEMID_SUCCESSFUL = 'DATA_GET_GROUPING_SCHEDULE_FUNCTIONAL_SECTOR_GROUPS_WITH_ITEMID_SUCCESSFUL'
export const DATA_GET_GROUPING_SCHEDULE_FUNCTIONAL_SECTOR_GROUPS_WITH_ITEMID_FAIL = 'DATA_GET_GROUPING_SCHEDULE_FUNCTIONAL_SECTOR_GROUPS_WITH_ITEMID_FAIL'

// Returns a functional sector group for grouping schedule
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/wop.yaml#/Grouping%20Schedule/get_grouping_schedule__estimateId__functional_sector_groups__itemId_
export const getGroupingScheduleFunctionalSectorGroupsWithItemIdRequest = (
  requestArguments: getGroupingScheduleFunctionalSectorGroupsWithItemIdRequestWopArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_GROUPING_SCHEDULE_FUNCTIONAL_SECTOR_GROUPS_WITH_ITEMID_START',
      successful: 'DATA_GET_GROUPING_SCHEDULE_FUNCTIONAL_SECTOR_GROUPS_WITH_ITEMID_SUCCESSFUL',
      fail: 'DATA_GET_GROUPING_SCHEDULE_FUNCTIONAL_SECTOR_GROUPS_WITH_ITEMID_FAIL'
    },
    embeddedType: {
      nonEmbedded: {
        schemaPath: '#/definitions/GroupingScheduleItem',
        schemaKey: 'GroupingScheduleItem'
      }
    },
    fnName: 'getGroupingScheduleFunctionalSectorGroupsWithItemIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/wop-api`,
    method: 'GET',
    url: `/grouping-schedule/${options?.estimateId || store.app.calculation}/functional-sector-groups/${requestArguments.path['itemId']}`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      nonEmbedded: {
        schemaPath: '#/definitions/GroupingScheduleItem',
        schemaKey: 'GroupingScheduleItem'
      }
    }, 'getGroupingScheduleFunctionalSectorGroupsWithItemIdRequest', '/wop-api'),
    actions: {
      start: {
        type: DATA_GET_GROUPING_SCHEDULE_FUNCTIONAL_SECTOR_GROUPS_WITH_ITEMID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_GROUPING_SCHEDULE_FUNCTIONAL_SECTOR_GROUPS_WITH_ITEMID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_GROUPING_SCHEDULE_FUNCTIONAL_SECTOR_GROUPS_WITH_ITEMID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getGroupingScheduleFunctionalSectorGroupsWithItemIdRequestDefinitions = (definitionsArguments: {| requestArgs: getGroupingScheduleFunctionalSectorGroupsWithItemIdRequestWopArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getGroupingScheduleFunctionalSectorGroupsWithItemIdRequestWopArguments, payload?: Object |} => ({
  fnName: 'getGroupingScheduleFunctionalSectorGroupsWithItemIdRequest',
  basePath: '/wop-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_PATCH_GROUPING_SCHEDULE_FUNCTIONAL_SECTOR_GROUPS_WITH_ITEMID_START = 'DATA_PATCH_GROUPING_SCHEDULE_FUNCTIONAL_SECTOR_GROUPS_WITH_ITEMID_START'
export const DATA_PATCH_GROUPING_SCHEDULE_FUNCTIONAL_SECTOR_GROUPS_WITH_ITEMID_SUCCESSFUL = 'DATA_PATCH_GROUPING_SCHEDULE_FUNCTIONAL_SECTOR_GROUPS_WITH_ITEMID_SUCCESSFUL'
export const DATA_PATCH_GROUPING_SCHEDULE_FUNCTIONAL_SECTOR_GROUPS_WITH_ITEMID_FAIL = 'DATA_PATCH_GROUPING_SCHEDULE_FUNCTIONAL_SECTOR_GROUPS_WITH_ITEMID_FAIL'

// Update column data of a functional sector group
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/wop.yaml#/Grouping%20Schedule/patch_grouping_schedule__estimateId__functional_sector_groups__itemId_
export const patchGroupingScheduleFunctionalSectorGroupsWithItemIdRequest = (
  requestArguments: patchGroupingScheduleFunctionalSectorGroupsWithItemIdRequestWopArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_PATCH_GROUPING_SCHEDULE_FUNCTIONAL_SECTOR_GROUPS_WITH_ITEMID_START',
      successful: 'DATA_PATCH_GROUPING_SCHEDULE_FUNCTIONAL_SECTOR_GROUPS_WITH_ITEMID_SUCCESSFUL',
      fail: 'DATA_PATCH_GROUPING_SCHEDULE_FUNCTIONAL_SECTOR_GROUPS_WITH_ITEMID_FAIL'
    },
    embeddedType: null,
    fnName: 'patchGroupingScheduleFunctionalSectorGroupsWithItemIdRequest'
  }
  const config = {
    headers: { 'Content-Type': 'application/json' },
    baseURL: `${API_BASE_URL}/wop-api`,
    method: 'PATCH',
    url: `/grouping-schedule/${options?.estimateId || store.app.calculation}/functional-sector-groups/${requestArguments.path['itemId']}`,
    params: {
      changeOrder: requestArguments.query?.['changeOrder'],
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: requestArguments.body
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'patchGroupingScheduleFunctionalSectorGroupsWithItemIdRequest', '/wop-api'),
    actions: {
      start: {
        type: DATA_PATCH_GROUPING_SCHEDULE_FUNCTIONAL_SECTOR_GROUPS_WITH_ITEMID_START, payload, config, meta
      },
      successful: {
        type: DATA_PATCH_GROUPING_SCHEDULE_FUNCTIONAL_SECTOR_GROUPS_WITH_ITEMID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_PATCH_GROUPING_SCHEDULE_FUNCTIONAL_SECTOR_GROUPS_WITH_ITEMID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_DELETE_GROUPING_SCHEDULE_FUNCTIONAL_SECTOR_GROUPS_WITH_ITEMID_START = 'DATA_DELETE_GROUPING_SCHEDULE_FUNCTIONAL_SECTOR_GROUPS_WITH_ITEMID_START'
export const DATA_DELETE_GROUPING_SCHEDULE_FUNCTIONAL_SECTOR_GROUPS_WITH_ITEMID_SUCCESSFUL = 'DATA_DELETE_GROUPING_SCHEDULE_FUNCTIONAL_SECTOR_GROUPS_WITH_ITEMID_SUCCESSFUL'
export const DATA_DELETE_GROUPING_SCHEDULE_FUNCTIONAL_SECTOR_GROUPS_WITH_ITEMID_FAIL = 'DATA_DELETE_GROUPING_SCHEDULE_FUNCTIONAL_SECTOR_GROUPS_WITH_ITEMID_FAIL'

// Delete a functional sector group
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/wop.yaml#/Grouping%20Schedule/delete_grouping_schedule__estimateId__functional_sector_groups__itemId_
export const deleteGroupingScheduleFunctionalSectorGroupsWithItemIdRequest = (
  requestArguments: deleteGroupingScheduleFunctionalSectorGroupsWithItemIdRequestWopArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_DELETE_GROUPING_SCHEDULE_FUNCTIONAL_SECTOR_GROUPS_WITH_ITEMID_START',
      successful: 'DATA_DELETE_GROUPING_SCHEDULE_FUNCTIONAL_SECTOR_GROUPS_WITH_ITEMID_SUCCESSFUL',
      fail: 'DATA_DELETE_GROUPING_SCHEDULE_FUNCTIONAL_SECTOR_GROUPS_WITH_ITEMID_FAIL'
    },
    embeddedType: null,
    fnName: 'deleteGroupingScheduleFunctionalSectorGroupsWithItemIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/wop-api`,
    method: 'DELETE',
    url: `/grouping-schedule/${options?.estimateId || store.app.calculation}/functional-sector-groups/${requestArguments.path['itemId']}`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'deleteGroupingScheduleFunctionalSectorGroupsWithItemIdRequest', '/wop-api'),
    actions: {
      start: {
        type: DATA_DELETE_GROUPING_SCHEDULE_FUNCTIONAL_SECTOR_GROUPS_WITH_ITEMID_START, payload, config, meta
      },
      successful: {
        type: DATA_DELETE_GROUPING_SCHEDULE_FUNCTIONAL_SECTOR_GROUPS_WITH_ITEMID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_DELETE_GROUPING_SCHEDULE_FUNCTIONAL_SECTOR_GROUPS_WITH_ITEMID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_GET_GROUPING_SCHEDULE_FUNCTIONAL_SECTOR_GROUPS_FUNCTION_GROUPS_WITH_ITEMID_START = 'DATA_GET_GROUPING_SCHEDULE_FUNCTIONAL_SECTOR_GROUPS_FUNCTION_GROUPS_WITH_ITEMID_START'
export const DATA_GET_GROUPING_SCHEDULE_FUNCTIONAL_SECTOR_GROUPS_FUNCTION_GROUPS_WITH_ITEMID_SUCCESSFUL = 'DATA_GET_GROUPING_SCHEDULE_FUNCTIONAL_SECTOR_GROUPS_FUNCTION_GROUPS_WITH_ITEMID_SUCCESSFUL'
export const DATA_GET_GROUPING_SCHEDULE_FUNCTIONAL_SECTOR_GROUPS_FUNCTION_GROUPS_WITH_ITEMID_FAIL = 'DATA_GET_GROUPING_SCHEDULE_FUNCTIONAL_SECTOR_GROUPS_FUNCTION_GROUPS_WITH_ITEMID_FAIL'

// Returns function groups of a functional sector group
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/wop.yaml#/Grouping%20Schedule/get_grouping_schedule__estimateId__functional_sector_groups__itemId__function_groups
export const getGroupingScheduleFunctionalSectorGroupsFunctionGroupsWithItemIdRequest = (
  requestArguments: getGroupingScheduleFunctionalSectorGroupsFunctionGroupsWithItemIdRequestWopArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_GROUPING_SCHEDULE_FUNCTIONAL_SECTOR_GROUPS_FUNCTION_GROUPS_WITH_ITEMID_START',
      successful: 'DATA_GET_GROUPING_SCHEDULE_FUNCTIONAL_SECTOR_GROUPS_FUNCTION_GROUPS_WITH_ITEMID_SUCCESSFUL',
      fail: 'DATA_GET_GROUPING_SCHEDULE_FUNCTIONAL_SECTOR_GROUPS_FUNCTION_GROUPS_WITH_ITEMID_FAIL'
    },
    embeddedType: {
      items: {
        schemaPath: '#/definitions/GroupingScheduleItem',
        schemaKey: 'GroupingScheduleItem'
      }
    },
    fnName: 'getGroupingScheduleFunctionalSectorGroupsFunctionGroupsWithItemIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/wop-api`,
    method: 'GET',
    url: `/grouping-schedule/${options?.estimateId || store.app.calculation}/functional-sector-groups/${requestArguments.path['itemId']}/function-groups`,
    params: {
      includeEmpty: requestArguments.query?.['includeEmpty'],
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      items: {
        schemaPath: '#/definitions/GroupingScheduleItem',
        schemaKey: 'GroupingScheduleItem'
      }
    }, 'getGroupingScheduleFunctionalSectorGroupsFunctionGroupsWithItemIdRequest', '/wop-api'),
    actions: {
      start: {
        type: DATA_GET_GROUPING_SCHEDULE_FUNCTIONAL_SECTOR_GROUPS_FUNCTION_GROUPS_WITH_ITEMID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_GROUPING_SCHEDULE_FUNCTIONAL_SECTOR_GROUPS_FUNCTION_GROUPS_WITH_ITEMID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_GROUPING_SCHEDULE_FUNCTIONAL_SECTOR_GROUPS_FUNCTION_GROUPS_WITH_ITEMID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getGroupingScheduleFunctionalSectorGroupsFunctionGroupsWithItemIdRequestDefinitions = (definitionsArguments: {| requestArgs: getGroupingScheduleFunctionalSectorGroupsFunctionGroupsWithItemIdRequestWopArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getGroupingScheduleFunctionalSectorGroupsFunctionGroupsWithItemIdRequestWopArguments, payload?: Object |} => ({
  fnName: 'getGroupingScheduleFunctionalSectorGroupsFunctionGroupsWithItemIdRequest',
  basePath: '/wop-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_GET_GROUPING_SCHEDULE_FUNCTION_GROUPS_WITH_ITEMID_START = 'DATA_GET_GROUPING_SCHEDULE_FUNCTION_GROUPS_WITH_ITEMID_START'
export const DATA_GET_GROUPING_SCHEDULE_FUNCTION_GROUPS_WITH_ITEMID_SUCCESSFUL = 'DATA_GET_GROUPING_SCHEDULE_FUNCTION_GROUPS_WITH_ITEMID_SUCCESSFUL'
export const DATA_GET_GROUPING_SCHEDULE_FUNCTION_GROUPS_WITH_ITEMID_FAIL = 'DATA_GET_GROUPING_SCHEDULE_FUNCTION_GROUPS_WITH_ITEMID_FAIL'

// Returns a function group for grouping schedule
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/wop.yaml#/Grouping%20Schedule/get_grouping_schedule__estimateId__function_groups__itemId_
export const getGroupingScheduleFunctionGroupsWithItemIdRequest = (
  requestArguments: getGroupingScheduleFunctionGroupsWithItemIdRequestWopArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_GROUPING_SCHEDULE_FUNCTION_GROUPS_WITH_ITEMID_START',
      successful: 'DATA_GET_GROUPING_SCHEDULE_FUNCTION_GROUPS_WITH_ITEMID_SUCCESSFUL',
      fail: 'DATA_GET_GROUPING_SCHEDULE_FUNCTION_GROUPS_WITH_ITEMID_FAIL'
    },
    embeddedType: {
      nonEmbedded: {
        schemaPath: '#/definitions/GroupingScheduleItem',
        schemaKey: 'GroupingScheduleItem'
      }
    },
    fnName: 'getGroupingScheduleFunctionGroupsWithItemIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/wop-api`,
    method: 'GET',
    url: `/grouping-schedule/${options?.estimateId || store.app.calculation}/function-groups/${requestArguments.path['itemId']}`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      nonEmbedded: {
        schemaPath: '#/definitions/GroupingScheduleItem',
        schemaKey: 'GroupingScheduleItem'
      }
    }, 'getGroupingScheduleFunctionGroupsWithItemIdRequest', '/wop-api'),
    actions: {
      start: {
        type: DATA_GET_GROUPING_SCHEDULE_FUNCTION_GROUPS_WITH_ITEMID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_GROUPING_SCHEDULE_FUNCTION_GROUPS_WITH_ITEMID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_GROUPING_SCHEDULE_FUNCTION_GROUPS_WITH_ITEMID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getGroupingScheduleFunctionGroupsWithItemIdRequestDefinitions = (definitionsArguments: {| requestArgs: getGroupingScheduleFunctionGroupsWithItemIdRequestWopArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getGroupingScheduleFunctionGroupsWithItemIdRequestWopArguments, payload?: Object |} => ({
  fnName: 'getGroupingScheduleFunctionGroupsWithItemIdRequest',
  basePath: '/wop-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_PATCH_GROUPING_SCHEDULE_FUNCTION_GROUPS_WITH_ITEMID_START = 'DATA_PATCH_GROUPING_SCHEDULE_FUNCTION_GROUPS_WITH_ITEMID_START'
export const DATA_PATCH_GROUPING_SCHEDULE_FUNCTION_GROUPS_WITH_ITEMID_SUCCESSFUL = 'DATA_PATCH_GROUPING_SCHEDULE_FUNCTION_GROUPS_WITH_ITEMID_SUCCESSFUL'
export const DATA_PATCH_GROUPING_SCHEDULE_FUNCTION_GROUPS_WITH_ITEMID_FAIL = 'DATA_PATCH_GROUPING_SCHEDULE_FUNCTION_GROUPS_WITH_ITEMID_FAIL'

// Update column data of a function group
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/wop.yaml#/Grouping%20Schedule/patch_grouping_schedule__estimateId__function_groups__itemId_
export const patchGroupingScheduleFunctionGroupsWithItemIdRequest = (
  requestArguments: patchGroupingScheduleFunctionGroupsWithItemIdRequestWopArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_PATCH_GROUPING_SCHEDULE_FUNCTION_GROUPS_WITH_ITEMID_START',
      successful: 'DATA_PATCH_GROUPING_SCHEDULE_FUNCTION_GROUPS_WITH_ITEMID_SUCCESSFUL',
      fail: 'DATA_PATCH_GROUPING_SCHEDULE_FUNCTION_GROUPS_WITH_ITEMID_FAIL'
    },
    embeddedType: null,
    fnName: 'patchGroupingScheduleFunctionGroupsWithItemIdRequest'
  }
  const config = {
    headers: { 'Content-Type': 'application/json' },
    baseURL: `${API_BASE_URL}/wop-api`,
    method: 'PATCH',
    url: `/grouping-schedule/${options?.estimateId || store.app.calculation}/function-groups/${requestArguments.path['itemId']}`,
    params: {
      changeOrder: requestArguments.query?.['changeOrder'],
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: requestArguments.body
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'patchGroupingScheduleFunctionGroupsWithItemIdRequest', '/wop-api'),
    actions: {
      start: {
        type: DATA_PATCH_GROUPING_SCHEDULE_FUNCTION_GROUPS_WITH_ITEMID_START, payload, config, meta
      },
      successful: {
        type: DATA_PATCH_GROUPING_SCHEDULE_FUNCTION_GROUPS_WITH_ITEMID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_PATCH_GROUPING_SCHEDULE_FUNCTION_GROUPS_WITH_ITEMID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_DELETE_GROUPING_SCHEDULE_FUNCTION_GROUPS_WITH_ITEMID_START = 'DATA_DELETE_GROUPING_SCHEDULE_FUNCTION_GROUPS_WITH_ITEMID_START'
export const DATA_DELETE_GROUPING_SCHEDULE_FUNCTION_GROUPS_WITH_ITEMID_SUCCESSFUL = 'DATA_DELETE_GROUPING_SCHEDULE_FUNCTION_GROUPS_WITH_ITEMID_SUCCESSFUL'
export const DATA_DELETE_GROUPING_SCHEDULE_FUNCTION_GROUPS_WITH_ITEMID_FAIL = 'DATA_DELETE_GROUPING_SCHEDULE_FUNCTION_GROUPS_WITH_ITEMID_FAIL'

// Delete a function group
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/wop.yaml#/Grouping%20Schedule/delete_grouping_schedule__estimateId__function_groups__itemId_
export const deleteGroupingScheduleFunctionGroupsWithItemIdRequest = (
  requestArguments: deleteGroupingScheduleFunctionGroupsWithItemIdRequestWopArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_DELETE_GROUPING_SCHEDULE_FUNCTION_GROUPS_WITH_ITEMID_START',
      successful: 'DATA_DELETE_GROUPING_SCHEDULE_FUNCTION_GROUPS_WITH_ITEMID_SUCCESSFUL',
      fail: 'DATA_DELETE_GROUPING_SCHEDULE_FUNCTION_GROUPS_WITH_ITEMID_FAIL'
    },
    embeddedType: null,
    fnName: 'deleteGroupingScheduleFunctionGroupsWithItemIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/wop-api`,
    method: 'DELETE',
    url: `/grouping-schedule/${options?.estimateId || store.app.calculation}/function-groups/${requestArguments.path['itemId']}`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'deleteGroupingScheduleFunctionGroupsWithItemIdRequest', '/wop-api'),
    actions: {
      start: {
        type: DATA_DELETE_GROUPING_SCHEDULE_FUNCTION_GROUPS_WITH_ITEMID_START, payload, config, meta
      },
      successful: {
        type: DATA_DELETE_GROUPING_SCHEDULE_FUNCTION_GROUPS_WITH_ITEMID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_DELETE_GROUPING_SCHEDULE_FUNCTION_GROUPS_WITH_ITEMID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_POST_GROUPING_SCHEDULE_FUNCTION_GROUPS_WITH_ITEMID_START = 'DATA_POST_GROUPING_SCHEDULE_FUNCTION_GROUPS_WITH_ITEMID_START'
export const DATA_POST_GROUPING_SCHEDULE_FUNCTION_GROUPS_WITH_ITEMID_SUCCESSFUL = 'DATA_POST_GROUPING_SCHEDULE_FUNCTION_GROUPS_WITH_ITEMID_SUCCESSFUL'
export const DATA_POST_GROUPING_SCHEDULE_FUNCTION_GROUPS_WITH_ITEMID_FAIL = 'DATA_POST_GROUPING_SCHEDULE_FUNCTION_GROUPS_WITH_ITEMID_FAIL'

// Add a new function group to the grouping schedule
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/wop.yaml#/Grouping%20Schedule/post_grouping_schedule__estimateId__function_groups__itemId_
export const postGroupingScheduleFunctionGroupsWithItemIdRequest = (
  requestArguments: postGroupingScheduleFunctionGroupsWithItemIdRequestWopArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_POST_GROUPING_SCHEDULE_FUNCTION_GROUPS_WITH_ITEMID_START',
      successful: 'DATA_POST_GROUPING_SCHEDULE_FUNCTION_GROUPS_WITH_ITEMID_SUCCESSFUL',
      fail: 'DATA_POST_GROUPING_SCHEDULE_FUNCTION_GROUPS_WITH_ITEMID_FAIL'
    },
    embeddedType: null,
    fnName: 'postGroupingScheduleFunctionGroupsWithItemIdRequest'
  }
  const config = {
    headers: { 'Content-Type': 'application/json' },
    baseURL: `${API_BASE_URL}/wop-api`,
    method: 'POST',
    url: `/grouping-schedule/${options?.estimateId || store.app.calculation}/function-groups/${requestArguments.path['itemId']}`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: requestArguments.body
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'postGroupingScheduleFunctionGroupsWithItemIdRequest', '/wop-api'),
    actions: {
      start: {
        type: DATA_POST_GROUPING_SCHEDULE_FUNCTION_GROUPS_WITH_ITEMID_START, payload, config, meta
      },
      successful: {
        type: DATA_POST_GROUPING_SCHEDULE_FUNCTION_GROUPS_WITH_ITEMID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_POST_GROUPING_SCHEDULE_FUNCTION_GROUPS_WITH_ITEMID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_GET_GROUPING_SCHEDULE_FUNCTION_GROUPS_CAN_MOVE_TO_WITH_ITEMID_START = 'DATA_GET_GROUPING_SCHEDULE_FUNCTION_GROUPS_CAN_MOVE_TO_WITH_ITEMID_START'
export const DATA_GET_GROUPING_SCHEDULE_FUNCTION_GROUPS_CAN_MOVE_TO_WITH_ITEMID_SUCCESSFUL = 'DATA_GET_GROUPING_SCHEDULE_FUNCTION_GROUPS_CAN_MOVE_TO_WITH_ITEMID_SUCCESSFUL'
export const DATA_GET_GROUPING_SCHEDULE_FUNCTION_GROUPS_CAN_MOVE_TO_WITH_ITEMID_FAIL = 'DATA_GET_GROUPING_SCHEDULE_FUNCTION_GROUPS_CAN_MOVE_TO_WITH_ITEMID_FAIL'

// Get id and description of functional sector groups to which the function-groups can be moved to
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/wop.yaml#/Grouping%20Schedule/get_grouping_schedule__estimateId__function_groups__itemId__can_move_to
export const getGroupingScheduleFunctionGroupsCanMoveToWithItemIdRequest = (
  requestArguments: getGroupingScheduleFunctionGroupsCanMoveToWithItemIdRequestWopArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_GROUPING_SCHEDULE_FUNCTION_GROUPS_CAN_MOVE_TO_WITH_ITEMID_START',
      successful: 'DATA_GET_GROUPING_SCHEDULE_FUNCTION_GROUPS_CAN_MOVE_TO_WITH_ITEMID_SUCCESSFUL',
      fail: 'DATA_GET_GROUPING_SCHEDULE_FUNCTION_GROUPS_CAN_MOVE_TO_WITH_ITEMID_FAIL'
    },
    embeddedType: {
      items: {
        schemaPath: '#/definitions/Enumeral',
        schemaKey: 'Enumeral'
      }
    },
    fnName: 'getGroupingScheduleFunctionGroupsCanMoveToWithItemIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/wop-api`,
    method: 'GET',
    url: `/grouping-schedule/${options?.estimateId || store.app.calculation}/function-groups/${requestArguments.path['itemId']}/can-move-to`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      items: {
        schemaPath: '#/definitions/Enumeral',
        schemaKey: 'Enumeral'
      }
    }, 'getGroupingScheduleFunctionGroupsCanMoveToWithItemIdRequest', '/wop-api'),
    actions: {
      start: {
        type: DATA_GET_GROUPING_SCHEDULE_FUNCTION_GROUPS_CAN_MOVE_TO_WITH_ITEMID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_GROUPING_SCHEDULE_FUNCTION_GROUPS_CAN_MOVE_TO_WITH_ITEMID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_GROUPING_SCHEDULE_FUNCTION_GROUPS_CAN_MOVE_TO_WITH_ITEMID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getGroupingScheduleFunctionGroupsCanMoveToWithItemIdRequestDefinitions = (definitionsArguments: {| requestArgs: getGroupingScheduleFunctionGroupsCanMoveToWithItemIdRequestWopArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getGroupingScheduleFunctionGroupsCanMoveToWithItemIdRequestWopArguments, payload?: Object |} => ({
  fnName: 'getGroupingScheduleFunctionGroupsCanMoveToWithItemIdRequest',
  basePath: '/wop-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_GET_GROUPING_SCHEDULE_FUNCTION_GROUPS_PROCESS_GROUPS_WITH_ITEMID_START = 'DATA_GET_GROUPING_SCHEDULE_FUNCTION_GROUPS_PROCESS_GROUPS_WITH_ITEMID_START'
export const DATA_GET_GROUPING_SCHEDULE_FUNCTION_GROUPS_PROCESS_GROUPS_WITH_ITEMID_SUCCESSFUL = 'DATA_GET_GROUPING_SCHEDULE_FUNCTION_GROUPS_PROCESS_GROUPS_WITH_ITEMID_SUCCESSFUL'
export const DATA_GET_GROUPING_SCHEDULE_FUNCTION_GROUPS_PROCESS_GROUPS_WITH_ITEMID_FAIL = 'DATA_GET_GROUPING_SCHEDULE_FUNCTION_GROUPS_PROCESS_GROUPS_WITH_ITEMID_FAIL'

// Returns process groups of a function group
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/wop.yaml#/Grouping%20Schedule/get_grouping_schedule__estimateId__function_groups__itemId__process_groups
export const getGroupingScheduleFunctionGroupsProcessGroupsWithItemIdRequest = (
  requestArguments: getGroupingScheduleFunctionGroupsProcessGroupsWithItemIdRequestWopArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_GROUPING_SCHEDULE_FUNCTION_GROUPS_PROCESS_GROUPS_WITH_ITEMID_START',
      successful: 'DATA_GET_GROUPING_SCHEDULE_FUNCTION_GROUPS_PROCESS_GROUPS_WITH_ITEMID_SUCCESSFUL',
      fail: 'DATA_GET_GROUPING_SCHEDULE_FUNCTION_GROUPS_PROCESS_GROUPS_WITH_ITEMID_FAIL'
    },
    embeddedType: {
      items: {
        schemaPath: '#/definitions/GroupingScheduleItem',
        schemaKey: 'GroupingScheduleItem'
      }
    },
    fnName: 'getGroupingScheduleFunctionGroupsProcessGroupsWithItemIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/wop-api`,
    method: 'GET',
    url: `/grouping-schedule/${options?.estimateId || store.app.calculation}/function-groups/${requestArguments.path['itemId']}/process-groups`,
    params: {
      includeEmpty: requestArguments.query?.['includeEmpty'],
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      items: {
        schemaPath: '#/definitions/GroupingScheduleItem',
        schemaKey: 'GroupingScheduleItem'
      }
    }, 'getGroupingScheduleFunctionGroupsProcessGroupsWithItemIdRequest', '/wop-api'),
    actions: {
      start: {
        type: DATA_GET_GROUPING_SCHEDULE_FUNCTION_GROUPS_PROCESS_GROUPS_WITH_ITEMID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_GROUPING_SCHEDULE_FUNCTION_GROUPS_PROCESS_GROUPS_WITH_ITEMID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_GROUPING_SCHEDULE_FUNCTION_GROUPS_PROCESS_GROUPS_WITH_ITEMID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getGroupingScheduleFunctionGroupsProcessGroupsWithItemIdRequestDefinitions = (definitionsArguments: {| requestArgs: getGroupingScheduleFunctionGroupsProcessGroupsWithItemIdRequestWopArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getGroupingScheduleFunctionGroupsProcessGroupsWithItemIdRequestWopArguments, payload?: Object |} => ({
  fnName: 'getGroupingScheduleFunctionGroupsProcessGroupsWithItemIdRequest',
  basePath: '/wop-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_GET_GROUPING_SCHEDULE_PROCESS_GROUPS_WITH_ITEMID_START = 'DATA_GET_GROUPING_SCHEDULE_PROCESS_GROUPS_WITH_ITEMID_START'
export const DATA_GET_GROUPING_SCHEDULE_PROCESS_GROUPS_WITH_ITEMID_SUCCESSFUL = 'DATA_GET_GROUPING_SCHEDULE_PROCESS_GROUPS_WITH_ITEMID_SUCCESSFUL'
export const DATA_GET_GROUPING_SCHEDULE_PROCESS_GROUPS_WITH_ITEMID_FAIL = 'DATA_GET_GROUPING_SCHEDULE_PROCESS_GROUPS_WITH_ITEMID_FAIL'

// Returns a process group for grouping schedule
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/wop.yaml#/Grouping%20Schedule/get_grouping_schedule__estimateId__process_groups__itemId_
export const getGroupingScheduleProcessGroupsWithItemIdRequest = (
  requestArguments: getGroupingScheduleProcessGroupsWithItemIdRequestWopArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_GROUPING_SCHEDULE_PROCESS_GROUPS_WITH_ITEMID_START',
      successful: 'DATA_GET_GROUPING_SCHEDULE_PROCESS_GROUPS_WITH_ITEMID_SUCCESSFUL',
      fail: 'DATA_GET_GROUPING_SCHEDULE_PROCESS_GROUPS_WITH_ITEMID_FAIL'
    },
    embeddedType: {
      nonEmbedded: {
        schemaPath: '#/definitions/GroupingScheduleItem',
        schemaKey: 'GroupingScheduleItem'
      }
    },
    fnName: 'getGroupingScheduleProcessGroupsWithItemIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/wop-api`,
    method: 'GET',
    url: `/grouping-schedule/${options?.estimateId || store.app.calculation}/process-groups/${requestArguments.path['itemId']}`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      nonEmbedded: {
        schemaPath: '#/definitions/GroupingScheduleItem',
        schemaKey: 'GroupingScheduleItem'
      }
    }, 'getGroupingScheduleProcessGroupsWithItemIdRequest', '/wop-api'),
    actions: {
      start: {
        type: DATA_GET_GROUPING_SCHEDULE_PROCESS_GROUPS_WITH_ITEMID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_GROUPING_SCHEDULE_PROCESS_GROUPS_WITH_ITEMID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_GROUPING_SCHEDULE_PROCESS_GROUPS_WITH_ITEMID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getGroupingScheduleProcessGroupsWithItemIdRequestDefinitions = (definitionsArguments: {| requestArgs: getGroupingScheduleProcessGroupsWithItemIdRequestWopArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getGroupingScheduleProcessGroupsWithItemIdRequestWopArguments, payload?: Object |} => ({
  fnName: 'getGroupingScheduleProcessGroupsWithItemIdRequest',
  basePath: '/wop-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_PATCH_GROUPING_SCHEDULE_PROCESS_GROUPS_WITH_ITEMID_START = 'DATA_PATCH_GROUPING_SCHEDULE_PROCESS_GROUPS_WITH_ITEMID_START'
export const DATA_PATCH_GROUPING_SCHEDULE_PROCESS_GROUPS_WITH_ITEMID_SUCCESSFUL = 'DATA_PATCH_GROUPING_SCHEDULE_PROCESS_GROUPS_WITH_ITEMID_SUCCESSFUL'
export const DATA_PATCH_GROUPING_SCHEDULE_PROCESS_GROUPS_WITH_ITEMID_FAIL = 'DATA_PATCH_GROUPING_SCHEDULE_PROCESS_GROUPS_WITH_ITEMID_FAIL'

// Update column data of a process group
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/wop.yaml#/Grouping%20Schedule/patch_grouping_schedule__estimateId__process_groups__itemId_
export const patchGroupingScheduleProcessGroupsWithItemIdRequest = (
  requestArguments: patchGroupingScheduleProcessGroupsWithItemIdRequestWopArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_PATCH_GROUPING_SCHEDULE_PROCESS_GROUPS_WITH_ITEMID_START',
      successful: 'DATA_PATCH_GROUPING_SCHEDULE_PROCESS_GROUPS_WITH_ITEMID_SUCCESSFUL',
      fail: 'DATA_PATCH_GROUPING_SCHEDULE_PROCESS_GROUPS_WITH_ITEMID_FAIL'
    },
    embeddedType: null,
    fnName: 'patchGroupingScheduleProcessGroupsWithItemIdRequest'
  }
  const config = {
    headers: { 'Content-Type': 'application/json' },
    baseURL: `${API_BASE_URL}/wop-api`,
    method: 'PATCH',
    url: `/grouping-schedule/${options?.estimateId || store.app.calculation}/process-groups/${requestArguments.path['itemId']}`,
    params: {
      changeOrder: requestArguments.query?.['changeOrder'],
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: requestArguments.body
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'patchGroupingScheduleProcessGroupsWithItemIdRequest', '/wop-api'),
    actions: {
      start: {
        type: DATA_PATCH_GROUPING_SCHEDULE_PROCESS_GROUPS_WITH_ITEMID_START, payload, config, meta
      },
      successful: {
        type: DATA_PATCH_GROUPING_SCHEDULE_PROCESS_GROUPS_WITH_ITEMID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_PATCH_GROUPING_SCHEDULE_PROCESS_GROUPS_WITH_ITEMID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_DELETE_GROUPING_SCHEDULE_PROCESS_GROUPS_WITH_ITEMID_START = 'DATA_DELETE_GROUPING_SCHEDULE_PROCESS_GROUPS_WITH_ITEMID_START'
export const DATA_DELETE_GROUPING_SCHEDULE_PROCESS_GROUPS_WITH_ITEMID_SUCCESSFUL = 'DATA_DELETE_GROUPING_SCHEDULE_PROCESS_GROUPS_WITH_ITEMID_SUCCESSFUL'
export const DATA_DELETE_GROUPING_SCHEDULE_PROCESS_GROUPS_WITH_ITEMID_FAIL = 'DATA_DELETE_GROUPING_SCHEDULE_PROCESS_GROUPS_WITH_ITEMID_FAIL'

// Delete a process group
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/wop.yaml#/Grouping%20Schedule/delete_grouping_schedule__estimateId__process_groups__itemId_
export const deleteGroupingScheduleProcessGroupsWithItemIdRequest = (
  requestArguments: deleteGroupingScheduleProcessGroupsWithItemIdRequestWopArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_DELETE_GROUPING_SCHEDULE_PROCESS_GROUPS_WITH_ITEMID_START',
      successful: 'DATA_DELETE_GROUPING_SCHEDULE_PROCESS_GROUPS_WITH_ITEMID_SUCCESSFUL',
      fail: 'DATA_DELETE_GROUPING_SCHEDULE_PROCESS_GROUPS_WITH_ITEMID_FAIL'
    },
    embeddedType: null,
    fnName: 'deleteGroupingScheduleProcessGroupsWithItemIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/wop-api`,
    method: 'DELETE',
    url: `/grouping-schedule/${options?.estimateId || store.app.calculation}/process-groups/${requestArguments.path['itemId']}`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'deleteGroupingScheduleProcessGroupsWithItemIdRequest', '/wop-api'),
    actions: {
      start: {
        type: DATA_DELETE_GROUPING_SCHEDULE_PROCESS_GROUPS_WITH_ITEMID_START, payload, config, meta
      },
      successful: {
        type: DATA_DELETE_GROUPING_SCHEDULE_PROCESS_GROUPS_WITH_ITEMID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_DELETE_GROUPING_SCHEDULE_PROCESS_GROUPS_WITH_ITEMID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_POST_GROUPING_SCHEDULE_PROCESS_GROUPS_WITH_ITEMID_START = 'DATA_POST_GROUPING_SCHEDULE_PROCESS_GROUPS_WITH_ITEMID_START'
export const DATA_POST_GROUPING_SCHEDULE_PROCESS_GROUPS_WITH_ITEMID_SUCCESSFUL = 'DATA_POST_GROUPING_SCHEDULE_PROCESS_GROUPS_WITH_ITEMID_SUCCESSFUL'
export const DATA_POST_GROUPING_SCHEDULE_PROCESS_GROUPS_WITH_ITEMID_FAIL = 'DATA_POST_GROUPING_SCHEDULE_PROCESS_GROUPS_WITH_ITEMID_FAIL'

// Add a new process group to the grouping schedule
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/wop.yaml#/Grouping%20Schedule/post_grouping_schedule__estimateId__process_groups__itemId_
export const postGroupingScheduleProcessGroupsWithItemIdRequest = (
  requestArguments: postGroupingScheduleProcessGroupsWithItemIdRequestWopArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_POST_GROUPING_SCHEDULE_PROCESS_GROUPS_WITH_ITEMID_START',
      successful: 'DATA_POST_GROUPING_SCHEDULE_PROCESS_GROUPS_WITH_ITEMID_SUCCESSFUL',
      fail: 'DATA_POST_GROUPING_SCHEDULE_PROCESS_GROUPS_WITH_ITEMID_FAIL'
    },
    embeddedType: null,
    fnName: 'postGroupingScheduleProcessGroupsWithItemIdRequest'
  }
  const config = {
    headers: { 'Content-Type': 'application/json' },
    baseURL: `${API_BASE_URL}/wop-api`,
    method: 'POST',
    url: `/grouping-schedule/${options?.estimateId || store.app.calculation}/process-groups/${requestArguments.path['itemId']}`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: requestArguments.body
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'postGroupingScheduleProcessGroupsWithItemIdRequest', '/wop-api'),
    actions: {
      start: {
        type: DATA_POST_GROUPING_SCHEDULE_PROCESS_GROUPS_WITH_ITEMID_START, payload, config, meta
      },
      successful: {
        type: DATA_POST_GROUPING_SCHEDULE_PROCESS_GROUPS_WITH_ITEMID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_POST_GROUPING_SCHEDULE_PROCESS_GROUPS_WITH_ITEMID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_GET_GROUPING_SCHEDULE_PROCESS_GROUPS_PROCESSES_WITH_ITEMID_START = 'DATA_GET_GROUPING_SCHEDULE_PROCESS_GROUPS_PROCESSES_WITH_ITEMID_START'
export const DATA_GET_GROUPING_SCHEDULE_PROCESS_GROUPS_PROCESSES_WITH_ITEMID_SUCCESSFUL = 'DATA_GET_GROUPING_SCHEDULE_PROCESS_GROUPS_PROCESSES_WITH_ITEMID_SUCCESSFUL'
export const DATA_GET_GROUPING_SCHEDULE_PROCESS_GROUPS_PROCESSES_WITH_ITEMID_FAIL = 'DATA_GET_GROUPING_SCHEDULE_PROCESS_GROUPS_PROCESSES_WITH_ITEMID_FAIL'

// Returns processes of a process group
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/wop.yaml#/Grouping%20Schedule/get_grouping_schedule__estimateId__process_groups__itemId__processes
export const getGroupingScheduleProcessGroupsProcessesWithItemIdRequest = (
  requestArguments: getGroupingScheduleProcessGroupsProcessesWithItemIdRequestWopArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_GROUPING_SCHEDULE_PROCESS_GROUPS_PROCESSES_WITH_ITEMID_START',
      successful: 'DATA_GET_GROUPING_SCHEDULE_PROCESS_GROUPS_PROCESSES_WITH_ITEMID_SUCCESSFUL',
      fail: 'DATA_GET_GROUPING_SCHEDULE_PROCESS_GROUPS_PROCESSES_WITH_ITEMID_FAIL'
    },
    embeddedType: {
      items: {
        schemaPath: '#/definitions/GroupingScheduleItem',
        schemaKey: 'GroupingScheduleItem'
      }
    },
    fnName: 'getGroupingScheduleProcessGroupsProcessesWithItemIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/wop-api`,
    method: 'GET',
    url: `/grouping-schedule/${options?.estimateId || store.app.calculation}/process-groups/${requestArguments.path['itemId']}/processes`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      items: {
        schemaPath: '#/definitions/GroupingScheduleItem',
        schemaKey: 'GroupingScheduleItem'
      }
    }, 'getGroupingScheduleProcessGroupsProcessesWithItemIdRequest', '/wop-api'),
    actions: {
      start: {
        type: DATA_GET_GROUPING_SCHEDULE_PROCESS_GROUPS_PROCESSES_WITH_ITEMID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_GROUPING_SCHEDULE_PROCESS_GROUPS_PROCESSES_WITH_ITEMID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_GROUPING_SCHEDULE_PROCESS_GROUPS_PROCESSES_WITH_ITEMID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getGroupingScheduleProcessGroupsProcessesWithItemIdRequestDefinitions = (definitionsArguments: {| requestArgs: getGroupingScheduleProcessGroupsProcessesWithItemIdRequestWopArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getGroupingScheduleProcessGroupsProcessesWithItemIdRequestWopArguments, payload?: Object |} => ({
  fnName: 'getGroupingScheduleProcessGroupsProcessesWithItemIdRequest',
  basePath: '/wop-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_GET_GROUPING_SCHEDULE_PROCESS_GROUPS_CAN_MOVE_TO_WITH_ITEMID_START = 'DATA_GET_GROUPING_SCHEDULE_PROCESS_GROUPS_CAN_MOVE_TO_WITH_ITEMID_START'
export const DATA_GET_GROUPING_SCHEDULE_PROCESS_GROUPS_CAN_MOVE_TO_WITH_ITEMID_SUCCESSFUL = 'DATA_GET_GROUPING_SCHEDULE_PROCESS_GROUPS_CAN_MOVE_TO_WITH_ITEMID_SUCCESSFUL'
export const DATA_GET_GROUPING_SCHEDULE_PROCESS_GROUPS_CAN_MOVE_TO_WITH_ITEMID_FAIL = 'DATA_GET_GROUPING_SCHEDULE_PROCESS_GROUPS_CAN_MOVE_TO_WITH_ITEMID_FAIL'

// Get id and description of function groups to which the process-groups can be moved to
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/wop.yaml#/Grouping%20Schedule/get_grouping_schedule__estimateId__process_groups__itemId__can_move_to
export const getGroupingScheduleProcessGroupsCanMoveToWithItemIdRequest = (
  requestArguments: getGroupingScheduleProcessGroupsCanMoveToWithItemIdRequestWopArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_GROUPING_SCHEDULE_PROCESS_GROUPS_CAN_MOVE_TO_WITH_ITEMID_START',
      successful: 'DATA_GET_GROUPING_SCHEDULE_PROCESS_GROUPS_CAN_MOVE_TO_WITH_ITEMID_SUCCESSFUL',
      fail: 'DATA_GET_GROUPING_SCHEDULE_PROCESS_GROUPS_CAN_MOVE_TO_WITH_ITEMID_FAIL'
    },
    embeddedType: {
      items: {
        schemaPath: '#/definitions/Enumeral',
        schemaKey: 'Enumeral'
      }
    },
    fnName: 'getGroupingScheduleProcessGroupsCanMoveToWithItemIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/wop-api`,
    method: 'GET',
    url: `/grouping-schedule/${options?.estimateId || store.app.calculation}/process-groups/${requestArguments.path['itemId']}/can-move-to`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      items: {
        schemaPath: '#/definitions/Enumeral',
        schemaKey: 'Enumeral'
      }
    }, 'getGroupingScheduleProcessGroupsCanMoveToWithItemIdRequest', '/wop-api'),
    actions: {
      start: {
        type: DATA_GET_GROUPING_SCHEDULE_PROCESS_GROUPS_CAN_MOVE_TO_WITH_ITEMID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_GROUPING_SCHEDULE_PROCESS_GROUPS_CAN_MOVE_TO_WITH_ITEMID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_GROUPING_SCHEDULE_PROCESS_GROUPS_CAN_MOVE_TO_WITH_ITEMID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getGroupingScheduleProcessGroupsCanMoveToWithItemIdRequestDefinitions = (definitionsArguments: {| requestArgs: getGroupingScheduleProcessGroupsCanMoveToWithItemIdRequestWopArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getGroupingScheduleProcessGroupsCanMoveToWithItemIdRequestWopArguments, payload?: Object |} => ({
  fnName: 'getGroupingScheduleProcessGroupsCanMoveToWithItemIdRequest',
  basePath: '/wop-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_GET_GROUPING_SCHEDULE_PROCESSES_WITH_ITEMID_START = 'DATA_GET_GROUPING_SCHEDULE_PROCESSES_WITH_ITEMID_START'
export const DATA_GET_GROUPING_SCHEDULE_PROCESSES_WITH_ITEMID_SUCCESSFUL = 'DATA_GET_GROUPING_SCHEDULE_PROCESSES_WITH_ITEMID_SUCCESSFUL'
export const DATA_GET_GROUPING_SCHEDULE_PROCESSES_WITH_ITEMID_FAIL = 'DATA_GET_GROUPING_SCHEDULE_PROCESSES_WITH_ITEMID_FAIL'

// Returns a process for grouping schedule
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/wop.yaml#/Grouping%20Schedule/get_grouping_schedule__estimateId__processes__itemId_
export const getGroupingScheduleProcessesWithItemIdRequest = (
  requestArguments: getGroupingScheduleProcessesWithItemIdRequestWopArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_GROUPING_SCHEDULE_PROCESSES_WITH_ITEMID_START',
      successful: 'DATA_GET_GROUPING_SCHEDULE_PROCESSES_WITH_ITEMID_SUCCESSFUL',
      fail: 'DATA_GET_GROUPING_SCHEDULE_PROCESSES_WITH_ITEMID_FAIL'
    },
    embeddedType: {
      nonEmbedded: {
        schemaPath: '#/definitions/GroupingScheduleItem',
        schemaKey: 'GroupingScheduleItem'
      }
    },
    fnName: 'getGroupingScheduleProcessesWithItemIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/wop-api`,
    method: 'GET',
    url: `/grouping-schedule/${options?.estimateId || store.app.calculation}/processes/${requestArguments.path['itemId']}`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      nonEmbedded: {
        schemaPath: '#/definitions/GroupingScheduleItem',
        schemaKey: 'GroupingScheduleItem'
      }
    }, 'getGroupingScheduleProcessesWithItemIdRequest', '/wop-api'),
    actions: {
      start: {
        type: DATA_GET_GROUPING_SCHEDULE_PROCESSES_WITH_ITEMID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_GROUPING_SCHEDULE_PROCESSES_WITH_ITEMID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_GROUPING_SCHEDULE_PROCESSES_WITH_ITEMID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getGroupingScheduleProcessesWithItemIdRequestDefinitions = (definitionsArguments: {| requestArgs: getGroupingScheduleProcessesWithItemIdRequestWopArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getGroupingScheduleProcessesWithItemIdRequestWopArguments, payload?: Object |} => ({
  fnName: 'getGroupingScheduleProcessesWithItemIdRequest',
  basePath: '/wop-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_PATCH_GROUPING_SCHEDULE_PROCESSES_WITH_ITEMID_START = 'DATA_PATCH_GROUPING_SCHEDULE_PROCESSES_WITH_ITEMID_START'
export const DATA_PATCH_GROUPING_SCHEDULE_PROCESSES_WITH_ITEMID_SUCCESSFUL = 'DATA_PATCH_GROUPING_SCHEDULE_PROCESSES_WITH_ITEMID_SUCCESSFUL'
export const DATA_PATCH_GROUPING_SCHEDULE_PROCESSES_WITH_ITEMID_FAIL = 'DATA_PATCH_GROUPING_SCHEDULE_PROCESSES_WITH_ITEMID_FAIL'

// Update column data of a process
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/wop.yaml#/Grouping%20Schedule/patch_grouping_schedule__estimateId__processes__itemId_
export const patchGroupingScheduleProcessesWithItemIdRequest = (
  requestArguments: patchGroupingScheduleProcessesWithItemIdRequestWopArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_PATCH_GROUPING_SCHEDULE_PROCESSES_WITH_ITEMID_START',
      successful: 'DATA_PATCH_GROUPING_SCHEDULE_PROCESSES_WITH_ITEMID_SUCCESSFUL',
      fail: 'DATA_PATCH_GROUPING_SCHEDULE_PROCESSES_WITH_ITEMID_FAIL'
    },
    embeddedType: null,
    fnName: 'patchGroupingScheduleProcessesWithItemIdRequest'
  }
  const config = {
    headers: { 'Content-Type': 'application/json' },
    baseURL: `${API_BASE_URL}/wop-api`,
    method: 'PATCH',
    url: `/grouping-schedule/${options?.estimateId || store.app.calculation}/processes/${requestArguments.path['itemId']}`,
    params: {
      changeOrder: requestArguments.query?.['changeOrder'],
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: requestArguments.body
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'patchGroupingScheduleProcessesWithItemIdRequest', '/wop-api'),
    actions: {
      start: {
        type: DATA_PATCH_GROUPING_SCHEDULE_PROCESSES_WITH_ITEMID_START, payload, config, meta
      },
      successful: {
        type: DATA_PATCH_GROUPING_SCHEDULE_PROCESSES_WITH_ITEMID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_PATCH_GROUPING_SCHEDULE_PROCESSES_WITH_ITEMID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_DELETE_GROUPING_SCHEDULE_PROCESSES_WITH_ITEMID_START = 'DATA_DELETE_GROUPING_SCHEDULE_PROCESSES_WITH_ITEMID_START'
export const DATA_DELETE_GROUPING_SCHEDULE_PROCESSES_WITH_ITEMID_SUCCESSFUL = 'DATA_DELETE_GROUPING_SCHEDULE_PROCESSES_WITH_ITEMID_SUCCESSFUL'
export const DATA_DELETE_GROUPING_SCHEDULE_PROCESSES_WITH_ITEMID_FAIL = 'DATA_DELETE_GROUPING_SCHEDULE_PROCESSES_WITH_ITEMID_FAIL'

// Delete a process
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/wop.yaml#/Grouping%20Schedule/delete_grouping_schedule__estimateId__processes__itemId_
export const deleteGroupingScheduleProcessesWithItemIdRequest = (
  requestArguments: deleteGroupingScheduleProcessesWithItemIdRequestWopArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_DELETE_GROUPING_SCHEDULE_PROCESSES_WITH_ITEMID_START',
      successful: 'DATA_DELETE_GROUPING_SCHEDULE_PROCESSES_WITH_ITEMID_SUCCESSFUL',
      fail: 'DATA_DELETE_GROUPING_SCHEDULE_PROCESSES_WITH_ITEMID_FAIL'
    },
    embeddedType: null,
    fnName: 'deleteGroupingScheduleProcessesWithItemIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/wop-api`,
    method: 'DELETE',
    url: `/grouping-schedule/${options?.estimateId || store.app.calculation}/processes/${requestArguments.path['itemId']}`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'deleteGroupingScheduleProcessesWithItemIdRequest', '/wop-api'),
    actions: {
      start: {
        type: DATA_DELETE_GROUPING_SCHEDULE_PROCESSES_WITH_ITEMID_START, payload, config, meta
      },
      successful: {
        type: DATA_DELETE_GROUPING_SCHEDULE_PROCESSES_WITH_ITEMID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_DELETE_GROUPING_SCHEDULE_PROCESSES_WITH_ITEMID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_GET_GROUPING_SCHEDULE_PROCESSES_CAN_MOVE_TO_WITH_ITEMID_START = 'DATA_GET_GROUPING_SCHEDULE_PROCESSES_CAN_MOVE_TO_WITH_ITEMID_START'
export const DATA_GET_GROUPING_SCHEDULE_PROCESSES_CAN_MOVE_TO_WITH_ITEMID_SUCCESSFUL = 'DATA_GET_GROUPING_SCHEDULE_PROCESSES_CAN_MOVE_TO_WITH_ITEMID_SUCCESSFUL'
export const DATA_GET_GROUPING_SCHEDULE_PROCESSES_CAN_MOVE_TO_WITH_ITEMID_FAIL = 'DATA_GET_GROUPING_SCHEDULE_PROCESSES_CAN_MOVE_TO_WITH_ITEMID_FAIL'

// Get id and description of  porocess groups to which the processes can be moved to
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/wop.yaml#/Grouping%20Schedule/get_grouping_schedule__estimateId__processes__itemId__can_move_to
export const getGroupingScheduleProcessesCanMoveToWithItemIdRequest = (
  requestArguments: getGroupingScheduleProcessesCanMoveToWithItemIdRequestWopArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_GROUPING_SCHEDULE_PROCESSES_CAN_MOVE_TO_WITH_ITEMID_START',
      successful: 'DATA_GET_GROUPING_SCHEDULE_PROCESSES_CAN_MOVE_TO_WITH_ITEMID_SUCCESSFUL',
      fail: 'DATA_GET_GROUPING_SCHEDULE_PROCESSES_CAN_MOVE_TO_WITH_ITEMID_FAIL'
    },
    embeddedType: {
      items: {
        schemaPath: '#/definitions/Enumeral',
        schemaKey: 'Enumeral'
      }
    },
    fnName: 'getGroupingScheduleProcessesCanMoveToWithItemIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/wop-api`,
    method: 'GET',
    url: `/grouping-schedule/${options?.estimateId || store.app.calculation}/processes/${requestArguments.path['itemId']}/can-move-to`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      items: {
        schemaPath: '#/definitions/Enumeral',
        schemaKey: 'Enumeral'
      }
    }, 'getGroupingScheduleProcessesCanMoveToWithItemIdRequest', '/wop-api'),
    actions: {
      start: {
        type: DATA_GET_GROUPING_SCHEDULE_PROCESSES_CAN_MOVE_TO_WITH_ITEMID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_GROUPING_SCHEDULE_PROCESSES_CAN_MOVE_TO_WITH_ITEMID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_GROUPING_SCHEDULE_PROCESSES_CAN_MOVE_TO_WITH_ITEMID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getGroupingScheduleProcessesCanMoveToWithItemIdRequestDefinitions = (definitionsArguments: {| requestArgs: getGroupingScheduleProcessesCanMoveToWithItemIdRequestWopArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getGroupingScheduleProcessesCanMoveToWithItemIdRequestWopArguments, payload?: Object |} => ({
  fnName: 'getGroupingScheduleProcessesCanMoveToWithItemIdRequest',
  basePath: '/wop-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_GET_ESTIMATE_WITH_ESTIMATEID_START = 'DATA_GET_ESTIMATE_WITH_ESTIMATEID_START'
export const DATA_GET_ESTIMATE_WITH_ESTIMATEID_SUCCESSFUL = 'DATA_GET_ESTIMATE_WITH_ESTIMATEID_SUCCESSFUL'
export const DATA_GET_ESTIMATE_WITH_ESTIMATEID_FAIL = 'DATA_GET_ESTIMATE_WITH_ESTIMATEID_FAIL'

// Returns a WOP estimate
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/wop.yaml#/WOP%20Estimate/get_estimate__estimateId_
export const getEstimateWithEstimateIdRequest = (
  payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_ESTIMATE_WITH_ESTIMATEID_START',
      successful: 'DATA_GET_ESTIMATE_WITH_ESTIMATEID_SUCCESSFUL',
      fail: 'DATA_GET_ESTIMATE_WITH_ESTIMATEID_FAIL'
    },
    embeddedType: {
      nonEmbedded: {
        schemaPath: '#/definitions/Estimate',
        schemaKey: 'Estimate'
      }
    },
    fnName: 'getEstimateWithEstimateIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/wop-api`,
    method: 'GET',
    url: `/estimate/${options?.estimateId || store.app.calculation}`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      nonEmbedded: {
        schemaPath: '#/definitions/Estimate',
        schemaKey: 'Estimate'
      }
    }, 'getEstimateWithEstimateIdRequest', '/wop-api'),
    actions: {
      start: {
        type: DATA_GET_ESTIMATE_WITH_ESTIMATEID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_ESTIMATE_WITH_ESTIMATEID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_ESTIMATE_WITH_ESTIMATEID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getEstimateWithEstimateIdRequestDefinitions = (definitionsArguments: {| payload?: Object |}): {|
  ...TVDGARConfigWithoutRequestArgs, payload?: Object |} => ({
  fnName: 'getEstimateWithEstimateIdRequest',
  basePath: '/wop-api',
  payload: definitionsArguments.payload,
})


export const DATA_GET_ESTIMATE_LOCK_WITH_ESTIMATEID_START = 'DATA_GET_ESTIMATE_LOCK_WITH_ESTIMATEID_START'
export const DATA_GET_ESTIMATE_LOCK_WITH_ESTIMATEID_SUCCESSFUL = 'DATA_GET_ESTIMATE_LOCK_WITH_ESTIMATEID_SUCCESSFUL'
export const DATA_GET_ESTIMATE_LOCK_WITH_ESTIMATEID_FAIL = 'DATA_GET_ESTIMATE_LOCK_WITH_ESTIMATEID_FAIL'

// If resource is locked, returns information (owner, expiration) about the current lock
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/wop.yaml#/WOP%20Estimate/get_estimate__estimateId__lock
export const getEstimateLockWithEstimateIdRequest = (
  payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_ESTIMATE_LOCK_WITH_ESTIMATEID_START',
      successful: 'DATA_GET_ESTIMATE_LOCK_WITH_ESTIMATEID_SUCCESSFUL',
      fail: 'DATA_GET_ESTIMATE_LOCK_WITH_ESTIMATEID_FAIL'
    },
    embeddedType: {
      nonEmbedded: {
        schemaPath: '#/definitions/ResourceLock',
        schemaKey: 'ResourceLock'
      }
    },
    fnName: 'getEstimateLockWithEstimateIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/wop-api`,
    method: 'GET',
    url: `/estimate/${options?.estimateId || store.app.calculation}/lock`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      nonEmbedded: {
        schemaPath: '#/definitions/ResourceLock',
        schemaKey: 'ResourceLock'
      }
    }, 'getEstimateLockWithEstimateIdRequest', '/wop-api'),
    actions: {
      start: {
        type: DATA_GET_ESTIMATE_LOCK_WITH_ESTIMATEID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_ESTIMATE_LOCK_WITH_ESTIMATEID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_ESTIMATE_LOCK_WITH_ESTIMATEID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getEstimateLockWithEstimateIdRequestDefinitions = (definitionsArguments: {| payload?: Object |}): {|
  ...TVDGARConfigWithoutRequestArgs, payload?: Object |} => ({
  fnName: 'getEstimateLockWithEstimateIdRequest',
  basePath: '/wop-api',
  payload: definitionsArguments.payload,
})


export const DATA_PUT_ESTIMATE_LOCK_WITH_ESTIMATEID_START = 'DATA_PUT_ESTIMATE_LOCK_WITH_ESTIMATEID_START'
export const DATA_PUT_ESTIMATE_LOCK_WITH_ESTIMATEID_SUCCESSFUL = 'DATA_PUT_ESTIMATE_LOCK_WITH_ESTIMATEID_SUCCESSFUL'
export const DATA_PUT_ESTIMATE_LOCK_WITH_ESTIMATEID_FAIL = 'DATA_PUT_ESTIMATE_LOCK_WITH_ESTIMATEID_FAIL'

// Obtain a lock to a resource for editing. Edits are not permitted before first obtaining a lock. If another user has already locked the resource, Resource Locked Error (423) is returned.
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/wop.yaml#/WOP%20Estimate/put_estimate__estimateId__lock
export const putEstimateLockWithEstimateIdRequest = (
  requestArguments: putEstimateLockWithEstimateIdRequestWopArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_PUT_ESTIMATE_LOCK_WITH_ESTIMATEID_START',
      successful: 'DATA_PUT_ESTIMATE_LOCK_WITH_ESTIMATEID_SUCCESSFUL',
      fail: 'DATA_PUT_ESTIMATE_LOCK_WITH_ESTIMATEID_FAIL'
    },
    embeddedType: null,
    fnName: 'putEstimateLockWithEstimateIdRequest'
  }
  const config = {
    headers: { 'Content-Type': 'application/json' },
    baseURL: `${API_BASE_URL}/wop-api`,
    method: 'PUT',
    url: `/estimate/${options?.estimateId || store.app.calculation}/lock`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: requestArguments.body
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'putEstimateLockWithEstimateIdRequest', '/wop-api'),
    actions: {
      start: {
        type: DATA_PUT_ESTIMATE_LOCK_WITH_ESTIMATEID_START, payload, config, meta
      },
      successful: {
        type: DATA_PUT_ESTIMATE_LOCK_WITH_ESTIMATEID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_PUT_ESTIMATE_LOCK_WITH_ESTIMATEID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_DELETE_ESTIMATE_LOCK_WITH_ESTIMATEID_START = 'DATA_DELETE_ESTIMATE_LOCK_WITH_ESTIMATEID_START'
export const DATA_DELETE_ESTIMATE_LOCK_WITH_ESTIMATEID_SUCCESSFUL = 'DATA_DELETE_ESTIMATE_LOCK_WITH_ESTIMATEID_SUCCESSFUL'
export const DATA_DELETE_ESTIMATE_LOCK_WITH_ESTIMATEID_FAIL = 'DATA_DELETE_ESTIMATE_LOCK_WITH_ESTIMATEID_FAIL'

// Deletes a resource lock. If the lock was created by another user, error 423 is returned.
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/wop.yaml#/WOP%20Estimate/delete_estimate__estimateId__lock
export const deleteEstimateLockWithEstimateIdRequest = (
  payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_DELETE_ESTIMATE_LOCK_WITH_ESTIMATEID_START',
      successful: 'DATA_DELETE_ESTIMATE_LOCK_WITH_ESTIMATEID_SUCCESSFUL',
      fail: 'DATA_DELETE_ESTIMATE_LOCK_WITH_ESTIMATEID_FAIL'
    },
    embeddedType: null,
    fnName: 'deleteEstimateLockWithEstimateIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/wop-api`,
    method: 'DELETE',
    url: `/estimate/${options?.estimateId || store.app.calculation}/lock`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'deleteEstimateLockWithEstimateIdRequest', '/wop-api'),
    actions: {
      start: {
        type: DATA_DELETE_ESTIMATE_LOCK_WITH_ESTIMATEID_START, payload, config, meta
      },
      successful: {
        type: DATA_DELETE_ESTIMATE_LOCK_WITH_ESTIMATEID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_DELETE_ESTIMATE_LOCK_WITH_ESTIMATEID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_GET_OPERATING_PROFILE_SCHEDULE_WITH_ESTIMATEID_START = 'DATA_GET_OPERATING_PROFILE_SCHEDULE_WITH_ESTIMATEID_START'
export const DATA_GET_OPERATING_PROFILE_SCHEDULE_WITH_ESTIMATEID_SUCCESSFUL = 'DATA_GET_OPERATING_PROFILE_SCHEDULE_WITH_ESTIMATEID_SUCCESSFUL'
export const DATA_GET_OPERATING_PROFILE_SCHEDULE_WITH_ESTIMATEID_FAIL = 'DATA_GET_OPERATING_PROFILE_SCHEDULE_WITH_ESTIMATEID_FAIL'

// Gets a schedule of available operating profiles for an estimate. List type is flat because there is no hierarchy.
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/wop.yaml#/Operating%20Profiles/get_operating_profile_schedule__estimateId_
export const getOperatingProfileScheduleWithEstimateIdRequest = (
  payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_OPERATING_PROFILE_SCHEDULE_WITH_ESTIMATEID_START',
      successful: 'DATA_GET_OPERATING_PROFILE_SCHEDULE_WITH_ESTIMATEID_SUCCESSFUL',
      fail: 'DATA_GET_OPERATING_PROFILE_SCHEDULE_WITH_ESTIMATEID_FAIL'
    },
    embeddedType: {
      items: {
        schemaPath: '#/definitions/OperatingProfileScheduleItem',
        schemaKey: 'OperatingProfileScheduleItem'
      }
    },
    fnName: 'getOperatingProfileScheduleWithEstimateIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/wop-api`,
    method: 'GET',
    url: `/operating-profile-schedule/${options?.estimateId || store.app.calculation}`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      items: {
        schemaPath: '#/definitions/OperatingProfileScheduleItem',
        schemaKey: 'OperatingProfileScheduleItem'
      }
    }, 'getOperatingProfileScheduleWithEstimateIdRequest', '/wop-api'),
    actions: {
      start: {
        type: DATA_GET_OPERATING_PROFILE_SCHEDULE_WITH_ESTIMATEID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_OPERATING_PROFILE_SCHEDULE_WITH_ESTIMATEID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_OPERATING_PROFILE_SCHEDULE_WITH_ESTIMATEID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getOperatingProfileScheduleWithEstimateIdRequestDefinitions = (definitionsArguments: {| payload?: Object |}): {|
  ...TVDGARConfigWithoutRequestArgs, payload?: Object |} => ({
  fnName: 'getOperatingProfileScheduleWithEstimateIdRequest',
  basePath: '/wop-api',
  payload: definitionsArguments.payload,
})


export const DATA_GET_REPORTS_START = 'DATA_GET_REPORTS_START'
export const DATA_GET_REPORTS_SUCCESSFUL = 'DATA_GET_REPORTS_SUCCESSFUL'
export const DATA_GET_REPORTS_FAIL = 'DATA_GET_REPORTS_FAIL'

// Returns a list of available report types as Enumerals with reportType as value and a localized name for the type.
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/wop.yaml#/Reports/get_reports
export const getReportsRequest = (
  payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_REPORTS_START',
      successful: 'DATA_GET_REPORTS_SUCCESSFUL',
      fail: 'DATA_GET_REPORTS_FAIL'
    },
    embeddedType: {
      items: {
        schemaPath: '#/definitions/Enumeral',
        schemaKey: 'Enumeral'
      }
    },
    fnName: 'getReportsRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/wop-api`,
    method: 'GET',
    url: '/reports',
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      items: {
        schemaPath: '#/definitions/Enumeral',
        schemaKey: 'Enumeral'
      }
    }, 'getReportsRequest', '/wop-api'),
    actions: {
      start: {
        type: DATA_GET_REPORTS_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_REPORTS_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_REPORTS_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getReportsRequestDefinitions = (definitionsArguments: {| payload?: Object |}): {|
  ...TVDGARConfigWithoutRequestArgs, payload?: Object |} => ({
  fnName: 'getReportsRequest',
  basePath: '/wop-api',
  payload: definitionsArguments.payload,
})


export const DATA_GET_REPORTS_WITH_REPORTTYPE_START = 'DATA_GET_REPORTS_WITH_REPORTTYPE_START'
export const DATA_GET_REPORTS_WITH_REPORTTYPE_SUCCESSFUL = 'DATA_GET_REPORTS_WITH_REPORTTYPE_SUCCESSFUL'
export const DATA_GET_REPORTS_WITH_REPORTTYPE_FAIL = 'DATA_GET_REPORTS_WITH_REPORTTYPE_FAIL'

// Returns a pdf report of given type for requested estimate.
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/wop.yaml#/Reports/get_reports__reportType___estimateId_
export const getReportsWithReportTypeRequest = (
  requestArguments: getReportsWithReportTypeRequestWopArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_REPORTS_WITH_REPORTTYPE_START',
      successful: 'DATA_GET_REPORTS_WITH_REPORTTYPE_SUCCESSFUL',
      fail: 'DATA_GET_REPORTS_WITH_REPORTTYPE_FAIL'
    },
    embeddedType: null,
    fnName: 'getReportsWithReportTypeRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/wop-api`,
    method: 'GET',
    url: `/reports/${requestArguments.path['reportType']}/${options?.estimateId || store.app.calculation}`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'getReportsWithReportTypeRequest', '/wop-api'),
    actions: {
      start: {
        type: DATA_GET_REPORTS_WITH_REPORTTYPE_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_REPORTS_WITH_REPORTTYPE_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_REPORTS_WITH_REPORTTYPE_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getReportsWithReportTypeRequestDefinitions = (definitionsArguments: {| requestArgs: getReportsWithReportTypeRequestWopArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getReportsWithReportTypeRequestWopArguments, payload?: Object |} => ({
  fnName: 'getReportsWithReportTypeRequest',
  basePath: '/wop-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_GET_ENUM_WITH_ENUM_START = 'DATA_GET_ENUM_WITH_ENUM_START'
export const DATA_GET_ENUM_WITH_ENUM_SUCCESSFUL = 'DATA_GET_ENUM_WITH_ENUM_SUCCESSFUL'
export const DATA_GET_ENUM_WITH_ENUM_FAIL = 'DATA_GET_ENUM_WITH_ENUM_FAIL'

// Returns members of given enum as embedded Enumerals
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/wop.yaml#/Enum/get_enum__enum_
export const getEnumWithEnumRequest = (
  requestArguments: getEnumWithEnumRequestWopArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_ENUM_WITH_ENUM_START',
      successful: 'DATA_GET_ENUM_WITH_ENUM_SUCCESSFUL',
      fail: 'DATA_GET_ENUM_WITH_ENUM_FAIL'
    },
    embeddedType: {
      enumeral: {
        schemaPath: '#/definitions/Enumeral',
        schemaKey: 'Enumeral'
      }
    },
    fnName: 'getEnumWithEnumRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/wop-api`,
    method: 'GET',
    url: `/enum/${requestArguments.path['enumParam']}`,
    params: {
      category: requestArguments.query?.['category'],
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      enumeral: {
        schemaPath: '#/definitions/Enumeral',
        schemaKey: 'Enumeral'
      }
    }, 'getEnumWithEnumRequest', '/wop-api'),
    actions: {
      start: {
        type: DATA_GET_ENUM_WITH_ENUM_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_ENUM_WITH_ENUM_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_ENUM_WITH_ENUM_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getEnumWithEnumRequestDefinitions = (definitionsArguments: {| requestArgs: getEnumWithEnumRequestWopArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getEnumWithEnumRequestWopArguments, payload?: Object |} => ({
  fnName: 'getEnumWithEnumRequest',
  basePath: '/wop-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_GET_SWAGGER_START = 'DATA_GET_SWAGGER_START'
export const DATA_GET_SWAGGER_SUCCESSFUL = 'DATA_GET_SWAGGER_SUCCESSFUL'
export const DATA_GET_SWAGGER_FAIL = 'DATA_GET_SWAGGER_FAIL'

// Returns Swagger specification of the API
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/wop.yaml#/Swagger/get_swagger
export const getSwaggerRequest = (
  payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_SWAGGER_START',
      successful: 'DATA_GET_SWAGGER_SUCCESSFUL',
      fail: 'DATA_GET_SWAGGER_FAIL'
    },
    embeddedType: null,
    fnName: 'getSwaggerRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/wop-api`,
    method: 'GET',
    url: '/swagger',
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'getSwaggerRequest', '/wop-api'),
    actions: {
      start: {
        type: DATA_GET_SWAGGER_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_SWAGGER_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_SWAGGER_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getSwaggerRequestDefinitions = (definitionsArguments: {| payload?: Object |}): {|
  ...TVDGARConfigWithoutRequestArgs, payload?: Object |} => ({
  fnName: 'getSwaggerRequest',
  basePath: '/wop-api',
  payload: definitionsArguments.payload,
})


export const DATA_GET_SWAGGER_WITH_NODE_START = 'DATA_GET_SWAGGER_WITH_NODE_START'
export const DATA_GET_SWAGGER_WITH_NODE_SUCCESSFUL = 'DATA_GET_SWAGGER_WITH_NODE_SUCCESSFUL'
export const DATA_GET_SWAGGER_WITH_NODE_FAIL = 'DATA_GET_SWAGGER_WITH_NODE_FAIL'

// Returns a first level node from the Swagger specification of the API
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/wop.yaml#/Swagger/get_swagger__node_
export const getSwaggerWithNodeRequest = (
  requestArguments: getSwaggerWithNodeRequestWopArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_SWAGGER_WITH_NODE_START',
      successful: 'DATA_GET_SWAGGER_WITH_NODE_SUCCESSFUL',
      fail: 'DATA_GET_SWAGGER_WITH_NODE_FAIL'
    },
    embeddedType: null,
    fnName: 'getSwaggerWithNodeRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/wop-api`,
    method: 'GET',
    url: `/swagger/${requestArguments.path['node']}`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'getSwaggerWithNodeRequest', '/wop-api'),
    actions: {
      start: {
        type: DATA_GET_SWAGGER_WITH_NODE_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_SWAGGER_WITH_NODE_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_SWAGGER_WITH_NODE_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getSwaggerWithNodeRequestDefinitions = (definitionsArguments: {| requestArgs: getSwaggerWithNodeRequestWopArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getSwaggerWithNodeRequestWopArguments, payload?: Object |} => ({
  fnName: 'getSwaggerWithNodeRequest',
  basePath: '/wop-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_GET_SWAGGER_WITH_CHILDNODE_START = 'DATA_GET_SWAGGER_WITH_CHILDNODE_START'
export const DATA_GET_SWAGGER_WITH_CHILDNODE_SUCCESSFUL = 'DATA_GET_SWAGGER_WITH_CHILDNODE_SUCCESSFUL'
export const DATA_GET_SWAGGER_WITH_CHILDNODE_FAIL = 'DATA_GET_SWAGGER_WITH_CHILDNODE_FAIL'

// Returns a second level node from the Swagger specification of the API
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/wop.yaml#/Swagger/get_swagger__node___childNode_
export const getSwaggerWithChildNodeRequest = (
  requestArguments: getSwaggerWithChildNodeRequestWopArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_SWAGGER_WITH_CHILDNODE_START',
      successful: 'DATA_GET_SWAGGER_WITH_CHILDNODE_SUCCESSFUL',
      fail: 'DATA_GET_SWAGGER_WITH_CHILDNODE_FAIL'
    },
    embeddedType: null,
    fnName: 'getSwaggerWithChildNodeRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/wop-api`,
    method: 'GET',
    url: `/swagger/${requestArguments.path['node']}/${requestArguments.path['childNode']}`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'getSwaggerWithChildNodeRequest', '/wop-api'),
    actions: {
      start: {
        type: DATA_GET_SWAGGER_WITH_CHILDNODE_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_SWAGGER_WITH_CHILDNODE_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_SWAGGER_WITH_CHILDNODE_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getSwaggerWithChildNodeRequestDefinitions = (definitionsArguments: {| requestArgs: getSwaggerWithChildNodeRequestWopArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getSwaggerWithChildNodeRequestWopArguments, payload?: Object |} => ({
  fnName: 'getSwaggerWithChildNodeRequest',
  basePath: '/wop-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})
