// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.

import { closeAllModals } from '../actions/modals'

export default (store: Object) => (next: Function) => (action: Object) => {
  if (action && action.type === '@@router/LOCATION_CHANGE') {
    store.dispatch(closeAllModals())
  }
  if (action) {
    next(action)
  }
}
