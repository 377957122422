// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.
import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'
import { connect, batch } from 'react-redux'

import ControlBar from './../../../common/ControlBar/ControlBar'
import HierarchicalListContainer from '../../HierarchicalListContainer/HierarchicalListContainer'
import DescriptionCell from '../../../common/lists/common/DescriptionCell/DescriptionCell'
import DropdownMenu from '../../../common/menus/DropdownMenu/DropdownMenu'
import Slider from '../../../common/Slider/Slider'
import UserModifiedIcon from '../../../containers/infoComponents/UserModifiedIcon'

import { RENOVATION_MEASURE_ID, DESCRIPTION, RATIO } from '../../../../constants/attributes'
import { toggleWidgetModified } from '../../../../actions/widgets'
import { mergeListItems, modifyListItem, deleteListItem } from '../../../../actions/list'
import { getPatchOperationListItemsFromValueProperties } from '../../../../utils/listUtils'
import { patchOperationRenovationMeasureBasePath, TVD_PATCH_OPERATION_TYPE_UPDATE } from '../../../../constants/patchOperationConstants'
import { createPatchOperationParameter, createPatchOperation } from '../../../../utils/patchOperationUtil'
import {
  storePatchOperation,
  storePatchOperationParameter,
  type StorePatchOperationParameterOptions,
  clearPatchOperations
} from '../../../../actions/patchOperations'
import { setWidgetActive } from '../../../../actions/app'
import { combineStyleClassNames } from '../../../../utils/styleUtils'

const styles = {
  renovationRoot: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    flex: '1',
    overflow: 'auto'
  },
  renovationMeasureCell: {
    '& > *': {
      // 4px to compensate the dropdown input's padding so the header cell text and the dropdown line up
      paddingLeft: '4px',
      justifyContent: 'flex-start !important',
    },
    minWidth: '380px !important', // to show dropdown with the caret and force width to match DropdownMenu width XXL + paddings
  },
  renovationMeasureId: {
    justifyContent: 'flex-start !important'
  },
  inputContentWrapper: {
    // for aligning input with others due to the DropdownMenu's paddingLeft value
    paddingLeft: '4px'
  },
  modifiedIcon: {
    display: 'inline-flex',
    paddingLeft: '24px'
  }
}

type ReceivedProps = {|
  listStoreId: string, // key for list Store
  onCheckBoxChange: (showOnlyElementsToRenovate: boolean) => void, // callback function to trigger allowing control what happens when showOnlyElementsToRenovate is changed
  didMountCallback: (listId: string) => void, // callback to control what happens when HierarchicalListContainer is mounted
  widgetTab: string, // tab name where the component is located in as a child
  widgetId: string, // widget id where the component is located in as a child
|}

type HOCProps = {|
  t: Function, // i18n translation function
  classes: Object, // withStyles classes object
|}

type MappedProps = {|
  listItems: TVDListItems, // all list items from store retrieved with listStoreId prop
  isEstimateLockedToCurrentUser: $PropertyType<TVDApplicationStore, 'isEstimateLockedToCurrentUser'> // if the user owns the lock for the estimate
|}

type DispatchProps = {|
  dispatchStorePatchOperation: (patchOperation: TVDPatchOperation, options?: StorePatchOperationParameterOptions) => void, // store a TVDPatchOperation to ReduxStore
  dispatchStorePatchOperationParameter: (parameterObject: TVDPatchOperationParameterObject) => void, // store a single TVDPatchOperation's operationParameter to ReduxStore
  dispatchSetWidgetActive: () => void, // sets the current widget active for save pattern
  dispatchMergeListItems: (listItems: TVDListItems, parentId: string) => void, // merges list items by removing measure topic property list rows so that new ones can replace them due to measure topic change
  dispatchModifyListItem: (listItemId: string, columnName: string, value: any) => void, // sets a measure topic property list row's Value key as modified with the modified value
  dispatchToggleWidgetModified: () => void, // sets current component's wrapping widget as modified
  dispatchClearPatchOperations: () => void, // clears all patch operations tied to this list instance
  dispatchDeleteRow: (listItemId: string) => void, // remove the row after returning defaultValue
|}

type Props = {|
  ...MappedProps,
  ...DispatchProps,
  ...ReceivedProps,
  ...HOCProps
|}

type ShowOnlyElementsToRenovateCheckBox = {|
  onChange: Function, // function ran on checkbox checked status change
  label: string, // text next to checkbox
  checked: boolean, // checked status of the checkbox
|}

type State = {
  showOnlyElementsToRenovate: boolean
}

export class RenovationMeasureList extends Component<Props, State> {
  state = {
    showOnlyElementsToRenovate: false
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (prevState.showOnlyElementsToRenovate !== this.state.showOnlyElementsToRenovate) {
      // from API point of view showOnlyElementsToRenovate means that queryparameter includeAll is false and vice versa
      this.props.onCheckBoxChange(!this.state.showOnlyElementsToRenovate)
    }
  }

  componentWillUnmount() {
    const { dispatchClearPatchOperations } = this.props
    dispatchClearPatchOperations()
  }

  getColumns = (): Array<TVDListItemColumn> => {
    const { t } = this.props
    return [
      {
        propertyName: DESCRIPTION,
        localizedName: t('renovationColumns._RENOVATION_DESCRIPTION_COLUMN_'),
        dataType: 'string'
      },
      {
        propertyName: RENOVATION_MEASURE_ID,
        localizedName: t('renovationColumns._RENOVATION_MEASURE_COLUMN_'),
        dataType: 'enum'
      }
    ]
  }

  getCheckBox = (): ShowOnlyElementsToRenovateCheckBox => ({
    onChange: () => { this.setState({ showOnlyElementsToRenovate: !this.state.showOnlyElementsToRenovate }) },
    label: this.props.t('renovation._SHOW_ONLY_ELEMENTS_TO_RENOVATE_'),
    checked: this.state.showOnlyElementsToRenovate,
  })


  getMeasureTopicPropertyListItems = (measure: TVDMeasure, renovationMeasureIdSelection: string, listItem: TVDListItem) => {
    const { dispatchMergeListItems } = this.props
    const isPropertiesGroup = Object.keys(measure.properties || {}).length > 1
    const { properties } = measure
    const { id } = listItem
    if (!isPropertiesGroup && properties) {
      const measureTopicPropertyListItems = getPatchOperationListItemsFromValueProperties({
        properties,
        parentId: id,
      })
      batch(() => {
        dispatchMergeListItems(measureTopicPropertyListItems, id)
      })
    }
  }

  onRenovationMeasurePropertyChange = (listItem: TVDListItem, columnAttributeName: string, value: string | number) => {
    const {
      dispatchStorePatchOperationParameter,
      dispatchSetWidgetActive,
      dispatchModifyListItem,
      listItems,
    } = this.props

    const operationType = TVD_PATCH_OPERATION_TYPE_UPDATE
    const { parentId = '' } = listItem
    const {
      columnData: { [RENOVATION_MEASURE_ID]: unModifiedRenovationMeasureId },
      modifiedColumnData: { [RENOVATION_MEASURE_ID]: modifiedRenovationMeasureId } = {}
    } = listItems[parentId]

    const renovationMeasureId = modifiedRenovationMeasureId || unModifiedRenovationMeasureId

    const patchOperationParameter = createPatchOperationParameter({
      resourceId: parentId,
      basePath: patchOperationRenovationMeasureBasePath,
      resourcePath: `${renovationMeasureId}/${columnAttributeName}`,
      value,
      operationType,
    })
    batch(() => {
      dispatchStorePatchOperationParameter(patchOperationParameter)
      dispatchSetWidgetActive()
      dispatchModifyListItem(listItem.id, columnAttributeName, value)
    })
  }

  onRenovationMeasureChange = (listItem: TVDListItem, measureValue: string | number) => {
    const {
      dispatchStorePatchOperation,
      dispatchSetWidgetActive,
      dispatchModifyListItem
    } = this.props
    const { id } = listItem
    const patchOperation = createPatchOperation({
      resourceId: id,
      value: measureValue,
      basePath: patchOperationRenovationMeasureBasePath,
      operationType: TVD_PATCH_OPERATION_TYPE_UPDATE,
    })
    batch(() => {
      dispatchStorePatchOperation(patchOperation, { removeExistingPatchOperationParameters: true })
      dispatchModifyListItem(id, RENOVATION_MEASURE_ID, measureValue)
      if (listItem.columnData.reset) {
        // setting reset to false when item has been reset and then modified before save
        // this makes modified icon hidden and patch request correct
        dispatchModifyListItem(id, 'reset', false)
      }
      dispatchSetWidgetActive()
    })
  }

  getDropDownMenu = (row: TVDListItem): React$Element<typeof DropdownMenu> => {
    const { id, measures = [], columnData: { [RENOVATION_MEASURE_ID]: RenovationMeasureId } } = row
    const defaultEnumeral = measures.find((measure: TVDMeasure): boolean => measure.value === RenovationMeasureId)
    if (!defaultEnumeral) {
      console.error(`No default renovation measure enumeral for row id ${id} amongst ${measures.length} of enumerals`)
    }
    const defaultValue = defaultEnumeral && defaultEnumeral.value ? defaultEnumeral.value : ''
    return (
      <DropdownMenu
        alignInputText='left'
        defaultValue={defaultValue}
        minimalist
        width='XXL'
        onChange={(renovationMeasureIdSelection: string) => {
          const selectedMeasure = this.getSelectedMeasure(id, renovationMeasureIdSelection)
          this.onRenovationMeasureChange(row, renovationMeasureIdSelection)
          this.getMeasureTopicPropertyListItems(selectedMeasure, renovationMeasureIdSelection, row)
        }}
        items={measures} />
    )
  }

  getInput = (row: TVDListItem): Slider | null => {
    const {
      id,
      columnData,
      modifiedColumnData = {}
    } = row

    const fixedColumDataRatioId = row.parentId ? id.replace(row.parentId, '').substring(1) : null
    const hasModifiedColumnDataRatio = (modifiedColumnData[RATIO] === 0 || !!modifiedColumnData[RATIO])
    const hasColumnDataRatio = (columnData[RATIO] === 0 || !!columnData[RATIO])
    // When editing the value, before saving, The API gives an dynamic intermediate ID
    // $FlowFixMe for the row that we can use to access the ratio data
    const hasFixedColumnDataRatio = (columnData[fixedColumDataRatioId] === 0 || !!columnData[fixedColumDataRatioId])


    switch (true) {
      case (hasModifiedColumnDataRatio || hasColumnDataRatio || hasFixedColumnDataRatio): {
        let ratioData = null
        switch (true) {
          case hasModifiedColumnDataRatio: {
            ratioData = modifiedColumnData[RATIO]
            break
          }
          case hasColumnDataRatio: {
            ratioData = columnData[RATIO]
            break
          }
          case hasFixedColumnDataRatio: {
            // When editing the value, before saving, The API gives an dynamic intermediate ID
            // $FlowFixMe for the row that we can use to access the ratio data
            ratioData = columnData[fixedColumDataRatioId]
            break
          }
          default: break
        }
        return (
          <Slider
            value={ratioData}
            id={id}
            onChangeCommitted={(value: number) => {
              this.onRenovationMeasurePropertyChange(row, RATIO, value)
            }} />
        )
      }
      default: {
        return null
      }
    }
  }

  getSelectedMeasure = (listItemId: string, renovationMeasureIdSelection: string) => {
    const { listItems } = this.props
    const { measures = [] } = listItems[listItemId]
    const [selectedMeasure] = measures.filter((measure: TVDMeasure): boolean => measure.value === renovationMeasureIdSelection)
    return selectedMeasure
  }

  resetDefault = (row: TVDListItem) => {
    const {
      listItems, dispatchModifyListItem, dispatchDeleteRow, dispatchToggleWidgetModified
    } = this.props
    const listItemsValue: any = Object.values(listItems)
    const sliderItem: Object = listItemsValue
      .find((listItem: TVDListItem) => {
        if (!listItem.measures) return false
        return listItem.parentId === row.id && !listItem.measures.length
      })
    if (sliderItem) {
      dispatchDeleteRow(sliderItem.id)
    }
    dispatchModifyListItem(row.id, RENOVATION_MEASURE_ID, row.columnMeta?.[RENOVATION_MEASURE_ID].defaultValue)
    dispatchModifyListItem(row.id, 'reset', true)
    dispatchToggleWidgetModified()
  }

  getWrappedCellContents = () => ({
    [DESCRIPTION]: ({ content }: TVDWrappedCellCallbackParameters): React$Element<typeof DescriptionCell> => (
      <DescriptionCell fontSize='14px' text={content} />
    ),
    [RENOVATION_MEASURE_ID]: ({ row }: TVDWrappedCellCallbackParameters): React$Element<typeof DropdownMenu> | React$Element<any> | null => {
      const { measures } = row
      const { classes, t } = this.props
      const hasMeasures = Boolean(measures && measures.length)
      const renovationMeasureId = row.columnMeta?.[RENOVATION_MEASURE_ID]

      const getDefaultRenovationMeasure = () =>
        (measures?.find((measure: any) => measure.value === renovationMeasureId?.defaultValue))?.localizedName || ''

      return (
        <div className={combineStyleClassNames(classes.renovationMeasureId, !hasMeasures && classes.inputContentWrapper)}>
          { hasMeasures ? this.getDropDownMenu(row) : this.getInput(row) }
          { renovationMeasureId?.userModified && row.columnData.reset === undefined &&
          <span className={classes.modifiedIcon}><UserModifiedIcon
            actionCb={() => this.resetDefault(row)}
            text={t('userModifiedInfo._USER_MODIFIED_RENOVATION_MEASURE_', { defaultRenovationMeasure: getDefaultRenovationMeasure() })}
            actionText={t('userModifiedInfo._REVERT_TO_DEFAULT_RENOVATION_MEASURE_')} />
          </span>
          }
        </div>
      )
    }
  })

  onModifiedChange = (modifiedListItem: TVDListItem) => {
    const { dispatchToggleWidgetModified } = this.props
    if (modifiedListItem) dispatchToggleWidgetModified()
  }

  render(): React$Element<any> {
    const { classes, listStoreId, isEstimateLockedToCurrentUser } = this.props
    return (
      <div className={classes.renovationRoot}>
        <ControlBar checkboxes={[this.getCheckBox()]} />
        <HierarchicalListContainer
          allowOverflow
          listId={listStoreId}
          onModifiedChange={this.onModifiedChange}
          addedColumns={this.getColumns()}
          listHeaderCellClassNames={{ [RENOVATION_MEASURE_ID]: classes.renovationMeasureCell }}
          resizeDisabledColumns={[RENOVATION_MEASURE_ID]}
          wrappedCellContents={this.getWrappedCellContents()}
          didMountCallback={this.props.didMountCallback}
          disabled={!isEstimateLockedToCurrentUser} />
      </div>
    )
  }
}

const mapStateToProps = ({ list, app: { isEstimateLockedToCurrentUser } }: TVDReduxStore, { listStoreId }: ReceivedProps): MappedProps => ({
  listItems: (list[listStoreId] || {}).listItems,
  isEstimateLockedToCurrentUser
})

const mapDispatchToProps = (dispatch: Function, { widgetId, widgetTab, listStoreId }: ReceivedProps): DispatchProps => ({
  dispatchStorePatchOperation: (patchOperation: TVDPatchOperation, storePatchOperationParameterOptions?: StorePatchOperationParameterOptions) => {
    dispatch(storePatchOperation(listStoreId, patchOperation, storePatchOperationParameterOptions))
  },
  dispatchStorePatchOperationParameter: (patchOperationParameterObject: TVDPatchOperationParameterObject) => {
    dispatch(storePatchOperationParameter(listStoreId, patchOperationParameterObject))
  },
  dispatchToggleWidgetModified: () => {
    dispatch(toggleWidgetModified(widgetId, widgetTab, true))
  },
  dispatchMergeListItems: (listItems: TVDListItems, parentId: string) => {
    dispatch(mergeListItems(listStoreId, listItems, { removeInitialListItemsWithParentIdOf: parentId }))
  },
  dispatchModifyListItem: (listItemId: string, columnName: string, value: any) => {
    dispatch(modifyListItem({
      columnName, listId: listStoreId, listItemId, value
    }))
  },
  dispatchSetWidgetActive: () => { dispatch(setWidgetActive(widgetId, widgetTab)) },
  dispatchClearPatchOperations: () => { dispatch(clearPatchOperations(listStoreId)) },
  dispatchDeleteRow: (listItemId: string) => { dispatch(deleteListItem(listStoreId, listItemId)) },

})

export default withTranslation('translations')(withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(RenovationMeasureList)))
