// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.
/* eslint max-len: ["error", { "code": 350 }] */
/*
  Haahtela Building Elements Api 22.1.0
  Building elements services for Haahtela Api - this file is to be deprecated, refer to openapi.yaml instead!
  192.168.254.119:5001
*/

import requestFn from './../requests/request'
import { API_BASE_URL } from './../../constants/envConstants'
import parserMapper from './../parserMapper'
import { getState } from '../../store'

export const basePath = '/building-elements-api'

export const DATA_POST_BUILDING_TASKS_WITH_ESTIMATEID_START = 'DATA_POST_BUILDING_TASKS_WITH_ESTIMATEID_START'
export const DATA_POST_BUILDING_TASKS_WITH_ESTIMATEID_SUCCESSFUL = 'DATA_POST_BUILDING_TASKS_WITH_ESTIMATEID_SUCCESSFUL'
export const DATA_POST_BUILDING_TASKS_WITH_ESTIMATEID_FAIL = 'DATA_POST_BUILDING_TASKS_WITH_ESTIMATEID_FAIL'

// Post selected building tasks and selected row ids to add building tasks to element schedule
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/buildingelements.yaml#/Building%20Tasks/post_building_tasks__estimateId_
export const postBuildingTasksWithEstimateIdRequest = (
  requestArguments: postBuildingTasksWithEstimateIdRequestBuildingelementsArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_POST_BUILDING_TASKS_WITH_ESTIMATEID_START',
      successful: 'DATA_POST_BUILDING_TASKS_WITH_ESTIMATEID_SUCCESSFUL',
      fail: 'DATA_POST_BUILDING_TASKS_WITH_ESTIMATEID_FAIL'
    },
    embeddedType: null,
    fnName: 'postBuildingTasksWithEstimateIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/building-elements-api`,
    method: 'POST',
    url: `/building-tasks/${options?.estimateId || store.app.calculation}`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: requestArguments.body
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'postBuildingTasksWithEstimateIdRequest', '/building-elements-api'),
    actions: {
      start: {
        type: DATA_POST_BUILDING_TASKS_WITH_ESTIMATEID_START, payload, config, meta
      },
      successful: {
        type: DATA_POST_BUILDING_TASKS_WITH_ESTIMATEID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_POST_BUILDING_TASKS_WITH_ESTIMATEID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_PUT_BUILDING_TASKS_WITH_ESTIMATEID_START = 'DATA_PUT_BUILDING_TASKS_WITH_ESTIMATEID_START'
export const DATA_PUT_BUILDING_TASKS_WITH_ESTIMATEID_SUCCESSFUL = 'DATA_PUT_BUILDING_TASKS_WITH_ESTIMATEID_SUCCESSFUL'
export const DATA_PUT_BUILDING_TASKS_WITH_ESTIMATEID_FAIL = 'DATA_PUT_BUILDING_TASKS_WITH_ESTIMATEID_FAIL'

// Put selected building tasks and selected row ids to replace building tasks in element schedule
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/buildingelements.yaml#/Building%20Tasks/put_building_tasks__estimateId_
export const putBuildingTasksWithEstimateIdRequest = (
  requestArguments: putBuildingTasksWithEstimateIdRequestBuildingelementsArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_PUT_BUILDING_TASKS_WITH_ESTIMATEID_START',
      successful: 'DATA_PUT_BUILDING_TASKS_WITH_ESTIMATEID_SUCCESSFUL',
      fail: 'DATA_PUT_BUILDING_TASKS_WITH_ESTIMATEID_FAIL'
    },
    embeddedType: null,
    fnName: 'putBuildingTasksWithEstimateIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/building-elements-api`,
    method: 'PUT',
    url: `/building-tasks/${options?.estimateId || store.app.calculation}`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: requestArguments.body
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'putBuildingTasksWithEstimateIdRequest', '/building-elements-api'),
    actions: {
      start: {
        type: DATA_PUT_BUILDING_TASKS_WITH_ESTIMATEID_START, payload, config, meta
      },
      successful: {
        type: DATA_PUT_BUILDING_TASKS_WITH_ESTIMATEID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_PUT_BUILDING_TASKS_WITH_ESTIMATEID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_GET_BUILDING_TASKS_PROPERTIES_WITH_ESTIMATEID_START = 'DATA_GET_BUILDING_TASKS_PROPERTIES_WITH_ESTIMATEID_START'
export const DATA_GET_BUILDING_TASKS_PROPERTIES_WITH_ESTIMATEID_SUCCESSFUL = 'DATA_GET_BUILDING_TASKS_PROPERTIES_WITH_ESTIMATEID_SUCCESSFUL'
export const DATA_GET_BUILDING_TASKS_PROPERTIES_WITH_ESTIMATEID_FAIL = 'DATA_GET_BUILDING_TASKS_PROPERTIES_WITH_ESTIMATEID_FAIL'

// Gets a categorized list of building task related properties for an estimate
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/buildingelements.yaml#/Building%20Tasks/get_building_tasks__estimateId__properties
export const getBuildingTasksPropertiesWithEstimateIdRequest = (
  requestArguments: getBuildingTasksPropertiesWithEstimateIdRequestBuildingelementsArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_BUILDING_TASKS_PROPERTIES_WITH_ESTIMATEID_START',
      successful: 'DATA_GET_BUILDING_TASKS_PROPERTIES_WITH_ESTIMATEID_SUCCESSFUL',
      fail: 'DATA_GET_BUILDING_TASKS_PROPERTIES_WITH_ESTIMATEID_FAIL'
    },
    embeddedType: {
      properties: {
        schemaPath: '#/definitions/ValueProperty',
        schemaKey: 'ValueProperty'
      }
    },
    fnName: 'getBuildingTasksPropertiesWithEstimateIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/building-elements-api`,
    method: 'GET',
    url: `/building-tasks/${options?.estimateId || store.app.calculation}/properties`,
    params: {
      buildingTaskIds: requestArguments.query?.['buildingTaskIds'],
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      properties: {
        schemaPath: '#/definitions/ValueProperty',
        schemaKey: 'ValueProperty'
      }
    }, 'getBuildingTasksPropertiesWithEstimateIdRequest', '/building-elements-api'),
    actions: {
      start: {
        type: DATA_GET_BUILDING_TASKS_PROPERTIES_WITH_ESTIMATEID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_BUILDING_TASKS_PROPERTIES_WITH_ESTIMATEID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_BUILDING_TASKS_PROPERTIES_WITH_ESTIMATEID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getBuildingTasksPropertiesWithEstimateIdRequestDefinitions = (definitionsArguments: {| requestArgs: getBuildingTasksPropertiesWithEstimateIdRequestBuildingelementsArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getBuildingTasksPropertiesWithEstimateIdRequestBuildingelementsArguments, payload?: Object |} => ({
  fnName: 'getBuildingTasksPropertiesWithEstimateIdRequest',
  basePath: '/building-elements-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_PATCH_BUILDING_TASKS_PROPERTIES_WITH_ESTIMATEID_START = 'DATA_PATCH_BUILDING_TASKS_PROPERTIES_WITH_ESTIMATEID_START'
export const DATA_PATCH_BUILDING_TASKS_PROPERTIES_WITH_ESTIMATEID_SUCCESSFUL = 'DATA_PATCH_BUILDING_TASKS_PROPERTIES_WITH_ESTIMATEID_SUCCESSFUL'
export const DATA_PATCH_BUILDING_TASKS_PROPERTIES_WITH_ESTIMATEID_FAIL = 'DATA_PATCH_BUILDING_TASKS_PROPERTIES_WITH_ESTIMATEID_FAIL'

// Update building task properties
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/buildingelements.yaml#/Building%20Tasks/patch_building_tasks__estimateId__properties
export const patchBuildingTasksPropertiesWithEstimateIdRequest = (
  requestArguments: patchBuildingTasksPropertiesWithEstimateIdRequestBuildingelementsArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_PATCH_BUILDING_TASKS_PROPERTIES_WITH_ESTIMATEID_START',
      successful: 'DATA_PATCH_BUILDING_TASKS_PROPERTIES_WITH_ESTIMATEID_SUCCESSFUL',
      fail: 'DATA_PATCH_BUILDING_TASKS_PROPERTIES_WITH_ESTIMATEID_FAIL'
    },
    embeddedType: null,
    fnName: 'patchBuildingTasksPropertiesWithEstimateIdRequest'
  }
  const config = {
    headers: { 'Content-Type': 'application/json' },
    baseURL: `${API_BASE_URL}/building-elements-api`,
    method: 'PATCH',
    url: `/building-tasks/${options?.estimateId || store.app.calculation}/properties`,
    params: {
      resetProperties: requestArguments.query?.['resetProperties'],
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: requestArguments.body
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'patchBuildingTasksPropertiesWithEstimateIdRequest', '/building-elements-api'),
    actions: {
      start: {
        type: DATA_PATCH_BUILDING_TASKS_PROPERTIES_WITH_ESTIMATEID_START, payload, config, meta
      },
      successful: {
        type: DATA_PATCH_BUILDING_TASKS_PROPERTIES_WITH_ESTIMATEID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_PATCH_BUILDING_TASKS_PROPERTIES_WITH_ESTIMATEID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_GET_BUILDING_TASKS_PROPERTIES_INFO_WITH_PROPERTYNAME_START = 'DATA_GET_BUILDING_TASKS_PROPERTIES_INFO_WITH_PROPERTYNAME_START'
export const DATA_GET_BUILDING_TASKS_PROPERTIES_INFO_WITH_PROPERTYNAME_SUCCESSFUL = 'DATA_GET_BUILDING_TASKS_PROPERTIES_INFO_WITH_PROPERTYNAME_SUCCESSFUL'
export const DATA_GET_BUILDING_TASKS_PROPERTIES_INFO_WITH_PROPERTYNAME_FAIL = 'DATA_GET_BUILDING_TASKS_PROPERTIES_INFO_WITH_PROPERTYNAME_FAIL'

// Get short information text for a building task property
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/buildingelements.yaml#/Building%20Tasks/get_building_tasks__estimateId__properties_info__propertyName_
export const getBuildingTasksPropertiesInfoWithPropertyNameRequest = (
  requestArguments: getBuildingTasksPropertiesInfoWithPropertyNameRequestBuildingelementsArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_BUILDING_TASKS_PROPERTIES_INFO_WITH_PROPERTYNAME_START',
      successful: 'DATA_GET_BUILDING_TASKS_PROPERTIES_INFO_WITH_PROPERTYNAME_SUCCESSFUL',
      fail: 'DATA_GET_BUILDING_TASKS_PROPERTIES_INFO_WITH_PROPERTYNAME_FAIL'
    },
    embeddedType: null,
    fnName: 'getBuildingTasksPropertiesInfoWithPropertyNameRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/building-elements-api`,
    method: 'GET',
    url: `/building-tasks/${options?.estimateId || store.app.calculation}/properties/info/${requestArguments.path['propertyName']}`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'getBuildingTasksPropertiesInfoWithPropertyNameRequest', '/building-elements-api'),
    actions: {
      start: {
        type: DATA_GET_BUILDING_TASKS_PROPERTIES_INFO_WITH_PROPERTYNAME_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_BUILDING_TASKS_PROPERTIES_INFO_WITH_PROPERTYNAME_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_BUILDING_TASKS_PROPERTIES_INFO_WITH_PROPERTYNAME_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getBuildingTasksPropertiesInfoWithPropertyNameRequestDefinitions = (definitionsArguments: {| requestArgs: getBuildingTasksPropertiesInfoWithPropertyNameRequestBuildingelementsArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getBuildingTasksPropertiesInfoWithPropertyNameRequestBuildingelementsArguments, payload?: Object |} => ({
  fnName: 'getBuildingTasksPropertiesInfoWithPropertyNameRequest',
  basePath: '/building-elements-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_GET_BUILDING_TASKS_SCHEDULE_WITH_ESTIMATEID_START = 'DATA_GET_BUILDING_TASKS_SCHEDULE_WITH_ESTIMATEID_START'
export const DATA_GET_BUILDING_TASKS_SCHEDULE_WITH_ESTIMATEID_SUCCESSFUL = 'DATA_GET_BUILDING_TASKS_SCHEDULE_WITH_ESTIMATEID_SUCCESSFUL'
export const DATA_GET_BUILDING_TASKS_SCHEDULE_WITH_ESTIMATEID_FAIL = 'DATA_GET_BUILDING_TASKS_SCHEDULE_WITH_ESTIMATEID_FAIL'

// Gets building task schedule
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/buildingelements.yaml#/Building%20Tasks/get_building_tasks__estimateId__schedule
export const getBuildingTasksScheduleWithEstimateIdRequest = (
  requestArguments: getBuildingTasksScheduleWithEstimateIdRequestBuildingelementsArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_BUILDING_TASKS_SCHEDULE_WITH_ESTIMATEID_START',
      successful: 'DATA_GET_BUILDING_TASKS_SCHEDULE_WITH_ESTIMATEID_SUCCESSFUL',
      fail: 'DATA_GET_BUILDING_TASKS_SCHEDULE_WITH_ESTIMATEID_FAIL'
    },
    embeddedType: {
      items: {
        schemaPath: '#/definitions/ElementScheduleItem',
        schemaKey: 'ElementScheduleItem'
      }
    },
    fnName: 'getBuildingTasksScheduleWithEstimateIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/building-elements-api`,
    method: 'GET',
    url: `/building-tasks/${options?.estimateId || store.app.calculation}/schedule`,
    params: {
      buildingTaskIds: requestArguments.query?.['buildingTaskIds'],
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      items: {
        schemaPath: '#/definitions/ElementScheduleItem',
        schemaKey: 'ElementScheduleItem'
      }
    }, 'getBuildingTasksScheduleWithEstimateIdRequest', '/building-elements-api'),
    actions: {
      start: {
        type: DATA_GET_BUILDING_TASKS_SCHEDULE_WITH_ESTIMATEID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_BUILDING_TASKS_SCHEDULE_WITH_ESTIMATEID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_BUILDING_TASKS_SCHEDULE_WITH_ESTIMATEID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getBuildingTasksScheduleWithEstimateIdRequestDefinitions = (definitionsArguments: {| requestArgs: getBuildingTasksScheduleWithEstimateIdRequestBuildingelementsArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getBuildingTasksScheduleWithEstimateIdRequestBuildingelementsArguments, payload?: Object |} => ({
  fnName: 'getBuildingTasksScheduleWithEstimateIdRequest',
  basePath: '/building-elements-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_GET_BUILDING_TASKS_SCHEDULE_COLUMNS_WITH_ESTIMATEID_START = 'DATA_GET_BUILDING_TASKS_SCHEDULE_COLUMNS_WITH_ESTIMATEID_START'
export const DATA_GET_BUILDING_TASKS_SCHEDULE_COLUMNS_WITH_ESTIMATEID_SUCCESSFUL = 'DATA_GET_BUILDING_TASKS_SCHEDULE_COLUMNS_WITH_ESTIMATEID_SUCCESSFUL'
export const DATA_GET_BUILDING_TASKS_SCHEDULE_COLUMNS_WITH_ESTIMATEID_FAIL = 'DATA_GET_BUILDING_TASKS_SCHEDULE_COLUMNS_WITH_ESTIMATEID_FAIL'

// Gets building task schedule columns
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/buildingelements.yaml#/Building%20Tasks/get_building_tasks__estimateId__schedule_columns
export const getBuildingTasksScheduleColumnsWithEstimateIdRequest = (
  payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_BUILDING_TASKS_SCHEDULE_COLUMNS_WITH_ESTIMATEID_START',
      successful: 'DATA_GET_BUILDING_TASKS_SCHEDULE_COLUMNS_WITH_ESTIMATEID_SUCCESSFUL',
      fail: 'DATA_GET_BUILDING_TASKS_SCHEDULE_COLUMNS_WITH_ESTIMATEID_FAIL'
    },
    embeddedType: {
      properties: {
        schemaPath: '#/definitions/ValueProperty',
        schemaKey: 'ValueProperty'
      }
    },
    fnName: 'getBuildingTasksScheduleColumnsWithEstimateIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/building-elements-api`,
    method: 'GET',
    url: `/building-tasks/${options?.estimateId || store.app.calculation}/schedule/columns`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      properties: {
        schemaPath: '#/definitions/ValueProperty',
        schemaKey: 'ValueProperty'
      }
    }, 'getBuildingTasksScheduleColumnsWithEstimateIdRequest', '/building-elements-api'),
    actions: {
      start: {
        type: DATA_GET_BUILDING_TASKS_SCHEDULE_COLUMNS_WITH_ESTIMATEID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_BUILDING_TASKS_SCHEDULE_COLUMNS_WITH_ESTIMATEID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_BUILDING_TASKS_SCHEDULE_COLUMNS_WITH_ESTIMATEID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getBuildingTasksScheduleColumnsWithEstimateIdRequestDefinitions = (definitionsArguments: {| payload?: Object |}): {|
  ...TVDGARConfigWithoutRequestArgs, payload?: Object |} => ({
  fnName: 'getBuildingTasksScheduleColumnsWithEstimateIdRequest',
  basePath: '/building-elements-api',
  payload: definitionsArguments.payload,
})


export const DATA_GET_V2_BUILDING_TASKS_PROPERTIES_WITH_ESTIMATEID_START = 'DATA_GET_V2_BUILDING_TASKS_PROPERTIES_WITH_ESTIMATEID_START'
export const DATA_GET_V2_BUILDING_TASKS_PROPERTIES_WITH_ESTIMATEID_SUCCESSFUL = 'DATA_GET_V2_BUILDING_TASKS_PROPERTIES_WITH_ESTIMATEID_SUCCESSFUL'
export const DATA_GET_V2_BUILDING_TASKS_PROPERTIES_WITH_ESTIMATEID_FAIL = 'DATA_GET_V2_BUILDING_TASKS_PROPERTIES_WITH_ESTIMATEID_FAIL'

// Gets a categorized list of building task related properties for an estimate
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/buildingelements.yaml#/BuildingTasksApiControllerV2/get_v2_building_tasks__estimateId__properties
export const getV2BuildingTasksPropertiesWithEstimateIdRequest = (
  requestArguments: getV2BuildingTasksPropertiesWithEstimateIdRequestBuildingelementsArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_V2_BUILDING_TASKS_PROPERTIES_WITH_ESTIMATEID_START',
      successful: 'DATA_GET_V2_BUILDING_TASKS_PROPERTIES_WITH_ESTIMATEID_SUCCESSFUL',
      fail: 'DATA_GET_V2_BUILDING_TASKS_PROPERTIES_WITH_ESTIMATEID_FAIL'
    },
    embeddedType: {
      nonEmbedded: {
        schemaPath: '#/definitions/GetBuildingTaskPropertiesV2',
        schemaKey: 'GetBuildingTaskPropertiesV2'
      }
    },
    fnName: 'getV2BuildingTasksPropertiesWithEstimateIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/building-elements-api`,
    method: 'GET',
    url: `/v2/building-tasks/${options?.estimateId || store.app.calculation}/properties`,
    params: {
      buildingTasks: requestArguments.query['buildingTasks'],
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      nonEmbedded: {
        schemaPath: '#/definitions/GetBuildingTaskPropertiesV2',
        schemaKey: 'GetBuildingTaskPropertiesV2'
      }
    }, 'getV2BuildingTasksPropertiesWithEstimateIdRequest', '/building-elements-api'),
    actions: {
      start: {
        type: DATA_GET_V2_BUILDING_TASKS_PROPERTIES_WITH_ESTIMATEID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_V2_BUILDING_TASKS_PROPERTIES_WITH_ESTIMATEID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_V2_BUILDING_TASKS_PROPERTIES_WITH_ESTIMATEID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getV2BuildingTasksPropertiesWithEstimateIdRequestDefinitions = (definitionsArguments: {| requestArgs: getV2BuildingTasksPropertiesWithEstimateIdRequestBuildingelementsArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getV2BuildingTasksPropertiesWithEstimateIdRequestBuildingelementsArguments, payload?: Object |} => ({
  fnName: 'getV2BuildingTasksPropertiesWithEstimateIdRequest',
  basePath: '/building-elements-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_PATCH_V2_BUILDING_TASKS_PROPERTIES_WITH_ESTIMATEID_START = 'DATA_PATCH_V2_BUILDING_TASKS_PROPERTIES_WITH_ESTIMATEID_START'
export const DATA_PATCH_V2_BUILDING_TASKS_PROPERTIES_WITH_ESTIMATEID_SUCCESSFUL = 'DATA_PATCH_V2_BUILDING_TASKS_PROPERTIES_WITH_ESTIMATEID_SUCCESSFUL'
export const DATA_PATCH_V2_BUILDING_TASKS_PROPERTIES_WITH_ESTIMATEID_FAIL = 'DATA_PATCH_V2_BUILDING_TASKS_PROPERTIES_WITH_ESTIMATEID_FAIL'

// Update building tasks properties
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/buildingelements.yaml#/BuildingTasksApiControllerV2/patch_v2_building_tasks__estimateId__properties
export const patchV2BuildingTasksPropertiesWithEstimateIdRequest = (
  requestArguments: patchV2BuildingTasksPropertiesWithEstimateIdRequestBuildingelementsArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_PATCH_V2_BUILDING_TASKS_PROPERTIES_WITH_ESTIMATEID_START',
      successful: 'DATA_PATCH_V2_BUILDING_TASKS_PROPERTIES_WITH_ESTIMATEID_SUCCESSFUL',
      fail: 'DATA_PATCH_V2_BUILDING_TASKS_PROPERTIES_WITH_ESTIMATEID_FAIL'
    },
    embeddedType: null,
    fnName: 'patchV2BuildingTasksPropertiesWithEstimateIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/building-elements-api`,
    method: 'PATCH',
    url: `/v2/building-tasks/${options?.estimateId || store.app.calculation}/properties`,
    params: {
      resetProperties: requestArguments.query?.['resetProperties'],
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: requestArguments.body
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'patchV2BuildingTasksPropertiesWithEstimateIdRequest', '/building-elements-api'),
    actions: {
      start: {
        type: DATA_PATCH_V2_BUILDING_TASKS_PROPERTIES_WITH_ESTIMATEID_START, payload, config, meta
      },
      successful: {
        type: DATA_PATCH_V2_BUILDING_TASKS_PROPERTIES_WITH_ESTIMATEID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_PATCH_V2_BUILDING_TASKS_PROPERTIES_WITH_ESTIMATEID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_GET_V2_BUILDING_TASKS_PROPERTIES_INFO_WITH_PROPERTYNAME_START = 'DATA_GET_V2_BUILDING_TASKS_PROPERTIES_INFO_WITH_PROPERTYNAME_START'
export const DATA_GET_V2_BUILDING_TASKS_PROPERTIES_INFO_WITH_PROPERTYNAME_SUCCESSFUL = 'DATA_GET_V2_BUILDING_TASKS_PROPERTIES_INFO_WITH_PROPERTYNAME_SUCCESSFUL'
export const DATA_GET_V2_BUILDING_TASKS_PROPERTIES_INFO_WITH_PROPERTYNAME_FAIL = 'DATA_GET_V2_BUILDING_TASKS_PROPERTIES_INFO_WITH_PROPERTYNAME_FAIL'

// Get short information text for a building task property
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/buildingelements.yaml#/BuildingTasksApiControllerV2/get_v2_building_tasks__estimateId__properties__propertyName__info
export const getV2BuildingTasksPropertiesInfoWithPropertyNameRequest = (
  requestArguments: getV2BuildingTasksPropertiesInfoWithPropertyNameRequestBuildingelementsArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_V2_BUILDING_TASKS_PROPERTIES_INFO_WITH_PROPERTYNAME_START',
      successful: 'DATA_GET_V2_BUILDING_TASKS_PROPERTIES_INFO_WITH_PROPERTYNAME_SUCCESSFUL',
      fail: 'DATA_GET_V2_BUILDING_TASKS_PROPERTIES_INFO_WITH_PROPERTYNAME_FAIL'
    },
    embeddedType: null,
    fnName: 'getV2BuildingTasksPropertiesInfoWithPropertyNameRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/building-elements-api`,
    method: 'GET',
    url: `/v2/building-tasks/${options?.estimateId || store.app.calculation}/properties/${requestArguments.path['propertyName']}/info`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'getV2BuildingTasksPropertiesInfoWithPropertyNameRequest', '/building-elements-api'),
    actions: {
      start: {
        type: DATA_GET_V2_BUILDING_TASKS_PROPERTIES_INFO_WITH_PROPERTYNAME_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_V2_BUILDING_TASKS_PROPERTIES_INFO_WITH_PROPERTYNAME_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_V2_BUILDING_TASKS_PROPERTIES_INFO_WITH_PROPERTYNAME_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getV2BuildingTasksPropertiesInfoWithPropertyNameRequestDefinitions = (definitionsArguments: {| requestArgs: getV2BuildingTasksPropertiesInfoWithPropertyNameRequestBuildingelementsArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getV2BuildingTasksPropertiesInfoWithPropertyNameRequestBuildingelementsArguments, payload?: Object |} => ({
  fnName: 'getV2BuildingTasksPropertiesInfoWithPropertyNameRequest',
  basePath: '/building-elements-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_POST_V2_BUILDING_TASKS_WITH_ESTIMATEID_START = 'DATA_POST_V2_BUILDING_TASKS_WITH_ESTIMATEID_START'
export const DATA_POST_V2_BUILDING_TASKS_WITH_ESTIMATEID_SUCCESSFUL = 'DATA_POST_V2_BUILDING_TASKS_WITH_ESTIMATEID_SUCCESSFUL'
export const DATA_POST_V2_BUILDING_TASKS_WITH_ESTIMATEID_FAIL = 'DATA_POST_V2_BUILDING_TASKS_WITH_ESTIMATEID_FAIL'

// Add selected building tasks and selected row ids to add building tasks to element schedule
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/buildingelements.yaml#/BuildingTasksApiControllerV2/post_v2_building_tasks__estimateId_
export const postV2BuildingTasksWithEstimateIdRequest = (
  payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_POST_V2_BUILDING_TASKS_WITH_ESTIMATEID_START',
      successful: 'DATA_POST_V2_BUILDING_TASKS_WITH_ESTIMATEID_SUCCESSFUL',
      fail: 'DATA_POST_V2_BUILDING_TASKS_WITH_ESTIMATEID_FAIL'
    },
    embeddedType: null,
    fnName: 'postV2BuildingTasksWithEstimateIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/building-elements-api`,
    method: 'POST',
    url: `/v2/building-tasks/${options?.estimateId || store.app.calculation}`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'postV2BuildingTasksWithEstimateIdRequest', '/building-elements-api'),
    actions: {
      start: {
        type: DATA_POST_V2_BUILDING_TASKS_WITH_ESTIMATEID_START, payload, config, meta
      },
      successful: {
        type: DATA_POST_V2_BUILDING_TASKS_WITH_ESTIMATEID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_POST_V2_BUILDING_TASKS_WITH_ESTIMATEID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_PUT_V2_BUILDING_TASKS_WITH_ESTIMATEID_START = 'DATA_PUT_V2_BUILDING_TASKS_WITH_ESTIMATEID_START'
export const DATA_PUT_V2_BUILDING_TASKS_WITH_ESTIMATEID_SUCCESSFUL = 'DATA_PUT_V2_BUILDING_TASKS_WITH_ESTIMATEID_SUCCESSFUL'
export const DATA_PUT_V2_BUILDING_TASKS_WITH_ESTIMATEID_FAIL = 'DATA_PUT_V2_BUILDING_TASKS_WITH_ESTIMATEID_FAIL'

// Replace selected building tasks and selected row ids to replace building tasks in element schedule
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/buildingelements.yaml#/BuildingTasksApiControllerV2/put_v2_building_tasks__estimateId_
export const putV2BuildingTasksWithEstimateIdRequest = (
  payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_PUT_V2_BUILDING_TASKS_WITH_ESTIMATEID_START',
      successful: 'DATA_PUT_V2_BUILDING_TASKS_WITH_ESTIMATEID_SUCCESSFUL',
      fail: 'DATA_PUT_V2_BUILDING_TASKS_WITH_ESTIMATEID_FAIL'
    },
    embeddedType: null,
    fnName: 'putV2BuildingTasksWithEstimateIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/building-elements-api`,
    method: 'PUT',
    url: `/v2/building-tasks/${options?.estimateId || store.app.calculation}`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'putV2BuildingTasksWithEstimateIdRequest', '/building-elements-api'),
    actions: {
      start: {
        type: DATA_PUT_V2_BUILDING_TASKS_WITH_ESTIMATEID_START, payload, config, meta
      },
      successful: {
        type: DATA_PUT_V2_BUILDING_TASKS_WITH_ESTIMATEID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_PUT_V2_BUILDING_TASKS_WITH_ESTIMATEID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_GET_V2_BUILDING_TASKS_BUILDING_TASKS_SCHEDULE_WITH_ESTIMATEID_START = 'DATA_GET_V2_BUILDING_TASKS_BUILDING_TASKS_SCHEDULE_WITH_ESTIMATEID_START'
export const DATA_GET_V2_BUILDING_TASKS_BUILDING_TASKS_SCHEDULE_WITH_ESTIMATEID_SUCCESSFUL = 'DATA_GET_V2_BUILDING_TASKS_BUILDING_TASKS_SCHEDULE_WITH_ESTIMATEID_SUCCESSFUL'
export const DATA_GET_V2_BUILDING_TASKS_BUILDING_TASKS_SCHEDULE_WITH_ESTIMATEID_FAIL = 'DATA_GET_V2_BUILDING_TASKS_BUILDING_TASKS_SCHEDULE_WITH_ESTIMATEID_FAIL'

// Gets a generated building task schedule
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/buildingelements.yaml#/BuildingTasksApiControllerV2/get_v2_building_tasks__estimateId__building_tasks_schedule
export const getV2BuildingTasksBuildingTasksScheduleWithEstimateIdRequest = (
  requestArguments: getV2BuildingTasksBuildingTasksScheduleWithEstimateIdRequestBuildingelementsArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_V2_BUILDING_TASKS_BUILDING_TASKS_SCHEDULE_WITH_ESTIMATEID_START',
      successful: 'DATA_GET_V2_BUILDING_TASKS_BUILDING_TASKS_SCHEDULE_WITH_ESTIMATEID_SUCCESSFUL',
      fail: 'DATA_GET_V2_BUILDING_TASKS_BUILDING_TASKS_SCHEDULE_WITH_ESTIMATEID_FAIL'
    },
    embeddedType: null,
    fnName: 'getV2BuildingTasksBuildingTasksScheduleWithEstimateIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/building-elements-api`,
    method: 'GET',
    url: `/v2/building-tasks/${options?.estimateId || store.app.calculation}/building-tasks-schedule`,
    params: {
      buildingTasks: requestArguments.query['buildingTasks'],
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'getV2BuildingTasksBuildingTasksScheduleWithEstimateIdRequest', '/building-elements-api'),
    actions: {
      start: {
        type: DATA_GET_V2_BUILDING_TASKS_BUILDING_TASKS_SCHEDULE_WITH_ESTIMATEID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_V2_BUILDING_TASKS_BUILDING_TASKS_SCHEDULE_WITH_ESTIMATEID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_V2_BUILDING_TASKS_BUILDING_TASKS_SCHEDULE_WITH_ESTIMATEID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getV2BuildingTasksBuildingTasksScheduleWithEstimateIdRequestDefinitions = (definitionsArguments: {| requestArgs: getV2BuildingTasksBuildingTasksScheduleWithEstimateIdRequestBuildingelementsArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getV2BuildingTasksBuildingTasksScheduleWithEstimateIdRequestBuildingelementsArguments, payload?: Object |} => ({
  fnName: 'getV2BuildingTasksBuildingTasksScheduleWithEstimateIdRequest',
  basePath: '/building-elements-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_GET_PRICELIST_WITH_ESTIMATEID_START = 'DATA_GET_PRICELIST_WITH_ESTIMATEID_START'
export const DATA_GET_PRICELIST_WITH_ESTIMATEID_SUCCESSFUL = 'DATA_GET_PRICELIST_WITH_ESTIMATEID_SUCCESSFUL'
export const DATA_GET_PRICELIST_WITH_ESTIMATEID_FAIL = 'DATA_GET_PRICELIST_WITH_ESTIMATEID_FAIL'

// Returns the price list from the root level
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/buildingelements.yaml#/Price%20List/get_pricelist__estimateId_
export const getPricelistWithEstimateIdRequest = (
  requestArguments: getPricelistWithEstimateIdRequestBuildingelementsArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_PRICELIST_WITH_ESTIMATEID_START',
      successful: 'DATA_GET_PRICELIST_WITH_ESTIMATEID_SUCCESSFUL',
      fail: 'DATA_GET_PRICELIST_WITH_ESTIMATEID_FAIL'
    },
    embeddedType: {
      items: {
        schemaPath: '#/definitions/ElementScheduleItem',
        schemaKey: 'ElementScheduleItem'
      }
    },
    fnName: 'getPricelistWithEstimateIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/building-elements-api`,
    method: 'GET',
    url: `/pricelist/${options?.estimateId || store.app.calculation}`,
    params: {
      listType: requestArguments.query['listType'],
      searchString: requestArguments.query['searchString'],
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      items: {
        schemaPath: '#/definitions/ElementScheduleItem',
        schemaKey: 'ElementScheduleItem'
      }
    }, 'getPricelistWithEstimateIdRequest', '/building-elements-api'),
    actions: {
      start: {
        type: DATA_GET_PRICELIST_WITH_ESTIMATEID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_PRICELIST_WITH_ESTIMATEID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_PRICELIST_WITH_ESTIMATEID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getPricelistWithEstimateIdRequestDefinitions = (definitionsArguments: {| requestArgs: getPricelistWithEstimateIdRequestBuildingelementsArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getPricelistWithEstimateIdRequestBuildingelementsArguments, payload?: Object |} => ({
  fnName: 'getPricelistWithEstimateIdRequest',
  basePath: '/building-elements-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_GET_PRICELIST_ITEM_WITH_ID_START = 'DATA_GET_PRICELIST_ITEM_WITH_ID_START'
export const DATA_GET_PRICELIST_ITEM_WITH_ID_SUCCESSFUL = 'DATA_GET_PRICELIST_ITEM_WITH_ID_SUCCESSFUL'
export const DATA_GET_PRICELIST_ITEM_WITH_ID_FAIL = 'DATA_GET_PRICELIST_ITEM_WITH_ID_FAIL'

// Returns a price list item
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/buildingelements.yaml#/Price%20List/get_pricelist__estimateId__item__id_
export const getPricelistItemWithIdRequest = (
  requestArguments: getPricelistItemWithIdRequestBuildingelementsArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_PRICELIST_ITEM_WITH_ID_START',
      successful: 'DATA_GET_PRICELIST_ITEM_WITH_ID_SUCCESSFUL',
      fail: 'DATA_GET_PRICELIST_ITEM_WITH_ID_FAIL'
    },
    embeddedType: {
      nonEmbedded: {
        schemaPath: '#/definitions/ElementScheduleItem',
        schemaKey: 'ElementScheduleItem'
      }
    },
    fnName: 'getPricelistItemWithIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/building-elements-api`,
    method: 'GET',
    url: `/pricelist/${options?.estimateId || store.app.calculation}/item/${requestArguments.path['id']}`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      nonEmbedded: {
        schemaPath: '#/definitions/ElementScheduleItem',
        schemaKey: 'ElementScheduleItem'
      }
    }, 'getPricelistItemWithIdRequest', '/building-elements-api'),
    actions: {
      start: {
        type: DATA_GET_PRICELIST_ITEM_WITH_ID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_PRICELIST_ITEM_WITH_ID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_PRICELIST_ITEM_WITH_ID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getPricelistItemWithIdRequestDefinitions = (definitionsArguments: {| requestArgs: getPricelistItemWithIdRequestBuildingelementsArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getPricelistItemWithIdRequestBuildingelementsArguments, payload?: Object |} => ({
  fnName: 'getPricelistItemWithIdRequest',
  basePath: '/building-elements-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_GET_PRICELIST_ITEM_ITEMS_WITH_ID_START = 'DATA_GET_PRICELIST_ITEM_ITEMS_WITH_ID_START'
export const DATA_GET_PRICELIST_ITEM_ITEMS_WITH_ID_SUCCESSFUL = 'DATA_GET_PRICELIST_ITEM_ITEMS_WITH_ID_SUCCESSFUL'
export const DATA_GET_PRICELIST_ITEM_ITEMS_WITH_ID_FAIL = 'DATA_GET_PRICELIST_ITEM_ITEMS_WITH_ID_FAIL'

// Returns price list items
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/buildingelements.yaml#/Price%20List/get_pricelist__estimateId__item__id__items
export const getPricelistItemItemsWithIdRequest = (
  requestArguments: getPricelistItemItemsWithIdRequestBuildingelementsArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_PRICELIST_ITEM_ITEMS_WITH_ID_START',
      successful: 'DATA_GET_PRICELIST_ITEM_ITEMS_WITH_ID_SUCCESSFUL',
      fail: 'DATA_GET_PRICELIST_ITEM_ITEMS_WITH_ID_FAIL'
    },
    embeddedType: {
      items: {
        schemaPath: '#/definitions/ElementScheduleItem',
        schemaKey: 'ElementScheduleItem'
      }
    },
    fnName: 'getPricelistItemItemsWithIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/building-elements-api`,
    method: 'GET',
    url: `/pricelist/${options?.estimateId || store.app.calculation}/item/${requestArguments.path['id']}/items`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      items: {
        schemaPath: '#/definitions/ElementScheduleItem',
        schemaKey: 'ElementScheduleItem'
      }
    }, 'getPricelistItemItemsWithIdRequest', '/building-elements-api'),
    actions: {
      start: {
        type: DATA_GET_PRICELIST_ITEM_ITEMS_WITH_ID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_PRICELIST_ITEM_ITEMS_WITH_ID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_PRICELIST_ITEM_ITEMS_WITH_ID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getPricelistItemItemsWithIdRequestDefinitions = (definitionsArguments: {| requestArgs: getPricelistItemItemsWithIdRequestBuildingelementsArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getPricelistItemItemsWithIdRequestBuildingelementsArguments, payload?: Object |} => ({
  fnName: 'getPricelistItemItemsWithIdRequest',
  basePath: '/building-elements-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_GET_PRICELIST_ITEM_ASSEMBLY_PROPERTIES_WITH_ID_START = 'DATA_GET_PRICELIST_ITEM_ASSEMBLY_PROPERTIES_WITH_ID_START'
export const DATA_GET_PRICELIST_ITEM_ASSEMBLY_PROPERTIES_WITH_ID_SUCCESSFUL = 'DATA_GET_PRICELIST_ITEM_ASSEMBLY_PROPERTIES_WITH_ID_SUCCESSFUL'
export const DATA_GET_PRICELIST_ITEM_ASSEMBLY_PROPERTIES_WITH_ID_FAIL = 'DATA_GET_PRICELIST_ITEM_ASSEMBLY_PROPERTIES_WITH_ID_FAIL'

// Returns properties for adding a price item assembly from the price list
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/buildingelements.yaml#/Price%20List/get_pricelist__estimateId__item__id__assembly_properties
export const getPricelistItemAssemblyPropertiesWithIdRequest = (
  requestArguments: getPricelistItemAssemblyPropertiesWithIdRequestBuildingelementsArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_PRICELIST_ITEM_ASSEMBLY_PROPERTIES_WITH_ID_START',
      successful: 'DATA_GET_PRICELIST_ITEM_ASSEMBLY_PROPERTIES_WITH_ID_SUCCESSFUL',
      fail: 'DATA_GET_PRICELIST_ITEM_ASSEMBLY_PROPERTIES_WITH_ID_FAIL'
    },
    embeddedType: {
      properties: {
        schemaPath: '#/definitions/ValueProperty',
        schemaKey: 'ValueProperty'
      }
    },
    fnName: 'getPricelistItemAssemblyPropertiesWithIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/building-elements-api`,
    method: 'GET',
    url: `/pricelist/${options?.estimateId || store.app.calculation}/item/${requestArguments.path['id']}/assembly-properties`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      properties: {
        schemaPath: '#/definitions/ValueProperty',
        schemaKey: 'ValueProperty'
      }
    }, 'getPricelistItemAssemblyPropertiesWithIdRequest', '/building-elements-api'),
    actions: {
      start: {
        type: DATA_GET_PRICELIST_ITEM_ASSEMBLY_PROPERTIES_WITH_ID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_PRICELIST_ITEM_ASSEMBLY_PROPERTIES_WITH_ID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_PRICELIST_ITEM_ASSEMBLY_PROPERTIES_WITH_ID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getPricelistItemAssemblyPropertiesWithIdRequestDefinitions = (definitionsArguments: {| requestArgs: getPricelistItemAssemblyPropertiesWithIdRequestBuildingelementsArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getPricelistItemAssemblyPropertiesWithIdRequestBuildingelementsArguments, payload?: Object |} => ({
  fnName: 'getPricelistItemAssemblyPropertiesWithIdRequest',
  basePath: '/building-elements-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_GET_PRICELIST_COLUMNS_WITH_ESTIMATEID_START = 'DATA_GET_PRICELIST_COLUMNS_WITH_ESTIMATEID_START'
export const DATA_GET_PRICELIST_COLUMNS_WITH_ESTIMATEID_SUCCESSFUL = 'DATA_GET_PRICELIST_COLUMNS_WITH_ESTIMATEID_SUCCESSFUL'
export const DATA_GET_PRICELIST_COLUMNS_WITH_ESTIMATEID_FAIL = 'DATA_GET_PRICELIST_COLUMNS_WITH_ESTIMATEID_FAIL'

// Gets columns for price list
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/buildingelements.yaml#/Price%20List/get_pricelist__estimateId__columns
export const getPricelistColumnsWithEstimateIdRequest = (
  payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_PRICELIST_COLUMNS_WITH_ESTIMATEID_START',
      successful: 'DATA_GET_PRICELIST_COLUMNS_WITH_ESTIMATEID_SUCCESSFUL',
      fail: 'DATA_GET_PRICELIST_COLUMNS_WITH_ESTIMATEID_FAIL'
    },
    embeddedType: {
      properties: {
        schemaPath: '#/definitions/ValueProperty',
        schemaKey: 'ValueProperty'
      }
    },
    fnName: 'getPricelistColumnsWithEstimateIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/building-elements-api`,
    method: 'GET',
    url: `/pricelist/${options?.estimateId || store.app.calculation}/columns`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      properties: {
        schemaPath: '#/definitions/ValueProperty',
        schemaKey: 'ValueProperty'
      }
    }, 'getPricelistColumnsWithEstimateIdRequest', '/building-elements-api'),
    actions: {
      start: {
        type: DATA_GET_PRICELIST_COLUMNS_WITH_ESTIMATEID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_PRICELIST_COLUMNS_WITH_ESTIMATEID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_PRICELIST_COLUMNS_WITH_ESTIMATEID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getPricelistColumnsWithEstimateIdRequestDefinitions = (definitionsArguments: {| payload?: Object |}): {|
  ...TVDGARConfigWithoutRequestArgs, payload?: Object |} => ({
  fnName: 'getPricelistColumnsWithEstimateIdRequest',
  basePath: '/building-elements-api',
  payload: definitionsArguments.payload,
})


export const DATA_GET_SCHEDULE_WITH_ESTIMATEID_START = 'DATA_GET_SCHEDULE_WITH_ESTIMATEID_START'
export const DATA_GET_SCHEDULE_WITH_ESTIMATEID_SUCCESSFUL = 'DATA_GET_SCHEDULE_WITH_ESTIMATEID_SUCCESSFUL'
export const DATA_GET_SCHEDULE_WITH_ESTIMATEID_FAIL = 'DATA_GET_SCHEDULE_WITH_ESTIMATEID_FAIL'

// Returns an element schedule root
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/buildingelements.yaml#/Building%20Element%20Schedule/get_schedule__estimateId_
export const getScheduleWithEstimateIdRequest = (
  requestArguments: getScheduleWithEstimateIdRequestBuildingelementsArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_SCHEDULE_WITH_ESTIMATEID_START',
      successful: 'DATA_GET_SCHEDULE_WITH_ESTIMATEID_SUCCESSFUL',
      fail: 'DATA_GET_SCHEDULE_WITH_ESTIMATEID_FAIL'
    },
    embeddedType: {
      items: {
        schemaPath: '#/definitions/ElementScheduleItem',
        schemaKey: 'ElementScheduleItem'
      }
    },
    fnName: 'getScheduleWithEstimateIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/building-elements-api`,
    method: 'GET',
    url: `/schedule/${options?.estimateId || store.app.calculation}`,
    params: {
      listType: requestArguments.query['listType'],
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      items: {
        schemaPath: '#/definitions/ElementScheduleItem',
        schemaKey: 'ElementScheduleItem'
      }
    }, 'getScheduleWithEstimateIdRequest', '/building-elements-api'),
    actions: {
      start: {
        type: DATA_GET_SCHEDULE_WITH_ESTIMATEID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_SCHEDULE_WITH_ESTIMATEID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_SCHEDULE_WITH_ESTIMATEID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getScheduleWithEstimateIdRequestDefinitions = (definitionsArguments: {| requestArgs: getScheduleWithEstimateIdRequestBuildingelementsArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getScheduleWithEstimateIdRequestBuildingelementsArguments, payload?: Object |} => ({
  fnName: 'getScheduleWithEstimateIdRequest',
  basePath: '/building-elements-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_DELETE_SCHEDULE_WITH_ESTIMATEID_START = 'DATA_DELETE_SCHEDULE_WITH_ESTIMATEID_START'
export const DATA_DELETE_SCHEDULE_WITH_ESTIMATEID_SUCCESSFUL = 'DATA_DELETE_SCHEDULE_WITH_ESTIMATEID_SUCCESSFUL'
export const DATA_DELETE_SCHEDULE_WITH_ESTIMATEID_FAIL = 'DATA_DELETE_SCHEDULE_WITH_ESTIMATEID_FAIL'

// Delete a element schedule
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/buildingelements.yaml#/Building%20Element%20Schedule/delete_schedule__estimateId_
export const deleteScheduleWithEstimateIdRequest = (
  payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_DELETE_SCHEDULE_WITH_ESTIMATEID_START',
      successful: 'DATA_DELETE_SCHEDULE_WITH_ESTIMATEID_SUCCESSFUL',
      fail: 'DATA_DELETE_SCHEDULE_WITH_ESTIMATEID_FAIL'
    },
    embeddedType: null,
    fnName: 'deleteScheduleWithEstimateIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/building-elements-api`,
    method: 'DELETE',
    url: `/schedule/${options?.estimateId || store.app.calculation}`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'deleteScheduleWithEstimateIdRequest', '/building-elements-api'),
    actions: {
      start: {
        type: DATA_DELETE_SCHEDULE_WITH_ESTIMATEID_START, payload, config, meta
      },
      successful: {
        type: DATA_DELETE_SCHEDULE_WITH_ESTIMATEID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_DELETE_SCHEDULE_WITH_ESTIMATEID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_PUT_SCHEDULE_WITH_ESTIMATEID_START = 'DATA_PUT_SCHEDULE_WITH_ESTIMATEID_START'
export const DATA_PUT_SCHEDULE_WITH_ESTIMATEID_SUCCESSFUL = 'DATA_PUT_SCHEDULE_WITH_ESTIMATEID_SUCCESSFUL'
export const DATA_PUT_SCHEDULE_WITH_ESTIMATEID_FAIL = 'DATA_PUT_SCHEDULE_WITH_ESTIMATEID_FAIL'

// Import current reference schedule as new building element schedule. Current building element schedule will be replaced.
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/buildingelements.yaml#/Building%20Element%20Schedule/put_schedule__estimateId_
export const putScheduleWithEstimateIdRequest = (
  requestArguments: putScheduleWithEstimateIdRequestBuildingelementsArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_PUT_SCHEDULE_WITH_ESTIMATEID_START',
      successful: 'DATA_PUT_SCHEDULE_WITH_ESTIMATEID_SUCCESSFUL',
      fail: 'DATA_PUT_SCHEDULE_WITH_ESTIMATEID_FAIL'
    },
    embeddedType: null,
    fnName: 'putScheduleWithEstimateIdRequest'
  }
  const config = {
    headers: { 'Content-Type': 'application/json' },
    baseURL: `${API_BASE_URL}/building-elements-api`,
    method: 'PUT',
    url: `/schedule/${options?.estimateId || store.app.calculation}`,
    params: {
      importReference: requestArguments.query?.['importReference'],
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: requestArguments.body
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'putScheduleWithEstimateIdRequest', '/building-elements-api'),
    actions: {
      start: {
        type: DATA_PUT_SCHEDULE_WITH_ESTIMATEID_START, payload, config, meta
      },
      successful: {
        type: DATA_PUT_SCHEDULE_WITH_ESTIMATEID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_PUT_SCHEDULE_WITH_ESTIMATEID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_POST_SCHEDULE_WITH_ESTIMATEID_START = 'DATA_POST_SCHEDULE_WITH_ESTIMATEID_START'
export const DATA_POST_SCHEDULE_WITH_ESTIMATEID_SUCCESSFUL = 'DATA_POST_SCHEDULE_WITH_ESTIMATEID_SUCCESSFUL'
export const DATA_POST_SCHEDULE_WITH_ESTIMATEID_FAIL = 'DATA_POST_SCHEDULE_WITH_ESTIMATEID_FAIL'

// Adds elements from the reference schedule to the current building element schedule. Existing items are not replaced.
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/buildingelements.yaml#/Building%20Element%20Schedule/post_schedule__estimateId_
export const postScheduleWithEstimateIdRequest = (
  requestArguments: postScheduleWithEstimateIdRequestBuildingelementsArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_POST_SCHEDULE_WITH_ESTIMATEID_START',
      successful: 'DATA_POST_SCHEDULE_WITH_ESTIMATEID_SUCCESSFUL',
      fail: 'DATA_POST_SCHEDULE_WITH_ESTIMATEID_FAIL'
    },
    embeddedType: null,
    fnName: 'postScheduleWithEstimateIdRequest'
  }
  const config = {
    headers: { 'Content-Type': 'application/json' },
    baseURL: `${API_BASE_URL}/building-elements-api`,
    method: 'POST',
    url: `/schedule/${options?.estimateId || store.app.calculation}`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: requestArguments.body
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'postScheduleWithEstimateIdRequest', '/building-elements-api'),
    actions: {
      start: {
        type: DATA_POST_SCHEDULE_WITH_ESTIMATEID_START, payload, config, meta
      },
      successful: {
        type: DATA_POST_SCHEDULE_WITH_ESTIMATEID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_POST_SCHEDULE_WITH_ESTIMATEID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_GET_SCHEDULE_COLUMNS_WITH_ESTIMATEID_START = 'DATA_GET_SCHEDULE_COLUMNS_WITH_ESTIMATEID_START'
export const DATA_GET_SCHEDULE_COLUMNS_WITH_ESTIMATEID_SUCCESSFUL = 'DATA_GET_SCHEDULE_COLUMNS_WITH_ESTIMATEID_SUCCESSFUL'
export const DATA_GET_SCHEDULE_COLUMNS_WITH_ESTIMATEID_FAIL = 'DATA_GET_SCHEDULE_COLUMNS_WITH_ESTIMATEID_FAIL'

// Get possible columns for element schedule
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/buildingelements.yaml#/Building%20Element%20Schedule/get_schedule__estimateId__columns
export const getScheduleColumnsWithEstimateIdRequest = (
  payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_SCHEDULE_COLUMNS_WITH_ESTIMATEID_START',
      successful: 'DATA_GET_SCHEDULE_COLUMNS_WITH_ESTIMATEID_SUCCESSFUL',
      fail: 'DATA_GET_SCHEDULE_COLUMNS_WITH_ESTIMATEID_FAIL'
    },
    embeddedType: {
      properties: {
        schemaPath: '#/definitions/ValueProperty',
        schemaKey: 'ValueProperty'
      }
    },
    fnName: 'getScheduleColumnsWithEstimateIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/building-elements-api`,
    method: 'GET',
    url: `/schedule/${options?.estimateId || store.app.calculation}/columns`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      properties: {
        schemaPath: '#/definitions/ValueProperty',
        schemaKey: 'ValueProperty'
      }
    }, 'getScheduleColumnsWithEstimateIdRequest', '/building-elements-api'),
    actions: {
      start: {
        type: DATA_GET_SCHEDULE_COLUMNS_WITH_ESTIMATEID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_SCHEDULE_COLUMNS_WITH_ESTIMATEID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_SCHEDULE_COLUMNS_WITH_ESTIMATEID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getScheduleColumnsWithEstimateIdRequestDefinitions = (definitionsArguments: {| payload?: Object |}): {|
  ...TVDGARConfigWithoutRequestArgs, payload?: Object |} => ({
  fnName: 'getScheduleColumnsWithEstimateIdRequest',
  basePath: '/building-elements-api',
  payload: definitionsArguments.payload,
})


export const DATA_GET_SCHEDULE_VALUES_WITH_ESTIMATEID_START = 'DATA_GET_SCHEDULE_VALUES_WITH_ESTIMATEID_START'
export const DATA_GET_SCHEDULE_VALUES_WITH_ESTIMATEID_SUCCESSFUL = 'DATA_GET_SCHEDULE_VALUES_WITH_ESTIMATEID_SUCCESSFUL'
export const DATA_GET_SCHEDULE_VALUES_WITH_ESTIMATEID_FAIL = 'DATA_GET_SCHEDULE_VALUES_WITH_ESTIMATEID_FAIL'

// Returns values calculated for building element schedule
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/buildingelements.yaml#/Building%20Element%20Schedule/get_schedule__estimateId__values
export const getScheduleValuesWithEstimateIdRequest = (
  requestArguments: getScheduleValuesWithEstimateIdRequestBuildingelementsArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_SCHEDULE_VALUES_WITH_ESTIMATEID_START',
      successful: 'DATA_GET_SCHEDULE_VALUES_WITH_ESTIMATEID_SUCCESSFUL',
      fail: 'DATA_GET_SCHEDULE_VALUES_WITH_ESTIMATEID_FAIL'
    },
    embeddedType: {
      properties: {
        schemaPath: '#/definitions/ValueProperty',
        schemaKey: 'ValueProperty'
      }
    },
    fnName: 'getScheduleValuesWithEstimateIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/building-elements-api`,
    method: 'GET',
    url: `/schedule/${options?.estimateId || store.app.calculation}/values`,
    params: {
      view: requestArguments.query?.['view'],
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      properties: {
        schemaPath: '#/definitions/ValueProperty',
        schemaKey: 'ValueProperty'
      }
    }, 'getScheduleValuesWithEstimateIdRequest', '/building-elements-api'),
    actions: {
      start: {
        type: DATA_GET_SCHEDULE_VALUES_WITH_ESTIMATEID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_SCHEDULE_VALUES_WITH_ESTIMATEID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_SCHEDULE_VALUES_WITH_ESTIMATEID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getScheduleValuesWithEstimateIdRequestDefinitions = (definitionsArguments: {| requestArgs: getScheduleValuesWithEstimateIdRequestBuildingelementsArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getScheduleValuesWithEstimateIdRequestBuildingelementsArguments, payload?: Object |} => ({
  fnName: 'getScheduleValuesWithEstimateIdRequest',
  basePath: '/building-elements-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_GET_SCHEDULE_ITEM_WITH_ID_START = 'DATA_GET_SCHEDULE_ITEM_WITH_ID_START'
export const DATA_GET_SCHEDULE_ITEM_WITH_ID_SUCCESSFUL = 'DATA_GET_SCHEDULE_ITEM_WITH_ID_SUCCESSFUL'
export const DATA_GET_SCHEDULE_ITEM_WITH_ID_FAIL = 'DATA_GET_SCHEDULE_ITEM_WITH_ID_FAIL'

// Returns a price item for element schedule
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/buildingelements.yaml#/Building%20Element%20Schedule/get_schedule__estimateId__item__id_
export const getScheduleItemWithIdRequest = (
  requestArguments: getScheduleItemWithIdRequestBuildingelementsArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_SCHEDULE_ITEM_WITH_ID_START',
      successful: 'DATA_GET_SCHEDULE_ITEM_WITH_ID_SUCCESSFUL',
      fail: 'DATA_GET_SCHEDULE_ITEM_WITH_ID_FAIL'
    },
    embeddedType: {
      nonEmbedded: {
        schemaPath: '#/definitions/ElementScheduleItem',
        schemaKey: 'ElementScheduleItem'
      }
    },
    fnName: 'getScheduleItemWithIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/building-elements-api`,
    method: 'GET',
    url: `/schedule/${options?.estimateId || store.app.calculation}/item/${requestArguments.path['id']}`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      nonEmbedded: {
        schemaPath: '#/definitions/ElementScheduleItem',
        schemaKey: 'ElementScheduleItem'
      }
    }, 'getScheduleItemWithIdRequest', '/building-elements-api'),
    actions: {
      start: {
        type: DATA_GET_SCHEDULE_ITEM_WITH_ID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_SCHEDULE_ITEM_WITH_ID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_SCHEDULE_ITEM_WITH_ID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getScheduleItemWithIdRequestDefinitions = (definitionsArguments: {| requestArgs: getScheduleItemWithIdRequestBuildingelementsArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getScheduleItemWithIdRequestBuildingelementsArguments, payload?: Object |} => ({
  fnName: 'getScheduleItemWithIdRequest',
  basePath: '/building-elements-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_GET_SCHEDULE_ITEM_INFO_WITH_ID_START = 'DATA_GET_SCHEDULE_ITEM_INFO_WITH_ID_START'
export const DATA_GET_SCHEDULE_ITEM_INFO_WITH_ID_SUCCESSFUL = 'DATA_GET_SCHEDULE_ITEM_INFO_WITH_ID_SUCCESSFUL'
export const DATA_GET_SCHEDULE_ITEM_INFO_WITH_ID_FAIL = 'DATA_GET_SCHEDULE_ITEM_INFO_WITH_ID_FAIL'

// Returns information text about a schedule item
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/buildingelements.yaml#/Building%20Element%20Schedule/get_schedule__estimateId__item__id__info
export const getScheduleItemInfoWithIdRequest = (
  requestArguments: getScheduleItemInfoWithIdRequestBuildingelementsArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_SCHEDULE_ITEM_INFO_WITH_ID_START',
      successful: 'DATA_GET_SCHEDULE_ITEM_INFO_WITH_ID_SUCCESSFUL',
      fail: 'DATA_GET_SCHEDULE_ITEM_INFO_WITH_ID_FAIL'
    },
    embeddedType: null,
    fnName: 'getScheduleItemInfoWithIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/building-elements-api`,
    method: 'GET',
    url: `/schedule/${options?.estimateId || store.app.calculation}/item/${requestArguments.path['id']}/info`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'getScheduleItemInfoWithIdRequest', '/building-elements-api'),
    actions: {
      start: {
        type: DATA_GET_SCHEDULE_ITEM_INFO_WITH_ID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_SCHEDULE_ITEM_INFO_WITH_ID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_SCHEDULE_ITEM_INFO_WITH_ID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getScheduleItemInfoWithIdRequestDefinitions = (definitionsArguments: {| requestArgs: getScheduleItemInfoWithIdRequestBuildingelementsArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getScheduleItemInfoWithIdRequestBuildingelementsArguments, payload?: Object |} => ({
  fnName: 'getScheduleItemInfoWithIdRequest',
  basePath: '/building-elements-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_GET_SCHEDULE_ITEM_ITEMS_WITH_ID_START = 'DATA_GET_SCHEDULE_ITEM_ITEMS_WITH_ID_START'
export const DATA_GET_SCHEDULE_ITEM_ITEMS_WITH_ID_SUCCESSFUL = 'DATA_GET_SCHEDULE_ITEM_ITEMS_WITH_ID_SUCCESSFUL'
export const DATA_GET_SCHEDULE_ITEM_ITEMS_WITH_ID_FAIL = 'DATA_GET_SCHEDULE_ITEM_ITEMS_WITH_ID_FAIL'

// Returns child items for a price item
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/buildingelements.yaml#/Building%20Element%20Schedule/get_schedule__estimateId__item__id__items
export const getScheduleItemItemsWithIdRequest = (
  requestArguments: getScheduleItemItemsWithIdRequestBuildingelementsArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_SCHEDULE_ITEM_ITEMS_WITH_ID_START',
      successful: 'DATA_GET_SCHEDULE_ITEM_ITEMS_WITH_ID_SUCCESSFUL',
      fail: 'DATA_GET_SCHEDULE_ITEM_ITEMS_WITH_ID_FAIL'
    },
    embeddedType: {
      items: {
        schemaPath: '#/definitions/ElementScheduleItem',
        schemaKey: 'ElementScheduleItem'
      }
    },
    fnName: 'getScheduleItemItemsWithIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/building-elements-api`,
    method: 'GET',
    url: `/schedule/${options?.estimateId || store.app.calculation}/item/${requestArguments.path['id']}/items`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      items: {
        schemaPath: '#/definitions/ElementScheduleItem',
        schemaKey: 'ElementScheduleItem'
      }
    }, 'getScheduleItemItemsWithIdRequest', '/building-elements-api'),
    actions: {
      start: {
        type: DATA_GET_SCHEDULE_ITEM_ITEMS_WITH_ID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_SCHEDULE_ITEM_ITEMS_WITH_ID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_SCHEDULE_ITEM_ITEMS_WITH_ID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getScheduleItemItemsWithIdRequestDefinitions = (definitionsArguments: {| requestArgs: getScheduleItemItemsWithIdRequestBuildingelementsArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getScheduleItemItemsWithIdRequestBuildingelementsArguments, payload?: Object |} => ({
  fnName: 'getScheduleItemItemsWithIdRequest',
  basePath: '/building-elements-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_DELETE_HEADING_WITH_ID_START = 'DATA_DELETE_HEADING_WITH_ID_START'
export const DATA_DELETE_HEADING_WITH_ID_SUCCESSFUL = 'DATA_DELETE_HEADING_WITH_ID_SUCCESSFUL'
export const DATA_DELETE_HEADING_WITH_ID_FAIL = 'DATA_DELETE_HEADING_WITH_ID_FAIL'

// Delete a heading
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/buildingelements.yaml#/Heading/delete_heading__id_
export const deleteHeadingWithIdRequest = (
  requestArguments: deleteHeadingWithIdRequestBuildingelementsArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_DELETE_HEADING_WITH_ID_START',
      successful: 'DATA_DELETE_HEADING_WITH_ID_SUCCESSFUL',
      fail: 'DATA_DELETE_HEADING_WITH_ID_FAIL'
    },
    embeddedType: null,
    fnName: 'deleteHeadingWithIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/building-elements-api`,
    method: 'DELETE',
    url: `/heading/${requestArguments.path['id']}`,
    params: {
      estimateId: options?.estimateId || store.app.calculation,
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'deleteHeadingWithIdRequest', '/building-elements-api'),
    actions: {
      start: {
        type: DATA_DELETE_HEADING_WITH_ID_START, payload, config, meta
      },
      successful: {
        type: DATA_DELETE_HEADING_WITH_ID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_DELETE_HEADING_WITH_ID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_POST_HEADING_WITH_ID_START = 'DATA_POST_HEADING_WITH_ID_START'
export const DATA_POST_HEADING_WITH_ID_SUCCESSFUL = 'DATA_POST_HEADING_WITH_ID_SUCCESSFUL'
export const DATA_POST_HEADING_WITH_ID_FAIL = 'DATA_POST_HEADING_WITH_ID_FAIL'

// Create a new heading
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/buildingelements.yaml#/Heading/post_heading__id_
export const postHeadingWithIdRequest = (
  requestArguments: postHeadingWithIdRequestBuildingelementsArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_POST_HEADING_WITH_ID_START',
      successful: 'DATA_POST_HEADING_WITH_ID_SUCCESSFUL',
      fail: 'DATA_POST_HEADING_WITH_ID_FAIL'
    },
    embeddedType: null,
    fnName: 'postHeadingWithIdRequest'
  }
  const config = {
    headers: { 'Content-Type': 'application/json' },
    baseURL: `${API_BASE_URL}/building-elements-api`,
    method: 'POST',
    url: `/heading/${requestArguments.path['id']}`,
    params: {
      estimateId: options?.estimateId || store.app.calculation,
      copyId: requestArguments.query?.['copyId'],
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: requestArguments.body
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'postHeadingWithIdRequest', '/building-elements-api'),
    actions: {
      start: {
        type: DATA_POST_HEADING_WITH_ID_START, payload, config, meta
      },
      successful: {
        type: DATA_POST_HEADING_WITH_ID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_POST_HEADING_WITH_ID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_GET_HEADING_WITH_ID_START = 'DATA_GET_HEADING_WITH_ID_START'
export const DATA_GET_HEADING_WITH_ID_SUCCESSFUL = 'DATA_GET_HEADING_WITH_ID_SUCCESSFUL'
export const DATA_GET_HEADING_WITH_ID_FAIL = 'DATA_GET_HEADING_WITH_ID_FAIL'

// Returns a heading for element schedule
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/buildingelements.yaml#/Heading/get_heading__id_
export const getHeadingWithIdRequest = (
  requestArguments: getHeadingWithIdRequestBuildingelementsArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_HEADING_WITH_ID_START',
      successful: 'DATA_GET_HEADING_WITH_ID_SUCCESSFUL',
      fail: 'DATA_GET_HEADING_WITH_ID_FAIL'
    },
    embeddedType: {
      nonEmbedded: {
        schemaPath: '#/definitions/ElementScheduleItem',
        schemaKey: 'ElementScheduleItem'
      }
    },
    fnName: 'getHeadingWithIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/building-elements-api`,
    method: 'GET',
    url: `/heading/${requestArguments.path['id']}`,
    params: {
      estimateId: options?.estimateId || store.app.calculation,
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      nonEmbedded: {
        schemaPath: '#/definitions/ElementScheduleItem',
        schemaKey: 'ElementScheduleItem'
      }
    }, 'getHeadingWithIdRequest', '/building-elements-api'),
    actions: {
      start: {
        type: DATA_GET_HEADING_WITH_ID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_HEADING_WITH_ID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_HEADING_WITH_ID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getHeadingWithIdRequestDefinitions = (definitionsArguments: {| requestArgs: getHeadingWithIdRequestBuildingelementsArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getHeadingWithIdRequestBuildingelementsArguments, payload?: Object |} => ({
  fnName: 'getHeadingWithIdRequest',
  basePath: '/building-elements-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_PATCH_HEADING_WITH_ID_START = 'DATA_PATCH_HEADING_WITH_ID_START'
export const DATA_PATCH_HEADING_WITH_ID_SUCCESSFUL = 'DATA_PATCH_HEADING_WITH_ID_SUCCESSFUL'
export const DATA_PATCH_HEADING_WITH_ID_FAIL = 'DATA_PATCH_HEADING_WITH_ID_FAIL'

// Update heading column data properties
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/buildingelements.yaml#/Heading/patch_heading__id_
export const patchHeadingWithIdRequest = (
  requestArguments: patchHeadingWithIdRequestBuildingelementsArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_PATCH_HEADING_WITH_ID_START',
      successful: 'DATA_PATCH_HEADING_WITH_ID_SUCCESSFUL',
      fail: 'DATA_PATCH_HEADING_WITH_ID_FAIL'
    },
    embeddedType: null,
    fnName: 'patchHeadingWithIdRequest'
  }
  const config = {
    headers: { 'Content-Type': 'application/json' },
    baseURL: `${API_BASE_URL}/building-elements-api`,
    method: 'PATCH',
    url: `/heading/${requestArguments.path['id']}`,
    params: {
      estimateId: options?.estimateId || store.app.calculation,
      changeOrder: requestArguments.query?.['changeOrder'],
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: requestArguments.body
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'patchHeadingWithIdRequest', '/building-elements-api'),
    actions: {
      start: {
        type: DATA_PATCH_HEADING_WITH_ID_START, payload, config, meta
      },
      successful: {
        type: DATA_PATCH_HEADING_WITH_ID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_PATCH_HEADING_WITH_ID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_GET_HEADING_CAN_MOVE_TO_WITH_ID_START = 'DATA_GET_HEADING_CAN_MOVE_TO_WITH_ID_START'
export const DATA_GET_HEADING_CAN_MOVE_TO_WITH_ID_SUCCESSFUL = 'DATA_GET_HEADING_CAN_MOVE_TO_WITH_ID_SUCCESSFUL'
export const DATA_GET_HEADING_CAN_MOVE_TO_WITH_ID_FAIL = 'DATA_GET_HEADING_CAN_MOVE_TO_WITH_ID_FAIL'

// Get id and description for schedule items to which the heading can be moved to
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/buildingelements.yaml#/Heading/get_heading__id__can_move_to
export const getHeadingCanMoveToWithIdRequest = (
  requestArguments: getHeadingCanMoveToWithIdRequestBuildingelementsArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_HEADING_CAN_MOVE_TO_WITH_ID_START',
      successful: 'DATA_GET_HEADING_CAN_MOVE_TO_WITH_ID_SUCCESSFUL',
      fail: 'DATA_GET_HEADING_CAN_MOVE_TO_WITH_ID_FAIL'
    },
    embeddedType: {
      items: {
        schemaPath: '#/definitions/Enumeral',
        schemaKey: 'Enumeral'
      }
    },
    fnName: 'getHeadingCanMoveToWithIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/building-elements-api`,
    method: 'GET',
    url: `/heading/${requestArguments.path['id']}/can-move-to`,
    params: {
      estimateId: options?.estimateId || store.app.calculation,
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      items: {
        schemaPath: '#/definitions/Enumeral',
        schemaKey: 'Enumeral'
      }
    }, 'getHeadingCanMoveToWithIdRequest', '/building-elements-api'),
    actions: {
      start: {
        type: DATA_GET_HEADING_CAN_MOVE_TO_WITH_ID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_HEADING_CAN_MOVE_TO_WITH_ID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_HEADING_CAN_MOVE_TO_WITH_ID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getHeadingCanMoveToWithIdRequestDefinitions = (definitionsArguments: {| requestArgs: getHeadingCanMoveToWithIdRequestBuildingelementsArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getHeadingCanMoveToWithIdRequestBuildingelementsArguments, payload?: Object |} => ({
  fnName: 'getHeadingCanMoveToWithIdRequest',
  basePath: '/building-elements-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_DELETE_PRICEITEM_WITH_ID_START = 'DATA_DELETE_PRICEITEM_WITH_ID_START'
export const DATA_DELETE_PRICEITEM_WITH_ID_SUCCESSFUL = 'DATA_DELETE_PRICEITEM_WITH_ID_SUCCESSFUL'
export const DATA_DELETE_PRICEITEM_WITH_ID_FAIL = 'DATA_DELETE_PRICEITEM_WITH_ID_FAIL'

// Delete a price item. Will queue calculation if needed
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/buildingelements.yaml#/Price%20Item/delete_priceitem__id_
export const deletePriceitemWithIdRequest = (
  requestArguments: deletePriceitemWithIdRequestBuildingelementsArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_DELETE_PRICEITEM_WITH_ID_START',
      successful: 'DATA_DELETE_PRICEITEM_WITH_ID_SUCCESSFUL',
      fail: 'DATA_DELETE_PRICEITEM_WITH_ID_FAIL'
    },
    embeddedType: null,
    fnName: 'deletePriceitemWithIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/building-elements-api`,
    method: 'DELETE',
    url: `/priceitem/${requestArguments.path['id']}`,
    params: {
      estimateId: options?.estimateId || store.app.calculation,
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'deletePriceitemWithIdRequest', '/building-elements-api'),
    actions: {
      start: {
        type: DATA_DELETE_PRICEITEM_WITH_ID_START, payload, config, meta
      },
      successful: {
        type: DATA_DELETE_PRICEITEM_WITH_ID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_DELETE_PRICEITEM_WITH_ID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_POST_PRICEITEM_WITH_ID_START = 'DATA_POST_PRICEITEM_WITH_ID_START'
export const DATA_POST_PRICEITEM_WITH_ID_SUCCESSFUL = 'DATA_POST_PRICEITEM_WITH_ID_SUCCESSFUL'
export const DATA_POST_PRICEITEM_WITH_ID_FAIL = 'DATA_POST_PRICEITEM_WITH_ID_FAIL'

// Create a new price item
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/buildingelements.yaml#/Price%20Item/post_priceitem__id_
export const postPriceitemWithIdRequest = (
  requestArguments: postPriceitemWithIdRequestBuildingelementsArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_POST_PRICEITEM_WITH_ID_START',
      successful: 'DATA_POST_PRICEITEM_WITH_ID_SUCCESSFUL',
      fail: 'DATA_POST_PRICEITEM_WITH_ID_FAIL'
    },
    embeddedType: null,
    fnName: 'postPriceitemWithIdRequest'
  }
  const config = {
    headers: { 'Content-Type': 'application/json' },
    baseURL: `${API_BASE_URL}/building-elements-api`,
    method: 'POST',
    url: `/priceitem/${requestArguments.path['id']}`,
    params: {
      estimateId: options?.estimateId || store.app.calculation,
      copyId: requestArguments.query?.['copyId'],
      pricelistItemId: requestArguments.query?.['pricelistItemId'],
      assemblyId: requestArguments.query?.['assemblyId'],
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: requestArguments.body
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'postPriceitemWithIdRequest', '/building-elements-api'),
    actions: {
      start: {
        type: DATA_POST_PRICEITEM_WITH_ID_START, payload, config, meta
      },
      successful: {
        type: DATA_POST_PRICEITEM_WITH_ID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_POST_PRICEITEM_WITH_ID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_GET_PRICEITEM_WITH_ID_START = 'DATA_GET_PRICEITEM_WITH_ID_START'
export const DATA_GET_PRICEITEM_WITH_ID_SUCCESSFUL = 'DATA_GET_PRICEITEM_WITH_ID_SUCCESSFUL'
export const DATA_GET_PRICEITEM_WITH_ID_FAIL = 'DATA_GET_PRICEITEM_WITH_ID_FAIL'

// Returns price item for building element schedule
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/buildingelements.yaml#/Price%20Item/get_priceitem__id_
export const getPriceitemWithIdRequest = (
  requestArguments: getPriceitemWithIdRequestBuildingelementsArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_PRICEITEM_WITH_ID_START',
      successful: 'DATA_GET_PRICEITEM_WITH_ID_SUCCESSFUL',
      fail: 'DATA_GET_PRICEITEM_WITH_ID_FAIL'
    },
    embeddedType: {
      nonEmbedded: {
        schemaPath: '#/definitions/ElementScheduleItem',
        schemaKey: 'ElementScheduleItem'
      }
    },
    fnName: 'getPriceitemWithIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/building-elements-api`,
    method: 'GET',
    url: `/priceitem/${requestArguments.path['id']}`,
    params: {
      estimateId: options?.estimateId || store.app.calculation,
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      nonEmbedded: {
        schemaPath: '#/definitions/ElementScheduleItem',
        schemaKey: 'ElementScheduleItem'
      }
    }, 'getPriceitemWithIdRequest', '/building-elements-api'),
    actions: {
      start: {
        type: DATA_GET_PRICEITEM_WITH_ID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_PRICEITEM_WITH_ID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_PRICEITEM_WITH_ID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getPriceitemWithIdRequestDefinitions = (definitionsArguments: {| requestArgs: getPriceitemWithIdRequestBuildingelementsArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getPriceitemWithIdRequestBuildingelementsArguments, payload?: Object |} => ({
  fnName: 'getPriceitemWithIdRequest',
  basePath: '/building-elements-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_PATCH_PRICEITEM_WITH_ID_START = 'DATA_PATCH_PRICEITEM_WITH_ID_START'
export const DATA_PATCH_PRICEITEM_WITH_ID_SUCCESSFUL = 'DATA_PATCH_PRICEITEM_WITH_ID_SUCCESSFUL'
export const DATA_PATCH_PRICEITEM_WITH_ID_FAIL = 'DATA_PATCH_PRICEITEM_WITH_ID_FAIL'

// Update column data properties
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/buildingelements.yaml#/Price%20Item/patch_priceitem__id_
export const patchPriceitemWithIdRequest = (
  requestArguments: patchPriceitemWithIdRequestBuildingelementsArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_PATCH_PRICEITEM_WITH_ID_START',
      successful: 'DATA_PATCH_PRICEITEM_WITH_ID_SUCCESSFUL',
      fail: 'DATA_PATCH_PRICEITEM_WITH_ID_FAIL'
    },
    embeddedType: null,
    fnName: 'patchPriceitemWithIdRequest'
  }
  const config = {
    headers: { 'Content-Type': 'application/json' },
    baseURL: `${API_BASE_URL}/building-elements-api`,
    method: 'PATCH',
    url: `/priceitem/${requestArguments.path['id']}`,
    params: {
      estimateId: options?.estimateId || store.app.calculation,
      resetPricing: requestArguments.query?.['resetPricing'],
      changeOrder: requestArguments.query?.['changeOrder'],
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: requestArguments.body
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'patchPriceitemWithIdRequest', '/building-elements-api'),
    actions: {
      start: {
        type: DATA_PATCH_PRICEITEM_WITH_ID_START, payload, config, meta
      },
      successful: {
        type: DATA_PATCH_PRICEITEM_WITH_ID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_PATCH_PRICEITEM_WITH_ID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_GET_PRICEITEM_PROPERTIES_WITH_ID_START = 'DATA_GET_PRICEITEM_PROPERTIES_WITH_ID_START'
export const DATA_GET_PRICEITEM_PROPERTIES_WITH_ID_SUCCESSFUL = 'DATA_GET_PRICEITEM_PROPERTIES_WITH_ID_SUCCESSFUL'
export const DATA_GET_PRICEITEM_PROPERTIES_WITH_ID_FAIL = 'DATA_GET_PRICEITEM_PROPERTIES_WITH_ID_FAIL'

// Returns all properties of a price item
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/buildingelements.yaml#/Price%20Item/get_priceitem__id__properties
export const getPriceitemPropertiesWithIdRequest = (
  requestArguments: getPriceitemPropertiesWithIdRequestBuildingelementsArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_PRICEITEM_PROPERTIES_WITH_ID_START',
      successful: 'DATA_GET_PRICEITEM_PROPERTIES_WITH_ID_SUCCESSFUL',
      fail: 'DATA_GET_PRICEITEM_PROPERTIES_WITH_ID_FAIL'
    },
    embeddedType: {
      properties: {
        schemaPath: '#/definitions/ValueProperty',
        schemaKey: 'ValueProperty'
      }
    },
    fnName: 'getPriceitemPropertiesWithIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/building-elements-api`,
    method: 'GET',
    url: `/priceitem/${requestArguments.path['id']}/properties`,
    params: {
      estimateId: options?.estimateId || store.app.calculation,
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      properties: {
        schemaPath: '#/definitions/ValueProperty',
        schemaKey: 'ValueProperty'
      }
    }, 'getPriceitemPropertiesWithIdRequest', '/building-elements-api'),
    actions: {
      start: {
        type: DATA_GET_PRICEITEM_PROPERTIES_WITH_ID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_PRICEITEM_PROPERTIES_WITH_ID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_PRICEITEM_PROPERTIES_WITH_ID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getPriceitemPropertiesWithIdRequestDefinitions = (definitionsArguments: {| requestArgs: getPriceitemPropertiesWithIdRequestBuildingelementsArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getPriceitemPropertiesWithIdRequestBuildingelementsArguments, payload?: Object |} => ({
  fnName: 'getPriceitemPropertiesWithIdRequest',
  basePath: '/building-elements-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_PATCH_PRICEITEM_PROPERTIES_WITH_ID_START = 'DATA_PATCH_PRICEITEM_PROPERTIES_WITH_ID_START'
export const DATA_PATCH_PRICEITEM_PROPERTIES_WITH_ID_SUCCESSFUL = 'DATA_PATCH_PRICEITEM_PROPERTIES_WITH_ID_SUCCESSFUL'
export const DATA_PATCH_PRICEITEM_PROPERTIES_WITH_ID_FAIL = 'DATA_PATCH_PRICEITEM_PROPERTIES_WITH_ID_FAIL'

// Update price item properties
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/buildingelements.yaml#/Price%20Item/patch_priceitem__id__properties
export const patchPriceitemPropertiesWithIdRequest = (
  requestArguments: patchPriceitemPropertiesWithIdRequestBuildingelementsArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_PATCH_PRICEITEM_PROPERTIES_WITH_ID_START',
      successful: 'DATA_PATCH_PRICEITEM_PROPERTIES_WITH_ID_SUCCESSFUL',
      fail: 'DATA_PATCH_PRICEITEM_PROPERTIES_WITH_ID_FAIL'
    },
    embeddedType: null,
    fnName: 'patchPriceitemPropertiesWithIdRequest'
  }
  const config = {
    headers: { 'Content-Type': 'application/json' },
    baseURL: `${API_BASE_URL}/building-elements-api`,
    method: 'PATCH',
    url: `/priceitem/${requestArguments.path['id']}/properties`,
    params: {
      estimateId: options?.estimateId || store.app.calculation,
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: requestArguments.body
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'patchPriceitemPropertiesWithIdRequest', '/building-elements-api'),
    actions: {
      start: {
        type: DATA_PATCH_PRICEITEM_PROPERTIES_WITH_ID_START, payload, config, meta
      },
      successful: {
        type: DATA_PATCH_PRICEITEM_PROPERTIES_WITH_ID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_PATCH_PRICEITEM_PROPERTIES_WITH_ID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_GET_PRICEITEM_VALUES_WITH_ID_START = 'DATA_GET_PRICEITEM_VALUES_WITH_ID_START'
export const DATA_GET_PRICEITEM_VALUES_WITH_ID_SUCCESSFUL = 'DATA_GET_PRICEITEM_VALUES_WITH_ID_SUCCESSFUL'
export const DATA_GET_PRICEITEM_VALUES_WITH_ID_FAIL = 'DATA_GET_PRICEITEM_VALUES_WITH_ID_FAIL'

// Returns building element key figures
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/buildingelements.yaml#/Building%20Element%20Key%20Figures/get_priceitem__id__values
export const getPriceitemValuesWithIdRequest = (
  requestArguments: getPriceitemValuesWithIdRequestBuildingelementsArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_PRICEITEM_VALUES_WITH_ID_START',
      successful: 'DATA_GET_PRICEITEM_VALUES_WITH_ID_SUCCESSFUL',
      fail: 'DATA_GET_PRICEITEM_VALUES_WITH_ID_FAIL'
    },
    embeddedType: {
      properties: {
        schemaPath: '#/definitions/ValueProperty',
        schemaKey: 'ValueProperty'
      }
    },
    fnName: 'getPriceitemValuesWithIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/building-elements-api`,
    method: 'GET',
    url: `/priceitem/${requestArguments.path['id']}/values`,
    params: {
      estimateId: options?.estimateId || store.app.calculation,
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      properties: {
        schemaPath: '#/definitions/ValueProperty',
        schemaKey: 'ValueProperty'
      }
    }, 'getPriceitemValuesWithIdRequest', '/building-elements-api'),
    actions: {
      start: {
        type: DATA_GET_PRICEITEM_VALUES_WITH_ID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_PRICEITEM_VALUES_WITH_ID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_PRICEITEM_VALUES_WITH_ID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getPriceitemValuesWithIdRequestDefinitions = (definitionsArguments: {| requestArgs: getPriceitemValuesWithIdRequestBuildingelementsArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getPriceitemValuesWithIdRequestBuildingelementsArguments, payload?: Object |} => ({
  fnName: 'getPriceitemValuesWithIdRequest',
  basePath: '/building-elements-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_GET_PRICEITEM_PROPERTIES_INFO_WITH_PROPERTYNAME_START = 'DATA_GET_PRICEITEM_PROPERTIES_INFO_WITH_PROPERTYNAME_START'
export const DATA_GET_PRICEITEM_PROPERTIES_INFO_WITH_PROPERTYNAME_SUCCESSFUL = 'DATA_GET_PRICEITEM_PROPERTIES_INFO_WITH_PROPERTYNAME_SUCCESSFUL'
export const DATA_GET_PRICEITEM_PROPERTIES_INFO_WITH_PROPERTYNAME_FAIL = 'DATA_GET_PRICEITEM_PROPERTIES_INFO_WITH_PROPERTYNAME_FAIL'

// Get short information text for a price item property
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/buildingelements.yaml#/Price%20Item/get_priceitem__id__properties_info__propertyName_
export const getPriceitemPropertiesInfoWithPropertyNameRequest = (
  requestArguments: getPriceitemPropertiesInfoWithPropertyNameRequestBuildingelementsArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_PRICEITEM_PROPERTIES_INFO_WITH_PROPERTYNAME_START',
      successful: 'DATA_GET_PRICEITEM_PROPERTIES_INFO_WITH_PROPERTYNAME_SUCCESSFUL',
      fail: 'DATA_GET_PRICEITEM_PROPERTIES_INFO_WITH_PROPERTYNAME_FAIL'
    },
    embeddedType: null,
    fnName: 'getPriceitemPropertiesInfoWithPropertyNameRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/building-elements-api`,
    method: 'GET',
    url: `/priceitem/${requestArguments.path['id']}/properties/info/${requestArguments.path['propertyName']}`,
    params: {
      estimateId: options?.estimateId || store.app.calculation,
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'getPriceitemPropertiesInfoWithPropertyNameRequest', '/building-elements-api'),
    actions: {
      start: {
        type: DATA_GET_PRICEITEM_PROPERTIES_INFO_WITH_PROPERTYNAME_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_PRICEITEM_PROPERTIES_INFO_WITH_PROPERTYNAME_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_PRICEITEM_PROPERTIES_INFO_WITH_PROPERTYNAME_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getPriceitemPropertiesInfoWithPropertyNameRequestDefinitions = (definitionsArguments: {| requestArgs: getPriceitemPropertiesInfoWithPropertyNameRequestBuildingelementsArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getPriceitemPropertiesInfoWithPropertyNameRequestBuildingelementsArguments, payload?: Object |} => ({
  fnName: 'getPriceitemPropertiesInfoWithPropertyNameRequest',
  basePath: '/building-elements-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_GET_PRICEITEM_CAN_MOVE_TO_WITH_ID_START = 'DATA_GET_PRICEITEM_CAN_MOVE_TO_WITH_ID_START'
export const DATA_GET_PRICEITEM_CAN_MOVE_TO_WITH_ID_SUCCESSFUL = 'DATA_GET_PRICEITEM_CAN_MOVE_TO_WITH_ID_SUCCESSFUL'
export const DATA_GET_PRICEITEM_CAN_MOVE_TO_WITH_ID_FAIL = 'DATA_GET_PRICEITEM_CAN_MOVE_TO_WITH_ID_FAIL'

// Get id and description for schedule items to which the price item can be moved to
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/buildingelements.yaml#/Price%20Item/get_priceitem__id__can_move_to
export const getPriceitemCanMoveToWithIdRequest = (
  requestArguments: getPriceitemCanMoveToWithIdRequestBuildingelementsArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_PRICEITEM_CAN_MOVE_TO_WITH_ID_START',
      successful: 'DATA_GET_PRICEITEM_CAN_MOVE_TO_WITH_ID_SUCCESSFUL',
      fail: 'DATA_GET_PRICEITEM_CAN_MOVE_TO_WITH_ID_FAIL'
    },
    embeddedType: {
      items: {
        schemaPath: '#/definitions/Enumeral',
        schemaKey: 'Enumeral'
      }
    },
    fnName: 'getPriceitemCanMoveToWithIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/building-elements-api`,
    method: 'GET',
    url: `/priceitem/${requestArguments.path['id']}/can-move-to`,
    params: {
      estimateId: options?.estimateId || store.app.calculation,
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      items: {
        schemaPath: '#/definitions/Enumeral',
        schemaKey: 'Enumeral'
      }
    }, 'getPriceitemCanMoveToWithIdRequest', '/building-elements-api'),
    actions: {
      start: {
        type: DATA_GET_PRICEITEM_CAN_MOVE_TO_WITH_ID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_PRICEITEM_CAN_MOVE_TO_WITH_ID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_PRICEITEM_CAN_MOVE_TO_WITH_ID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getPriceitemCanMoveToWithIdRequestDefinitions = (definitionsArguments: {| requestArgs: getPriceitemCanMoveToWithIdRequestBuildingelementsArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getPriceitemCanMoveToWithIdRequestBuildingelementsArguments, payload?: Object |} => ({
  fnName: 'getPriceitemCanMoveToWithIdRequest',
  basePath: '/building-elements-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_DELETE_ASSEMBLY_WITH_ID_START = 'DATA_DELETE_ASSEMBLY_WITH_ID_START'
export const DATA_DELETE_ASSEMBLY_WITH_ID_SUCCESSFUL = 'DATA_DELETE_ASSEMBLY_WITH_ID_SUCCESSFUL'
export const DATA_DELETE_ASSEMBLY_WITH_ID_FAIL = 'DATA_DELETE_ASSEMBLY_WITH_ID_FAIL'

// Delete a Price Item Assembly. Will queue calculation if needed
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/buildingelements.yaml#/Price%20Item%20Assembly/delete_assembly__id_
export const deleteAssemblyWithIdRequest = (
  requestArguments: deleteAssemblyWithIdRequestBuildingelementsArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_DELETE_ASSEMBLY_WITH_ID_START',
      successful: 'DATA_DELETE_ASSEMBLY_WITH_ID_SUCCESSFUL',
      fail: 'DATA_DELETE_ASSEMBLY_WITH_ID_FAIL'
    },
    embeddedType: null,
    fnName: 'deleteAssemblyWithIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/building-elements-api`,
    method: 'DELETE',
    url: `/assembly/${requestArguments.path['id']}`,
    params: {
      estimateId: options?.estimateId || store.app.calculation,
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'deleteAssemblyWithIdRequest', '/building-elements-api'),
    actions: {
      start: {
        type: DATA_DELETE_ASSEMBLY_WITH_ID_START, payload, config, meta
      },
      successful: {
        type: DATA_DELETE_ASSEMBLY_WITH_ID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_DELETE_ASSEMBLY_WITH_ID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_POST_ASSEMBLY_WITH_ID_START = 'DATA_POST_ASSEMBLY_WITH_ID_START'
export const DATA_POST_ASSEMBLY_WITH_ID_SUCCESSFUL = 'DATA_POST_ASSEMBLY_WITH_ID_SUCCESSFUL'
export const DATA_POST_ASSEMBLY_WITH_ID_FAIL = 'DATA_POST_ASSEMBLY_WITH_ID_FAIL'

// Create a new price item assembly
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/buildingelements.yaml#/Price%20Item%20Assembly/post_assembly__id_
export const postAssemblyWithIdRequest = (
  requestArguments: postAssemblyWithIdRequestBuildingelementsArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_POST_ASSEMBLY_WITH_ID_START',
      successful: 'DATA_POST_ASSEMBLY_WITH_ID_SUCCESSFUL',
      fail: 'DATA_POST_ASSEMBLY_WITH_ID_FAIL'
    },
    embeddedType: null,
    fnName: 'postAssemblyWithIdRequest'
  }
  const config = {
    headers: { 'Content-Type': 'application/json' },
    baseURL: `${API_BASE_URL}/building-elements-api`,
    method: 'POST',
    url: `/assembly/${requestArguments.path['id']}`,
    params: {
      estimateId: options?.estimateId || store.app.calculation,
      copyId: requestArguments.query?.['copyId'],
      pricelistItemId: requestArguments.query?.['pricelistItemId'],
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: requestArguments.body
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'postAssemblyWithIdRequest', '/building-elements-api'),
    actions: {
      start: {
        type: DATA_POST_ASSEMBLY_WITH_ID_START, payload, config, meta
      },
      successful: {
        type: DATA_POST_ASSEMBLY_WITH_ID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_POST_ASSEMBLY_WITH_ID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_GET_ASSEMBLY_WITH_ID_START = 'DATA_GET_ASSEMBLY_WITH_ID_START'
export const DATA_GET_ASSEMBLY_WITH_ID_SUCCESSFUL = 'DATA_GET_ASSEMBLY_WITH_ID_SUCCESSFUL'
export const DATA_GET_ASSEMBLY_WITH_ID_FAIL = 'DATA_GET_ASSEMBLY_WITH_ID_FAIL'

// Returns a Price Item Assembly for building element schedule
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/buildingelements.yaml#/Price%20Item%20Assembly/get_assembly__id_
export const getAssemblyWithIdRequest = (
  requestArguments: getAssemblyWithIdRequestBuildingelementsArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_ASSEMBLY_WITH_ID_START',
      successful: 'DATA_GET_ASSEMBLY_WITH_ID_SUCCESSFUL',
      fail: 'DATA_GET_ASSEMBLY_WITH_ID_FAIL'
    },
    embeddedType: {
      nonEmbedded: {
        schemaPath: '#/definitions/ElementScheduleItem',
        schemaKey: 'ElementScheduleItem'
      }
    },
    fnName: 'getAssemblyWithIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/building-elements-api`,
    method: 'GET',
    url: `/assembly/${requestArguments.path['id']}`,
    params: {
      estimateId: options?.estimateId || store.app.calculation,
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      nonEmbedded: {
        schemaPath: '#/definitions/ElementScheduleItem',
        schemaKey: 'ElementScheduleItem'
      }
    }, 'getAssemblyWithIdRequest', '/building-elements-api'),
    actions: {
      start: {
        type: DATA_GET_ASSEMBLY_WITH_ID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_ASSEMBLY_WITH_ID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_ASSEMBLY_WITH_ID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getAssemblyWithIdRequestDefinitions = (definitionsArguments: {| requestArgs: getAssemblyWithIdRequestBuildingelementsArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getAssemblyWithIdRequestBuildingelementsArguments, payload?: Object |} => ({
  fnName: 'getAssemblyWithIdRequest',
  basePath: '/building-elements-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_PATCH_ASSEMBLY_WITH_ID_START = 'DATA_PATCH_ASSEMBLY_WITH_ID_START'
export const DATA_PATCH_ASSEMBLY_WITH_ID_SUCCESSFUL = 'DATA_PATCH_ASSEMBLY_WITH_ID_SUCCESSFUL'
export const DATA_PATCH_ASSEMBLY_WITH_ID_FAIL = 'DATA_PATCH_ASSEMBLY_WITH_ID_FAIL'

// Update column data properties
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/buildingelements.yaml#/Price%20Item%20Assembly/patch_assembly__id_
export const patchAssemblyWithIdRequest = (
  requestArguments: patchAssemblyWithIdRequestBuildingelementsArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_PATCH_ASSEMBLY_WITH_ID_START',
      successful: 'DATA_PATCH_ASSEMBLY_WITH_ID_SUCCESSFUL',
      fail: 'DATA_PATCH_ASSEMBLY_WITH_ID_FAIL'
    },
    embeddedType: null,
    fnName: 'patchAssemblyWithIdRequest'
  }
  const config = {
    headers: { 'Content-Type': 'application/json' },
    baseURL: `${API_BASE_URL}/building-elements-api`,
    method: 'PATCH',
    url: `/assembly/${requestArguments.path['id']}`,
    params: {
      estimateId: options?.estimateId || store.app.calculation,
      resetPricing: requestArguments.query?.['resetPricing'],
      changeOrder: requestArguments.query?.['changeOrder'],
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: requestArguments.body
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'patchAssemblyWithIdRequest', '/building-elements-api'),
    actions: {
      start: {
        type: DATA_PATCH_ASSEMBLY_WITH_ID_START, payload, config, meta
      },
      successful: {
        type: DATA_PATCH_ASSEMBLY_WITH_ID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_PATCH_ASSEMBLY_WITH_ID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_GET_ASSEMBLY_PRICELIST_WITH_ID_START = 'DATA_GET_ASSEMBLY_PRICELIST_WITH_ID_START'
export const DATA_GET_ASSEMBLY_PRICELIST_WITH_ID_SUCCESSFUL = 'DATA_GET_ASSEMBLY_PRICELIST_WITH_ID_SUCCESSFUL'
export const DATA_GET_ASSEMBLY_PRICELIST_WITH_ID_FAIL = 'DATA_GET_ASSEMBLY_PRICELIST_WITH_ID_FAIL'

// Returns price items as Enumerals that can be added to this assembly
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/buildingelements.yaml#/Price%20Item%20Assembly/get_assembly__id__pricelist
export const getAssemblyPricelistWithIdRequest = (
  requestArguments: getAssemblyPricelistWithIdRequestBuildingelementsArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_ASSEMBLY_PRICELIST_WITH_ID_START',
      successful: 'DATA_GET_ASSEMBLY_PRICELIST_WITH_ID_SUCCESSFUL',
      fail: 'DATA_GET_ASSEMBLY_PRICELIST_WITH_ID_FAIL'
    },
    embeddedType: {
      items: {
        schemaPath: '#/definitions/Enumeral',
        schemaKey: 'Enumeral'
      }
    },
    fnName: 'getAssemblyPricelistWithIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/building-elements-api`,
    method: 'GET',
    url: `/assembly/${requestArguments.path['id']}/pricelist`,
    params: {
      estimateId: options?.estimateId || store.app.calculation,
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      items: {
        schemaPath: '#/definitions/Enumeral',
        schemaKey: 'Enumeral'
      }
    }, 'getAssemblyPricelistWithIdRequest', '/building-elements-api'),
    actions: {
      start: {
        type: DATA_GET_ASSEMBLY_PRICELIST_WITH_ID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_ASSEMBLY_PRICELIST_WITH_ID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_ASSEMBLY_PRICELIST_WITH_ID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getAssemblyPricelistWithIdRequestDefinitions = (definitionsArguments: {| requestArgs: getAssemblyPricelistWithIdRequestBuildingelementsArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getAssemblyPricelistWithIdRequestBuildingelementsArguments, payload?: Object |} => ({
  fnName: 'getAssemblyPricelistWithIdRequest',
  basePath: '/building-elements-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_GET_ASSEMBLY_CAN_MOVE_TO_WITH_ID_START = 'DATA_GET_ASSEMBLY_CAN_MOVE_TO_WITH_ID_START'
export const DATA_GET_ASSEMBLY_CAN_MOVE_TO_WITH_ID_SUCCESSFUL = 'DATA_GET_ASSEMBLY_CAN_MOVE_TO_WITH_ID_SUCCESSFUL'
export const DATA_GET_ASSEMBLY_CAN_MOVE_TO_WITH_ID_FAIL = 'DATA_GET_ASSEMBLY_CAN_MOVE_TO_WITH_ID_FAIL'

// Get id and description for schedule items to which the price item assembly can be moved to
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/buildingelements.yaml#/Price%20Item%20Assembly/get_assembly__id__can_move_to
export const getAssemblyCanMoveToWithIdRequest = (
  requestArguments: getAssemblyCanMoveToWithIdRequestBuildingelementsArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_ASSEMBLY_CAN_MOVE_TO_WITH_ID_START',
      successful: 'DATA_GET_ASSEMBLY_CAN_MOVE_TO_WITH_ID_SUCCESSFUL',
      fail: 'DATA_GET_ASSEMBLY_CAN_MOVE_TO_WITH_ID_FAIL'
    },
    embeddedType: {
      items: {
        schemaPath: '#/definitions/Enumeral',
        schemaKey: 'Enumeral'
      }
    },
    fnName: 'getAssemblyCanMoveToWithIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/building-elements-api`,
    method: 'GET',
    url: `/assembly/${requestArguments.path['id']}/can-move-to`,
    params: {
      estimateId: options?.estimateId || store.app.calculation,
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      items: {
        schemaPath: '#/definitions/Enumeral',
        schemaKey: 'Enumeral'
      }
    }, 'getAssemblyCanMoveToWithIdRequest', '/building-elements-api'),
    actions: {
      start: {
        type: DATA_GET_ASSEMBLY_CAN_MOVE_TO_WITH_ID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_ASSEMBLY_CAN_MOVE_TO_WITH_ID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_ASSEMBLY_CAN_MOVE_TO_WITH_ID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getAssemblyCanMoveToWithIdRequestDefinitions = (definitionsArguments: {| requestArgs: getAssemblyCanMoveToWithIdRequestBuildingelementsArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getAssemblyCanMoveToWithIdRequestBuildingelementsArguments, payload?: Object |} => ({
  fnName: 'getAssemblyCanMoveToWithIdRequest',
  basePath: '/building-elements-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_GET_ASSEMBLY_PROPERTIES_WITH_ID_START = 'DATA_GET_ASSEMBLY_PROPERTIES_WITH_ID_START'
export const DATA_GET_ASSEMBLY_PROPERTIES_WITH_ID_SUCCESSFUL = 'DATA_GET_ASSEMBLY_PROPERTIES_WITH_ID_SUCCESSFUL'
export const DATA_GET_ASSEMBLY_PROPERTIES_WITH_ID_FAIL = 'DATA_GET_ASSEMBLY_PROPERTIES_WITH_ID_FAIL'

// Returns all properties of a Price Item Assembly
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/buildingelements.yaml#/Price%20Item%20Assembly/get_assembly__id__properties
export const getAssemblyPropertiesWithIdRequest = (
  requestArguments: getAssemblyPropertiesWithIdRequestBuildingelementsArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_ASSEMBLY_PROPERTIES_WITH_ID_START',
      successful: 'DATA_GET_ASSEMBLY_PROPERTIES_WITH_ID_SUCCESSFUL',
      fail: 'DATA_GET_ASSEMBLY_PROPERTIES_WITH_ID_FAIL'
    },
    embeddedType: {
      properties: {
        schemaPath: '#/definitions/ValueProperty',
        schemaKey: 'ValueProperty'
      }
    },
    fnName: 'getAssemblyPropertiesWithIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/building-elements-api`,
    method: 'GET',
    url: `/assembly/${requestArguments.path['id']}/properties`,
    params: {
      estimateId: options?.estimateId || store.app.calculation,
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      properties: {
        schemaPath: '#/definitions/ValueProperty',
        schemaKey: 'ValueProperty'
      }
    }, 'getAssemblyPropertiesWithIdRequest', '/building-elements-api'),
    actions: {
      start: {
        type: DATA_GET_ASSEMBLY_PROPERTIES_WITH_ID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_ASSEMBLY_PROPERTIES_WITH_ID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_ASSEMBLY_PROPERTIES_WITH_ID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getAssemblyPropertiesWithIdRequestDefinitions = (definitionsArguments: {| requestArgs: getAssemblyPropertiesWithIdRequestBuildingelementsArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getAssemblyPropertiesWithIdRequestBuildingelementsArguments, payload?: Object |} => ({
  fnName: 'getAssemblyPropertiesWithIdRequest',
  basePath: '/building-elements-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_PATCH_ASSEMBLY_PROPERTIES_WITH_ID_START = 'DATA_PATCH_ASSEMBLY_PROPERTIES_WITH_ID_START'
export const DATA_PATCH_ASSEMBLY_PROPERTIES_WITH_ID_SUCCESSFUL = 'DATA_PATCH_ASSEMBLY_PROPERTIES_WITH_ID_SUCCESSFUL'
export const DATA_PATCH_ASSEMBLY_PROPERTIES_WITH_ID_FAIL = 'DATA_PATCH_ASSEMBLY_PROPERTIES_WITH_ID_FAIL'

// Update Price Item Assembly properties
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/buildingelements.yaml#/Price%20Item%20Assembly/patch_assembly__id__properties
export const patchAssemblyPropertiesWithIdRequest = (
  requestArguments: patchAssemblyPropertiesWithIdRequestBuildingelementsArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_PATCH_ASSEMBLY_PROPERTIES_WITH_ID_START',
      successful: 'DATA_PATCH_ASSEMBLY_PROPERTIES_WITH_ID_SUCCESSFUL',
      fail: 'DATA_PATCH_ASSEMBLY_PROPERTIES_WITH_ID_FAIL'
    },
    embeddedType: null,
    fnName: 'patchAssemblyPropertiesWithIdRequest'
  }
  const config = {
    headers: { 'Content-Type': 'application/json' },
    baseURL: `${API_BASE_URL}/building-elements-api`,
    method: 'PATCH',
    url: `/assembly/${requestArguments.path['id']}/properties`,
    params: {
      estimateId: options?.estimateId || store.app.calculation,
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: requestArguments.body
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'patchAssemblyPropertiesWithIdRequest', '/building-elements-api'),
    actions: {
      start: {
        type: DATA_PATCH_ASSEMBLY_PROPERTIES_WITH_ID_START, payload, config, meta
      },
      successful: {
        type: DATA_PATCH_ASSEMBLY_PROPERTIES_WITH_ID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_PATCH_ASSEMBLY_PROPERTIES_WITH_ID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_GET_ASSEMBLY_VALUES_WITH_ID_START = 'DATA_GET_ASSEMBLY_VALUES_WITH_ID_START'
export const DATA_GET_ASSEMBLY_VALUES_WITH_ID_SUCCESSFUL = 'DATA_GET_ASSEMBLY_VALUES_WITH_ID_SUCCESSFUL'
export const DATA_GET_ASSEMBLY_VALUES_WITH_ID_FAIL = 'DATA_GET_ASSEMBLY_VALUES_WITH_ID_FAIL'

// Returns building element assembly key figures
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/buildingelements.yaml#/Building%20Element%20Assembly%20Key%20Figures/get_assembly__id__values
export const getAssemblyValuesWithIdRequest = (
  requestArguments: getAssemblyValuesWithIdRequestBuildingelementsArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_ASSEMBLY_VALUES_WITH_ID_START',
      successful: 'DATA_GET_ASSEMBLY_VALUES_WITH_ID_SUCCESSFUL',
      fail: 'DATA_GET_ASSEMBLY_VALUES_WITH_ID_FAIL'
    },
    embeddedType: {
      properties: {
        schemaPath: '#/definitions/ValueProperty',
        schemaKey: 'ValueProperty'
      }
    },
    fnName: 'getAssemblyValuesWithIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/building-elements-api`,
    method: 'GET',
    url: `/assembly/${requestArguments.path['id']}/values`,
    params: {
      estimateId: options?.estimateId || store.app.calculation,
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      properties: {
        schemaPath: '#/definitions/ValueProperty',
        schemaKey: 'ValueProperty'
      }
    }, 'getAssemblyValuesWithIdRequest', '/building-elements-api'),
    actions: {
      start: {
        type: DATA_GET_ASSEMBLY_VALUES_WITH_ID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_ASSEMBLY_VALUES_WITH_ID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_ASSEMBLY_VALUES_WITH_ID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getAssemblyValuesWithIdRequestDefinitions = (definitionsArguments: {| requestArgs: getAssemblyValuesWithIdRequestBuildingelementsArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getAssemblyValuesWithIdRequestBuildingelementsArguments, payload?: Object |} => ({
  fnName: 'getAssemblyValuesWithIdRequest',
  basePath: '/building-elements-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_GET_ASSEMBLY_PROPERTIES_INFO_WITH_PROPERTYNAME_START = 'DATA_GET_ASSEMBLY_PROPERTIES_INFO_WITH_PROPERTYNAME_START'
export const DATA_GET_ASSEMBLY_PROPERTIES_INFO_WITH_PROPERTYNAME_SUCCESSFUL = 'DATA_GET_ASSEMBLY_PROPERTIES_INFO_WITH_PROPERTYNAME_SUCCESSFUL'
export const DATA_GET_ASSEMBLY_PROPERTIES_INFO_WITH_PROPERTYNAME_FAIL = 'DATA_GET_ASSEMBLY_PROPERTIES_INFO_WITH_PROPERTYNAME_FAIL'

// Get short information text for an assembly property
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/buildingelements.yaml#/Price%20Item%20Assembly/get_assembly__id__properties_info__propertyName_
export const getAssemblyPropertiesInfoWithPropertyNameRequest = (
  requestArguments: getAssemblyPropertiesInfoWithPropertyNameRequestBuildingelementsArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_ASSEMBLY_PROPERTIES_INFO_WITH_PROPERTYNAME_START',
      successful: 'DATA_GET_ASSEMBLY_PROPERTIES_INFO_WITH_PROPERTYNAME_SUCCESSFUL',
      fail: 'DATA_GET_ASSEMBLY_PROPERTIES_INFO_WITH_PROPERTYNAME_FAIL'
    },
    embeddedType: null,
    fnName: 'getAssemblyPropertiesInfoWithPropertyNameRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/building-elements-api`,
    method: 'GET',
    url: `/assembly/${requestArguments.path['id']}/properties/info/${requestArguments.path['propertyName']}`,
    params: {
      estimateId: options?.estimateId || store.app.calculation,
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'getAssemblyPropertiesInfoWithPropertyNameRequest', '/building-elements-api'),
    actions: {
      start: {
        type: DATA_GET_ASSEMBLY_PROPERTIES_INFO_WITH_PROPERTYNAME_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_ASSEMBLY_PROPERTIES_INFO_WITH_PROPERTYNAME_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_ASSEMBLY_PROPERTIES_INFO_WITH_PROPERTYNAME_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getAssemblyPropertiesInfoWithPropertyNameRequestDefinitions = (definitionsArguments: {| requestArgs: getAssemblyPropertiesInfoWithPropertyNameRequestBuildingelementsArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getAssemblyPropertiesInfoWithPropertyNameRequestBuildingelementsArguments, payload?: Object |} => ({
  fnName: 'getAssemblyPropertiesInfoWithPropertyNameRequest',
  basePath: '/building-elements-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_GET_ASSEMBLY_ITEMS_WITH_ID_START = 'DATA_GET_ASSEMBLY_ITEMS_WITH_ID_START'
export const DATA_GET_ASSEMBLY_ITEMS_WITH_ID_SUCCESSFUL = 'DATA_GET_ASSEMBLY_ITEMS_WITH_ID_SUCCESSFUL'
export const DATA_GET_ASSEMBLY_ITEMS_WITH_ID_FAIL = 'DATA_GET_ASSEMBLY_ITEMS_WITH_ID_FAIL'

// Returns items in a Price Item Assembly
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/buildingelements.yaml#/Price%20Item%20Assembly/get_assembly__id__items
export const getAssemblyItemsWithIdRequest = (
  requestArguments: getAssemblyItemsWithIdRequestBuildingelementsArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_ASSEMBLY_ITEMS_WITH_ID_START',
      successful: 'DATA_GET_ASSEMBLY_ITEMS_WITH_ID_SUCCESSFUL',
      fail: 'DATA_GET_ASSEMBLY_ITEMS_WITH_ID_FAIL'
    },
    embeddedType: {
      items: {
        schemaPath: '#/definitions/ElementScheduleItem',
        schemaKey: 'ElementScheduleItem'
      }
    },
    fnName: 'getAssemblyItemsWithIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/building-elements-api`,
    method: 'GET',
    url: `/assembly/${requestArguments.path['id']}/items`,
    params: {
      estimateId: options?.estimateId || store.app.calculation,
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      items: {
        schemaPath: '#/definitions/ElementScheduleItem',
        schemaKey: 'ElementScheduleItem'
      }
    }, 'getAssemblyItemsWithIdRequest', '/building-elements-api'),
    actions: {
      start: {
        type: DATA_GET_ASSEMBLY_ITEMS_WITH_ID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_ASSEMBLY_ITEMS_WITH_ID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_ASSEMBLY_ITEMS_WITH_ID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getAssemblyItemsWithIdRequestDefinitions = (definitionsArguments: {| requestArgs: getAssemblyItemsWithIdRequestBuildingelementsArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getAssemblyItemsWithIdRequestBuildingelementsArguments, payload?: Object |} => ({
  fnName: 'getAssemblyItemsWithIdRequest',
  basePath: '/building-elements-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_GET_ESTIMATE_WITH_ESTIMATEID_START = 'DATA_GET_ESTIMATE_WITH_ESTIMATEID_START'
export const DATA_GET_ESTIMATE_WITH_ESTIMATEID_SUCCESSFUL = 'DATA_GET_ESTIMATE_WITH_ESTIMATEID_SUCCESSFUL'
export const DATA_GET_ESTIMATE_WITH_ESTIMATEID_FAIL = 'DATA_GET_ESTIMATE_WITH_ESTIMATEID_FAIL'

// Returns Building element estimate
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/buildingelements.yaml#/Building%20Element%20Estimate/get_estimate__estimateId_
export const getEstimateWithEstimateIdRequest = (
  payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_ESTIMATE_WITH_ESTIMATEID_START',
      successful: 'DATA_GET_ESTIMATE_WITH_ESTIMATEID_SUCCESSFUL',
      fail: 'DATA_GET_ESTIMATE_WITH_ESTIMATEID_FAIL'
    },
    embeddedType: {
      nonEmbedded: {
        schemaPath: '#/definitions/Estimate',
        schemaKey: 'Estimate'
      }
    },
    fnName: 'getEstimateWithEstimateIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/building-elements-api`,
    method: 'GET',
    url: `/estimate/${options?.estimateId || store.app.calculation}`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      nonEmbedded: {
        schemaPath: '#/definitions/Estimate',
        schemaKey: 'Estimate'
      }
    }, 'getEstimateWithEstimateIdRequest', '/building-elements-api'),
    actions: {
      start: {
        type: DATA_GET_ESTIMATE_WITH_ESTIMATEID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_ESTIMATE_WITH_ESTIMATEID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_ESTIMATE_WITH_ESTIMATEID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getEstimateWithEstimateIdRequestDefinitions = (definitionsArguments: {| payload?: Object |}): {|
  ...TVDGARConfigWithoutRequestArgs, payload?: Object |} => ({
  fnName: 'getEstimateWithEstimateIdRequest',
  basePath: '/building-elements-api',
  payload: definitionsArguments.payload,
})


export const DATA_GET_ESTIMATE_LOCK_WITH_ESTIMATEID_START = 'DATA_GET_ESTIMATE_LOCK_WITH_ESTIMATEID_START'
export const DATA_GET_ESTIMATE_LOCK_WITH_ESTIMATEID_SUCCESSFUL = 'DATA_GET_ESTIMATE_LOCK_WITH_ESTIMATEID_SUCCESSFUL'
export const DATA_GET_ESTIMATE_LOCK_WITH_ESTIMATEID_FAIL = 'DATA_GET_ESTIMATE_LOCK_WITH_ESTIMATEID_FAIL'

// If resource is locked, returns information (owner, expiration) about the current lock
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/buildingelements.yaml#/Building%20Element%20Estimate/get_estimate__estimateId__lock
export const getEstimateLockWithEstimateIdRequest = (
  payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_ESTIMATE_LOCK_WITH_ESTIMATEID_START',
      successful: 'DATA_GET_ESTIMATE_LOCK_WITH_ESTIMATEID_SUCCESSFUL',
      fail: 'DATA_GET_ESTIMATE_LOCK_WITH_ESTIMATEID_FAIL'
    },
    embeddedType: {
      nonEmbedded: {
        schemaPath: '#/definitions/ResourceLock',
        schemaKey: 'ResourceLock'
      }
    },
    fnName: 'getEstimateLockWithEstimateIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/building-elements-api`,
    method: 'GET',
    url: `/estimate/${options?.estimateId || store.app.calculation}/lock`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      nonEmbedded: {
        schemaPath: '#/definitions/ResourceLock',
        schemaKey: 'ResourceLock'
      }
    }, 'getEstimateLockWithEstimateIdRequest', '/building-elements-api'),
    actions: {
      start: {
        type: DATA_GET_ESTIMATE_LOCK_WITH_ESTIMATEID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_ESTIMATE_LOCK_WITH_ESTIMATEID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_ESTIMATE_LOCK_WITH_ESTIMATEID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getEstimateLockWithEstimateIdRequestDefinitions = (definitionsArguments: {| payload?: Object |}): {|
  ...TVDGARConfigWithoutRequestArgs, payload?: Object |} => ({
  fnName: 'getEstimateLockWithEstimateIdRequest',
  basePath: '/building-elements-api',
  payload: definitionsArguments.payload,
})


export const DATA_PUT_ESTIMATE_LOCK_WITH_ESTIMATEID_START = 'DATA_PUT_ESTIMATE_LOCK_WITH_ESTIMATEID_START'
export const DATA_PUT_ESTIMATE_LOCK_WITH_ESTIMATEID_SUCCESSFUL = 'DATA_PUT_ESTIMATE_LOCK_WITH_ESTIMATEID_SUCCESSFUL'
export const DATA_PUT_ESTIMATE_LOCK_WITH_ESTIMATEID_FAIL = 'DATA_PUT_ESTIMATE_LOCK_WITH_ESTIMATEID_FAIL'

// Obtain a lock to a resource for editing. Edits are not permitted before first obtaining a lock. If another user has already locked the resource, Resource Locked Error (423) is returned.
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/buildingelements.yaml#/Building%20Element%20Estimate/put_estimate__estimateId__lock
export const putEstimateLockWithEstimateIdRequest = (
  requestArguments: putEstimateLockWithEstimateIdRequestBuildingelementsArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_PUT_ESTIMATE_LOCK_WITH_ESTIMATEID_START',
      successful: 'DATA_PUT_ESTIMATE_LOCK_WITH_ESTIMATEID_SUCCESSFUL',
      fail: 'DATA_PUT_ESTIMATE_LOCK_WITH_ESTIMATEID_FAIL'
    },
    embeddedType: null,
    fnName: 'putEstimateLockWithEstimateIdRequest'
  }
  const config = {
    headers: { 'Content-Type': 'application/json' },
    baseURL: `${API_BASE_URL}/building-elements-api`,
    method: 'PUT',
    url: `/estimate/${options?.estimateId || store.app.calculation}/lock`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: requestArguments.body
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'putEstimateLockWithEstimateIdRequest', '/building-elements-api'),
    actions: {
      start: {
        type: DATA_PUT_ESTIMATE_LOCK_WITH_ESTIMATEID_START, payload, config, meta
      },
      successful: {
        type: DATA_PUT_ESTIMATE_LOCK_WITH_ESTIMATEID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_PUT_ESTIMATE_LOCK_WITH_ESTIMATEID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_DELETE_ESTIMATE_LOCK_WITH_ESTIMATEID_START = 'DATA_DELETE_ESTIMATE_LOCK_WITH_ESTIMATEID_START'
export const DATA_DELETE_ESTIMATE_LOCK_WITH_ESTIMATEID_SUCCESSFUL = 'DATA_DELETE_ESTIMATE_LOCK_WITH_ESTIMATEID_SUCCESSFUL'
export const DATA_DELETE_ESTIMATE_LOCK_WITH_ESTIMATEID_FAIL = 'DATA_DELETE_ESTIMATE_LOCK_WITH_ESTIMATEID_FAIL'

// Deletes a resource lock. If the lock was created by another user, error 423 is returned.
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/buildingelements.yaml#/Building%20Element%20Estimate/delete_estimate__estimateId__lock
export const deleteEstimateLockWithEstimateIdRequest = (
  payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_DELETE_ESTIMATE_LOCK_WITH_ESTIMATEID_START',
      successful: 'DATA_DELETE_ESTIMATE_LOCK_WITH_ESTIMATEID_SUCCESSFUL',
      fail: 'DATA_DELETE_ESTIMATE_LOCK_WITH_ESTIMATEID_FAIL'
    },
    embeddedType: null,
    fnName: 'deleteEstimateLockWithEstimateIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/building-elements-api`,
    method: 'DELETE',
    url: `/estimate/${options?.estimateId || store.app.calculation}/lock`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'deleteEstimateLockWithEstimateIdRequest', '/building-elements-api'),
    actions: {
      start: {
        type: DATA_DELETE_ESTIMATE_LOCK_WITH_ESTIMATEID_START, payload, config, meta
      },
      successful: {
        type: DATA_DELETE_ESTIMATE_LOCK_WITH_ESTIMATEID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_DELETE_ESTIMATE_LOCK_WITH_ESTIMATEID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_PUT_ESTIMATE_PROPERTIES_WITH_ESTIMATEID_START = 'DATA_PUT_ESTIMATE_PROPERTIES_WITH_ESTIMATEID_START'
export const DATA_PUT_ESTIMATE_PROPERTIES_WITH_ESTIMATEID_SUCCESSFUL = 'DATA_PUT_ESTIMATE_PROPERTIES_WITH_ESTIMATEID_SUCCESSFUL'
export const DATA_PUT_ESTIMATE_PROPERTIES_WITH_ESTIMATEID_FAIL = 'DATA_PUT_ESTIMATE_PROPERTIES_WITH_ESTIMATEID_FAIL'

// Import properties from reference estimate. Current properties will be replaced.
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/buildingelements.yaml#/Reference%20Schedule/put_estimate__estimateId__properties
export const putEstimatePropertiesWithEstimateIdRequest = (
  requestArguments: putEstimatePropertiesWithEstimateIdRequestBuildingelementsArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_PUT_ESTIMATE_PROPERTIES_WITH_ESTIMATEID_START',
      successful: 'DATA_PUT_ESTIMATE_PROPERTIES_WITH_ESTIMATEID_SUCCESSFUL',
      fail: 'DATA_PUT_ESTIMATE_PROPERTIES_WITH_ESTIMATEID_FAIL'
    },
    embeddedType: null,
    fnName: 'putEstimatePropertiesWithEstimateIdRequest'
  }
  const config = {
    headers: { 'Content-Type': 'application/json' },
    baseURL: `${API_BASE_URL}/building-elements-api`,
    method: 'PUT',
    url: `/estimate/${options?.estimateId || store.app.calculation}/properties`,
    params: {
      importReference: requestArguments.query?.['importReference'],
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'putEstimatePropertiesWithEstimateIdRequest', '/building-elements-api'),
    actions: {
      start: {
        type: DATA_PUT_ESTIMATE_PROPERTIES_WITH_ESTIMATEID_START, payload, config, meta
      },
      successful: {
        type: DATA_PUT_ESTIMATE_PROPERTIES_WITH_ESTIMATEID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_PUT_ESTIMATE_PROPERTIES_WITH_ESTIMATEID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_GET_REFERENCE_SCHEDULE_WITH_ESTIMATEID_START = 'DATA_GET_REFERENCE_SCHEDULE_WITH_ESTIMATEID_START'
export const DATA_GET_REFERENCE_SCHEDULE_WITH_ESTIMATEID_SUCCESSFUL = 'DATA_GET_REFERENCE_SCHEDULE_WITH_ESTIMATEID_SUCCESSFUL'
export const DATA_GET_REFERENCE_SCHEDULE_WITH_ESTIMATEID_FAIL = 'DATA_GET_REFERENCE_SCHEDULE_WITH_ESTIMATEID_FAIL'

// Returns a building element reference schedule
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/buildingelements.yaml#/Reference%20Schedule/get_reference_schedule__estimateId_
export const getReferenceScheduleWithEstimateIdRequest = (
  payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_REFERENCE_SCHEDULE_WITH_ESTIMATEID_START',
      successful: 'DATA_GET_REFERENCE_SCHEDULE_WITH_ESTIMATEID_SUCCESSFUL',
      fail: 'DATA_GET_REFERENCE_SCHEDULE_WITH_ESTIMATEID_FAIL'
    },
    embeddedType: {
      items: {
        schemaPath: '#/definitions/ElementScheduleItem',
        schemaKey: 'ElementScheduleItem'
      }
    },
    fnName: 'getReferenceScheduleWithEstimateIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/building-elements-api`,
    method: 'GET',
    url: `/reference-schedule/${options?.estimateId || store.app.calculation}`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      items: {
        schemaPath: '#/definitions/ElementScheduleItem',
        schemaKey: 'ElementScheduleItem'
      }
    }, 'getReferenceScheduleWithEstimateIdRequest', '/building-elements-api'),
    actions: {
      start: {
        type: DATA_GET_REFERENCE_SCHEDULE_WITH_ESTIMATEID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_REFERENCE_SCHEDULE_WITH_ESTIMATEID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_REFERENCE_SCHEDULE_WITH_ESTIMATEID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getReferenceScheduleWithEstimateIdRequestDefinitions = (definitionsArguments: {| payload?: Object |}): {|
  ...TVDGARConfigWithoutRequestArgs, payload?: Object |} => ({
  fnName: 'getReferenceScheduleWithEstimateIdRequest',
  basePath: '/building-elements-api',
  payload: definitionsArguments.payload,
})


export const DATA_GET_REFERENCE_SCHEDULE_COLUMNS_WITH_ESTIMATEID_START = 'DATA_GET_REFERENCE_SCHEDULE_COLUMNS_WITH_ESTIMATEID_START'
export const DATA_GET_REFERENCE_SCHEDULE_COLUMNS_WITH_ESTIMATEID_SUCCESSFUL = 'DATA_GET_REFERENCE_SCHEDULE_COLUMNS_WITH_ESTIMATEID_SUCCESSFUL'
export const DATA_GET_REFERENCE_SCHEDULE_COLUMNS_WITH_ESTIMATEID_FAIL = 'DATA_GET_REFERENCE_SCHEDULE_COLUMNS_WITH_ESTIMATEID_FAIL'

// Gets reference schedule columns
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/buildingelements.yaml#/Reference%20Schedule/get_reference_schedule__estimateId__columns
export const getReferenceScheduleColumnsWithEstimateIdRequest = (
  payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_REFERENCE_SCHEDULE_COLUMNS_WITH_ESTIMATEID_START',
      successful: 'DATA_GET_REFERENCE_SCHEDULE_COLUMNS_WITH_ESTIMATEID_SUCCESSFUL',
      fail: 'DATA_GET_REFERENCE_SCHEDULE_COLUMNS_WITH_ESTIMATEID_FAIL'
    },
    embeddedType: {
      properties: {
        schemaPath: '#/definitions/ValueProperty',
        schemaKey: 'ValueProperty'
      }
    },
    fnName: 'getReferenceScheduleColumnsWithEstimateIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/building-elements-api`,
    method: 'GET',
    url: `/reference-schedule/${options?.estimateId || store.app.calculation}/columns`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      properties: {
        schemaPath: '#/definitions/ValueProperty',
        schemaKey: 'ValueProperty'
      }
    }, 'getReferenceScheduleColumnsWithEstimateIdRequest', '/building-elements-api'),
    actions: {
      start: {
        type: DATA_GET_REFERENCE_SCHEDULE_COLUMNS_WITH_ESTIMATEID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_REFERENCE_SCHEDULE_COLUMNS_WITH_ESTIMATEID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_REFERENCE_SCHEDULE_COLUMNS_WITH_ESTIMATEID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getReferenceScheduleColumnsWithEstimateIdRequestDefinitions = (definitionsArguments: {| payload?: Object |}): {|
  ...TVDGARConfigWithoutRequestArgs, payload?: Object |} => ({
  fnName: 'getReferenceScheduleColumnsWithEstimateIdRequest',
  basePath: '/building-elements-api',
  payload: definitionsArguments.payload,
})


export const DATA_GET_REFERENCE_SCHEDULE_PROPERTIES_WITH_ESTIMATEID_START = 'DATA_GET_REFERENCE_SCHEDULE_PROPERTIES_WITH_ESTIMATEID_START'
export const DATA_GET_REFERENCE_SCHEDULE_PROPERTIES_WITH_ESTIMATEID_SUCCESSFUL = 'DATA_GET_REFERENCE_SCHEDULE_PROPERTIES_WITH_ESTIMATEID_SUCCESSFUL'
export const DATA_GET_REFERENCE_SCHEDULE_PROPERTIES_WITH_ESTIMATEID_FAIL = 'DATA_GET_REFERENCE_SCHEDULE_PROPERTIES_WITH_ESTIMATEID_FAIL'

// Gets reference estimate properties
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/buildingelements.yaml#/Reference%20Schedule/get_reference_schedule__estimateId__properties
export const getReferenceSchedulePropertiesWithEstimateIdRequest = (
  payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_REFERENCE_SCHEDULE_PROPERTIES_WITH_ESTIMATEID_START',
      successful: 'DATA_GET_REFERENCE_SCHEDULE_PROPERTIES_WITH_ESTIMATEID_SUCCESSFUL',
      fail: 'DATA_GET_REFERENCE_SCHEDULE_PROPERTIES_WITH_ESTIMATEID_FAIL'
    },
    embeddedType: {
      properties: {
        schemaPath: '#/definitions/ValueProperty',
        schemaKey: 'ValueProperty'
      }
    },
    fnName: 'getReferenceSchedulePropertiesWithEstimateIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/building-elements-api`,
    method: 'GET',
    url: `/reference-schedule/${options?.estimateId || store.app.calculation}/properties`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      properties: {
        schemaPath: '#/definitions/ValueProperty',
        schemaKey: 'ValueProperty'
      }
    }, 'getReferenceSchedulePropertiesWithEstimateIdRequest', '/building-elements-api'),
    actions: {
      start: {
        type: DATA_GET_REFERENCE_SCHEDULE_PROPERTIES_WITH_ESTIMATEID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_REFERENCE_SCHEDULE_PROPERTIES_WITH_ESTIMATEID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_REFERENCE_SCHEDULE_PROPERTIES_WITH_ESTIMATEID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getReferenceSchedulePropertiesWithEstimateIdRequestDefinitions = (definitionsArguments: {| payload?: Object |}): {|
  ...TVDGARConfigWithoutRequestArgs, payload?: Object |} => ({
  fnName: 'getReferenceSchedulePropertiesWithEstimateIdRequest',
  basePath: '/building-elements-api',
  payload: definitionsArguments.payload,
})


export const DATA_GET_REFERENCE_SCHEDULE_VALUES_WITH_ESTIMATEID_START = 'DATA_GET_REFERENCE_SCHEDULE_VALUES_WITH_ESTIMATEID_START'
export const DATA_GET_REFERENCE_SCHEDULE_VALUES_WITH_ESTIMATEID_SUCCESSFUL = 'DATA_GET_REFERENCE_SCHEDULE_VALUES_WITH_ESTIMATEID_SUCCESSFUL'
export const DATA_GET_REFERENCE_SCHEDULE_VALUES_WITH_ESTIMATEID_FAIL = 'DATA_GET_REFERENCE_SCHEDULE_VALUES_WITH_ESTIMATEID_FAIL'

// Gets reference schedule values
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/buildingelements.yaml#/Reference%20Schedule/get_reference_schedule__estimateId__values
export const getReferenceScheduleValuesWithEstimateIdRequest = (
  payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_REFERENCE_SCHEDULE_VALUES_WITH_ESTIMATEID_START',
      successful: 'DATA_GET_REFERENCE_SCHEDULE_VALUES_WITH_ESTIMATEID_SUCCESSFUL',
      fail: 'DATA_GET_REFERENCE_SCHEDULE_VALUES_WITH_ESTIMATEID_FAIL'
    },
    embeddedType: {
      properties: {
        schemaPath: '#/definitions/ValueProperty',
        schemaKey: 'ValueProperty'
      }
    },
    fnName: 'getReferenceScheduleValuesWithEstimateIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/building-elements-api`,
    method: 'GET',
    url: `/reference-schedule/${options?.estimateId || store.app.calculation}/values`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      properties: {
        schemaPath: '#/definitions/ValueProperty',
        schemaKey: 'ValueProperty'
      }
    }, 'getReferenceScheduleValuesWithEstimateIdRequest', '/building-elements-api'),
    actions: {
      start: {
        type: DATA_GET_REFERENCE_SCHEDULE_VALUES_WITH_ESTIMATEID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_REFERENCE_SCHEDULE_VALUES_WITH_ESTIMATEID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_REFERENCE_SCHEDULE_VALUES_WITH_ESTIMATEID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getReferenceScheduleValuesWithEstimateIdRequestDefinitions = (definitionsArguments: {| payload?: Object |}): {|
  ...TVDGARConfigWithoutRequestArgs, payload?: Object |} => ({
  fnName: 'getReferenceScheduleValuesWithEstimateIdRequest',
  basePath: '/building-elements-api',
  payload: definitionsArguments.payload,
})


export const DATA_GET_REFERENCE_SCHEDULE_VIEW_WITH_ESTIMATEID_START = 'DATA_GET_REFERENCE_SCHEDULE_VIEW_WITH_ESTIMATEID_START'
export const DATA_GET_REFERENCE_SCHEDULE_VIEW_WITH_ESTIMATEID_SUCCESSFUL = 'DATA_GET_REFERENCE_SCHEDULE_VIEW_WITH_ESTIMATEID_SUCCESSFUL'
export const DATA_GET_REFERENCE_SCHEDULE_VIEW_WITH_ESTIMATEID_FAIL = 'DATA_GET_REFERENCE_SCHEDULE_VIEW_WITH_ESTIMATEID_FAIL'

// Returns view of space estimate that was used to generate current reference schedule
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/buildingelements.yaml#/Reference%20Schedule/get_reference_schedule__estimateId__view
export const getReferenceScheduleViewWithEstimateIdRequest = (
  payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_REFERENCE_SCHEDULE_VIEW_WITH_ESTIMATEID_START',
      successful: 'DATA_GET_REFERENCE_SCHEDULE_VIEW_WITH_ESTIMATEID_SUCCESSFUL',
      fail: 'DATA_GET_REFERENCE_SCHEDULE_VIEW_WITH_ESTIMATEID_FAIL'
    },
    embeddedType: {
      nonEmbedded: {
        schemaKey: null
      }
    },
    fnName: 'getReferenceScheduleViewWithEstimateIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/building-elements-api`,
    method: 'GET',
    url: `/reference-schedule/${options?.estimateId || store.app.calculation}/view`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      nonEmbedded: {
        schemaKey: null
      }
    }, 'getReferenceScheduleViewWithEstimateIdRequest', '/building-elements-api'),
    actions: {
      start: {
        type: DATA_GET_REFERENCE_SCHEDULE_VIEW_WITH_ESTIMATEID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_REFERENCE_SCHEDULE_VIEW_WITH_ESTIMATEID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_REFERENCE_SCHEDULE_VIEW_WITH_ESTIMATEID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getReferenceScheduleViewWithEstimateIdRequestDefinitions = (definitionsArguments: {| payload?: Object |}): {|
  ...TVDGARConfigWithoutRequestArgs, payload?: Object |} => ({
  fnName: 'getReferenceScheduleViewWithEstimateIdRequest',
  basePath: '/building-elements-api',
  payload: definitionsArguments.payload,
})


export const DATA_PUT_REFERENCE_SCHEDULE_VIEW_WITH_ESTIMATEID_START = 'DATA_PUT_REFERENCE_SCHEDULE_VIEW_WITH_ESTIMATEID_START'
export const DATA_PUT_REFERENCE_SCHEDULE_VIEW_WITH_ESTIMATEID_SUCCESSFUL = 'DATA_PUT_REFERENCE_SCHEDULE_VIEW_WITH_ESTIMATEID_SUCCESSFUL'
export const DATA_PUT_REFERENCE_SCHEDULE_VIEW_WITH_ESTIMATEID_FAIL = 'DATA_PUT_REFERENCE_SCHEDULE_VIEW_WITH_ESTIMATEID_FAIL'

// Set space estimate view for generating reference schedule. This will generate a new reference schedule
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/buildingelements.yaml#/Reference%20Schedule/put_reference_schedule__estimateId__view
export const putReferenceScheduleViewWithEstimateIdRequest = (
  requestArguments: putReferenceScheduleViewWithEstimateIdRequestBuildingelementsArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_PUT_REFERENCE_SCHEDULE_VIEW_WITH_ESTIMATEID_START',
      successful: 'DATA_PUT_REFERENCE_SCHEDULE_VIEW_WITH_ESTIMATEID_SUCCESSFUL',
      fail: 'DATA_PUT_REFERENCE_SCHEDULE_VIEW_WITH_ESTIMATEID_FAIL'
    },
    embeddedType: null,
    fnName: 'putReferenceScheduleViewWithEstimateIdRequest'
  }
  const config = {
    headers: { 'Content-Type': 'application/json' },
    baseURL: `${API_BASE_URL}/building-elements-api`,
    method: 'PUT',
    url: `/reference-schedule/${options?.estimateId || store.app.calculation}/view`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: `"${requestArguments.body}"`
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'putReferenceScheduleViewWithEstimateIdRequest', '/building-elements-api'),
    actions: {
      start: {
        type: DATA_PUT_REFERENCE_SCHEDULE_VIEW_WITH_ESTIMATEID_START, payload, config, meta
      },
      successful: {
        type: DATA_PUT_REFERENCE_SCHEDULE_VIEW_WITH_ESTIMATEID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_PUT_REFERENCE_SCHEDULE_VIEW_WITH_ESTIMATEID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_GET_REFERENCE_SCHEDULE_ID_WITH_ESTIMATEID_START = 'DATA_GET_REFERENCE_SCHEDULE_ID_WITH_ESTIMATEID_START'
export const DATA_GET_REFERENCE_SCHEDULE_ID_WITH_ESTIMATEID_SUCCESSFUL = 'DATA_GET_REFERENCE_SCHEDULE_ID_WITH_ESTIMATEID_SUCCESSFUL'
export const DATA_GET_REFERENCE_SCHEDULE_ID_WITH_ESTIMATEID_FAIL = 'DATA_GET_REFERENCE_SCHEDULE_ID_WITH_ESTIMATEID_FAIL'

// Returns id of space estimate that was used to generate current reference schedule
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/buildingelements.yaml#/Reference%20Schedule/get_reference_schedule__estimateId__id
export const getReferenceScheduleIdWithEstimateIdRequest = (
  payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_REFERENCE_SCHEDULE_ID_WITH_ESTIMATEID_START',
      successful: 'DATA_GET_REFERENCE_SCHEDULE_ID_WITH_ESTIMATEID_SUCCESSFUL',
      fail: 'DATA_GET_REFERENCE_SCHEDULE_ID_WITH_ESTIMATEID_FAIL'
    },
    embeddedType: null,
    fnName: 'getReferenceScheduleIdWithEstimateIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/building-elements-api`,
    method: 'GET',
    url: `/reference-schedule/${options?.estimateId || store.app.calculation}/id`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'getReferenceScheduleIdWithEstimateIdRequest', '/building-elements-api'),
    actions: {
      start: {
        type: DATA_GET_REFERENCE_SCHEDULE_ID_WITH_ESTIMATEID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_REFERENCE_SCHEDULE_ID_WITH_ESTIMATEID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_REFERENCE_SCHEDULE_ID_WITH_ESTIMATEID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getReferenceScheduleIdWithEstimateIdRequestDefinitions = (definitionsArguments: {| payload?: Object |}): {|
  ...TVDGARConfigWithoutRequestArgs, payload?: Object |} => ({
  fnName: 'getReferenceScheduleIdWithEstimateIdRequest',
  basePath: '/building-elements-api',
  payload: definitionsArguments.payload,
})


export const DATA_PUT_REFERENCE_SCHEDULE_ID_WITH_ESTIMATEID_START = 'DATA_PUT_REFERENCE_SCHEDULE_ID_WITH_ESTIMATEID_START'
export const DATA_PUT_REFERENCE_SCHEDULE_ID_WITH_ESTIMATEID_SUCCESSFUL = 'DATA_PUT_REFERENCE_SCHEDULE_ID_WITH_ESTIMATEID_SUCCESSFUL'
export const DATA_PUT_REFERENCE_SCHEDULE_ID_WITH_ESTIMATEID_FAIL = 'DATA_PUT_REFERENCE_SCHEDULE_ID_WITH_ESTIMATEID_FAIL'

// Set id of reference schedule. This will generate a new reference schedule
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/buildingelements.yaml#/Reference%20Schedule/put_reference_schedule__estimateId__id
export const putReferenceScheduleIdWithEstimateIdRequest = (
  requestArguments: putReferenceScheduleIdWithEstimateIdRequestBuildingelementsArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_PUT_REFERENCE_SCHEDULE_ID_WITH_ESTIMATEID_START',
      successful: 'DATA_PUT_REFERENCE_SCHEDULE_ID_WITH_ESTIMATEID_SUCCESSFUL',
      fail: 'DATA_PUT_REFERENCE_SCHEDULE_ID_WITH_ESTIMATEID_FAIL'
    },
    embeddedType: null,
    fnName: 'putReferenceScheduleIdWithEstimateIdRequest'
  }
  const config = {
    headers: { 'Content-Type': 'application/json' },
    baseURL: `${API_BASE_URL}/building-elements-api`,
    method: 'PUT',
    url: `/reference-schedule/${options?.estimateId || store.app.calculation}/id`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: `"${requestArguments.body}"`
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'putReferenceScheduleIdWithEstimateIdRequest', '/building-elements-api'),
    actions: {
      start: {
        type: DATA_PUT_REFERENCE_SCHEDULE_ID_WITH_ESTIMATEID_START, payload, config, meta
      },
      successful: {
        type: DATA_PUT_REFERENCE_SCHEDULE_ID_WITH_ESTIMATEID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_PUT_REFERENCE_SCHEDULE_ID_WITH_ESTIMATEID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_GET_REFERENCE_SCHEDULE_ESTIMATES_WITH_ESTIMATEID_START = 'DATA_GET_REFERENCE_SCHEDULE_ESTIMATES_WITH_ESTIMATEID_START'
export const DATA_GET_REFERENCE_SCHEDULE_ESTIMATES_WITH_ESTIMATEID_SUCCESSFUL = 'DATA_GET_REFERENCE_SCHEDULE_ESTIMATES_WITH_ESTIMATEID_SUCCESSFUL'
export const DATA_GET_REFERENCE_SCHEDULE_ESTIMATES_WITH_ESTIMATEID_FAIL = 'DATA_GET_REFERENCE_SCHEDULE_ESTIMATES_WITH_ESTIMATEID_FAIL'

// Returns a list of spaces estimates that can be imported
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/buildingelements.yaml#/Reference%20Schedule/get_reference_schedule__estimateId__estimates
export const getReferenceScheduleEstimatesWithEstimateIdRequest = (
  payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_REFERENCE_SCHEDULE_ESTIMATES_WITH_ESTIMATEID_START',
      successful: 'DATA_GET_REFERENCE_SCHEDULE_ESTIMATES_WITH_ESTIMATEID_SUCCESSFUL',
      fail: 'DATA_GET_REFERENCE_SCHEDULE_ESTIMATES_WITH_ESTIMATEID_FAIL'
    },
    embeddedType: {
      items: {
        schemaPath: '#/definitions/Enumeral',
        schemaKey: 'Enumeral'
      }
    },
    fnName: 'getReferenceScheduleEstimatesWithEstimateIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/building-elements-api`,
    method: 'GET',
    url: `/reference-schedule/${options?.estimateId || store.app.calculation}/estimates`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      items: {
        schemaPath: '#/definitions/Enumeral',
        schemaKey: 'Enumeral'
      }
    }, 'getReferenceScheduleEstimatesWithEstimateIdRequest', '/building-elements-api'),
    actions: {
      start: {
        type: DATA_GET_REFERENCE_SCHEDULE_ESTIMATES_WITH_ESTIMATEID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_REFERENCE_SCHEDULE_ESTIMATES_WITH_ESTIMATEID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_REFERENCE_SCHEDULE_ESTIMATES_WITH_ESTIMATEID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getReferenceScheduleEstimatesWithEstimateIdRequestDefinitions = (definitionsArguments: {| payload?: Object |}): {|
  ...TVDGARConfigWithoutRequestArgs, payload?: Object |} => ({
  fnName: 'getReferenceScheduleEstimatesWithEstimateIdRequest',
  basePath: '/building-elements-api',
  payload: definitionsArguments.payload,
})


export const DATA_GET_REPORTS_START = 'DATA_GET_REPORTS_START'
export const DATA_GET_REPORTS_SUCCESSFUL = 'DATA_GET_REPORTS_SUCCESSFUL'
export const DATA_GET_REPORTS_FAIL = 'DATA_GET_REPORTS_FAIL'

// Returns a list of available report types as Enumerals with reportType as value and a localized name for the type.
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/buildingelements.yaml#/Reports/get_reports
export const getReportsRequest = (
  payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_REPORTS_START',
      successful: 'DATA_GET_REPORTS_SUCCESSFUL',
      fail: 'DATA_GET_REPORTS_FAIL'
    },
    embeddedType: {
      items: {
        schemaPath: '#/definitions/Enumeral',
        schemaKey: 'Enumeral'
      }
    },
    fnName: 'getReportsRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/building-elements-api`,
    method: 'GET',
    url: '/reports',
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      items: {
        schemaPath: '#/definitions/Enumeral',
        schemaKey: 'Enumeral'
      }
    }, 'getReportsRequest', '/building-elements-api'),
    actions: {
      start: {
        type: DATA_GET_REPORTS_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_REPORTS_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_REPORTS_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getReportsRequestDefinitions = (definitionsArguments: {| payload?: Object |}): {|
  ...TVDGARConfigWithoutRequestArgs, payload?: Object |} => ({
  fnName: 'getReportsRequest',
  basePath: '/building-elements-api',
  payload: definitionsArguments.payload,
})


export const DATA_GET_REPORTS_WITH_REPORTTYPE_START = 'DATA_GET_REPORTS_WITH_REPORTTYPE_START'
export const DATA_GET_REPORTS_WITH_REPORTTYPE_SUCCESSFUL = 'DATA_GET_REPORTS_WITH_REPORTTYPE_SUCCESSFUL'
export const DATA_GET_REPORTS_WITH_REPORTTYPE_FAIL = 'DATA_GET_REPORTS_WITH_REPORTTYPE_FAIL'

// Returns a pdf report of given type for requested estimate.
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/buildingelements.yaml#/Reports/get_reports__reportType___estimateId_
export const getReportsWithReportTypeRequest = (
  requestArguments: getReportsWithReportTypeRequestBuildingelementsArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_REPORTS_WITH_REPORTTYPE_START',
      successful: 'DATA_GET_REPORTS_WITH_REPORTTYPE_SUCCESSFUL',
      fail: 'DATA_GET_REPORTS_WITH_REPORTTYPE_FAIL'
    },
    embeddedType: null,
    fnName: 'getReportsWithReportTypeRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/building-elements-api`,
    method: 'GET',
    url: `/reports/${requestArguments.path['reportType']}/${options?.estimateId || store.app.calculation}`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'getReportsWithReportTypeRequest', '/building-elements-api'),
    actions: {
      start: {
        type: DATA_GET_REPORTS_WITH_REPORTTYPE_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_REPORTS_WITH_REPORTTYPE_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_REPORTS_WITH_REPORTTYPE_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getReportsWithReportTypeRequestDefinitions = (definitionsArguments: {| requestArgs: getReportsWithReportTypeRequestBuildingelementsArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getReportsWithReportTypeRequestBuildingelementsArguments, payload?: Object |} => ({
  fnName: 'getReportsWithReportTypeRequest',
  basePath: '/building-elements-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_GET_QUEUE_WITH_ID_START = 'DATA_GET_QUEUE_WITH_ID_START'
export const DATA_GET_QUEUE_WITH_ID_SUCCESSFUL = 'DATA_GET_QUEUE_WITH_ID_SUCCESSFUL'
export const DATA_GET_QUEUE_WITH_ID_FAIL = 'DATA_GET_QUEUE_WITH_ID_FAIL'

// Returns status of queued operation
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/buildingelements.yaml#/Queue/get_queue__id_
export const getQueueWithIdRequest = (
  requestArguments: getQueueWithIdRequestBuildingelementsArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_QUEUE_WITH_ID_START',
      successful: 'DATA_GET_QUEUE_WITH_ID_SUCCESSFUL',
      fail: 'DATA_GET_QUEUE_WITH_ID_FAIL'
    },
    embeddedType: {
      nonEmbedded: {
        schemaPath: '#/definitions/QueueStatus',
        schemaKey: 'QueueStatus'
      }
    },
    fnName: 'getQueueWithIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/building-elements-api`,
    method: 'GET',
    url: `/queue/${requestArguments.path['id']}`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      nonEmbedded: {
        schemaPath: '#/definitions/QueueStatus',
        schemaKey: 'QueueStatus'
      }
    }, 'getQueueWithIdRequest', '/building-elements-api'),
    actions: {
      start: {
        type: DATA_GET_QUEUE_WITH_ID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_QUEUE_WITH_ID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_QUEUE_WITH_ID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getQueueWithIdRequestDefinitions = (definitionsArguments: {| requestArgs: getQueueWithIdRequestBuildingelementsArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getQueueWithIdRequestBuildingelementsArguments, payload?: Object |} => ({
  fnName: 'getQueueWithIdRequest',
  basePath: '/building-elements-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_DELETE_QUEUE_WITH_ID_START = 'DATA_DELETE_QUEUE_WITH_ID_START'
export const DATA_DELETE_QUEUE_WITH_ID_SUCCESSFUL = 'DATA_DELETE_QUEUE_WITH_ID_SUCCESSFUL'
export const DATA_DELETE_QUEUE_WITH_ID_FAIL = 'DATA_DELETE_QUEUE_WITH_ID_FAIL'

// Deletes an operation from the queue
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/buildingelements.yaml#/Queue/delete_queue__id_
export const deleteQueueWithIdRequest = (
  requestArguments: deleteQueueWithIdRequestBuildingelementsArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_DELETE_QUEUE_WITH_ID_START',
      successful: 'DATA_DELETE_QUEUE_WITH_ID_SUCCESSFUL',
      fail: 'DATA_DELETE_QUEUE_WITH_ID_FAIL'
    },
    embeddedType: null,
    fnName: 'deleteQueueWithIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/building-elements-api`,
    method: 'DELETE',
    url: `/queue/${requestArguments.path['id']}`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'deleteQueueWithIdRequest', '/building-elements-api'),
    actions: {
      start: {
        type: DATA_DELETE_QUEUE_WITH_ID_START, payload, config, meta
      },
      successful: {
        type: DATA_DELETE_QUEUE_WITH_ID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_DELETE_QUEUE_WITH_ID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_GET_ENUM_WITH_ENUM_START = 'DATA_GET_ENUM_WITH_ENUM_START'
export const DATA_GET_ENUM_WITH_ENUM_SUCCESSFUL = 'DATA_GET_ENUM_WITH_ENUM_SUCCESSFUL'
export const DATA_GET_ENUM_WITH_ENUM_FAIL = 'DATA_GET_ENUM_WITH_ENUM_FAIL'

// Returns members of given enum as embedded Enumerals
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/buildingelements.yaml#/Enum/get_enum__enum_
export const getEnumWithEnumRequest = (
  requestArguments: getEnumWithEnumRequestBuildingelementsArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_ENUM_WITH_ENUM_START',
      successful: 'DATA_GET_ENUM_WITH_ENUM_SUCCESSFUL',
      fail: 'DATA_GET_ENUM_WITH_ENUM_FAIL'
    },
    embeddedType: {
      enumeral: {
        schemaPath: '#/definitions/Enumeral',
        schemaKey: 'Enumeral'
      }
    },
    fnName: 'getEnumWithEnumRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/building-elements-api`,
    method: 'GET',
    url: `/enum/${requestArguments.path['enumParam']}`,
    params: {
      category: requestArguments.query?.['category'],
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper({
      enumeral: {
        schemaPath: '#/definitions/Enumeral',
        schemaKey: 'Enumeral'
      }
    }, 'getEnumWithEnumRequest', '/building-elements-api'),
    actions: {
      start: {
        type: DATA_GET_ENUM_WITH_ENUM_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_ENUM_WITH_ENUM_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_ENUM_WITH_ENUM_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getEnumWithEnumRequestDefinitions = (definitionsArguments: {| requestArgs: getEnumWithEnumRequestBuildingelementsArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getEnumWithEnumRequestBuildingelementsArguments, payload?: Object |} => ({
  fnName: 'getEnumWithEnumRequest',
  basePath: '/building-elements-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_GET_SWAGGER_START = 'DATA_GET_SWAGGER_START'
export const DATA_GET_SWAGGER_SUCCESSFUL = 'DATA_GET_SWAGGER_SUCCESSFUL'
export const DATA_GET_SWAGGER_FAIL = 'DATA_GET_SWAGGER_FAIL'

// Returns Swagger specification of the API
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/buildingelements.yaml#/Swagger/get_swagger
export const getSwaggerRequest = (
  payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_SWAGGER_START',
      successful: 'DATA_GET_SWAGGER_SUCCESSFUL',
      fail: 'DATA_GET_SWAGGER_FAIL'
    },
    embeddedType: null,
    fnName: 'getSwaggerRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/building-elements-api`,
    method: 'GET',
    url: '/swagger',
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'getSwaggerRequest', '/building-elements-api'),
    actions: {
      start: {
        type: DATA_GET_SWAGGER_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_SWAGGER_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_SWAGGER_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getSwaggerRequestDefinitions = (definitionsArguments: {| payload?: Object |}): {|
  ...TVDGARConfigWithoutRequestArgs, payload?: Object |} => ({
  fnName: 'getSwaggerRequest',
  basePath: '/building-elements-api',
  payload: definitionsArguments.payload,
})


export const DATA_GET_SWAGGER_WITH_NODE_START = 'DATA_GET_SWAGGER_WITH_NODE_START'
export const DATA_GET_SWAGGER_WITH_NODE_SUCCESSFUL = 'DATA_GET_SWAGGER_WITH_NODE_SUCCESSFUL'
export const DATA_GET_SWAGGER_WITH_NODE_FAIL = 'DATA_GET_SWAGGER_WITH_NODE_FAIL'

// Returns a first level node from the Swagger specification of the API
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/buildingelements.yaml#/Swagger/get_swagger__node_
export const getSwaggerWithNodeRequest = (
  requestArguments: getSwaggerWithNodeRequestBuildingelementsArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_SWAGGER_WITH_NODE_START',
      successful: 'DATA_GET_SWAGGER_WITH_NODE_SUCCESSFUL',
      fail: 'DATA_GET_SWAGGER_WITH_NODE_FAIL'
    },
    embeddedType: null,
    fnName: 'getSwaggerWithNodeRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/building-elements-api`,
    method: 'GET',
    url: `/swagger/${requestArguments.path['node']}`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'getSwaggerWithNodeRequest', '/building-elements-api'),
    actions: {
      start: {
        type: DATA_GET_SWAGGER_WITH_NODE_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_SWAGGER_WITH_NODE_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_SWAGGER_WITH_NODE_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getSwaggerWithNodeRequestDefinitions = (definitionsArguments: {| requestArgs: getSwaggerWithNodeRequestBuildingelementsArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getSwaggerWithNodeRequestBuildingelementsArguments, payload?: Object |} => ({
  fnName: 'getSwaggerWithNodeRequest',
  basePath: '/building-elements-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_GET_SWAGGER_WITH_CHILDNODE_START = 'DATA_GET_SWAGGER_WITH_CHILDNODE_START'
export const DATA_GET_SWAGGER_WITH_CHILDNODE_SUCCESSFUL = 'DATA_GET_SWAGGER_WITH_CHILDNODE_SUCCESSFUL'
export const DATA_GET_SWAGGER_WITH_CHILDNODE_FAIL = 'DATA_GET_SWAGGER_WITH_CHILDNODE_FAIL'

// Returns a second level node from the Swagger specification of the API
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/buildingelements.yaml#/Swagger/get_swagger__node___childNode_
export const getSwaggerWithChildNodeRequest = (
  requestArguments: getSwaggerWithChildNodeRequestBuildingelementsArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_SWAGGER_WITH_CHILDNODE_START',
      successful: 'DATA_GET_SWAGGER_WITH_CHILDNODE_SUCCESSFUL',
      fail: 'DATA_GET_SWAGGER_WITH_CHILDNODE_FAIL'
    },
    embeddedType: null,
    fnName: 'getSwaggerWithChildNodeRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/building-elements-api`,
    method: 'GET',
    url: `/swagger/${requestArguments.path['node']}/${requestArguments.path['childNode']}`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'getSwaggerWithChildNodeRequest', '/building-elements-api'),
    actions: {
      start: {
        type: DATA_GET_SWAGGER_WITH_CHILDNODE_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_SWAGGER_WITH_CHILDNODE_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_SWAGGER_WITH_CHILDNODE_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getSwaggerWithChildNodeRequestDefinitions = (definitionsArguments: {| requestArgs: getSwaggerWithChildNodeRequestBuildingelementsArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getSwaggerWithChildNodeRequestBuildingelementsArguments, payload?: Object |} => ({
  fnName: 'getSwaggerWithChildNodeRequest',
  basePath: '/building-elements-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})
