// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.

import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Typography, Button } from '@material-ui/core'

const styles = ({ palette, typography }: TVDTheme): Object => ({
  appButton: {
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '170px',
    height: '170px',
    backgroundColor: palette.primary100,
    margin: '6px',
    transition: 'all 0.3s ease',
    zIndex: '1',
    borderRadius: '2px',
    '&:hover': {
      transition: 'all 0.3s ease',
      backgroundColor: '#0080bd'
    },
    '&:disabled': {
      backgroundColor: palette.geyser,
    }
  },
  appText: {
    textTransform: 'none',
    fontSize: '20px',
    color: palette.white,
    fontFamily: typography.fontFamilyBase
  },
  label: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  }
})

export type AppButtonProps = {|
  id: number, // id of application
  img: string, // path to the image file for the icon in the button
  label: string, // the translated text in the button
  disabled?: boolean, // indicates if button is disabled
  name: string, // name of the app the button opens
  testid: string, // testid for testing purposes
  url: string, // url of the application
  onClick: Function, // click handler
|}

type Props = {
  ...AppButtonProps,
  classes: Object, // withStyles styles prop
}

export class AppButton extends Component<Props> {
  handleClick = (appUrl: string) => {
    this.props.onClick(appUrl)
  }
  render(): React$Element<Button> {
    const {
      img,
      name,
      disabled,
      testid,
      label,
      classes,
      url,
    } = this.props

    return (
      <Button
        role='button'
        data-testid={testid}
        tabIndex={0}
        disabled={disabled}
        key={name}
        className={classes.appButton}
        classes={{ label: classes.label }}
        onClick={() => this.handleClick(url)}>
        <img src={img} width='90' height='106' alt='app logo' />
        <Typography className={classes.appText}>{label}</Typography>
      </Button>
    )
  }
}

export default withStyles(styles)(AppButton)
