// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.
import { find } from 'lodash'
import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { closeModal } from '../../../../actions/modals'
import { MODAL_TYPE_ADD_USER_TO_GROUP_MODAL } from '../../../../constants/modalConstants'
import {
  getUsersRequest,
  postUserGroupsUsersWithUserIdRequest
} from '../../../../utils/generated-api-requests/users'
import AutoCompleteMenu, { type AutoCompleteMenuItem } from '../../../common/menus/AutoCompleteMenu/AutoCompleteMenu'
import ModalForm from '../../../common/ModalForm/ModalForm'


type MappedProps = {|
  selectedAccountId?: string, // user selected account id
|}

type HOCProps = {|
  dispatchCloseModal: Function,
|}

type ReceivedProps = {|
  initialGroupUsers: Array<AutoCompleteMenuItem>, // initial array of users
  groupId: string, // ID for the group
  disableEdit: boolean, // if the modal should be in a disabled state
  onClose: () => void, // fired when the modal closes
  postUserSuccessCallBack: () => void, // callback for actions taken after a successfull user adding post call
|}

type Props = {|
  ...HOCProps,
  ...ReceivedProps
|}

type State = {|
  addedUsers: Array<AutoCompleteMenuItem>, // currently selected users in the input
  availableUsers: Array<AutoCompleteMenuItem> // all users fetched minus the initialGroupUsers prop users
|}

export class AddUserToGroup extends React.Component<Props, State> {
  static defaultProps = {
    disableEdit: false,
    onClose: () => {},
    postUserSuccessCallBack: () => {}
  }

  state = {
    addedUsers: [],
    availableUsers: [],
  }

  componentDidMount() {
    this.getUsers()
  }

  getUsers = () => {
    let result = []

    const setResult = (response: Array<TVDUser>) => {
      result = response.map((user: TVDUser) => (
        {
          id: user.id,
          name: [user.firstName, user.lastName].join(' ')
        }
      ))
      this.setState({ availableUsers: this.removeInitialUsersFrom(result) })
    }

    getUsersRequest(
      {},
      {},
      (res: Array<TVDUser>) => setResult(res),
      null,
    )
  }

  removeInitialUsersFrom = (users: Array<AutoCompleteMenuItem>): Array<AutoCompleteMenuItem> => {
    const { initialGroupUsers } = this.props
    return users.filter((user: AutoCompleteMenuItem): boolean => !find(initialGroupUsers, ['id', user.id]))
  }

  userList = () => {
    const { disableEdit } = this.props
    const { addedUsers, availableUsers } = this.state

    return (
      <AutoCompleteMenu
        options={availableUsers}
        onChange={this.changeUserSelection}
        value={addedUsers}
        disabled={disableEdit}
        multiple />
    )
  }

  changeUserSelection = (event: any, users: Array<AutoCompleteMenuItem>) => {
    this.setState({ addedUsers: users })
  }

  saveUsers = () => {
    const { groupId } = this.props
    const { addedUsers } = this.state
    Promise.all(addedUsers.map((user: AutoCompleteMenuItem): Promise<any> =>
      postUserGroupsUsersWithUserIdRequest(
        { path: { id: groupId, userId: user.id } },
        {},
        null,
        null
      )))
      .then(this.props.postUserSuccessCallBack)
  }

  handleSave = () => {
    this.saveUsers()
    this.handleClose()
  }

  handleClose = () => {
    this.props.onClose()
    this.props.dispatchCloseModal()
  }

  render(): React$Element<ModalForm> {
    const { disableEdit } = this.props
    const modified = this.state.addedUsers.length !== 0

    return (
      <ModalForm
        hideSave={disableEdit || !modified}
        items={[this.userList()]}
        onSave={this.handleSave}
        onClose={this.handleClose}
        testId='AddUserToGroup'
        valid
        modified={modified}
        saveButtonText='buttons._ADD_TO_GROUP_' />
    )
  }
}

const mapDispatchToProps = (dispatch: Function) => ({
  dispatchCloseModal: () => { dispatch(closeModal(MODAL_TYPE_ADD_USER_TO_GROUP_MODAL)) },
})

const mapStateToProps = ({ app: { selectedAccountId } }: TVDReduxStore): MappedProps => ({
  selectedAccountId
})

export default compose(connect(mapStateToProps, mapDispatchToProps))(AddUserToGroup)
