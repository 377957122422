// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.

import React, { Component } from 'react'
import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core'
import Icon from '@material-ui/core/Icon'
import { colors, typographyClasses, borderRadiuses, boxShadows } from 'frontend-assets'
import Spinner from '../Spinner/Spinner'
import { combineStyleClassNames } from '../../../utils/styleUtils'

const { borderRadiusNormal, borderRadiusMedium } = borderRadiuses
const { boxShadowComponent } = boxShadows

const {
  primary20, primary10, primary100, primary120, white, gray80, gray100, dark60, dark80, error100, error120, primary60
} = colors
const { button, bodyDefaultBold, buttonSmall } = typographyClasses

const styles = () => ({
  buttonBase: {
    ...button,
    whiteSpace: 'nowrap',
    textTransform: 'none',
    boxShadow: 'none',
    height: '36px',
    borderRadius: borderRadiusMedium,
    padding: '5px 16px',
    '&:hover': {
      backgroundColor: primary60
    },
    '&:active': {
      backgroundColor: primary100,
      boxShadow: boxShadowComponent
    },
    '&:focus-visible': {
      boxShadow: boxShadowComponent
    },
  },
  buttonTextPrimary: {
    '&$buttonTextPrimary': {
      color: primary100
    },
    '&:hover': {
      backgroundColor: primary20
    },
    '&:active': {
      backgroundColor: white
    }
  },
  buttonTextSecondary: {
    '&$buttonTextSecondary': {
      color: colors.primary100,
      borderColor: colors.primary100,
      '&:hover': {
        backgroundColor: colors.primary20
      },
      '&:focus': {
        backgroundColor: colors.white
      }
    },
  },
  buttonDisabled: {
    '&$buttonDisabled': {
      color: gray80,
      borderColor: gray100,
      '& > span > span': {
        color: `${gray100}!important`
      }
    }
  },
  buttonContained: {
    '&$buttonContained': {
      color: white,
      backgroundColor: primary100
    },
    '&$buttonDisabled': {
      color: white,
      backgroundColor: gray100
    },
    '&:hover': {
      backgroundColor: primary60
    },
    '&:active': {
      backgroundColor: primary100
    },
  },
  buttonOutlined: {
    color: primary100,
    borderColor: primary100,
    '&$buttonDisabled': {
      color: gray100
    },
    '&:hover': {
      backgroundColor: primary10
    },
    '&:active': {
      backgroundColor: white
    },
  },
  buttonToolButton: {
    ...bodyDefaultBold,
    backgroundColor: `${white}!important`,
    textTransform: 'none',
    color: `${dark80}!important`,
    height: '46px',
    border: `1px solid ${gray80}`,
    '&:hover': {
      backgroundColor: `${primary10}!important`,
      border: `1px solid ${primary60}`
    },
    '&:active': {
      backgroundColor: `${primary10}!important`,
      border: `1px solid ${primary100}`
    },
    '& > span > span': {
      color: dark60
    },
    '&$buttonDisabled': {
      color: `${gray100}!important`
    },
  },
  isActiveToolButton: {
    backgroundColor: `${primary10}!important`,
    border: `1px solid ${primary100}`,
    outline: '1px solid transparent',
    boxShadow: 'none'
  },
  buttonIcon: {
    minWidth: '46px',
    width: '46px',
    '&:hover': {
      backgroundColor: primary20,
      borderColor: primary60,
      border: '1px solid'
    },
    '&:active': {
      backgroundColor: primary20,
      borderColor: primary100,
      border: `1px solid ${primary100}`,
      outline: '1px solid transparent',
      boxShadow: boxShadowComponent
    }
  },
  buttonLogin: {
    width: '280px'
  },
  buttonsmall: {
    ...buttonSmall,
    textTransform: 'none',
    borderRadius: borderRadiusNormal,
    padding: '2px 8px',
    minHeight: '25px',
    maxHeight: '25px'
  },
  leftIcon: {
    marginRight: '4px',
    marginLeft: '-4px',
    fontSize: '21px'
  },
  rightIcon: {
    marginLeft: '4px',
    marginRight: '-4px',
    fontSize: '21px',
    color: dark60
  },
  containedDanger: {
    backgroundColor: `${error120} !important`,
    '&:hover': {
      backgroundColor: `${error100} !important`,
    }
  },
  noMargin: {
    margin: 0,
    color: primary100
  },
  label: {
    fontFamily: typographyClasses.bodyDefaultBold
  },
  containedSlim: {
    height: '25px'
  },
  number: {
    color: primary100,
    marginRight: '6px'
  },
  clearSelected: {
    ...bodyDefaultBold,
    color: primary100,
    textTransform: 'none',
    '&:hover': {
      backgroundColor: 'transparent',
      color: primary120
    },
    '&:active:': {
      backgroundColor: 'transparent',
      color: primary120
    },
  }
})

export type TVDButtonVariants = 'text' | 'contained' | 'outlined' | 'toolButton' | 'icon' | 'login'
| 'small' | 'containedDanger' | 'containedSlim' | 'smallDanger' | 'smallOutlined'
type ButtonTypes = 'primary' | 'secondary'

export type TextButtonProps = {|
  text: string, // text that is shown inside the button
  onClick?: Function, // action fired when the button is clicked
  variant?: TVDButtonVariants, // selects the variant of the button to be used
  type?: ButtonTypes, // selects the type of the button, primary or secondary
  disabled?: boolean, // flag to prevent button click actions triggering
  showSpinner?: boolean, // flag to show loading spinner on button during calculation
  id?: string, // id for testing
  iconRight?: string, // icon component on the right side of the text
  iconLeft?: string, // icon component on the left side of the text
  disableElevation?: boolean, // flag to display dropshadow on button
  number?: number // toolButton with a number, as in "1. Luo toiminnan tilat"
  |}

type Props = {|
  ...TextButtonProps,
  classes: Object, // styles object
|}

export class TextButton extends Component<Props> {
  static defaultProps = {
    id: '',
    iconRight: null,
    iconLeft: null,
    variant: 'contained',
    type: 'primary',
    disabled: false,
    showSpinner: false,
    disableElevation: true,
    number: null
  }

  getButtonClasses = () => {
    const { variant, classes } = this.props
    // first three are MUI variants and handled by withStyles, toolButton and login are our own
    switch (variant) {
      case 'text':
      case 'contained':
      case 'outlined':
        return ''
      case 'toolButton':
        return classes.buttonToolButton
      case 'icon':
        return combineStyleClassNames(classes.buttonToolButton, classes.buttonIcon)
      case 'login':
        return classes.buttonLogin
      case 'containedDanger':
        return classes.containedDanger
      case 'smallDanger':
        return combineStyleClassNames(classes.buttonsmall, classes.containedDanger)
      case 'smallOutlined':
        return combineStyleClassNames(classes.buttonsmall, classes.buttonOutlined)
      case 'containedSlim':
        return classes.containedSlim
      case 'clearSelected':
        return classes.clearSelected
      default:
        return ''
    }
  }

  getButtonMUIVariant = () => {
    // MUI component will error if you give it a variant outside of what it accepts.
    // All our custom variants are based on the contained variant
    switch (this.props.variant) {
      case 'text':
      case 'outlined':
        return this.props.variant
      case 'smallOutlined':
      case 'toolButton':
      case 'icon':
        return 'outlined'
      case 'containedSlim':
      case 'contained':
      case 'login':
      case 'small':
      case 'containedDanger':
        return 'contained'
      case 'clearSelected':
        return 'text'
      default:
        return 'contained'
    }
  }

  capitalize = (input: string): string => {
    if (typeof input !== 'string') return ''
    return input.charAt(0).toUpperCase() + input.slice(1)
  }

  render(): React$Element<Button> {
    const {
      disabled,
      classes,
      id,
      text,
      iconRight,
      iconLeft,
      type,
      variant,
      onClick,
      showSpinner,
      disableElevation,
      number
    } = this.props
    const size = variant === 'small' ? 'small' : 'medium'
    const MUIVariant = this.getButtonMUIVariant()
    const SpecialButtonClasses = this.getButtonClasses()
    const testID = id !== undefined ? id : text
    const isIconButton = variant === 'icon'
    return (
      <Button
        disableRipple
        size={size}
        disabled={disabled || showSpinner}
        data-testid={`TextButton-${testID}`}
        onClick={onClick}
        variant={MUIVariant}
        color={type}
        className={SpecialButtonClasses}
        disableElevation={disableElevation}
        classes={{
          root: classes.buttonBase,
          disabled: classes.buttonDisabled,
          textPrimary: classes.buttonTextPrimary,
          textSecondary: classes.buttonTextSecondary,
          containedPrimary: classes.buttonContained,
          outlinedPrimary: classes.buttonOutlined,
          sizeSmall: classes.buttonsmall,
          label: classes.label
        }}>
        {showSpinner && <Spinner size={20} />}
        {iconLeft && <Icon classes={{ root: combineStyleClassNames(classes.left, disabled && classes.buttonDisabled) }}>{iconLeft}</Icon>}
        {number !== null && <div className={combineStyleClassNames(classes.number, disabled && classes.buttonDisabled)}>{number}.</div>}
        {this.props.variant === 'toolButton' ? this.capitalize(text.toLowerCase()) : text}
        {
          iconRight &&
          <Icon
            style={{ color: isIconButton && primary100 }}
            classes={{ root: combineStyleClassNames(classes.rightIcon, isIconButton && classes.noMargin, disabled && classes.buttonDisabled) }}>
            {iconRight}
          </Icon>
        }
      </Button>
    )
  }
}

export default withStyles(styles)(TextButton)
