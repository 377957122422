// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.
import React, { Component } from 'react'
import { map } from 'lodash'
import { withStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core'
import CategoryRow from '../CategoryRow/CategoryRow'
import ListHeaderCell from '../../../lists/common/ListHeaderCell/ListHeaderCell'
import { setScrollPositionComponentPreference } from '../../../../../actions/componentPreferences'

const commonWrapperStyles = {
  display: 'flex',
  alignItems: 'center',
  height: 32,
  fontSize: 14,
  fontWeight: 600,
}

const styles = ({ palette, typography }: Object): Object => ({
  tableHeader: {
    commonWrapperStyles,
    fontSize: 16,
    fontFamily: typography.latoBoldItalic,
    color: palette.nevada,
    marginTop: 25,
    paddingLeft: 16,
    letterSpacing: 0.5
  },
  listHeaderCellContent: {
    ...commonWrapperStyles,
    justifyContent: 'flex-end',
    paddingRight: '4px',
    color: palette.manatee,
  },
  firstListHeaderCellContent: {
    ...commonWrapperStyles,
    justifyContent: 'flex-start',
    letterSpacing: 0.5,
    color: palette.manatee,
    paddingLeft: 32
  },
  properties: {
    display: 'flex',
    flexDirection: 'column',
    overflowX: 'hidden',
    overflowY: 'auto',
    height: '100%',
    width: '100%'
  },
  tableRoot: {
    height: '100%',
    display: 'block',
    overflowX: 'hidden',
    overflowY: 'auto'
  },
  headerSpannerCell: {
    backgroundColor: palette.white,
    top: 0,
    position: 'sticky',
    width: '100%',
    borderBottom: `1px solid ${palette.porcelain}`,
  }
})

type HOCProps = {|
  classes: Object, // withstyles classes object
|}

type OwnProps = {|
  listItems: Object, // data object
  parser: Function,
  onChange: Function,
  onBlur: Function,
  onChange: Function,
  resourceId: string,
  resourceListId: string,
  hierarchy: Object,
  disabled: boolean, // flag to disable data table row events
  resetDefault: Function, // reset property to default value
  type: string, // widget type
  actionText?: string, // optional actionText for userModifiedIcon to overwrite default text
  staticValue: boolean, // should the inputs be static instead of modifiable (set where PropertyContainer is called)
  onPropertyRowMount?: (row: TVDPropertiesListItem) => void, // optional cb for when row mounts
|}

type DispatchProps = {|
  dispatchSetScrollPositionComponentPreference: (scrollPosition: number) => void
|}

type MappedProps = {|
  scrollPosition: number
|}

type Props = {|
  ...OwnProps,
  ...DispatchProps,
  ...HOCProps,
  ...MappedProps
|}

export class PropertiesDataTable extends Component<Props> {
  static defaultProps = {
    actionText: '',
    onPropertyRowMount: undefined
  }

  tableRef: { current: null | HTMLTableElement }
  propertiesCountRef: { current: null | number} = { current: 0 }

  componentDidUpdate() {
    const { scrollPosition } = this.props
    const { current } = this.tableRef
    if (typeof scrollPosition === 'number' && current && this.properties.length !== this.propertiesCountRef.current) {
      current.scrollTop = scrollPosition
    }
    this.propertiesCountRef.current = this.properties.length
  }


  get headerSpanner(): React$Element<typeof TableCell> {
    const { classes } = this.props
    return <TableCell className={classes.headerSpannerCell} />
  }

  get table(): React$Element<any> {
    const { classes } = this.props
    return (
      <Table
        ref={this.tableRef}
        onScroll={this.handleScroll}
        classes={{ root: classes.tableRoot }}>
        <TableHead>
          <TableRow>
            <ListHeaderCell
              initialWidth={500}>
              <div className={classes.firstListHeaderCellContent} />
            </ListHeaderCell>
            <ListHeaderCell
              initialWidth={300}>
              <div className={classes.listHeaderCellContent} />
            </ListHeaderCell>
            {this.headerSpanner}
          </TableRow>
        </TableHead>
        <TableBody>
          {this.properties}
        </TableBody>
      </Table>
    )
  }

  get properties(): Array<React$Element<any>> {
    const {
      listItems,
      parser,
      onChange,
      onBlur,
      resourceId,
      resourceListId,
      hierarchy,
      disabled,
      resetDefault,
      type,
      actionText,
      staticValue,
      onPropertyRowMount
    } = this.props

    return map(listItems, (value: Object, key: string) => (
      <CategoryRow
        actionText={actionText}
        widgetType={type}
        key={key}
        hierarchy={hierarchy}
        title={key}
        data={value}
        onBlur={onBlur}
        onChange={onChange}
        parser={parser}
        resourceId={resourceId}
        resourceListId={resourceListId}
        disabled={disabled}
        resetDefault={resetDefault}
        staticValue={staticValue}
        onPropertyRowMount={onPropertyRowMount} />
    ))
  }

  handleScroll = (event: SyntheticEvent<HTMLTableElement>): void => {
    const { dispatchSetScrollPositionComponentPreference } = this.props
    dispatchSetScrollPositionComponentPreference(event.currentTarget.scrollTop)
  }

  constructor(props: Props) {
    super(props)
    this.tableRef = React.createRef()
  }


  render(): React$Element<any> {
    const { classes } = this.props
    return (
      <div className={classes.properties}>
        {this.table}
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch: Function, props: OwnProps): DispatchProps => ({
  dispatchSetScrollPositionComponentPreference: (scrollPosition: number) => {
    dispatch(setScrollPositionComponentPreference(props.type, scrollPosition))
  }
})

const mapStateToProps = ({ componentPreferences }: TVDReduxStore, { type }: OwnProps): MappedProps => {
  const {
    [type]: {
      scrollPosition
    } = {}
  } = componentPreferences
  return {
    scrollPosition
  }
}

export default compose(
  withStyles(styles),
  withTranslation('translations'),
  connect(mapStateToProps, mapDispatchToProps)
)(PropertiesDataTable)

