// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.
import Axios from 'axios'
import * as SpacesGARs from './generated-api-requests/spaces'
import * as ElementsGARs from './generated-api-requests/buildingelements'
import * as WOPGARs from './generated-api-requests/wop'
import * as SpacesRenovationGARs from './generated-api-requests/spaces_renovation'
import * as EstimatesGARs from './generated-api-requests/estimates'
import * as LocanGARs from './generated-api-requests/locan'

type AllGARs = {
  [basePath: string]: {
    [GARName: string]: Function, // imported generated api function
  }
}

export const allGARs: {|
  getAll: () => AllGARs
|} = ({
  getAll: (): AllGARs => ({
    [SpacesGARs.basePath]: SpacesGARs,
    [ElementsGARs.basePath]: ElementsGARs,
    [WOPGARs.basePath]: WOPGARs,
    [SpacesRenovationGARs.basePath]: SpacesRenovationGARs,
    [EstimatesGARs.basePath]: EstimatesGARs,
    [LocanGARs.basePath]: LocanGARs
  })
})

export function runGAR(requestDefinition: TVDGARConfig): Promise<any> {
  const {
    basePath,
    fnName,
    // explicit undefined default definition for flow understanding that this is allowed to be undefined
    requestArgs = undefined,
    payload = {}, // defaulting payload to empty Object so filter does not remove it from the params array and we keep the param order intact
    options,
    successCb = null,
    errorCb = null
  } = requestDefinition
  const params = [requestArgs, payload, successCb, errorCb, options].filter((param: Object | Function) => typeof param !== 'undefined')
  return allGARs.getAll()[basePath][fnName](...params)
}

export const getEnumGARDefinition = (basePath: string, enumParam: string, category?: string): Function | null => {
  switch (basePath) {
    case SpacesGARs.basePath: return SpacesGARs.getEnumWithEnumRequestDefinitions({ requestArgs: { path: { enumParam }, query: { category } } })
    case ElementsGARs.basePath: return ElementsGARs.getEnumWithEnumRequestDefinitions({ requestArgs: { path: { enumParam }, query: { category } } })
    case WOPGARs.basePath: return WOPGARs.getEnumWithEnumRequestDefinitions({ requestArgs: { path: { enumParam }, query: { category } } })
    case EstimatesGARs.basePath: return EstimatesGARs.getEnumWithEnumRequestDefinitions({ requestArgs: { path: { enumParam }, query: { category } } })
    case LocanGARs.basePath: return LocanGARs.getEnumWithEnumRequestDefinitions({ requestArgs: { path: { enumParam }, query: { category } } })
    default:
      console.error(`No enum request found with basePath ${basePath}`)
      return null
  }
}

export function runGARs(requestDefinitions: TVDGARConfigs): any {
  return Object.keys(requestDefinitions).map((requestDefinitionKey: string) => runGAR(requestDefinitions[requestDefinitionKey]))
}

type TVDRunAllGARsOptions = {|
  onErrorCb?: (Error) => void, // optional error callback handler
|}

export function runAllGARs(GARs: Array<Promise<any>>, options?: TVDRunAllGARsOptions): Promise<any> {
  return Axios.all(GARs).catch((error: Error) => {
    const onErrorCb = options?.onErrorCb
    if (error && onErrorCb) {
      onErrorCb(error)
    }
  })
}

export const includesGARFnName = (basePath: string, fnName: string): boolean => {
  const GARs = allGARs.getAll()[basePath]
  if (!GARs) {
    console.error(`Could not find GARs with basePath of ${basePath}`)
    return false
  }
  return !!GARs[fnName]
}
