// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.

import axios from 'axios'
import { TVD_HEADER_ACCOUNT_ID } from '../../../constants/apiConstants'
import {
  API_BASE_URL,
  ALLOW_WITH_CREDENTIALS
} from '../../../constants/envConstants'
import { getDefaultRequestHeaders } from './requestUtils'

const executeMutation = async (endpoint: string, body: Object, params: Object) => {
  const headers = getDefaultRequestHeaders()
  await axios.post(`${API_BASE_URL}/real-estate-api/${endpoint}`, body, {
    headers: {
      ...headers
    },
    params: {
      accountId: headers[TVD_HEADER_ACCOUNT_ID],
      ...params
    },
    ...(ALLOW_WITH_CREDENTIALS ? { withCredentials: true } : {})
  })
}

const executeDeleteMutation = async (endpoint: string, params: Object) => {
  const headers = getDefaultRequestHeaders()
  await axios.delete(`${API_BASE_URL}/real-estate-api/${endpoint}`, {
    headers: {
      ...headers
    },
    params: {
      accountId: headers[TVD_HEADER_ACCOUNT_ID],
      ...params
    },
    ...(ALLOW_WITH_CREDENTIALS ? { withCredentials: true } : {})
  })
}

const executePatchMutation = async (endpoint: string, body: Object, params: Object) => {
  const headers = getDefaultRequestHeaders()
  await axios.patch(`${API_BASE_URL}/real-estate-api/${endpoint}`, body, {
    headers: {
      ...headers
    },
    params: {
      accountId: headers[TVD_HEADER_ACCOUNT_ID],
      ...params
    },
    ...(ALLOW_WITH_CREDENTIALS ? { withCredentials: true } : {})
  })
}

const executePutMutation = async (endpoint: string, body: Object, params: Object) => {
  const headers = getDefaultRequestHeaders()
  await axios({
    baseURL: `${API_BASE_URL}/real-estate-api`,
    url: endpoint,
    method: 'PUT',
    headers: {
      ...headers,
      'Content-Type': 'application/json'
    },
    data: JSON.stringify(body),
    params: {
      accountId: headers[TVD_HEADER_ACCOUNT_ID],
      ...params
    },
    ...(ALLOW_WITH_CREDENTIALS ? { withCredentials: true } : {})
  })
}


export const postRealEstate = async (realEstate: Object) => {
  const mutation = await executeMutation('real-estates', realEstate)
  return mutation
}

export const deleteRealEstate = async (realEstateId: string) => {
  const mutation = await executeDeleteMutation(`real-estates/${realEstateId}`)
  return mutation
}

export const renameRealEstate = async (realEstateId: string, Description: Object) => {
  const mutation = await executePatchMutation(`real-estates/${realEstateId}`, { Description })
  return mutation
}

export const putPrimarySpacesEstimate = async (buildingId: string, estimateId: string) => {
  const mutation = await executePutMutation(`buildings/${buildingId}/primary-spaces-estimate/id`, estimateId)
  return mutation
}

export const putPrimaryLocanEstimate = async (siteId: string, estimateId: Object) => {
  const mutation = await executePutMutation(`sites/${siteId}/primary-locan-estimate/id`, estimateId)
  return mutation
}

export const putPrimaryWopEstimate = async (buildingId: string, estimateId: Object) => {
  const mutation = await executePutMutation(`buildings/${buildingId}/primary-abe-estimate/id`, estimateId)
  return mutation
}

export const putPrimaryBuildingElementsEstimate = async (buildingId: string, estimateId: Object) => {
  const mutation = await executePutMutation(`buildings/${buildingId}/primary-building-elements-estimate/id`, estimateId)
  return mutation
}

export const putPrimaryProfitabilityEstimate = async (buildingId: string, estimateId: Object) => {
  const mutation = await executePutMutation(`buildings/${buildingId}/primary-profitability-estimate/id`, estimateId)
  return mutation
}

export const putPrimaryProcurementElementsEstimate = async (buildingId: string, estimateId: Object) => {
  const mutation = await executePutMutation(`buildings/${buildingId}/primary-procurement-estimate/id`, estimateId)
  return mutation
}

export const putPrimaryRenovationProgramEstimate = async (buildingId: string, estimateId: Object) => {
  const mutation = await executePutMutation(`buildings/${buildingId}/primary-renovation-program-estimate/id`, estimateId)
  return mutation
}
export const putPrimaryUsageMaintenanceEstimate = async (buildingId: string, estimateId: Object) => {
  const mutation = await executePutMutation(`buildings/${buildingId}/primary-usage-maintenance-estimate/id`, estimateId)
  return mutation
}
