// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.
// @flow
import React, { PureComponent } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { colors } from 'frontend-assets'
import { ListItem, ListItemText, Icon } from '@material-ui/core'
import { type ColumnStates } from './RelationalColumn'
import theme from '../../../../styles/theme'
// $FlowFixMe
import { ReactComponent as SVGIconOtherClose } from '../../../../../node_modules/frontend-assets/static/assets/images/icons/Other Close.svg'

const getColumnButtonStyles = (background: string, backgroundHover: string, borderColor: string, borderSize: number) => ({
  border: `${borderSize}px solid ${borderColor}`,
  borderTop: 'none',
  backgroundColor: background,
  '&:focus': {
    backgroundColor: background
  },
  '&:hover': {
    backgroundColor: backgroundHover
  },
  '&:first-child': {
    borderTop: `${borderSize}px solid ${borderColor}`
  },
})

const styles = (): Object => ({
  cell: {
    paddingLeft: '10px',
    paddingRight: '10px',
    minHeight: '44px',
    maxHeight: '44px',
    color: colors.dark80,
  },
  basic: {
    ...getColumnButtonStyles(colors.white, colors.gray20, colors.gray80, 1)
  },
  active: {
    ...getColumnButtonStyles(colors.primary10, colors.primary20, colors.primary100, 1),
    '&:hover': {
      color: colors.dark80,
      backgroundColor: colors.primary20
    }
  },
  focused: {
    ...getColumnButtonStyles(colors.primary10, colors.primary20, colors.primary40, 2),
    marginRight: '6px',
    '&:hover': {
      color: colors.dark80,
      backgroundColor: colors.primary20
    }
  },
  focusing: {
    ...getColumnButtonStyles(colors.white, colors.gray20, colors.gray80, 1),
  },
  text: {
    ...theme.typography.classes.bodyBig,
    color: colors.dark80
  },
  black: {
    color: 'inherit'
  },
  closeIcon: {
    width: 16,
    '& path': {
      fill: colors.primary80
    },
    '&:hover': {
      '& path': {
        fill: colors.primary100
      }
    }
  }
})

export type RelationalItemProps = {|
  value: string, // unique id for the button
  localizedName: string, // the text in the button
  onClick: (cellId: string, cellLabel: string) => void, // function for when the button is clicked
  onCellClose: () => void, // function for when the closing button is clicked
  mode: ColumnStates, // current item mode - corresponds to column modes
  selected: boolean, // whether this item is in user selected mode (= will render a closing X - icon)
|}

type Props = {|
  ...RelationalItemProps,
  classes: Object, // styles object
  disabled: boolean, // flag to disable cell events
  className?: string, // optional className to add styles to the list item
|}

export class RelationalItem extends PureComponent<Props> {
  static defaultProps = {
    disabled: false,
    selected: false,
    mode: 'basic',
    onCellClick: () => {},
    onCellCloseClick: () => {},
  }

  get closeIcon(): React$Element<typeof Icon> | null {
    const { localizedName, classes } = this.props
    return (
      <SVGIconOtherClose
        data-testid={`cell-${localizedName}-close`}
        className={classes.closeIcon}
        onClick={this.handleOnCellClose} />
    )
  }

  handleClick = () => {
    if (this.props.disabled) return
    if (this.props.mode === 'active') return
    this.props.onClick(this.props.value, this.props.localizedName)
  }

  handleOnCellClose = (event: SyntheticEvent<any>) => {
    if (this.props.disabled) return
    event.stopPropagation()
    this.props.onCellClose()
  }

  render(): React$Element<any> {
    const {
      classes,
      localizedName,
      disabled,
      selected,
      mode,
      className
    } = this.props
    const style = `${classes.cell} ${classes[mode]} ${className || ''}`
    const textStyles = `${classes.text} ${classes.black}`

    return (
      <ListItem
        button
        disabled={disabled}
        className={style}
        key={`cell-${localizedName}`}
        data-testid={`cell-${localizedName}`}
        onClick={this.handleClick}
        disableRipple>
        <ListItemText disableTypography primary={localizedName} classes={{ root: textStyles }}>
          {`${this.props.value} ${this.props.localizedName}`}
        </ListItemText>
        { selected ? this.closeIcon : null }
      </ListItem>
    )
  }
}

export default withStyles(styles)(RelationalItem)
