// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.
import { SET_MFE_STATES, RESET_MFE_STATES } from '../actions/MFEStates'

const initialState: TVDMFEStatesStore = {}

export default function MFEStatesReducer(state: TVDMFEStatesStore = initialState, action: TVDAction): TVDMFEStatesStore {
  switch (action.type) {
    case SET_MFE_STATES: {
      const {
        payload: {
          mfeName,
          isOpen,
          componentName,
          contentProps
        }
      } = action
      return {
        ...state,
        [mfeName]: {
          isOpen,
          componentName,
          contentProps
        }
      }
    }

    case RESET_MFE_STATES: return {}

    default: return state
  }
}
