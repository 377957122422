// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.

import moment from 'moment'
import isAlphanumeric from 'validator/lib/isAlphanumeric'
import isNumeric from 'validator/lib/isNumeric'
import blacklist from 'validator/lib/blacklist'
import isDecimal from 'validator/lib/isDecimal'
import isEmail from 'validator/lib/isEmail'
import isMobilePhone from 'validator/lib/isMobilePhone'
import toDate from 'validator/lib/toDate'
import { round } from 'lodash'
import { ELEMENTS, SPACES, WOP, spacesUrl, elementsUrl, wopUrl, LOCAN } from '../constants/moduleConstants'

export function isEnum(type: string): boolean {
  return type === 'enum'
}

export function isObject(variable: any): boolean {
  return typeof variable === 'object' && !Array.isArray(variable) && variable !== null
}

export function formattedDate(unFormattedDate: string, locale: string = 'fi'): string {
  const localeFormats = {
    fi: 'DD.MM.YYYY',
  }
  return moment(unFormattedDate).format(localeFormats[locale])
}

export function formattedTime(unFormattedTime: string, locale: string = 'fi'): string {
  const localeFormats = {
    fi: 'hh.mm',
  }
  return moment(unFormattedTime).format(localeFormats[locale])
}

// in some MUI components, number 0 is not rendered, unless it's converted into string
export function renderZero(value: number | string): string | number {
  if (value === 0) return '0'
  return value
}

export function elementTypeByApp(app: string = ''): string {
  switch (app) {
    case ELEMENTS:
      return 'buildingElements'
    case SPACES:
      return 'space'
    case WOP:
      return 'wop'
    case LOCAN:
      return 'locan'
    default:
      return ''
  }
}

export function appUrlByType(app?: string): string {
  switch (app) {
    case ELEMENTS:
      return elementsUrl
    case SPACES:
      return spacesUrl
    case WOP:
      return wopUrl
    default:
      return ''
  }
}

export function appTypeByName(name: string): string {
  switch (name) {
    case 'tilat':
      return SPACES
    case 'rakennusosat':
      return ELEMENTS
    case 'wop':
      return WOP
    case 'locan':
      return LOCAN
    default:
      return name
  }
}

export const romanize = (num: number): string | typeof NaN => {
  if (isNaN(num)) {
    return NaN
  }
  const digits = String(+num).split('')
  const key = ['', 'C', 'CC', 'CCC', 'CD', 'D', 'DC', 'DCC', 'DCCC', 'CM',
    '', 'X', 'XX', 'XXX', 'XL', 'L', 'LX', 'LXX', 'LXXX', 'XC',
    '', 'I', 'II', 'III', 'IV', 'V', 'VI', 'VII', 'VIII', 'IX']
  let roman = ''
  let i = 3
  while (i) {
    i -= 1
    roman = (key[+digits.pop() + (i * 10)] || '') + roman
  }
  return Array(+digits.join('') + 1).join('M') + roman
}

export function getLocalStorage(name: string): string | null | void {
  return localStorage.getItem(name)
}

export function setLocalStorage(name: string, value: string) {
  localStorage.setItem(name, value)
}

/** Formats commas out of a string and turns them into dots.
 *
 * @param {string} value the string to have its commas changed to dots
 * @return {string} The value with commas changed to dots.
 */
export function commasToDots(value: number | string): string {
  switch (typeof value) {
    case 'number':
      return value.toString().replace(',', '.')
    case 'string':
      return value.replace(',', '.')
    default:
      return value
  }
}

/** Formats dots into commas in the given string or number.
 * @param {string | number} value The value to have its dots changed to commas.
 * @return {string} The value as a string with dots changed to commas.
 */
export function dotsToCommas(value: string | number): string {
  switch (typeof value) {
    case 'number':
      return value.toString().replace('.', ',')
    case 'string':
      return value.replace('.', ',')
    default:
      return value
  }
}

/**
 * Rounds the value to the precision of the given digits.
 * @param {number | undefined | null} value The value to be rounded.
 * @param {number} digits Amount of digits in the result.
 */
export function roundToPrecision(value: number, digits: number): number {
  return value ? round(value, digits) : value
}

export function validateUserInput(
  dataType: string,
  value: string | number,
  blacklistChars: Array<string>,
  locale: Array<string>,
  required: boolean
): boolean {
  switch (dataType) {
    case 'text':
    case 'name':
    case 'string':
      if (!value && required) return false // return false if value is empty and required
      if (!value && !required) return true // return true if value is empty and optional
      // return true if value is received but empty after blackListChars (ignored characters) are filtered from it
      // this means that value is symbolic, $,€, % etc..
      if (dataType === 'name' && value && (blacklist(value, [...blacklistChars, '-']))) return true
      if (value && (blacklist(value, blacklistChars) === '')) return true
      return isAlphanumeric(blacklist(value, blacklistChars), locale)
    case 'number':
    case 'double':
      if (isNaN(value) && required) return false
      return isDecimal(commasToDots(value))
    case 'int':
    case 'integer':
      if (!value && !required) return true
      if (isNaN(value) && required) return false
      return isDecimal(value.toString(), { no_symbols: true, decimal_digits: '0' })
    case 'date':
      return Boolean(toDate(value))
    case 'year':
      if (isNaN(value) && required) return false
      return Boolean(isNumeric(value.toString(), { no_symbols: true }) && (value.toString().length <= 4))
    case 'email':
      return isEmail(value)
    case 'postalcode':
      if (value === '' && !required) return true
      return isNumeric(value.toString(), { no_symbols: true })
    case 'phone':
      return isMobilePhone(value.toString().replace(/-/g, ''))
    default:
      return false
  }
}

export function validateUserInputNoNegatives(data: Object): boolean {
  const {
    dataType,
    value,
    ignoreCharacters: blacklistChars,
    locale,
    required
  } = data
  switch (dataType) {
    case 'text':
      return true
    case 'name':
      return true
    case 'string':
      if (!value && required) return false // return false if value is empty and required
      if (!value && !required) return true // return true if value is empty and optional
      // return true if value is received but empty after blackListChars (ignored characters) are filtered from it
      // this means that value is symbolic, $,€, % etc..
      if (dataType === 'name' && value && (blacklist(value, [...blacklistChars, '-']))) return true
      if (value && (blacklist(value, blacklistChars) === '')) return true
      return isAlphanumeric(blacklist(value, blacklistChars), locale)
    case 'number':
      if (isNaN(value) && required) return false
      if (Number(commasToDots(value)) < 0) return false
      return isDecimal(commasToDots(value))
    case 'double':
      if (isNaN(value) && required) return false
      if (Number(commasToDots(value)) < 0) return false
      return isDecimal(commasToDots(value))
    case 'int':
      return true
    case 'integer':
      if (Number(value) < 0) return false
      if (!value && !required) return true
      if (isNaN(value) && required) return false
      return isDecimal(value.toString(), { no_symbols: true, decimal_digits: '0' })
    case 'date':
      return Boolean(toDate(value))
    case 'year':
      if (isNaN(value) && required) return false
      return Boolean(isNumeric(value.toString(), { no_symbols: true }) && (value.toString().length <= 4))
    case 'email':
      return isEmail(value)
    case 'postalcode':
      if (value === '' && !required) return true
      return isNumeric(value.toString(), { no_symbols: true })
    case 'phone':
      return isMobilePhone(value.toString().replace(/-/g, ''))
    default:
      return false
  }
}

export const sum = (array: Array<number>): number => array.reduce((result: number, number: number): number => result + number, 0)

export const getNextArrayItem = (array: Array<string>, item: any) => {
  if (item === array[array.length - 1]) console.error(`getNextArrayItem: Warning, given 2nd argument "${item}" is the last item in the array.`)
  return array[array.indexOf(item) + 1]
}

export const markdownConcatStringsWithBlankLine = (array: Array<string>): string => array.join('  \n` `  \n')
export const markdownConcatStrings = (array: Array<string>): string => array.join('  \n` ` ')

export const removeHighlight = (rowRef?: TVDRef, highlightedRow?: string) => {
  if (!rowRef) return
  if (rowRef.current) {
    rowRef.current.classList.remove(highlightedRow)
  }
}

export const showNDecimals = (value: number, numberOfDecimals: number, forceZero?: boolean): string => {
  const newValueStr = value.toString()
  const dotIndexOf = newValueStr.indexOf('.')
  const newValueWithDecimals = dotIndexOf !== -1 ? newValueStr.slice(0, (newValueStr.indexOf('.')) + (numberOfDecimals + 1)) : newValueStr
  if (forceZero && newValueWithDecimals.indexOf('.') === -1) {
    return `${newValueWithDecimals}.0`
  }
  return newValueWithDecimals
}

export const firstLetterLowerCase = (str: string): string => {
  if (!str) return str
  return str.charAt(0).toLowerCase() + str.slice(1)
}

