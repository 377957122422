// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.
import React from 'react'
import { findKey, filter } from 'lodash'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core'
import { withTranslation } from 'react-i18next'

import HamburgerMenu from '../../../common/menus/HamburgerMenu/HamburgerMenu'
import ErrorBoundary from '../../../common/ErrorBoundary/ErrorBoundary'
import HierarchicalListContainer from '../../HierarchicalListContainer/HierarchicalListContainer'
import Spinner from '../../../common/Spinner/Spinner'
import DescriptionCell from '../../../common/lists/common/DescriptionCell/DescriptionCell'
import SentientHOC from '../../../hocs/SentientHOC/SentientHOC'

import { AREAM2PERPC, QUANTITYPCS } from '../../../../constants/attributes'
import { SPACES } from '../../../../constants/moduleConstants'
import {
  SPACE,
  SPACEGROUP,
  FUNCTIONALSECTOR,
  FUNCTION,
  MULTI_EDIT_SPACE_GROUP_WIDGET,
  MULTI_EDIT_SPACE_WIDGET
} from '../../../../constants/contentTypes'
import { copyItem, undoModifyListItem } from '../../../../actions/list'
import { openContentWidget } from '../../../../actions/widgets'
import { postPolling } from '../../../../actions/postPolling'
import {
  buildRenameModal, buildDeleteModal, buildCreateModal, buildMoveModal
} from '../../../../actions/modals'
import { removeHighlight } from '../../../../utils/commonUtils'
import {
  patchSpaceScheduleSpaceGroupsWithIdRequest,
  patchSpaceScheduleSpacesWithIdRequest,
  getSpaceScheduleWithEstimateIdRequestDefinitions,
  getSpaceScheduleColumnsWithEstimateIdRequestDefinitions,
} from '../../../../utils/generated-api-requests/spaces'

const styles = ({ palette }: TVDTheme) => ({
  listContainer: {
    flex: 1,
    height: 0
  },
  descriptionRowContainer: {
    alignItems: 'center',
    color: palette.nevada,
    cursor: 'default',
    display: 'flex',
    justifyContent: 'space-between',
    marginRight: 2,
    outline: 'none',
    width: '100%',
  },
  iconsContainer: {
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center'
  },
  disabled: {
    color: palette.ui06
  },
})

type HOCProps = {|
  t: Function, // i18n translate function
  classes: Object, // withStyles styles prop
  sentient: TVDSentient, // Object providing helpers via SentientHOC
|}

type DispatchProps = {|
  dispatchOpenContentWidget: (TVDOpenContentWidgetArguments) => void, // Open widget function
  dispatchBuildRenameModal: Function, // open rename list item modal
  dispatchCopyItem: Function, // copy list item
  dispatchBuildDeleteModal: Function, // open delete list item modal
  dispatchBuildCreateModal: Function, // open create list item modal
  dispatchBuildMoveModal: Function, // open move list item modal
  dispatchUndoModifyListItem: (TVDListItem) => void, // used to revert back to the old value if patching fails
  dispatchPostPolling: () => void // function to trigger postpolling
|}

type MappedProps = {|
  spacesListId: string, // the unique id for the main Spaces list component
  activeEdit: boolean, // boolean to tell if global edit mode in the app is active
  activeCalculation?: boolean, // flag that indicates if calculation is running
  list: Object,
  isEstimateLockedToCurrentUser: $PropertyType<TVDApplicationStore, 'isEstimateLockedToCurrentUser'>, // if estimate is locked to current user
  application: string, // current application
  isEstimateFrozen: $PropertyType<TVDApplicationStore, 'isEstimateFrozen'>, // if estimate is frozen
  // spacesEstimateType and spacesResultView are required in sentientConfig
  spacesEstimateType: $PropertyType<TVDApplicationStore, 'spacesEstimateType'>, // spaces estimate type
  spacesResultView: $PropertyType<TVDApplicationStore, 'spacesResultView'> // spaces result view

|}

type ReceivedProps = {|
  // listId is only used in mapDispatchToProps - this might need to be refactored to use either just the prop or the id from store
  // eslint-disable-next-line react/no-unused-prop-types
  listId: string, // a list id used for connecting to store
|}

type Props = {|
  ...HOCProps,
  ...DispatchProps,
  ...MappedProps,
  ...ReceivedProps
|}

export class SpacesListContainer extends React.Component<Props> {
  static defaultProps = {
    isEstimateLockedToCurrentUser: false,
    activeEdit: false,
  }

  showContextMenu = (row: Object) => {
    const { spacesListId, list } = this.props

    const listHasSelectedRows = findKey(list[spacesListId].listItems, 'selected')
    const rowIsNotSelected = (typeof row.selected === 'undefined' || row.selected === false)
    const _showContextMenu = !((rowIsNotSelected && listHasSelectedRows) || row.type?.toLowerCase() === 'nonloadbearingstructure')
    return _showContextMenu
  }

  getContextMenuItems(row: Object, rowRef?: TVDRef, highlightedRow?: string): Array<Object> {
    if (!this.showContextMenu(row)) return []
    const {
      t,
      list,
      spacesListId,
      dispatchBuildRenameModal,
      dispatchCopyItem,
      dispatchBuildDeleteModal,
      dispatchBuildCreateModal,
      dispatchBuildMoveModal,
      dispatchOpenContentWidget,
      application
    } = this.props

    const rowTypeUpperCase = row.type.toUpperCase()

    const getSelectedListItemsByType = (type: string) => {
      const { listItems } = list[spacesListId]
      return filter(listItems, (listItem: Object) => listItem.selected && listItem.type.toUpperCase() === type)
    }

    const multipleFunctionalSectorsSelected = getSelectedListItemsByType(FUNCTIONALSECTOR).length > 1
    const multipleFunctionsSelected = getSelectedListItemsByType(FUNCTION).length > 1
    const multipleSpaceGroupsSelected = getSelectedListItemsByType(SPACEGROUP).length > 1
    const multipleSpacesSelected = getSelectedListItemsByType(SPACE).length > 1
    const multipleSelected = multipleFunctionalSectorsSelected || multipleSpacesSelected || multipleSpaceGroupsSelected || multipleFunctionsSelected

    const deleteMultipleMenuItem = {
      localizedName: t('buttons._DELETE_MULTIPLE_'),
      onClick: () => {
        dispatchBuildDeleteModal(row.id, spacesListId, application)
        removeHighlight(rowRef, highlightedRow)
      },
      testId: 'ContextMenuItem-delete-multiple'
    }

    const multipleSelectedActions = {
      [FUNCTIONALSECTOR]: [
        deleteMultipleMenuItem
      ],
      [FUNCTION]: [
        deleteMultipleMenuItem,
        {
          localizedName: t('buttons._MOVE_FUNCTIONS_'),
          onClick: () => {
            dispatchBuildMoveModal(row, spacesListId)
            removeHighlight(rowRef, highlightedRow)
          },
          testId: 'ContextMenuItem-move-functions'
        }
      ],
      [SPACEGROUP]: [
        deleteMultipleMenuItem,
        {
          localizedName: t('buttons._EDIT_SPACE_GROUPS_'),
          onClick: () => {
            dispatchOpenContentWidget({
              widgetId: MULTI_EDIT_SPACE_GROUP_WIDGET,
              widgetType: MULTI_EDIT_SPACE_GROUP_WIDGET,
              contentProps: {
                resourceId: row.id,
                listStoreId: spacesListId,
                propertiesStoreId: spacesListId,
              }
            })
            removeHighlight(rowRef, highlightedRow)
          },
          testId: 'ContextMenuItem-edit-spacegroups'
        },
        {
          localizedName: t('buttons._MOVE_SPACE_GROUPS_'),
          onClick: () => {
            dispatchBuildMoveModal(row, spacesListId)
            removeHighlight(rowRef, highlightedRow)
          },
          testId: 'ContextMenuItem-move-spacegroups'
        }
      ],
      [SPACE]: [
        deleteMultipleMenuItem,
        {
          localizedName: t('buttons._EDIT_SPACES_'),
          onClick: () => {
            dispatchOpenContentWidget({
              widgetId: MULTI_EDIT_SPACE_WIDGET,
              widgetType: MULTI_EDIT_SPACE_WIDGET,
              contentProps: {
                resourceId: row.id,
                listStoreId: spacesListId,
                propertiesStoreId: spacesListId,
              },
            })
            removeHighlight(rowRef, highlightedRow)
          },
          testId: 'ContextMenuItem-edit-spaces'
        },
        {
          localizedName: t('buttons._MOVE_SPACES_'),
          onClick: () => {
            dispatchBuildMoveModal(row, spacesListId)
            removeHighlight(rowRef, highlightedRow)
          },
          testId: 'ContextMenuItem-move-spaces'
        }]
    }
    const basicMenuItems = [{
      localizedName: t('buttons._RENAME_'),
      onClick: () => {
        dispatchBuildRenameModal({ row, listId: spacesListId })
        removeHighlight(rowRef, highlightedRow)
      },
      testId: 'ContextMenuItem-rename'
    }, {
      localizedName: t('buttons._COPY_'),
      onClick: () => {
        dispatchCopyItem(row)
        removeHighlight(rowRef, highlightedRow)
      },
      testId: 'ContextMenuItem-copy'
    }, {
      localizedName: t('buttons._DELETE_'),
      onClick: () => {
        dispatchBuildDeleteModal(row.id, spacesListId, application)
        removeHighlight(rowRef, highlightedRow)
      },
      testId: 'ContextMenuItem-delete'
    }, {
      localizedName: t('buttons._MOVE_'),
      onClick: () => {
        dispatchBuildMoveModal(row, spacesListId)
        removeHighlight(rowRef, highlightedRow)
      },
      testId: 'ContextMenuItem-move'
    }]
    if (rowTypeUpperCase === FUNCTIONALSECTOR || multipleSelected) basicMenuItems.pop()

    const singleSelectedActions = {
      [FUNCTIONALSECTOR]: [
        ...basicMenuItems,
        {
          localizedName: `${t('buttons._CREATE_')} ${t('widgets._FUNCTION_').toLowerCase()}`,
          onClick: () => {
            dispatchBuildCreateModal('function', row.id, spacesListId)
            removeHighlight(rowRef, highlightedRow)
          },
          testId: 'ContextMenuItem-create-function'
        },
        {
          localizedName: `${t('buttons._CREATE_')} ${t('widgets._FUNCTIONALSECTOR_').toLowerCase()}`,
          onClick: () => {
            dispatchBuildCreateModal(row.type, row.id, spacesListId)
            removeHighlight(rowRef, highlightedRow)
          },
          testId: 'ContextMenuItem-create-functionalsector'
        },
      ],
      [FUNCTION]: [
        ...basicMenuItems,
        {
          localizedName: `${t('buttons._CREATE_')} ${t('widgets._SPACEGROUP_').toLowerCase()}`,
          onClick: () => {
            dispatchBuildCreateModal('spacegroup', row.id, spacesListId)
            removeHighlight(rowRef, highlightedRow)
          },
          testId: 'ContextMenuItem-create-spacegroup'
        }
      ],
      [SPACEGROUP]: [
        ...basicMenuItems,
      ],
      [SPACE]: [
        ...basicMenuItems
      ]
    }
    switch (row.type.toUpperCase()) {
      case FUNCTIONALSECTOR: return multipleSelected ? multipleSelectedActions[rowTypeUpperCase] : singleSelectedActions[rowTypeUpperCase]
      case FUNCTION: return multipleSelected ? multipleSelectedActions[rowTypeUpperCase] : singleSelectedActions[rowTypeUpperCase]
      case SPACEGROUP: return multipleSelected ? multipleSelectedActions[rowTypeUpperCase] : singleSelectedActions[rowTypeUpperCase]
      case SPACE: return multipleSelected ? multipleSelectedActions[rowTypeUpperCase] : singleSelectedActions[rowTypeUpperCase]
      default: return []
    }
  }

  getIconsContainer(row: Object, rowRef: TVDRef, highlightedRow: string): React$Element<'div'> {
    const {
      classes,
      isEstimateLockedToCurrentUser,
      isEstimateFrozen
    } = this.props
    const showContextMenu = this.showContextMenu(row)
    return (
      <div className={classes.iconsContainer} data-visible_on_hover>
        {
          (showContextMenu && isEstimateLockedToCurrentUser && !isEstimateFrozen) &&
          <HamburgerMenu
            id='spacesList'
            items={this.getContextMenuItems(row, rowRef, highlightedRow)}
            onToggleChange={() => rowRef.current && rowRef.current.classList.add(highlightedRow)}
            onToggleClose={() => rowRef.current && rowRef.current.classList.remove(highlightedRow)} />
        }
      </div>
    )
  }

  getDescriptionRowContent(content: string, rowIndex: number, row: Object, rowRef: TVDRef, highlightedRow: string): React$Element<any> {
    const {
      classes,
      dispatchOpenContentWidget,
      activeEdit
    } = this.props
    const rowType = row.type.toUpperCase()

    switch (rowType) {
      case SPACE:
      case SPACEGROUP: {
        return (
          <div
            data-testid='listRowCell-Description-text-Space-Spacegroup'
            className={activeEdit ? `${classes.descriptionRowContainer} ${classes.disabled}` : `${classes.descriptionRowContainer}`}
            role='button'
            tabIndex={0}
            onClick={(event: Object) => {
              dispatchOpenContentWidget({
                widgetId: row.id,
                widgetType: rowType,
                widgetTitle: row.columnData.Description,
                widgetResultBarFormatOptions: { disableRounding: true },
                contentProps: {
                  spaceId: row.id,
                  propertiesStoreId: `${row.id}-properties`,
                  ...(rowType === SPACE ? {
                    equipmentsListStoreId: `${row.id}-equipments-list`,
                    surfacesListStoreId: `${row.id}-surfaces-list`
                  } : {}),
                }
              })
              event.stopPropagation()
            }}>
            <DescriptionCell text={content} highlighted />
            {this.getIconsContainer(row, rowRef, highlightedRow)}
          </div>
        )
      }
      default: {
        return (
          <div data-testid='listRowCell-Description-text' className={classes.descriptionRowContainer}>
            <DescriptionCell text={content} />
            {this.getIconsContainer(row, rowRef, highlightedRow)}
          </div>
        )
      }
    }
  }

  list(): React$Element<any> {
    const {
      spacesListId,
      dispatchPostPolling,
      activeEdit,
      activeCalculation,
      isEstimateLockedToCurrentUser,
      sentient,
      dispatchUndoModifyListItem,
      isEstimateFrozen
    } = this.props

    return (
      <ErrorBoundary>
        {activeCalculation && <Spinner />}
        <HierarchicalListContainer
          isEstimateFrozen={isEstimateFrozen}
          contextMenuItems={(row: Object) => this.getContextMenuItems(row)}
          checkboxDisabled={activeEdit || isEstimateFrozen || !isEstimateLockedToCurrentUser}
          displayCheckBoxes
          listId={spacesListId}
          listType={SPACES}
          disabled={activeEdit}
          isEstimateLockedToCurrentUser={isEstimateLockedToCurrentUser}
          editableColumns={[QUANTITYPCS, AREAM2PERPC]}
          testId='spacesList'
          didMountCallback={() => {
            sentient.runSetup({
              getSpaceScheduleWithEstimateIdRequestDefinitions: {
                requestArgs: {
                  query: {
                    listType: 'flat'
                  }
                }
              }
            })
          }}
          onModifiedChange={(modifiedListItem: Object) => {
            if (modifiedListItem) {
              switch (modifiedListItem.type.toUpperCase()) {
                case SPACEGROUP: {
                  patchSpaceScheduleSpaceGroupsWithIdRequest(
                    { path: { id: modifiedListItem.id }, body: modifiedListItem.modifiedColumnData, },
                    { listId: spacesListId, modifiedListItem },
                    dispatchPostPolling,
                    () => dispatchUndoModifyListItem(modifiedListItem)
                  )
                  break
                }
                case SPACE: {
                  patchSpaceScheduleSpacesWithIdRequest(
                    { path: { id: modifiedListItem.id }, body: modifiedListItem.modifiedColumnData, },
                    { listId: spacesListId, modifiedListItem },
                    dispatchPostPolling,
                    () => dispatchUndoModifyListItem(modifiedListItem)
                  )
                  break
                  }
                default:
                  console.warn(`No PATCH request for type of "${modifiedListItem.type}"`)
                  break
              }
            }
          }}
          wrappedCellContents={{
            Description: ({
              content, rowIndex, row, rowRef, highlightedRow
            }: Object) => this.getDescriptionRowContent(content, rowIndex, row, rowRef, highlightedRow),
          }} />
      </ErrorBoundary>
    )
  }

  render(): React$Element<'div'> {
    const { classes } = this.props
    return (
      <div className={classes.listContainer}>
        {this.list()}
      </div>
    )
  }
}

const mapStateToProps = ({ app, list }: TVDReduxStore): MappedProps => {
  const {
    spacesListId,
    activeEdit,
    activeCalculation,
    isEstimateLockedToCurrentUser,
    application,
    isEstimateFrozen,
    spacesResultView,
    spacesEstimateType
  } = app
  return {
    spacesListId,
    activeEdit,
    activeCalculation,
    list,
    isEstimateLockedToCurrentUser,
    application,
    isEstimateFrozen,
    spacesResultView,
    spacesEstimateType
  }
}

function mapDispatchToProps(dispatch: Function, { listId: spacesListId }: ReceivedProps): DispatchProps {
  return {
    dispatchOpenContentWidget: (openContentWidgetArgs: TVDOpenContentWidgetArguments) => { dispatch(openContentWidget(openContentWidgetArgs)) },
    dispatchBuildRenameModal: ({ row, listId }: Object) => { dispatch(buildRenameModal({ row, listId })) },
    dispatchCopyItem: (row: Object) => { dispatch(copyItem(row, spacesListId)) },
    dispatchBuildDeleteModal: (rowId: string, listId: string, application: string) => { dispatch(buildDeleteModal(rowId, listId, application)) },
    dispatchBuildCreateModal: (rowType: string, rowId: string, listId: string) => { dispatch(buildCreateModal(rowType, rowId, listId)) },
    dispatchBuildMoveModal: (row: Object, listId: string, multiple: boolean = false) => { dispatch(buildMoveModal({ row, listId, multiple })) },
    dispatchPostPolling: () => { dispatch(postPolling()) },
    dispatchUndoModifyListItem: (row: TVDListItem) => { dispatch(undoModifyListItem({ listId: spacesListId, listItem: row })) },
  }
}


const sentientConfig: TVDSentientConfig = {
  updateOnPropsChange: ({ spacesResultView, spacesEstimateType }: Props) => ({ spacesResultView, spacesEstimateType }),
  getSetupRequestDefinitions: (store: Object, { spacesListId }: Props): TVDGARConfigs =>
    ({
      getSpaceScheduleWithEstimateIdRequestDefinitions: getSpaceScheduleWithEstimateIdRequestDefinitions({
        requestArgs: { query: { listType: 'flat' } },
        payload: { listStoreId: spacesListId },
      }),
      getSpaceScheduleColumnsWithEstimateIdRequestDefinitions: getSpaceScheduleColumnsWithEstimateIdRequestDefinitions({
        payload: { listStoreId: spacesListId }
      })
    })
}

export default withTranslation('translations')((
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SentientHOC(SpacesListContainer, sentientConfig)))
))
