// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.
/* eslint max-len: ["error", { "code": 350 }] */
/*
  Haahtela Usage Maintenance Api 1.2.0
  Usage Maintenance services for Haahtela Api
  undefined
*/

import requestFn from './../requests/request'
import { API_BASE_URL } from './../../constants/envConstants'
import parserMapper from './../parserMapper'
import { getState } from '../../store'

export const basePath = '/usage-maintenance-api'

export const DATA_GET_ESTIMATE_WITH_ESTIMATEID_START = 'DATA_GET_ESTIMATE_WITH_ESTIMATEID_START'
export const DATA_GET_ESTIMATE_WITH_ESTIMATEID_SUCCESSFUL = 'DATA_GET_ESTIMATE_WITH_ESTIMATEID_SUCCESSFUL'
export const DATA_GET_ESTIMATE_WITH_ESTIMATEID_FAIL = 'DATA_GET_ESTIMATE_WITH_ESTIMATEID_FAIL'

// Returns a renovation program estimate.
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/usage-maintenance.yaml#/Estimate/get_estimate__estimateId_
export const getEstimateWithEstimateIdRequest = (
  payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_ESTIMATE_WITH_ESTIMATEID_START',
      successful: 'DATA_GET_ESTIMATE_WITH_ESTIMATEID_SUCCESSFUL',
      fail: 'DATA_GET_ESTIMATE_WITH_ESTIMATEID_FAIL'
    },
    embeddedType: null,
    fnName: 'getEstimateWithEstimateIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/usage-maintenance-api`,
    method: 'GET',
    url: `/estimate/${options?.estimateId || store.app.calculation}`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'getEstimateWithEstimateIdRequest', '/usage-maintenance-api'),
    actions: {
      start: {
        type: DATA_GET_ESTIMATE_WITH_ESTIMATEID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_ESTIMATE_WITH_ESTIMATEID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_ESTIMATE_WITH_ESTIMATEID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getEstimateWithEstimateIdRequestDefinitions = (definitionsArguments: {| payload?: Object |}): {|
  ...TVDGARConfigWithoutRequestArgs, payload?: Object |} => ({
  fnName: 'getEstimateWithEstimateIdRequest',
  basePath: '/usage-maintenance-api',
  payload: definitionsArguments.payload,
})


export const DATA_PUT_ESTIMATE_UNFREEZE_WITH_ESTIMATEID_START = 'DATA_PUT_ESTIMATE_UNFREEZE_WITH_ESTIMATEID_START'
export const DATA_PUT_ESTIMATE_UNFREEZE_WITH_ESTIMATEID_SUCCESSFUL = 'DATA_PUT_ESTIMATE_UNFREEZE_WITH_ESTIMATEID_SUCCESSFUL'
export const DATA_PUT_ESTIMATE_UNFREEZE_WITH_ESTIMATEID_FAIL = 'DATA_PUT_ESTIMATE_UNFREEZE_WITH_ESTIMATEID_FAIL'

// Unfreeze the estimate.
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/usage-maintenance.yaml#/Estimate/put_estimate__estimateId__unfreeze
export const putEstimateUnfreezeWithEstimateIdRequest = (
  payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_PUT_ESTIMATE_UNFREEZE_WITH_ESTIMATEID_START',
      successful: 'DATA_PUT_ESTIMATE_UNFREEZE_WITH_ESTIMATEID_SUCCESSFUL',
      fail: 'DATA_PUT_ESTIMATE_UNFREEZE_WITH_ESTIMATEID_FAIL'
    },
    embeddedType: null,
    fnName: 'putEstimateUnfreezeWithEstimateIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/usage-maintenance-api`,
    method: 'PUT',
    url: `/estimate/${options?.estimateId || store.app.calculation}/unfreeze`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'putEstimateUnfreezeWithEstimateIdRequest', '/usage-maintenance-api'),
    actions: {
      start: {
        type: DATA_PUT_ESTIMATE_UNFREEZE_WITH_ESTIMATEID_START, payload, config, meta
      },
      successful: {
        type: DATA_PUT_ESTIMATE_UNFREEZE_WITH_ESTIMATEID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_PUT_ESTIMATE_UNFREEZE_WITH_ESTIMATEID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_GET_ESTIMATE_REFERENCE_SPACES_ESTIMATE_ID_WITH_ESTIMATEID_START = 'DATA_GET_ESTIMATE_REFERENCE_SPACES_ESTIMATE_ID_WITH_ESTIMATEID_START'
export const DATA_GET_ESTIMATE_REFERENCE_SPACES_ESTIMATE_ID_WITH_ESTIMATEID_SUCCESSFUL = 'DATA_GET_ESTIMATE_REFERENCE_SPACES_ESTIMATE_ID_WITH_ESTIMATEID_SUCCESSFUL'
export const DATA_GET_ESTIMATE_REFERENCE_SPACES_ESTIMATE_ID_WITH_ESTIMATEID_FAIL = 'DATA_GET_ESTIMATE_REFERENCE_SPACES_ESTIMATE_ID_WITH_ESTIMATEID_FAIL'

// Returns id of reference spaces estimate.
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/usage-maintenance.yaml#/Estimate/get_estimate__estimateId__reference_spaces_estimate_id
export const getEstimateReferenceSpacesEstimateIdWithEstimateIdRequest = (
  payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_ESTIMATE_REFERENCE_SPACES_ESTIMATE_ID_WITH_ESTIMATEID_START',
      successful: 'DATA_GET_ESTIMATE_REFERENCE_SPACES_ESTIMATE_ID_WITH_ESTIMATEID_SUCCESSFUL',
      fail: 'DATA_GET_ESTIMATE_REFERENCE_SPACES_ESTIMATE_ID_WITH_ESTIMATEID_FAIL'
    },
    embeddedType: null,
    fnName: 'getEstimateReferenceSpacesEstimateIdWithEstimateIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/usage-maintenance-api`,
    method: 'GET',
    url: `/estimate/${options?.estimateId || store.app.calculation}/reference-spaces-estimate/id`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'getEstimateReferenceSpacesEstimateIdWithEstimateIdRequest', '/usage-maintenance-api'),
    actions: {
      start: {
        type: DATA_GET_ESTIMATE_REFERENCE_SPACES_ESTIMATE_ID_WITH_ESTIMATEID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_ESTIMATE_REFERENCE_SPACES_ESTIMATE_ID_WITH_ESTIMATEID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_ESTIMATE_REFERENCE_SPACES_ESTIMATE_ID_WITH_ESTIMATEID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getEstimateReferenceSpacesEstimateIdWithEstimateIdRequestDefinitions = (definitionsArguments: {| payload?: Object |}): {|
  ...TVDGARConfigWithoutRequestArgs, payload?: Object |} => ({
  fnName: 'getEstimateReferenceSpacesEstimateIdWithEstimateIdRequest',
  basePath: '/usage-maintenance-api',
  payload: definitionsArguments.payload,
})


export const DATA_GET_ESTIMATE_SUMMARY_WITH_ESTIMATEID_START = 'DATA_GET_ESTIMATE_SUMMARY_WITH_ESTIMATEID_START'
export const DATA_GET_ESTIMATE_SUMMARY_WITH_ESTIMATEID_SUCCESSFUL = 'DATA_GET_ESTIMATE_SUMMARY_WITH_ESTIMATEID_SUCCESSFUL'
export const DATA_GET_ESTIMATE_SUMMARY_WITH_ESTIMATEID_FAIL = 'DATA_GET_ESTIMATE_SUMMARY_WITH_ESTIMATEID_FAIL'

// Gets estimate summary data.
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/usage-maintenance.yaml#/Estimate/get_estimate__estimateId__summary
export const getEstimateSummaryWithEstimateIdRequest = (
  payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_ESTIMATE_SUMMARY_WITH_ESTIMATEID_START',
      successful: 'DATA_GET_ESTIMATE_SUMMARY_WITH_ESTIMATEID_SUCCESSFUL',
      fail: 'DATA_GET_ESTIMATE_SUMMARY_WITH_ESTIMATEID_FAIL'
    },
    embeddedType: null,
    fnName: 'getEstimateSummaryWithEstimateIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/usage-maintenance-api`,
    method: 'GET',
    url: `/estimate/${options?.estimateId || store.app.calculation}/summary`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'getEstimateSummaryWithEstimateIdRequest', '/usage-maintenance-api'),
    actions: {
      start: {
        type: DATA_GET_ESTIMATE_SUMMARY_WITH_ESTIMATEID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_ESTIMATE_SUMMARY_WITH_ESTIMATEID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_ESTIMATE_SUMMARY_WITH_ESTIMATEID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getEstimateSummaryWithEstimateIdRequestDefinitions = (definitionsArguments: {| payload?: Object |}): {|
  ...TVDGARConfigWithoutRequestArgs, payload?: Object |} => ({
  fnName: 'getEstimateSummaryWithEstimateIdRequest',
  basePath: '/usage-maintenance-api',
  payload: definitionsArguments.payload,
})


export const DATA_PUT_ESTIMATE_IMPORT_SPACES_ESTIMATE_WITH_ESTIMATEID_START = 'DATA_PUT_ESTIMATE_IMPORT_SPACES_ESTIMATE_WITH_ESTIMATEID_START'
export const DATA_PUT_ESTIMATE_IMPORT_SPACES_ESTIMATE_WITH_ESTIMATEID_SUCCESSFUL = 'DATA_PUT_ESTIMATE_IMPORT_SPACES_ESTIMATE_WITH_ESTIMATEID_SUCCESSFUL'
export const DATA_PUT_ESTIMATE_IMPORT_SPACES_ESTIMATE_WITH_ESTIMATEID_FAIL = 'DATA_PUT_ESTIMATE_IMPORT_SPACES_ESTIMATE_WITH_ESTIMATEID_FAIL'

// Import reference estimate data-
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/usage-maintenance.yaml#/Estimate/put_estimate__estimateId__import_spaces_estimate
export const putEstimateImportSpacesEstimateWithEstimateIdRequest = (
  payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_PUT_ESTIMATE_IMPORT_SPACES_ESTIMATE_WITH_ESTIMATEID_START',
      successful: 'DATA_PUT_ESTIMATE_IMPORT_SPACES_ESTIMATE_WITH_ESTIMATEID_SUCCESSFUL',
      fail: 'DATA_PUT_ESTIMATE_IMPORT_SPACES_ESTIMATE_WITH_ESTIMATEID_FAIL'
    },
    embeddedType: null,
    fnName: 'putEstimateImportSpacesEstimateWithEstimateIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/usage-maintenance-api`,
    method: 'PUT',
    url: `/estimate/${options?.estimateId || store.app.calculation}/import/spaces-estimate`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'putEstimateImportSpacesEstimateWithEstimateIdRequest', '/usage-maintenance-api'),
    actions: {
      start: {
        type: DATA_PUT_ESTIMATE_IMPORT_SPACES_ESTIMATE_WITH_ESTIMATEID_START, payload, config, meta
      },
      successful: {
        type: DATA_PUT_ESTIMATE_IMPORT_SPACES_ESTIMATE_WITH_ESTIMATEID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_PUT_ESTIMATE_IMPORT_SPACES_ESTIMATE_WITH_ESTIMATEID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_GET_ESTIMATE_PERMISSIONS_USERS_SELF_WITH_ESTIMATEID_START = 'DATA_GET_ESTIMATE_PERMISSIONS_USERS_SELF_WITH_ESTIMATEID_START'
export const DATA_GET_ESTIMATE_PERMISSIONS_USERS_SELF_WITH_ESTIMATEID_SUCCESSFUL = 'DATA_GET_ESTIMATE_PERMISSIONS_USERS_SELF_WITH_ESTIMATEID_SUCCESSFUL'
export const DATA_GET_ESTIMATE_PERMISSIONS_USERS_SELF_WITH_ESTIMATEID_FAIL = 'DATA_GET_ESTIMATE_PERMISSIONS_USERS_SELF_WITH_ESTIMATEID_FAIL'

// undefined
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/usage-maintenance.yaml#/Permission/get_estimate__estimateId__permissions_users_self
export const getEstimatePermissionsUsersSelfWithEstimateIdRequest = (
  requestArguments: getEstimatePermissionsUsersSelfWithEstimateIdRequestUsageMaintenanceArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_ESTIMATE_PERMISSIONS_USERS_SELF_WITH_ESTIMATEID_START',
      successful: 'DATA_GET_ESTIMATE_PERMISSIONS_USERS_SELF_WITH_ESTIMATEID_SUCCESSFUL',
      fail: 'DATA_GET_ESTIMATE_PERMISSIONS_USERS_SELF_WITH_ESTIMATEID_FAIL'
    },
    embeddedType: null,
    fnName: 'getEstimatePermissionsUsersSelfWithEstimateIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/usage-maintenance-api`,
    method: 'GET',
    url: `/estimate/${options?.estimateId || store.app.calculation}/permissions/users/self`,
    params: {
      directPermissions: requestArguments.query?.['directPermissions'],
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'getEstimatePermissionsUsersSelfWithEstimateIdRequest', '/usage-maintenance-api'),
    actions: {
      start: {
        type: DATA_GET_ESTIMATE_PERMISSIONS_USERS_SELF_WITH_ESTIMATEID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_ESTIMATE_PERMISSIONS_USERS_SELF_WITH_ESTIMATEID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_ESTIMATE_PERMISSIONS_USERS_SELF_WITH_ESTIMATEID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getEstimatePermissionsUsersSelfWithEstimateIdRequestDefinitions = (definitionsArguments: {| requestArgs: getEstimatePermissionsUsersSelfWithEstimateIdRequestUsageMaintenanceArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getEstimatePermissionsUsersSelfWithEstimateIdRequestUsageMaintenanceArguments, payload?: Object |} => ({
  fnName: 'getEstimatePermissionsUsersSelfWithEstimateIdRequest',
  basePath: '/usage-maintenance-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_GET_ESTIMATE_PERMISSIONS_WITH_ESTIMATEID_START = 'DATA_GET_ESTIMATE_PERMISSIONS_WITH_ESTIMATEID_START'
export const DATA_GET_ESTIMATE_PERMISSIONS_WITH_ESTIMATEID_SUCCESSFUL = 'DATA_GET_ESTIMATE_PERMISSIONS_WITH_ESTIMATEID_SUCCESSFUL'
export const DATA_GET_ESTIMATE_PERMISSIONS_WITH_ESTIMATEID_FAIL = 'DATA_GET_ESTIMATE_PERMISSIONS_WITH_ESTIMATEID_FAIL'

// undefined
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/usage-maintenance.yaml#/Permission/get_estimate__estimateId__permissions
export const getEstimatePermissionsWithEstimateIdRequest = (
  requestArguments: getEstimatePermissionsWithEstimateIdRequestUsageMaintenanceArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_ESTIMATE_PERMISSIONS_WITH_ESTIMATEID_START',
      successful: 'DATA_GET_ESTIMATE_PERMISSIONS_WITH_ESTIMATEID_SUCCESSFUL',
      fail: 'DATA_GET_ESTIMATE_PERMISSIONS_WITH_ESTIMATEID_FAIL'
    },
    embeddedType: null,
    fnName: 'getEstimatePermissionsWithEstimateIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/usage-maintenance-api`,
    method: 'GET',
    url: `/estimate/${options?.estimateId || store.app.calculation}/permissions`,
    params: {
      directPermissions: requestArguments.query?.['directPermissions'],
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'getEstimatePermissionsWithEstimateIdRequest', '/usage-maintenance-api'),
    actions: {
      start: {
        type: DATA_GET_ESTIMATE_PERMISSIONS_WITH_ESTIMATEID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_ESTIMATE_PERMISSIONS_WITH_ESTIMATEID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_ESTIMATE_PERMISSIONS_WITH_ESTIMATEID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getEstimatePermissionsWithEstimateIdRequestDefinitions = (definitionsArguments: {| requestArgs: getEstimatePermissionsWithEstimateIdRequestUsageMaintenanceArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getEstimatePermissionsWithEstimateIdRequestUsageMaintenanceArguments, payload?: Object |} => ({
  fnName: 'getEstimatePermissionsWithEstimateIdRequest',
  basePath: '/usage-maintenance-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_POST_ESTIMATE_PERMISSIONS_WITH_ESTIMATEID_START = 'DATA_POST_ESTIMATE_PERMISSIONS_WITH_ESTIMATEID_START'
export const DATA_POST_ESTIMATE_PERMISSIONS_WITH_ESTIMATEID_SUCCESSFUL = 'DATA_POST_ESTIMATE_PERMISSIONS_WITH_ESTIMATEID_SUCCESSFUL'
export const DATA_POST_ESTIMATE_PERMISSIONS_WITH_ESTIMATEID_FAIL = 'DATA_POST_ESTIMATE_PERMISSIONS_WITH_ESTIMATEID_FAIL'

// undefined
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/usage-maintenance.yaml#/Permission/post_estimate__estimateId__permissions
export const postEstimatePermissionsWithEstimateIdRequest = (
  payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_POST_ESTIMATE_PERMISSIONS_WITH_ESTIMATEID_START',
      successful: 'DATA_POST_ESTIMATE_PERMISSIONS_WITH_ESTIMATEID_SUCCESSFUL',
      fail: 'DATA_POST_ESTIMATE_PERMISSIONS_WITH_ESTIMATEID_FAIL'
    },
    embeddedType: null,
    fnName: 'postEstimatePermissionsWithEstimateIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/usage-maintenance-api`,
    method: 'POST',
    url: `/estimate/${options?.estimateId || store.app.calculation}/permissions`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'postEstimatePermissionsWithEstimateIdRequest', '/usage-maintenance-api'),
    actions: {
      start: {
        type: DATA_POST_ESTIMATE_PERMISSIONS_WITH_ESTIMATEID_START, payload, config, meta
      },
      successful: {
        type: DATA_POST_ESTIMATE_PERMISSIONS_WITH_ESTIMATEID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_POST_ESTIMATE_PERMISSIONS_WITH_ESTIMATEID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_DELETE_ESTIMATE_PERMISSIONS_WITH_ESTIMATEID_START = 'DATA_DELETE_ESTIMATE_PERMISSIONS_WITH_ESTIMATEID_START'
export const DATA_DELETE_ESTIMATE_PERMISSIONS_WITH_ESTIMATEID_SUCCESSFUL = 'DATA_DELETE_ESTIMATE_PERMISSIONS_WITH_ESTIMATEID_SUCCESSFUL'
export const DATA_DELETE_ESTIMATE_PERMISSIONS_WITH_ESTIMATEID_FAIL = 'DATA_DELETE_ESTIMATE_PERMISSIONS_WITH_ESTIMATEID_FAIL'

// undefined
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/usage-maintenance.yaml#/Permission/delete_estimate__estimateId__permissions
export const deleteEstimatePermissionsWithEstimateIdRequest = (
  payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_DELETE_ESTIMATE_PERMISSIONS_WITH_ESTIMATEID_START',
      successful: 'DATA_DELETE_ESTIMATE_PERMISSIONS_WITH_ESTIMATEID_SUCCESSFUL',
      fail: 'DATA_DELETE_ESTIMATE_PERMISSIONS_WITH_ESTIMATEID_FAIL'
    },
    embeddedType: null,
    fnName: 'deleteEstimatePermissionsWithEstimateIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/usage-maintenance-api`,
    method: 'DELETE',
    url: `/estimate/${options?.estimateId || store.app.calculation}/permissions`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'deleteEstimatePermissionsWithEstimateIdRequest', '/usage-maintenance-api'),
    actions: {
      start: {
        type: DATA_DELETE_ESTIMATE_PERMISSIONS_WITH_ESTIMATEID_START, payload, config, meta
      },
      successful: {
        type: DATA_DELETE_ESTIMATE_PERMISSIONS_WITH_ESTIMATEID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_DELETE_ESTIMATE_PERMISSIONS_WITH_ESTIMATEID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_PUT_ESTIMATE_PERMISSIONS_WITH_ESTIMATEID_START = 'DATA_PUT_ESTIMATE_PERMISSIONS_WITH_ESTIMATEID_START'
export const DATA_PUT_ESTIMATE_PERMISSIONS_WITH_ESTIMATEID_SUCCESSFUL = 'DATA_PUT_ESTIMATE_PERMISSIONS_WITH_ESTIMATEID_SUCCESSFUL'
export const DATA_PUT_ESTIMATE_PERMISSIONS_WITH_ESTIMATEID_FAIL = 'DATA_PUT_ESTIMATE_PERMISSIONS_WITH_ESTIMATEID_FAIL'

// undefined
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/usage-maintenance.yaml#/Permission/put_estimate__estimateId__permissions
export const putEstimatePermissionsWithEstimateIdRequest = (
  requestArguments: putEstimatePermissionsWithEstimateIdRequestUsageMaintenanceArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_PUT_ESTIMATE_PERMISSIONS_WITH_ESTIMATEID_START',
      successful: 'DATA_PUT_ESTIMATE_PERMISSIONS_WITH_ESTIMATEID_SUCCESSFUL',
      fail: 'DATA_PUT_ESTIMATE_PERMISSIONS_WITH_ESTIMATEID_FAIL'
    },
    embeddedType: null,
    fnName: 'putEstimatePermissionsWithEstimateIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/usage-maintenance-api`,
    method: 'PUT',
    url: `/estimate/${options?.estimateId || store.app.calculation}/permissions`,
    params: {
      resetPermissions: requestArguments.query['resetPermissions'],
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'putEstimatePermissionsWithEstimateIdRequest', '/usage-maintenance-api'),
    actions: {
      start: {
        type: DATA_PUT_ESTIMATE_PERMISSIONS_WITH_ESTIMATEID_START, payload, config, meta
      },
      successful: {
        type: DATA_PUT_ESTIMATE_PERMISSIONS_WITH_ESTIMATEID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_PUT_ESTIMATE_PERMISSIONS_WITH_ESTIMATEID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_GET_ESTIMATE_PERMISSIONS_USERS_WITH_ESTIMATEID_START = 'DATA_GET_ESTIMATE_PERMISSIONS_USERS_WITH_ESTIMATEID_START'
export const DATA_GET_ESTIMATE_PERMISSIONS_USERS_WITH_ESTIMATEID_SUCCESSFUL = 'DATA_GET_ESTIMATE_PERMISSIONS_USERS_WITH_ESTIMATEID_SUCCESSFUL'
export const DATA_GET_ESTIMATE_PERMISSIONS_USERS_WITH_ESTIMATEID_FAIL = 'DATA_GET_ESTIMATE_PERMISSIONS_USERS_WITH_ESTIMATEID_FAIL'

// undefined
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/usage-maintenance.yaml#/Permission/get_estimate__estimateId__permissions_users
export const getEstimatePermissionsUsersWithEstimateIdRequest = (
  requestArguments: getEstimatePermissionsUsersWithEstimateIdRequestUsageMaintenanceArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_ESTIMATE_PERMISSIONS_USERS_WITH_ESTIMATEID_START',
      successful: 'DATA_GET_ESTIMATE_PERMISSIONS_USERS_WITH_ESTIMATEID_SUCCESSFUL',
      fail: 'DATA_GET_ESTIMATE_PERMISSIONS_USERS_WITH_ESTIMATEID_FAIL'
    },
    embeddedType: null,
    fnName: 'getEstimatePermissionsUsersWithEstimateIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/usage-maintenance-api`,
    method: 'GET',
    url: `/estimate/${options?.estimateId || store.app.calculation}/permissions/users`,
    params: {
      includeUsers: requestArguments.query?.['includeUsers'],
      directPermissions: requestArguments.query?.['directPermissions'],
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'getEstimatePermissionsUsersWithEstimateIdRequest', '/usage-maintenance-api'),
    actions: {
      start: {
        type: DATA_GET_ESTIMATE_PERMISSIONS_USERS_WITH_ESTIMATEID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_ESTIMATE_PERMISSIONS_USERS_WITH_ESTIMATEID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_ESTIMATE_PERMISSIONS_USERS_WITH_ESTIMATEID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getEstimatePermissionsUsersWithEstimateIdRequestDefinitions = (definitionsArguments: {| requestArgs: getEstimatePermissionsUsersWithEstimateIdRequestUsageMaintenanceArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getEstimatePermissionsUsersWithEstimateIdRequestUsageMaintenanceArguments, payload?: Object |} => ({
  fnName: 'getEstimatePermissionsUsersWithEstimateIdRequest',
  basePath: '/usage-maintenance-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_GET_ESTIMATE_PERMISSIONS_USER_GROUPS_WITH_ESTIMATEID_START = 'DATA_GET_ESTIMATE_PERMISSIONS_USER_GROUPS_WITH_ESTIMATEID_START'
export const DATA_GET_ESTIMATE_PERMISSIONS_USER_GROUPS_WITH_ESTIMATEID_SUCCESSFUL = 'DATA_GET_ESTIMATE_PERMISSIONS_USER_GROUPS_WITH_ESTIMATEID_SUCCESSFUL'
export const DATA_GET_ESTIMATE_PERMISSIONS_USER_GROUPS_WITH_ESTIMATEID_FAIL = 'DATA_GET_ESTIMATE_PERMISSIONS_USER_GROUPS_WITH_ESTIMATEID_FAIL'

// undefined
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/usage-maintenance.yaml#/Permission/get_estimate__estimateId__permissions_user_groups
export const getEstimatePermissionsUserGroupsWithEstimateIdRequest = (
  requestArguments: getEstimatePermissionsUserGroupsWithEstimateIdRequestUsageMaintenanceArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_ESTIMATE_PERMISSIONS_USER_GROUPS_WITH_ESTIMATEID_START',
      successful: 'DATA_GET_ESTIMATE_PERMISSIONS_USER_GROUPS_WITH_ESTIMATEID_SUCCESSFUL',
      fail: 'DATA_GET_ESTIMATE_PERMISSIONS_USER_GROUPS_WITH_ESTIMATEID_FAIL'
    },
    embeddedType: null,
    fnName: 'getEstimatePermissionsUserGroupsWithEstimateIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/usage-maintenance-api`,
    method: 'GET',
    url: `/estimate/${options?.estimateId || store.app.calculation}/permissions/user-groups`,
    params: {
      includeUserGroups: requestArguments.query?.['includeUserGroups'],
      directPermissions: requestArguments.query?.['directPermissions'],
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'getEstimatePermissionsUserGroupsWithEstimateIdRequest', '/usage-maintenance-api'),
    actions: {
      start: {
        type: DATA_GET_ESTIMATE_PERMISSIONS_USER_GROUPS_WITH_ESTIMATEID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_ESTIMATE_PERMISSIONS_USER_GROUPS_WITH_ESTIMATEID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_ESTIMATE_PERMISSIONS_USER_GROUPS_WITH_ESTIMATEID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getEstimatePermissionsUserGroupsWithEstimateIdRequestDefinitions = (definitionsArguments: {| requestArgs: getEstimatePermissionsUserGroupsWithEstimateIdRequestUsageMaintenanceArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getEstimatePermissionsUserGroupsWithEstimateIdRequestUsageMaintenanceArguments, payload?: Object |} => ({
  fnName: 'getEstimatePermissionsUserGroupsWithEstimateIdRequest',
  basePath: '/usage-maintenance-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_GET_PRICE_LIST_WITH_ESTIMATEID_START = 'DATA_GET_PRICE_LIST_WITH_ESTIMATEID_START'
export const DATA_GET_PRICE_LIST_WITH_ESTIMATEID_SUCCESSFUL = 'DATA_GET_PRICE_LIST_WITH_ESTIMATEID_SUCCESSFUL'
export const DATA_GET_PRICE_LIST_WITH_ESTIMATEID_FAIL = 'DATA_GET_PRICE_LIST_WITH_ESTIMATEID_FAIL'

// Returns price list items.
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/usage-maintenance.yaml#/PriceList/get_price_list__estimateId_
export const getPriceListWithEstimateIdRequest = (
  requestArguments: getPriceListWithEstimateIdRequestUsageMaintenanceArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_PRICE_LIST_WITH_ESTIMATEID_START',
      successful: 'DATA_GET_PRICE_LIST_WITH_ESTIMATEID_SUCCESSFUL',
      fail: 'DATA_GET_PRICE_LIST_WITH_ESTIMATEID_FAIL'
    },
    embeddedType: null,
    fnName: 'getPriceListWithEstimateIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/usage-maintenance-api`,
    method: 'GET',
    url: `/price-list/${options?.estimateId || store.app.calculation}`,
    params: {
      listType: requestArguments.query?.['listType'],
      parentId: requestArguments.query?.['parentId'],
      searchString: requestArguments.query?.['searchString'],
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'getPriceListWithEstimateIdRequest', '/usage-maintenance-api'),
    actions: {
      start: {
        type: DATA_GET_PRICE_LIST_WITH_ESTIMATEID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_PRICE_LIST_WITH_ESTIMATEID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_PRICE_LIST_WITH_ESTIMATEID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getPriceListWithEstimateIdRequestDefinitions = (definitionsArguments: {| requestArgs: getPriceListWithEstimateIdRequestUsageMaintenanceArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getPriceListWithEstimateIdRequestUsageMaintenanceArguments, payload?: Object |} => ({
  fnName: 'getPriceListWithEstimateIdRequest',
  basePath: '/usage-maintenance-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_GET_PRICE_LIST_ITEMS_PROPERTIES_WITH_ID_START = 'DATA_GET_PRICE_LIST_ITEMS_PROPERTIES_WITH_ID_START'
export const DATA_GET_PRICE_LIST_ITEMS_PROPERTIES_WITH_ID_SUCCESSFUL = 'DATA_GET_PRICE_LIST_ITEMS_PROPERTIES_WITH_ID_SUCCESSFUL'
export const DATA_GET_PRICE_LIST_ITEMS_PROPERTIES_WITH_ID_FAIL = 'DATA_GET_PRICE_LIST_ITEMS_PROPERTIES_WITH_ID_FAIL'

// Get price list item properties.
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/usage-maintenance.yaml#/PriceList/get_price_list__estimateId__items__id__properties
export const getPriceListItemsPropertiesWithIdRequest = (
  requestArguments: getPriceListItemsPropertiesWithIdRequestUsageMaintenanceArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_PRICE_LIST_ITEMS_PROPERTIES_WITH_ID_START',
      successful: 'DATA_GET_PRICE_LIST_ITEMS_PROPERTIES_WITH_ID_SUCCESSFUL',
      fail: 'DATA_GET_PRICE_LIST_ITEMS_PROPERTIES_WITH_ID_FAIL'
    },
    embeddedType: null,
    fnName: 'getPriceListItemsPropertiesWithIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/usage-maintenance-api`,
    method: 'GET',
    url: `/price-list/${options?.estimateId || store.app.calculation}/items/${requestArguments.path['id']}/properties`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'getPriceListItemsPropertiesWithIdRequest', '/usage-maintenance-api'),
    actions: {
      start: {
        type: DATA_GET_PRICE_LIST_ITEMS_PROPERTIES_WITH_ID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_PRICE_LIST_ITEMS_PROPERTIES_WITH_ID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_PRICE_LIST_ITEMS_PROPERTIES_WITH_ID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getPriceListItemsPropertiesWithIdRequestDefinitions = (definitionsArguments: {| requestArgs: getPriceListItemsPropertiesWithIdRequestUsageMaintenanceArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getPriceListItemsPropertiesWithIdRequestUsageMaintenanceArguments, payload?: Object |} => ({
  fnName: 'getPriceListItemsPropertiesWithIdRequest',
  basePath: '/usage-maintenance-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_GET_SWAGGER_START = 'DATA_GET_SWAGGER_START'
export const DATA_GET_SWAGGER_SUCCESSFUL = 'DATA_GET_SWAGGER_SUCCESSFUL'
export const DATA_GET_SWAGGER_FAIL = 'DATA_GET_SWAGGER_FAIL'

// undefined
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/usage-maintenance.yaml#/SwaggerApi/get_swagger
export const getSwaggerRequest = (
  payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_SWAGGER_START',
      successful: 'DATA_GET_SWAGGER_SUCCESSFUL',
      fail: 'DATA_GET_SWAGGER_FAIL'
    },
    embeddedType: null,
    fnName: 'getSwaggerRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/usage-maintenance-api`,
    method: 'GET',
    url: '/swagger',
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'getSwaggerRequest', '/usage-maintenance-api'),
    actions: {
      start: {
        type: DATA_GET_SWAGGER_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_SWAGGER_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_SWAGGER_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getSwaggerRequestDefinitions = (definitionsArguments: {| payload?: Object |}): {|
  ...TVDGARConfigWithoutRequestArgs, payload?: Object |} => ({
  fnName: 'getSwaggerRequest',
  basePath: '/usage-maintenance-api',
  payload: definitionsArguments.payload,
})


export const DATA_GET_SWAGGER_WITH_NODE_START = 'DATA_GET_SWAGGER_WITH_NODE_START'
export const DATA_GET_SWAGGER_WITH_NODE_SUCCESSFUL = 'DATA_GET_SWAGGER_WITH_NODE_SUCCESSFUL'
export const DATA_GET_SWAGGER_WITH_NODE_FAIL = 'DATA_GET_SWAGGER_WITH_NODE_FAIL'

// undefined
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/usage-maintenance.yaml#/SwaggerApi/get_swagger__node_
export const getSwaggerWithNodeRequest = (
  requestArguments: getSwaggerWithNodeRequestUsageMaintenanceArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_SWAGGER_WITH_NODE_START',
      successful: 'DATA_GET_SWAGGER_WITH_NODE_SUCCESSFUL',
      fail: 'DATA_GET_SWAGGER_WITH_NODE_FAIL'
    },
    embeddedType: null,
    fnName: 'getSwaggerWithNodeRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/usage-maintenance-api`,
    method: 'GET',
    url: `/swagger/${requestArguments.path['node']}`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'getSwaggerWithNodeRequest', '/usage-maintenance-api'),
    actions: {
      start: {
        type: DATA_GET_SWAGGER_WITH_NODE_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_SWAGGER_WITH_NODE_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_SWAGGER_WITH_NODE_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getSwaggerWithNodeRequestDefinitions = (definitionsArguments: {| requestArgs: getSwaggerWithNodeRequestUsageMaintenanceArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getSwaggerWithNodeRequestUsageMaintenanceArguments, payload?: Object |} => ({
  fnName: 'getSwaggerWithNodeRequest',
  basePath: '/usage-maintenance-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_GET_SWAGGER_WITH_CHILDNODE_START = 'DATA_GET_SWAGGER_WITH_CHILDNODE_START'
export const DATA_GET_SWAGGER_WITH_CHILDNODE_SUCCESSFUL = 'DATA_GET_SWAGGER_WITH_CHILDNODE_SUCCESSFUL'
export const DATA_GET_SWAGGER_WITH_CHILDNODE_FAIL = 'DATA_GET_SWAGGER_WITH_CHILDNODE_FAIL'

// undefined
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/usage-maintenance.yaml#/SwaggerApi/get_swagger__node___childNode_
export const getSwaggerWithChildNodeRequest = (
  requestArguments: getSwaggerWithChildNodeRequestUsageMaintenanceArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_SWAGGER_WITH_CHILDNODE_START',
      successful: 'DATA_GET_SWAGGER_WITH_CHILDNODE_SUCCESSFUL',
      fail: 'DATA_GET_SWAGGER_WITH_CHILDNODE_FAIL'
    },
    embeddedType: null,
    fnName: 'getSwaggerWithChildNodeRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/usage-maintenance-api`,
    method: 'GET',
    url: `/swagger/${requestArguments.path['node']}/${requestArguments.path['childNode']}`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'getSwaggerWithChildNodeRequest', '/usage-maintenance-api'),
    actions: {
      start: {
        type: DATA_GET_SWAGGER_WITH_CHILDNODE_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_SWAGGER_WITH_CHILDNODE_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_SWAGGER_WITH_CHILDNODE_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getSwaggerWithChildNodeRequestDefinitions = (definitionsArguments: {| requestArgs: getSwaggerWithChildNodeRequestUsageMaintenanceArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getSwaggerWithChildNodeRequestUsageMaintenanceArguments, payload?: Object |} => ({
  fnName: 'getSwaggerWithChildNodeRequest',
  basePath: '/usage-maintenance-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_GET_USAGE_MAINTENANCE_TASK_MODELLED_USAGE_MAINTENANCE_TASKS_PROPERTIES_WITH_MODELLEDUSAGEMAINTENANCETASKID_START = 'DATA_GET_USAGE_MAINTENANCE_TASK_MODELLED_USAGE_MAINTENANCE_TASKS_PROPERTIES_WITH_MODELLEDUSAGEMAINTENANCETASKID_START'
export const DATA_GET_USAGE_MAINTENANCE_TASK_MODELLED_USAGE_MAINTENANCE_TASKS_PROPERTIES_WITH_MODELLEDUSAGEMAINTENANCETASKID_SUCCESSFUL = 'DATA_GET_USAGE_MAINTENANCE_TASK_MODELLED_USAGE_MAINTENANCE_TASKS_PROPERTIES_WITH_MODELLEDUSAGEMAINTENANCETASKID_SUCCESSFUL'
export const DATA_GET_USAGE_MAINTENANCE_TASK_MODELLED_USAGE_MAINTENANCE_TASKS_PROPERTIES_WITH_MODELLEDUSAGEMAINTENANCETASKID_FAIL = 'DATA_GET_USAGE_MAINTENANCE_TASK_MODELLED_USAGE_MAINTENANCE_TASKS_PROPERTIES_WITH_MODELLEDUSAGEMAINTENANCETASKID_FAIL'

// Returns modelled usage & maintenance tasks properties.
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/usage-maintenance.yaml#/UsageMaintenanceTask/get_usage_maintenance_task__estimateId__modelled_usage_maintenance_tasks__modelledUsageMaintenanceTaskId__properties
export const getUsageMaintenanceTaskModelledUsageMaintenanceTasksPropertiesWithModelledUsageMaintenanceTaskIdRequest = (
  requestArguments: getUsageMaintenanceTaskModelledUsageMaintenanceTasksPropertiesWithModelledUsageMaintenanceTaskIdRequestUsageMaintenanceArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_USAGE_MAINTENANCE_TASK_MODELLED_USAGE_MAINTENANCE_TASKS_PROPERTIES_WITH_MODELLEDUSAGEMAINTENANCETASKID_START',
      successful: 'DATA_GET_USAGE_MAINTENANCE_TASK_MODELLED_USAGE_MAINTENANCE_TASKS_PROPERTIES_WITH_MODELLEDUSAGEMAINTENANCETASKID_SUCCESSFUL',
      fail: 'DATA_GET_USAGE_MAINTENANCE_TASK_MODELLED_USAGE_MAINTENANCE_TASKS_PROPERTIES_WITH_MODELLEDUSAGEMAINTENANCETASKID_FAIL'
    },
    embeddedType: null,
    fnName: 'getUsageMaintenanceTaskModelledUsageMaintenanceTasksPropertiesWithModelledUsageMaintenanceTaskIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/usage-maintenance-api`,
    method: 'GET',
    url: `/usage-maintenance-task/${options?.estimateId || store.app.calculation}/modelled-usage-maintenance-tasks/${requestArguments.path['modelledUsageMaintenanceTaskId']}/properties`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'getUsageMaintenanceTaskModelledUsageMaintenanceTasksPropertiesWithModelledUsageMaintenanceTaskIdRequest', '/usage-maintenance-api'),
    actions: {
      start: {
        type: DATA_GET_USAGE_MAINTENANCE_TASK_MODELLED_USAGE_MAINTENANCE_TASKS_PROPERTIES_WITH_MODELLEDUSAGEMAINTENANCETASKID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_USAGE_MAINTENANCE_TASK_MODELLED_USAGE_MAINTENANCE_TASKS_PROPERTIES_WITH_MODELLEDUSAGEMAINTENANCETASKID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_USAGE_MAINTENANCE_TASK_MODELLED_USAGE_MAINTENANCE_TASKS_PROPERTIES_WITH_MODELLEDUSAGEMAINTENANCETASKID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getUsageMaintenanceTaskModelledUsageMaintenanceTasksPropertiesWithModelledUsageMaintenanceTaskIdRequestDefinitions = (definitionsArguments: {| requestArgs: getUsageMaintenanceTaskModelledUsageMaintenanceTasksPropertiesWithModelledUsageMaintenanceTaskIdRequestUsageMaintenanceArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getUsageMaintenanceTaskModelledUsageMaintenanceTasksPropertiesWithModelledUsageMaintenanceTaskIdRequestUsageMaintenanceArguments, payload?: Object |} => ({
  fnName: 'getUsageMaintenanceTaskModelledUsageMaintenanceTasksPropertiesWithModelledUsageMaintenanceTaskIdRequest',
  basePath: '/usage-maintenance-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_GET_USAGE_MAINTENANCE_TASK_USAGE_MAINTENANCE_TASKS_PROPERTIES_WITH_USAGEMAINTENANCETASKID_START = 'DATA_GET_USAGE_MAINTENANCE_TASK_USAGE_MAINTENANCE_TASKS_PROPERTIES_WITH_USAGEMAINTENANCETASKID_START'
export const DATA_GET_USAGE_MAINTENANCE_TASK_USAGE_MAINTENANCE_TASKS_PROPERTIES_WITH_USAGEMAINTENANCETASKID_SUCCESSFUL = 'DATA_GET_USAGE_MAINTENANCE_TASK_USAGE_MAINTENANCE_TASKS_PROPERTIES_WITH_USAGEMAINTENANCETASKID_SUCCESSFUL'
export const DATA_GET_USAGE_MAINTENANCE_TASK_USAGE_MAINTENANCE_TASKS_PROPERTIES_WITH_USAGEMAINTENANCETASKID_FAIL = 'DATA_GET_USAGE_MAINTENANCE_TASK_USAGE_MAINTENANCE_TASKS_PROPERTIES_WITH_USAGEMAINTENANCETASKID_FAIL'

// Returns usage & maintenance tasks properties.
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/usage-maintenance.yaml#/UsageMaintenanceTask/get_usage_maintenance_task__estimateId__usage_maintenance_tasks__usageMaintenanceTaskId__properties
export const getUsageMaintenanceTaskUsageMaintenanceTasksPropertiesWithUsageMaintenanceTaskIdRequest = (
  requestArguments: getUsageMaintenanceTaskUsageMaintenanceTasksPropertiesWithUsageMaintenanceTaskIdRequestUsageMaintenanceArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_USAGE_MAINTENANCE_TASK_USAGE_MAINTENANCE_TASKS_PROPERTIES_WITH_USAGEMAINTENANCETASKID_START',
      successful: 'DATA_GET_USAGE_MAINTENANCE_TASK_USAGE_MAINTENANCE_TASKS_PROPERTIES_WITH_USAGEMAINTENANCETASKID_SUCCESSFUL',
      fail: 'DATA_GET_USAGE_MAINTENANCE_TASK_USAGE_MAINTENANCE_TASKS_PROPERTIES_WITH_USAGEMAINTENANCETASKID_FAIL'
    },
    embeddedType: null,
    fnName: 'getUsageMaintenanceTaskUsageMaintenanceTasksPropertiesWithUsageMaintenanceTaskIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/usage-maintenance-api`,
    method: 'GET',
    url: `/usage-maintenance-task/${options?.estimateId || store.app.calculation}/usage-maintenance-tasks/${requestArguments.path['usageMaintenanceTaskId']}/properties`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'getUsageMaintenanceTaskUsageMaintenanceTasksPropertiesWithUsageMaintenanceTaskIdRequest', '/usage-maintenance-api'),
    actions: {
      start: {
        type: DATA_GET_USAGE_MAINTENANCE_TASK_USAGE_MAINTENANCE_TASKS_PROPERTIES_WITH_USAGEMAINTENANCETASKID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_USAGE_MAINTENANCE_TASK_USAGE_MAINTENANCE_TASKS_PROPERTIES_WITH_USAGEMAINTENANCETASKID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_USAGE_MAINTENANCE_TASK_USAGE_MAINTENANCE_TASKS_PROPERTIES_WITH_USAGEMAINTENANCETASKID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getUsageMaintenanceTaskUsageMaintenanceTasksPropertiesWithUsageMaintenanceTaskIdRequestDefinitions = (definitionsArguments: {| requestArgs: getUsageMaintenanceTaskUsageMaintenanceTasksPropertiesWithUsageMaintenanceTaskIdRequestUsageMaintenanceArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getUsageMaintenanceTaskUsageMaintenanceTasksPropertiesWithUsageMaintenanceTaskIdRequestUsageMaintenanceArguments, payload?: Object |} => ({
  fnName: 'getUsageMaintenanceTaskUsageMaintenanceTasksPropertiesWithUsageMaintenanceTaskIdRequest',
  basePath: '/usage-maintenance-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_GET_USAGE_MAINTENANCE_TASK_USAGE_MAINTENANCE_TASKS_CAN_MOVE_TO_WITH_USAGEMAINTENANCETASKID_START = 'DATA_GET_USAGE_MAINTENANCE_TASK_USAGE_MAINTENANCE_TASKS_CAN_MOVE_TO_WITH_USAGEMAINTENANCETASKID_START'
export const DATA_GET_USAGE_MAINTENANCE_TASK_USAGE_MAINTENANCE_TASKS_CAN_MOVE_TO_WITH_USAGEMAINTENANCETASKID_SUCCESSFUL = 'DATA_GET_USAGE_MAINTENANCE_TASK_USAGE_MAINTENANCE_TASKS_CAN_MOVE_TO_WITH_USAGEMAINTENANCETASKID_SUCCESSFUL'
export const DATA_GET_USAGE_MAINTENANCE_TASK_USAGE_MAINTENANCE_TASKS_CAN_MOVE_TO_WITH_USAGEMAINTENANCETASKID_FAIL = 'DATA_GET_USAGE_MAINTENANCE_TASK_USAGE_MAINTENANCE_TASKS_CAN_MOVE_TO_WITH_USAGEMAINTENANCETASKID_FAIL'

// Returns usage & maintenance list of possible parents.
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/usage-maintenance.yaml#/UsageMaintenanceTask/get_usage_maintenance_task__estimateId__usage_maintenance_tasks__usageMaintenanceTaskId__can_move_to
export const getUsageMaintenanceTaskUsageMaintenanceTasksCanMoveToWithUsageMaintenanceTaskIdRequest = (
  requestArguments: getUsageMaintenanceTaskUsageMaintenanceTasksCanMoveToWithUsageMaintenanceTaskIdRequestUsageMaintenanceArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_USAGE_MAINTENANCE_TASK_USAGE_MAINTENANCE_TASKS_CAN_MOVE_TO_WITH_USAGEMAINTENANCETASKID_START',
      successful: 'DATA_GET_USAGE_MAINTENANCE_TASK_USAGE_MAINTENANCE_TASKS_CAN_MOVE_TO_WITH_USAGEMAINTENANCETASKID_SUCCESSFUL',
      fail: 'DATA_GET_USAGE_MAINTENANCE_TASK_USAGE_MAINTENANCE_TASKS_CAN_MOVE_TO_WITH_USAGEMAINTENANCETASKID_FAIL'
    },
    embeddedType: null,
    fnName: 'getUsageMaintenanceTaskUsageMaintenanceTasksCanMoveToWithUsageMaintenanceTaskIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/usage-maintenance-api`,
    method: 'GET',
    url: `/usage-maintenance-task/${options?.estimateId || store.app.calculation}/usage-maintenance-tasks/${requestArguments.path['usageMaintenanceTaskId']}/can-move-to`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'getUsageMaintenanceTaskUsageMaintenanceTasksCanMoveToWithUsageMaintenanceTaskIdRequest', '/usage-maintenance-api'),
    actions: {
      start: {
        type: DATA_GET_USAGE_MAINTENANCE_TASK_USAGE_MAINTENANCE_TASKS_CAN_MOVE_TO_WITH_USAGEMAINTENANCETASKID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_USAGE_MAINTENANCE_TASK_USAGE_MAINTENANCE_TASKS_CAN_MOVE_TO_WITH_USAGEMAINTENANCETASKID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_USAGE_MAINTENANCE_TASK_USAGE_MAINTENANCE_TASKS_CAN_MOVE_TO_WITH_USAGEMAINTENANCETASKID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getUsageMaintenanceTaskUsageMaintenanceTasksCanMoveToWithUsageMaintenanceTaskIdRequestDefinitions = (definitionsArguments: {| requestArgs: getUsageMaintenanceTaskUsageMaintenanceTasksCanMoveToWithUsageMaintenanceTaskIdRequestUsageMaintenanceArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getUsageMaintenanceTaskUsageMaintenanceTasksCanMoveToWithUsageMaintenanceTaskIdRequestUsageMaintenanceArguments, payload?: Object |} => ({
  fnName: 'getUsageMaintenanceTaskUsageMaintenanceTasksCanMoveToWithUsageMaintenanceTaskIdRequest',
  basePath: '/usage-maintenance-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_POST_USAGE_MAINTENANCE_TASK_USAGE_MAINTENANCE_TASKS_WITH_ESTIMATEID_START = 'DATA_POST_USAGE_MAINTENANCE_TASK_USAGE_MAINTENANCE_TASKS_WITH_ESTIMATEID_START'
export const DATA_POST_USAGE_MAINTENANCE_TASK_USAGE_MAINTENANCE_TASKS_WITH_ESTIMATEID_SUCCESSFUL = 'DATA_POST_USAGE_MAINTENANCE_TASK_USAGE_MAINTENANCE_TASKS_WITH_ESTIMATEID_SUCCESSFUL'
export const DATA_POST_USAGE_MAINTENANCE_TASK_USAGE_MAINTENANCE_TASKS_WITH_ESTIMATEID_FAIL = 'DATA_POST_USAGE_MAINTENANCE_TASK_USAGE_MAINTENANCE_TASKS_WITH_ESTIMATEID_FAIL'

// undefined
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/usage-maintenance.yaml#/UsageMaintenanceTask/post_usage_maintenance_task__estimateId__usage_maintenance_tasks
export const postUsageMaintenanceTaskUsageMaintenanceTasksWithEstimateIdRequest = (
  requestArguments: postUsageMaintenanceTaskUsageMaintenanceTasksWithEstimateIdRequestUsageMaintenanceArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_POST_USAGE_MAINTENANCE_TASK_USAGE_MAINTENANCE_TASKS_WITH_ESTIMATEID_START',
      successful: 'DATA_POST_USAGE_MAINTENANCE_TASK_USAGE_MAINTENANCE_TASKS_WITH_ESTIMATEID_SUCCESSFUL',
      fail: 'DATA_POST_USAGE_MAINTENANCE_TASK_USAGE_MAINTENANCE_TASKS_WITH_ESTIMATEID_FAIL'
    },
    embeddedType: null,
    fnName: 'postUsageMaintenanceTaskUsageMaintenanceTasksWithEstimateIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/usage-maintenance-api`,
    method: 'POST',
    url: `/usage-maintenance-task/${options?.estimateId || store.app.calculation}/usage-maintenance-tasks`,
    params: {
      priceListItemId: requestArguments.query?.['priceListItemId'],
      copyUsageMaintenanceTaskId: requestArguments.query?.['copyUsageMaintenanceTaskId'],
      assemblyUsageMaintenanceTaskId: requestArguments.query?.['assemblyUsageMaintenanceTaskId'],
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'postUsageMaintenanceTaskUsageMaintenanceTasksWithEstimateIdRequest', '/usage-maintenance-api'),
    actions: {
      start: {
        type: DATA_POST_USAGE_MAINTENANCE_TASK_USAGE_MAINTENANCE_TASKS_WITH_ESTIMATEID_START, payload, config, meta
      },
      successful: {
        type: DATA_POST_USAGE_MAINTENANCE_TASK_USAGE_MAINTENANCE_TASKS_WITH_ESTIMATEID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_POST_USAGE_MAINTENANCE_TASK_USAGE_MAINTENANCE_TASKS_WITH_ESTIMATEID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_PATCH_USAGE_MAINTENANCE_TASK_USAGE_MAINTENANCE_TASKS_WITH_USAGEMAINTENANCETASKID_START = 'DATA_PATCH_USAGE_MAINTENANCE_TASK_USAGE_MAINTENANCE_TASKS_WITH_USAGEMAINTENANCETASKID_START'
export const DATA_PATCH_USAGE_MAINTENANCE_TASK_USAGE_MAINTENANCE_TASKS_WITH_USAGEMAINTENANCETASKID_SUCCESSFUL = 'DATA_PATCH_USAGE_MAINTENANCE_TASK_USAGE_MAINTENANCE_TASKS_WITH_USAGEMAINTENANCETASKID_SUCCESSFUL'
export const DATA_PATCH_USAGE_MAINTENANCE_TASK_USAGE_MAINTENANCE_TASKS_WITH_USAGEMAINTENANCETASKID_FAIL = 'DATA_PATCH_USAGE_MAINTENANCE_TASK_USAGE_MAINTENANCE_TASKS_WITH_USAGEMAINTENANCETASKID_FAIL'

// Patches usage & maintenance task properties.
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/usage-maintenance.yaml#/UsageMaintenanceTask/patch_usage_maintenance_task__estimateId__usage_maintenance_tasks__usageMaintenanceTaskId_
export const patchUsageMaintenanceTaskUsageMaintenanceTasksWithUsageMaintenanceTaskIdRequest = (
  requestArguments: patchUsageMaintenanceTaskUsageMaintenanceTasksWithUsageMaintenanceTaskIdRequestUsageMaintenanceArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_PATCH_USAGE_MAINTENANCE_TASK_USAGE_MAINTENANCE_TASKS_WITH_USAGEMAINTENANCETASKID_START',
      successful: 'DATA_PATCH_USAGE_MAINTENANCE_TASK_USAGE_MAINTENANCE_TASKS_WITH_USAGEMAINTENANCETASKID_SUCCESSFUL',
      fail: 'DATA_PATCH_USAGE_MAINTENANCE_TASK_USAGE_MAINTENANCE_TASKS_WITH_USAGEMAINTENANCETASKID_FAIL'
    },
    embeddedType: null,
    fnName: 'patchUsageMaintenanceTaskUsageMaintenanceTasksWithUsageMaintenanceTaskIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/usage-maintenance-api`,
    method: 'PATCH',
    url: `/usage-maintenance-task/${options?.estimateId || store.app.calculation}/usage-maintenance-tasks/${requestArguments.path['usageMaintenanceTaskId']}`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'patchUsageMaintenanceTaskUsageMaintenanceTasksWithUsageMaintenanceTaskIdRequest', '/usage-maintenance-api'),
    actions: {
      start: {
        type: DATA_PATCH_USAGE_MAINTENANCE_TASK_USAGE_MAINTENANCE_TASKS_WITH_USAGEMAINTENANCETASKID_START, payload, config, meta
      },
      successful: {
        type: DATA_PATCH_USAGE_MAINTENANCE_TASK_USAGE_MAINTENANCE_TASKS_WITH_USAGEMAINTENANCETASKID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_PATCH_USAGE_MAINTENANCE_TASK_USAGE_MAINTENANCE_TASKS_WITH_USAGEMAINTENANCETASKID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_DELETE_USAGE_MAINTENANCE_TASK_USAGE_MAINTENANCE_TASKS_WITH_USAGEMAINTENANCETASKID_START = 'DATA_DELETE_USAGE_MAINTENANCE_TASK_USAGE_MAINTENANCE_TASKS_WITH_USAGEMAINTENANCETASKID_START'
export const DATA_DELETE_USAGE_MAINTENANCE_TASK_USAGE_MAINTENANCE_TASKS_WITH_USAGEMAINTENANCETASKID_SUCCESSFUL = 'DATA_DELETE_USAGE_MAINTENANCE_TASK_USAGE_MAINTENANCE_TASKS_WITH_USAGEMAINTENANCETASKID_SUCCESSFUL'
export const DATA_DELETE_USAGE_MAINTENANCE_TASK_USAGE_MAINTENANCE_TASKS_WITH_USAGEMAINTENANCETASKID_FAIL = 'DATA_DELETE_USAGE_MAINTENANCE_TASK_USAGE_MAINTENANCE_TASKS_WITH_USAGEMAINTENANCETASKID_FAIL'

// Deletes usage & maintenance task.
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/usage-maintenance.yaml#/UsageMaintenanceTask/delete_usage_maintenance_task__estimateId__usage_maintenance_tasks__usageMaintenanceTaskId_
export const deleteUsageMaintenanceTaskUsageMaintenanceTasksWithUsageMaintenanceTaskIdRequest = (
  requestArguments: deleteUsageMaintenanceTaskUsageMaintenanceTasksWithUsageMaintenanceTaskIdRequestUsageMaintenanceArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_DELETE_USAGE_MAINTENANCE_TASK_USAGE_MAINTENANCE_TASKS_WITH_USAGEMAINTENANCETASKID_START',
      successful: 'DATA_DELETE_USAGE_MAINTENANCE_TASK_USAGE_MAINTENANCE_TASKS_WITH_USAGEMAINTENANCETASKID_SUCCESSFUL',
      fail: 'DATA_DELETE_USAGE_MAINTENANCE_TASK_USAGE_MAINTENANCE_TASKS_WITH_USAGEMAINTENANCETASKID_FAIL'
    },
    embeddedType: null,
    fnName: 'deleteUsageMaintenanceTaskUsageMaintenanceTasksWithUsageMaintenanceTaskIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/usage-maintenance-api`,
    method: 'DELETE',
    url: `/usage-maintenance-task/${options?.estimateId || store.app.calculation}/usage-maintenance-tasks/${requestArguments.path['usageMaintenanceTaskId']}`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'deleteUsageMaintenanceTaskUsageMaintenanceTasksWithUsageMaintenanceTaskIdRequest', '/usage-maintenance-api'),
    actions: {
      start: {
        type: DATA_DELETE_USAGE_MAINTENANCE_TASK_USAGE_MAINTENANCE_TASKS_WITH_USAGEMAINTENANCETASKID_START, payload, config, meta
      },
      successful: {
        type: DATA_DELETE_USAGE_MAINTENANCE_TASK_USAGE_MAINTENANCE_TASKS_WITH_USAGEMAINTENANCETASKID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_DELETE_USAGE_MAINTENANCE_TASK_USAGE_MAINTENANCE_TASKS_WITH_USAGEMAINTENANCETASKID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const DATA_GET_USAGE_MAINTENANCE_TASK_ATTRIBUTES_ENUMS_WITH_ATTRIBUTEID_START = 'DATA_GET_USAGE_MAINTENANCE_TASK_ATTRIBUTES_ENUMS_WITH_ATTRIBUTEID_START'
export const DATA_GET_USAGE_MAINTENANCE_TASK_ATTRIBUTES_ENUMS_WITH_ATTRIBUTEID_SUCCESSFUL = 'DATA_GET_USAGE_MAINTENANCE_TASK_ATTRIBUTES_ENUMS_WITH_ATTRIBUTEID_SUCCESSFUL'
export const DATA_GET_USAGE_MAINTENANCE_TASK_ATTRIBUTES_ENUMS_WITH_ATTRIBUTEID_FAIL = 'DATA_GET_USAGE_MAINTENANCE_TASK_ATTRIBUTES_ENUMS_WITH_ATTRIBUTEID_FAIL'

// Returns usage & maintenance task attribute enumerations.
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/usage-maintenance.yaml#/UsageMaintenanceTask/get_usage_maintenance_task_attributes__attributeId__enums
export const getUsageMaintenanceTaskAttributesEnumsWithAttributeIdRequest = (
  requestArguments: getUsageMaintenanceTaskAttributesEnumsWithAttributeIdRequestUsageMaintenanceArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_USAGE_MAINTENANCE_TASK_ATTRIBUTES_ENUMS_WITH_ATTRIBUTEID_START',
      successful: 'DATA_GET_USAGE_MAINTENANCE_TASK_ATTRIBUTES_ENUMS_WITH_ATTRIBUTEID_SUCCESSFUL',
      fail: 'DATA_GET_USAGE_MAINTENANCE_TASK_ATTRIBUTES_ENUMS_WITH_ATTRIBUTEID_FAIL'
    },
    embeddedType: null,
    fnName: 'getUsageMaintenanceTaskAttributesEnumsWithAttributeIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/usage-maintenance-api`,
    method: 'GET',
    url: `/usage-maintenance-task/attributes/${requestArguments.path['attributeId']}/enums`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'getUsageMaintenanceTaskAttributesEnumsWithAttributeIdRequest', '/usage-maintenance-api'),
    actions: {
      start: {
        type: DATA_GET_USAGE_MAINTENANCE_TASK_ATTRIBUTES_ENUMS_WITH_ATTRIBUTEID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_USAGE_MAINTENANCE_TASK_ATTRIBUTES_ENUMS_WITH_ATTRIBUTEID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_USAGE_MAINTENANCE_TASK_ATTRIBUTES_ENUMS_WITH_ATTRIBUTEID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getUsageMaintenanceTaskAttributesEnumsWithAttributeIdRequestDefinitions = (definitionsArguments: {| requestArgs: getUsageMaintenanceTaskAttributesEnumsWithAttributeIdRequestUsageMaintenanceArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getUsageMaintenanceTaskAttributesEnumsWithAttributeIdRequestUsageMaintenanceArguments, payload?: Object |} => ({
  fnName: 'getUsageMaintenanceTaskAttributesEnumsWithAttributeIdRequest',
  basePath: '/usage-maintenance-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_GET_USAGE_MAINTENANCE_TASK_USAGE_MAINTENANCE_TASKS_PRICE_LIST_ITEMS_WITH_USAGEMAINTENANCETASKASSEMBLYID_START = 'DATA_GET_USAGE_MAINTENANCE_TASK_USAGE_MAINTENANCE_TASKS_PRICE_LIST_ITEMS_WITH_USAGEMAINTENANCETASKASSEMBLYID_START'
export const DATA_GET_USAGE_MAINTENANCE_TASK_USAGE_MAINTENANCE_TASKS_PRICE_LIST_ITEMS_WITH_USAGEMAINTENANCETASKASSEMBLYID_SUCCESSFUL = 'DATA_GET_USAGE_MAINTENANCE_TASK_USAGE_MAINTENANCE_TASKS_PRICE_LIST_ITEMS_WITH_USAGEMAINTENANCETASKASSEMBLYID_SUCCESSFUL'
export const DATA_GET_USAGE_MAINTENANCE_TASK_USAGE_MAINTENANCE_TASKS_PRICE_LIST_ITEMS_WITH_USAGEMAINTENANCETASKASSEMBLYID_FAIL = 'DATA_GET_USAGE_MAINTENANCE_TASK_USAGE_MAINTENANCE_TASKS_PRICE_LIST_ITEMS_WITH_USAGEMAINTENANCETASKASSEMBLYID_FAIL'

// Returns usage & maintenance task assembly price list items.
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/usage-maintenance.yaml#/UsageMaintenanceTask/get_usage_maintenance_task__estimateId__usage_maintenance_tasks__usageMaintenanceTaskAssemblyId__price_list_items
export const getUsageMaintenanceTaskUsageMaintenanceTasksPriceListItemsWithUsageMaintenanceTaskAssemblyIdRequest = (
  requestArguments: getUsageMaintenanceTaskUsageMaintenanceTasksPriceListItemsWithUsageMaintenanceTaskAssemblyIdRequestUsageMaintenanceArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_USAGE_MAINTENANCE_TASK_USAGE_MAINTENANCE_TASKS_PRICE_LIST_ITEMS_WITH_USAGEMAINTENANCETASKASSEMBLYID_START',
      successful: 'DATA_GET_USAGE_MAINTENANCE_TASK_USAGE_MAINTENANCE_TASKS_PRICE_LIST_ITEMS_WITH_USAGEMAINTENANCETASKASSEMBLYID_SUCCESSFUL',
      fail: 'DATA_GET_USAGE_MAINTENANCE_TASK_USAGE_MAINTENANCE_TASKS_PRICE_LIST_ITEMS_WITH_USAGEMAINTENANCETASKASSEMBLYID_FAIL'
    },
    embeddedType: null,
    fnName: 'getUsageMaintenanceTaskUsageMaintenanceTasksPriceListItemsWithUsageMaintenanceTaskAssemblyIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/usage-maintenance-api`,
    method: 'GET',
    url: `/usage-maintenance-task/${options?.estimateId || store.app.calculation}/usage-maintenance-tasks/${requestArguments.path['usageMaintenanceTaskAssemblyId']}/price-list-items`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'getUsageMaintenanceTaskUsageMaintenanceTasksPriceListItemsWithUsageMaintenanceTaskAssemblyIdRequest', '/usage-maintenance-api'),
    actions: {
      start: {
        type: DATA_GET_USAGE_MAINTENANCE_TASK_USAGE_MAINTENANCE_TASKS_PRICE_LIST_ITEMS_WITH_USAGEMAINTENANCETASKASSEMBLYID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_USAGE_MAINTENANCE_TASK_USAGE_MAINTENANCE_TASKS_PRICE_LIST_ITEMS_WITH_USAGEMAINTENANCETASKASSEMBLYID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_USAGE_MAINTENANCE_TASK_USAGE_MAINTENANCE_TASKS_PRICE_LIST_ITEMS_WITH_USAGEMAINTENANCETASKASSEMBLYID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getUsageMaintenanceTaskUsageMaintenanceTasksPriceListItemsWithUsageMaintenanceTaskAssemblyIdRequestDefinitions = (definitionsArguments: {| requestArgs: getUsageMaintenanceTaskUsageMaintenanceTasksPriceListItemsWithUsageMaintenanceTaskAssemblyIdRequestUsageMaintenanceArguments, payload?: Object |}): {|
  ...TVDGARConfig, requestArgs: getUsageMaintenanceTaskUsageMaintenanceTasksPriceListItemsWithUsageMaintenanceTaskAssemblyIdRequestUsageMaintenanceArguments, payload?: Object |} => ({
  fnName: 'getUsageMaintenanceTaskUsageMaintenanceTasksPriceListItemsWithUsageMaintenanceTaskAssemblyIdRequest',
  basePath: '/usage-maintenance-api',
  requestArgs: definitionsArguments.requestArgs,
  payload: definitionsArguments.payload,
})


export const DATA_GET_USAGE_MAINTENANCE_TASKS_SCHEDULE_WITH_ESTIMATEID_START = 'DATA_GET_USAGE_MAINTENANCE_TASKS_SCHEDULE_WITH_ESTIMATEID_START'
export const DATA_GET_USAGE_MAINTENANCE_TASKS_SCHEDULE_WITH_ESTIMATEID_SUCCESSFUL = 'DATA_GET_USAGE_MAINTENANCE_TASKS_SCHEDULE_WITH_ESTIMATEID_SUCCESSFUL'
export const DATA_GET_USAGE_MAINTENANCE_TASKS_SCHEDULE_WITH_ESTIMATEID_FAIL = 'DATA_GET_USAGE_MAINTENANCE_TASKS_SCHEDULE_WITH_ESTIMATEID_FAIL'

// Returns usage & maintenance tasks with classification and comparison data.
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/usage-maintenance.yaml#/UsageMaintenanceTasksSchedule/get_usage_maintenance_tasks_schedule__estimateId_
export const getUsageMaintenanceTasksScheduleWithEstimateIdRequest = (
  payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_GET_USAGE_MAINTENANCE_TASKS_SCHEDULE_WITH_ESTIMATEID_START',
      successful: 'DATA_GET_USAGE_MAINTENANCE_TASKS_SCHEDULE_WITH_ESTIMATEID_SUCCESSFUL',
      fail: 'DATA_GET_USAGE_MAINTENANCE_TASKS_SCHEDULE_WITH_ESTIMATEID_FAIL'
    },
    embeddedType: null,
    fnName: 'getUsageMaintenanceTasksScheduleWithEstimateIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/usage-maintenance-api`,
    method: 'GET',
    url: `/usage-maintenance-tasks-schedule/${options?.estimateId || store.app.calculation}`,
    params: {
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'getUsageMaintenanceTasksScheduleWithEstimateIdRequest', '/usage-maintenance-api'),
    actions: {
      start: {
        type: DATA_GET_USAGE_MAINTENANCE_TASKS_SCHEDULE_WITH_ESTIMATEID_START, payload, config, meta
      },
      successful: {
        type: DATA_GET_USAGE_MAINTENANCE_TASKS_SCHEDULE_WITH_ESTIMATEID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_GET_USAGE_MAINTENANCE_TASKS_SCHEDULE_WITH_ESTIMATEID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}


export const getUsageMaintenanceTasksScheduleWithEstimateIdRequestDefinitions = (definitionsArguments: {| payload?: Object |}): {|
  ...TVDGARConfigWithoutRequestArgs, payload?: Object |} => ({
  fnName: 'getUsageMaintenanceTasksScheduleWithEstimateIdRequest',
  basePath: '/usage-maintenance-api',
  payload: definitionsArguments.payload,
})


export const DATA_PUT_USAGE_MAINTENANCE_TASKS_SCHEDULE_USAGE_MAINTENANCE_TASKS_WITH_ESTIMATEID_START = 'DATA_PUT_USAGE_MAINTENANCE_TASKS_SCHEDULE_USAGE_MAINTENANCE_TASKS_WITH_ESTIMATEID_START'
export const DATA_PUT_USAGE_MAINTENANCE_TASKS_SCHEDULE_USAGE_MAINTENANCE_TASKS_WITH_ESTIMATEID_SUCCESSFUL = 'DATA_PUT_USAGE_MAINTENANCE_TASKS_SCHEDULE_USAGE_MAINTENANCE_TASKS_WITH_ESTIMATEID_SUCCESSFUL'
export const DATA_PUT_USAGE_MAINTENANCE_TASKS_SCHEDULE_USAGE_MAINTENANCE_TASKS_WITH_ESTIMATEID_FAIL = 'DATA_PUT_USAGE_MAINTENANCE_TASKS_SCHEDULE_USAGE_MAINTENANCE_TASKS_WITH_ESTIMATEID_FAIL'

// Add/replace usage & maintenance tasks with modelled usage & maintenance tasks.
// https://docs.haahtela.fi/haahtela-api/swagger-ui/index.html?url=../swagger/usage-maintenance.yaml#/UsageMaintenanceTasksSchedule/put_usage_maintenance_tasks_schedule__estimateId__usage_maintenance_tasks
export const putUsageMaintenanceTasksScheduleUsageMaintenanceTasksWithEstimateIdRequest = (
  requestArguments: putUsageMaintenanceTasksScheduleUsageMaintenanceTasksWithEstimateIdRequestUsageMaintenanceArguments, payload?: Object,
  successCb?: Function,
  errorCb?: Function,
  options?: Object
) => {
  const store = getState()
  const meta = {
    actionNames: {
      start: 'DATA_PUT_USAGE_MAINTENANCE_TASKS_SCHEDULE_USAGE_MAINTENANCE_TASKS_WITH_ESTIMATEID_START',
      successful: 'DATA_PUT_USAGE_MAINTENANCE_TASKS_SCHEDULE_USAGE_MAINTENANCE_TASKS_WITH_ESTIMATEID_SUCCESSFUL',
      fail: 'DATA_PUT_USAGE_MAINTENANCE_TASKS_SCHEDULE_USAGE_MAINTENANCE_TASKS_WITH_ESTIMATEID_FAIL'
    },
    embeddedType: null,
    fnName: 'putUsageMaintenanceTasksScheduleUsageMaintenanceTasksWithEstimateIdRequest'
  }
  const config = {
    headers: {},
    baseURL: `${API_BASE_URL}/usage-maintenance-api`,
    method: 'PUT',
    url: `/usage-maintenance-tasks-schedule/${options?.estimateId || store.app.calculation}/usage-maintenance-tasks`,
    params: {
      usageMaintenanceClassificationId: requestArguments.query?.['usageMaintenanceClassificationId'],
      modelledUsageMaintenanceTaskId: requestArguments.query?.['modelledUsageMaintenanceTaskId'],
      replace: requestArguments.query?.['replace'],
      language: options?.languageCode || store.app.languageCode,
      accountId: options?.selectedAccountId || store.app.selectedAccountId
    },
    data: {}
  }
  const requestFnArgs = {
    config,
    parser: parserMapper(null, 'putUsageMaintenanceTasksScheduleUsageMaintenanceTasksWithEstimateIdRequest', '/usage-maintenance-api'),
    actions: {
      start: {
        type: DATA_PUT_USAGE_MAINTENANCE_TASKS_SCHEDULE_USAGE_MAINTENANCE_TASKS_WITH_ESTIMATEID_START, payload, config, meta
      },
      successful: {
        type: DATA_PUT_USAGE_MAINTENANCE_TASKS_SCHEDULE_USAGE_MAINTENANCE_TASKS_WITH_ESTIMATEID_SUCCESSFUL, payload, config, meta
      },
      fail: {
        type: DATA_PUT_USAGE_MAINTENANCE_TASKS_SCHEDULE_USAGE_MAINTENANCE_TASKS_WITH_ESTIMATEID_FAIL, payload, config, meta
      },
    },
    callbacks: {
      successCb,
      errorCb,
    },
    options,
  }
  return requestFn(requestFnArgs)
}
