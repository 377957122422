// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withStyles } from '@material-ui/core'
import { withTranslation } from 'react-i18next'
import Typography from '@material-ui/core/Typography'

import SentientHOC from '../../../hocs/SentientHOC/SentientHOC'
import HamburgerMenu from '../../../common/menus/HamburgerMenu/HamburgerMenu'
import HierarchicalListContainer from '../../../containers/HierarchicalListContainer/HierarchicalListContainer'
import DescriptionCell from '../../lists/common/DescriptionCell/DescriptionCell'
import InfoPopover from '../../InfoPopover/InfoPopover'
import Feature from '../../../containers/Feature/Feature'
import UserModifiedIcon from '../../../containers/infoComponents/UserModifiedIcon'

import { RENOVATION } from '../../../../constants/viewModeConstants'
import { FEATURE_RESET_EQUIPMENTS } from '../../../../constants/features'
import { AMOUNT, QUANTITY } from '../../../../constants/attributes'
import { EQUIPMENT, EQUIPMENT_PRICING } from '../../../../constants/contentTypes'
import { EQUIPMENTS_TAB } from '../../../../constants/moduleConstants'

import { createUserDefinedEquipmentModal } from '../../../../actions/modals'
import { deleteListItem, addListItem, modifyListItem } from '../../../../actions/list'
import { getIsUserModifiedContent } from '../../../../utils/listUtils'
import {
  toggleWidgetModified,
  setSelectedTab,
  openContentWidget,
  setWidgetContentProps
} from '../../../../actions/widgets'
import {
  getSpacesEquipmentItemsDefaultWithItemIdRequest,
  getSpacesEquipmentColumnsWithSpaceIdRequestDefinitions,
  getSpacesEquipmentWithSpaceIdRequestDefinitions,
} from '../../../../utils/generated-api-requests/spaces'
import ViewHeaderText from '../../ViewHeaderText/ViewHeaderText'

const styles = ({ palette, typography }: TVDTheme) => ({
  headerRowContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    marginRight: 2,
    width: '100%',
    outline: 0,
  },
  iconsContainer: {
    lineHeight: 1,
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center'
  },
  warningBox: {
    backgroundColor: palette.catskillWhite,
    padding: '12px 28px'
  },
  warningLabel: {
    fontWeight: 400,
    font: `14px ${typography.fontFamilyBase}`,
    color: palette.nevada
  }
})

type HOCProps = {|
  t: Function, // translate function
  classes: Object, // withstyles classes object
  sentient: TVDSentient, // Object providing helpers via SentientHOC
|}

type DispatchProps = {|
  dispatchCreateEquipment: (Function, Object) => void, // open create user-defined equipment modal
  dispatchToggleWidgetModified: (boolean, string) => void, // Marks the tab to have unsaved changes
  dispatchOpenContentWidget: (TVDOpenContentWidgetArguments) => void, // Open widget function
  dispatchDeleteListItem: (string) => void,
  dispatchAddListItem: (Object, string, boolean) => void,
  dispatchSetSelectedTab: Function,
  dispatchOpenEquipmentRegistry: Function,
  dispatchSetWidgetContentProps: () => void, // sets widget content to widget for the use of saving the mounted list
  dispatchSetModifiedData: Function, // modifies data in the store
|}

type MappedProps = {|
  application: string, // current application
  calculation: string, // ID of the calculation
  resourceId: string,
  widgetType: string,
  activeEdit: boolean, // flag to disable widget content if view is being edited
  isEstimateFrozen: $PropertyType<TVDApplicationStore, 'isEstimateFrozen'>, // if estimate is frozen
  isEstimateLockedToCurrentUser: $PropertyType<TVDApplicationStore, 'isEstimateLockedToCurrentUser'>, // if estimate is locked to current user
  spacesEstimateType: $PropertyType<TVDApplicationStore, 'spacesEstimateType'>, // spaces estimate type
  spacesResultView: $PropertyType<TVDApplicationStore, 'spacesResultView'> // spaces result view
|}

type ReceivedProps = {|
  listStoreId: string, // uuid or constant used as the key in list Store for the list
  tab: string, // name of the tab the Equipments is rendered in
  disabled: boolean, // status of if the widget is disabled or not where the Equipments is rendered
|}

type Props = {|
  ...HOCProps,
  ...MappedProps,
  ...DispatchProps,
  ...ReceivedProps
|}

export class Equipments extends Component<Props> {
  static defaultProps = {
    isEstimateLockedToCurrentUser: false,
  }

  componentDidMount() {
    this.props.dispatchSetSelectedTab()
  }

  openElementWidget = (id: string, title: string) => {
    const { dispatchOpenContentWidget, resourceId, listStoreId } = this.props
    const propertiesStoreId = id
    dispatchOpenContentWidget({
      widgetId: id,
      widgetType: EQUIPMENT,
      widgetTitle: title,
      contentProps: {
        propertiesStoreId,
        equipmentId: id,
        spaceId: resourceId,
        listStoreId
      }
    })
  }

  onRowClick = (row: Object) => {
    const { canHaveChildren, id, columnData: { Description } } = row
    if (!canHaveChildren && row.parentId !== undefined && !this.props.activeEdit) {
      this.openElementWidget(id, Description)
    }
  }

  onModifiedChange = (isModified: boolean) => {
    const { dispatchToggleWidgetModified, tab } = this.props
    dispatchToggleWidgetModified(isModified, tab)
  }

  showContextMenu = (): boolean => {
    const { isEstimateLockedToCurrentUser, isEstimateFrozen } = this.props
    return !!(isEstimateLockedToCurrentUser && !isEstimateFrozen)
  }

  getDescriptionCellContent = (content: string, row: Object) => {
    const { classes } = this.props
    return (
      <div
        className={classes.headerRowContainer}>
        <DescriptionCell highlighted={row.level > 0} text={content} />
        { this.getIconsContainer(row) }
      </div>
    )
  }
  getWarningText = (): React$Element<any> | null => {
    const { spacesEstimateType, t, classes } = this.props

    return spacesEstimateType === RENOVATION ?
      <div className={classes.warningBox}>
        <Typography className={classes.warningLabel}>
          {t('tabs._WARNING_MESSAGE_')}
        </Typography>
      </div> :
      null
  }

  getContextMenuItems(row: Object): Array<TVDMenuItem> {
    if (!this.showContextMenu()) return []
    const {
      t,
      dispatchAddListItem,
      dispatchCreateEquipment,
      dispatchDeleteListItem,
      dispatchOpenEquipmentRegistry
    } = this.props

    const addItem = {
      localizedName: t('widgets._ADD_EQUIPMENT_FROM_REGISTRY_'),
      onClick: () => {
        dispatchOpenEquipmentRegistry(row.parentId || row.id)
      },
      testId: 'ContextMenuItem-add-equipment-from-registry'
    }

    const addUserDefinedItem = {
      localizedName: t('widgets._ADD_USER_DEFINED_ITEM_'),
      onClick: () => {
        dispatchCreateEquipment((listItem: Object) => dispatchAddListItem(listItem, row.id, true), row)
      },
      testId: 'ContextMenuItem-add-user-defined-item'
    }

    const deleteItem = {
      localizedName: t('buttons._DELETE_'),
      onClick: () => {
        dispatchDeleteListItem(row.id)
      },
      testId: 'ContextMenuItem-delete'
    }

    return row.parentId ? [addItem, deleteItem] : [addItem, addUserDefinedItem]
  }

  getUserModifiedIcon(row: Object): React$Element<Feature> {
    const { t } = this.props
    return (
      <Feature name={FEATURE_RESET_EQUIPMENTS}>
        <UserModifiedIcon
          actionText={t('userModifiedInfo._SPACES_RESET_DEFAULT_VALUE_')}
          id={row.columnData.Description}
          visible
          actionCb={() => { this.resetEquipmentToDefaults(row) }} />
      </Feature>
    )
  }

  resetEquipmentToDefaults(row: Object): Function {
    const { resourceId, listStoreId } = this.props

    getSpacesEquipmentItemsDefaultWithItemIdRequest(
      { path: { spaceId: resourceId, itemId: row.id } },
      { listId: listStoreId, mergeOptions: { removeInitialListItemsWithParentIdOf: row.id } }, () => {
        this.props.dispatchSetModifiedData({
          listItemId: row.id,
          listId: listStoreId,
          columnName: 'userModifiedContent',
          value: false,
        })
      }
    )
  }

  getIconsContainer(row: TVDListItem): React$Element<any> | null {
    const {
      classes,
      application,
      activeEdit,
      resourceId
    } = this.props
    if (activeEdit) return null

    return (
      <div className={classes.iconsContainer} data-visible_on_hover>
        { getIsUserModifiedContent(row) && this.getUserModifiedIcon(row) }
        {
          row.canGetInfo &&
          <InfoPopover
            id={`${row.id}-InfoPopover`}
            application={application}
            infoData={{
              spaceId: resourceId,
              itemId: row.id,
              type: EQUIPMENT,
              heading: row.columnData.Description,
            }}
            resourceId={row.id} />
        }
        { this.showContextMenu() && this.getHamburgerMenu(row) }
      </div>
    )
  }

  getHamburgerMenu(row: Object): React$Element<HamburgerMenu> {
    const { columnData: { Description } = {} } = row
    return <HamburgerMenu visibleOnHover id={Description} items={this.getContextMenuItems(row)} />
  }

  viewHeaderText(): React$Element<ViewHeaderText> | null {
    const { spacesEstimateType, t } = this.props
    return spacesEstimateType === RENOVATION ?
      <ViewHeaderText inWidget>{t('renovation._EQUIPMENTS_TAB_VIEW_HEADER_TEXT_')}</ViewHeaderText> :
      null
  }

  render(): React$Element<any> {
    const {
      listStoreId,
      disabled,
      isEstimateLockedToCurrentUser,
      dispatchSetWidgetContentProps,
      sentient,
      isEstimateFrozen
    } = this.props

    return (
      <>
        {this.getWarningText()}
        { this.viewHeaderText() }
        <HierarchicalListContainer
          isEstimateFrozen={isEstimateFrozen}
          contextMenuItems={(row: Object) => this.getContextMenuItems(row)}
          isEstimateLockedToCurrentUser={isEstimateLockedToCurrentUser}
          disabled={disabled}
          listId={listStoreId}
          editableColumns={[AMOUNT, QUANTITY]}
          wrappedCellContents={{
            Description: ({ content, row }: Object) => this.getDescriptionCellContent(content, row),
          }}
          didMountCallback={() => {
            dispatchSetWidgetContentProps()
            sentient.runSetup()
          }}
          onRowClick={(row: Object) => this.onRowClick(row)}
          onModifiedChange={(isModified: boolean) => this.onModifiedChange(isModified)} />
      </>
    )
  }
}

function mapStateToProps({ app, widgets }: TVDReduxStore, { resourceId }: Props): MappedProps {
  const {
    calculation,
    activeEdit,
    application,
    isEstimateFrozen,
    isEstimateLockedToCurrentUser,
    spacesEstimateType,
    spacesResultView
  } = app
  const { [resourceId]: { widgetType } = {} } = widgets
  return {
    application,
    calculation,
    resourceId,
    widgetType,
    activeEdit,
    isEstimateFrozen,
    isEstimateLockedToCurrentUser,
    spacesEstimateType,
    spacesResultView
  }
}

function mapDispatchToProps(dispatch: Function, { widgetId, resourceId, listStoreId }: Object): Object {
  return {
    dispatchSetWidgetContentProps: () => {
      dispatch(setWidgetContentProps(widgetId, { equipmentsListId: listStoreId, spaceId: resourceId, disableFooterActions: false }))
    },
    dispatchToggleWidgetModified: (isModified: boolean) => { dispatch(toggleWidgetModified(widgetId, EQUIPMENTS_TAB, isModified)) },
    dispatchOpenContentWidget: (openContentWidgetArgs: TVDOpenContentWidgetArguments) => { dispatch(openContentWidget(openContentWidgetArgs)) },
    dispatchCreateEquipment: (onSave: Function, listItem: Object) => dispatch(createUserDefinedEquipmentModal(onSave, listItem)),
    dispatchDeleteListItem: (rowId: string) => dispatch(deleteListItem(listStoreId, rowId)),
    dispatchSetModifiedData: (payload: Object) => { dispatch(modifyListItem(payload)) },
    dispatchSetSelectedTab: () => { dispatch(setSelectedTab(EQUIPMENTS_TAB, widgetId)) },
    dispatchAddListItem: (listItem: Object, parentId: string, isUserAdded: boolean) => {
      dispatch(addListItem(listStoreId, listItem, parentId, isUserAdded))
    },
    dispatchOpenEquipmentRegistry: (itemId: string) => {
      dispatch(openContentWidget({
        contentProps: {
          itemId,
          spaceId: resourceId,
          pricingListId: listStoreId,
          pricingType: 'equipment',
          widgetLevelChild: true
        },
        widgetId: EQUIPMENT_PRICING,
        widgetType: EQUIPMENT_PRICING,
      }))
    },
  }
}


const sentientConfig: TVDSentientConfig = {
  updateOnPropsChange: ({ spacesEstimateType, spacesResultView }: Props) => ({ spacesEstimateType, spacesResultView }),
  getSetupRequestDefinitions: (store: Object, { listStoreId, resourceId }: Object) =>
    ({
      getSpacesEquipmentColumnsWithSpaceIdRequestDefinitions: getSpacesEquipmentColumnsWithSpaceIdRequestDefinitions({
        requestArgs: { path: { spaceId: resourceId } },
        payload: { listId: listStoreId }
      }),
      getSpacesEquipmentWithSpaceIdRequestDefinitions: getSpacesEquipmentWithSpaceIdRequestDefinitions({
        requestArgs: { path: { spaceId: resourceId }, query: { listType: 'flat' } },
        payload: { listId: listStoreId, mergeOptions: { openAllListItems: true } }
      })
    }),
}

export default compose(
  withStyles(styles),
  withTranslation('translations'),
  connect(mapStateToProps, mapDispatchToProps)
)(SentientHOC(Equipments, sentientConfig))
