// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.
import React, { Component } from 'react'
import type { Element } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { typographyClasses, colors } from 'frontend-assets'
import { combineStyleClassNames } from '../../../utils/styleUtils'

const { h4 } = typographyClasses
const { dark80 } = colors

const styles = (): Object => ({
  wrapper: {
    ...h4,
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    color: dark80,
    lineHeight: '29px',
    paddingTop: '10px',
    paddingBottom: '15px'
  },
  inWidget: {
    ...h4,
    color: dark80,
    padding: '32px 24px 24px',
    justifyContent: 'flex-start'
  }
})

type Props = {|
  classes: Object, // withStyles styles object
  children: React$Element<any> | Element<any>,
  inWidget: boolean, // indicates if ViewHeaderText appears inside a widget, thus needs smaller styles
  testId: string // unique id for testing
|}

export class ViewHeaderText extends Component<Props> {
  render(): Element<'div'> {
    const {
      classes,
      children,
      inWidget,
      testId
    } = this.props
    const viewHeaderStyles = combineStyleClassNames(classes.wrapper, inWidget && classes.inWidget)

    return (
      <div className={viewHeaderStyles} data-testid={testId}>
        {children}
      </div>
    )
  }
}

export default withStyles(styles)(ViewHeaderText)
