// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.

import { RENOVATION_MEASURE_ID } from './attributes'
import { RENOVATION_SPACEGROUPS, SPACEGROUP, RENOVATION_SPACES, SPACE } from './contentTypes'

export const TVD_PATCH_OPERATION_TYPE_UPDATE: TVDPatchOperationTypeUPDATE = 'UPDATE'
export const TVD_PATCH_OPERATION_TYPE_MOVE: TVDPatchOperationTypeMOVE = 'MOVE'
export const TVD_PATCH_OPERATION_TYPE_RESET: TVDPatchOperationTypeRESET = 'RESET'
export const TVD_PATCH_OPERATION_TYPE_ADD: TVDPatchOperationTypeADD = 'ADD'
export const TVD_PATCH_OPERATION_TYPE_DELETE: TVDPatchOperationTypeDELETE = 'DELETE'

export const patchOperationRenovationMeasureBasePath = `/columnData/${RENOVATION_MEASURE_ID}`
export const patchOperationRenovationProfileBasePath = '/columnData/RenovationProfileId'

export const ALLOWED_TYPES_FOR_PATCH_OPERATIONS_BY_ID = {
  [RENOVATION_SPACEGROUPS]: [SPACEGROUP.toLowerCase()],
  [RENOVATION_SPACES]: [SPACE.toLowerCase()]
}
