// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.
import React from 'react'
import { debounce } from 'lodash'
import { withTranslation } from 'react-i18next'
import IconTooltip from '../../common/Tooltip/IconTooltip/IconTooltip'

import {
  SPACE,
  SPACEGROUP,
  CALCULATION_PROPERTIES,
  BUILDING_ELEMENTS_SCHEDULE,
  PRICEITEM,
  BUILDING_ELEMENTS_TASK,
  EQUIPMENT,
  AREA_EQUIPMENT,
  SURFACES,
  STORY_PROPERTY,
  ACCESS_CONTROL_USER_GROUP,
  MULTI_EDIT_SPACE_WIDGET,
  MULTI_EDIT_SPACE_GROUP_WIDGET
} from '../../../constants/contentTypes'
import { MODAL_TYPE_CALCULATION_INFORMATION } from '../../../constants/modalConstants'
import { ELEMENTS } from '../../../constants/moduleConstants'
import { markdownConcatStringsWithBlankLine, markdownConcatStrings } from '../../../utils/commonUtils'
import { getEstimatesInfoWithPropertyNameRequest } from '../../../utils/generated-api-requests/estimates'
import {
  getSpaceScheduleSpacesPropertiesInfoWithPropertyNameRequest,
  getSpaceScheduleSpaceGroupsPropertiesInfoWithPropertyNameRequest,
  getEstimatePropertiesInfoWithPropertyNameRequest,
  getBuildingElementsScheduleItemInfoWithItemIdRequest,
  getSpacesEquipmentInfoWithItemIdRequest,
  getSpacesSurfacesInfoWithItemIdRequest,
  getEstimateEquipmentInfoWithItemIdRequest,
  getSpacesEquipmentPropertiesInfoWithPropertyNameRequest,
  getDesignModelStoriesPropertiesInfoWithPropertyNameRequest
} from '../../../utils/generated-api-requests/spaces'
import {
  getPriceitemPropertiesInfoWithPropertyNameRequest,
  getBuildingTasksPropertiesInfoWithPropertyNameRequest,
  getScheduleItemInfoWithIdRequest,
} from '../../../utils/generated-api-requests/buildingelements'
import { getUserGroupsUsersWithIdRequest } from '../../../utils/generated-api-requests/users'

export const ESTIMATE_IS_PRIVATE_INFO = 'ESTIMATE_IS_PRIVATE_INFO'
export const CHANGE_LOCATION_INFO = 'CHANGE_LOCATION_INFO'
export const DATA_SOURCES_INFO = 'DATA_SOURCES_INFO'

type HOCProps = {|
  t: Function // i18n translate function
|}

type ReceivedProps = {|
  id: string, // id for e2e testing purposes
  infoData: Object, // infoData object for infotooltip request
  application: string, // currently open application
  icon?: Object | null, // prop to override default info icon
  placement?: string // prop to override default tooltip placement
|}

type Props = {|
  ...HOCProps,
  ...ReceivedProps
|}

type State = {
  tooltipText: string // a message that is received as request response
}

export class InfoPopover extends React.Component<Props, State> {
  static defaultProps = {
    id: 'undefined',
    icon: null
  }

  mouseOverTimer = null

  state = {
    tooltipText: '',
  }


  debouncedHandleMouseEnter = debounce(() => {
    this.handleMouseEnter()
  }, 500, { leading: true })

  debouncedHandleMouseLeave = debounce(() => {
    this.handleMouseLeave()
  }, 500, { leading: true })

  handleMouseEnter = () => {
    this.mouseOverTimer = setTimeout(this.getText.bind(this), 100)
  }

  handleMouseLeave = () => {
    clearTimeout(this.mouseOverTimer)
  }

  getText() {
    const { t, infoData, application } = this.props

    const setTooltipText = (tooltipText: string) => {
      this.setState({ tooltipText })
    }

    const successCb = (response: TVDRequestResponse) => {
      setTooltipText(response.data)
    }

    switch (infoData.type) {
      case BUILDING_ELEMENTS_SCHEDULE: {
        if (application === ELEMENTS) {
          getScheduleItemInfoWithIdRequest(
            { path: { id: infoData.id } },
            {},
            successCb
          )
          break
        }
        getBuildingElementsScheduleItemInfoWithItemIdRequest(
          { path: { itemId: infoData.itemId } },
          {},
          successCb
        )
        break
      }
      case PRICEITEM: {
        getPriceitemPropertiesInfoWithPropertyNameRequest(
          { path: { id: infoData.id, propertyName: infoData.propertyName } },
          {},
          successCb
        )
        break
      }
      case BUILDING_ELEMENTS_TASK: {
        getBuildingTasksPropertiesInfoWithPropertyNameRequest(
          { path: { propertyName: infoData.propertyName } },
          {},
          successCb
        )
        break
      }
      case CALCULATION_PROPERTIES: {
        getEstimatePropertiesInfoWithPropertyNameRequest(
          { path: { propertyName: infoData.propertyName } },
          {},
          successCb
        )
        break
      }
      case MODAL_TYPE_CALCULATION_INFORMATION: {
        getEstimatesInfoWithPropertyNameRequest(
          { path: { propertyName: infoData.propertyName } },
          {},
          successCb
        )
        break
      }
      case MULTI_EDIT_SPACE_WIDGET:
      case SPACE.toLowerCase(): {
        getSpaceScheduleSpacesPropertiesInfoWithPropertyNameRequest(
          { path: { id: infoData.id, propertyName: infoData.propertyName } },
          {},
          successCb
        )
        break
      }
      case MULTI_EDIT_SPACE_GROUP_WIDGET:
      case SPACEGROUP.toLowerCase(): {
        getSpaceScheduleSpaceGroupsPropertiesInfoWithPropertyNameRequest(
          { path: { id: infoData.id, propertyName: infoData.propertyName } },
          {},
          successCb
        )
        break
      }
      case EQUIPMENT: {
        // Equipment widget may have two kinds of rows: propertyRows or basic listRows
        // if infoData object contains propertyName key, row is a propertyRow and should
        // fetch the property info instead of equipment info
        if (infoData.propertyName) {
          getSpacesEquipmentPropertiesInfoWithPropertyNameRequest(
            { path: { spaceId: infoData.spaceId, id: infoData.id, propertyName: infoData.propertyName } },
            {},
            successCb
          )
        } else {
          getSpacesEquipmentInfoWithItemIdRequest(
            { path: { spaceId: infoData.spaceId, itemId: infoData.itemId } },
            {},
            successCb
          )
        }
        break
      }
      case AREA_EQUIPMENT: {
        getEstimateEquipmentInfoWithItemIdRequest(
          { path: { itemId: infoData.itemId } },
          {},
          successCb
        )
        break
      }
      case SURFACES: {
        getSpacesSurfacesInfoWithItemIdRequest(
          { path: { spaceId: infoData.spaceId, itemId: infoData.itemId, } },
          {},
          successCb
        )
        break
      }
      case ESTIMATE_IS_PRIVATE_INFO: {
        const messages = [t('calculationInformation._PRIVATE_ESTIMATE_INFO_'), t('calculationInformation._PUBLIC_ESTIMATE_INFO_')]
        setTooltipText(markdownConcatStringsWithBlankLine(messages))
        break
      }
      case STORY_PROPERTY: {
        getDesignModelStoriesPropertiesInfoWithPropertyNameRequest(
          { path: { propertyName: infoData.propertyName } },
          {},
          successCb
        )
        break
      }
      case ACCESS_CONTROL_USER_GROUP: {
        getUserGroupsUsersWithIdRequest(
          { path: { id: infoData.userGroupId } },
          {},
          (users: Array<TVDUserReducedWithPermissions>) => {
            const usersInGroup = users.map((user: TVDUserReducedWithPermissions) => `${user.firstName} ${user.lastName}`)
            // add "Users in a group: - title to tooltip as first item"
            usersInGroup.unshift(` ${t('buildingModal._USERS_IN_USER_GROUP_')} \n \`  \``)
            setTooltipText(markdownConcatStrings(usersInGroup))
          }
        )
        break
      }
      case CHANGE_LOCATION_INFO: {
        const messages = t(`siteInformation._${CHANGE_LOCATION_INFO}_`)
        setTooltipText(messages)
        break
      }
      case DATA_SOURCES_INFO: {
        const messages = [
          t('siteProperties._SOURCES_INFO_A_'),
          t('siteProperties._SOURCES_INFO_B_'),
          t('siteProperties._SOURCES_INFO_C_'),
          t('siteProperties._SOURCES_INFO_D_'),
          t('siteProperties._SOURCES_INFO_E_')
        ]
        setTooltipText(markdownConcatStringsWithBlankLine(messages))
        break
      }
      default: {
        console.log('switch case not found for type: ', infoData.type)
        break
      }
    }
  }

  render(): any {
    const {
      icon,
      infoData,
      id,
      placement
    } = this.props
    return (
      <div
        onMouseEnter={!this.state.tooltipText ? this.debouncedHandleMouseEnter : null}
        onMouseLeave={!this.state.tooltipText ? this.debouncedHandleMouseLeave : null}>
        <IconTooltip
          id={`${id}-InfoPopoverIcon`}
          text={this.state.tooltipText}
          list={infoData.type === ACCESS_CONTROL_USER_GROUP}
          data-testid={`${id}-InfoPopoverIcon`}
          placement={placement}>
          <IconTooltip.SimpleIcon
            icon={icon?.name || 'info_outlined'}
            size={icon?.size}
            iconClasses={icon?.classes} />
        </IconTooltip>
      </div>
    )
  }
}

export default withTranslation('translations')(InfoPopover)
