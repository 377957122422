// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.
import { createMuiTheme } from '@material-ui/core/styles'
import MUITheme from '../../node_modules/frontend-assets/src/theme/MUITheme'

const theme = createMuiTheme(MUITheme)

export type Theme = typeof theme

export default theme
