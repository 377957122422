// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { compose } from 'redux'
import { withRouter, Redirect } from 'react-router-dom'
import { withStyles } from '@material-ui/core'
import { typographyClasses, colors } from 'frontend-assets'

import { setModalTitle, closeModal } from '../../../actions/modals'
import FooterButtons from '../widgets/FooterButtons/FooterButtons'
import { type TextButtonProps } from '../../../components/common/TextButton/TextButton'
import { getSignOutRouteByPath } from '../../../utils/urlUtil'
import { refreshAccessToken } from '../../../actions/user'
import { TVD_TOKEN_USER_TYPE_USER } from '../../../constants/apiConstants'

const styles = () => ({
  contentWrapper: {
    ...typographyClasses.bodyBig,
    color: colors.dark80,
    marginBottom: 40
  }
})

// Properties received from Higher Order Components
type HOCProps = {|
  t: Function, // withTranslation translate function
  location: Object, // withRouter location object
  classes: Object, // withStyles classes object
|}

// Properties received from mapStateToProps function
type MappedProps = {|
  userType: $PropertyType<TVDApplicationStore, 'userType'>
|}

type DispatchProps = {|
  dispatchSetModalTitle: (title: string) => void, //  sets a new modal title for the modal found with modalId prop
  dispatchCloseModal: () => void, // closes modal with the id from the prop modalId
|}

// Properties received from parent elements in component tree
type ReceivedProps = {|
  // modalId is used in mDTP closeModal action
  // eslint-disable-next-line react/no-unused-prop-types
  modalId: string, // the id of the modal where the container is rendered in
  secondsUntillExpiration?: number // number of seconds until the access token is expired
|}

type Props = {
  ...HOCProps,
  ...MappedProps,
  ...DispatchProps,
  ...ReceivedProps,
}

type State = {|
  isExpired?: boolean,
  secondsUntillExpiration?: number,
  isSigningOff: boolean,
  isRenewingToken: boolean,
  timerIntervalId?: IntervalID,
|}

export class AccessTokenModalContainer extends Component<Props, State> {
  static defaultProps = {
    secondsUntillExpiration: undefined
  }

  state = {
    isExpired: undefined,
    secondsUntillExpiration: undefined,
    isSigningOff: false,
    isRenewingToken: false,
    timerIntervalId: undefined
  }

  componentDidMount = () => {
    const { secondsUntillExpiration } = this.props
    this.setState({ isExpired: !secondsUntillExpiration })
    if (secondsUntillExpiration) {
      this.startTimer(secondsUntillExpiration)
    }
  }

  componentDidUpdate = (prevProps: Props, prevState: State) => {
    const { dispatchSetModalTitle, t } = this.props
    const { isExpired } = this.state
    if (isExpired && !prevState.isExpired) {
      dispatchSetModalTitle(t('accessToken._EXPIRED_'))
    }
  }

  intervalCallback = (timeLeftSeconds: number) => {
    if (timeLeftSeconds === 0) {
      this.clearTimer()
      this.setState({ isExpired: true })
    } else {
      timeLeftSeconds -= 1
      this.setState({ secondsUntillExpiration: timeLeftSeconds })
    }
  }
  startTimer = (timerSeconds: number) => {
    this.setState({ secondsUntillExpiration: timerSeconds }, () => {
      const interval = setInterval(() => {
        this.intervalCallback(this.state.secondsUntillExpiration || 0)
      }, 1000)
      this.setState({ timerIntervalId: interval })
    })
  }

  clearTimer = () => {
    const { timerIntervalId } = this.state
    if (timerIntervalId) clearInterval(timerIntervalId)
  }

  getFooterButtons = (): Array<TextButtonProps> => {
    const { dispatchCloseModal, t, userType } = this.props
    const { isExpired } = this.state
    return [
      {
        text: isExpired ? t('login._LOGINTEXT_') : t('accessToken._CONTINUE_USING_'),
        onClick: () => {
          if (isExpired) {
            this.clearTimer()
            this.setState({ isSigningOff: true })
          } else {
            this.clearTimer()
            this.setState({ isRenewingToken: true })
            if (!userType) { console.error('userType from Store is undefined, reverting to normal user type') }
            refreshAccessToken(userType || TVD_TOKEN_USER_TYPE_USER, () => {
              this.setState({ isRenewingToken: false }, () => {
                dispatchCloseModal()
              })
            })
          }
        }
      }
    ]
  }

  getAccessTokenExpiredContent = (): React$Element<'div'> => {
    const { t } = this.props
    return <div>{t('accessToken._LOG_IN_TO_CONTINUE_')}</div>
  }

  getAccessTokenAboutToExpireContent = (): React$Element<typeof React.Fragment> => {
    const { secondsUntillExpiration } = this.state
    const { t } = this.props
    return (
      <>
        <div>
          {t('accessToken._TIME_LEFT_UNTIL_EXPIRATION_', { seconds: secondsUntillExpiration })}
        </div>
        <div>{t('accessToken._CONTINUE_INSTRUCTIONS_')}</div>
      </>
    )
  }

  getContent = (): React$Element<'div'> | React$Element<typeof React.Fragment> | null => {
    const { isExpired } = this.state
    if (typeof isExpired === 'undefined') return null
    if (isExpired) return this.getAccessTokenExpiredContent()
    return this.getAccessTokenAboutToExpireContent()
  }


  render = (): React$Element<typeof Redirect | typeof React.Fragment> => {
    const { location: { pathname }, classes } = this.props
    const { isSigningOff, isRenewingToken } = this.state
    if (isSigningOff) return <Redirect to={getSignOutRouteByPath(pathname)} />
    return (
      <>
        <div className={classes.contentWrapper}>
          {this.getContent()}
        </div>
        <FooterButtons disabled={isRenewingToken} items={this.getFooterButtons()} />
      </>
    )
  }
}

const mapStateToProps = ({ app: { userType } }: TVDReduxStore): MappedProps => ({
  userType,
})

const mapDispatchToProps = (dispatch: Function, { modalId }: ReceivedProps): DispatchProps => ({
  dispatchSetModalTitle: (title: string) => { dispatch(setModalTitle(title, modalId)) },
  dispatchCloseModal: () => { dispatch(closeModal(modalId)) }
})

export default compose(
  withTranslation('translations'),
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  withStyles(styles)
)(AccessTokenModalContainer)
