// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.

import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import Icon from '@material-ui/core/Icon'
import { combineStyleClassNames } from '../../../utils/styleUtils'

const styles = ({ palette }: TVDTheme) => ({
  iconWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 26,
    height: 26,
    cursor: 'pointer',
    borderRadius: '50%',
    marginRight: '5px'
  },
  activityLinkIcon: {
    color: palette.dark60,
    fontSize: 18,
    '&:hover': {
      backgroundColor: palette.gray40,
      borderRadius: '50%',
    },
    '&:active': {
      background: palette.primary60,
      color: palette.white
    }
  },
  activityLinkIconSelected: {
    backgroundColor: palette.primary60,
    color: palette.white,
    '&:hover': {
      backgroundColor: palette.primary60
    }
  }
})

type Props = {
  classes: Object, // withStyles classes object
  onClick: Function, // onClick callback
  selected?: boolean, // icon is selected or not
}

export class ActivityLinkIcon extends Component<Props> {
  static defaultProps = {
    selected: false
  }

  onClick = (event: SyntheticEvent<any>) => {
    event.stopPropagation()
    this.props.onClick()
  }

  render(): React$Element<any> {
    const { classes, selected } = this.props

    return (
      <div
        className={combineStyleClassNames(classes.iconWrapper, selected ? classes.activityLinkIconSelected : classes.activityLinkIcon)}>
        <Icon
          id='activityLink'
          style={{ fontSize: 18 }}
          onClick={this.onClick}>
          link
        </Icon>
      </div>
    )
  }
}

export default withStyles(styles)(ActivityLinkIcon)
