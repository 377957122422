// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.

import React, { Component } from 'react'
import { withStyles, Typography } from '@material-ui/core'
import { withTranslation } from 'react-i18next'

import LabeledInput from '../../common/LabeledInput/LabeledInput'
import ModalForm from '../../common/ModalForm/ModalForm'
import DropDownContainer from '../../containers/DropDownContainer/DropDownContainer'

export type DropdownModalContentProps = {|
  requestDefinition: TVDGARConfig, // request definitions for forming and executing a GAR
  message: string, // message that is displayed within the modal form
|}

type Props = {|
  classes: Object, // withStyles styles prop
  t: Function, // translation function
  saveButtonText: ?string, // optional text for save button given as translateKey
  onSave: (value: string, successCb: Function) => void, // onSave callback
  onClose: () => void, // close modal
  contentProps: DropdownModalContentProps
|}

type State = {
  dropdownValue: string, // sole input field
  selectedValue: TVDMenuItem, // value of the selected item in the list
}

const styles = ({ palette, typography }: TVDTheme): Object => ({
  message: {
    color: palette.nevada,
    fontFamily: typography.fontFamily,
    fontSize: 16,
    width: 500,
    padding: '0 0 18px'
  },
})

export class DropdownModal extends Component<Props, State> {
  state = {
    dropdownValue: '',
    selectedValue: {}
  }

  get message(): React$Element<typeof Typography> {
    const { classes, t, contentProps } = this.props
    return (
      <Typography className={classes.message}>{t(contentProps.message)}</Typography>
    )
  }

  get dropdown(): LabeledInput {
    const { contentProps: { requestDefinition } } = this.props

    return (
      <DropDownContainer
        requestDefinition={requestDefinition}
        searchable
        width='XXL'
        key='dropdownmodal-dropdown'
        id='modal-dropdown'
        dataType='item'
        onDropDownChange={this.handleDropdownChange}
        menuPosition='top' />)
  }

  items = (): Array<LabeledInput> => [this.message, this.dropdown]

  handleDropdownChange = (selectionValue: string, item: TVDMenuItem) => {
    this.setState({ dropdownValue: selectionValue, selectedValue: item })
  }

  handleSave = () => {
    this.props.onSave(this.state.dropdownValue, this.state.selectedValue)
    this.props.onClose()
  }

  render(): React$Element<any> {
    const { saveButtonText, onClose } = this.props
    const { dropdownValue } = this.state
    return (
      <ModalForm
        testId='DropdownModal'
        items={this.items()}
        valid={!!dropdownValue}
        onSave={this.handleSave}
        onClose={onClose}
        saveButtonText={saveButtonText} />
    )
  }
}

export default withTranslation('translations')(withStyles(styles)(DropdownModal))
