// @flow
/* eslint max-len: ["error", { "code": 99999200 }] */
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.

import React, { useEffect, useState, useRef } from 'react'
import { Redirect } from 'react-router-dom'
import { makeStyles, Paper } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import ReactMarkdown from 'react-markdown'
import { useDispatch } from 'react-redux'
import remarkGfm from 'remark-gfm'
import { TextButton } from 'frontend-components'
import { getLegalTermsOfServiceRequest, patchUsersSelfLegalConsentRequest } from '../../../utils/generated-api-requests/users'
import { logout } from '../../../actions/user'
import { getUserTypeFromPathname, getUsersAPIBasePath } from '../../../utils/apiUtils'
import { TVD_TOKEN_USER_TYPE_USER, TVD_HEADER_LANGUAGE } from '../../../constants/apiConstants'
import { supportedTOSLanguageCodes, supportedLanguageCodes } from '../../../constants/languageCodes'
import theme from '../../../styles/theme'

type HOCProps = {|
  location: Object, // withRouter location object
|}

type Props = {|
  ...HOCProps,
|}

const BGImage = './assets/images/login/haahtela_tvd_login_bg.jpg'
export const TermsOfServiceWrapperTestId = 'TermsOfService-WrapperTestId'
export const TermsOfServiceMarkdownWrapperTestId = 'TermsOfService-MarkdownWrapperTestId'

const useStyles = makeStyles(({ palette }: TVDTheme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    background: `url(${BGImage}) no-repeat center center fixed`,
    backgroundSize: 'cover'
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    height: '854px',
    maxHeight: '98vh',
    width: '740px',
    maxWidth: '98vw',
    backgroundColor: palette.white,
    borderRadius: '3px',
    color: palette.dark100
  },
  title: {
    marginTop: '80px',
    marginBottom: '56px',
    ...theme.typography.classes.loginHeadline,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  TOSTextWrapper: {
    height: '581px',
    overflowY: 'scroll',
    marginLeft: '85px',
    marginRight: '85px'
  },
  footerButtons: {
    marginTop: '40px',
    marginBottom: '48px',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  buttonsWrapper: {
    '& button': {
      width: '140px'
    },
    '& button:first-of-type': {
      marginRight: '6px'
    }
  },
  reactMarkDown: {
    '& p': {
      ...theme.typography.classes.bodyDefault
    },
    '& h2': {
      ...theme.typography.classes.h4
    },
    '& h3': {
      ...theme.typography.classes.bodyBigBold
    },
    '& tr, td': {
      ...theme.typography.classes.bodyDefault
    }
  },
  '::-webkit-scrollbar': {
    width: '10px'
  }
}))

const onScroll = (el: HTMLElement | Object, hasScrolledDown: boolean, setHasScrolledDownCb: () => void) => {
  if (!hasScrolledDown) {
    // Adding bit of tolerance so that the user does not have to have scrolled down pixel perfectly
    if (el.scrollHeight / (el.scrollTop + el.offsetHeight) < 1.006) {
      setHasScrolledDownCb()
    }
  }
}

const defaultFromLocation = { pathname: '/', search: '' }

const TermsOfService = ({ location }: Props) => {
  const { state: { from } = {} } = location
  const dispatch = useDispatch()
  const classes = useStyles()
  const { t, i18n } = useTranslation()
  const [TOSText, setTOSText] = useState('')
  const [GAROptions, setGAROptions] = useState('')
  const [TOSLanguageCode, setTOSLanguageCode] = useState('')
  const [stateFrom, setFrom] = useState(defaultFromLocation)
  const [TOSConsentPatchBody, setTOSConsentPatchBody] = useState({})
  const [hasAcceptedTOS, setHasAcceptedTOS] = useState(undefined)
  const [hasScrolledDown, setHasScrolledDown] = useState(false)
  const TOSRef = useRef()
  const fromPathname = from ? from.pathname : defaultFromLocation.pathname

  useEffect(() => {
    // $FlowFixMe userLanguage is for IE compatibility https://developer.mozilla.org/en-US/docs/Web/API/NavigatorLanguage/language
    const userLang = navigator.language || navigator.userLanguage
    if (userLang) {
      const [languageCode] = userLang.split('-')
      const supportedTOSLanguageCode = supportedTOSLanguageCodes[languageCode]
      const userLanguageCode = supportedTOSLanguageCode || supportedTOSLanguageCodes[supportedLanguageCodes.en]
      i18n.changeLanguage(userLanguageCode)
      setTOSLanguageCode(userLanguageCode)
    }
  }, [i18n, TOSLanguageCode])

  useEffect(() => {
    if (
      from &&
      // preventing setting these as redirect routes as the user might end up in a loop or with blank page
      !from.pathname.startsWith('/auth') &&
      !from.pathname.startsWith('/terms-of-service')
    ) {
      setFrom(from)
    }
  }, [from])

  useEffect(() => {
    const userType = getUserTypeFromPathname(fromPathname)
    if (!userType) {
      console.error(`Could not resolve user type from pathname ${fromPathname}`)
    }
    const userAPIBasePath = getUsersAPIBasePath(userType || TVD_TOKEN_USER_TYPE_USER)
    const options = { basePath: userAPIBasePath, languageCode: TOSLanguageCode }
    setGAROptions(options)
    if (TOSLanguageCode) {
      getLegalTermsOfServiceRequest({}, ({ termsText, termsVersion, termsHash }: TVDTermsOfService) => {
        setTOSText(termsText)
        setTOSConsentPatchBody({ consentVersion: termsVersion, consentHash: termsHash })
        onScroll(TOSRef.current, false, () => { setHasScrolledDown(true) })
      }, null, {
        ...options,
        headers: { [TVD_HEADER_LANGUAGE]: TOSLanguageCode }
      })
    }
  }, [TOSLanguageCode, fromPathname])


  if (hasAcceptedTOS) {
    return <Redirect to={stateFrom} />
  }

  return (
    <>
      <div data-testid={TermsOfServiceWrapperTestId} className={classes.wrapper}>
        <Paper className={classes.paper}>
          <div className={classes.title}>{t('termsOfService._TERMS_OF_SERVICE_')}</div>
          <div
            data-testid={TermsOfServiceMarkdownWrapperTestId}
            ref={TOSRef}
            onScroll={() => {
            onScroll(TOSRef.current, hasScrolledDown, () => { setHasScrolledDown(true) })
          }}
            className={classes.TOSTextWrapper}>
            <ReactMarkdown remarkPlugins={[remarkGfm]} className={classes.reactMarkDown}>{TOSText}</ReactMarkdown>
          </div>
          <div className={classes.footerButtons}>
            <div className={classes.buttonsWrapper}>
              <TextButton
                disabled={!hasScrolledDown}
                onClick={() => {
                setHasAcceptedTOS(true)
                patchUsersSelfLegalConsentRequest(
                  { body: TOSConsentPatchBody },
                  {},
                  () => { setHasAcceptedTOS(true) },
                  null,
                  GAROptions
                )
              }}
                text={t('termsOfService._I_ACCEPT_')}
                variant='contained' />
              <TextButton
                onClick={() => { dispatch(logout()) }}
                text={t('termsOfService._I_DONT_ACCEPT_')}
                variant='outlined' />
            </div>
          </div>
        </Paper>
      </div>
    </>
  )
}

export default TermsOfService
