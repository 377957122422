// @flow
// Copyright © 2010–2022 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.

import React, { Component } from 'react'
import { connect, batch } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import { compose } from 'redux'
import { colors } from 'frontend-assets'
import { toggleDrawer } from '../../../actions/view/actions'
import { createQueryParamsString } from '../../../utils/urlUtil'
import { deleteEstimatesLockWithEstimateIdRequest } from '../../../utils/generated-api-requests/estimates'
import {
  setBuildingId,
  setBuildingName,
  setCalculation,
  clearPrimaryEstimatesForModules,
  setApplication,
  setRealEstateId,
  setRealEstateName,
  setLicenseType,
  setIsEstimateLockedToCurrentUser
} from '../../../actions/app'
import { resetActiveRealEstate } from '../../../actions/activeRealEstate'
import {
  clearWidgets
} from '../../../actions/widgets'
import theme from '../../../styles/theme'
// $FlowFixMe
import { ReactComponent as ArrowIcon } from '../../../../node_modules/frontend-assets/static/assets/images/icons/arrowDown.svg'


export const headerHeight = 60

const styles = ({ palette }: TVDTheme): Object => ({
  widgetHeader: {
    flexDirection: 'column',
    boxShadow: 'none',
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: 1201
  },
  appBar: {
    display: 'flex',
    backgroundColor: palette.white,
    justifyContent: 'space-between',
    alignItems: 'center',
    height: headerHeight,
    minHeight: headerHeight,
    padding: '0 24px 0 0',
    width: '100vw'
  },
  appRight: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    paddingLeft: '24px',
    paddingRight: '8px',
    cursor: 'pointer'
  },
  iconButton: {
    padding: 0,
    '& path': {
      fill: palette.dark80,
    },
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  usernameTypography: {
    paddingRight: '10px',
    ...theme.typography.classes.bodyDefault,
    color: palette.dark80,
    '&:hover': {
      color: palette.primary100
    }
  },
  verticalCenter: {
    display: 'flex',
    alignItems: 'center',
    padding: '24px'
  }
})

type HOCProps = {|
  history: Object, // history object from withRouter
  classes: Object, // classes-object created by withstyles function
|}

type MappedProps = {|
  selectedAccountId?: string, // the name of the currently selected account
  isDrawerOpen: boolean, // status of the drawer,
  claims: Object, // object containing user claims that are used for showing information about the logged in user
  isEstimateLockedToCurrentUser: $PropertyType<TVDApplicationStore, 'isEstimateLockedToCurrentUser'>, // if the user owns the lock for the estimate
  activeEstimate: TVDActiveEstimateStore, // currently active estimate
|}

type DispatchProps = {|
  dispatchToggleDrawer: Function, // function that dispatches drawer toggling
  dispatchClearStore: () => void, // clears data from store so we can reselect a real estate
|}

type Props = {
  ...HOCProps,
  ...MappedProps,
  ...DispatchProps
}

class Header extends Component<Props> {
  get appBar(): React$Element<typeof Toolbar> {
    const {
      classes,
      dispatchToggleDrawer,
      isDrawerOpen,
      claims,
    } = this.props
    const onClick = () => {
      dispatchToggleDrawer(!isDrawerOpen)
    }
    const logoDisplay = () => {
      if (window.location.pathname === '/') return <span />

      return (
        <div>
          <a href='/' onClick={this.onLogoClick} className={classes.verticalCenter}>
            <img
              height='13px'
              alt='logo and selected property'
              src='/assets/images/realaizer/Realaizer_logo.svg' />
          </a>
        </div>
      )
    }

    return (
      <Toolbar
        classes={{
          root: classes.appBar
        }}
        style={{
          backgroundColor: (
          window.location.pathname === '/'
        ) ? colors.white : colors.gray20
        }}>
        {logoDisplay()}
        <div className={classes.appRight} onClick={onClick} role='link' tabIndex={0}>
          <Typography classes={{ root: classes.usernameTypography }}>{claims.firstName} {claims.lastName}</Typography>
          <IconButton data-testid='DrawerRight-open-icon' className={classes.iconButton}>
            <ArrowIcon />
          </IconButton>
        </div>
      </Toolbar>
    )
  }

  onLogoClick = (event: SyntheticMouseEvent<any>) => {
    const { history, dispatchClearStore, activeEstimate } = this.props
    event.preventDefault()
    const { isEstimateLockedToCurrentUser, selectedAccountId } = this.props
    const url = `/${createQueryParamsString({ accountId: selectedAccountId })}`
    if (isEstimateLockedToCurrentUser && !!activeEstimate) {
      deleteEstimatesLockWithEstimateIdRequest(
        {},
        () => {
          dispatchClearStore()
          history.push(url)
        },
        () => {
          console.error('Could not remove estimate lock')
        }
      )
    } else {
      dispatchClearStore()
      history.push(url)
    }
  }

  render(): React$Element<'div'> {
    const { classes } = this.props
    return (
      <AppBar classes={{ root: classes.widgetHeader }}>
        {this.appBar}
      </AppBar>
    )
  }
}

const mapDispatchToProps = (dispatch: Function): DispatchProps => ({
  dispatchToggleDrawer: () => { dispatch(toggleDrawer(true)) },
  dispatchClearStore: () => {
    batch(() => {
      dispatch(setBuildingId(''))
      dispatch(setBuildingName(''))
      dispatch(setCalculation(''))
      dispatch(clearPrimaryEstimatesForModules())
      dispatch(clearWidgets())
      dispatch(setApplication(''))
      dispatch(setRealEstateId(''))
      dispatch(setRealEstateName(''))
      dispatch(setLicenseType(''))
      dispatch(setIsEstimateLockedToCurrentUser(false))
      dispatch(resetActiveRealEstate())
    })
  }
})

const mapStateToProps = ({
  view: { isDrawerOpen },
  app: {
    isEstimateLockedToCurrentUser,
    selectedAccountId
  },
  user: { claims },
  activeEstimate
}: TVDReduxStore): MappedProps => ({
  isDrawerOpen,
  claims,
  selectedAccountId,
  isEstimateLockedToCurrentUser,
  activeEstimate
})

export default compose(
  withRouter,
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(Header)
