// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.

import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { boxShadows } from 'frontend-assets'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import MenuList from '@material-ui/core/MenuList'
import Paper from '@material-ui/core/Paper'
import MenuItems from '../components/MenuItems/MenuItems'

const { boxShadowComponent } = boxShadows

const styles = (): Object => ({
  menuPaper: {
    boxShadow: boxShadowComponent,
    borderRadius: '2px'
  },
})

type Props = {
  classes: Object, // withStyles classes object
  items: Array<TVDMenuItem>, // items for the menu
  id: string, // id for testing purposes
  location: Object, // location of the click
  isSubMenu: boolean, // indicates if contextMenu is a submenu of another contextMenu
  handleClose: () => void, // closes the menu
}

export class ContextMenu extends Component<Props> {
  render(): React$Element<any> {
    const {
      handleClose,
      items,
      location,
      id,
      classes,
      isSubMenu
    } = this.props

    const style = {
      position: isSubMenu ? 'absolute' : 'fixed',
      top: location.y,
      left: location.x,
      zIndex: location.zIndex || 0
    }

    return (
      <div id={id} style={style}>
        <ClickAwayListener onClickAway={handleClose}>
          <Paper classes={{ root: classes.menuPaper }} onClick={(e: SyntheticEvent<any>) => e.stopPropagation()}>
            <MenuList>
              <MenuItems items={items} handleClose={handleClose}>
                {
                  ({ menuItems }: Function) => menuItems
                }
              </MenuItems>
            </MenuList>
          </Paper>
        </ClickAwayListener>
      </div>
    )
  }
}

export default withStyles(styles)(ContextMenu)
