// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.

import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { map, find } from 'lodash'
import InputField from '../InputField/InputField'
import DropdownMenu from '../menus/DropdownMenu/DropdownMenu'
import CheckBox from '../CheckBox/CheckBox'
import Tooltip from '../Tooltip/Tooltip'

const styles = ({ palette }: Object): Object => ({
  container: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    marginRight: '15px'
  },
  infoBall: {
    position: 'absolute',
    right: '-15px',
    background: palette.tint02,
    borderRadius: '50%',
    width: '5px',
    height: '5px'
  }
})

export type MultipleEditTypes = 'enum' | 'string' | 'number' | 'integer' | 'boolean' | 'year'

export type MultipleEditInputProps = {|
  dataType: MultipleEditTypes, // used to automatically select the correct input
  multiselectedItems: any, // array of strings or booleans of all the unique selected values. Used for parsing the tooltip.
  onChange: (selection: string | boolean | number) => {}, // passed to the respective input components and works as it would with each one
  items: Array<Object>, // items for DropdownMenus only
  placeholderText?: string, // placeholder for the component
  tooltipText?: string, // if you want to override the generated tooltip
  id?: string, // for testing
  unit?: string, // for InputFields only
  booleanTooltipText: string, // for boolean values
  value: any, // selected value of the row
|}

type Props = {
  ...MultipleEditInputProps,
  classes: Object, // withStyles styles prop
}

type State = {
  checkboxState: boolean,
  edited: boolean
}

export class MultipleEditInput extends Component<Props, State> {
  static defaultProps = {
    items: [],
    multiselectedItems: [],
    tooltipText: '',
    booleanTooltipText: '',
    placeholderText: ''
  }

  state = {
    // if the multiselection is 1 item long it's in a stable state and should render in that state
    checkboxState: this.props.multiselectedItems.length === 1 ? Boolean(this.props.multiselectedItems[0]) : false,
    edited: false
  }

  get infoBall(): any {
    const { classes, dataType } = this.props
    let ballPosition

    switch (dataType) {
      case 'boolean':
        ballPosition = '0px'
        break
      default:
        ballPosition = '5px'
        break
    }

    return <span className={classes.infoBall} style={{ top: ballPosition }} id='InfoBall' />
  }

  get input(): any {
    const {
      dataType,
      items,
      placeholderText,
      id,
      unit,
      multiselectedItems,
      value
    } = this.props

    const { checkboxState } = this.state

    switch (dataType) {
      case 'enum':
        return (
          <DropdownMenu
            items={items}
            placeholder={items.length ? placeholderText : ''}
            id={id}
            onChange={this.handleChange}
            defaultValue={value}
            minimalist />
        )
      case 'string':
      case 'number':
      case 'integer':
      case 'year':
        return (
          <InputField
            dataType={dataType}
            placeholder={this.tooltipText() ? placeholderText : null}
            initialValue={value}
            id={id}
            onChange={this.handleChange}
            unit={unit} />
        )
      case 'boolean':
        return <CheckBox checked={checkboxState} onChange={this.switchCheckboxChange} indeterminate={Boolean(multiselectedItems.length > 1)} />
      default:
        console.error(`MultipleEditInput must be given one of the supported dataType props: \n
        enum, string, number, integer, year, boolean`)
        return <div />
    }
  }

  switchCheckboxChange = () => {
    this.setState(
      (prevState: Object) => ({ checkboxState: !prevState.checkboxState }),
      () => { this.handleChange(this.state.checkboxState) }
    )
  }

  tooltipText = () => {
    const {
      multiselectedItems,
      dataType,
      items,
      tooltipText
    } = this.props

    // if tooltip was overriden, return that
    if (tooltipText) return tooltipText

    // empty or one item which means there is no multiselection situation
    if (multiselectedItems.length <= 1 || this.state.edited) return ''

    // this will be needed in all the other cases, initialized with the multiselectedItems in case this isn't a dropdown
    let multiItems = multiselectedItems

    // in dropdowns we need to parse the human-readable texts instead of using the values, if there are no items there's nothing to compare
    if (dataType === 'enum' && multiselectedItems.length > 1 && items.length > 0) {
      multiItems = map(multiselectedItems, (multiselectItem: string) => find(items, ['value', multiselectItem]).localizedName)
    }

    // all inputfield type multiItem lists and the enum type multiItem list just parsed are turned into a react-markdown usable string
    const result = multiItems.length >= 6 ?
      `${multiItems.slice(0, 5).toString()}  \n ...` :
      `${multiItems.toString()}`

    return result.replace(/,/g, '  \n')
  }

  checkboxTooltip = () => {
    const { multiselectedItems, booleanTooltipText } = this.props
    if (multiselectedItems.length > 1 && !this.state.edited) return booleanTooltipText
    return ''
  }

  handleChange = (selection: any) => {
    const { onChange } = this.props

    this.setState({ edited: true }, () => { if (onChange) onChange(selection) })
  }

  render(): React$Element<any> {
    const { classes, dataType } = this.props

    // checkboxes require their own logic
    const tooltip = dataType === 'boolean' ? this.checkboxTooltip() : this.tooltipText()
    return (
      <Tooltip text={tooltip} disablePortal>
        <div className={classes.container}>
          {this.input}
          {tooltip ? this.infoBall : null}
        </div>
      </Tooltip>
    )
  }
}

export default withStyles(styles)(MultipleEditInput)
