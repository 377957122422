// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.

import React from 'react'

import UserInput from './UserInput/UserInput'
import ValueField from './ValueField/ValueField'

import { roundToPrecision } from '../../../utils/commonUtils'
import Unit from '../Unit/Unit'

type Props = {|
  id: string, // id for testing
  initialValue?: number | string, // forces the component selection to this value
  unit?: string | null, // unit of the inputfield value
  disabled?: boolean, // whether the InputField is in a disabled state
  dataType: string, // datatype of inputfield, used for validating input value,
  displayDataType?: ?string, // optional datatype to display different datatype in ValueField than in UserInput
  onChange: (value: any) => void, // triggered when the input is saved, through onblur or onKeyDown, returns the current input state depending on the dataType prop
  width?: ?number | string, // set width for the InputField
  placeholder?: ?string, // placeholder text for the input
  alignLeft?: boolean, // align InputField text to the left side of the container
  validityChangeCb?: (boolean) => void, // optional callback when the validity of the input changes
  customValidator?: () => any // custom validator for input
|}

type State = {
  isOpen: boolean,
  inputValue: number | string,
}

class InputField extends React.PureComponent<Props, State> {
  static defaultProps = {
    id: 'InputField',
    dataType: 'string',
    displayDataType: null,
    disabled: false,
    initialValue: '',
    width: null,
    placeholder: '',
    alignLeft: false,
    unit: null,
    validityChangeCb: undefined,
    customValidator: undefined
  }

  state = {
    isOpen: false,
    inputValue: ''
  }

  componentDidMount() {
    const { initialValue } = this.props
    if (initialValue || initialValue === 0) this.setState({ inputValue: initialValue })
  }

  componentDidUpdate(prevProps: Object) {
    if (prevProps.initialValue !== this.props.initialValue) this.setState({ inputValue: this.props.initialValue })
  }

  get valueField(): React$Element<any> {
    const {
      disabled,
      dataType,
      displayDataType,
      initialValue,
      id,
      placeholder,
      alignLeft
    } = this.props
    const checkPlaceholder = placeholder ? disabled : disabled || initialValue === null
    return (
      <ValueField
        alignLeft={alignLeft}
        onClick={this.open}
        placeholder={placeholder}
        id={id}
        disabled={checkPlaceholder}
        dataType={displayDataType || dataType}>
        {this.state.inputValue}
      </ValueField>
    )
  }

  get inputField(): React$Element<any> {
    const {
      id,
      dataType,
      placeholder,
      alignLeft,
      validityChangeCb,
      customValidator
    } = this.props

    return (
      <UserInput
        customValidator={customValidator}
        validityChangeCb={validityChangeCb}
        alignLeft={alignLeft}
        dataType={dataType}
        initialValue={this.state.inputValue}
        onSave={this.handleInputFieldSave}
        closeInput={this.close}
        placeholder={placeholder}
        id={id} />
    )
  }

  handleInputFieldSave = (inputValue: string | number) => {
    const { onChange } = this.props
    const maximumAmountOfDigitsToShow = 4
    const roundedStateinputValue = (typeof this.state.inputValue === 'string')
      ? this.state.inputValue
      : roundToPrecision(this.state.inputValue, maximumAmountOfDigitsToShow)

    if (inputValue === roundedStateinputValue) {
      this.setState({ isOpen: false })
    } else {
      this.setState({ inputValue, isOpen: false }, () => {
        if (onChange) onChange(this.state.inputValue)
      })
    }
  }

  close = (callback: Function) => this.setState({ isOpen: false }, () => callback)

  open = (callback: Function) => this.setState({ isOpen: true }, () => callback)

  render(): React$Element<any> {
    const { width } = this.props
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          height: '30px',
          width
        }}>
        {this.state.isOpen ? this.inputField : this.valueField}
        {this.props.unit && <Unit value={this.props.unit} editable /> }
      </div>
    )
  }
}

export default InputField
