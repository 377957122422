// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { buildConfirmationModal, closeModal } from '../../../actions/modals'
import { MODAL_TYPE_ADD_USER_MODAL } from '../../../constants/modalConstants'
import { postUsersRequest } from '../../../utils/generated-api-requests/users'
import { getRealEstatesUserGroupsWithRealEstateIdRequest } from '../../../utils/generated-api-requests/realestate'

import UserInfo, { type UserDataObject } from '../UserInfo/UserInfo'
// TODO: Remove after confirming what is the way to add new users in the new user access (PPR-321)
// import { type AutoCompleteMenuItem } from '../../common/menus/AutoCompleteMenu/AutoCompleteMenu'
import { getUsersAPIBasePath } from '../../../utils/apiUtils'
import {
  TVD_TOKEN_USER_TYPE_USER,
} from '../../../constants/apiConstants'


// Properties received from mapStateToProps function
type MappedProps = {|
  userType: $PropertyType<TVDApplicationStore, 'userType'>,
|}

type DispatchProps = {|
  dispatchCloseModal: () => void, // function to close user modal
  dispatchBuildConfirmationModal: (Object) => void, // function to create confirmationDialog if form is modified and closed
|}

// Properties received from parent elements in component tree
type ReceivedProps = {|
  postUserSuccessCallBack: () => void, // callback function triggered when posting new user is successful
  realEstateId: $PropertyType<TVDApplicationStore, 'realEstateId'>
|}

type Props = {
  ...MappedProps,
  ...DispatchProps,
  ...ReceivedProps,
}

type State = {|
  groups: any[]
|}


export class AddUserModalContainer extends Component<Props, State> {
  state = {
    groups: [],
  }
  componentDidMount = () => {
    const { realEstateId, userType } = this.props
    if (userType === TVD_TOKEN_USER_TYPE_USER) {
      getRealEstatesUserGroupsWithRealEstateIdRequest(
        {
          path: { realEstateId }
        },
        {},
        (userGroupData: any): void => {
          const {
            HALParsedData: {
              _embedded: {
                usergroups: realEstateUserGroups
              }
            }
          } = userGroupData
          const asdf = realEstateUserGroups.map(({ id, localizedDescription }: any): TVDMenuItem =>
            ({
              value: id,
              localizedName: localizedDescription
            }))
          this.setState({ groups: asdf })
        }
      )
    }
  }

  getUsersAPIOptions = (): {|
    basePath: typeof undefined | string
  |} => {
    const { userType } = this.props
    return { basePath: userType && getUsersAPIBasePath(userType) }
  }

  handleSave = (userData: UserDataObject) => {
    const {
      dispatchCloseModal,
      postUserSuccessCallBack,
    } = this.props

    const {
      firstName,
      lastName,
      email,
      phone,
      userAddedToGroups,
      selectedRealEstateUserGroupId
    } = userData

    const groups = userAddedToGroups.map((group: any): string => group.id)
    if (selectedRealEstateUserGroupId) {
      groups.push(selectedRealEstateUserGroupId)
    }

    postUsersRequest(
      {
        query: {
          userGroupIds: groups
        },
        body: {
          firstName,
          lastName,
          email,
          phone,
          status: 'active'
        }
      },
      {},
      () => {
        dispatchCloseModal()
        postUserSuccessCallBack()
      },
      null,
      this.getUsersAPIOptions()
    )
  }


  render(): React$Element<typeof UserInfo> {
    const {
      dispatchCloseModal,
      dispatchBuildConfirmationModal,
      userType,
    } = this.props
    const { groups } = this.state
    return (
      <UserInfo
        groups={groups}
        userType={userType}
        modalType={MODAL_TYPE_ADD_USER_MODAL}
        onClose={dispatchCloseModal}
        onSave={this.handleSave}
        confirm={dispatchBuildConfirmationModal} />
    )
  }
}
const mapStateToProps = ({ app: { userType } }: TVDReduxStore): MappedProps => ({
  userType
})

const mapDispatchToProps = (dispatch: Function): DispatchProps => ({
  dispatchBuildConfirmationModal: (content: Object) => { dispatch(buildConfirmationModal(content)) },
  dispatchCloseModal: () => { dispatch(closeModal(MODAL_TYPE_ADD_USER_MODAL)) }
})

export default connect(mapStateToProps, mapDispatchToProps)(AddUserModalContainer)
