// @flow
// Copyright © 2010–2023 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.
import React from 'react'
import { makeStyles } from '@material-ui/core'
import { headerHeight } from '../../../components/common/Header/Header'
import LocationAnalysisMFEContainer from '../../../components/containers/LocationAnalysisMFEContainer/LocationAnalysisMFEContainer'

const useStyles = makeStyles(({ palette }: TVDTheme) => ({
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
    paddingTop: headerHeight,
    background: palette.gray20,
  },
  innerContainer: {
    background: palette.white,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  }
}))

export const LocationAnalysis = (): React$Element<'div'> => {
  const classes = useStyles()
  return (
    <div className={classes.contentWrapper}>
      <div className={classes.innerContainer}>
        <LocationAnalysisMFEContainer />
      </div>
    </div>
  )
}

export default LocationAnalysis

