// @flow
// Copyright © 2010–2022 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.

import React, { useEffect, useState } from 'react'
import ReactMarkdown from 'react-markdown'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import Icon from '@material-ui/core/Icon'
import Link from '@material-ui/core/Link'
import { typographyClasses, colors } from 'frontend-assets'
import { getUsersSelfServiceMessagesRequest } from '../../../utils/generated-api-requests/users'
import { formattedDate } from '../../../utils/commonUtils'
import { RELEASE_VERSION } from '../../../constants/envConstants'

const { primary100 } = colors
const {
  bodyBigAllCaps,
  bodyDefault,
  bodyDefaultBold,
  bodyBigBold,
  h4
} = typographyClasses

const useStyles = makeStyles(() => ({
  modal: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    marginBottom: '36px'
  },
  logoContainer: {
    margin: '0 auto',
    textAlign: 'center',
    marginBottom: '18px'
  },
  brandLogo: {
    paddingTop: '30px',
    paddingBottom: '18px',
    width: 'auto',
    height: '80px'
  },
  releaseNotesContainer: {
    ...bodyDefault,
    maxWidth: '550px',
    color: colors.dark80,
    maxHeight: '600px',
    overflowY: 'auto',
    '& [class*="MuiListItemText-root"]': {
      flex: 'inherit',
      marginRight: '5px'
    },
  },
  releaseNotesTitle: {
    marginLeft: '16px',
    display: 'flex',
    marginBottom: '4px'
  },
  circleIcon: {
    fontSize: '6px',
    alignSelf: 'center',
    marginRight: '10px',
    marginLeft: '12px'
  },
  contactInfoContainer: {
    marginTop: '30px',
    color: colors.primary100,
  },
  releaseNotesLink: {
    cursor: 'pointer',
    color: colors.primary100,
    fontSize: '14px',
    marginLeft: '2px',
    textTransform: 'lowercase',
    '&:hover': {
      textDecoration: 'none'
    },
  },
  listItemContainer: {
    '& [class*="MuiTypography-root"]': {
      color: colors.dark80
    },
  },
  messageTextMarkdown: {
    ...bodyDefault,
    '& h2': {
      ...bodyBigAllCaps,
      fontWeight: 400 // HTML element h2 needs this specification not to be bold
    },
    '& h3': {
      ...bodyBigBold,
      '&:first-of-type': {
        ...h4
      }
    },
    '& h4': {
      ...bodyDefaultBold
    },
    '& a': {
      color: primary100
    }
  },
  version: {
    ...h4,
    width: '100%',
    color: colors.primary100,
    fontSize: '18px',
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '30px'
  }
}))

const ReleaseNotesModal = (): React$Element<'div'> => {
  const classes = useStyles()
  const { t } = useTranslation()
  const [openReleaseNotesDetails, setOpenReleaseNotesDetails] = useState(false)
  const [selectedReleaseNote, setSelectedReleaseNote] = useState(0)
  const [releaseNotes, setReleaseNotes] = useState<TVDServiceMessage[]>([])

  useEffect(() => {
    getUsersSelfServiceMessagesRequest(
      {},
      (data: {[key: string]: TVDServiceMessage}) => {
        const releaseNotesData = ((Object.values(data): any): TVDServiceMessage[])
          .sort((a: TVDServiceMessage, b: TVDServiceMessage) =>
            Date.parse(b.startDate) - Date.parse(a.startDate))
          .filter((info: TVDServiceMessage) => info.messageType === 'releaseNotes')

        if (!releaseNotesData) return
        setReleaseNotes(releaseNotesData)
      },
      null,
      {}
    )
  }, [])

  const getTVDLogo = (): React$Element<'span'> => (
    <span className={classes.logoContainer}>
      <img
        data-testid='test-logo'
        className={classes.brandLogo}
        src='/assets/images/realaizer/Realaizer_logo.svg'
        alt='Realaizer logo' />
    </span>
  )

  const getReleaseNotes = (): React$Element<'div'> => (
    <div className={classes.releaseNotesContainer} data-testid='test-release-container'>
      {releaseNotes.map(({ releaseDate, createdDate }: TVDServiceMessage, index: number): React$Element<'span'> => {
          const dateToDisplay = releaseDate || createdDate || ''
          return (
            <span key={(releaseDate || createdDate) || index} className={classes.releaseNotesTitle}>
              <Icon className={classes.circleIcon}>circle</Icon>
              <span data-testid='release-notes-modal-info-text'>
                {
              // version will be later added to come from API
              // BE: https://haahtela-kehitys.atlassian.net/browse/HAAH-13008
              // FE: https://haahtela-kehitys.atlassian.net/browse/HAAH-13007
            }
                {t('maintenanceOutageNotification._SYSTEM_UPDATED_TITLE_', {
                date: formattedDate(dateToDisplay)
            })}
              </span>
              <Link
                data-testid='test-release-link'
                onClick={() => {
              setOpenReleaseNotesDetails(true)
              setSelectedReleaseNote(index)
            }}
                className={classes.releaseNotesLink}>
                ({t('maintenanceOutageNotification._SHOW_UPDATE_INFORMATION_')})
              </Link>
            </span>
          )
})}
      <div className={classes.contactInfoContainer}>
        <Typography>https://support.realaizer.com/</Typography>
        <Typography>
          tuki@haahtela.fi
        </Typography>
      </div>
    </div>
  )

  const getReleasedNotesDetails = (index: number): React$Element<'div'> => {
    const releaseNote = releaseNotes[index]

    return (
      <div className={classes.listItemContainer} data-testid='test-released-notes' key={releaseNote.id}>
        <ReactMarkdown className={classes.messageTextMarkdown}>{releaseNote.messageText}</ReactMarkdown>
      </div>
    )
  }

  const displayContent = (): React$Element<'div'> => {
    if (!openReleaseNotesDetails) return getReleaseNotes()
    return (
      <div className={classes.releaseNotesContainer}>
        {getReleasedNotesDetails(selectedReleaseNote)}
      </div>
    )
  }

  return (
    <div className={classes.modal}>
      {getTVDLogo()}
      <div className={classes.version}>V{RELEASE_VERSION}</div>
      {displayContent()}
    </div>
  )
}

export default ReleaseNotesModal
