/* eslint-disable react-hooks/exhaustive-deps */
// @flow
// Copyright © 2010–2022 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.
import React, { useCallback, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { loadMFE, registeredMFEs } from '../../../utils/MFEUtils'
import {
  API_BASE_URL,
  ALLOW_WITH_CREDENTIALS,
  FEATURE_SET
} from '../../../constants/envConstants'
import { ACCOUNT_INFORMATION_VIEW_KEY, ACCOUNT_INFORMATION_VIEW_VALUE_ESTATE_MANAGEMENT } from '../../../constants/searchParams'
import { openGenericErrorModal } from '../../../utils/modalUtils'
import { dispatch } from '../../../store'
import { BUILDING_SETTINGS, TERMS_OF_SERVICE, RENAME_PROJECT } from '../../../constants/modalConstants'
import { closeModal, openModal } from '../../../actions/modals'
import { createQueryParamsString } from '../../../utils/urlUtil'
import { getWSConnection } from '../../../utils/websocketUtils'
import { summaryUrl, scenarioNewConstructionUrl, scenarioRepairConstructionUrl } from '../../../constants/moduleConstants'
import { SCENARIO } from '../../../constants/licenseTypes'
import { setMainViewActiveIndex } from '../../../actions/view/actions'
import { setActiveRealEstate } from '../../../actions/activeRealEstate'
import { getIsFeatureEnabledInSet } from '../../../utils/features'
import { FEATURE_SCENARIO_RENOVATION_USE } from '../../../constants/features'

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  }
}))

const RealEstateListing = (): React$Element<'div'> => {
  const classes = useStyles()
  const history = useHistory()
  const {
    accessToken,
    haahtelaApiUserToken,
    userId,
    languageCode,
    selectedAccountId,
    userPrivileges,
    realEstateLastUpdatedTS,
    realEstateId,
    isPausingEnabledForUseCaseProjectLicenses
  } = useSelector(({
    app,
    user,
  }: TVDReduxStore): {|
    languageCode: $PropertyType<TVDApplicationStore, 'languageCode'>,
    selectedAccountId: $PropertyType<TVDApplicationStore, 'selectedAccountId'>,
    accessToken: $PropertyType<TVDUserAuthorization, 'accessToken'>,
    haahtelaApiUserToken: $PropertyType<TVDUserClaims, 'haahtelaApiUserToken'>,
    userId: $PropertyType<TVDUserClaims, 'userId'>,
    userPrivileges: $PropertyType<TVDUserStore, 'userPrivileges'>,
    realEstateLastUpdatedTS: $PropertyType<TVDApplicationStore, 'realEstateLastUpdatedTS'>,
    realEstateId: $PropertyType<TVDApplicationStore, 'realEstateId'>,
    isPausingEnabledForUseCaseProjectLicenses: $PropertyType<TVDApplicationStore, 'isPausingEnabledForUseCaseProjectLicenses'>,
  |} => ({
    languageCode: app.languageCode,
    selectedAccountId: app.selectedAccountId,
    accessToken: user.authorization.accessToken,
    haahtelaApiUserToken: user.claims.haahtelaApiUserToken,
    userId: user.claims.userId,
    userPrivileges: user.userPrivileges,
    realEstateLastUpdatedTS: app.realEstateLastUpdatedTS,
    realEstateId: app.realEstateId,
    isPausingEnabledForUseCaseProjectLicenses: app.isPausingEnabledForUseCaseProjectLicenses
  }))
  const { t } = useTranslation()


  const openTOS = (): void => {
    dispatch(openModal(
      {
        type: TERMS_OF_SERVICE,
        disableBackdropClick: false
      },
      TERMS_OF_SERVICE
    ))
  }

  const openBuildingSettingModal = (selectedRealEstateId: string, selectedRealEstateName: string): void => {
    dispatch(openModal(
      {
        type: BUILDING_SETTINGS,
        containerType: 'dialog',
        buildingName: selectedRealEstateName,
        contentProps: {
          realEstateId: selectedRealEstateId,
          realEstateName: selectedRealEstateName
        },
        onClose: () => dispatch(closeModal(BUILDING_SETTINGS))
      },
      BUILDING_SETTINGS
    ))
  }

  const openAccountInformation = (): void => {
    history.push({
      pathname: '/',
      search: createQueryParamsString({ [ACCOUNT_INFORMATION_VIEW_KEY]: ACCOUNT_INFORMATION_VIEW_VALUE_ESTATE_MANAGEMENT })
    })
    dispatch(setMainViewActiveIndex(1))
  }

  let updateRealEstateList = false

  const openRenameModal = (selectedRealEstateId: string, nameToRename: string): void => {
    dispatch(openModal(
      {
        type: RENAME_PROJECT,
        title: t('buttons._RENAME_'),
        contentProps: {
          realEstateId: selectedRealEstateId,
          nameToRename
        },
        onClose: () => dispatch(closeModal(RENAME_PROJECT))
      },
      RENAME_PROJECT
    ))
    updateRealEstateList = !updateRealEstateList
  }

  const getScenarioTypeURL = (scenarioType: string): string => {
    switch (scenarioType) {
      case 'newConstruction': {
        return scenarioNewConstructionUrl
      }
      case 'repairConstruction': {
        return scenarioRepairConstructionUrl
      }
      default: {
        console.error(`Error when resolving scenario type URL: scenario type ${scenarioType} is not among the supported types`)
        return ''
      }
    }
  }

  const handleRealEstateSelection = useCallback((
    selectedRealEstateId: string,
    selectedRealEstateName: string,
    selectedLicenseType: string,
    scenariType: string
  ): void => {
    history.push({
      pathname: selectedLicenseType === SCENARIO ? `${getScenarioTypeURL(scenariType)}/${selectedRealEstateName}` : summaryUrl,
      search: createQueryParamsString({ realEstateId: selectedRealEstateId })
    })
  }, [
    history
  ])

  const WSConnection = getWSConnection()

  const registeredRealEstateManagementModule = registeredMFEs['real-estate-management']
  // TODO: fix multiple loadMFE calls resulting in error of MFE being already loaded
  useEffect(() => {
    if (
      languageCode &&
      accessToken &&
      haahtelaApiUserToken &&
      userId &&
      selectedAccountId &&
      userPrivileges
    ) {
      const render = (module: TVDMFEFunctionality): void => {
        const moduleRender = module.render || null
        const setActiveRealEstateToStore = (realEstate: TVDRealEstate): void => {
          dispatch(setActiveRealEstate(realEstate))
        }
        if (moduleRender) {
          moduleRender(
            'real-estate-management',
            {
              APIBaseURL: API_BASE_URL,
              languageCode,
              accessToken,
              haahtelaApiUserToken,
              userId,
              selectedAccountId,
              openGenericErrorModal,
              openTOS,
              openBuildingSettingModal,
              openAccountInformation,
              handleRealEstateSelection,
              userPrivileges,
              ALLOW_WITH_CREDENTIALS,
              WSConnection,
              FEATURE_SET,
              openRenameModal,
              realEstateLastUpdatedTS,
              realEstateId,
              setActiveRealEstateToStore,
              allowRenovationScenarioCreation: getIsFeatureEnabledInSet(FEATURE_SCENARIO_RENOVATION_USE),
              isPausingEnabledForUseCaseProjectLicenses
            }
          )
        }
      }
      if (registeredRealEstateManagementModule) {
        render(registeredRealEstateManagementModule)
      } else {
        loadMFE('license_management', 'real-estate-management-module', (module: TVDMFEFunctionality): void => {
          render(module)
        })
      }
    }
  }, [
    languageCode,
    accessToken,
    haahtelaApiUserToken,
    userId,
    selectedAccountId,
    handleRealEstateSelection,
    userPrivileges,
    WSConnection,
    userPrivileges,
    registeredRealEstateManagementModule,
    FEATURE_SET,
    realEstateLastUpdatedTS,
    realEstateId,
    isPausingEnabledForUseCaseProjectLicenses
  ])
  return (
    <div className={classes.root} id='real-estate-management' />
  )
}

export default RealEstateListing
