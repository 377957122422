// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.
import React, { Component } from 'react'
import { withStyles } from '@material-ui/core'
import { withTranslation } from 'react-i18next'
import PropertyContainer from '../Tabs/Properties/PropertyContainer/PropertyContainer'
import { PROPERTIES_MODAL } from '../../../constants/contentTypes'
import FooterButtons from '../../containers/widgets/FooterButtons/FooterButtons'
import { type TextButtonProps } from '../TextButton/TextButton'

const styles = ({ palette }: TVDTheme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '65vh',
  },
  properties: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'auto',
    paddingTop: '16px',
    width: '750px'
  },
  footer: {
    width: '100%',
    display: 'flex',
    padding: '25px',
    position: 'relative',
    justifyContent: 'flex-start',
    alignSelf: 'flex-end'
  },
  replaceTip: {
    color: palette.nevada,
    fontSize: '12px',
    display: 'flex',
    alignItems: 'flex-end',
    wordWrap: 'break-word',
    width: '178px'
  },
  buttons: {
    display: 'flex',
    flex: '1',
    justifyContent: 'flex-end'
  }
})

export type PropertiesModalContentProps = {|
  saveButtonText: string, // text displayed in modal footer save button
  footerInfoText?: string, // info text displayed in modal footer
  onSave: Function, // action excecuted when modal save button is clicked
  onClose: Function, // action executed when modal cancel buttons is clicked
  customWidth?: number | string // custom width for modal that overwrites the default
|}

type Props = {|
  ...PropertiesModalContentProps,
  classes: Object, // withStyles classes object
  t: Function, // translate function
|}

export class PropertiesModal extends Component<Props> {
  getFooterButtonItems = (): TextButtonProps[] => {
    const { t, saveButtonText } = this.props
    const footerButtonItems: TextButtonProps[] = [
      {
        id: 'Properties-Modal-Save',
        onClick: this.props.onSave,
        text: t(saveButtonText),
      },
      {
        id: 'Properties-Modal-Cancel',
        onClick: this.props.onClose,
        text: t('widgets._CANCEL_'),
        variant: 'text'
      }
    ]
    return footerButtonItems
  }

  render(): React$Element<any> {
    const {
      classes,
      footerInfoText,
      t,
      customWidth
    } = this.props
    const footerButtonItems = this.getFooterButtonItems()
    const customStyles = customWidth ? { width: customWidth } : null
    return (
      <div className={classes.root} >
        <div className={classes.properties} style={customStyles}>
          <PropertyContainer type={PROPERTIES_MODAL} modalId={PROPERTIES_MODAL} propertiesStoreId={PROPERTIES_MODAL} disabled />
        </div>
        <div className={classes.footer}>
          {footerInfoText && <div className={classes.replaceTip} id='footer-info-text'>{t(footerInfoText)}</div>}
          <div className={classes.buttons}>
            <FooterButtons items={footerButtonItems} />
          </div>
        </div>
      </div>
    )
  }
}

export default withTranslation('translations')(withStyles(styles)(PropertiesModal))
