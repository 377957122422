// @flow
// Copyright © 2010–2022 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.
import {
  TOGGLE_DRAWER,
  SET_CONTEXT_MENU,
  SET_MAIN_VIEW_ACTIVE_INDEX
} from './actionTypes'

export const toggleDrawer = (payload: boolean) => ({
  type: TOGGLE_DRAWER,
  payload,
})

export const setContextMenu = (payload: Object) => ({
  type: SET_CONTEXT_MENU,
  payload,
})

export const setMainViewActiveIndex = (payload: number) => ({
  type: SET_MAIN_VIEW_ACTIVE_INDEX,
  payload,
})

