// @flow
// Copyright © 2010–2023 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.

import React, { Component } from 'react'
import { map } from 'lodash'
import { withStyles } from '@material-ui/core/styles'
import { colors } from 'frontend-assets'
import SwitchIcon from '../SwitchIcon/SwitchIcon'
import CheckBox from '../CheckBox/CheckBox'
import { combineStyleClassNames } from '../../../utils/styleUtils'
import { USE_DESIGN_MODEL_DEFAULTS } from '../../../constants/widgetKeys'
import theme from '../../../styles/theme'
// $FlowFixMe
import { ReactComponent as UserModifiedSVG } from
  '../../../../node_modules/frontend-assets/static/assets/images/icons/Other User defined.svg'
// $FlowFixMe
import { ReactComponent as CheckSingleSVG } from
  '../../../../node_modules/frontend-assets/static/assets/images/icons/Check Single.svg'

const styles = (): Object => ({
  bar: {
    display: 'flex',
    flex: '0 auto',
    flexDirection: 'row',
    backgroundColor: colors.gray20,
    color: colors.dark80,
    ...theme.typography.classes.bodyDefault
  },
  boxWrapper: {
    display: 'flex',
    flexDirection: 'row',
    margin: '12px 14px',
    alignItems: 'center',
    '&:first-of-type': {
      paddingLeft: '9px',
    }
  },
  checkboxContainer: {
    display: 'flex',
    alignItems: 'center',
    margin: 0
  },
  checkbox: {
    top: '0',
    '&$checked': {
      color: colors.primary100
    }
  },
  checked: {},
  checkboxIcon: {
    fontSize: '20px'
  },
  checkedIcon: {
    fontSize: '20px'
  },
  checkboxLabel: {
    display: 'inline-block',
    color: colors.dark80,
    alignSelf: 'center',
    marginLeft: '12px',
    paddingTop: '1px'
  },
  checkboxLabelLink: {
    color: colors.primary100,
    cursor: 'pointer',
    paddingLeft: '5px'
  },
  disabled: {
    cursor: 'initial',
    color: colors.disabledText
  }
})


type Props = {
  classes: Object, // withStyles styles prop
  children: React$Element<any>[], // Array of react elements for content,
  checkboxes: Array<TVDCheckBoxProps> // Array of objects which are rendered into checkboxes
}

export class ControlBar extends Component<Props> {
  get checkboxes(): Array<any> {
    const { classes } = this.props

    return map(this.props.checkboxes, (checkbox: TVDCheckBoxProps, i: number) => (
      <div key={`${i}-${checkbox.checked.toString()}`} className={classes.boxWrapper}>
        <span className={classes.checkboxContainer}>
          {
            checkbox.id === USE_DESIGN_MODEL_DEFAULTS
            ? <SwitchIcon
                truthy={checkbox.checked}
                truthyIcon={<CheckSingleSVG />}
                falsyIcon={<UserModifiedSVG />} />
            : <CheckBox
                className={classes.checkbox}
                checked={checkbox.checked}
                disabled={checkbox.disabled}
                onChange={checkbox.onChange}
                name={checkbox.name}
                id={checkbox.id} />
          }
        </span>
        { this.getCheckBoxLabel(checkbox) }
      </div>
    ))
  }

  getCheckBoxLabel(checkbox: Object): React$Element<any> | null {
    const { classes } = this.props
    switch (true) {
      case (typeof checkbox.label === 'string'): {
        return <div className={classes.checkboxLabel}>{`${checkbox.label}`}</div>
      }
      case Array.isArray(checkbox.label): {
        return (
          <div className={classes.checkboxLabel}>
            {
              map(checkbox.label, (label: string, idx: number) => (
                idx < 1 ? label : (
                  <span
                    key={label}
                    role='presentation'
                    data-testid='useDefaultValuesLink'
                    className={combineStyleClassNames(classes.checkboxLabelLink, checkbox.disabled && classes.disabled)}
                    onClick={!checkbox.disabled && checkbox.onChange}>
                    {label}
                  </span>
                )
              ))
            }
          </div>
        )
      }
      case typeof checkbox.label === 'function': { // for completely custom checkbox labels
        return checkbox.label()
      }
      default: {
        return null
      }
    }
  }


  render(): React$Element<any> {
    const { classes } = this.props
    return (
      <div className={classes.bar}>
        {this.checkboxes}
        {this.props.children}
      </div>
    )
  }
}

export default withStyles(styles)(ControlBar)
