// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.
import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { filter } from 'lodash'

import type { EditorProps, HorizontalSliderProps } from './Editor/Editor'
import Editor from './Editor/Editor'
import Levels from './Levels/Levels'
import ControlBar from '../../../common/ControlBar/ControlBar'
import FeaturesHOC from '../../../hocs/FeaturesHOC/FeaturesHOC'
import SentientHOC from '../../../hocs/SentientHOC/SentientHOC'

import { USE_DESIGN_MODEL_DEFAULTS, UPDATE_PROTECTED_CIRCULATION, UPDATE_STRUCTURES } from '../../../../constants/widgetKeys'
import { CONFIRMATION_MODAL } from '../../../../constants/contentTypes'
import { openModal, closeModal } from '../../../../actions/modals'
import { openContentWidget } from '../../../../actions/widgets'
import { postPolling } from '../../../../actions/postPolling'
import {
  patchDesignModelWithEstimateIdRequest,
  patchDesignModelStoriesWithStoryIdRequest,
  getDesignModelWithEstimateIdRequestDefinitions,
  getDesignModelStoriesWithEstimateIdRequestDefinitions,
  getEstimateSettingsWithEstimateIdRequestDefinitions,
  patchEstimateSettingsWithEstimateIdRequest
} from '../../../../utils/generated-api-requests/spaces'
import {
  updateSlider,
  updateVerticalSlider as updateVerticalSliderAction,
  setUpdateProtectedCirculation
} from '../../../../actions/widgets/designModelWidget'
import { MODAL_TYPE_TOGGLE_EXTERNAL_GEOMETRY } from '../../../../constants/modalConstants'

const CHECKBOX_HEIGHT = 40
const CHECKBOX_BAR_MARGIN_BOTTOM = 2
const CONTENT_MIN_HEIGHT = 550

const styles = ({ palette, typography }: TVDTheme) => ({
  root: {
    flexDirection: 'column',
    height: '100%',
    width: '100%',
    overflow: 'hidden',
    justifyContent: 'center',
    position: 'relative',
  },
  checkBoxesWrapper: {
    display: 'flex',
    paddingLeft: '15px',
    backgroundColor: palette.catskillWhite,
    marginBottom: CHECKBOX_BAR_MARGIN_BOTTOM
  },
  checkboxWrapper: {
    height: CHECKBOX_HEIGHT,
    marginRight: '30px'
  },
  content: {
    display: 'flex',
    height: `calc(100% - ${CHECKBOX_HEIGHT + CHECKBOX_BAR_MARGIN_BOTTOM}px)`,
    overflow: 'auto',
    position: 'relative'
  },
  left: {
    minWidth: CONTENT_MIN_HEIGHT,
    width: '60%',
  },
  right: {
    minWidth: '355px',
    width: '40%',
    backgroundColor: palette.catskillWhite,
  },
  checkboxLabel: {
    font: `14px ${typography.fontFamilyBase}`,
    color: palette.nevada
  }
})

export type PatchDesignModelQueryParams = {|
  updateProtectedCirculation?: boolean, // update protected circulation checkbox boolean
  updateStructures?: boolean // 25.09.2020 deprecated query param will be removed when also removed from swagger
|}

type HOCProps = {|
  t: Function, // i18n translation function
  theme: Object, // MUI Theme object
  classes: Object, // classes-object created by withstyles function
  sentient: TVDSentient, // Object providing helpers via SentientHOC
  features: TVDFeatureHOCProps // features from Store and helper functions from the HOC
|}

type MappedProps = {|
  calculation: string, // current calculation
  architectureSliders: Array<Object>, // Array of sliders that are rendered as SVGSlider components in architecture section
  horizontalSliders?: Array<HorizontalSliderProps>, // array of sliders that are rendered as SVGSlider components
  verticalSliders: Array<TVDSVGVerticalSlider>, // array of vertical sliders tha are rendered as SVGVerticalSlider components
  editor: EditorProps, // editor related data
  isEstimateLockedToCurrentUser: $PropertyType<TVDApplicationStore, 'isEstimateLockedToCurrentUser'>, // if the user owns the lock for the estimate
  updateProtectedCirculation: boolean, // designModel updateProtectedCirculation option value
  showStructures: boolean, // estimate settings show structures (Näytä Rakenteet) checkbox value
  isEstimateFrozen: $PropertyType<TVDApplicationStore, 'isEstimateFrozen'>, // if estimate is frozen
|}

type DispatchProps = {|
  dispatchUpdateSlider: Function, // function to handle value changes of SVGSlider components
  dispatchOpenContentWidget: Function, // function to open widget
  dispatchSetUpdateProtectedCirculation: (value: boolean) => void, // setting updateProtectedCirculation value to DesignModel store
  dispatchSetShowStructures: (value: boolean) => void, // setting updateStructures value to DesignModel store
  patchSlider: Function, // function to make patch request with updated slider value
  toggleSlider: Function, // enable or disable slider
  toggleDefaults: Function, // function to patch UseDefaultValues attribute with value: true - this returns designModel to default state calculated by backend
  updateVerticalSlider: Function, // function to handle value changes of vertical slider components
  dispatchOpenToggleExternalGeomtetryModal: (toggleValue: boolean, designModelPatchQueryParams: PatchDesignModelQueryParams) => void, // addme
|}

type Props = {|
  ...HOCProps,
  ...MappedProps,
  ...DispatchProps,
  disabled: boolean, // flag to disable widget content
|}

type State = {|
  levelsTop: number, // the amount of pixels that level graphs should be from top of the container
|}

export class DesignModelContainer extends Component<Props, State> {
  static defaultProps = {
    isEstimateLockedToCurrentUser: false,
  }

  state = {
    levelsTop: 0
  }

  editorRef: TVDRef = React.createRef()
  innerEditorRef: TVDRef = React.createRef()
  floorsRef: TVDRef = React.createRef()

  componentDidMount() {
    const { sentient } = this.props
    sentient.runSetup()
  }

  componentDidUpdate = () => {
    const { levelsTop } = this.state
    const newLevelsTop = this.getLevelsTop()
    if (levelsTop !== newLevelsTop) {
      this.setState({ levelsTop: newLevelsTop })
    }
  }

  getLevelsTop = (): number => {
    const { current } = this.editorRef
    const { current: innerEditorRefCurrent } = this.innerEditorRef
    const { current: floorsRefCurrent } = this.floorsRef
    if (current && innerEditorRefCurrent && floorsRefCurrent) {
      return current.offsetTop + innerEditorRefCurrent.offsetTop + floorsRefCurrent.offsetTop + floorsRefCurrent.offsetHeight
    }
    return 0
  }

  getSliderHeightsTotal = (sliders?: Array<Object>): number => {
    if (Array.isArray(sliders)) {
      return sliders.reduce((result: number, slider: Object) => result + slider.HeightIncludingSlabM, 0)
    }
    return 0
  }

  patchSlider = (slider: Object, value: number, sliderGroup: string): void => {
    // for business logical reasons we ant to patch also constructedGroundSurfaceM slider value
    // when LevelOfOriginalGroundSurfaceM value changes
    if (slider.id === 'LevelOfOriginalGroundSurfaceM') {
      const constructedGroundSurfaceMSlider = this.props.verticalSliders[0]
      this.props.patchSlider(slider, value, sliderGroup, this.patchDesignModelQueryParams(), constructedGroundSurfaceMSlider)
    } else {
      this.props.patchSlider(slider, value, sliderGroup, this.patchDesignModelQueryParams())
    }
  }

  lockedStories = (): number => filter(this.props.horizontalSliders, { RatioIsLocked: true }).length

  isDisabled = (): boolean => {
    const { disabled, isEstimateLockedToCurrentUser, isEstimateFrozen } = this.props
    return disabled || !isEstimateLockedToCurrentUser || !!isEstimateFrozen
  }

  patchDesignModelQueryParams(): PatchDesignModelQueryParams {
    const { updateProtectedCirculation } = this.props
    return { updateProtectedCirculation }
  }

  checkBoxes(): Array<TVDCheckBoxProps> {
    const {
      t,
      features,
      editor,
      toggleDefaults,
      dispatchSetUpdateProtectedCirculation,
      dispatchSetShowStructures,
      showStructures
    } = this.props
    const { updateProtectedCirculation } = this.patchDesignModelQueryParams()

    return features.getEnabledFeatures([
      {
        id: USE_DESIGN_MODEL_DEFAULTS,
        featureName: USE_DESIGN_MODEL_DEFAULTS,
        disabled: this.isDisabled(),
        checked: editor.UseDefaultValues,
        name: 'useDefaults',
        label: editor.UseDefaultValues
          ? t(`designModel._${USE_DESIGN_MODEL_DEFAULTS}_TRUE_`)
          : [t(`designModel._${USE_DESIGN_MODEL_DEFAULTS}_FALSE_`), t('designModel._USE_DEFAULTS_LABEL_')],
        onChange: () => toggleDefaults(!editor.UseDefaultValues, this.patchDesignModelQueryParams())
      },
      {
        id: UPDATE_PROTECTED_CIRCULATION,
        featureName: UPDATE_PROTECTED_CIRCULATION,
        disabled: this.isDisabled() || editor.MassingPerformedByUser,
        checked: updateProtectedCirculation,
        name: 'updateProtectedCirculation',
        label: t(`designModel._${UPDATE_PROTECTED_CIRCULATION}_`),
        onChange: () => dispatchSetUpdateProtectedCirculation(!updateProtectedCirculation)
      },
      {
        id: UPDATE_STRUCTURES,
        featureName: UPDATE_STRUCTURES,
        disabled: this.isDisabled() || editor.MassingPerformedByUser,
        checked: showStructures,
        name: 'createUpdateSctructures',
        label: t(`designModel._${UPDATE_STRUCTURES}_`),
        onChange: () => dispatchSetShowStructures(!showStructures)
      }
    ])
  }

  render(): React$Element<any> {
    const {
      architectureSliders,
      classes,
      horizontalSliders,
      verticalSliders,
      dispatchUpdateSlider,
      toggleSlider,
      updateVerticalSlider,
      dispatchOpenContentWidget,
      editor,
      theme: { dimensions: { designModelEditorRowHeightToPxMultiplier } },
      dispatchOpenToggleExternalGeomtetryModal
    } = this.props

    const { levelsTop } = this.state

    const totalStoriesHeightMeters = this.getSliderHeightsTotal(horizontalSliders)
    const totalStoriesHeightPixels = totalStoriesHeightMeters * designModelEditorRowHeightToPxMultiplier
    const editorContentHeight = horizontalSliders ? CONTENT_MIN_HEIGHT + (totalStoriesHeightPixels) : CONTENT_MIN_HEIGHT

    return (
      <div className={classes.root}>
        { editor &&
          <>
            <ControlBar checkboxes={this.checkBoxes()} />
            <div className={classes.content}>
              <div className={classes.left} style={{ minHeight: editorContentHeight }}>
                {
                editor && <Editor
                  editorRef={this.editorRef}
                  innerEditorRef={this.innerEditorRef}
                  floorsRef={this.floorsRef}
                  architectureSliders={architectureSliders}
                  disabled={this.isDisabled()}
                  editor={editor}
                  horizontalSliders={horizontalSliders}
                  manualFeeding={editor.MassingPerformedByUser}
                  updateSlider={dispatchUpdateSlider}
                  patchSlider={this.patchSlider}
                  toggleSlider={toggleSlider}
                  openWidget={dispatchOpenContentWidget}
                  toggleManualFeeding={() => {
                    dispatchOpenToggleExternalGeomtetryModal(!editor.MassingPerformedByUser, this.patchDesignModelQueryParams())
                  }} />
                }
              </div>
              <div className={classes.right} style={{ minHeight: editorContentHeight }}>
                <Levels
                  levelsTop={levelsTop}
                  verticalSliders={verticalSliders}
                  updateVerticalSlider={updateVerticalSlider}
                  patchSlider={this.patchSlider}
                  disabled={this.isDisabled() || editor.MassingPerformedByUser || this.lockedStories() > 0} />
              </div>
            </div>
          </>
      }
      </div>
    )
  }
}

function mapStateToProps({ designModel, app }: TVDReduxStore): MappedProps {
  const { calculation, isEstimateLockedToCurrentUser, isEstimateFrozen } = app
  const {
    architectureSliders,
    verticalSliders,
    horizontalSliders,
    editor,
    options: {
      updateProtectedCirculation,
      showStructures
    }
  } = designModel

  return {
    architectureSliders,
    editor,
    horizontalSliders,
    verticalSliders,
    calculation,
    isEstimateLockedToCurrentUser,
    updateProtectedCirculation,
    showStructures,
    isEstimateFrozen
  }
}

function mapDispatchToProps(dispatch: Function, { t }: Props): DispatchProps {
  return {
    dispatchSetUpdateProtectedCirculation: (value: boolean) => { dispatch(setUpdateProtectedCirculation(value)) },
    dispatchSetShowStructures: (value: boolean) => {
      patchEstimateSettingsWithEstimateIdRequest({ body: { ShowStructures: value } }, {}, () => dispatch(postPolling()))
    },
    toggleDefaults: (value: boolean, query: PatchDesignModelQueryParams) => {
      const modalId = 'DESIGN_MODEL_CONFIRM_DEFAULTS_DIALOG'
      const patch = () => {
        patchDesignModelWithEstimateIdRequest({ body: { UseDefaultValues: value }, query }, {}, () => { dispatch(postPolling()) })
      }
      if (value) {
        dispatch(openModal({
          saveButtonText: t('designModel._USE_DEFAULTS_'),
          message: t('designModel._USE_DEFAULTS_WARNING_'),
          onSave: () => patch(),
          onClose: () => dispatch(closeModal(modalId)),
          type: CONFIRMATION_MODAL
        }, modalId))
      } else {
        patch()
      }
    },
    toggleSlider: (slider: Object, query: PatchDesignModelQueryParams) => {
      patchDesignModelStoriesWithStoryIdRequest({
        path: { storyId: slider.id },
        body: { RatioIsLocked: !slider.RatioIsLocked },
        query
      }, {}, () => dispatch(postPolling()))
    },
    dispatchUpdateSlider: (sliderId: string, width: number, sliderGroup: string) => { dispatch(updateSlider(sliderId, width, sliderGroup)) },
    patchSlider: (slider: Object, value: number, sliderGroup: string, query: PatchDesignModelQueryParams, relatedSlider?: Object) => {
      // value is patched with sliders propertyName
      // architecturesliders have their propertyName bound in name attribute and verticalSliders have it bound in id attribute
      const sliderName: string = sliderGroup === 'verticalSliders' ? slider.id : slider.name
      switch (sliderGroup) {
        case 'architectureSliders':
        case 'verticalSliders': {
          if (relatedSlider) {
            patchDesignModelWithEstimateIdRequest(
              { body: { [sliderName]: value, [relatedSlider.id]: relatedSlider.value }, query }, {},
              () => dispatch(postPolling())
            )
          } else {
            patchDesignModelWithEstimateIdRequest({ body: { [sliderName]: value }, query }, {}, () => dispatch(postPolling()))
          }
          break
        }
        case 'horizontalSliders':
          patchDesignModelStoriesWithStoryIdRequest({
            path: { storyId: slider.id },
            body: { RatioSpaceArea: value },
            query
          }, {}, () => dispatch(postPolling()))
          break
        default:
          break
      }
    },
    updateVerticalSlider: (id: string, height: number, value: number) => { dispatch(updateVerticalSliderAction(id, height, value)) },
    dispatchOpenContentWidget: (contentProps: Object, widgetId: string, widgetType: string, widgetTitle: string) => {
      dispatch(openContentWidget({
        contentProps, widgetId, widgetType, widgetTitle
      }))
    },
    dispatchOpenToggleExternalGeomtetryModal: (toggleValue: boolean, designModelPatchQueryParams: PatchDesignModelQueryParams) => {
      dispatch(openModal({
        id: MODAL_TYPE_TOGGLE_EXTERNAL_GEOMETRY,
        type: MODAL_TYPE_TOGGLE_EXTERNAL_GEOMETRY,
        contentProps: {
          toggleValue,
          designModelPatchQueryParams
        }
      }, MODAL_TYPE_TOGGLE_EXTERNAL_GEOMETRY))
    }
  }
}

const sentientConfig: TVDSentientConfig = {
  getSetupRequestDefinitions: (): TVDGARConfigs =>
    ({
      getDesignModelWithEstimateIdRequestDefinitions: getDesignModelWithEstimateIdRequestDefinitions({
        payload: {}
      }),
      getDesignModelStoriesWithEstimateIdRequestDefinitions: getDesignModelStoriesWithEstimateIdRequestDefinitions({
        payload: {}
      }),
      getEstimateSettingsWithEstimateIdRequestDefinitions: getEstimateSettingsWithEstimateIdRequestDefinitions({
        payload: {}
      })
    })
}

export default withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)((
  withStyles(styles, { withTheme: true })(FeaturesHOC((SentientHOC(DesignModelContainer, sentientConfig)))))))
