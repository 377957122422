// @flow
// Copyright © 2010–2022 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.

// Tilat - Suunnittelumallin käsinsyötto
export const MODULE_FEATURE_DESIGN_MODEL_MANUAL_FEEDING = 101

// Alternative module feature enum that allows manual feeding
export const MODULE_FEATURE_DESIGN_MODEL_MANUAL_FEEDING_ALTERNATIVE = 115

// Tilat - Laaja rakennusosat
export const MODULE_FEATURE_EXTENSIVE_BUILDING_ELEMENTS = 102

// Tilamitoitus - Tapahtumat
export const MODULE_FEATURE_SPACE_PROGRAM_ACTIVITIES = 301

// Tilamitoitus - Aikamalli
export const MODULE_FEATURE_SPACE_PROGRAM_TIME_MODEL = 302

// Tilamitoitus - Ryhmittely
export const MODULE_FEATURE_SPACE_PROGRAM_TIME_GROUPING = 303

