// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.

import React, { Component } from 'react'
import { withStyles } from '@material-ui/core'
import { colors, typographyClasses, boxShadows } from 'frontend-assets'
import { withTranslation } from 'react-i18next'
import AppBar from '@material-ui/core/AppBar'
import MUITabs from '@material-ui/core/Tabs'
import MUITab from '@material-ui/core/Tab'

const styles = (): Object => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: 0, // fixes vertical scrolling when using firefox https://stackoverflow.com/a/28639686
    height: '100%'
  },
  tabsBar: {
    backgroundColor: colors.white,
    color: colors.dark80,
    boxShadow: boxShadows.boxShadowComponent,
  },
  selectedTab: {
    color: colors.primary100,
  },
  tabPrimary: {
    color: colors.dark80,
  },
  tabsContent: {
    height: '100%',
    overflow: 'auto'
  },
  indicator: {
    backgroundColor: colors.primary100,
    height: 3
  },
  tabRoot: {
    ...typographyClasses.bodyDefaultBold,
    textTransform: 'none',
    opacity: 1,
    borderBottom: `3px solid ${colors.white}`,
    '&:hover': {
      borderBottom: `3px solid ${colors.dark60}`,
      opacity: 1
    },
  },
})

type Props = {
  classes: Object, // withStyles classes object
  tabs: Array<TVDTab>, // list of objects containing prop data for the tabs
  initialSelectedTab?: number, // the nth tab that should be selected when opening the TabsPlain
  onChange?: (selectedTab: number) => void, // option onChange listener for when tab focus is changed to antoher
  selectedTab?: number, // optional index value of the tab that is selected if the value needs to be controlled
}

type State = {
  selectedTab: number, // selected tab number that is initialized from props
}


export class TabsPlain extends Component<Props, State> {
  static defaultProps = {
    initialSelectedTab: 0,
    onChange: undefined,
    selectedTab: undefined
  }

  state = {
    selectedTab: this.props.initialSelectedTab || this.props.selectedTab || TabsPlain.defaultProps.initialSelectedTab,
  }

  get tabs(): Array<typeof MUITab> {
    const { tabs, classes } = this.props
    return (
      tabs.map((tab: TVDTab, index: number): React$Element<typeof MUITab> => (
        <MUITab
          data-testid={tab.testId}
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          classes={{ root: classes.tabRoot, textColorPrimary: classes.tabPrimary, selected: classes.selectedTab }}
          {...tab.props} />))
    )
  }

  get appBar(): React$Element<typeof AppBar> {
    const { selectedTab } = this.state
    const { classes } = this.props
    return (
      <AppBar className={classes.tabsBar} position='static'>
        <MUITabs value={selectedTab} onChange={this.handleChange} classes={{ indicator: classes.indicator, flexContainer: classes.wrapper }}>
          {this.tabs}
        </MUITabs>
      </AppBar>
    )
  }

  componentDidUpdate = (prevProps: Props) => {
    const { selectedTab: prevSelectedTab } = prevProps
    const { selectedTab } = this.props
    if (!isNaN(selectedTab) && !isNaN(prevSelectedTab) && prevSelectedTab !== selectedTab) {
      this.setState({ selectedTab })
    }
  }

  handleChange = (event: SyntheticEvent<any>, selectedTab: any) => {
    const { onChange } = this.props
    this.setState({ selectedTab })
    if (onChange) onChange(selectedTab)
  }

  getContent = (): React$Element<'div'> => {
    const { tabs, classes, selectedTab: controlledSelectedTab } = this.props
    const { selectedTab } = this.state
    const { content, contentStyles } = tabs[controlledSelectedTab || selectedTab]
    return (
      <div data-testid='tabsContent' className={classes.tabsContent} style={contentStyles || undefined}>
        {content}
      </div>
    )
  }

  render(): React$Element<any> {
    const { classes } = this.props
    return (
      <div className={classes.root}>
        {this.appBar}
        {this.getContent()}
      </div>
    )
  }
}

export default withStyles(styles)(withTranslation('translations')(TabsPlain))
