// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.

import axios from 'axios'
import { TVD_HEADER_ACCOUNT_ID } from '../../../constants/apiConstants'
import {
  API_BASE_URL,
  ALLOW_WITH_CREDENTIALS
} from '../../../constants/envConstants'
import { getDefaultRequestHeaders } from './requestUtils'

const executeQuery = async (endpoint: string, params: Object) => {
  const headers = getDefaultRequestHeaders()
  const { data } = await axios.get(`${API_BASE_URL}/real-estate-api${endpoint}`, {
    headers: {
      ...headers
    },
    params: {
      accountId: headers[TVD_HEADER_ACCOUNT_ID],
      ...params
    },
    ...(ALLOW_WITH_CREDENTIALS ? { withCredentials: true } : {})
  })
  return data
}

export const getRealEstates = async () => {
  const realEstates = await executeQuery('/real-estates')
  return realEstates
}

export const getRealEstateById = async (realEstateId: string) => {
  const realEstate = await executeQuery(`/real-estates/${realEstateId}`)
  return realEstate
}

export const getRealEstatesColumns = async (view: string = 'landingpage') => {
  const columns = await executeQuery('/real-estates/columns', { view })
  return columns
}

export const followRealEstateLink = async (URL: string) => {
  const result = await executeQuery(URL)
  return result
}

