// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withTranslation } from 'react-i18next'
import { withStyles } from '@material-ui/core/styles'
import { each } from 'lodash'

import HierarchicalListContainer from '../../HierarchicalListContainer/HierarchicalListContainer'
import DescriptionCell from '../../../common/lists/common/DescriptionCell/DescriptionCell'
import HamburgerMenu from '../../../common/menus/HamburgerMenu/HamburgerMenu'
import SentientHOC from '../../../hocs/SentientHOC/SentientHOC'

import { CREATE_OTHER_SPACES } from '../../../../constants/contentTypes'
import {
  CIRCULATION,
  FACILITY_SERVICES,
  TECHNICAL_BUILDING_SERVICES,
  PROTECTED_CIRCULATION
} from '../../../../constants/registryConstants'

import { postPolling } from '../../../../actions/postPolling'
import {
  postCommonFunctionsWithFunctionTypeRequest,
  putCommonFunctionsWithFunctionTypeRequest,
  getCommonFunctionsColumnsWithFunctionTypeRequestDefinitions,
  getCommonFunctionsWithFunctionTypeRequestDefinitions
} from '../../../../utils/generated-api-requests/spaces'
import ViewHeaderText from '../../../common/ViewHeaderText/ViewHeaderText'

const styles = () => ({
  descriptionCellContent: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    marginRight: 10,
    width: '100%',
    outline: 0
  },
})

type MappedProps = {|
  calculation: string, // id of currently opened calculation
  isEstimateLockedToCurrentUser: $PropertyType<TVDApplicationStore, 'isEstimateLockedToCurrentUser'>, // if the user owns the lock for the estimate
  list: Object, // state list object
  isEstimateFrozen?: $PropertyType<TVDApplicationStore, 'isEstimateFrozen'> // if estimate is frozen
|}

type DispatchProps = {|
  dispatchPostPolling: () => void, // initiates post polling
|}

type FunctionTypes = TECHNICAL_BUILDING_SERVICES | FACILITY_SERVICES | CIRCULATION | PROTECTED_CIRCULATION

type Props = {|
  ...MappedProps,
  ...DispatchProps,
  classes: Object, // classes object
  t: Function, // translation function
  functionType: FunctionTypes,
  resourceListId: string, // id of list containing current functions
  sentient: TVDSentient, // Object providing helpers via SentientHOC
|}

export class CommonFunctions extends Component<Props> {
  static defaultProps = {
    isEstimateLockedToCurrentUser: false,
  }

  showHamburgerMenu = (row: TVDListItem): boolean => {
    const { isEstimateLockedToCurrentUser, isEstimateFrozen } = this.props
    return !!isEstimateLockedToCurrentUser && !isEstimateFrozen && row.level !== 3
  }

  addCommonFunctions(rowId: string, replace?: boolean) {
    const {
      dispatchPostPolling,
      functionType,
      list,
      resourceListId
    } = this.props
    const { listItems } = list[resourceListId]

    const referenceIdsToAdd = []
    referenceIdsToAdd.push(listItems[rowId].referenceId)
    const getChildrenReferenceId = (parentId: Object) => {
      each(listItems, (listItem: Object) => {
        if (listItem.parentId && listItem.parentId === parentId) {
          referenceIdsToAdd.push(listItem.referenceId)
          getChildrenReferenceId(listItem.id)
        }
      })
    }
    getChildrenReferenceId(rowId)
    const fn = replace ? putCommonFunctionsWithFunctionTypeRequest : postCommonFunctionsWithFunctionTypeRequest
    fn({ path: { functionType }, body: referenceIdsToAdd }, {}, () => dispatchPostPolling())
  }

  subheading(): React$Element<ViewHeaderText> {
    const { t } = this.props
    return (
      <ViewHeaderText inWidget>
        {t(`createOtherSpaces._CREATE_${this.props.functionType.toUpperCase()}_OBJECTS_`)}
      </ViewHeaderText>
    )
  }

  getContextMenuItems(row: Object): Array<TVDMenuItem> {
    const { t, functionType } = this.props

    const addCommonFunctions = {
      localizedName: t('createOtherSpaces._ADD_COMMON_FUNCTIONS_'),
      onClick: () => this.addCommonFunctions(row.id),
      testId: `ContextMenuItem-${row.id}-AddCommonFunctions`
    }
    const addCirculation = {
      localizedName: row.level === 2
        ? t('createOtherSpaces._ADD_SPACEGROUP_LEVEL_CIRCULATION_')
        : t('createOtherSpaces._ADD_HEADING_LEVEL_CIRCULATION_'),
      onClick: () => this.addCommonFunctions(row.id),
      testId: `ContextMenuItem-${row.id}-AddCirculation`
    }
    const replaceCirculation = {
      localizedName: row.level === 2
        ? t('createOtherSpaces._REPLACE_SPACEGROUP_LEVEL_CIRCULATION_')
        : t('createOtherSpaces._REPLACE_HEADING_LEVEL_CIRCULATION_'),
      onClick: () => this.addCommonFunctions(row.id, true),
      testId: `ContextMenuItem-${row.id}-ReplaceCirculation`
    }
    return functionType === CIRCULATION ? [addCirculation, replaceCirculation] : [addCommonFunctions]
  }

  getHamburgerMenu(row: Object): React$Element<HamburgerMenu> {
    const { columnData: { Description } = {} } = row
    return <HamburgerMenu visibleOnHover id={Description} items={this.getContextMenuItems(row)} />
  }

  render(): React$Element<any> {
    const {
      classes,
      isEstimateLockedToCurrentUser,
      resourceListId: listId,
      isEstimateFrozen
    } = this.props
    return (
      <React.Fragment>
        {this.subheading()}
        <HierarchicalListContainer
          isEstimateFrozen={isEstimateFrozen}
          listType={CREATE_OTHER_SPACES}
          contextMenuItems={(row: Object) => this.getContextMenuItems(row)}
          isEstimateLockedToCurrentUser={isEstimateLockedToCurrentUser}
          wrappedCellContents={{
            Description: ({ content, row }: Object) => (
              <div className={classes.descriptionCellContent} data-testid='test-desciption-cell'>
                <DescriptionCell noPointer highlighted={row.level > 1} text={content} />
                {
                  this.showHamburgerMenu(row) && this.getHamburgerMenu(row)
                }
              </div>
            )
          }}
          didMountCallback={() => {
            const { sentient } = this.props
            sentient.runSetup()
          }}
          listId={listId} />
      </React.Fragment>
    )
  }
}

const mapStateToProps = ({ app, list }: TVDReduxStore): MappedProps => {
  const { calculation, isEstimateLockedToCurrentUser, isEstimateFrozen } = app
  return {
    calculation,
    isEstimateLockedToCurrentUser,
    list,
    isEstimateFrozen
  }
}

const mapDispatchToProps = (dispatch: Function): DispatchProps => ({
  dispatchPostPolling: () => { dispatch(postPolling()) }
})

const sentientConfig: TVDSentientConfig = {
  updateOnPropsChange: ({ functionType }: Props) => ({ functionType }),
  getSetupRequestDefinitions: (store: Object, { functionType, resourceListId }: Props): TVDGARConfigs =>
    ({
      getCommonFunctionsColumnsWithFunctionTypeRequestDefinitions: getCommonFunctionsColumnsWithFunctionTypeRequestDefinitions({
        payload: { listId: resourceListId },
        requestArgs: { path: { functionType } }
      }),
      getCommonFunctionsWithFunctionTypeRequestDefinitions: getCommonFunctionsWithFunctionTypeRequestDefinitions({
        payload: { listId: resourceListId, mergeOptions: { initialListItems: {}, openAllListItems: true } },
        requestArgs: { path: { functionType } }
      })
    })
}

export default compose(
  withStyles(styles),
  withTranslation('translations'),
  connect(mapStateToProps, mapDispatchToProps),
)(SentientHOC(CommonFunctions, sentientConfig))
